import { APIClientBase, HTTP_METHOD } from '@/clients/api-client-base'
import { ReservesPaymentsCheckedGetResponse } from '@/dtos/reserve-funds/payments/checked/get'
import { ReservePaymentsCheckedPostRequest } from '@/dtos/reserve-funds/payments/checked/post'
import { ReservePaymentsGetResponse } from '@/dtos/reserve-funds/payments/get'
import { ReservePaymentDetailGetRequest, ReservePaymentDetailGetResponse } from '@/dtos/reserve-funds/payments/get-detail'
import { ReservePaymentsPdfGetRequest, ReservesPaymentsPdfGetResponse } from '@/dtos/reserve-funds/payments/pdf/get'
import { PersonalPlansGetRequest, PersonalPlansGetResponse } from '@/dtos/reserve-funds/payments/plans/get'
import { PersonalPlansInitialFormGetRequest, PersonalPlansInitialFormGetResponse } from '@/dtos/reserve-funds/payments/plans/initial-form/get-detail'
import { PersonalPlansPostRequest } from '@/dtos/reserve-funds/payments/plans/post'
import { ReservesPersonalPlanProspectsGetRequest, ReservesPersonalPlanProspectsGetResponse } from '@/dtos/reserve-funds/payments/plans/prospects/get'
import { ReservePaymentsPutRequest } from '@/dtos/reserve-funds/payments/put'

class ReservePaymentsClient extends APIClientBase {
  constructor() {
    super('/reserves/payments')
  }

  async getPayments():Promise<ReservePaymentsGetResponse> {
    return await this.get('', undefined, ReservePaymentsGetResponse)
  }

  async putPayments(req:ReservePaymentsPutRequest):Promise<void> {
    return await this.put('', req)
  }

  async getPaymentDetail(req:ReservePaymentDetailGetRequest):Promise<ReservePaymentDetailGetResponse> {
    return await this.get(`${req.buildingUnitId}`, undefined, ReservePaymentDetailGetResponse)
  }

  async getPaymentsPdf(req:ReservePaymentsPdfGetRequest):Promise<ReservesPaymentsPdfGetResponse> {
    return this.get('pdf', req, ReservesPaymentsPdfGetResponse)
  }

  async getPaymentsCsv():Promise<void> {
    return await this.download('csv', null, HTTP_METHOD.GET, true)
  }

  async getPaymentsChecked():Promise<ReservesPaymentsCheckedGetResponse> {
    return await this.get('checked', undefined, ReservesPaymentsCheckedGetResponse)
  }

  async postPaymentsChecked(req:ReservePaymentsCheckedPostRequest):Promise<void> {
    return await this.post('checked', req)
  }

  async getPersonalPlans(req:PersonalPlansGetRequest):Promise<PersonalPlansGetResponse> {
    const { buildingUnitId, ...params } = req
    return await this.get(`${buildingUnitId}/plans`, params, PersonalPlansGetResponse)
  }

  async postPersonalPlans(req:PersonalPlansPostRequest):Promise<void> {
    return await this.post(`${req.buildingUnitId}/plans`, req)
  }

  async getPersonalPlansInitialForm(req:PersonalPlansInitialFormGetRequest):Promise<PersonalPlansInitialFormGetResponse> {
    const { buildingUnitId, ...params } = req
    return await this.get(`${buildingUnitId}/plans/initialForm`, params, PersonalPlansInitialFormGetResponse)
  }

  getPaymentPersonalProspects(req: ReservesPersonalPlanProspectsGetRequest): Promise<ReservesPersonalPlanProspectsGetResponse> {
    const { buildingUnitId, ...params } = req
    return this.get(`${buildingUnitId}/plans/prospects`, params, ReservesPersonalPlanProspectsGetResponse)
  }
}

export const reservePaymentsClient = new ReservePaymentsClient()
