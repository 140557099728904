import { IRepairPlansIO, IRepairPlansIOCategoryItem } from './common'

export class RepairPlanPostCategoryItem implements IRepairPlansIOCategoryItem {
  categoryLabel: string
  itemLabel: string
  prospects: (number | undefined)[]
  performances: (number | undefined)[]

  constructor(categoryLabel: string, itemLabel: string, prospects: (number | undefined)[], performances: (number | undefined)[]) {
    this.categoryLabel = categoryLabel
    this.itemLabel = itemLabel
    this.prospects = prospects
    this.performances = performances
  }
}

export class RepairPlanPostRequest implements IRepairPlansIO {
  public currentPeriod: number
  public planningStartPeriod: number
  public uploadedFileName: string
  public categories: RepairPlanPostCategoryItem[]

  constructor(currentPeriod: number, planningStartPeriod: number, uploadedFileName: string, categories: RepairPlanPostCategoryItem[]) {
    this.currentPeriod = currentPeriod
    this.planningStartPeriod = planningStartPeriod
    this.uploadedFileName = uploadedFileName
    this.categories = categories
  }
}
