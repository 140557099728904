














































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Header, TableItemInterface } from '@/components/molecules/SmTableData.vue'
import { QUESTIONNAIRE_STATES } from '@/constants/schema-constants'
import { QuestionnairesRespondentsGetRequest, QuestionnairesRespondentsGetResponse, Respondent } from '@/dtos/questionnaires/respondents/get'
import { QuestionnaireDetailGetRequest } from '@/dtos/questionnaires/get'
import { questionnairesModule } from '@/stores/questionnaires-store'
import { staticRoutes } from '@/routes'
import { generateUuid } from '@/libs/uuid-generator'

export class RespondentTableItemDto extends Respondent implements TableItemInterface {
  hideItem = false
  checkboxDisabled!: boolean
  answered!: string
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),

    QuestionnairesProxyInputModal: () => import('@/pages/questionnaires/questionnaire-detail/respondents/QuestionnairesProxyInputModal.vue'),
  }
})
export default class RespondentsSubPage extends Vue {
  async created(): Promise<void> {
    await questionnairesModule.fetchQuestionnairesRespondents(new QuestionnairesRespondentsGetRequest(this.questionnaireId))
  }

  @Prop({ required: true, default: '' })
  private readonly questionnaireId!: string

  selectedUserIds: string[] = []

  headers: Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '112px' }),
    new Header({ text: '氏名', value: 'ownerUserName', sortable: false, width: '102px' }),
    new Header({ text: '票数', value: 'answerCount', sortable: false }),
    new Header({ text: '回答', value: 'answered', sortable: false, width: '60px' }),
  ]

  private get questionnaireRespondents(): QuestionnairesRespondentsGetResponse {
    return questionnairesModule.questionnaireRespondents(this.questionnaireId) ?? new QuestionnairesRespondentsGetResponse()
  }

  private get respondents(): RespondentTableItemDto[] {
    const respondents = this.questionnaireRespondents.respondents
    if (!respondents.length) return []
    return respondents.map(r => {
      const dto = new RespondentTableItemDto()
      dto.ownerUserId = r.ownerUserId
      dto.ownerUserName = r.ownerUserName
      dto.roomNumber = r.roomNumber
      dto.answerCount = r.answerCount
      dto.isAnswered = r.isAnswered
      dto.answered = r.isAnswered ? '済' : '未'
      dto.checkboxDisabled = r.isAnswered
      return dto
    })
  }

  private get usersAnswerCounts(): Map<string, number> {
    const userIdAndAnswerCounts: Map<string, number> = new Map()
    this.respondents.forEach(r => {
      userIdAndAnswerCounts.set(r.ownerUserId, r.answerCount)
    })
    return userIdAndAnswerCounts
  }

  private get isNotSelected(): boolean {
    return (!this.selectedUserIds.length || questionnairesModule.questionnaireDetail(this.questionnaireId)?.questionnaireState === QUESTIONNAIRE_STATES.RELEASED)
  }

  private onClickRoomNumber(ownerUserId: string): void {
    this.$router.push({ name: staticRoutes.questionnaireRespondentDetail.name, params: { questionnaireId: this.questionnaireId, userId: ownerUserId } })
  }

  isProxyInputModalVisible = false
  proxyInputModalKey = generateUuid()
  async onClickSubstitute(): Promise<void> {
    await this.fetchQuestionnaireDetail()
    this.proxyInputModalKey = generateUuid()
    this.isProxyInputModalVisible = true
  }

  async fetchQuestionnaireDetail(): Promise<void> {
    await questionnairesModule.fetchQuestionnaireDetail(new QuestionnaireDetailGetRequest(this.questionnaireId))
  }
}
