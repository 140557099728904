import { APIClientBase, HTTP_METHOD } from './api-client-base'
import { RESOLUTION_TYPES } from '@/constants/schema-constants'
import { GMResolutionDetailGetRequest, GMResolutionDetailGetResponse } from '@/dtos/resolutions/gm-resolution/get-detail'
import { GMResolutionsPostRequest } from '@/dtos/resolutions/gm-resolution/post'
import { GMResolutionsDraftPostRequest } from '@/dtos/resolutions/gm-resolution/draft/post'
import { GMResolutionsPutRequest } from '@/dtos/resolutions/gm-resolution/put'
import { GMResolutionsPutCloseRequest } from '@/dtos/resolutions/gm-resolution/close/put'
import { GMResolutionsDeleteRequest } from '@/dtos/resolutions/gm-resolution/delete'
import { GMResolutionsDraftDeleteRequest } from '@/dtos/resolutions/gm-resolution/draft/delete'
import { StatementsGetResponse } from '@/dtos/resolutions/gm-resolution/statements/get'
import { StatementsPutRequest } from '@/dtos/resolutions/gm-resolution/statements/put'
import { StatementsCsvGetRequest } from '@/dtos/resolutions/gm-resolution/statements/get-csv'
import { ResultsGetResponse } from '@/dtos/resolutions/gm-resolution/results/get'
import { ResultsPostRequest } from '@/dtos/resolutions/gm-resolution/results/post'
import { ResultsPutRequest } from '@/dtos/resolutions/gm-resolution/results/put'

class GMResolutionsClient extends APIClientBase {
  constructor() {
    super(`/resolutions/${RESOLUTION_TYPES.GENERAL_MEETING}`)
  }

  getResolutionDetail(req: GMResolutionDetailGetRequest): Promise<GMResolutionDetailGetResponse> {
    return this.get(`${req.resolutionId}`, '', GMResolutionDetailGetResponse)
  }

  async postResolutions(req: GMResolutionsPostRequest): Promise<void> {
    await this.post('', req)
  }

  async postDraftResolutions(req: GMResolutionsDraftPostRequest): Promise<void> {
    await this.post('/draft', req)
  }

  async putResolutions(req: GMResolutionsPutRequest): Promise<void> {
    await this.put(`${req.resolutionId}`, req)
  }

  // 意見表明締切
  async putResolutionsClose(req: GMResolutionsPutCloseRequest): Promise<void> {
    await this.put(`${req.resolutionId}/close`, req)
  }

  async deleteResolutions(req: GMResolutionsDeleteRequest): Promise<void> {
    await this.delete(`/${req.resolutionId}`)
  }

  async deleteDraftResolutions(req: GMResolutionsDraftDeleteRequest): Promise<void> {
    await this.delete(`/draft/${req.resolutionId}`)
  }

  // 決議内訳 取得
  getStatements(resolutionId: string) {
    return this.get(`${resolutionId}/statements`, null, StatementsGetResponse)
  }

  // 決議内訳 更新
  async putStatements(req: StatementsPutRequest): Promise<void> {
    await this.put(`${req.resolutionId}/statements`, req)
  }

  // 決議内訳CSVファイル取得
  async downloadStatementCsv(req: StatementsCsvGetRequest): Promise<void> {
    await this.download(`${req.resolutionId}/statements/csv`, null, HTTP_METHOD.GET, true)
  }

  // 決議結果取得
  getResults(resolutionId: string) {
    return this.get(`${resolutionId}/results`, null, ResultsGetResponse)
  }

  // 決議結果 登録
  postResults(req: ResultsPostRequest) {
    return this.post(`${req.resolutionId}/results`, req)
  }

  // 決議結果 更新
  putResults(req: ResultsPutRequest) {
    return this.put(`${req.resolutionId}/results`, req)
  }
}

export const gmResolutionsClient = new GMResolutionsClient()
