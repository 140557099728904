






























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
  }
})

export default class SmOwCheckBoxInverse extends Vue {
  @Prop({ required: true, default: '' })
  private readonly label!: string

  @Prop({ required: true, default: true })
  private readonly value!: boolean

  private get check():boolean { return this.value }

  private set check(newValue : boolean) {
    this.$emit('input', newValue)
  }
}
