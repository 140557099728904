

































import { Vue, Component, Prop } from 'vue-property-decorator'
import { buildingsModule } from '@/stores/buildings-store'
import { Column, Row, Cell } from '@/components/molecules/SmTableSimpleRepairPlanDetail.vue'
import { SimpleRepairsPlansDetailGetResponse } from '@/dtos/simple-repairs/plans/get'

/**
 * periodごとの列のid（period1 , period2・・・）。ヘッダ行とデータ行とで各カラムのidを一致させる。
 */
function getPeriodId(period: number): string {
  return 'period' + period
}

class Period {
  id!:string
  period!:number
  yearMonth!:string
}

class Page {
  minPeriod!:number
  maxPeriod!:number
  columns:Column[] = []
}

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmPrintHeader: () => import('@/components/organisms/SmPrintHeader.vue'),

    SmTableSimpleRepairPlanDetailPrint: () => import('@/components/molecules/SmTableSimpleRepairPlanDetailPrint.vue'),
  }
})
export default class SimpleRepairPlanConstructionSubPrintPage extends Vue {
  @Prop()
  private readonly detail?: SimpleRepairsPlansDetailGetResponse

  private get processedAt(): string {
    return this.detail?.processedAt ?? ''
  }

  private get periods():Period[] {
    return this.detail?.constructionPlan?.totalRows?.periodTotals.map(total => {
      return {
        id: getPeriodId(total.period),
        period: total.period,
        yearMonth: buildingsModule.periodToYearMonth(total.period)
      }
    }) ?? []
  }

  // ページ
  private get page(): Page {
    // ラベル列 -> 期の一覧 -> 総計列 という構造の表を作る。
    if (!this.periods.length) return { minPeriod: 0, maxPeriod: 0, columns: [] }
    return {
      minPeriod: this.periods[0].period,
      maxPeriod: this.periods[0].period + this.periods.length - 1,
      columns: [{ id: 'rowLabel', isCategoryColumn: true }, ...this.periods, { id: 'itemTotal', isTotalColumn: true }]
    }
  }

  // データ行（全体）
  private get rows(): Row[][] {
    // データ行全て取得
    const allrows = [...this.detailRows, ...this.totalRows]

    // 1ページ分の配列に分割
    const splitCount = 40
    let pageRows:Row[] = []
    const pages:Row[][] = []

    allrows.forEach(value => {
      // 以下の場合はページ送り
      // splitCount行目
      // (splitCount)行目かつ青行の場合（ヘッダーだけ前ページに出るのを防ぐ）
      if (pageRows.length === splitCount || (pageRows.length === splitCount - 1 && value.isCategoryRow)) {
        pages.push([...pageRows])
        pageRows = []
      }
      pageRows.push(value)
    })
    // 最終ページを追加
    if (pageRows.length > 0) {
      pages.push([...pageRows])
    }
    return pages
  }

  // データ行（明細）
  private get detailRows(): Row[] {
    // カテゴリの配列がそれぞれ修繕項目の配列を持っているものを、フラットな1つの配列に変換
    return this.detail?.constructionPlan?.detailRows?.categories.flatMap(category => {
      const periods = category.items.map(item => {
        const cells = item.periods.reduce((acc: Record<string, Cell>, current) => {
          acc[getPeriodId(current.period)] = { prospect: current.prospect ?? undefined }
          return acc
        }, {})

        return { rowLabel: item.itemLabel, cells: cells, itemTotal: { total: item.total } }
      })

      return [
        { rowLabel: category.categoryLabel, isCategoryRow: true },
        ...periods,
      ]
    }) ?? []
  }

  // データ行（合計値）
  private get totalRows(): Row[] {
    if (!this.detail || !this.detail.constructionPlan?.totalRows?.rowTotal) return []
    const taxExcludedCells : Record<string, Cell> = {}
    const taxCells : Record<string, Cell> = {}
    const taxIncludedCells : Record<string, Cell> = {}
    const cumulativeCells : Record<string, Cell> = {}
    const performanceTaxIncludedCells : Record<string, Cell> = {}
    const performanceCumulativeCells : Record<string, Cell> = {}

    this.detail.constructionPlan?.totalRows.periodTotals.forEach(periodTotal => {
      const periodId = getPeriodId(periodTotal.period)
      taxExcludedCells[periodId] = { total: periodTotal.taxExcluded }
      taxCells[periodId] = { total: periodTotal.tax }
      taxIncludedCells[periodId] = { total: periodTotal.taxIncluded }
      cumulativeCells[periodId] = { total: periodTotal.cumulative }
      performanceTaxIncludedCells[periodId] = { total: periodTotal.performanceTaxIncluded }
      performanceCumulativeCells[periodId] = { total: periodTotal.performanceCumulative }
    })

    const rowTotal = this.detail.constructionPlan?.totalRows.rowTotal
    return [
      { rowLabel: '単年度修繕費', cells: taxExcludedCells, itemTotal: { total: rowTotal.taxExcluded }, isBlueTotalRow: true },
      { rowLabel: '単年度消費税', cells: taxCells, itemTotal: { total: rowTotal.tax }, isBlueTotalRow: true },
      { rowLabel: '単年度合計', cells: taxIncludedCells, itemTotal: { total: rowTotal.taxIncluded }, isGrayTotalRow: true },
      { rowLabel: '修繕費累計', cells: cumulativeCells, itemTotal: { total: rowTotal.cumulative }, isGrayTotalRow: true },
      { rowLabel: '単年度合計（実績）', cells: performanceTaxIncludedCells, itemTotal: { total: rowTotal?.performanceTaxIncluded }, isRedTotalRow: true },
      { rowLabel: '修繕費累計（実績）', cells: performanceCumulativeCells, itemTotal: { total: rowTotal?.performanceCumulative }, isRedTotalRow: true },
    ]
  }

  private get myCurrentBuilding():string | undefined {
    return buildingsModule.buildingDetailGet?.buildingName
  }
}
