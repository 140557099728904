import { Material } from '@/dtos/commons'

export class OwnerUser {
  userId!: string

  constructor(userId: string) {
    this.userId = userId
  }
}

export class OwnerIdeasPostRequest {
  title!: string
  background!: string
  ownerUser!: OwnerUser
  ideaId?: string
  material?: Material

  constructor(title: string, background: string, ownerUser: OwnerUser, ideaId?: string, material?: Material | null) {
    this.title = title
    this.background = background
    this.ownerUser = ownerUser
    this.ideaId = ideaId
    this.material = material ?? undefined
  }
}
