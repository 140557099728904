




































import { Vue, Component } from 'vue-property-decorator'
import { buildingsModule } from '@/stores/buildings-store'
import { repairsModule } from '@/stores/repairs-store'
import { RepairsExpensesGetRequest, RepairsExpensesGetResponse } from '@/dtos/repairs/expenses/get'

const LIMIT_PER_PAGE = 30

@Component({
  components: {
    SmTextPrintSizeAnnounce: () => import('@/components/atoms/SmTextPrintSizeAnnounce.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmPrintHeader: () => import('@/components/organisms/SmPrintHeader.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    RepairPlanSummarySubPage: () => import('@/pages/repair-plan/repair-plan-detail/repair-plan-summary/RepairPlanSummarySubPrintPage.vue'),

  }
})
export default class RepairPlanPage extends Vue {
  async beforeCreate():Promise<void> {
    await repairsModule.fetchRepairsExpenses(new RepairsExpensesGetRequest())
  }

  private get repairsExpensesGet():RepairsExpensesGetResponse {
    return repairsModule.repairsExpensesGet
  }

  private get summarySplits(): {min:number, max:number}[] {
    if (!this.repairsExpensesGet.repairPlan) return []
    const length = this.repairsExpensesGet.repairPlan.originals.length
    const startPeriod = this.repairsExpensesGet.repairPlan.originals[0].period
    const endPeriod = this.repairsExpensesGet.repairPlan.originals.slice(-1)[0].period
    // LIMIT_PER_PAGEを超過した場合は2分割する
    if (length > LIMIT_PER_PAGE) {
      const summarySplits:{min:number, max:number}[] = []
      // 前半の長さを設定
      const slicePoint = Math.floor(length / 2)
      summarySplits.push({ min: startPeriod, max: startPeriod + slicePoint - 1 })

      summarySplits.push({ min: startPeriod + slicePoint, max: endPeriod })
      return summarySplits
    } else return [{ min: startPeriod, max: endPeriod }]
  }

  private get myCurrentBuilding():string | undefined {
    return buildingsModule.buildingDetailGet?.buildingName
  }
}
