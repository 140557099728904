























































import { Vue, Component, Prop } from 'vue-property-decorator'
import type { Color, Level, Format } from '@/components/atoms/SmText.vue'
import { NOTIFICATION_ELEMENT_TYPES } from '@/constants/schema-constants'
import type { NotificationElementType, HierarchyTypes } from '@/constants/schema-constants'
import { BulletPoint } from '@/dtos/owner-notifications/get-detail'
import { getHierarchyTypeDot, getHierarchyTypeIndex } from '@/libs/type-handler'
import { generateUuid } from '@/libs/uuid-generator'

@Component({
  components: {
    SmTextConvertedToBoldAndStrikethrough: () => import('@/components/atoms/SmTextConvertedToBoldAndStrikethrough.vue'),
    SmTextConvertedToList: () => import('@/components/atoms/SmTextConvertedToList.vue'),
  }
})
export default class SmTextConvertedToList extends Vue {
  NOTIFICATION_ELEMENT_TYPES = Object.freeze(NOTIFICATION_ELEMENT_TYPES)

  @Prop()
  private format?: Format

  @Prop()
  private color?: Color

  @Prop()
  private level?: Level

  @Prop()
  private notificationElementType?: NotificationElementType

  @Prop()
  private bulletPoints!: BulletPoint[]

  @Prop({ default: false })
  private sameOwner!: boolean

  // この階層で表示するリストの記号を取得する
  private get getClassByHierarchyType(): string {
    const hierarchyLevel = this.bulletPoints[0].hierarchyLevel
    const hierarchyType = hierarchyLevel % 3 as HierarchyTypes
    if (this.notificationElementType === NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.DOT) {
      return this.sameOwner ? 'sm__hierarchy-dot-' + getHierarchyTypeDot(hierarchyType) : 'sm__hierarchy-dot-disc'
    } else {
      return 'sm__hierarchy-index-' + getHierarchyTypeIndex(hierarchyType)
    }
  }

  // この階層で表示する部分と再帰して次の階層で表示する部分を切り分ける
  private get bulletPointsForText(): { bulletPointId:string, type: 'li' | 'bulletPoints', text?: string, nextBulletPoints?: BulletPoint[] }[] {
    // 要素が1つもなければ空配列を返却して終了
    if (this.bulletPoints.length === 0) return []

    const hierarchyLevel = this.bulletPoints[0].hierarchyLevel
    const result: { bulletPointId:string, type: 'li' | 'bulletPoints', text?: string, nextBulletPoints?: BulletPoint[] }[] = []
    let differentBulletPoints: BulletPoint[] = []
    let differentHierarchyFlg = false
    for (const bulletPoint of this.bulletPoints) {
      if (!differentHierarchyFlg && hierarchyLevel === bulletPoint.hierarchyLevel) {
        result.push({ bulletPointId: bulletPoint.bulletPointId, type: 'li', text: bulletPoint.bulletPointBody })
        continue
      } else {
        if (hierarchyLevel === bulletPoint.hierarchyLevel) {
          if (differentHierarchyFlg) {
            result.push({ bulletPointId: bulletPoint.bulletPointId, type: 'bulletPoints', nextBulletPoints: differentBulletPoints })
            differentBulletPoints = []
            differentHierarchyFlg = false
          }
          result.push({ bulletPointId: bulletPoint.bulletPointId, type: 'li', text: bulletPoint.bulletPointBody })
          continue
        } else {
          differentBulletPoints.push(bulletPoint)
          differentHierarchyFlg = true
        }
      }
    }
    // 次の階層で表示する項目で終わっている場合に結果に詰める
    if (differentHierarchyFlg) result.push({ bulletPointId: generateUuid(), type: 'bulletPoints', nextBulletPoints: differentBulletPoints })
    return result
  }
}
