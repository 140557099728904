import * as ExcelJs from 'exceljs'
import { ExcelParserBase } from './excel-parser-base'
import { Category, ConstructionPlan, Expense, FinancialPlan } from '@/dtos/simple-repairs/plans/post'

// 0始まり
const DATA_START_COL_IDX = 1 // 1列目が空欄のため2列目からデータが設定される
const PERIOD_START_IDX = 3 // 開始期は4列目からデータが設定される
const PERIOD_END_IDX = 32 // 計画期の最大は33列目に設定される
const SUM_IDX = 33 // 合計列

class SimpleRepairPlanExcelParser extends ExcelParserBase {
  /**
   * 長期修繕計画ファイルをパースします
   * @param file 長期修繕計画ファイル
   * @returns APIリクエスト用の工事計画クラス
   */
  async constructionPlanParse(file: File): Promise<ConstructionPlan | undefined> {
    // 長期修繕計画の中分類表は左から2番目のシートのためsheetIndexに1を設定する
    const dataRows = await this.fromRepairPlanExcel(file, 1)
    if (!dataRows) return undefined

    // バリデーションチェック
    if (!await this.fromExcel(file, 1, this._constructionPlanParseValidator)) return undefined

    // 期を取得
    const searchWord = /期$/
    let rowIndex = 0
    for (const dataRow of dataRows) {
      const convertedValue = String(dataRow[PERIOD_START_IDX])
      if (searchWord.test(convertedValue)) break
      rowIndex++
    }
    const periods = dataRows[rowIndex].slice(PERIOD_START_IDX, PERIOD_END_IDX + 1).map(v => String(v)).map(s => s.replace('期', '')).map(Number)
    if (!periods) return undefined

    // 期毎の合計金額を取得（費用項目の合計列まで取得）
    // 桁数区切りのカンマを削除、未設定費用欄を0に変換
    const taxExcluded = dataRows[this._getRowIndex(dataRows, '単年度修繕費')].slice(PERIOD_START_IDX, SUM_IDX + 1).map(v => String(v)).map(s => s.replace(/,/g, '')).map(e => e === 'null' ? '0' : e).map(Number)
    const tax = dataRows[this._getRowIndex(dataRows, '単年度消費税')].slice(PERIOD_START_IDX, SUM_IDX + 1).map(v => String(v)).map(s => s.replace(/,/g, '')).map(e => e === 'null' ? '0' : e).map(Number)
    const taxIncluded = dataRows[this._getRowIndex(dataRows, '単年度合計')].slice(PERIOD_START_IDX, SUM_IDX + 1).map(v => String(v)).map(s => s.replace(/,/g, '')).map(e => e === 'null' ? '0' : e).map(Number)
    const cumulative = dataRows[this._getRowIndex(dataRows, '修繕費累計')].slice(PERIOD_START_IDX, SUM_IDX + 1).map(v => String(v)).map(s => s.replace(/,/g, '')).map(e => e === 'null' ? '0' : e).map(Number)

    // 単年度修繕費、単年度消費税、単年度合計、修繕費累計が数値でなければエラー
    if (![taxExcluded, tax, taxIncluded, cumulative].flat().every((value) => Number.isInteger(value))) return undefined

    const expense = new Expense(taxExcluded, tax, taxIncluded, cumulative)

    // 修繕項目を取得
    const categories: Category[] = []
    const previousCategoryStart = this._getRowIndex(dataRows, '西暦')
    // 取得する修繕項目の範囲を確定するため期毎の合計金額の先頭項目の行数を取得
    const categoryEnd = this._getRowIndex(dataRows, '単年度修繕費')
    for (let i = previousCategoryStart + 1; i < categoryEnd; i++) {
      const dataRow = dataRows[i]
      // 全て空欄の行は取得対象外のため処理を飛ばす
      if (this._isAllNull(dataRow)) continue
      // 修繕項目のカテゴリと修繕項目名を数字とピリオドを含むかどうかで判定する
      const checkCategoryLabel = /\d./.test(String(dataRow[DATA_START_COL_IDX]))
      if (checkCategoryLabel) {
        // 修繕項目のカテゴリ行の次行が修繕項目名と費用を含む行のためそこから設定データを取得する
        const nextDataRow = dataRows[i + 1]

        // 実績が空欄か数値でなければエラー
        if (!nextDataRow.slice(PERIOD_START_IDX, SUM_IDX + 1).every(value => Number.isInteger(value) || value === null)) return undefined

        categories.push(new Category(
          String(dataRow[DATA_START_COL_IDX]),
          String(nextDataRow[DATA_START_COL_IDX]),
          nextDataRow.slice(PERIOD_START_IDX, SUM_IDX + 1).map(v => v === null ? undefined : v) as number[] ?? [],
          // prospectsが空だった場合totalを取得できないため0を設定する
          isNaN(nextDataRow.slice(-1).map(v => v === null ? 0 : v).map(Number)[0]) ? 0 : nextDataRow.slice(-1).map(v => v === null ? 0 : v).map(Number)[0]
        ))
      } else {
        const itemLabel = String(dataRow[DATA_START_COL_IDX])
        // 既に同じ修繕項目名を設定済みの場合重複するため処理を飛ばす
        if (categories.map(e => e.itemLabel).includes(itemLabel)) continue

        // 実績が空欄か数値でなければエラー
        if (!dataRow.slice(PERIOD_START_IDX, SUM_IDX + 1).every(value => Number.isInteger(value) || value === null)) return undefined

        // 重複しない修繕項目名の場合ひとつ前に設定される修繕項目のカテゴリと同じになる
        if (!categories.length) return undefined// 修繕項目の先頭行が存在しないときにエラー
        categories.push(new Category(
          categories.slice(-1)[0].categoryLabel,
          itemLabel,
          dataRow.slice(PERIOD_START_IDX, SUM_IDX + 1).map(v => v === null ? undefined : v) as number[] ?? [],
          // prospectsが空だった場合totalを取得できないため0を設定する
          isNaN(dataRow.slice(-1).map(v => v === null ? 0 : v).map(Number)[0]) ? 0 : dataRow.slice(-1).map(v => v === null ? 0 : v).map(Number)[0]
        ))
      }
    }

    return new ConstructionPlan(periods, categories, expense)
  }

  /**
   * 資金計画表ファイルをパースします
   * @param file 資金計画表ファイル
   * @returns APIリクエスト用の資金計画クラス
   */
  async financialPlanParse(file: File): Promise<FinancialPlan | undefined> {
    // 資金計画表の取り込み対象シートは一番左のためsheetIndexに0を設定
    const dataRows = await this.fromFinancialPlanExcel(file, 0)
    if (!dataRows) return undefined

    // バリデーションチェック
    if (!await this.fromExcel(file, 0, this._financialPlanParseValidator)) return undefined

    // 収入単年度合計を取得
    // 桁数区切りのカンマを削除し数値に変換
    const annualRevenues = dataRows[this._getRowIndex(dataRows, '収入単年度合計')].slice(PERIOD_START_IDX, SUM_IDX + 1).map(v => String(v)).map(s => s.replace(/,/g, '')).map(e => e === 'null' ? '0' : e).map(Number)

    // 収入単年度合計が数値でなければエラー
    if (!annualRevenues.every((value) => Number.isInteger(value))) return undefined

    // 積立金残高は画面入力項目のためパース処理後page側で入力値を代入
    const balance = 0

    return new FinancialPlan(annualRevenues, balance)
  }

  /**
   * 取得した行のデータが全てnullかどうかをチェックします
   * @param dataRow チェック対象の行データ
   * @returns 全てnullならtrue、ひとつでも値があればfalse
   */
  private _isAllNull(dataRow: unknown[]): boolean {
    return dataRow.every(e => e === null)
  }

  /**
   * 取得した行から任意の項目が含まれる行数を取得します
   * @param dataRows ファイルをパースした結果データ
   * @param searchItem 項目名
   * @returns 行数
   */
  private _getRowIndex(dataRows: unknown[][], searchItem: string): number {
    return dataRows.findIndex(row => row.includes(searchItem))
  }

  private _constructionPlanParseValidator(parsed: ExcelJs.CellValue[][]): boolean {
    const CONSTRUCTION_PLAN_STATIC_HEADERS = ['修 繕 項 目', '築年数']

    const termIndex = ((parsed.findIndex(row => row.includes('築年数'))) !== -1) ? (parsed.findIndex(row => row.includes('築年数'))) : undefined
    if (!termIndex) return false

    const categoryIndex = ((parsed.findIndex(row => row.includes('西暦'))) !== -1) ? (parsed.findIndex(row => row.includes('西暦'))) : undefined
    if (!categoryIndex) return false

    // 「単年度修繕費」の行数を取得
    const annualRepairCostIdx = ((parsed.findIndex(row => row.includes('単年度修繕費'))) !== -1) ? (parsed.findIndex(row => row.includes('単年度修繕費'))) : undefined
    if (!annualRepairCostIdx) return false

    const categories = parsed.slice(categoryIndex + 1, annualRepairCostIdx - 1)
    for (const category of categories) {
      // 金額が存在するが、修繕項目のラベルないときにバリデーションエラー
      if (category.some(value => Number.isInteger(value)) && !category[DATA_START_COL_IDX]) return false
    }

    if (termIndex === -1) return false
    // 表題の見出しの並びが「修繕項目」「築年数」の順になっているかをチェック
    return CONSTRUCTION_PLAN_STATIC_HEADERS.every((h, idx) => h === parsed[termIndex][idx + 1]) &&
    // 期が抜け無く順序通り入力されているかをチェック
    parsed[termIndex].slice(PERIOD_START_IDX, SUM_IDX)
      .every((num, idx, arr) => idx === 0 || Number((num as string)?.replace('期', '')) === Number((arr[idx - 1] as string)?.replace('期', '')) + 1) &&
    // 30期分の次の列に合計列が存在するかをチェック
    parsed[termIndex][SUM_IDX] === '合 計' &&
    // 「単年度消費税」「単年度合計」「修繕費累計」が存在するかをチェック
    (parsed.findIndex(row => row.includes('単年度消費税'))) !== -1 &&
    (parsed.findIndex(row => row.includes('単年度合計'))) !== -1 &&
    (parsed.findIndex(row => row.includes('修繕費累計'))) !== -1
  }

  private _financialPlanParseValidator(parsed: ExcelJs.CellValue[][]): boolean {
    const FINANCIAL_PLAN_STATIC_HEADERS = ['項目', '築年数']
    const termIndex = parsed.findIndex(row => row.includes('項目'))
    if (termIndex === -1) return false
    // 表題の見出しの並びが「項目」「築年数」の順になっているかをチェック
    return FINANCIAL_PLAN_STATIC_HEADERS.every((h, idx) => h === parsed[termIndex][idx + 1]) &&
    // 期が抜け無く順序通り入力されているかをチェック
    parsed[termIndex].slice(PERIOD_START_IDX, SUM_IDX)
      .every((num, idx, arr) => idx === 0 || Number((num as string)?.replace('期', '')) === Number((arr[idx - 1] as string)?.replace('期', '')) + 1) &&
    // 「収入単年度合計」が存在するかをチェック
    (parsed.findIndex(row => row.includes('収入単年度合計'))) !== -1 &&
    parsed.filter(row => row.includes('収入単年度合計')).length === parsed.filter(row => row.includes('収入')).length &&
    parsed.findIndex(row => row.includes('収入単年度合計')) > parsed.findIndex(row => row.includes('項目'))
  }
}

export const simpleRepairPlanExcelParser = new SimpleRepairPlanExcelParser()
