








































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { errorsModule } from '@/stores/errors'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})
export default class SmTextarea extends Vue {
  private get fieldErrors() {
    return errorsModule.fieldErrors(this.fieldId)?.join(' ')
  }

  @Prop()
  private readonly label?: string

  @Prop({ default: '' })
  private readonly placeholder!: string

  @Prop({ default: '' })
  private readonly value!: string

  @Prop({ default: '' })
  private readonly fieldId!: string

  @Prop({ default: false })
  private readonly required!: boolean

  @Prop({ default: 1000 })
  private readonly counter!: number

  @Prop({ default: false })
  private readonly showCounter!: boolean

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly immediate!: boolean

  @Prop({ default: () => [] })
  private readonly validationRules!: string[]

  @Prop()
  private readonly height?: string

  @Prop({ default: true })
  private readonly autoGrow!: boolean

  @Prop({ default: '[表示されるテキスト](URL)と入力することで、文中にリンクを作成できます。' })
  private readonly captionText?: string

  @Prop({ default: 'この入力欄' })
  private readonly validationText?: string

  private get rules(): string {
    const rules = [...this.validationRules]
    if (this.required) rules.push('required')
    if (this.counter) rules.push(`max:${this.counter}`)
    return rules.join('|')
  }

  private get inputText(): string { return this.value }
  private set inputText(newValue: string) {
    if (errorsModule.fieldErrors(this.fieldId)) errorsModule.clearSingleFieldError(this.fieldId)
    this.$emit('input', newValue)
  }
}
