import { Type } from 'class-transformer'

export class CasysDetectionsGetRequest {
  keyword?: string
  skip: number
  take: number

  constructor(skip: number, take: number, keyword?: string) {
    this.skip = skip
    this.take = take
    this.keyword = keyword
  }
}

export class Detection {
  casysDifferenceDetectionBatchStatusId!: string
  uploadedAt!: string
  userName!: string
  fileCount!: number
  diferenceCount!: number
}

export class CasysDetectionsGetResponse {
  detectionCount!: number
  @Type(() => Detection) detections: Detection[] = []
}
