import { Type } from 'class-transformer'

export class ReservesPersonalPlanProspectsGetRequest {
  buildingUnitId: string
  appliedAt: string
  constructor(buildingUnitId: string, appliedAt: string) {
    this.buildingUnitId = buildingUnitId
    this.appliedAt = appliedAt
  }
}

export class PlanProspectsApplyingSummary {
  appliedAt!: string
  recordedReservedAmount!: number
  minReservedAmount!: number
  remainingMonth!: number
}

export class PlanProspectsUnitPrice {
  period!: number
  unitPrice!: number
}

export class PlanProspectsPaymentPlan {
  paymentPlanId!: string
  planCode!: string
  planName!: string
  planReservedAmount!: number
  @Type(() => PlanProspectsUnitPrice) paymentPlanDetail: PlanProspectsUnitPrice[] = []
}

export class ReservesPersonalPlanProspectsGetResponse {
  roomNumber!: string
  occupiedArea!: number
  @Type(() => PlanProspectsApplyingSummary) applyingSummary = new PlanProspectsApplyingSummary()
  @Type(() => PlanProspectsUnitPrice) reservePlan: PlanProspectsUnitPrice[] = []
  @Type(() => PlanProspectsPaymentPlan) paymentPlans: PlanProspectsPaymentPlan[] = []
}
