

































import { SimpleRepairsPlansDetailGetResponse } from '@/dtos/simple-repairs/plans/get'
import { staticRoutes } from '@/routes'
import { simpleRepairsModule } from '@/stores/simple-repairs-store'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmChartSimpleRepairPlan: () => import('@/components/molecules/SmChartSimpleRepairPlan.vue'),
    SmTableSimpleRepairPlan: () => import('@/components/molecules/SmTableSimpleRepairPlan.vue'),

    SmOwBtnText: () => import('@/components/owner-apps/SmOwBtnText.vue'),
  }
})
export default class SimpleRepairPlanSummarySubPage extends Vue {
  private get simpleRepairsDetail(): SimpleRepairsPlansDetailGetResponse { return simpleRepairsModule.simpleRepairsDetailGet }

  outputPDF(): void {
    this.$router.push({ name: staticRoutes.simpleRepairPlanDetailSummaryPrint.name })
  }
}
