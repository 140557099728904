

















































import { Vue, Component, Model, Prop } from 'vue-property-decorator'

export class RadioOption {
  label!: string
  value!: string | number | boolean
  disabled?: boolean
  color?: string

  constructor(label: string, value: string | number | boolean, disabled?: boolean, color? :string) {
    this.label = label
    this.value = value
    this.disabled = disabled
    this.color = color
  }
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmRadio extends Vue {
  // 横一行に並べたい場合に「row」を指定する。
  // ※column,rowの同時指定をするとv-radioと同じ挙動をしないので、そもそも同時指定させない

  // labelスロットにカスタムコンポーネントのみを設定すると描画されないため、空文字の力を借りる
  private emptyDummyString = ''

  @Prop({ default: false })
  private readonly row!: boolean

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly required!: boolean

  @Prop()
  private readonly title?: string

  @Prop({ default: '' })
  private readonly fieldId!: string

  @Model('change')
  private readonly radioValue!: string | number

  @Prop({ required: true, default: () => [] })
  private readonly options!:RadioOption[]

  @Prop({ default: '' })
  private readonly caption?: string

  private get _radioValue():string | number | boolean { return this.radioValue }
  private set _radioValue(newValue : string | number | boolean) { this.$emit('change', newValue) }

  private get rules(): string {
    const rules = []
    if (this.required) rules.push('required')
    return rules.join('|')
  }
}
