
























































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TICKET_TASK_STATES, TICKET_TASK_NAME_TYPES, TICKET_TASK_TYPES, RESOLUTION_STATES } from '@/constants/schema-constants'
import type { TicketTaskState, TicketTaskNameType } from '@/constants/schema-constants'
import { DAY_OF_WEEK } from '@/constants/ux-constants'
import { canEditTicketTaskName, getTicketTaskNameTypeLabel, isTaskAutoComplete } from '@/libs/type-handler'
import { ListResponseTaskDto } from '@/dtos/tasks/search/post'
import { getTicketTaskStateLabel } from '@/libs/state-handler'

export type UploadTask = { task:ListResponseTaskDto, isAutoMadeRawTask:boolean }

const SELECTION_STATUS_ITEMS = [
  { value: TICKET_TASK_STATES.NOT_STARTED_YET, label: getTicketTaskStateLabel(TICKET_TASK_STATES.NOT_STARTED_YET) },
  { value: TICKET_TASK_STATES.NOT_COMPLETED, label: getTicketTaskStateLabel(TICKET_TASK_STATES.NOT_COMPLETED) }
]

// 決議新規作成のメニューの内容
const RESOLUTION_ITEMS: {[id: string]: { text: string, label: string }} = {
  ONLINE: { text: 'オンライン決議を新規作成する', label: '決議を新規作成する' },
  GENERAL_MEETING: { text: '総会決議を新規作成する', label: '決議を新規作成する' },
}

const RESOLUTION_ITEMS_GENERAL_MEETING: {[id: string]: { text: string, label: string }} = {
  GENERAL_MEETING: { text: '総会決議を新規作成する', label: '決議を新規作成する' },
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class SmCardTaskStates extends Vue {
  @Prop({ default: false })
  private readonly isFocus!: boolean

  @Prop({ required: true, default: {} })
  private readonly task!: ListResponseTaskDto

  @Prop({ required: true, default: false })
  isExpired!: boolean

  @Prop({ default: '' })
  private readonly fieldIdPrefix!: string

  private get isCreateFromDraft():boolean {
    return this.task.ticketTaskType === TICKET_TASK_TYPES.RESOLUTION_CREATE && (this.task.resolution?.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.DRAFT || this.task.resolution?.resolutionState === RESOLUTION_STATES.ONLINE.DRAFT)
  }

  selectedTaskItems :{ value: number, label: string }[] =
  [
    { value: this.task.ticketTaskType, label: getTicketTaskNameTypeLabel(this.task.ticketTaskType) },
    { value: TICKET_TASK_NAME_TYPES.FREE_WRITING, label: getTicketTaskNameTypeLabel(TICKET_TASK_NAME_TYPES.FREE_WRITING) + '（自由入力）' }
  ]

  TICKET_TASK_STATES = Object.freeze(TICKET_TASK_STATES)
  TICKET_TASK_TYPES = Object.freeze(TICKET_TASK_TYPES)

  editTask: ListResponseTaskDto | null = null

  private get editTaskNameDisplay(): string {
    if (canEditTicketTaskName(this.task.ticketTaskType)) {
      return getTicketTaskNameTypeLabel(this.task.ticketTaskType) + '（自由入力）'
    } else {
      return getTicketTaskNameTypeLabel(this.task.ticketTaskType)
    }
  }

  selectedStateItems = SELECTION_STATUS_ITEMS

  createResolution():void {
    switch (this.resolutionType) {
      case 'ONLINE':
        this.$emit('create-online-resolution')
        break
      case 'GENERAL_MEETING':
        this.$emit('create-general-meeting-resolution')
    }
  }

  // -------------- 画面描画用のデータ ---------------
  resolutionType = 'GENERAL_MEETING'
  resolutionItems = RESOLUTION_ITEMS

  created():void {
    if (!this.task.ticket.relationIdeaTicketCount || this.task.ticket.relationIdeaTicketCount <= 1) {
      this.resolutionItems = RESOLUTION_ITEMS
    } else {
      this.resolutionItems = RESOLUTION_ITEMS_GENERAL_MEETING
    }
  }

  private deadline(deadlineDate: string): string {
    const date = new Date(deadlineDate)
    const numberOfDate = date.getDay()
    const dayOfWeek = DAY_OF_WEEK[numberOfDate]
    const [year, month, day] = deadlineDate.split('-')
    return `${year}年${month}月${day}日(${dayOfWeek})`
  }

  private get isTaskAutoComplete():boolean {
    return isTaskAutoComplete(this.task.ticketTaskType)
  }

  onClickCancel(): void {
    this.$emit('cancel-update')
    this.editTask = null
  }

  onClickStateSelect(): void {
    if (this.editTask === null || this.selectedTicketTaskState === null) return
    this.editTask.ticketTaskState = this.selectedTicketTaskState
  }

  onClickTaskSelect(): void {
    if (this.editTask === null || this.selectedTicketTaskNameType === null) return
    this.editTask.ticketTaskNameType = this.selectedTicketTaskNameType
    if (this.selectedTicketTaskNameType === TICKET_TASK_NAME_TYPES.FREE_WRITING) this.editTask.ticketTaskName = ''
  }

  private get isFreeInput():boolean {
    return this.selectedTicketTaskNameType === TICKET_TASK_NAME_TYPES.FREE_WRITING && this.task.isEditMode
  }

  selectedTicketTaskState: TicketTaskState | null = null
  selectedTicketTaskNameType: TicketTaskNameType | null = null
  selectedDeadlineDate: string | null = null
  onClickEditTask():void {
    this.editTask = new ListResponseTaskDto()
    this.editTask.ticketTaskType = this.task.ticketTaskType
    this.editTask.ticketTaskState = this.task.ticketTaskState
    this.editTask.ticketTaskNameType = this.task.ticketTaskNameType
    this.editTask.ticketTaskName = this.task.ticketTaskName
    this.editTask.deadline = this.task.deadline
    this.editTask.deadlineDate = this.task.deadlineDate
    this.selectedTicketTaskState = this.task.ticketTaskState
    this.selectedTicketTaskNameType = this.task.ticketTaskNameType
    this.selectedDeadlineDate = this.task.deadlineDate
    this.$emit('click-update-btn')
  }

  onClickUpdate():void {
    if (this.editTask === null || this.selectedDeadlineDate === null) return
    this.editTask.deadlineDate = this.selectedDeadlineDate
    this.editTask.deadline = this.deadline(this.selectedDeadlineDate)
    this.$emit('update', this.editTask)
  }
}
