
















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})
export default class SmOwTextRequired extends Vue {
  @Prop({ required: true, default: '' })
  private readonly text!: string

  @Prop({ required: true, default: false })
  private readonly isRequired!: boolean

  @Prop({ default: 'sm-ow__h3' })
  private readonly format?: string
}
