

























































































import { Component, Prop, Vue } from 'vue-property-decorator'

import { CHIP_COLORS } from '@/constants/ux-constants'

import { errorsModule } from '@/stores/errors'
import { newTabLocalParamStorageModule, OwnerIdeaPreviewContent } from '@/stores/new-tab-local-param-storage-store'

const CONTENT_NOT_EXIST = 'プレビューを表示できませんでした。タブを閉じて再度プレビューボタンから画面を開きなおしてください。'

@Component({
  components: {
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmOwListUser: () => import('@/components/owner-apps/SmOwListUser.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class OwnerIdeaPreviewPage extends Vue {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)
  @Prop({ default: '' })
  private readonly id!: string

  created(): void {
    if (!this.ownerIdea) {
      errorsModule.setGlobalErrors([CONTENT_NOT_EXIST])
    }
  }

  private get ownerIdea(): OwnerIdeaPreviewContent | undefined { return newTabLocalParamStorageModule.ownerIdeaPreviewContent(this.id) ?? new OwnerIdeaPreviewContent() }

  private get caption(): string | undefined { return this.ownerIdea?.material?.caption }
}
