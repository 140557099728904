




























import { Vue, Component, Prop } from 'vue-property-decorator'
import { PAYMENT_CODE_ONE_TIME } from '@/constants/schema-constants'

import { Header } from '@/components/molecules/SmTableData.vue'
import { PaymentDetail } from '@/dtos/reserve-funds/get'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue')

  }
})
export default class PaymentPlansTable extends Vue {
  @Prop({ required: true, default: () => [] })
  paymentPlans!:PaymentDetail[]

  tableHeaders:Header[] = [
    new Header({ text: '作成日', value: 'plannedAt', width: '200px' }),
    new Header({ text: 'プランID', value: 'planCode', width: '110px' }),
    new Header({ text: 'プラン名', value: 'planName' }),
  ]

  PAYMENT_CODE_ONE_TIME = PAYMENT_CODE_ONE_TIME
}
