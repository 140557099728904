
export class CasysUnitsPutRequest {
  casysDifferenceOwnerId: string
  owningBuildingUnitId: string

  constructor(casysDifferenceOwnerId: string, owningBuildingUnitId: string) {
    this.casysDifferenceOwnerId = casysDifferenceOwnerId
    this.owningBuildingUnitId = owningBuildingUnitId
  }
}
