import { Material } from '../commons'

export class ConsultationsPutRequest {
  userId!: string
  consultationId!: string
  message: string
  material?: Material
  version!: number
  constructor(userId: string, consultationId: string, message: string, version: number, material?: Material | null) {
    this.userId = userId
    this.consultationId = consultationId
    this.message = message
    this.version = version
    this.material = material ?? undefined
  }
}

export class ConsultationsPutResponse {
  version!: number
}
