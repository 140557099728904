









































import { Vue, Component, Prop } from 'vue-property-decorator'
import { StateChanger } from 'vue-infinite-loading'

export class LoadingHandler {
  private readonly _changer: StateChanger

  constructor(changer: StateChanger) { this._changer = changer }

  /**
   * 読み込み処理が完了し、続きがある場合にコールする。
   * loadableイベントが発火してからこれをコールするまで、次のloadableイベントは発火されない。
   */
  loaded(): void { this._changer.loaded() }

  /**
   * 読み込み処理が完了し、終端に達した際にコールする。
   * コール後は、ローディング位置に達してもloadableイベントが発火されなくなる。
   */
  complete(): void { this._changer.complete() }
}

@Component({
  components: {
    InfiniteLoading: () => import('vue-infinite-loading'),

    SmProgressCircular: () => import('@/components/atoms/SmProgressCircular.vue'),
  }
})
export default class SmInfiniteLoading extends Vue {
  /**
   * identifierの値を更新することで1度発した終端宣言（complete）をリセットでき、
   * 再度ローディング位置に達した際にloadableイベントが発火されるようになる。
   */
  @Prop({ default: Date.now() })
  identifier!: number

  /** デフォルト：下方向スクロール、 reverse：上方向スクロール */
  @Prop({ default: false })
  reverse!: boolean

  private get direction() { return this.reverse ? 'top' : 'bottom' }

  load($state: StateChanger): void {
    this.$emit('loadable', new LoadingHandler($state))
  }
}
