import type { ResolutionState, ResolutionType } from '@/constants/schema-constants'

export class ResolutionsGetRequest {
  resolutionStates: ResolutionState[] | null | undefined = null
  resolutionTypes: ResolutionType[] | null | undefined = null
  fromDate: string | null | undefined = null
  toDate: string | null | undefined = null
  keyword: string | null | undefined = null
  sortOrder: string | null | undefined = null
  skipToken: string | undefined
  take: number

  constructor(take: number) { this.take = take }
}

export class ListResponseResolutionDto {
  resolutionType!: ResolutionType
  resolutionId!: string
  resolutionState!: ResolutionState
  title!: string
  deadline?: string
  holdingAt?: string
  readCount?: number
  voteCount?: number
  statementCount?: number
  questionCount?: number
  ticketId!: string
}

export class ResolutionsGetResponse {
  resolutions: ListResponseResolutionDto[] = []
  skipToken!: string
}
