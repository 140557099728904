















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { NOTIFICATION_ELEMENT_TYPES, OWNER_NOTIFICATION_STATE, OWNER_NOTIFICATION_TYPE, PINNING_SETTING_TYPE, TARGET_BUILDING_TYPE, TARGET_OWNER_TYPE, TRANSITION_TO } from '@/constants/schema-constants'
import type { TransitionTo, OwnerNotificationState, TargetOwnerType } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import type { ChipColors } from '@/constants/ux-constants'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { OwnerNotificationsArchivePutRequest } from '@/dtos/owner-notifications/archive/put'
import { TargetBuilding, TargetOwner } from '@/dtos/owner-notifications/commons'
import { OwnerNotificationsDeleteRequest } from '@/dtos/owner-notifications/delete'
import { OwnerNotificationDetailGetResponse } from '@/dtos/owner-notifications/get-detail'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { getOwnerNotificationStateLabel } from '@/libs/state-handler'
import { windowOpen } from '@/libs/window-open'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { ownerNotificationsModule } from '@/stores/owner-notifications-store'

@Component({
  components: {
    SmBadgeContent: () => import('@/components/atoms/SmBadgeContent.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmFreeFormatNotificationElement: () => import('@/components/organisms/SmFreeFormatNotificationElement.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class OwnerNotificationDetailPage extends Vue {
  @Prop({ required: true, default: '' })
  private readonly ownerNotificationId!: string

  async created(): Promise<void> {
    await ownerNotificationsModule.fetchOwnerNotificationDetail({ ownerNotificationId: this.ownerNotificationId })
  }

  OWNER_NOTIFICATION_STATE = Object.freeze(OWNER_NOTIFICATION_STATE)
  OWNER_NOTIFICATION_TYPE = Object.freeze(OWNER_NOTIFICATION_TYPE)
  NOTIFICATION_ELEMENT_TYPES = Object.freeze(NOTIFICATION_ELEMENT_TYPES)

  private get ownerNotification(): OwnerNotificationDetailGetResponse {
    return ownerNotificationsModule.detailResponse(this.ownerNotificationId) ?? new OwnerNotificationDetailGetResponse()
  }

  private get pinningSettingType(): string {
    if (!this.ownerNotification.pinningSettingType) return 'normalOwnerNotification'

    switch (this.ownerNotification.pinningSettingType) {
      case PINNING_SETTING_TYPE.NORMAL: return 'normalOwnerNotification'
      case PINNING_SETTING_TYPE.PINNED: return 'pinnedOwnerNotification'
      default: return assertExhaustive(this.ownerNotification.pinningSettingType)
    }
  }

  private get notificationStateLabel(): string {
    return getOwnerNotificationStateLabel(this.ownerNotification.notificationState)
  }

  private get notificationStateColor(): ChipColors {
    if (!this.ownerNotification.notificationState) return CHIP_COLORS.WHITE2
    switch (this.ownerNotification.notificationState) {
      case OWNER_NOTIFICATION_STATE.DRAFT: return CHIP_COLORS.GREEN
      case OWNER_NOTIFICATION_STATE.SCHEDULED: return CHIP_COLORS.WHITE1
      case OWNER_NOTIFICATION_STATE.NOTIFIED: return CHIP_COLORS.WHITE2
      case OWNER_NOTIFICATION_STATE.ARCHIVED: return CHIP_COLORS.WHITE2
      default: return assertExhaustive(this.ownerNotification.notificationState)
    }
  }

  private get isBorder(): boolean {
    return this.ownerNotification.notificationState === OWNER_NOTIFICATION_STATE.SCHEDULED
  }

  private get transitionBtn(): string | undefined {
    const transitionBtn = this.ownerNotification.freeFormatNotificationElements.find(e => e.notificationElementType === NOTIFICATION_ELEMENT_TYPES.BUTTON.POST_IDEA || e.notificationElementType === NOTIFICATION_ELEMENT_TYPES.BUTTON.CONSULTATION)
    if (!transitionBtn) return undefined
    if (transitionBtn.notificationElementType === NOTIFICATION_ELEMENT_TYPES.BUTTON.POST_IDEA) return 'アイデアを投稿する'
    if (transitionBtn.notificationElementType === NOTIFICATION_ELEMENT_TYPES.BUTTON.CONSULTATION) return '相談・連絡する'
  }

  // --------------- お知らせの投稿元を返す ---------------

  private get postedTo(): string {
    if (!this.ownerNotification.targetBuildingType) return ''
    const targetBuildings = this.ownerNotification.targetBuildings

    switch (this.ownerNotification.targetBuildingType) {
      case TARGET_BUILDING_TYPE.ALL:
        return this.ownerNotification.targetBuildingCount ? 'すべてのマンション　' + `${this.ownerNotification.targetBuildingCount}件` : '該当なし'
      case TARGET_BUILDING_TYPE.ALL_IN_CHARGE:
        return targetBuildings.length ? this.getBuildingNames(targetBuildings) : '該当なし'
      case TARGET_BUILDING_TYPE.INDIVIDUALLY_SELECTED:
        if (!targetBuildings.length) return '該当なし'
        if (targetBuildings.length === 1) return this.onlyBuildingPostedToLabel(this.ownerNotification.notificationState, targetBuildings[0], this.ownerNotification.targetOwners, this.ownerNotification.targetOwnerType, this.ownerNotification.idea?.title, this.ownerNotification.resolution?.title)
        return this.getBuildingNames(targetBuildings)
      default: return assertExhaustive(this.ownerNotification.targetBuildingType)
    }
  }

  getBuildingNames(targetBuildings: TargetBuilding[]): string {
    return targetBuildings.map(b => b.buildingName).join('｜')
  }

  getOwnerNames(targetOwners: TargetOwner[]): string {
    if (!targetOwners.length) return '該当なし'
    return targetOwners.map(o => `${o.roomNumber} ${o.userName}`).join('、')
  }

  onlyBuildingPostedToLabel(notificationState: OwnerNotificationState, targetBuilding: TargetBuilding, targetOwners: TargetOwner[], targetOwnerType: TargetOwnerType, ideaTitle?: string, resolutionTitle?: string): string {
    // 対象区分所有者種別が全ての区分所有者の場合は、物件名のみ表示させる
    if (targetOwnerType === TARGET_OWNER_TYPE.ALL) return targetBuilding.buildingName
    //  対象区分所有者種別が個別に選択で対象の区分所有者を指定している、またはお知らせ状態が「公開中」であるのに、送信対象の区分所有者が存在しない場合は「該当なし」
    if (!targetOwners.length && (targetOwnerType === TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED || notificationState === OWNER_NOTIFICATION_STATE.NOTIFIED)) return '該当なし'

    let label = `[${targetBuilding.buildingName}]`

    // 対象区分所有者種別が個別に選択した区分所有者の場合は、物件名と区分所有者名のみ表示させる
    if (targetOwnerType === TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED) {
      return label.concat(this.getOwnerNames(targetOwners))
    }

    switch (notificationState) {
      case OWNER_NOTIFICATION_STATE.SCHEDULED:
        label = label.concat(this.addTitle(targetOwnerType, ideaTitle, resolutionTitle))
        if (!targetOwners.length) label = label.concat('\n該当なし')
        break
      case OWNER_NOTIFICATION_STATE.NOTIFIED:
      case OWNER_NOTIFICATION_STATE.ARCHIVED:
        label = label.concat(this.addTitle(targetOwnerType, ideaTitle, resolutionTitle))
        label = label.concat('\n', this.getOwnerNames(targetOwners))
        break
      case OWNER_NOTIFICATION_STATE.DRAFT:
        throw new Error(('The notification state(' + notificationState + ') is unavailable'))
      default:
        assertExhaustive(notificationState)
    }

    return label
  }

  addTitle(targetOwnerType: TargetOwnerType, ideaTitle?: string, resolutionTitle?: string): string {
    switch (targetOwnerType) {
      case TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED:
        return 'オンライン決議　' + resolutionTitle + '　未投票の区分所有者'
      case TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED:
        return '総会決議　' + resolutionTitle + '　意思表明をしていない区分所有者'
      case TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD:
        return 'プラン　' + ideaTitle + '　未読の区分所有者'
      default:
        return ''
    }
  }

  // --------------- ボタン押下時の処理 ---------------

  isArchiveOwnerNotificationDialogVisible = false
  openArchiveDialog(): void {
    this.isArchiveOwnerNotificationDialogVisible = true
  }

  async executeArchiveOwnerNotification(): Promise<void> {
    const req = new OwnerNotificationsArchivePutRequest(this.ownerNotification.ownerNotificationId, this.ownerNotification.version)
    this.isArchiveOwnerNotificationDialogVisible = false
    await ownerNotificationsModule.archiveOwnerNotifications(req)

    await ownerNotificationsModule.fetchOwnerNotificationDetail({ ownerNotificationId: this.ownerNotificationId })
  }

  isDeleteOwnerNotificationDialogVisible = false
  openDeleteDialog(): void {
    this.isDeleteOwnerNotificationDialogVisible = true
  }

  async executeDeleteOwnerNotification(): Promise<void> {
    const req = new OwnerNotificationsDeleteRequest(this.ownerNotification.ownerNotificationId)
    this.isDeleteOwnerNotificationDialogVisible = false
    await ownerNotificationsModule.deleteOwnerNotifications(req)

    this.$router.push({ name: staticRoutes.ownerNotificationsList.name })
  }

  goToEditPage(): void {
    this.$router.push({ name: staticRoutes.ownerNotificationUpdate.name, params: { ownerNotificationId: this.ownerNotificationId } })
  }

  goToPostPage(): void {
    this.$router.push({ name: staticRoutes.ownerNotificationCreate.name, query: { ownerNotificationId: this.ownerNotificationId } })
  }

  async goToSmoothePage(transitionType: TransitionTo, transitionParams: Record<string, string>): Promise<void> {
    // 画面に遷移前に遷移先の物件の詳細情報を取得する
    const buildingId = this.ownerNotification.targetBuildings[0].buildingId
    const buildingReq = new BuildingDetailGetRequest(buildingId)
    await buildingsModule.fetchBuildingDetail(buildingReq)
    currentStateModule.setCurrentBuilding(buildingId)

    switch (transitionType) {
      case TRANSITION_TO.IDEA.OWNER:
        this.$router.push({ name: staticRoutes.ownerIdeaDetail.name, params: transitionParams }); break
      case TRANSITION_TO.IDEA.ADMIN:
        this.$router.push({ name: staticRoutes.adminIdeaDetail.name, params: transitionParams }); break
      case TRANSITION_TO.RESOLUTION.GENERAL_MEETING:
        this.$router.push({ name: staticRoutes.gmResolutionDetail.name, params: transitionParams }); break
      case TRANSITION_TO.RESOLUTION.ONLINE:
        this.$router.push({ name: staticRoutes.onlineResolutionDetail.name, params: transitionParams }); break
      case TRANSITION_TO.QA:
      case TRANSITION_TO.REPORT:
      case TRANSITION_TO.TICKET.DETAIL:
      case TRANSITION_TO.TICKET.DETAIL_TASK:
      case TRANSITION_TO.NOTICE:
      case TRANSITION_TO.STAFF_DETAIL.FRONT:
      case TRANSITION_TO.STAFF_DETAIL.LIFE_MANAGER:
      case TRANSITION_TO.CASYS_RESULTS:
        throw new Error(('The transition type(' + transitionType + ') is unavailable'))
      default:
        assertExhaustive(transitionType)
    }
  }

  goToExternalPage(externalSiteUrl: string): void {
    windowOpen(externalSiteUrl)
  }
}
