







































































































































































































































































import { Mixins, Component, Watch } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { ADMIN_ROLE, BUILDING_GROUP_TYPE, BUILDING_MENU_ITEMS, BUILDING_MENU_TYPES, OWNER_NOTIFICATION_STATE, PINNING_SETTING_TYPE, TARGET_BUILDING_TYPE, TARGET_OWNER_TYPE } from '@/constants/schema-constants'
import type { BuildingGroupType, BuildingMenuTypes, OwnerNotificationState, PinningSettingType, TargetOwnerType, TargetBuildingType } from '@/constants/schema-constants'

import { Choice } from '@/components/molecules/SmBtnToggle.vue'
import { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'
import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { OwnerNotification, OwnerNotificationsSearchPostRequest } from '@/dtos/owner-notifications/search/post'
import { TargetBuilding, TargetOwner } from '@/dtos/owner-notifications/commons'
import { StaffsGetRequest, StaffsGetResponseStaff } from '@/dtos/staffs/get'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { getGroupLabel } from '@/libs/type-handler'
import { generateUuid } from '@/libs/uuid-generator'

import { buildingsModule } from '@/stores/buildings-store'
import { errorsModule } from '@/stores/errors'
import { myProfileModule } from '@/stores/my-profile-store'
import { ownerNotificationsModule } from '@/stores/owner-notifications-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { staffsModule } from '@/stores/staffs-store'

const SELECTED_NOTIFICATION_STATES = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  NOTIFIED: 'notified',
  ARCHIVED: 'archived',
}

const SELECTED_PINNING_SETTING_TYPES = {
  NORMAL: 'normal',
  PINNED: 'pinned',
}

// 並び順のメニューの内容
const SORT_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  desc: {
    text: '公開日が新しい順に並び替える',
    label: '公開日が新しい順'
  },
  asc: {
    text: '公開日が古い順に並び替える',
    label: '公開日が古い順'
  }
}

const TAKE = 20
const STORAGE_KEY = staticRoutes.ownerNotificationsList.name

// storeに検索条件を保存するためのクラス
class OwnerNotificationsSearchCondition extends OwnerNotificationsSearchPostRequest {
  selectedBuildingMenuId!: BuildingMenuTypes
  selectedBuildingGroupType!: BuildingGroupType
  constructor() { super(TAKE) }
}

// Cardにプロパティを渡すためのクラス
class SmCardOwnerNotificationDto {
  ownerNotificationId!: string
  notificationState!: OwnerNotificationState
  pinningSettingType!: PinningSettingType
  title!: string
  notifiedAt?: string
  scheduledPostingAt?: string
  pinningDeadline?: string
  postedTo!: string
  poster!: string

  constructor(ownerNotificationId: string, notificationState: OwnerNotificationState, pinningSettingType: PinningSettingType, title: string, postedTo: string, poster: string, notifiedAt?: string, scheduledPostingAt?: string, pinningDeadline?: string) {
    this.ownerNotificationId = ownerNotificationId
    this.notificationState = notificationState
    this.pinningSettingType = pinningSettingType
    this.title = title
    this.notifiedAt = notifiedAt
    this.scheduledPostingAt = scheduledPostingAt
    this.pinningDeadline = pinningDeadline
    this.postedTo = postedTo
    this.poster = poster
  }
}

/** システム管理者のダミー管理者ID */
const SYSTEM_DUMMY_ADMIN_ID = '99999999-9999-9999-9999-999999999999'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmBtnToggle: () => import('@/components/molecules/SmBtnToggle.vue'),
    SmCardOwnerNotification: () => import('@/components/molecules/card/SmCardOwnerNotification.vue'),
    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    BuildingGroupSelectModal: () => import('@/components/organisms/modal/BuildingGroupSelectModal.vue'),
    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class OwnerNotificationsListPage extends Mixins(CurrentAdminManager) {
  // リクエストパラメータ
  searchCondition: OwnerNotificationsSearchCondition = new OwnerNotificationsSearchCondition()

  // 絞り込み条件
  selectedNotificationStates: string[] = []
  selectedPinningSettingTypes: string[] = []
  fromDate = ''
  toDate = ''
  selectedBuildings: Building[] = []
  selectedPoster = ''
  keyword = ''

  selectedBuildingMenuId: BuildingMenuTypes = BUILDING_MENU_TYPES.ALL // マンションの絞り込みメニューの種類

  selectedBuildingDisplay = 'すべてのマンション'

  private get buildingNames(): string {
    return this.selectedBuildings.map(b => b.buildingName).join(' | ')
  }

  BUILDING_MENU_ITEMS = Object.freeze(BUILDING_MENU_ITEMS)
  selectedBuildingsByEach: Building[] = [] // 個別選択ダイアログで以前選択した物件を保持しておく変数
  selectedBuildingGroupType: BuildingGroupType = BUILDING_GROUP_TYPE.TOKYO // マンショングループ選択ダイアログで以前選択したマンショングループを保持しておく変数

  private get staffs(): StaffsGetResponseStaff[] { return staffsModule.staffs(ADMIN_ROLE.SERVICE_STAFF) }

  private get selectionItems(): { value: string, label: string }[] {
    const items = [{ value: '', label: 'すべて' }, { value: SYSTEM_DUMMY_ADMIN_ID, label: 'システム管理者' }]
    const staffs = this.staffs.map(s => { return { value: s.userId, label: s.userName } })
    return items.concat(staffs)
  }

  // トグルボタン
  notificationStatesChoice: Choice[] = [
    new Choice(SELECTED_NOTIFICATION_STATES.DRAFT, '下書き'),
    new Choice(SELECTED_NOTIFICATION_STATES.SCHEDULED, '公開予定'),
    new Choice(SELECTED_NOTIFICATION_STATES.NOTIFIED, '公開中'),
    new Choice(SELECTED_NOTIFICATION_STATES.ARCHIVED, 'アーカイブ'),
  ]

  pinningSettingTypesChoice: Choice[] = [
    new Choice(SELECTED_PINNING_SETTING_TYPES.NORMAL, '通常のお知らせ'),
    new Choice(SELECTED_PINNING_SETTING_TYPES.PINNED, '固定指定されたお知らせ'),
  ]

  async created():Promise<void> {
    this.searchCondition.sortOrder = 'desc'

    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999))
    await staffsModule.fetchStaffs(new StaffsGetRequest(ADMIN_ROLE.SERVICE_STAFF))

    // 初期検索パラメーターの設定（以前の検索条件が残っている場合、それを復元して反映）
    const stored = paramStorageModule.savedParam(STORAGE_KEY, OwnerNotificationsSearchCondition)
    if (stored) {
      this.selectedNotificationStates = this.changeToSelectedNotificationStates(stored.notificationStates)
      this.selectedPinningSettingTypes = this.changeToSelectedPinningSettingTypes(stored.pinningSettingTypes)
      this.fromDate = stored.fromDate ?? ''
      this.toDate = stored.toDate ?? ''
      if (stored.selectedBuildingMenuId === BUILDING_MENU_TYPES.GROUP) {
        // マンショングループを選択していた場合は、検索条件に設定する物件IDを取得し直す
        stored.buildings = this.getBuildingIdsByGroupType(stored.selectedBuildingGroupType, buildingsModule.buildingsGet.buildings)
      }
      this.selectedBuildings = this.getTargetBuildings(stored.buildings)
      this.selectedPoster = stored.adminId ?? ''
      this.keyword = stored.keyword ?? ''
      this.selectedBuildingMenuId = stored.selectedBuildingMenuId ?? BUILDING_MENU_TYPES.GROUP
      this.selectedBuildingsByEach = this.selectedBuildingMenuId === BUILDING_MENU_TYPES.EACH ? this.selectedBuildings : []
      this.selectedBuildingGroupType = stored.selectedBuildingGroupType ?? BUILDING_GROUP_TYPE.TOKYO
      this.setSelectedBuildingDisplay()

      this.searchCondition = stored
    }

    this.search()
  }

  changeToSelectedNotificationStates(notificationStates?: OwnerNotificationState[]): string[] {
    const selectedNotificationStates: string[] = []
    if (notificationStates?.includes(OWNER_NOTIFICATION_STATE.DRAFT)) selectedNotificationStates.push(SELECTED_NOTIFICATION_STATES.DRAFT)
    if (notificationStates?.includes(OWNER_NOTIFICATION_STATE.SCHEDULED)) selectedNotificationStates.push(SELECTED_NOTIFICATION_STATES.SCHEDULED)
    if (notificationStates?.includes(OWNER_NOTIFICATION_STATE.NOTIFIED)) selectedNotificationStates.push(SELECTED_NOTIFICATION_STATES.NOTIFIED)
    if (notificationStates?.includes(OWNER_NOTIFICATION_STATE.ARCHIVED)) selectedNotificationStates.push(SELECTED_NOTIFICATION_STATES.ARCHIVED)

    return selectedNotificationStates
  }

  changeToSelectedPinningSettingTypes(pinningSettingTypes?: PinningSettingType[]): string[] {
    const selectedPinningSettingTypes: string[] = []
    if (pinningSettingTypes?.includes(PINNING_SETTING_TYPE.NORMAL)) selectedPinningSettingTypes.push(SELECTED_PINNING_SETTING_TYPES.NORMAL)
    if (pinningSettingTypes?.includes(PINNING_SETTING_TYPE.PINNED)) selectedPinningSettingTypes.push(SELECTED_PINNING_SETTING_TYPES.PINNED)

    return selectedPinningSettingTypes
  }

  getTargetBuildings(buildingIds?: string[]): Building[] {
    return buildingsModule.buildingsGet.buildings.filter(b => buildingIds?.includes(b.buildingId))
  }

  private get skipToken(): string | null { return ownerNotificationsModule.skipToken }
  private get smCardOwnerNotificationDtos(): SmCardOwnerNotificationDto[] {
    return this.ownerNotifications.map(n => new SmCardOwnerNotificationDto(
      n.ownerNotificationId,
      n.notificationState,
      n.pinningSettingType,
      n.title,
      this.getPostedTo(n.notificationState, n.targetBuildings, n.targetOwners, n.targetBuildingType, n.targetOwnerType, n.targetBuildingCount, n.ideaTitle, n.resolutionTitle),
      n.poster,
      n.notifiedAt,
      n.scheduledPostingAt,
      n.pinningDeadline
    ))
  }

  private get ownerNotifications(): OwnerNotification[] { return ownerNotificationsModule.ownerNotifications }
  isFilterMenuOpen = true

  sortMenuItems = SORT_MENU_ITEMS

  // --------------- データの読み込み ---------------
  identifier = 0
  isWaitingSwitch = false // switch・toggleの連打防止用
  handler: LoadingHandler | null = null

  search(): void {
    ownerNotificationsModule.clearFetchedOwnerNotifications()
    this.identifier++
  }

  async loadNotifications(handler: LoadingHandler): Promise<void> {
    // 検索パラメータの初期化が完了していなければ完了扱いにする
    if (!this.identifier) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = true
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }

    const beforeOwnerNotificationsCount = this.ownerNotifications.length

    this.searchCondition.adminId = this.selectedPoster ? this.selectedPoster : null
    this.searchCondition.skipToken = this.skipToken ?? undefined
    this.searchCondition.selectedBuildingMenuId = this.selectedBuildingMenuId
    this.searchCondition.selectedBuildingGroupType = this.selectedBuildingGroupType

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...this.searchCondition } })

    // グループ選択時に対象物件が0件だった場合はAPIを呼び出さず0件表示
    if (this.selectedBuildingMenuId === BUILDING_MENU_TYPES.GROUP && this.searchCondition.buildings?.length === 0) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    await ownerNotificationsModule.fetchOwnerNotifications(this.toPostRequest())

    // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
    if (this.ownerNotifications.length === 0) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = false
    handler.loaded()
    const expectingToBe = beforeOwnerNotificationsCount + this.searchCondition.take
    if (this.ownerNotifications.length < expectingToBe) handler.complete()
  }

  private get isAllSearch(): boolean {
    return (
      !this.searchCondition.notificationStates?.length &&
      !this.searchCondition.pinningSettingTypes?.length &&
      !this.searchCondition.fromDate &&
      !this.searchCondition.toDate &&
      !this.searchCondition.adminId &&
      !this.searchCondition.keyword &&
      this.selectedBuildingMenuId === BUILDING_MENU_TYPES.ALL
    )
  }

  toPostRequest(): OwnerNotificationsSearchPostRequest {
    const req = new OwnerNotificationsSearchPostRequest(TAKE)
    req.notificationStates = this.searchCondition.notificationStates
    req.pinningSettingTypes = this.searchCondition.pinningSettingTypes
    req.fromDate = this.searchCondition.fromDate ?? undefined
    req.toDate = this.searchCondition.toDate ?? undefined
    req.buildings = this.searchCondition.buildings
    req.adminId = this.searchCondition.adminId ?? undefined
    req.keyword = this.searchCondition.keyword ?? undefined
    req.sortOrder = this.searchCondition.sortOrder
    req.skipToken = this.skipToken ?? undefined
    return req
  }

  // --------------- DTOのプロパティ作成する処理 ---------------

  getPostedTo(notificationState: OwnerNotificationState, targetBuildings: TargetBuilding[], targetOwners: TargetOwner[], targetBuildingType: TargetBuildingType, targetOwnerType: TargetOwnerType, targetBuildingCount?: number, ideaTitle?: string, resolutionTitle?: string): string {
    switch (targetBuildingType) {
      case TARGET_BUILDING_TYPE.ALL:
        return targetBuildingCount ? 'すべてのマンション　' + `${targetBuildingCount}件` : '該当なし'
      case TARGET_BUILDING_TYPE.ALL_IN_CHARGE:
        return targetBuildings.length ? this.getBuildingNames(targetBuildings) : '該当なし'
      case TARGET_BUILDING_TYPE.INDIVIDUALLY_SELECTED:
        if (!targetBuildings.length) return '該当なし'
        if (targetBuildings.length === 1) return this.onlyBuildingPostedToLabel(notificationState, targetBuildings[0], targetOwners, targetOwnerType, ideaTitle, resolutionTitle)
        return this.getBuildingNames(targetBuildings)
      default: return assertExhaustive(targetBuildingType)
    }
  }

  getBuildingNames(targetBuildings: TargetBuilding[]): string {
    return targetBuildings.map(b => b.buildingName).join('｜')
  }

  getOwnerNames(targetOwners: TargetOwner[]): string {
    if (!targetOwners.length) return '該当なし'
    return targetOwners.map(o => `${o.roomNumber} ${o.userName}`).join('、')
  }

  onlyBuildingPostedToLabel(notificationState: OwnerNotificationState, targetBuilding: TargetBuilding, targetOwners: TargetOwner[], targetOwnerType: TargetOwnerType, ideaTitle?: string, resolutionTitle?: string): string {
    // 対象区分所有者種別が全ての区分所有者の場合は、物件名のみ表示させる
    if (targetOwnerType === TARGET_OWNER_TYPE.ALL) return targetBuilding.buildingName
    //  対象区分所有者種別が個別に選択で対象の区分所有者を指定している、またはお知らせ状態が「公開中」であるのに、送信対象の区分所有者が存在しない場合は「該当なし」
    if (!targetOwners.length && (targetOwnerType === TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED || notificationState === OWNER_NOTIFICATION_STATE.NOTIFIED)) return '該当なし'

    let label = `[${targetBuilding.buildingName}]`

    // 対象区分所有者種別が個別に選択した区分所有者の場合は、物件名と区分所有者名のみ表示させる
    if (targetOwnerType === TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED) {
      return label.concat(this.getOwnerNames(targetOwners))
    }

    switch (notificationState) {
      case OWNER_NOTIFICATION_STATE.DRAFT:
        label = label.concat(this.addTitle(targetOwnerType, ideaTitle, resolutionTitle))
        break
      case OWNER_NOTIFICATION_STATE.SCHEDULED:
        label = label.concat(this.addTitle(targetOwnerType, ideaTitle, resolutionTitle))
        if (!targetOwners.length) label = label.concat('\n該当なし')
        break
      case OWNER_NOTIFICATION_STATE.NOTIFIED:
      case OWNER_NOTIFICATION_STATE.ARCHIVED:
        label = label.concat(this.addTitle(targetOwnerType, ideaTitle, resolutionTitle))
        label = label.concat('\n', this.getOwnerNames(targetOwners))
        break
      default:
        assertExhaustive(notificationState)
    }

    return label
  }

  addTitle(targetOwnerType: TargetOwnerType, ideaTitle?: string, resolutionTitle?: string): string {
    switch (targetOwnerType) {
      case TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED:
        return 'オンライン決議　' + resolutionTitle + '　未投票の区分所有者'
      case TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED:
        return '総会決議　' + resolutionTitle + '　意思表明をしていない区分所有者'
      case TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD:
        return 'プラン　' + ideaTitle + '　未読の区分所有者'
      default:
        return ''
    }
  }

  // --------------- ボタンなどを押下した際の処理 ---------------

  goToCreateNotificationPage(): void {
    this.$router.push({ name: staticRoutes.ownerNotificationCreate.name })
  }

  // カードクリック処理
  onClickCard(notificationState: OwnerNotificationState, notificationId: string): void {
    if (notificationState === OWNER_NOTIFICATION_STATE.DRAFT) {
      this.$router.push({ name: staticRoutes.ownerNotificationCreate.name, query: { ownerNotificationId: notificationId } })
    } else {
      this.$router.push({ name: staticRoutes.ownerNotificationDetail.name, params: { ownerNotificationId: notificationId } })
    }
  }

  // 絞り込み処理
  onChangeSelectedNotificationStates(): void {
    const notificationStates: OwnerNotificationState[] = []
    if (this.selectedNotificationStates.includes(SELECTED_NOTIFICATION_STATES.DRAFT)) notificationStates.push(OWNER_NOTIFICATION_STATE.DRAFT)
    if (this.selectedNotificationStates.includes(SELECTED_NOTIFICATION_STATES.SCHEDULED)) notificationStates.push(OWNER_NOTIFICATION_STATE.SCHEDULED)
    if (this.selectedNotificationStates.includes(SELECTED_NOTIFICATION_STATES.NOTIFIED)) notificationStates.push(OWNER_NOTIFICATION_STATE.NOTIFIED)
    if (this.selectedNotificationStates.includes(SELECTED_NOTIFICATION_STATES.ARCHIVED)) notificationStates.push(OWNER_NOTIFICATION_STATE.ARCHIVED)

    this.searchCondition.notificationStates = notificationStates
    this.search()
  }

  onChangePinningSettingTypes(): void {
    const pinningSettingTypes: PinningSettingType[] = []
    if (this.selectedPinningSettingTypes.includes(SELECTED_PINNING_SETTING_TYPES.NORMAL)) pinningSettingTypes.push(PINNING_SETTING_TYPE.NORMAL)
    if (this.selectedPinningSettingTypes.includes(SELECTED_PINNING_SETTING_TYPES.PINNED)) pinningSettingTypes.push(PINNING_SETTING_TYPE.PINNED)

    this.searchCondition.pinningSettingTypes = pinningSettingTypes
    this.search()
  }

  onSearchDateFilter(): void {
    this.searchCondition.fromDate = this.fromDate === '' ? null : this.fromDate
    this.searchCondition.toDate = this.toDate === '' ? null : this.toDate
    this.search()
  }

  selectBuildingMenu(): void {
    switch (this.selectedBuildingMenuId) {
      case BUILDING_MENU_TYPES.ALL:
        this.onChangeBuilding()
        break
      case BUILDING_MENU_TYPES.GROUP:
        this.openBuildingGroupSelectModal()
        break
      case BUILDING_MENU_TYPES.EACH:
        this.openBuildingSelectModal()
        break
    }
  }

  setBuildingGroupType(selectedGroupType: BuildingGroupType): void {
    this.selectedBuildingGroupType = selectedGroupType
    const buildingIds = this.getBuildingIdsByGroupType(this.selectedBuildingGroupType, buildingsModule.buildingsGet.buildings)
    this.onChangeBuilding(buildingIds)
  }

  // マンショングループに応じたマンションIDの一覧を取得する
  private getBuildingIdsByGroupType(buildingGroupType:BuildingGroupType, buildings: Building[]): string[] {
    return this.getBuildingsByGroupType(buildingGroupType, buildings).map(e => e.buildingId)
  }

  // マンショングループに応じたマンションの一覧を取得する
  private getBuildingsByGroupType(buildingGroupType:BuildingGroupType, buildings: Building[]): Building[] {
    return buildings.filter(e => e.buildingAreaType === buildingGroupType)
  }

  onChangeBuilding(buildingIds?: string[]): void {
    this.selectedBuildings = this.getTargetBuildings(buildingIds)

    // 個別マンション選択ダイアログで選択されたマンションを保持する（それ以外の場合はクリア）
    this.selectedBuildingsByEach = this.selectedBuildingMenuId === BUILDING_MENU_TYPES.EACH ? this.selectedBuildings : []

    // 「すべてのマンション」と「個別に選択」を選択した場合、グループ選択ダイアログで保持していた値をクリアする
    if (this.selectedBuildingMenuId === BUILDING_MENU_TYPES.ALL || this.selectedBuildingMenuId === BUILDING_MENU_TYPES.EACH) {
      this.selectedBuildingGroupType = BUILDING_GROUP_TYPE.TOKYO
    }

    // 物件IDを検索条件に追加する
    this.searchCondition.buildings = buildingIds ?? []

    this.setSelectedBuildingDisplay()
    this.search()
  }

  // マンションの横に表示するラベルをセットする
  setSelectedBuildingDisplay(): void {
    switch (this.selectedBuildingMenuId) {
      case BUILDING_MENU_TYPES.ALL:
        this.selectedBuildingDisplay = 'すべてのマンション'
        break
      case BUILDING_MENU_TYPES.GROUP:
        this.selectedBuildingDisplay = getGroupLabel(this.selectedBuildingGroupType)
        break
      case BUILDING_MENU_TYPES.EACH:
        this.selectedBuildingDisplay = this.buildingNames
        break
    }
  }

  onClickPosterSelect(): void {
    this.searchCondition.adminId = this.selectedPoster
    this.search()
  }

  onSelectMyself(): void {
    const myAdminId = myProfileModule.myProfileGet?.user.userId
    this.selectedPoster = myAdminId ?? ''
    this.searchCondition.adminId = myAdminId ?? null
    this.search()
  }

  onSearchKeyword(): void {
    this.searchCondition.keyword = this.keyword === '' ? null : this.keyword
    this.search()
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
    this.isWaitingSwitch = false
  }

  // --------------- モーダル表示等の処理 ---------------

  // 物件個別選択モーダル
  inputText = ''
  isStaff = true
  buildingKeyword = ''

  isBuildingSelectModalVisible = false
  buildingSelectModalKey = generateUuid()
  openBuildingSelectModal(): void {
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingSelectModalVisible = true
  }

  // 物件グループ選択モーダル
  isBuildingGroupSelectModalVisible = false
  buildingGroupSelectModalKey = generateUuid()
  openBuildingGroupSelectModal(): void {
    this.buildingGroupSelectModalKey = generateUuid()
    this.isBuildingGroupSelectModalVisible = true
  }
}
