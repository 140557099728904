import { materialsClient } from '@/clients/materials-client'
import { s3Client } from '@/clients/non-api/s3-client'
import { Material, MaterialFormInputDto } from '@/dtos/commons'
import { MaterialPostRequest } from '@/dtos/materials/post'
import { MaterialPutRequest } from '@/dtos/materials/put'

/**
 * how-to:
 * async execute() {
 *   const rawReq = new XxxxRequest(...)
 *   const uploader = new FileUploader() // ※※※※※ create each time ※※※※※
 *
 *   const req = await uploader.prepare(rawReq)
 *   await xxxxModule.post(req)
 * }
 */
export class FileUploader {
  private _stack: { url: string, file: File }[] = []

  /**
   * 素材情報を含みうるsmooth-eのAPIへのリクエストオブジェクトを受け取り、必要なアップロード素材を取り分けた上で、必要な情報を含んだ形にそれを整形して返します。
   * @param request リクエストボディに使うオブジェクト
   */
  async prepare<T>(request: T): Promise<T> {
    const prepared = await this._uploadThenReplaceMaterialOf(request)
    await Promise.all(this._stack.map(q => s3Client.upload(q.url, q.file, q.file.type)))
    return prepared
  }

  /** 再帰的にプロパティを潜り、新しいファイルがあればいったんqueueに溜めつつmaterialIdを採番して返す */
  private async _uploadThenReplaceMaterialOf<T>(target: T): Promise<T> {
    if (typeof target !== 'object' || target === null || toString.call(target) === '[object Date]') return Promise.resolve(target)

    const done: Record<string, unknown> = {}
    for (const [k, v] of Object.entries(target)) {
      if (this._isPrimOrDateOrNull(v)) {
        // プリミティブはそのまま
        done[k] = v
      } else if (Array.isArray(v)) {
        // 配列であれば子要素1つ1つで同じことを実行
        done[k] = await Promise.all(v.map(vElement => this._uploadThenReplaceMaterialOf(vElement)))
      } else if (v instanceof MaterialFormInputDto) {
        // 素材であればアップロードの準備をしてリクエストに必要な情報のみを保持
        done[k] = await this._handleMaterialInput(v)
      } else {
        // その他 = オブジェクトであればさらにその子要素に同じことを実行
        done[k] = await this._uploadThenReplaceMaterialOf(v)
      }
    }
    return done as T // ※戻り値はその型のインスタンスでは無くなってしまう
  }

  private async _handleMaterialInput(m: MaterialFormInputDto): Promise<Material | null> {
    // 既に素材IDを保有しているのは、親データの更新で、且つファイルの削除・再添付が無かった場合
    if (m.materialId) {
      // このとき、ファイル名だけ変更されているケースがあり、その場合に限り PUT /materials をコール。いずれの場合もファイルのS3アップロードは不要のため終了
      // ※親データの更新APIコール前に呼び出すため、後処理でそれに失敗してもファイル名だけ更新済となってしまう。それが許容できない場合、同一トランザクション下で更新されるよう個別のAPI I/Fを見直す
      if (m.originalFileName && m.fileNameChanged) await materialsClient.putMaterial(new MaterialPutRequest(m.materialId, m.originalFileName))

      const needForReq: Partial<Material> = { materialId: m.materialId, caption: m.caption }
      return Promise.resolve(Object.assign(new Material(), needForReq))
    } else if (!m.file) {
      // 素材IDを保有していないのにファイルの実体も無いのは、ファイルの添付がされていない場合にあたる
      return Promise.resolve(null)
    }

    // 上記以外の場合は、添付されたファイルの情報を POST /materials をコールしてサーバに登録し、本体をS3にアップロード
    const postResponse = await materialsClient.postMaterial(new MaterialPostRequest(m.file.type, m.originalFileName ?? m.file.name))
    if (!postResponse) return null

    // ファイルのアップロードは出揃ってから一括で実施
    this._stack.push({ url: postResponse.signedUrl, file: m.file })

    const needForReq: Partial<Material> = { materialId: postResponse.materialId, caption: m.caption }
    return Object.assign(new Material(), needForReq)
  }

  private _isPrimOrDateOrNull(v: unknown): boolean {
    return typeof v !== 'object' || v === null || toString.call(v) === '[object Date]'
  }
}

/**
 * 素材情報を受け取り、必要なアップロード素材を取り分けた上で、必要な情報を含んだ形にそれを整形して返します。
 * @param materialInputDto リクエストボディに使うオブジェクト
 * @param filePath オブジェクトの格納先パス。オブジェクトのキー
 * @param needReferenceUrl 素材URL(参照用)の要否フラグ
 */
export async function uploadMaterial(m: MaterialFormInputDto, filePath?: string, needReferenceUrl = true): Promise<MaterialFormInputDto | undefined> {
  // 既に素材IDを保有しているのは、親データの更新で、且つファイルの削除・再添付が無かった場合
  if (m.materialId) {
    // このとき、ファイル名だけ変更されているケースがあり、その場合に限り PUT /materials をコール。いずれの場合もファイルのS3アップロードは不要のため終了
    // ※親データの更新APIコール前に呼び出すため、後処理でそれに失敗してもファイル名だけ更新済となってしまう。それが許容できない場合、同一トランザクション下で更新されるよう個別のAPI I/Fを見直す
    if (m.originalFileName && m.fileNameChanged) await materialsClient.putMaterial(new MaterialPutRequest(m.materialId, m.originalFileName))

    const res: Partial<Material> = { materialId: m.materialId, materialUrl: m.materialUrl, caption: m.caption, materialType: m.materialType, originalFileName: m.originalFileName }
    return Promise.resolve(Object.assign(new MaterialFormInputDto(), res))
  } else if (!m.file) {
    // 素材IDを保有していないのにファイルの実体も無いのは、ファイルの添付がされていない場合にあたる
    return Promise.resolve(undefined)
  }

  // 上記以外の場合は、添付されたファイルの情報を POST /materials をコールしてサーバに登録し、本体をS3にアップロード
  const postResponse = await materialsClient.postMaterial(new MaterialPostRequest(m.file.type, m.originalFileName ?? m.file.name, filePath, needReferenceUrl))
  if (!postResponse) return undefined

  // ファイルのアップロードをする
  await s3Client.upload(postResponse.signedUrl, m.file, m.file.type)

  const res: Partial<Material> = { materialId: postResponse.materialId, materialUrl: postResponse.signedReferenceUrl, caption: m.caption, materialType: m.materialType, originalFileName: m.originalFileName }
  return Promise.resolve(Object.assign(new MaterialFormInputDto(), res))
}
