import { Type } from 'class-transformer'
import type { OperationState } from '@/constants/schema-constants'

export class ManagementOperationsSearchCommonPostRequest {
  buildings?: string[] = []
  admins?: string[] = []
  showOnlyUnresolvedTasks: boolean
  showTasksBeyondTargetMonth: boolean
  skip: number
  take: number

  constructor(showOnlyUnresolvedTasks: boolean, showTasksBeyondTargetMonth: boolean, skip: number, take: number) {
    this.showOnlyUnresolvedTasks = showOnlyUnresolvedTasks
    this.showTasksBeyondTargetMonth = showTasksBeyondTargetMonth
    this.skip = skip
    this.take = take
  }
}

export class ManagementOperationsSearchCommonPostResponseExecutor {
  adminId!: string
  adminName!: string
  adminShortName!: string
  unregisteredExecutorId!: string
  unregisteredExecutorName!: string
}

export class ManagementOperationsTaskProgress {
  managementOperationsTaskCode!: string
  managementOperationTaskStatusId!: string
  taskName!: string
  @Type(() => ManagementOperationsSearchCommonPostResponseExecutor) executor?: ManagementOperationsSearchCommonPostResponseExecutor
  targetDate!: string
  operationState!: OperationState
  completedAt?: string
  memo?: string
  version!: number
}

export class ManagementOperationsCommon {
  condominiumCd!: string
  buildingId!: string
  buildingName!: string
  isOutOfService!: boolean
  adminShortName?: string
  @Type(() => ManagementOperationsTaskProgress) managementOperationsTaskProgress: ManagementOperationsTaskProgress[] = []
}

export class ManagementOperationsSearchCommonPostResponse {
  @Type(() => ManagementOperationsCommon) managementOperations?: ManagementOperationsCommon[]
  managementOperationCount!: number
}
