import { Type } from 'class-transformer'
import { TicketCommentState } from '@/constants/schema-constants'
import { Material, User } from '@/dtos/commons'

export class TicketCommentsGetRequest {
  ticketId: string
  take: number
  skipToken?: string
  constructor(ticketId: string, take: number, skipToken?: string) {
    this.ticketId = ticketId
    this.take = take
    this.skipToken = skipToken
  }
}

export class TicketComment {
  ticketCommentId!: string
  @Type(() => User) admin!: User
  ticketCommentState!: TicketCommentState
  ticketCommentBody!: string
  @Type(() => Material) material?: Material
  postedAt!: string
  version!: number
  // 編集用の項目
  isEditMode = false
}

export class TicketCommentsGetResponse {
  @Type(() => TicketComment) ticketComments: TicketComment[] = []
  skipToken!: string
}
