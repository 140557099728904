export class TargetBuilding {
  buildingId!: string
  buildingName!: string
}

export class TargetOwner {
  userId!: string
  userName!: string
  roomNumber!: string
}
