

























import { Vue, Component, Prop, Model } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmCheckbox extends Vue {
  // labelスロットにカスタムコンポーネントのみを設定すると描画されないため、空文字の力を借りる
  private emptyDummyString = ''

  @Prop()
  @Model('change')
  private readonly check!: boolean | string[] | number[]

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly indeterminate!: boolean

  @Prop()
  private readonly value?: string | number | boolean

  @Prop()
  private readonly label?: string

  private get _check(): boolean | string[] | number[] { return this.check }
  private set _check(newValue: boolean | string[] | number[]) { this.$emit('change', newValue) }
}
