import { Material } from '@/dtos/commons'
import { Subject } from '@/dtos/resolutions/gm-resolution/results/commons'

export class ResultsPostRequest {
  resolutionId!: string
  resultComment!: string
  material?: Material
  subjects!: Subject[]
  version!: number // post処理だがversionを使用する（"決議結果"の登録 ≒ "決議"の更新になるため、"決議" に対するversionチェックを行う）
}
