












































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import { HOLDING_METHOD_TYPE, RESOLUTION_STATES } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'

import { getResolutionLabel } from '@/libs/state-handler'
import { windowOpen } from '@/libs/window-open'

import { staticRoutes } from '@/routes'

import { GMResolutionPreviewContent, newTabLocalParamStorageModule } from '@/stores/new-tab-local-param-storage-store'

@Component({
  components: {
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmOwBtnContained: () => import('@/components/owner-apps/SmOwBtnContained.vue'),
    SmOwBtnText: () => import('@/components/owner-apps/SmOwBtnText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class GMResolutionPreviewPage extends Vue {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)
  HOLDING_METHOD_TYPE = Object.freeze(HOLDING_METHOD_TYPE)

  @Prop({ default: '' })
    private readonly gmResolutionPreviewId!: string

  private get resolutionDetail(): GMResolutionPreviewContent { return newTabLocalParamStorageModule.gmResolutionPreviewContent(this.gmResolutionPreviewId) ?? new GMResolutionPreviewContent() }

  private get holdingAt(): string {
    return `${this.resolutionDetail.holdingAt} ${this.resolutionDetail.holdingDate}`
  }

  private get resolutionLabel(): string {
    return getResolutionLabel(this.resolutionDetail.resolutionState)
  }

  private get isAcceptingDeclare(): boolean {
    return this.resolutionDetail.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL || this.resolutionDetail.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT || this.resolutionDetail.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.DRAFT
  }

  private get questionAreaText(): { guide: string, btnLabel: string } {
    if (this.resolutionDetail.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL || this.resolutionDetail.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.DRAFT) {
      return { guide: '議案についてわからないことがあれば質問できます。', btnLabel: '質問をする・見る' }
    }
    return { guide: '議案についての質問が確認できます。', btnLabel: '質問を見る' }
  }

  openMeetingUrl(): void { /* nothing to do */ }

  openPresentConfirmDialog(): void { /* nothing to do */ }

  openVoteModal(): void { /* nothing to do */ }

  gotoBaseIdea(baseIdeaId: string): void {
    const path = staticRoutes.adminIdeaDetailPreview.path.replace(':ideaId', baseIdeaId)
    windowOpen(`${path}/detail?buildingId=${this.resolutionDetail?.currentBuildingId}`)
  }

  goToQuestion(): void {
    if (this.resolutionDetail?.resolutionId && this.resolutionDetail?.isQuestions) {
      const path = this.resolutionDetail ? staticRoutes.gmResolutionDetailPreview.path.replace(':resolutionId', this.resolutionDetail?.resolutionId) : ''
      windowOpen(`${path}/questions?buildingId=${this.resolutionDetail?.currentBuildingId}`)
    }
  }
}
