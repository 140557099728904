













































import { Vue, Component, Prop } from 'vue-property-decorator'
import { User } from '@/dtos/commons'
import { CommentsGetResponseLog } from '@/dtos/repairs/updates/comments/get'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),

    SmTableRepairPlanUpdateDetails: () => import('@/pages/repair-plan/SmTableRepairPlanUpdateDetails.vue'),
  }
})
export default class SmListRepairPlanUpdatesComment extends Vue {
  @Prop()
  private readonly user!: User

  @Prop({ required: true, default: '' })
  private readonly comment!: string

  @Prop()
  private readonly logs?: CommentsGetResponseLog[]

  @Prop({ required: true, default: '' })
  private readonly postedAt!: string

  @Prop({ default: false })
  private readonly isEditable!: boolean
}
