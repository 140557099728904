var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sm-template',{attrs:{"title":"区分所有者検索","top-left-icon":"","is-no-max-width":""},scopedSlots:_vm._u([(_vm.$isServiceStaff)?{key:"actions",fn:function(){return [_c('sm-btn',{attrs:{"type":"secondary","text":"CASYSとの差分を確認する"},on:{"click-btn":_vm.goToCasysResultsListPage}})]},proxy:true}:null],null,true)},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"sm__background-white px-6 py-4 no-gutters align-center"},[_c('v-col',{staticStyle:{"width":"422px"},attrs:{"cols":"auto"}},[_c('sm-text-field',{attrs:{"placeholder":"氏名／住戸番号／MaNo.／マンション名／メールアドレスで検索","type":"text","counter":100},model:{value:(_vm.inputKeyword),callback:function ($$v) {_vm.inputKeyword=$$v},expression:"inputKeyword"}})],1),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"auto"}},[_c('sm-btn',{attrs:{"type":"primary-flex","text":"検索","disabled":invalid},on:{"click-btn":function($event){return _vm.onClickSearchBtn()}}})],1),_c('v-col',{staticClass:"ml-4",attrs:{"cols":"auto"}},[(_vm.$isFrontPerson)?_c('sm-switch',{attrs:{"label":"自分が担当しているマンションのみ"},on:{"change":function($event){return _vm.onClickIsStaffSwitch()}},model:{value:(_vm.isStaff),callback:function ($$v) {_vm.isStaff=$$v},expression:"isStaff"}}):_vm._e()],1)],1)]}}])}),_c('div',{staticClass:"sm__background-white px-6 pb-4"},[_c('sm-table-data-external-paging',{attrs:{"headers":_vm.tableHeaders,"items":_vm.users,"item-key":"ownership_summary_id","content-name":"区分所有者情報","disable-row-click":"","custom-sorts":_vm.customSorts,"items-per-page":_vm.take,"total-item-length":_vm.userCount,"page":_vm.pageNum,"is-init-search":_vm.isInitSearch,"no-results-message":"キーワードや条件を入力して、検索してください。"},on:{"change-page":function($event){return _vm.onClickPageBtn($event)}},scopedSlots:_vm._u([{key:"item.buildingName",fn:function(ref){
var item = ref.item;
return [_c('sm-btn-text',{attrs:{"text":item.buildingName,"right-icon":"$open_in_new_outlined"},on:{"click":function($event){return _vm.goBuildingDetail(item.buildingId)}}})]}},{key:"item.roomNumber",fn:function(ref){
var item = ref.item;
return [_c('sm-btn-text',{attrs:{"text":item.roomNumber,"right-icon":"$open_in_new_outlined"},on:{"click":function($event){return _vm.goUserDetail(item.buildingId, item.buildingUnitId)}}})]}},{key:"item.emailAddress",fn:function(ref){
var item = ref.item;
return [_c('sm-text',{attrs:{"text":item.emailAddress,"format":"sm__data","color":"black"}})]}},{key:"item.isInitialAuth",fn:function(ref){
var item = ref.item;
return [((_vm.$isServiceStaff || _vm.$isFrontPerson || _vm.$isGroupCallCenter) && !item.isBuildingOutOfService)?_c('div',{staticClass:"d-flex align-center"},[_c('sm-btn-text',{attrs:{"text":item.isInitialAuth ? '利用中画面を見る' : "利用中画面を見る\n（アプリ未登録）","disabled":!item.isInitialAuth},on:{"click":function($event){return _vm.openSimulateConfirmationDialog(item.buildingUnitId)}}}),_c('v-icon',{class:['sm__btn-text ml-2', !item.isInitialAuth ? 'sm__btn-text--disabled' : ''],attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openSimulateConfirmationDialog(item.buildingUnitId)}}},[_vm._v(" $open_in_new_outlined ")])],1):_vm._e()]}}],null,true)})],1),_c('sm-dialog-simulate-confirmation',{on:{"execute":function($event){return _vm.startSimulate()},"close":function($event){_vm.isSimulateConfirmationDialogVisible = false}},model:{value:(_vm.isSimulateConfirmationDialogVisible),callback:function ($$v) {_vm.isSimulateConfirmationDialogVisible=$$v},expression:"isSimulateConfirmationDialogVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }