














































































































































import { Vue, Mixins, Component, Prop, Watch } from 'vue-property-decorator'
import { NavigationGuardNext, Route } from 'vue-router/types'

import { IDEA_TYPES } from '@/constants/schema-constants'
import { DAY_OF_WEEK } from '@/constants/ux-constants'

import { MaterialFormInputDto, User } from '@/dtos/commons'
import { OwnerIdeaDetailGetRequest, OwnerIdeaDetailGetResponse } from '@/dtos/ideas/owner-idea/get-detail'
import { OwnerIdeasPostRequest, OwnerUser } from '@/dtos/ideas/owner-idea/post'
import { OwnerIdeasDraftPostRequest } from '@/dtos/ideas/owner-idea/draft/post'
import { Owner, OwnersGetRequest } from '@/dtos/owners/get'

import { FileUploader, uploadMaterial } from '@/libs/file-uploader'
import { generateUuid } from '@/libs/uuid-generator'
import { windowOpen } from '@/libs/window-open'

import { CurrentAdminManager } from '@/mixins/current-admin-manager'

import { staticRoutes } from '@/routes'

import { newTabLocalParamStorageModule, OwnerIdeaPreviewContent } from '@/stores/new-tab-local-param-storage-store'
import { ownerIdeasModule } from '@/stores/owner-ideas-store'
import { ownersModule } from '@/stores/owners-store'

class FormInputs {
  title = ''
  background = ''
  material: MaterialFormInputDto | null = null
  materialCaption: string | null = null
  ownerUser!: User
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmDraftInterceptor: () => import('@/components/organisms/SmDraftInterceptor.vue'),
    OwnerUsersSelectModal: () => import('@/pages/ideas/OwnerUsersSelectModal.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class OwnerIdeaRegisterPage extends Mixins(CurrentAdminManager) {
  IDEA_TYPES = Object.freeze(IDEA_TYPES)

  @Prop()
  private readonly ideaId?: string

  async created(): Promise<void> {
    if (this.ideaId) {
      const req = new OwnerIdeaDetailGetRequest()
      req.ideaId = this.ideaId
      await ownerIdeasModule.fetchIdeaDetail(req)
    }
  }

  inputs = new FormInputs()

  private get initialInputs(): OwnerIdeaDetailGetResponse | undefined { return ownerIdeasModule.detailResponse(this.ideaId ?? '') }
  @Watch('initialInputs', { immediate: false, deep: false })
  onInitialInputsChange(newValue: OwnerIdeaDetailGetResponse): void {
    this.inputs = {
      title: newValue.title,
      background: newValue.background,
      material: newValue.material ? Object.assign(new MaterialFormInputDto(), newValue.material) : null,
      materialCaption: newValue.material?.caption ?? null,
      ownerUser: newValue.ownerUser,
    }
  }

  nextRoute: Route | null = null
  processCompleted = false
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (this.processCompleted || this.nextRoute) {
      next()
    } else {
      this.nextRoute = to
      next(false)
    }
  }

  leaveHere(): void {
    const routeName = this.nextRoute?.name
    const routeParams = this.nextRoute?.params
    if (routeName) this.$router.push({ name: routeName, params: routeParams })
  }

  isOwnerUsersSelectModalVisible = false
  ownerUsersSelectModalKey = generateUuid()
  async openOwnerUsersSelectModal(): Promise<void> {
    const req = new OwnersGetRequest()
    req.isInitialAuth = false
    await ownersModule.fetchOwners(req)
    this.ownerUsersSelectModalKey = generateUuid()
    this.isOwnerUsersSelectModalVisible = true
  }

  keyword = ''
  inputText=''
  setSelectedOwnerUser(selectedUser: Owner): void {
    this.$set(this.inputs, 'ownerUser', selectedUser)
  }

  isPostDialogVisible = false
  openPostDialog(): void {
    this.isPostDialogVisible = true
  }

  isPostDraftDialogVisible = false
  openPostDraftDialog(): void {
    this.isPostDraftDialogVisible = true
  }

  // 素材IDと取得済み参照用URLを含む素材情報の組み合わせを保持
  materialReferenceURLMap: Map<string, MaterialFormInputDto> = new Map()

  async openOwnerIdeaPreview(): Promise<void> {
    const previewContents: OwnerIdeaPreviewContent = new OwnerIdeaPreviewContent()
    previewContents.title = this.inputs.title
    previewContents.background = this.inputs.background
    previewContents.ideaType = IDEA_TYPES.OWNER_IDEA
    previewContents.readCount = '0'
    previewContents.ownerUser = this.inputs.ownerUser
    previewContents.postedAt = this.nowDate()

    if (this.inputs.material) {
      if (!this.inputs.material.materialId) { // 新たに添付した素材の場合
        // ローカルストレージのサイズの制約上、プレビュー時に素材をそのまま別タブに渡すのが難しいため、ここでアップロードする
        const uploadedMaterial = await uploadMaterial(this.inputs.material)
        if (uploadedMaterial) {
          this.materialReferenceURLMap.set(uploadedMaterial.materialId, uploadedMaterial)
          // 画面に表示中の素材データにもIDを格納することで投稿／編集時に素材を再登録しなくて済むようにする
          this.inputs.material.materialId = uploadedMaterial.materialId
          previewContents.material = uploadedMaterial
        }
      } else if (this.materialReferenceURLMap.get(this.inputs.material.materialId)) { // 一度プレビューした素材を付け替えずに使用する場合
        previewContents.material = this.materialReferenceURLMap.get(this.inputs.material.materialId)
        // ファイル名の更新を反映
        if (previewContents.material) previewContents.material.originalFileName = this.inputs.material.originalFileName
      } else { // すでにDBに登録済みの素材を画面初期表示から一度も付け替えずに使用する場合
        previewContents.material = this.inputs.material
      }
      if (previewContents.material) previewContents.material.caption = this.inputs.materialCaption ?? undefined
    }

    const previewContentsId = generateUuid()
    newTabLocalParamStorageModule.setOwnerIdeaPreviewContent({ key: previewContentsId, ownerIdeaPreviewContent: previewContents })
    windowOpen(staticRoutes.ownerIdeaPreview.path.replace(':id', previewContentsId))
  }

  private nowDate(): string {
    const date = new Date()
    const numberOfDate = date.getDay()
    const dayOfWeek = DAY_OF_WEEK[numberOfDate]
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日(${dayOfWeek})`
  }

  async postOwnerIdea(isDraft: boolean): Promise<void> {
    this.isPostDraftDialogVisible = false
    this.isPostDialogVisible = false
    const reqOwnerUser = new OwnerUser(this.inputs.ownerUser.userId)
    if (this.inputs.material) {
      if (this.inputs.materialCaption) this.inputs.material.caption = this.inputs.materialCaption
    }

    if (isDraft) {
      const req = new OwnerIdeasDraftPostRequest(this.inputs.title, this.inputs.background, reqOwnerUser, this.ideaId, this.inputs.material)
      await this._prepareThenPost(req, ownerIdeasModule.postDraftIdeas)
    } else {
      const req = new OwnerIdeasPostRequest(this.inputs.title, this.inputs.background, reqOwnerUser, this.ideaId, this.inputs.material)
      await this._prepareThenPost(req, ownerIdeasModule.postIdeas)
    }

    this.processCompleted = true
    this.$router.push({ name: staticRoutes.ideasList.name })
  }

  private async _prepareThenPost<T>(rawReq: T, exec: (req: T) => Promise<void>) {
    const uploader = new FileUploader()
    const req = await uploader.prepare(rawReq)

    await exec(req)
  }

  isDeleteDraftDialogVisible = false
  openDeleteDraftDialog(): void {
    this.isDeleteDraftDialogVisible = true
  }

  async deleteDraft(): Promise<void> {
    this.isDeleteDraftDialogVisible = false
    if (!this.ideaId) return

    await ownerIdeasModule.deleteDraftIdeas(this.ideaId)

    this.processCompleted = true
    this.$router.push({ name: staticRoutes.ideasList.name })
  }
}
