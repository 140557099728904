




























































import { Vue, Component, Model, Prop } from 'vue-property-decorator'

const btnTypes = [
  'text',
  'button'
] as const
type BtnType = typeof btnTypes[number]

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})
export default class SmMenu extends Vue {
  @Prop({
    default: 'text',
    validator: m => btnTypes.includes(m)
  })
  private readonly menuBtnType!: BtnType

  @Prop({ default: '$sort_round' })
  private readonly icon!: string

  @Model('change')
  private readonly menuId!: string

  @Prop({ required: true, default: () => ({}) })
  private readonly menuItems!: {[id: string]: { text: string, label: string | undefined }}

  @Prop()
  private readonly nonSelectedLabel?: string

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: 'secondary' })
  private readonly color!: string

  private get menuLabel() : string | undefined {
    return this.menuItems[this._id]?.label ?? this.nonSelectedLabel
  }

  private get _id(): string { return this.menuId }
  private set _id(newValue: string) {
    this.$emit('change', newValue)
  }
}
