import type { ChartType } from '@/constants/schema-constants'
import { Material, MaterialFormInputDto } from '@/dtos/commons'
import { Type } from 'class-transformer'

export class ChoiceForPreview {
  questionnaireQuestionElementId!: string
  name!: string
  count!: number
}

export class VoteResultForPreview {
  label!: string
  @Type(() => ChoiceForPreview) choices!: ChoiceForPreview[]
}

export class QuestionForPreview {
  sortOrderNum!: number
  questionSentence!: string
  chartType!: ChartType
  @Type(() => VoteResultForPreview) currentVoteResult!: VoteResultForPreview
  @Type(() => VoteResultForPreview) comparisonVoteResult!: VoteResultForPreview[]
}

export class ImplementationResultForPreview {
  title?: string
  explanation?: string
  @Type(() => QuestionForPreview) questions?: QuestionForPreview[]
  @Type(() => Material) material?: Material | null = null
}

export class ResultForPreview {
  title?: string
  explanation?: string
  @Type(() => MaterialFormInputDto) material?: MaterialFormInputDto
}

export class OpinionForPreview {
  ownerFreeAnswers!: string[]
  adminAnswer!: string
}
