import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyInitialized, store } from './index'
import { myProfileClient } from '@/clients/my-profile-client'
import { MyProfileGetResponse } from '@/dtos/my-profile/get'
import { MyProfilePutRequest } from '@/dtos/my-profile/put'

@Module({ store, dynamic: true, namespaced: true, name: 'myProfile', preserveState: isAlreadyInitialized })
class MyProfileStore extends VuexModule {
  private _myProfileGet: MyProfileGetResponse | null = null

  get myProfileGet(): MyProfileGetResponse | null {
    return this._myProfileGet
  }

  @Mutation
  private SET_MY_PROFILE_GET(res: MyProfileGetResponse): void {
    this._myProfileGet = res
  }

  @Mutation
  private CLEAR_MY_PROFILE(): void {
    this._myProfileGet = null
  }

  @Mutation
  private UPDATE_ICON_PATH(path?: string): void {
    if (this._myProfileGet) this._myProfileGet.user.iconImagePath = path
  }

  @Action
  async fetchMyProfile(): Promise<void> {
    const res = await myProfileClient.getMyProfile()
    this.SET_MY_PROFILE_GET(res)
  }

  @Action
  async updateProfile({ req, newIconPath }: { req: MyProfilePutRequest, newIconPath?: string }): Promise<void> {
    await myProfileClient.putMyProfile(req)
    this.UPDATE_ICON_PATH(newIconPath)
  }

  @Action
  clear() {
    this.CLEAR_MY_PROFILE()
  }
}

export const myProfileModule = getModule(MyProfileStore)
