import { Type } from 'class-transformer'
import { Material, User } from '../commons'

export class ConsultationGroupsGetRequest {
  userId: string | null = null
  keyword: string | null = null
  skip!: number
  take!: number
}

export class ConsultationGroup {
  isUnread!: boolean
  hasDraft!: boolean
  @Type(() => User) ownerUser = new User()
  message?: string
  @Type(() => Material) material?: Material
  postedAt?: string
  reactions!: string[]
  @Type(() => User) admin?: User
}

export class ConsultationGroupsGetResponse {
  @Type(() => ConsultationGroup) consultationGroups: ConsultationGroup[] = []
}
