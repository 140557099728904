import { AdminIdeaPreparingStatusPostRequest, AdminIdeaPreparingStatusPostResponse } from './post'

export class AdminIdeaPreparingStatusPutRequest extends AdminIdeaPreparingStatusPostRequest {
  version!: string

  constructor(
    ideaId: string,
    preparingStatusBody: string,
    version: string
  ) {
    super(ideaId, preparingStatusBody)
    this.version = version
  }
}

export class AdminIdeaPreparingStatusPutResponse extends AdminIdeaPreparingStatusPostResponse {
}
