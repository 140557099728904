






























import { windowOpen } from '@/libs/window-open'
import { Vue, Component, Prop } from 'vue-property-decorator'
import type { Color, Format, Level } from '@/components/atoms/SmText.vue'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmOwTextLink: () => import('@/components/owner-apps/SmOwTextLink.vue'),
  }
})
export default class SmOwTextHyperLinked extends Vue {
  @Prop()
  private text!: string

  @Prop()
  private format?: Format

  @Prop()
  private color?: Color

  @Prop()
  private level?: Level

  private get dividedText(): { type: 'text'|'url', text: string, url?: string }[] {
    try {
      if (!this.text) return []

      const pattern = /\[([^[\]]+)\]\((https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9]{1,6}([-a-zA-Z0-9@:%_+.~#?&/=]*))\)|https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)(?=$|\s)/g
      const results: { type: 'text'|'url', text: string, url?: string }[] = []
      let currentIndex = 0

      for (const match of this.text.matchAll(pattern)) {
      // 現在位置からURL開始位置までの文字列を'text'として記録
        if (currentIndex !== match.index) {
          results.push({ type: 'text', text: this.text.substring(currentIndex, match.index) })
          currentIndex += (match.index ?? 0) - currentIndex
        }

        // URL部分を'url'として記録し、現在位置をその終端まで進める
        // 第2,3キャプチャグループが存在すればパターン1、でなければパターン2
        results.push({ type: 'url', text: match[1] ?? match[0], url: match[2] ?? match[0] })
        currentIndex += match[0].length
      }

      // 最後に、末尾の通常文字列を'text'として記録（マッチ0だった場合も含む）
      if (currentIndex !== this.text.length) results.push({ type: 'text', text: this.text.substring(currentIndex, this.text.length) })

      return results
    } catch { // support iOS 12.x or lower: https://caniuse.com/mdn-javascript_builtins_string_matchall
      return [{ type: 'text', text: this.text }]
    }
  }

  openNew(url: string): void {
    windowOpen(url)
  }
}
