
















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class SmCardSectionMoney extends Vue {
  @Prop({ default: false })
  private readonly head!: boolean

  @Prop({ default: false })
  private readonly foot!: boolean

  @Prop({ default: false })
  private readonly deletable!: boolean

  @Prop({ default: '' })
  private readonly fieldIdPrefix!: string

  @Prop()
  private readonly budgetLabel?: string

  @Prop()
  private readonly budget?: number

  @Prop()
  private readonly expenseLabel?: string

  @Prop()
  private readonly expense?: number

  @Prop({ default: null })
  private readonly spentLabel!: string | null

  @Prop()
  private readonly spent?: number

  @Prop()
  private readonly balanceLabel?: string

  private get _budgetLabel(): string | undefined { return this.budgetLabel }
  private set _budgetLabel(newValue: string | undefined) { this.$emit('update:budgetLabel', newValue) }

  private get _budget(): number | undefined { return this.budget }
  private set _budget(newValue: number | undefined) { this.$emit('update:budget', newValue) }

  private get _expenseLabel(): string | undefined { return this.expenseLabel }
  private set _expenseLabel(newValue: string | undefined) { this.$emit('update:expenseLabel', newValue) }

  private get _expense(): number | undefined { return this.expense }
  private set _expense(newValue: number | undefined) { this.$emit('update:expense', newValue) }

  private get _spentLabel(): string | null { return this.spentLabel }
  private set _spentLabel(newValue: string | null) { this.$emit('update:spentLabel', newValue === '' ? null : newValue) }

  private get _spent(): number | undefined { return this.spent }
  private set _spent(newValue: number | undefined) { this.$emit('update:spent', newValue) }

  private get _balanceLabel(): string | undefined { return this.balanceLabel }
  private set _balanceLabel(newValue: string | undefined) { this.$emit('update:balanceLabel', newValue) }

  // 予算残高を表示する。予算額・発注額のいずれかが未入力か、予算残高が0円未満のときは、'￥--'を表示する。
  private get balance():string {
    if (this.budget === undefined) return '￥--'
    if (this.expense === undefined) return '￥--'
    const balance: number = this.budget - this.expense - (this.spent ?? 0)

    if (balance < 0) return '￥--'
    else return '￥' + balance
  }

  private get isWithinBudget(): boolean {
    // budget、expenseは入力必須項目のため、未入力の場合は必須チェックの方でエラーを出す
    if (this._budget === undefined || this._expense === undefined) return true

    const spent = (this._spent === undefined ? 0 : this._spent)
    return this._budget >= (this._expense + spent)
  }

  private get shouldHideFieldErrorArea(): boolean | string {
    return !this.isWithinBudget ? true : 'auto'
  }
}
