









































































































































































































import { Menu } from '@/components/owner-apps/SmOwMenu.vue'

import { Material } from '@/dtos/commons'

import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmOwListUser: () => import('@/components/owner-apps/SmOwListUser.vue'),
  }
})
export default class SmOwCardOpinion extends Vue {
  @Prop({ required: true })
  private userIconImagePath!: string

  @Prop({ required: true })
  private userName!: string

  @Prop()
  private userRoom?: string

  @Prop()
  private menus?:Menu[]

  @Prop({ default: false })
  private isAlternative!: boolean

  @Prop()
  private comment?: string

  @Prop()
  private alternativeIdeaTitle?: string

  @Prop()
  private alternativeIdeaBackground?: string

  @Prop({ default: false })
  private hyperLinked!: boolean

  @Prop()
  private material?: Material

  @Prop({ required: true })
  private date!: string

  @Prop()
  private opinionCommentBody?: string

  @Prop()
  private opinionCommentUserIconImagePath?: string

  @Prop()
  private opinionCommentUserName?: string

  @Prop()
  private opinionCommentPostedAt?: string

  clickMenu(id:string):void {
    this.$emit('click-menu', id)
  }
}

