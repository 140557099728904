





















































































































































































































































































import { Mixins, Component, Prop, Watch } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { FileUploader } from '@/libs/file-uploader'
import { getDeletedMessage } from '@/libs/opinion-state-handler'
import { generateUuid } from '@/libs/uuid-generator'

import type { OpinionState } from '@/constants/schema-constants'
import { IDEA_STATES, OPINION_STATES } from '@/constants/schema-constants'
import { ERROR_MESSAGES } from '@/constants/ux-constants'

import { adminIdeasModule, AdminIdeaCommonState } from '@/stores/admin-ideas-store'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { myProfileModule } from '@/stores/my-profile-store'
import { ownersModule } from '@/stores/owners-store'
import { structureModule } from '@/stores/structure-store'

import { Material, User } from '@/dtos/commons'
import { Details } from '@/dtos/ideas/admin-idea/commons'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { AdminIdeaOpinionsGetRequest, AdminIdeaOpinionsGetResponse, Opinion } from '@/dtos/ideas/admin-idea/opinions/get'
import { AdminIdeasOpinionsDeleteRequest } from '@/dtos/ideas/admin-idea/opinions/delete'
import { AdminIdeaOpinionsPostRequest } from '@/dtos/ideas/admin-idea/opinions/post'
import { OpinionPostFormInput } from '@/pages/ideas/admin-idea/admin-idea-detail/opinions/OpinionPostModal.vue'
import { AdminIdeasOpinionsCommentsPostRequest } from '@/dtos/ideas/admin-idea/opinions/comments/post'
import { AdminIdeasOpinionsCommentsPutRequest } from '@/dtos/ideas/admin-idea/opinions/comments/put'
import { AdminIdeasOpinionsCommentsDeleteRequest } from '@/dtos/ideas/admin-idea/opinions/comments/delete'
import { OwnersGetRequest } from '@/dtos/owners/get'

import { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'

const TAKE = 20

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmModeSwitchableForm: () => import('@/components/molecules/SmModeSwitchableForm.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    OpinionPostModal: () => import('@/pages/ideas/admin-idea/admin-idea-detail/opinions/OpinionPostModal.vue'),
  }
})
export default class OpinionsSubPage extends Mixins(CurrentAdminManager) {
  OPINION_STATES = OPINION_STATES
  IDEA_STATES = Object.freeze(IDEA_STATES)

  @Prop()
  ideaId!: string

  @Prop()
  buildingId?: string

  async created(): Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    this.request.ideaId = this.ideaId
    this.request.take = TAKE
    this.request.isAlternative = this.isAlternative
    this.request.isAvailable = this.isAvailable

    this.refreshList()
  }

  displayingOpinions: Opinion[] = []
  skipToken: string | undefined = undefined

  private get isPreview(): boolean { return structureModule.isPreview }

  private get ideaOpinions(): AdminIdeaOpinionsGetResponse { return adminIdeasModule.adminIdeasOpinionsGet(this.ideaId) ?? new AdminIdeaOpinionsGetResponse() }

  async fetchDisplayingOpinions(): Promise<void> {
    await adminIdeasModule.fetchIdeaOpinions(this.request)

    const copiedOpinions = deepCopy(
      this.ideaOpinions.opinions,
      {
        details: new ColumnToType(Details),
        user: new ColumnToType(User),
        opinions: new ColumnToType(Opinion),
        ownerUser: new ColumnToType(User),
        material: new ColumnToType(Material),
      },
      'opinions'
    )

    this.skipToken = this.ideaOpinions.skipToken
    this.displayingOpinions.push(...copiedOpinions)
  }

  updateExistingDisplayingOpinions():void {
    const copiedOpinions = deepCopy(
      this.ideaOpinions.opinions,
      {
        details: new ColumnToType(Details),
        user: new ColumnToType(User),
        opinions: new ColumnToType(Opinion),
        ownerUser: new ColumnToType(User),
        material: new ColumnToType(Material),
      },
      'opinions'
    )
    this.displayingOpinions = copiedOpinions
  }

  identifier = 1
  request = new AdminIdeaOpinionsGetRequest()
  isWaitingSwitch = false
  private async loadOpinions(handler: LoadingHandler): Promise<void> {
    this.isWaitingSwitch = true

    this.request.skipToken = this.skipToken
    const toBeSkipped = this.displayingOpinions.length
    await this.fetchDisplayingOpinions()

    // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
    if (this.displayingOpinions.length === 0) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = false
    handler.loaded()
    const expectingToBe = toBeSkipped + this.request.take
    if (this.displayingOpinions.length < expectingToBe) handler.complete()
  }

  isAlternative = true
  isAvailable = true
  showDeleteDialog = false
  isProxyPostModalVisible = false
  targetOpinionId = ''

  private get isSearchAll(): boolean {
    return !this.isAlternative && !this.isAvailable
  }

  private getDeletedMessage(state: OpinionState):string | undefined {
    return getDeletedMessage(state)
  }

  private get ideaCommon(): AdminIdeaCommonState {
    return adminIdeasModule.adminIdeaCommonStateGet ?? new AdminIdeaCommonState()
  }

  private get isDeleteable(): boolean {
    return this.$isServiceStaff &&
          (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION ||
          this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT ||
          this.ideaCommon.ideaState === IDEA_STATES.ADMIN.COUNTING)
  }

  private get canPost(): boolean {
    return this.$isServiceStaff &&
    (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION ||
    this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT ||
    this.ideaCommon.ideaState === IDEA_STATES.ADMIN.COUNTING)
  }

  private get hasOpinion(): boolean {
    return this.ideaOpinions.opinions.length > 0
  }

  private refreshList(): void {
    this.displayingOpinions = []
    this.skipToken = undefined
    this.identifier++
  }

  private onChangeOnlyAlternativeSwitch(newValue: boolean): void {
    this.request.isAlternative = newValue
    this.refreshList()
  }

  private onChangeOnlyAvailableSwitch(newValue: boolean): void {
    this.request.isAvailable = newValue
    this.refreshList()
  }

  private onClickOpinionDelete(opinionId: string): void {
    if (this.isPreview) return
    this.targetOpinionId = opinionId
    this.showDeleteDialog = true
  }

  private async deleteOpinion(): Promise<void> {
    const req = new AdminIdeasOpinionsDeleteRequest()
    req.ideaId = this.ideaOpinions.ideaId
    req.opinionId = this.targetOpinionId

    this.showDeleteDialog = false
    await adminIdeasModule.deleteIdeaOpinions(req)
    this.refreshList()
  }

  async openProxyPostModal(): Promise<void> {
    if (this.isPreview) return
    const req = new OwnersGetRequest()
    req.isInitialAuth = false
    await ownersModule.fetchOwners(req)

    this.isProxyPostModalVisible = true
  }

  async postOpinion(op: OpinionPostFormInput): Promise<void> {
    const rawReq = new AdminIdeaOpinionsPostRequest()
    rawReq.ideaId = this.ideaId
    if (op.isAlternative) {
      rawReq.alternativeIdeaTitle = op.alternativeIdeaTitle
      rawReq.alternativeIdeaBackground = op.alternativeIdeaBackground
    } else {
      rawReq.body = op.body
    }
    rawReq.isAlternative = op.isAlternative
    rawReq.ownerUser = op.user
    rawReq.material = op.material
    const uploader = new FileUploader()
    const req = await uploader.prepare(rawReq)

    await adminIdeasModule.postOpinion(req)
    this.isProxyPostModalVisible = false

    this.refreshList()
  }

  // 意見コメント周り
  commentKey = generateUuid()
  refreshCommentComponent():void {
    this.commentKey = generateUuid()
  }

  isPostCommentDialogVisible = false
  isPutCommentDialogVisible = false
  isDeleteCommentDialogVisible = false
  commentTargetOpinionId = ''
  commentBody = ''

  get isOpinionCommentEditable():boolean {
    return (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT ||
    this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION ||
    this.ideaCommon.ideaState === IDEA_STATES.ADMIN.COUNTING) && this.$isServiceStaff
  }

  openPostCommentDialog(commentBody:string, commentTargetOpinionId:string):void {
    if (this.isPreview) return
    this.commentBody = commentBody
    this.commentTargetOpinionId = commentTargetOpinionId
    this.isPostCommentDialogVisible = true
  }

  closePostCommentDialog():void {
    this.isPostCommentDialogVisible = false
  }

  async postComment():Promise<void> {
    const req = new AdminIdeasOpinionsCommentsPostRequest()
    req.ideaId = this.ideaId
    req.opinionId = this.commentTargetOpinionId
    req.commentBody = this.commentBody
    this.closePostCommentDialog()
    await adminIdeasModule.postAdminIdeasOpinionsComment(req)
    this.refreshCommentComponent()
    this.updateExistingDisplayingOpinions()
  }

  openPutCommentDialog(commentBody:string, commentTargetOpinionId:string):void {
    if (this.isPreview) return
    this.commentBody = commentBody
    this.commentTargetOpinionId = commentTargetOpinionId
    this.isPutCommentDialogVisible = true
  }

  closePutCommentDialog():void {
    this.isPutCommentDialogVisible = false
  }

  async putComment():Promise<void> {
    const req = new AdminIdeasOpinionsCommentsPutRequest()
    const opinionComment = this.ideaOpinions.opinions.find(e => e.opinionId === this.commentTargetOpinionId)?.opinionComment
    if (!opinionComment || !opinionComment.opinionCommentId || !opinionComment.version) return
    req.ideaId = this.ideaId
    req.opinionId = this.commentTargetOpinionId
    req.opinionCommentId = opinionComment.opinionCommentId
    req.version = opinionComment.version
    req.commentBody = this.commentBody
    this.closePutCommentDialog()
    // 項目エラー発生時、当該項目に対してエラーを表示するための設定
    errorsModule.presetResponseFieldErrorPrefix(`opinions[${this.commentTargetOpinionId}]`)
    await adminIdeasModule.putAdminIdeasOpinionsComment(req)
    this.refreshCommentComponent()
    this.updateExistingDisplayingOpinions()
  }

  openDeleteCommentDialog(commentTargetOpinionId:string):void {
    if (this.isPreview) return
    this.commentTargetOpinionId = commentTargetOpinionId
    this.isDeleteCommentDialogVisible = true
  }

  closeDeleteCommentDialog():void {
    this.isDeleteCommentDialogVisible = false
  }

  async deleteComment():Promise<void> {
    const req = new AdminIdeasOpinionsCommentsDeleteRequest()
    const opinionComment = this.ideaOpinions.opinions.find(e => e.opinionId === this.commentTargetOpinionId)?.opinionComment
    if (!opinionComment || !opinionComment.opinionCommentId) return
    req.ideaId = this.ideaId
    req.opinionId = this.commentTargetOpinionId
    req.opinionCommentId = opinionComment.opinionCommentId
    this.closeDeleteCommentDialog()
    await adminIdeasModule.deleteAdminIdeasOpinionsComment(req)
    this.refreshCommentComponent()
    this.updateExistingDisplayingOpinions()
  }

  private get hasError(): string[] { return errorsModule.globalErrors }

  @Watch('hasError')
  scrollTop(hasError: string[]): void {
    if (!hasError.length) return
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }
}
