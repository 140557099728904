import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { ideasClient } from '@/clients/ideas-client'
import { IdeasGetRequest, IdeasGetResponse, ListResponseIdeaDto } from '@/dtos/ideas/get'
import { IdeasReadsPostRequest } from '@/dtos/ideas/reads/post'

@Module({ store, dynamic: true, namespaced: true, name: 'ideasStore' })
class IdeasStore extends VuexModule {
  private _ideas: ListResponseIdeaDto[] = []
  private _skipToken: string | null = null

  get ideas(): ListResponseIdeaDto[] {
    return this._ideas
  }

  get skipToken(): string | null {
    return this._skipToken
  }

  @Mutation
  private PUSH_IDEAS(res: IdeasGetResponse): void {
    this._ideas.push(...res.ideas)
    this._skipToken = res.skipToken
  }

  @Mutation
  private CLEAR_IDEAS(): void {
    this._ideas = []
    this._skipToken = null
  }

  @Action
  async fetchIdeas(req: IdeasGetRequest): Promise<void> {
    const res = await ideasClient.getIdeas(req)
    this.PUSH_IDEAS(res)
  }

  @Action
  clearFetchedIdea(): void {
    this.CLEAR_IDEAS()
  }

  @Action
  async readIdea(req: IdeasReadsPostRequest): Promise<void> {
    await ideasClient.postIdeasReads(req)
  }
}

export const ideasModule = getModule(IdeasStore)
