
















import { Tab } from '@/components/molecules/SmTabs.vue'
import { staticRoutes } from '@/routes'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class TasksPage extends Vue {
  private get tabs(): Tab[] {
    const tabs = [
      new Tab('アイデア・決議・相談', staticRoutes.tasks.getChild('list').name),
      new Tab('稼働準備', staticRoutes.tasks.getChild('onboarding').name),
      new Tab('定例業務', staticRoutes.tasks.getChild('routine').name),
    ]
    return tabs
  }

  currentTab = ''
  private get isNoMaxWidth(): boolean {
    // 稼働準備・定例業務のタブを選択中の場合のみ、表示領域を広げる
    const tabName = this.currentTab.split('/').pop()
    return tabName === staticRoutes.tasks.getChild('onboarding').path || tabName === staticRoutes.tasks.getChild('routine').path
  }
}
