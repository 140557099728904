
























































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
import { QuestionnaireQuestionElementType, QUESTIONNAIRE_QUESTION_ELEMENT_TYPES, QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'
import type { QuestionnaireQuestionType } from '@/constants/schema-constants'
import { QuestionnairesProxyInputQuestionElement } from '@/pages/questionnaires/questionnaire-detail/respondents/QuestionnairesProxyInputTable.vue'
import { assertExhaustive } from '@/libs/exhaustive-helper'

export class QuestionOption {
  questionnaireQuestionElementId :string
  label: string
  value: string | number
  elementType: QuestionnaireQuestionElementType

  constructor(questionnaireQuestionElementId: string, label: string, value: string | number, elementType: QuestionnaireQuestionElementType) {
    this.questionnaireQuestionElementId = questionnaireQuestionElementId
    this.label = label
    this.value = value
    this.elementType = elementType
  }
}
@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCheckboxAnswers: () => import('@/components/molecules/SmCheckboxAnswers.vue'),
    SmRadioButtonAnswers: () => import('@/components/molecules/SmRadioButtonAnswers.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
  }
})
export default class ProxyAnswerEachQuestion extends Vue {
  private QUESTIONNAIRE_QUESTION_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_TYPES)
  private QUESTIONNAIRE_QUESTION_ELEMENT_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_ELEMENT_TYPES)

  @Prop({ required: true, default: QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE })
  private readonly questionType!: QuestionnaireQuestionType

  @Prop({ required: true, default: () => [] })
  @Model('change')
  private readonly candidateAnswers!: QuestionnairesProxyInputQuestionElement[]

  private get _candidateAnswers(): QuestionnairesProxyInputQuestionElement[] { return this.candidateAnswers }
  private set _candidateAnswers(newValue: QuestionnairesProxyInputQuestionElement[]) {
    this.$emit('change', newValue)
  }

  @Prop({ required: true, default: false })
  private readonly requiredAnswerQuestion!: boolean

  @Prop({ required: true, default: '' })
  private readonly fieldId!: string

  @Prop({ required: true })
  private readonly showConfirmPage!: boolean

  @Prop({ required: true })
  private readonly respondentOwnerUserId!: string

  // -------------------共通----------------------

  confirmPageText=''

  @Watch('showConfirmPage')
  private clickNextBtn():void {
    switch (this.questionType) {
      case QUESTIONNAIRE_QUESTION_TYPES.FREE: {
        this.confirmPageText = this.freeText
        if (this.freeText !== '') {
          this._candidateAnswers[0].answeredOwnerUserId = this.respondentOwnerUserId
          this._candidateAnswers[0].questionnaireQuestionElementFreeAnswer = this.freeText
        }
        break
      }
      case QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE: {
        if (this.radioOtherOptionInputText !== '') {
          this.confirmPageText = 'その他：' + this.radioOtherOptionInputText
        } else {
          if (this.selectedRadioOption !== '') { this.confirmPageText = this.radioOptions[Number(this.selectedRadioOption)].label }
        }
        if (this.selectedRadioOption !== '') {
          this._candidateAnswers.forEach(answer => {
            answer.answeredOwnerUserId = undefined
            answer.questionnaireQuestionElementFreeAnswer = undefined
          })
          this._candidateAnswers[Number(this.selectedRadioOption)].answeredOwnerUserId = this.respondentOwnerUserId
          this._candidateAnswers[Number(this.selectedRadioOption)].questionnaireQuestionElementFreeAnswer = this.radioOtherOptionInputText
        }
        break
      }
      case QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES: {
        const sortedCheckboxOptions = this._candidateAnswers.filter((ca, idx) => this.selectedCheckboxOptions.includes(idx))
        const otherOptionIndex = sortedCheckboxOptions.findIndex(a => a.questionnaireQuestionElementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION)
        this._candidateAnswers.forEach(answer => {
          answer.answeredOwnerUserId = undefined
          answer.questionnaireQuestionElementFreeAnswer = undefined
        })
        if (otherOptionIndex !== -1) {
          sortedCheckboxOptions[otherOptionIndex].questionnaireQuestionElementFreeAnswer = this.checkboxOtherOptionInputText
        }
        sortedCheckboxOptions.forEach(sc => {
          this._candidateAnswers.forEach(ca => {
            if (ca.questionnaireQuestionElementId === sc.questionnaireQuestionElementId) {
              ca.answeredOwnerUserId = this.respondentOwnerUserId
              if (sc.questionnaireQuestionElementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION) {
                ca.questionnaireQuestionElementFreeAnswer = this.checkboxOtherOptionInputText
              }
            }
          })
        }
        )
        this.confirmPageText = sortedCheckboxOptions.flatMap(sco => sco.questionnaireQuestionElementChoice).join('/')
        if (this.checkboxOtherOptionInputText) this.confirmPageText = this.confirmPageText + '：' + this.checkboxOtherOptionInputText
        break
      }
      default: assertExhaustive(this.questionType)
    }
  }

  // -------------------単一選択（ラジオボタン）----------------------
  private get radioOptions(): QuestionOption[] {
    return this.candidateAnswers.map((c, idx) => new QuestionOption(c.questionnaireQuestionElementId, c.questionnaireQuestionElementChoice ?? '', idx, c.questionnaireQuestionElementType))
  }

  radioOtherOptionInputText=''
  selectedRadioOption=''

  // -------------------複数選択（チェックボックス）-------------------

  private get checkboxOptions(): QuestionOption[] {
    return this.candidateAnswers.map((c, idx) => new QuestionOption(c.questionnaireQuestionElementId, c.questionnaireQuestionElementChoice ?? '', idx, c.questionnaireQuestionElementType))
  }

  selectedCheckboxOptions: number[] = []
  checkboxOtherOptionInputText=''

  // -------------------自由入力-------------------------------------
  freeText=''
}
