import * as ExcelJs from 'exceljs'
import { ExcelParserBase } from './excel-parser-base'
import { IRepairPlansIOCategoryItem } from '@/dtos/repairs/plans/common'
import { RepairPlanGetResponse } from '@/dtos/repairs/plans/get'

const STATIC_HEADERS = ['大分類', '修繕項目カテゴリ', '修繕項目', '築年数', '小計']
const DATA_START_COL_IDX = STATIC_HEADERS.length // start with 0
const DATA_START_ROW_IDX = 5 // start with 0

class RepairPlanExcelParser extends ExcelParserBase {
  async parse(file: File) {
    const dataRows = await this.fromExcel(file, 0, this._parseValidator)
    if (!dataRows) return undefined

    const planningStartPeriod = Number((dataRows[0][DATA_START_COL_IDX] as string).replace('期', ''))
    const uploadedFileName = file.name

    const baseArray: undefined[] = Array(dataRows[0].filter(v => (v as string).endsWith('期')).length).fill(undefined)
    const fill = (arr: (number | undefined)[]) => [...arr, ...baseArray].slice(0, baseArray.length)

    // 「修繕項目カテゴリ + 修繕項目」が一致すれば同じ要素として予測／実績を管理、でなければ異なる要素として新しく予測／実績（初期値は要素数だけ等しい配列）を追加
    const categories = dataRows.slice(DATA_START_ROW_IDX).reduce((acc: IRepairPlansIOCategoryItem[], cur) => {
      // Excelインジェクション対策としてExcelダウンロード時、先頭に'を追加しているので'を除く
      const curCatLabel = `${String(cur[0]).trim().replaceAll(' ', '_').replace(/^'/, '')} ${String(cur[1]).trim().replaceAll(' ', '_').replace(/^'/, '')}`
      const curItemLabel = String(cur[2]).replace(/^'/, '')
      const inputs = cur.slice(DATA_START_COL_IDX).map(v => v === 0 ? undefined : v) as (number | undefined)[]

      const former = acc.find(parent => parent.categoryLabel + '___' + parent.itemLabel === curCatLabel + '___' + curItemLabel)
      if (former) {
        switch (cur[3]) {
          case '予測': former.prospects = fill(inputs); break
          case '実績': former.performances = fill(inputs); break
        }
      } else {
        const newItem: IRepairPlansIOCategoryItem = {
          categoryLabel: curCatLabel,
          itemLabel: curItemLabel,
          prospects: cur[3] === '予測' ? fill(inputs) : baseArray,
          performances: cur[3] === '実績' ? fill(inputs) : baseArray,
        }
        acc.push(newItem)
      }
      return acc
    }, [])

    return { planningStartPeriod, uploadedFileName, categories }
  }

  private _parseValidator(parsed: ExcelJs.CellValue[][]): boolean {
    // 先頭行の見出しの並びが適当
    return STATIC_HEADERS.every((h, idx) => h === parsed[0][idx]) &&
      String(parsed[0][DATA_START_COL_IDX]).endsWith('期') &&
      // 期が抜け無く、順序通り入力されている
      parsed[0].slice(DATA_START_COL_IDX).every((num, idx, arr) => idx === 0 || Number((num as string)?.replace('期', '')) === Number((arr[idx - 1] as string)?.replace('期', '')) + 1) &&
      // データ行が想定した行から開始（1行目のヘッダ以降、DATA_START_ROW_IDXまで値が空）
      parsed.slice(1, DATA_START_ROW_IDX).every(row => row[1] == null) &&
      // 予測・実績が全て空欄か数値
      parsed.slice(DATA_START_ROW_IDX).every(row => row.slice(DATA_START_COL_IDX).every(v => v == null || Number.isInteger(v))) &&
      // 大分類、修繕項目カテゴリ、修繕項目に全て値が入力されている
      parsed.slice(DATA_START_ROW_IDX).every(row => row.slice(0, 3).every(v => v != null)) &&
      // 各項目の予測や実績がそれぞれ2行以上存在しない
      (() => {
        const target = parsed.slice(DATA_START_ROW_IDX)
        const rowLength = new Set(target.map(t => `${t[0]}${t[1]}${t[2]}${t[3]}`))
        return target.length === rowLength.size
      })()
  }

  // todo: 配色や書式、罫線の設定
  async downloadAsExcel(data: RepairPlanGetResponse, periodToYearMonth: (period: number) => string): Promise<void> {
    await this.toExcel(data.uploadedFileName, (sheet: ExcelJs.Worksheet) => {
      sheet.views = [{ state: 'frozen', xSplit: DATA_START_COL_IDX, ySplit: DATA_START_ROW_IDX /*, showGridLines: false */ }]

      const baseArray: null[] = Array(data.categories[0]?.performances.length ?? 0).fill(null)
      sheet.columns = [...STATIC_HEADERS, ...(baseArray.map((_, idx) => `${data.planningStartPeriod + idx}期`))].map((h, idx) => ({ header: h, key: idx + '' }))
      sheet.addRow([null, null, null, '西暦', null, ...(baseArray.map((_, idx) => periodToYearMonth(data.planningStartPeriod + idx)))])

      const categoryRows = data.categories.flatMap((c, idx) => {
        const cLabels = c.categoryLabel.split(' ')
        const c1 = cLabels[0]
        const c2 = cLabels.slice(1).join()
        const rowCountBase = 2 * idx + (DATA_START_ROW_IDX + 1)
        return [
          [this.esc(c1), this.esc(c2), this.esc(c.itemLabel), '予測', { formula: `SUM(F${rowCountBase}:BZ${rowCountBase})`, result: undefined }, ...c.prospects],
          [this.esc(c1), this.esc(c2), this.esc(c.itemLabel), '実績', { formula: `SUM(F${rowCountBase + 1}:BZ${rowCountBase + 1})`, result: undefined }, ...c.performances]
        ]
      })
      sheet.addRows(categoryRows)

      const totals = (condition: string) => baseArray.map((_, idx) => {
        const colAlpha = this.idxToAlpha(idx + DATA_START_COL_IDX + 1)
        const formula = `SUMIFS(${colAlpha}${DATA_START_ROW_IDX + 1}:${colAlpha}1000,$D${DATA_START_ROW_IDX + 1}:$D1000,"${condition}")`
        return { formula, result: undefined }
      })

      const sumRows = [
        ['単年度修繕費の小計', null, null, '当初', { formula: 'SUM(F3:BZ3)', result: undefined }, ...data.originals],
        [null, null, null, '予測', { formula: 'SUM(F4:BZ4)', result: undefined }, ...totals('予測')],
        [null, null, null, '実績', { formula: 'SUM(F5:BZ5)', result: undefined }, ...totals('実績')],
      ]
      sheet.insertRows(3, sumRows)
    })
  }
}

export const repairPlanExcelParser = new RepairPlanExcelParser()
