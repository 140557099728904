
















































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { errorsModule } from '@/stores/errors'
import { DAY_OF_WEEK } from '@/constants/ux-constants'
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
  }
})
export default class SmDateAndTime extends Vue {
  private OBSERVER_REF = 'OBSERVER_REF'
  private errors:string[] = []
  private menu = false
  private get fieldErrors() {
    return errorsModule.fieldErrors(this.fieldId)?.join(' ')
  }

  // 共通
  @Prop({ default: '' })
  private readonly fieldId!: string

  @Prop()
  private readonly name?: string // エラー文言用の名前

  @Prop({ default: false })
  private readonly required!: boolean

  @Prop({ default: false })
  private readonly isNotPast!: boolean

  @Prop({ default: false })
  private readonly immediate!: boolean

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop()
  private readonly caption?: string

  // 日付用
  @Prop()
  private readonly date!: string // YYYY-MM-DD

  @Prop()
  private readonly placeholder?: string

  @Prop()
  private readonly dateLabel?: string

  @Prop()
  private readonly allowedDateFrom?: Date

  // 時間用（時）
  @Prop()
  private readonly hour!: number // HH

  @Prop()
  private readonly hourLabel?: string

  @Prop({ required: true, default: () => [] })
  private readonly hourItems!: { value: string | number | null, label: string }[]

  // 時間用（分）
  @Prop()
  private readonly minutes!: number // MM

  @Prop()
  private readonly minutesLabel?: string

  @Prop({ required: true, default: () => [] })
  private readonly minuteItems!: { value: string | number | null, label: string }[]

  // 算術演算子（共通）
  private get rules(): string {
    const rules = []
    if (this.required) rules.push('required')
    return rules.join('|')
  }

  private get dateRules(): string {
    const rules = []
    if (this.required) rules.push('required')
    if (this.isNotPast) rules.push(`is_not_past:${this.date},${this.hour},${this.minutes}`)
    return rules.join('|')
  }

  // 算術演算子（日付用）
  private get _date(): string { return this.date }
  private set _date(newValue: string) {
    this.$emit('update:date', newValue)
  }

  private get formatDate(): string | undefined {
    if (!this._date) return
    const date = new Date(this._date)
    const numberOfDate = date.getDay()
    const dayOfWeek = DAY_OF_WEEK[numberOfDate]
    const [year, month, day] = this.date.substr(0, 10).split('-')
    return `${year}年${month}月${day}日(${dayOfWeek})`
  }

  private get allowedDates(): ((val: string) => boolean) | undefined {
    if (!this.allowedDateFrom) return
    const date = this.allowedDateFrom
    // ISOString形式の日本時間を取得したいが、
    // toISOString()の結果はUTCのため新規・修正に関わらず9時間進める
    date.setHours(date.getHours() + 9)
    const afterDate = date.toISOString().substr(0, 10).replaceAll('-', '')
    return (val) => parseInt(afterDate) <= parseInt(val.replaceAll('-', ''))
  }

  private get isDateNotSelected(): boolean {
    return !this._date
  }

  // 算術演算子（時間）
  private get _hour(): number { return this.hour }
  private set _hour(newValue: number) {
    this.$emit('update:hour', newValue)
  }

  // 算術演算子（分）
  private get _minutes(): number { return this.minutes }
  private set _minutes(newValue: number) {
    this.$emit('update:minutes', newValue)
  }

  // メソッド
  private onClickClear(): void {
    this.menu = false
    this._date = ''
  }

  // 日付だけ、クリアボタン押された際にchangeイベントが発火しないので、値を監視する
  @Watch('date')
  changeDate(): void {
    this.checkValidationError()
  }

  // 日付、時間、分のいずれかが変更されたタイミングでバリデーションのチェックをする
  private async checkValidationError(): Promise<void> {
    // フィールドエラーのクリア
    if (errorsModule.fieldErrors(this.fieldId)) errorsModule.clearSingleFieldError(this.fieldId)

    // validationProviderのエラーの検知
    const observer = this.$refs[this.OBSERVER_REF] as InstanceType<typeof ValidationObserver>
    let observerErrors: Record<string, string[]> = {}
    await observer.validateWithInfo().then(info => {
      observerErrors = info.errors
    })
    this.errors = Object.values(observerErrors).flatMap(e => e)
  }
}
