import { QuestionnaireQuestionType } from '@/constants/schema-constants'
import { Material } from '@/dtos/commons'
import { Type } from 'class-transformer'

export class QuestionnairesRespondentDetailGetRequest {
  questionnaireId: string
  userId: string
  constructor(questionnaireId: string, userId: string) {
    this.questionnaireId = questionnaireId
    this.userId = userId
  }
}

export class RespondentQuestionnaireChoiceAnswer {
  questionnaireChoiceAnswerId!: string
  ownerAnswer!: string
}

export class RespondentQuestionnaireQuestion {
  questionnaireQuestionId!: string
  questionnaireQuestionType!: QuestionnaireQuestionType
  questionSentence!: string
  sortOrderNum!: number
  @Type(() => RespondentQuestionnaireChoiceAnswer) questionnaireChoiceAnswers: RespondentQuestionnaireChoiceAnswer[] = []
}

export class RespondentQuestionnaireSection {
  questionnaireSectionId!: string
  sectionTitle?: string
  sectionExplanation?: string
  sortOrderNum!: number
  @Type(() => Material) material?: Material
  @Type(() => RespondentQuestionnaireQuestion) questionnaireQuestions: RespondentQuestionnaireQuestion[] = []
}

export class RespondentOpinionAndRequest {
  questionnaireSectionId!: string
  sectionTitle?: string
  sectionExplanation?: string
  @Type(() => Material) material?: Material
  questionnaireQuestionId!: string
  questionSentence!: string
  questionnaireChoiceAnswerId!: string
  ownerAnswer!: string
}

export class RespondentQuestionnaire {
  @Type(() => RespondentQuestionnaireSection) questionnaireSections: RespondentQuestionnaireSection[] = []
  @Type(() => RespondentOpinionAndRequest) opinionAndRequest?: RespondentOpinionAndRequest
}

export class RespondentOwnerUser {
  userId!: string
  userName!: string
}

export class QuestionnairesRespondentDetailGetResponse {
  questionnaireId!: string
  @Type(() => RespondentOwnerUser) ownerUser = new RespondentOwnerUser()
  @Type(() => RespondentQuestionnaire) questionnaire = new RespondentQuestionnaire()
}
