export class StaffPutRequest {
  adminId!: string
  adminName!: string
  adminShortName?: string
  emailAddress?: string
  isEmailSent!: boolean
  constructor(adminId: string, adminName: string, isEmailSent: boolean) {
    this.adminId = adminId
    this.adminName = adminName
    this.isEmailSent = isEmailSent
  }
}

export class LifeManagerPutRequest {
  adminId!: string
  adminName!: string
  authKeyRecipient!: string
  phoneNumber!: string
  isEmailSent!: boolean
  constructor(adminId: string, adminName: string, authKeyRecipient: string, phoneNumber: string, isEmailSent: boolean) {
    this.adminId = adminId
    this.adminName = adminName
    this.authKeyRecipient = authKeyRecipient
    this.phoneNumber = phoneNumber
    this.isEmailSent = isEmailSent
  }
}
