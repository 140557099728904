



































































import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { errorsModule } from '@/stores/errors'
import type { Tab } from '@/components/molecules/SmTabs.vue'
import { structureModule } from '@/stores/structure-store'
import { WIDTH_PATTERNS } from '@/constants/ux-constants'
import type { WidthPattern } from '@/constants/ux-constants'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmAppBar: () => import('@/components/organisms/SmAppBar.vue'),
  }
})
export default class SmTemplate extends Vue {
  @Prop()
  title?: string

  @Prop({ default: '$arrow_back_round' })
  topLeftIcon!: string

  @Prop()
  tabs?: Tab[]

  @Prop({ default: false })
  isAvailableExtensionContent!: boolean

  @Prop()
  extensionHeight?: number

  // 編集画面用プロパティ
  // 編集画面の場合、背景色は全て白にする
  @Prop({ default: false })
  isBackgroundWhite!: boolean

  // 横スクロールが発生する画面用のプロパティ
  // 最大幅を持たせず画面サイズに合わせて拡張する
  @Prop({ default: false })
  isNoMaxWidth!: boolean

  @Model('change-tab')
  currentTab?: string

  private get _currentTab(): string | undefined { return this.currentTab }
  private set _currentTab(newValue: string | undefined) { this.$emit('change-tab', newValue) }

  private get widthPattern(): WidthPattern | undefined { return structureModule.widthPattern }

  WIDTH_PATTERNS = Object.freeze(WIDTH_PATTERNS)

  get globalErrors(): string[] { return errorsModule.globalErrors }
}
