var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sm-table-data',{attrs:{"headers":_vm.tableHeaders,"items":_vm._statements,"item-key":"userId","disable-row-click":"","content-name":"区分所有者","keyword":_vm.keyword},scopedSlots:_vm._u([{key:"item.votingRightCount",fn:function(ref){
var item = ref.item;
return [_c('sm-text-field',{attrs:{"required":"","text":item.votingRightCount,"type":"number","field-id":("users[" + (item.userId) + "].votingRightCount"),"validation-rules":['min_value:0', 'integer'],"counter":9},on:{"input":function($event){return _vm.inputVotingRightCount(item.userId, $event)}}})]}},{key:"item.isPresent",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ml-1"},[_c('sm-radio',{attrs:{"field-id":("statements[" + (_vm.getIndex(item.userId)) + "].isPresent"),"options":_vm.isPresentOptions(_vm.holdingMethodType),"disabled":!_vm.isPresentClickable(item),"radio-value":item.statementType},on:{"change":function($event){return _vm.clickPresent(item.userId, $event, $event)}}})],1)]}},{key:"item.reset",fn:function(ref){
var item = ref.item;
return [(!item.isStatement)?[_c('sm-btn-text',{attrs:{"text":"リセット","disabled":!_vm.isVoteAndPresentResetChangable(item)},on:{"click":function($event){return _vm.reset(item.userId)}}})]:_vm._e()]}},_vm._l((_vm.subjects),function(subject,subjectIndex){return {key:("item.vote[" + subjectIndex + "].voteType"),fn:function(ref){
var item = ref.item;
return [_c('sm-radio',{key:subject.subjectId,attrs:{"options":_vm.voteTypes,"radio-value":item.vote[subjectIndex].voteType,"disabled":!_vm.isVoteChangable(item),"field-id":("users[" + (item.userId) + "].vote" + subjectIndex + ".voteType")},on:{"change":function($event){return _vm.changeVote(item.userId, item.statementType, subjectIndex, $event)}}})]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }