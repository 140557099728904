





















import { Vue, Component } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { Tab } from '@/components/molecules/SmTabs.vue'

// 社員新規登録メニューの内容
const APP_BAR_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  SERVICE_STAFF: { text: '管理者業務執行者を新規登録する', label: '社員を新規登録する' },
  FRONT: { text: 'フロント担当を新規登録する', label: '社員を新規登録する' },
  LIFE_MANAGER: { text: 'ライフマネージャーを新規登録する', label: '社員を新規登録する' },
}

@Component({
  components: {
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class StaffsListPage extends Vue {
  tabs = [
    new Tab('管理者業務執行者', staticRoutes.staffsList.getChild('serviceStaff').name),
    new Tab('フロント担当者', staticRoutes.staffsList.getChild('front').name),
    new Tab('ライフマネージャー', staticRoutes.staffsList.getChild('lifeManager').name),
  ]

  appBarMenuId ='SERVICE_STAFF'
  appBarMenuItems = APP_BAR_MENU_ITEMS

  onChangeAppBarMenu():void {
    // 各ロール用の社員新規登録画面へ遷移する
    switch (this.appBarMenuId) {
      case 'SERVICE_STAFF':
        this.$router.push({ name: staticRoutes.serviceStaffRegister.name })
        break
      case 'FRONT':
        this.$router.push({ name: staticRoutes.frontRegister.name })
        break
      case 'LIFE_MANAGER':
        this.$router.push({ name: staticRoutes.lifeManagerRegister.name })
        break
      default:
        break
    }
  }
}
