














































































































































































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'

import { RadioOption } from '@/components/atoms/SmRadio.vue'

import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { generateUuid } from '@/libs/uuid-generator'
import { getInitialAuthCodeTypeLabel } from '@/libs/type-handler'

import { unitsModule } from '@/stores/units-store'

import { INITIAL_AUTH_CODE_TYPE, OWNER_EDIT_PAGE_TYPE } from '@/constants/schema-constants'
import type { InitialAuthCodeType, OwnerEditPageType } from '@/constants/schema-constants'

import { Unit, UnitsGetRequest } from '@/dtos/units/get'
import { UnitDetailGetRequest, UnitDetailGetResponse } from '@/dtos/units/get-detail'
import { UnitsPutRequest, UnitsChangePutRequest } from '@/dtos/units/put'

import VueRouter from 'vue-router'

const DIALOG_MESSAGE = {
  SINGLE_UNIT_OWNER_UNLINKED: 'この操作によって、変更前の区分所有者情報は削除されます。変更前の区分所有者のアカウントが停止され、新規の区分所有者アカウントが発行されます。本当に大丈夫ですか？',
  SINGLE_UNIT_OWNER_LINKED: 'この操作によって、変更前の区分所有者情報は削除されます。変更前の区分所有者のアカウントが停止され、住戸に既存の区分所有者アカウントが紐づけられます。本当に大丈夫ですか？',
  MULTIPLE_UNITS_OWNER_UNLINKED: 'この操作によって、変更前の区分所有者情報は削除され、新規の区分所有者アカウントが発行されます。本当に大丈夫ですか？',
  MULTIPLE_UNITS_OWNER_LINKED: 'この操作によって、変更前の区分所有者情報は削除され、住戸に既存の区分所有者アカウントが紐づけられます。本当に大丈夫ですか？',
} as const

class OwnerFormInputs {
  unitId!: string
  votingRightCount!: number
  casysName!: string
  userName?: string
  joinedAt!: string
  emailAddress?: string
  isLivingOutside!: boolean
  isPaperUseApplied!: boolean
  isInitialAuth!: boolean
  initialAuthCode?: string
  initialAuthCodeType?: InitialAuthCodeType
  personalMemo?: string
}

class OwnerChangeFormInputs extends OwnerFormInputs {
  relatedUserId?: string
}

interface PageTypeSpecifications {
  inputs: OwnerFormInputs
  created: (unit: UnitDetailGetResponse) => void
  linkedOwner: (relatedOwner: UnitDetailGetResponse) => void
  reloadForm: (unit: UnitDetailGetResponse) => void
  onClickExecute: ($router: VueRouter, selectedJoinedAtDate: string | null, selectedLostDate: string | null) => Promise<void> | void
}

class OwnerEditSpecifications implements PageTypeSpecifications {
  inputs = new OwnerFormInputs()

  created(unit: UnitDetailGetResponse): void {
    this.inputs.unitId = unit.unitId
    this.inputs.votingRightCount = unit.votingRightCount
    this.inputs.casysName = unit.casysName
    this.inputs.userName = unit.userName
    this.inputs.joinedAt = unit.joinedAt
    this.inputs.emailAddress = unit.emailAddress
    this.inputs.isLivingOutside = unit.isLivingOutside
    this.inputs.isPaperUseApplied = unit.isPaperUseApplied
    this.inputs.isInitialAuth = unit.isInitialAuth
    this.inputs.initialAuthCode = unit.initialAuthCode
    this.inputs.initialAuthCodeType = unit.initialAuthCodeType
    this.inputs.personalMemo = unit.personalMemo ?? ''
  }

  linkedOwner(): void { /* nothing to do */ }

  reloadForm(): void { /* nothing to do */ }

  async onClickExecute($router: VueRouter): Promise<void> {
    const req = new UnitsPutRequest()
    req.unitId = this.inputs.unitId
    req.votingRightCount = Number(this.inputs.votingRightCount)
    req.casysName = this.inputs.casysName
    if (this.inputs.casysName) req.casysName = this.inputs.casysName
    if (this.inputs.userName) req.userName = this.inputs.userName
    req.isLivingOutside = this.inputs.isLivingOutside
    req.isPaperUseApplied = this.inputs.isPaperUseApplied
    if (this.inputs.initialAuthCode) req.initialAuthCode = this.inputs.initialAuthCode
    if (this.inputs.initialAuthCodeType) req.initialAuthCodeType = this.inputs.initialAuthCodeType
    if (this.inputs.personalMemo) req.personalMemo = this.inputs.personalMemo

    if (req) await unitsModule.putUnits(req)
    $router.go(-1) // 区分所有者詳細画面で矢印アイコンから遷移元の画面に戻れるように遷移する
  }
}

class OwnerChangeSpecifications implements PageTypeSpecifications {
  inputs = new OwnerChangeFormInputs()

  created(unit: UnitDetailGetResponse): void {
    this.inputs.unitId = unit.unitId
    this.inputs.votingRightCount = unit.votingRightCount
    this.inputs.isLivingOutside = false
    this.inputs.isPaperUseApplied = false
  }

  linkedOwner(relatedOwner: UnitDetailGetResponse): void {
    this.inputs.relatedUserId = relatedOwner.userId
    this.inputs.casysName = relatedOwner.casysName
    this.inputs.userName = relatedOwner.userName
    this.inputs.joinedAt = relatedOwner.joinedAt
    this.inputs.emailAddress = relatedOwner.emailAddress
    this.inputs.isLivingOutside = relatedOwner.isLivingOutside
    this.inputs.isPaperUseApplied = relatedOwner.isPaperUseApplied
    this.inputs.isInitialAuth = relatedOwner.isInitialAuth
    this.inputs.initialAuthCode = relatedOwner.initialAuthCode
    this.inputs.personalMemo = relatedOwner.personalMemo
  }

  reloadForm(unit: UnitDetailGetResponse): void {
    this.inputs = new OwnerChangeFormInputs()
    this.inputs.unitId = unit.unitId
    this.inputs.votingRightCount = unit.votingRightCount
    this.inputs.isLivingOutside = false
    this.inputs.isPaperUseApplied = false
  }

  async onClickExecute($router: VueRouter, selectedJoinedAtDate: string | null, selectedLostDate: string | null): Promise<void> {
    const req = new UnitsChangePutRequest()
    req.unitId = this.inputs.unitId
    // 既存の区分所有者を紐づける場合
    if (this.inputs.relatedUserId) req.relatedUserId = this.inputs.relatedUserId
    req.casysName = this.inputs.casysName
    if (this.inputs.casysName) req.casysName = this.inputs.casysName
    if (this.inputs.userName) req.userName = this.inputs.userName
    // 既存の区分所有者を紐づけない場合
    if (!this.inputs.relatedUserId && selectedJoinedAtDate) req.joinedDate = selectedJoinedAtDate
    // 既存の区分所有者を紐づける場合
    if (this.inputs.relatedUserId && selectedLostDate) req.owningEndDate = selectedLostDate
    req.isLivingOutside = this.inputs.isLivingOutside
    req.isPaperUseApplied = this.inputs.isPaperUseApplied
    if (this.inputs.initialAuthCode) req.initialAuthCode = this.inputs.initialAuthCode
    if (this.inputs.personalMemo) req.personalMemo = this.inputs.personalMemo

    if (req) await unitsModule.putUnitsChange(req)
    $router.go(-1) // 区分所有者詳細画面で矢印アイコンから遷移元の画面に戻れるように遷移する
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    RelatedOwnerSelectModal: () => import('@/pages/owners/RelatedOwnerSelectModal.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

  }
})
export default class OwnerEditPage extends Mixins(CurrentAdminManager) {
  OWNER_EDIT_PAGE_TYPE = Object.freeze(OWNER_EDIT_PAGE_TYPE)

  @Prop()
  private readonly unitId!:string

  @Prop({ required: true, default: OWNER_EDIT_PAGE_TYPE.CHANGE })
  private readonly pageType!: OwnerEditPageType

  unit = new UnitDetailGetResponse()
  keyword = ''
  inputText = ''
  relatedOwner = new UnitDetailGetResponse()

  private get typeSpecs(): PageTypeSpecifications | undefined {
    switch (this.pageType) {
      case OWNER_EDIT_PAGE_TYPE.EDIT: return new OwnerEditSpecifications()
      case OWNER_EDIT_PAGE_TYPE.CHANGE: return new OwnerChangeSpecifications()
      default: return assertExhaustive(this.pageType)
    }
  }

  async created():Promise<void> {
    await unitsModule.fetchUnits(new UnitsGetRequest())
    await unitsModule.fetchUnitDetail(new UnitDetailGetRequest(this.unitId))
    this.unit = deepCopy(unitsModule.unitDetail(this.unitId) ?? new UnitDetailGetResponse(), { this: new ColumnToType(UnitDetailGetResponse) }, 'this')
    if (this.unit) this.typeSpecs?.created(this.unit)
  }

  private get isSingleUnit(): boolean {
    return unitsModule.unitsGet.units.filter(unit => unit.userId === this.unit.userId).length === 1
  }

  private get isRelatedOwner(): boolean {
    return this.pageType === OWNER_EDIT_PAGE_TYPE.CHANGE && this.relatedOwner.unitId !== undefined
  }

  private get relatedOwnerCasysUserName(): string {
    return this.relatedOwner.casysName
  }

  private get relatedOwnerUserName(): string {
    return this.relatedOwner.userName ?? ''
  }

  private get isLivingOutsideOptions(): RadioOption[] {
    return [new RadioOption('無', false), new RadioOption('有', true)]
  }

  private get isPaperUseAppliedOptions(): RadioOption[] {
    return [new RadioOption('未申請', false), new RadioOption('申請済', true)]
  }

  private get initialAuthCodeTypeOptions(): RadioOption[] {
    return [new RadioOption(getInitialAuthCodeTypeLabel(INITIAL_AUTH_CODE_TYPE.SUTEKINET), INITIAL_AUTH_CODE_TYPE.SUTEKINET), new RadioOption(getInitialAuthCodeTypeLabel(INITIAL_AUTH_CODE_TYPE.SMOOTH_E), INITIAL_AUTH_CODE_TYPE.SMOOTH_E)]
  }

  selectedJoinedAtDate: string | null = null
  selectedLostDate: string | null = null

  relatedOwnerSelectModalKey = generateUuid()
  isRelatedOwnerSelectModalVisible = false
  async openRelatedOwnerSelectModal(): Promise<void> {
    await unitsModule.fetchUnits(new UnitsGetRequest())
    this.relatedOwnerSelectModalKey = generateUuid()
    this.isRelatedOwnerSelectModalVisible = true
  }

  async selectedRelatedOwner(selectedOwner: Unit): Promise<void> {
    this.selectedJoinedAtDate = null
    await unitsModule.fetchUnitDetail(new UnitDetailGetRequest(selectedOwner.unitId))
    this.relatedOwner = deepCopy(unitsModule.unitDetail(selectedOwner.unitId) ?? new UnitDetailGetResponse(), { this: new ColumnToType(UnitDetailGetResponse) }, 'this')
    this.typeSpecs?.linkedOwner(this.relatedOwner)
  }

  clickCancelLinkedBtn(): void {
    this.selectedLostDate = null
    this.relatedOwner = new UnitDetailGetResponse()
    this.typeSpecs?.reloadForm(this.unit)
  }

  isOwnerChangeConfirmationDialogVisible = false
  openOwnerChangeConfirmationDialog(): void {
    this.isOwnerChangeConfirmationDialogVisible = true
  }

  private getConfirmationDialogMessage(): string | undefined {
    if (this.isSingleUnit && !this.isRelatedOwner) return DIALOG_MESSAGE.SINGLE_UNIT_OWNER_UNLINKED
    if (this.isSingleUnit && this.isRelatedOwner) return DIALOG_MESSAGE.SINGLE_UNIT_OWNER_LINKED
    if (!this.isSingleUnit && !this.isRelatedOwner) return DIALOG_MESSAGE.MULTIPLE_UNITS_OWNER_UNLINKED
    if (!this.isSingleUnit && this.isRelatedOwner) return DIALOG_MESSAGE.MULTIPLE_UNITS_OWNER_LINKED
    return undefined
  }

  dialogCaptionForMultipleUnitsOwner = '※別の住戸も所有しているため、区分所有者のアカウント自体は維持されます'
  private getConfirmationDialogCaption(): string {
    const beforeCasysName = this.unit.casysName
    const beforeAppName = this.unit.userName
    const isBeforeAppNameEmpty = !this.unit.userName
    let caption = `変更前：${this.unit.roomNumber}` + '　' + `${isBeforeAppNameEmpty ? beforeCasysName : beforeAppName}`
    if (!this.isSingleUnit) caption = `${caption}\n${this.dialogCaptionForMultipleUnitsOwner}`
    if (!this.isRelatedOwner) {
      const afterCasysName = this.typeSpecs?.inputs.casysName
      const afterAppName = this.typeSpecs?.inputs.userName
      const isAfterAppNameEmpty = !this.typeSpecs?.inputs.userName
      caption = `${caption}\n変更後：${this.unit.roomNumber}` + '　' + `${isAfterAppNameEmpty ? afterCasysName : afterAppName}`
    } else {
      const relatedCasysName = this.relatedOwner.casysName
      const relatedAppName = this.relatedOwner.userName
      const isRelatedAppNameEmpty = !this.relatedOwner.userName
      caption = `${caption}\n変更後：${this.relatedOwner.roomNumber}` + '　' + `${isRelatedAppNameEmpty ? relatedCasysName : relatedAppName}`
    }
    return caption
  }

  onClickExecute(): void {
    this.isOwnerChangeConfirmationDialogVisible = false
    this.typeSpecs?.onClickExecute(this.$router, this.selectedJoinedAtDate, this.selectedLostDate)
  }
}
