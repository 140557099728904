
























































import { Vue, Component, Model, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
  }
})
export default class SmExpansionPanels extends Vue {
  @Prop({ required: true, default: '' })
  private readonly headerTitle!: string

  @Prop()
  private readonly headerCloseTitle?: string

  @Model('change')
  private readonly open!: boolean

  @Prop({ default: 'secondary' })
  private readonly iconColor?: string

 @Prop({ default: undefined })
  private readonly subIconName?:string

 @Prop({ default: undefined })
  private readonly subIconColor?:string

  @Prop({ default: 'secondary' })
  private readonly titleColor?: string

  @Prop({ default: 'sm__label' })
  private readonly titleFormat?: string

  @Prop({ default: undefined })
  private readonly linkTitle?: string

  private get title() {
    if (!this.open && this.headerCloseTitle) {
      return this.headerCloseTitle
    } else {
      return this.headerTitle
    }
  }

  get panelValue(): number | null { return this.open ? 0 : null }
  set panelValue(newValue: number | null) {
    this.$emit('change', newValue === 0)
  }

  onChange(): void { this.panelValue = this.panelValue === 0 ? null : 0 }
}
