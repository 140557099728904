import { APIClientBase } from './api-client-base'
import { MaterialPostRequest, MaterialPostResponse } from '@/dtos/materials/post'
import { MaterialPutRequest } from '@/dtos/materials/put'

class MaterialsClient extends APIClientBase {
  constructor() {
    super('/materials')
  }

  postMaterial(req: MaterialPostRequest) {
    return this.post('', req, MaterialPostResponse)
  }

  async putMaterial(req: MaterialPutRequest) {
    await this.put(`/${req.materialId}`, req)
  }
}

export const materialsClient = new MaterialsClient()
