

















import { Component, Mixins } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutesLocal } from '@/routes'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class TemplateTab2 extends Mixins(CurrentAdminManager) {
  goToTab2(): void {
    this.$router.push({ name: staticRoutesLocal.templateUsage.getChild('tab1').name })
  }
}
