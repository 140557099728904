import { Material } from '@/dtos/commons'

export class User {
  userId!: string
}

export class AdminIdeaOpinionsPostRequest {
    ideaId!: string
    body?: string
    isAlternative!: boolean
    ownerUser!: User
    material?: Material
    alternativeIdeaTitle?:string
    alternativeIdeaBackground?:string
}
