






























































































import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { BuildingOwnerDto, BuildingOwnersGetRequest } from '@/dtos/buildings/owners/get'
import { generateUuid } from '@/libs/uuid-generator'
import { buildingsModule } from '@/stores/buildings-store'

// マンション選択メニューの内容
const TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS: {[id: string]: { text: string, label: string, key: string }} = {
  allBuildingsIncludingUnset: {
    text: 'すべてのマンション（マンション未設定を含む）',
    label: '変更する',
    key: 'ALL_BUILDINGS_INCLUDING_UNSET'
  },
  individuallySelected: {
    text: '個別に選択する',
    label: '変更する',
    key: 'INDIVIDUALLY_SELECTED'
  }
}

const TARGET_BUILDING_MENU_TYPES = {
  ALL_BUILDINGS_INCLUDING_UNSET: 'allBuildingsIncludingUnset',
  INDIVIDUALLY_SELECTED: 'individuallySelected'
} as const
type TargetBuildingMenuTypes = typeof TARGET_BUILDING_MENU_TYPES[keyof typeof TARGET_BUILDING_MENU_TYPES]

// 区分所有者選択のメニューの内容
const TARGET_OWNER_TYPE_SELECT_MENU_ITEMS: {[id: string]: { text: string, label: string, key: string }} = {
  allOwnersIncludingUnset: {
    text: 'すべての区分所有者（区分所有者未設定を含む）',
    label: '変更する',
    key: 'ALL_OWNERS_INCLUDING_UNSET'
  },
  individuallySelected: {
    text: '個別に選択する',
    label: '変更する',
    key: 'INDIVIDUALLY_SELECTED'
  }
}

const TARGET_OWNER_MENU_TYPES = {
  ALL_OWNERS_INCLUDING_UNSET: 'allOwnersIncludingUnset',
  INDIVIDUALLY_SELECTED: 'individuallySelected'
} as const
type TargetOwnerMenuTypes = typeof TARGET_OWNER_MENU_TYPES[keyof typeof TARGET_OWNER_MENU_TYPES]

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMenu: () => import('@/components/molecules/SmMenu.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),

    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),
    OwnerSelectModal: () => import('@/pages/owner-notifications/OwnerSelectModal.vue'),
  }
})
export default class BuildingAndOwnerSelectModal extends Vue {
  TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS = Object.freeze(TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS)
  TARGET_OWNER_TYPE_SELECT_MENU_ITEMS = Object.freeze(TARGET_OWNER_TYPE_SELECT_MENU_ITEMS)
  isStaff = false // TODO: 既存画面改修時に削除（building-select-modalの引数も一緒に削除）

  @Model('change')
  private readonly isVisible!: boolean

  @Prop({ default: () => [] })
  private readonly selectedBuildingsInit!: Building[]

  @Prop({ default: () => [] })
  private readonly selectedOwnersInit!: BuildingOwnerDto[]

  created(): void {
    this.selectedBuildings = this.selectedBuildingsInit
    this.selectedOwners = this.selectedOwnersInit

    if (this.selectedBuildingsInit.length === 0) {
      this.selectedTargetBuildingMenuTypeId = TARGET_BUILDING_MENU_TYPES.ALL_BUILDINGS_INCLUDING_UNSET
      this.preservingTargetBuildingMenuTypeId = TARGET_BUILDING_MENU_TYPES.ALL_BUILDINGS_INCLUDING_UNSET
    } else {
      this.selectedTargetBuildingMenuTypeId = TARGET_BUILDING_MENU_TYPES.INDIVIDUALLY_SELECTED
      this.preservingTargetBuildingMenuTypeId = TARGET_BUILDING_MENU_TYPES.INDIVIDUALLY_SELECTED
    }

    if (this.selectedOwnersInit.length === 0) {
      this.selectedTargetOwnerMenuTypeId = TARGET_OWNER_MENU_TYPES.ALL_OWNERS_INCLUDING_UNSET
      this.preservingTargetOwnerMenuTypeId = TARGET_OWNER_MENU_TYPES.ALL_OWNERS_INCLUDING_UNSET
    } else {
      this.selectedTargetOwnerMenuTypeId = TARGET_OWNER_MENU_TYPES.INDIVIDUALLY_SELECTED
      this.preservingTargetOwnerMenuTypeId = TARGET_OWNER_MENU_TYPES.INDIVIDUALLY_SELECTED
    }
  }

  selectedTargetBuildingMenuTypeId: TargetBuildingMenuTypes = TARGET_BUILDING_MENU_TYPES.ALL_BUILDINGS_INCLUDING_UNSET
  preservingTargetBuildingMenuTypeId: TargetBuildingMenuTypes = TARGET_BUILDING_MENU_TYPES.ALL_BUILDINGS_INCLUDING_UNSET
  selectedBuildings: Building[] = []
  buildingKeyword = ''
  buildingInputText = ''
  isBuildingSelectModalVisible = false
  buildingSelectModalKey = generateUuid()

  selectedTargetOwnerMenuTypeId: TargetOwnerMenuTypes = TARGET_OWNER_MENU_TYPES.ALL_OWNERS_INCLUDING_UNSET
  preservingTargetOwnerMenuTypeId: TargetOwnerMenuTypes = TARGET_OWNER_MENU_TYPES.ALL_OWNERS_INCLUDING_UNSET
  selectedOwners: BuildingOwnerDto[] = []
  ownerKeyword = ''
  ownerInputText = ''
  isOwnerSelectModalVisible = false
  ownerSelectModalKey = generateUuid()

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get isOneBuildingSelected(): boolean {
    return this.selectedBuildings.length === 1
  }

  private get selectedOwnerUserIds(): string[] {
    return this.selectedOwners.map(o => o.userId)
  }

  private get isOutOfService(): boolean {
    if (this.selectedBuildings.length > 0) return this.selectedBuildings[0].isOutOfService
    return false
  }

  async onSelectTargetBuildingType(): Promise<void> {
    if (this.selectedTargetBuildingMenuTypeId === TARGET_BUILDING_MENU_TYPES.ALL_BUILDINGS_INCLUDING_UNSET) {
      this.preservingTargetBuildingMenuTypeId = TARGET_BUILDING_MENU_TYPES.ALL_BUILDINGS_INCLUDING_UNSET
      this.selectedBuildings = []
      this.buildingKeyword = ''
      this.buildingInputText = ''

      this.resetSelectedOwnerData()
    } else {
      await this.openBuildingSelectModal()
    }
  }

  async openBuildingSelectModal(): Promise<void> {
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999))
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingSelectModalVisible = true
  }

  async onSelectTargetOwnerType(): Promise<void> {
    if (this.selectedTargetOwnerMenuTypeId === TARGET_OWNER_MENU_TYPES.ALL_OWNERS_INCLUDING_UNSET) {
      this.resetSelectedOwnerData()
    } else {
      await this.openOwnerSelectModal()
    }
  }

  async openOwnerSelectModal(): Promise<void> {
    if (!this.isOneBuildingSelected) return

    const req = new BuildingOwnersGetRequest()
    req.buildingId = this.selectedBuildings[0].buildingId
    await buildingsModule.fetchBuildingOwners(req)

    this.ownerSelectModalKey = generateUuid()
    this.isOwnerSelectModalVisible = true
  }

  private get selectedBuildingLabel(): string {
    if (this.preservingTargetBuildingMenuTypeId === TARGET_BUILDING_MENU_TYPES.ALL_BUILDINGS_INCLUDING_UNSET) {
      return 'すべてのマンション（マンション未設定を含む）'
    } else {
      return this.buildingNames
    }
  }

  private get buildingNames(): string {
    return this.selectedBuildings.map(b => b.buildingName).join('｜')
  }

  private get selectedOwnerLabel(): string {
    if (this.preservingTargetOwnerMenuTypeId === TARGET_OWNER_MENU_TYPES.ALL_OWNERS_INCLUDING_UNSET || !this.isOneBuildingSelected) {
      return 'すべての区分所有者（区分所有者未設定を含む）'
    } else {
      return this.ownerNames
    }
  }

  private get ownerNames(): string {
    return this.selectedOwners.map(o => o.roomNumber + ' ' + o.userName).join('、')
  }

  onSelectBuildingsByModal(buildingIds?: string[]): void {
    this.selectedBuildings = this.getTargetBuildings(buildingIds)
    this.preservingTargetBuildingMenuTypeId = this.selectedTargetBuildingMenuTypeId
    this.resetSelectedOwnerData()
  }

  getTargetBuildings(buildingIds?: string[]): Building[] {
    return buildingsModule.buildingsGet.buildings.filter(b => buildingIds?.includes(b.buildingId))
  }

  onSelectOwnersByModal(ownerIds?: string[]): void {
    this.selectedOwners = this.getTargetOwners(ownerIds)
    this.preservingTargetOwnerMenuTypeId = this.selectedTargetOwnerMenuTypeId
  }

  getTargetOwners(ownerIds?: string[]): BuildingOwnerDto[] {
    return buildingsModule.buildingOwnersGet.owners.filter(o => ownerIds?.includes(o.userId))
  }

  resetSelectedOwnerData(): void {
    this.selectedTargetOwnerMenuTypeId = TARGET_OWNER_MENU_TYPES.ALL_OWNERS_INCLUDING_UNSET
    this.preservingTargetOwnerMenuTypeId = TARGET_OWNER_MENU_TYPES.ALL_OWNERS_INCLUDING_UNSET
    this.selectedOwners = []
    this.ownerKeyword = ''
    this.ownerInputText = ''
  }

  onClickBtn(): void {
    this.$emit('select', this.selectedBuildings, this.selectedOwners)
    this.isModalVisible = false
  }
}
