import { Type } from 'class-transformer'

export class Category {
  categoryLabel!: string
  itemLabel!: string
  prospects?: number[]
  total?: number

  constructor(categoryLabel: string, itemLabel: string, prospects?: number[], total?: number) {
    this.categoryLabel = categoryLabel
    this.itemLabel = itemLabel
    this.prospects = prospects
    this.total = total
  }
}

export class Expense {
  taxExcluded?: number[]
  tax?: number[]
  taxIncluded?: number[]
  cumulative?: number[]

  constructor(taxExcluded?: number[], tax?: number[], taxIncluded?: number[], cumulative?: number[]) {
    this.taxExcluded = taxExcluded
    this.tax = tax
    this.taxIncluded = taxIncluded
    this.cumulative = cumulative
  }
}

export class ConstructionPlan {
  periods!: number[]
  @Type(() => Category) categories?: Category[]
  expense?: Expense

  constructor(periods: number[], categories?: Category[], expense?: Expense) {
    this.periods = periods
    this.categories = categories
    this.expense = expense
  }
}

export class FinancialPlan {
  annualRevenues!: number[]
  balance?: number

  constructor(annualRevenues: number[], balance?: number) {
    this.annualRevenues = annualRevenues
    this.balance = balance
  }
}

export class SimpleRepairPlanPostRequest {
  constructionPlan: ConstructionPlan
  financialPlan: FinancialPlan

  constructor(constructionPlan: ConstructionPlan, financialPlan: FinancialPlan) {
    this.constructionPlan = constructionPlan
    this.financialPlan = financialPlan
  }
}
