var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sm__background-white pa-6"},[_c('div',{staticClass:"mb-4"},[(_vm.isPutStatement)?_c('sm-btn',{staticClass:"mr-4",attrs:{"type":"secondary","text":"意思表明を代理入力する"},on:{"click-btn":_vm.clickPutStatements}}):_vm._e(),(!_vm.isBuildingOutOfService)?_c('sm-btn',{attrs:{"type":"secondary","text":"csvを出力する"},on:{"click-btn":_vm.clickDownloadCSV}}):_vm._e()],1),_c('div',{staticClass:"sm__scroll-area"},[_c('div',{staticClass:"d-flex mb-4"},[_c('div',[_c('sm-text',{attrs:{"text":_vm.emptyDummyString,"format":"sm__h2"}})],1),_c('div',{staticClass:"sm__scroll-title"},[_c('sm-text',{attrs:{"text":"決議内訳","format":"sm__h2"}})],1)]),_c('div',{staticClass:"mb-4"},[_c('sm-table-data',{staticClass:"sm__statement-table",attrs:{"headers":_vm.summaryHeaders,"items":_vm.summaryItems,"item-key":"","content-name":"サマリデータ"},scopedSlots:_vm._u([{key:"item.headerRow",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(_vm.getCellStyles(item.headerRow.text))},[_c('div',{staticClass:"pa-2"},[(item.headerRow.text)?_c('div',[_c('sm-text',{attrs:{"text":item.headerRow.text,"format":"sm__data"}})],1):_vm._e(),(item.headerRow.secondText)?_c('div',[_c('sm-text',{attrs:{"text":item.headerRow.secondText,"format":"sm__data"}})],1):_vm._e()])])]}},{key:"item.statementCount",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.statementCount.text)?_c('div',[_c('sm-text',{attrs:{"text":item.statementCount.text,"format":"sm__data"}})],1):_vm._e(),(item.statementCount.secondText)?_c('div',[_c('sm-text',{attrs:{"text":item.statementCount.secondText,"format":"sm__data"}})],1):_vm._e()])]}},{key:"item.isPresent",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[(item.isPresent.text)?_c('div',[_c('sm-text',{attrs:{"text":item.isPresent.text,"format":"sm__data"}})],1):_vm._e(),(item.isPresent.secondText)?_c('div',[_c('sm-text',{attrs:{"text":item.isPresent.secondText,"format":"sm__data"}})],1):_vm._e()])]}},_vm._l((_vm.summaryVote),function(vote,voteIndex){return {key:("item.vote[" + voteIndex + "].voteType"),fn:function(ref){
var item = ref.item;
return [_c('div',{key:vote.subjectId},[_c('div',[_c('sm-text',{attrs:{"text":item.vote[voteIndex].text,"format":"sm__data"}})],1),_c('div',[_c('sm-text',{attrs:{"text":item.vote[voteIndex].secondText,"format":"sm__data"}})],1)])]}}})],null,true)})],1),_c('div',{staticClass:"d-flex mb-4"},[_c('div',[_c('sm-text',{attrs:{"text":_vm.emptyDummyString,"format":"sm__h2"}})],1),_c('div',{staticClass:"sm__scroll-title"},[_c('sm-text',{attrs:{"text":"意思表明内訳","format":"sm__h2"}})],1),_c('div',{staticClass:"sm__scroll-title-left"},[_c('sm-text',{staticClass:"mr-4",attrs:{"text":"賛成：○","format":"sm__caption","color":"black","level":"2"}}),_c('sm-text',{attrs:{"text":"反対：×","format":"sm__caption","color":"black","level":"2"}})],1)]),_c('div',[_c('sm-table-data',{staticClass:"sm__statement-table",attrs:{"headers":_vm.detailtHeaders,"items":_vm.detailItems,"item-key":"roomNumber","content-name":"意思表明の内訳"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ 'sm__no-statment': !item.isStatement && !item.statementType }},[_c('td',{staticClass:"sm__header-row"}),_c('td',[_c('sm-text',{attrs:{"text":item.roomNumber,"format":"sm__data"}})],1),_c('td',[_c('sm-text',{attrs:{"text":item.userName,"format":"sm__data"}})],1),_c('td',[_c('sm-text',{attrs:{"text":item.votingRightCount,"format":"sm__data"}})],1),_c('td',[_c('sm-text',{attrs:{"text":_vm.getStatementLabel(item.statementType),"format":"sm__data"}})],1),_vm._l((_vm.summaryVote),function(vote,voteIndex){return _c('td',{key:("text-" + (vote.subjectId))},[(item.vote[voteIndex] && (item.statementType === _vm.STATEMENT_TYPES.VOTE || item.statementType === _vm.STATEMENT_TYPES.PRESENT_REMOTE))?_c('sm-text',{attrs:{"text":_vm.getVoteLabel(item.vote[voteIndex].voteType),"format":"sm__data"}}):_vm._e()],1)})],2)]}}])})],1)]),(_vm.isPutStatement)?_c('sm-btn',{staticClass:"mt-4",attrs:{"type":"secondary","text":"意思表明を代理入力する"},on:{"click-btn":_vm.clickPutStatements}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }