











































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { UnitPaymentPlanDetail, UnitPlanDetailMonthlyPrice } from '@/dtos/reserve-funds/payments/get-detail'

class MonthlyPrices {
  month!:number
  priceForEachPeriods!:UnitPlanDetailMonthlyPrice[]
}

@Component({
  components: {
    SmBadgeInline: () => import('@/components/atoms/SmBadgeInline.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

  }
})
export default class PersonalPaymentDetailTable extends Vue {
  @Prop({ required: true })
  paymentPlanDetails!:UnitPaymentPlanDetail[]

  private get monthlyPrices():MonthlyPrices[] {
    const baseMonths = this.paymentPlanDetails.find(e => e.monthlyPrices.length <= 12)?.monthlyPrices.map(e => e.month)
    if (!baseMonths) return []
    const result:MonthlyPrices[] = baseMonths.map(month =>
      this.paymentPlanDetails.reduce<MonthlyPrices>((acc:MonthlyPrices, current:UnitPaymentPlanDetail) => {
        const monthlyPrice = current.monthlyPrices.find(monthlyPrice => monthlyPrice.month === month)
        acc.priceForEachPeriods.push(monthlyPrice ?? new UnitPlanDetailMonthlyPrice())
        return acc
      }, {
        month: month,
        priceForEachPeriods: []
      }))
    return result
  }
}
