












































































import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import type { TargetOwnerType } from '@/constants/schema-constants'
import { TARGET_OWNER_TYPE } from '@/constants/schema-constants'
import { buildingsModule } from '@/stores/buildings-store'
import { Tab } from '@/components/molecules/SmTabsInPage.vue'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmTabsInPage: () => import('@/components/molecules/SmTabsInPage.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),

    UnvotedGmResolutionSelectModalContent: () => import('@/pages/owner-notifications/owner-group-select/gm-resolutions/UnvotedGmResolutionSelectModalContent.vue'),
    UnreadIdeaSelectModalContent: () => import('@/pages/owner-notifications/owner-group-select/ideas/UnreadIdeaSelectModalContent.vue'),
    UnvotedOnlineResolutionSelectModalContent: () => import('@/pages/owner-notifications/owner-group-select/online-resolutions/UnvotedOnlineResolutionSelectModalContent.vue'),
  }
})

export default class OwnerGroupSelectModal extends Vue {
  @Model('change', { required: true, default: false })
  private readonly isVisible!: boolean

  // 物件ID
  @Prop({ required: true, default: '' })
  buildingId!: string

  // 選択中のプラン/決議の区分所有者タイプ(2, 3, 4のいずれか) => お知らせ編集時に使用
  @Prop({ default: TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD })
  targetOwnerType!: TargetOwnerType

  // 選択中のプラン/決議のID(区分所有者タイプに応じたID) => お知らせ編集時に使用
  @Prop()
  targetId?: string

  // プランタブのtextfieldに入力された値
  @Prop({ default: '' })
  ideaInputText!: string

  // プランタブの検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  ideaKeyword!: string

  // オンライン決議タブのtextfieldに入力された値
  @Prop({ default: '' })
  onlineResolutionInputText!: string

  // オンライン決議タブの検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  onlineResolutionKeyword!: string

  // 総会決議タブのtextfieldに入力された値
  @Prop({ default: '' })
  gmResolutionInputText!: string

  // 総会決議タブの検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  gmResolutionKeyword!: string

  // 表示中のタブで選択されているID
  selectedTargetId = ''

  // プランタブで選択中のプランID
  ideaId = ''

  // オンライン決議タブで選択中の決議ID
  onlineResolutionId = ''

  // 総会決議タブで選択中の決議ID
  gmResolutionId = ''

  // プランタブで選択された場合にIDを設定する
  selectIdea(ideaId: string): void {
    this.ideaId = ideaId
    this.selectedTargetId = ideaId
  }

  // オンライン決議タブで選択された場合にIDを設定する
  selectOnlineResolution(resolutionId: string): void {
    this.onlineResolutionId = resolutionId
    this.selectedTargetId = resolutionId
  }

  // 総会決議タブで選択された場合にIDを設定する
  selectGmResolution(resolutionId: string): void {
    this.gmResolutionId = resolutionId
    this.selectedTargetId = resolutionId
  }

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get _ideaInputText(): string { return this.ideaInputText }
  private set _ideaInputText(newValue: string) { this.$emit('update:ideaInputText', newValue) }

  private get _ideaKeyword(): string { return this.ideaKeyword }
  private set _ideaKeyword(newValue: string) { this.$emit('update:ideaKeyword', newValue) }

  private get _onlineResolutionInputText(): string { return this.onlineResolutionInputText }
  private set _onlineResolutionInputText(newValue: string) { this.$emit('update:onlineResolutionInputText', newValue) }

  private get _onlineResolutionKeyword(): string { return this.onlineResolutionKeyword }
  private set _onlineResolutionKeyword(newValue: string) { this.$emit('update:onlineResolutionKeyword', newValue) }

  private get _gmResolutionInputText(): string { return this.gmResolutionInputText }
  private set _gmResolutionInputText(newValue: string) { this.$emit('update:gmResolutionInputText', newValue) }

  private get _gmResolutionKeyword(): string { return this.gmResolutionKeyword }
  private set _gmResolutionKeyword(newValue: string) { this.$emit('update:gmResolutionKeyword', newValue) }

  tabs: Tab[] = [
    new Tab(TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD.toString(), 'プラン未読の区分所有者'),
    new Tab(TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED.toString(), 'オンライン決議未投票の区分所有者'),
    new Tab(TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED.toString(), '総会決議で意思表明をしていない区分所有者'),
  ]

  currentTab = this.targetOwnerType.toString()

  onClickBtn(): void {
    const selectedTargetOwnerType = this.convertOwnerType(this.currentTab)
    this.selectedTargetId = this.getTargetIdOnCurrentTab(selectedTargetOwnerType)
    this.$emit('select', selectedTargetOwnerType, this.selectedTargetId)
    this.isModalVisible = false
  }

  @Watch('isModalVisible', { immediate: true })
  applyOwnerGroupTargetId(isModalVisible: boolean): void {
    if (isModalVisible && this.targetId) {
      // 選択されているグループに応じてIDを設定する
      switch (this.targetOwnerType) {
        case TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD:
          this.ideaId = this.targetId
          break
        case TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED:
          this.onlineResolutionId = this.targetId
          break
        case TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED:
          this.gmResolutionId = this.targetId
          break
        case TARGET_OWNER_TYPE.ALL:
        case TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED:
        // TARGET_OWNER_TYPEに 2, 3, 4以外が指定された場合はエラーとする
          throw new Error('The target owner type(' + this.targetOwnerType + ') is unavailable')
        default: return assertExhaustive(this.targetOwnerType)
      }
    }
  }

  @Watch('currentTab', { immediate: true })
  onCurrentTabChanged(newValue: string): void {
    // 選択中のタブに合わせて状態を更新する
    const selectedTargetOwnerType = this.convertOwnerType(newValue)
    this.selectedTargetId = this.getTargetIdOnCurrentTab(selectedTargetOwnerType)
  }

  private convertOwnerType(ownerTypeStr: string) : TargetOwnerType {
    switch (Number(ownerTypeStr)) {
      case TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD: return TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD
      case TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED: return TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED
      case TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED: return TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED
      case TARGET_OWNER_TYPE.ALL:
      case TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED:
        // TARGET_OWNER_TYPEに 2, 3, 4以外が指定された場合はエラーとする
        throw new Error('The target owner type(' + ownerTypeStr + ') is unavailable')
      default: return TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD
    }
  }

  private getTargetIdOnCurrentTab(targetOwnerType: TargetOwnerType) : string {
    switch (targetOwnerType) {
      case TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD: return this.ideaId
      case TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED: return this.onlineResolutionId
      case TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED: return this.gmResolutionId
      case TARGET_OWNER_TYPE.ALL:
      case TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED:
        // TARGET_OWNER_TYPEに 2, 3, 4以外が指定された場合はエラーとする
        throw new Error('The target owner type(' + targetOwnerType + ') is unavailable')
      default: return assertExhaustive(targetOwnerType)
    }
  }

  private get isSelected(): boolean {
    return !!this.selectedTargetId
  }

  private get buildingName(): string {
    return buildingsModule.buildingsGet.buildings.find(b => b.buildingId === this.buildingId)?.buildingName ?? ''
  }
}
