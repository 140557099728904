export class AnswersPostRequest {
  resolutionId!: string
  questionId!: string
  answerBody!: string

  constructor(resolutionId: string, questionId: string, answerBody: string) {
    this.resolutionId = resolutionId
    this.questionId = questionId
    this.answerBody = answerBody
  }
}

export class AnswersPostResponse {
  postedAt!: string
  version!: number
}
