



























































import { Component, Watch, Vue } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { Header } from '@/components/molecules/SmTableData.vue'
import { CasysDetectionsGetRequest, Detection } from '@/dtos/casys/detections/get'
import { casysModule } from '@/stores/casys-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmTableDataExternalPaging: () => import('@/components/molecules/SmTableDataExternalPaging.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class CasysDetectionsListPage extends Vue {
  tableHeaders:Header[] = [
    new Header({ text: 'アップロード日時', value: 'uploadedAt', width: '232px' }),
    new Header({ text: '担当者', value: 'userName', sortable: false, width: '102px' }),
    new Header({ text: 'ファイル数', value: 'fileCount', sortable: false, width: '102px' }),
    new Header({ text: '差分件数', value: 'differenceCount', sortable: false }),
  ]

  get detectionCount(): number { return casysModule.casysDetectionsGet.detectionCount ?? 0 }
  get detections(): Detection[] { return casysModule.casysDetectionsGet.detections }
  get isInitSearch(): boolean { return !this.searchingKeyword }

  take = 50
  inputKeyword = ''
  searchingKeyword = ''
  focusedPageNum = 1

  @Watch('focusedPageNum', { immediate: false })
  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  async created(): Promise<void> {
    await this.initFetchDetections()
  }

  async fetchDetections(): Promise<void> {
    const skip = this.take * (this.focusedPageNum - 1)
    await casysModule.fetchCasysDetections(new CasysDetectionsGetRequest(skip, this.take, this.searchingKeyword))
  }

  async initFetchDetections(): Promise<void> {
    this.focusedPageNum = 1
    await this.fetchDetections()
  }

  async onSearchKeyword(): Promise<void> {
    this.searchingKeyword = this.inputKeyword
    await this.initFetchDetections()
  }

  async onClickPageBtn(pageNum: number): Promise<void> {
    this.focusedPageNum = pageNum
    await this.fetchDetections()
  }

  onClickUploadedAt(casysDifferenceDetectionBatchStatusId : string): void {
    // CASYS差分照合履歴(詳細)画面へ遷移
    this.$router.push({
      name: staticRoutes.casysDetectionDetailPage.name,
      params: { casysDifferenceDetectionBatchStatusId: casysDifferenceDetectionBatchStatusId }
    })
  }
}
