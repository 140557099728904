






















































































































import { Mixins, Component } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { MATERIAL_TYPES } from '@/constants/schema-constants'

import { staticRoutes } from '@/routes'
import { repairPlanExcelParser } from '@/libs/excel-parser/repair-plan-parser'
import { buildingsModule } from '@/stores/buildings-store'
import { errorsModule } from '@/stores/errors'
import { repairsModule } from '@/stores/repairs-store'

import { MaterialFormInputDto } from '@/dtos/commons'
import { BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { RepairPlanPostRequest } from '@/dtos/repairs/plans/post'

const INVALID_FORMAT_MESSAGES = ['ファイルの形式が想定と異なるか、不正な値が設定されているため、アップロードできません。']

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class RepairPlanRegisterPage extends Mixins(CurrentAdminManager) {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  private get buildingDetail(): BuildingDetailGetResponse | null { return buildingsModule.buildingDetailGet }

  uploaded: MaterialFormInputDto | null = null
  selectedPeriod = 1

  periods = Array.from({ length: 60 }).map((_, index) => {
    const period = index + 1
    return { value: period, label: `${period}期` }
  })

  isUploadDialogVisible = false

  closeUploadDialog(): void {
    this.uploaded = null
    this.selectedPeriod = 1
    this.isUploadDialogVisible = false
  }

  async uploadFile(): Promise<void> {
    errorsModule.clearGlobalErrors()
    if (!this.uploaded?.file) return
    this.isUploadDialogVisible = false
    const data = await repairPlanExcelParser.parse(this.uploaded.file)

    if (!data) {
      errorsModule.setGlobalErrors(INVALID_FORMAT_MESSAGES)
      return
    }

    const req = new RepairPlanPostRequest(
      this.selectedPeriod,
      data.planningStartPeriod,
      data.uploadedFileName,
      data.categories
    )
    await repairsModule.postRepairPlan(req)

    this.$router.replace({ name: staticRoutes.repairPlanDetail.getChild('summary').name, params: { needsGuideDialog: 'true' } })
  }
}
