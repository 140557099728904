import { usersClient } from '@/clients/users-client'
import { UsersGetRequest, UsersGetResponse } from '@/dtos/users/get'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'

@Module({ store, dynamic: true, namespaced: true, name: 'users' })
class UsersStore extends VuexModule {
  private _usersGet = new UsersGetResponse()

  public get usersGet(): UsersGetResponse { return this._usersGet }

  @Mutation
  private SET_USERS_GET(res:UsersGetResponse) { this._usersGet = res }

  @Action
  public async fetchUsers(req: UsersGetRequest) {
    const res = await usersClient.getUsers(req)
    this.SET_USERS_GET(res)
  }
}

export const usersModule = getModule(UsersStore)
