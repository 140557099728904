















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { buildingsModule } from '@/stores/buildings-store'
import { Column, Row, Cell } from '@/components/molecules/SmTableRepairPlanDetail.vue'
import { RepairsItemsGetResponse } from '@/dtos/repairs/items/get'

/**
 * periodごとの列のid（period1 , period2・・・）。ヘッダ行とデータ行とで各カラムのidを一致させる。
 */
function getPeriodId(period: number): string {
  return 'period' + period
}

class Period {
  id!:string
  period!:number
  yearMonth!:string
}

class Page {
  minPeriod!:number
  maxPeriod!:number
  columns:Column[] = []
}

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmOwLegend: () => import('@/components/owner-apps/SmOwLegend.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmPrintHeader: () => import('@/components/organisms/SmPrintHeader.vue'),

    SmTableRepairPlanDetailPrint: () => import('@/components/molecules/SmTableRepairPlanDetailPrint.vue'),
  }
})
export default class RepairPlanConstructionSubPage extends Vue {
  @Prop()
  private readonly detail?: RepairsItemsGetResponse

  private get periods():Period[] {
    return this.detail?.totalRows?.periodTotals.map(total => {
      return {
        id: getPeriodId(total.period),
        period: total.period,
        yearMonth: buildingsModule.periodToYearMonth(total.period)
      }
    }) ?? []
  }

  // ページ
  private get pages(): Page[] {
    // ラベル列 -> 期の一覧 -> 総計列 という構造の表を作る。
    // [[ラベル - 1-30期], [ラベル - 31-60期 - 総計]]にする
    // 30期以下なら分割しない
    if (this.periods.length <= 30) {
      return [
        {
          minPeriod: 1,
          maxPeriod: this.periods.length,
          columns: [{ id: 'rowLabel', isCategoryColumn: true }, ...this.periods, { id: 'itemTotal', isTotalColumn: true }]
        }, // 前半
      ]
    }

    const slicePoint = Math.ceil(this.periods.length / 2)
    const pages:Page[] = []

    // 前半
    const firstPageColumns = this.periods.slice(0, slicePoint)
    pages.push({
      minPeriod: firstPageColumns[0].period,
      maxPeriod: firstPageColumns.slice(-1)[0].period,
      columns: [{ id: 'rowLabel', isCategoryColumn: true }, ...firstPageColumns]
    })

    // 後半
    const secondPageColumns = this.periods.slice(slicePoint)
    pages.push({
      minPeriod: secondPageColumns[0].period,
      maxPeriod: secondPageColumns.slice(-1)[0].period,
      columns: [{ id: 'rowLabel', isCategoryColumn: true }, ...secondPageColumns, { id: 'itemTotal', isTotalColumn: true }]
    })
    return pages
  }

  // データ行（全体）
  private get rows(): Row[][] {
    // データ行全て取得
    const allrows = [...this.detailRows, ...this.totalRows]

    // 1ページ分の配列に分割
    const splitCount = 28
    let pageRows:Row[] = []
    const pages:Row[][] = []

    allrows.forEach(value => {
      // 以下の場合はページ送り
      // splitCount行目
      // (splitCount)行目かつ青行の場合（ヘッダーだけ前ページに出るのを防ぐ）
      if (pageRows.length === splitCount || (pageRows.length === splitCount - 1 && value.isCategoryRow)) {
        pages.push([...pageRows])
        pageRows = []
      }
      pageRows.push(value)
    })
    // 最終ページを追加
    if (pageRows.length > 0) {
      pages.push([...pageRows])
    }
    return pages
  }

  // データ行（明細）
  private get detailRows(): Row[] {
    // カテゴリの配列がそれぞれ修繕項目の配列を持っているものを、フラットな1つの配列に変換
    return this.detail?.detailRows?.categories.flatMap(category => {
      const periods = category.items.map(item => {
        const cells = item.periods.reduce((acc: Record<string, Cell>, current) => {
          acc[getPeriodId(current.period)] = { prospect: current.prospect ?? undefined, performance: current.performance ?? undefined }
          return acc
        }, {})

        return { rowLabel: item.itemLabel, cells: cells, itemTotal: { total: item.total } }
      })

      return [
        { rowLabel: category.categoryLabel, isCategoryRow: true },
        ...periods,
      ]
    }) ?? []
  }

  // データ行（合計値）
  private get totalRows(): Row[] {
    if (!this.detail || !this.detail.totalRows?.rowTotal) return []
    const taxExcludedCells : Record<string, Cell> = {}
    const taxCells : Record<string, Cell> = {}
    const taxIncludedCells : Record<string, Cell> = {}
    const cumulativeCells : Record<string, Cell> = {}

    this.detail.totalRows.periodTotals.forEach(periodTotal => {
      const periodId = getPeriodId(periodTotal.period)
      taxExcludedCells[periodId] = { total: periodTotal.taxExcluded }
      taxCells[periodId] = { total: periodTotal.tax }
      taxIncludedCells[periodId] = { total: periodTotal.taxIncluded }
      cumulativeCells[periodId] = { total: periodTotal.cumulative }
    })

    const rowTotal = this.detail.totalRows.rowTotal
    return [
      { rowLabel: '単年度修繕費', cells: taxExcludedCells, itemTotal: { total: rowTotal.taxExcluded }, isBlueTotalRow: true },
      { rowLabel: '単年度消費税', cells: taxCells, itemTotal: { total: rowTotal.tax }, isBlueTotalRow: true },
      { rowLabel: '単年度合計', cells: taxIncludedCells, itemTotal: { total: rowTotal.taxIncluded }, isGrayTotalRow: true },
      { rowLabel: '修繕費累計', cells: cumulativeCells, itemTotal: { total: rowTotal.cumulative }, isGrayTotalRow: true },
    ]
  }

  private get myCurrentBuilding():string | undefined {
    return buildingsModule.buildingDetailGet?.buildingName
  }
}
