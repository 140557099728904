











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmProgressCircular: () => import('@/components/atoms/SmProgressCircular.vue'),
  }
})
export default class SmSnackbar extends Vue {
  @Prop({ required: true, default: true })
  showOverlay!: boolean
}
