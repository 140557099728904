

















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { SECTION_TYPES } from '@/constants/schema-constants'
import { QuestionnaireDetailGetRequest, QuestionnaireDetailGetResponse, QuestionnaireSection } from '@/dtos/questionnaires/get'
import { convertIndexIntoSerialChar } from '@/libs/add-alphabet-provider'
import { questionnairesModule } from '@/stores/questionnaires-store'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmQuestionAnswer: () => import('@/components/molecules/SmQuestionAnswer.vue'),
  }
})
export default class DetailSubPage extends Vue {
  @Prop({ required: true, default: '' })
  private readonly questionnaireId!: string

  async created(): Promise<void> {
    await questionnairesModule.fetchQuestionnaireDetail(new QuestionnaireDetailGetRequest(this.questionnaireId))
  }

  private get questionnaire(): QuestionnaireDetailGetResponse {
    return questionnairesModule.questionnaireDetail(this.questionnaireId) ?? new QuestionnaireDetailGetResponse()
  }

  private get questionSections(): QuestionnaireSection[] {
    return this.questionnaire.questionnaireSections.filter(s => s.questionnaireSectionType === SECTION_TYPES.QUESTION)
  }

  private get optionSection(): QuestionnaireSection | undefined {
    return this.questionnaire.questionnaireSections.find(s => s.questionnaireSectionType === SECTION_TYPES.OPINION) ?? undefined
  }

  private _getSectionName(idx: number): string { return `回答セクション${convertIndexIntoSerialChar(idx)}` }
}
