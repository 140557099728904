










































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { generateUuid } from '@/libs/uuid-generator'
import type { TargetOwnerType, TransitionTo } from '@/constants/schema-constants'
import { MATERIAL_TYPES, TARGET_OWNER_TYPE, TRANSITION_TO } from '@/constants/schema-constants'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { User } from '@/dtos/commons'
import { OwnersGetRequest } from '@/dtos/owners/get'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { ownersModule } from '@/stores/owners-store'
import { Header } from '@/components/molecules/SmTableData.vue'
import { Tab } from '@/components/molecules/SmTabsInPage.vue'
import { UploadedData } from '@/pages/collation-results/detail/CollationResultDetailUploadDataArea.vue'
import { FrontStaffFormInputs } from '@/pages/buildings/building-edit/FrontStaffPostModal.vue'
import { LmStaffFormInputs } from '@/pages/buildings/building-edit/LmStaffPostModal.vue'
import { CollationResultTableItem } from '@/components/molecules/SmTableCollationResult.vue'

class TableItem {
  ideaId!: string
  ideaTitle!: string
  postedAt!: string
  deadlineDateTime!: string
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialInputArea: () => import('@/components/molecules/SmMaterialInputArea.vue'),
    SmTabsInPage: () => import('@/components/molecules/SmTabsInPage.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmTableCollationResult: () => import('@/components/molecules/SmTableCollationResult.vue'),
    SmTableData: () => import('@/components/molecules/SmTableData.vue'),

    CollationResultDetailUploadDataArea: () => import('@/pages/collation-results/detail/CollationResultDetailUploadDataArea.vue'),
    FrontStaffPostModal: () => import('@/pages/buildings/building-edit/FrontStaffPostModal.vue'),
    LmStaffPostModal: () => import('@/pages/buildings/building-edit/LmStaffPostModal.vue'),
    RelatedOwnerSelectModal: () => import('@/pages/owners/RelatedOwnerSelectModal.vue'),
    OwnerGroupSelectModal: () => import('@/pages/owner-notifications/owner-group-select/OwnerGroupSelectModal.vue'),
    TransitionToSelectModal: () => import('@/pages/owner-notifications/transition-to-select/TransitionToSelectModal.vue'),

  }
})

export default class HelloWorldPage5 extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  files: File[] = []

  private singleSelected = ''

  inputText = ''
  keyword = ''

  // inputText, keywordを各モーダルで共通利用しているため、それぞれのモーダルごとに別にしたほうがよいかも（サンプルのため未対応）
  private ideaInputText = ''
  private ideaKeyword = ''
  private adminIdeaInputText = ''
  private adminIdeaKeyword = ''
  private onlineResolutionInputText = ''
  private onlineResolutionKeyword = ''
  private gmResolutionInputText = ''
  private gmResolutionKeyword = ''

  private currentBuildingId = '4920c22c-86fa-463f-ac1e-b36c07e72ecf'
  private currentTargetOwnerType: TargetOwnerType = TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD
  private currentTargetId = ''
  private currentTransitionType: TransitionTo = TRANSITION_TO.IDEA.OWNER
  private currentTargetTransitionId = ''

  // 区分所有者グループ選択ダイアログで選択されているアイデア/決議のタイトル
  private selectedTitle = ''
  // 遷移先全選択ダイアログで選択されているアイデア/決議のタイトル
  private selectedTransitionToTitle = ''

  tabs: Tab[] = [
    new Tab('tab1', 'タブ1', false),
    new Tab('tab2', 'タブ2', false),
    new Tab('tab3', 'タブ3', false),
  ]

  currentTab = ''// this.tabs[0].key // デフォルトで表示したいタブのkeyを設定

  tableHeaders: Header[] = [
    new Header({ text: '投稿日時', value: 'postedAt', filterable: false, width: '198px' }),
    new Header({ text: '賛同締切日時', value: 'deadlineDateTime', filterable: false, width: '198px' }),
    new Header({ text: 'タイトル', value: 'ideaTitle' }),
  ]

  tableItems: TableItem[] = [
    { ideaId: 'idea1', ideaTitle: '共用部の蛍光灯の変更について', postedAt: '2021年01月03日(月) 12:00', deadlineDateTime: '2021年01月03日(月) 12:00' },
    { ideaId: 'idea2', ideaTitle: 'テストアイデア123', postedAt: '2020年12月30日(月) 12:00', deadlineDateTime: '2020年12月30日(月) 12:00' },
  ]

  showSelect = true

  async created(): Promise<void> {
    // テスト用: 物件詳細情報を取得してキャッシュする（物件詳細情報はcurrentBuildingのみ取得可能なため物件IDも設定）
    currentStateModule.setCurrentBuilding(this.currentBuildingId)
    buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.currentBuildingId))
  }

  private ownerGroupSelectModalKey = generateUuid()
  private isOwnerGroupSelectModalVisible = false

  openOwnerGroupSelectModal(): void {
    this.ownerGroupSelectModalKey = generateUuid()
    this.isOwnerGroupSelectModalVisible = true
  }

  /**
   * 区分所有者グループ選択ダイアログで「選択する」をクリックした際に呼ばれる
   * @param targetOwnerType 選択したタブの情報（4: アイデア, 2: オンライン決議, 3: 総会決議 のいずれか）
   * @param targetId 選択したタブのアイデアID or 決議ID
   */
  selectOwnerGroup(targetOwnerType: TargetOwnerType, targetId: string): void {
    this.currentTargetOwnerType = targetOwnerType
    this.currentTargetId = targetId

    switch (targetOwnerType) {
      case TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD:
        // 未読のアイデア： buildingAdminIdeasGet
        this.selectedTitle = buildingsModule.buildingAdminIdeasGet.ideas.find(idea => idea.ideaId === targetId)?.title ?? ''
        break
      case TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED:
        // 未投票のオンライン決議： buildingOnlineResolutionsGet
        this.selectedTitle = buildingsModule.buildingOnlineResolutionsGet.resolutions.find(resolution => resolution.resolutionId === targetId)?.title ?? ''
        break
      case TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED:
        // 未意思表明の総会決議： buildingGmResolutionsGet
        this.selectedTitle = buildingsModule.buildingGmResolutionsGet.resolutions.find(resolution => resolution.resolutionId === targetId)?.title ?? ''
        break
      case TARGET_OWNER_TYPE.ALL:
      case TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED:
        // TARGET_OWNER_TYPEに 2, 3, 4以外が指定された場合はエラーとする
        throw new Error('The target owner type(' + targetOwnerType + ') is unavailable')
      default: assertExhaustive(targetOwnerType)
    }
  }

  private transitionToSelectModalKey = generateUuid()
  private isTransitionSelectModalVisible = false

  openTransitionToSelectModal(): void {
    this.transitionToSelectModalKey = generateUuid()
    this.isTransitionSelectModalVisible = true
  }

  /**
   * 遷移先全選択ダイアログで「選択する」をクリックした際に呼ばれる
   * @param transitionType 選択したタブの情報（110: アイデア(区分所有者), 120: アイデア(賛同・意見受付用), 140: オンライン決議, 130: 総会決議 のいずれか）
   * @param targetId 選択したタブのアイデアID or 決議ID
   */
  selectTransitionTo(transitionType: TransitionTo, targetId: string): void {
    this.currentTransitionType = transitionType
    this.currentTargetTransitionId = targetId

    switch (transitionType) {
      case TRANSITION_TO.IDEA.OWNER:
        // アイデア（区分所有者）： buildingOwnerIdeasGet
        this.selectedTransitionToTitle = buildingsModule.buildingOwnerIdeasGet.ideas.find(idea => idea.ideaId === targetId)?.title ?? ''
        break
      case TRANSITION_TO.IDEA.ADMIN:
        // アイデア（賛同・意見受付用）： buildingAdminIdeasGet
        this.selectedTransitionToTitle = buildingsModule.buildingAdminIdeasGet.ideas.find(idea => idea.ideaId === targetId)?.title ?? ''
        break
      case TRANSITION_TO.RESOLUTION.ONLINE:
        // オンライン決議： buildingOnlineResolutionsGet
        this.selectedTransitionToTitle = buildingsModule.buildingOnlineResolutionsGet.resolutions.find(resolution => resolution.resolutionId === targetId)?.title ?? ''
        break
      case TRANSITION_TO.RESOLUTION.GENERAL_MEETING:
        // 総会決議： buildingGmResolutionsGet
        this.selectedTransitionToTitle = buildingsModule.buildingGmResolutionsGet.resolutions.find(resolution => resolution.resolutionId === targetId)?.title ?? ''
        break
      case TRANSITION_TO.QA:
      case TRANSITION_TO.REPORT:
      case TRANSITION_TO.TICKET.DETAIL:
      case TRANSITION_TO.TICKET.DETAIL_TASK:
      case TRANSITION_TO.NOTICE:
      case TRANSITION_TO.STAFF_DETAIL.FRONT:
      case TRANSITION_TO.STAFF_DETAIL.LIFE_MANAGER:
      case TRANSITION_TO.CASYS_RESULTS:
        // transitionType に110, 120, 130, 140以外が指定された場合はエラーとする
        throw new Error('The transition type(' + transitionType + ') is unavailable')
      default: assertExhaustive(transitionType)
    }
  }

  frontStaffPostModalKey = generateUuid()
  isFrontStaffPostModalVisible = false

  openFrontStaffPostModal(): void {
    this.frontStaffPostModalKey = generateUuid()
    this.isFrontStaffPostModalVisible = true
  }

  postFrontStaff(formInputs: FrontStaffFormInputs): void {
    console.log('postFrontStaff', formInputs)
    // フロント担当登録APIを呼び出す
    this.isFrontStaffPostModalVisible = false
  }

  lmStaffPostModalKey = generateUuid()
  isLmStaffPostModalVisible = false

  openLmStaffPostModal(): void {
    this.lmStaffPostModalKey = generateUuid()
    this.isLmStaffPostModalVisible = true
  }

  postLmStaff(formInputs: LmStaffFormInputs): void {
    console.log('postLmStaff', formInputs)
    // ライフマネージャー登録APIを呼び出す
    this.isLmStaffPostModalVisible = false
  }

  selectModalKey = generateUuid()
  isSelectModalVisible = false

  async openRelatedOwnerSelectModal(): Promise<void> {
    const req = new OwnersGetRequest()
    req.isInitialAuth = false
    await ownersModule.fetchOwners(req)

    this.selectModalKey = generateUuid()
    this.isSelectModalVisible = true
  }

  selectedOwner(selectedUser: User): void {
    console.log('selectedOwner', selectedUser)
  }

  uploadedData: UploadedData = { uploadedAt: '2021年07月02日(月)11:00', userName: '秋元真夏', fileCount: 10, differenceCount: 7 }

  collationResultItems:CollationResultTableItem[] = [
    { unitId: 'unit1', casysRoomNumber: '201', casysOwnerCode: '00123456', casysFullName: '高山一実', roomNumber: '201', fullName: '生田絵梨花', isUpdated: false },
    { unitId: 'unit2', casysRoomNumber: '201', casysOwnerCode: '00123456', casysFullName: '高山一実', roomNumber: '201', fullName: '生田絵梨花', isUpdated: true },
    { unitId: 'unit3', roomNumber: '201', fullName: '生田絵梨花', isUpdated: false },
    { unitId: 'unit4', casysRoomNumber: '201', casysOwnerCode: '00123456', casysFullName: '生田絵梨花', roomNumber: '201', fullName: '生田絵梨花', isUpdated: false },
  ]

  fixOwnerName(item: CollationResultTableItem): void {
    // 区分所有者名を修正する
    console.log('fixOwnerName ', item)
  }

  goToOwnerDetail(unitId: string): void {
    // 区分所有者詳細画面に遷移する
    console.log('goToOwnerDetail ', unitId)
  }
}
