


















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmLabelValueDisplay extends Vue {
  @Prop({ required: true })
  label!:string

  @Prop({ required: true })
  value!:string
}
