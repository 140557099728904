












































import { Vue, Component, Prop } from 'vue-property-decorator'
import { MaterialFormInputDto } from '@/dtos/commons'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'

export class UpdateEditFormInputs {
  updateBody!: string
  material?: MaterialFormInputDto | null = null
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),

    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
  }
})
export default class UpdateEditCard extends Vue {
  created(): void {
    this.inputs.updateBody = this.updateBody
    this.inputs.material = deepCopy(
      this.material,
      { material: new ColumnToType(MaterialFormInputDto) },
      'material'
    )
  }

  inputs = new UpdateEditFormInputs()

  @Prop({ default: '' })
  private readonly updateBody!: string

  @Prop({ default: null })
  private readonly material?: MaterialFormInputDto | null

  @Prop()
  private readonly fieldIdprefix?: string

  private get _caption(): string | undefined { return this.inputs.material?.caption }
  private set _caption(newValue: string | undefined) {
    if (!this.inputs.material) return
    this.inputs.material.caption = newValue
  }

  onClickUpdateBtn(): void {
    this.$emit('execute', this.inputs)
  }
}
