var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sm__dashboard"},[_c('div',{staticClass:"mt-6"},[_c('sm-tickets-tasks-summary',{attrs:{"near-deadline-tasks-title":"一週間期限の担当タスク","tickets-icon-name":"$content_copy_round","tickets-title":"あなたの担当チケット","tasks-icon-name":"$task_alt_round","tasks-title":"あなたの担当タスク","tickets-tasks-summary":_vm.dashboardSummary},on:{"click-tickets-count":_vm.clickTicketsCount,"click-tasks-count":_vm.clickTasksCount,"click-near-deadline-tasks-count":_vm.clickNearDeadlineTasksCount,"click-expired-tasks-count":_vm.clickExpiredTasksCount}})],1),_c('div',{staticClass:"mt-6 px-6 py-4 sm__background-white sm__ticket-table"},[_c('sm-expansion-area',{attrs:{"header-title":_vm.personalDeadlineTaskHeaderTitle,"icon-color":"black","sub-icon-name":"$local_fire_department_outlined","sub-icon-color":"caution","title-color":"black","title-format":"sm__title","link-title":"タスク一覧"},on:{"go-target-page":_vm.goTaskListPageExpired},model:{value:(_vm.isFilterMenuOpenOfPersonalDeadlineTask),callback:function ($$v) {_vm.isFilterMenuOpenOfPersonalDeadlineTask=$$v},expression:"isFilterMenuOpenOfPersonalDeadlineTask"}},[_c('div',{staticClass:"mt-4"},[_c('sm-table-data-external-paging',{attrs:{"headers":_vm.PersonalDeadlineTaskTableHeaders,"items":_vm.deadlineTasks,"item-key":"ticket_task_id","content-name":"あなたの期限切れ担当タスク","total-item-length":999,"is-transition-click":"","is-init-search":""},on:{"click":_vm.goToTicketDetailPageTaskTab},scopedSlots:_vm._u([{key:"item.buildingName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.buildingId)?_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.buildingName,"format":"sm__data","color":"black"}}):_c('sm-text',{attrs:{"text":"未設定","format":"sm__data","color":"black"}})],1)]}},{key:"item.ticketType",fn:function(ref){
var item = ref.item;
return [_c('sm-badge-category',{attrs:{"icon":item.ticketTypeIcon,"icon-color":item.ticketTypeIconColor,"text":item.ticketTypeText,"text-style":item.ticketTypeTextStyle}})]}},{key:"item.ticketTaskName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.ticketTaskName,"format":"sm__data","color":"black"}})],1)]}},{key:"item.ticketName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.ticketName,"format":"sm__data","color":"black"}})],1)]}}],null,true)})],1)])],1),_c('div',{staticClass:"mt-6 px-6 py-4 sm__background-white sm__ticket-table"},[_c('sm-expansion-area',{attrs:{"header-title":_vm.personalTicketHeaderTitle,"icon-color":"black","title-color":"black","title-format":"sm__title","link-title":"チケット一覧"},on:{"go-target-page":_vm.goTicketsListPage},model:{value:(_vm.isFilterMenuOpenOfPersonalTicket),callback:function ($$v) {_vm.isFilterMenuOpenOfPersonalTicket=$$v},expression:"isFilterMenuOpenOfPersonalTicket"}},[_c('div',{staticClass:"mt-4"},[_c('sm-table-data-external-paging',{attrs:{"headers":_vm.PersonalTicketTableHeaders,"items":_vm.tickets,"item-key":"ticket_id","content-name":"あなたの担当チケット","total-item-length":999,"is-transition-click":"","is-init-search":""},on:{"click":_vm.goToTicketDetailPage},scopedSlots:_vm._u([{key:"item.buildingName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.buildingId)?_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.buildingName,"format":"sm__data","color":"black"}}):_c('sm-text',{attrs:{"text":"未設定","format":"sm__data","color":"black"}})],1)]}},{key:"item.ticketType",fn:function(ref){
var item = ref.item;
return [_c('sm-badge-category',{attrs:{"icon":item.ticketTypeIcon,"icon-color":item.ticketTypeIconColor,"text":item.ticketTypeText,"text-style":item.ticketTypeTextStyle}})]}},{key:"item.ticketName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.ticketName,"format":"sm__data","color":"black"}})],1)]}}],null,true)})],1)])],1),_c('div',{staticClass:"my-6 px-6 py-4 sm__background-white sm__ticket-table"},[_c('sm-expansion-area',{attrs:{"header-title":_vm.personalTaskHeaderTitle,"icon-color":"black","title-color":"black","title-format":"sm__title","link-title":"タスク一覧"},on:{"go-target-page":_vm.goTaskListPage},model:{value:(_vm.isFilterMenuOpenOfPersonalTask),callback:function ($$v) {_vm.isFilterMenuOpenOfPersonalTask=$$v},expression:"isFilterMenuOpenOfPersonalTask"}},[_c('div',{staticClass:"mt-4"},[_c('sm-table-data-external-paging',{attrs:{"headers":_vm.PersonalTaskTableHeaders,"items":_vm.tasks,"item-key":"ticket_task_id","content-name":"あなたの担当タスク","total-item-length":999,"is-transition-click":"","is-init-search":""},on:{"click":_vm.goToTicketDetailPageTaskTab},scopedSlots:_vm._u([{key:"item.ticketTaskState",fn:function(ref){
var item = ref.item;
return [_c('sm-chip',{attrs:{"text":item.ticketTaskState,"color-type":item.chipColor}})]}},{key:"item.buildingName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.buildingId)?_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.buildingName,"format":"sm__data","color":"black"}}):_c('sm-text',{attrs:{"text":"未設定","format":"sm__data","color":"black"}})],1)]}},{key:"item.ticketType",fn:function(ref){
var item = ref.item;
return [_c('sm-badge-category',{attrs:{"icon":item.ticketTypeIcon,"icon-color":item.ticketTypeIconColor,"text":item.ticketTypeText,"text-style":item.ticketTypeTextStyle}})]}},{key:"item.ticketTaskName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.ticketTaskName,"format":"sm__data","color":"black"}})],1)]}},{key:"item.ticketName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.ticketName,"format":"sm__data","color":"black"}})],1)]}}],null,true)})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }