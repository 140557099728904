export class SimpleRepairsPerformancesPostRequest {
  period!: number
  repairCost!: number
  reservedAmount!: number

  constructor(period: number, repairCost: number, reservedAmount: number) {
    this.period = period
    this.repairCost = repairCost
    this.reservedAmount = reservedAmount
  }
}
