


























































































import { PaymentDetail } from '@/dtos/reserve-funds/get'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmBadgeInline: () => import('@/components/atoms/SmBadgeInline.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

  }
})
export default class MonthlyAmountTable extends Vue {
  @Prop({ required: true, default: () => [] })
  paymentPlans!:PaymentDetail[]
}
