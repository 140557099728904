import { APIClientBase } from './api-client-base'
import { OwnerNotificationsArchivePutRequest } from '@/dtos/owner-notifications/archive/put'
import { OwnerNotificationsDeleteRequest } from '@/dtos/owner-notifications/delete'
import { OwnerNotificationDetailGetRequest, OwnerNotificationDetailGetResponse } from '@/dtos/owner-notifications/get-detail'
import { OwnerNotificationsDraftPostRequest } from '@/dtos/owner-notifications/draft/post'
import { OwnerNotificationsPostRequest } from '@/dtos/owner-notifications/post'
import { OwnerNotificationsPutRequest } from '@/dtos/owner-notifications/put'
import { OwnerNotificationsSearchPostRequest, OwnerNotificationsSearchPostResponse } from '@/dtos/owner-notifications/search/post'

class OwnerNotificationsClient extends APIClientBase {
  constructor() {
    super('/owner-notifications')
  }

  // 区分所有者お知らせ一覧取得
  postOwnerNotificationsSearch(req: OwnerNotificationsSearchPostRequest): Promise<OwnerNotificationsSearchPostResponse | void> {
    return this.post('search', req, OwnerNotificationsSearchPostResponse, false)
  }

  // 区分所有者お知らせ詳細取得
  getOwnerNotificationDetail(req: OwnerNotificationDetailGetRequest): Promise<OwnerNotificationDetailGetResponse> {
    return this.get(`${req.ownerNotificationId}`, '', OwnerNotificationDetailGetResponse)
  }

  // 区分所有者お知らせ投稿
  postOwnerNotification(req: OwnerNotificationsPostRequest) {
    return this.post('', req)
  }

  // 区分所有者お知らせ下書き保存
  postOwnerNotificationDraft(req: OwnerNotificationsDraftPostRequest) {
    return this.post('draft', req)
  }

  // 区分所有者お知らせ更新
  putOwnerNotification(req: OwnerNotificationsPutRequest) {
    return this.put(`${req.ownerNotificationId}`, req)
  }

  // 区分所有者お知らせ更新（アーカイブ）
  putOwnerNotificationsArchive(req: OwnerNotificationsArchivePutRequest): Promise<void> {
    return this.put(`${req.ownerNotificationId}/archive`, req)
  }

  // 区分所有者お知らせ削除
  deleteOwnerNotifications(req: OwnerNotificationsDeleteRequest): Promise<void> {
    return this.delete(`${req.ownerNotificationId}`)
  }
}

export const ownerNotificationsClient = new OwnerNotificationsClient()
