










































































































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { buildingsModule } from '@/stores/buildings-store'
import { BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { Report } from '@/dtos/reports/get'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { OPINION_STATES } from '@/constants/schema-constants'
import { getDeletedMessage } from '@/libs/opinion-state-handler'
@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

  }
})
export default class SmReportCard extends Mixins(CurrentAdminManager) {
  @Prop({ required: true })
  report!: Report

  private get deleteMessage(): string | undefined { return getDeletedMessage(this.report.opinion.opinionState) }

  private get building(): BuildingDetailGetResponse | null { return buildingsModule.buildingDetailGet }

  private get isConsultationUse(): boolean | undefined {
    return this.building?.isConsultationUse
  }

  OPINION_STATES = Object.freeze(OPINION_STATES)
}
