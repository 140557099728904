import { Type } from 'class-transformer'
import { ChartType, PostTimingType } from '@/constants/schema-constants'
import { Material } from '@/dtos/commons'

export class ImplementationResultQuestion {
  questionnaireQuestionId!: string
  questionType!: number
  chartType?: ChartType
  sortOrderNum!: number
}

export class ImplementationResult {
  title?: string
  explanation?: string
  @Type(() => Material)
  material?: Material | null = null

  targetQuestionnaireIds?: string[]
  @Type(() => ImplementationResultQuestion)
  implementationResultQuestions?: ImplementationResultQuestion[]
}

export class QuestionnaireOpinionAndAnswerSection {
  questionnaireChoiceAnswerIds!: string[]
  answerBody!: string
  sortOrderNum!: number
  isModelAnswer?: boolean
}

export class QuestionnaireOpinionAndAnswer {
  @Type(() => QuestionnaireOpinionAndAnswerSection)
  questionnaireOpinionAndAnswerSections!: QuestionnaireOpinionAndAnswerSection[]
}

export class QuestionnaireReportsPostRequest {
  questionnaireReportId?: string
  reportTitle!: string
  reportExplanation!: string
  @Type(() => Material)
  reportMaterial?: Material

  postTimingType!:PostTimingType
  postedAt?: string

  @Type(() => ImplementationResult)
  implementationResult?: ImplementationResult

  resultTitle?: string
  resultExplanation?: string
  @Type(() => Material)
  resultMaterial?: Material

  @Type(() => QuestionnaireOpinionAndAnswer)
  questionnaireOpinionAndAnswer?: QuestionnaireOpinionAndAnswer

  version?: number
}
