import type { ResolutionType } from '@/constants/schema-constants'
import { Type } from 'class-transformer'

export class Requirement {
  resolutionType!: ResolutionType
  requirement!: string
}

export class RequirementsGetResponse {
  @Type(() => Requirement) requirements: Requirement[] = []
}
