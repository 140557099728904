



























import { Vue, Component, Prop } from 'vue-property-decorator'
import { MaterialType, MATERIAL_TYPES } from '@/constants/schema-constants'
import { MaterialFormInputDto } from '@/dtos/commons'
import { blobUrlModule } from '@/stores/blob-url-store'

export type UploadedFile = Pick<MaterialFormInputDto, 'file' | 'materialType' | 'materialUrl'>

class MaterialTypeDetail {
  label!: string
  fileExtensions!: string[]
  mimeType!: string
}

const ACCEPTABLE_FILES: Record<MaterialType, MaterialTypeDetail[]> = {
  [MATERIAL_TYPES.IMAGE]: [
    { label: 'jpg', fileExtensions: ['.jpg', '.jpeg'], mimeType: 'image/jpeg' },
    { label: 'png', fileExtensions: ['.png'], mimeType: 'image/png' },
    { label: 'gif', fileExtensions: ['.gif'], mimeType: 'image/gif' }
  ],
  [MATERIAL_TYPES.VIDEO]: [
    { label: 'mp4', fileExtensions: ['.mp4'], mimeType: 'video/mp4' },
    { label: 'mov', fileExtensions: ['.mov'], mimeType: 'video/quicktime' }
  ],
  [MATERIAL_TYPES.PDF]: [
    { label: 'pdf', fileExtensions: ['.pdf'], mimeType: 'application/pdf' }
  ],
  [MATERIAL_TYPES.EXCEL]: [
    { label: 'xlsx', fileExtensions: ['.xlsx'], mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' } // no '.xls'
  ],
  [MATERIAL_TYPES.EXCEL_OLD]: [
    { label: 'xls', fileExtensions: ['.xls'], mimeType: 'application/vnd.ms-excel' }
  ],
  [MATERIAL_TYPES.ZIP]: [
    { label: 'zip', fileExtensions: ['.zip'], mimeType: 'application/zip' }
  ],
  [MATERIAL_TYPES.EXCEL_MACRO]: [
    { label: 'xlsm', fileExtensions: ['.xlsm'], mimeType: 'application/vnd.ms-excel.sheet.macroEnabled.12' }
  ]
}

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmMaterialInputBase extends Vue {
  @Prop({ default: () => [] })
  accepts!: MaterialType[]

  @Prop({ default: 'ファイルを添付' })
  text!: string

  private get _accepts(): { label: string, types: string } {
    const labels: string[] = []
    const types: string[] = []

    for (const type of this.accepts) {
      labels.push(...ACCEPTABLE_FILES[type].map(file => file.label))
      types.push(...ACCEPTABLE_FILES[type].flatMap(file => file.fileExtensions))
    }
    return { label: '形式：' + labels.join(','), types: types.join(',') }
  }

  readonly INPUT_REF = 'uploader'
  selectedFile: File | undefined

  async onFileSelected(event: InputEvent): Promise<void> {
    const file = (event.target as HTMLInputElement)?.files?.item(0)
    if (!file) {
      this.$emit('failed')
      return
    }

    const materialType = this.getMaterialType(file.type)
    if (!materialType) {
      this.$emit('failed')
      return
    }

    if (materialType === MATERIAL_TYPES.EXCEL || materialType === MATERIAL_TYPES.EXCEL_OLD || MATERIAL_TYPES.EXCEL_MACRO || materialType === MATERIAL_TYPES.PDF) {
      this.$emit('selected', { file, materialType })
    } else if (materialType === MATERIAL_TYPES.VIDEO) {
      const materialUrl = window.URL.createObjectURL(file)
      blobUrlModule.putBlobUrl(materialUrl)
      const uploaded: UploadedFile = { file, materialType, materialUrl }
      this.$emit('selected', uploaded)
    } else {
      const materialUrl = await this.toDataUrl(file)
      const uploaded: UploadedFile = { file, materialType, materialUrl }
      this.$emit('selected', uploaded)
    }
  }

  toDataUrl(file: File): Promise<string> {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = f => resolve(f.target?.result as string)
      reader.readAsDataURL(file)
    })
  }

  getMaterialType(mimeType?: string): MaterialType | undefined {
    if (!mimeType) return undefined

    for (const type of this.accepts) {
      if (ACCEPTABLE_FILES[type].map(detail => detail.mimeType).includes(mimeType)) return type
    }
    return undefined
  }
}
