import { Type } from 'class-transformer'

export class PaymentPlanGetRequest {
  // 全件取得を前提にするため、skip・takeは多めにデフォルトを設定しておく
  skip = 0
  take = 1000
  constructor(init?:Partial<PaymentPlanGetRequest>) {
    Object.assign(this, init)
  }
}
export class PaymentPlan {
  paymentPlanId!:string
  plannedAt!:string
  planCode!:string
  planName!:string
}

export class PaymentPlansGetResponse {
  @Type(() => PaymentPlan)paymentPlans:PaymentPlan[] = []
  // TODO 総件数取得（今の所使わない）
}
