import { ManagementOperationsSearchCommonPostRequest, ManagementOperationsSearchCommonPostResponse, ManagementOperationsCommon } from '../../common/search/post'

export class ManagementOperationsOnboardingSearchPostRequest extends ManagementOperationsSearchCommonPostRequest {
  smIntroducedYear?: number
  smIntroducedMonth?: number
}

export class ManagementOperationsOnboarding extends ManagementOperationsCommon {
  smIntroducedDate!: string
}

export class ManagementOperationsOnboardingSearchPostResponse extends ManagementOperationsSearchCommonPostResponse {
  smIntroducedYearList!: number[]
}
