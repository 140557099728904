import { APIClientBase } from './api-client-base'

import { ConsultationGroupsGetRequest, ConsultationGroupsGetResponse } from '@/dtos/consultations/get'
import { ConsultationDetailGetRequest, ConsultationDetailGetResponse } from '@/dtos/consultations/get-detail'
import { ConsultationsPostRequest } from '@/dtos/consultations/post'
import { ConsultationsPutRequest, ConsultationsPutResponse } from '@/dtos/consultations/put'
import { ConsultationsDeleteRequest } from '@/dtos/consultations/delete'
import { ConsultationsReadsPostRequest } from '@/dtos/consultations/reads/post'

import { ConsultationDraftGetRequest, ConsultationDraftGetResponse } from '@/dtos/consultations/draft/get'
import { ConsultationDraftPostRequest } from '@/dtos/consultations/draft/post'
import { ConsultationDraftDeleteRequest } from '@/dtos/consultations/draft/delete'

import { ConsultationReactionPostRequest } from '@/dtos/consultations/reactions/post'
import { ConsultationReactionDeleteRequest } from '@/dtos/consultations/reactions/delete'

class ConsultationsClient extends APIClientBase {
  constructor() {
    super('/consultations')
  }

  getConsultationGroups(req: ConsultationGroupsGetRequest): Promise<ConsultationGroupsGetResponse> {
    return this.get('', req, ConsultationGroupsGetResponse, false)
  }

  getConsultationDetail(req: ConsultationDetailGetRequest): Promise<ConsultationDetailGetResponse> {
    return this.get(`${req.userId}`, req, ConsultationDetailGetResponse, false)
  }

  postConsultations(req: ConsultationsPostRequest): Promise<void> {
    return this.post(`${req.userId}`, req)
  }

  putConsultations(req: ConsultationsPutRequest): Promise<ConsultationsPutResponse | void> {
    return this.put(`${req.userId}/${req.consultationId}`, req, ConsultationsPutResponse)
  }

  deleteConsultations(req: ConsultationsDeleteRequest): Promise<void> {
    return this.delete(`${req.userId}/${req.consultationId}`)
  }

  // 相談既読登録
  postConsultationsReads(req: ConsultationsReadsPostRequest): Promise<void> {
    return this.post(`${req.userId}/reads`, null, undefined, false)
  }

  // リアクションの登録
  postConsultationReaction(req: ConsultationReactionPostRequest): Promise<void> {
    return this.post(`${req.userId}/${req.consultationId}/reaction`, req, undefined, false)
  }

  // リアクションの削除
  deleteConsultationReaction(req: ConsultationReactionDeleteRequest): Promise<void> {
    return this.delete(`${req.userId}/${req.consultationId}/reaction/${req.assetId}`, undefined, false)
  }

  // 下書きの取得
  getConsultationDraft(req: ConsultationDraftGetRequest): Promise<ConsultationDraftGetResponse> {
    return this.get(`${req.userId}/draft`, null, ConsultationDraftGetResponse, false)
  }

  // 下書きの登録
  postConsultationDraft(req: ConsultationDraftPostRequest): Promise<void> {
    return this.post(`${req.userId}/draft`, req)
  }

  // 下書きの削除
  deleteConsultationDraft(req: ConsultationDraftDeleteRequest): Promise<void> {
    return this.delete(`${req.userId}/draft/${req.consultationId}`)
  }
}

export const consultationsClient = new ConsultationsClient()
