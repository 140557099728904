





























































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'

import { assertExhaustive } from '@/libs/exhaustive-helper'
import { getAreaLabel, getCaseLabel, getInformationSourceLabel, getTicketLabel } from '@/libs/type-handler'
import { generateUuid } from '@/libs/uuid-generator'
import { openNewTab } from '@/libs/open-new-tab'

import { RadioOption } from '@/components/atoms/SmRadio.vue'

import type { AreaType, CaseType, InformationSourceType, ResolutionState, TicketType } from '@/constants/schema-constants'
import { CASE_TYPES, INFORMATION_SOURCE_TYPES, NEW_TAB_TRANSITION_TO, TICKET_INQUIRY_SOURCE_TYPES, TICKET_STATES, TICKET_TYPES, TRANSITION_TO, RESOLUTION_STATES, RESOLUTION_TYPES } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { BuildingAdminIdea, BuildingAdminIdeasGetRequest } from '@/dtos/buildings/admin-idea/get'
import { BuildingOwnerDto, BuildingOwnersGetRequest } from '@/dtos/buildings/owners/get'
import { User } from '@/dtos/commons'
import { AdminIdeaDecisionGetResponse } from '@/dtos/ideas/admin-idea/decision/get'
import { TicketBuildingDto, TicketDetailGetRequest, TicketDetailGetResponse, TicketInquirySourceDto, TicketRelatedInformationDto } from '@/dtos/tickets/get-detail'
import { TicketGetResponse, TicketsGetRequest } from '@/dtos/tickets/get'
import { TicketsPostRequestBuilding, TicketsPostRequestOwnerUser } from '@/dtos/tickets/post'
import { TicketBaseIdea, TicketDetailPutRequest } from '@/dtos/tickets/put'

import { buildingsModule } from '@/stores/buildings-store'
import { NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { ticketsModule } from '@/stores/tickets-store'

// チケット一覧リンクの絞込条件の内容を保存する箱
class InputParams {
    buildings: Building[] = []
    owners: BuildingOwnerDto[] = []
}

// 更新内容
class FormInputs {
  ticketType: TicketType = TICKET_TYPES.IDEA
  ticketName = ''
  receptionDate = ''
  informationSourceType: InformationSourceType = INFORMATION_SOURCE_TYPES.IDEA_POSTED
  informationSource = ''
  caseType: CaseType = CASE_TYPES.OWNER_IDEA
  inquiryBody? = ''
  inquirySources: TicketInquirySourceDto[] = []
  ideaIds: string[] = []
  correspondence? = ''
  isManagementStatusReport = true
  isOperationStatusReport = true
  buildingId? = ''
  ownerUserId? = ''
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    AdminIdeasSelectModal: () => import('@/pages/ideas/admin-idea/AdminIdeasSelectModal.vue'),
    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),
    OwnerSelectModal: () => import('@/pages/owner-notifications/OwnerSelectModal.vue'),
  }
})
export default class DetailSubPage extends Vue {
  TICKET_TYPES = Object.freeze(TICKET_TYPES)
  CHIP_COLORS = Object.freeze(CHIP_COLORS)

  @Prop({ required: true, default: '' })
  private readonly ticketId!: string

  isEditMode = false
  /** チケット編集時の更新内容 */
  inputs = new FormInputs()

  get ticketCommon(): TicketGetResponse {
    return ticketsModule.ticketDetail(this.ticketId) ?? new TicketGetResponse()
  }

  get ticketContent(): TicketDetailGetResponse {
    return ticketsModule.ticketDetailContentGet(this.ticketId) ?? new TicketDetailGetResponse()
  }

  private get isCompleted(): boolean {
    return this.ticketCommon.ticketState === TICKET_STATES.COMPLETED
  }

  async created(): Promise<void> {
    await this.fetchTicketDetailContent()
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999, undefined, undefined, true))
    this.inputs.buildingId = this.ticketContent.building?.buildingId
    this.inputs.ownerUserId = this.ticketContent.ownerUser?.userId
    if (this.inputs.buildingId && this.inputs.ownerUserId) {
      const req = new BuildingOwnersGetRequest()
      req.buildingId = this.inputs.buildingId
      await buildingsModule.fetchBuildingOwners(req)
    }

    // 決議チケットの場合、議案化するアイデアを表示させるために最初に物件に紐づくプランを取得する
    if (this.ticketContent.ticketType === TICKET_TYPES.RESOLUTION && this.ticketContent.building) {
      await buildingsModule.fetchAdminIdeas(new BuildingAdminIdeasGetRequest(this.ticketContent.building.buildingId, this.ticketContent.ticketId))
      this.inputs.ideaIds = this.inputs.inquirySources.map(i => i.inquirySourceId)
    }
    this.inputs.informationSourceType = this.ticketContent.informationSourceType
  }

  async fetchTicketDetail(): Promise<void> {
    await ticketsModule.fetchTicketDetail(new TicketsGetRequest(this.ticketId))
  }

  async fetchTicketDetailContent(): Promise<void> {
    await ticketsModule.fetchTicketDetailContent(new TicketDetailGetRequest(this.ticketId))
  }

  onClickEdit(): void {
    const inputs = new FormInputs()
    inputs.ticketType = this.ticketContent.ticketType
    inputs.ticketName = this.ticketContent.ticketName
    inputs.receptionDate = this.ticketContent.receptionDate
    inputs.informationSourceType = this.ticketContent.informationSourceType
    inputs.informationSource = this.ticketContent.informationSource
    inputs.caseType = this.ticketContent.caseType
    inputs.inquiryBody = this.ticketContent.inquiryBody
    inputs.inquirySources = this.ticketContent.inquirySources
    if (inputs.ticketType === TICKET_TYPES.RESOLUTION) inputs.ideaIds = this.ticketContent.inquirySources.map(e => e.inquirySourceId)
    inputs.correspondence = this.ticketContent.correspondence
    inputs.isManagementStatusReport = this.ticketContent.isManagementStatusReport
    inputs.isOperationStatusReport = this.ticketContent.isOperationStatusReport
    inputs.buildingId = this.ticketContent.building?.buildingId
    inputs.ownerUserId = this.ticketContent.ownerUser?.userId
    this.inputs = inputs

    this.isEditMode = true
  }

  onClickCancel(): void {
    this.isEditMode = false
    this.inputs.buildingId = this.ticketContent.building?.buildingId
    this.inputs.ownerUserId = this.ticketContent.ownerUser?.userId
  }

  async putTicketDetail(): Promise<void> {
    const req = new TicketDetailPutRequest()
    req.ticketId = this.ticketId
    req.ticketType = this.inputs.ticketType
    req.ticketName = this.inputs.ticketName
    req.receptionDate = this.inputs.receptionDate
    req.informationSourceType = this.inputs.informationSourceType
    req.caseType = this.inputs.caseType
    req.isManagementStatusReport = this.inputs.isManagementStatusReport
    req.isOperationStatusReport = this.inputs.isOperationStatusReport
    req.version = this.ticketCommon.version
    // Optional
    req.informationSource = this.inputs.informationSource ?? undefined
    req.inquiryBody = this.inputs.inquiryBody ?? undefined
    req.correspondence = this.inputs.correspondence ?? undefined
    if (this.inputs.ideaIds.length > 0 && this.inputs.informationSourceType === INFORMATION_SOURCE_TYPES.IDEA_POSTED) req.baseIdeas = this.inputs.ideaIds.map((id, index) => new TicketBaseIdea(id, index + 1))
    req.building = this.inputs.buildingId && (this.inputs.ticketType === TICKET_TYPES.MANUAL || this.inputs.ticketType === TICKET_TYPES.RESOLUTION) ? new TicketsPostRequestBuilding(this.inputs.buildingId) : undefined
    req.ownerUser = this.inputs.ownerUserId && this.inputs.ticketType === TICKET_TYPES.MANUAL ? new TicketsPostRequestOwnerUser(this.inputs.ownerUserId) : undefined

    // 確認ダイアログを閉じる
    this.showChangeTypeDialog = false
    // チケット詳細（内容）更新API呼び出し
    await ticketsModule.putTicketDetailContent(req)
    // 更新状態を解除
    this.isEditMode = false
    // チケット詳細（共通）、チケット詳細（内容）取得APIを呼び出してチケット詳細画面を更新
    await Promise.all([
      this.fetchTicketDetail(),
      this.fetchTicketDetailContent()
    ])
  }

  // --------------- リンククリック用処理 ---------------

  onClickInquirySourceLinkByAdminIdea(idea: AdminIdeaDecisionGetResponse): void {
    const inquirySource = new TicketInquirySourceDto()
    inquirySource.inquirySourceId = idea.ideaId
    inquirySource.inquirySourceTitle = idea.title
    inquirySource.inquirySourceType = TICKET_INQUIRY_SOURCE_TYPES.ADMIN_IDEA
    this.onClickInquirySourceLink(inquirySource)
  }

  onClickInquirySourceLink(inquirySource: TicketInquirySourceDto): void {
    if (this.ticketContent.building) {
      this.openInquirySourcePage(this.ticketContent.building.buildingId, inquirySource)
    }
  }

  onClickRelatedInformationLink(): void {
    if (this.ticketContent.building && this.ticketContent.relatedInformation) {
      this.openRelatedInformationPage(this.ticketContent.building.buildingId, this.ticketContent.relatedInformation)
    }
  }

  onClickBuildingLink(): void {
    if (this.ticketContent.building) {
      this.openBuildingDetailPage(this.ticketContent.building.buildingId)
    }
  }

  async onClickBuildingTicketLink(): Promise<void> {
    if (this.ticketContent.building) {
      // 該当のマンションで絞り込まれたチケット一覧に遷移
      await this.goToTicketsListPage(this.ticketContent.building)
    }
  }

  onClickOwnerUserLink(): void {
    if (this.ticketContent.building && this.ticketContent.ownerUser && this.ticketContent.ownerUser.unitId) {
      this.openOwnerUserDetailPage(this.ticketContent.building.buildingId, this.ticketContent.ownerUser.unitId)
    }
  }

  async onClickOwnerUserTicketLink(): Promise<void> {
    if (this.ticketContent.building && this.ticketContent.ownerUser) {
      // 該当の区分所有者で絞り込まれたチケット一覧に遷移
      await this.goToTicketsListPage(this.ticketContent.building, this.ticketContent.ownerUser)
    }
  }

  openInquirySourcePage(buildingId: string, inquirySource: TicketInquirySourceDto): void {
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId

    switch (inquirySource.inquirySourceType) {
      case TICKET_INQUIRY_SOURCE_TYPES.OWNER_IDEA: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.IDEA.OWNER
        newTabTransitionParams.ideaId = inquirySource.inquirySourceId
        break
      }
      case TICKET_INQUIRY_SOURCE_TYPES.ADMIN_IDEA: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.IDEA.ADMIN
        newTabTransitionParams.ideaId = inquirySource.inquirySourceId
        break
      }
      case TICKET_INQUIRY_SOURCE_TYPES.CONSULTATION: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.QA
        newTabTransitionParams.userId = this.ticketContent.ownerUser?.userId
        break
      }
      default: return assertExhaustive(inquirySource.inquirySourceType)
    }

    openNewTab(newTabTransitionParams)
  }

  openRelatedInformationPage(buildingId: string, relatedInformation: TicketRelatedInformationDto): void {
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId

    switch (this.ticketContent.ticketType) {
      // 【プランチケットの場合】
      // 紐づいたプラン詳細画面を別タブで表示
      case TICKET_TYPES.IDEA: {
        newTabTransitionParams.newTabTransitionTo = this.ticketContent.relatedInformation ? NEW_TAB_TRANSITION_TO.IDEA.ADMIN : NEW_TAB_TRANSITION_TO.IDEA.OWNER
        newTabTransitionParams.ideaId = relatedInformation.transitionParams.replace('ideaId=', '')
        break
      }
      // 【決議チケットの場合】
      // 紐づいた決議詳細画面を別タブで表示
      case TICKET_TYPES.RESOLUTION: {
        newTabTransitionParams.resolutionId = relatedInformation.transitionParams.replace('resolutionId=', '')
        // 総会決議の場合
        if (relatedInformation.transitionType === TRANSITION_TO.RESOLUTION.GENERAL_MEETING) {
          newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.RESOLUTION.GENERAL_MEETING
        // オンライン決議の場合
        } else {
          newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.RESOLUTION.ONLINE
        }
        break
      }
      case TICKET_TYPES.CONSULTATION:
      case TICKET_TYPES.MANUAL:
        break
      default: return assertExhaustive(this.ticketContent.ticketType)
    }

    openNewTab(newTabTransitionParams)
  }

  openBuildingDetailPage(buildingId: string): void {
    // 該当のマンション詳細画面を別タブで開く
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId
    openNewTab(newTabTransitionParams)
  }

  openOwnerUserDetailPage(buildingId: string, unitId: string): void {
    // 該当の区分所有者詳細画面を別タブで開く
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.OWNER_DETAIL
    newTabTransitionParams.buildingId = buildingId
    newTabTransitionParams.unitId = unitId
    openNewTab(newTabTransitionParams)
  }

  async goToTicketsListPage(building: TicketBuildingDto, ownerUser?: User): Promise<void> {
    // マンション検索から該当のマンション情報を取得
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999))
    const buildingDto = buildingsModule.buildingsGet.buildings.find(b => b.buildingId === building.buildingId)

    // 区分所有者情報はチケット詳細のレスポンスから生成（過去の区分所有者の場合にownershipSummaryから情報が取れないため）
    let buildingOwnerDto: BuildingOwnerDto | undefined
    if (ownerUser) {
      buildingOwnerDto = new BuildingOwnerDto()
      buildingOwnerDto.userId = ownerUser.userId
      buildingOwnerDto.userName = ownerUser.userName
      buildingOwnerDto.roomNumber = ownerUser.roomNumber ?? ''
    }

    // マンションの絞り込みのためにチケット一覧の検索条件にマンション情報を保存
    const STORAGE_KEY = staticRoutes.ticketsList.name
    const inputParams = new InputParams()
    if (buildingDto) inputParams.buildings = [buildingDto]
    if (buildingOwnerDto) inputParams.owners = [buildingOwnerDto]
    paramStorageModule.save({ key: STORAGE_KEY, params: { ...inputParams } })

    // チケット一覧に遷移
    this.$router.push({ name: staticRoutes.ticketsList.name })
  }

  // --------------- チケット詳細エリア表示、チケット編集入力フォーム用処理 ---------------

  get isResolutionTicket(): boolean { return this.ticketContent.ticketType === TICKET_TYPES.RESOLUTION || this.inputs.ticketType === TICKET_TYPES.RESOLUTION }

  get showCorrespondenceDetail(): boolean {
    if (this.isEditMode) return this.inputs.ticketType === TICKET_TYPES.CONSULTATION || this.inputs.ticketType === TICKET_TYPES.MANUAL

    return this.ticketContent.ticketType === TICKET_TYPES.CONSULTATION || this.ticketContent.ticketType === TICKET_TYPES.MANUAL
  }

  informationSourceLabel(informationSourceType: InformationSourceType): string { return getInformationSourceLabel(informationSourceType) }

  caseLabel(caseType: CaseType): string { return getCaseLabel(caseType) }

  relatedInformationLabel(ticketType: TicketType): string { return getTicketLabel(ticketType) }

  areaLabel(areaType: AreaType): string { return getAreaLabel(areaType) }

  managementStatusReportLabel(isManagementStatusReport: boolean): string {
    return isManagementStatusReport ? `${this.reportLabel(isManagementStatusReport)}（${this.managementCaseTypeLabel(this.ticketContent.caseType)}）` : `${this.reportLabel(isManagementStatusReport)}`
  }

  operationStatusReportLabel(isOperationStatusReport: boolean): string {
    return isOperationStatusReport ? `${this.reportLabel(isOperationStatusReport)}（${this.operationCaseTypeLabel(this.ticketContent.caseType)}）` : `${this.reportLabel(isOperationStatusReport)}`
  }

  reportLabel(isReport: boolean): string { return isReport ? '出力する' : '出力しない' }

  isVisibleInquirySource(informationSourceType: InformationSourceType): boolean { return informationSourceType === INFORMATION_SOURCE_TYPES.OTHERS }

  get isVisibleRelatedInformation(): boolean {
    // 関連情報がなければ表示しない
    if (!this.ticketContent.relatedInformation) return false
    // 決議チケット以外であれば表示する
    if (this.ticketCommon.ticketType !== TICKET_TYPES.RESOLUTION) return true
    // 決議チケットで、決議が下書きor削除済みであれば表示しない
    const resolutionHideStates:ResolutionState[] = [
      RESOLUTION_STATES.GENERAL_MEETING.DRAFT,
      RESOLUTION_STATES.GENERAL_MEETING.DELETED,
      RESOLUTION_STATES.ONLINE.DRAFT,
      RESOLUTION_STATES.ONLINE.DELETED,
    ]
    return !!this.ticketContent.relatedInformation.resolution?.resolutionState && !resolutionHideStates.includes(this.ticketContent.relatedInformation.resolution?.resolutionState)
  }

  get isOnlineResolution(): boolean {
    return this.ticketCommon.ticketType === TICKET_TYPES.RESOLUTION && this.ticketContent.relatedInformation?.resolution?.resolutionType === RESOLUTION_TYPES.ONLINE
  }

  get isDisableReportRadioButton(): boolean { return this.inputs.caseType === CASE_TYPES.BASED_ON_THE_TERMS }

  get isEditableInquirySourceType(): boolean {
    switch (this.ticketContent.ticketType) {
      case TICKET_TYPES.IDEA:
      case TICKET_TYPES.RESOLUTION:
      case TICKET_TYPES.CONSULTATION:
        return false // アイデア・プラン、決議、相談・連絡チケットは固定
      case TICKET_TYPES.MANUAL:
        if (this.inputs.ticketType === TICKET_TYPES.RESOLUTION) return false
        return true
      default: return assertExhaustive(this.ticketContent.ticketType)
    }
  }

  get isEditableCaseType(): boolean {
    switch (this.ticketContent.ticketType) {
      case TICKET_TYPES.IDEA:
      case TICKET_TYPES.RESOLUTION:
        return false // アイデア・プラン、決議チケットは固定
      case TICKET_TYPES.CONSULTATION:
      case TICKET_TYPES.MANUAL:
        if (this.inputs.ticketType === TICKET_TYPES.RESOLUTION) return false
        return true
      default: return assertExhaustive(this.ticketContent.ticketType)
    }
  }

  get isVisibleIdeaSelectButton(): boolean {
    if (this.isResolutionTicket) {
      if (!this.ticketContent.relatedInformation) return true
      if (this.ticketContent.relatedInformation.resolution?.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.DRAFT) return true
      if (this.ticketContent.relatedInformation.resolution?.resolutionState === RESOLUTION_STATES.ONLINE.DRAFT) return true
    }
    return false
  }

  onChangeInformationSource(value: InformationSourceType): void {
    this.inputs.informationSource = ''
    if (value === INFORMATION_SOURCE_TYPES.IDEA_POSTED) {
      this.inputs.caseType = CASE_TYPES.OWNER_IDEA
    } else {
      this.inputs.caseType = CASE_TYPES.OWNER_CONSULTATION
    }
    this.inputs.isManagementStatusReport = true
    this.inputs.isOperationStatusReport = true
  }

  onChangeCaseType(value: CaseType): void {
    this.inputs.isManagementStatusReport = value !== CASE_TYPES.BASED_ON_THE_TERMS
    this.inputs.isOperationStatusReport = value !== CASE_TYPES.BASED_ON_THE_TERMS
  }

  onChangeSelectedAdminIdeas(ideaIds: string[]): void {
    this.inputs.ideaIds = ideaIds

    // 決議チケットで議案化するアイデアを選択した場合は「顧客対応（アイデア・プラン）」で固定表示
    if (this.inputs.ticketType === TICKET_TYPES.RESOLUTION) {
      if (this.inputs.ideaIds.length > 0) this.inputs.caseType = CASE_TYPES.OWNER_IDEA
    }
  }

  onChangeSelectedBuilding(buildingIds: string[]):void {
    this.inputs.buildingId = buildingIds[0]
    this.inputs.ownerUserId = ''
    this.inputs.ideaIds = []
  }

  private get selectedBuilding():Building[] {
    const building = buildingsModule.buildingsGet.buildings.find(building => building.buildingId === this.inputs.buildingId)
    return building ? [building] : []
  }

  private get selectedBuildingName(): string | undefined {
    if (!this.inputs.buildingId) return undefined
    return buildingsModule.buildingsGet.buildings.find(b => b.buildingId === this.inputs.buildingId)?.buildingName ?? this.ticketContent.building?.buildingName
  }

  private get selectedBuildingCondominiumCd(): string | undefined {
    if (!this.inputs.buildingId) return undefined
    return buildingsModule.buildingsGet.buildings.find(b => b.buildingId === this.inputs.buildingId)?.condominiumCd ?? this.ticketContent.building?.condominiumCd
  }

  private get selectedBuildingAreaType(): number | undefined {
    if (!this.inputs.buildingId) return undefined
    return buildingsModule.buildingsGet.buildings.find(b => b.buildingId === this.inputs.buildingId)?.buildingAreaType ?? this.ticketContent.building?.areaType
  }

  private get selectedOwnerName(): string | undefined {
    if (!this.inputs.ownerUserId) return undefined
    return buildingsModule.buildingOwnersGet.owners.find(o => o.userId === this.inputs.ownerUserId)?.userName ?? this.ticketContent.ownerUser?.userName
  }

  private get selectedOwnerRoomNumber(): string | undefined {
    if (!this.inputs.ownerUserId) return undefined
    return buildingsModule.buildingOwnersGet.owners.find(o => o.userId === this.inputs.ownerUserId)?.roomNumber ?? this.ticketContent.ownerUser?.roomNumber
  }

  private get isOutOfServiceBuilding(): boolean {
    return !!this.inputs.buildingId && !buildingsModule.buildingsGet.buildings.find(b => b.buildingId === this.inputs.buildingId)
  }

  onClickClearSelectedBuilding(): void {
    this.inputs.buildingId = ''
    this.inputs.ownerUserId = ''
    this.inputs.ideaIds = []
  }

  onChangeSelectedOwnerUser(ownerUserIds: string[]): void {
    this.inputs.ownerUserId = ownerUserIds[0]
  }

  onClickClearSelectedOwnerUser(): void {
    this.inputs.ownerUserId = ''
  }

  // チケット発生契機選択内容
  get selectionInformationSourceItems(): {value: InformationSourceType, label: string}[] {
    const items: {value: InformationSourceType, label: string}[] = []
    if (this.inputs.ticketType === TICKET_TYPES.RESOLUTION) {
      items.push(
        { value: INFORMATION_SOURCE_TYPES.IDEA_POSTED, label: 'アイデア・プラン投稿' },
      )
    } else {
      items.push(
        { value: INFORMATION_SOURCE_TYPES.FRONT, label: 'フロント' },
        { value: INFORMATION_SOURCE_TYPES.LM, label: 'LM' },
        { value: INFORMATION_SOURCE_TYPES.GCC, label: 'GCC' },
        { value: INFORMATION_SOURCE_TYPES.SM_AT_HASEKO, label: 'sm@haseko' },
        { value: INFORMATION_SOURCE_TYPES.MAIL_RECEIVED_AT_SERVICE_STAFF, label: '管理者業務執行者宛メール受信' },
        { value: INFORMATION_SOURCE_TYPES.HP, label: 'HP' },
        { value: INFORMATION_SOURCE_TYPES.OTHERS, label: 'そのほか（自由入力）' },
      )
    }
    return items
  }

  // 対応内容選択内容
  get selectionCaseTypeItems(): {value: CaseType, label: string}[] {
    switch (this.inputs.ticketType) {
      case TICKET_TYPES.MANUAL:
      case TICKET_TYPES.CONSULTATION:
        return [
          { value: CASE_TYPES.OWNER_CONSULTATION, label: '顧客対応（相談・連絡）' },
          { value: CASE_TYPES.JUDGEMENT, label: '判断対応' },
          { value: CASE_TYPES.BASED_ON_THE_TERMS, label: '規約に基づく申請受付' },
        ]
      case TICKET_TYPES.IDEA:
        break
      case TICKET_TYPES.RESOLUTION:
        return [
          { value: CASE_TYPES.OWNER_IDEA, label: '顧客対応（アイデア・プラン）' },
        ]
      default:
        assertExhaustive(this.inputs.ticketType)
    }
    return []
  }

  managementCaseTypeLabel(caseType: CaseType): string {
    switch (caseType) {
      case CASE_TYPES.OWNER_IDEA: return 'Webアプリの投稿アイデアへの取り組み'
      case CASE_TYPES.OWNER_CONSULTATION: return '居住者からの意見・要望への取り組み'
      case CASE_TYPES.JUDGEMENT: return '居住者からの意見・要望への取り組み'
      case CASE_TYPES.BASED_ON_THE_TERMS: return '-'
      default: return assertExhaustive(caseType)
    }
  }

  operationCaseTypeLabel(caseType: CaseType): string {
    switch (caseType) {
      case CASE_TYPES.OWNER_IDEA: return '対応履歴'
      case CASE_TYPES.OWNER_CONSULTATION: return '対応履歴'
      case CASE_TYPES.JUDGEMENT: return '対応履歴'
      case CASE_TYPES.BASED_ON_THE_TERMS: return '-'
      default: return assertExhaustive(caseType)
    }
  }

  get baseIdeas(): BuildingAdminIdea[] {
    if (this.inputs.ideaIds.length) {
      return buildingsModule.adminIdeasGet.ideas.filter(i => this.inputs.ideaIds.includes(i.ideaId))
    } else return []
  }

  get reportOutputRadioOptions(): RadioOption[] {
    return [
      new RadioOption('出力する', true),
      new RadioOption('出力しない', false),
    ]
  }

  private get isInvalidInput(): boolean {
    // validation-observerでは検知しないデータのバリデーションチェックを行う
    if (this.inputs.ticketType === TICKET_TYPES.RESOLUTION) {
      if (!this.inputs.buildingId) return true
    }
    return false
  }

  // チケットに物件が紐づいていても、以下の場合は編集時にマンション選択ボタンを表示させない
  private get isDisplayBuildingSelectButton(): boolean {
    if (this.inputs.ticketType === TICKET_TYPES.IDEA || this.inputs.ticketType === TICKET_TYPES.CONSULTATION) return true
    // チケットにプラン、決議が紐づいている場合
    if (this.ticketContent.inquirySources.length || this.ticketContent.relatedInformation) return true
    // チケットに紐づくマンションが利用終了している場合
    if (this.isOutOfServiceBuilding) return true
    return false
  }

  // プラン選択モーダル
  isAdminIdeaSelectModalVisible = false
  adminIdeaSelectModalKey = generateUuid()
  openAdminIdeaSelectModal(): void {
    this.adminIdeaSelectModalKey = generateUuid()
    this.isAdminIdeaSelectModalVisible = true
  }

  // マンション選択モーダル
  isBuildingSelectModalVisible = false
  buildingSelectModalKey = generateUuid()
  buildingSelectModalKeyword = ''
  buildingSelectModalInputText = ''
  openBuildingSelectModal(): void {
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingSelectModalVisible = true
  }

  // 区分所有者選択モーダル
  isOwnerSelectModalVisible = false
  ownerSelectModalKey = generateUuid()
  ownerSelectModalKeyword = ''
  ownerSelectModalInputText = ''
  async openOwnerSelectModal(): Promise<void> {
    if (!this.inputs.buildingId) return

    const req = new BuildingOwnersGetRequest()
    req.buildingId = this.inputs.buildingId
    await buildingsModule.fetchBuildingOwners(req)

    this.ownerSelectModalKey = generateUuid()
    this.isOwnerSelectModalVisible = true
  }

  // コンテンツ選択内容
  get selectionTicketTypeItems(): {value: TicketType, label: string}[] {
    switch (this.ticketContent.ticketType) {
      case TICKET_TYPES.MANUAL:
        return [
          { value: TICKET_TYPES.MANUAL, label: '手動' },
          { value: TICKET_TYPES.RESOLUTION, label: '決議' },
        ]
      case TICKET_TYPES.CONSULTATION:
      case TICKET_TYPES.IDEA:
      case TICKET_TYPES.RESOLUTION:
        break
      default:
        assertExhaustive(this.ticketContent.ticketType)
    }
    return []
  }

  showChangeTypeDialog = false

  onChangeTicketTypeBetweenManualAndResolution(value: TicketType): void {
    this.inputs.ticketType = value
    if (this.inputs.ticketType === TICKET_TYPES.MANUAL) {
      this.inputs.informationSourceType = this.ticketContent.informationSourceType
      this.inputs.informationSource = this.ticketContent.informationSource
      this.inputs.caseType = this.ticketContent.caseType
    } else if (this.inputs.ticketType === TICKET_TYPES.RESOLUTION) { // 編集状態で手動→決議に変更時
      this.inputs.informationSourceType = this.selectionInformationSourceItems[0].value
      this.inputs.informationSource = this.selectionInformationSourceItems[0].label
      this.inputs.caseType = this.selectionCaseTypeItems[0].value
      this.inputs.ownerUserId = ''
    }
  }

  onClickPutBtn(): void {
    // 手動チケットを決議チケットに変更している場合確認ダイアログを表示する
    if (this.ticketContent.ticketType === TICKET_TYPES.MANUAL && this.inputs.ticketType === TICKET_TYPES.RESOLUTION) {
      this.showChangeTypeDialog = true
    } else {
      this.putTicketDetail()
    }
  }

  onClickDialogCancel(): void {
    this.showChangeTypeDialog = false
    this.isEditMode = true
  }
}
