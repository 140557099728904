




































import { Component, Vue, Prop, Model } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { ADMIN_ROLE } from '@/constants/schema-constants'
import { StaffsGetRequest, StaffsGetResponseStaff } from '@/dtos/staffs/get'
import { staffsModule } from '@/stores/staffs-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class ServiceStaffSelectModalContent extends Vue {
  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)
  @Model('change')
  private readonly isVisible!: boolean

  @Prop({ default: '選択する' })
  private readonly topRightBtnText!: string

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!:string

  // 検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!:string

  // 選択したID
  @Prop({ default: '' })
  targetId!: string

  @Prop({ default: false })
  private readonly isIncludeIconImagePath?: boolean

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  private get staffs(): StaffsGetResponseStaff[] {
    return staffsModule.staffs(ADMIN_ROLE.SERVICE_STAFF)
  }

  async created(): Promise<void> {
    this._selectedIds = [this.targetId]
    this.fetchStaffs()
  }

  async fetchStaffs(): Promise<void> {
    const req = new StaffsGetRequest(ADMIN_ROLE.SERVICE_STAFF)
    req.isIncludeIconImagePath = this.isIncludeIconImagePath
    await staffsModule.fetchStaffs(req)
  }

  private get headers(): Header[] {
    const headers:Header[] = []
    headers.push(new Header({ text: '社員名', value: 'userName', sortable: false }))
    return headers
  }

  private selectedIds: string[] = []

  private get _selectedIds() { return this.selectedIds }
  private set _selectedIds(newValue: string[]) {
    const selectedName = this.staffs.find(s => s.userId === newValue[0])?.userName
    const selectedAdminShortName = this.staffs.find(s => s.userId === newValue[0])?.userShortName
    this.selectedIds = newValue
    this.$emit('select', newValue[0] ?? '', selectedName ?? '', selectedAdminShortName ?? '') // コンポーネント（SmTableData）の返却値は配列だが、当モーダルは単一選択のため返却値は一つのみ
  }

  search():void {
    this._keyword = this._inputText
  }
}
