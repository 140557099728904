import { errorsModule } from '@/stores/errors'
import { structureModule } from '@/stores/structure-store'
import axios from 'axios'

class S3Client {
  _client = axios.create()

  constructor() {
    this._client.interceptors.request.use(config => {
      structureModule.requestShowProgressOverlay()
      return config
    })

    this._client.interceptors.response.use(response => {
      structureModule.requestHideProgressOverlay()
      return response
    }, error => {
      structureModule.forceHideProgressOverlay()
      errorsModule.setGlobalErrors(['ファイルのアップロードに失敗しました。再度ファイルを選択し、アップロードしてください。'])
      throw error
    })
  }

  async upload(url: string, file: File, contentType: string) {
    if (!url) { console.log('skip upload material cause now is offline state'); return }
    await this._client.put(
      url,
      file,
      { headers: { 'Content-Type': contentType, 'x-amz-acl': 'bucket-owner-full-control' } }
    )
  }
}

export const s3Client = new S3Client()
