
























































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import { concatSerialChar } from '@/libs/add-alphabet-provider'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { adminIdeasModule } from '@/stores/admin-ideas-store'
import { AdminIdeaAgreementsGetResponse, Agreement, Plan } from '@/dtos/ideas/admin-idea/agreements/get'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),

    ProxyAgreeTable: () => import('@/pages/ideas/admin-idea/admin-idea-detail/agreements/ProxyAgreeTable.vue'),
  }
})
export default class ProxyAgreeModal extends Vue {
  @Model('change')
  private readonly isVisible!: boolean

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!: string

  // 検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!: string

  // 区分所有者が賛同済みのプランに対して、見出しを追加するための変数
  @Prop({ default: () => [] })
  planTitles!: string[]

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  private agreements: Agreement[] = []

  async created(): Promise<void> {
    const copiedAgreements: AdminIdeaAgreementsGetResponse = deepCopy(
      adminIdeasModule.agreementsGet,
      {
        AdminIdeaAgreementsGetResponse: new ColumnToType(AdminIdeaAgreementsGetResponse),
        agreements: new ColumnToType(Agreement),
        plans: new ColumnToType(Plan),
      },
      'AdminIdeaAgreementsGetResponse',
    )
    this.agreements = copiedAgreements.agreements
    for (const agreement of this.agreements) {
      if (!agreement.isOwnerAgreed) continue
      agreement.plans.forEach(plan => {
        const prefix = concatSerialChar(this.planTitles.length, this.planTitles.indexOf(plan.planTitle))
        const planTitleWithPrefix = `【プラン${prefix}】${plan.planTitle}`
        plan.planTitle = planTitleWithPrefix
      })
    }
  }

  private isDialogVisible = false

  private updateAgreements(): void {
    this.isDialogVisible = false

    this.$emit('update-agreements', this.agreements)
  }

  private changeAgreements(item: Agreement): void {
    this.agreements = this.agreements.map(e => {
      const agree = { ...e }
      return agree.userId === item.userId ? item : agree
    })
  }

  search(): void {
    this._keyword = this._inputText
  }
}
