import { render, staticRenderFns } from "./DetailSubPage.vue?vue&type=template&id=9d4d4552&"
import script from "./DetailSubPage.vue?vue&type=script&lang=ts&"
export * from "./DetailSubPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports