









































































































































































































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { onlineResolutionsModule } from '@/stores/online-resolutions-store'
import { OnlineResolutionDetailGetRequest, OnlineResolutionDetailGetResponse } from '@/dtos/resolutions/online-resolution/get-detail'
import { buildingsModule } from '@/stores/buildings-store'
import { MATERIAL_TYPES } from '@/constants/schema-constants'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),
    SmTextPrintSizeAnnounce: () => import('@/components/atoms/SmTextPrintSizeAnnounce.vue'),

    SmListBudgetPrint: () => import('@/components/molecules/list/SmListBudgetPrint.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmPrintLabel: () => import('@/components/molecules/SmPrintLabel.vue'),

    SmPrintHeader: () => import('@/components/organisms/SmPrintHeader.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class DetailPrintPage extends Mixins(CurrentAdminManager) {
  @Prop({ required: true, default: '' })
  private readonly resolutionId!:string

  async created():Promise<void> {
    await onlineResolutionsModule.fetchResolutionDetail(new OnlineResolutionDetailGetRequest(this.resolutionId))
  }

  /**
   * 取得する日付から時刻を削除する
   */
  private get sliceProcessedAt(): string {
    if (!this.detail.processedAt) return ''
    const processedAt: string = this.detail.processedAt
    return processedAt.slice(0, 14)
  }

  // TODO データを取得できなかったケースの実装
  private get detail(): OnlineResolutionDetailGetResponse {
    return onlineResolutionsModule.detailResponse(this.resolutionId) ?? new OnlineResolutionDetailGetResponse()
  }

  private get myCurrentBuilding():string | undefined {
    return buildingsModule.buildingDetailGet?.buildingName
  }

  // 投票期日を時間なし（YYYY年MM月DD日（曜））で表示
  private get deadline(): string {
    return this.detail.voteDeadline?.substring(0, 14)
  }

  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
}
