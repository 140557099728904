























































import type { OperationState } from '@/constants/schema-constants'
import { OPERATION_STATES } from '@/constants/schema-constants'

import { assertExhaustive } from '@/libs/exhaustive-helper'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTooltip: () => import('@/components/molecules/SmTooltip.vue'),
  }
})

export default class ManagementOperationTaskStatusArea extends Vue {
  OPERATION_STATES = Object.freeze(OPERATION_STATES)

  @Prop({ required: false })
  private readonly executorName?: string

  @Prop({ required: true, default: '' })
  private readonly targetDate!: string

  @Prop({ required: true, default: '' })
  private readonly operationState!: OperationState

  @Prop({ required: false })
  private readonly completedAt?: string

  @Prop({ required: false })
  private readonly memo?: string

  get operationStateText(): string {
    switch (this.operationState) {
      case OPERATION_STATES.UNRESOLVED: return '未'
      case OPERATION_STATES.COMPLETED: {
        if (this.completedAt) {
          // オンボーディングと定例業務のデータ移行時にステータスが済で完了日が指定していないタスクは'1901-01-01'で設定しており、その場合は'ー'で表示する
          if (this.completedAt === '1901-01-01') return '済 ー'
          const [, month, day] = this.completedAt.split('-')
          const formattedMonth = parseInt(month, 10)
          const formattedDay = parseInt(day, 10)

          return `済 ${formattedMonth}/${formattedDay}`
        }
        return '済'
      }
      case OPERATION_STATES.OUT_OF_SCOPE: return '対象外'

      default: return assertExhaustive(this.operationState)
    }
  }

  get truncateExecutorName(): string {
    if (!this.executorName) return ''
    if (this.executorName.length <= 6) {
      return this.executorName
    } else {
      return this.executorName.slice(0, 5) + '･･･'
    }
  }

  hovering = false
  handleMouseOver(): void {
    this.hovering = true
  }

  handleMouseLeave(): void {
    this.hovering = false
  }

  get targetMonth(): number {
    return Number(this.targetDate.split('/')[1])
  }

  get targetMonthBackgroundColor(): string {
    if (this.operationState === OPERATION_STATES.COMPLETED || this.operationState === OPERATION_STATES.OUT_OF_SCOPE) return '' // ステータスが済の場合、目安月セルの背景色の指定をやめ、大元のdivタグでの背景色の指定を優先する
    const currentDate = new Date()
    const targetDate = new Date(this.targetDate)

    if (currentDate.getFullYear() > targetDate.getFullYear() ||
        (currentDate.getFullYear() === targetDate.getFullYear() && currentDate.getMonth() > targetDate.getMonth())) {
      return 'sm__cell-target-month-caution'
    } else if (currentDate.getFullYear() === targetDate.getFullYear() && currentDate.getMonth() === targetDate.getMonth()) {
      return 'sm__cell-target-month-warning'
    } else {
      return ''
    }
  }
}
// 追加開発ネタNo.85対応時、下記の処理は削除する
// 印刷時、memoのアイコン名が表示されてしまうのを防ぐため、印刷時はmemoアイコンを表示しない
// 印刷ダイアログを開く直前、.sm__tooltipクラスおよび<sm-tooltip>タグを持つ全ての要素を非表示とする
window.onbeforeprint = function() {
  document.querySelectorAll('.sm__tooltip, sm-tooltip').forEach(function(element) {
    (element as HTMLElement).style.display = 'none'
  })
}
// 印刷ダイアログを閉じた後、上記のイベントリスナー内で非表示にした要素を再表示する
window.onafterprint = function() {
  document.querySelectorAll('.sm__tooltip, sm-tooltip').forEach(function(element) {
    (element as HTMLElement).style.display = ''
  })
}
