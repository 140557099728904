


























































































































































import { Component, Vue, Prop } from 'vue-property-decorator'

import { RadioOption } from '@/components/atoms/SmRadio.vue'

import { ADMIN_ROLE, INITIAL_AUTH_STATE } from '@/constants/schema-constants'
import type { AdminRole } from '@/constants/schema-constants'

import { StaffDetailGetResponse } from '@/dtos/staffs/get-detail'
import { StaffPutRequest, LifeManagerPutRequest } from '@/dtos/staffs/put'

import { assertExhaustive } from '@/libs/exhaustive-helper'
import { getAdminRoleLabel, getInitialAuthStateLabel } from '@/libs/state-handler'

import { staffsModule } from '@/stores/staffs-store'

import VueRouter from 'vue-router'

const toServiceStaffPutRequest = (inputs: ServiceStaffFormInputs): StaffPutRequest => {
  const registerServiceStaff = new StaffPutRequest(inputs.adminId, inputs.userName, inputs.isEmailSent)
  registerServiceStaff.adminShortName = inputs.adminShortName
  registerServiceStaff.emailAddress = inputs.emailAddress
  return registerServiceStaff
}

const toFrontPutRequest = (inputs: StaffFormInputs): StaffPutRequest => {
  return new StaffPutRequest(inputs.adminId, inputs.userName, inputs.isEmailSent)
}

const toLifeManagerPutRequest = (inputs: LifeManagerFormInputs): LifeManagerPutRequest => {
  return new LifeManagerPutRequest(inputs.adminId, inputs.userName, inputs.authKeyRecipient, inputs.phoneNumber, inputs.isEmailSent)
}

class StaffFormInputs {
  roleType!: AdminRole
  adminId!: string
  employeeId!: string
  userName!: string
  isEmailSent!: boolean
  constructor(roleType: AdminRole) {
    this.roleType = roleType
  }
}

class ServiceStaffFormInputs extends StaffFormInputs {
  adminShortName!: string
  emailAddress!: string
}

class FrontStaffFormInputs extends StaffFormInputs {
}

class LifeManagerFormInputs extends StaffFormInputs {
  authKeyRecipient!: string
  phoneNumber!: string
}

interface PageTypeSpecifications {
  adminRole: string
  inputs: StaffFormInputs
  created: (member: StaffDetailGetResponse) => Promise<void> | void
  onClickExecute: ($router: VueRouter, inputs: StaffFormInputs) => Promise<void>
}

class ServiceStaffSpecifications implements PageTypeSpecifications {
  readonly adminRole = getAdminRoleLabel(ADMIN_ROLE.SERVICE_STAFF)

  inputs = new ServiceStaffFormInputs(ADMIN_ROLE.SERVICE_STAFF)

  created(member: StaffDetailGetResponse): Promise<void> | void {
    this.inputs.roleType = member.role
    this.inputs.adminId = member.userId
    this.inputs.employeeId = member.employeeId
    this.inputs.userName = member.userName
    this.inputs.adminShortName = member.adminShortName ?? ''
    this.inputs.emailAddress = member.emailAddress ?? ''
    this.inputs.isEmailSent = member.isEmailSent
  }

  async onClickExecute($router: VueRouter): Promise<void> {
    const req = toServiceStaffPutRequest(this.inputs)
    if (req) await staffsModule.putStaff(req)
    $router.go(-1) // 社員詳細画面で矢印アイコンから遷移元の画面に戻れるように遷移する
  }
}

class FrontStaffSpecifications implements PageTypeSpecifications {
  readonly adminRole = getAdminRoleLabel(ADMIN_ROLE.FRONT)

  inputs = new FrontStaffFormInputs(ADMIN_ROLE.FRONT)

  created(member: StaffDetailGetResponse): Promise<void> | void {
    this.inputs.roleType = member.role
    this.inputs.adminId = member.userId
    this.inputs.employeeId = member.employeeId
    this.inputs.userName = member.userName
    this.inputs.isEmailSent = member.isEmailSent
  }

  async onClickExecute($router: VueRouter): Promise<void> {
    const req = toFrontPutRequest(this.inputs)
    if (req) await staffsModule.putStaff(req)
    $router.go(-1) // 社員詳細画面で矢印アイコンから遷移元の画面に戻れるように遷移する
  }
}

class LifeManagerStaffSpecifications implements PageTypeSpecifications {
  readonly adminRole = getAdminRoleLabel(ADMIN_ROLE.LIFE_MANAGER)

  inputs = new LifeManagerFormInputs(ADMIN_ROLE.LIFE_MANAGER)

  created(member: StaffDetailGetResponse): Promise<void> | void {
    this.inputs.roleType = member.role
    this.inputs.adminId = member.userId
    this.inputs.employeeId = member.employeeId
    this.inputs.userName = member.userName
    this.inputs.authKeyRecipient = member.authKeyRecipient ?? ''
    this.inputs.phoneNumber = member.phoneNumber ?? ''
    this.inputs.isEmailSent = member.isEmailSent
  }

  async onClickExecute($router: VueRouter): Promise<void> {
    const req = toLifeManagerPutRequest(this.inputs)
    if (req) await staffsModule.putLifeManagerStaff(req)
    $router.go(-1) // 社員詳細画面で矢印アイコンから遷移元の画面に戻れるように遷移する
  }
}

@Component({
  components: {
    SmAvatar: () => import('@/components/atoms/SmAvatar.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class StaffEditPage extends Vue {
  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)
  INITIAL_AUTH_STATE = Object.freeze(INITIAL_AUTH_STATE)

  @Prop({ required: true, default: '' })
  private readonly adminId!: string

  @Prop({ required: true, default: ADMIN_ROLE.SERVICE_STAFF })
  private readonly pageType!: AdminRole

  get member(): StaffDetailGetResponse | undefined { return staffsModule.staffDetail(this.adminId) }

  async created(): Promise<void> { if (this.member) this.typeSpecs?.created(this.member) }

  private get typeSpecs(): PageTypeSpecifications | undefined {
    switch (this.pageType) {
      case ADMIN_ROLE.SERVICE_STAFF: return new ServiceStaffSpecifications()
      case ADMIN_ROLE.FRONT: return new FrontStaffSpecifications()
      case ADMIN_ROLE.LIFE_MANAGER: return new LifeManagerStaffSpecifications()
      case ADMIN_ROLE.ACCOUNTING_DEPARTMENT:
      case ADMIN_ROLE.GROUP_CALL_CENTER:
        return
      default: return assertExhaustive(this.pageType)
    }
  }

  options: RadioOption[] = [
    new RadioOption('未完了', false),
    new RadioOption('完了', true)
  ]

  private getInitialAuthStateLabel(): string | undefined {
    if (!this.member || !this.member.initialAuthState) return
    return getInitialAuthStateLabel(this.member.initialAuthState)
  }

  async clickUpdateBtn(): Promise<void> { this.typeSpecs?.onClickExecute(this.$router, this.typeSpecs.inputs) }
}
