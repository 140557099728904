
























































































import { Vue, Component, Prop } from 'vue-property-decorator'

const formats = [
  'sm__display',
  'sm__h1',
  'sm__h2',
  'sm__h3',
  'sm__h4',
  'sm__body',
  'sm__caption',
  'sm__caption-mini',
  'sm__title',
  'sm__form-title',
  'sm__btn-ex-large',
  'sm__btn-large',
  'sm__btn-medium',
  'sm__btn-mini',
  'sm__label',
  'sm__label-mini',
  'sm__data',
  'sm__data-header',
  'sm-ow__h1',
  'sm-ow__h2',
  'sm-ow__h3',
  'sm-ow__h4',
  'sm-ow__body',
  'sm-ow__btn-medium',
  'sm-ow__btn-mini',
  'sm-ow__caption',
  'sm-ow__caption-mini',
  'sm-ow__data',
  'sm-ow__data-header',
  'sm-ow__label',
  'sm-ow__label-mini',
  'sm__print-title',
  'sm__print-section-title',
  'sm__print',
  'sm__print-header',
] as const
export type Format = typeof formats[number]

const colors = [
  'black',
  'blue-gray-dark',
  'brown-dark',
  'caution',
  'gold',
  'gray',
  'gray-dark',
  'green-dark',
  'green-dark-2',
  'green-light',
  'midnight-blue',
  'orange-dark',
  'pink-dark',
  'purple-dark',
  'red',
  'red-dark',
  'saddle-brown',
  'secondary',
  'white',
] as const
export type Color = typeof colors[number]

const levels = [
  '1',
  '2',
  '3'
] as const
export type Level = typeof levels[number]

@Component
export default class SmText extends Vue {
  @Prop()
  private text!: string | number

  @Prop({
    default: 'sm__body',
    validator: f => formats.includes(f)
  })
  private format!: Format

  @Prop({
    validator: c => c === undefined || colors.includes(c)
  })
  private color?: Color

  @Prop({
    default: '1',
    validator: l => levels.includes(l)
  })
  private level!: Level

  private get colorClass():string | undefined {
    if (!this.color) {
      return undefined
    }
    switch (this.color) {
      case 'white':
      case 'black':
        return `sm__${this.color}-${this.level}`
      default:
        return `sm__${this.color}`
    }
  }

  private get classes():string[] {
    const classes = ['sm__text', this.format]
    if (this.colorClass != null) {
      classes.push(this.colorClass)
    }
    return classes
  }
}
