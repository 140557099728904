import { ConsultationReactionsResponse } from '@/dtos/static-assets/get'
import { APIClientBase } from './api-client-base'

class StaticAssetsClient extends APIClientBase {
  constructor() {
    super('/static-assets')
  }

  // リアクション一覧の取得
  getConsultationReactions(): Promise<ConsultationReactionsResponse> {
    return this.get('/reactions', '', ConsultationReactionsResponse, false)
  }
}

export const staticAssetsClient = new StaticAssetsClient()
