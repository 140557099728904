import { questionnairesClient } from '@/clients/questionnaires-client'
import { QuestionnaireQuestionsPostRequest, QuestionnaireQuestionsPostResponse } from '@/dtos/questionnaires/questions/post'
import { QuestionnaireAnswerLibraryPostRequest, QuestionnaireAnswerLibraryPostResponse } from '@/dtos/questionnaires/reports/answer-libraries/post'
import { QuestionnaireAnswerLibraryPutRequest } from '@/dtos/questionnaires/reports/answer-libraries/put'
import { QuestionnaireOpinionsSearchPostRequest, QuestionnaireOpinionsSearchPostResponse } from '@/dtos/questionnaires/reports/opinions/post'
import { QuestionnaireComparisonsGetRequest, QuestionnaireComparisonsGetResponse, QuestionnaireReportsResponse } from '@/dtos/questionnaires/reports/get'
import { QuestionnaireReportsPostRequest } from '@/dtos/questionnaires/reports/post'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'

@Module({ store, dynamic: true, namespaced: true, name: 'questionnaireReportsStore' })
class QuestionnaireReportsStore extends VuexModule {
  private _questionnaireReportsResponse: QuestionnaireReportsResponse = new QuestionnaireReportsResponse()

  public get questionnaireReportsResponse(): QuestionnaireReportsResponse {
    return this._questionnaireReportsResponse
  }

  @Mutation
  private SET_QUESTIONNAIRE_REPORT_GET(res: QuestionnaireReportsResponse): void {
    this._questionnaireReportsResponse = res
  }

  @Mutation
  private CLEAR_QUESTIONNAIRE_REPORT_GET(): void {
    this._questionnaireReportsResponse = new QuestionnaireReportsResponse()
  }

  @Action
  async deleteReportDrafts({ questionnaireId, questionnaireReportId }: {questionnaireId: string, questionnaireReportId: string}): Promise<void> {
    await questionnairesClient.deleteQuestionnairesReportsDrafts(questionnaireId, questionnaireReportId)
  }

  @Action
  async fetchReport({ questionnaireId, questionnaireReportId }: {questionnaireId: string, questionnaireReportId: string}): Promise<void> {
    const res = await questionnairesClient.getQuestionnairesReports(questionnaireId, questionnaireReportId)
    this.SET_QUESTIONNAIRE_REPORT_GET(res)
  }

  @Action
  async postReport({ questionnaireId, req }: {questionnaireId: string, req: QuestionnaireReportsPostRequest}): Promise<void> {
    await questionnairesClient.postQuestionnairesReports(questionnaireId, req)
  }

  @Action
  async postReportDraft({ questionnaireId, req }: {questionnaireId: string, req: QuestionnaireReportsPostRequest}): Promise<void> {
    await questionnairesClient.postQuestionnairesReportsDrafts(questionnaireId, req)
  }

  @Action
  async putReport({ questionnaireId, req }: {questionnaireId: string, req: QuestionnaireReportsPostRequest}): Promise<void> {
    await questionnairesClient.putQuestionnairesReports(questionnaireId, req)
  }

  @Action
  clearFetchedReport(): void {
    this.CLEAR_QUESTIONNAIRE_REPORT_GET()
  }

  // 意見・要望一覧取得
  private _opinionsGet = new QuestionnaireOpinionsSearchPostResponse()

  public get opinionsGet(): QuestionnaireOpinionsSearchPostResponse { return this._opinionsGet }

  @Mutation
  private SET_OPINIONS_GET(res: QuestionnaireOpinionsSearchPostResponse): void { this._opinionsGet = res }

  @Action
  public async fetchQuestionnaireOpinions(req: QuestionnaireOpinionsSearchPostRequest) {
    const res = await questionnairesClient.postQuestionnairesOpinionsSearch(req)
    if (res) this.SET_OPINIONS_GET(res)
  }

  // アンケート（設問）一覧取得
  private _questionsGet = new QuestionnaireQuestionsPostResponse()

  public get questionGet(): QuestionnaireQuestionsPostResponse { return this._questionsGet }

  @Mutation
  private SET_QUESTIONS_GET(res: QuestionnaireQuestionsPostResponse): void { this._questionsGet = res }

  @Action
  async fetchQuestionnaireQuestions({ questionnaireId, req }: {questionnaireId: string, req: QuestionnaireQuestionsPostRequest}) {
    const res = await questionnairesClient.postQuestionnaireQuestions(questionnaireId, req)
    if (res) this.SET_QUESTIONS_GET(res)
  }

  // テンプレート内アンケート一覧検索
  private _comparisonsGet = new QuestionnaireComparisonsGetResponse()
  public get comparisonsGet(): QuestionnaireComparisonsGetResponse { return this._comparisonsGet }

  @Mutation
  private SET_COMPARISONS_GET(res: QuestionnaireComparisonsGetResponse): void {
    this._comparisonsGet = res
  }

  @Action
  async fetchQuestionnaireComparisons(req: QuestionnaireComparisonsGetRequest): Promise<void> {
    const res = await questionnairesClient.getQuestionnaireComparisons(req)
    this.SET_COMPARISONS_GET(res)
  }

  // 模範回答一覧取得
  private _answerLibrariesGet = new QuestionnaireAnswerLibraryPostResponse()

  public get answerLibrariesGet(): QuestionnaireAnswerLibraryPostResponse { return this._answerLibrariesGet }

  @Mutation
  private SET_ANSWER_LIBRARY_GET(res: QuestionnaireAnswerLibraryPostResponse): void { this._answerLibrariesGet = res }

  @Action
  public async fetchQuestionnaireAnswerLibraries(req: QuestionnaireAnswerLibraryPostRequest) {
    const res = await questionnairesClient.postQuestionnaireAnswerLibraries(req)
    if (res) this.SET_ANSWER_LIBRARY_GET(res)
  }

  @Action
  async putQuestionnaireAnswerLibraries({ questionnaireReportResultSectionAnswerId, req }: {questionnaireReportResultSectionAnswerId: string, req: QuestionnaireAnswerLibraryPutRequest}): Promise<void> {
    await questionnairesClient.putQuestionnaireAnswerLibraries(questionnaireReportResultSectionAnswerId, req)
  }
}

export const questionnaireReportsModule = getModule(QuestionnaireReportsStore)
