import type { PinningSettingType, PostTimingType, TargetBuildingType, TargetOwnerType } from '@/constants/schema-constants'
import { Material } from '../commons'
import { OwnerNotificationsPostRequestBuilding, OwnerNotificationsPostRequestFFNElement, OwnerNotificationsPostRequestOwner } from './post'

export class OwnerNotificationsPutRequest {
  ownerNotificationId: string
  title: string
  targetBuildingType: TargetBuildingType
  targetOwnerType: TargetOwnerType
  postTimingType: PostTimingType
  pinningSettingType: PinningSettingType
  freeFormatNotificationElements: OwnerNotificationsPostRequestFFNElement[] = []
  version: number
  material?: Material
  targetBuildings?: OwnerNotificationsPostRequestBuilding[]
  ideaId?: string
  resolutionId?: string
  targetOwners?: OwnerNotificationsPostRequestOwner[]
  scheduledPostDateTime?: string
  pinningDeadlineDate?: string

  constructor(ownerNotificationId: string, title: string, targetBuildingType: TargetBuildingType, targetOwnerType: TargetOwnerType, postTimingType: PostTimingType,
    pinningSettingType: PinningSettingType, freeFormatNotificationElements: OwnerNotificationsPostRequestFFNElement[], version: number,
    material?: Material, targetBuildings?: OwnerNotificationsPostRequestBuilding[], ideaId?: string,
    resolutionId?: string, targetOwners?: OwnerNotificationsPostRequestOwner[], scheduledPostDateTime?: string, pinningDeadlineDate?: string) {
    this.ownerNotificationId = ownerNotificationId
    this.title = title
    this.targetBuildingType = targetBuildingType
    this.targetOwnerType = targetOwnerType
    this.postTimingType = postTimingType
    this.pinningSettingType = pinningSettingType
    this.freeFormatNotificationElements = freeFormatNotificationElements
    this.version = version
    this.material = material
    this.targetBuildings = targetBuildings
    this.ideaId = ideaId
    this.resolutionId = resolutionId
    this.targetOwners = targetOwners
    this.scheduledPostDateTime = scheduledPostDateTime
    this.pinningDeadlineDate = pinningDeadlineDate
  }
}
