






































import { Vue, Component, Model, Prop } from 'vue-property-decorator'

export class Tab {
  key: string
  label: string
  disabled: boolean

  constructor(key: string, label: string, disabled = false) {
    this.key = key
    this.label = label
    this.disabled = disabled
  }
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})

export default class SmTabsInPage extends Vue {
  @Prop({ required: true, default: () => [] })
  private readonly tabs!: Tab[]

  @Prop()
  @Model('change-tabs-item')
  private readonly currentTab!: string

  private get _currentTab(): string { return this.currentTab }
  private set _currentTab(newValue: string) {
    this.$emit('change-tabs-item', newValue)
  }
}
