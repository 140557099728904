/** お知らせ新規作成／編集画面で用いる定数をまとめたファイル。
 *  個別の画面で用いる定数は基本的に画面ファイル内で定義するが、お知らせ新規作成／編集画面はファイルのサイズが大きいため本ファイルに切り出している。 */

import { NOTIFICATION_ELEMENT_TYPES } from '../schema-constants'

export const TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS = {
  ALL: 'ALL',
  ALL_IN_CHARGE: 'ALL_IN_CHARGE',
  INDIVIDUALLY_SELECTED: 'INDIVIDUALLY_SELECTED',
}
export type TargetBuildingTypeSelectMenuItemsIds = typeof TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS[keyof typeof TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS]

export const TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_DEFAULT: {[id: string]: { text: string, label: string, key: string }} = {
  ALL: { text: 'すべてのマンション', label: 'マンションを選択する', key: 'ALL' },
  INDIVIDUALLY_SELECTED: { text: '個別に選択する', label: 'マンションを選択する', key: 'INDIVIDUALLY_SELECTED' },
} as const

export const TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_AFTER_BUILDING_SELECTED: {[id: string]: { text: string, label: string, key: string }} = {
  ALL: { text: 'すべてのマンション', label: 'マンションを変更する', key: 'ALL' },
  INDIVIDUALLY_SELECTED: { text: '個別に選択する', label: 'マンションを変更する', key: 'INDIVIDUALLY_SELECTED' },
} as const

export const TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_IDS = {
  ALL: 'ALL',
  GROUP: 'GROUP',
  INDIVIDUALLY_SELECTED: 'INDIVIDUALLY_SELECTED',
}
export type TargetOwnerTypeSelectMenuItemsIds = typeof TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_IDS[keyof typeof TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_IDS]

export const TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_DEFAULT: {[id: string]: { text: string, label: string, key: string }} = {
  ALL: { text: 'すべての区分所有者', label: '区分所有者を選択する', key: 'ALL' },
  GROUP: { text: 'グループから選択する', label: '区分所有者を選択する', key: 'GROUP' },
  INDIVIDUALLY_SELECTED: { text: '個別に選択する', label: '区分所有者を選択する', key: 'INDIVIDUALLY_SELECTED' },
} as const

export const TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_AFTER_OWNER_SELECTED: {[id: string]: { text: string, label: string, key: string }} = {
  ALL: { text: 'すべての区分所有者', label: '区分所有者を変更する', key: 'ALL' },
  GROUP: { text: 'グループから選択する', label: '区分所有者を変更する', key: 'GROUP' },
  INDIVIDUALLY_SELECTED: { text: '個別に選択する', label: '区分所有者を変更する', key: 'INDIVIDUALLY_SELECTED' },
} as const

export const FFNELEMENT_MENU_ITEMS_DEFAULT: {[id: string]: { text: string, label: string, key: string }} = {
  TEXT_MODE_WYSIWYG_EDITOR: { text: 'テキスト', label: '要素を追加する', key: 'TEXT_MODE_WYSIWYG_EDITOR' },
  ANNOTATION_MODE_WYSIWYG_EDITOR: { text: '注釈', label: '要素を追加する', key: 'ANNOTATION_MODE_WYSIWYG_EDITOR' },
  LINK_SMOOTHE: { text: 'リンク（内部）', label: '要素を追加する', key: 'LINK_SMOOTHE' },
  LINK_EXTERNAL: { text: 'リンク（外部）', label: '要素を追加する', key: 'LINK_EXTERNAL' },
  ATTACHMENT: { text: '添付ファイル', label: '要素を追加する', key: 'ATTACHMENT' },
  HORIZON: { text: '区切り線', label: '要素を追加する', key: 'HORIZON' },
  LINK_EMAIL: { text: 'メールアドレス', label: '要素を追加する', key: 'LINK_EMAIL' },
  LINK_PHONE_NUMBER: { text: '電話番号', label: '要素を追加する', key: 'LINK_PHONE_NUMBER' },
} as const

export const FFNELEMENT_MENU_ITEMS_LINK_SMOOTHE_RESTRICTED: {[id: string]: { text: string, label: string, key: string }} = {
  TEXT_MODE_WYSIWYG_EDITOR: { text: 'テキスト', label: '要素を追加する', key: 'TEXT_MODE_WYSIWYG_EDITOR' },
  ANNOTATION_MODE_WYSIWYG_EDITOR: { text: '注釈', label: '要素を追加する', key: 'ANNOTATION_MODE_WYSIWYG_EDITOR' },
  LINK_EXTERNAL: { text: 'リンク（外部）', label: '要素を追加する', key: 'LINK_EXTERNAL' },
  ATTACHMENT: { text: '添付ファイル', label: '要素を追加する', key: 'ATTACHMENT' },
  HORIZON: { text: '区切り線', label: '要素を追加する', key: 'HORIZON' },
  LINK_EMAIL: { text: 'メールアドレス', label: '要素を追加する', key: 'LINK_EMAIL' },
  LINK_PHONE_NUMBER: { text: '電話番号', label: '要素を追加する', key: 'LINK_PHONE_NUMBER' },
} as const

export const FFNELEMENT_MENU_ITEMS_ATTACHMENT_RESTRICTED: {[id: string]: { text: string, label: string, key: string }} = {
  TEXT_MODE_WYSIWYG_EDITOR: { text: 'テキスト', label: '要素を追加する', key: 'TEXT_MODE_WYSIWYG_EDITOR' },
  ANNOTATION_MODE_WYSIWYG_EDITOR: { text: '注釈', label: '要素を追加する', key: 'ANNOTATION_MODE_WYSIWYG_EDITOR' },
  LINK_SMOOTHE: { text: 'リンク（内部）', label: '要素を追加する', key: 'LINK_SMOOTHE' },
  LINK_EXTERNAL: { text: 'リンク（外部）', label: '要素を追加する', key: 'LINK_EXTERNAL' },
  HORIZON: { text: '区切り線', label: '要素を追加する', key: 'HORIZON' },
  LINK_EMAIL: { text: 'メールアドレス', label: '要素を追加する', key: 'LINK_EMAIL' },
  LINK_PHONE_NUMBER: { text: '電話番号', label: '要素を追加する', key: 'LINK_PHONE_NUMBER' },
} as const

export const FFNELEMENT_MENU_ITEMS_LINK_SMOOTHE_ATTACHMENT_RESTRICTED: {[id: string]: { text: string, label: string, key: string }} = {
  TEXT_MODE_WYSIWYG_EDITOR: { text: 'テキスト', label: '要素を追加する', key: 'TEXT_MODE_WYSIWYG_EDITOR' },
  ANNOTATION_MODE_WYSIWYG_EDITOR: { text: '注釈', label: '要素を追加する', key: 'ANNOTATION_MODE_WYSIWYG_EDITOR' },
  LINK_EXTERNAL: { text: 'リンク（外部）', label: '要素を追加する', key: 'LINK_EXTERNAL' },
  HORIZON: { text: '区切り線', label: '要素を追加する', key: 'HORIZON' },
  LINK_EMAIL: { text: 'メールアドレス', label: '要素を追加する', key: 'LINK_EMAIL' },
  LINK_PHONE_NUMBER: { text: '電話番号', label: '要素を追加する', key: 'LINK_PHONE_NUMBER' },
} as const

export const FFNELEMENT_CARD_TYPE = {
  TEXT_MODE_WYSIWYG_EDITOR: 1,
  ANNOTATION_MODE_WYSIWYG_EDITOR: 2,
  LINK_SMOOTHE: 3,
  LINK_EXTERNAL: 4,
  ATTACHMENT: 5,
  HORIZON: 6,
  LINK_EMAIL: 7,
  LINK_PHONE_NUMBER: 8,
} as const
export type FFNElementCardType = typeof FFNELEMENT_CARD_TYPE[keyof typeof FFNELEMENT_CARD_TYPE]

export const FFNELEMENT_CARD_TYPE_RECORD: Record<number, FFNElementCardType> = {
  [NOTIFICATION_ELEMENT_TYPES.BODY]: FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR,
  [NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL2]: FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR,
  [NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL3]: FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR,
  [NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL4]: FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR,
  [NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.DOT]: FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR,
  [NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.INDEX]: FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR,
  [NOTIFICATION_ELEMENT_TYPES.ROUNDED_SQUARE.LIGHT_GRAY]: FFNELEMENT_CARD_TYPE.ANNOTATION_MODE_WYSIWYG_EDITOR,
  [NOTIFICATION_ELEMENT_TYPES.LINK.SMOOTH_E]: FFNELEMENT_CARD_TYPE.LINK_SMOOTHE,
  [NOTIFICATION_ELEMENT_TYPES.LINK.EXTERNAL]: FFNELEMENT_CARD_TYPE.LINK_EXTERNAL,
  [NOTIFICATION_ELEMENT_TYPES.ATTACHMENT]: FFNELEMENT_CARD_TYPE.ATTACHMENT,
  [NOTIFICATION_ELEMENT_TYPES.HORIZON]: FFNELEMENT_CARD_TYPE.HORIZON,
  [NOTIFICATION_ELEMENT_TYPES.LINK.EMAIL]: FFNELEMENT_CARD_TYPE.LINK_EMAIL,
  [NOTIFICATION_ELEMENT_TYPES.LINK.PHONE_NUMBER]: FFNELEMENT_CARD_TYPE.LINK_PHONE_NUMBER,
} as const

export const TRANSITION_BUTTON_OPTION_VALUE = {
  NONE: 'none',
  POST_IDEA: 'postIdea',
  CONSULTATION: 'consultation',
} as const
export type TransitionButtonOptionValues = typeof TRANSITION_BUTTON_OPTION_VALUE[keyof typeof TRANSITION_BUTTON_OPTION_VALUE]
