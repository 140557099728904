import { ResolutionState } from '@/constants/schema-constants'
import { BaseIdea, Estimate, Section, User } from '@/dtos/commons'
import { Details } from '@/dtos/resolutions/commons'
import { Type } from 'class-transformer'

export class OnlineResolutionDetailGetRequest {
  resolutionId!: string

  constructor(resolutionId: string) {
    this.resolutionId = resolutionId
  }
}

export class OnlineResolutionDetailGetResponse {
  resolutionId!: string
  resolutionState!: ResolutionState
  postedAt!: string
  title!: string
  deadline!: string
  deadlineDateTime!: string
  voteDeadline!: string
  voteDeadlineDateTime!: string
  @Type(() => User) user = new User()
  @Type(() => Details) details: Details = new Details()
  matter!: string
  @Type(() => BaseIdea) baseIdea?: BaseIdea
  @Type(() => Estimate) estimates: Estimate[] = []
  @Type(() => Section) sections: Section[] = []
  version!: number
  processedAt!: string
}
