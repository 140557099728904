



















































import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator'
import { ownerIdeasModule } from '@/stores/owner-ideas-store'
import { OwnerIdea } from '@/dtos/ideas/owner-idea/get'
import { Header } from '@/components/molecules/SmTableData.vue'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class OwnerIdeasSelectModal extends Vue {
  headers : Header[] = [
    new Header({ text: '投稿日時', value: 'postedAt', filterable: false, width: '220px' }),
    new Header({ text: 'アイデアタイトル', value: 'title' }),
  ]

  private selectedIdeaIds:string[] = []

  @Model('change')
  private readonly isModalVisible!: boolean

  @Prop()
  private readonly baseIdeaId?: string

  @Prop({ default: '' })
  private readonly inputText!:string

  @Prop({ default: '' })
  private readonly keyword!: string

  private get ideas(): OwnerIdea[] { return ownerIdeasModule.ownerIdeasGet.ideas }

  @Watch('isModalVisible')
  openModal(): void {
    this.selectedIdeaIds = []
    if (this.baseIdeaId) this.selectedIdeaIds.push(this.baseIdeaId)
  }

  private selectedIdea():void {
    this.$emit('select', this.selectedIdeaIds[0]) // コンポーネント（SmTableData）の返却値は配列だが、当モーダルは単一選択のため返却値は一つのみ
  }

  async releaseIdea(): Promise<void> {
    this.$emit('release')
  }

  private get isSelected(): boolean {
    return this.selectedIdeaIds.length > 0
  }

  private get _isModalVisible(): boolean { return this.isModalVisible }
  private set _isModalVisible(newValue: boolean) { this.$emit('change', newValue) }

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }
}
