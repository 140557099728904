



























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'
import type { QuestionnaireQuestionType } from '@/constants/schema-constants'
import { QuestionnaireQuestionElement } from '@/dtos/questionnaires/get'
import { assertExhaustive } from '@/libs/exhaustive-helper'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmQuestion extends Vue {
  QUESTIONNAIRE_QUESTION_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_TYPES)

  @Prop({ default: QUESTIONNAIRE_QUESTION_TYPES.FREE })
  questionnaireQuestionType!: QuestionnaireQuestionType

  @Prop()
  sentence?: string

  @Prop()
  required!: boolean

  @Prop({ default: 1 })
  sortOrderNum!: number

  @Prop({ default: () => [] })
  questionnaireQuestionElements!: QuestionnaireQuestionElement[]

  private get questionTypeText(): string {
    switch (this.questionnaireQuestionType) {
      case QUESTIONNAIRE_QUESTION_TYPES.FREE: return `${this.sortOrderNum}. 設問(入力式)`
      case QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE: return `${this.sortOrderNum}. 設問(単一選択式)`
      case QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES: return `${this.sortOrderNum}. 設問(複数選択式)`
      default: return assertExhaustive(this.questionnaireQuestionType)
    }
  }
}
