

















import { Vue, Component, Prop } from 'vue-property-decorator'
import { ReserveFund, RepairPlan } from '@/dtos/simple-repairs/plans/get'

@Component({
  components: {
    SmChartSimpleRepairPlanPrint: () => import('@/components/molecules/SmChartSimpleRepairPlanPrint.vue'),
    SmTableSimpleRepairPlanPrint: () => import('@/components/molecules/SmTableSimpleRepairPlanPrint.vue'),
  }
})
export default class SimpleRepairPlanSummarySubPrintPage extends Vue {
  // 縦軸の値を2つの画面で共有するために、分割前のデータをもらう
  @Prop()
  repairPlan?:RepairPlan

  @Prop()
  reserveFund?:ReserveFund

  @Prop({ required: true })
  min!:number

  @Prop({ required: true })
  max!:number

  // もらうデータは分割前のものなので、テーブル用に分割する
  private get tableRepairPlan():RepairPlan {
    const repairPlan = new RepairPlan()
    repairPlan.originals = this.repairPlan?.originals.filter(e => this.min <= e.period && e.period <= this.max) ?? []
    repairPlan.performances = this.repairPlan?.performances?.filter(e => this.min <= e.period && e.period <= this.max) ?? []
    return repairPlan
  }

  private get tableReserveFund():ReserveFund {
    const reserveFund = new ReserveFund()
    reserveFund.prospects = this.reserveFund?.prospects.filter(e => this.min <= e.period && e.period <= this.max) ?? []
    reserveFund.performances = this.reserveFund?.performances?.filter(e => this.min <= e.period && e.period <= this.max) ?? []
    return reserveFund
  }
}
