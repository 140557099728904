






























































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { DAY_OF_WEEK } from '@/constants/ux-constants'

import { BuildingOwnersGetRequest } from '@/dtos/buildings/owners/get'
import { Section, MaterialFormInputDto, BaseIdea } from '@/dtos/commons'
import { SessionGetResponseSession, Guide, Result } from '@/dtos/ideas/admin-idea/commons'
import { AdminIdeaDetailGetRequest, AdminIdeaDetailGetResponse, AdminIdeaDetailGetResponsePlan } from '@/dtos/ideas/admin-idea/get-detail'
import { AdminIdeaOpinionsGetRequest, AdminIdeaOpinionsGetResponse } from '@/dtos/ideas/admin-idea/opinions/get'
import { PreviewPlan, PreviewSession } from '@/dtos/ideas/admin-idea/post'
import { AdminIdeaResultsGetRequest, AdminIdeaResultsGetResponse } from '@/dtos/ideas/admin-idea/results/get'
import { AdminIdeaSessionGetResponse } from '@/dtos/ideas/admin-idea/session/get'
import { AdminIdeaSessionPutRequest } from '@/dtos/ideas/admin-idea/session/put'
import { AdminIdeaUpdatesGetRequest, AdminIdeaUpdatesGetResponse } from '@/dtos/ideas/admin-idea/updates/get'

import { BusinessDate } from '@/libs/business-date'
import { deepCopy, ColumnToType } from '@/libs/deep-copy-provider'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'
import { staticKeyProvider } from '@/libs/static-key-provider'
import { generateUuid } from '@/libs/uuid-generator'
import { windowOpen } from '@/libs/window-open'

import { staticRoutes } from '@/routes'

import { adminIdeasModule } from '@/stores/admin-ideas-store'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { newTabLocalParamStorageModule, AdminIdeaPreviewContent } from '@/stores/new-tab-local-param-storage-store'

const TAKE = 10

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardSectionText: () => import('@/components/molecules/card/SmCardSectionText.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class AdminIdeaSessionUpdatePage extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop()
  ideaId!: string

  showPutDialog = false
  sessions: SessionGetResponseSession[] = []

  private buildingId: string | null = null

  async created(): Promise<void> {
    // プレビュー画面>内部リンク遷移で使用する物件IDを取得
    this.buildingId = currentStateModule.currentBuildingId
    const buildingReq = new BuildingOwnersGetRequest()
    buildingReq.buildingId = this.buildingId ?? ''
    await buildingsModule.fetchBuildingOwners(buildingReq)
    if (this.ideaId) {
      await adminIdeasModule.fetchIdeaSession({ ideaId: this.ideaId })
      const detailReq = new AdminIdeaDetailGetRequest(this.ideaId)
      await adminIdeasModule.fetchIdeaDetail(detailReq)
      const opinionReq = new AdminIdeaOpinionsGetRequest()
      opinionReq.ideaId = this.ideaId
      opinionReq.take = TAKE
      await adminIdeasModule.fetchIdeaOpinions(opinionReq)
      const agreementReq = new AdminIdeaResultsGetRequest()
      agreementReq.ideaId = this.ideaId
      await adminIdeasModule.fetchIdeaResults(agreementReq)
      const updatereq = new AdminIdeaUpdatesGetRequest()
      updatereq.take = TAKE
      updatereq.ideaId = this.ideaId
      await adminIdeasModule.fetchIdeaUpdates(updatereq)
    }
  }

  private get storeSessions(): AdminIdeaSessionGetResponse {
    return adminIdeasModule.adminIdeasSessionGet(this.ideaId) ?? new AdminIdeaSessionGetResponse()
  }

  @Watch('storeSessions', { immediate: false, deep: false })
  private onStoredResolutionFetched(fetched: AdminIdeaSessionGetResponse | undefined): void {
    if (!fetched) return

    this.sessions = deepCopy(
      fetched.sessions,
      {
        sessions: new ColumnToType(SessionGetResponseSession, true),
        guide: new ColumnToType(Guide),
        result: new ColumnToType(Result),
        material: new ColumnToType(MaterialFormInputDto),
        sections: new ColumnToType(Section, true),
      },
      'sessions'
    )
  }

  private get ideaDetail(): AdminIdeaDetailGetResponse {
    return adminIdeasModule.detailResponse(this.ideaId) ?? new AdminIdeaDetailGetResponse()
  }

  private get ideaOpinions(): AdminIdeaOpinionsGetResponse {
    return adminIdeasModule.adminIdeasOpinionsGet(this.ideaId) ?? new AdminIdeaOpinionsGetResponse()
  }

  private get ideaAgreement(): AdminIdeaResultsGetResponse {
    return adminIdeasModule.adminIdeasResultsGet(this.ideaId) ?? new AdminIdeaResultsGetResponse()
  }

  private get ideaUpdate(): AdminIdeaUpdatesGetResponse | undefined {
    if (!this.ideaId) return
    return adminIdeasModule.adminIdeasUpdatesGet(this.ideaId) ?? new AdminIdeaUpdatesGetResponse()
  }

  private get ownerCount(): number {
    return buildingsModule.buildingOwnersGet.owners.length
  }

  private async putSessions(): Promise<void> {
    const rawReq = new AdminIdeaSessionPutRequest()
    rawReq.ideaId = this.ideaId
    rawReq.sessions = this.sessions
    rawReq.version = this.storeSessions.version

    this.showPutDialog = false
    const uploader = new FileUploader()
    const req = await uploader.prepare(rawReq)

    await adminIdeasModule.putIdeaSession(req)
    this.$router.go(-1)
  }

  private isSessionResult(sessionIndex: number) {
    if (this.sessions[sessionIndex].result) {
      return true
    }
    return false
  }

  // 素材IDと取得済み参照用URLを含む素材情報の組み合わせを保持
  materialReferenceURLMap: Map<string, MaterialFormInputDto> = new Map()

  async onClickPreviewBtn(): Promise<void> {
    const previewContents: AdminIdeaPreviewContent = new AdminIdeaPreviewContent()
    previewContents.ideaId = this.ideaId
    previewContents.title = this.ideaDetail.title
    previewContents.ideaState = this.ideaDetail.ideaState
    previewContents.isAgreementDenied = this.ideaDetail.isNotAcceptable
    previewContents.previewSession = await this.toSessionPreviewDto(this.sessions)
    previewContents.previewPlans = this.toPlansPreviewDto(this.ideaDetail.plans, this.ideaAgreement)
    previewContents.previewOpinions = this.ideaOpinions?.opinions
    if (this.ideaDetail.baseIdea?.ideaId) previewContents.previewBaseIdea = new BaseIdea(this.ideaDetail.baseIdea?.ideaId)
    previewContents.agreedOwnerCount = this.ideaAgreement?.summary.agreedOwnerCount ?? 0
    const businessDateFormatHandler = new BusinessDate()
    previewContents.opinionDeadline = businessDateFormatHandler.toDateTimeString(new Date(this.ideaDetail.opinionDeadlineDateTime))
    previewContents.deadline = businessDateFormatHandler.toDateTimeString(new Date(this.ideaDetail.deadlineDateTime))
    previewContents.lastUpdatedAt = this.ideaDetail.processedAt
    if (this.ideaDetail.user) previewContents.user = this.ideaDetail.user
    previewContents.postedAt = this.ideaDetail.postedAt
    previewContents.currentBuildingId = this.buildingId ?? ''
    previewContents.ownerCount = this.ownerCount
    previewContents.agreementRequirement = buildingsModule.buildingDetailGet?.agreementRequirement ?? ''
    previewContents.isUpdates = this.ideaUpdate?.updates ? this.ideaUpdate?.updates.length > 0 : false

    const previewContentsId = generateUuid()
    newTabLocalParamStorageModule.setAdminIdeaPreviewContent({ key: previewContentsId, adminIdeaPreviewContent: previewContents })
    windowOpen(staticRoutes.adminIdeaPreview.path.replace(':id', previewContentsId))
  }

  async toSessionPreviewDto(sessions: SessionGetResponseSession[]): Promise<PreviewSession> {
    const pewviewSessionDto = new PreviewSession()
    pewviewSessionDto.postedAt = this.nowDate()
    pewviewSessionDto.sessions = await Promise.all(sessions.map(async section => {
      if (section.guide.material) {
        section.guide.material = await this._uploadMaterial(Object.assign(new MaterialFormInputDto(), section.guide.material))
      }
      if (section.result?.material) {
        section.result.material = await this._uploadMaterial(Object.assign(new MaterialFormInputDto(), section.result.material))
      }
      return section
    }))
    return pewviewSessionDto
  }

  private nowDate(): string {
    const date = new Date()
    const numberOfDate = date.getDay()
    const dayOfWeek = DAY_OF_WEEK[numberOfDate]
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日(${dayOfWeek})`
  }

  toPlansPreviewDto(plans: AdminIdeaDetailGetResponsePlan[], ideaAgreement: AdminIdeaResultsGetResponse): PreviewPlan[] {
    const previewPlans = plans.map(plan => {
      const previewPlan = new PreviewPlan()
      previewPlan.planId = plan.planId
      previewPlan.title = plan.itemTitle
      // 賛同ボタンの押下処理は必要ないため、falseで固定
      previewPlan.isAgreed = false
      previewPlan.agreementCount = ideaAgreement ? this.getAgreementCount(plan.planId, ideaAgreement) : 0
      previewPlan.budgets = plan.estimates.map(estimate => estimate)
      previewPlan.previewElements = plan.sections.map(section => section)
      return previewPlan
    })
    return previewPlans
  }

  getAgreementCount(planId: string, ideaAgreement: AdminIdeaResultsGetResponse): number {
    return ideaAgreement.agreements.find(agreement => agreement.planId === planId)?.agreementCount ?? 0
  }

  private async _uploadMaterial(material: MaterialFormInputDto): Promise<MaterialFormInputDto | undefined> {
    if (!material.materialId) { // 新たに添付した素材の場合
      // ローカルストレージのサイズの制約上、プレビュー時に素材をそのまま別タブに渡すのが難しいため、ここでアップロードする
      const uploadedMaterial = await uploadMaterial(material)
      if (uploadedMaterial) {
        this.materialReferenceURLMap.set(uploadedMaterial.materialId, uploadedMaterial)
        return uploadedMaterial
      }
    } else if (this.materialReferenceURLMap.get(material.materialId)) { // 一度プレビューした素材を付け替えずに使用する場合
      const renamedMaterial = this.materialReferenceURLMap.get(material.materialId)
      if (renamedMaterial) renamedMaterial.originalFileName = material.originalFileName
      return renamedMaterial
    } else { // すでにDBに登録済みの素材を画面初期表示から一度も付け替えずに使用する場合
      return material
    }
  }

  private addSessionResult(sessionIndex: number): void {
    const result = new Result()
    result.itemTitle = '意見交換会の結果'
    this.$set(this.sessions[sessionIndex], 'result', result)
  }

  private deleteSessionResult(sessionIndex: number): void {
    this.$delete(this.sessions[sessionIndex], 'result')
  }

  private addSession(): void {
    const session = staticKeyProvider.create(SessionGetResponseSession)
    session.guide.sections.push(this.createSection())
    session.guide.itemTitle = '意見交換会のご案内'
    this.sessions.push(session)
  }

  private deleteSession(sessionIndex: number): void {
    this.sessions.splice(sessionIndex, 1)
  }

  private createSection(): Section {
    const section = staticKeyProvider.create(Section)
    section.sectionTitle = ''
    section.sectionBody = ''
    section.material = null
    return section
  }

  private addSectionToSession(sessionIndex: number): void {
    this.sessions[sessionIndex].guide.sections.push(this.createSection())
  }

  private deleteSectionFromSession(sessionIndex: number, sectionIndex: number): void {
    this.sessions[sessionIndex].guide.sections.splice(sectionIndex, 1)
  }

  private goUpSectionInSession(sessionIndex: number, sectionIndex: number): void {
    const targetSections = this.sessions[sessionIndex].guide.sections
    targetSections.splice(sectionIndex - 1, 2, targetSections[sectionIndex], targetSections[sectionIndex - 1])
  }

  private goDownSectionInSession(sessionIndex: number, sectionIndex: number): void {
    const targetSections = this.sessions[sessionIndex].guide.sections
    targetSections.splice(sectionIndex, 2, targetSections[sectionIndex + 1], targetSections[sectionIndex])
  }
}
