














































































































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import { errorsModule } from '@/stores/errors'

const inputTypes = [
  'text',
  'number',
  'password'
] as const
type InputType = typeof inputTypes[number]

@Component({
  components: {
    SmLegend: () => import('@/components/atoms/SmLegend.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmTextField extends Vue {
  showPassword = false

  private get fieldErrors() {
    return errorsModule.fieldErrors(this.fieldId)?.join(' ')
  }

  @Prop()
  private readonly label?: string

  @Prop({ default: false })
  private readonly hideLabel!: boolean

  @Prop()
  private readonly legendLabel?: string

  @Prop()
  private readonly legendColor?: string

  @Prop()
  private readonly placeholder?: string

  @Prop({
    default: 'text',
    validator: t => inputTypes.includes(t)
  })
  private readonly type!: InputType

  @Model('input', { default: '' })
  private readonly text?: string | number // type="number"のとき、数字以外の値が入力された場合にundefinedを代入するするため、Optional Parameterを付与している

  @Prop({ default: '' })
  private readonly fieldId!: string

  @Prop({ default: 'auto' })
  private readonly hideDetails!: boolean | string

  @Prop({ default: false })
  private readonly required!: boolean

  @Prop({ default: 100 })
  private readonly counter!: number

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly showCounter!: boolean

  @Prop({ default: false })
  private readonly isReadonly!: boolean

  @Prop({ default: false })
  private readonly immediate!: boolean

  @Prop({ default: () => [] })
  private readonly validationRules!: string[]

  @Prop({ default: 'この入力欄' })
  private readonly validationText?: string

  private get rules(): string {
    const rules = [...this.validationRules]
    if (this.required) rules.push('required')
    if (this.counter) rules.push(`max:${this.counter}`)
    return rules.join('|')
  }

  private get inputText(): string | number | undefined { return this.text }
  private set inputText(newValue: string | number | undefined) {
    if (errorsModule.fieldErrors(this.fieldId)) errorsModule.clearSingleFieldError(this.fieldId)
    if (this.type === 'number' && typeof (newValue) !== 'number') this.$emit('input', undefined) // number型のときでも空欄がstring型の空文字になる問題を解消する
    else this.$emit('input', newValue)
  }

  private get password(): boolean {
    return this.type === 'password'
  }

  private get icon():string {
    if (!this.password) { return '' }
    return this.showPassword ? '$visibility_outlined' : '$visibility_off_outlined'
  }
}
