import { ClassConstructor, plainToClass } from 'class-transformer'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyInitialized, store } from './index'

/**
 * 再びその画面に戻ってくる場合を見越してパラメータを保持させたい場合などに、その保管先として用いるストア
 * 原則的に保持のキーは画面名（staticRoutes.hoge.name）を指定する想定だが、1画面に複数の保管対象がある場合などは、各画面内で一意になるよう定義する
 *
 * 保存したパラメータは、保存者の意図しない特定のタイミングでリセットされることがある（ログアウトや特別なボタン押下時など）
 * そういったリセットの影響を受けたくないパラメータは、キーの頭文字を小文字アンダースコア(_)で定義すること
 *
 * 本ストアのパラメータはブラウザを閉じると失われるので、ブラウザを閉じても保持しておきたい場合はlocal-param-storage-store.tsを保管先とすること
 *
 * リアクティビティが要求されるユースケースは想定していない
 */
@Module({ store, dynamic: true, namespaced: true, name: 'paramStorage', preserveState: isAlreadyInitialized })
class ParamStorageStore extends VuexModule {
  private _savedParams: Record<string, unknown> = {}

  get savedParam() {
    return <T>(key: string, parsedTo: ClassConstructor<T>): T | undefined => {
      if (!this._savedParams) return undefined
      const stored = this._savedParams[key]
      return stored ? plainToClass(parsedTo, stored) : undefined
    }
  }

  @Mutation private SET({ key, params }: { key: string, params: unknown }) {
    if (!this._savedParams) this._savedParams = {}
    this._savedParams[key] = params
  }

  @Mutation private CLEAR_SAVE_PARAMS() {
    this._savedParams = {}
  }

  @Mutation private DELETE(key: string) { delete this._savedParams[key] }

  @Action save(_: { key: string, params: unknown }) { this.SET(_) }
  @Action delete(key: string) { this.DELETE(key) }

  @Action deleteAll() {
    Object.keys(this._savedParams).filter(key => !key.startsWith('_')).forEach(key => this.DELETE(key))
  }

  @Action clear() {
    this.CLEAR_SAVE_PARAMS()
  }
}

export const paramStorageModule = getModule(ParamStorageStore)
