import { Type } from 'class-transformer'
import type { IdeaState, IdeaType } from '@/constants/schema-constants'

export class BuildingIdeasGetRequest {
  buildingId!: string
  ideaStates!: IdeaState[]
  hasUnreadOwners?: boolean
}

export class BuildingIdeaDto {
  ideaId!: string
  ideaType!: IdeaType
  ideaState!: IdeaState
  title!: string
  postedAt!: string
  deadline?: string
  deadlineDateTime?: string
}

export class BuildingIdeasGetResponse {
  @Type(() => BuildingIdeaDto) ideas: BuildingIdeaDto[] = []
}
