





















































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { RESOLUTION_STATES } from '@/constants/schema-constants'
import { CHIP_COLORS, ERROR_MESSAGES } from '@/constants/ux-constants'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { myProfileModule } from '@/stores/my-profile-store'
import { onlineResolutionsModule } from '@/stores/online-resolutions-store'
import { structureModule } from '@/stores/structure-store'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { OnlineResolutionDetailGetRequest, OnlineResolutionDetailGetResponse } from '@/dtos/resolutions/online-resolution/get-detail'
import { getResolutionLabel } from '@/libs/state-handler'

@Component({
  components: {
    SmBadgeContent: () => import('@/components/atoms/SmBadgeContent.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmListBudget: () => import('@/components/molecules/list/SmListBudget.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
  }
})
export default class DetailSubPage extends Mixins(CurrentAdminManager) {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)

  @Prop({ required: true, default: '' })
  private readonly resolutionId!:string

  @Prop()
  private readonly buildingId?: string

  async created():Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    await onlineResolutionsModule.fetchResolutionDetail(new OnlineResolutionDetailGetRequest(this.resolutionId))
  }

  private get isPreview(): boolean { return structureModule.isPreview }

  // TODO データを取得できなかったケースの実装
  private get detail(): OnlineResolutionDetailGetResponse {
    return onlineResolutionsModule.detailResponse(this.resolutionId) ?? new OnlineResolutionDetailGetResponse()
  }

  private get stateLabel():string {
    return getResolutionLabel(this.detail.resolutionState)
  }

  private get stateType():string {
    return this.detail.resolutionState === RESOLUTION_STATES.ONLINE.COUNTING || this.detail.resolutionState === RESOLUTION_STATES.ONLINE.RELEASED ? 'onlineArchive' : 'online'
  }

  // 管理者業務執行者、且つ決議の投稿者のみ編集権限を持つ
  private get hasEditAuthority():boolean {
    return this.$isServiceStaff
  }

  // 管理者業務執行者、フロント担当のみ印刷可能
  private get hasPrintAuthority(): boolean {
    return this.$isServiceStaff || this.$isFrontPerson
  }

  private get isReleased():boolean {
    return this.detail.resolutionState === RESOLUTION_STATES.ONLINE.RELEASED
  }

  // 編集権限を持ち、且つ結果公開済以外のタイミングのみ編集可能
  private get isEditable():boolean {
    return this.hasEditAuthority && !this.isReleased
  }

  isExpansion = false

  onClickEdit():void {
    if (this.isPreview) return
    this.$router.push({ name: staticRoutes.onlineResolutionUpdate.name, params: { resolutionId: this.resolutionId } })
  }

  // TODO PDFボタンクリック実装
  onClickPrintPdf():void {
    if (this.isPreview) return
    this.$router.push({ name: staticRoutes.onlineResolutionDetailPrint.name, params: { resolutionId: this.resolutionId } })
  }

  /**
   * 決議のもととなった情報を表示するため、賛同意見受付画面へ遷移
   */
  private goToBaseIdea():void {
    if (this.isPreview) return
    const ideaId = this.detail.baseIdea?.ideaId ?? ''
    this.$router.push({ name: staticRoutes.adminIdeaDetail.name, params: { ideaId: ideaId } })
  }
}
