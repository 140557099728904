import { TaskPostRequest, TaskPostResponse } from '@/dtos/tasks/post'
import { TaskPutRequest, TaskPutResponse } from '@/dtos/tasks/put'
import { TasksSearchPostRequest, TasksSearchPostResponse } from '@/dtos/tasks/search/post'
import { TaskStatePutRequest, TaskStatePutResponse } from '@/dtos/tasks/state/put'
import { APIClientBase } from './api-client-base'

class TasksClient extends APIClientBase {
  constructor() {
    super('/tasks')
  }

  // タスク一覧取得 ※検索条件の上限撤廃のため、getではなくpostを使用
  postSearchTasks(req: TasksSearchPostRequest):Promise<TasksSearchPostResponse | void> {
    return this.post('search', req, TasksSearchPostResponse, false)
  }

  postTask(req: TaskPostRequest): Promise<void | TaskPostResponse> {
    return this.post('', req, TaskPostResponse)
  }

  putTask(req: TaskPutRequest): Promise<void | TaskPutResponse> {
    const { ticketTaskId, ...body } = req
    return this.put(`${ticketTaskId}`, body, TaskPutResponse)
  }

  putTaskStates(req: TaskStatePutRequest): Promise<void | TaskStatePutResponse> {
    const { ticketTaskId, ...body } = req
    return this.put(`${ticketTaskId}/states`, body, TaskStatePutResponse)
  }
}
export const tasksClient = new TasksClient()
