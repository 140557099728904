import { Type } from 'class-transformer'

export class BuildingOwnersGetRequest {
  buildingId!:string
}

export class BuildingOwnerDto {
  userId!:string
  userName!:string
  roomNumber!:string
}

export class BuildingOwnersGetResponse {
  @Type(() => BuildingOwnerDto)
  owners:BuildingOwnerDto[] = []
}
