





































import { Vue, Component, Prop } from 'vue-property-decorator'
import type { Reaction } from '@/dtos/static-assets/get'
import { staticAssetsModule } from '@/stores/static-assets-store'

@Component
export default class SmMenu extends Vue {
  @Prop({ default: () => [] })
  private readonly selectedReactions!: string[]

  @Prop({ required: true, default: false })
  private isAvailableReaction!: boolean

  private get reactions(): Reaction[] { return staticAssetsModule.reactions }

  private _selected(assetId: string): boolean {
    return this.selectedReactions?.some(selectedAssetId => selectedAssetId === assetId)
  }
}
