import { questionnairesClient } from '@/clients/questionnaires-client'

import { QuestionnaireTemplateDetailGetResponse, QuestionnaireTemplateDetailGetRequest } from '@/dtos/questionnaires/templates/get-detail'
import { QuestionnaireTemplatePostResponse, QuestionnaireTemplatePostRequest } from '@/dtos/questionnaires/templates/post'
import { QuestionnaireTemplatePutResponse, QuestionnaireTemplatePutRequest } from '@/dtos/questionnaires/templates/put'
import { QuestionnaireTemplatesDeleteRequest } from '@/dtos/questionnaires/templates/delete'
import { QuestionnaireTemplatesGetRequest, QuestionnaireTemplatesGetResponse } from '@/dtos/questionnaires/templates/get'
import { QuestionnaireTemplateDraftDeleteRequest } from '@/dtos/questionnaires/templates/draft/delete'
import { QuestionnaireTemplateDraftPostRequest } from '@/dtos/questionnaires/templates/draft/post'
import { TemplateQuestionsGetResponse } from '@/dtos/questionnaires/templates/questions/get'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'

@Module({ store, dynamic: true, namespaced: true, name: 'questionnaireTemplatesStore' })
class QuestionnaireTemplatesStore extends VuexModule {
  // テンプレート投稿
  private _questionnaireTemplatePost = new QuestionnaireTemplatePostResponse()

  get questionnaireTemplatePost():QuestionnaireTemplatePostResponse { return this._questionnaireTemplatePost }

  @Mutation
  private SET_QUESTIONNAIRE_TEMPLATE_POST(newValue:QuestionnaireTemplatePostResponse) { this._questionnaireTemplatePost = newValue }

  @Action
  async postQuestionnaireTemplate(req:QuestionnaireTemplatePostRequest):Promise<void> {
    const res = await questionnairesClient.postQuestionnaireTemplate(req)
    if (res) this.SET_QUESTIONNAIRE_TEMPLATE_POST(res)
  }

  // アンケートテンプレート下書き保存
  @Action
  async postQuestionnaireTemplateDraft(req: QuestionnaireTemplateDraftPostRequest): Promise<void> {
    await questionnairesClient.postQuestionnaireTemplateDraft(req)
  }

  // アンケートテンプレート下書き削除
  @Action
  public async deleteQuestionnaireTemplateDraft(req: QuestionnaireTemplateDraftDeleteRequest) {
    await questionnairesClient.deleteQuestionnaireTemplateDraft(req)
  }

  // テンプレート更新
  private _questionnaireTemplatePut = new QuestionnaireTemplatePutResponse()

  get questionnaireTemplatePut():QuestionnaireTemplatePutResponse { return this._questionnaireTemplatePut }

  @Mutation
  private SET_QUESTIONNAIRE_TEMPLATE_PUT(newValue:QuestionnaireTemplatePutResponse) { this._questionnaireTemplatePost = newValue }

  @Action
  async putQuestionnaireTemplate(req:QuestionnaireTemplatePutRequest):Promise<void> {
    const res = await questionnairesClient.putQuestionnaireTemplate(req)
    if (res) this.SET_QUESTIONNAIRE_TEMPLATE_PUT(res)
  }

  // テンプレート詳細取得
  private _questionnaireTemplateDetailGet = new QuestionnaireTemplateDetailGetResponse()

  get questionnaireTemplateDetailGet():QuestionnaireTemplateDetailGetResponse { return this._questionnaireTemplateDetailGet }

  @Mutation
  private SET_QUESTIONNAIRE_TEMPLATE_DETAIL_GET(newValue:QuestionnaireTemplateDetailGetResponse) { this._questionnaireTemplateDetailGet = newValue }

  @Action
  async fetchQuestionnaireTemplateDetail(req:QuestionnaireTemplateDetailGetRequest):Promise<void> {
    const res = await questionnairesClient.getQuestionnaireTemplateDetail(req)
    if (res) this.SET_QUESTIONNAIRE_TEMPLATE_DETAIL_GET(res)
  }

  @Mutation
  private SET_CLEAR_QUESTIONNAIRE_TEMPLATE() { this._questionnaireTemplateDetailGet = new QuestionnaireTemplateDetailGetResponse() }

  @Action
  clearQuestionnaireTemplateDetail():void {
    this.SET_CLEAR_QUESTIONNAIRE_TEMPLATE()
  }

  private _templatesGet = new QuestionnaireTemplatesGetResponse()

  public get templatesGet(): QuestionnaireTemplatesGetResponse {
    return this._templatesGet
  }

  // 一覧検索
  @Mutation
  private SET_TEMPLATES_GET(res:QuestionnaireTemplatesGetResponse): void {
    this._templatesGet = res
  }

  @Action
  public async fetchQuestionnaireTemplates(req: QuestionnaireTemplatesGetRequest): Promise<void> {
    const res = await questionnairesClient.getQuestionnaireTemplates(req)
    this.SET_TEMPLATES_GET(res)
  }

  // アンケートテンプレート削除
  @Action
  public async deleteQuestionnaireTemplate(req: QuestionnaireTemplatesDeleteRequest) {
    await questionnairesClient.deleteQuestionnaireTemplate(req)
  }

  // アンケートテンプレート（設問）一覧取得
  private _questionsGet = new TemplateQuestionsGetResponse()

  public get questionGet(): TemplateQuestionsGetResponse { return this._questionsGet }

  @Mutation
  private SET_QUESTIONS_GET(res: TemplateQuestionsGetResponse): void { this._questionsGet = res }

  @Action
  async fetchTemplateQuestions(templateId: string) {
    const res = await questionnairesClient.getQuestionnaireTemplateQuestions(templateId)
    if (res) this.SET_QUESTIONS_GET(res)
  }
}

export const questionnaireTemplatesModule = getModule(QuestionnaireTemplatesStore)
