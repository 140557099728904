






























































import { concatSerialChar } from '@/libs/add-alphabet-provider'

import { Vue, Component, Prop } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { Agreement } from '@/dtos/ideas/admin-idea/agreements/get'

@Component({
  components: {
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
  }
})
export default class ProxyAgreeTable extends Vue {
  tableHeaders: Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '113px' }),
    new Header({ text: '氏名', value: 'userName', width: '144px' }),
    new Header({ text: '保有議決権', value: 'votingRightCount', width: '120px', filterable: false }),
    new Header({ text: '賛同内容', value: 'plans', sortable: false, filterable: false }),
  ]

  @Prop({ default: () => [] })
  agreements!: Agreement[]

  @Prop()
  private readonly keyword?: string

  getPlanTitleWithPrefix(planTitle:string, planLength:number, planIndex: number): string {
    return `【プラン${concatSerialChar(planLength, planIndex)}】${planTitle}`
  }
}
