/**
 * 新規ウィンドウ展開
 * （タブナビング対策済、window.openを使う際はこちらを使うこと）
 * 参考：https://cheatsheetseries.owasp.org/cheatsheets/HTML5_Security_Cheat_Sheet.html#tabnabbing
 */
export function windowOpen(url:string):void {
  const newWindow = window.open(url, undefined, 'noopener,noreferrer')
  if (newWindow) {
    newWindow.opener = null
  }
}
