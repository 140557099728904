import { Type } from 'class-transformer'
import type { TemplateState, TemplateType } from '@/constants/schema-constants'

export class QuestionnaireTemplatesGetRequest {
  skip: number
  take: number
  keyword?: string

  constructor(skip: number, take: number, keyword?: string) {
    this.skip = skip
    this.take = take
    this.keyword = keyword
  }
}

export class QuestionnaireTemplate {
  templateId!: string
  templateName!: string
  templateType!: TemplateType
  templateState!: TemplateState
  usageNote!: string
  sectionsNumber!: number
}

export class QuestionnaireTemplatesGetResponse {
  @Type(() => QuestionnaireTemplate) templates:QuestionnaireTemplate[] = []
}
