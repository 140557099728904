import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { Report, ReportsGetRequest, ReportsGetResponse } from '@/dtos/reports/get'
import { reportsClient } from '@/clients/reports-client'
import { SettleReportDeleteRequest } from '@/dtos/reports/settle/delete'

@Module({ store, dynamic: true, namespaced: true, name: 'reports' })
class ReportsStore extends VuexModule {
  // 通報一覧
  private _reports:Report[] = []

  public get reports():Report[] {
    return this._reports
  }

  @Mutation
  private SET_REPORTS(res:ReportsGetResponse):void {
    this._reports = res.reports
  }

  @Action
  public async fetchReports(req:ReportsGetRequest):Promise<void> {
    const res = await reportsClient.getReports(req)
    this.SET_REPORTS(res)
  }

  // 削除
  @Action
  public async deleteOpinion(req:SettleReportDeleteRequest) {
    await reportsClient.deleteSettleReport(req)
  }
}

export const reportsModule = getModule(ReportsStore)
