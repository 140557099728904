import { Type } from 'class-transformer'

export class RepairsItemsGetRequest {
  repairPlanId?: string

  constructor(repairPlanId?: string) {
    this.repairPlanId = repairPlanId
  }
}

export class Period {
  period!: number
  prospect?: number
  performance?: number
}

export class Item {
  itemLabel!: string
  @Type(() => Period) periods: Period[] = []
  total!: number
}
export class Category {
  categoryLabel!: string
  @Type(() => Item) items: Item[] = []
}

export class DetailRow {
  @Type(() => Category) categories: Category[] = []
}

export class PeriodTotal {
  period!: number
  taxExcluded?: number
  tax?: number
  taxIncluded?: number
  cumulative?: number
}

export class RowTotal {
  taxExcluded!: number
  tax!: number
  taxIncluded!: number
  cumulative!: number
}

export class TotalRow {
  @Type(() => PeriodTotal) periodTotals: PeriodTotal[] = []
  @Type(() => RowTotal) rowTotal = new RowTotal()
}

/*
 * 【Responseの構造】
 *
 * RepairsItemsGetResponse
 * ┣ detailRows
 * ┃ ┗ categories[]
 * ┃   ┣ categoryLabel
 * ┃   ┗ items[]
 * ┃     ┣ itemLabel
 * ┃     ┣ periods[]
 * ┃     ┃ ┣ period
 * ┃     ┃ ┣ prospect?
 * ┃     ┃ ┗ performance?
 * ┃     ┗ 項目合計
 * ┣ totalRows
 * ┃ ┣ periodTotals[]
 * ┃ ┃ ┣ period
 * ┃ ┃ ┣ taxExcluded
 * ┃ ┃ ┣ tax
 * ┃ ┃ ┣ taxIncluded
 * ┃ ┃ ┗ cumulative
 * ┃ ┗ rowTotal
 * ┃    ┣ taxExcluded
 * ┃    ┣ tax
 * ┃    ┣ taxIncluded
 * ┃    ┗ cumulative
 * ┗ lastUpdatedAt
 */
export class RepairsItemsGetResponse {
  @Type(() => DetailRow) detailRows?: DetailRow
  @Type(() => TotalRow) totalRows?: TotalRow
  lastUpdatedAt?: string
  processedAt?: string
}
