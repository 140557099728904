import { Type } from 'class-transformer'

export class RepairsUpdatesLogDetailGetRequest {
  logId!: string

  constructor(logId: string) {
    this.logId = logId
  }
}

export class FileName {
  previous!: string
  current!: string
}

export class LogDetailGetResponseLog {
  previous!: string
  current!: string
}

export class RepairsUpdatesLogDetailGetResponse {
  logId!: string
  updatedAt!: string
  @Type(() => FileName) fileName = new FileName()
  @Type(() => LogDetailGetResponseLog) logs: LogDetailGetResponseLog[] = []
  repairPlanId!: string
  isOriginalPlan!: boolean
  isAvailable!: boolean
}
