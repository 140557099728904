import { PreparingStatusPostRequest, PreparingStatusPostResponse } from './post'

export class PreparingStatusPutRequest extends PreparingStatusPostRequest {
  resolutionPreparingStatusId!: string
  version!: number

  constructor(ideaId: string, resolutionPreparingStatusBody: string, resolutionPreparingStatusId: string, version: number) {
    super(ideaId, resolutionPreparingStatusBody)
    this.resolutionPreparingStatusId = resolutionPreparingStatusId
    this.version = version
  }
}

export class PreparingStatusPutResponse extends PreparingStatusPostResponse {
}
