import { Type } from 'class-transformer'

export class ReservePaymentsPdfGetRequest {
  buildingUnits:string[] = []
  paymentPlans:string[] = []
}

export class PeriodAmount {
  period!: number
  amount!: number
}

export class ProspectivePlan {
  paymentPlanId!:string
  @Type(() => PeriodAmount)amounts:PeriodAmount[] = []
}

export class ReserveFund {
  total!:number
  amountPaid!:number
  balance!:number
  @Type(() => PeriodAmount)minimumAmounts:PeriodAmount[] = []
  @Type(() => ProspectivePlan)prospectivePlansAmounts:ProspectivePlan[] = []
  @Type(() => PeriodAmount)currentPlanAmounts?:PeriodAmount[]
  baseMonth!:string
}

export class PlanDetail {
  period!: string
  payment!: number
  pricePerSqm!: number
}

export class Plan {
  paymentPlanId!:string
  planCode!:string
  planName!:string
  @Type(() => PlanDetail)planDetails:PlanDetail[] = []
}

export class UnitPaymentPlan {
  buildingUnitId!: string
  roomNumber!: string
  userName!: string
  occupiedArea!: number
  @Type(() => ReserveFund)reserveFund = new ReserveFund()
  @Type(() => Plan)currentPlan?:Plan
  @Type(() => Plan)prospectivePlans:Plan[] = []
}

export class ReservesPaymentsPdfGetResponse {
  @Type(() => UnitPaymentPlan)unitPaymentPlans:UnitPaymentPlan[] = []
  processedAt!:string
  lastPeriod!:number
}
