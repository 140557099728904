




















































































import { Vue, Component, Model, Prop, Watch } from 'vue-property-decorator'
import { QuestionnairesProxyInputQuestionElement, QuestionnairesProxyInputItem } from '@/pages/questionnaires/questionnaire-detail/respondents/QuestionnairesProxyInputTable.vue'
import { QUESTIONNAIRE_QUESTION_ELEMENT_TYPES } from '@/constants/schema-constants'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { QuestionnaireDetailGetRequest, QuestionnaireDetailGetResponse } from '@/dtos/questionnaires/get'
import { QuestionnairesRespondentsGetRequest, QuestionnairesRespondentsGetResponse } from '@/dtos/questionnaires/respondents/get'
import { QuestionnairesRespondentsPostRequest, QuestionnairesRespondentsAnswerElement, QuestionnairesRespondentsProxyAnswer, QuestionnairesRespondentsProxyQuestion } from '@/dtos/questionnaires/respondents/post'
import { questionnairesModule } from '@/stores/questionnaires-store'

class QuestionnairesProxyInputItemAddOwner {
    ownerUserId!: string
    ownerUserName!: string
    questionnairesProxyInputItem!: QuestionnairesProxyInputItem[]
    roomNumber!: string
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),

    QuestionnairesProxyInputTable: () => import('@/pages/questionnaires/questionnaire-detail/respondents/QuestionnairesProxyInputTable.vue'),
  }
})
export default class QuestionnairesProxyInputModal extends Vue {
  @Model('change')
  private readonly isVisible!: boolean

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  @Prop({ required: true, default: [] })
  private readonly selectedUserIds!: string[]

  private get _selectedUserIds(): string[] { return this.selectedUserIds }
  private set _selectedUserIds(newValue: string[]) { this.$emit('update:selectedUserIds', newValue) }

  @Prop({ required: true, default: '' })
  private readonly questionnaireId!: string

  @Prop({ required: true })
  private readonly usersAnswerCounts!: Map<string, number>

  private get questionnaire(): QuestionnaireDetailGetResponse {
    return questionnairesModule.questionnaireDetail(this.questionnaireId) ?? new QuestionnaireDetailGetResponse()
  }

  private get questionnaireRespondents(): QuestionnairesRespondentsGetResponse {
    return questionnairesModule.questionnaireRespondents(this.questionnaireId) ?? new QuestionnairesRespondentsGetResponse()
  }

  proxyInputItemsAddOwner: QuestionnairesProxyInputItemAddOwner[] = []

  @Watch('isVisible', { immediate: true })
  async openModal():Promise<void> {
    const proxyInputItemsAddOwner : QuestionnairesProxyInputItemAddOwner[] = []
    this.proxyInputItemsAddOwner.splice(0, this.proxyInputItemsAddOwner.length)

    // アンケート詳細取得のレスポンスのデータをアンケート代理入力テーブル用のクラスに詰め替える
    const respondents = this.questionnaireRespondents.respondents
    if (!respondents.length) return
    const selectedRespondent = respondents.filter(r => { return this.selectedUserIds.includes(r.ownerUserId) })
    selectedRespondent.forEach(sr => {
      const tableItemAddOwner = new QuestionnairesProxyInputItemAddOwner()
      tableItemAddOwner.ownerUserId = sr.ownerUserId
      tableItemAddOwner.ownerUserName = sr.ownerUserName
      tableItemAddOwner.roomNumber = sr.roomNumber
      const proxyInputTableItems : QuestionnairesProxyInputItem[] = []
      this.questionnaire.questionnaireSections.forEach(qs => {
        qs.questionnaireQuestions.forEach((qq) => {
          const tableItem = new QuestionnairesProxyInputItem(
            qs.questionnaireSectionId,
            qs.questionnaireSectionType,
            qs.title ?? '',
            qq.questionnaireQuestionId,
            qq.questionnaireQuestionType,
            qq.sentence,
            qq.required
          )
          qq.questionnaireQuestionElements.forEach(qqe => {
            const tableItemQuestionElement = new QuestionnairesProxyInputQuestionElement(qqe.questionnaireQuestionElementId, qqe.questionnaireQuestionElementType)
            tableItemQuestionElement.questionnaireQuestionElementChoice = qqe.choice
            tableItemQuestionElement.questionnaireQuestionElementPlaceholder = qqe.placeholder
            tableItem.questionnaireQuestionElements.push(tableItemQuestionElement)
          })
          proxyInputTableItems.push(tableItem)
          tableItemAddOwner.questionnairesProxyInputItem = proxyInputTableItems
        })
      })
      proxyInputItemsAddOwner.push(tableItemAddOwner)
      Object.assign(this.proxyInputItemsAddOwner, proxyInputItemsAddOwner)
    })
  }

  showDialog = false

  showConfirmPage = false

  // アンケート代理登録リクエスト送信
  async postProxyAnswers():Promise<void> {
    this.showDialog = false

    const proxyAnswers : QuestionnairesRespondentsProxyAnswer[] = []
    this.proxyInputItemsAddOwner.forEach(i => {
      const questions : QuestionnairesRespondentsProxyQuestion[] = []
      i.questionnairesProxyInputItem.forEach(inputItem => {
        const proxyAnswerElements : QuestionnairesRespondentsAnswerElement[] = []
        inputItem.questionnaireQuestionElements.forEach(qe => {
          switch (qe.questionnaireQuestionElementType) {
            case QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.FREE: {
              const answeredElement = new QuestionnairesRespondentsAnswerElement()
              answeredElement.questionnaireQuestionElementId = qe.questionnaireQuestionElementId
              if (qe.questionnaireQuestionElementFreeAnswer) {
                answeredElement.freeAnswer = qe.questionnaireQuestionElementFreeAnswer
              }
              proxyAnswerElements.push(answeredElement)
              break
            }
            case QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.SELECTABLE_OPTION: {
              const answeredElement = new QuestionnairesRespondentsAnswerElement()
              answeredElement.questionnaireQuestionElementId = qe.questionnaireQuestionElementId
              if (qe.answeredOwnerUserId === i.ownerUserId) {
                answeredElement.choiceAnswer = true
              } else {
                answeredElement.choiceAnswer = false
              }
              proxyAnswerElements.push(answeredElement)
              break
            }
            case QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION: {
              const answeredElement = new QuestionnairesRespondentsAnswerElement()
              answeredElement.questionnaireQuestionElementId = qe.questionnaireQuestionElementId
              if (qe.answeredOwnerUserId === i.ownerUserId) {
                answeredElement.choiceAnswer = true
                answeredElement.freeAnswer = qe.questionnaireQuestionElementFreeAnswer
              } else {
                answeredElement.choiceAnswer = false
              }
              proxyAnswerElements.push(answeredElement)
              break
            } default: return assertExhaustive(qe.questionnaireQuestionElementType)
          }
        })
        const question = new QuestionnairesRespondentsProxyQuestion()
        question.questionnaireQuestionId = inputItem.questionnaireQuestionId
        question.questionnaireQuestionType = inputItem.questionnaireQuestionType
        question.required = inputItem.questionnaireQuestionRequired
        question.answers = proxyAnswerElements
        questions.push(question)
      })
      const proxyAnswer = new QuestionnairesRespondentsProxyAnswer()
      proxyAnswer.ownerUserId = i.ownerUserId
      proxyAnswer.answerCount = this.usersAnswerCounts.get(i.ownerUserId) ?? 1
      proxyAnswer.questions = questions
      proxyAnswers.push(proxyAnswer)
    })
    const req = new QuestionnairesRespondentsPostRequest()
    req.proxyAnswers = proxyAnswers
    const questionnaireId = this.questionnaireId
    await questionnairesModule.postProxyAnswers({ questionnaireId, req })

    this.isModalVisible = false
    this.showConfirmPage = false
    this._selectedUserIds = []
    await questionnairesModule.fetchQuestionnairesRespondents(new QuestionnairesRespondentsGetRequest(questionnaireId))
    await questionnairesModule.fetchQuestionnaireDetail(new QuestionnaireDetailGetRequest(this.questionnaireId))
  }
}
