































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AREA_TYPE, AUDIT_STATES, ADMIN_ROLE, REPAIR_PLAN_TYPES } from '@/constants/schema-constants'
import type { RepairPlanType } from '@/constants/schema-constants'

import { BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { User } from '@/dtos/commons'
import { BuildingsPutRequest, StaffUserId } from '@/dtos/buildings/put'

import { getAuditLabel } from '@/libs/state-handler'
import { getAreaLabel, getRepairPlanTypeLabel } from '@/libs/type-handler'

import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { buildingsModule } from '@/stores/buildings-store'
import { staticRoutes } from '@/routes'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    StaffSelectModal: () => import('@/components/organisms/modal/StaffSelectModal.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

  }
})
export default class BuildingEditPage extends Vue {
  @Prop({ required: true, default: '' })
  private readonly buildingId!: string

  async created(): Promise<void> {
    // const req = new BuildingDetailGetRequest(this.buildingId)

    // todo: ここで詳細取得を呼んだところで今の初期化方法では間に合っていない（手前のページで取得出来ているから上手くいくだけ）
    // 実際、現状はマンション詳細からしか遷移しえない構造なので良しとする、取り急ぎAPI呼び出しはコメントアウトしている
    // await buildingsModule.fetchBuildingDetail(req)
  }

  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)

  get building(): BuildingDetailGetResponse | null {
    return buildingsModule.buildingDetailGet
  }

  get isOutOfService(): boolean {
    return this.building?.isOutOfService ?? false
  }

  get isSimpleRepairPlan(): boolean {
    return this.building?.repairPlanType === REPAIR_PLAN_TYPES.SIMPLE_REPAIR_PLAN
  }

  get formIsConsultationUse(): boolean | string {
    if (!this.isTrialTargetBuilding) return ''
    return this.isConsultationUse
  }

  set formIsConsultationUse(value: boolean | string) {
    if (typeof value === 'boolean') this.isConsultationUse = value
  }

  serviceStaff = this.building?.serviceStaff
  frontStaffs = this.building?.frontStaff ?? []
  lmStaffs = this.building?.lmStaff ?? []
  buildingAreaType = this.building?.buildingAreaType ?? AREA_TYPE.TOKYO
  condominiumCd = this.building?.condominiumCd ?? ''
  ridgeCd = this.building?.ridgeCd ?? ''
  buildingName = this.building?.buildingName ?? ''
  buildingPostalCode = this.building?.buildingPostalCode ?? ''
  buildingAddress = this.building?.buildingAddress ?? ''
  isFacilityReservationAvailable = this.building?.isFacilityReservationAvailable ?? false
  gmMonth = this.building?.gmMonth ?? 0
  isTrialTargetBuilding = this.building?.isTrialTargetBuilding ?? false
  isConsultationUse = this.building?.isConsultationUse ?? false
  accounting = this.building?.accounting ?? AUDIT_STATES.ENTRUST_TO_OUTSIDE_AUDITOR
  adoptRepairPlanService = this.building?.adoptRepairPlanService ?? false
  adoptReservePlanService = this.building?.adoptReservePlanService ?? false
  keyPerson = this.building?.keyPerson ?? null
  concerns = this.building?.concerns ?? null
  serviceStaffInputText = ''
  serviceStaffKeyword = ''
  frontStaffInputText = ''
  frontStaffKeyword = ''
  lmStaffInputText = ''
  lmStaffKeyword = ''

  buildingAreaTypeList = [
    { value: AREA_TYPE.TOKYO, label: getAreaLabel(AREA_TYPE.TOKYO) },
    { value: AREA_TYPE.KANSAI, label: getAreaLabel(AREA_TYPE.KANSAI) },
    { value: AREA_TYPE.CMQ, label: getAreaLabel(AREA_TYPE.CMQ) },
    { value: AREA_TYPE.CMW, label: getAreaLabel(AREA_TYPE.CMW) },
    { value: AREA_TYPE.CMO, label: getAreaLabel(AREA_TYPE.CMO) },
  ]

  auditItems = [
    { value: AUDIT_STATES.ENTRUST_TO_OUTSIDE_AUDITOR, label: getAuditLabel(AUDIT_STATES.ENTRUST_TO_OUTSIDE_AUDITOR) },
    { value: AUDIT_STATES.ELECT_INSIDE_AUDITOR, label: getAuditLabel(AUDIT_STATES.ELECT_INSIDE_AUDITOR) }
  ]

  processOptions = [new RadioOption('未完了', false), new RadioOption('完了', true)]
  availableOptions = [new RadioOption('無', false), new RadioOption('有', true)]
  trialTargetOptions = [new RadioOption('試験提供', true), new RadioOption('本提供', false)]

  repairPlanTypeLabel(repairPlanType: RepairPlanType): string { return getRepairPlanTypeLabel(repairPlanType) }

  showServiceStaffSelectModal = false
  async openServiceStaffSelectModal():Promise<void> {
    this.showServiceStaffSelectModal = true
  }

  showFrontStaffSelectModal = false
  async openFrontStaffSelectModal():Promise<void> {
    this.showFrontStaffSelectModal = true
  }

  showLmStaffSelectModal = false
  async openLmStaffSelectModal():Promise<void> {
    this.showLmStaffSelectModal = true
  }

  isUpdateDialogVisible = false
  openUpdateDialog(): void {
    this.isUpdateDialogVisible = true
  }

  async onClickUpdate(): Promise<void> {
    this.isUpdateDialogVisible = false
    let frontStaffIds
    if (this.frontStaffs.length > 0) {
      frontStaffIds = this.frontStaffs.map(fr => new StaffUserId(fr.userId))
    }
    let lmStaffIds
    if (this.lmStaffs.length > 0) {
      lmStaffIds = this.lmStaffs.map(lm => new StaffUserId(lm.userId))
    }

    const req = new BuildingsPutRequest(
      this.buildingId,
      this.buildingAreaType,
      this.condominiumCd,
      this.ridgeCd,
      this.buildingName,
      this.buildingPostalCode,
      this.buildingAddress,
      this.gmMonth,
      this.isTrialTargetBuilding,
      this.accounting,
      this.adoptRepairPlanService,
      this.adoptReservePlanService,
      this.isFacilityReservationAvailable,
      this.isTrialTargetBuilding ? this.isConsultationUse : undefined,
      this.keyPerson,
      this.concerns,
      this.serviceStaff,
      frontStaffIds,
      lmStaffIds
    )
    await buildingsModule.putBuildings(req)

    this.$router.push({ name: staticRoutes.buildingDetail.name, params: { buildingId: this.buildingId } })
  }

  selectServiceStaff(serviceStaff : User[]): void {
    this.serviceStaff = serviceStaff[0]
  }

  selectFrontStaffs(frontStaffs : User[]): void {
    this.frontStaffs = [...frontStaffs]
  }

  selectLmStaffs(lmStaffs: User[]): void {
    this.lmStaffs = [...lmStaffs]
  }
}
