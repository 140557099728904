import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyInitialized, store } from './index'

// ※※※ 他のストアやapi-client-baseから参照される基礎的なストアとなる ※※※
// ※※※ その関係で、逆にこのストアから他への参照は持ってはならない ※※※
@Module({ store, dynamic: true, namespaced: true, name: 'current', preserveState: isAlreadyInitialized })
class CurrentStateStore extends VuexModule {
  private _currentBuildingId: string | null = null

  get currentBuildingId(): string | null { return this._currentBuildingId }

  @Mutation private SET_CURRENT_BUILDING(id: string | null) { this._currentBuildingId = id }
  @Action setCurrentBuilding(id: string | null) { this.SET_CURRENT_BUILDING(id) }
  @Action clear() { this.SET_CURRENT_BUILDING(null) }
}

export const currentStateModule = getModule(CurrentStateStore)
