import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import MaterialIcon from '@/components/atoms/icons/MaterialIcon.vue'
import SmChatIcon from '@/components/atoms/icons/SmChatIcon.vue'
import SmQuestionnaireIcon from '@/components/atoms/icons/SmQuestionnaireIcon.vue'
import SmIdeaPlanIcon from '@/components/atoms/icons/SmIdeaPlanIcon.vue'
import SmPlanIcon from '@/components/atoms/icons/SmPlanIcon.vue'
import { VuetifyIcons } from 'vuetify/types/services/icons'

Vue.use(Vuetify)

// https://vuetifyjs.com/en/features/icons/#missing-material-icons
function missingMaterialIcons(ids:string[]) {
  const icons: Partial<VuetifyIcons> = {}
  for (const id of ids) {
    for (const suffix of ['round', 'outlined']) { // 使用するテーマをここに追加 eg. 'round', 'outlined'
      const name = `${id}_${suffix}`
      icons[name] = {
        component: MaterialIcon,
        props: {
          name
        }
      }
    }
  }
  return icons
}

export default new Vuetify({
  breakpoint: {
    thresholds: {
      md: 1024,
    },
    mobileBreakpoint: 'md'
  },
  icons: {
    iconfont: 'md', // https://material.io/resources/icons
    values: {
      'sm-chat': {
        component: SmChatIcon,
      },
      'sm-questionnaire-icon': {
        component: SmQuestionnaireIcon,
      },
      'sm-idea-plan-icon': {
        component: SmIdeaPlanIcon,
      },
      'sm-plan-icon': {
        component: SmPlanIcon,
      },
      ...missingMaterialIcons([ // 追加したテーマを適用するアイコンをここに追加する（alphabet順）
        'add',
        'add_circle_outline',
        'add_reaction',
        'apartment',
        'arrow_back',
        'arrow_drop_down',
        'attach_file',
        'auto_awesome_mosaic',
        'campaign',
        'check_box',
        'check_box_outline_blank',
        'chevron_right',
        'close',
        'content_copy',
        'create',
        'delete_outline',
        'description',
        'drag_handle',
        'east',
        'edit_outline',
        'emoji_objects',
        'error_outline',
        'expand_more',
        'flag',
        'groups',
        'highlight_off',
        'how_to_vote',
        'indeterminate_check_box',
        'insert_chart',
        'local_fire_department',
        'manage_accounts',
        'more_vert',
        'north',
        'notifications_none',
        'open_in_new',
        'picture_as_pdf',
        'push_pin',
        'receipt_long',
        'sort',
        'south',
        'search',
        'sticky_note_2',
        'task_alt',
        'text_snippet',
        'thumb_up',
        'visibility',
        'visibility_off',
      ])
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#00A2D5', // Surface/MainColor, Object/MainColor
        secondary: '#0073A3', // Surface/MainDarkColor, Text/MainDarkColor, Object/MainDarkColor, Focus/Stroke
        accent: '#FFEF51', // Surface/AccentColor
        info: '#000000', // figmaのカラー指定との対応が判明次第修正？
        warning: '#B22D12', // Surface/CautionColor, Object/Caution, Stroke/Caution
        success: '#0073A3',
        error: '#B22D12',

        // custom
        reflection: '#C55200', // Surface/Reflection, Object/Reflection, Expressive/OrangeDark
        caution: '#B22D12',
      }
    },
    options: { cspNonce: 'dQw4w9WgXcQ' },
  }
})
