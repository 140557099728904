





import { Component, Mixins } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { STATUS_CODE, TransitionTo, TRANSITION_TO } from '@/constants/schema-constants'
import { ERROR_MESSAGES } from '@/constants/ux-constants'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { TransitionParams } from '@/dtos/transition'
import { cognitoAuth } from '@/libs/cognito-auth-adapter'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { authModule } from '@/stores/auth-store'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { localParamStorageModule } from '@/stores/local-param-storage-store'
import { myProfileModule } from '@/stores/my-profile-store'
import { newTabLocalParamStorageModule } from '@/stores/new-tab-local-param-storage-store'
import { newTabLocalTransitionParamStorageModule } from '@/stores/new-tab-local-transition-param-storage-store'
import { staticAssetsModule } from '@/stores/static-assets-store'

const TRANSITION_TO_RECORD: Record<TransitionTo, { route: string }> = {
  [TRANSITION_TO.IDEA.OWNER]: { route: staticRoutes.ownerIdeaDetail.name },
  [TRANSITION_TO.IDEA.ADMIN]: { route: staticRoutes.adminIdeaDetail.name },
  [TRANSITION_TO.RESOLUTION.GENERAL_MEETING]: { route: staticRoutes.gmResolutionDetail.name },
  [TRANSITION_TO.RESOLUTION.ONLINE]: { route: staticRoutes.onlineResolutionDetail.name },
  [TRANSITION_TO.QA]: { route: staticRoutes.consultationDetail.name },
  [TRANSITION_TO.REPORT]: { route: staticRoutes.reports.name },
  [TRANSITION_TO.TICKET.DETAIL]: { route: staticRoutes.ticketDetail.name },
  [TRANSITION_TO.TICKET.DETAIL_TASK]: { route: staticRoutes.ticketDetail.getChild('tasks').name },
  [TRANSITION_TO.NOTICE]: { route: staticRoutes.notices.name },
  [TRANSITION_TO.STAFF_DETAIL.FRONT]: { route: staticRoutes.frontDetail.name },
  [TRANSITION_TO.STAFF_DETAIL.LIFE_MANAGER]: { route: staticRoutes.lifeManagerDetail.name },
  [TRANSITION_TO.CASYS_RESULTS]: { route: staticRoutes.casysResultsListPage.name },
}

@Component({
  components: {
    SmProgressOverlay: () => import('@/components/organisms/SmProgressOverlay.vue'),
  }
})
export default class LoginCompletionPage extends Mixins(CurrentAdminManager) {
  async created(): Promise<void> {
    // 自動ログインの場合（認証済トークンを持って画面に入ってくる）
    if (cognitoAuth.sessionIsValid()) {
      await this.transition()
    } else {
      // Azure ADから認証されて来た場合、クエリパラメータを解釈してセッション情報を生成する
      await cognitoAuth.parseSAMLResponse(window.location.href, {
        // 認証成功
        onSuccess: async() => {
          await this.transition()
        },
        // 認証失敗(Azure ADでは処理成功しているがURLが改ざんされている等)
        onFailure: async() => {
          await authModule.logout(ERROR_MESSAGES.UNEXPECTED)
        }
      })
    }
  }

  async transition(): Promise<void> {
    try {
      await authModule.login()
      if (authModule.loginResponse.resultCode !== STATUS_CODE.SUCCESS) {
        await authModule.logout(ERROR_MESSAGES.INVALID_USER)
        return
      }
      await myProfileModule.fetchMyProfile()
      if (this.$isServiceStaff) await staticAssetsModule.fetchReactions()
      // メールリンクから遷移した場合はメールリンクのクエリパラメータを元に遷移先を判断する
      if (localParamStorageModule.transitionParams) await this.moveBasedOnTransitionParams(localParamStorageModule.transitionParams)
      else await this.moveBasedOnAccount()
    } catch (err) {
      await authModule.logout(ERROR_MESSAGES.SESSION_EXPIRED)
    } finally {
      localParamStorageModule.clearTransitionParams()
      newTabLocalParamStorageModule.deleteAllOwnerNotificationPreviewContents()
      newTabLocalTransitionParamStorageModule.deleteAllNewTabTransitionParams()
    }
  }

  async moveBasedOnTransitionParams(transitionParams:TransitionParams): Promise<void> {
    const { buildingId, transitionTo, ...params } = transitionParams
    if (buildingId) {
      // 物件配下の画面へ遷移を要求されている場合、その物件の詳細取得と、選択中物件の保持
      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(buildingId))
      currentStateModule.setCurrentBuilding(buildingId)
    }

    if (transitionTo) {
      const toRouteName = TRANSITION_TO_RECORD[parseInt(transitionTo) as TransitionTo]?.route
      if (toRouteName) {
        await this.moveBasedOnAccount().then(firstRoute => { // 遷移先の戻る画面を、このログイン画面ではなくするために2段階遷移する
          if (firstRoute.name !== toRouteName) this.$router.push({ name: toRouteName, params: params as Record<string, string> /* avoid error */ })
        })
        return
      }
    }
    await this.moveBasedOnAccount() // 遷移先が無指定 or 特定できなかった場合は権限に応じて遷移
  }

  moveBasedOnAccount(): Promise<Route> { // 基本導線
    if (this.$isServiceStaff) return this.$router.push({ name: staticRoutes.dashboard.name })
    else if (this.$isAccountingDept || this.$isFrontPerson || this.$isGroupCallCenter) return this.$router.push({ name: staticRoutes.buildingsList.name })
    else throw new Error() // unexpected
  }
}
