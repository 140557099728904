






















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { MaterialFormInputDto } from '@/dtos/commons'
import { MaterialType, MATERIAL_TYPES } from '@/constants/schema-constants'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class SmCardSectionText extends Vue {
  @Prop({ default: 'セクション' })
  private readonly title!: string

  @Prop({ default: 'セクションタイトル' })
  private readonly sectionTitleLabel!: string

  @Prop({ default: 'セクション本文' })
  private readonly sectionBodyLabel!: string

  @Prop()
  private readonly sectionTitlePlaceholder?: string

   @Prop()
  private readonly sectionBodyPlaceholder?: string

  @Prop({ default: () => [MATERIAL_TYPES.IMAGE, MATERIAL_TYPES.VIDEO, MATERIAL_TYPES.PDF] })
  private readonly acceptMaterials!: MaterialType[]

  @Prop({ default: false })
  private readonly head!: boolean

  @Prop({ default: false })
  private readonly foot!: boolean

  @Prop({ default: false })
  private readonly deletable!: boolean

  @Prop()
  private readonly fieldIdPrefix?: string

  @Prop()
  private sectionTitle?: string

  @Prop()
  private sectionBody?: string

  @Prop()
  private material?: MaterialFormInputDto

  private get _sectionTitle(): string | undefined { return this.sectionTitle }
  private set _sectionTitle(newValue: string | undefined) {
    this.$emit('update:sectionTitle', newValue)
  }

  private get _sectionBody(): string | undefined { return this.sectionBody }
  private set _sectionBody(newValue: string | undefined) {
    this.$emit('update:sectionBody', newValue)
  }

  private get _material(): MaterialFormInputDto | undefined { return this.material }
  private set _material(newValue: MaterialFormInputDto | undefined) {
    this.$emit('update:material', newValue)
  }
}
