import { Type } from 'class-transformer'
import { CASE_TYPES, INFORMATION_SOURCE_TYPES, TICKET_TYPES } from '@/constants/schema-constants'
import type { AreaType, CaseType, InformationSourceType, TicketInquirySourceType, TicketType, TransitionTo, ResolutionType, ResolutionState } from '@/constants/schema-constants'
import { User } from '@/dtos/commons'

export class TicketDetailGetRequest {
  ticketId!: string

  constructor(ticketId: string) {
    this.ticketId = ticketId
  }
}

export class TicketInquirySourceDto {
  inquirySourceType!: TicketInquirySourceType
  inquirySourceId!: string
  inquirySourceTitle!: string
}

export class TicketResolutionDto {
  resolutionType!: ResolutionType
  resolutionState!: ResolutionState
}

export class TicketRelatedInformationDto {
  title!: string
  transitionType!: TransitionTo
  transitionParams!: string
  @Type(() => TicketResolutionDto) resolution?: TicketResolutionDto
}

export class TicketBuildingDto {
  buildingId!: string
  buildingName!: string
  condominiumCd!: string
  areaType!: AreaType
}

export class TicketUserDto extends User {
  unitId? : string
}

export class TicketDetailGetResponse {
  ticketId!: string
  ticketType: TicketType = TICKET_TYPES.IDEA
  ticketName!: string
  receptionAt!: string
  receptionDate!: string
  informationSourceType: InformationSourceType = INFORMATION_SOURCE_TYPES.IDEA_POSTED
  informationSource!: string
  caseType: CaseType = CASE_TYPES.OWNER_IDEA
  inquiryBody?: string
  @Type(() => TicketInquirySourceDto) inquirySources!: TicketInquirySourceDto[]
  correspondence?: string
  @Type(() => TicketRelatedInformationDto) relatedInformation?: TicketRelatedInformationDto
  @Type(() => TicketBuildingDto) building?: TicketBuildingDto
  @Type(() => TicketUserDto) ownerUser?: TicketUserDto
  isManagementStatusReport!: boolean
  isOperationStatusReport!: boolean
}
