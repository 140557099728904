






































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { buildingsModule } from '@/stores/buildings-store'
import { ReserveFund, RepairPlan } from '@/dtos/repairs/expenses/get'

class Record {
  label!: string
  symbolColor!:string
  isLine?: boolean
  cells!: (number | undefined)[]
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmLegend: () => import('@/components/atoms/SmLegend.vue'),

    VueScrollingTable: () => import('@/components/external-packages/vue-scrolling-table/VueScrollingTable.vue'),
  }
})
export default class SmTableRepairPlan extends Vue {
  @Prop()
  private readonly repairPlan?: RepairPlan

  @Prop()
  private readonly reserveFund?: ReserveFund

  private get periods():number[] {
    return this.repairPlan?.originals.map(e => e.period) ?? []
  }

  private get yearMonths():string[] {
    return this.periods.map(e => `${e}期\n${buildingsModule.periodToYearMonth(e)}`)
  }

  private get records():Record[] {
    if (!this.repairPlan || !this.reserveFund) return []
    return [
      {
        label: '当初修繕計画',
        symbolColor: '#A1ABAE',
        isLine: false,
        cells: this.repairPlan.originals.map(e => e.amount),
      },
      {
        label: '修繕費予測',
        symbolColor: '#00A2D5',
        isLine: false,
        cells: this.repairPlan.prospects.map(e => e.amount),
      },
      {
        label: '修繕費実績',
        symbolColor: '#FC5353',
        isLine: false,
        cells: this.repairPlan.performances.map(e => e.amount),
      },
      {
        label: '積立額予測',
        symbolColor: '#0073A3',
        isLine: true,
        cells: this.reserveFund.prospects.map(e => e.amount),
      },
      {
        label: '積立額実績',
        symbolColor: '#B03131',
        isLine: true,
        cells: this.reserveFund.performances.map(e => e.amount),
      },
    ]
  }
}
