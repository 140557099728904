














































































































import { Component, Vue } from 'vue-property-decorator'
import { reserveFundsPlansModule } from '@/stores/reserve-funds-plans-store'
import { PaymentPlanDetailEdit, PaymentPlanDetailInitialForm, PaymentPlanInitialFormGetResponse } from '@/dtos/reserve-funds/plans/initial-form/get-detail'
import { PaymentPlanPostRequest } from '@/dtos/reserve-funds/plans/post'
import { PaymentDetail, Price } from '@/dtos/reserve-funds/get'
import { staticRoutes } from '@/routes'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { BulkInputProp } from '@/pages/reserve-funds/BulkInputDialog.vue'
import { getFirstPeriodScale, getBillingPeriod } from '@/libs/reserve-funds-billing-handler'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    BulkInputDialog: () => import('@/pages/reserve-funds/BulkInputDialog.vue'),

    PaymentPlanDetailTable: () => import('@/pages/reserve-funds/plans/PaymentPlanDetailTable.vue'),
    MonthlyAmountTable: () => import('@/pages/reserve-funds/MonthlyAmountTable.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class PaymentPlanEditPage extends Vue {
  private get planCode():string { return reserveFundsPlansModule.reservePlanInitialForm.planCode }

  paymentPlanDetails:PaymentPlanDetailEdit[] = []

  private get paymentDetails():PaymentDetail[] {
    if (!this.paymentPlanDetails || !this.paymentPlanDetails) {
      return []
    }
    const paymentDetails = reserveFundsPlansModule.reservePlanInitialForm.paymentDetail.map(paymentDetail => {
      paymentDetail.prices = this.paymentPlanDetails.map(paymentPlanDetail => {
        const price = new Price()
        price.period = paymentPlanDetail.period
        // 1の位で四捨五入
        price.monthlyPrice = Math.round(((paymentPlanDetail.unitPrice ?? 0) * paymentDetail.occupiedArea) / 10) * 10
        return price
      })
      return paymentDetail
    })
    return paymentDetails
  }

  private get billingPeriod(): number {
    return getBillingPeriod()
  }

  private get firstPeriodScale(): number {
    return getFirstPeriodScale()
  }

  private get estimatedCost(): number[] {
    let total = 0
    return this.paymentPlanDetails.map(plan => {
      this.paymentDetails.forEach(paymentDetail => {
        const minUnitPrice = Math.round(((plan.minUnitPrice ?? 0) * paymentDetail.occupiedArea) / 10) * 10
        total = total + minUnitPrice * (plan.period === this.billingPeriod ? this.firstPeriodScale : 12) * paymentDetail.buildingUnitCount
      })
      return total
    })
  }

  private get totalPeriodReserves(): number[] {
    let total = 0
    return this.paymentPlanDetails.map((plan, index) => {
      this.paymentDetails.forEach(paymentDetail => {
        total = total + paymentDetail.prices[index].monthlyPrice * (plan.period === this.billingPeriod ? this.firstPeriodScale : 12) * paymentDetail.buildingUnitCount
      })
      return total
    })
  }

  request = new PaymentPlanPostRequest()

  error = ''

  isPostDialogVisible = false
  openPostDialog(): void {
    this.isPostDialogVisible = true
  }

  closePostDialog(): void {
    this.isPostDialogVisible = false
  }

  async onClickExecuteBtn():Promise<void> {
    this.isPostDialogVisible = false
    this.error = ''
    // 積立金額の超過チェック
    let isCompleted = false
    let isCompletedPeriod = 0
    const lastTotalMinUnitPrice = this.estimatedCost[this.estimatedCost.length - 1]
    this.paymentPlanDetails.some((paymentPlanDetail, index) => {
      // 既に必要な積立金を満たした状態で追加で㎡単価を入力した場合エラー
      if (isCompleted && paymentPlanDetail.unitPrice > 0) {
        this.error = `必要な積立額を${isCompletedPeriod}期で満たしていますが、${isCompletedPeriod + 1}期以降も㎡単価が入力されています。`
        return true
      } else {
        const totalUnitPrice = this.totalPeriodReserves[index]
        if (!isCompleted && totalUnitPrice >= lastTotalMinUnitPrice) {
          // 必要な積立金を満たしたタイミングを記録する
          isCompleted = true
          isCompletedPeriod = paymentPlanDetail.period
        }
        return false
      }
    })
    // エラーがあったらリクエストをしない
    if (this.error) return

    // リクエスト生成
    this.request.paymentPlanDetail = this.paymentPlanDetails.map(e => {
      return {
        targetYear: e.targetYear,
        unitPrice: e.unitPrice
      }
    })
    await reserveFundsPlansModule.postReservePlan(this.request)
    this.$router.push(staticRoutes.reserveFunds.getChild('plans'))
  }

  async created():Promise<void> {
    await reserveFundsPlansModule.fetchReservePlanInitialForm()
    const reservePlanInitialForm = deepCopy(reserveFundsPlansModule.reservePlanInitialForm, {
      this: new ColumnToType(PaymentPlanInitialFormGetResponse),
      paymentPlanDetail: new ColumnToType(PaymentPlanDetailInitialForm),
      paymentDetail: new ColumnToType(PaymentDetail),
    }, 'this')

    this.paymentPlanDetails = reservePlanInitialForm.paymentPlanDetail.map(e => {
      const paymentPlanDetail = Object.assign(new PaymentPlanDetailEdit(), e)

      paymentPlanDetail.unitPrice = paymentPlanDetail.minUnitPrice
      return paymentPlanDetail
    })
  }

  bulkInsertDialogVisible = false

  private get periodItems(): { value: number | null, label: string }[] {
    return Array.from({ length: 60 - (this.billingPeriod - 1) }).map((_, index) => {
      const period = this.billingPeriod + index
      return { value: period, label: `${period}` }
    })
  }

  bulkInput(bulkInputProp:BulkInputProp):void {
    this.paymentPlanDetails = this.paymentPlanDetails.map(e => {
      if (bulkInputProp.inputValue !== null && bulkInputProp.duration.periodFrom <= e.period && e.period <= bulkInputProp.duration.periodTo) {
        e.unitPrice = bulkInputProp.inputValue
      }
      return { ...e } // 参照渡しすると更新がうまく走らなくなるので、スプレッド構文で値を渡す
    })
    this.bulkInsertDialogVisible = false
  }
}
