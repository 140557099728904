





































import { ReservePaymentDetailGetResponse } from '@/dtos/reserve-funds/payments/get-detail'
import { reservePaymentsModule } from '@/stores/reserve-payments-store'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    SmBadgeInline: () => import('@/components/atoms/SmBadgeInline.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmLabelValueDisplay: () => import('@/components/molecules/SmLabelValueDisplay.vue'),

    PersonalPaymentDetailTable: () => import('@/pages/reserve-funds/payments/payment-detail/detail/PersonalPaymentDetailTable.vue'),
  }
})
export default class PersonalPaymentsDetailSubPage extends Vue {
  @Prop({ required: true })
  buildingUnitId!:string

  private get paymentDetail():ReservePaymentDetailGetResponse | undefined {
    return reservePaymentsModule.paymentDetail(this.buildingUnitId)
  }
}
