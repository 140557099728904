






























































































































































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { REPAIR_PLAN_TYPES } from '@/constants/schema-constants'
import type { AuditState, RepairPlanType } from '@/constants/schema-constants'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { User } from '@/dtos/commons'
import { BuildingDetailGetRequest, BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { BuildingDeleteRequest } from '@/dtos/buildings/delete'
import { getAuditLabel } from '@/libs/state-handler'
import { getRepairPlanTypeLabel } from '@/libs/type-handler'
import { buildingsModule } from '@/stores/buildings-store'
import { staticRoutes } from '@/routes'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class BuildingDetailPage extends Mixins(CurrentAdminManager) {
  @Prop({ required: true })
  private readonly buildingId!: string

  @Prop({ default: '' })
  private readonly routeFromName!: string

  showStopServiceConfirmationDialog = false
  showRegisterRequestDialog = false

  get registerRequestMessage(): string {
    const requestRequestMessageList = ['登録すると管理者業務執行者、フロント担当者、ライフマネージャーが以下のような操作を行えます',
      '・管理者業務執行者：稼働準備・定例業務の実施者を自動で登録できます',
      '・フロント担当者：マンション検索画面と区分所有者検索画面を「自分が担当しているマンションのみ」で絞り込んで表示できます',
      '・ライフマネージャー：担当マンションの閲覧ができます'
    ]
    return requestRequestMessageList.join('\n')
  }

  async created(): Promise<void> {
    if (this.routeFromName === staticRoutes.buildingRegister.name) this.showRegisterRequestDialog = true

    await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
  }

  get building(): BuildingDetailGetResponse | null {
    return buildingsModule.buildingDetailGet
  }

  get serviceStaff(): User | undefined {
    return this.building?.serviceStaff ?? undefined
  }

  get frontStaffs(): User[] | undefined {
    return this.building?.frontStaff ?? []
  }

  get lmStaffs(): User[] | undefined {
    return this.building?.lmStaff ?? []
  }

  get isSimpleRepairPlan(): boolean {
    return this.building?.repairPlanType === REPAIR_PLAN_TYPES.SIMPLE_REPAIR_PLAN
  }

  get isOutOfService(): boolean {
    return this.building?.isOutOfService ?? true
  }

  auditLabel(auditState: AuditState): string { return getAuditLabel(auditState) }

  repairPlanTypeLabel(repairPlanType: RepairPlanType): string { return getRepairPlanTypeLabel(repairPlanType) }

  onClickEdit(): void {
    this.$router.push({ name: staticRoutes.buildingEdit.name, params: { buildingId: this.buildingId } })
  }

  async onClickStopService(): Promise<void> {
    this.showStopServiceConfirmationDialog = false

    await buildingsModule.deleteBuilding(new BuildingDeleteRequest(this.buildingId))

    await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
  }
}
