


















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { PersonalPlansPostRequest } from '@/dtos/reserve-funds/payments/plans/post'
import { PaymentDetail, Price } from '@/dtos/reserve-funds/get'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { getBillingPeriod, getFirstPeriodScale } from '@/libs/reserve-funds-billing-handler'
import { BulkInputProp } from '@/pages/reserve-funds/BulkInputDialog.vue'
import { reservePaymentsModule } from '@/stores/reserve-payments-store'
import { PersonalPlanDetail, PersonalPlansInitialFormGetRequest, PersonalPlansInitialFormGetResponse } from '@/dtos/reserve-funds/payments/plans/initial-form/get-detail'
import { buildingsModule } from '@/stores/buildings-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    BulkInputDialog: () => import('@/pages/reserve-funds/BulkInputDialog.vue'),

    PaymentPlanDetailTable: () => import('@/pages/reserve-funds/plans/PaymentPlanDetailTable.vue'),
    MonthlyAmountTable: () => import('@/pages/reserve-funds/MonthlyAmountTable.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class PersonalPlanEditPage extends Vue {
  @Prop({ required: true }) // from path
  buildingUnitId!: string

  @Prop({ required: true }) period!: string // from query
  private get _period() { return Number(this.period) }

  @Prop({ required: true }) month!: string // from query
  private get _month() { return Number(this.month) }

  async created():Promise<void> {
    const req = new PersonalPlansInitialFormGetRequest()
    req.buildingUnitId = this.buildingUnitId
    req.appliedAt = this.appliedAt
    this.request.appliedAt = this.appliedAt
    await reservePaymentsModule.fetchPersonalPlansInitialForm(req)
    const reservePlanInitialForm = deepCopy(this.res, {
      this: new ColumnToType(PersonalPlansInitialFormGetResponse),
      paymentPlanDetail: new ColumnToType(PersonalPlanDetail),
      paymentDetail: new ColumnToType(PaymentDetail),
    }, 'this')

    // 支払対象の期を抽出
    const targetPersonalPlan = reservePlanInitialForm.paymentPlanDetail.filter(e => e.period >= this._period)
    this.personalPlanDetails = targetPersonalPlan.map(e => {
      const personalPlanDetail = Object.assign(new PersonalPlanDetail(), e)
      personalPlanDetail.unitPrice = personalPlanDetail.minUnitPrice
      return personalPlanDetail
    })
  }

  // パラメータ用の支払いプラン適用開始年月
  private get appliedAt():string {
    const closingMonth = buildingsModule.buildingDetailGet?.closingMonth ?? 0
    let period = this._period
    // buildingsModule.periodToYearMonthで取得する年の調整
    if (this._month > closingMonth) {
      period -= 1
    }
    const year = buildingsModule.periodToYearMonth(period).substring(0, 4)
    const month = ('00' + this._month).slice(-2)
    return `${year}-${month}-01`
  }

  private get res(): PersonalPlansInitialFormGetResponse { return reservePaymentsModule.personalPlansInitialForm }

  // 住戸別支払いプラン用
  personalPlanDetails:PersonalPlanDetail[] = []

  // 月額金額表示用
  private get paymentDetails():PaymentDetail[] {
    const paymentDetails = this.res.paymentDetail.map(paymentDetail => {
      paymentDetail.prices = this.personalPlanDetails.map(personalPlanDetail => {
        const price = new Price()
        price.period = personalPlanDetail.period
        // 1の位で四捨五入
        price.monthlyPrice = this.roundOnesPlace((personalPlanDetail.unitPrice ?? 0) * paymentDetail.occupiedArea)
        return price
      })
      return paymentDetail
    })
    return paymentDetails
  }

  // 1の位で四捨五入
  roundOnesPlace(num:number):number {
    return Math.round(num / 10) * 10
  }

  private get billingPeriod(): number {
    return getBillingPeriod()
  }

  private get firstPeriodScale(): number {
    return getFirstPeriodScale()
  }

  // 最低積立額（各期までの累計）
  private get estimatedCost(): number[] {
    let total = 0
    let estimateIndex = 0
    const allEstimateCost = this.res.paymentPlanDetail.map((plan, index) => {
      // 累積を切りだす箇所の設定
      if (plan.period === this._period) estimateIndex = index
      // 累積計算
      let remainingMonth = 12
      // 請求開始の期は日割り計算
      if (plan.period === this.billingPeriod) remainingMonth = this.firstPeriodScale
      total = total + Math.round(plan.minUnitPrice * this.paymentDetails[0].occupiedArea / 10) * 10 * remainingMonth
      return total
    })
    return allEstimateCost.slice(estimateIndex)
  }

  // 支払いプラン合計額（各期までの累計）
  private get totalPeriodReserves(): number[] {
    let total = this.res.recordedReservedAmount ?? 0
    return this.personalPlanDetails.map(plan => {
      let remainingMonth = 12
      if (plan.period === this._period) {
        remainingMonth = this.diffMonth(this.appliedAt, plan.endDate)
      } else {
        remainingMonth = this.diffMonth(plan.startDate, plan.endDate)
      }
      total = total + Math.round(plan.unitPrice * this.paymentDetails[0].occupiedArea / 10) * 10 * remainingMonth
      return total
    })
  }

  request = new PersonalPlansPostRequest()
  error = ''

  isPostDialogVisible = false
  openPostDialog(): void {
    this.isPostDialogVisible = true
  }

  closePostDialog(): void {
    this.isPostDialogVisible = false
  }

  async onClickExecuteBtn():Promise<void> {
    this.isPostDialogVisible = false
    this.error = ''

    // 積立金額の超過チェック
    let isCompleted = false
    let isCompletedPeriod = 0
    const totalRequiredAmount = this.estimatedCost[this.estimatedCost.length - 1]
    this.personalPlanDetails.some((personalPlanDetail, index) => {
      // 既に必要な積立金を満たした状態で追加で㎡単価を入力した場合エラー
      if (isCompleted && personalPlanDetail.unitPrice > 0) {
        this.error = `必要な積立額を${isCompletedPeriod}期で満たしていますが、${isCompletedPeriod + 1}期以降も㎡単価が入力されています。`
        return true
      }
      const totalPeriodReserves = this.totalPeriodReserves[index]
      if (!isCompleted && totalPeriodReserves >= totalRequiredAmount) {
        // 必要な積立金を満たしたタイミングを記録する
        isCompleted = true
        isCompletedPeriod = personalPlanDetail.period
      }
      return false
    })
    this.closePostDialog()

    // エラーがあったらリクエストをしない
    if (this.error) { return }

    // リクエスト生成
    this.request.paymentPlanDetail = this.personalPlanDetails.map(e => {
      return {
        targetYear: e.targetYear,
        unitPrice: e.unitPrice
      }
    })
    this.request.buildingUnitId = this.buildingUnitId
    this.request.appliedAt = this.appliedAt
    this.request.recordedReservedAmount = this.res.recordedReservedAmount
    await reservePaymentsModule.postPersonalPlans(this.request)
    this.$router.go(-1)
  }

  diffMonth(from:string, to:string):number {
    const fromDate = new Date(from)
    const toDate = new Date(to)

    const fromMonth = fromDate.getFullYear() * 12 + fromDate.getMonth()
    const toMonth = toDate.getFullYear() * 12 + toDate.getMonth()

    return toMonth - fromMonth + 1
  }

  bulkInsertDialogVisible = false

  private get periodItems(): { value: number | null, label: string }[] {
    return this.personalPlanDetails.map(e => {
      return { value: e.period, label: `${e.period}` }
    })
  }

  bulkInput(bulkInputProp:BulkInputProp):void {
    this.personalPlanDetails = this.personalPlanDetails.map(e => {
      if (bulkInputProp.inputValue !== null && bulkInputProp.duration.periodFrom <= e.period && e.period <= bulkInputProp.duration.periodTo) {
        e.unitPrice = bulkInputProp.inputValue
      }
      return { ...e } // 参照渡しすると更新がうまく走らなくなるので、スプレッド構文で値を渡す
    })
    this.bulkInsertDialogVisible = false
  }
}
