import { Route } from 'vue-router'
import { PAGE_TYPES, WIDTH_PATTERNS } from '@/constants/ux-constants'
import type { WidthPattern } from '@/constants/ux-constants'
import { ADMIN_ROLE, IDEA_TYPES, RESOLUTION_TYPES, OWNER_EDIT_PAGE_TYPE, MANAGEMENT_OPERATION_TYPES } from '@/constants/schema-constants'

export const NAVIGATION_GROUP = {
  INDEPENDENT: 0,
  BUILDING: 1,
  OWNER: 2,
  IDEA: 3,
  RESOLUTION: 4,
  REPAIR_PLAN: 5,
  REPAIR_RESERVE_FUND: 6,
  CONSULTATION: 7,
  REPORT: 8,
} as const
export type NavigationGroup = typeof NAVIGATION_GROUP[keyof typeof NAVIGATION_GROUP]

export class RouteMetaData {
  needLogin = true

  /** 分類に基づいてナビゲーションのカレント表示が制御される */
  navigationGroup: NavigationGroup = NAVIGATION_GROUP.INDEPENDENT
  hideSystemBar = false
  hideNavigation = false
  widthPattern:WidthPattern | undefined = undefined
  moveIn = false
  newTab = false
  hideSystemBarBtns = false
  isPreview = false

  constructor(partial?: Partial<RouteMetaData>) {
    Object.assign(this, partial)
  }
}

export class StaticRoute {
  public readonly name: string
  public readonly path: string
  public readonly meta?: RouteMetaData
  public readonly alias?: string
  public readonly redirect?: string
  public readonly props?: Record<string, unknown> | ((route: Route) => unknown)
  public readonly children?: Record<string, StaticRoute>

  getChild(childName: string): StaticRoute {
    // 開発時にエラー原因に気づきやすいようにするためのもの。単体テスト以降で通過想定なし
    if (!this.children) throw new Error(`no routes '${childName}' under '${this.name}'`)

    return this.children[childName]
  }

  constructor(
    name: string, path: string, meta?: Partial<RouteMetaData>, props?: Record<string, unknown> | ((route: Route) => unknown),
    alias?: string, redirect?: string, children?: Record<string, StaticRoute>
  ) {
    this.name = name
    this.path = path
    this.meta = new RouteMetaData(meta)
    this.alias = alias
    this.redirect = redirect
    this.props = props

    if (!children) return
    else if (Object.values(children).some(child => child.path.startsWith('/'))) {
      // 子ルートのパスは / 無しでないと、親のパス配下に配置されずルート直下に配置されてしまうのでエラーにして気付かせる
      throw new Error('child route path should not starts with /')
    }

    this.children = children
  }
}

export const staticRoutes = {
  // ランディング
  landing: new StaticRoute('Landing', '/', { needLogin: false, hideSystemBar: true, hideNavigation: true }, route => ({ ...route.params, transitionParams: route.query })),

  // リンクからのログイン
  externalLogin: new StaticRoute('ExternalLogin', '/external-login', { needLogin: false, hideSystemBar: true, hideNavigation: true }, route => ({ ...route.params, transitionParams: route.query })),

  // 認証
  loginCompletion: new StaticRoute('LoginCompletion', '/login/completion', { needLogin: false, hideSystemBar: true, hideNavigation: true }),

  // 別タブで開く
  newTabTransition: new StaticRoute('NewTab', '/tab/:id', { hideNavigation: true, hideSystemBarBtns: true, newTab: true }),

  // アカウント設定
  account: new StaticRoute('Account', '/account', { hideNavigation: true }),

  // ダッシュボード
  dashboard: new StaticRoute('Dashboard', '/dashboard', { hideNavigation: true }, undefined, undefined, '/dashboard/all', {
    all: new StaticRoute('Dashboard_All', 'all', { hideNavigation: true }),
    personal: new StaticRoute('Dashboard_Personal', 'personal', { hideNavigation: true }),
  } as const),

  // チケット
  ticketsList: new StaticRoute('TicketsList', '/tickets', { hideNavigation: true }),
  ticketCreate: new StaticRoute('TicketCreate', '/ticket/register', { hideNavigation: true }),
  ticketDetail: new StaticRoute('TicketDetail', '/ticket/:ticketId', { hideNavigation: true }, undefined, undefined, '/ticket/:ticketId/detail', {
    detail: new StaticRoute('Ticket_Detail', 'detail', { hideNavigation: true }),
    tasks: new StaticRoute('Ticket_Tasks', 'tasks', { hideNavigation: true }),
    comments: new StaticRoute('Ticket_Comments', 'comments', { hideNavigation: true }),
  } as const),

  // タスク
  tasks: new StaticRoute('Tasks', '/tasks', { hideNavigation: true }, undefined, undefined, '/tasks/list', {
    list: new StaticRoute('Tasks_List', 'list', { hideNavigation: true }),
    onboarding: new StaticRoute('Tasks_Onboarding', 'onboarding', { hideNavigation: true }, route => ({ ...route.params, operationType: MANAGEMENT_OPERATION_TYPES.ONBOARDING })),
    routine: new StaticRoute('Tasks_Routine', 'routine', { hideNavigation: true }, route => ({ ...route.params, operationType: MANAGEMENT_OPERATION_TYPES.ROUTINE })),
  } as const),

  // アイデア・プラン
  ideasList: new StaticRoute('IdeasList', '/ideas', { navigationGroup: NAVIGATION_GROUP.IDEA }),

  // アイデア
  ownerIdeaRegister: new StaticRoute('ownerIdeaRegister', `/ideas/${IDEA_TYPES.OWNER_IDEA}/register`, { navigationGroup: NAVIGATION_GROUP.IDEA, moveIn: true }, route => ({ ...route.query })),
  ownerIdeaPreview: new StaticRoute('ownerIdeaPreview', `/ideas/${IDEA_TYPES.OWNER_IDEA}/preview/:id`, { hideNavigation: true, hideSystemBarBtns: true, newTab: true, widthPattern: WIDTH_PATTERNS.OWNER }, route => ({ ...route.params })),
  ownerIdeaDetail: new StaticRoute('OwnerIdeaDetail', `/ideas/${IDEA_TYPES.OWNER_IDEA}/:ideaId`, { navigationGroup: NAVIGATION_GROUP.IDEA }),
  ownerIdeaDetailPreview: new StaticRoute('OwnerIdeaDetailPreview', `/ideas/${IDEA_TYPES.OWNER_IDEA}/:ideaId/preview`, { navigationGroup: NAVIGATION_GROUP.IDEA, newTab: true, hideSystemBarBtns: true, moveIn: true, isPreview: true }, route => ({ ...route.params, ...route.query })),

  // プラン
  adminIdeaCreate: new StaticRoute('adminIdeaCreate', `/ideas/${IDEA_TYPES.ADMIN_IDEA}/register`, { navigationGroup: NAVIGATION_GROUP.IDEA, moveIn: true }, route => ({ pageType: PAGE_TYPES.CREATE, ...route.query })),
  adminIdeaPreview: new StaticRoute('adminIdeaPreview', `/ideas/${IDEA_TYPES.ADMIN_IDEA}/preview/:id`, { hideNavigation: true, hideSystemBarBtns: true, newTab: true, widthPattern: WIDTH_PATTERNS.OWNER }, route => ({ ...route.params })),
  adminIdeaUpdate: new StaticRoute('AdminIdeaUpdate', `/ideas/${IDEA_TYPES.ADMIN_IDEA}/:ideaId/edit`, { navigationGroup: NAVIGATION_GROUP.IDEA, moveIn: true }, route => ({ pageType: PAGE_TYPES.EDIT, ...route.params })),
  adminIdeaDetail: new StaticRoute('AdminIdeaDetail', `/ideas/${IDEA_TYPES.ADMIN_IDEA}/:ideaId`, { navigationGroup: NAVIGATION_GROUP.IDEA }, undefined, undefined, '/ideas/2/:ideaId/detail', {
    detail: new StaticRoute('AdminIdea_Detail', 'detail', { navigationGroup: NAVIGATION_GROUP.IDEA }),
    session: new StaticRoute('AdminIdea_Session', 'session', { navigationGroup: NAVIGATION_GROUP.IDEA }),
    opinions: new StaticRoute('AdminIdea_Opinions', 'opinions', { navigationGroup: NAVIGATION_GROUP.IDEA }),
    updates: new StaticRoute('AdminIdea_Updates', 'updates', { navigationGroup: NAVIGATION_GROUP.IDEA }),
    agreements: new StaticRoute('AdminIdea_Agreements', 'agreements', { navigationGroup: NAVIGATION_GROUP.IDEA }),
  } as const),
  adminIdeaDetailPreview: new StaticRoute('AdminIdeaDetailPreview', `/ideas/${IDEA_TYPES.ADMIN_IDEA}/:ideaId/preview`, { navigationGroup: NAVIGATION_GROUP.IDEA }, undefined, undefined, '/ideas/2/:ideaId/preview/detail', {
    detail: new StaticRoute('AdminIdeaPreview_Detail', 'detail', { navigationGroup: NAVIGATION_GROUP.IDEA, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
    session: new StaticRoute('AdminIdeaPreview_Session', 'session', { navigationGroup: NAVIGATION_GROUP.IDEA, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
    opinions: new StaticRoute('AdminIdeaPreview_Opinions', 'opinions', { navigationGroup: NAVIGATION_GROUP.IDEA, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
    updates: new StaticRoute('AdminIdeaPreview_Updates', 'updates', { navigationGroup: NAVIGATION_GROUP.IDEA, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
    agreements: new StaticRoute('AdminIdeaPreview_Agreements', 'agreements', { navigationGroup: NAVIGATION_GROUP.IDEA, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
  } as const),
  adminIdeaDetailPrint: new StaticRoute('AdminIdeaDetailPrintPage', `/ideas/${IDEA_TYPES.ADMIN_IDEA}/:ideaId/print`, { navigationGroup: NAVIGATION_GROUP.IDEA, widthPattern: WIDTH_PATTERNS.PRINT_A4 }),
  adminIdeaSessionUpdate: new StaticRoute('AdminIdeaSessionUpdate', `/ideas/${IDEA_TYPES.ADMIN_IDEA}/:ideaId/session/edit`, { navigationGroup: NAVIGATION_GROUP.IDEA, moveIn: true }),

  // 決議一覧
  resolutionsList: new StaticRoute('ResolutionsList', '/resolutions', { navigationGroup: NAVIGATION_GROUP.RESOLUTION }),
  // 決議要件
  requirementsList: new StaticRoute('RequirementsList', '/resolutions/requirements', { navigationGroup: NAVIGATION_GROUP.RESOLUTION }),

  // 総会決議
  gmResolutionCreate: new StaticRoute('GMResolutionCreate', `/resolutions/${RESOLUTION_TYPES.GENERAL_MEETING}/register`, { navigationGroup: NAVIGATION_GROUP.RESOLUTION, moveIn: true }, route => ({ pageType: PAGE_TYPES.CREATE, ...route.query })),
  gmResolutionPreview: new StaticRoute('GMResolutionPreview', `/resolutions/${RESOLUTION_TYPES.GENERAL_MEETING}/preview/:gmResolutionPreviewId`, { hideNavigation: true, hideSystemBarBtns: true, newTab: true, widthPattern: WIDTH_PATTERNS.OWNER }, route => ({ ...route.params })),
  gmResolutionUpdate: new StaticRoute('GMResolutionUpdate', `/resolutions/${RESOLUTION_TYPES.GENERAL_MEETING}/:resolutionId/detail/edit`, { navigationGroup: NAVIGATION_GROUP.RESOLUTION, moveIn: true }, route => ({ pageType: PAGE_TYPES.EDIT, ...route.params })),
  gmResolutionDetail: new StaticRoute(
    'GMResolutionDetailPage',
    `/resolutions/${RESOLUTION_TYPES.GENERAL_MEETING}/:resolutionId`,
    { navigationGroup: NAVIGATION_GROUP.RESOLUTION },
    undefined,
    undefined,
    `/resolutions/${RESOLUTION_TYPES.GENERAL_MEETING}/:resolutionId/detail`,
    {
      detail: new StaticRoute('GMDetailSubPage', 'detail', { navigationGroup: NAVIGATION_GROUP.RESOLUTION }),
      questions: new StaticRoute('GMQuestionsSubPage', 'questions', { navigationGroup: NAVIGATION_GROUP.RESOLUTION }),
      statements: new StaticRoute('GMStatementsSubPage', 'statements', { navigationGroup: NAVIGATION_GROUP.RESOLUTION }),
      results: new StaticRoute('GMResultsSubPage', 'results', { navigationGroup: NAVIGATION_GROUP.RESOLUTION }),
    } as const
  ),
  gmResolutionDetailPreview: new StaticRoute(
    'GMResolutionDetailPreviewPage',
    `/resolutions/${RESOLUTION_TYPES.GENERAL_MEETING}/:resolutionId/preview`,
    { navigationGroup: NAVIGATION_GROUP.RESOLUTION },
    undefined,
    undefined,
    `/resolutions/${RESOLUTION_TYPES.GENERAL_MEETING}/:resolutionId/preview/detail`,
    {
      detail: new StaticRoute('GMDetailPreviewSubPage', 'detail', { navigationGroup: NAVIGATION_GROUP.RESOLUTION, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
      questions: new StaticRoute('GMQuestionsPreviewSubPage', 'questions', { navigationGroup: NAVIGATION_GROUP.RESOLUTION, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
      statements: new StaticRoute('GMStatementsPreviewSubPage', 'statements', { navigationGroup: NAVIGATION_GROUP.RESOLUTION, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
      results: new StaticRoute('GMResultsPreviewSubPage', 'results', { navigationGroup: NAVIGATION_GROUP.RESOLUTION, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
    } as const
  ),

  // 総会決議 決議内訳編集
  statementEdit: new StaticRoute('StatementEdit', `/resolutions/${RESOLUTION_TYPES.GENERAL_MEETING}/:resolutionId/statements/edit`, { navigationGroup: NAVIGATION_GROUP.RESOLUTION, moveIn: true }),

  // オンライン決議
  onlineResolutionCreate: new StaticRoute('OnlineResolutionCreate', `/resolutions/${RESOLUTION_TYPES.ONLINE}/register`, { navigationGroup: NAVIGATION_GROUP.RESOLUTION, moveIn: true }, route => ({ pageType: PAGE_TYPES.CREATE, ...route.query })),
  onlineResolutionPreview: new StaticRoute('OnlineResolutionPreview', `/resolutions/${RESOLUTION_TYPES.ONLINE}/preview/:id`, { hideNavigation: true, hideSystemBarBtns: true, newTab: true, widthPattern: WIDTH_PATTERNS.OWNER }, route => ({ ...route.params })),
  onlineResolutionUpdate: new StaticRoute('OnlineResolutionUpdate', `/resolutions/${RESOLUTION_TYPES.ONLINE}/:resolutionId/detail/edit`, { navigationGroup: NAVIGATION_GROUP.RESOLUTION, moveIn: true }, route => ({ pageType: PAGE_TYPES.EDIT, ...route.params })),
  onlineResolutionDetailPrint: new StaticRoute('OnlineResolutionDetailPrintPage', `/resolutions/${RESOLUTION_TYPES.ONLINE}/:resolutionId/print`, { navigationGroup: NAVIGATION_GROUP.RESOLUTION, widthPattern: WIDTH_PATTERNS.PRINT_A4 }),
  onlineResolutionDetail: new StaticRoute(
    'OnlineResolutionDetailPage',
    `/resolutions/${RESOLUTION_TYPES.ONLINE}/:resolutionId`,
    { navigationGroup: NAVIGATION_GROUP.RESOLUTION },
    undefined,
    undefined,
    `/resolutions/${RESOLUTION_TYPES.ONLINE}/:resolutionId/detail`,
    {
      detail: new StaticRoute('OnlineDetailSubPage', 'detail', { navigationGroup: NAVIGATION_GROUP.RESOLUTION }),
      questions: new StaticRoute('OnlineQuestionsSubPage', 'questions', { navigationGroup: NAVIGATION_GROUP.RESOLUTION }),
      results: new StaticRoute('OnlineResultsSubPage', 'results', { navigationGroup: NAVIGATION_GROUP.RESOLUTION }),
    } as const
  ),
  onlineResolutionDetailPreview: new StaticRoute(
    'OnlineResolutionDetailPreviewPage',
    `/resolutions/${RESOLUTION_TYPES.ONLINE}/:resolutionId/preview`,
    { navigationGroup: NAVIGATION_GROUP.RESOLUTION },
    undefined,
    undefined,
    `/resolutions/${RESOLUTION_TYPES.ONLINE}/:resolutionId/preview/detail`,
    {
      detail: new StaticRoute('OnlineDetailPreviewSubPage', 'detail', { navigationGroup: NAVIGATION_GROUP.RESOLUTION, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
      questions: new StaticRoute('OnlineQuestionsPreviewSubPage', 'questions', { navigationGroup: NAVIGATION_GROUP.RESOLUTION, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
      results: new StaticRoute('OnlineResultsPreviewSubPage', 'results', { navigationGroup: NAVIGATION_GROUP.RESOLUTION, newTab: true, hideSystemBarBtns: true, isPreview: true }, route => ({ ...route.params, ...route.query })),
    } as const
  ),

  // アンケート
  questionnaireReportPostPage: new StaticRoute('QuestionnaireReportPostPage', '/questionnaire/report/register', { hideNavigation: true }, route => ({ ...route.query })),
  questionnaireTemplateCreate: new StaticRoute('QuestionnaireTemplateCreate', '/questionnaires/templates/register', { hideNavigation: true }, route => ({ pageType: PAGE_TYPES.CREATE, ...route.params })),
  questionnaireTemplateUpdate: new StaticRoute('QuestionnaireTemplateUpdate', '/questionnaires/templates/:templateId/edit', { hideNavigation: true }, route => ({ pageType: PAGE_TYPES.EDIT, ...route.params })),
  questionnaireCreate: new StaticRoute('QuestionnaireCreate', '/questionnaires/register', { hideNavigation: true }, route => ({ ...route.query })),
  questionnaireUpdate: new StaticRoute('QuestionnaireUpdate', '/questionnaires/:questionnaireId/edit', { hideNavigation: true }, route => ({ ...route.params })),
  questionnairePreviewTop: new StaticRoute('QuestionnairePreviewTop', '/questionnaires/preview/:id', { hideNavigation: true, hideSystemBarBtns: true, newTab: true, widthPattern: WIDTH_PATTERNS.OWNER }, route => ({ ...route.params })),
  questionnairesList: new StaticRoute('QuestionnairesList', '/questionnaires', { hideNavigation: true }),
  questionnaireReportPreviewPage: new StaticRoute('QuestionnaireReportPreviewPage', '/questionnaires/:questionnaireId/preview/report/:questionnaireReportId', { hideNavigation: true, hideSystemBarBtns: true, newTab: true, widthPattern: WIDTH_PATTERNS.OWNER }, route => ({ ...route.params })),

  questionnaireDetail: new StaticRoute('QuestionnaireDetail', '/questionnaires/:questionnaireId', { hideNavigation: true }, undefined, undefined, '/questionnaires/:questionnaireId/detail', {
    detail: new StaticRoute('Questionnaire_Detail', 'detail', { hideNavigation: true }),
    answer: new StaticRoute('Questionnaire_Answer', 'answer', { hideNavigation: true }),
    respondents: new StaticRoute('Questionnaire_Respondents', 'respondents', { hideNavigation: true }),
    report: new StaticRoute('Questionnaire_Report', 'report', { hideNavigation: true }),
  } as const),
  questionnaireDetailPrint: new StaticRoute('QuestionnaireDetailPrintPage', '/questionnaires/:questionnaireId/print', { hideNavigation: true, widthPattern: WIDTH_PATTERNS.PRINT_A4 }),
  questionnaireRespondentDetail: new StaticRoute('QuestionnaireRespondentDetail', '/questionnaires/:questionnaireId/respondents/:userId', { hideNavigation: true }),
  // アンケート報告書印刷
  questionnaireReportPrint: new StaticRoute('QuestionnaireReportPrintPage', '/questionnaire/report/print', { hideNavigation: true, widthPattern: WIDTH_PATTERNS.PRINT_A4 }, route => ({ ...route.query })),

  // 長期修繕計画
  repairPlanDetailSummaryPrint: new StaticRoute('RepairPlanSummaryPrint', '/repair-plan/summary/print', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN, widthPattern: WIDTH_PATTERNS.PRINT_A3 }),
  repairPlanDetailConstructionPrint: new StaticRoute('RepairPlanConstructionPrint', '/repair-plan/construction/print', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN, widthPattern: WIDTH_PATTERNS.PRINT_A3 }),
  repairPlanDetail: new StaticRoute('RepairPlan', '/repair-plan', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }, undefined, undefined, '/repair-plan/summary', {
    summary: new StaticRoute('RepairPlanSummary', 'summary', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }),
    construction: new StaticRoute('RepairPlanConstruction', 'construction', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }),
  } as const),

  repairPlanRegister: new StaticRoute('RepairPlanRegister', '/repair-plan/register', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN, moveIn: true }),
  repairPlanComments: new StaticRoute('RepairPlanComments', '/repair-plan/comments', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }),
  repairPlanCommentsCreate: new StaticRoute('RepairPlanCommentsCreate', '/repair-plan/comments/register', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN, moveIn: true }, { pageType: PAGE_TYPES.CREATE }),
  repairPlanCommentsUpdate: new StaticRoute('RepairPlanCommentsUpdate', '/repair-plan/comments/:commentId', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN, moveIn: true }, route => ({ pageType: PAGE_TYPES.EDIT, ...route.params })),
  repairPlanUpdates: new StaticRoute('RepairPlanUpdates', '/repair-plan/updates', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }),
  repairPlanUpdateDetail: new StaticRoute('RepairPlanUpdateDetail', '/repair-plan/updates/:logId', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }, undefined, undefined, '/repair-plan/updates/:logId/summary', {
    summary: new StaticRoute('RepairPlanUpdateDetailSummary', 'summary', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }),
    construction: new StaticRoute('RepairPlanUpdateDetailConstruction', 'construction', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }),
  } as const),

  // 簡易版長計
  simpleRepairPlanDetailSummaryPrint: new StaticRoute('SimpleRepairPlanSummaryPrint', '/simple-repair-plan/summary/print', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN, widthPattern: WIDTH_PATTERNS.PRINT_A3 }),
  simpleRepairPlanDetailConstructionPrint: new StaticRoute('SimpleRepairPlanConstructionPrint', '/simple-repair-plan/construction/print', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN, widthPattern: WIDTH_PATTERNS.PRINT_A3 }),
  simpleRepairPlanDetail: new StaticRoute('SimpleRepairPlan', '/simple-repair-plan', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }, undefined, undefined, '/simple-repair-plan/summary', {
    summary: new StaticRoute('SimpleRepairPlanSummary', 'summary', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }),
    construction: new StaticRoute('SimpleRepairPlanConstruction', 'construction', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }),
  } as const),

  simpleRepairPlanRegister: new StaticRoute('SimpleRepairPlanRegister', '/simple-repair-plan/register', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN, moveIn: true }),
  simpleRepairPlanComments: new StaticRoute('SimpleRepairPlanComments', '/simple-repair-plan/comments', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN }),
  simpleRepairPlanCommentsCreate: new StaticRoute('SimpleRepairPlanCommentsCreate', '/simple-repair-plan/comments/register', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN, moveIn: true }, { pageType: PAGE_TYPES.CREATE }),
  simpleRepairPlanCommentsUpdate: new StaticRoute('SimpleRepairPlanCommentsUpdate', '/simple-repair-plan/comments/:commentId', { navigationGroup: NAVIGATION_GROUP.REPAIR_PLAN, moveIn: true }, route => ({ pageType: PAGE_TYPES.EDIT, ...route.params })),

  // 修繕積立金
  reserveFunds: new StaticRoute('ReserveFund', '/reserve-funds', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND }, undefined, undefined, '/reserve-funds/detail', {
    detail: new StaticRoute('ReserveFund_Detail', 'detail', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND }),
    plans: new StaticRoute('ReserveFund_Plan', 'plans', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND }),
    payments: new StaticRoute('ReserveFund_Payments', 'payments', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND })
  }as const),
  reserveFundDetailRegister: new StaticRoute('ReserveFund_Detail_Register', '/reserve-funds/detail/register', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND, moveIn: true }, () => { return { isRegister: true } }),
  reserveFundDetailEdit: new StaticRoute('ReserveFund_Detail_Edit', '/reserve-funds/detail/edit', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND, moveIn: true }, () => { return { isRegister: false } }),

  reserveFundsPaymentPlanEdit: new StaticRoute('ReserveFund_Plan_Edit', '/reserves-funds/plans/register', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND, moveIn: true }),
  reserveFundsPaymentPlanDetail: new StaticRoute('ReserveFund_Plan_Detail', '/reserve-funds/plans/:paymentPlanId', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND }),
  reserveFundPaymentsPrint: new StaticRoute('ReserveFund_Detail_Print', '/reserve-funds/payments/print', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND, widthPattern: WIDTH_PATTERNS.PRINT_A4 }, route => ({ ...route.query })),

  reserveFundsPersonalDetail: new StaticRoute('ReserveFund_Personal_Detail', '/reserve-funds/payments/:buildingUnitId', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND }, undefined, undefined, '/reserve-funds/payments/:buildingUnitId/detail', {
    detail: new StaticRoute('ReserveFund_Personal_Detail_ReserveFund', 'detail', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND }),
    plans: new StaticRoute('ReserveFund_Personal_Detail_PaymentPlans', 'personal-plans', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND })
  }),

  reserveFundsPersonalEdit: new StaticRoute('ReserveFund_Personal_Detail_PaymentPlans_Edit', '/reserve-funds/payments/:buildingUnitId/personal-plans/register/', { navigationGroup: NAVIGATION_GROUP.REPAIR_RESERVE_FUND, moveIn: true }, route => ({ ...route.params, ...route.query })),

  // マンション
  buildingsList: new StaticRoute('BuildingsList', '/buildings', { hideNavigation: true }),
  buildingRegister: new StaticRoute('BuildingRegister', '/buildings/register', { hideNavigation: true }),
  buildingDetail: new StaticRoute('BuildingDetail', '/buildings/:buildingId', { navigationGroup: NAVIGATION_GROUP.BUILDING }),
  buildingEdit: new StaticRoute('BuildingEdit', '/buildings/:buildingId/edit', { navigationGroup: NAVIGATION_GROUP.BUILDING, moveIn: true }),

  // 相談
  consultationsList: new StaticRoute('ConsultationsList', '/consultations', { navigationGroup: NAVIGATION_GROUP.CONSULTATION }),
  consultationDetail: new StaticRoute('ConsultationDetail', '/consultations/:userId', { navigationGroup: NAVIGATION_GROUP.CONSULTATION }, route => ({ ...route.params, ...route.query })),

  // お知らせ管理
  ownerNotificationsList: new StaticRoute('OwnerNotificationsList', '/owner-notifications', { hideNavigation: true }),
  ownerNotificationCreate: new StaticRoute('OwnerNotificationCreate', '/owner-notifications/register', { hideNavigation: true }, route => ({ pageType: PAGE_TYPES.CREATE, ...route.query })),
  ownerNotificationUpdate: new StaticRoute('OwnerNotificationUpdate', '/owner-notifications/:ownerNotificationId/edit', { hideNavigation: true }, route => ({ pageType: PAGE_TYPES.EDIT, ...route.params })),
  ownerNotificationDetail: new StaticRoute('OwnerNotificationDetail', '/owner-notifications/:ownerNotificationId', { hideNavigation: true }),
  ownerNotificationPreview: new StaticRoute('OwnerNotificationPreview', '/owner-notifications/preview/:id', { hideNavigation: true, hideSystemBarBtns: true, newTab: true, widthPattern: WIDTH_PATTERNS.OWNER }, route => ({ ...route.params })),

  // CASYS差分
  casysResultsListPage: new StaticRoute('CasysResultsList', '/casys/results', { hideNavigation: true }),
  casysDetectionsListPage: new StaticRoute('CasysDetectionsList', '/casys/detections', { hideNavigation: true }),
  casysDetectionDetailPage: new StaticRoute('CasysDetectionDetail', '/casys/detections/:casysDifferenceDetectionBatchStatusId', { hideNavigation: true }),

  // 区分所有者詳細
  ownerDetail: new StaticRoute('OwnerDetail', '/owners/:unitId', { navigationGroup: NAVIGATION_GROUP.OWNER }, route => ({ ...route.params })),
  ownerEdit: new StaticRoute('OwnerEdit', '/owners/:unitId/edit', { navigationGroup: NAVIGATION_GROUP.OWNER }, route => ({ pageType: OWNER_EDIT_PAGE_TYPE.EDIT, ...route.params })),
  ownerChange: new StaticRoute('OwnerChange', '/owners/:unitId/change', { navigationGroup: NAVIGATION_GROUP.OWNER }, route => ({ pageType: OWNER_EDIT_PAGE_TYPE.CHANGE, ...route.params })),

  // 区分所有者一覧
  ownersList: new StaticRoute('Owners', '/owners', { navigationGroup: NAVIGATION_GROUP.OWNER }),

  // 区分所有者検索
  usersSearch: new StaticRoute('UsersSearch', '/users', { hideNavigation: true }),

  // 通知
  notices: new StaticRoute('Notices', '/notices', { hideNavigation: true }),

  // 社員
  staffsList: new StaticRoute('StaffList', '/staffs', { hideNavigation: true }, undefined, undefined, `/staffs/${ADMIN_ROLE.SERVICE_STAFF}`, {
    serviceStaff: new StaticRoute('ServiceStaffsList', `${ADMIN_ROLE.SERVICE_STAFF}`, { hideNavigation: true }, { pageType: ADMIN_ROLE.SERVICE_STAFF }),
    front: new StaticRoute('FrontsList', `${ADMIN_ROLE.FRONT}`, { hideNavigation: true }, { pageType: ADMIN_ROLE.FRONT }),
    lifeManager: new StaticRoute('LifeManagersList', `${ADMIN_ROLE.LIFE_MANAGER}`, { hideNavigation: true }, { pageType: ADMIN_ROLE.LIFE_MANAGER }),
  } as const),

  serviceStaffRegister: new StaticRoute('ServiceStaffRegister', `/staffs/${ADMIN_ROLE.SERVICE_STAFF}/register`, { hideNavigation: true }, { pageType: ADMIN_ROLE.SERVICE_STAFF }),
  frontRegister: new StaticRoute('FrontRegister', `/staffs/${ADMIN_ROLE.FRONT}/register`, { hideNavigation: true }, { pageType: ADMIN_ROLE.FRONT }),
  lifeManagerRegister: new StaticRoute('LifeManagerRegister', `/staffs/${ADMIN_ROLE.LIFE_MANAGER}/register`, { hideNavigation: true }, { pageType: ADMIN_ROLE.LIFE_MANAGER }),

  serviceStaffDetail: new StaticRoute('ServiceStaffDetail', `/staffs/${ADMIN_ROLE.SERVICE_STAFF}/:adminId`, { hideNavigation: true }, route => ({ pageType: ADMIN_ROLE.SERVICE_STAFF, ...route.params })),
  frontDetail: new StaticRoute('FrontDetail', `/staffs/${ADMIN_ROLE.FRONT}/:adminId`, { hideNavigation: true }, route => ({ pageType: ADMIN_ROLE.FRONT, ...route.params })),
  lifeManagerDetail: new StaticRoute('LifeManagerDetail', `/staffs/${ADMIN_ROLE.LIFE_MANAGER}/:adminId`, { hideNavigation: true }, route => ({ pageType: ADMIN_ROLE.LIFE_MANAGER, ...route.params })),

  serviceStaffEdit: new StaticRoute('ServiceStaffEdit', `/staffs/${ADMIN_ROLE.SERVICE_STAFF}/:adminId/edit`, { hideNavigation: true }, route => ({ pageType: ADMIN_ROLE.SERVICE_STAFF, ...route.params })),
  frontEdit: new StaticRoute('FrontEdit', `/staffs/${ADMIN_ROLE.FRONT}/:adminId/edit`, { hideNavigation: true }, route => ({ pageType: ADMIN_ROLE.FRONT, ...route.params })),
  lifeManagerEdit: new StaticRoute('LifeManagerEdit', `/staffs/${ADMIN_ROLE.LIFE_MANAGER}/:adminId/edit`, { hideNavigation: true }, route => ({ pageType: ADMIN_ROLE.LIFE_MANAGER, ...route.params })),

  // 通報
  reports: new StaticRoute('Reports', '/reports', { navigationGroup: NAVIGATION_GROUP.REPORT }),

  // NotFound
  notFound: new StaticRoute('NotFound', '*', { needLogin: false }),
} as const

export const staticRoutesLocal = {
  helloWorld: new StaticRoute('HelloWorld', '/hello-world', undefined, undefined, '/hello-world/page-1', '/', { // ← ルートパスか、 /hello-world で到達、到達の際、/hello-world/page-1にリダイレクトしてくれる
    page1: new StaticRoute('HelloWorld_Page1', 'page-1', undefined, undefined, ''), // ← /hello-world か、 /hello-world/page-1 で到達
    page2: new StaticRoute('HelloWorld_Page2', 'page-2'),
    page3: new StaticRoute('HelloWorld_Page3', 'page-3'),
    page4: new StaticRoute('HelloWorld_page4', 'page-4'),
    page5: new StaticRoute('HelloWorld_page5', 'page-5'),
    page6: new StaticRoute('HelloWorld_page6', 'page-6'),
  } as const),

  templateUsage: new StaticRoute('TemplateUsage', '/template', undefined, undefined, undefined, 'template/tab1', {
    tab1: new StaticRoute('TemplateTab1', 'tab1'),
    tab2: new StaticRoute('TemplateTab2', 'tab2'),
  } as const),

  draftInterceptor: new StaticRoute('DraftInterceptor', '/draft-interceptor'),
} as const
