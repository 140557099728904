import 'reflect-metadata'
import './assets/style.css'
import '@/plugins/class-component-hooks'

import Vue from 'vue'
import { store } from '@/stores'
import '@/stores/preserve-state'
import router from '@/router'
import App from './App.vue'

import vuetify from './plugins/vuetify'

import ja from 'vee-validate/dist/locale/ja.json'
import { localize, ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, max, digits, email, length, min, min_value as excluded } from 'vee-validate/dist/rules'

import { errorsModule } from './stores/errors'
import { logClient } from './clients/log-client'
import { LogPostRequest } from './dtos/log/post'
import { CSPReportPostRequest } from './dtos/log/csp/post'

Vue.config.productionTip = false

localize('ja', ja)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

const currencyFormatter = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'JPY'
})

Vue.filter('currency', function(value:number) {
  if (!value) { return '¥0' }
  return currencyFormatter.format(value) // -> ¥xx,xxx,xxx
})

const commaFormatter = new Intl.NumberFormat('en')

/**
 * カンマ区切りにする
 * 例：123456789 → 123,456,789
 */
Vue.filter('comma', function(value:number) {
  if (value === undefined) { return '' }
  return commaFormatter.format(Math.round(value))
})
/**
 * 千円単位に変換（100円桁を四捨五入）した後にカンマ区切りにする
 * 例：123456789 → 123,457
 */
Vue.filter('commaByThousand', function(value:number) {
  if (value === undefined || value === null) { return '' }
  return commaFormatter.format(Math.round(value / 1000))
})

extend('required', required)
extend('max', max)
extend('min', min)
// extend('alpha_num', alphaNum) ⇒ 「alpha_num」は日本語を許容するため英数字のみのバリデーションチェックは「alphanumeric」を使用すること
extend('length', length)
extend('digits', digits) // 数値、かつ桁固定専用のルール
extend('email', {
  ...email,
  message: 'メールアドレスの形式が正しくありません。'
})
extend('excluded', excluded)

extend('phoneNumber', {
  validate(value) {
    if (value === '') return true
    return value.match(/^\+?[\d-()]+$/)
  },
  message: '電話番号の形式が正しくありません。'
})

/**
 * 郵便番号（000-0000）
 */
extend('zipCode', {
  validate(value) {
    if (value === '') return true
    return value.match(/^[0-9]{3}[-][0-9]{4}$/)
  },
  message: '郵便番号は000-0000の形で入力してください。'
})

/**
 * 整数以外ならエラーを出す
 */
extend('integer', {
  validate(value) {
    if (value === '') return true
    return Number.isInteger(value)
  },
  message: '{_field_}は整数のみ使用できます'
})

/**
 * 最小値未満ならエラーを出す
 */
extend('min_value', {
  params: ['min'],
  validate(value, params) {
    if (Array.isArray(params)) return false
    if (value === '') return true
    const min = params.min
    return value >= min
  },
  message: '{_field_}は{min}以上でなければなりません'
})

/**
 * target以上
 */
extend('duration_to', {
  params: ['target'],
  validate(value, params) {
    if (Array.isArray(params)) return false
    if (!params.target || !value) return true
    const target = params.target
    return value >= target
  },
  message: '　'

})

/**
 * target以下
 */
extend('duration_from', {
  params: ['target'],
  validate(value, params) {
    if (Array.isArray(params)) return false
    if (!params.target || !value) return true
    const target = params.target
    return value <= target
  },
  message: '期の開始が正しくありません。'
})

/**
 * 修積：積立計画 入力時バリデーション
 */
extend('enough_unit_price', {
  params: ['income', 'reservedAmount', 'estimatedCost'],
  validate(unitPrice, params) {
    if (Array.isArray(params)) return false
    const income = isNaN(parseInt(params.income)) ? 0 : parseInt(params.income)
    const reservedAmount = isNaN(parseInt(params.reservedAmount)) ? 0 : parseInt(params.reservedAmount)
    const estimatedCost = parseInt(params.estimatedCost)

    const reserved = reservedAmount + income
    return estimatedCost <= reserved
  },
  message: '必要な積立額を下回っています。'
})

extend('password', {
  validate(inputValue) {
    const passwordPattern = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[!-~]{8,}$/
    return passwordPattern.test(inputValue)
  },
  message: '大文字・小文字いずれも含む英字と数字の8文字以上の組み合わせで入力してください。'
})

extend('feature-date', {
  validate(inputValue) {
    const nowDate = new Date()
    const nowDay = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0)
    // YYYY-MM-DD形式に直す
    const formattedValue = inputValue.replace(/\([日月火水木金土]\)/, '').replace(/[年月]/g, '-').replace('日', '')
    const inputDate = new Date(formattedValue)

    return inputDate >= nowDay
  },
  message: '今日以降の日付を選択してください'
})

extend('past-date', {
  validate(inputValue) {
    const nowDate = new Date()
    const nowDay = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + 1, 0, 0, 0)
    // YYYY-MM-DD形式に直す
    const formattedValue = inputValue.replace(/\([日月火水木金土]\)/, '').replace(/[年月]/g, '-').replace('日', '')
    const inputDate = new Date(formattedValue)

    return inputDate <= nowDay
  },
  message: '今日以前の日付を選択してください。'
})

/**
 * 過去の日時バリデーション
 */
extend('is_not_past', {
  // date、hour、minutesは入力必須項目
  params: ['date', 'hour', 'minutes'],
  validate(values, params) {
    if (Array.isArray(params)) return false
    // date、hour、minutesが未入力の場合は必須チェックの方でエラーを出す
    if (params.date === 'undefined' || params.hour === 'undefined' || params.minutes === 'undefined') return true
    const currentDate = new Date()
    const selectedDate = new Date(params.date)
    selectedDate.setHours(params.hour)
    selectedDate.setMinutes(params.minutes)
    return currentDate < selectedDate
  },
  message: '{_field_}は現在日時以降を選択してください'
})

extend('is_date_after', {
  params: ['targetDate', 'targetDateName'],
  validate(inputValue, params) {
    if (Array.isArray(params)) return false
    // YYYY-MM-DD形式に直す
    const formattedValue = inputValue.replace(/\([日月火水木金土]\)/, '').replace(/[年月]/g, '-').replace('日', '')
    const inputDate = new Date(formattedValue)
    // targetはYYYY-MM-DD形式で受け取る想定
    return new Date(params.targetDate) <= inputDate
  },
  message: '{targetDateName}以降の日付を選択してください'
})

extend('is_date_before', {
  params: ['targetDate', 'message'],
  validate(inputValue, params) {
    if (Array.isArray(params)) return false
    // YYYY-MM-DD形式に直す
    const formattedValue = inputValue.replace(/\([日月火水木金土]\)/, '').replace(/[年月]/g, '-').replace('日', '')
    const inputDate = new Date(formattedValue)
    // targetはYYYY-MM-DD形式で受け取る想定
    return inputDate <= new Date(params.targetDate)
  },
  message: '{message}'
})

extend('confirmation_password', {
  params: ['targetPassword'],
  validate(inputPassword, params) {
    if (Array.isArray(params)) return false
    return inputPassword === params.targetPassword
  },
  message: 'パスワードが一致しません'
})

/**
 * 予算・発注額・今期支出済額 入力時バリデーション
 * 予算 >= 発注額 + 今期支出済額でなければエラーを出す
 */
extend('is_within_budget', {
  // budget、expenseは入力必須項目
  params: ['budget', 'expense', 'spent'],
  validate(amount, params) {
    if (Array.isArray(params)) return false
    // budget、expenseが未入力の場合は必須チェックの方でエラーを出す
    if (params.budget === 'undefined' || params.expense === 'undefined') return true
    const budget = parseInt(params.budget)
    const expense = parseInt(params.expense)
    const spent = params.spent === 'undefined' ? 0 : parseInt(params.spent)

    return budget >= (expense + spent)
  },
  message: ''
})

/**
 * 過去日付のバリデーション
 */
extend('is_past_date', {
  params: ['targetDate', 'targetDateName'],
  validate(value, params) {
    if (Array.isArray(params)) return false
    if (!params.targetDate) return true
    // YYYY-MM-DD形式に直す。曜日が付いているとiOS/EdgeでDate型に変換できないエラーとなるため、前方10文字を切り出す。
    const afterDate = new Date(params.targetDate.replace(/(\d{4})年(\d{2})月(\d{2})日/, '$1-$2-$3').slice(0, 10))
    const beforeDate = new Date(value.replace(/(\d{4})年(\d{2})月(\d{2})日/, '$1-$2-$3').slice(0, 10))
    return beforeDate < afterDate
  },
  message: '{targetDateName}より前の日付を選択してください。'
})

/**
 * 未来日付のバリデーション
 */
extend('is_future_date', {
  params: ['targetDate', 'targetDateName'],
  validate(inputValue, params) {
    if (Array.isArray(params)) return false
    if (!params.targetDate) return true
    // YYYY-MM-DD形式に直す。曜日が付いているとiOS/EdgeでDate型に変換できないエラーとなるため、前方10文字を切り出す。
    const afterDate = new Date(params.targetDate.replace(/(\d{4})年(\d{2})月(\d{2})日/, '$1-$2-$3').slice(0, 10))
    const beforeDate = new Date(inputValue.replace(/(\d{4})年(\d{2})月(\d{2})日/, '$1-$2-$3').slice(0, 10))
    return afterDate < beforeDate
  },
  message: '{targetDateName}より後の日付を選択してください'
})

// 相方が入力されていれば必須(0を許容しない)
extend('nxor-required', {
  params: ['target'],
  validate(value, params) {
    if (Array.isArray(params)) return false
    const target = (params.target === 'null' ? null : params.target)
    // 自分が入力されていればtrue(相方でエラーを出す)
    if (value) {
      return true
    }
    // 相方が入力されているとfalse
    return !target
  },
  message: '{target}が入力されている場合、{_field_}は必須です'
})

// 相方が入力されていれば必須（0を許容する）
extend('nxor-required-allow-zero', {
  params: ['target'],
  validate(value, params) {
    if (Array.isArray(params)) return false
    const target = (params.target === 'null' ? null : params.target)
    // 自分が入力されていればtrue(相方でエラーを出す)
    if (value || value === 0) return true
    // 相方が入力されているとfalse
    return !target && target !== 0
  },
  message: '{target}が入力されている場合、{_field_}は必須です'
})

extend('min-max', {
  params: ['min', 'max'],
  validate(value, params) {
    if (Array.isArray(params)) return false
    const min = parseInt(params.min)
    const max = parseInt(params.max)
    return value.length >= min && value.length <= max
  },
  message: '{_field_}は{min}~{max}桁にしてください。'
})

/**
 * 英数字のみを許容する
 * vee-validateの標準ルール「alpha_num」では日本語を許容するため英数字のみのバリデーションチェックはこちらを使用すること
 */
extend('alphanumeric', {
  validate(inputValue) {
    const passwordPattern = /^[0-9a-zA-Z]*$/
    return passwordPattern.test(inputValue)
  },
  message: '{_field_}は半角英数字のみで入力してください。'
})

/**
 * 指定した最大桁数より大きいならエラーを出す
 * sm-text-fieldをtype='number'で使用時、巨大な数値が指数表記に変換されてしまうことがある
 * ex. ) 1,000,000,000,000,000,000,000 → 1e+21
 * これによってcounterでのバリデーションから漏れてしまう場合に使用
 * counterとの併用はしない
 */
extend('max_number_length', {
  params: ['max_number_length'],
  validate(value, params) {
    if (Array.isArray(params)) return false
    if (value === '') return true
    const maxLength = params.max_number_length
    return value < (10 ** (maxLength))
  },
  message: '{max_number_length}桁以下で入力してください'
})

const reportError = async(info: string | Record<string, unknown>, stackTrace?: string, err?: Error) => {
  // APIサーバからエラーが返却された場合なら何も処理しない
  if (errorsModule.hasErrors) return
  // navigation guard系のエラーも連携不要
  if (typeof info === 'object' && info._isRouter) return

  try {
    await logClient.postLogReport(new LogPostRequest(router.currentRoute.path, JSON.stringify(info), stackTrace))
  } catch (e) {
    console.error('failed to report error to server, cause: %o, original error: %o', e, err)
  }
}

// 例外処理
Vue.config.errorHandler = async function(err, vm, info) {
  await reportError(info, err.stack, err)
  throw err
}
// ↑の残りのエラーをキャッチ
window.addEventListener('error', async event => { await reportError(event.error) })
window.addEventListener('unhandledrejection', async event => { await reportError(event.reason) })
Vue.config.warnHandler = async function(msg, vm, trace) { console.warn(`${msg}, trace:${trace} `) }

// CSP違反イベントをキャッチ
window.addEventListener('securitypolicyviolation', async event => {
  const req = new CSPReportPostRequest(event)
  await logClient.postCSPReport(req)
})

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
