





































































































import { Vue, Component, Model, Watch } from 'vue-property-decorator'
import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { consultationsModule } from '@/stores/consultations-store'
import { MaterialFormInputDto } from '@/dtos/commons'
import { ConsultationDraftGetResponse } from '@/dtos/consultations/draft/get'
import { ValidationObserver } from 'vee-validate'

export class ConsultationPostFormInputs {
  consultationId?: string
  message = ''
  material?: MaterialFormInputDto | null = null
}
const DEFAULT_TEXTAREA_HEIGHT = 71
const HEADER_HEIGHT = 96
const DRAFT_AREA_HEIGHT = 32
const ERROR_MESSAGE_HEIGHT = 30

// 相談カードが投稿フォームと重ならないようにするための値（下書きなし、だいたい投稿フォームの高さ（112px）- 画面に表示している割合（45%））
const CLOSE_FORM_PADDING_BOTTOM = 62
// 相談カードが投稿フォームと重ならないようにするための値（下書きあり、だいたい投稿フォームの高さ（152px）- 画面に表示している割合（32%））
const CLOSE_FORM_PADDING_BOTTOM_DRAFT = 104

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class ConsultationsPostForm extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
  inputs = new ConsultationPostFormInputs()

  @Model('input')
  private readonly isFormOpened!: boolean

  private get _isFormOpened(): boolean { return this.isFormOpened }
  private set _isFormOpened(newValue: boolean) { this.$emit('input', newValue) }

  private get _consultationDraft():ConsultationDraftGetResponse { return consultationsModule.getConsultationDraft }
  private get isConsultationDraft(): boolean { return this._consultationDraft.consultationId !== undefined }
  private get closeConsultationText(): string {
    if (!this._consultationDraft.message) return ''
    const spritText = this._consultationDraft.message.split(/\r\n|\r|\n/)
    return spritText[0]
  }

  private get getPostFormClass(): string {
    if (this.isFormOpened) return 'sm__input-focus-out'
    else if (this.isConsultationDraft) return 'sm__input-focus-in-for-draft'
    else return 'sm__input-focus-in'
  }

  mounted(): void {
    document.addEventListener('mousemove', this.draggingForm)
    document.addEventListener('mouseup', this.endDraggingForm)
    document.addEventListener('touchmove', this.draggingForm)
    document.addEventListener('touchend', this.endDraggingForm)
    window.addEventListener('resize', this.handleResize)

    this.$nextTick(function() {
      setTimeout(() => { // 画面に画像・動画の表示が完了するのを待つため、0.5秒後に処理を動かす
        this.setFormPaddingBottom()
      }, 500)
    })
  }

  beforeDestroy(): void {
    document.removeEventListener('mousemove', this.draggingForm)
    document.removeEventListener('mouseup', this.endDraggingForm)
    document.removeEventListener('touchmove', this.draggingForm)
    document.removeEventListener('touchend', this.endDraggingForm)
    window.removeEventListener('resize', this.handleResize)
  }

  @Watch('isFormOpened')
  onFormStateChange(isFormOpened: boolean): void {
    this.inputs = new ConsultationPostFormInputs()
    if (!isFormOpened) this.textHeight = DEFAULT_TEXTAREA_HEIGHT // 投稿フォームを閉じた際にデフォルトの高さに戻す
    else {
      if (this.isConsultationDraft) Object.assign(this.inputs, this._consultationDraft) // 投稿フォームを開いた際、下書きがあれば入力項目に代入する
      if (this.isInitialFormHeight) {
        this.$nextTick(function() {
          setTimeout(() => { // 画面に画像・動画の表示が完了するのを待つため、0.5秒後に処理を動かす
            this.initialFormHeight = this.getPostFormHeight() // 初期の投稿フォームの高さを保持
            this.materialHeight = this.getMaterialInputHeight() // 初期の添付コンポーネントの高さを保持
            this.isDraftInitialDisplay = this.isConsultationDraft
            this.isInitialFormHeight = false
            this.setFormPaddingBottom() // 幅が調整された後に、一覧最後尾の空白を調整する
          }, 500)
        })
      }
    }
  }

  @Watch('inputs.message', { deep: true })
  async changeMessage(): Promise<void> {
    const inValid = await this.getIsInvalid()
    this.isInValid = inValid
    if (inValid) {
      this.$nextTick(function() {
        setTimeout(() => { // 画面に画像・動画の表示が完了するのを待つため、0.5秒後に処理を動かす
          this.handleResize() // 画像添付でテキストエリアが広げられる最大より大きくなっている場合に調整する
        }, 500)
      })
    }
  }

  @Watch('inputs.material', { deep: true })
  onInputMaterial(): void {
    // 画像添付変更時に基準となる初期の投稿フォームの大きさを更新する
    this.$nextTick(function() {
      setTimeout(() => { // 画面に画像・動画の表示が完了するのを待つため、0.5秒後に処理を動かす
        const currentMaterialHeight = this.getMaterialInputHeight() // 添付コンポーネントの高さを取得
        const materialHeightDifference = currentMaterialHeight - this.materialHeight // 変更前との差分
        this.initialFormHeight = this.initialFormHeight + materialHeightDifference // 初期の投稿フォームの高さを更新
        this.materialHeight = currentMaterialHeight // 添付コンポーネントの高さを更新
        this.handleResize() // 画像添付でテキストエリアが広げられる最大より大きくなっている場合に調整する
        this.setFormPaddingBottom() // 幅が調整された後に、一覧最後尾の空白を調整する
      }, 500)
    })
  }

  @Watch('isConsultationDraft')
  changeDraft(): void {
    this.$nextTick(function() {
      setTimeout(() => { // 画面に画像・動画の表示が完了するのを待つため、0.5秒後に処理を動かす
        this.handleResize() // 画像添付でテキストエリアが広げられる最大より大きくなっている場合に調整する
        this.setFormPaddingBottom() // 幅が調整された後に、一覧最後尾の空白を調整する
      }, 500)
    })
  }

  openForm(): void {
    this._isFormOpened = true
  }

  closeForm(): void {
    this._isFormOpened = false
    this.$nextTick(function() {
      setTimeout(() => { // 画面に画像・動画の表示が完了するのを待つため、0.5秒後に処理を動かす
        this.setFormPaddingBottom() // 幅が調整された後に、一覧最後尾の空白を調整する
      }, 500)
    })
  }

  deleteDraft(): void {
    this.$emit('delete-draft', this._consultationDraft.consultationId)
  }

  saveDraft(): void {
    // 初期表示で下書きがない状態で、保存→保存した場合のため、下書きがある場合、相談IDを詰めなおす
    if (this.isConsultationDraft) this.inputs.consultationId = this._consultationDraft.consultationId
    this.$emit('save-draft', this.inputs)
  }

  passFormInputs(): void {
    // 初期表示で下書きがない状態で、保存→投稿した場合のため、下書きがある場合、相談IDを詰めなおす
    if (this.isConsultationDraft) this.inputs.consultationId = this._consultationDraft.consultationId
    this.$emit('post', this.inputs)
  }

  // 投稿フォームの高さ調整用
  readonly POST_FORM_REF = 'postForm'
  readonly MATERIAL_INPUT_REF = 'materialInput'
  readonly POST_FORM_OBSERVER_REF = 'observer'
  isInitialFormHeight = true // 初期の投稿フォームの高さを取得したかのフラグ
  isDraftInitialDisplay = false // 初期表示で下書きがあるかのフラグ（下書きによる幅の調整用）
  initialFormHeight = 0 // 初期の投稿フォームの高さ（投稿フォームを広げた際更新される）
  materialHeight = 0 // マテリアルインプットの高さ
  isMoveDragging = false // ドラッグ中か判定するフラグ
  moveDraggingHeight = 0 // ドラッグして移動させた高さ
  textHeight = DEFAULT_TEXTAREA_HEIGHT // テキストエリアの高さ
  startPageY = 0 // ドラッグ開始直後のマウスのY座標
  startTextareaHeight = DEFAULT_TEXTAREA_HEIGHT // ドラッグ開始直後のテキストエリアの高さ
  formPaddingBottom = CLOSE_FORM_PADDING_BOTTOM // 一覧の下部にある投稿フォーム分の余白
  isInValid = true

  // ドラッグ中の幅の変更を取得するため、computedで再度テキストエリアの高さを返す
  private get _textHeight(): number {
    return this.textHeight
  }

  // 描画タイミングがドラッグ等の最後になる都合上、computedで高さを返す
  private get _formPaddingBottom(): number {
    return this.formPaddingBottom
  }

  // ドラッグ開始の処理
  startDraggingForm(event: MouseEvent|TouchEvent):void {
    this.startPageY = (event instanceof MouseEvent) ? event.pageY : event.touches[0].pageY
    this.startTextareaHeight = this.textHeight
    this.isMoveDragging = true
  }

  // ドラッグ終了の処理
  endDraggingForm():void {
    this.isMoveDragging = false
    this.setFormPaddingBottom()
  }

  // ドラッグ中の高さの更新処理
  draggingForm(event: MouseEvent|TouchEvent):void {
    if (this._isFormOpened && this.isMoveDragging) {
      const maxHeight = this.getSpreadableMaxHeight()
      const eventPageY = (event instanceof MouseEvent) ? event.pageY : event.touches[0].pageY
      const moveDraggingHeight = this.startPageY - eventPageY // ドラッグ直後の位置から動かした高さを取得
      const textHeight = this.startTextareaHeight + moveDraggingHeight

      if (textHeight >= maxHeight) {
        this.textHeight = maxHeight
      } else if (textHeight <= DEFAULT_TEXTAREA_HEIGHT) {
        this.textHeight = DEFAULT_TEXTAREA_HEIGHT
      } else {
        this.textHeight = textHeight
      }
    }
  }

  // 投稿フォームを広げた状態で画面サイズ変更した際の処理
  handleResize(): void {
    const maxHeight = this.getSpreadableMaxHeight()
    if (this.textHeight > maxHeight) {
      this.textHeight = maxHeight
    }
  }

  // 投稿フォーム分のカードのボトムマージンの調整
  setFormPaddingBottom(): void {
    if (!this._isFormOpened) {
      if (this.isConsultationDraft) this.formPaddingBottom = CLOSE_FORM_PADDING_BOTTOM_DRAFT
      else this.formPaddingBottom = CLOSE_FORM_PADDING_BOTTOM
    } else {
      // 画面操作に関係なく必要なパディング
      let paddingBottom = this.initialFormHeight + this._textHeight - DEFAULT_TEXTAREA_HEIGHT
      // エラー発生時の高さの調整
      if (!this.isInValid) paddingBottom += ERROR_MESSAGE_HEIGHT
      // 下書きの高さの調整
      if (!this.isDraftInitialDisplay && this.isConsultationDraft) paddingBottom -= DRAFT_AREA_HEIGHT
      else if (this.isDraftInitialDisplay && !this.isConsultationDraft) paddingBottom += DRAFT_AREA_HEIGHT

      this.formPaddingBottom = paddingBottom
    }
  }

  // 投稿フォームを広げられる最大の高さを返却
  // 元のテキストエリアの高さ + ドラッグして広げられる幅（画面表示 - ヘッダー - 投稿フォームの幅） - ヘッダーとのマージン（16px）
  // 上記に加えて、下書きが開いてから保存して増えた場合、下書きエリアの高さを追加する（開いている間に下書きエリアが減ることはない）
  getSpreadableMaxHeight(): number {
    const displayHeight = document.documentElement.clientHeight // 画面表示（スクロール除く）の高さ

    // 画面操作に関係なく操作できる高さ
    let maxHeight = displayHeight - HEADER_HEIGHT - this.initialFormHeight + DEFAULT_TEXTAREA_HEIGHT
    // エラー発生時の高さの調整
    if (!this.isInValid) maxHeight -= ERROR_MESSAGE_HEIGHT
    // 下書きの高さの調整
    if (!this.isDraftInitialDisplay && this.isConsultationDraft) maxHeight -= DRAFT_AREA_HEIGHT
    else if (this.isDraftInitialDisplay && !this.isConsultationDraft) maxHeight += DRAFT_AREA_HEIGHT
    return maxHeight > DEFAULT_TEXTAREA_HEIGHT ? maxHeight : DEFAULT_TEXTAREA_HEIGHT
  }

  // 投稿フォームの高さを取得
  getPostFormHeight(): number {
    const postFormRef = this.$refs[this.POST_FORM_REF] as HTMLImageElement
    return postFormRef.clientHeight
  }

  // 添付コンポーネントの高さを取得
  getMaterialInputHeight(): number {
    const materialInputRef = this.$refs[this.MATERIAL_INPUT_REF] as HTMLImageElement
    // 添付ファイルエリアのみ下書き表示のため要素がなくなるので、取得できない場合に0を返す
    if (!materialInputRef) return 0
    else return materialInputRef.clientHeight ?? 0
  }

  // エラー状態を取得する
  async getIsInvalid(): Promise<boolean> {
    const observer = this.$refs[this.POST_FORM_OBSERVER_REF] as InstanceType<typeof ValidationObserver>
    let inValid = true
    await observer.validate().then(result => {
      inValid = result
    })
    return inValid
  }
}
