export class ResolutionTicketRelatedIdeaGetRequest {
  ticketId: string

  constructor(ticketId:string) {
    this.ticketId = ticketId
  }
}

export class ResolutionTicketRelatedIdeaGetResponse {
  ideaIds:string[] = []
}
