









import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmTextPrintSizeAnnounce extends Vue {
  @Prop({ required: true })
  private readonly size!: string

  @Prop({ required: true })
  private readonly direction!: string
}
