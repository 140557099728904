









































































































































import { Mixins, Component, Watch } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { IDEA_STATES, IDEA_TYPES } from '@/constants/schema-constants'
import type { IdeaState, IdeaType } from '@/constants/schema-constants'

import { errorsModule } from '@/stores/errors'
import { ideasModule } from '@/stores/ideas-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { IdeasGetRequest, ListResponseIdeaDto } from '@/dtos/ideas/get'
import { Choice } from '@/components/molecules/SmBtnToggle.vue'
import { FilterLabel } from '@/components/molecules/SmExpansionFilterMenu.vue'
import type { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'

const TAKE = 20
const STORAGE_KEY = staticRoutes.ideasList.name

// アイデア新規作成のメニューの内容
const APP_BAR_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  OWNER_IDEA: { text: 'アイデアを代理入力する', label: 'アイデアを代理入力・プランを新規作成する' },
  ADMIN_IDEA: { text: 'プランを新規作成する', label: 'アイデアを代理入力・プランを新規作成する' },
}

// トグルボタンの各ボタンIDの定義
const FILTER_TOGGLE_IDS = {
  DRAFT: 'draft',
  OWNER_POSTED: 'ownerPosted',
  ADMIN_ACCEPTING: 'adminAccepting',
  ADMIN_ACCEPTING_OPINION: 'adminAcceptingOpinion',
  ADMIN_COUNTING: 'adminCounting',
  DELETED: 'deleted',
  ARCHIVE: 'archive',
}
type FilterToggleId = typeof FILTER_TOGGLE_IDS[keyof typeof FILTER_TOGGLE_IDS]

// トグルボタンIDに対するアイデアステータスの定義
const FILTER_TOGGLE_CHOICES_STATE:Record<FilterToggleId, IdeaState[]> = {
  [FILTER_TOGGLE_IDS.DRAFT]: [IDEA_STATES.OWNER.DRAFT, IDEA_STATES.ADMIN.DRAFT],
  [FILTER_TOGGLE_IDS.OWNER_POSTED]: [IDEA_STATES.OWNER.POSTED],
  [FILTER_TOGGLE_IDS.ADMIN_ACCEPTING]: [IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT],
  [FILTER_TOGGLE_IDS.ADMIN_ACCEPTING_OPINION]: [IDEA_STATES.ADMIN.ACCEPTING_OPINION],
  [FILTER_TOGGLE_IDS.ADMIN_COUNTING]: [IDEA_STATES.ADMIN.COUNTING],
  [FILTER_TOGGLE_IDS.DELETED]: [IDEA_STATES.OWNER.DELETED, IDEA_STATES.OWNER.DELETED_SELF],
  [FILTER_TOGGLE_IDS.ARCHIVE]: [IDEA_STATES.OWNER.ADOPTED, IDEA_STATES.OWNER.REJECTED, IDEA_STATES.ADMIN.RELEASED],
}

// 並び替えメニューの内容
const SORT_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  desc: {
    text: '投稿日が新しい順に並び替える',
    label: '投稿日が新しい順'
  },
  asc: {
    text: '投稿日が古い順に並び替える',
    label: '投稿日が古い順'
  }
}

class InputParams {
  selectedChoices: FilterToggleId[] = [FILTER_TOGGLE_IDS.OWNER_POSTED, FILTER_TOGGLE_IDS.ADMIN_ACCEPTING, FILTER_TOGGLE_IDS.ADMIN_ACCEPTING_OPINION, FILTER_TOGGLE_IDS.ADMIN_COUNTING, FILTER_TOGGLE_IDS.ARCHIVE]
  fromDate:string | null = null
  toDate:string | null = null
  keyword = ''
  onlyUnread = false
  sortMenuId = 'desc' // 並べ替えメニューのキー（id）
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmBtnToggle: () => import('@/components/molecules/SmBtnToggle.vue'),
    SmCardIdea: () => import('@/components/molecules/card/SmCardIdea.vue'),
    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmExpansionFilterMenu: () => import('@/components/molecules/SmExpansionFilterMenu.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class IdeasListPage extends Mixins(CurrentAdminManager) {
  async created(): Promise<void> { // FIXME このcreatedはasyncする必要がないはず
    // 初期検索パラメーターの設定（以前の検索条件が残っている場合、それを復元して反映）
    const stored = paramStorageModule.savedParam(STORAGE_KEY, InputParams)
    if (stored) {
      this.inputParams = stored
    } else {
    // 管理者業務執行者の場合、初期選択条件に下書きステータスを加える
      if (this.$isServiceStaff) {
        this.inputParams.selectedChoices.push(FILTER_TOGGLE_IDS.DRAFT)
      }
    }

    this.ideaGetRequestParam = new IdeasGetRequest(TAKE)
    this.ideaGetRequestParam.ideaStates = this.inputParams.selectedChoices.flatMap(s => FILTER_TOGGLE_CHOICES_STATE[s])
    if (this.inputParams.fromDate) this.ideaGetRequestParam.fromDate = this.inputParams.fromDate
    if (this.inputParams.toDate) this.ideaGetRequestParam.toDate = this.inputParams.toDate
    if (this.inputParams.keyword) this.ideaGetRequestParam.keyword = this.inputParams.keyword
    this.ideaGetRequestParam.isUnread = this.inputParams.onlyUnread
    this.ideaGetRequestParam.sortOrder = this.inputParams.sortMenuId

    this.reloadIdeas()
  }

  private get ideas(): ListResponseIdeaDto[] { return ideasModule.ideas }
  private get skipToken(): string | null { return ideasModule.skipToken }

  // --------------- リクエストパラメータと、そのもととなる画面上の入力項目 ---------------
  ideaGetRequestParam: IdeasGetRequest | null = null

  inputParams = new InputParams()

  // --------------- データの読み込み ---------------
  identifier = 1
  isWaitingSwitch = false
  handler: LoadingHandler | null = null

  async loadIdeas(handler: LoadingHandler): Promise<void> {
    this.isWaitingSwitch = true
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }

    // 検索パラメータの初期化が完了していなければ完了扱いにする
    if (!this.ideaGetRequestParam) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    const beforeLength = this.ideas.length
    this.ideaGetRequestParam.skipToken = this.skipToken ?? undefined
    await ideasModule.fetchIdeas(this.toGetRequest())
    paramStorageModule.save({ key: STORAGE_KEY, params: { ...this.inputParams } })

    // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
    if (this.ideas.length === 0) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = false
    handler.loaded()

    const expectingToBe = beforeLength + this.ideaGetRequestParam.take
    if (this.ideas.length < expectingToBe) handler.complete()
  }

  reloadIdeas(): void {
    ideasModule.clearFetchedIdea()
    this.identifier++
  }

  toGetRequest(): IdeasGetRequest {
    const req = new IdeasGetRequest(TAKE)

    req.ideaStates = this.ideaGetRequestParam?.ideaStates ?? undefined
    req.fromDate = this.ideaGetRequestParam?.fromDate ?? undefined
    req.toDate = this.ideaGetRequestParam?.toDate ?? undefined
    req.keyword = this.ideaGetRequestParam?.keyword ?? undefined
    req.isUnread = this.ideaGetRequestParam?.isUnread ?? undefined
    req.sortOrder = this.ideaGetRequestParam?.sortOrder ?? undefined
    req.skipToken = this.ideaGetRequestParam?.skipToken
    return req
  }

  // -------------- 画面描画用のデータ ---------------
  appBarMenuId ='OWNER_IDEA'
  appBarMenuItems = APP_BAR_MENU_ITEMS

  sortMenuItems = SORT_MENU_ITEMS

  isFilterExpansionOpen = true

  // 絞り込みメニューのラベル名（各slotのname属性ごとにラベルを用意する）
  filterLabels = {
    condition1: new FilterLabel('ステータス'),
    condition2: new FilterLabel('投稿日', 'center'),
    condition3: new FilterLabel('キーワード', 'center'),
  }

  private get choices(): Choice[] {
    const choices = [
      new Choice(FILTER_TOGGLE_IDS.OWNER_POSTED, 'アイデア'),
      new Choice(FILTER_TOGGLE_IDS.ADMIN_ACCEPTING_OPINION, 'プラン／意見受付中'),
      new Choice(FILTER_TOGGLE_IDS.ADMIN_ACCEPTING, 'プラン／賛同受付中'),
      new Choice(FILTER_TOGGLE_IDS.ADMIN_COUNTING, 'プラン／賛同数集計中'),
      new Choice(FILTER_TOGGLE_IDS.DELETED, '削除済み'),
      new Choice(FILTER_TOGGLE_IDS.ARCHIVE, 'アーカイブ'),
    ]
    if (this.$isServiceStaff) {
      // 下書きステータスは管理者業務執行者の場合のみ表示、選択可
      choices.unshift(new Choice(FILTER_TOGGLE_IDS.DRAFT, '下書き'))
    }
    return choices
  }

  // 絞り込みを行っているかの判定（0件時のメッセージの判定に利用）
  private get isNoCondition(): boolean {
    if (!this.ideaGetRequestParam) return true

    if (this.inputParams.selectedChoices.length > 0 ||
    this.ideaGetRequestParam.fromDate ||
    this.ideaGetRequestParam.toDate ||
    this.ideaGetRequestParam.keyword ||
    this.ideaGetRequestParam.isUnread) {
      return false
    }
    return true
  }

  // --------------- ボタンなどを押下した際の処理 ---------------
  // 絞り込み処理（各フォームを操作した際のみ検索条件として反映）
  private onChangeSelectedChoices(): void {
    if (!this.ideaGetRequestParam) return
    const ideaState = this.inputParams.selectedChoices.flatMap(s => FILTER_TOGGLE_CHOICES_STATE[s])
    this.ideaGetRequestParam.ideaStates = ideaState
    this.reloadIdeas()
  }

  private onSearchDateFilter(): void {
    if (!this.ideaGetRequestParam) return
    this.ideaGetRequestParam.fromDate = this.inputParams.fromDate === '' ? null : this.inputParams.fromDate
    this.ideaGetRequestParam.toDate = this.inputParams.toDate === '' ? null : this.inputParams.toDate
    this.reloadIdeas()
  }

  private onSearchKeyword(): void {
    if (!this.ideaGetRequestParam) return
    this.ideaGetRequestParam.keyword = this.inputParams.keyword === '' ? null : this.inputParams.keyword
    this.reloadIdeas()
  }

  private onChangeUnreadFilter(): void {
    if (!this.ideaGetRequestParam) return
    this.ideaGetRequestParam.isUnread = this.inputParams.onlyUnread
    this.reloadIdeas()
  }

  private onChangeSortOrder(): void {
    if (!this.ideaGetRequestParam) return
    this.ideaGetRequestParam.sortOrder = this.inputParams.sortMenuId
    this.reloadIdeas()
  }

  onChangeAppBarMenu():void {
    switch (this.appBarMenuId) {
      case 'OWNER_IDEA':
        this.$router.push({ name: staticRoutes.ownerIdeaRegister.name })
        break
      case 'ADMIN_IDEA':
        this.$router.push({ name: staticRoutes.adminIdeaCreate.name })
        break
      default:
        break
    }
  }

  // カードクリック処理
  private onClickCard(ideaType: IdeaType, ideaState: IdeaState, ideaId: string): void {
    switch (ideaType) {
      case IDEA_TYPES.OWNER_IDEA:
        if (ideaState === IDEA_STATES.OWNER.DRAFT) {
          this.$router.push({ name: staticRoutes.ownerIdeaRegister.name, query: { ideaId: ideaId } }); break
        } else {
          this.$router.push({ name: staticRoutes.ownerIdeaDetail.name, params: { ideaId: ideaId } }); break
        }
      case IDEA_TYPES.ADMIN_IDEA:
        if (ideaState === IDEA_STATES.ADMIN.DRAFT) {
          this.$router.push({ name: staticRoutes.adminIdeaCreate.name, query: { ideaId: ideaId } }); break
        } else {
          this.$router.push({ name: staticRoutes.adminIdeaDetail.name, params: { ideaId: ideaId } }); break
        }
    }
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
    this.isWaitingSwitch = false
  }
}
