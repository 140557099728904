








































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Route, NavigationGuardNext } from 'vue-router'
import { staticRoutes } from '@/routes'

import { TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_AFTER_BUILDING_SELECTED, TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_DEFAULT, TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS } from '@/constants/owner-notifications/owner-notification-post-page-constants'
import type { TargetBuildingTypeSelectMenuItemsIds } from '@/constants/owner-notifications/owner-notification-post-page-constants'
import { MATERIAL_TYPES, POST_TIMING_TYPE, QUESTIONNAIRE_QUESTION_ELEMENT_TYPES, QUESTIONNAIRE_QUESTION_TYPES, QUESTIONNAIRE_STATES, SECTION_TYPES } from '@/constants/schema-constants'
import type { PostTimingType, QuestionnaireQuestionType } from '@/constants/schema-constants'
import { DAY_OF_WEEK, PAGE_TYPES_STR } from '@/constants/ux-constants'
import type { PageTypeStr } from '@/constants/ux-constants'

import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { Material, MaterialFormInputDto } from '@/dtos/commons'
import { QuestionnaireQuestionBase, QuestionnaireQuestionForPreview, QuestionnaireQuestionElementBase, QuestionnaireQuestionElementForPreview, QuestionnaireSectionBase, QuestionnaireSectionForPreview, QuestionnaireTargetBuilding } from '@/dtos/questionnaires/commons'
import { QuestionnaireDeleteRequest } from '@/dtos/questionnaires/delete'
import { QuestionnaireDetailGetRequest, QuestionnaireDetailGetResponse, QuestionnaireSection } from '@/dtos/questionnaires/get'
import { QuestionnairePostRequest } from '@/dtos/questionnaires/post'
import { QuestionnairePutRequest } from '@/dtos/questionnaires/put'
import { QuestionnaireTemplateDetailGetRequest, QuestionnaireTemplateDetailGetResponse } from '@/dtos/questionnaires/templates/get-detail'

import { convertIndexIntoSerialChar } from '@/libs/add-alphabet-provider'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'
import { staticKeyProvider } from '@/libs/static-key-provider'
import { generateUuid } from '@/libs/uuid-generator'
import { windowOpen } from '@/libs/window-open'

import { buildingsModule } from '@/stores/buildings-store'
import { newTabLocalParamStorageModule, QuestionnairePreviewContent } from '@/stores/new-tab-local-param-storage-store'
import { questionnairesModule } from '@/stores/questionnaires-store'
import { questionnaireTemplatesModule } from '@/stores/questionnaire-templates-store'

import { RadioOption } from '@/components/atoms/SmRadio.vue'

function sectionFactory(base:QuestionnaireSectionBase, sortOrderNum:number):QuestionnaireSectionBase {
  const section = new QuestionnaireSectionBase()
  section.sectionType = base.sectionType
  section.sectionTitle = base.sectionTitle
  section.sectionExplanation = base.sectionExplanation
  section.sortOrderNum = sortOrderNum
  section.material = base.material
  section.questions = base.questions.map((baseQuestion, questionIndex) => {
    const question = new QuestionnaireQuestionBase()
    question.templateQuestionId = baseQuestion.templateQuestionId
    question.questionType = baseQuestion.questionType
    question.questionSentence = baseQuestion.questionSentence
    question.questionRequired = baseQuestion.questionRequired
    question.sortOrderNum = questionIndex + 1
    question.questionElements = baseQuestion.questionElements
    return question
  })
  return section
}

function questionnaireSectionToFormSection(baseSection: QuestionnaireSection): QuestionnaireSectionBase {
  const section = new QuestionnaireSectionBase()
  section.sectionTitle = baseSection.title
  section.sectionExplanation = baseSection.explanation
  section.sectionType = baseSection.questionnaireSectionType
  section.material = baseSection.material
  section.questions = baseSection.questionnaireQuestions.map(baseQuestion => {
    const question = new QuestionnaireQuestionBase()
    question.templateQuestionId = baseQuestion.templateQuestionId
    question.questionType = baseQuestion.questionnaireQuestionType
    question.questionSentence = baseQuestion.sentence ?? ''
    question.questionRequired = baseQuestion.required
    question.sortOrderNum = baseQuestion.sortOrderNum
    question.questionElements = baseQuestion.questionnaireQuestionElements.map(baseElement => {
      const element = new QuestionnaireQuestionElementBase()
      element.templateQuestionElementId = baseElement.templateQuestionElementId
      element.answerPlaceholder = baseElement.placeholder
      element.choice = baseElement.choice
      element.elementType = baseElement.questionnaireQuestionElementType
      element.sortOrderNum = baseElement.questionnaireQuestionElementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION ? 99 : baseElement.sortOrderNum
      return element
    })
    return question
  })
  return section
}

// YYYY-MM-DD形式で現在の年月日を返す
const currentDate = () => {
  const date = new Date()
  // ISOString形式の日本時間を取得したいが、
  // toISOString()の結果はUTCのため新規・修正に関わらず9時間進める
  date.setHours(date.getHours() + 9)
  return date.toISOString().substr(0, 10)
}

export class FormInputs {
  questionnaireTitle!: string
  questionnaireExplanation!: string
  material?: Material | null = null
  buildings:Building[] = []
  postTimingType:PostTimingType = POST_TIMING_TYPE.IMMEDIATE
  postedAtDate = currentDate()
  postedAtHour = 0
  postedAtMinute = 0
  deadlineDate = currentDate()
  deadlineHour = 0
  deadlineMinute = 0

  get postedAt(): string {
    return `${this.postedAtDate} ${this.postedAtHour}:${this.postedAtMinute}`
  }

  get deadline(): string {
    return `${this.deadlineDate} ${this.deadlineHour}:${this.deadlineMinute}`
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardQuestionnaireSection: () => import('@/components/molecules/card/SmCardQuestionnaireSection.vue'),
    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmDraftInterceptor: () => import('@/components/organisms/SmDraftInterceptor.vue'),

    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')

  }
})
export default class QuestionnairePostPage extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
  TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_DEFAULT = Object.freeze(TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_DEFAULT)
  TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_AFTER_BUILDING_SELECTED = Object.freeze(TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_AFTER_BUILDING_SELECTED)
  POST_TIMING_TYPE = Object.freeze(POST_TIMING_TYPE)
  QUESTIONNAIRE_STATES = Object.freeze(QUESTIONNAIRE_STATES)

  @Prop({ required: true, default: '' })
  private readonly templateId!: string

  @Prop()
  private readonly questionnaireId?: string

  @Prop()
  private readonly pageType?: PageTypeStr

  async created():Promise<void> {
    // アンケートIDが採番されていた場合はアンケート詳細取得
    if (this.questionnaireId) {
      await questionnairesModule.fetchQuestionnaireDetail(new QuestionnaireDetailGetRequest(this.questionnaireId))
    }
    // アンケート詳細が取得できた場合はアンケート詳細ベースでフォームを構成する
    if (this.questionnaireDetail) {
      this.inputs = new FormInputs()
      this.inputs.questionnaireTitle = this.questionnaireDetail.title
      this.inputs.questionnaireExplanation = this.questionnaireDetail.explanation
      this.inputs.material = this.questionnaireDetail.material
      // 下書き登録段階で物件一つにつきアンケート一つが生成される
      this.inputs.buildings = this.storedBuildings.filter(e => e.buildingId === this.questionnaireDetail?.building.buildingId)
      if (this.inputs.buildings.length > 0) this.isTargetBuildingSelected = true
      this.inputs.postTimingType = this.questionnaireDetail.postTimingType
      this.inputs.postedAtDate = this.questionnaireDetail.postedAtDateTime.substring(0, 10)
      this.inputs.postedAtHour = Number(this.questionnaireDetail.postedAtDateTime.substring(11, 13))
      this.inputs.postedAtMinute = Number(this.questionnaireDetail.postedAtDateTime.substring(14, 16))
      this.inputs.deadlineDate = this.questionnaireDetail.deadlineDateTime.substring(0, 10)
      this.inputs.deadlineHour = Number(this.questionnaireDetail.deadlineDateTime.substring(11, 13))
      this.inputs.deadlineMinute = Number(this.questionnaireDetail.deadlineDateTime.substring(14, 16))
      // セクションを分割・フォーマット
      const answerSections = this.questionnaireDetail.questionnaireSections.filter(e => e.questionnaireSectionType === SECTION_TYPES.QUESTION)
      this.answerSections = answerSections.map(e => questionnaireSectionToFormSection(e))
      const opinionSection = this.questionnaireDetail.questionnaireSections.find(e => e.questionnaireSectionType === SECTION_TYPES.OPINION)
      if (opinionSection) {
        this.opinionSection = questionnaireSectionToFormSection(opinionSection)
      }
      // アンケート詳細を取得できなかった場合はテンプレートから取得
    } else {
      await questionnaireTemplatesModule.fetchQuestionnaireTemplateDetail(new QuestionnaireTemplateDetailGetRequest(this.templateId))
      this.inputs = Object.assign(new FormInputs(), this.templateDetail)
      const answerSections = this.templateDetail.sections.filter(e => e.sectionType === SECTION_TYPES.QUESTION)
      this.answerSections = answerSections.map(e => {
        const section = Object.assign(new QuestionnaireSectionBase(), e)
        section.questions = e.templateQuestions
        return section
      })
      const opinionSection = this.templateDetail.sections.find(e => e.sectionType === SECTION_TYPES.OPINION)
      if (opinionSection) {
        this.opinionSection = Object.assign(new QuestionnaireSectionBase(), opinionSection)
        this.opinionSection.questions = opinionSection.templateQuestions
      }
    }
  }

  private formatDate(date: Date): string {
    const y = date.getFullYear()
    const m = ('00' + (date.getMonth() + 1)).slice(-2)
    const d = ('00' + date.getDate()).slice(-2)
    return (y + '-' + m + '-' + d)
  }

  private get isDraft(): boolean { return this.questionnaireDetail?.questionnaireState === QUESTIONNAIRE_STATES.DRAFT }

  private get isScheduledEditableQuestionnaire(): boolean {
    return this.questionnaireDetail?.questionnaireState === QUESTIONNAIRE_STATES.SCHEDULED && this.pageType === PAGE_TYPES_STR.EDIT
  }

  private get deadlineDateFormValidationRules():string[] {
    const rules:string[] = []
    if (this.isScheduledPost) {
      // 投稿日より締切日が過去になっていないか
      rules.push(`is_future_date:${this.inputs.postedAt},投稿日`)
    }
    return rules
  }

  private get isScheduledPost(): boolean { return this.inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED }

  get templateDetail():QuestionnaireTemplateDetailGetResponse {
    return questionnaireTemplatesModule.questionnaireTemplateDetailGet
  }

  get questionnaireDetail():QuestionnaireDetailGetResponse | undefined {
    if (!this.questionnaireId) return
    return questionnairesModule.questionnaireDetail(this.questionnaireId)
  }

  private get isSectionsValidation(): boolean {
    if (this.answerSections.length > 0) {
      return this.answerSections.find(answerSection => !(answerSection.questions.length > 0)) !== undefined
    }
    return !this.opinionSection
  }

  // 日時選択用データ
  private get hours(): { value: number, label: string }[] {
    const hours : { value: number, label: string }[] = []
    for (let i = 0; i <= 23; i++) {
      hours.push({ value: i, label: ('0' + i).slice(-2) }) // 数字を2桁で表示
    }
    return hours
  }

  // --------------フォーム------------
  inputs = new FormInputs()

  // 回答セクション
  answerSections:QuestionnaireSectionBase[] = []

  // 意見セクション
  opinionSection:QuestionnaireSectionBase | null = null

  // 素材IDと取得済み参照用URLを含む素材情報の組み合わせを保持
  materialReferenceURLMap: Map<string, MaterialFormInputDto> = new Map()

  // 投稿タイミング設定用データ／メソッド
  postTimingOptions = [new RadioOption('即時', POST_TIMING_TYPE.IMMEDIATE), new RadioOption('投稿日時指定', POST_TIMING_TYPE.SCHEDULED)]

  // --------------物件選択----------------

  // 対象マンション設定用データ／メソッド
  private get storedBuildings(): Building[] { return buildingsModule.buildingsGet.buildings }

  private isTargetBuildingSelected = false

  private get selectedBuildingLabel(): string | undefined {
    if (this.inputs.buildings.length === 0) return 'すべてのマンション'
    return this.inputs.buildings.map(b => b.buildingName).join(' | ')
  }

  selectedTargetBuildingTypeId: TargetBuildingTypeSelectMenuItemsIds | null = null
  selectedTargetBuildingsByModal:Building[] = []
  buildingKeyword = ''
  inputText = ''

  async onSelectTargetBuildingType(): Promise<void> {
    switch (this.selectedTargetBuildingTypeId) {
      case TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS.ALL:
        this.inputs.buildings = []
        this.selectedTargetBuildingsByModal = []
        break
      case TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS.INDIVIDUALLY_SELECTED:
        await this.openBuildingSelectModal()
        break
    }
    this.isTargetBuildingSelected = true
  }

  isBuildingSelectModalVisible = false
  buildingSelectModalKey = generateUuid()
  async openBuildingSelectModal(): Promise<void> {
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999, undefined, undefined, true))
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingSelectModalVisible = true
  }

  onSelectBuildingsByModal(buildingIds: string[]): void {
    this.inputs.buildings = this.storedBuildings.filter(sb => buildingIds.includes(sb.buildingId))
  }

  // -------------ダイアログメッセージ------------
  isQuestionnaireEdited = false
  private get registerConfirmDialogMessage():string {
    if (this.isQuestionnaireEdited) return '追加・削除を行ったセクションまたは回答は、過去のものと比較できなくなります。アンケートを投稿してもよろしいですか？'
    return 'アンケートを投稿します。よろしいですか？'
  }

  private get updateConfirmDialogMessage():string {
    if (this.isQuestionnaireEdited) return '追加・削除を行ったセクションまたは回答は、過去のものと比較できなくなります。アンケートを更新してもよろしいですか？'
    return 'アンケートを更新します。よろしいですか？'
  }

  // セクション部分で追加・削除が発生した際に変更フラグを立てる
  private onContaminate():void {
    this.isQuestionnaireEdited = true
  }

  // --------------下書き----------------

  isDeleteDraftDialogVisible = false
  async onClickDeleteDraft():Promise<void> {
    this.processCompleted = true
    this.isDeleteDraftDialogVisible = false
    if (!this.isDraft || !this.questionnaireId) return
    await this._deleteDraft()
    this.$router.push({ name: staticRoutes.questionnairesList.name })
  }

  isSaveDraftDialogVisible = false
  async onClickSaveDraft():Promise<void> {
    this.processCompleted = true
    this.isSaveDraftDialogVisible = false
    const rawReq = new QuestionnairePostRequest(this.templateId)
    if (this.isDraft) rawReq.questionnaireId = this.questionnaireId
    rawReq.questionnaireTitle = this.inputs.questionnaireTitle
    rawReq.explanation = this.inputs.questionnaireExplanation
    rawReq.material = this.inputs.material ? this.inputs.material : undefined
    rawReq.postTimingType = this.inputs.postTimingType
    rawReq.postedAt = this.inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED ? this.inputs.postedAt : undefined
    rawReq.deadline = this.inputs.deadline
    rawReq.targetBuildings = this.inputs.buildings.map(e => new QuestionnaireTargetBuilding(e.buildingId))
    // 登録のためにセクションデータをまとめ、表示順を採番
    const sections = [...this.answerSections]
    if (this.opinionSection) sections.push(this.opinionSection)
    rawReq.sections = sections.map((e, i) => sectionFactory(e, i + 1))
    const fileUploader = new FileUploader()
    const req = await fileUploader.prepare(rawReq)
    await questionnairesModule.postQuestionnaireDraft(req)
    this.$router.push({ name: staticRoutes.questionnairesList.name })
  }

  // ------------プレビュー------------
  async goToQuestionnairePreviewPage(): Promise<void> {
    const previewContents = new QuestionnairePreviewContent()
    previewContents.questionnaireTitle = this.inputs.questionnaireTitle
    previewContents.explanation = this.inputs.questionnaireExplanation
    previewContents.deadline = this.formatPreviewDate(new Date(this.inputs.deadlineDate), this.inputs.deadlineHour, this.inputs.deadlineMinute)
    if (this.inputs.material) previewContents.material = await this.getPreUploadFile(Object.assign(new MaterialFormInputDto(), this.inputs.material))
    const previewSections = this.answerSections.map(section => { return section })
    if (this.opinionSection) previewSections.push(this.opinionSection)
    if (previewSections) {
      previewContents.sections = await Promise.all(previewSections.map(async section => {
        const previewSection = new QuestionnaireSectionForPreview()
        previewSection.sectionId = generateUuid()
        previewSection.sectionTitle = section.sectionTitle
        previewSection.sectionExplanation = section.sectionExplanation
        previewSection.sectionType = section.sectionType
        if (section.material) {
          previewSection.material = await this.getPreUploadFile(Object.assign(new MaterialFormInputDto(), section.material))
        }
        if (section.questions) {
          previewSection.questions = section.questions.map(question => {
            const previewQuestion = new QuestionnaireQuestionForPreview()
            previewQuestion.questionId = generateUuid()
            previewQuestion.questionType = question.questionType
            previewQuestion.questionRequired = question.questionRequired
            previewQuestion.questionSentence = question.questionSentence
            previewQuestion.sortOrderNum = question.sortOrderNum
            if (question.questionElements) {
              previewQuestion.questionElements = question.questionElements.map(element => {
                const previewQuestionElement = new QuestionnaireQuestionElementForPreview()
                previewQuestionElement.questionElementId = generateUuid()
                previewQuestionElement.answerPlaceholder = element.answerPlaceholder
                previewQuestionElement.choiceName = element.choice
                previewQuestionElement.elementType = element.elementType
                previewQuestionElement.sortOrderNum = element.sortOrderNum
                return previewQuestionElement
              })
            }
            return previewQuestion
          })
        }
        return previewSection
      })
      )
    }
    const previewContentsId = generateUuid()
    newTabLocalParamStorageModule.setQuestionnairePreviewContent({ key: previewContentsId, questionnairePreviewContent: previewContents })
    windowOpen(staticRoutes.questionnairePreviewTop.path.replace(':id', previewContentsId))
  }

  private formatPreviewDate(date: Date, hour: number, minute: number): string {
    const year = date.getFullYear()
    const month = ('00' + (date.getMonth() + 1)).slice(-2)
    const day = ('00' + date.getDate()).slice(-2)
    const numberOfDate = date.getDay()
    const dayOfWeek = DAY_OF_WEEK[numberOfDate]
    const fixHour = String(hour).length === 1 ? `0${String(hour)}` : String(hour)
    const fixMinute = String(minute).length === 1 ? `0${String(minute)}` : String(minute)
    return `${year}年${month}月${day}日(${dayOfWeek}) ${fixHour}:${fixMinute}`
  }

  async getPreUploadFile(material: MaterialFormInputDto): Promise<MaterialFormInputDto | undefined> {
    if (!material.materialId) { // 新たに添付した素材の場合
      // ローカルストレージのサイズの制約上、プレビュー時に素材をそのまま別タブに渡すのが難しいため、ここでアップロードする
      const uploadedMaterial = await uploadMaterial(material)
      if (uploadedMaterial) {
        this.materialReferenceURLMap.set(uploadedMaterial.materialId, uploadedMaterial)
        // 画面に表示中の素材データにもIDを格納することで投稿／編集時に素材を再登録しなくて済むようにする
        material.materialId = uploadedMaterial.materialId
        return uploadedMaterial
      }
    } else if (this.materialReferenceURLMap.get(material.materialId)) { // 一度プレビューした素材を付け替えずに使用する場合
      return this.materialReferenceURLMap.get(material.materialId)
    } else { // すでにDBに登録済みの素材を画面初期表示から一度も付け替えずに使用する場合
      return material
    }
  }

  // --------------登録---------------
  registerConfirmDialogVisible = false
  updateConfirmationDialogVisible = false
  openQuestionnaireRegisterModal():void { this.registerConfirmDialogVisible = true }
  openQuestionnaireUpdateModal():void { this.updateConfirmationDialogVisible = true }

  async registerQuestionnaire():Promise<void> {
    this.processCompleted = true
    this.registerConfirmDialogVisible = false
    const rawReq = new QuestionnairePostRequest(this.templateId)
    if (this.isDraft) rawReq.questionnaireId = this.questionnaireId
    rawReq.questionnaireTitle = this.inputs.questionnaireTitle
    rawReq.explanation = this.inputs.questionnaireExplanation
    rawReq.material = this.inputs.material ? this.inputs.material : undefined
    rawReq.postTimingType = this.inputs.postTimingType
    rawReq.postedAt = this.inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED ? this.inputs.postedAt : undefined
    rawReq.deadline = this.inputs.deadline
    rawReq.targetBuildings = this.inputs.buildings.map(e => new QuestionnaireTargetBuilding(e.buildingId))
    // 登録のためにセクションデータをまとめ、表示順を採番
    const sections = [...this.answerSections]
    if (this.opinionSection) sections.push(this.opinionSection)
    rawReq.sections = sections.map((e, i) => sectionFactory(e, i + 1))
    const fileUploader = new FileUploader()
    const req = await fileUploader.prepare(rawReq)
    await questionnairesModule.postQuestionnaire(req)
    this.$router.push({ name: staticRoutes.questionnairesList.name })
  }

  async updateQuestionnaire():Promise<void> {
    this.processCompleted = true
    this.updateConfirmationDialogVisible = false
    const rawReq = new QuestionnairePutRequest(this.templateId, this.questionnaireId ?? '')
    rawReq.questionnaireTitle = this.inputs.questionnaireTitle
    rawReq.explanation = this.inputs.questionnaireExplanation
    rawReq.material = this.inputs.material ? this.inputs.material : undefined
    rawReq.postTimingType = this.inputs.postTimingType
    rawReq.postedAt = this.inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED ? this.inputs.postedAt : undefined
    rawReq.deadline = this.inputs.deadline
    rawReq.version = this.questionnaireDetail?.version
    // 登録のためにセクションデータをまとめ、表示順を採番
    const sections = [...this.answerSections]
    if (this.opinionSection) sections.push(this.opinionSection)
    rawReq.sections = sections.map((e, i) => sectionFactory(e, i + 1))
    const fileUploader = new FileUploader()
    const req = await fileUploader.prepare(rawReq)
    await questionnairesModule.putQuestionnaire(req)
    this.$router.go(-1)
  }

  private async _deleteDraft(): Promise<void> {
    if (this.isDraft && this.questionnaireId) await questionnairesModule.deleteQuestionnaires(new QuestionnaireDeleteRequest(this.questionnaireId))
  }

  // -------------------セクション操作----------------

  // 回答セクション名生成
  getSectionName(index: number): string {
    return `回答セクション${convertIndexIntoSerialChar(index)}`
  }

  // 回答セクション追加
  addAnswerSection():void {
    const newSection = new QuestionnaireSectionBase()
    newSection.sectionType = SECTION_TYPES.QUESTION
    newSection.questions.push(this.createAnswerQuestion(QUESTIONNAIRE_QUESTION_TYPES.FREE, 1))
    newSection.questions.push(this.createAnswerQuestion(QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE, 2))
    newSection.questions.push(this.createAnswerQuestion(QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES, 3))
    this.answerSections.push(newSection)
    this.isQuestionnaireEdited = true
  }

  // 回答セクション追加時の設問追加
  createAnswerQuestion(questionType: QuestionnaireQuestionType, sortOrderNum: number): QuestionnaireQuestionBase {
    const question = staticKeyProvider.create(QuestionnaireQuestionBase)
    question.questionSentence = ''
    question.questionRequired = true
    question.questionType = questionType
    question.sortOrderNum = sortOrderNum
    switch (questionType) {
      case QUESTIONNAIRE_QUESTION_TYPES.FREE: {
        const element = staticKeyProvider.create(QuestionnaireQuestionElementBase)
        element.sortOrderNum = 1
        element.answerPlaceholder = ''
        element.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.FREE
        question.questionElements.push(element)
        break
      }
      case QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE: {
        question.questionType = QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE
        question.questionElements.push(this.createAnswerQuestionElement(1))
        question.questionElements.push(this.createAnswerQuestionElement(2))
        break
      }
      case QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES: {
        question.questionType = QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES
        question.questionElements.push(this.createAnswerQuestionElement(1))
        question.questionElements.push(this.createAnswerQuestionElement(2))
      }
    }
    return question
  }

  // 回答セクション追加時の選択式設問構成要素追加
  createAnswerQuestionElement(sortOrderNum: number): QuestionnaireQuestionElementBase {
    const option = new QuestionnaireQuestionElementBase()
    option.choice = ''
    option.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.SELECTABLE_OPTION
    option.sortOrderNum = sortOrderNum
    return option
  }

  // セクション更新
  updateSection(newValue:QuestionnaireSectionBase, index:number):void {
    this.answerSections[index] = Object.assign(staticKeyProvider.create(QuestionnaireSectionBase), newValue)
    this.isQuestionnaireEdited = true
  }

  // セクション削除
  onClickAnswerSectionDelete(index:number):void {
    this.answerSections.splice(index, 1)
    this.isQuestionnaireEdited = true
  }

  // ご意見・ご要望作成
  private createOpinionSection():void {
    this.opinionSection = new QuestionnaireSectionBase()
    this.opinionSection.sectionType = SECTION_TYPES.OPINION
    const opinionQuestion = new QuestionnaireQuestionBase()
    opinionQuestion.questionRequired = true
    opinionQuestion.questionType = QUESTIONNAIRE_QUESTION_TYPES.FREE
    opinionQuestion.questionSentence = ''
    opinionQuestion.sortOrderNum = 99 // 末尾
    const questionElement = staticKeyProvider.create(QuestionnaireQuestionElementBase)
    questionElement.sortOrderNum = 1
    questionElement.answerPlaceholder = ''
    questionElement.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.FREE
    opinionQuestion.questionElements.push(questionElement)
    this.opinionSection.questions.push(opinionQuestion)
    this.isQuestionnaireEdited = true
  }

  // ご意見・ご要望削除
  onClickOpinionSectionDelete():void {
    this.opinionSection = null
    this.isQuestionnaireEdited = true
  }

  // 作成内容破棄確認ダイアログ
  nextRoute: Route | null = null
  processCompleted = false
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (this.processCompleted || this.nextRoute) {
      next()
    } else {
      this.nextRoute = to
      next(false)
    }
  }

  leaveHere(): void {
    const routeName = this.nextRoute?.name
    const routeParams = this.nextRoute?.params
    // 画面遷移元が以下のリスト内だった場合、ヒストリーを戻す
    const goBackRouteNames = [
      staticRoutes.questionnaireDetail.getChild('detail').name
    ]
    const goTwiceBackRouteNames = [
      staticRoutes.questionnaireTemplateCreate.name
    ]
    if (routeName) {
      if (goBackRouteNames.includes(routeName)) this.$router.go(-1)
      else if (goTwiceBackRouteNames.includes(routeName)) this.$router.go(-2)
      else this.$router.push({ name: routeName, params: routeParams })
    }
  }
}
