





















































































































































import { Vue, Component, Prop, Model } from 'vue-property-decorator'

/**
 * 標準のDataTableHeaderから、利用するプロパティのみを抜粋
 */
export class Header {
  /**
   * ヘッダに表示するラベル（例："マンション名"）
   */
  text!: string
  /**
   * 列に振られる変数名（例：ideaId）
   */
  value!: string

  /**
   * データの並び替えを可能とするかどうか
   * デフォルトは全列並び替え可能で、並び替え不可にしたい列のみfalseを設定する
   */
  sortable?: boolean

  /**
   * 各列をキーワード検索対象に含めるかどうか
   * デフォルトは全列キーワード検索対象なので、含めたくない列にのみ falseを設定
   */
  filterable?: boolean

  /**
   * セル・カラムの幅
   * 全カラムに指定すると、テーブルが幅全体を埋めようとして正しく挙動しなくなるため注意
   */
  width?:string

  /**
   * 該当列のセル内における表示位置を指定する
   * 列ごと非表示にする場合は' d-none'を指定する
   */
  align?: string

  /**
   * ヘッダークラス
   * 複数指定する場合は配列で指定する
   */
  class:string | string[] = 'sm__data-header'

  /**
   * セルクラス
   * 複数指定する場合は配列で指定する
   */
  cellClass:string | string[] = 'sm__data'

  constructor(init:Partial<Header>) {
    Object.assign(this, init)
  }
}

/**
 * ソートメソッドカスタム設定用オブジェクトインターフェイス
 * custom-sortには、
 * {
 *    (ソート対象列の物理名):CustomSortInterfaceオブジェクト,
 *    (ソート対象列の物理名2):CustomSortInterfaceオブジェクト,
 *    ...
 * }
 * と指定する。
 */
export interface CustomSortInterface {
  /**
   * ソートcompareメソッド
   * （compareメソッドなので、詳細仕様は以下のサイト参照）
   * https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
   * 型：親側で指定した配列の型を指定する。
   * 昇順（aがbより小さいときに正の数を返す）で記述する
   */
  sortMethod<T>(a:T, b:T): number

}

/**
 * TableItem用のインターフェース
 * itemsに渡すオブジェクトに設定する
 */
export interface TableItemInterface {
  /**
   * 行（Item）を非表示にするフラグ（true：非表示）
   * キーワード検索（search）とは別に表示の絞り込みをしたい場合に使用する
   */
  hideItem: boolean

  /**
   * チェックボックスが選択可能かを判定するフラグ（true：選択不可能）
   * customCheckboxをtrueにした場合のみ使用可能
   */
  checkboxDisabled: boolean
}

type TableItem = Record<string, string | number>

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue')
  }
})
export default class SmTableData extends Vue {
  @Prop({ required: true, default: () => [] })
  private readonly headers!: Header[]

  @Prop({ required: true, default: () => [] })
  private readonly items!: TableItem[]

  private get _items(): TableItem[] {
    // itemにhideItem=trueが設定されているものを除外して表示対象とする
    return this.items.filter(item => !item.hideItem)
  }

  @Prop({ required: true, default: '' })
  private readonly itemKey!: string

  @Prop({ default: false })
  private readonly showSelect?: boolean

  // TODO singleSelectの見た目をFigmaにあわせてradioボタン化する
  @Prop({ default: false })
  private readonly singleSelect?: boolean

  @Prop({ default: false })
  private readonly hasLimit!: boolean

  /**
   * 選択されたのうち、itemKeyのみを保持
   */
  @Model('change')
  private readonly selected?: (string | number)[] | undefined

  /**
   * 選択されたデータをv-data-tableとやりとりする
   * v-data-tableはTableItem単位でデータを操作するので、翻訳が必要
   */
  private get _selected(): TableItem[] | undefined {
    return this.items.filter(e => this.selected?.includes(e[this.itemKey]))
  }

  private set _selected(newValue: TableItem[] | undefined) {
    this.$emit('change', newValue?.map(e => e[this.itemKey]))
  }

  /**
   * ページングする場合、一ページに表示するデータ件数（指定しないとページング無しとなる）
   */
  @Prop()
  private readonly itemsPerPage?: number

  private pageCount?: number = 0
  private page?: number = 1

  /**
   * 画面に表示されている項目に対して検索をかけたい場合、keywordに文字列を入れるとコンポーネント側で検索してくれる
   * 検索は「大文字・小文字の区別をしない（コンポーネント標準）」
   * 検索に含めたくない項目に対しては「filterable: false」と設定すること
   * 画面非表示の項目（userIdなど）で検索したい場合、コンポーネントの検索機能は使えない
   * コンポーネントを使う側でフィルタリング機能を作成し、
   * filterしたデータをitemsとしてコンポーネントに渡すこと
   */
  @Prop()
  private readonly keyword?: string

  @Prop({ default: false })
  private readonly disableRowClick!:boolean

  @Prop()
  private readonly contentName?:string

  @Prop({ default: () => { return {} } })
  private readonly customSorts!:Record<string, CustomSortInterface>

  @Prop({ default: false })
  private readonly hideDefaultHeader!: boolean

  @Prop({ default: false })
  private readonly customNoData!: boolean

  @Prop({ default: false })
  private readonly customCheckbox!: boolean

  @Prop()
  private readonly actionIcon?: string

  @Prop()
  private readonly bulletPointItem?: string

  // ソート処理

  private get sort(): (items:Record<string, string | number | boolean>[], indexes:string[], isDesc:boolean[]) => Record<string, string | number | boolean>[] {
    return (items:Record<string, string | number | boolean>[], indexes:string[], isDesc:boolean[]) => {
      const index = indexes[0]
      // ソート条件が指定されていなければ何もせず終了（初期表示など）
      if (!index) return items

      const customSort = this.customSorts[index]

      // v-data-table側ではマルチソート想定で配列が渡してくるが、マルチソートは使わないため、最初の項目を取得してしまう
      const isDescending = isDesc[0]
      items.sort((a:Record<string, string | number | boolean>, b:Record<string, string | number | boolean>) => {
        if (customSort) {
          // カスタムソートが設定されていれば適用
          return customSort.sortMethod(a, b) * (isDescending ? -1 : 1)
        } else {
          // デフォルトソート
          const aValue = a[index] ?? ''
          const bValue = b[index] ?? ''

          if (aValue === bValue) return 0
          if (isDescending) {
            return bValue > aValue ? 1 : -1
          }
          return aValue > bValue ? 1 : -1
        }
      })
      return items
    }
  }

  private onClickRow(selectedItem:TableItem):void {
    this._selected = this.changeSelectedItems(selectedItem)
  }

  private changeSelectedItems(selectedItem:TableItem):TableItem[] {
    if (this.disableRowClick || !this.showSelect) return this._selected ?? []
    if (this.customCheckbox && selectedItem.checkboxDisabled) return this._selected ?? []
    if (!this._selected || this._selected.length === 0) {
      return [selectedItem]
    }

    if (this.singleSelect) {
      if (this._selected[0][this.itemKey] !== selectedItem[this.itemKey]) {
        return [selectedItem]
      }
      return []
    }

    // 複数選択のパターン
    if (this._selected.some(e => e[this.itemKey] === selectedItem[this.itemKey])) {
      return this._selected.filter(e => e[this.itemKey] !== selectedItem[this.itemKey])
    }
    return this._selected.concat(selectedItem)
  }

  private onClickSelectAll(prop: { items: TableItem[], value: boolean }): void {
    if (!this.customCheckbox) return
    const selectItem = prop.items.filter(i => !i.checkboxDisabled)
    const selectedOtherPageItems = this._selected?.filter(i => !prop.items.includes(i)) ?? []

    if (selectItem.length === ((this._selected?.length ?? 0) - selectedOtherPageItems.length)) { // 全選択を外す場合
      this._selected = this._selected?.filter(i => !prop.items.includes(i))
    } else {
      this._selected = [...selectItem, ...selectedOtherPageItems]
    }
  }

  private get bulletPointItemName(): string {
    return 'item.' + this.bulletPointItem
  }

  // スロットにカスタムコンポーネントのみを設定すると描画されないため、空文字の力を借りる
  emptyDummyString = ''
}
