import { Type } from 'class-transformer'
import { User } from '@/dtos/commons'

export class RepairsUpdatesGetRequest {}

export class Log {
  previous!: string
  current!: string
}

export class RepairsUpdatesGetResponse {
  commentId?: string
  comment?: string
  logId?: string
  @Type(() => Log) logs?: Log[] = []
  @Type(() => User) user?: User
  postedAt?: string
}
