




































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { staticRoutes } from '@/routes'
import { IDEA_STATES } from '@/constants/schema-constants'
import { ERROR_MESSAGES } from '@/constants/ux-constants'

import { adminIdeasModule, AdminIdeaCommonState } from '@/stores/admin-ideas-store'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { myProfileModule } from '@/stores/my-profile-store'
import { structureModule } from '@/stores/structure-store'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { Material, User, Section } from '@/dtos/commons'
import { Details, Result, SessionGetResponseSession, Guide } from '@/dtos/ideas/admin-idea/commons'
import { AdminIdeaSessionGetResponse } from '@/dtos/ideas/admin-idea/session/get'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue')
  }
})
export default class SessionSubPage extends Mixins(CurrentAdminManager) {
  @Prop()
  ideaId!: string

  @Prop()
  buildingId?: string

  async created(): Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    await adminIdeasModule.fetchIdeaSession({ ideaId: this.ideaId })
  }

  private get isPreview(): boolean { return structureModule.isPreview }

  private get ideaCommon(): AdminIdeaCommonState {
    return adminIdeasModule.adminIdeaCommonStateGet ?? new AdminIdeaCommonState()
  }

  private get ideaSession():AdminIdeaSessionGetResponse {
    const res = adminIdeasModule.adminIdeasSessionGet(this.ideaId) ?? new AdminIdeaSessionGetResponse()
    return deepCopy(
      res,
      {
        AdminIdeaSessionGetResponse: new ColumnToType(AdminIdeaSessionGetResponse),
        details: new ColumnToType(Details),
        user: new ColumnToType(User),
        material: new ColumnToType(Material),
        sessions: new ColumnToType(SessionGetResponseSession, true),
        guide: new ColumnToType(Guide),
        result: new ColumnToType(Result),
        sections: new ColumnToType(Section, true),
      },
      'AdminIdeaSessionGetResponse')
  }

  private get isExistSession(): boolean {
    return this.ideaSession.sessions !== undefined && this.ideaSession.sessions.length > 0
  }

  private get isEditable(): boolean {
    return this.$isServiceStaff &&
        (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT || this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION)
  }

  private onClickUpdateSession(): void {
    if (this.isPreview) return
    this.$router.push({ name: staticRoutes.adminIdeaSessionUpdate.name, params: { ideaId: this.ideaId } })
  }
}
