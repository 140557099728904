import { Material } from '@/dtos/commons'

export class AdminIdeaUpdatesPutRequest {
  ideaId!: string
  updateId!: string
  updateBody!: string
  material?: Material
  version!: number
}

export class AdminIdeaUpdatesPutResponse {
  ideaId!: string
  postedAt!: string
  version!: number
}
