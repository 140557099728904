



























































































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { STYLES } from '@/constants/styles'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'

export const SYSTEM_BAR_ACTIONS = {
  DASHBOARD: 'dashboard',
  TICKETS: 'tickets',
  TASKS: 'tasks',
  BUILDINGS: 'buildings',
  QUESTIONNAIRE: 'questionnaire',
  OWNER_NOTIFICATIONS: 'owner-notifications',
  STAFFS: 'staffs',
  USERS: 'users'
} as const
export type SystemBarActions = typeof SYSTEM_BAR_ACTIONS[keyof typeof SYSTEM_BAR_ACTIONS]

export class SystemBarBtn {
  /** 押下イベントとともにemitされるid */
  id: SystemBarActions
  icon: string
  text: string

  constructor(id: SystemBarActions, icon: string, text: string) {
    this.id = id
    this.icon = icon
    this.text = text
  }
}

class SystemBarBtns {
  firstBtn?: SystemBarBtn
  secondBtn?: SystemBarBtn
  thirdBtn?: SystemBarBtn
  fourthBtn?: SystemBarBtn

  constructor(firstBtn?: SystemBarBtn, secondBtn?: SystemBarBtn, thirdBtn?: SystemBarBtn, fourthBtn?: SystemBarBtn) {
    this.firstBtn = firstBtn
    this.secondBtn = secondBtn
    this.thirdBtn = thirdBtn
    this.fourthBtn = fourthBtn
  }
}

@Component({
  components: {
    SmAvatar: () => import('@/components/atoms/SmAvatar.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmLogo: () => import('@/components/atoms/images/SmLogo.vue'),

    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
  }
})
export default class SmSystemBar extends Mixins(CurrentAdminManager) {
  STYLES = Object.freeze(STYLES)

  get systemBarBtns(): SystemBarBtns {
    const dashboardBtn = new SystemBarBtn(SYSTEM_BAR_ACTIONS.DASHBOARD, '$auto_awesome_mosaic_round', 'ダッシュボード')
    const ownerNotificationsBtn = new SystemBarBtn(SYSTEM_BAR_ACTIONS.OWNER_NOTIFICATIONS, '$campaign_outlined', 'お知らせ')
    const staffsBtn = new SystemBarBtn(SYSTEM_BAR_ACTIONS.STAFFS, '$manage_accounts_round', '社員')
    const questionnaireBtn = new SystemBarBtn(SYSTEM_BAR_ACTIONS.QUESTIONNAIRE, '$groups_outlined', 'アンケート')

    const apartmentBtn = new SystemBarBtn(SYSTEM_BAR_ACTIONS.BUILDINGS, '$apartment_outlined', 'マンション検索')
    const usersSearchBtn = new SystemBarBtn(SYSTEM_BAR_ACTIONS.USERS, '$search_outlined', '区分所有者検索')

    if (this.$isServiceStaff) {
      return new SystemBarBtns(dashboardBtn, ownerNotificationsBtn, questionnaireBtn, staffsBtn)
    } else if (this.$isAccountingDept || this.$isFrontPerson || this.$isGroupCallCenter) {
      return new SystemBarBtns(apartmentBtn, usersSearchBtn)
    }
    return new SystemBarBtns()
  }

  @Prop()
  userIconImagePath?: string

  @Prop({ default: true })
  btnsVisible!: boolean

  @Prop({ default: false })
  isPreview!: boolean

  @Prop()
  firstMenuItems?: {[id: string]: { text: string, label: string | undefined }}

  @Prop()
  secondMenuItems?: {[id: string]: { text: string, label: string | undefined }}

  firstMenuId = 'menu1'
  secondMenuId = 'menu1'

  onChangeFirstMenu(id:string):void {
    this.firstMenuId = id
    this.$emit('click-search-first-menu', id)
  }

  onChangeSecondMenu(id:string):void {
    this.secondMenuId = id
    this.$emit('click-search-second-menu', id)
  }

  onClickNoticeIcon():void {
    const refs = this.$refs.vIconRef as Vue
    const el = refs.$el as HTMLButtonElement
    el.blur()
    this.$emit('click-notice-icon')
  }
}

