













































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableDataExternalPaging.vue'
import { managementOperationsModule } from '@/stores/management-operations-store'
import { ManagementOperationsOnboarding, ManagementOperationsOnboardingSearchPostRequest } from '@/dtos/management-operations/onboarding/search/post'
import { OPERATION_STATES } from '@/constants/schema-constants'
import { ManagementOperationsEditItem } from '../pages/tasks/onboardingAndRoutine/SmDialogManagementOperationEdit.vue'

const TAKE = 10

const HEADERS: Header[] = [
  new Header({ text: 'サービス提供開始年月', value: 'smIntroducedDate' }),
  new Header({ text: 'MaNo.', value: 'condominiumCd' }),
  new Header({ text: 'マンション名', value: 'buildingName' }),
  new Header({ text: '担当', value: 'adminShortName' }),
  new Header({ text: '', value: 'labels' }),
]

export class ManagementOperationsTaskTableItem extends ManagementOperationsOnboarding {
  labels : string[] = ['実施者', '目安', 'ステータス', 'メモ']
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTooltip: () => import('@/components/molecules/SmTooltip.vue'),

    SmOwLegend: () => import('@/components/owner-apps/SmOwLegend.vue'),
    SmTableDataExternalPaging: () => import('@/components/molecules/SmTableDataExternalPaging.vue'),

    ManagementOperationTaskStatusArea: () => import('@/components/organisms/ManagementOperationTaskStatusArea.vue'),
    SmDialogManagementOperationEdit: () => import('../pages/tasks/onboardingAndRoutine/SmDialogManagementOperationEdit.vue'),

    ExecutorSelectModal: () => import('@/pages/tasks/onboardingAndRoutine/ExecutorSelectModal.vue'),
  }
})

export default class HelloWorldPage6 extends Vue {
  OPERATION_STATES = Object.freeze(OPERATION_STATES)

  private get managementOperations(): ManagementOperationsOnboarding[] {
    return managementOperationsModule.managementOperationsOnboarding
  }

  get taskTableAddLabelItems(): ManagementOperationsTaskTableItem[] {
    const taskTableItems: ManagementOperationsTaskTableItem[] = []
    const _managementOperations = managementOperationsModule.managementOperationsOnboarding
    _managementOperations.forEach(mo => {
      const taskTableItem = new ManagementOperationsTaskTableItem()
      taskTableItem.smIntroducedDate = mo.smIntroducedDate
      taskTableItem.condominiumCd = mo.condominiumCd
      taskTableItem.buildingId = mo.buildingId
      taskTableItem.buildingName = mo.buildingName
      taskTableItem.adminShortName = mo.adminShortName
      taskTableItem.managementOperationsTaskProgress = mo.managementOperationsTaskProgress
      taskTableItems.push(taskTableItem)
    }

    )
    return taskTableItems
  }

  private detailHeaders:Header[] = []
  private makeDetail():void {
    const headers: Header[] = [...HEADERS]
    this.managementOperations[0].managementOperationsTaskProgress.forEach((tp, i) => headers.push(new Header({ text: `task[${i}]`, value: `managementOperationsTaskProgress[${i}]`, width: '100px', sortable: false })))
    this.detailHeaders = headers
  }

  async created(): Promise<void> {
    await managementOperationsModule.fetchManagementOperationsOnboarding(this.toSearchPostRequest())

    this.makeDetail()
  }

  toSearchPostRequest(): ManagementOperationsOnboardingSearchPostRequest {
    const skip = TAKE
    const req = new ManagementOperationsOnboardingSearchPostRequest(false, false, skip, TAKE)
    return req
  }

  clickCell() :void {
    console.log('clickCell')
  }

  pageNum = 1
  async onClickPageBtn(pageNum: number): Promise<void> {
    this.pageNum = pageNum
  }

  isManagementOperationEditDialog = false
  managementOperationsEditItem = new ManagementOperationsEditItem()
  openManagementOperationEditDialog(): void {
    this.managementOperationsEditItem.adminId = '39278947-5ff0-8392-73a4-53c56a0869b0'
    this.managementOperationsEditItem.targetDate = '2024-05'
    this.managementOperationsEditItem.operationState = OPERATION_STATES.UNRESOLVED
    // this.managementOperationsEditItem.completedAt = '2024-05-05'
    this.managementOperationsEditItem.memo = '4月予定の作業ですが、5月下旬の実施を検討（前田）'
    this.managementOperationsEditItem.taskName = '① Ma.認キー登録'
    this.managementOperationsEditItem.adminName = '前田 健太'
    this.managementOperationsEditItem.buildingName = 'カサーレ梅田プレミア'

    this.isManagementOperationEditDialog = true
  }

  execute(newValue: ManagementOperationsEditItem):void {
    console.log('execute')
    console.log(newValue)
  }
}
