import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'

import { questionnairesClient } from '@/clients/questionnaires-client'

import { QuestionnaireDeleteRequest } from '@/dtos/questionnaires/delete'
import { QuestionnaireDetailGetRequest, QuestionnaireDetailGetResponse } from '@/dtos/questionnaires/get'
import { QuestionnaireQuestionDetailGetResponse, QuestionQuestionGetRequest } from '@/dtos/questionnaires/questions/get'
import { QuestionnairePostRequest } from '@/dtos/questionnaires/post'
import { QuestionnairesRespondentsGetRequest, QuestionnairesRespondentsGetResponse } from '@/dtos/questionnaires/respondents/get'
import { QuestionnairesRespondentDetailGetRequest, QuestionnairesRespondentDetailGetResponse } from '@/dtos/questionnaires/respondents/get-detail'
import { QuestionnairePutRequest } from '@/dtos/questionnaires/put'
import { QuestionnairesRespondentsPostRequest } from '@/dtos/questionnaires/respondents/post'
import { Questionnaire, QuestionnairesSearchPostRequest, QuestionnairesSearchPostResponse } from '@/dtos/questionnaires/search/post'

@Module({ store, dynamic: true, namespaced: true, name: 'questionnairesStore' })
class QuestionnairesStore extends VuexModule {
  // アンケート一覧
  private _questionnaires: Questionnaire[] = []
  private _skipToken: string | null = null

  get questionnaires(): Questionnaire[] {
    return this._questionnaires
  }

  get skipToken(): string | null {
    return this._skipToken
  }

  @Mutation
  private PUSH_QUESTIONNAIRES(res: QuestionnairesSearchPostResponse): void {
    this._questionnaires.push(...res.questionnaires)
    this._skipToken = res.skipToken
  }

  @Mutation
  private CLEAR_QUESTIONNAIRES_GET(): void {
    this._questionnaires = []
    this._skipToken = null
  }

  @Action
  async initAndFetchQuestionnaires(req: QuestionnairesSearchPostRequest): Promise<void> {
    const res = await questionnairesClient.postQuestionnairesSearch(req)
    this.clearFetchedQuestionnaires()
    if (res) this.PUSH_QUESTIONNAIRES(res)
  }

  @Action
  async fetchQuestionnaires(req: QuestionnairesSearchPostRequest): Promise<void> {
    const res = await questionnairesClient.postQuestionnairesSearch(req)
    if (res) this.PUSH_QUESTIONNAIRES(res)
  }

  @Action
  clearFetchedQuestionnaires(): void {
    this.CLEAR_QUESTIONNAIRES_GET()
  }

  // アンケート詳細取得
  private _questionnaireDetail: Record<string, QuestionnaireDetailGetResponse> = {}

  get questionnaireDetail(): (id: string) => QuestionnaireDetailGetResponse | undefined {
    return (id: string) => this._questionnaireDetail[id]
  }

  @Mutation
  private SET_QUESTIONNAIRE_DETAIL(res: QuestionnaireDetailGetResponse): void {
    Vue.set(this._questionnaireDetail, res.questionnaireId, res)
  }

  @Action
  async fetchQuestionnaireDetail(req: QuestionnaireDetailGetRequest): Promise<void> {
    const res = await questionnairesClient.getQuestionnaire(req)
    this.SET_QUESTIONNAIRE_DETAIL(res)
  }

  @Action
  async deleteQuestionnaires(req: QuestionnaireDeleteRequest): Promise<void> {
    await questionnairesClient.deleteQuestionnaire(req)
  }

  // アンケート登録
  @Action
  async postQuestionnaire(req: QuestionnairePostRequest): Promise<void> {
    await questionnairesClient.postQuestionnaire(req)
  }

  // アンケート更新
  @Action
  async putQuestionnaire(req: QuestionnairePutRequest): Promise<void> {
    await questionnairesClient.putQuestionnaire(req)
  }

  // アンケート下書き登録
  @Action
  async postQuestionnaireDraft(req: QuestionnairePostRequest): Promise<void> {
    await questionnairesClient.postQuestionnaireDraft(req)
  }

  // アンケート（設問）詳細取得
  private _questionnaireQuestionDetail = new QuestionnaireQuestionDetailGetResponse()

  get questionnaireQuestionDetail():QuestionnaireQuestionDetailGetResponse { return this._questionnaireQuestionDetail }

  @Mutation
  private SET_QUESTIONNAIRE_QUESTION_DETAIL(res: QuestionnaireQuestionDetailGetResponse):void {
    this._questionnaireQuestionDetail = res
  }

  @Action
  async getQuestionnaireQuestionDetail(req:QuestionQuestionGetRequest):Promise<void> {
    const res = await questionnairesClient.getQuestionnaireQuestionDetail(req)
    this.SET_QUESTIONNAIRE_QUESTION_DETAIL(res)
  }

  // アンケート（回答者）一覧取得
  private _questionnaireRespondents: Record<string, QuestionnairesRespondentsGetResponse> = {}

  get questionnaireRespondents(): (id: string) => QuestionnairesRespondentsGetResponse | undefined {
    return (id: string) => this._questionnaireRespondents[id]
  }

  @Mutation
  private SET_QUESTIONNAIRE_RESPONDENTS(res: QuestionnairesRespondentsGetResponse) {
    Vue.set(this._questionnaireRespondents, res.questionnaireId, res)
  }

  @Action
  async fetchQuestionnairesRespondents(req: QuestionnairesRespondentsGetRequest): Promise<void> {
    const res = await questionnairesClient.getQuestionnaireRespondents(req)
    this.SET_QUESTIONNAIRE_RESPONDENTS(res)
  }

  // アンケート（回答者）詳細取得
  private _questionnaireRespondentDetail: Record<string, QuestionnairesRespondentDetailGetResponse> = {}

  get questionnaireRespondentDetail(): (id: string) => QuestionnairesRespondentDetailGetResponse | undefined {
    return (id: string) => this._questionnaireRespondentDetail[id]
  }

  @Mutation
  private SET_QUESTIONNAIRE_RESPONDENT_DETAIL(res: QuestionnairesRespondentDetailGetResponse) {
    Vue.set(this._questionnaireRespondentDetail, res.ownerUser.userId, res)
  }

  @Action
  async fetchQuestionnairesRespondentDetail(req: QuestionnairesRespondentDetailGetRequest): Promise<void> {
    const res = await questionnairesClient.getQuestionnairesRespondentDetail(req)
    this.SET_QUESTIONNAIRE_RESPONDENT_DETAIL(res)
  }

  // アンケート代理回答登録
  @Action
  async postProxyAnswers({ questionnaireId, req }: {questionnaireId: string, req: QuestionnairesRespondentsPostRequest}): Promise<void> {
    await questionnairesClient.postQuestionnairesRespondentProxyAnswers(questionnaireId, req)
  }
}

export const questionnairesModule = getModule(QuestionnairesStore)
