


















































































































































































import { ReservesGetResponse } from '@/dtos/reserve-funds/get'
import { Component, Mixins } from 'vue-property-decorator'
import { reserveFundsModule } from '@/stores/reserve-funds-store'
import { ReservesIncomeRecordRequest } from '@/dtos/reserve-funds/records/post'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { RESERVE_PLAN_STATES } from '@/constants/schema-constants'
import { staticRoutes } from '@/routes'

@Component({
  components: {
    SmBadgeInline: () => import('@/components/atoms/SmBadgeInline.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogClosable: () => import('@/components/organisms/dialog/SmDialogClosable.vue'),

    ReservePlanTable: () => import('@/pages/reserve-funds/detail/ReservePlanTable.vue'),
    ReservedAmountTable: () => import('@/pages/reserve-funds/detail/ReservedAmountTable.vue'),
    MonthlyAmountTable: () => import('@/pages/reserve-funds/MonthlyAmountTable.vue')
  }
})
export default class DetailSubPage extends Mixins(CurrentAdminManager) {
  private get response():ReservesGetResponse {
    return reserveFundsModule.reserveFund
  }

  async beforeCreate():Promise<void> {
    await reserveFundsModule.fetchReserveFund()
  }

  insertRecordDialogVisible = false

  targetYear:number | null = null

  private get periodItems():{ value: number | null, label: string }[] {
    const nullItem:{ value: number | null, label: string }[] = [{ value: null, label: '-' }]
    if (!this.response.reservePlan) return nullItem
    return nullItem.concat(this.response.reservePlan.map(e => { return { value: e.targetYear, label: `${e.period}` } }))
  }

  incomeRecord:number | null = null
  RESERVE_PLAN_STATES = Object.freeze(RESERVE_PLAN_STATES)

  private get isVisibleBtn():boolean {
    return this.$isServiceStaff
  }

  editPlan():void {
    this.$router.push({ name: staticRoutes.reserveFundDetailEdit.name })
  }

  showInsertRecordDialog(): void {
    this.targetYear = null
    this.incomeRecord = null
    this.insertRecordDialogVisible = true
  }

  async insertRecord():Promise<void> {
    this.insertRecordDialogVisible = false
    if (this.targetYear && this.incomeRecord !== null) {
      const req = new ReservesIncomeRecordRequest()
      req.targetYear = this.targetYear
      req.incomeRecord = this.incomeRecord
      await reserveFundsModule.postReservesIncomeRecords(req)
      // 実績更新後、修積データを更新
      await reserveFundsModule.fetchReserveFund()
    }
  }

  createPlan():void {
    this.$router.push({ name: staticRoutes.reserveFundDetailRegister.name })
  }
}
