import { QuestionnaireQuestionType } from '@/constants/schema-constants'
import { Type } from 'class-transformer'

export class QuestionnairesRespondentsAnswerElement {
  questionnaireQuestionElementId!: string
  choiceAnswer?: boolean
  freeAnswer?: string
}

export class QuestionnairesRespondentsProxyQuestion {
  questionnaireQuestionId!: string
  questionnaireQuestionType!: QuestionnaireQuestionType
  required!: boolean
  @Type(() => QuestionnairesRespondentsAnswerElement)
  answers!: QuestionnairesRespondentsAnswerElement[]
}

export class QuestionnairesRespondentsProxyAnswer {
  ownerUserId!: string
  answerCount!: number
  @Type(() => QuestionnairesRespondentsProxyQuestion)
  questions!: QuestionnairesRespondentsProxyQuestion[]
}

export class QuestionnairesRespondentsPostRequest {
  @Type(() => QuestionnairesRespondentsProxyAnswer)
  proxyAnswers!: QuestionnairesRespondentsProxyAnswer[]
}
