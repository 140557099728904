










































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import { IDEA_STATES } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'

import { Menu } from '@/components/owner-apps/SmOwMenu.vue'

import { getIdeaLabel } from '@/libs/state-handler'
import { windowOpen } from '@/libs/window-open'

import { staticRoutes } from '@/routes'

import { newTabLocalParamStorageModule, AdminIdeaPreviewContent } from '@/stores/new-tab-local-param-storage-store'
import { SessionGetResponseSession } from '@/dtos/ideas/admin-idea/commons'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmOwBtnOutline: () => import('@/components/owner-apps/SmOwBtnOutline.vue'),
    SmOwBtnText: () => import('@/components/owner-apps/SmOwBtnText.vue'),
    SmOwCardOpinion: () => import('@/components/owner-apps/SmOwCardOpinion.vue'),
    SmOwListPlan: () => import('@/components/owner-apps/SmOwListPlan.vue'),
    SmOwTextLink: () => import('@/components/owner-apps/SmOwTextLink.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class adminIdeaPreviewPage extends Vue {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)

  @Prop({ default: '' })
    private readonly id!: string

  private get adminIdea(): AdminIdeaPreviewContent { return newTabLocalParamStorageModule.adminIdeaPreviewContent(this.id) ?? new AdminIdeaPreviewContent() }

  menu:Menu[] = [{ id: 'REPORT', icon: '$flag_outlined', text: '不適切な意見を報告する' }]

  private get chipText(): string {
    return getIdeaLabel(this.adminIdea?.ideaState)
  }

  private get isArchived(): boolean { return this.adminIdea?.ideaState === IDEA_STATES.ADMIN.RELEASED }

  private get announce():string | undefined {
    if (this.adminIdea?.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT) {
      if (!this.adminIdea?.previewPlans.length) {
        return undefined
      } else if (this.adminIdea?.previewPlans.length === 1) {
        return 'プランに賛同する場合は、賛同するを押してください。'
      }
      return '賛同できるプランをお選びください。'
    } else if (this.adminIdea?.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION) {
      return '候補のプランに関して意見を投稿できます。'
    }
    return undefined
  }

  private get sortSessions(): SessionGetResponseSession[] | undefined {
    if (!this.adminIdea.previewSession?.sessions) return
    return this.adminIdea.previewSession.sessions.slice().reverse()
  }

  private get deadlines():string | undefined {
    if (this.adminIdea?.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION) {
      return '意見締切日時：' + this.adminIdea?.opinionDeadline
    } else {
      return '賛同締切日時：' + this.adminIdea?.deadline
    }
  }

  private get opinionsExist(): boolean {
    return (this.adminIdea?.previewOpinions && this.adminIdea?.previewOpinions.length > 0) ?? false
  }

  private get isDuringAcceptingAgreement():boolean {
    return this.adminIdea?.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT
  }

  private get isDraftOrAcceptingOpinion(): boolean {
    return this.adminIdea?.ideaState === (IDEA_STATES.ADMIN.ACCEPTING_OPINION || IDEA_STATES.ADMIN.DRAFT)
  }

  private get requirement(): string { return '「このプランに賛同する」の合計数が全区分所有者の10％以上となること' }

  goToIdeaUpdate(): void {
    const path = this.adminIdea?.lastUpdatedAt ? staticRoutes.adminIdeaDetailPreview.path.replace(':ideaId', this.adminIdea?.ideaId) : ''
    windowOpen(`${path}/updates?buildingId=${this.adminIdea?.currentBuildingId}`)
  }

  openOpinionModal(): void {
    // nothing to do
  }

  onClickMenu(): void {
    // nothing to do
  }

  goToBaseIdea(): void {
    const path = this.adminIdea?.previewBaseIdea ? staticRoutes.ownerIdeaDetailPreview.path.replace(':ideaId', this.adminIdea?.previewBaseIdea.ideaId) : ''
    windowOpen(`${path}?buildingId=${this.adminIdea?.currentBuildingId}`)
  }
}
