import { Type } from 'class-transformer'
import type { ResolutionState, ResolutionType } from '@/constants/schema-constants'

export class BuildingResolutionsGetRequest {
  buildingId!: string
  resolutionStates!: ResolutionState[]
  hasUnvotedOwners?: boolean
}

export class BuildingResolutionDto {
  resolutionId!: string
  resolutionType!: ResolutionType
  resolutionState!: ResolutionState
  title!: string
  deadline?: string
  deadlineDateTime?: string
  holdingAt?: string
  holdingDate?: string
  postedAt!: string
}

export class BuildingResolutionsGetResponse {
  @Type(() => BuildingResolutionDto) resolutions: BuildingResolutionDto[] = []
}
