





























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getQuestionnaireQuestionTypeLabel } from '@/libs/type-handler'
import type { ChartType, QuestionnaireQuestionElementType, QuestionnaireQuestionType } from '@/constants/schema-constants'
import { CHART_TYPES, QUESTIONNAIRE_QUESTION_ELEMENT_TYPES, QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'
import { RadioOption } from '@/components/atoms/SmRadio.vue'

export class Choice {
  questionnaireQuestionElementId!: string
  choice!: string
  templateQuestionElementId?: string
  questionnaireQuestionElementType!: QuestionnaireQuestionElementType
  sortOrderNum!: number
  answerCount!: number
  previousAnswerCount?: number
}

export class CompareChoice {
  choices: Choice[] = []
  deadline!: string
}

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
  }
})
export default class SmCardQuestionnaireReportResult extends Vue {
  QUESTIONNAIRE_QUESTION_ELEMENT_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_ELEMENT_TYPES)
  QUESTIONNAIRE_QUESTION_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_TYPES)

  @Prop({ required: true, default: false })
  private readonly head!: boolean

  @Prop({ required: true, default: false })
  private readonly foot!: boolean

  @Prop({ required: true, default: false })
  private readonly deletable!: boolean

  @Prop({ required: true, default: '' })
  private readonly fieldIdPrefix!: string

  @Prop({ required: true, default: '' })
  private readonly questionnaireSentence!: string

  @Prop({ required: true, default: QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE })
  private readonly questionType!: QuestionnaireQuestionType

  @Prop({ required: false, default: undefined })
  private readonly chartType?: ChartType

  @Prop({ required: true, default: [] })
  private readonly compareChoices!: CompareChoice[]

  private get questionTitle():string {
    return `設問(${getQuestionnaireQuestionTypeLabel(this.questionType)})`
  }

  // ----------------------------共通----------------------------

  private getPercentage(elementNum: number, total: number): number {
    return total ? Math.round(elementNum / total * 100) : 0
  }

  private totalCount(compareChoice: CompareChoice): number {
    return compareChoice.choices.reduce((acc:number, cur:Choice) => acc + cur.answerCount, 0)
  }

  // ----------------------------入力式----------------------------

  // ----------------------------選択肢（単一選択式）----------------------------
  chartTypes = [new RadioOption('帯グラフ', CHART_TYPES.HORIZONTAL), new RadioOption('円グラフ', CHART_TYPES.PIE)]

  selectedChartType = 0

  created(): void {
    this.selectedChartType = this.chartType as number
  }

  @Watch('selectedChartType')
  private updateChartType():void {
    this.$emit('update-chart-type', this.selectedChartType)
  }
}
