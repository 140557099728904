import { CognitoAuth, CognitoAuthSession } from 'amazon-cognito-auth-js'

class CognitoAuthAdapter {
  private auth:CognitoAuth

  private getCognitoAuth():CognitoAuth {
    const authData = {
      ClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
      AppWebDomain: process.env.VUE_APP_COGNITO_WEB_DOMAIN,
      TokenScopesArray: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'], // Cognitoで許可しているスコープ
      RedirectUriSignIn: process.env.VUE_APP_REDIRECT_URL_SIGN_IN,
      RedirectUriSignOut: process.env.VUE_APP_REDIRECT_URL_SIGN_OUT,
      IdentityProvider: process.env.VUE_APP_IDENTITY_PROVIDER,
      UserPoolId: process.env.VUE_APP_USER_POOL_ID
    }
    const auth = new CognitoAuth(authData)
    auth.useCodeGrantFlow()
    return auth
  }

  constructor() {
    this.auth = this.getCognitoAuth()
  }

  /**
   * IDトークンを取得する
   */
  public getIdToken():string {
    return this.auth.getCachedSession().getIdToken().getJwtToken()
  }

  /**
   * ログインしているかを確認する
   */
  public sessionIsValid():boolean {
    return this.auth.getCachedSession().isValid()
  }

  /**
   * リフレッシュトークンの存在有無を確認する
   */
  public hasRefreshToken():boolean {
    return !!this.auth.getCachedSession().getRefreshToken().getToken()
  }

  /**
   * リフレッシュトークンを用いてアクセストークン・IDトークンを更新する
   * onSuccess:更新成功時の処理
   * onFailure:更新失敗時の処理
   */
  public refreshSession(userHandler:CognitoAuthUserHandler) {
    this.auth = this.getCognitoAuth()
    this.auth.userhandler = userHandler
    this.auth.refreshSession(this.auth.getSignInUserSession().getRefreshToken().getToken())
  }

  /**
   * 現行のログイン情報からセッションを取得する。
   * アクセストークンが有効：現在保持しているセッションをそのまま維持し、onSuccess処理を実行
   * アクセストークンが無効かつリフレッシュトークンが有効：リフレッシュ処理後、onSuccess処理を実行
   * リフレッシュトークンが無効：onFailure処理を実行
   */
  public async getSession(userHandler:CognitoAuthUserHandler) {
    this.auth = this.getCognitoAuth()
    this.auth.userhandler = userHandler
    await this.auth.getSession()
  }

  /**
   * クエリパラメータからセッションを取得する。
   * アクセストークンが有効：現在保持しているセッションをそのまま維持し、onSuccess処理を実行
   * アクセストークンが無効かつリフレッシュトークンが有効：リフレッシュ処理後、onSuccess処理を実行
   * リフレッシュトークンが無効：onFailure処理を実行
   */
  public async parseSAMLResponse(url:string, userHandler:CognitoAuthUserHandler) {
    this.auth = this.getCognitoAuth()
    this.auth.userhandler = userHandler
    await this.auth.parseCognitoWebResponse(url)
  }

  /**
   * ログアウトする
   */
  public async signOut() {
    await this.auth.signOut()
  }
}

/**
 * 認証後のコールバック
 * ※コンストラクタを作るとgetSession等の可読性が下がるのであえてコンストラクタを作っていない
 */
export class CognitoAuthUserHandler {
  public onSuccess: (session: CognitoAuthSession) => void = () => { throw new Error('onSuccess handler undefined') }
  public onFailure:(response:string) => void = () => { throw new Error('onFailure handler undefined') }
}

export const cognitoAuth = new CognitoAuthAdapter()
