
































































import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { staticRoutes } from '@/routes'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { PAGE_TYPES } from '@/constants/ux-constants'
import type { PageType } from '@/constants/ux-constants'
import { repairsModule } from '@/stores/repairs-store'
import { RepairsUpdatesCommentsPostRequest } from '@/dtos/repairs/updates/comments/post'
import { RepairsUpdatesCommentsPutRequest } from '@/dtos/repairs/updates/comments/put'
import { RepairsUpdatesCommentDetailGetRequest, RepairsUpdatesCommentDetailGetResponse } from '@/dtos/repairs/updates/comments/get-detail'
import { RepairsUpdatesLogDetailGetRequest, RepairsUpdatesLogDetailGetResponse } from '@/dtos/repairs/updates/logs/get-detail'
import { RepairsUpdatesLogsGetRequest } from '@/dtos/repairs/updates/logs/get'
import { generateUuid } from '@/libs/uuid-generator'

interface PageTypeSpecifications {
  pageTitle: string
  executeBtnLabel: string
  dialogMessage: string

  created(commentId?: string): Promise<void>
  onClickExecute($router: VueRouter, comment: string, shouldAddLog: boolean, selectedLogId: string, commentId?: string, version?: number): Promise<void>
}

class CreateSpecifications implements PageTypeSpecifications {
  readonly pageTitle = '変更コメント新規作成'
  readonly executeBtnLabel = '投稿する'
  readonly dialogMessage = '変更コメントを投稿します。よろしいですか？'

  async created() { /** nothing to do */ }

  async onClickExecute($router: VueRouter, comment: string, shouldAddLog: boolean, selectedLogId: string): Promise<void> {
    const req = new RepairsUpdatesCommentsPostRequest()
    req.comment = comment
    if (shouldAddLog) { req.logId = selectedLogId }

    await repairsModule.postRepairsUpdatesComments(req)
    $router.push({ name: staticRoutes.repairPlanDetail.name })
  }
}

class UpdateSpecifications implements PageTypeSpecifications {
  readonly pageTitle = '変更コメント編集'
  readonly executeBtnLabel = '更新する'
  readonly dialogMessage = '変更コメントを更新します。よろしいですか？'

  async created(commentId: string): Promise<void> {
    await repairsModule.fetchRepairsUpdatesCommentDetail(new RepairsUpdatesCommentDetailGetRequest(commentId))
  }

  async onClickExecute($router: VueRouter, comment: string, shouldAddLog: boolean, selectedLogId: string, commentId: string, version: number): Promise<void> {
    const req = new RepairsUpdatesCommentsPutRequest()
    req.commentId = commentId
    req.comment = comment
    if (shouldAddLog) { req.logId = selectedLogId }
    req.version = version

    await repairsModule.putRepairsUpdatesComments(req)

    $router.go(-1)
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    SmTableRepairPlanUpdateDetails: () => import('@/pages/repair-plan/SmTableRepairPlanUpdateDetails.vue'),
    UpdatesLogsSelectModal: () => import('@/pages/repair-plan/repair-plan-comments-register/UpdatesLogsSelectModal.vue'),
  }
})
export default class RepairPlanCommentsRegisterPage extends Vue {
  @Prop({ required: true, default: PAGE_TYPES.CREATE })
  private readonly pageType!: PageType

  @Prop()
  private readonly commentId?: string

  async created(): Promise<void> {
    await this.typeSpecs.created(this.commentId)
  }

  private get typeSpecs(): PageTypeSpecifications {
    switch (this.pageType) {
      case PAGE_TYPES.CREATE: return new CreateSpecifications()
      case PAGE_TYPES.EDIT: return new UpdateSpecifications()
      default: return assertExhaustive(this.pageType)
    }
  }

  comment = ''
  version: number | null = null
  selectedLogId = ''

  private get storedRepairsUpdatesCommentDetail(): RepairsUpdatesCommentDetailGetResponse | undefined {
    if (!this.commentId) return undefined
    return repairsModule.repairsUpdatesCommentDetailGet(this.commentId)
  }

  @Watch('storedRepairsUpdatesCommentDetail', { immediate: false, deep: false })
  onStoredUpdatesCommentDetailFetched(fetched: RepairsUpdatesCommentDetailGetResponse | undefined): void {
    if (!fetched) return
    this.comment = fetched.comment
    this.version = fetched.version
    if (fetched.logId) {
      this.fetchSelectedLog(fetched.logId)
      this.shouldAddLog = true
    }
  }

  private get RepairsUpdatesLogDetail(): RepairsUpdatesLogDetailGetResponse | undefined { return repairsModule.repairsUpdatesLogDetailGet(this.selectedLogId) }

  fetchSelectedLog(selectedLogId: string): void {
    this.selectedLogId = selectedLogId
    repairsModule.fetchRepairsUpdatesLogDetail(new RepairsUpdatesLogDetailGetRequest(selectedLogId))
  }

  shouldAddLog = false

  isExecuteDialogVisible = false
  openExecuteDialog(): void { this.isExecuteDialogVisible = true }
  closeExecuteDialog(): void { this.isExecuteDialogVisible = false }

  async onClickExecute($router: VueRouter, comment: string, shouldAddLog: boolean, selectedLogId: string, commentId: string, version: number): Promise<void> {
    this.isExecuteDialogVisible = false
    await this.typeSpecs.onClickExecute($router, comment, shouldAddLog, selectedLogId, commentId, version)
  }

  isUpdatesLogsSelectModalVisible = false
  updatesLogsSelectModalKey = generateUuid()
  async openUpdatesLogsSelectModal(): Promise<void> {
    const logsReq = new RepairsUpdatesLogsGetRequest(0, 1000)
    logsReq.hasDiffs = true
    await repairsModule.fetchRepairsUpdatesLogs(logsReq)
    this.updatesLogsSelectModalKey = generateUuid()
    this.isUpdatesLogsSelectModalVisible = true
  }
}
