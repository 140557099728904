import { Type } from 'class-transformer'
import { Material, Subject, User } from '@/dtos/commons'
import { Details } from '@/dtos/resolutions/commons'
import type { HoldingMethodType, ResolutionState } from '@/constants/schema-constants'

export class GMResolutionDetailGetRequest {
  resolutionId: string

  constructor(resolutionId: string) {
    this.resolutionId = resolutionId
  }
}

export class GMResolutionDetailGetResponse {
  resolutionId!: string
  resolutionState!: ResolutionState
  postedAt!: string
  deadline!: string
  deadlineDateTime!: string
  title!: string
  @Type(() => User) user = new User()
  @Type(() => Details) details: Details = new Details()
  holdingMethodType!: HoldingMethodType
  holdingAt!: string
  holdingDate!: string
  holdingTime!: string
  venue!: string
  meetingUrl?: string
  meetingInformation?: string
  statementDeadlineText!: string
  statementDeadlineAnnotation?: string
  @Type(() => Material) material = new Material()
  @Type(() => Subject) subjects: Subject[] = []
  version!: number
}
