





































































































































import { Mixins, Component, Watch } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { RESOLUTION_TYPES, RESOLUTION_STATES } from '@/constants/schema-constants'
import type { ResolutionState, ResolutionType } from '@/constants/schema-constants'

import { errorsModule } from '@/stores/errors'
import { resolutionsModule } from '@/stores/resolutions-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { ListResponseResolutionDto, ResolutionsGetRequest } from '@/dtos/resolutions/get'
import { Choice } from '@/components/molecules/SmBtnToggle.vue'
import { FilterLabel } from '@/components/molecules/SmExpansionFilterMenu.vue'
import type { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'

const TAKE = 20
const STORAGE_KEY = staticRoutes.resolutionsList.name

// 絞り込みメニューのラベル
const FILTER_LABELS = {
  condition1: new FilterLabel('ステータス'),
  condition2: new FilterLabel('種別', 'center'),
  condition3: new FilterLabel('投稿日', 'center'),
  condition4: new FilterLabel('キーワード', 'center'),
}

// 決議ステータスのトグルボタンの各ボタンIDの定義
const FILTER_TOGGLE_STATE_IDS = {
  DRAFT: 'draft',
  ACCEPTING: 'accepting',
  COUTING: 'counting',
  ARCHIVED: 'archive',
}
type FilterToggleId = typeof FILTER_TOGGLE_STATE_IDS[keyof typeof FILTER_TOGGLE_STATE_IDS]

// 決議ステータスのトグルボタンIDに対するアイデアステータスの定義
const FILTER_STATE_TOGGLE_MAPPER: Record<FilterToggleId, ResolutionState[]> = {
  [FILTER_TOGGLE_STATE_IDS.DRAFT]: [
    RESOLUTION_STATES.GENERAL_MEETING.DRAFT,
    RESOLUTION_STATES.ONLINE.DRAFT,
  ],
  [FILTER_TOGGLE_STATE_IDS.ACCEPTING]: [
    RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL,
    RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT,
    RESOLUTION_STATES.ONLINE.ACCEPTING_ALL,
    RESOLUTION_STATES.ONLINE.ACCEPTING_VOTE,
  ],
  [FILTER_TOGGLE_STATE_IDS.COUTING]: [
    RESOLUTION_STATES.GENERAL_MEETING.COUNTING,
    RESOLUTION_STATES.ONLINE.COUNTING,
  ],
  [FILTER_TOGGLE_STATE_IDS.ARCHIVED]: [
    RESOLUTION_STATES.GENERAL_MEETING.RELEASED,
    RESOLUTION_STATES.ONLINE.RELEASED,
  ],
}

// 決議タイプのトグルボタン用定義
const FILTER_TOGGLE_CHOICES_TYPE = [
  new Choice('GENERAL_MEETING', '総会決議'),
  new Choice('ONLINE', 'オンライン決議'),
]

// 並び順のメニューの内容
const SORT_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  desc: {
    text: '投稿日が新しい順に並び替える',
    label: '投稿日が新しい順'
  },
  asc: {
    text: '投稿日が古い順に並び替える',
    label: '投稿日が古い順'
  }
}

class InputParams {
  selectedStates: FilterToggleId[] = []
  selectedTypes: ('GENERAL_MEETING' | 'ONLINE')[] = []
  fromDate = ''
  toDate = ''
  keyword=''
  sortMenuId = 'desc' // 並べ替えメニューのキー（id）
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmBtnToggle: () => import('@/components/molecules/SmBtnToggle.vue'),
    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmCardResolution: () => import('@/components/molecules/card/SmCardResolution.vue'),
    SmExpansionFilterMenu: () => import('@/components/molecules/SmExpansionFilterMenu.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class ResolutionsListPage extends Mixins(CurrentAdminManager) {
  async created(): Promise<void> { // FIXME このcreatedはasyncする必要がないはず
    // 初期検索パラメーターの設定（以前の検索条件が残っている場合、それを復元して反映）
    const stored = paramStorageModule.savedParam(STORAGE_KEY, InputParams)
    if (stored) {
      this.inputParams = stored
    }

    this.req = new ResolutionsGetRequest(TAKE)
    this.req.resolutionStates = this.inputParams.selectedStates.flatMap(s => FILTER_STATE_TOGGLE_MAPPER[s])
    this.req.resolutionTypes = this.inputParams.selectedTypes.map(s => RESOLUTION_TYPES[s])
    if (this.inputParams.fromDate) this.req.fromDate = this.inputParams.fromDate
    if (this.inputParams.toDate) this.req.toDate = this.inputParams.toDate
    if (this.inputParams.keyword) this.req.keyword = this.inputParams.keyword
    this.req.sortOrder = this.inputParams.sortMenuId

    this.reloadResolutions()
  }

  private get resolutions(): ListResponseResolutionDto[] { return resolutionsModule.resolutions }
  private get skipToken(): string | null { return resolutionsModule.skipToken }

  // --------------- リクエストパラメータと、そのもととなる画面上の入力項目 ---------------
  req: ResolutionsGetRequest | null = null
  inputParams = new InputParams()

  // --------------- データの読み込み ---------------
  identifier = 1
  isWaitingSwitch = false // switch・toggleの連打防止用
  handler: LoadingHandler | null = null
  async loadResolutions(handler: LoadingHandler): Promise<void> {
    // 検索パラメータの初期化が完了していなければ完了扱いにする
    if (!this.req) { handler.complete(); return }
    this.isWaitingSwitch = true
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }

    const beforeResolutionsCount = this.resolutions.length
    this.req.skipToken = this.skipToken ?? undefined
    await resolutionsModule.fetchResolutions(this.toGetRequest())
    paramStorageModule.save({ key: STORAGE_KEY, params: { ...this.inputParams } })

    // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
    if (this.resolutions.length === 0) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = false
    handler.loaded()
    const expectingToBe = beforeResolutionsCount + this.req.take
    if (this.resolutions.length < expectingToBe) handler.complete()
  }

  reloadResolutions(): void {
    resolutionsModule.clearFetchedResolutions()
    this.identifier++
  }

  toGetRequest(): ResolutionsGetRequest {
    const request = new ResolutionsGetRequest(TAKE)

    request.resolutionStates = this.req?.resolutionStates ?? undefined
    request.resolutionTypes = this.req?.resolutionTypes ?? undefined
    request.fromDate = this.req?.fromDate ?? undefined
    request.toDate = this.req?.toDate ?? undefined
    request.keyword = this.req?.keyword ?? undefined
    request.sortOrder = this.req?.sortOrder ?? undefined
    request.skipToken = this.req?.skipToken
    return request
  }

  // -------------- 画面描画用のデータ ---------------
  appBarMenuId ='ONLINE'

  sortMenuItems = SORT_MENU_ITEMS

  isFilterExpansionOpen = true

  // 絞り込みメニューのラベル名（各slotのname属性ごとにラベルを用意する）
  filterLabels = FILTER_LABELS

  // 決議ステータスのトグルボタンの設定
  private get stateToggles(): Choice[] {
    const toggles = [
      new Choice(FILTER_TOGGLE_STATE_IDS.ACCEPTING, '投票・意思表明受付中'),
      new Choice(FILTER_TOGGLE_STATE_IDS.COUTING, '結果集計中'),
      new Choice(FILTER_TOGGLE_STATE_IDS.ARCHIVED, 'アーカイブ'),
    ]
    // トグルボタンの権限による制御（管理者業務執行者以外の権限で下書きステータスを表示しない）
    if (this.$isServiceStaff) {
      toggles.unshift(new Choice(FILTER_TOGGLE_STATE_IDS.DRAFT, '下書き'))
    }
    return toggles
  }

  // 決議タイプのトグルボタンの設定
  typeToggles = FILTER_TOGGLE_CHOICES_TYPE

  // 絞り込みを行っているかの判定（0件時のメッセージの判定に利用）
  private get isNoCondition(): boolean {
    if (!this.req) return true

    if ((this.req.resolutionStates && this.req.resolutionStates.length > 0) ||
      (this.req.resolutionTypes && this.req.resolutionTypes.length > 0) ||
      this.req.fromDate ||
      this.req.toDate ||
      this.req.keyword) {
      return false
    }
    return true
  }

  // --------------- ボタンなどを押下した際の処理 ---------------
  goToRequirementPage(): void {
    this.$router.push({ name: staticRoutes.requirementsList.name })
  }

  // カードクリック処理
  onClickCard(resolutionType:ResolutionType, resolutionState: ResolutionState, resolutionId: string, ticketId: string):void {
    switch (resolutionType) {
      case RESOLUTION_TYPES.GENERAL_MEETING:
        if (resolutionState === RESOLUTION_STATES.GENERAL_MEETING.DRAFT) {
          this.$router.push({ name: staticRoutes.gmResolutionCreate.name, query: { resolutionId: resolutionId, ticketId: ticketId } })
          break
        }
        this.$router.push({ name: staticRoutes.gmResolutionDetail.name, params: { resolutionId: resolutionId } })
        break
      case RESOLUTION_TYPES.ONLINE:
        if (resolutionState === RESOLUTION_STATES.ONLINE.DRAFT) {
          this.$router.push({ name: staticRoutes.onlineResolutionCreate.name, query: { resolutionId: resolutionId, ticketId: ticketId } })
          break
        }
        this.$router.push({ name: staticRoutes.onlineResolutionDetail.name, params: { resolutionId: resolutionId } })
        break
      default:
        break
    }
  }

  // 絞り込み処理
  onChangeSelectedState():void {
    if (!this.req) return
    const resolutionState = this.inputParams.selectedStates.flatMap(s => FILTER_STATE_TOGGLE_MAPPER[s])
    this.req.resolutionStates = resolutionState
    this.reloadResolutions()
  }

  onChangeSelectedType():void {
    if (!this.req) return
    const resolutionTypes = this.inputParams.selectedTypes.map(s => RESOLUTION_TYPES[s])
    this.req.resolutionTypes = resolutionTypes
    this.reloadResolutions()
  }

  onSearchDateFilter():void {
    if (!this.req) return
    this.req.fromDate = this.inputParams.fromDate === '' ? null : this.inputParams.fromDate
    this.req.toDate = this.inputParams.toDate === '' ? null : this.inputParams.toDate
    this.reloadResolutions()
  }

  onSearchKeyword():void {
    if (!this.req) return
    this.req.keyword = this.inputParams.keyword === '' ? null : this.inputParams.keyword
    this.reloadResolutions()
  }

  onChangeSortMenu():void {
    if (!this.req) return
    this.req.sortOrder = this.inputParams.sortMenuId
    this.reloadResolutions()
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
    this.isWaitingSwitch = false
  }
}
