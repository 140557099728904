

































import { RepairsExpensesGetResponse } from '@/dtos/repairs/expenses/get'
import { staticRoutes } from '@/routes'
import { repairsModule } from '@/stores/repairs-store'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmChartRepairPlan: () => import('@/components/molecules/SmChartRepairPlan.vue'),
    SmTableRepairPlan: () => import('@/components/molecules/SmTableRepairPlan.vue'),

    SmOwBtnText: () => import('@/components/owner-apps/SmOwBtnText.vue'),
  }
})
export default class RepairPlanSummarySubPage extends Vue {
  private get repairsExpenses(): RepairsExpensesGetResponse { return repairsModule.repairsExpensesGet }

  outputPDF(): void {
    this.$router.push({ name: staticRoutes.repairPlanDetailSummaryPrint.name })
  }
}
