import { Type } from 'class-transformer'
import { ConsultationState } from '@/constants/schema-constants'
import { Material, User } from '../commons'

export class ConsultationDetailGetRequest {
  userId!: string
  skip!: number
  take!: number

  constructor(userId: string, skip: number, take: number) {
    this.userId = userId
    this.skip = skip
    this.take = take
  }
}

export class ConsultationDetailGetResponseConsultation {
  consultationId!: string
  isUnread?: boolean
  @Type(() => User) user?: User
  message!: string
  @Type(() => Material)material?: Material
  postedAt!: string
  consultationState!: ConsultationState
  reactions!: string[]
  version!: number
  // 編集用の項目
  isEditMode = false
}

export class ConsultationDetailGetResponse {
  @Type(() => User) ownerUser: User = new User()
  @Type(() => ConsultationDetailGetResponseConsultation) consultations: ConsultationDetailGetResponseConsultation[] = []
}
