import dayjs from 'dayjs'
import 'dayjs/locale/ja'

dayjs.locale('ja') // https://github.com/iamkun/dayjs/blob/dev/src/locale/ja.js

/**
 * ロケールを考慮した日時の取得・整形処理を司る
 */
export interface IBusinessDate {
  /**
   * 現在時刻
   */
  now(): Date

  /**
   * 指定日時の値をコピーしたインスタンスを返す
   * @param src コピー元の日時
   */
  copy(src:Date): Date

  /**
   * stringオブジェクトをDateオブジェクトへ変換する
   * @param s 整形するstringオブジェクト
   */
  toDate(s: string): Date

  /**
   * ISO8601形式のUTC日時文字列を、JSTを表現するDateインスタンスに変換
   * @param utc yyyy-MM-ddTHH:mm:ss.fffZ
   */
  utcStringToJst(utc: string): Date

  /**
   * 現在時刻を示すUNIXタイムスタンプ（秒）
   */
  currentTimestamp(): number

  /**
   * yyyy年MM月
   * @param d 整形するDateオブジェクト
   * @param zeroPadding trueなら0埋めする（初期値はtrue）
   */
  toYearMonthString(d: Date, zeroPadding: boolean): string

  /**
   * yyyy年MM月dd日
   * @param d 整形するDateオブジェクト
   * @param zeroPadding trueなら0埋めする（初期値はtrue）
   */
  toDateString(d: Date, zeroPadding: boolean): string

  /**
   * yyyy年MM月dd日 HH:mm
   * @param d 整形するDateオブジェクト
   */
  toDateTimeString(d: Date): string

  /**
   * yyyy-MM-dd
   */
  toHyphenatedDateString(d: Date): string

  /**
   * yyyy-MM-dd HH:mm
   */
  toHyphenatedDateTimeString(d: Date): string

  /**
   * スケジュール実行のログ出力用文字列へ変換する
   * @param d 整形するDateオブジェクト
   */
  toEventLogString(d: Date): string

  /**
   * 対象の日付が何期にあたるか
   * @param target 期を判断したい日付
   * @param firstPeriodEndYear 物件の最初の会計年度の終わりの年
   * @param accountingMonth 物件の決算月
   */
  dateToPeriod(target: Date, firstPeriodEndYear: number, accountingMonth: number): number

  /**
   * 現在日時のn日後の日付を取得する
   * 引数のオブジェクトに対しプラスするため、引数のオブジェクトの日にちを変更したくない場合はcopyを使用すること
   * @param days プラスする日数
   */
  getAfterDay(baseDate:Date, days:number):Date

  /**
   * ランダムな秒とミリ秒を追加した日時を取得する
   * @param baseDate もととなる日時
   */
  toDateHavingRandomSeconds(baseDate: Date): Date

  /**
   * 時分秒を0に設定する
   * @param baseDate もととなる日時
   */
  toDateHavingZeroTime(baseDate: Date): Date

  /**
   * 時分秒を23:59:59.999に設定する
   * @param baseDate もととなる日時
   */
  toEndDateTime(baseDate: Date): Date
}

export class BusinessDate implements IBusinessDate {
  now(): Date {
    const d = dayjs()
    return this._adjustToJst(d)
  }

  copy(src:Date): Date {
    return dayjs(src).toDate()
  }

  toDate(s: string): Date {
    return dayjs(s).toDate()
  }

  utcStringToJst(utc: string): Date {
    const d = dayjs(utc)
    return this._adjustToJst(d)
  }

  currentTimestamp(): number {
    return dayjs().unix()
  }

  toYearMonthString(d: Date, zeroPadding = true): string {
    return dayjs(d).format(zeroPadding ? 'YYYY年MM月' : 'YYYY年M月')
  }

  toDateString(d: Date, zeroPadding = true): string {
    return dayjs(d).format(zeroPadding ? 'YYYY年MM月DD日(dd)' : 'YYYY年M月D日(dd)')
  }

  toDateTimeString(d: Date): string {
    return dayjs(d).format('YYYY年MM月DD日(dd) HH:mm')
  }

  toHyphenatedDateString(d: Date): string {
    return dayjs(d).format('YYYY-MM-DD')
  }

  toHyphenatedDateTimeString(d: Date): string {
    return dayjs(d).format('YYYY-MM-DD HH:mm')
  }

  toEventLogString(d: Date): string {
    return dayjs(d).format('YYYY-MM-DD HH:mm:ss.SSS')
  }

  toSiemLogFileString(d: Date): string {
    return dayjs(d).format('YYYYMMDD_HHmmssSSS')
  }

  toSiemLogDateString(d: Date): string {
    return dayjs(d).format('YYYY/MM/DD HH:mm:ss')
  }

  toSlashedDateString(d: Date): string {
    return dayjs(d).format('YYYY/MM/DD')
  }

  dateToPeriod(target: Date, firstPeriodEndYear: number, accountingMonth: number): number {
    // Date.prototype.getMonth が0オリジンであることに注意しながら、決算月を超えているか判断して調整
    const adjustment = target.getMonth() + 1 > accountingMonth ? 1 : 0
    return (target.getFullYear() - firstPeriodEndYear + 1) + adjustment
  }

  private _adjustToJst(d: dayjs.Dayjs): Date {
    const offset = d.toDate().getTimezoneOffset()
    // offsetは日本標準時 = -540（9時間 * 60先行）になることを期待していて、結果として日本時間になるよう調整する
    return d.add(540 + offset, 'minute').toDate()
  }

  getAfterDay(baseDate:Date, days:number):Date {
    baseDate.setDate(baseDate.getDate() + days)
    return baseDate
  }

  toDateHavingRandomSeconds(baseDate: Date): Date {
    const date = this.copy(baseDate)
    date.setSeconds(Math.floor(Math.random() * 60), Math.floor(Math.random() * 1000))
    return date
  }

  toDateHavingZeroTime(baseDate: Date): Date {
    const date = this.copy(baseDate)
    date.setHours(0, 0, 0, 0)
    return date
  }

  toEndDateTime(baseDate: Date): Date {
    const date = this.copy(baseDate)
    date.setHours(23, 59, 59, 999)
    return date
  }
}
