































































import { Mixins, Component } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { ADMIN_ROLE } from '@/constants/schema-constants'
import { Header } from '@/components/molecules/SmTableData.vue'
import { repairsModule } from '@/stores/repairs-store'
import { staffsModule } from '@/stores/staffs-store'
import { LogsGetResponseLog, RepairsUpdatesLogsGetRequest } from '@/dtos/repairs/updates/logs/get'
import { RepairsUpdatesLogsDeleteRequest } from '@/dtos/repairs/updates/logs/delete'
import { StaffsGetRequest, StaffsGetResponseStaff } from '@/dtos/staffs/get'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class RepairPlanUpdatesPage extends Mixins(CurrentAdminManager) {
  async created(): Promise<void> {
    await Promise.all([
      repairsModule.fetchRepairsUpdatesLogs(new RepairsUpdatesLogsGetRequest(0, 1000)),
      staffsModule.fetchStaffs(new StaffsGetRequest(ADMIN_ROLE.SERVICE_STAFF)),
    ])
  }

  private get repairsUpdatesLogs(): LogsGetResponseLog[] { return repairsModule.repairsUpdatesLogsGet.logs }
  private get serviceStaffs(): StaffsGetResponseStaff[] { return staffsModule.staffs(ADMIN_ROLE.SERVICE_STAFF) }

  headers: Header[] = [
    new Header({ text: '更新日時', value: 'updatedAt', filterable: false, width: '220px' }),
    new Header({ text: '担当者', value: 'userName', sortable: false, width: '100px' }),
    new Header({ text: 'ファイル', value: 'fileName', sortable: false, filterable: false, width: '200px' }),
    new Header({ text: '', value: 'deleteBtn', sortable: false, filterable: false, width: '50px' }),
    new Header({ text: '当初修繕計画の設定（再設定含む）', value: 'isOriginalPlan', sortable: false, filterable: false, width: '170px' }),
  ]

  // 表の更新日時を正しく昇順／降順にするためにSmTableDataに渡すオブジェクト
  customSorts = {
    updatedAt: {
      sortMethod: (a: LogsGetResponseLog, b: LogsGetResponseLog):number => {
        if (this.changeLogToDate(a) >= this.changeLogToDate(b)) {
          return 1
        } else {
          return -1
        }
      }
    }
  }

  changeLogToDate(log: LogsGetResponseLog): Date {
    const year = parseInt(log.updatedAt.match(/^[0-9]{4}年/g)?.toString() ?? '')
    const month = parseInt(log.updatedAt.match(/(0[1-9]|1[0-2])月/g)?.toString() ?? '')
    const day = parseInt(log.updatedAt.match(/(0[1-9]|[12][0-9]|3[01])日/g)?.toString() ?? '')
    const hour = parseInt(log.updatedAt.match(/([01][0-9]|2[0-3]):/g)?.toString() ?? '')
    const minute = parseInt(log.updatedAt.match(/[0-5][0-9]$/g)?.toString() ?? '')
    return new Date(year, month - 1, day, hour, minute)
  }

  isDeletable(updatesLog: LogsGetResponseLog): boolean {
    return !updatesLog.isOriginalPlan && updatesLog.isAvailable
  }

  targetLogId = ''
  isDeleteDialogVisible = false
  isGuideDialogVisible = false

  openDeleteDialog(logId: string): void {
    this.targetLogId = logId
    this.isDeleteDialogVisible = true
  }

  async deleteUpdatesLog(): Promise<void> {
    this.isDeleteDialogVisible = false
    await repairsModule.deleteRepairsUpdatesLogs(new RepairsUpdatesLogsDeleteRequest(this.targetLogId))

    this.isGuideDialogVisible = true
    await repairsModule.fetchRepairsUpdatesLogs(new RepairsUpdatesLogsGetRequest(0, 1000))
  }

  goToRepairPlanUpdatesDetail(logId: string): void {
    this.$router.push({ name: staticRoutes.repairPlanUpdateDetail.name, params: { logId } })
  }

  goToRepairPlanComments(): void {
    this.$router.push({ name: staticRoutes.repairPlanComments.name })
  }
}
