import { User } from '@/dtos/commons'
import { Type } from 'class-transformer'

export class AdminIdeasOpinionsCommentsPostRequest {
  ideaId!:string
  opinionId!:string
  commentBody!:string
}

export class AdminIdeasOpinionsCommentsPostResponse {
  opinionCommentId!:string
  @Type(() => User)admin: User = new User()
  commentBody!:string
  postedAt!:string
  version!:number
  ideaId!:string
  opinionId!:string
}
