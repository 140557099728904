import { IdeaState } from '@/constants/schema-constants'
import { User, Material } from '@/dtos/commons'
import { Details } from '@/dtos/ideas/admin-idea/commons'
import { Type } from 'class-transformer'

export class AdminIdeaUpdatesGetRequest {
  skipToken?: string
  take!: number
  ideaId!: string
}

export class UpdateGetResponseUpdate {
  updateId!: string
  updateBody!: string
  @Type(() => User)user: User = new User()
  postedAt!: string
  @Type(() => Material) material?: Material
  version!: number
  // 編集用の項目
  isEditMode = false
}

export class AdminIdeaUpdatesGetResponse {
  ideaId!: string
  ideaState!: IdeaState
  postedAt!: string
  title!: string
  @Type(() => Details)details: Details = new Details()
  @Type(() => User)user: User = new User()
  @Type(() => UpdateGetResponseUpdate)updates: UpdateGetResponseUpdate[] = []
  skipToken!: string
  version!: number
}
