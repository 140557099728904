















































































































































































































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { IDEA_STATES } from '@/constants/schema-constants'
import { CHIP_COLORS, ERROR_MESSAGES } from '@/constants/ux-constants'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'

import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { OwnerIdeasDeleteRequest } from '@/dtos/ideas/owner-idea/delete'
import { OwnerIdeaDetailGetResponse } from '@/dtos/ideas/owner-idea/get-detail'
import { AdminIdeaPreparingStatusPostRequest } from '@/dtos/ideas/owner-idea/admin-idea-preparing-status/post'
import { AdminIdeaPreparingStatusPutRequest } from '@/dtos/ideas/owner-idea/admin-idea-preparing-status/put'
import { AdminIdeaPreparingStatusDeleteRequest } from '@/dtos/ideas/owner-idea/admin-idea-preparing-status/delete'
import { OwnerIdeasRejectRequest } from '@/dtos/ideas/owner-idea/reject/put'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { ideasModule } from '@/stores/ideas-store'
import { myProfileModule } from '@/stores/my-profile-store'
import { ownerIdeasModule } from '@/stores/owner-ideas-store'
import { structureModule } from '@/stores/structure-store'
import { generateUuid } from '@/libs/uuid-generator'

const PREPARING_STATUS_AREA_SHOW_MODE = {
  REGISTER: 'register',
  UPDATE: 'update',
  VIEW: 'view',
} as const
type PreparingStatusAreaShowMode = typeof PREPARING_STATUS_AREA_SHOW_MODE[keyof typeof PREPARING_STATUS_AREA_SHOW_MODE]

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmModeSwitchableForm: () => import('@/components/molecules/SmModeSwitchableForm.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    SmAppBar: () => import('@/components/organisms/SmAppBar.vue'),
    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class OwnerIdeaDetailPage extends Mixins(CurrentAdminManager) {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)
  PREPARING_STATUS_AREA_SHOW_MODE = Object.freeze(PREPARING_STATUS_AREA_SHOW_MODE)

  rejectedReason=''
  isDeleteDialogVisible = false
  isRejectDialogVisible = false

  private get isPreview(): boolean { return structureModule.isPreview }

  @Prop({ required: true, default: '' })
  private readonly ideaId!: string

  @Prop()
  private readonly buildingId?: string

  async created():Promise<void> {
    // プレビュー画面から内部リンクで開かれた場合
    if (this.isPreview) {
      if (!this.buildingId) { // クエリで物件IDが渡されなかった場合
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    await Promise.all([
      ideasModule.readIdea({ ideaId: this.ideaId }),
      ownerIdeasModule.fetchIdeaDetail({ ideaId: this.ideaId })
    ])
    this.preparingStatusAreaShowMode = this.idea.adminIdeaPreparingStatus ? PREPARING_STATUS_AREA_SHOW_MODE.VIEW : PREPARING_STATUS_AREA_SHOW_MODE.REGISTER
  }

  private get idea():OwnerIdeaDetailGetResponse { return ownerIdeasModule.detailResponse(this.ideaId) ?? new OwnerIdeaDetailGetResponse() }

  private get hasFollowingIdea(): boolean {
    return Boolean(this.idea.followingIdea?.ideaId)
  }

  private get isPosted(): boolean {
    return this.idea.ideaState === IDEA_STATES.OWNER.POSTED
  }

  private get isAdopted(): boolean {
    return this.idea.ideaState === IDEA_STATES.OWNER.ADOPTED
  }

  private get isRejected(): boolean {
    return this.idea.ideaState === IDEA_STATES.OWNER.REJECTED
  }

  private get isDeleted(): boolean {
    return this.isOwnerDeleted || this.isAdminDeleted
  }

  private get isOwnerDeleted(): boolean {
    return this.idea.ideaState === IDEA_STATES.OWNER.DELETED_SELF
  }

  private get isAdminDeleted(): boolean {
    return this.idea.ideaState === IDEA_STATES.OWNER.DELETED
  }

  private get isEdited(): boolean {
    return this.idea.isEdited
  }

  private get deletedMessage(): string | undefined {
    if (this.isOwnerDeleted) return 'このアイデアは区分所有者により削除されました'
    if (this.isAdminDeleted) return 'このアイデアは管理者業務執行者により削除されました'
  }

  showPreparingStatus(): boolean {
    if (this.$isFrontPerson) {
      if (this.idea.adminIdeaPreparingStatus) return true
    }
    if (this.$isServiceStaff) {
      if (this.isPosted || this.isAdopted || this.isRejected || this.isDeleted) return true
      if (this.idea.adminIdeaPreparingStatus) return true
    }
    return false
  }

  isPreparationEditable(): boolean {
    return this.$isServiceStaff && (this.isPosted || this.isAdopted || this.isRejected || this.isDeleted)
  }

  backToPage(): void {
    this.$router.go(-1)
  }

  async deleteIdea(ideaId: string): Promise<void> {
    const req = new OwnerIdeasDeleteRequest()
    req.ideaId = ideaId
    this.closeDeleteDialog()
    await ownerIdeasModule.deleteIdeas(req)

    this.$router.push({ name: staticRoutes.ideasList.name })
  }

  async rejectIdea(): Promise<void> {
    const req = new OwnerIdeasRejectRequest(this.ideaId, this.idea.version, this.rejectedReason)
    this.closeRejectDialog()
    await ownerIdeasModule.rejectIdeas(req)

    this.$router.push({ name: staticRoutes.ideasList.name })
  }

  openDeleteDialog(): void {
    if (this.isPreview) return
    this.isDeleteDialogVisible = true
  }

  closeDeleteDialog(): void {
    this.isDeleteDialogVisible = false
  }

  openRejectDialog(): void {
    if (this.isPreview) return
    this.isRejectDialogVisible = true
  }

  closeRejectDialog(): void {
    this.isRejectDialogVisible = false
  }

  // プラン準備状況周り
  preparingStatusBody=''
  isPostPreparingStatusDialogVisible = false
  isPutPreparingStatusDialogVisible = false
  isDeletePreparingStatusDialogVisible = false
  preparingStatusAreaShowMode:PreparingStatusAreaShowMode = PREPARING_STATUS_AREA_SHOW_MODE.REGISTER
  // 登録・更新・削除時、SmCommentを再生成させることでステータスを更新する
  preparingStatusKey = generateUuid()

  openPostPreparingStatusDialog(preparingStatusBody:string): void {
    if (this.isPreview) return
    this.preparingStatusBody = preparingStatusBody
    this.isPostPreparingStatusDialogVisible = true
  }

  closePostPreparingStatusDialog(): void {
    this.isPostPreparingStatusDialogVisible = false
  }

  async postPreparingStatus(): Promise<void> {
    const req = new AdminIdeaPreparingStatusPostRequest(this.ideaId, this.preparingStatusBody)
    this.closePostPreparingStatusDialog()
    await ownerIdeasModule.postAdminIdeaPreparingStatus(req)
    this.refreshPreparingStatusForm()
  }

  openPutPreparingStatusDialog(preparingStatusBody:string): void {
    this.preparingStatusBody = preparingStatusBody
    this.isPutPreparingStatusDialogVisible = true
  }

  closePutPreparingStatusDialog(): void {
    this.isPutPreparingStatusDialogVisible = false
  }

  async putPreparingStatus(): Promise<void> {
    const version = this.idea.adminIdeaPreparingStatus?.version
    this.closePutPreparingStatusDialog()
    if (!version) throw new Error() // unexpected
    const req = new AdminIdeaPreparingStatusPutRequest(this.ideaId, this.preparingStatusBody, version)
    await ownerIdeasModule.putAdminIdeaPreparingStatus(req)
    this.preparingStatusAreaShowMode = PREPARING_STATUS_AREA_SHOW_MODE.VIEW
    this.refreshPreparingStatusForm()
  }

  openDeletePreparingStatusDialog(): void {
    if (this.isPreview) return
    this.isDeletePreparingStatusDialogVisible = true
  }

  closeDeletePreparingStatusDialog(): void {
    this.isDeletePreparingStatusDialogVisible = false
  }

  async deletePreparingStatus(): Promise<void> {
    const req = new AdminIdeaPreparingStatusDeleteRequest(this.ideaId)
    this.closeDeletePreparingStatusDialog()
    await ownerIdeasModule.deleteAdminIdeaPreparingStatus(req)
    this.preparingStatusBody = ''
    this.preparingStatusAreaShowMode = PREPARING_STATUS_AREA_SHOW_MODE.REGISTER
    this.refreshPreparingStatusForm()
  }

  refreshPreparingStatusForm(): void {
    this.preparingStatusKey = generateUuid()
  }

  goToPostPage(): void {
    if (this.isPreview) return
    this.$router.push({ name: staticRoutes.adminIdeaCreate.name, query: { baseIdeaId: this.idea.ideaId } })
  }

  goToAdminIdeaDetailPage(): void {
    if (this.isPreview) return
    const ideaId = this.idea.followingIdea?.ideaId ?? ''
    this.$router.push({ name: staticRoutes.adminIdeaDetail.name, params: { ideaId: ideaId } })
  }
}
