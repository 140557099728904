











































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { gmResolutionsModule } from '@/stores/gm-resolutions-store'
import { myProfileModule } from '@/stores/my-profile-store'
import { structureModule } from '@/stores/structure-store'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { getSubjectLabel, getStatementLabel } from '@/libs/type-handler'
import { HOLDING_METHOD_TYPE, RESOLUTION_STATES, STATEMENT_TYPES, SUBJECT_TYPES, VOTE_TYPES } from '@/constants/schema-constants'
import type { StatementType, VoteType } from '@/constants/schema-constants'
import { ERROR_MESSAGES, SUBJECT_ABBR_LENGTH } from '@/constants/ux-constants'
import { Header } from '@/components/molecules/SmTableData.vue'
import { BuildingDetailGetRequest, BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { Statement, StatementsGetResponse, SummaryVote } from '@/dtos/resolutions/gm-resolution/statements/get'
import { StatementsCsvGetRequest } from '@/dtos/resolutions/gm-resolution/statements/get-csv'
import { ResolutionCommonState } from '@/dtos/resolutions/commons'
import { Vote } from '@/dtos/resolutions/gm-resolution/statements/common'
import { DisplayingStatement } from '@/pages/resolutions/gm-resolution/StatementsUpdateTable.vue'

const FIX_SUMMRY_HEADERS: Header[] = [
  new Header({ text: '', value: 'headerRow', width: '90px', sortable: false, class: 'sm__header-row', cellClass: 'sm__header-row' }),
  new Header({ text: '住戸数 ', value: 'roomCount', width: '120px', sortable: false }),
  new Header({ text: '区分所有者総数', value: 'userCount', width: '140px', sortable: false }),
  new Header({ text: '議決権総数', value: 'votingRightCount', width: '120px', sortable: false }),
  new Header({ text: '意思表明数', value: 'statementCount', width: '140px', sortable: false }),
]

const FIX_DETAIL_HEADERS: Header[] = [
  new Header({ text: '', value: 'headerRow', width: '90px', class: 'sm__header-row' }),
  new Header({ text: '住戸番号', value: 'roomNumber', width: '120px' }),
  new Header({ text: '氏名', value: 'userName', width: '144px' }),
  new Header({ text: '保有議決権', value: 'votingRightCount', width: '120px' }),
  new Header({ text: '意思表明', value: 'statement', width: '140px' }),
]

class Item {
  text?: string
  secondText?: string
  constructor(text?:string, secondText?: string) {
    this.text = text
    this.secondText = secondText
  }
}

class SummaryItem {
  headerRow!: Item
  roomCount!: string
  userCount!: string
  votingRightCount!: string
  statementCount!: Item
  vote!: Item[]

  constructor(headerRow: Item, roomCount: string, userCount: string, votingRightCount: string,
    statementCount: Item, vote: Item[]) {
    this.headerRow = headerRow
    this.roomCount = roomCount
    this.userCount = userCount
    this.votingRightCount = votingRightCount
    this.statementCount = statementCount
    this.vote = vote
  }
}

/**
 * 与えられた文字列を省略表示した値（例：【第1号…）を返す
 * @param word 省略表示したい値
 * @returns 省略表示した値（閾値以下の場合はそのまま返す）
 */
export function getAbbr(word:string):string {
  if (word.length <= SUBJECT_ABBR_LENGTH) return word
  return word.slice(0, SUBJECT_ABBR_LENGTH) + '…'
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
  }
})
export default class StatementsSubPage extends Mixins(CurrentAdminManager) {
  STATEMENT_TYPES = Object.freeze(STATEMENT_TYPES)

  @Prop({ required: true })
  private readonly resolutionId!:string

  @Prop()
  private readonly buildingId?: string

  private summaryHeaders:Header[] = []
  private summaryItems: SummaryItem[] = []
  private detailtHeaders:Header[] = []

  // label部分のスペースを確保するために、空文字の力を借りる
  private emptyDummyString = '　'

  async created():Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    await gmResolutionsModule.fetchStatements(this.resolutionId)
    // 各テーブルの設定
    this.makeSummary()
    this.makeDetail()
  }

  private get isPreview(): boolean { return structureModule.isPreview }

  private get res():StatementsGetResponse {
    return gmResolutionsModule.statementsGet(this.resolutionId) ?? new StatementsGetResponse()
  }

  // サーバから取得したstatementsにisPresentを付与して意思表明内訳のSmTableDataに渡すデータに変換する
  private get detailItems(): DisplayingStatement[] {
    const newStatements = deepCopy(
      this.res.statements,
      {
        statements: new ColumnToType(Statement),
        vote: new ColumnToType(Vote),
      },
      'statements'
    )

    return newStatements.map(statement =>
      new DisplayingStatement(
        statement.userId,
        statement.roomNumber,
        statement.userName,
        statement.votingRightCount,
        statement.statementType,
        statement.isStatement,
        statement.vote,
      )
    )
  }

  private get countStatementsLocal() : number|string {
    if (this.res.holdingMethodType === HOLDING_METHOD_TYPE.LOCAL || this.res.holdingMethodType === HOLDING_METHOD_TYPE.BOTH) return this.res.statements.filter(totalStatements => totalStatements.statementType === STATEMENT_TYPES.PRESENT_LOCAL).length
    return 'ー'
  }

  private get countStatementsRemote() : number|string {
    if (this.res.holdingMethodType === HOLDING_METHOD_TYPE.REMOTE || this.res.holdingMethodType === HOLDING_METHOD_TYPE.BOTH) return this.res.statements.filter(totalStatements => totalStatements.statementType === STATEMENT_TYPES.PRESENT_REMOTE).length
    return 'ー'
  }

  private get common(): ResolutionCommonState {
    return gmResolutionsModule.gmResolutionCommonStateGet
  }

  private get isPutStatement(): boolean {
    return (this.common.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL ||
    this.common.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT ||
    this.common.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.COUNTING) &&
    (this.$isServiceStaff || this.$isFrontPerson)
  }

  private get summaryVote(): SummaryVote[] {
    return this.res.summary.statement.details.vote
  }

  get building(): BuildingDetailGetResponse | null {
    return buildingsModule.buildingDetailGet
  }

  get isBuildingOutOfService(): boolean {
    return this.building ? this.building.isOutOfService : false
  }

  private makeSummary():void {
    const headers: Header[] = [...FIX_SUMMRY_HEADERS]
    this.summaryVote.forEach((v, i) => headers.push(new Header({ text: getAbbr(v.subjectTitle), value: `vote[${i}].voteType`, width: '150px', sortable: false })))
    this.summaryHeaders = headers
    // 決議内訳の各行に表示する内容を個別に設定
    //   ヘッダ列（Item）, 住戸数, 区分所有者総数, 議決権総数,
    //   意思表明数（Item）,
    //   議案別（それぞれがItem）
    this.summaryItems.push(
      new SummaryItem(
        new Item(), `${this.res.summary.buildingUnitCount}戸`, `${this.res.summary.ownerCount}名`, `${this.res.summary.votingRightCount}`,
        new Item(),
        this.res.summary.statement.details.vote.map(v => new Item(getSubjectLabel(v.subjectType)))
      )
    )
    if (this.res.holdingMethodType === HOLDING_METHOD_TYPE.LOCAL || this.res.holdingMethodType === HOLDING_METHOD_TYPE.BOTH) {
      this.summaryItems.push(
        new SummaryItem(
          new Item('議決権数', '(会場参加)'), '', '', '',
          new Item(`${this.res.summary.statement.presentLocal?.votingRightCount ?? 0}`),
          this.res.summary.statement.details.vote
            .map(() => new Item('会場で採決'))
        )
      )
    }
    if (this.res.holdingMethodType === HOLDING_METHOD_TYPE.REMOTE || this.res.holdingMethodType === HOLDING_METHOD_TYPE.BOTH) {
      this.summaryItems.push(
        new SummaryItem(
          new Item('議決権数', '(リモート参加)'), '', '', '',
          new Item(`${this.res.summary.statement.presentRemote?.votingRightCount ?? 0}`),
          this.res.summary.statement.details.vote.map(v => new Item(`賛成 ${v.agreements.presentRemote?.votingRightCount ?? 0}`)
          )
        )
      )
    }
    this.summaryItems.push(
      new SummaryItem(
        new Item('議決権数', '(議決権行使)'), '', '', '',
        new Item(`${this.res.summary.statement.exerciseVote.votingRightCount}`),
        this.res.summary.statement.details.vote.map(v => new Item(`賛成 ${v.agreements.exerciseVote.votingRightCount}`)
        )
      )
    )
    this.summaryItems.push(
      new SummaryItem(
        new Item('議決権数', '合計'), '', '', '',
        new Item(`${this.res.summary.statement.votingRightCount}`),
        this.res.summary.statement.details.vote
          .map(v => v.subjectType === SUBJECT_TYPES.SPECIAL
            ? new Item(`賛成 ${v.agreements.votingRightCount}/${v.agreements.totalVotingRightCount}`,
                 `(${v.agreements.votingRightRate}%)`)
            : new Item(`賛成 ${v.agreements.votingRightCount}/${this.res.summary.statement.votingRightCount}`,
               `(${v.agreements.votingRightRate}%)`)
          )
      )
    )
    if (this.res.holdingMethodType === HOLDING_METHOD_TYPE.LOCAL || this.res.holdingMethodType === HOLDING_METHOD_TYPE.BOTH) {
      this.summaryItems.push(
        new SummaryItem(
          new Item('区分所有者数', '(会場参加)'), '', '', '',
          new Item(`${this.res.summary.statement.presentLocal?.ownerCount}名`),
          this.res.summary.statement.details.vote
            .map(v => v.subjectType === SUBJECT_TYPES.SPECIAL
              ? new Item('会場で採決')
              : new Item())
        )
      )
    }
    if (this.res.holdingMethodType === HOLDING_METHOD_TYPE.REMOTE || this.res.holdingMethodType === HOLDING_METHOD_TYPE.BOTH) {
      this.summaryItems.push(
        new SummaryItem(
          new Item('区分所有者数', '(リモート参加)'), '', '', '',
          new Item(`${this.res.summary.statement.presentRemote?.ownerCount}名`),
          this.res.summary.statement.details.vote
            .map(v => v.subjectType === SUBJECT_TYPES.SPECIAL
              ? new Item(`賛成 ${v.agreements.presentRemote?.ownerCount ?? 0}名`)
              : new Item())
        )
      )
    }
    this.summaryItems.push(
      new SummaryItem(
        new Item('区分所有者数', '(議決権行使)'), '', '', '',
        new Item(`${this.res.summary.statement.exerciseVote.ownerCount ?? 0}名`),
        this.res.summary.statement.details.vote
          .map(v => v.subjectType === SUBJECT_TYPES.SPECIAL
            ? new Item(`賛成 ${v.agreements.exerciseVote.ownerCount}名`)
            : new Item())
      )
    )
    this.summaryItems.push(
      new SummaryItem(
        new Item('区分所有者数', '合計'), '', '', '',
        new Item(`${this.res.summary.statement.ownerCount}名`),
        this.res.summary.statement.details.vote
          .map(v => v.subjectType === SUBJECT_TYPES.SPECIAL
            ? new Item(`賛成 ${v.agreements.ownerCount}/${v.agreements.totalOwnerCount}名`,
          `(${v.agreements.ownerRate}%)`)
            : new Item())
      )
    )
  }

  private makeDetail():void {
    const headers: Header[] = [...FIX_DETAIL_HEADERS]
    this.summaryVote.forEach((e, i) => headers.push(new Header({ text: getAbbr(e.subjectTitle), value: `vote[${i}].voteType`, width: '150px', sortable: false })))
    this.detailtHeaders = headers
  }

  private clickPutStatements():void {
    if (this.isPreview) return
    this.$router.push({ name: staticRoutes.statementEdit.name, params: { resolutionId: this.resolutionId } })
  }

  private getStatementLabel(statementType: StatementType): string {
    return getStatementLabel(statementType)
  }

  private getIsPresent(statementType: StatementType): string {
    if (statementType === STATEMENT_TYPES.PRESENT_LOCAL || statementType === STATEMENT_TYPES.PRESENT_REMOTE) return getStatementLabel(statementType)
    return ''
  }

  private getVoteLabel(voteType: VoteType): string {
    if (voteType === VOTE_TYPES.FOR) return '○'
    if (voteType === VOTE_TYPES.AGAINST) return '×'
    return ''
  }

  private async clickDownloadCSV():Promise<void> {
    if (this.isPreview) return
    await gmResolutionsModule.downloadStatementCsv(new StatementsCsvGetRequest(this.resolutionId))
  }

  private getCellStyles(text: string) {
    if (text === '区分所有者数') {
      return 'background-color: #F2F2F2;'
    }
  }
}
