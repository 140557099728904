import { Type } from 'class-transformer'
import type { TicketState, TicketType } from '@/constants/schema-constants'

export class TicketsSearchPostRequest {
  ticketStates?: TicketState[] = []
  ticketTypes?: TicketType[] = []
  buildings?: string[] = []
  owners?: string[] = []
  fromDate?: string | null = null
  toDate?: string | null = null
  admins?: string[] = []
  keyword?: string | null = null
  sortOrder?: string
  skip: number
  take: number

  constructor(skip: number, take: number) {
    this.skip = skip
    this.take = take
  }
}

export class Ticket {
  ticketId!: string
  ticketNo!: string
  ticketName!: string
  ticketType!: TicketType
  ticketState!: TicketState
  postedAt!: string
  completedTaskCount!: number
  allTaskCount!: number
  buildingId?: string
  buildingName?: string
  adminName?: string
  ownerName?: string
}

export class TicketsSearchPostResponse {
  @Type(() => Ticket) tickets: Ticket[] = []
  ticketCount!: number
}

export class ListResponseTicketDto {
  ticketId!: string
  ticketNo!: string
  ticketName!: string
  ticketType!: number
  ticketState!: number
  postedAt!: string
  completedTaskCount!: number
  allTaskCount!: number
  buildingId:string | null | undefined = null
  buildingName:string | null | undefined = null
  adminName:string | null | undefined = null
  ownerName:string | null | undefined = null
}

export class TasksSearchPostResponse {
  skipToken!: string
  ticketCount!: number
  tickets:ListResponseTicketDto[] = []
}
