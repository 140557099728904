


















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmLegend extends Vue {
  @Prop({ default: 'sm__form-title' })
  private readonly format!:string

  @Prop({ default: false })
  private readonly line!:boolean

  @Prop()
  private readonly borderDash?:number[]

  @Prop({ required: true, default: '' })
  private readonly label!:string

  @Prop({ required: true, default: 'black' })
  private readonly symbolColor!:string

  private readonly LINE_HEIGHT = 1
  private readonly DASH_LINE_HEIGHT = 2
  private readonly REC_HEIGHT = 12
  private readonly SYMBOL_WIDTH = 12

  private get _height():number {
    if (this.borderDash) { return this.DASH_LINE_HEIGHT }
    if (this.line) { return this.LINE_HEIGHT }

    return this.REC_HEIGHT
  }

  private get legendStyle():string {
    let style = `
      display:inline-block;
      height: ${this._height}px;
      width: ${this.SYMBOL_WIDTH}px;
      margin-top: 4.5px;
      `
    if (this.borderDash) {
      style = style +
      `background-image: linear-gradient(to right, ${this.symbolColor}, ${this.symbolColor} ${this.borderDash[0]}px, transparent ${this.borderDash[0]}px, transparent ${this.borderDash[0] + this.borderDash[1]}px);
      background-size: ${this.borderDash[0] + this.borderDash[1]}px ${this._height}px;
      background-repeat: repeat-x;`
    } else {
      style = style + `background-color: ${this.symbolColor};`
    }

    return style
  }
}
