











































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'

import { buildingsModule } from '@/stores/buildings-store'
import { simpleRepairsModule } from '@/stores/simple-repairs-store'

import { BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { SimpleRepairsPerformancesPostRequest } from '@/dtos/simple-repairs/performances/post'
import { SimpleRepairsPerformancesDeleteRequest } from '@/dtos/simple-repairs/performances/delete'
import { RegisteredPerformance, SimpleRepairsPlansDetailGetResponse } from '@/dtos/simple-repairs/plans/get'
import { SimpleRepairPlanUpdatesLatestCommentResponse } from '@/dtos/simple-repairs/updates/comments/get-latest'

import { generateUuid } from '@/libs/uuid-generator'

import { Tab } from '@/components/molecules/SmTabs.vue'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmTabs: () => import('@/components/molecules/SmTabs.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    SmDialogSimpleRepairPlanReset: () => import('@/pages/simple-repair-plan/SmDialogSimpleRepairPlanReset.vue'),
    SmDialogSimpleRepairPlanPerformanceRegister: () => import('@/pages/simple-repair-plan/SmDialogSimpleRepairPlanPerformanceRegister.vue')
  }
})
export default class SimpleRepairPlanDetailPage extends Mixins(CurrentAdminManager) {
  @Prop()
  needsGuideDialog?: string

  async created(): Promise<void> {
    await Promise.all([
      simpleRepairsModule.fetchLatestComment(),
      this.refreshPage(),
    ])
  }

  async refreshPage(): Promise<void> {
    await simpleRepairsModule.fetchSimpleRepairsDetail()
    if (this.simpleRepairsDetail.incomeExpensePlan.repairPlan.originals.length === 0) { this.$router.replace({ name: staticRoutes.simpleRepairPlanRegister.name }) }
  }

  tabs = [
    new Tab('収支計画', staticRoutes.simpleRepairPlanDetail.getChild('summary').name),
    new Tab('工事計画', staticRoutes.simpleRepairPlanDetail.getChild('construction').name),
  ]

  private get latestComment(): SimpleRepairPlanUpdatesLatestCommentResponse { return simpleRepairsModule.latestComment }
  private get buildingDetail(): BuildingDetailGetResponse | null { return buildingsModule.buildingDetailGet }
  private get simpleRepairsDetail(): SimpleRepairsPlansDetailGetResponse { return simpleRepairsModule.simpleRepairsDetailGet }
  private get registeredPerformances(): RegisteredPerformance[] {
    if (!this.simpleRepairsDetail.incomeExpensePlan || !this.simpleRepairsDetail.incomeExpensePlan.registeredPerformances) return []
    return this.simpleRepairsDetail.incomeExpensePlan.registeredPerformances
  }

  private get getPeriods(): number[] {
    return this.simpleRepairsDetail.incomeExpensePlan.repairPlan.originals.map(p => p.period) ?? []
  }

  private get getRegisteredPeriods(): number[] {
    return this.simpleRepairsDetail.incomeExpensePlan.registeredPerformances.map(p => p.period) ?? []
  }

  isPerformanceRegisterDialog = false
  isRepairPlanReconfigureDialog = false
  isPerformanceRegisterConfirmationDialog = false
  isPerformanceDeleteConfirmationDialog = false

  goToSimpleRepairPlanComments(): void {
    this.$router.push({ name: staticRoutes.simpleRepairPlanComments.name })
  }

  goToSimpleRepairPlanCommentsCreate(): void {
    this.$router.push({ name: staticRoutes.simpleRepairPlanCommentsCreate.name })
  }

  selectedPeriod = 0
  repairCostPerformance = 0
  reserveFundPerformance = 0

  simpleRepairPlanPerformanceRegisterModalModalKey = generateUuid()
  openSimpleRepairPlanPerformanceRegisterModal(): void {
    this.simpleRepairPlanPerformanceRegisterModalModalKey = generateUuid()
    this.isPerformanceRegisterDialog = true
  }

  openPerformanceRegisterConfirmationDialog(period: number, repairCostPerformance: number, reserveFundPerformance: number): void {
    this.selectedPeriod = period
    this.repairCostPerformance = repairCostPerformance
    this.reserveFundPerformance = reserveFundPerformance

    this.isPerformanceRegisterDialog = false
    this.isPerformanceRegisterConfirmationDialog = true
  }

  closePerformanceRegisterConfirmationDialog(): void {
    this.selectedPeriod = 0
    this.repairCostPerformance = 0
    this.reserveFundPerformance = 0

    this.isPerformanceRegisterConfirmationDialog = false
  }

  openPerformanceDeleteConfirmationDialog(period: number): void {
    this.selectedPeriod = period

    this.isPerformanceRegisterDialog = false
    this.isPerformanceDeleteConfirmationDialog = true
  }

  closePerformanceDeleteConfirmationDialog(): void {
    this.selectedPeriod = 0

    this.isPerformanceDeleteConfirmationDialog = false
  }

  async registerPerformance(): Promise<void> {
    const req = new SimpleRepairsPerformancesPostRequest(this.selectedPeriod, this.repairCostPerformance, this.reserveFundPerformance)

    this.isPerformanceRegisterConfirmationDialog = false
    await simpleRepairsModule.postPerformances(req)
    await this.refreshPage()
  }

  async deletePerformance(): Promise<void> {
    const req = new SimpleRepairsPerformancesDeleteRequest(this.selectedPeriod)

    this.isPerformanceDeleteConfirmationDialog = false
    await simpleRepairsModule.deletePerformances(req)
    await this.refreshPage()
  }
}
