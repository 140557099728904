import { PostTimingType } from '@/constants/schema-constants'
import { Type } from 'class-transformer'
import { Material } from '@/dtos/commons'
import { QuestionnaireSectionBase } from '@/dtos/questionnaires/commons'

export class QuestionnairePutRequest {
  questionnaireId!:string
  templateId!: string
  questionnaireTitle!: string
  explanation!: string
  postTimingType!:PostTimingType
  postedAt?: string
  deadline!: string
  @Type(() => Material)material?: Material
  @Type(() => QuestionnaireSectionBase)sections: QuestionnaireSectionBase[] = []
  version?: number
  constructor(templateId: string, questionnaireId: string) {
    this.templateId = templateId
    this.questionnaireId = questionnaireId
  }
}
