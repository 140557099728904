



























































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { Header } from '@/components/molecules/SmTableData.vue'
import { NEW_TAB_TRANSITION_TO } from '@/constants/schema-constants'
import { openNewTab } from '@/libs/open-new-tab'
import { windowOpen } from '@/libs/window-open'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'

import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { UnitOwnerSimulateTokenGetRequest } from '@/dtos/units/simulate-token/get'
import { UsersGetRequest, UsersGetResponseUser } from '@/dtos/users/get'

import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { myProfileModule } from '@/stores/my-profile-store'
import { NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { unitsModule } from '@/stores/units-store'
import { usersModule } from '@/stores/users-store'

export const OWNER_SIMULATE_LOGIN_COMPLETION_URL = 'simulate-login/completion'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmTableDataExternalPaging: () => import('@/components/molecules/SmTableDataExternalPaging.vue'),

    SmDialogSimulateConfirmation: () => import('@/components/organisms/dialog/SmDialogSimulateConfirmation.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})

export default class UsersSearchPage extends Mixins(CurrentAdminManager) {
  tableHeaders:Header[] = [
    new Header({ text: 'MaNo.', value: 'condominiumCd', width: '100px' }),
    new Header({ text: 'マンション名', value: 'buildingName', width: '240px' }),
    new Header({ text: '住戸番号', value: 'roomNumber', width: '120px' }),
    new Header({ text: '区分所有者', value: 'userName', width: '126px' }),
    new Header({ text: 'メールアドレス', value: 'emailAddress', width: '230px' }),
    new Header({ text: '', value: 'isInitialAuth', width: '160px', sortable: false }),
  ]

  customSorts = {
    condominiumCd: {
      sortMethod: (a: UsersGetResponseUser, b: UsersGetResponseUser): number => {
        const aCondominiumCd = a.condominiumCd ?? ''
        const bCondominiumCd = b.condominiumCd ?? ''
        if (a.condominiumCd === b.condominiumCd) {
          // 物件コードが同じだった場合、住戸番号で並び替えをしています。
          const aRoomNumber = a.roomNumber ?? ''
          const bRoomNumber = b.roomNumber ?? ''

          if (aRoomNumber === bRoomNumber) return 0
          return aRoomNumber > bRoomNumber ? 1 : -1
        } else {
          return aCondominiumCd > bCondominiumCd ? 1 : -1
        }
      }
    },

    buildingName: {
      sortMethod: (a: UsersGetResponseUser, b: UsersGetResponseUser): number => {
        const aBuildingName = a.buildingName ?? ''
        const bBuildingName = b.buildingName ?? ''
        if (aBuildingName === bBuildingName) {
          // 物件名が同じだった場合、住戸番号で並び替えをしています。
          const aRoomNumber = a.roomNumber ?? ''
          const bRoomNumber = b.roomNumber ?? ''

          if (aRoomNumber === bRoomNumber) return 0
          return aRoomNumber > bRoomNumber ? 1 : -1
        } else {
          return aBuildingName > bBuildingName ? 1 : -1
        }
      }

    }
  }

  private get users():UsersGetResponseUser[] { return usersModule.usersGet.users }
  private get userCount():number { return usersModule.usersGet.userCount ?? 0 }

  private get isInitSearch(): boolean {
    if (this.$isFrontPerson) { return !this.searchingKeyword && this.isStaff === true }
    return !this.searchingKeyword
  }

  take = 50
  inputKeyword = ''
  searchingKeyword = ''
  isStaff = false

  pageNum = 1

  @Watch('pageNum', { immediate: false })
  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  async created():Promise<void> {
    // 成り代わり機能を使用できる管理者のみ、「利用中画面を見る」ボタンを表示させる
    if (this.$isServiceStaff || this.$isFrontPerson || this.$isGroupCallCenter) { this.tableHeaders[4].align = 'start' }
    // 「担当物件のみ」が利用できるユーザーの場合、初期値はtrue（担当物件のみ表示）とする
    if (this.$isFrontPerson) this.isStaff = true
    await this.fetchUsers()
  }

  async fetchUsers(): Promise<void> {
    const skip = this.take * (this.pageNum - 1)
    await usersModule.fetchUsers(new UsersGetRequest(skip, this.take, this.isStaff, this.searchingKeyword))
  }

  async onClickSearchBtn():Promise<void> {
    // 検索ボタン押下時のみ、入力された検索キーワードをクエリに反映する
    this.searchingKeyword = this.inputKeyword
    await this.doFetchUsers()
  }

  async onClickIsStaffSwitch(): Promise<void> {
    await this.doFetchUsers()
  }

  async onClickPageBtn(pageNum: number): Promise<void> {
    this.pageNum = pageNum
    await this.fetchUsers()
  }

  async doFetchUsers():Promise<void> {
    this.pageNum = 1
    await this.fetchUsers()
  }

  goBuildingDetail(buildingId : string): void {
    this.openBuildingDetailPage(buildingId)
  }

  goUserDetail(buildingId: string, unitId: string): void {
    this.openOwnerUserDetailPage(buildingId, unitId)
  }

  openBuildingDetailPage(buildingId: string): void {
    // 該当のマンション詳細画面を別タブで開く
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId
    openNewTab(newTabTransitionParams)
  }

  openOwnerUserDetailPage(buildingId: string, unitId: string): void {
    // 該当の区分所有者詳細画面を別タブで開く
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.OWNER_DETAIL
    newTabTransitionParams.buildingId = buildingId
    newTabTransitionParams.unitId = unitId
    openNewTab(newTabTransitionParams)
  }

  async setCurrentBuilding(buildingId: string): Promise<void> {
    const req = new BuildingDetailGetRequest(buildingId)
    await buildingsModule.fetchBuildingDetail(req)
    currentStateModule.setCurrentBuilding(buildingId)
  }

  unitId = ''
  isSimulateConfirmationDialogVisible = false

  openSimulateConfirmationDialog(unitId: string): void {
    this.unitId = unitId
    this.isSimulateConfirmationDialogVisible = true
  }

  async startSimulate(): Promise<void> {
    this.isSimulateConfirmationDialogVisible = false
    const req = new UnitOwnerSimulateTokenGetRequest(this.unitId)
    await unitsModule.fetchSimulateToken(req)

    const adminId = myProfileModule.myProfileGet?.user.userId
    const token = unitsModule.simulateTokenGet.oneTimeToken

    windowOpen(`${process.env.VUE_APP_OWNER_FRONT_BASE_URL}${OWNER_SIMULATE_LOGIN_COMPLETION_URL}?adminId=${adminId}&oneTimeToken=${token}`)
  }

  goToCasysResultsListPage(): void {
    // CASYS差分確認画面へ遷移する
    this.$router.push({ name: staticRoutes.casysResultsListPage.name })
  }
}
