





















































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})

export default class SmCardSectionFrame extends Vue {
  @Prop({ required: true, default: '' })
  private readonly cardTitle!: string

  @Prop({ required: true, default: false })
  private readonly head!: boolean

  @Prop({ required: true, default: false })
  private readonly foot!: boolean

  @Prop({ required: true, default: false })
  private readonly deletable!: boolean
}
