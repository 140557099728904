import { AdminRole } from '@/constants/schema-constants'
import { User } from '@/dtos/commons'

import { Type } from 'class-transformer'

export class MyProfileGetResponse {
  @Type(() => User)user = new User()
  emailAddress?: string
  role!: AdminRole
  buildings!: string[]
}
