import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { onlineResolutionsClient } from '@/clients/online-resolutions-client'
import { ResolutionCommonState } from '@/dtos/resolutions/commons'
import { OnlineResolutionDetailGetRequest, OnlineResolutionDetailGetResponse } from '@/dtos/resolutions/online-resolution/get-detail'
import { OnlineResolutionsPostRequest } from '@/dtos/resolutions/online-resolution/post'
import { OnlineResolutionsDraftPostRequest } from '@/dtos/resolutions/online-resolution/draft/post'
import { OnlineResolutionsDraftDeleteRequest } from '@/dtos/resolutions/online-resolution/draft/delete'
import { OnlineResolutionsPutRequest } from '@/dtos/resolutions/online-resolution/put'
import { OnlineResolutionsDeleteRequest } from '@/dtos/resolutions/online-resolution/delete'
import { OnlineResultsGetResponse } from '@/dtos/resolutions/online-resolution/results/get'
import { OnlineResultsPostRequest } from '@/dtos/resolutions/online-resolution/results/post'
import { OnlineResultsPutRequest } from '@/dtos/resolutions/online-resolution/results/put'
import { VotesGetRequest, VotesGetResponse } from '@/dtos/resolutions/online-resolution/votes/get'
import { VotesPostRequest } from '@/dtos/resolutions/online-resolution/votes/post'

@Module({ store, dynamic: true, namespaced: true, name: 'onlineResolutionsStore' })
class onlineResolutionsStore extends VuexModule {
  private _onlineResolutionCommonStateGet = new ResolutionCommonState()

  get onlineResolutionCommonStateGet(): ResolutionCommonState {
    return this._onlineResolutionCommonStateGet
  }

  @Mutation
  private SET_COMMON_STATE_GET(state: ResolutionCommonState): void {
    this._onlineResolutionCommonStateGet.resolutionId = state.resolutionId
    this._onlineResolutionCommonStateGet.resolutionState = state.resolutionState
    this._onlineResolutionCommonStateGet.version = state.version
    this._onlineResolutionCommonStateGet.title = state.title
    this._onlineResolutionCommonStateGet.details = state.details
    this._onlineResolutionCommonStateGet.user = state.user
  }

  @Action
  setOnlineResolutionCommonState(state: ResolutionCommonState): void {
    this.SET_COMMON_STATE_GET(state)
  }

  // オンライン決議詳細
  private _onlineDetails: Record<string, OnlineResolutionDetailGetResponse> = {}

  get detailResponse(): (id: string) => OnlineResolutionDetailGetResponse | undefined { return (id: string) => this._onlineDetails[id] }

  @Mutation
  private SET_RESOLUTION_DETAIL_GET(res: OnlineResolutionDetailGetResponse): void {
    Vue.set(this._onlineDetails, res.resolutionId, res)
  }

  @Action
  async fetchResolutionDetail(req: OnlineResolutionDetailGetRequest): Promise<void> {
    const res = await onlineResolutionsClient.getResolutionDetail(req)
    this.setOnlineResolutionCommonState({
      resolutionId: res.resolutionId,
      resolutionState: res.resolutionState,
      version: res.version,
      title: res.title,
      details: res.details,
      user: res.user
    })
    this.SET_RESOLUTION_DETAIL_GET(res)
  }

  @Action
  async postResolutions(req: OnlineResolutionsPostRequest): Promise<void> {
    await onlineResolutionsClient.postResolutions(req)
  }

  @Action
  async postDraftResolutions(req: OnlineResolutionsDraftPostRequest): Promise<void> {
    await onlineResolutionsClient.postDraftResolutions(req)
  }

  @Action
  async putResolutions(req: OnlineResolutionsPutRequest): Promise<void> {
    await onlineResolutionsClient.putResolutions(req)
  }

  @Action
  async deleteResolutions(req: OnlineResolutionsDeleteRequest): Promise<void> {
    await onlineResolutionsClient.deleteResolutions(req)
  }

  @Action
  async deleteResolutionsDraft(req: OnlineResolutionsDraftDeleteRequest): Promise<void> {
    await onlineResolutionsClient.deleteDraftResolution(req)
  }

  // 決議結果 取得
  private _resultsGet: OnlineResultsGetResponse = new OnlineResultsGetResponse()

  get resultsGet(): OnlineResultsGetResponse {
    return this._resultsGet
  }

  @Mutation
  private SET_RESULTS(res:OnlineResultsGetResponse) {
    this._resultsGet = res
  }

  @Action
  async fetchResults(resolutionId:string): Promise<void> {
    const res = await onlineResolutionsClient.getResults(resolutionId)
    this.setOnlineResolutionCommonState({
      resolutionId: res.resolutionId,
      resolutionState: res.resolutionState,
      version: res.version,
      title: res.title,
      details: res.details,
      user: res.user
    })
    this.SET_RESULTS(res)
  }

  // 決議結果 登録
  @Action
  async postResults(req: OnlineResultsPostRequest) {
    await onlineResolutionsClient.postResults(req)
  }

  // 決議結果 登録
  @Action
  async putResults(req: OnlineResultsPutRequest) {
    await onlineResolutionsClient.putResults(req)
  }

  // 投票一覧取得
  private _votes = new VotesGetResponse()

  public get votesGet():VotesGetResponse { return this._votes }

  @Mutation
  private SET_VOTES(res:VotesGetResponse) { this._votes = res }

  @Action
  public async fetchVotes(req:VotesGetRequest):Promise<void> {
    const res = await onlineResolutionsClient.getVotes(req)
    this.SET_VOTES(res)
  }

  // 代理投票
  @Action
  public async postVotes(req:VotesPostRequest):Promise<void> {
    await onlineResolutionsClient.postVotes(req)
  }
}

export const onlineResolutionsModule = getModule(onlineResolutionsStore)
