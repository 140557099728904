import { Type } from 'class-transformer'
import { IdeaState } from '@/constants/schema-constants'
import { User, Material } from '@/dtos/commons'

export class OwnerIdeaDetailGetRequest {
  ideaId!: string
}

export class FollowingIdea {
  ideaId!: string
  title!: string
}

export class AdminIdeaPreparingStatus {
  @Type(() => User)admin!: User
  preparingStatusBody!: string
  version!: string
  postedAt!: string
}

export class OwnerIdeaDetailGetResponse {
  ideaId!: string
  ideaState!: IdeaState
  isEdited!: boolean
  postedAt!: string
  rejectedAt?: string
  deletedAt?: string
  title!: string
  background!: string
  @Type(() => Material) material?: Material
  rejectedReason?: string
  @Type(() => FollowingIdea) followingIdea?: FollowingIdea
  @Type(() => AdminIdeaPreparingStatus) adminIdeaPreparingStatus?: AdminIdeaPreparingStatus
  @Type(() => User) ownerUser = new User()
  version!: number
}
