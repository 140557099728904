import { Type } from 'class-transformer'
import type { ResolutionState, ResolutionType, TaskInquirySourceType, TicketTaskNameType, TicketTaskState, TicketTaskType, TicketType } from '@/constants/schema-constants'

export class Period {
  fromDate: string | null | undefined = null
  toDate: string | null | undefined = null
}
export class TasksSearchPostRequest {
  ticketId?: string | null | undefined = null
  ticketTaskStates?: TicketTaskState[] | null | undefined = null
  ticketTypes?: TicketType[] | null | undefined = null
  ticketTaskTypes?: TicketTaskType[] | null | undefined = null
  buildings?: string[] | null | undefined = null
  staffs?: string[] | null | undefined = null
  @Type(() => Period) postedDate: Period | null | undefined = null
  @Type(() => Period) deadline: Period | null | undefined = null
  keyword?: string | null | undefined = null
  sortItem?: string | null | undefined = null
  skipToken?: string
  take: number
  constructor(take: number) { this.take = take }
}

export class Ticket {
  ticketId!: string
  ticketNo!: string
  ticketName!: string
  ticketType!: TicketType
  adminName: string | null | undefined = null
  adminShortName: string | null | undefined = null
  relationIdeaTicketCount?: number
}

export class TaskBuilding {
  buildingId!: string
  buildingName!: string
}

export class TaskInquirySource {
  inquirySourceTitle!: string
  inquirySourceType!: TaskInquirySourceType
  inquirySourceId!: string
}

export class TaskResolution {
  resolutionType!: ResolutionType
  resolutionState!: ResolutionState
  resolutionId!: string
}

export class ListResponseTaskDto {
  ticketTaskId!: string
  ticketTaskType!: TicketTaskType
  ticketTaskState!: TicketTaskState
  ticketTaskNameType!: TicketTaskNameType
  ticketTaskName!: string
  deadline!: string
  deadlineDate!: string
  postedAt!: string
  completedAt?: string
  isExpired!: boolean
  version!: number
  @Type(() => Ticket) ticket!: Ticket
  @Type(() => TaskInquirySource) inquirySources?: TaskInquirySource[]
  @Type(() => TaskBuilding) building?: TaskBuilding
  @Type(() => TaskResolution) resolution?: TaskResolution

  // 編集用の項目
  isEditMode = false
}

export class TasksSearchPostResponse {
  @Type(() => ListResponseTaskDto) tasks!: ListResponseTaskDto[]
  skipToken!: string
}
