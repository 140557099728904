









































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { QUESTION_STATES, RESOLUTION_TYPES } from '@/constants/schema-constants'
import type { ResolutionType } from '@/constants/schema-constants'
import { ERROR_MESSAGES } from '@/constants/ux-constants'
import { deepCopy, ColumnToType } from '@/libs/deep-copy-provider'
import { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { myProfileModule } from '@/stores/my-profile-store'
import { resolutionsModule, QuestionForRequest } from '@/stores/resolutions-store'
import { gmResolutionsModule } from '@/stores/gm-resolutions-store'
import { onlineResolutionsModule } from '@/stores/online-resolutions-store'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { User } from '@/dtos/commons'
import { Details, ResolutionCommonState } from '@/dtos/resolutions/commons'
import { QuestionsGetRequest, QuestionsGetResponse, DisplayingQuestion, Answer } from '@/dtos/resolutions/questions/get'

const TAKE = 20

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),

    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmListQA: () => import('@/components/molecules/list/SmListQA.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
  }
})
export default class QuestionsSubPage extends Mixins(CurrentAdminManager) {
  @Prop({ required: true })
  private readonly resolutionId!:string

  @Prop({ required: true })
  private readonly resolutionType!: ResolutionType

  @Prop({ default: false })
  private readonly isPreview!: boolean

  @Prop()
  private readonly buildingId?: string

  questions: DisplayingQuestion[] = []
  skipToken: string | undefined = undefined

  async created(): Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    this.req.resolutionId = this.resolutionId
    this.req.take = TAKE
    this.refreshList()
  }

  private get res(): QuestionsGetResponse { return resolutionsModule.questionsGet(this.resolutionId) ?? new QuestionsGetResponse() }

  async fetchQuestions(): Promise<void> {
    await resolutionsModule.fetchQuestions(this.req)
    this.updateCommonState()

    const copiedQuestions = deepCopy(
      this.res.questions,
      {
        questions: new ColumnToType(DisplayingQuestion),
        ownerUser: new ColumnToType(User),
        user: new ColumnToType(User),
        answer: new ColumnToType(Answer),
      },
      'questions'
    )

    this.skipToken = this.res.skipToken
    this.questions.push(...copiedQuestions)
  }

  updateCommonState(): void {
    const commonState = new ResolutionCommonState()

    commonState.resolutionId = this.res.resolutionId
    commonState.resolutionState = this.res.resolutionState
    commonState.details = this.res.details ?? new Details()
    commonState.title = this.res.title ?? ''
    commonState.user = this.res.user

    if (this.resolutionType === RESOLUTION_TYPES.GENERAL_MEETING) {
      gmResolutionsModule.setGMResolutionCommonState(commonState)
    } else if (this.resolutionType === RESOLUTION_TYPES.ONLINE) {
      onlineResolutionsModule.setOnlineResolutionCommonState(commonState)
    }
  }

  identifier = 1
  req = new QuestionsGetRequest()
  isWaitingSwitch = false
  private async loadQuestions(handler: LoadingHandler): Promise<void> {
    this.isWaitingSwitch = true

    this.req.skipToken = this.skipToken
    const questionsSkip = this.questions.length
    await this.fetchQuestions()

    if (this.questions.length === 0) {
      // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = false
    handler.loaded()
    const expectingToBe = questionsSkip + this.req.take
    if (this.questions.length < expectingToBe) handler.complete()
  }

  // 質問削除、回答の投稿・編集・削除が出来る権限
  private get hasAuthority():boolean {
    // 管理者業務執行者のみ
    return this.$isServiceStaff
  }

  isUnanswered = false
  isAvailable = false
  filterBySwitch(): void {
    this.req.isUnanswered = this.isUnanswered
    this.req.isAvailable = this.isAvailable
    this.refreshList()
  }

  private refreshList(): void {
    this.questions = []
    this.skipToken = undefined
    this.identifier++
  }

  selectedQuestionIndex = 0

  // 質問削除 ダイアログ関連
  showDelQuestionDialog = false
  clickDelQuestion(questionIndex: number): void {
    if (this.isPreview) return
    this.selectedQuestionIndex = questionIndex
    this.showDelQuestionDialog = true
  }

  // 質問削除 実行
  async executeDelQuestion():Promise<void> {
    this.showDelQuestionDialog = false
    const selectedQuestion = this.questions[this.selectedQuestionIndex]
    await resolutionsModule.deleteQuestions(new QuestionForRequest(this.resolutionId, selectedQuestion))

    // 画面で描画するデータの書き換え
    this.questions[this.selectedQuestionIndex].questionState = QUESTION_STATES.DELETED
  }

  // 回答登録 ダイアログ関連
  showPostAnswerDialog = false
  clickPostAnswer(questionIndex: number): void {
    if (this.isPreview) return
    this.selectedQuestionIndex = questionIndex
    this.showPostAnswerDialog = true
  }

  // 回答登録 実行
  async executePostAnswer():Promise<void> {
    this.showPostAnswerDialog = false
    const question = this.questions[this.selectedQuestionIndex]
    // 項目エラー発生時、当該項目に対してエラーを表示するための設定
    errorsModule.presetResponseFieldErrorPrefix(this.getPrefix(question.questionId))

    await resolutionsModule.postAnswers(new QuestionForRequest(this.resolutionId, question))

    // サーバ側のデータ更新後、画面に保持しているデータをversionを含む変更後のデータに変える
    const res = resolutionsModule.answerResponse(question.questionId)
    if (res && res.postedAt) {
      const postedAnswerContent = new Answer()
      postedAnswerContent.answerId = String(new Date().getTime()) // answerIdは画面側で使用しない（回答の登録・更新・削除時にはquestionIdを使用）ため、登録後画面に保持するidはダミー値を設定する
      postedAnswerContent.answerBody = question.answerInput
      postedAnswerContent.user = myProfileModule.myProfileGet?.user ?? new User()
      postedAnswerContent.postedAt = res.postedAt
      postedAnswerContent.version = res.version

      question.answer = postedAnswerContent
      question.isEditMode = false

      this.$set(this.questions, this.selectedQuestionIndex, question)
    }
  }

  // 回答削除 ダイアログ関連
  showDelAnswerDialog = false
  clickDelAnswer(questionIndex: number): void {
    if (this.isPreview) return
    this.selectedQuestionIndex = questionIndex
    this.showDelAnswerDialog = true
  }

  async executeDelAnswer():Promise<void> {
    this.showDelAnswerDialog = false
    const question = this.questions[this.selectedQuestionIndex]
    await resolutionsModule.deleteAnswers(new QuestionForRequest(this.resolutionId, question))

    // 画面で描画するデータの書き換え
    question.answer = undefined
    question.answerInput = ''
  }

  // 回答編集 ダイアログ関連
  showUpdAnswerDialog = false
  clickEditAnswer(questionIndex:number):void {
    if (this.isPreview) return
    this.questions[questionIndex].answerInput = this.questions[questionIndex].answer?.answerBody ?? ''
    this.questions[questionIndex].isEditMode = true
  }

  cancelEditAnswer(questionIndex:number):void {
    this.questions[questionIndex].isEditMode = false
    this.questions[questionIndex].answerInput = this.questions[questionIndex].answer?.answerBody ?? ''
  }

  clickUpdAnswer(questionIndex:number): void {
    this.selectedQuestionIndex = questionIndex
    this.showUpdAnswerDialog = true
  }

  async executeUpdAnswer():Promise<void> {
    this.showUpdAnswerDialog = false
    const question = this.questions[this.selectedQuestionIndex]
    // 項目エラー発生時、当該項目に対してエラーを表示するための設定
    errorsModule.presetResponseFieldErrorPrefix(this.getPrefix(question.questionId))

    await resolutionsModule.putAnswers(new QuestionForRequest(this.resolutionId, question))

    // サーバ側のデータ更新後、画面に保持しているデータをversionを含む変更後のデータに変える
    const res = resolutionsModule.answerResponse(question.questionId)
    if (res) {
      if (question.answer) {
        question.answer.version = res.version
        question.answer.answerBody = question.answerInput
      }
      question.isEditMode = false
      this.$set(this.questions, this.selectedQuestionIndex, question)
    }
  }

  private getPrefix(questionId: string):string {
    return 'question[' + questionId + '].'
  }
}
