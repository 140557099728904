







































import { Component, Mixins } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { authModule } from '@/stores/auth-store'
import { blobUrlModule } from '@/stores/blob-url-store'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { localParamStorageModule } from '@/stores/local-param-storage-store'
import { myProfileModule } from '@/stores/my-profile-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { cognitoAuth } from '@/libs/cognito-auth-adapter'
import { ERROR_MESSAGES } from '@/constants/ux-constants'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class LandingPage extends Mixins(CurrentAdminManager) {
  async created(): Promise<void> {
    this.clearSessionStorage()
    // エラーがある場合は自動ログインを試行しない（無限ループ抑止）
    if (localParamStorageModule.loginErrorMessage) {
      errorsModule.setGlobalErrors([localParamStorageModule.loginErrorMessage])
      localParamStorageModule.resetLoginErrorMessage()
      return
    }
    // セッション情報を持っていない場合は自動ログインを試行しない
    if (!cognitoAuth.hasRefreshToken()) return

    // 期限切れの場合はリフレッシュを図る
    if (!cognitoAuth.sessionIsValid()) {
      return new Promise((resolve) => cognitoAuth.refreshSession({
      // 有効なトークンがあった場合
        onSuccess: () => {
          this.$router.push({ name: staticRoutes.loginCompletion.name })
          resolve()
        },
        // セッション取得失敗（リフレッシュトークンを含めた期限切れ）
        onFailure: async() => {
          // トークンをクリアする
          await authModule.logout()
          await cognitoAuth.signOut()
          resolve()
        }
      }))
    }
    // セッションが有効な場合
    this.$router.push({ name: staticRoutes.loginCompletion.name })
  }

  async onClickLogin(): Promise<void> {
    // 認証要求
    await cognitoAuth.getSession({
      // 有効なトークンがあった場合
      // （実際には自動ログインしてしまうためこの処理は動かないが、型定義の都合で必要）
      onSuccess: () => {
        this.$router.push({ name: staticRoutes.loginCompletion.name })
      },
      // セッション取得失敗（リフレッシュトークンを含めた期限切れ）
      onFailure: () => {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.SESSION_EXPIRED])
      }
    })
  }

  clearSessionStorage(): void {
    blobUrlModule.clear()
    buildingsModule.clear()
    currentStateModule.clear()
    myProfileModule.clear()
    paramStorageModule.clear()
  }

  get globalErrors(): string[] { return errorsModule.globalErrors }
}
