import { APIClientBase } from './api-client-base'

import { SimpleRepairsPerformancesDeleteRequest } from '@/dtos/simple-repairs/performances/delete'
import { SimpleRepairsPerformancesPostRequest } from '@/dtos/simple-repairs/performances/post'
import { SimpleRepairsPlansDetailGetResponse } from '@/dtos/simple-repairs/plans/get'
import { SimpleRepairPlanPostRequest } from '@/dtos/simple-repairs/plans/post'
import { SimpleRepairsUpdatesCommentsPostRequest } from '@/dtos/simple-repairs/updates/comments/post'
import { SimpleRepairsUpdatesCommentDetailGetRequest, SimpleRepairsUpdatesCommentDetailGetResponse } from '@/dtos/simple-repairs/updates/comments/get-details'
import { SimpleRepairsUpdatesCommentsPutRequest } from '@/dtos/simple-repairs/updates/comments/put'
import { SimpleRepairPlanUpdatesLatestCommentResponse } from '@/dtos/simple-repairs/updates/comments/get-latest'
import { SimpleRepairsUpdatesCommentsGetRequest, SimpleRepairsUpdatesCommentsGetResponse } from '@/dtos/simple-repairs/updates/comments/get'
import { SimpleRepairsUpdatesCommentsDeleteRequest } from '@/dtos/simple-repairs/updates/comments/delete'

class SimpleRepairsClient extends APIClientBase {
  constructor() {
    super('/simple-repairs')
  }

  // 簡易版長期修繕計画登録
  async postSimpleRepairPlan(req: SimpleRepairPlanPostRequest): Promise<void> {
    await this.post('/plans', req)
  }

  // 簡易版長期修繕計画詳細取得
  getSimpleRepairsDetail(): Promise<SimpleRepairsPlansDetailGetResponse> {
    return this.get('/plans', null, SimpleRepairsPlansDetailGetResponse)
  }

  // 簡易版長期修繕計画実績登録
  async postPerformances(req: SimpleRepairsPerformancesPostRequest): Promise<void> {
    await this.post(`/performances/${req.period}`, req)
  }

  // 簡易版長期修繕計画実績削除
  async deletePerformances(req: SimpleRepairsPerformancesDeleteRequest): Promise<void> {
    await this.delete(`/performances/${req.period}`)
  }

  // 最新コメント取得
  getLatestComment() {
    return this.get('/updates/comment', undefined, SimpleRepairPlanUpdatesLatestCommentResponse, false)
  }

  // -------------------- 変更コメント --------------------
  postSimpleRepairsUpdatesComments(req: SimpleRepairsUpdatesCommentsPostRequest): Promise<void> {
    return this.post('/updates/comments', req)
  }

  getSimpleRepairsUpdatesCommentDetail(req: SimpleRepairsUpdatesCommentDetailGetRequest): Promise<SimpleRepairsUpdatesCommentDetailGetResponse> {
    return this.get(`/updates/comments/${req.commentId}`, null, SimpleRepairsUpdatesCommentDetailGetResponse)
  }

  putSimpleRepairsUpdatesComments(req: SimpleRepairsUpdatesCommentsPutRequest): Promise<void> {
    return this.put(`/updates/comments/${req.commentId}`, req)
  }

  // 変更コメント取得
  getSimpleRepairsUpdatesComments(req: SimpleRepairsUpdatesCommentsGetRequest): Promise<SimpleRepairsUpdatesCommentsGetResponse> {
    return this.get('/updates/comments', req, SimpleRepairsUpdatesCommentsGetResponse, false)
  }

  // 変更コメント削除
  deleteSimpleRepairsUpdatesComments(req: SimpleRepairsUpdatesCommentsDeleteRequest): Promise<void> {
    return this.delete(`/updates/comments/${req.commentId}`)
  }
}

export const simpleRepairsClient = new SimpleRepairsClient()
