import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'

import { adminIdeasClient } from '@/clients/admin-ideas-client'
import { IDEA_STATES } from '@/constants/schema-constants'
import type { IdeaState } from '@/constants/schema-constants'

import { FollowingResolution, Section, User } from '@/dtos/commons'
import { AdminIdeaAgreementsGetRequest, AdminIdeaAgreementsGetResponse } from '@/dtos/ideas/admin-idea/agreements/get'
import { AdminIdeaAgreementsPutRequest } from '@/dtos/ideas/admin-idea/agreements/put'
import { Decision, Details } from '@/dtos/ideas/admin-idea/commons'
import { AdminIdeaDecisionGetRequest, AdminIdeaDecisionGetResponse } from '@/dtos/ideas/admin-idea/decision/get'
import { AdminIdeasDeleteRequest } from '@/dtos/ideas/admin-idea/delete'
import { AdminIdeasDraftDeleteRequest } from '@/dtos/ideas/admin-idea/draft/delete'
import { AdminIdeasDraftPostRequest } from '@/dtos/ideas/admin-idea/draft/post'
import { AdminIdeaDetailGetRequest, AdminIdeaDetailGetResponse } from '@/dtos/ideas/admin-idea/get-detail'
import { AdminIdeaOpinionsGetRequest, AdminIdeaOpinionsGetResponse, Opinion, OpinionComment } from '@/dtos/ideas/admin-idea/opinions/get'
import { AdminIdeaOpinionsPostRequest } from '@/dtos/ideas/admin-idea/opinions/post'
import { AdminIdeasOpinionsDeleteRequest } from '@/dtos/ideas/admin-idea/opinions/delete'
import { AdminIdeasOpinionsCommentsDeleteRequest } from '@/dtos/ideas/admin-idea/opinions/comments/delete'
import { AdminIdeasOpinionsCommentsPostRequest, AdminIdeasOpinionsCommentsPostResponse } from '@/dtos/ideas/admin-idea/opinions/comments/post'
import { AdminIdeasOpinionsCommentsPutRequest } from '@/dtos/ideas/admin-idea/opinions/comments/put'
import { AdminIdeasPostRequest } from '@/dtos/ideas/admin-idea/post'
import { AdminIdeasPutRequest } from '@/dtos/ideas/admin-idea/put'
import { AdminIdeaSessionGetRequest, AdminIdeaSessionGetResponse } from '@/dtos/ideas/admin-idea/session/get'
import { AdminIdeaSessionPutRequest } from '@/dtos/ideas/admin-idea/session/put'
import { PreparingStatusDeleteRequest } from '@/dtos/ideas/admin-idea/preparing-status/delete'
import { PreparingStatusPostRequest, PreparingStatusUpdateDto } from '@/dtos/ideas/admin-idea/preparing-status/post'
import { PreparingStatusPutRequest } from '@/dtos/ideas/admin-idea/preparing-status/put'
import { AdminIdeaResultsGetRequest, AdminIdeaResultsGetResponse, ResolutionPreparingStatus, Result, ResultAgreement, Summary } from '@/dtos/ideas/admin-idea/results/get'
import { AdminIdeaResultsPutRequest } from '@/dtos/ideas/admin-idea/results/put'
import { AdminIdeaUpdatesDeleteRequest } from '@/dtos/ideas/admin-idea/updates/delete'
import { AdminIdeaUpdatesGetRequest, AdminIdeaUpdatesGetResponse } from '@/dtos/ideas/admin-idea/updates/get'
import { AdminIdeaUpdatesPostRequest } from '@/dtos/ideas/admin-idea/updates/post'
import { AdminIdeaUpdatesPutRequest, AdminIdeaUpdatesPutResponse } from '@/dtos/ideas/admin-idea/updates/put'

import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { myProfileModule } from './my-profile-store'

export class AdminIdeaCommonState {
  ideaId = ''
  ideaState: IdeaState = IDEA_STATES.ADMIN.DRAFT
  details: Details = new Details()
  title = ''
  user: User = new User()
}

@Module({ store, dynamic: true, namespaced: true, name: 'adminIdeasStore' })
class AdminIdeasStore extends VuexModule {
  private _adminIdeaCommonState = new AdminIdeaCommonState()

  get adminIdeaCommonStateGet(): AdminIdeaCommonState {
    return this._adminIdeaCommonState
  }

  @Mutation
  private SET_COMMON_STATE_GET(state: AdminIdeaCommonState): void {
    this._adminIdeaCommonState.ideaId = state.ideaId
    this._adminIdeaCommonState.ideaState = state.ideaState
    this._adminIdeaCommonState.title = state.title
    this._adminIdeaCommonState.details = state.details
    this._adminIdeaCommonState.user = state.user
  }

  @Action
  private setAdminIdeaCommonState(state: AdminIdeaCommonState): void {
    this.SET_COMMON_STATE_GET(state)
  }

  private _ideaDetails: Record<string, AdminIdeaDetailGetResponse> = {}

  get detailResponse(): (id: string) => AdminIdeaDetailGetResponse | undefined { return (id: string) => this._ideaDetails[id] }

  @Mutation
  private SET_IDEA_DETAIL_GET(res: AdminIdeaDetailGetResponse): void {
    Vue.set(this._ideaDetails, res.ideaId, res)
  }

  @Action
  async fetchIdeaDetail(req: AdminIdeaDetailGetRequest): Promise<void> {
    const res = await adminIdeasClient.getIdeaDetail(req)
    this.setAdminIdeaCommonState({
      ideaId: res.ideaId,
      ideaState: res.ideaState,
      title: res.title,
      details: res.details,
      user: res.user
    })
    this.SET_IDEA_DETAIL_GET(res)
  }

  private _ideaDecisions: Record<string, AdminIdeaDecisionGetResponse> = {}

  get decisionResponse(): (id: string) => AdminIdeaDecisionGetResponse | undefined { return (id: string) => this._ideaDecisions[id] }

  @Mutation
  private SET_IDEA_DECISION_GET(res: AdminIdeaDecisionGetResponse): void {
    Vue.set(this._ideaDecisions, res.ideaId, res)
  }

  @Action
  async fetchIdeaDecision(req: AdminIdeaDecisionGetRequest): Promise<void> {
    const res = await adminIdeasClient.getIdeaDecision(req)
    this.SET_IDEA_DECISION_GET(res)
  }

  @Action
  async postIdeas(req: AdminIdeasPostRequest): Promise<void> {
    await adminIdeasClient.postAdminIdea(req)
  }

  @Action
  async postAdminIdeaDraft(req: AdminIdeasDraftPostRequest): Promise<void> {
    await adminIdeasClient.postAdminIdeaDraft(req)
  }

  @Action
  async putAdminIdeas(req: AdminIdeasPutRequest): Promise<void> {
    await adminIdeasClient.putAdminIdea(req)
  }

  @Action
  async deleteIdea(req: AdminIdeasDeleteRequest): Promise<void> {
    await adminIdeasClient.deleteAdminIdea(req)
  }

  @Action
  async deleteDraftIdea(req: AdminIdeasDraftDeleteRequest): Promise<void> {
    await adminIdeasClient.deleteDraftAdminIdea(req)
  }

  private _adminIdeaSessionGet: Record<string, AdminIdeaSessionGetResponse> = {}

  get adminIdeasSessionGet(): (id: string) => AdminIdeaSessionGetResponse | undefined { return (id: string) => this._adminIdeaSessionGet[id] }

  @Mutation
  private SET_IDEA_SESSION_GET(res: AdminIdeaSessionGetResponse): void {
    Vue.set(this._adminIdeaSessionGet, res.ideaId, res)
  }

  @Action
  async fetchIdeaSession(req: AdminIdeaSessionGetRequest): Promise<void> {
    const res = await adminIdeasClient.getAdminIdeaSessions(req)
    this.setAdminIdeaCommonState({
      ideaId: res.ideaId,
      ideaState: res.ideaState,
      title: res.title,
      details: res.details,
      user: res.user
    })
    this.SET_IDEA_SESSION_GET(res)
  }

  @Action
  async putIdeaSession(req: AdminIdeaSessionPutRequest): Promise<void> {
    await adminIdeasClient.putAdminIdeaSessions(req)
  }

  private _adminIdeaOpinionsGet: Record<string, AdminIdeaOpinionsGetResponse> = {}

  get adminIdeasOpinionsGet(): (id: string) => AdminIdeaOpinionsGetResponse | undefined { return (id: string) => this._adminIdeaOpinionsGet[id] }

  @Mutation
  private SET_IDEA_OPINIONS_GET(res: AdminIdeaOpinionsGetResponse): void {
    Vue.set(this._adminIdeaOpinionsGet, res.ideaId, res)
  }

  @Action
  async fetchIdeaOpinions(req: AdminIdeaOpinionsGetRequest): Promise<void> {
    const res = await adminIdeasClient.getAdminIdeaOpinions(req)
    this.setAdminIdeaCommonState({
      ideaId: res.ideaId,
      ideaState: res.ideaState,
      title: res.title,
      details: res.details,
      user: res.user
    })
    this.SET_IDEA_OPINIONS_GET(res)
  }

  @Action
  async postOpinion(req: AdminIdeaOpinionsPostRequest): Promise<void> {
    await adminIdeasClient.postOpinion(req)
  }

  @Action
  async deleteIdeaOpinions(req: AdminIdeasOpinionsDeleteRequest): Promise<void> {
    await adminIdeasClient.deleteAdminIdeaOpinion(req)
  }

  @Mutation
  private SET_IDEA_OPINIONS_COMMENT(res:AdminIdeasOpinionsCommentsPostResponse): void {
    const { ideaId, opinionId, ...opinionComment } = res
    const idea = deepCopy(this._adminIdeaOpinionsGet[ideaId], {
      this: new ColumnToType(AdminIdeaOpinionsGetResponse),
      details: new ColumnToType(Details),
      user: new ColumnToType(User),
      opinions: new ColumnToType(Opinion),
      opinionComment: new ColumnToType(OpinionComment),
      admin: new ColumnToType(User),
    }, 'this')
    const opinions = idea.opinions
    const opinion = deepCopy(opinions.find(e => e.opinionId === opinionId), {
      this: new ColumnToType(OpinionComment),
      admin: new ColumnToType(User)
    }, 'this')
    if (!opinion) throw new Error('target opinion is not registered')
    opinion.opinionComment = opinionComment
    const updatedOpinions = opinions.map(e => {
      if (e.opinionId !== opinionId) return e
      return opinion
    })
    idea.opinions = updatedOpinions
    Vue.set(this._adminIdeaOpinionsGet, ideaId, idea)
  }

  @Mutation
  private CLEAR_IDEA_OPINION_COMMENT(req:AdminIdeasOpinionsCommentsDeleteRequest) {
    const idea = deepCopy(this._adminIdeaOpinionsGet[req.ideaId], {
      this: new ColumnToType(AdminIdeaOpinionsGetResponse),
      details: new ColumnToType(Details),
      user: new ColumnToType(User),
      opinions: new ColumnToType(Opinion),
      opinionComment: new ColumnToType(OpinionComment),
      admin: new ColumnToType(User),
    }, 'this')
    const opinions = idea.opinions
    const opinion = deepCopy(opinions.find(e => e.opinionId === req.opinionId), {
      this: new ColumnToType(OpinionComment),
      admin: new ColumnToType(User)
    }, 'this')
    if (!opinion) throw new Error('target opinion is not registered')
    opinion.opinionComment = undefined
    const updatedOpinions = opinions.map(e => {
      if (e.opinionId !== req.opinionId) return e
      return opinion
    })
    idea.opinions = updatedOpinions
    Vue.set(this._adminIdeaOpinionsGet, req.ideaId, idea)
  }

  @Action
  async postAdminIdeasOpinionsComment(req:AdminIdeasOpinionsCommentsPostRequest) {
    const res = await adminIdeasClient.postAdminIdeaOpinionComment(req)
    if (!res) throw new Error() // unexpected
    this.SET_IDEA_OPINIONS_COMMENT(res)
  }

  @Action
  async putAdminIdeasOpinionsComment(req:AdminIdeasOpinionsCommentsPutRequest) {
    const res = await adminIdeasClient.putAdminIdeaOpinionComment(req)
    if (!res) throw new Error() // unexpected
    this.SET_IDEA_OPINIONS_COMMENT(res)
  }

  @Action
  async deleteAdminIdeasOpinionsComment(req:AdminIdeasOpinionsCommentsDeleteRequest) {
    await adminIdeasClient.deleteAdminIdeaOpinionComment(req)
    this.CLEAR_IDEA_OPINION_COMMENT(req)
  }

  private _adminIdeaUpdatesGet: Record<string, AdminIdeaUpdatesGetResponse> = {}
  private _adminIdeaUpdatesPut: AdminIdeaUpdatesPutResponse = new AdminIdeaUpdatesPutResponse()

  get adminIdeasUpdatesGet(): (id: string) => AdminIdeaUpdatesGetResponse | undefined { return (id: string) => this._adminIdeaUpdatesGet[id] }
  get adminIdeaUpdatesPut(): AdminIdeaUpdatesPutResponse { return this._adminIdeaUpdatesPut }

  @Mutation
  private SET_IDEA_UPDATES_GET(res: AdminIdeaUpdatesGetResponse): void {
    Vue.set(this._adminIdeaUpdatesGet, res.ideaId, res)
  }

  @Mutation
  private SET_IDEA_UPDATES_PUT(res: AdminIdeaUpdatesPutResponse): void {
    this._adminIdeaUpdatesPut = res
  }

  @Action
  async fetchIdeaUpdates(req: AdminIdeaUpdatesGetRequest): Promise<void> {
    const res = await adminIdeasClient.getAdminIdeaUpdates(req)
    this.setAdminIdeaCommonState({
      ideaId: res.ideaId,
      ideaState: res.ideaState,
      title: res.title,
      details: res.details,
      user: res.user
    })
    this.SET_IDEA_UPDATES_GET(res)
  }

  @Action
  clearFetchedIdeaUpdates(): void {
    this.SET_IDEA_UPDATES_GET(new AdminIdeaUpdatesGetResponse())
  }

  @Action
  async postIdeaUpdates(req: AdminIdeaUpdatesPostRequest): Promise<void> {
    await adminIdeasClient.postAdminIdeaUpdates(req)
  }

  @Action
  async putIdeaUpdates(req: AdminIdeaUpdatesPutRequest): Promise<void> {
    const res = await adminIdeasClient.putAdminIdeaUpdates(req)
    if (res) this.SET_IDEA_UPDATES_PUT(res)
  }

  @Action
  async deleteIdeaUpdates(req: AdminIdeaUpdatesDeleteRequest): Promise<void> {
    await adminIdeasClient.deleteAdminIdeaUpdates(req)
  }

  // 賛同
  private _agreements = new AdminIdeaAgreementsGetResponse()
  get agreementsGet(): AdminIdeaAgreementsGetResponse { return this._agreements }

  @Mutation
  private SET_AGREEMENTS_GET(res: AdminIdeaAgreementsGetResponse): void {
    this._agreements = res
  }

  @Action
  async fetchAgreements(req: AdminIdeaAgreementsGetRequest): Promise<void> {
    const res = await adminIdeasClient.getAgreements(req)
    this.SET_AGREEMENTS_GET(res)
  }

  @Action
  async putIdeaAgreements(req: AdminIdeaAgreementsPutRequest): Promise<void> {
    await adminIdeasClient.putAgreements(req)
  }

  private _adminIdeaResultsGet: Record<string, AdminIdeaResultsGetResponse> = {}

  get adminIdeasResultsGet(): (id: string) => AdminIdeaResultsGetResponse | undefined { return (id: string) => this._adminIdeaResultsGet[id] }

  @Mutation
  private SET_IDEA_RESULTS_GET(res: AdminIdeaResultsGetResponse): void {
    Vue.set(this._adminIdeaResultsGet, res.ideaId, res)
  }

  @Action
  async fetchIdeaResults(req: AdminIdeaResultsGetRequest): Promise<void> {
    const res = await adminIdeasClient.getAdminIdeaResults(req)
    this.setAdminIdeaCommonState({
      ideaId: res.ideaId,
      ideaState: res.ideaState,
      title: res.title,
      details: res.details,
      user: res.user
    })
    this.SET_IDEA_RESULTS_GET(res)
  }

  @Action
  async putIdeaResult(req:AdminIdeaResultsPutRequest) {
    await adminIdeasClient.putAdminIdeaResults(req)
    // 不成立で更新した場合は議案準備状況を削除する
    if (!req.decision.isAgreed) this.DELETE_PREPARING_STATUS(req.ideaId)
  }

  /* 議案準備状況関連 */
  @Mutation
  private SET_PREPARING_STATUS(preparingStatusDto: PreparingStatusUpdateDto) {
    const { ideaId, ...preparingStatus } = preparingStatusDto
    // ストア内の要素の一つだけを更新する際、単純に再代入するとVueが変更を検知できないので、ディープコピーして別インスタンスを生成する
    const result = deepCopy(this._adminIdeaResultsGet[ideaId], {
      this: new ColumnToType(AdminIdeaResultsGetResponse),
      details: new ColumnToType(Details),
      user: new ColumnToType(User),
      result: new ColumnToType(Result),
      decision: new ColumnToType(Decision),
      sections: new ColumnToType(Section, true),
      followingResolution: new ColumnToType(FollowingResolution),
      summary: new ColumnToType(Summary),
      agreements: new ColumnToType(ResultAgreement, true),
      agreedOwner: new ColumnToType(User, true),
      resolutionPreparingStatus: new ColumnToType(ResolutionPreparingStatus),
      admin: new ColumnToType(User)
    }, 'this')
    if (!result) throw new Error('target admin idea result is not registered')
    result.resolutionPreparingStatus = preparingStatus
    Vue.set(this._adminIdeaResultsGet, ideaId, result)
  }

  @Mutation
  private DELETE_PREPARING_STATUS(ideaId: string) {
    // ストア内の要素の一つだけを更新する際、単純に再代入するとVueが変更を検知できないので、ディープコピーして別インスタンスを生成する
    const result = deepCopy(this._adminIdeaResultsGet[ideaId], {
      this: new ColumnToType(AdminIdeaResultsGetResponse),
      details: new ColumnToType(Details),
      user: new ColumnToType(User),
      result: new ColumnToType(Result),
      decision: new ColumnToType(Decision),
      sections: new ColumnToType(Section, true),
      followingResolution: new ColumnToType(FollowingResolution),
      summary: new ColumnToType(Summary),
      agreements: new ColumnToType(ResultAgreement, true),
      agreedOwner: new ColumnToType(User, true),
      resolutionPreparingStatus: new ColumnToType(ResolutionPreparingStatus),
      admin: new ColumnToType(User)
    }, 'this')
    if (!result) throw new Error('target admin idea result is not registered')
    result.resolutionPreparingStatus = undefined
    Vue.set(this._adminIdeaResultsGet, ideaId, result)
  }

  @Action
  async postPreparingStatus(req: PreparingStatusPostRequest): Promise<void> {
    const res = await adminIdeasClient.postPreparingStatus(req)
    if (!res) throw new Error() // unexpected
    const admin = myProfileModule.myProfileGet?.user
    if (!admin) throw new Error() // unexpected
    const resolutionPreparingStatus = new PreparingStatusUpdateDto(req, res, admin)
    this.SET_PREPARING_STATUS(resolutionPreparingStatus)
  }

  @Action
  async putPreparingStatus(req: PreparingStatusPutRequest): Promise<void> {
    const res = await adminIdeasClient.putPreparingStatus(req)
    if (!res) throw new Error() // unexpected
    const admin = myProfileModule.myProfileGet?.user
    if (!admin) throw new Error() // unexpected
    const preparingStatus = new PreparingStatusUpdateDto(req, res, admin)
    this.SET_PREPARING_STATUS(preparingStatus)
  }

  @Action
  async deletePreparingStatus(req: PreparingStatusDeleteRequest): Promise<void> {
    await adminIdeasClient.deletePreparingStatus(req)
    this.DELETE_PREPARING_STATUS(req.ideaId)
  }
}

export const adminIdeasModule = getModule(AdminIdeasStore)
