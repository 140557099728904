import type { HoldingMethodType } from '@/constants/schema-constants'
import { Material, Subject } from '@/dtos/commons'

export class GMResolutionsPutRequest {
  resolutionId: string
  title: string
  deadline: string
  holdingMethodType: HoldingMethodType
  holdingAt: string
  holdingTime: string
  venue: string
  meetingUrl?: string
  meetingInformation?: string
  statementDeadlineText: string
  statementDeadlineAnnotation?: string
  material: Material
  subjects: Subject[] = []
  version: number

  constructor(resolutionId: string, title: string, deadline: string, holdingMethodType: HoldingMethodType, holdingAt: string, holdingTime: string,
    venue: string, statementDeadlineText: string, material: Material, subjects: Subject[], version: number, meetingUrl?: string, meetingInformation?: string, statementDeadlineAnnotation?: string) {
    this.resolutionId = resolutionId
    this.title = title
    this.deadline = deadline
    this.holdingMethodType = holdingMethodType
    this.holdingAt = holdingAt
    this.holdingTime = holdingTime
    this.venue = venue
    this.meetingUrl = meetingUrl
    this.meetingInformation = meetingInformation
    this.statementDeadlineText = statementDeadlineText
    this.statementDeadlineAnnotation = statementDeadlineAnnotation
    this.material = material
    this.subjects = subjects
    this.version = version
  }
}
