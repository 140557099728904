import { AREA_TYPE, AREA_TYPE_MAP, AUDIT_STATES, REPAIR_PLAN_TYPES } from '@/constants/schema-constants'
import type { AreaType } from '@/constants/schema-constants'
import * as ExcelJs from 'exceljs'
import { ExcelParserBase } from './excel-parser-base'
import { BuildingPostDto, OwningBuildingUnitPostDto } from '@/dtos/buildings/post'

const STATIC_HEADERS_BUILDINGS = ['#', '項目名', '設定内容（例）', '設定内容']
const STATIC_HEADERS_UNITS = ['#', '住戸番号', '住戸タイプコード', '専有面積', '議決権数', 'CASYS登録氏名', '組合員資格取得日', 'アプリ利用氏名', '人物メモ', '外部居住', '複数住戸の区分所有者', '認証キー']

const SHEET_IDX_BUILDINGS = 0
const SHEET_IDX_UNITS = 1

const DATA_START_ROW_IDX_BUILDINGS = 1 // start with 0（0：項目名、1以降：データ）
const DATA_START_ROW_IDX_UNITS = 3 // start with 0（0：項目名、1：注釈、2：例、3以降：データ）

const FIXED_LENGTH_MESSAGE = '桁で入力してください。'
const INVALID_IS_CONSULTATION_USE_MESSAGE = 'で本提供と入力した場合、相談・連絡機能の有無は有を入力してください。'
const IS_ALPHA_NUMERIC_MESSAGE = 'は半角英数字のみで入力してください。'
const IS_NUMERIC_LENGTH_MESSAGE = '桁の数字で入力してください。'
const IS_NOT_EMPTY_MESSAGE = 'を入力してください。'
const IS_DATE_MESSAGE = 'はYYYY/MM/DDの形式で入力してください。'
const IS_POSTAL_CODE_MESSAGE = 'はxxx-xxxxの形式で入力してください。'
const MAX_LENGTH_MESSAGE = '文字以下で入力してください。'
const MIN_MAX_INT_MESSAGE = 'の整数で入力してください。'
const IS_NUMERIC = 'は数字で入力してください。'
const MIN_NUMBER_MESSAGE = '以上の数字で入力してください。'

const BUILDING_ROW_IDX = {
  AREA: 1,
  CONDOMINIUM_CD: 2,
  RIDGE_CD: 3,
  BUILDING_NAME: 4,
  POSTAL_CODE: 5,
  ADDRESS: 6,
  EMPLOYEE_ID: 7,
  IS_FACILITY_RESERVATION_AVAILABLE: 8,
  ACCOUNTING_MONTH: 9,
  GM_MONTH: 10,
  SM_INTRODUCED_DATE: 11,
  IS_TRIAL_TARGET_BUILDING: 12,
  IS_CONSULTATION_USE: 13,
  ACCOUNTING: 14,
  REPAIR_PLAN_TYPE: 15,
  ADOPT_REPAIR_PLAN_SERVICE: 16,
  ADOPT_RESERVE_PLAN_SERVICE: 17,
  BILLING_START_DATE: 18,
  FIRST_PERIOD_END_YEAR: 19,
  KEY_PERSON: 20,
  CONCERN: 21
}
const BUILDING_IDX_COL = 0
const BUILDING_DATA_COL = 3

const UNITS_COL_IDX = {
  NO: 0,
  ROOM_NUMBER: 1,
  UNIT_TYPE_CODE: 2,
  OCCUPIED_AREA: 3,
  VOTING_RIGHT_COUNT: 4,
  CASYS_NAME: 5,
  JOINED_AT: 6,
  USER_NAME: 7,
  PERSONAL_MEMO: 8,
  IS_LIVING_OUTSIDE: 9,
  SAME_OWNER_NO: 10,
  INITIAL_AUTH_CODE: 11
}

type ValidateOptions = {
  data: string,
  isRequired?: boolean,
  maxLength?: number,
  fixedLength?: number,
  isDate?: boolean,
  isAlphaNumeric?: boolean,
  fixedNumericLength?: number,
  minNumber?: number,
  minMaxInt?: [number, number],
  isPostalCode?: boolean
  minMaxLength?: {minLength: number, maxLength: number},
  isNumeric?: boolean
}

class BuildingExcelParser extends ExcelParserBase {
  async parse(file: File) {
    const [buildingDataRows, unitsDataRows] = await Promise.all([
      this.fromExcel(file, SHEET_IDX_BUILDINGS, this._buildingParseValidator),
      this.fromExcel(file, SHEET_IDX_UNITS, this._unitsParseValidator)
    ])

    if (!buildingDataRows || !unitsDataRows) return {} // ファイルフォーマットが異なる場合は空を返却する
    const building = buildingDataRows?.slice(DATA_START_ROW_IDX_BUILDINGS)
    const units = unitsDataRows?.slice(DATA_START_ROW_IDX_UNITS)

    this.errors.clear()

    // 取得した値をAPI用に変換
    const reqBuilding = new BuildingPostDto()
    building?.forEach(row => {
      const dataStr = this._toString(row[BUILDING_DATA_COL])

      switch (row[BUILDING_IDX_COL]) { // 「#」列の値で分岐
        case BUILDING_ROW_IDX.AREA: {
          const dataHalfWidthStr = this._toHalfWidth(dataStr)
          const error = this._invalidateData({ data: dataHalfWidthStr })
          if (error) this._setError('マンション情報の管理会社', error)
          else {
            const areaType = this._getAreaType(dataHalfWidthStr)
            if (areaType) reqBuilding.buildingAreaType = areaType
          }
          break
        }
        case BUILDING_ROW_IDX.CONDOMINIUM_CD: {
          const dataHalfWidthStr = this._toHalfWidth(dataStr)
          const error = this._invalidateData({ data: dataHalfWidthStr, fixedNumericLength: 6 })
          if (error) this._setError('マンション情報のMaNo.', error)
          else reqBuilding.condominiumCd = dataHalfWidthStr
          break
        }
        case BUILDING_ROW_IDX.RIDGE_CD: {
          const dataHalfWidthStr = this._toHalfWidth(dataStr)
          const error = this._invalidateData({ data: dataHalfWidthStr, fixedNumericLength: 2 })
          if (error) this._setError('マンション情報の棟コード', error)
          else reqBuilding.ridgeCd = dataHalfWidthStr
          break
        }
        case BUILDING_ROW_IDX.BUILDING_NAME: {
          const error = this._invalidateData({ data: dataStr, maxLength: 50 })
          if (error) this._setError('マンション情報のマンション名', error)
          else reqBuilding.buildingName = dataStr
          break
        }
        case BUILDING_ROW_IDX.POSTAL_CODE: {
          const dataHalfWidthStr = this._toHalfWidth(dataStr)
          const error = this._invalidateData({ data: dataHalfWidthStr, isPostalCode: true })
          if (error) this._setError('マンション情報のマンション所在地（郵便番号）', error)
          else reqBuilding.buildingPostalCode = dataHalfWidthStr
          break
        }
        case BUILDING_ROW_IDX.ADDRESS: {
          const error = this._invalidateData({ data: dataStr, maxLength: 100 })
          if (error) this._setError('マンション情報のマンション所在地（住所）', error)
          else reqBuilding.buildingAddress = dataStr
          break
        }
        case BUILDING_ROW_IDX.EMPLOYEE_ID: {
          const error = this._invalidateData({ data: dataStr, isRequired: false, fixedLength: 7 })
          if (error) this._setError('マンション情報の管理者業務執行者（社員番号）', error)
          else reqBuilding.employeeId = dataStr
          break
        }
        case BUILDING_ROW_IDX.IS_FACILITY_RESERVATION_AVAILABLE: {
          const error = this._invalidateData({ data: dataStr })
          if (error) this._setError('マンション情報の素敵ネットメニューの有無（施設予約）', error)
          else reqBuilding.isFacilityReservationAvailable = dataStr === '有'
          break
        }
        case BUILDING_ROW_IDX.ACCOUNTING_MONTH: {
          const dataHalfWidthStr = this._toHalfWidth(dataStr)
          const error = this._invalidateData({ data: dataHalfWidthStr })
          if (error) this._setError('マンション情報の決算月', error)
          else reqBuilding.accountingMonth = Number(dataHalfWidthStr)
          break
        }
        case BUILDING_ROW_IDX.GM_MONTH: {
          const dataHalfWidthStr = this._toHalfWidth(dataStr)
          const error = this._invalidateData({ data: dataHalfWidthStr })
          if (error) this._setError('マンション情報の総会開催月', error)
          else reqBuilding.gmMonth = Number(dataHalfWidthStr)
          break
        }
        case BUILDING_ROW_IDX.SM_INTRODUCED_DATE: {
          const error = this._invalidateData({ data: dataStr, isDate: true })
          if (error) this._setError('マンション情報のサービス提供開始日', error)
          else reqBuilding.smIntroducedDate = new Date(dataStr)
          break
        }
        case BUILDING_ROW_IDX.IS_TRIAL_TARGET_BUILDING: {
          const error = this._invalidateData({ data: dataStr })
          if (error) this._setError('マンション情報のサービス提供種類', error)
          else reqBuilding.isTrialTargetBuilding = dataStr === '試験提供'
          break
        }
        case BUILDING_ROW_IDX.IS_CONSULTATION_USE: {
          const error = this._invalidateData({ data: dataStr })
          if (error) this._setError('マンション情報の相談・連絡機能の有無', error)
          else reqBuilding.isConsultationUse = dataStr === '有'
          // サービス提供種類が"本提供"、相談・連絡機能の有無が"無"の場合はエラーとする
          if (!reqBuilding.isTrialTargetBuilding && !reqBuilding.isConsultationUse) this._setError('マンション情報のサービス提供種類', INVALID_IS_CONSULTATION_USE_MESSAGE)
          break
        }
        case BUILDING_ROW_IDX.ACCOUNTING: {
          const error = this._invalidateData({ data: dataStr })
          if (error) this._setError('マンション情報の監査', error)
          else reqBuilding.accounting = dataStr === '外部監査役に委託' ? AUDIT_STATES.ENTRUST_TO_OUTSIDE_AUDITOR : AUDIT_STATES.ELECT_INSIDE_AUDITOR
          break
        }
        case BUILDING_ROW_IDX.REPAIR_PLAN_TYPE: {
          const error = this._invalidateData({ data: dataStr })
          if (error) this._setError('マンション情報の長期修繕計画種類', error)
          else reqBuilding.repairPlanType = dataStr === 'リアルタイム長計' ? REPAIR_PLAN_TYPES.REPAIR_PLAN : REPAIR_PLAN_TYPES.SIMPLE_REPAIR_PLAN
          break
        }
        case BUILDING_ROW_IDX.ADOPT_REPAIR_PLAN_SERVICE: {
          const error = this._invalidateData({ data: dataStr, isRequired: reqBuilding.repairPlanType === REPAIR_PLAN_TYPES.REPAIR_PLAN })
          if (error) this._setError('マンション情報のTOBE長計化の総会決議', error)
          else reqBuilding.adoptRepairPlanService = dataStr === '完了'
          break
        }
        case BUILDING_ROW_IDX.ADOPT_RESERVE_PLAN_SERVICE: {
          const error = this._invalidateData({ data: dataStr, isRequired: reqBuilding.repairPlanType === REPAIR_PLAN_TYPES.REPAIR_PLAN })
          if (error) this._setError('マンション情報のえらべる積立金導入決議', error)
          else reqBuilding.adoptReservePlanService = dataStr === '完了'
          break
        }
        case BUILDING_ROW_IDX.BILLING_START_DATE: {
          const error = this._invalidateData({ data: dataStr, isRequired: reqBuilding.repairPlanType === REPAIR_PLAN_TYPES.REPAIR_PLAN, isDate: true })
          if (error) this._setError('マンション情報の初年度請求開始日', error)
          else reqBuilding.billingStartDate = dataStr ? new Date(dataStr) : undefined
          break
        }
        case BUILDING_ROW_IDX.FIRST_PERIOD_END_YEAR: {
          const dataHalfWidthStr = this._toHalfWidth(dataStr)
          const error = this._invalidateData({ data: dataHalfWidthStr, minMaxInt: [1900, 9999] })
          if (error) this._setError('マンション情報の第1期終了年', error)
          else reqBuilding.firstPeriodEndYear = Number(dataHalfWidthStr)
          break
        }
        case BUILDING_ROW_IDX.KEY_PERSON: {
          const error = this._invalidateData({ data: dataStr, isRequired: false, maxLength: 1000 })
          if (error) this._setError('マンション情報のキーパーソン', error)
          else reqBuilding.keyPerson = dataStr
          break
        }
        case BUILDING_ROW_IDX.CONCERN: {
          const error = this._invalidateData({ data: dataStr, isRequired: false, maxLength: 1000 })
          if (error) this._setError('マンション情報の懸念事項', error)
          else reqBuilding.concerns = dataStr
          break
        }
      }
    })

    const reqOwningBuildingUnits: OwningBuildingUnitPostDto[] = []
    let error: string | undefined
    units?.forEach(row => {
      const owningBuildingDto = new OwningBuildingUnitPostDto()
      // #
      const rowNo = this._toString(row[UNITS_COL_IDX.NO])
      error = this._invalidateData({ data: rowNo })
      if (error) {
        this._setError('住戸・区分所有者情報の#', error)
        return // #列が入力されていない場合は同じ行の他のエラーチェックはスキップする
      }
      // 住戸番号
      const roomNumber = this._toHalfWidth(this._toString(row[UNITS_COL_IDX.ROOM_NUMBER]))
      error = this._invalidateData({ data: roomNumber, maxLength: 50 })
      if (error) this._setError('住戸・区分所有者情報の住戸番号、', error, `#${rowNo}`)
      else owningBuildingDto.roomNumber = roomNumber
      // 住戸タイプコード
      const unitTypeCode = this._toHalfWidth(this._toString(row[UNITS_COL_IDX.UNIT_TYPE_CODE]))
      error = this._invalidateData({ data: unitTypeCode, isRequired: reqBuilding.repairPlanType === REPAIR_PLAN_TYPES.REPAIR_PLAN, minMaxInt: [0, 65535] }) // SMALLINT unsigned
      if (error) this._setError('住戸・区分所有者情報の住戸タイプコード、', error, `#${rowNo}`)
      else owningBuildingDto.unitTypeCode = unitTypeCode ? Number(unitTypeCode) : undefined
      // 専有面積
      const occupiedArea = this._toHalfWidth(this._toString(row[UNITS_COL_IDX.OCCUPIED_AREA]))
      error = this._invalidateData({ data: occupiedArea, isRequired: reqBuilding.repairPlanType === REPAIR_PLAN_TYPES.REPAIR_PLAN, maxLength: 12, minNumber: 1, isNumeric: true })
      if (error) this._setError('住戸・区分所有者情報の専有面積、', error, `#${rowNo}`)
      else owningBuildingDto.occupiedArea = occupiedArea ? Number(occupiedArea) : undefined
      // 議決権数
      const votingRightCount = this._toHalfWidth(this._toString(row[UNITS_COL_IDX.VOTING_RIGHT_COUNT]))
      error = this._invalidateData({ data: votingRightCount, minMaxInt: [1, 999999999] })
      if (error) this._setError('住戸・区分所有者情報の議決権数、', error, `#${rowNo}`)
      else owningBuildingDto.votingRightCount = Number(votingRightCount)
      // CASYS登録氏名
      const casysName = this._toString(row[UNITS_COL_IDX.CASYS_NAME])
      error = this._invalidateData({ data: casysName, maxLength: 200 })
      if (error) this._setError('住戸・区分所有者情報のCASYS登録氏名、', error, `#${rowNo}`)
      else owningBuildingDto.casysName = casysName
      // 組合員資格取得日
      const joinedAt = this._toString(row[UNITS_COL_IDX.JOINED_AT])
      error = this._invalidateData({ data: joinedAt, isRequired: false, isDate: true })
      if (error) this._setError('住戸・区分所有者情報の組合員資格取得日、', error, `#${rowNo}`)
      else owningBuildingDto.joinedAt = new Date(joinedAt) || undefined
      // アプリ利用氏名
      const fullName = this._toString(row[UNITS_COL_IDX.USER_NAME])
      error = this._invalidateData({ data: fullName, isRequired: false, maxLength: 200 })
      if (error) this._setError('住戸・区分所有者情報のアプリ利用氏名、', error, `#${rowNo}`)
      else owningBuildingDto.userName = fullName || undefined
      // 人物メモ
      const personalMemo = this._toString(row[UNITS_COL_IDX.PERSONAL_MEMO])
      error = this._invalidateData({ data: personalMemo, isRequired: false, maxLength: 1000 })
      if (error) this._setError('住戸・区分所有者情報の人物メモ、', error, `#${rowNo}`)
      else owningBuildingDto.personalMemo = personalMemo || undefined
      // 外部居住
      const isLivingOutside = this._toString(row[UNITS_COL_IDX.IS_LIVING_OUTSIDE])
      error = this._invalidateData({ data: isLivingOutside, isRequired: false })
      if (error) this._setError('住戸・区分所有者情報の外部居住、', error, `#${rowNo}`)
      else owningBuildingDto.isLivingOutside = isLivingOutside === '有'
      // 複数住戸の区分所有者
      const sameOwnerNo = this._toHalfWidth(this._toString(row[UNITS_COL_IDX.SAME_OWNER_NO]))
      error = this._invalidateData({ data: sameOwnerNo, isRequired: false, minNumber: 0 })
      if (error) this._setError('住戸・区分所有者情報の複数住戸の区分所有者、', error, `#${rowNo}`)
      else owningBuildingDto.sameOwnerNo = sameOwnerNo ? Number(sameOwnerNo) : undefined
      // 認証キー
      const initialAuthCode = this._toHalfWidth(this._toString(row[UNITS_COL_IDX.INITIAL_AUTH_CODE]))
      error = this._invalidateData({ data: initialAuthCode, isRequired: false, minMaxLength: { minLength: 10, maxLength: 12 }, isAlphaNumeric: true })
      if (error) this._setError('住戸・区分所有者情報の認証キー、', error, `#${rowNo}`)
      else owningBuildingDto.initialAuthCode = initialAuthCode || undefined

      reqOwningBuildingUnits.push(owningBuildingDto)
    })

    const errors = this.errors
    return { reqBuilding, reqOwningBuildingUnits, errors }
  }

  private _buildingParseValidator(parsed: ExcelJs.CellValue[][]): boolean {
    // 先頭行の見出しの並びが適当
    if (!parsed || !parsed.length) return false
    return STATIC_HEADERS_BUILDINGS.every((h, idx) => h === parsed[0][idx]) &&
      (() => {
        const target = parsed.slice(DATA_START_ROW_IDX_BUILDINGS)
        const row = new Set(target.map(t => `${t[0]}${t[1]}${t[2]}${t[3]}`))
        return target.length === row.size
      })()
  }

  private _unitsParseValidator(parsed: ExcelJs.CellValue[][]): boolean {
    // 先頭行の見出しの並びが適当
    if (!parsed || !parsed.length) return false
    return STATIC_HEADERS_UNITS.every((h, idx) => h === parsed[0][idx]) &&
      (() => {
        const target = parsed.slice(DATA_START_ROW_IDX_UNITS)
        const row = new Set(target.map(t => `${t[0]}${t[1]}${t[2]}${t[3]}${t[4]}${t[5]}${t[6]}${t[7]}${t[8]}${t[9]}${t[10]}${t[11]}${t[12]}${t[13]}`))
        return target.length === row.size
      })()
  }

  private errors = new Map<string, string>() // 項目名、エラーメッセージを保持

  /**
   * エラーメッセージをMapにセットする
   * @param key キー（項目名）
   * @param error エラーメッセージ
   * @param row 項番
   */
  private _setError(key: string, error: string, row?: string): void {
    const keyError = `${key}|${error}` // 項目名+エラーメッセージで一意となるキーを生成
    const exists = this.errors.get(keyError)
    if (exists && exists.includes(error) && row) { // すでに登録されているエラーがあり項番を指定している場合、項番を最後尾に追加
      this.errors.set(keyError, exists.replace(/#\d+(?!.*#\d+)/, `$&,${row}`))
      return
    }
    this.errors.set(keyError, `${row ?? ''}${error}`)
  }

  private _toString(data: unknown): string {
    if (data === null || data === undefined) return ''
    if (this._isRichText(data)) {
      return data.richText.flatMap(val => val.text).join('') // セルに色がついている場合に文字列部分のみを取得
    }
    return String(data)
  }

  private _isRichText(data: unknown): data is ExcelJs.CellRichTextValue {
    if (typeof data !== 'object' || data === null) return false
    return 'richText' in data
  }

  private _toHalfWidth(data: string): string {
    if (!data) return data
    return data.replace(/[Ａ-Ｚａ-ｚ０-９－．]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0) // 文字コード -0xFEE0 で全角から半角に変換
    })
  }

  private _getAreaType(areaName: string): AreaType | undefined {
    switch (areaName) {
      case AREA_TYPE_MAP.get(1)?.LABEL: // 'HCM東京'
        return AREA_TYPE.TOKYO
      case AREA_TYPE_MAP.get(2)?.LABEL: // 'HCM関西'
        return AREA_TYPE.KANSAI
      case AREA_TYPE_MAP.get(3)?.LABEL: // 'CMQ'
        return AREA_TYPE.CMQ
      case AREA_TYPE_MAP.get(4)?.LABEL: // 'CMW'
        return AREA_TYPE.CMW
      case AREA_TYPE_MAP.get(5)?.LABEL: // 'CMO'
        return AREA_TYPE.CMO
      default:
        return undefined // エラー
    }
  }

  /**
   * データの整合性をチェックします。エラーの場合はエラーメッセージを返します。
   * @param data データ
   * @param isRequired 必須かチェックする場合に設定（デフォルト：true）
   * @param maxLength 最大文字数を超えているかチェックする場合に設定
   * @param fixedLength 文字数と一致するかチェックする場合に設定
   * @param isDate 日付形式かチェックする場合に設定
   * @param isPostalCode 郵便番号形式がチェックする場合に設定
   * @param isAlphaNumeric 英数字のみかチェックする場合に設定
   * @param minNumber 最小の数値未満かチェックする場合に設定
   * @param minMaxInt 整数の範囲内かチェックする場合に設定
   * @param minMaxLength 桁数の範囲内かチェックする場合に設定
   * @return データが正しい場合：undefined、データが不正な場合：エラーメッセージ
   */
  private _invalidateData({ data, isRequired = true, maxLength = undefined, fixedLength = undefined, isDate = false, isPostalCode = false, isAlphaNumeric = false, fixedNumericLength = undefined, minNumber = undefined, minMaxInt = undefined, minMaxLength = undefined, isNumeric = false }: ValidateOptions): string | undefined {
    if (isRequired && !data) return IS_NOT_EMPTY_MESSAGE
    if (maxLength && data && data.length > maxLength) return `は${maxLength}${MAX_LENGTH_MESSAGE}`
    if (fixedLength && data && data.length !== fixedLength) return `は${fixedLength}${FIXED_LENGTH_MESSAGE}`
    if (isDate && data && isNaN(new Date(data).getTime())) return IS_DATE_MESSAGE
    if (isPostalCode && data && !/^[0-9]{3}-[0-9]{4}$/.test(data)) return IS_POSTAL_CODE_MESSAGE
    if (isAlphaNumeric && data && !/^[0-9a-zA-Z]*$/.test(data)) return IS_ALPHA_NUMERIC_MESSAGE
    if (fixedNumericLength && data && (data.length !== fixedNumericLength || !/^[0-9]*$/.test(data))) return `は${fixedNumericLength}${IS_NUMERIC_LENGTH_MESSAGE}`
    if (minNumber !== undefined && data && Number(data) < minNumber) return `は${minNumber}${MIN_NUMBER_MESSAGE}`
    if (minMaxInt && data && (!Number.isInteger(Number(data)) || Number(data) < minMaxInt[0] || Number(data) > minMaxInt[1])) { return `は${minMaxInt[0]}~${minMaxInt[1]}${MIN_MAX_INT_MESSAGE}` }
    if (minMaxLength && data && (data.length < minMaxLength.minLength || data.length > minMaxLength.maxLength)) return `は${minMaxLength.minLength}~${minMaxLength.maxLength}${FIXED_LENGTH_MESSAGE}`
    if (isNumeric && data && !Number(data)) return IS_NUMERIC
    return undefined
  }
}

export const buildingExcelParser = new BuildingExcelParser()
