import { render, staticRenderFns } from "./SimpleRepairPlanDetailPage.vue?vue&type=template&id=6a50bb92&scoped=true&"
import script from "./SimpleRepairPlanDetailPage.vue?vue&type=script&lang=ts&"
export * from "./SimpleRepairPlanDetailPage.vue?vue&type=script&lang=ts&"
import style0 from "./SimpleRepairPlanDetailPage.vue?vue&type=style&index=0&id=6a50bb92&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a50bb92",
  null
  
)

export default component.exports