import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { dashboardClient } from '@/clients/dashboard-client'

import { DashboardSummaryGetRequest, DashboardSummaryGetResponse } from '@/dtos/dashboard/summary/get'
import { DashboardProcessingStatesGetResponse } from '@/dtos/dashboard/processing-states/get'

@Module({ store, dynamic: true, namespaced: true, name: 'dashboard' })
class DashboardStore extends VuexModule {
  // ダッシュボードサマリエリア取得
  private _dashboardSummary:DashboardSummaryGetResponse = new DashboardSummaryGetResponse()

  get getDashboardSummary(): DashboardSummaryGetResponse { return this._dashboardSummary }

  @Mutation
  private SET_DASHBOARD_SUMMARY(res: DashboardSummaryGetResponse): void {
    this._dashboardSummary = res
  }

  @Action
  async fetchDashboardSummary(req: DashboardSummaryGetRequest): Promise<void> {
    const res = await dashboardClient.getDashboardSummary(req)
    this.SET_DASHBOARD_SUMMARY(res)
  }

  // 担当者別処理状況エリア取得
  private _dashboardProcessingStates: DashboardProcessingStatesGetResponse = new DashboardProcessingStatesGetResponse()

  get getDashboardProcessingStates(): DashboardProcessingStatesGetResponse { return this._dashboardProcessingStates }

  @Mutation
  private SET_DASHBOARD_PROCESSING_STATES(res: DashboardProcessingStatesGetResponse): void {
    this._dashboardProcessingStates = res
  }

  @Action
  async fetchDashboardProcessingStates(): Promise<void> {
    const res = await dashboardClient.getDashboardProcessingStates()
    this.SET_DASHBOARD_PROCESSING_STATES(res)
  }
}
export const dashboardModule = getModule(DashboardStore)
