


































































































































import { Vue, Component, Model } from 'vue-property-decorator'

import { BuildingGroupType, BUILDING_GROUP_TYPE, BUILDING_MENU_ITEMS } from '@/constants/schema-constants'
import { Header } from '@/components/molecules/SmTableDataExternalPaging.vue'

import { Building } from '@/dtos/buildings/get'
import { QuestionnaireAnswerLibraryPostRequest, QuestionnaireAnswerLibraryItem } from '@/dtos/questionnaires/reports/answer-libraries/post'

import { getGroupLabel } from '@/libs/type-handler'
import { generateUuid } from '@/libs/uuid-generator'

import { buildingsModule } from '@/stores/buildings-store'
import { questionnaireReportsModule } from '@/stores/questionnaire-reports-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    BuildingGroupSelectModal: () => import('@/components/organisms/modal/BuildingGroupSelectModal.vue'),
    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class QuestionnaireAnswerLibrarySelectModal extends Vue {
  BUILDING_MENU_ITEMS = Object.freeze(BUILDING_MENU_ITEMS)

  @Model('change', { required: true, default: false })
  private readonly isVisible!: boolean

  // -----------------------変数------------------------------
  private textValue = ''
  private keyword = ''

  private selectedAnswerIds: string[] = []

  selectedBuildingMenuId = 'all'
  selectedBuildings: Building[] = []
  selectedBuildingsByEach: Building[] = []
  BuildingKeyword = ''
  BuildingInputText = ''
  isBuildingSelectModalVisible = false
  buildingSelectModalKey = generateUuid()

  isBuildingGroupSelectModalVisible = false
  buildingGroupSelectModalKey = generateUuid()
  selectedBuildingGroupType: BuildingGroupType = BUILDING_GROUP_TYPE.TOKYO

  // -----------------------定数------------------------------
  headers : Header[] = [
    new Header({ text: 'マンション名', value: 'buildingName', width: '117px' }),
    new Header({ text: '意見・要望', value: 'freeAnswers', width: '474px' }),
    new Header({ text: '回答', value: 'answerBody', width: '273px' }),
    new Header({ text: '', value: 'actions', width: '56px' }),
  ]

  // -----------------------マンション選択・グループ選択モーダル用 メソッド------------------------------
  private openBuildingSelectModal(): void {
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingSelectModalVisible = true
  }

  private openBuildingGroupSelectModal(): void {
    this.buildingGroupSelectModalKey = generateUuid()
    this.isBuildingGroupSelectModalVisible = true
  }

  private async onChangeBuildingMenu(): Promise<void> {
    switch (this.selectedBuildingMenuId) {
      case 'all':
        this.onChangeBuilding()
        break
      case 'group':
        this.openBuildingGroupSelectModal()
        break
      case 'each':
        this.openBuildingSelectModal()
        break
    }
  }

  private get selectedBuildingDisplay(): string {
    switch (this.selectedBuildingMenuId) {
      case 'all':
        return 'すべてのマンション'
      case 'group':
        return getGroupLabel(this.selectedBuildingGroupType)
      case 'each':
        return this.buildingNames
      default:
        return 'すべてのマンション'
    }
  }

  private async onChangeBuilding(buildingIds?: string[]): Promise<void> {
    this.selectedBuildings = this.getTargetBuildings(buildingIds)
    this.selectedBuildingsByEach = this.selectedBuildingMenuId === 'each' ? this.selectedBuildings : []
    if (this.selectedBuildingMenuId === 'all' || this.selectedBuildingMenuId === 'each') this.selectedBuildingGroupType = BUILDING_GROUP_TYPE.TOKYO
    if (this.selectedBuildingMenuId !== 'each') {
      this.BuildingKeyword = ''
      this.BuildingInputText = ''
    }

    this.refreshTable()
  }

  private getTargetBuildings(buildingIds?: string[]): Building[] {
    return buildingsModule.buildingsGet.buildings.filter(b => buildingIds?.includes(b.buildingId))
  }

  private getBuildingIdsByGroupType(buildingGroupType:BuildingGroupType, buildings: Building[]): string[] {
    return this.getBuildingsByGroupType(buildingGroupType, buildings).map(e => e.buildingId)
  }

  private getBuildingsByGroupType(buildingGroupType:BuildingGroupType, buildings: Building[]): Building[] {
    return buildings.filter(e => e.buildingAreaType === buildingGroupType)
  }

  private setBuildingGroupType(selectedGroupType: BuildingGroupType): void {
    this.selectedBuildingGroupType = selectedGroupType
    const buildingIds = this.getBuildingIdsByGroupType(selectedGroupType, buildingsModule.buildingsGet.buildings)
    this.onChangeBuilding(buildingIds)
  }

  private async refreshTable(): Promise<void> {
    await questionnaireReportsModule.fetchQuestionnaireAnswerLibraries(new QuestionnaireAnswerLibraryPostRequest(this.buildingIds))
  }

  private get buildingIds():string[] {
    return this.selectedBuildings.map(b => b.buildingId)
  }

  private get buildingNames(): string {
    return this.selectedBuildings.map(b => b.buildingName).join('｜')
  }

  // ----------------------- その他 ------------------------------
  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get answerLibraries():QuestionnaireAnswerLibraryItem[] {
    return questionnaireReportsModule.answerLibrariesGet.questionnaireAnswerLibraries
  }

  private get isSelected(): boolean {
    return !!this.selectedAnswerIds.length
  }

  async onClickDeleteIcon(item: QuestionnaireAnswerLibraryItem): Promise<void> {
    this.$emit('delete', item, this.buildingIds)
  }

  private onClickBtn():void {
    this.$emit('select', this.answerLibraries.filter(answer => answer.questionnaireReportResultSectionAnswerId === this.selectedAnswerIds[0])[0].answerBody)
    this.isModalVisible = false
  }
}
