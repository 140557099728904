






































import type { BtnType } from '@/constants/ux-constants'
import { Vue, Component, Prop, Model } from 'vue-property-decorator'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDialogBase: () => import('@/components/organisms/dialog/SmDialogBase.vue'),
  }
})
export default class SmDialogText extends Vue {
  @Prop({ required: true, default: '' })
  private readonly message!: string

  @Prop()
  private readonly caption?: string

  @Prop({ default: 'sm__caption' })
  private readonly captionFormat!: string

  @Prop({ default: '2' })
  private readonly captionFontColorLevel!: string

  @Prop({ default: false })
  private readonly showCloseIcon!: boolean

  @Prop()
  private readonly cancelBtnLabel?: string

  @Prop()
  private readonly executionBtnLabel?: string

  @Prop({ default: 'reflection' })
  private readonly executionBtnType!: BtnType

  @Prop()
  private readonly height?: string

  @Model('change')
  private isVisible!: boolean
}
