














































import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { errorsModule } from '@/stores/errors'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})

export default class SmSelect extends Vue {
  private get fieldErrors() {
    if (!this.fieldId) return undefined
    return errorsModule.fieldErrors(this.fieldId)?.join(' ')
  }

  @Prop({ required: true, default: () => [] })
  private readonly items!: { value: string | number | null, label: string }[]

  @Prop()
  private readonly label?: string

  @Prop()
  private readonly fieldId?: string

  @Prop({ default: () => [] })
  private readonly validationRules!: string[]

  @Prop({ default: false })
  private readonly required!: boolean

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop()
  private readonly placeholder?: string

  @Prop({ default: 'min-content' })
  private readonly width!: string

  @Prop({ default: 'auto' })
  private readonly minWidth!: string

  @Prop({ default: true })
  private hideDetails!: boolean | string

  @Prop()
  @Model('change')
  private value!: string | number | null

  @Prop()
  private validationLabel?: string

  @Prop({ default: false })
  private readonly immediate!: boolean

  private get selectedValue(): string | number | null { return this.value }
  private set selectedValue(newValue: string | number | null) {
    if (this.fieldId && errorsModule.fieldErrors(this.fieldId)) errorsModule.clearSingleFieldError(this.fieldId)
    this.$emit('change', newValue)
  }

  private get rules(): string {
    const rules = [...this.validationRules]
    if (this.required) rules.push('required')
    return rules.join('|')
  }
}

