import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyInitialized, store } from './index'
import { staticAssetsClient } from '@/clients/static-assets-client'
import { ConsultationReactionsResponse } from '@/dtos/static-assets/get'
import type { Reaction } from '@/dtos/static-assets/get'

@Module({ store, dynamic: true, namespaced: true, name: 'staticAssets', preserveState: isAlreadyInitialized })
class StaticAssetsStore extends VuexModule {
  // リアクション一覧の取得
  private _reactions: Reaction[] = []

  get reactions(): Reaction[] { return this._reactions }

  @Mutation
  private SET_REACTION_GET(res: ConsultationReactionsResponse): void {
    this._reactions = res.reactions
  }

  @Action
  async fetchReactions(): Promise<void> {
    const res = await staticAssetsClient.getConsultationReactions()
    this.SET_REACTION_GET(res)
  }
}

export const staticAssetsModule = getModule(StaticAssetsStore)
