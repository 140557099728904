import { BaseIdea, Estimate, Section } from '@/dtos/commons'

export class OnlineResolutionsPutRequest {
  resolutionId: string
  title: string
  deadline: string
  voteDeadline: string
  matter: string
  baseIdea?: BaseIdea
  estimates: Estimate[]
  sections: Section[]
  version?: number

  constructor(resolutionId: string, title: string, deadline: string, voteDeadline: string, matter: string,
    estimates: Estimate[], sections: Section[], version?: number, baseIdea?: BaseIdea) {
    this.resolutionId = resolutionId
    this.title = title
    this.deadline = deadline
    this.voteDeadline = voteDeadline
    this.matter = matter
    this.estimates = estimates
    this.sections = sections
    this.version = version
    this.baseIdea = baseIdea
  }
}
