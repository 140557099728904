




























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { QUESTIONNAIRE_STATES } from '@/constants/schema-constants'
import type { QuestionnaireState } from '@/constants/schema-constants'
import { ChipColors, CHIP_COLORS } from '@/constants/ux-constants'
import { getQuestionnaireLabel } from '@/libs/state-handler'

@Component({
  components: {
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmCardQuestionnaire extends Vue {
  private CHIP_COLORS = Object.freeze(CHIP_COLORS)
  private QUESTIONNAIRE_STATES = Object.freeze(QUESTIONNAIRE_STATES)

  @Prop({ required: true, default: '' })
  private templateName!: string

  @Prop({ required: true, default: QUESTIONNAIRE_STATES.DRAFT })
  private questionnaireState!: QuestionnaireState

  @Prop({ required: true, default: '' })
  private title!: string

  @Prop({ required: true, default: '' })
  private answerDeadline!: string

  @Prop({ required: true, default: '' })
  private postedAt!: string

  @Prop({ required: true, default: '' })
  private postedTo!: string

  @Prop()
  private readCount?: number

  @Prop()
  private answerCount?: number

  private get questionnaireStateLabel():string {
    return getQuestionnaireLabel(this.questionnaireState)
  }

  private get chipColor(): ChipColors {
    return this.questionnaireState === QUESTIONNAIRE_STATES.DRAFT ? CHIP_COLORS.GREEN : this.questionnaireState === QUESTIONNAIRE_STATES.SCHEDULED ? CHIP_COLORS.WHITE1 : CHIP_COLORS.WHITE2
  }

  private get isBorder():boolean {
    return this.questionnaireState === QUESTIONNAIRE_STATES.SCHEDULED
  }
}
