import { CaseType, InformationSourceType, TicketType } from '@/constants/schema-constants'
import { TicketsPostRequestBuilding, TicketsPostRequestOwnerUser } from './post'

export class TicketBaseIdea {
  ideaId: string
  sortOrderNum: number

  constructor(ideaId: string, sortOrderNum: number) {
    this.ideaId = ideaId
    this.sortOrderNum = sortOrderNum
  }
}

export class TicketDetailPutRequest {
  ticketId!: string
  ticketType!: TicketType
  ticketName!: string
  receptionDate!: string
  informationSourceType!: InformationSourceType
  informationSource?: string
  caseType!: CaseType
  inquiryBody?: string
  baseIdeas?: TicketBaseIdea[]
  correspondence?: string
  isManagementStatusReport!: boolean
  isOperationStatusReport!: boolean
  building?: TicketsPostRequestBuilding
  ownerUser?: TicketsPostRequestOwnerUser
  version!: number
}
