import { Type } from 'class-transformer'
import { User } from '@/dtos/commons'
import type { TicketState, TicketType } from '@/constants/schema-constants'

export class TicketsGetRequest {
  ticketId!: string

  constructor(ticketId: string) {
    this.ticketId = ticketId
  }
}

export class TicketGetResponse {
  ticketId!: string
  ticketNo!: string
  ticketName!: string
  ticketType!: TicketType
  ticketState!: TicketState
  postedAt!: string
  completedAt?: string
  @Type(() => User)admin = new User()
  isAllTasksCompleted!: boolean
  version!: number
}
