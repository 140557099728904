import * as ExcelJs from 'exceljs'
import { ExcelParserBase } from './excel-parser-base'

import { ReservesPersonalPlanProspectsGetResponse, PlanProspectsUnitPrice, PlanProspectsApplyingSummary, PlanProspectsPaymentPlan } from '@/dtos/reserve-funds/payments/plans/prospects/get'
import { ReservePaymentDetailGetResponse, UnitPlanDetailMonthlyPrice } from '@/dtos/reserve-funds/payments/get-detail'

// ---------------
//  1: プラン名
//  2: マンション名
//  3: 住戸番号
//  4: 専有面積
//  5: 空行（"適用開始"マークあり）
//  6: 表のヘッダ（期）
//  7: 最低積立規定額 - ㎡単価
//  8: 最低積立規定額 - 月額
//  9: 最低積立規定額 - 年額累計
// 10: 現在のプラン - ㎡単価（平均）
// 11: 現在のプラン - 月額（平均）
// 12: 現在のプラン - 年額累計
// 13: 現在のプラン - 最低積立規定額との差額（累計）
// 14: 切替先のプラン - ㎡単価
// 15: 切替先のプラン - 月額
// 16: 切替先のプラン - 年額累計
// 17: 切替先のプラン - 最低積立規定額との差額（累計）
// 18: 現在⇔切替先の差分 - 単年
// 19: 現在⇔切替先の差分 - 累計
// 20: 空行
// 21: 検討プラン - ㎡単価
// 22: 検討プラン - 月額
// 23: 検討プラン - 年額累計
// 24: 検討プラン - 最低積立規定額との差額
//
// ㎡単価・月額は設定のある欄のみ表示、年額累計・差額は最低積立規定額の設定がある期は全て表示
// ---------------------------------------------------------------------------

const fill = <T>(length: number, val: T) => Array(length).fill(val)

class RervesPersonalPlanParser extends ExcelParserBase {
  async downloadAsExcel(buildingName: string, prospect: ReservesPersonalPlanProspectsGetResponse, currentPlan: ReservePaymentDetailGetResponse) {
    const fileName = `専用支払いプラン検討_${buildingName}_${prospect.roomNumber}`
    // 現在適用中のプラン以外が出力する検討対象
    const comparisons = prospect.paymentPlans.filter(p => p.planCode !== currentPlan.planCode)

    // 検討対象ごとのシートは大部分が共通の構造で成立
    const template = this._formatCommonRows(buildingName, prospect, currentPlan)

    if (comparisons.length > 0) {
      await this.toExcel(
        fileName,
        comparisons.map(plan => this._formatSinglePlan(template, prospect.applyingSummary, plan)),
        comparisons.map(p => `${p.planCode}_${p.planName}`),
      )
    } else {
      // 移行検討先にあたるプランが無い場合はそれと分かるようなシートを1枚出力
      await this.toExcel(
        fileName,
        this._formatSinglePlan(template, prospect.applyingSummary),
      )
    }
  }

  private _formatSinglePlan(template: ExcelJs.CellValue[][], summary: PlanProspectsApplyingSummary, plan?: PlanProspectsPaymentPlan): (sheet: ExcelJs.Worksheet) => void {
    return (sheet: ExcelJs.Worksheet) => {
      // 共通の設定
      sheet.views = [{ state: 'frozen', xSplit: 2, ySplit: 0, showGridLines: false, zoomScale: 100 }]
      sheet.properties.defaultRowHeight = 15
      sheet.properties.defaultColWidth = 12; sheet.getColumn(1).width = 40; sheet.getColumn(2).width = 30

      sheet.addRows(template)

      if (plan) {
        // 切替検討先によって異なる行（14-17）を後埋め
        sheet.getRow(1).values = [`${this.esc(plan.planName)}（${plan.planCode}）`]
        const eachPlanRows = this._formatSpecificPlanRows(plan, summary)
        for (let i = 0; i < 4; i++) sheet.getRow(14 + i).values = eachPlanRows[i]
      } else {
        sheet.getRow(1).values = ['※現在適用中のもの以外の支払いプランがありません']
      }

      // 書式設定
      // -- タイトルだけ大きく
      sheet.getCell('A1').font = { size: 16 }

      // -- ヘッダ列はマージ
      this.mergeByRange(sheet, true, 'A6:B6', 'A7:A9', 'A10:A13', 'A14:A17', 'A18:A19', 'A21:A24')

      // -- 表部分は枠で囲う
      const lastCol = this.idxToAlpha(sheet.getRow(6).cellCount) // 表のヘッダ行の長さ分値が埋まっているはず
      this.borderizeByRange(sheet, 'thin', 'A2:B4', `A6:${lastCol}19`, `A21:${lastCol}24`)
      this.surroundByRange(sheet, 'medium', `A6:${lastCol}6`, `A7:${lastCol}9`, `A10:${lastCol}13`, `A14:${lastCol}17`, `A18:${lastCol}19`, `A21:${lastCol}24`)

      // -- 差額系の行は値がマイナスであれば赤字にする
      const makeRowRed = (rowIdx: number) => {
        sheet.addConditionalFormatting({ ref: `${rowIdx}:${rowIdx}`, rules: [{ type: 'cellIs', operator: 'lessThan', formulae: [0], priority: 0, style: { font: { color: { argb: 'FFFF0000' } } } }] })
      }
      [13, 17, 18, 19, 24].forEach(row => makeRowRed(row))

      // -- 注目すべきセルに蛍光色、入力不可なセルに灰色の背景色を付けて強調
      sheet.getCell('D5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F79646' } }
      this.getRange(sheet, `D21:${lastCol}21`).forEach(cell => { cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '92D050' } } });
      ['C7', 'C8', 'C10', 'C11', 'C14', 'C15', 'C18', 'C21', 'C22'].forEach(cr => { sheet.getCell(cr).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'A6A6A6' } } })

      // -- 表の数字は全て3桁ごとにカンマ区切りで表記、専有面積はそれに加えて小数点以下第二位まで表示
      sheet.eachRow((row, idx) => { if (idx >= 7) row.numFmt = '#,##0' })
      sheet.getCell('B4').numFmt = '#,##0.00'
    }
  }

  private _formatCommonRows(buildingName: string, prospect: ReservesPersonalPlanProspectsGetResponse, currentPlan: ReservePaymentDetailGetResponse): ExcelJs.CellValue[][] {
    const topRows = [
      [/* plan name */],
      ['マンション名', this.esc(buildingName)],
      ['住戸番号', this.esc(prospect.roomNumber)],
      ['専有面積（㎡）', prospect.occupiedArea],
      [null, null, null, '適用開始→'],
    ]

    const periods = prospect.reservePlan.map(r => r.period)
    const headerRow = this._formatHeaderRow(prospect.applyingSummary, periods)

    const minPriceRows = this._formatMinPriceRows(prospect.reservePlan, prospect.applyingSummary)
    const currentPlanRows = this._formatCurrentPlanRow(currentPlan, prospect.applyingSummary, periods[0])
    const diffRows = this._formatDiffRows(prospect.applyingSummary)
    const consideringRows = this._formatConsideringRows(prospect.applyingSummary)

    return [
      ...topRows, // 1-5
      headerRow, // 6
      ...minPriceRows, // 7-9
      ...currentPlanRows, // 10-13
      [], [], [], [], // 14-17（個別に埋める）
      ...diffRows, // 18, 19
      [], // 20（空行）
      ...consideringRows, // 21-24
    ]
  }

  private _formatHeaderRow(summary: PlanProspectsApplyingSummary, periods: number[]) {
    const suf = (period: number) => `${period}期`

    // 適用月がちょうど期の切り替わりの場合
    if (summary.remainingMonth === 12) return [null, null, suf(periods[0] - 1), ...periods.map(suf)]

    // 期中切り替わりの場合、1,2マス目が同じ期になり境の月を併記
    const _m = Number(summary.appliedAt.split('-')[1])
    const appliedTo = _m === 1 ? 12 : _m - 1
    const appliedFrom = _m

    const header = [null, null, suf(periods[0]), ...periods.map(suf)]
    header[2] += `（～${appliedTo}月）`
    header[3] += `（${appliedFrom}月～）`
    return header
  }

  private _formatMinPriceRows(reservePlans: PlanProspectsUnitPrice[], summary: PlanProspectsApplyingSummary) {
    return [
      ['最低積立規定額', '㎡単価', null, ...reservePlans.map(r => r.unitPrice)],
      [null, '月額', null, { shareType: 'shared', ref: 'D8:CZ8', formula: 'IF(ISNUMBER(D7),ROUND(D7*$B$4,-1),"")' }, ...fill(100, { sharedFormula: 'D8' })],
      [
        null, '年額累計', summary.minReservedAmount,
        { formula: `IF(ISNUMBER(D7),C9+D8*${summary.remainingMonth},"")` },
        { shareType: 'shared', ref: 'E9:CZ9', formula: 'IF(ISNUMBER(E7),D9+E8*12,"")' }, ...fill(99, { sharedFormula: 'E9' }),
      ],
    ]
  }

  private _formatCurrentPlanRow(currentPlan: ReservePaymentDetailGetResponse, summary: PlanProspectsApplyingSummary, appliedPeriod: number) {
    const calcAve = (prices: UnitPlanDetailMonthlyPrice[], monthCount: number) => {
      const yearTotal = prices.slice(-monthCount).reduce((total, month) => { total += month.monthlyPrice ?? 0; return total }, 0)
      return Math.round(yearTotal / monthCount)
    }
    const monthlyAves = currentPlan.paymentPlanDetail.filter(d => d.period >= appliedPeriod).map(d => calcAve(d.monthlyPrices, d.period === appliedPeriod ? summary.remainingMonth : 12))

    return [
      [`現在の支払いプラン：\r\n${currentPlan.planName ?? '（無し）'}`, '㎡単価（平均）', null, { shareType: 'shared', ref: 'D10:CZ10', formula: 'IF(ISNUMBER(D11),ROUND(D11/$B$4,0),"")' }, ...fill(100, { sharedFormula: 'D10' })],
      [null, '月額（平均）', null, ...monthlyAves],
      [
        null, '年額累計', summary.recordedReservedAmount,
        { formula: `IF(ISNUMBER(D9),C12+IF(ISNUMBER(D11),D11*${summary.remainingMonth},0),"")` },
        { shareType: 'shared', ref: 'E12:CZ12', formula: 'IF(ISNUMBER(E9),D12+IF(ISNUMBER(E11),E11*12,0),"")' }, ...fill(99, { sharedFormula: 'E12' }),
      ],
      [null, '最低額との差（累計）', { shareType: 'shared', ref: 'C13:CZ13', formula: 'IF(ISNUMBER(C12),C12-C9,"")' }, ...fill(100, { sharedFormula: 'C13' })],
    ]
  }

  private _formatSpecificPlanRows(plan: PlanProspectsPaymentPlan, summary: PlanProspectsApplyingSummary) {
    return [
      [`変更したいプラン：\r\n${plan.planName}`, '㎡単価', null, ...plan.paymentPlanDetail.map(d => d.unitPrice)],
      [null, '月額', null, { shareType: 'shared', ref: 'D15:CZ15', formula: 'IF(ISNUMBER(D14),ROUND(D14*$B$4,-1),"")' }, ...fill(100, { sharedFormula: 'D15' })],
      [
        null, '年額累計', plan.planReservedAmount,
        { formula: `IF(ISNUMBER(D9),C16+IF(ISNUMBER(D15),D15*${summary.remainingMonth},0),"")` },
        { shareType: 'shared', ref: 'E16:CZ16', formula: 'IF(ISNUMBER(E9),D16+IF(ISNUMBER(E15),E15*12,0),"")' }, ...fill(99, { sharedFormula: 'E16' }),
      ],
      [null, '最低額との差（累計）', { shareType: 'shared', ref: 'C17:CZ17', formula: 'IF(ISNUMBER(C16),C16-C9,"")' }, ...fill(100, { sharedFormula: 'C17' })],
    ]
  }

  private _formatDiffRows(summary: PlanProspectsApplyingSummary) {
    return [
      [
        '変更検討先 － 現在', '単年', null,
        { formula: `IF(ISNUMBER(D15),(D15-IF(ISNUMBER(D10),D10,0))*${summary.remainingMonth},"")` },
        { shareType: 'shared', ref: 'E18:CZ18', formula: 'IF(ISNUMBER(E15),(E15-IF(ISNUMBER(E10),E10,0))*12,"")' }, ...fill(99, { sharedFormula: 'E18' })
      ],
      [null, '累計', { shareType: 'shared', ref: 'C19:CZ19', formula: 'IF(ISNUMBER(C16),C16-IF(ISNUMBER(C12),C12,0),"")' }, ...fill(100, { sharedFormula: 'C19' })],
    ]
  }

  private _formatConsideringRows(summary: PlanProspectsApplyingSummary) {
    return [
      ['検討プラン', '㎡単価'],
      [null, '月額', null, { shareType: 'shared', ref: 'D22:CZ22', formula: 'IF(ISNUMBER(D21),ROUND(D21*$B$4,-1),"")' }, ...fill(100, { sharedFormula: 'D22' })],
      [
        null, '年額累計', { formula: 'IF(ISNUMBER(C12),C12,0)' },
        { formula: `IF(ISNUMBER(D9),C23+IF(ISNUMBER(D22),D22*${summary.remainingMonth},0),"")` },
        { shareType: 'shared', ref: 'E23:CZ23', formula: 'IF(ISNUMBER(E9),D23+IF(ISNUMBER(E22),E22*12,0),"")' }, ...fill(99, { sharedFormula: 'E23' }),
      ],
      [null, '最低額との差（累計）', { shareType: 'shared', ref: 'C24:CZ24', formula: 'IF(ISNUMBER(C23),C23-C9,"")' }, ...fill(100, { sharedFormula: 'C24' })],
    ]
  }
}

export const reservesPersonalPlanParser = new RervesPersonalPlanParser()
