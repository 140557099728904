import { Type } from 'class-transformer'

export class OwnersGetRequest {
  isInitialAuth!: boolean
  keyword?: string
}

export class Owner {
  userId!: string
  userName!: string
  roomNumber!: string
  iconImagePath!: string
}

export class OwnersGetResponse {
  @Type(() => Owner) owners: Owner[] = []
}
