import { Type } from 'class-transformer'

export class UnitsGetRequest {
  keyword?: string
  constructor(keyword?: string) { this.keyword = keyword }
}

export class Unit {
  unitId!: string
  userId!: string
  userName!: string
  casysUserName!: string
  roomNumber!: string
  isAlias!: boolean
  votingRightCount!: number
  isInitialAuth!: boolean
  isLivingOutside!: boolean
  isPaperUseApplied!: boolean
  ownedDwellingUnits?: string
  initialAuthCode?: string
  adminShortName?: string
  emailAddress?: string
}

export class UnitsGetResponse {
  initialAuthCount!: number
  ownerCount!: number
  paperUseAppliedCount!: number
  @Type(() => Unit) units:Unit[] = []
}
