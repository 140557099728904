import { Material } from '@/dtos/commons'

export class TicketCommentsPostRequest {
  ticketId: string
  ticketCommentBody: string
  material?: Material

  constructor(ticketId: string, ticketCommentBody: string, material?: Material) {
    this.ticketId = ticketId
    this.ticketCommentBody = ticketCommentBody
    this.material = material
  }
}
