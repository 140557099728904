import { APIClientBase } from './api-client-base'
import { IDEA_TYPES } from '@/constants/schema-constants'
import { OwnerIdeasGetRequest, OwnerIdeasGetResponse } from '@/dtos/ideas/owner-idea/get'
import { OwnerIdeaDetailGetRequest, OwnerIdeaDetailGetResponse } from '@/dtos/ideas/owner-idea/get-detail'
import { OwnerIdeasPostRequest } from '@/dtos/ideas/owner-idea/post'
import { OwnerIdeasDraftPostRequest } from '@/dtos/ideas/owner-idea/draft/post'
import { OwnerIdeasDeleteRequest } from '@/dtos/ideas/owner-idea/delete'
import { OwnerIdeasRejectRequest } from '@/dtos/ideas/owner-idea/reject/put'
import { OwnerIdeasDraftDeleteRequest } from '@/dtos/ideas/owner-idea/draft/delete'
import { AdminIdeaPreparingStatusPostRequest, AdminIdeaPreparingStatusPostResponse } from '@/dtos/ideas/owner-idea/admin-idea-preparing-status/post'
import { AdminIdeaPreparingStatusDeleteRequest } from '@/dtos/ideas/owner-idea/admin-idea-preparing-status/delete'
import { AdminIdeaPreparingStatusPutRequest, AdminIdeaPreparingStatusPutResponse } from '@/dtos/ideas/owner-idea/admin-idea-preparing-status/put'

class OwnerIdeasClient extends APIClientBase {
  constructor() {
    super(`/ideas/${IDEA_TYPES.OWNER_IDEA}`)
  }

  getIdeas(req: OwnerIdeasGetRequest) {
    return this.get('', req, OwnerIdeasGetResponse)
  }

  getIdeaDetail(req: OwnerIdeaDetailGetRequest) {
    return this.get(`${req.ideaId}`, '', OwnerIdeaDetailGetResponse)
  }

  postIdeas(req: OwnerIdeasPostRequest): Promise<void> {
    return this.post('', req)
  }

  postDraftIdeas(req: OwnerIdeasDraftPostRequest): Promise<void> {
    return this.post('/draft', req)
  }

  deleteIdeas(req: OwnerIdeasDeleteRequest): Promise<void> {
    return this.delete(`${req.ideaId}`)
  }

  deleteDraftIdeas(req: OwnerIdeasDraftDeleteRequest): Promise<void> {
    return this.delete(`/draft/${req.ideaId}`)
  }

  rejectIdeas(req: OwnerIdeasRejectRequest): Promise<void> {
    return this.put(`${req.ideaId}/reject`, req)
  }

  postAdminIdeaPreparingStatus(req: AdminIdeaPreparingStatusPostRequest) {
    const { ideaId, ...body } = req
    return this.post(`${ideaId}/preparing-status`, body, AdminIdeaPreparingStatusPostResponse)
  }

  putAdminIdeaPreparingStatus(req: AdminIdeaPreparingStatusPutRequest) {
    const { ideaId, ...body } = req
    return this.put(`${ideaId}/preparing-status`, body, AdminIdeaPreparingStatusPutResponse)
  }

  deleteAdminIdeaPreparingStatus(req: AdminIdeaPreparingStatusDeleteRequest) {
    return this.delete(`${req.ideaId}/preparing-status`, undefined)
  }
}

export const ownerIdeasClient = new OwnerIdeasClient()
