import type { OperationState } from '@/constants/schema-constants'

export class ManagementOperationsTaskStatusPutRequestExecutor {
  adminId?: string
  unregisteredExecutorId?: string
}

export class ManagementOperationsTaskStatusPutRequest {
  managementOperationTaskStatusId : string

  executor?: ManagementOperationsTaskStatusPutRequestExecutor
  updatedBy: string
  operationState: OperationState
  targetDate: string
  version: number
  completedAt?: string
  memo?: string

  constructor(managementOperationTaskStatusId : string, executor: ManagementOperationsTaskStatusPutRequestExecutor | undefined, updatedBy: string, operationState: OperationState, targetDate: string, version: number, completedAt: string | undefined, memo: string | undefined) {
    this.managementOperationTaskStatusId = managementOperationTaskStatusId
    this.executor = executor ?? undefined
    this.updatedBy = updatedBy
    this.operationState = operationState
    this.targetDate = targetDate
    this.version = version
    this.completedAt = completedAt ?? undefined
    this.memo = memo ?? undefined
  }
}

export class ManagementOperationsTaskStatusPutResponse {
  version!: number
}
