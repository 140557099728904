











































import { Vue, Component, Prop } from 'vue-property-decorator'
import type { Color, Level, Format } from './SmText.vue'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmTextConvertedToBoldAndStrikethrough extends Vue {
  @Prop()
  private text!: string

  @Prop()
  private format?: Format

  @Prop()
  private color?: Color

  @Prop()
  private level?: Level

  // textの中の太文字、または、取り消し線を付与する文字列を抽出する
  private get dividedText(): { type: 'text'|'bold'|'strikethrough', text: { type: 'text'|'bold'|'strikethrough', text: string }[] | string }[] {
    try {
      if (!this.text) return []

      const target = /\*\*(.*?)\*\*|~~(.*?)~~/g
      const results: { type: 'text'|'bold'|'strikethrough', text: { type: 'text'|'bold'|'strikethrough', text: string }[] | string }[] = []
      let currentIndex = 0

      for (const match of this.text.matchAll(target)) {
      // 現在位置から変換対象までの文字列を'text'として記録
        if (currentIndex !== match.index) {
          results.push({ type: 'text', text: this.text.substring(currentIndex, match.index) })
          currentIndex += (match.index ?? 0) - currentIndex
        }

        // 太文字を'bold'、取り消し線部分を'strikethrough'として記録し、現在位置をその終端まで進める
        if (match[1]) {
          const convertToBoldText = match[1] ?? match[0]
          results.push({ type: 'bold', text: this.divideStrikethroughIntoBold(convertToBoldText) })
        } else if (match[2]) {
          const convertToStrikethroughText = match[2] ?? match[0]
          results.push({ type: 'strikethrough', text: this.divideBoldIntoStrikethrough(convertToStrikethroughText) })
        }
        currentIndex += match[0].length
      }

      // 最後に、末尾の通常文字列を'text'として記録（マッチ0だった場合も含む）
      if (currentIndex !== this.text.length) results.push({ type: 'text', text: this.text.substring(currentIndex, this.text.length) })

      return results
    } catch { // support iOS 12.x or lower: https://caniuse.com/mdn-javascript_builtins_string_matchall
      return [{ type: 'text', text: this.text }]
    }
  }

  // 太文字にする文字列の中に取り消し線を付与する文字列が含まれていた場合に分別する
  divideBoldIntoStrikethrough(text: string): { type: 'text'|'bold', text: string }[] {
    try {
      if (!text) return []

      const target = /\*\*(.*?)\*\*/g
      const results: { type: 'text'|'bold', text: string }[] = []
      let currentIndex = 0

      for (const match of text.matchAll(target)) {
      // 現在位置から変換対象までの文字列を'text'として記録
        if (currentIndex !== match.index) {
          results.push({ type: 'text', text: text.substring(currentIndex, match.index) })
          currentIndex += (match.index ?? 0) - currentIndex
        }

        // 太文字を'bold'、取り消し線部分を'strikethrough'として記録し、現在位置をその終端まで進める
        results.push({ type: 'bold', text: match[1] ?? match[0] })
        currentIndex += match[0].length
      }

      // 最後に、末尾の通常文字列を'text'として記録（マッチ0だった場合も含む）
      if (currentIndex !== text.length) results.push({ type: 'text', text: text.substring(currentIndex, text.length) })

      return results
    } catch { // support iOS 12.x or lower: https://caniuse.com/mdn-javascript_builtins_string_matchall
      return [{ type: 'text', text: text }]
    }
  }

  // 取り消し線を付与する文字列の中に太文字にする文字列が含まれていた場合に分別する
  divideStrikethroughIntoBold(text: string): { type: 'text'|'strikethrough', text: string}[] {
    try {
      if (!text) return []

      const target = /~~(.*?)~~/g
      const results: { type: 'text'|'strikethrough', text: string }[] = []
      let currentIndex = 0

      for (const match of text.matchAll(target)) {
      // 現在位置から変換対象までの文字列を'text'として記録
        if (currentIndex !== match.index) {
          results.push({ type: 'text', text: text.substring(currentIndex, match.index) })
          currentIndex += (match.index ?? 0) - currentIndex
        }

        // 太文字を'bold'、取り消し線部分を'strikethrough'として記録し、現在位置をその終端まで進める
        results.push({ type: 'strikethrough', text: match[1] ?? match[0] })
        currentIndex += match[0].length
      }

      // 最後に、末尾の通常文字列を'text'として記録（マッチ0だった場合も含む）
      if (currentIndex !== text.length) results.push({ type: 'text', text: text.substring(currentIndex, text.length) })

      return results
    } catch { // support iOS 12.x or lower: https://caniuse.com/mdn-javascript_builtins_string_matchall
      return [{ type: 'text', text: text }]
    }
  }
}
