















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmTableCell extends Vue {
  @Prop()
  private readonly prospect?: number

  @Prop()
  private readonly total?: number

  @Prop({ default: false })
  private readonly mini!: boolean

  private get _value(): number | undefined {
    if (this.total || this.total === 0) {
      return this.total
    } else if (this.prospect || this.prospect === 0) {
      return this.prospect
    } else {
      return undefined
    }
  }
}
