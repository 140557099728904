






















import { Vue, Component, Model, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router/types'

@Component({
  components: {
    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
  }
})
export default class SmInterceptor extends Vue {
  @Model('change')
  private nextRoute!: Route | null

  private get _nextRoute(): Route | null { return this.nextRoute }
  private set _nextRoute(newRoute: Route | null) { this.$emit('change', newRoute) }

  @Watch('nextRoute', { immediate: false })
  onNextRouteUpdate(newRoute: Route | null): void {
    // どこかへ遷移しようとした時（null => Route）にダイアログを表示させる
    if (newRoute) this.confirmDialogVisible = true
  }

  confirmDialogVisible = false

  onClickCancel(): void {
    this._nextRoute = null
    this.confirmDialogVisible = false
  }
}

