





























































































































































































import { Component, Vue, Model } from 'vue-property-decorator'
import { generateUuid } from '@/libs/uuid-generator'

import { MaterialFormInputDto, User } from '@/dtos/commons'
import { User as UserForPost } from '@/dtos/ideas/admin-idea/opinions/post'

import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { ValidationObserver } from 'vee-validate'

export class OpinionPostFormInput {
  body?: string
  isAlternative!: boolean
  user!: UserForPost
  material?: MaterialFormInputDto
  alternativeIdeaTitle?:string
  alternativeIdeaBackground?:string
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),
    OwnerUsersSelectModal: () => import('@/pages/ideas/OwnerUsersSelectModal.vue'),
  }
})
export default class OpinionPostModal extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Model('change', { required: true })
  private readonly isModalVisible!: boolean

  private get _isModalVisible(): boolean { return this.isModalVisible }
  private set _isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private isPostDialogVisible = false
  private isSelectModalVisible = false
  private selectModalKey = generateUuid()
  private isAlternativeSelectModalVisible = false
  private alternativeSelectModalKey = generateUuid()

  private body = ''
  private material: MaterialFormInputDto | null = null
  private isAlternative = false
  private user: User|null = null

  // 代替アイデア投稿プロパティ（画面状態保持用）
  private alternativeTitle = ''
  private alternativeBackground = ''
  private alternativeMaterial: MaterialFormInputDto | null = null
  private alternativeMaterialCaption = ''
  private alternativeUser: User|null = null

  private keyword = ''
  private inputText=''
  private alternativeKeyword = ''
  private alternativeInputText=''

  private showSelectModal(): void {
    this.isSelectModalVisible = true
    this.selectModalKey = generateUuid()
  }

  private async validateMode(): Promise<void> {
    // チェックボックスで画面が変更された後にバリデーションを動かすので遅延実行
    this.$nextTick(async function() {
      const validator = this.$refs.validator as InstanceType<typeof ValidationObserver>

      // 未入力の状態でバリデーションエラーを出したくないので値がある時のみバリデーション
      if (this.isAlternative) {
        if (this.alternativeTitle || this.alternativeBackground || this.alternativeMaterialCaption) {
          await validator.validate()
        }
      } else {
        if (this.body) {
          await validator.validate()
        }
      }
    })
  }

  private showAlternativeIdeaSelectModal(): void {
    this.isAlternativeSelectModalVisible = true
    this.alternativeSelectModalKey = generateUuid()
  }

  private setSelectedOwnerUser(selectedUser: User): void {
    this.user = selectedUser
  }

  private setAlternativeOwnerUser(selectedUser: User): void {
    this.alternativeUser = selectedUser
  }

  private postOpinion():void {
    this.isPostDialogVisible = false

    const opinion = new OpinionPostFormInput()
    opinion.body = this.body
    opinion.isAlternative = this.isAlternative
    if (this.user) opinion.user = this.user
    if (this.material) opinion.material = this.material
    this.$emit('post', opinion)
  }

  private postAlternativeIdea():void {
    this.isPostDialogVisible = false

    const opinion = new OpinionPostFormInput()
    opinion.alternativeIdeaTitle = this.alternativeTitle
    opinion.alternativeIdeaBackground = this.alternativeBackground
    opinion.isAlternative = this.isAlternative
    if (this.alternativeMaterial) {
      opinion.material = this.alternativeMaterial
      if (this.alternativeMaterialCaption) opinion.material.caption = this.alternativeMaterialCaption
    }
    if (this.alternativeUser) opinion.user = this.alternativeUser
    this.$emit('post', opinion)
  }
}
