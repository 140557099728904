
















































import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator'
import { buildingsModule } from '@/stores/buildings-store'
import { BuildingAdminIdea, BuildingAdminIdeasGetRequest } from '@/dtos/buildings/admin-idea/get'

import { Header } from '@/components/molecules/SmTableData.vue'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class AdminIdeasSelectModal extends Vue {
  /** 本来は「いずれの決議にも紐付いていない」アイデアが対象となるが、指定すると「自身に紐づくか、いずれにも紐付いていない」が対象になる */
  @Prop()
  private readonly buildingId!: string

  @Prop()
  private readonly resolutionTicketId?: string

  @Prop()
  private readonly baseIdeaIds?: string[]

  @Prop({ default: false })
  private readonly singleSelect!: boolean

  @Model('change')
  private readonly isVisible!: boolean

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  headers : Header[] = [
    new Header({ text: 'アーカイブ日時', value: 'archivedAt', filterable: false, width: '220px' }),
    new Header({ text: 'プランタイトル', value: 'title' }),
  ]

  private selectedIdeaIds:string[] = []

  private get ideas(): BuildingAdminIdea[] { return buildingsModule.adminIdeasGet.ideas }
  onClickBtn():void {
    this.$emit('select', this.selectedIdeaIds)
    this.isModalVisible = false
  }

  @Watch('isVisible', { immediate: true, deep: false })
  async initialSettingSelectedIds(isVisible: boolean): Promise<void> {
    if (isVisible) {
      await buildingsModule.fetchAdminIdeas(new BuildingAdminIdeasGetRequest(this.buildingId, this.resolutionTicketId))
      this.selectedIdeaIds = this.baseIdeaIds ?? []
    }
  }

  /**
   * textfieldに入力された値
   */
  private textValue = ''
  /**
   * 検索時にコンポーネントに検索ワードを渡すための変数
   */
  private keyword = ''
}
