





































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { PAYMENT_CODE_ONE_TIME } from '@/constants/schema-constants'

import { Plan, PlanDetail } from '@/dtos/reserve-funds/payments/pdf/get'

// 1列あたりの件数
const CountPerColumn = 20

@Component({
  components: {
    SmLegend: () => import('@/components/atoms/SmLegend.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmChart: () => import('@/components/molecules/SmChart.vue'),
  }
})
export default class SmTableReservesPayments extends Vue {
  @Prop({ required: true })
  plan!: Plan

  @Prop({ required: true })
  title!: string

  @Prop({ default: '#000000' })
  symbolColor!: string

  @Prop()
  borderDash?: number[]

  PAYMENT_CODE_ONE_TIME = PAYMENT_CODE_ONE_TIME

  private get tables():PlanDetail[][] {
    if (!this.plan || this.plan.planDetails.length === 0) return []
    return this.plan.planDetails.reduce(
      (acc:PlanDetail[][], _, i) => i % CountPerColumn ? acc : [...acc, this.plan.planDetails.slice(i, i + CountPerColumn)], [] as PlanDetail[][]
    )
  }
}
