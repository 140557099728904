import { Type } from 'class-transformer'

export class Section { // commonsのSectionとは項目が異なる
  sectionTitle!: string
  sectionBody!: string
}

export class Subject { // commonsのSubjectとは項目が異なる
  subjectId!: string
  subjectTitle!: string
  isPassed?: boolean | null = null // 結果登録前はnull、結果登録・更新時には必須
  resultComment?: string =''
  @Type(() => Section) sections: Section[] = []
}
