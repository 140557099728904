










































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { QuestionnaireAnswerLibraryItem, QuestionnaireAnswerLibraryPostRequest } from '@/dtos/questionnaires/reports/answer-libraries/post'
import { QuestionnaireAnswerLibraryPutRequest } from '@/dtos/questionnaires/reports/answer-libraries/put'
import { convertIndexIntoSerialChar } from '@/libs/add-alphabet-provider'
import { generateUuid } from '@/libs/uuid-generator'
import { questionnaireReportsModule } from '@/stores/questionnaire-reports-store'

export class QuestionnaireReportSection {
  questionnaireChoiceAnswerIds: string[] = []
  sectionId!: string
  opinionRequestText: string[] = []
  answerText!: string
  isModelAnswer?: boolean
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    QuestionnaireAnswerLibrarySelectModal: () => import('@/components/organisms/modal/QuestionnaireAnswerLibrarySelectModal.vue'),
  }
})
export default class SmCardQuestionnaireReportSection extends Vue {
  @Prop({ required: true, default: {} })
  questionnaireReportSection!: QuestionnaireReportSection

  @Prop({ required: true, default: false })
  private readonly head!: boolean

  @Prop({ required: true, default: false })
  private readonly foot!: boolean

  @Prop({ required: true, default: false })
  private readonly deletable!: boolean

  @Prop({ required: true, default: '' })
  private readonly fieldIdPrefix!: string

  @Prop({ required: true, default: '' })
  private readonly questionnaireReportSectionsLength!: number

  @Prop({ required: true, default: '' })
  private readonly questionnaireReportSectionIndex!: number

  private get _questionnaireReportSection(): QuestionnaireReportSection { return this.questionnaireReportSection }
  private set _questionnaireReportSection(newValue: QuestionnaireReportSection) {
    this.$emit('update:questionnaireReportSection', newValue)
  }

  getPlanTitleWithPrefix(questionnaireReportSectionIndex: number): string {
    return convertIndexIntoSerialChar(questionnaireReportSectionIndex)
  }

  setAnswerText(answer:string): void {
    this._questionnaireReportSection.answerText = answer
  }

  async onClickDeleteIcon(item: QuestionnaireAnswerLibraryItem, buildingIds:string[]): Promise<void> {
    const questionnaireReportResultSectionAnswerId = item.questionnaireReportResultSectionAnswerId
    const req = new QuestionnaireAnswerLibraryPutRequest(false, item.version)
    await questionnaireReportsModule.putQuestionnaireAnswerLibraries({ questionnaireReportResultSectionAnswerId, req })
    await questionnaireReportsModule.fetchQuestionnaireAnswerLibraries(new QuestionnaireAnswerLibraryPostRequest(buildingIds))
  }

  // 報告書出力モーダル
  isQuestionnaireAnswerLibrarySelectModalVisible = false
  questionnaireAnswerLibrarySelectModalKey = generateUuid()

  openQuestionnaireAnswerLibrarySelectModal(): void {
    this.questionnaireAnswerLibrarySelectModalKey = generateUuid()
    this.isQuestionnaireAnswerLibrarySelectModalVisible = true
  }
}
