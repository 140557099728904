
import { Type } from 'class-transformer'

import { BaseIdea, Material, Plan, Session } from '@/dtos/commons'
import { SessionGetResponseSession } from '@/dtos/ideas/admin-idea/commons'

export class AdminIdeasPostRequest {
  title: string
  opinionDeadline: string
  deadline: string
  isNotAcceptable: boolean

  ideaId?: string
  sessions?: Session[]
  plans?: Plan[]
  baseIdea?: BaseIdea

  constructor(title: string, opinionDeadline: string, deadline: string, isNotAcceptable: boolean, ideaId?: string, sessions?: Session[], plans?: Plan[], baseIdea?: BaseIdea) {
    this.title = title
    this.opinionDeadline = opinionDeadline
    this.deadline = deadline
    this.isNotAcceptable = isNotAcceptable
    this.ideaId = ideaId
    this.sessions = sessions
    this.plans = plans
    this.baseIdea = baseIdea
  }
}

export class PreviewBudget {
  budget?:number
  budgetLabel!:string
  expense?:number
  expenseLabel!:string
  spent?:number
  spentLabel:string | null = null
  balanceLabel!:string
}

export class PreviewElement {
  sectionTitle?: string
  sectionBody?: string
  @Type(() => Material) material?: Material | null = null
}

export class PreviewSession {
  postedAt! :string
  @Type(() => SessionGetResponseSession)sessions: SessionGetResponseSession[] = []
}

export class PreviewPlan {
  planId!: string
  title!: string
  isAgreed!: boolean
  agreementCount!: number
  @Type(() => PreviewBudget) budgets!: PreviewBudget[]
  @Type(() => PreviewElement) previewElements!: PreviewElement[]
}
