import type { AdminRole } from '@/constants/schema-constants'

export class StaffPostRequest {
  role!: AdminRole
  employeeId!: string
  adminName!: string
  adminShortName?: string
  emailAddress?: string
  constructor(role: AdminRole, employeeId: string, adminName: string, adminShortName?:string, emailAddress?:string) {
    this.role = role
    this.employeeId = employeeId
    this.adminName = adminName
    this.adminShortName = adminShortName
    this.emailAddress = emailAddress
  }
}

export class LifeManagerStaffPostRequest {
  employeeId!: string
  adminName!: string
  authKeyRecipient!: string
  phoneNumber!: string
  constructor(employeeId: string, adminName: string, authKeyRecipient: string, phoneNumber: string) {
    this.employeeId = employeeId
    this.adminName = adminName
    this.authKeyRecipient = authKeyRecipient
    this.phoneNumber = phoneNumber
  }
}

export class Admin {
  userId!: string
}
export class StaffPostResponse {
  admin!: Admin
}
