
































import { Vue, Component, Model, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmSwitch extends Vue {
  @Prop()
  @Model('change')
  private readonly onSwitch!: boolean

  @Prop()
  private readonly label?: string

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly readonly!: boolean

  @Prop({ default: false, required: false })
  private readonly isDisabledLabel?: boolean

  private get state():boolean { return this.onSwitch }
  private set state(newValue: boolean) { this.$emit('change', newValue) }
}
