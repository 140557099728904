




















































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'

import { Choice } from '@/components/molecules/SmBtnToggle.vue'
import { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'

import { BuildingGroupType, BuildingMenuTypes, BUILDING_GROUP_TYPE, BUILDING_MENU_ITEMS, BUILDING_MENU_TYPES, QUESTIONNAIRE_STATES } from '@/constants/schema-constants'
import type { QuestionnaireState } from '@/constants/schema-constants'

import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { QuestionnaireInternalReportPostRequest } from '@/dtos/questionnaires/reports/internal/post'
import { QuestionnaireTemplate, QuestionnaireTemplatesGetRequest } from '@/dtos/questionnaires/templates/get'
import { QuestionnaireTemplateDetailGetRequest, QuestionnaireTemplateDetailGetResponse } from '@/dtos/questionnaires/templates/get-detail'
import { Questionnaire, QuestionnairesSearchPostRequest } from '@/dtos/questionnaires/search/post'

import { questionnaireExcelParser } from '@/libs/excel-parser/questionnaire-parser'
import { getGroupLabel } from '@/libs/type-handler'
import { generateUuid } from '@/libs/uuid-generator'

import { paramStorageModule } from '@/stores/param-storage-store'
import { buildingsModule } from '@/stores/buildings-store'
import { errorsModule } from '@/stores/errors'
import { questionnairesModule } from '@/stores/questionnaires-store'
import { questionnaireInternalReportsModule } from '@/stores/questionnaire-Internal-reports-store'
import { questionnaireTemplatesModule } from '@/stores/questionnaire-templates-store'

const DOWNLOAD_FAILED_MESSAGES = ['ファイルのダウンロードに失敗しました。画面を読み込み直すか、しばらくしてから再度お試しください。']

const TAKE = 20
const STORAGE_KEY = staticRoutes.questionnairesList.name

// ステータスのトグルの内容
export const FILTER_TOGGLE_QUESTIONNAIRE_STATES = {
  DRAFT: 'draft',
  OPEN: 'open',
  COUNTING: 'counting',
  RELEASED: 'released',
  SCHEDULED: 'scheduled',
}
export type FilterToggleQuestionnaireState = typeof FILTER_TOGGLE_QUESTIONNAIRE_STATES[keyof typeof FILTER_TOGGLE_QUESTIONNAIRE_STATES]

const FILTER_TOGGLE_CHOICES_STATE: Record<FilterToggleQuestionnaireState, QuestionnaireState> = {
  [FILTER_TOGGLE_QUESTIONNAIRE_STATES.DRAFT]: QUESTIONNAIRE_STATES.DRAFT,
  [FILTER_TOGGLE_QUESTIONNAIRE_STATES.OPEN]: QUESTIONNAIRE_STATES.OPEN,
  [FILTER_TOGGLE_QUESTIONNAIRE_STATES.COUNTING]: QUESTIONNAIRE_STATES.COUNTING,
  [FILTER_TOGGLE_QUESTIONNAIRE_STATES.RELEASED]: QUESTIONNAIRE_STATES.RELEASED,
  [FILTER_TOGGLE_QUESTIONNAIRE_STATES.SCHEDULED]: QUESTIONNAIRE_STATES.SCHEDULED
}

// 並び順のメニューの内容
const SORT_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  desc: {
    text: '回答締切日が新しい順に並び替える',
    label: '回答締切日が新しい順'
  },
  asc: {
    text: '回答締切日が古い順に並び替える',
    label: '回答締切日が古い順'
  }
}

const TEMPLATE_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  all: {
    text: 'すべてのテンプレート',
    label: '変更する'
  },
  each: {
    text: '個別に選択する',
    label: '変更する'
  },
}

export const TEMPLATE_MENU_TYPES = {
  ALL: 'all',
  EACH: 'each',
} as const
export type TemplateMenuTypes = typeof TEMPLATE_MENU_TYPES[keyof typeof TEMPLATE_MENU_TYPES]

class SmCardQuestionnaireDto {
  questionnaireId!: string
  templateName!: string
  templateId!: string
  buildingId!: string
  buildingName!: string
  questionnaireState!: QuestionnaireState
  title!: string
  postedTo!: string
  answerDeadline!: string
  postedAt!:string
  readCount?: number
  answerCount?: number

  constructor(questionnaireId: string, templateName: string, templateId: string, questionnaireState: QuestionnaireState, title: string, postedTo: string, answerDeadline: string, postedAt:string, readCount?: number, answerCount?: number) {
    this.questionnaireId = questionnaireId
    this.templateName = templateName
    this.templateId = templateId
    this.questionnaireState = questionnaireState
    this.title = title
    this.postedTo = postedTo
    this.answerDeadline = answerDeadline
    this.postedAt = postedAt
    this.readCount = readCount
    this.answerCount = answerCount
  }
}

// storeに検索条件を保存するためのクラス
class QuestionnaireSearchCondition extends QuestionnairesSearchPostRequest {
  selectedBuildingMenuId!: BuildingMenuTypes
  selectedBuildingGroupType!: BuildingGroupType
  constructor() { super(TAKE) }
}

// 絞込条件の内容
class QuestionnaireInputParams {
  questionnaireStates: FilterToggleQuestionnaireState[] = []
  selectedBuildingMenuId: BuildingMenuTypes = BUILDING_MENU_TYPES.ALL // マンションの絞り込みメニューの種類
  selectedBuildingGroupType: BuildingGroupType = BUILDING_GROUP_TYPE.TOKYO // マンショングループ選択ダイアログで以前選択したマンショングループを保持しておく変数
  selectedBuildingsByEach: Building[] = []
  buildings: Building[] = []
  selectedTemplateMenuType: TemplateMenuTypes = TEMPLATE_MENU_TYPES.ALL
  templateIds: string[] | null = null
  fromDate: string | null = null
  toDate: string | null = null
  keyword = ''
  sortMenuId = 'desc'
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmBtnToggle: () => import('@/components/molecules/SmBtnToggle.vue'),
    SmCardQuestionnaire: () => import('@/components/molecules/card/SmCardQuestionnaire.vue'),
    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    BuildingGroupSelectModal: () => import('@/components/organisms/modal/BuildingGroupSelectModal.vue'),
    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),
    QuestionnaireReportOutputModal: () => import('@/components/organisms/modal/QuestionnaireReportOutputModal.vue'),
    QuestionnaireTemplateSelectModal: () => import('@/components/organisms/modal/QuestionnaireTemplateSelectModal.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class QuestionnairesListPage extends Vue {
  async created(): Promise<void> {
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999))
    await questionnaireTemplatesModule.fetchQuestionnaireTemplates(new QuestionnaireTemplatesGetRequest(0, 999))

    // 初期検索パラメーターの設定（以前の検索条件が残っている場合、それを復元して反映）
    const stored = paramStorageModule.savedParam(STORAGE_KEY, QuestionnaireSearchCondition)
    if (stored) {
      this.searchCondition = stored

      if (stored.questionnaireStates) this.inputParams.questionnaireStates = this.changeToSelectedQuestionnaireStates(stored.questionnaireStates)
      if (stored.selectedBuildingGroupType) this.inputParams.selectedBuildingGroupType = stored.selectedBuildingGroupType
      if (stored.buildings) this.inputParams.buildings = this.getTargetBuildings(stored.buildings)
      if (stored.templates) this.inputParams.templateIds = stored.templates
      if (stored.fromDate) this.inputParams.fromDate = stored.fromDate ?? ''
      if (stored.toDate) this.inputParams.toDate = stored.toDate ?? ''
      if (stored.keyword) this.inputParams.keyword = stored.keyword ?? ''
      this.inputParams.selectedBuildingMenuId = stored.selectedBuildingMenuId ?? BUILDING_MENU_TYPES.ALL
      this.inputParams.selectedBuildingsByEach = stored.selectedBuildingMenuId === BUILDING_MENU_TYPES.EACH ? this.getTargetBuildings(stored.buildings) : []
      this.inputParams.sortMenuId = stored.sortOrder === 'asc' ? 'asc' : 'desc'
    }

    this.setSelectedBuildingDisplay()
    this.search()
  }

  changeToSelectedQuestionnaireStates(questionnaireState?: QuestionnaireState[]): string[] {
    const selectedQuestionnaireStates: string[] = []
    if (questionnaireState?.includes(QUESTIONNAIRE_STATES.DRAFT)) selectedQuestionnaireStates.push(FILTER_TOGGLE_QUESTIONNAIRE_STATES.DRAFT)
    if (questionnaireState?.includes(QUESTIONNAIRE_STATES.OPEN)) selectedQuestionnaireStates.push(FILTER_TOGGLE_QUESTIONNAIRE_STATES.OPEN)
    if (questionnaireState?.includes(QUESTIONNAIRE_STATES.COUNTING)) selectedQuestionnaireStates.push(FILTER_TOGGLE_QUESTIONNAIRE_STATES.COUNTING)
    if (questionnaireState?.includes(QUESTIONNAIRE_STATES.RELEASED)) selectedQuestionnaireStates.push(FILTER_TOGGLE_QUESTIONNAIRE_STATES.RELEASED)
    if (questionnaireState?.includes(QUESTIONNAIRE_STATES.SCHEDULED)) selectedQuestionnaireStates.push(FILTER_TOGGLE_QUESTIONNAIRE_STATES.SCHEDULED)

    return selectedQuestionnaireStates
  }

  // --------------- 絞り込み表示情報 ---------------
  SORT_MENU_ITEMS = Object.freeze(SORT_MENU_ITEMS)
  BUILDING_MENU_ITEMS = Object.freeze(BUILDING_MENU_ITEMS)
  TEMPLATE_MENU_ITEMS= Object.freeze(TEMPLATE_MENU_ITEMS)
  isFilterMenuOpen = true
  selectedBuildingDisplay = 'すべてのマンション'

  private get buildingNames(): string {
    return this.inputParams.buildings.map(b => b.buildingName).join(' | ')
  }

  // トグルボタン
  questionnaireStatesChoice: Choice[] = [
    new Choice(FILTER_TOGGLE_QUESTIONNAIRE_STATES.DRAFT, '下書き'),
    new Choice(FILTER_TOGGLE_QUESTIONNAIRE_STATES.SCHEDULED, '公開予定'),
    new Choice(FILTER_TOGGLE_QUESTIONNAIRE_STATES.OPEN, '公開中'),
    new Choice(FILTER_TOGGLE_QUESTIONNAIRE_STATES.COUNTING, '回答締切済'),
    new Choice(FILTER_TOGGLE_QUESTIONNAIRE_STATES.RELEASED, '報告書作成済'),
  ]

  private get selectedTemplateDisplay(): string {
    if (!this.inputParams.templateIds || this.inputParams.templateIds.length < 1) return 'すべてのテンプレート'
    else {
      return this.inputParams.templateIds.map(templateId => {
        return this.getTargetTemplate(templateId).templateName
      }).join(' | ')
    }
  }

  // --------------- 絞り込み条件変更の処理 ---------------

  onChangeSelectedQuestionnaireStates(): void {
    if (!this.searchCondition) return
    const questionnaireStates = this.inputParams.questionnaireStates.flatMap(s => FILTER_TOGGLE_CHOICES_STATE[s])
    this.searchCondition.questionnaireStates = questionnaireStates

    this.search()
  }

  onChangeTemplate(templateIds?: string[]): void {
    if (!this.searchCondition) return
    if (!templateIds || templateIds.length < 1) {
      this.inputParams.templateIds = null
      this.searchCondition.templates = undefined
    } else {
      this.inputParams.templateIds = templateIds
      this.searchCondition.templates = templateIds
    }

    this.search()
  }

  onSearchDateFilter(): void {
    if (!this.searchCondition) return
    this.searchCondition.fromDate = this.inputParams.fromDate === '' ? null : this.inputParams.fromDate
    this.searchCondition.toDate = this.inputParams.toDate === '' ? null : this.inputParams.toDate

    this.search()
  }

  onSearchKeyword(): void {
    if (!this.searchCondition) return
    this.searchCondition.keyword = this.inputParams.keyword === '' ? null : this.inputParams.keyword

    this.search()
  }

  onSelectSortOrderMenu(): void {
    if (!this.searchCondition) return
    this.searchCondition.sortOrder = this.inputParams.sortMenuId === 'asc' ? 'asc' : 'desc'

    this.search()
  }

  onChangeBuilding(buildingIds?: string[]): void {
    this.inputParams.buildings = this.getTargetBuildings(buildingIds)

    // 「すべてのマンション」と「個別に選択」を選択した場合、グループ選択ダイアログで保持していた値をクリアする
    if (this.inputParams.selectedBuildingMenuId === BUILDING_MENU_TYPES.ALL || this.inputParams.selectedBuildingMenuId === BUILDING_MENU_TYPES.EACH) {
      this.inputParams.selectedBuildingGroupType = BUILDING_GROUP_TYPE.TOKYO
    }

    // 個別マンション選択ダイアログで選択されたマンションを保持する（それ以外の場合はクリア）
    this.inputParams.selectedBuildingsByEach = this.inputParams.selectedBuildingMenuId === BUILDING_MENU_TYPES.EACH ? this.inputParams.buildings : []

    // 物件IDを検索条件に追加する
    this.searchCondition.buildings = buildingIds ?? []

    this.setSelectedBuildingDisplay()
    this.search()
  }

  selectBuildingMenu(): void {
    switch (this.inputParams.selectedBuildingMenuId) {
      case BUILDING_MENU_TYPES.ALL:
        this.onChangeBuilding()
        break
      case BUILDING_MENU_TYPES.GROUP:
        this.openBuildingGroupSelectModal()
        break
      case BUILDING_MENU_TYPES.EACH:
        this.openBuildingSelectModal()
        break
    }
  }

  selectTemplateMenu(): void {
    switch (this.inputParams.selectedTemplateMenuType) {
      case TEMPLATE_MENU_TYPES.ALL:
        this.onChangeTemplate(undefined)
        break
      case TEMPLATE_MENU_TYPES.EACH:
        this.openSelectTemplateModal()
        break
    }
  }

  setBuildingGroupType(selectedGroupType: BuildingGroupType): void {
    this.inputParams.selectedBuildingGroupType = selectedGroupType
    const buildingIds = this.getBuildingIdsByGroupType(selectedGroupType, buildingsModule.buildingsGet.buildings)
    this.onChangeBuilding(buildingIds)
  }

  // マンショングループに応じたマンションIDの一覧を取得する
  private getBuildingIdsByGroupType(buildingGroupType:BuildingGroupType, buildings: Building[]): string[] {
    return this.getBuildingsByGroupType(buildingGroupType, buildings).map(e => e.buildingId)
  }

  // マンショングループに応じたマンションの一覧を取得する
  private getBuildingsByGroupType(buildingGroupType:BuildingGroupType, buildings: Building[]): Building[] {
    return buildings.filter(e => e.buildingAreaType === buildingGroupType)
  }

  getTargetBuildings(buildingIds?: string[]): Building[] {
    return buildingsModule.buildingsGet.buildings.filter(b => buildingIds?.includes(b.buildingId))
  }

  getTargetTemplate(templateId: string): QuestionnaireTemplate {
    return questionnaireTemplatesModule.templatesGet.templates.filter(t => t.templateId === templateId)[0]
  }

  // マンションの横に表示するラベルをセットする
  setSelectedBuildingDisplay(): void {
    switch (this.inputParams.selectedBuildingMenuId) {
      case BUILDING_MENU_TYPES.ALL:
        this.selectedBuildingDisplay = 'すべてのマンション'
        break
      case BUILDING_MENU_TYPES.GROUP:
        this.selectedBuildingDisplay = getGroupLabel(this.inputParams.selectedBuildingGroupType)
        break
      case BUILDING_MENU_TYPES.EACH:
        this.selectedBuildingDisplay = this.buildingNames
        break
    }
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
    this.isWaitingSwitch = false
  }

  // --------------- 検索の処理 ---------------
  identifier = 0
  handler: LoadingHandler | null = null
  searchCondition: QuestionnaireSearchCondition = new QuestionnaireSearchCondition()
  inputParams = new QuestionnaireInputParams()

  isWaitingSwitch = false

  search(): void {
    questionnairesModule.clearFetchedQuestionnaires()
    this.identifier++
  }

  toSearchPostRequest(): QuestionnairesSearchPostRequest {
    const req = new QuestionnairesSearchPostRequest(TAKE)

    req.questionnaireStates = this.searchCondition?.questionnaireStates?.length ? this.searchCondition.questionnaireStates : undefined
    req.buildings = this.searchCondition?.buildings?.length ? this.searchCondition.buildings : undefined
    req.templates = this.searchCondition?.templates?.length ? this.searchCondition.templates : undefined
    req.fromDate = this.searchCondition?.fromDate ?? undefined
    req.toDate = this.searchCondition?.toDate ?? undefined
    req.keyword = this.searchCondition?.keyword ?? undefined
    req.sortOrder = this.searchCondition?.sortOrder ?? undefined
    req.skipToken = this.skipToken ?? undefined

    return req
  }

  // --------------- アンケート表示関連 ---------------
  private get questionnaires(): Questionnaire[] { return questionnairesModule.questionnaires }
  private get skipToken(): string | null { return questionnairesModule.skipToken }
  private get smCardQuestionnaireDtos(): SmCardQuestionnaireDto[] {
    return this.questionnaires.map(q => new SmCardQuestionnaireDto(
      q.questionnaireId,
      q.templateName,
      q.templateId,
      q.questionnaireState,
      q.questionnaireTitle,
      q.buildingName,
      q.deadlineDate,
      q.postedAt,
      q.readCount,
      q.answerCount
    ))
  }

  async loadQuestionnaires(handler: LoadingHandler): Promise<void> {
    // 検索パラメータの初期化が完了していなければ完了扱いにする
    if (!this.identifier) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = true
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }

    const beforeQuestionnairesCount = this.questionnaires.length

    this.searchCondition.selectedBuildingMenuId = this.inputParams.selectedBuildingMenuId
    this.searchCondition.selectedBuildingGroupType = this.inputParams.selectedBuildingGroupType
    this.searchCondition.skipToken = this.skipToken ?? undefined

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...this.searchCondition } })

    // グループ選択時に対象物件が0件だった場合はAPIを呼び出さず0件表示
    if (this.inputParams.selectedBuildingMenuId === BUILDING_MENU_TYPES.GROUP && this.searchCondition?.buildings?.length === 0) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    await questionnairesModule.fetchQuestionnaires(this.toSearchPostRequest())

    // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
    if (this.questionnaires.length === 0) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = false
    handler.loaded()
    const expectingToBe = beforeQuestionnairesCount + this.searchCondition.take
    if (this.questionnaires.length < expectingToBe) handler.complete()
  }

  private get isAllSearch(): boolean {
    return (
      !this.searchCondition.questionnaireStates?.length &&
      !this.searchCondition.buildings?.length &&
      !this.searchCondition.templates?.length &&
      !this.searchCondition.fromDate &&
      !this.searchCondition.toDate &&
      !this.searchCondition.keyword &&
      this.inputParams.selectedBuildingMenuId === BUILDING_MENU_TYPES.ALL
    )
  }

  // --------------- ボタンを押下した際の処理 ---------------

  goToCreateQuestionnairePage(questionnaireTemplateIds :string): void {
    this.$router.push({ name: staticRoutes.questionnaireCreate.name, query: { templateId: questionnaireTemplateIds[0] } })
  }

  goToCreateTemplatePage(questionnaireTemplateId? :string): void {
    if (questionnaireTemplateId) this.$router.push({ name: staticRoutes.questionnaireTemplateCreate.name, params: { templateId: questionnaireTemplateId } })
    else this.$router.push({ name: staticRoutes.questionnaireTemplateCreate.name })
  }

  goToEditTemplatePage(questionnaireTemplateId: string): void {
    this.$router.push({ name: staticRoutes.questionnaireTemplateUpdate.name, params: { templateId: questionnaireTemplateId } })
  }

  onClickCard(state: QuestionnaireState, questionnaireId: string, questionnaireTemplateId: string): void {
    if (state === QUESTIONNAIRE_STATES.DRAFT) this.$router.push({ name: staticRoutes.questionnaireCreate.name, query: { templateId: questionnaireTemplateId, questionnaireId: questionnaireId } })
    else this.$router.push({ name: staticRoutes.questionnaireDetail.name, params: { questionnaireId: questionnaireId } })
  }

  async outputClose():Promise<void> {
    this.search()
  }

  async onClickOutputBtn(questionnaireIds: string[], templateQuestionIds: string[], templateId: string):Promise<void> {
    errorsModule.clearGlobalErrors()
    await questionnaireInternalReportsModule.fetchQuestionnaireInternalReport(new QuestionnaireInternalReportPostRequest(questionnaireIds, templateQuestionIds))

    const data = questionnaireInternalReportsModule.internalReportGet
    if (!data) {
      errorsModule.setGlobalErrors(DOWNLOAD_FAILED_MESSAGES)
      return
    }

    try {
      await questionnaireTemplatesModule.fetchQuestionnaireTemplateDetail(new QuestionnaireTemplateDetailGetRequest(templateId))
      const template = this.filterTemplateQuestion(questionnaireTemplatesModule.questionnaireTemplateDetailGet, templateQuestionIds)
      await questionnaireExcelParser.downloadAsExcel(data, template)
    } catch (err) {
      errorsModule.setGlobalErrors(DOWNLOAD_FAILED_MESSAGES)
      throw err
    }
  }

  private filterTemplateQuestion(template: QuestionnaireTemplateDetailGetResponse, templateQuestionIds: string[]): QuestionnaireTemplateDetailGetResponse {
    if (!templateQuestionIds.length) return template
    template.sections.forEach(ts => {
      ts.templateQuestions = ts.templateQuestions.filter(tq => templateQuestionIds.includes(tq.templateQuestionId))
    })
    return template
  }

  // --------------- モーダル表示等の処理 ---------------

  // 報告書出力モーダル
  isQuestionnaireReportOutputModalVisible = false
  questionnaireReportOutputModalKey = generateUuid()
  openQuestionnaireReportModal(): void {
    this.questionnaireReportOutputModalKey = generateUuid()
    this.isQuestionnaireReportOutputModalVisible = true
  }

  // テンプレート選択モーダル
  inputTempleText = ''
  templateKeyword = ''

  isTemplateSelectModalVisible = false
  templateSelectModalKey = generateUuid()
  openSelectTemplateModal(): void {
    this.templateSelectModalKey = generateUuid()
    this.isTemplateSelectModalVisible = true
  }

  // アンケート新規作成用、テンプレート選択モーダル
  inputQuestionnaireText = ''
  questionnaireKeyword = ''

  isQuestionnaireCreateModalVisible = false
  questionnaireCreateModalKey = generateUuid()
  openCreateQuestionnaireModal(): void {
    this.questionnaireCreateModalKey = generateUuid()
    this.isQuestionnaireCreateModalVisible = true
  }

  // 物件個別選択モーダル
  inputBuildingText = ''
  buildingKeyword = ''

  isBuildingSelectModalVisible = false
  buildingSelectModalKey = generateUuid()
  openBuildingSelectModal(): void {
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingSelectModalVisible = true
  }

  // 物件グループ選択モーダル
  isBuildingGroupSelectModalVisible = false
  buildingGroupSelectModalKey = generateUuid()
  openBuildingGroupSelectModal(): void {
    this.buildingGroupSelectModalKey = generateUuid()
    this.isBuildingGroupSelectModalVisible = true
  }
}
