













































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ListResponseTaskDto } from '@/dtos/tasks/search/post'
import { getTicketTaskTypeLabel, getTicketLabel } from '@/libs/type-handler'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmBtnTextLinkAbbreviation: () => import('@/components/atoms/SmBtnTextLinkAbbreviation.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmCardTaskParentTicket extends Vue {
  @Prop({ required: true, default: {} })
  task!: ListResponseTaskDto

  @Prop({ default: false })
  disableTicketDetailLink?: boolean

  get parentTicketNameArea():string | undefined {
    return this.task.ticket.ticketNo + ' | ' + getTicketLabel(this.task.ticket.ticketType) + ' | ' + this.task.ticket.ticketName
  }

  get taskTrigger():string {
    return getTicketTaskTypeLabel(this.task.ticketTaskType)
  }
}
