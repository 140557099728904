






import { Vue, Component, Prop } from 'vue-property-decorator'

const stateTypes = {
  ownerIdea: { icon: '$emoji_objects_outlined', iconColor: '#03C775', text: 'アイデア', textStyle: 'green-dark' },
  ownerIdeaArchive: { icon: '$emoji_objects_outlined', iconColor: '#A1ABAE', text: 'アイデア', textStyle: 'gray-dark' },
  adminIdea: { icon: '$sm-plan-icon', iconColor: '#66CE14', text: 'プラン', textStyle: 'green-dark-2' },
  adminIdeaAgreement: { icon: '$sm-plan-icon', iconColor: '#CACE14', text: 'プラン', textStyle: 'green-light' },
  adminIdeaArchive: { icon: '$sm-plan-icon', iconColor: '#A1ABAE', text: 'プラン', textStyle: 'gray-dark' },
  online: { icon: '$how_to_vote_outlined', iconColor: '#FC5353', text: 'オンライン決議', textStyle: 'red-dark' },
  onlineArchive: { icon: '$how_to_vote_outlined', iconColor: '#A1ABAE', text: 'オンライン決議', textStyle: 'gray-dark' },
  generalMeeting: { icon: '$how_to_vote_outlined', iconColor: '#FC5353', text: '総会決議', textStyle: 'red-dark' },
  generalMeetingArchive: { icon: '$how_to_vote_outlined', iconColor: '#A1ABAE', text: '総会決議', textStyle: 'gray-dark' },
  normalOwnerNotification: { icon: '$campaign_outlined', iconColor: '#A1ABAE', text: '通常のお知らせ', textStyle: 'black' },
  pinnedOwnerNotification: { icon: '$push_pin_round', iconColor: '#A1ABAE', text: '固定指定されたお知らせ', textStyle: 'black' },
}

type StateType = keyof typeof stateTypes

@Component({
  components: {
    SmBadgeCategory: () => import('@/components/atoms/SmBadgeCategory.vue')
  }
})
export default class SmIBadgeContent extends Vue {
  @Prop({
    default: 'ownerIdea',
    validator: i => i in stateTypes
  })
  private readonly stateType!: StateType

  private get contents() : {[key: string]: string} {
    return stateTypes[this.stateType]
  }
}

