




















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { VOTE_TYPES } from '@/constants/schema-constants'
import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { OnlineResolutionVote } from '@/dtos/resolutions/online-resolution/votes/get'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class ProxyVoteTable extends Vue {
  tableHeaders: Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '113px' }),
    new Header({ text: '氏名', value: 'userName', width: '144px' }),
    new Header({ text: '保有議決権', value: 'votingRightCount', width: '120px', filterable: false }),
    new Header({ text: '', value: 'isStatement', width: '95px', sortable: false, filterable: false }),
    new Header({ text: '投票内容', value: 'voteType', sortable: false, filterable: false }),
  ]

  @Prop({ default: () => [] })
  votes!:OnlineResolutionVote[]

  @Prop()
  private readonly keyword?: string

  voteTypes = [new RadioOption('○', VOTE_TYPES.FOR), new RadioOption('×', VOTE_TYPES.AGAINST)]
}
