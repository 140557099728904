

















































import type { NoticeType, TicketType } from '@/constants/schema-constants'
import { getTicketBadge } from '@/libs/type-handler'
import { Vue, Component, Prop } from 'vue-property-decorator'

export class NoticeTargetContent {
  icon!:string
  iconColor!:string
  textStyle!:string
}

@Component({
  components: {
    SmBadgeCategory: () => import('@/components/atoms/SmBadgeCategory.vue'),
    SmBadgeRead: () => import('@/components/atoms/SmBadgeRead.vue'),

    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmListNotice extends Vue {
  @Prop({ required: true, default: '' })
  noticeId!:string

  @Prop({ required: true, default: false })
  isUnread!:boolean

  @Prop()
  noticeType?:NoticeType

  @Prop()
  noticeTargetType?:TicketType

  @Prop()
  noticeTargetTitle?:string

  @Prop({ required: true, default: '' })
  title!:string

  @Prop()
  buildingName?:string

  @Prop({ required: true, default: '' })
  notifiedAt!:string

  private get noticeTargetContent(): NoticeTargetContent | undefined {
    if (!this.noticeTargetType || !this.noticeTargetTitle) return undefined
    return getTicketBadge(this.noticeTargetType)
  }
}
