import { Type } from 'class-transformer'

export class UsersGetRequest {
  skip: number
  take: number
  keyword?: string
  isStaff: boolean | null

  constructor(skip: number, take: number, isStaff: boolean | null, keyword?: string) {
    this.skip = skip
    this.take = take
    this.keyword = keyword
    this.isStaff = isStaff
  }
}

export class UsersGetResponseUser {
  buildingId!: string
  condominiumCd!: string
  buildingName!: string
  isBuildingOutOfService!: boolean
  userName!: string
  userId!: string
  roomNumber!: string
  buildingUnitId!: string
  isInitialAuth!: boolean
}

export class UsersGetResponse {
  @Type(() => UsersGetResponseUser) users:UsersGetResponseUser[] = []
  userCount!: number
}
