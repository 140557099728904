import { Material, Section } from '@/dtos/commons'
import { Type } from 'class-transformer'

export class Details {
  isIdea = false
  isSession = false
  isOpinions = false
  isUpdates = false
  isAgreement = false
}

export class Decision {
  isAgreed!: boolean
  planId?: string
}

export class Guide {
  itemTitle!: string
  itemBody!: string
  @Type(() => Material)material?: Material
  @Type(() => Section)sections: Section[] = []
}

export class Result {
  itemTitle!: string
  itemBody!: string
  @Type(() => Material)material?: Material
}

export class SessionGetResponseSession {
  @Type(() => Guide)guide: Guide = new Guide()
  @Type(() => Result)result?: Result
}
