


























































































import { Header } from '@/components/molecules/SmTableDataExternalPaging.vue'
import { QuestionnaireOpinionDto, QuestionnaireOpinionsSearchPostResponse } from '@/dtos/questionnaires/reports/opinions/post'
import { questionnaireReportsModule } from '@/stores/questionnaire-reports-store'
import { Component, Vue, Model } from 'vue-property-decorator'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class QuestionnaireOpinionsAddModal extends Vue {
  @Model('change')
  private readonly isVisible!: boolean

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  questionnaireChoiceAnswerIds: string[] = []

  // textfieldに入力された値
  private textValue = ''

  // 検索時にコンポーネントに検索ワードを渡すための変数
  private keyword = ''

  private get res(): QuestionnaireOpinionsSearchPostResponse {
    return questionnaireReportsModule.opinionsGet
  }

  onClickAddBtn():void {
    this.isModalVisible = false
    this.$emit('add', this.selectedOpinion)
  }

  private get selectedOpinion(): QuestionnaireOpinionDto[] {
    return questionnaireReportsModule.opinionsGet.questionnaireOpinions.filter(qo => this.questionnaireChoiceAnswerIds.includes(qo.questionnaireChoiceAnswerId))
  }

  private get isSelected(): boolean {
    return (this.questionnaireChoiceAnswerIds.length > 0)
  }

    headers : Header[] = [
      new Header({ text: '住戸番号', value: 'roomNumber', filterable: true, sortable: false, width: '88px' }),
      new Header({ text: '氏名', value: 'userName', filterable: true, sortable: false, width: '102px' }),
      new Header({ text: '回答', value: 'opinionBody', filterable: true, sortable: false })
    ]
}
