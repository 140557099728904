









































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Tab } from '@/components/molecules/SmTabs.vue'
import { QUESTIONNAIRE_STATES } from '@/constants/schema-constants'
import type { ChipColors } from '@/constants/ux-constants'
import { QuestionnaireDeleteRequest } from '@/dtos/questionnaires/delete'
import { QuestionnaireDetailGetRequest, QuestionnaireDetailGetResponse } from '@/dtos/questionnaires/get'
import { getQuestionnaireLabel, getQuestionnaireStateLChipColor } from '@/libs/state-handler'
import { staticRoutes } from '@/routes'
import { questionnairesModule } from '@/stores/questionnaires-store'

@Component({
  components: {
    SmBadgeCategory: () => import('@/components/atoms/SmBadgeCategory.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})

export default class QuestionnaireDetailPage extends Vue {
  @Prop({ required: true, default: '' })
  private readonly questionnaireId!: string

  private get tabs(): Tab[] {
    return [
      new Tab('アンケート内容', staticRoutes.questionnaireDetail.getChild('detail').name),
      new Tab('回答', staticRoutes.questionnaireDetail.getChild('answer').name, (this.questionnaire.questionnaireState === QUESTIONNAIRE_STATES.SCHEDULED)),
      new Tab('回答者', staticRoutes.questionnaireDetail.getChild('respondents').name, (this.questionnaire.questionnaireState === QUESTIONNAIRE_STATES.SCHEDULED)),
      new Tab('報告書', staticRoutes.questionnaireDetail.getChild('report').name, (this.questionnaire.questionnaireState === QUESTIONNAIRE_STATES.OPEN || this.questionnaire.questionnaireState === QUESTIONNAIRE_STATES.SCHEDULED)),
    ]
  }

  async created(): Promise<void> {
    await questionnairesModule.fetchQuestionnaireDetail(new QuestionnaireDetailGetRequest(this.questionnaireId))
  }

  private get questionnaire(): QuestionnaireDetailGetResponse {
    return questionnairesModule.questionnaireDetail(this.questionnaireId) ?? new QuestionnaireDetailGetResponse()
  }

  private get questionnaireChipText(): string { return getQuestionnaireLabel(this.questionnaire.questionnaireState) }
  private get questionnaireChipColorType(): ChipColors { return getQuestionnaireStateLChipColor(this.questionnaire.questionnaireState) }

  isConfirmationDialogVisible = false
  deleteQuestionnaire(): void {
    this.isConfirmationDialogVisible = false
    questionnairesModule.deleteQuestionnaires(new QuestionnaireDeleteRequest(this.questionnaireId))
    this.$router.push({ name: staticRoutes.questionnairesList.name })
  }
}
