






























































import { Header } from '@/components/molecules/SmTableData.vue'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes, StaticRoute } from '@/routes'
import { staffsModule } from '@/stores/staffs-store'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { MembersGetRequest, MembersGetResponseStaff } from '@/dtos/staffs/members/get'
import { ADMIN_ROLE, INITIAL_AUTH_STATE } from '@/constants/schema-constants'
import type { AdminRole, InitialAuthState } from '@/constants/schema-constants'
import { getInitialAuthStateLabel } from '@/libs/state-handler'

class PageTypeSpecifications {
  tableHeaders!: Header[]
  placeHolder!: string
  routesTo!: StaticRoute
}

const SERVICE_STAFF_PAGE: PageTypeSpecifications = {
  tableHeaders: [
    new Header({ text: '社員番号', value: 'employeeId', width: '120px' }),
    new Header({ text: '社員名', value: 'userName', width: '300px' }),
    new Header({ text: 'メール送付', value: 'isEmailSent', filterable: false }),
  ],
  placeHolder: '社員番号／社員名で検索',
  routesTo: staticRoutes.serviceStaffDetail
}

const FRONT_PAGE: PageTypeSpecifications = {
  tableHeaders: [
    new Header({ text: '社員番号', value: 'employeeId', width: '120px' }),
    new Header({ text: '社員名', value: 'userName', width: '300px' }),
    new Header({ text: 'メール送付', value: 'isEmailSent', filterable: false }),
  ],
  placeHolder: '社員番号／社員名で検索',
  routesTo: staticRoutes.frontDetail
}

const LIFE_MANAGER_PAGE: PageTypeSpecifications = {
  tableHeaders: [
    new Header({ text: '社員番号', value: 'employeeId', width: '120px' }),
    new Header({ text: '社員名', value: 'userName', width: '180px' }),
    new Header({ text: '初回ログイン', value: 'initialAuthState', width: '140px', filterable: false }),
    new Header({ text: '初回パスワード', value: 'initialPassword', width: '150px', filterable: false }),
    new Header({ text: '認証キー受信者', value: 'authKeyRecipient', width: '170px', filterable: false }),
    new Header({ text: 'メール送付', value: 'isEmailSent', filterable: false }),
  ],
  placeHolder: '社員番号／社員名で検索',
  routesTo: staticRoutes.lifeManagerDetail
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class StaffsListSubPage extends Mixins(CurrentAdminManager) {
  @Prop({ required: true })
  private readonly pageType!: AdminRole

  inputText = ''
  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) {
    this.inputText = newValue
  }

  keyword = ''
  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) {
    this.keyword = newValue
  }

  INITIAL_AUTH_STATE = Object.freeze(INITIAL_AUTH_STATE)

  private get employees(): MembersGetResponseStaff[] {
    return staffsModule.members(this.pageType)
  }

  private get pageSpecs(): PageTypeSpecifications | undefined {
    switch (this.pageType) {
      case ADMIN_ROLE.SERVICE_STAFF : return SERVICE_STAFF_PAGE
      case ADMIN_ROLE.FRONT : return FRONT_PAGE
      case ADMIN_ROLE.LIFE_MANAGER : return LIFE_MANAGER_PAGE
      default:
    }
  }

  async reload(): Promise<void> {
    this._inputText = ''
    this._keyword = ''
    const req = new MembersGetRequest(this.pageType)
    if (this._keyword) req.keyword = this._keyword
    await staffsModule.fetchMembers(req)
  }

  // タブの切り替え（＝pageTypeが変わるタイミング）で再描画する（createdでのみ再描画する場合、3タブのうち初回に呼ばれる1タブ分しかリロードされないため、タブの切り替え時に再ロードするようにしている）
  @Watch('pageType', { immediate: true })
  tabChanged(): void {
    this.reload()
  }

  created(): void {
    // 想定されるpageType以外が渡された場合、Not Foundページに飛ばす
    if (!this.pageSpecs) this.$router.push({ name: staticRoutes.notFound.name })

    this.reload()
  }

  async search(): Promise<void> {
    this._keyword = this._inputText
  }

  getAuthLabel(initialAuthState: InitialAuthState): string {
    return getInitialAuthStateLabel(initialAuthState)
  }

  openStaffDetailPage(userId: string): void {
    // 該当の各ロール用社員詳細画面に遷移する
    if (this.pageSpecs) this.$router.push({ name: this.pageSpecs.routesTo.name, params: { adminId: userId } })
  }
}
