




















import { Vue, Component, Prop } from 'vue-property-decorator'

const btnTypes = [
  'reflection',
  'primary-fix',
  'primary-flex',
  'secondary',
  'secondary-caution'
] as const
type BtnType = typeof btnTypes[number]

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})
export default class SmBtn extends Vue {
  @Prop({ required: true, default: '' })
  private readonly text!: string

  @Prop({
    default: 'primary-flex',
    validator: c => c === undefined || btnTypes.includes(c)
  })
  private readonly type!: BtnType

  @Prop({ default: false })
  private readonly disabled!: boolean

  private get width(): string | undefined {
    return this.type === 'primary-fix' ? '160px' : undefined
  }

  private get outlined(): boolean {
    return this.type === 'secondary' || this.type === 'secondary-caution'
  }

  private get color(): string {
    switch (this.type) {
      case 'reflection':
        return 'reflection'
      case 'primary-fix':
      case 'primary-flex':
      case 'secondary':
        return 'secondary'
      case 'secondary-caution':
        return 'caution'
      default : {
        const _exhaustiveCheck: never = this.type
        return _exhaustiveCheck
      }
    }
  }

  private get textColor(): string | undefined {
    switch (this.type) {
      case 'reflection':
      case 'primary-fix':
      case 'primary-flex':
        return 'white'
      case 'secondary':
      case 'secondary-caution':
        return undefined // ボタンの色と同じsecondaryカラーになる
      default : {
        const _exhaustiveCheck: never = this.type
        return _exhaustiveCheck
      }
    }
  }
}
