
















































































































































































































































































































import { Header } from '@/components/molecules/SmTableData.vue'
import { Component, Vue } from 'vue-property-decorator'
import { staticRoutesLocal } from '@/routes'
import { OPINION_STATES, TICKET_TYPES, ADMIN_ROLE } from '@/constants/schema-constants'
import type { AdminRole } from '@/constants/schema-constants'
import { Report } from '@/dtos/reports/get'
import { reservePaymentsModule } from '@/stores/reserve-payments-store'
import { ReservePaymentsPdfGetRequest, UnitPaymentPlan } from '@/dtos/reserve-funds/payments/pdf/get'
import { authModule } from '@/stores/auth-store'
import { User } from '@/dtos/commons'

class TableItem {
  roomNumber!: string
  votingRightCount!: number
  userName!: string
  userId!: string
}

@Component({
  components: {
    SmAvatar: () => import('@/components/atoms/SmAvatar.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardSectionText: () => import('@/components/molecules/card/SmCardSectionText.vue'),
    SmListNotice: () => import('@/components/molecules/list/SmListNotice.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),

    SmDialogBase: () => import('@/components/organisms/dialog/SmDialogBase.vue'),
    SmDialogClosable: () => import('@/components/organisms/dialog/SmDialogClosable.vue'),

    SmCardReport: () => import('@/pages/reports/SmCardReport.vue'),

    SmChartReservesPayments: () => import('@/pages/reserve-funds/payments/print/SmChartReservesPayments.vue'),
    SmTableReservesPayments: () => import('@/pages/reserve-funds/payments/print/SmTableReservesPayments.vue'),

    StaffSelectModal: () => import('@/components/organisms/modal/StaffSelectModal.vue'),
    QuestionnaireTemplateSelectModal: () => import('@/components/organisms/modal/QuestionnaireTemplateSelectModal.vue')

  }
})
export default class HelloWorldPage3 extends Vue {
  private selected = []
  private singleSelected = []

  private keyword = ''

  tableHeaders:Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '150px' }),
    new Header({ text: '議決権数', value: 'votingRightCount', filterable: false, width: '150px' }),
    new Header({ text: '氏名', value: 'userName' }),
  ]

  // tableItems = undefined

  showSelect = true
  // custom-sortに指定するオブジェクト
  customSorts = {
    userName: { // 氏名の列のソートを変更する ※以下の例は「氏名の中にある数字」を比較する
      sortMethod: (a:TableItem, b:TableItem):number => {
        const aNumStr = a.userName.match(/\d+/)
        const aNum = aNumStr ? parseInt(aNumStr.toString()) : 0
        const bNumStr = b.userName.match(/\d+/)
        const bNum = bNumStr ? parseInt(bNumStr.toString()) : 0

        return aNum - bNum // a-bで昇順
      }
    }
  }

  tableItems:TableItem[] = [
    { roomNumber: '101', votingRightCount: 8, userName: '秋元真夏1', userId: 'user1' },
    { roomNumber: '102', votingRightCount: 6, userName: '生田絵梨花2', userId: 'user2' },
    { roomNumber: '103', votingRightCount: 5, userName: '樋口日奈3', userId: 'user3' },
    { roomNumber: '104', votingRightCount: 6, userName: '星野みなみ4', userId: 'user4' },
    { roomNumber: '105', votingRightCount: 6, userName: '伊藤純奈5', userId: 'user5' },
    { roomNumber: '106', votingRightCount: 4, userName: '星野みなみ6', userId: 'user6' },
    { roomNumber: '107', votingRightCount: 8, userName: '秋元康7', userId: 'user7' },
    { roomNumber: '108', votingRightCount: 6, userName: 'AKIMOTO9', userId: 'user8' },
    { roomNumber: '109', votingRightCount: 9, userName: 'akimoto10', userId: 'user9' },
    { roomNumber: '110', votingRightCount: 9, userName: 'つんく0', userId: 'user10' },
    { roomNumber: '111', votingRightCount: 3, userName: '秋元真夏11', userId: 'user11' },
    { roomNumber: '112', votingRightCount: 6, userName: '生田絵梨花12', userId: 'user12' },
    { roomNumber: '113', votingRightCount: 3, userName: '樋口日奈13', userId: 'user13' },
    { roomNumber: '114', votingRightCount: 3, userName: '星野みなみ14', userId: 'user14' },
    { roomNumber: '115', votingRightCount: 1, userName: '伊藤純奈15', userId: 'user15' },
    { roomNumber: '116', votingRightCount: 4, userName: '星野みなみ16', userId: 'user16' },
    { roomNumber: '117', votingRightCount: 8, userName: '秋元康17', userId: 'user17' },
    { roomNumber: '118', votingRightCount: 6, userName: 'AKIMOTO18', userId: 'user18' },
    { roomNumber: '119', votingRightCount: 6, userName: 'akimoto19', userId: 'user19' },
    { roomNumber: '120', votingRightCount: 3, userName: '20つんく', userId: 'user20' },
    { roomNumber: '121', votingRightCount: 9, userName: '21つんく', userId: 'user21' },
    { roomNumber: '122', votingRightCount: 2, userName: '22つんく', userId: 'user22' },
    { roomNumber: '123', votingRightCount: 9, userName: '23つんく', userId: 'user23' },
    { roomNumber: '124', votingRightCount: 10, userName: '24つんく', userId: 'user24' },
    { roomNumber: '125', votingRightCount: 7, userName: '25つんく', userId: 'user25' },
    { roomNumber: '126', votingRightCount: 4, userName: '26つんく', userId: 'user26' },
    { roomNumber: '127', votingRightCount: 10, userName: '27つんく', userId: 'user27' },
    { roomNumber: '128', votingRightCount: 6, userName: '28つんく', userId: 'user28' },
    { roomNumber: '129', votingRightCount: 6, userName: '29つんく', userId: 'user29' },
    { roomNumber: '130', votingRightCount: 8, userName: '30つんく', userId: 'user30' },
    { roomNumber: '131', votingRightCount: 4, userName: '31つんく', userId: 'user31' },
    { roomNumber: '132', votingRightCount: 8, userName: '32つんく', userId: 'user32' },
    { roomNumber: '133', votingRightCount: 4, userName: '33つんく', userId: 'user33' },
    { roomNumber: '134', votingRightCount: 3, userName: '34つんく', userId: 'user34' },
    { roomNumber: '135', votingRightCount: 5, userName: '35つんく', userId: 'user35' },
    { roomNumber: '136', votingRightCount: 5, userName: '36つんく', userId: 'user36' },
    { roomNumber: '137', votingRightCount: 9, userName: '37つんく', userId: 'user37' },
    { roomNumber: '138', votingRightCount: 2, userName: '38つんく', userId: 'user38' },
    { roomNumber: '139', votingRightCount: 8, userName: '39つんく', userId: 'user39' },
    { roomNumber: '140', votingRightCount: 1, userName: '40つんく', userId: 'user40' },
    { roomNumber: '141', votingRightCount: 5, userName: '41つんく', userId: 'user41' },
    { roomNumber: '142', votingRightCount: 10, userName: '42つんく', userId: 'user42' },
    { roomNumber: '143', votingRightCount: 11, userName: '43つんく', userId: 'user43' },
    { roomNumber: '144', votingRightCount: 6, userName: '44つんく', userId: 'user44' },
    { roomNumber: '145', votingRightCount: 9, userName: '45つんく', userId: 'user45' },
    { roomNumber: '146', votingRightCount: 9, userName: '46つんく', userId: 'user46' },
    { roomNumber: '147', votingRightCount: 6, userName: '47つんく', userId: 'user47' },
    { roomNumber: '148', votingRightCount: 4, userName: '48つんく', userId: 'user48' },
    { roomNumber: '149', votingRightCount: 4, userName: '49つんく', userId: 'user49' },
    { roomNumber: '150', votingRightCount: 2, userName: '50つんく', userId: 'user50' },
    { roomNumber: '151', votingRightCount: 9, userName: '51つんく', userId: 'user51' },
    { roomNumber: '152', votingRightCount: 5, userName: '52つんく', userId: 'user52' },
    { roomNumber: '153', votingRightCount: 5, userName: '53つんく', userId: 'user53' },
    { roomNumber: '154', votingRightCount: 10, userName: '54つんく', userId: 'user54' },
    { roomNumber: '155', votingRightCount: 9, userName: '55つんく', userId: 'user55' },
    { roomNumber: '156', votingRightCount: 2, userName: '56つんく', userId: 'user56' },
    { roomNumber: '157', votingRightCount: 5, userName: '57つんく', userId: 'user57' },
    { roomNumber: '158', votingRightCount: 4, userName: '58つんく', userId: 'user58' },
    { roomNumber: '159', votingRightCount: 7, userName: '59つんく', userId: 'user59' },
    { roomNumber: '160', votingRightCount: 4, userName: '60つんく', userId: 'user60' },
    { roomNumber: '161', votingRightCount: 7, userName: '61つんく', userId: 'user61' },
    { roomNumber: '162', votingRightCount: 9, userName: '62つんく', userId: 'user62' },
    { roomNumber: '163', votingRightCount: 6, userName: '63つんく', userId: 'user63' },
    { roomNumber: '164', votingRightCount: 3, userName: '64つんく', userId: 'user64' },
    { roomNumber: '165', votingRightCount: 4, userName: '65つんく', userId: 'user65' },
    { roomNumber: '166', votingRightCount: 1, userName: '66つんく', userId: 'user66' },
    { roomNumber: '167', votingRightCount: 8, userName: '67つんく', userId: 'user67' },
    { roomNumber: '168', votingRightCount: 4, userName: '68つんく', userId: 'user68' },
    { roomNumber: '169', votingRightCount: 3, userName: '69つんく', userId: 'user69' },
    { roomNumber: '170', votingRightCount: 11, userName: '70つんく', userId: 'user70' },
    { roomNumber: '171', votingRightCount: 3, userName: '71つんく', userId: 'user71' },
    { roomNumber: '172', votingRightCount: 3, userName: '72つんく', userId: 'user72' },
    { roomNumber: '173', votingRightCount: 7, userName: '73つんく', userId: 'user73' },
    { roomNumber: '174', votingRightCount: 7, userName: '74つんく', userId: 'user74' },
    { roomNumber: '175', votingRightCount: 7, userName: '75つんく', userId: 'user75' },
    { roomNumber: '176', votingRightCount: 3, userName: '76つんく', userId: 'user76' },
    { roomNumber: '177', votingRightCount: 9, userName: '77つんく', userId: 'user77' },
    { roomNumber: '178', votingRightCount: 2, userName: '78つんく', userId: 'user78' },
    { roomNumber: '179', votingRightCount: 6, userName: '79つんく', userId: 'user79' },
    { roomNumber: '180', votingRightCount: 9, userName: '80つんく', userId: 'user80' },
    { roomNumber: '181', votingRightCount: 3, userName: '81つんく', userId: 'user81' },
    { roomNumber: '182', votingRightCount: 4, userName: '82つんく', userId: 'user82' },
    { roomNumber: '183', votingRightCount: 4, userName: '83つんく', userId: 'user83' },
    { roomNumber: '184', votingRightCount: 7, userName: '84つんく', userId: 'user84' },
    { roomNumber: '185', votingRightCount: 2, userName: '85つんく', userId: 'user85' },
    { roomNumber: '186', votingRightCount: 6, userName: '86つんく', userId: 'user86' },
    { roomNumber: '187', votingRightCount: 10, userName: '87つんく', userId: 'user87' },
    { roomNumber: '188', votingRightCount: 3, userName: '88つんく', userId: 'user88' },
    { roomNumber: '189', votingRightCount: 9, userName: '89つんく', userId: 'user89' },
    { roomNumber: '190', votingRightCount: 8, userName: '90つんく', userId: 'user90' },
    { roomNumber: '191', votingRightCount: 3, userName: '91つんく', userId: 'user91' },
    { roomNumber: '192', votingRightCount: 9, userName: '92つんく', userId: 'user92' },
    { roomNumber: '193', votingRightCount: 3, userName: '93つんく', userId: 'user93' },
    { roomNumber: '194', votingRightCount: 7, userName: '94つんく', userId: 'user94' },
    { roomNumber: '195', votingRightCount: 5, userName: '95つんく', userId: 'user95' },
    { roomNumber: '196', votingRightCount: 8, userName: '96つんく', userId: 'user96' },
    { roomNumber: '197', votingRightCount: 2, userName: '97つんく', userId: 'user97' },
    { roomNumber: '198', votingRightCount: 11, userName: '98つんく', userId: 'user98' },
    { roomNumber: '199', votingRightCount: 9, userName: '99つんく', userId: 'user99' },
    { roomNumber: '200', votingRightCount: 4, userName: '100つんく', userId: 'user100' },

  ]

  clickTableBtn(name:string):void {
    console.log(`click btn in table:${name}`)
  }

  goToPage1(): void {
    this.$router.push({ name: staticRoutesLocal.helloWorld.getChild('page1').name })
  }

  selectedPeriod = null

  report:Report = Object.assign(new Report(), {
    idea: {
      ideaId: 'fa44a01a-984a-4372-b069-5e89db8edde9',
      title: 'エントランス花壇リニューアルについて',
      userId: '39278947-5ff0-8392-73a4-53c56a0869b0'
    },
    opinion: {
      opinionId: 'a971b9ef-1ab0-4b78-91b3-c58cd729af78',
      opinionBody: 'この意見全くいいと思いません。Aさんって以前からこういう意見ばかりで場を乱しているとしか思えないので投稿しないでほしいです。この意見全くいいと思いません。Aさんって以前からこういう意見ばかりで場を乱しているとしか思えないので投稿しないでほしいです。この意見全くいいと思いません。Aさんって以前からこういう意見ばかりで場を乱しているとしか思えないので投稿しないでほしいです。',
      ownerUser: {
        userId: '38e81cc4-e4ab-47dd-bcc6-2e4ba63ce318',
        iconImagePath: 'https://d3mot6qoomldbz.cloudfront.net/swagger/userIcons/icon_01.png',
        userName: '樋口日奈',
        roomNumber: '101号室'
      },
      postedAt: '2021年7月2日 11:00',
      isAlternative: false,
      opinionState: OPINION_STATES.ADMIN_DELETED,
      material: {
        materialId: 'aga51gtg-6tgg-5ta6-y7ga-56ya6ha46hga',
        materialType: 1,
        materialUrl: 'http://placehold.jp/800x600.png',
        originalFileName: 'dummy.jpg'
      }
    },
    report: {
      reportId: 'bf7060c2-50a9-44e5-978f-545d6bf6da2e',
      reportedReason: '問題を誘発する内容のため',
      postedAt: '2021年7月2日 11:30',
      ownerUser: {
        userId: 'beacc644-6d53-466a-a77e-705187fe132d',
        iconImagePath: 'https://d3mot6qoomldbz.cloudfront.net/swagger/userIcons/icon_01.png',
        userName: '斎藤薫',
        roomNumber: '103号室'
      }
    }
  })

  goContent(notification:Notification):void {
    console.log('goContent')
    console.log(notification)
  }

  goBuilding(notification:Notification):void {
    console.log('goContent')
    console.log(notification)
  }

  async created():Promise<void> {
    const req = new ReservePaymentsPdfGetRequest()
    req.buildingUnits = []
    req.paymentPlans = []

    await reservePaymentsModule.fetchPaymentsPdf(req)
  }

  TICKET_TYPES = Object.freeze(TICKET_TYPES)

  private get unitPaymentPlan():UnitPaymentPlan { return reservePaymentsModule.paymentPlansPdf.unitPaymentPlans[0] }

  clickCardNotification(notificationId:string):void {
    console.log(`clicked: ${notificationId}`)
  }

  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)

  isStaffModalOpen = false
  adminRole:AdminRole = ADMIN_ROLE.SERVICE_STAFF
  placeholder = ''
  roleName = ''
  staffInputText = ''
  staffKeyword = ''
  staffs:User[] = []

  openStaffModal(adminRole:AdminRole, roleName:string, placeholder:string):void {
    this.adminRole = adminRole
    this.roleName = roleName
    this.placeholder = placeholder
    this.isStaffModalOpen = true
  }

  selectStaffs(staffs: User[]):void {
    console.log('担当者を選択しました')
    this.staffs = [...staffs]
  }

  isTemplateSelectModalOpen = false
  questionnaireTemplateInputText = ''
  questionnaireTemplateKeyword = ''

  openTemplateSelectModal():void {
    this.isTemplateSelectModalOpen = true
  }

  async forceLogout():Promise<void> {
    await authModule.logout()
  }

  testMenu = ''
  testMenus = {
    menu1: {
      text: 'アイデア（区分所有者の投稿）',
      label: ''
    },
    menu2: {
      text: 'アイデア（賛同・意見受付用）',
      label: ''
    }
  }

  onClickMenu(roomNumber:string, menuId:string):void {
    console.log(roomNumber, menuId)
  }

  createQuestionnaire(questionnaireTemplateId:string):void {
    console.log(`テンプレート${questionnaireTemplateId}をもとにアンケートを作成します`)
  }

  createTemplate():void {
    console.log('アンケートテンプレートを新規作成します')
  }

  editTemplate(questionnaireTemplateId:string):void {
    console.log(`テンプレート${questionnaireTemplateId}を編集します`)
  }

  replicateTemplate(questionnaireTemplateId:string):void {
    console.log(`テンプレート${questionnaireTemplateId}を複製して新規作成します`)
  }
}
