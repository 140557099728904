import { Type } from 'class-transformer'

import { BaseIdea, Estimate, Material, Section } from '@/dtos/commons'

export class PreviewBudget {
  budget?:number
  budgetLabel!:string
  expense?:number
  expenseLabel!:string
  spent?:number
  spentLabel:string | null = null
  balanceLabel!:string
}

export class PreviewElement {
  sectionTitle?: string
  sectionBody?: string
  @Type(() => Material) material?: Material | null = null
}

export class OnlineResolutionsPostRequest {
  title!: string
  deadline!: string
  voteDeadline!: string
  matter!: string
  baseIdea?: BaseIdea
  estimates?: Estimate[]
  sections?: Section[]
  resolutionId?: string
  ticketId!: string

  constructor(title: string, deadline: string, voteDeadline: string, matter: string,
    estimates: Estimate[], sections: Section[], ticketId:string, resolutionId?: string, baseIdea?: BaseIdea) {
    this.title = title
    this.deadline = deadline
    this.voteDeadline = voteDeadline
    this.matter = matter
    this.estimates = estimates
    this.sections = sections
    this.ticketId = ticketId
    this.resolutionId = resolutionId
    this.baseIdea = baseIdea
  }
}
