import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { plainToClass, Type } from 'class-transformer'
import { isAlreadyLocalStoreInitialized, store } from './index'

import type { HoldingMethodType, IdeaState, IdeaType, ResolutionState } from '@/constants/schema-constants'

import { BaseIdea, Material, MaterialFormInputDto, Subject, User } from '@/dtos/commons'
import { PreviewPlan, PreviewSession } from '@/dtos/ideas/admin-idea/post'
import { Opinion } from '@/dtos/ideas/admin-idea/opinions/get'
import { PreviewBudget, PreviewElement } from '@/dtos/resolutions/online-resolution/post'
import { OwnerNotificationsPostRequestFFNElement } from '@/dtos/owner-notifications/post'
import { QuestionnaireSectionForPreview } from '@/dtos/questionnaires/commons'
import { ImplementationResultForPreview, ResultForPreview, OpinionForPreview } from '@/dtos/questionnaires/reports/commons'

/**
 * プレビュー画面等で別タブで画面表示をさせたい場合に、パラメータの保管先として用いるストア
 * 1画面に複数の保管対象が存在する前提のため、キーは各画面で一意になるよう定義する
 *
 * 保存したパラメータはブラウザやタブを閉じてもリセットされないため、リセットタイミングには注意すること
 */

export class OwnerIdeaPreviewContent {
  ideaType!: IdeaType
  title!: string
  background!: string

  @Type(() => Material) material?: Material
  readCount!: string

  @Type(() => User)ownerUser!: User
  postedAt!: string
}

export class AdminIdeaPreviewContent {
  ideaId!: string
  title!: string
  ideaState!: IdeaState
  isAgreementDenied!: boolean
  @Type(() => PreviewSession)previewSession?: PreviewSession
  @Type(() => PreviewPlan)previewPlans!: PreviewPlan[]
  @Type(() => Opinion)previewOpinions?: Opinion[]
  @Type(() => BaseIdea) previewBaseIdea?: BaseIdea
  agreedOwnerCount!: number
  opinionDeadline!: string
  deadline!: string
  lastUpdatedAt?: string
  @Type(() => User) user!: User
  postedAt!: string
  currentBuildingId!: string
  ownerCount!: number
  agreementRequirement!: string
  isUpdates!: boolean
}

export class OnlineResolutionPreviewContent {
  resolutionId!: string
  title!: string
  resolutionState!: ResolutionState
  deadlineDateTime!: string
  matter!: string
  isQustions!: boolean
  @Type(() => PreviewBudget) previewBudgets?: PreviewBudget[]
  @Type(() => PreviewElement) previewElements?: PreviewElement[]
  @Type(() => BaseIdea) baseIdea?: BaseIdea
  currentBuildingId!: string
}

export class OwnerNotificationPreviewContent {
  title!: string
  @Type(() => OwnerNotificationsPostRequestFFNElement)freeFormatNotificationElements!: OwnerNotificationsPostRequestFFNElement[]
  @Type(() => MaterialFormInputDto)material?: MaterialFormInputDto
  buildingId?: string
}

export class GMResolutionPreviewContent {
  resolutionId!: string
  resolutionState!: ResolutionState
  title!: string
  @Type(() => Material) material!: Material
  holdingAt!: string
  holdingDate!: string
  holdingMethodType!: HoldingMethodType
  venue!: string
  statementDeadlineText!: string
  statementDeadlineAnnotation?: string
  meetingUrl?: string
  meetingInformation?: string
  @Type(() => Subject) previewSubjects!: Subject[]
  isQuestions!: boolean
  currentBuildingId!: string
}

export class QuestionnairePreviewContent {
  questionnaireTitle!: string
  explanation!: string
  deadline!: string
  @Type(() => MaterialFormInputDto)material?: MaterialFormInputDto
  sections?: QuestionnaireSectionForPreview[]
}

export class QuestionnaireReportPreviewContent {
  questionnaireReportTitle!: string
  explanation!: string
  @Type(() => MaterialFormInputDto)material?: MaterialFormInputDto
  @Type(() => ImplementationResultForPreview)implementationResult?: ImplementationResultForPreview
  @Type(() => ResultForPreview)result!: ResultForPreview
  @Type(() => OpinionForPreview)opinions!: OpinionForPreview[]
}

@Module({ store, dynamic: true, namespaced: true, name: 'newTabLocalParamStorage', preserveState: isAlreadyLocalStoreInitialized })
class NewTabLocalParamStorageStore extends VuexModule {
  // アイデアプレビュー画面の表示内容
  private _ownerIdeaPreviewContents: Record<string, OwnerIdeaPreviewContent> = {}

  get ownerIdeaPreviewContent(): (key: string) => OwnerIdeaPreviewContent | undefined { return (key: string) => this._ownerIdeaPreviewContents[key] ? plainToClass(OwnerIdeaPreviewContent, this._ownerIdeaPreviewContents[key]) : undefined }

  @Mutation
  private SET_OWNER_IDEA_PREVIEW_CONTENT({ key, ownerIdeaPreviewContent }: { key: string, ownerIdeaPreviewContent: OwnerIdeaPreviewContent }): void {
    Vue.set(this._ownerIdeaPreviewContents, key, ownerIdeaPreviewContent)
  }

  @Mutation
  private DELETE_OWNER_IDEA_PREVIEW_CONTENT(key: string): void {
    Vue.delete(this._ownerIdeaPreviewContents, key)
  }

  @Action
  setOwnerIdeaPreviewContent({ key, ownerIdeaPreviewContent } : { key: string, ownerIdeaPreviewContent: OwnerIdeaPreviewContent }): void {
    this.SET_OWNER_IDEA_PREVIEW_CONTENT({ key, ownerIdeaPreviewContent })
  }

  // プランプレビュー画面の表示内容
  private _adminIdeaPreviewContents: Record<string, AdminIdeaPreviewContent> = {}

  get adminIdeaPreviewContent(): (key: string) => AdminIdeaPreviewContent | undefined { return (key: string) => this._adminIdeaPreviewContents[key] ? plainToClass(AdminIdeaPreviewContent, this._adminIdeaPreviewContents[key]) : undefined }

  @Mutation
  private SET_ADMIN_IDEA_PREVIEW_CONTENT({ key, adminIdeaPreviewContent }: { key: string, adminIdeaPreviewContent: AdminIdeaPreviewContent }): void {
    Vue.set(this._adminIdeaPreviewContents, key, adminIdeaPreviewContent)
  }

  @Mutation
  private DELETE_ADMIN_IDEA_PREVIEW_CONTENT(key: string): void {
    Vue.delete(this._adminIdeaPreviewContents, key)
  }

  @Action
  setAdminIdeaPreviewContent({ key, adminIdeaPreviewContent } : { key: string, adminIdeaPreviewContent: AdminIdeaPreviewContent }): void {
    this.SET_ADMIN_IDEA_PREVIEW_CONTENT({ key, adminIdeaPreviewContent })
  }

  // オンライン決議プレビュー画面の表示内容
  private _onlineResolutionPreviewContents: Record<string, OnlineResolutionPreviewContent> = {}

  get onlineReslutionPreviewContent(): (key: string) => OnlineResolutionPreviewContent | undefined { return (key: string) => this._onlineResolutionPreviewContents[key] ? plainToClass(OnlineResolutionPreviewContent, this._onlineResolutionPreviewContents[key]) : undefined }

  @Mutation
  private SET_ONLINE_RESOLUTION_PREVIEW_CONTENT({ key, onlineResolutionPreviewContent }: { key: string, onlineResolutionPreviewContent: OnlineResolutionPreviewContent }): void {
    Vue.set(this._onlineResolutionPreviewContents, key, onlineResolutionPreviewContent)
  }

  @Mutation
  private DELETE_ONLINE_RESOLUTION_PREVIEW_CONTENT(key: string): void {
    Vue.delete(this._onlineResolutionPreviewContents, key)
  }

  @Action
  setOnlineResolutionPreviewContent({ key, onlineResolutionPreviewContent } : { key: string, onlineResolutionPreviewContent: OnlineResolutionPreviewContent }): void {
    this.SET_ONLINE_RESOLUTION_PREVIEW_CONTENT({ key, onlineResolutionPreviewContent })
  }

  // 総会決議プレビュー画面の表示内容
  private _gmResolutionPreviewContents: Record<string, GMResolutionPreviewContent> = {}

  get gmResolutionPreviewContent(): (key: string) => GMResolutionPreviewContent | undefined { return (key: string) => this._gmResolutionPreviewContents[key] ? plainToClass(GMResolutionPreviewContent, this._gmResolutionPreviewContents[key]) : undefined }

  @Mutation
  private SET_GM_RESOLUTION_PREVIEW_CONTENT({ key, gmResolutionPreviewContent }: { key: string, gmResolutionPreviewContent: GMResolutionPreviewContent }): void {
    Vue.set(this._gmResolutionPreviewContents, key, gmResolutionPreviewContent)
  }

  @Mutation
  private DELETE_GM_RESOLUTION_PREVIEW_CONTENT(key: string): void {
    Vue.delete(this._gmResolutionPreviewContents, key)
  }

  @Action
  setGMResolutionPreviewContent({ key, gmResolutionPreviewContent } : { key: string, gmResolutionPreviewContent: GMResolutionPreviewContent }): void {
    this.SET_GM_RESOLUTION_PREVIEW_CONTENT({ key, gmResolutionPreviewContent })
  }

  // 区分所有者お知らせプレビュー画面の表示内容
  private _ownerNotificationPreviewContents: Record<string, OwnerNotificationPreviewContent> = {}

  get ownerNotificationPreviewContent(): (key: string) => OwnerNotificationPreviewContent | undefined { return (key: string) => this._ownerNotificationPreviewContents[key] ? plainToClass(OwnerNotificationPreviewContent, this._ownerNotificationPreviewContents[key]) : undefined }

  @Mutation
  private SET_OWNER_NOTIFICATION_PREVIEW_CONTENT({ key, ownerNotificationPreviewContent }: { key: string, ownerNotificationPreviewContent: OwnerNotificationPreviewContent }): void {
    Vue.set(this._ownerNotificationPreviewContents, key, ownerNotificationPreviewContent)
  }

  @Mutation
  private DELETE_OWNER_NOTIFICATION_PREVIEW_CONTENT(key: string): void {
    Vue.delete(this._ownerNotificationPreviewContents, key)
  }

  @Action
  setOwnerNotificationPreviewContent({ key, ownerNotificationPreviewContent } : { key: string, ownerNotificationPreviewContent: OwnerNotificationPreviewContent }): void {
    this.SET_OWNER_NOTIFICATION_PREVIEW_CONTENT({ key, ownerNotificationPreviewContent })
  }

  @Action
  deleteOwnerNotificationPreviewContent(key: string): void {
    this.DELETE_OWNER_NOTIFICATION_PREVIEW_CONTENT(key)
  }

  @Action
  deleteAllOwnerNotificationPreviewContents(): void {
    Object.keys(this._ownerNotificationPreviewContents).forEach(key => this.DELETE_OWNER_NOTIFICATION_PREVIEW_CONTENT(key))
  }

  // アンケートプレビュー画面の表示内容
  private _questionnairePreviewContents: Record<string, QuestionnairePreviewContent> = {}

  get questionnairePreviewContent(): (key: string) => QuestionnairePreviewContent | undefined { return (key: string) => this._questionnairePreviewContents[key] ? plainToClass(QuestionnairePreviewContent, this._questionnairePreviewContents[key]) : undefined }

  @Mutation
  private SET_QUESTIONNAIRE_PREVIEW_CONTENT({ key, questionnairePreviewContent }: { key: string, questionnairePreviewContent: QuestionnairePreviewContent }): void {
    Vue.set(this._questionnairePreviewContents, key, questionnairePreviewContent)
  }

  @Action
  setQuestionnairePreviewContent({ key, questionnairePreviewContent } : { key: string, questionnairePreviewContent: QuestionnairePreviewContent }): void {
    this.SET_QUESTIONNAIRE_PREVIEW_CONTENT({ key, questionnairePreviewContent })
  }

  // アンケート報告書プレビュー画面の表示内容
  private _questionnaireReportPreviewContents: Record<string, QuestionnaireReportPreviewContent> = {}

  get questionnaireReportPreviewContent(): (key: string) => QuestionnaireReportPreviewContent | undefined { return (key: string) => this._questionnaireReportPreviewContents[key] ? plainToClass(QuestionnaireReportPreviewContent, this._questionnaireReportPreviewContents[key]) : undefined }

  @Mutation
  private SET_QUESTIONNAIRE_REPORT_PREVIEW_CONTENT({ key, questionnaireReportPreviewContent }: { key: string, questionnaireReportPreviewContent: QuestionnaireReportPreviewContent }): void {
    Vue.set(this._questionnaireReportPreviewContents, key, questionnaireReportPreviewContent)
  }

  @Mutation
  private DELETE_QUESTIONNAIRE_REPORT_PREVIEW_CONTENT(key: string): void {
    Vue.delete(this._questionnaireReportPreviewContents, key)
  }

  @Action
  setQuestionnaireReportPreviewContent({ key, questionnaireReportPreviewContent }: { key :string, questionnaireReportPreviewContent: QuestionnaireReportPreviewContent}): void {
    this.SET_QUESTIONNAIRE_REPORT_PREVIEW_CONTENT({ key, questionnaireReportPreviewContent })
  }

  @Action
  deleteQuestionnaireReportPreviewContent(key: string): void {
    this.DELETE_QUESTIONNAIRE_REPORT_PREVIEW_CONTENT(key)
  }

  @Action
  deleteAllQuestionnaireReportPreviewContents(): void {
    Object.keys(this._questionnaireReportPreviewContents).forEach(key => this.DELETE_QUESTIONNAIRE_REPORT_PREVIEW_CONTENT(key))
  }
}

export const newTabLocalParamStorageModule = getModule(NewTabLocalParamStorageStore)
