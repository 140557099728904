






































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'

import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { openNewTab } from '@/libs/open-new-tab'
import { staticKeyProvider } from '@/libs/static-key-provider'
import { canEditTicketTaskName, getTicketTaskNameTypeLabel, isOwnerResponseTasks } from '@/libs/type-handler'

import type { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'

import { NEW_TAB_TRANSITION_TO, RESOLUTION_STATES, RESOLUTION_TYPES, TASK_INQUIRY_SOURCE_TYPES, TICKET_STATES, TICKET_TASK_STATES } from '@/constants/schema-constants'
import type { TicketTaskState } from '@/constants/schema-constants'

import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { Task, TaskPostRequest, TaskPostResponse } from '@/dtos/tasks/post'
import { TaskPutRequest } from '@/dtos/tasks/put'
import { TaskStatePutRequest } from '@/dtos/tasks/state/put'
import { ListResponseTaskDto, TaskBuilding, TaskInquirySource, TasksSearchPostRequest, Ticket } from '@/dtos/tasks/search/post'
import { TicketGetResponse, TicketsGetRequest } from '@/dtos/tickets/get'

import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { tasksModule } from '@/stores/tasks-store'
import { ticketsModule } from '@/stores/tickets-store'

const TAKE = 20

// 並び替えメニューの内容
const SORT_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  accrualDate: {
    text: '発生日が新しい順',
    label: '発生日が新しい順'
  },
  deadline: {
    text: '期日が古い順',
    label: '期日が古い順'
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmBtnToggle: () => import('@/components/molecules/SmBtnToggle.vue'),
    SmCardTask: () => import('@/components/molecules/card/SmCardTask.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    TasksPostForm: () => import('@/components/molecules/TasksPostForm.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
  }
})
export default class TasksSubPage extends Vue {
  @Prop({ required: true, default: '' })
  private readonly ticketId!: string

  // フォーカスするチケットタスクID
  @Prop({ default: '' })
  readonly ticketTaskId?: string

  // 未読・処理中のみフラグ
  onlyNotCompleted = false
  // 区分所有者への対応タスクのみフラグ
  onlyResponseOwnerTasks = false
  // 並べ替えメニューの選択中のキー
  sortItem = 'accrualDate'
  // 並べ替えメニューの表示項目
  SORT_MENU_ITEMS = Object.freeze(SORT_MENU_ITEMS)

  ticketTaskPostForms: Task[] = []

  ticketTasks: ListResponseTaskDto[] = []

  get skipToken(): string | null { return tasksModule.skipToken }

  get tasks(): ListResponseTaskDto[] { return tasksModule.tasks }

  get isTicketCompleted(): boolean { return this.ticketCommon.ticketState === TICKET_STATES.COMPLETED }

  get ticketCommon(): TicketGetResponse {
    return ticketsModule.ticketDetail(this.ticketId) ?? new TicketGetResponse()
  }

  // タスクカード内の担当者を更新するためにチケット詳細の担当者変更を監視する
  @Watch('ticketCommon.admin.userId')
  onChangeTicket():void {
    this.reloadTasks()
  }

  private req = new TasksSearchPostRequest(TAKE)

  created(): void {
    this.req.ticketId = this.ticketId
    this.req.ticketTaskStates = undefined
    this.req.sortItem = this.sortItem
    // 不要なリクエストパラメータを送信しない
    this.req.ticketTypes = undefined
    this.req.buildings = undefined
    this.req.staffs = undefined
    if (this.req.postedDate !== undefined && this.req.postedDate !== null) { this.req.postedDate.fromDate = undefined }
    if (this.req.postedDate !== undefined && this.req.postedDate !== null) { this.req.postedDate.toDate = undefined }
    if (this.req.deadline !== undefined && this.req.deadline !== null) { this.req.deadline.fromDate = undefined }
    if (this.req.deadline !== undefined && this.req.deadline !== null) { this.req.deadline.toDate = undefined }
    this.req.keyword = undefined
    this.reloadTasks()
  }

  async fetchTicketDetail(): Promise<void> {
    await ticketsModule.fetchTicketDetail(new TicketsGetRequest(this.ticketId))
  }

  async fetchTicketTasks(): Promise<void> {
    this.req.ticketTaskStates = this.onlyNotCompleted ? [TICKET_TASK_STATES.NOT_STARTED_YET, TICKET_TASK_STATES.NOT_COMPLETED] : undefined
    this.req.skipToken = this.skipToken ?? undefined
    await tasksModule.fetchTasks(this.req)

    const copiedTicketTasks = deepCopy(
      this.tasks,
      {
        ListResponseTaskDto: new ColumnToType(ListResponseTaskDto),
        ticket: new ColumnToType(Ticket),
        taskInquirySource: new ColumnToType(TaskInquirySource),
        building: new ColumnToType(TaskBuilding),
      },
      'TasksSearchPostResponse'
    )
    this.ticketTasks = copiedTicketTasks
  }

  // --------------- データの読み込み ---------------
  identifier = 1
  isWaitingSwitch = false
  handler: LoadingHandler | null = null

  // チケットタスクを読み込み
  isLoadingTasks = false
  async loadTasks(handler: LoadingHandler): Promise<void> {
    if (this.isLoadingTasks) {
      return
    }
    this.isLoadingTasks = true
    this.isWaitingSwitch = true
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }

    const beforeLength = this.ticketTasks.length

    // チケットタスク一覧取得
    await this.fetchTicketTasks()
    this.isLoadingTasks = false

    // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
    if (this.ticketTasks.length === 0) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = false
    handler.loaded()

    const expectingToBe = beforeLength + this.req.take
    if (this.ticketTasks.length < expectingToBe) handler.complete()
  }

  // チケットタスク一覧再読み込み
  private reloadTasks(): void {
    tasksModule.clearFetchedTask()
    this.ticketTasks = []
    this.identifier++
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
    this.isWaitingSwitch = false

    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }

  onChangeFilter(): void {
    this.req.ticketTaskStates = this.onlyNotCompleted ? [TICKET_TASK_STATES.NOT_STARTED_YET, TICKET_TASK_STATES.NOT_COMPLETED] : undefined
    this.req.ticketTaskTypes = this.onlyResponseOwnerTasks ? isOwnerResponseTasks(this.onlyResponseOwnerTasks) : undefined
    this.reloadTasks()
  }

  onChangeSortOrder(): void {
    this.req.sortItem = this.sortItem
    this.reloadTasks()
  }

  // --------------- タスクカード内ボタンクリック処理 ---------------
  changeEditMode(task :ListResponseTaskDto, isEditMode: boolean): void { task.isEditMode = isEditMode }

  onClickAddTicketTask(): void {
    // 一意なキーを持つタスクカードを追加する
    const task = staticKeyProvider.create(Task)
    this.ticketTaskPostForms.unshift(task)
  }

  targetCreateTaskIndex: number | null = null
  targetCreateTask: Task | null = null

  async onClickTicketTaskCreate(index: number, task: Task): Promise<void> {
    this.targetCreateTaskIndex = index
    this.targetCreateTask = task

    if (this.targetCreateTaskIndex === null || this.targetCreateTask === null) return

    // タスク登録API呼び出し
    const req = new TaskPostRequest(this.ticketCommon.ticketId, this.targetCreateTask.taskName, this.targetCreateTask.deadline)
    await tasksModule.postTasks(req)

    // タスクカードのリストの先頭に作成したタスクを追加する
    const createdTask = tasksModule.postTaskResponse
    if (!createdTask) return
    const listResponseTaskDto = this._toListResponseTaskDto(createdTask) // タスク一覧の型に変換
    this.ticketTasks.unshift(listResponseTaskDto)

    // タスクカード（新規作成）を削除する
    this.ticketTaskPostForms.splice(this.targetCreateTaskIndex, 1)

    // チケット詳細（共通）を更新する
    await this.fetchTicketDetail()
    this.reloadTasks()

    this.targetCreateTaskIndex = null
    this.targetCreateTask = null
  }

  onClickTicketTaskDelete(index: number):void {
    // タスク新規作成カードを削除する
    this.ticketTaskPostForms.splice(index, 1)
  }

  targetUpdateTaskIndex: number | null = null
  targetUpdateTask: ListResponseTaskDto | null = null

  async updateTask(index: number, task: ListResponseTaskDto): Promise<void> {
    this.targetUpdateTaskIndex = index
    this.targetUpdateTask = task

    if (this.targetUpdateTaskIndex === null || this.targetUpdateTask === null) return

    const targetTask = this.ticketTasks[this.targetUpdateTaskIndex]

    const req = new TaskPutRequest(targetTask.ticketTaskId, this.targetUpdateTask.ticketTaskState, this.targetUpdateTask.ticketTaskNameType, this.targetUpdateTask.deadlineDate, targetTask.version)
    // タスク名が手動で変更可能な場合のみタスク名をリクエストに設定する。タスク名が手動で変更可能な場合以外はタスク名種別に紐づくタスク名をAPIにて登録する
    if (canEditTicketTaskName(this.targetUpdateTask.ticketTaskNameType)) {
      req.ticketTaskName = this.targetUpdateTask.ticketTaskName
    }

    await tasksModule.putTasks(req)
    const res = tasksModule.putTaskResponse(targetTask.ticketTaskId)
    if (!res) return

    // 画面の再読み込みはせず、必要な部分だけクライアント側で更新する
    targetTask.ticketTaskState = this.targetUpdateTask.ticketTaskState
    targetTask.ticketTaskNameType = this.targetUpdateTask.ticketTaskNameType
    targetTask.ticketTaskName = canEditTicketTaskName(this.targetUpdateTask.ticketTaskNameType) ? this.targetUpdateTask.ticketTaskName : getTicketTaskNameTypeLabel(this.targetUpdateTask.ticketTaskType) // タスク名が変えられないタスク場合は、タスク種別に紐付くタスク名をセットする
    targetTask.deadline = this.targetUpdateTask.deadline
    targetTask.deadlineDate = this.targetUpdateTask.deadlineDate
    targetTask.version = res.version
    targetTask.isExpired = false // タスク更新後は期限切れフラグをクリアする
    targetTask.isEditMode = false

    // Vueに変更を伝える（配列の場合はVue.setを使用しないと変更を伝えられない）
    Vue.set(this.ticketTasks, this.targetUpdateTaskIndex, targetTask)

    this.targetUpdateTaskIndex = null
    this.targetUpdateTask = null
  }

  isCompleteDialogVisible = false
  targetCompleteTaskIndex: number | null = null
  targetCompleteTask: ListResponseTaskDto | null = null

  openCompleteConfirmationDialog(ticketTask: ListResponseTaskDto, index: number): void {
    this.targetCompleteTaskIndex = index
    this.targetCompleteTask = ticketTask
    this.isCompleteDialogVisible = true
  }

  async changeStateComplete(): Promise<void> {
    this.isCompleteDialogVisible = false

    if (this.targetCompleteTaskIndex === null || this.targetCompleteTask === null) return

    await this.changeState(this.targetCompleteTask, this.targetCompleteTaskIndex, TICKET_TASK_STATES.COMPLETED)

    this.targetCompleteTaskIndex = null
    this.targetCompleteTask = null
  }

  targetNotCompleteTaskIndex: number | null = null
  targetNotCompleteTask: ListResponseTaskDto | null = null

  async changeStateNotComplete(ticketTask: ListResponseTaskDto, index: number): Promise<void> {
    this.targetNotCompleteTaskIndex = index
    this.targetNotCompleteTask = ticketTask

    if (this.targetNotCompleteTask === null || this.targetNotCompleteTaskIndex === null) return

    await this.changeState(this.targetNotCompleteTask, this.targetNotCompleteTaskIndex, TICKET_TASK_STATES.NOT_COMPLETED)

    this.targetNotCompleteTaskIndex = null
    this.targetNotCompleteTask = null
  }

  isUnnecessaryDialogVisible = false
  targetUnnecessaryTaskIndex: number | null = null
  targetUnnecessaryTask: ListResponseTaskDto | null = null

  openUnnecessaryConfirmationDialog(ticketTask: ListResponseTaskDto, index: number): void {
    this.targetUnnecessaryTaskIndex = index
    this.targetUnnecessaryTask = ticketTask
    this.isUnnecessaryDialogVisible = true
  }

  async changeStateUnnecessary(): Promise<void> {
    this.isUnnecessaryDialogVisible = false
    if (this.targetUnnecessaryTaskIndex === null || this.targetUnnecessaryTask === null) return

    await this.changeState(this.targetUnnecessaryTask, this.targetUnnecessaryTaskIndex, TICKET_TASK_STATES.UNNECESSARY)

    this.targetUnnecessaryTaskIndex = null
    this.targetUnnecessaryTask = null
  }

  async changeState(ticketTask: ListResponseTaskDto, index: number, state: TicketTaskState): Promise<void> {
    // タスクステータス更新
    const req = new TaskStatePutRequest(ticketTask.ticketTaskId, state, ticketTask.version)
    await tasksModule.putTaskStates(req)

    // タスクステータス更新結果を反映する
    const res = tasksModule.putTaskStateResponse(ticketTask.ticketTaskId)
    if (!res) return
    ticketTask.ticketTaskState = res.taskState
    ticketTask.version = res.version
    ticketTask.completedAt = res.completedAt
    Vue.set(this.ticketTasks, index, ticketTask)

    // チケット詳細（共通）を更新する
    await this.fetchTicketDetail()
  }

  async createOnlineResolution(ticketTask: ListResponseTaskDto): Promise<void> {
    if (!ticketTask.building) return
    await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(ticketTask.building.buildingId))
    currentStateModule.setCurrentBuilding(ticketTask.building.buildingId)
    this.$router.push({ name: staticRoutes.onlineResolutionCreate.name, query: { ticketId: this.ticketId } })
  }

  async createGeneralMeetingResolution(ticketTask: ListResponseTaskDto): Promise<void> {
    if (!ticketTask.building) return
    await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(ticketTask.building.buildingId))
    currentStateModule.setCurrentBuilding(ticketTask.building.buildingId)
    this.$router.push({ name: staticRoutes.gmResolutionCreate.name, query: { ticketId: this.ticketId } })
  }

  async createFromDraft(ticketTask: ListResponseTaskDto): Promise<void> {
    if (!ticketTask.resolution) return
    if (!ticketTask.building) return
    await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(ticketTask.building.buildingId))
    currentStateModule.setCurrentBuilding(ticketTask.building.buildingId)

    switch (ticketTask.resolution.resolutionType) {
      case RESOLUTION_TYPES.GENERAL_MEETING:
        if (ticketTask.resolution.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.DRAFT) {
          this.$router.push({ name: staticRoutes.gmResolutionCreate.name, query: { resolutionId: ticketTask.resolution.resolutionId, ticketId: this.ticketId } })
        }
        break
      case RESOLUTION_TYPES.ONLINE:
        if (ticketTask.resolution.resolutionState === RESOLUTION_STATES.ONLINE.DRAFT) {
          this.$router.push({ name: staticRoutes.onlineResolutionCreate.name, query: { resolutionId: ticketTask.resolution.resolutionId, ticketId: this.ticketId } })
        }
        break
      default: return assertExhaustive(ticketTask.resolution.resolutionType)
    }
  }

  goContent(ticketTask: ListResponseTaskDto, inquirySourceIndex: number): void {
    if (!ticketTask.building || !ticketTask.inquirySources || !ticketTask.inquirySources[inquirySourceIndex]) return
    this.openInquirySourcePage(ticketTask.building.buildingId, ticketTask.inquirySources[inquirySourceIndex])
  }

  goBuilding(ticketTask: ListResponseTaskDto): void {
    if (!ticketTask.building) return
    this.openBuildingDetailPage(ticketTask.building.buildingId)
  }

  openInquirySourcePage(buildingId: string, inquirySource: TaskInquirySource): void {
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId

    switch (inquirySource.inquirySourceType) {
      case TASK_INQUIRY_SOURCE_TYPES.OWNER_IDEA: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.IDEA.OWNER
        newTabTransitionParams.ideaId = inquirySource.inquirySourceId
        break
      }
      case TASK_INQUIRY_SOURCE_TYPES.ADMIN_IDEA: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.IDEA.ADMIN
        newTabTransitionParams.ideaId = inquirySource.inquirySourceId
        break
      }
      case TASK_INQUIRY_SOURCE_TYPES.CONSULTATION: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.QA
        newTabTransitionParams.userId = inquirySource.inquirySourceId
        break
      }
      case TASK_INQUIRY_SOURCE_TYPES.GM_RESOLUTION: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.RESOLUTION.GENERAL_MEETING
        newTabTransitionParams.resolutionId = inquirySource.inquirySourceId
        break
      }
      case TASK_INQUIRY_SOURCE_TYPES.ONLINE_RESOLUTION: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.RESOLUTION.ONLINE
        newTabTransitionParams.resolutionId = inquirySource.inquirySourceId
        break
      }
      default: return assertExhaustive(inquirySource.inquirySourceType)
    }

    openNewTab(newTabTransitionParams)
  }

  openBuildingDetailPage(buildingId: string): void {
    // 該当のマンション詳細画面を別タブで開く
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId
    openNewTab(newTabTransitionParams)
  }

  private _toListResponseTaskDto(task: TaskPostResponse): ListResponseTaskDto {
    const res = new ListResponseTaskDto()
    res.ticketTaskId = task.taskId
    res.ticketTaskType = task.taskType
    res.ticketTaskState = task.taskState
    res.ticketTaskNameType = task.taskNameType
    res.ticketTaskName = task.taskName
    res.deadline = task.deadline
    res.deadlineDate = task.deadlineDate
    res.postedAt = task.postedAt
    res.isExpired = false
    res.version = task.version
    res.ticket = this._toTicketDto(this.ticketCommon)
    res.inquirySources = task.inquirySources
    res.building = task.building
    return res
  }

  private _toTicketDto(ticketDetail: TicketGetResponse): Ticket {
    const ticket = new Ticket()
    ticket.ticketId = ticketDetail.ticketId
    ticket.ticketNo = ticketDetail.ticketNo
    ticket.ticketName = ticketDetail.ticketName
    ticket.ticketType = ticketDetail.ticketType
    ticket.adminName = ticketDetail.admin.userName
    return ticket
  }
}
