import { noticesClient } from '@/clients/notices-client'
import { Notice, NoticesPostRequest, NoticesPostResponse } from '@/dtos/notices/post'
import { NoticeReadPutRequest } from '@/dtos/notices/read/put'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'

@Module({ store, dynamic: true, namespaced: true, name: 'notices' })
class NoticesStore extends VuexModule {
  private _notices:Notice[] = []
  private _skipToken:string | null = null

  public get notices(): Notice[] { return this._notices }
  public get skipToken():string | null { return this._skipToken }

  @Mutation
  private PUSH_NOTICES(res:NoticesPostResponse):void {
    this._notices.push(...res.notices)
    this._skipToken = res.skipToken
  }

  @Mutation
  private CLEAR_NOTICES():void {
    this._notices = []
    this._skipToken = null
  }

  @Action
  public async fetchNotices(req: NoticesPostRequest):Promise<void> {
    const res = await noticesClient.postNotices(req)
    if (res) this.PUSH_NOTICES(res)
  }

  @Action
  public clearNotices():void {
    this.CLEAR_NOTICES()
  }

  @Action
  public async putRead(req:NoticeReadPutRequest):Promise<void> {
    await noticesClient.putNoticesRead(req)
  }
}

export const noticesModule = getModule(NoticesStore)
