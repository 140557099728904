



















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'
import { QuestionnairesRespondentDetailGetRequest, QuestionnairesRespondentDetailGetResponse, RespondentOpinionAndRequest, RespondentQuestionnaireChoiceAnswer, RespondentQuestionnaireSection } from '@/dtos/questionnaires/respondents/get-detail'
import { questionnairesModule } from '@/stores/questionnaires-store'
@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class RespondentDetailPage extends Vue {
  QUESTIONNAIRE_QUESTION_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_TYPES)

  @Prop({ default: '' })
  private readonly userId!: string

  @Prop({ default: '' })
  private readonly questionnaireId!: string

  async created(): Promise<void> {
    questionnairesModule.fetchQuestionnairesRespondentDetail(new QuestionnairesRespondentDetailGetRequest(this.questionnaireId, this.userId))
  }

  private get respondentDetail(): QuestionnairesRespondentDetailGetResponse {
    return questionnairesModule.questionnaireRespondentDetail(this.userId) ?? new QuestionnairesRespondentDetailGetResponse()
  }

  private get sections(): RespondentQuestionnaireSection[] {
    return this.respondentDetail.questionnaire.questionnaireSections
  }

  private get opinion(): RespondentOpinionAndRequest | undefined {
    return this.respondentDetail.questionnaire.opinionAndRequest
  }

  private answersText(answers: RespondentQuestionnaireChoiceAnswer[]): string {
    const answerTexts = answers.map(a => a.ownerAnswer)
    return answerTexts.join('、')
  }
}
