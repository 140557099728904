









































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { repairPlanExcelParser } from '@/libs/excel-parser/repair-plan-parser'
import { repairsModule } from '@/stores/repairs-store'
import { buildingsModule } from '@/stores/buildings-store'
import { errorsModule } from '@/stores/errors'

import { Tab } from '@/components/molecules/SmTabs.vue'
import { RepairsExpensesGetRequest } from '@/dtos/repairs/expenses/get'
import { RepairsItemsGetRequest } from '@/dtos/repairs/items/get'
import { RepairsUpdatesLogDetailGetRequest, RepairsUpdatesLogDetailGetResponse } from '@/dtos/repairs/updates/logs/get-detail'
import { RepairsUpdatesLogsDeleteRequest } from '@/dtos/repairs/updates/logs/delete'

const DOWNLOAD_FAILED_MESSAGES = ['ファイルのダウンロードに失敗しました。画面を読み込み直すか、しばらくしてから再度お試しください。']

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTabs: () => import('@/components/molecules/SmTabs.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    SmTableRepairPlanUpdateDetails: () => import('@/pages/repair-plan/SmTableRepairPlanUpdateDetails.vue'),
  }
})
export default class RepairPlanUpdateDetailPage extends Mixins(CurrentAdminManager) {
  @Prop()
  private readonly logId!: string

  async created(): Promise<void> {
    await repairsModule.fetchRepairsUpdatesLogDetail(new RepairsUpdatesLogDetailGetRequest(this.logId))
    await Promise.all([
      repairsModule.fetchRepairsExpenses(new RepairsExpensesGetRequest(this.repairsUpdatesLogDetail?.repairPlanId)),
      repairsModule.fetchRepairsItems(new RepairsItemsGetRequest(this.repairsUpdatesLogDetail?.repairPlanId)),
    ])
  }

  private get repairsUpdatesLogDetail(): RepairsUpdatesLogDetailGetResponse { return repairsModule.repairsUpdatesLogDetailGet(this.logId) ?? new RepairsUpdatesLogDetailGetResponse() }

  private get isDeletable(): boolean { return !this.repairsUpdatesLogDetail.isOriginalPlan && this.repairsUpdatesLogDetail.isAvailable }

  // fileNameにpreviousが無い場合は「-」を格納する。その上でSmTableRepairPlanUpdateDetailsに渡せるようfileNameを配列に入れる。
  private get filteredFileName(): { previous: string, current: string }[] {
    const fileName = this.repairsUpdatesLogDetail.fileName
    if (!fileName) { return [] }
    if (!fileName.previous) { fileName.previous = '-' }
    return [fileName]
  }

  tabs = [
    new Tab('収支計画', staticRoutes.repairPlanUpdateDetail.getChild('summary').name),
    new Tab('工事計画', staticRoutes.repairPlanUpdateDetail.getChild('construction').name),
  ]

  async outputExcelFile(): Promise<void> {
    errorsModule.clearGlobalErrors()
    await repairsModule.fetchRepairPlan(this.repairsUpdatesLogDetail.repairPlanId)

    const data = repairsModule.repairPlanGetResponse(this.repairsUpdatesLogDetail.repairPlanId)
    if (!data) {
      errorsModule.setGlobalErrors(DOWNLOAD_FAILED_MESSAGES)
      return
    }

    try {
      await repairPlanExcelParser.downloadAsExcel(data, buildingsModule.periodToYearMonth)
    } catch (err) {
      errorsModule.setGlobalErrors(DOWNLOAD_FAILED_MESSAGES)
      throw err
    }
  }

  isDeleteDialogVisible = false
  openDeleteDialog(): void {
    this.isDeleteDialogVisible = true
  }

  async deleteUpdatesLog(): Promise<void> {
    this.isDeleteDialogVisible = false
    await repairsModule.deleteRepairsUpdatesLogs(new RepairsUpdatesLogsDeleteRequest(this.logId))
    this.$router.go(-1)
  }
}
