









































































































import { PaymentPlanDetail } from '@/dtos/reserve-funds/plans/get-detail'
import { Vue, Component, Prop, Model } from 'vue-property-decorator'

@Component({
  components: {
    SmBadgeInline: () => import('@/components/atoms/SmBadgeInline.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class PaymentPlanDetailTable extends Vue {
  @Model('input', { required: true, default: () => [] })
  paymentPlanDetails!:PaymentPlanDetail[]

  @Prop()
  totalPeriodReserves?: number[]

  @Prop()
  estimatedCost?: number[]

  @Prop({ default: 1 })
  billingPeriod!: number

  private get _paymentPlanDetails(): PaymentPlanDetail[] { return this.paymentPlanDetails }
  private set _paymentPlanDetails(newValue: PaymentPlanDetail[]) {
    this.$emit('input', newValue)
  }

  private inputText(index:number, input: string): void {
    this.$set(this._paymentPlanDetails[index], 'unitPrice', input)
  }

  @Prop({ default: false })
  edit!:boolean

  @Prop()
  startPeriod?:number

  @Prop()
  startMonth?:number
}
