import { Material } from '../commons'
import { Type } from 'class-transformer'
import type { NotificationElementType, PinningSettingType, PostTimingType, TargetBuildingType, TargetOwnerType, TransitionTo } from '@/constants/schema-constants'

export class OwnerNotificationsPostRequestBuilding {
  buildingId: string
  constructor(buildingId: string) {
    this.buildingId = buildingId
  }
}

export class OwnerNotificationsPostRequestOwner {
  userId: string
  constructor(userId: string) {
    this.userId = userId
  }
}

export class OwnerNotificationsPostRequestBulletPoint {
  sortOrderNum: number
  bulletPointBody: string
  hierarchyLevel: number

  constructor(sortOrderNum: number, bulletPointBody: string, hierarchyLevel: number) {
    this.sortOrderNum = sortOrderNum
    this.bulletPointBody = bulletPointBody
    this.hierarchyLevel = hierarchyLevel
  }
}

export class OwnerNotificationsPostRequestFFNElement {
  notificationElementType!: NotificationElementType
  sortOrderNum!: number
  elementBody?: string
  inputField?: string
  transitionType?: TransitionTo
  transitionParams?: Record<string, string>
  externalSiteUrl?: string
  emailAddress?: string
  phoneNumber?: string
  @Type(() => Material)material?: Material
  @Type(() => OwnerNotificationsPostRequestBulletPoint)bulletPoints?: OwnerNotificationsPostRequestBulletPoint[]
}

export class OwnerNotificationsPostRequest {
  title: string
  targetBuildingType: TargetBuildingType
  targetOwnerType: TargetOwnerType
  postTimingType: PostTimingType
  pinningSettingType: PinningSettingType
  freeFormatNotificationElements: OwnerNotificationsPostRequestFFNElement[] = []
  ownerNotificationId?: string
  material?: Material
  targetBuildings?: OwnerNotificationsPostRequestBuilding[]
  ideaId?: string
  resolutionId?: string
  targetOwners?: OwnerNotificationsPostRequestOwner[]
  scheduledPostDateTime?: string
  pinningDeadlineDate?: string

  constructor(title: string, targetBuildingType: TargetBuildingType, targetOwnerType: TargetOwnerType, postTimingType: PostTimingType,
    pinningSettingType: PinningSettingType, freeFormatNotificationElements: OwnerNotificationsPostRequestFFNElement[], ownerNotificationId?: string,
    material?: Material, targetBuildings?: OwnerNotificationsPostRequestBuilding[], ideaId?: string,
    resolutionId?: string, targetOwners?: OwnerNotificationsPostRequestOwner[], scheduledPostDateTime?: string, pinningDeadlineDate?: string) {
    this.title = title
    this.targetBuildingType = targetBuildingType
    this.targetOwnerType = targetOwnerType
    this.postTimingType = postTimingType
    this.pinningSettingType = pinningSettingType
    this.freeFormatNotificationElements = freeFormatNotificationElements
    this.ownerNotificationId = ownerNotificationId
    this.material = material
    this.targetBuildings = targetBuildings
    this.ideaId = ideaId
    this.resolutionId = resolutionId
    this.targetOwners = targetOwners
    this.scheduledPostDateTime = scheduledPostDateTime
    this.pinningDeadlineDate = pinningDeadlineDate
  }
}
