





























































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { SUBJECT_TYPES } from '@/constants/schema-constants'
import type { SubjectType } from '@/constants/schema-constants'
import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { BaseIdea } from '@/dtos/commons'
import { AdminIdeaDecisionGetResponse } from '@/dtos/ideas/admin-idea/decision/get'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmListBudget: () => import('@/components/molecules/list/SmListBudget.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class SmCardSubjectInput extends Vue {
  planExpansion = false
  subjectTypes = [new RadioOption('普通決議', SUBJECT_TYPES.ORDINARY), new RadioOption('特別決議', SUBJECT_TYPES.SPECIAL)]

  @Prop({ default: false })
  private readonly head!: boolean

  @Prop({ default: false })
  private readonly foot!: boolean

  @Prop({ default: true })
  private readonly changeable!: boolean

  @Prop({ default: false })
  private readonly deletable!: boolean

  @Prop({ default: true })
  private readonly deleteBtnVisible!: boolean

  @Prop()
  private readonly fieldIdPrefix?: string

  @Prop()
  private readonly decision?: AdminIdeaDecisionGetResponse

  @Prop()
  private subjectTitle?: string

  @Prop()
  private subjectType?: SubjectType

  @Prop()
  private baseIdea?: BaseIdea

  private get _subjectTitle(): string | undefined { return this.subjectTitle }
  private set _subjectTitle(newValue: string | undefined) {
    this.$emit('update:subjectTitle', newValue)
  }

  private get _subjectType(): SubjectType | undefined { return this.subjectType }
  private set _subjectType(newValue: SubjectType | undefined) {
    this.$emit('update:subjectType', newValue)
  }

  private get _baseIdea(): BaseIdea | undefined { return this.baseIdea }
  private set _baseIdea(newValue: BaseIdea | undefined) {
    this.$emit('update:baseIdea', newValue)
  }
}
