



















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { CONSULTATION_STATES, MATERIAL_TYPES } from '@/constants/schema-constants'
import type { ConsultationState } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import { Material, User } from '@/dtos/commons'
import type { Reaction } from '@/dtos/static-assets/get'
import { staticAssetsModule } from '@/stores/static-assets-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBadgeRead: () => import('@/components/atoms/SmBadgeRead.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmListReactions: () => import('@/components/molecules/list/SmListReactions.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
  }
})
export default class SmCardConsultationDetail extends Vue {
  CONSULTATION_STATES = Object.freeze(CONSULTATION_STATES)
  CHIP_COLORS = Object.freeze(CHIP_COLORS)
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop({ required: true, default: false })
  private isOwnerMessage!: boolean

  @Prop({ required: true, default: false })
  private isFocused!: boolean

  @Prop({ default: false })
  private isUnread!: boolean

  @Prop({ required: true, default: {} })
  private user!: User

  @Prop({ required: true, default: '' })
  private message!: string

  @Prop()
  private material?: Material

  @Prop({ required: true, default: '' })
  private postedAt!: string

  @Prop({ required: true, default: 0 /* どれでもない値 */ })
  private consultationState!: ConsultationState

  @Prop({ default: () => [] })
  private reactions!: string[]

  private get reactionsMaster(): Reaction[] { return staticAssetsModule.reactions }

  private get isAvailableReaction(): boolean {
    return this.isOwnerMessage && (this.consultationState !== CONSULTATION_STATES.OWNER_DELETED)
  }

  // 管理者側のメッセージで、リアクションのデータを持たない場合は、リアクションの表示部分を非表示にする
  private get isAdminNoReactions(): boolean {
    return !this.isOwnerMessage && this.reactions.length === 0
  }

  onClickIcon(assetId: string): void {
    this.$emit('click-icon', assetId)
  }
}
