







































































































































































































import { Component, Vue, Model } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { Questionnaire, QuestionnairesSearchPostRequest } from '@/dtos/questionnaires/search/post'
import { QuestionnaireTemplate } from '@/dtos/questionnaires/templates/get'
import { generateUuid } from '@/libs/uuid-generator'

import { questionnairesModule } from '@/stores/questionnaires-store'
import { questionnaireTemplatesModule } from '@/stores/questionnaire-templates-store'
import { QUESTIONNAIRE_STATES } from '@/constants/schema-constants'
import { TemplateQuestionItem } from '@/dtos/questionnaires/templates/questions/get'

export class ReportOutputParams {
  questionnaireIds: string[] = []
  templateQuestionIds: string[] = []
}

@Component({
  components: {
    SmBadgeCategory: () => import('@/components/atoms/SmBadgeCategory.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmTableData: () => import('@/components/molecules/SmTableData.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),

    QuestionnaireTemplateSelectModal: () => import('@/components/organisms/modal/QuestionnaireTemplateSelectModal.vue'),
    QuestionSelectModal: () => import('@/components/organisms/modal/QuestionSelectModal.vue'),
  }
})
export default class QuestionnaireReportOutputModal extends Vue {
  @Model('change')
  private readonly isVisible!: boolean

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  headers : Header[] = [
    new Header({ text: 'テンプレートタイトル', value: 'templateName', width: '190px' }),
    new Header({ text: 'アンケートタイトル', value: 'questionnaireTitle' }),
    new Header({ text: 'マンション名', value: 'buildingName', width: '172px' }),
    new Header({ text: '回答締切日', value: 'deadline', width: '180px' }),
  ]

  private get questionnaireItems(): Questionnaire[] {
    return questionnairesModule.questionnaires
  }

  private get questionnaireTemplates(): QuestionnaireTemplate[] { return questionnaireTemplatesModule.templatesGet.templates }
  private get templateQuestions(): TemplateQuestionItem[] { return questionnaireTemplatesModule.questionGet.templateQuestions }

  // 検索系
  inputParams = {
    templateId: '',
    answerDeadline: {
      fromDate: '',
      toDate: ''
    },
  }

  outputParams = new ReportOutputParams()

  isWaitingSwitch = false

  private get selectTemplateNameDisplay(): string {
    const templateId = this.inputParams.templateId
    if (templateId === '') return '未選択'
    // IDが一致する要素のテンプレートタイトルを表示する
    return this.questionnaireTemplates.find(e => e.templateId === templateId)?.templateName ?? ''
  }

  private get selectedQuestionNameDisplay(): string {
    if (!this.templateQuestionIds || this.templateQuestionIds.length < 1) return 'すべての設問'
    else {
      return this.templateQuestionIds.map(templateQuestionId => {
        return this.templateQuestions.find(q => q.templateQuestionId === templateQuestionId)?.questionSentence
      }).join(' | ')
    }
  }

  isTemplateSelectModalOpen = false
  questionnaireTemplateSelectModalKey = generateUuid()
  questionnaireTemplateInputText = ''
  questionnaireTemplateKeyword = ''

  isTemplateQuestionSelectModalVisible = false
  templateQuestionSelectModalKey = generateUuid()
  templateQuestionIds: string[] = []

  openTemplateSelectModal():void {
    this.questionnaireTemplateSelectModalKey = generateUuid()
    this.isTemplateSelectModalOpen = true
  }

  openTemplateQuestionSelectModal(): void {
    this.isTemplateQuestionSelectModalVisible = true
  }

  selectQuestions(reqQuestionIds:string[]): void {
    this.templateQuestionIds = reqQuestionIds
  }

  async selectTemplate(questionnaireTemplateIds: string[]): Promise<void> {
    this.templateQuestionIds = []
    this.inputParams.templateId = questionnaireTemplateIds[0]
    await this.search()
    await questionnaireTemplatesModule.fetchTemplateQuestions(questionnaireTemplateIds[0])
  }

  async onSearchDateFilter():Promise<void> {
    await this.search()
  }

  async search():Promise<void> {
    const questionnairesSearchPostRequest = new QuestionnairesSearchPostRequest(1000)
    if (this.inputParams.templateId.length > 0) questionnairesSearchPostRequest.templates = [this.inputParams.templateId]
    if (this.inputParams.answerDeadline.fromDate.length > 0) questionnairesSearchPostRequest.fromDate = this.inputParams.answerDeadline.fromDate
    if (this.inputParams.answerDeadline.toDate.length > 0) questionnairesSearchPostRequest.toDate = this.inputParams.answerDeadline.toDate
    questionnairesSearchPostRequest.questionnaireStates = [QUESTIONNAIRE_STATES.RELEASED]
    await questionnairesModule.initAndFetchQuestionnaires(questionnairesSearchPostRequest)
  }

  async onClickOutputBtn():Promise<void> {
    this.outputParams.templateQuestionIds = this.templateQuestionIds
    this.$emit('output', this.outputParams.questionnaireIds, this.outputParams.templateQuestionIds, this.inputParams.templateId)
    this.isModalVisible = false
  }

  onClickClose():void {
    this.$emit('close')
    this.isModalVisible = false
  }

  private get isSelected(): boolean {
    return this.outputParams.questionnaireIds.length > 0
  }
}
