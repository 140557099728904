

























import { Component, Model, Mixins, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { BUILDING_GROUP_TYPE } from '@/constants/schema-constants'
import type { BuildingGroupType } from '@/constants/schema-constants'
import { getGroupLabel } from '@/libs/type-handler'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class BuildingGroupSelectModal extends Mixins(CurrentAdminManager) {
  @Model('change', { required: true, default: false })
  private readonly isVisible!: boolean

  @Prop({ default: BUILDING_GROUP_TYPE.TOKYO })
  buildingGroupType!:BuildingGroupType

  groupOptions =
  [
    new RadioOption(getGroupLabel(BUILDING_GROUP_TYPE.TOKYO), BUILDING_GROUP_TYPE.TOKYO),
    new RadioOption(getGroupLabel(BUILDING_GROUP_TYPE.KANSAI), BUILDING_GROUP_TYPE.KANSAI),
    new RadioOption(getGroupLabel(BUILDING_GROUP_TYPE.CMQ), BUILDING_GROUP_TYPE.CMQ),
    new RadioOption(getGroupLabel(BUILDING_GROUP_TYPE.CMW), BUILDING_GROUP_TYPE.CMW),
    new RadioOption(getGroupLabel(BUILDING_GROUP_TYPE.CMO), BUILDING_GROUP_TYPE.CMO),
  ]

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  onClickBtn():void {
    this.$emit('select', this.selectedBuildingGroupType)
    this.isModalVisible = false
  }

  private selectedBuildingGroupType: BuildingGroupType = this.buildingGroupType
}
