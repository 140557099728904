














import { Vue, Component } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { ReservesGetResponse } from '@/dtos/reserve-funds/get'
import { Tab } from '@/components/molecules/SmTabs.vue'
import { reserveFundsModule } from '@/stores/reserve-funds-store'
import { RESERVE_PLAN_STATES } from '@/constants/schema-constants'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmAppBar: () => import('@/components/organisms/SmAppBar.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class ReserveFundPage extends Vue {
  async created(): Promise<void> {
    const state = this.res.reservePlanState
    if (!state) {
      await reserveFundsModule.fetchReserveFund()
    }
  }

  private get tabs(): Tab[] {
    const isCreatedReservePlan = (
      this.res.reservePlanState === RESERVE_PLAN_STATES.CREATED ||
      this.res.reservePlanState === RESERVE_PLAN_STATES.UPDATABLE
    )
    const tabs: Tab[] = [
      new Tab('積立計画', staticRoutes.reserveFunds.getChild('detail').name),
      new Tab('支払いプラン', staticRoutes.reserveFunds.getChild('plans').name, !isCreatedReservePlan),
      new Tab('住戸別支払い計画', staticRoutes.reserveFunds.getChild('payments').name, !isCreatedReservePlan),
    ]
    return tabs
  }

  private get res():ReservesGetResponse {
    return reserveFundsModule.reserveFund ?? new ReservesGetResponse()
  }

  currentTab = ''
}
