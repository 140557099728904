import { ItemType, MaterialType, ResolutionType, SubjectType } from '@/constants/schema-constants'
import { NOT_SELECTABLE_PERIOD_VALUE } from '@/constants/ux-constants'
import { Type } from 'class-transformer'

/** APIのI/Fで共通に登場する入出力の構造を定義する */

export class BaseIdea {
  ideaId: string

  constructor(baseIdeaId: string) { this.ideaId = baseIdeaId }
}

export class Estimate {
  expense?: number
  expenseLabel!: string
  budget?: number
  budgetLabel!: string
  spent?: number
  spentLabel: string | null = null
  balanceLabel!: string
}

export class FollowingResolution {
  resolutionId!: string
  resolutionType!: ResolutionType
  title!: string
}

export class Material {
  materialId!: string // POST, PUT時には、materialIdのみ必須。
  materialUrl?: string
  materialType?: MaterialType
  originalFileName?: string
  caption?: string
}

/** 素材のアップロードを画面で受け付ける際に、インプット用の部品にバインドするオブジェクト */
export class MaterialFormInputDto extends Material {
  file?: File

  // ファイル名を変更しようとした場合に、変更前の文字列を記録しておくハコ
  originalFileNameInitialCopy?: string
  get fileNameChanged(): boolean { return this.originalFileNameInitialCopy != null && this.originalFileName !== this.originalFileNameInitialCopy }
}

export class Section {
  sectionTitle?: string
  sectionBody?: string
  material?: Material | null = null
}

export class Session {
  itemType?: ItemType
  itemTitle?: string
  itemBody?: string
  material?: Material
  sections: Section[] = []
}

export class Subject {
  subjectId?: string
  subjectType: SubjectType | null = null // 議案種別の初期表示は非選択状態
  subjectTitle = ''
  @Type(() => BaseIdea) baseIdea?: BaseIdea
}

export class User {
  userId!: string
  iconImagePath?: string
  userName!: string
  roomNumber?: string
}

export class Duration {
  periodFrom:number = NOT_SELECTABLE_PERIOD_VALUE
  periodTo:number = NOT_SELECTABLE_PERIOD_VALUE

  constructor(from = NOT_SELECTABLE_PERIOD_VALUE, to = NOT_SELECTABLE_PERIOD_VALUE) {
    this.periodFrom = from
    this.periodTo = to
  }
}

export class BaseOpinion {
  opinionId!: string
  @Type(() => User)ownerUser = new User()
}

export class Plan {
  planId!: string
  itemType!: ItemType
  itemTitle!: string
  @Type(() => BaseOpinion)baseOpinions: BaseOpinion[] = []
  @Type(() => Estimate)estimates: Estimate[] = []
  @Type(() => Section)sections: Section[] = []
}
