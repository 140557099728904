import { Type } from 'class-transformer'

export class TemplateQuestionItem {
  templateQuestionId!: string
  sectionTitle?: string
  questionSentence!: string
}

export class TemplateQuestionsGetResponse {
  @Type(() => TemplateQuestionItem) templateQuestions:TemplateQuestionItem[] = []
}
