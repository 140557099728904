import { APIClientBase } from './api-client-base'
import { RepairsExpensesGetRequest, RepairsExpensesGetResponse } from '@/dtos/repairs/expenses/get'
import { RepairsItemsGetRequest, RepairsItemsGetResponse } from '@/dtos/repairs/items/get'
import { RepairsUpdatesGetRequest, RepairsUpdatesGetResponse } from '@/dtos/repairs/updates/get-detail'
import { RepairsUpdatesCommentsGetRequest, RepairsUpdatesCommentsGetResponse } from '@/dtos/repairs/updates/comments/get'
import { RepairsUpdatesCommentDetailGetRequest, RepairsUpdatesCommentDetailGetResponse } from '@/dtos/repairs/updates/comments/get-detail'
import { RepairsUpdatesCommentsPostRequest } from '@/dtos/repairs/updates/comments/post'
import { RepairsUpdatesCommentsPutRequest } from '@/dtos/repairs/updates/comments/put'
import { RepairsUpdatesCommentsDeleteRequest } from '@/dtos/repairs/updates/comments/delete'
import { RepairsUpdatesLogsGetRequest, RepairsUpdatesLogsGetResponse } from '@/dtos/repairs/updates/logs/get'
import { RepairsUpdatesLogDetailGetRequest, RepairsUpdatesLogDetailGetResponse } from '@/dtos/repairs/updates/logs/get-detail'
import { RepairsUpdatesLogsDeleteRequest } from '@/dtos/repairs/updates/logs/delete'
import { RepairPlanGetResponse } from '@/dtos/repairs/plans/get'
import { RepairPlanPostRequest } from '@/dtos/repairs/plans/post'
import { RepairPlanPutRequest } from '@/dtos/repairs/plans/put'

class RepairsClient extends APIClientBase {
  constructor() {
    super('/repairs')
  }

  // 長計（収支計画）取得
  getRepairsExpenses(req: RepairsExpensesGetRequest): Promise<RepairsExpensesGetResponse> {
    return this.get('/expenses', req, RepairsExpensesGetResponse)
  }

  // 長計（工事計画）取得
  getRepairsItems(req: RepairsItemsGetRequest): Promise<RepairsItemsGetResponse> {
    return this.get('/items', req, RepairsItemsGetResponse)
  }

  // 最新の変更コメント詳細取得
  getRepairsUpdates(req: RepairsUpdatesGetRequest): Promise<RepairsUpdatesGetResponse> {
    return this.get('/updates', req, RepairsUpdatesGetResponse)
  }

  // -------------------- 計画のCRUD --------------------
  getRepairPlan(repairPlanId?: string): Promise<RepairPlanGetResponse> {
    return this.get('/plans', { repairPlanId }, RepairPlanGetResponse)
  }

  async postRepairPlan(req: RepairPlanPostRequest): Promise<void> {
    await this.post('/plans', req)
  }

  async putRepairPlan(req: RepairPlanPutRequest): Promise<void> {
    await this.put('/plans', req)
  }

  // -------------------- 変更コメント --------------------
  getRepairsUpdatesComments(req: RepairsUpdatesCommentsGetRequest): Promise<RepairsUpdatesCommentsGetResponse> {
    return this.get('/updates/comments', req, RepairsUpdatesCommentsGetResponse, false)
  }

  getRepairsUpdatesCommentDetail(req: RepairsUpdatesCommentDetailGetRequest): Promise<RepairsUpdatesCommentDetailGetResponse> {
    return this.get(`/updates/comments/${req.commentId}`, null, RepairsUpdatesCommentDetailGetResponse)
  }

  postRepairsUpdatesComments(req: RepairsUpdatesCommentsPostRequest): Promise<void> {
    return this.post('/updates/comments', req)
  }

  putRepairsUpdatesComments(req: RepairsUpdatesCommentsPutRequest): Promise<void> {
    return this.put(`/updates/comments/${req.commentId}`, req)
  }

  deleteRepairsUpdatesComments(req: RepairsUpdatesCommentsDeleteRequest): Promise<void> {
    return this.delete(`/updates/comments/${req.commentId}`)
  }

  // -------------------- 更新履歴 --------------------
  getRepairsUpdatesLogs(req: RepairsUpdatesLogsGetRequest): Promise<RepairsUpdatesLogsGetResponse> {
    return this.get('updates/logs', req, RepairsUpdatesLogsGetResponse)
  }

  getRepairsUpdatesLogDetail(req: RepairsUpdatesLogDetailGetRequest): Promise<RepairsUpdatesLogDetailGetResponse> {
    return this.get(`updates/logs/${req.logId}`, null, RepairsUpdatesLogDetailGetResponse)
  }

  deleteRepairsUpdatesLogs(req: RepairsUpdatesLogsDeleteRequest): Promise<void> {
    return this.delete(`updates/logs/${req.logId}`)
  }
}

export const repairsClient = new RepairsClient()
