import { Type } from 'class-transformer'

export class CasysDetectionsResultGetRequest {
  skipToken?: string
  take: number

  constructor(take: number) {
    this.take = take
  }
}

export class Building {
  buildingId?: string
  buildingName!: string
  condominiumCd!: string
  existsAtSmoothe!: boolean
}

export class Difference {
  unitId!: string
  owningBuildingUnitId!: string
  casysDifferenceOwnerId!: string
  casysRoomNumber?: string
  casysOwnerCode?: string
  casysFullName?: string
  roomNumber!: string
  fullName!: string
  isUpdated!: boolean
}

export class Buildings {
  @Type(() => Building) building!: Building
  @Type(() => Difference) differences: Difference[] = []
}

export class InProgressData {
  casysDifferenceDetectionBatchStatusId!: string
  adminName!: string
  fileCount!: number
  uploadedAt!: string
}

export class DetectionResult {
  casysDifferenceDetectionBatchStatusId!: string
  adminName!: string
  uploadedAt!: string
  @Type(() => Buildings) buildings: Buildings[] = []
}

export class CasysDetectionsResultGetResponse {
  @Type(() => InProgressData) inProgressData: InProgressData[] = []
  @Type(() => DetectionResult) results: DetectionResult[] = []
  skipToken!: string
  casysDifferenceErrors?: string[]
}
