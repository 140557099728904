
































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { TICKET_COMMENT_STATES, MATERIAL_TYPES } from '@/constants/schema-constants'
import type { TicketCommentState } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import { Material, User } from '@/dtos/commons'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
  }
})

export default class SmCartTicketCommentDetail extends Vue {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)
  TICKET_COMMENT_STATES = Object.freeze(TICKET_COMMENT_STATES)
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop({ required: true, default: false })
  private readonly isMyMessage!: boolean

  @Prop({ required: true, default: {} })
  private readonly user!: User

  @Prop({ required: true, default: '' })
  private readonly comment!: string

  @Prop()
  private readonly material?: Material

  @Prop({ required: true, default: '' })
  private readonly postedAt!: string

  @Prop({ required: true, default: TICKET_COMMENT_STATES.RAW })
  private readonly commentState!: TicketCommentState
}
