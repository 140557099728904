import { Type } from 'class-transformer'

export class QuestionnaireAnswerLibraryPostRequest {
  buildings?: string[]

  constructor(buildings?:string[]) {
    this.buildings = buildings
  }
}

export class QuestionnaireAnswerLibraryItem {
  public questionnaireReportResultSectionAnswerId!: string
  public buildingId!: string
  public buildingName!: string
  public freeAnswers!: string[]
  public answerBody!: string
  public version!: number
}

export class QuestionnaireAnswerLibraryPostResponse {
  @Type(() => QuestionnaireAnswerLibraryItem) questionnaireAnswerLibraries: QuestionnaireAnswerLibraryItem[] = []
}
