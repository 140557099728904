import { APIClientBase } from './api-client-base'

import { IDEA_TYPES } from '@/constants/schema-constants'

import { AdminIdeaAgreementsGetRequest, AdminIdeaAgreementsGetResponse } from '@/dtos/ideas/admin-idea/agreements/get'
import { AdminIdeaAgreementsPutRequest } from '@/dtos/ideas/admin-idea/agreements/put'
import { AdminIdeaDecisionGetRequest, AdminIdeaDecisionGetResponse } from '@/dtos/ideas/admin-idea/decision/get'
import { AdminIdeasDeleteRequest } from '@/dtos/ideas/admin-idea/delete'
import { AdminIdeasDraftDeleteRequest } from '@/dtos/ideas/admin-idea/draft/delete'
import { AdminIdeasDraftPostRequest } from '@/dtos/ideas/admin-idea/draft/post'
import { AdminIdeaDetailGetRequest, AdminIdeaDetailGetResponse } from '@/dtos/ideas/admin-idea/get-detail'
import { AdminIdeasOpinionsDeleteRequest } from '@/dtos/ideas/admin-idea/opinions/delete'
import { AdminIdeaOpinionsGetRequest, AdminIdeaOpinionsGetResponse } from '@/dtos/ideas/admin-idea/opinions/get'
import { AdminIdeaOpinionsPostRequest } from '@/dtos/ideas/admin-idea/opinions/post'
import { AdminIdeasOpinionsCommentsDeleteRequest } from '@/dtos/ideas/admin-idea/opinions/comments/delete'
import { AdminIdeasOpinionsCommentsPostRequest, AdminIdeasOpinionsCommentsPostResponse } from '@/dtos/ideas/admin-idea/opinions/comments/post'
import { AdminIdeasOpinionsCommentsPutRequest, AdminIdeasOpinionsCommentsPutResponse } from '@/dtos/ideas/admin-idea/opinions/comments/put'
import { AdminIdeasPostRequest } from '@/dtos/ideas/admin-idea/post'
import { PreparingStatusDeleteRequest } from '@/dtos/ideas/admin-idea/preparing-status/delete'
import { PreparingStatusPostRequest, PreparingStatusPostResponse } from '@/dtos/ideas/admin-idea/preparing-status/post'
import { PreparingStatusPutRequest, PreparingStatusPutResponse } from '@/dtos/ideas/admin-idea/preparing-status/put'
import { AdminIdeasPutRequest } from '@/dtos/ideas/admin-idea/put'
import { AdminIdeaResultsGetRequest, AdminIdeaResultsGetResponse } from '@/dtos/ideas/admin-idea/results/get'
import { AdminIdeaResultsPutRequest } from '@/dtos/ideas/admin-idea/results/put'
import { AdminIdeaSessionGetRequest, AdminIdeaSessionGetResponse } from '@/dtos/ideas/admin-idea/session/get'
import { AdminIdeaSessionPutRequest } from '@/dtos/ideas/admin-idea/session/put'
import { AdminIdeaUpdatesDeleteRequest } from '@/dtos/ideas/admin-idea/updates/delete'
import { AdminIdeaUpdatesGetRequest, AdminIdeaUpdatesGetResponse } from '@/dtos/ideas/admin-idea/updates/get'
import { AdminIdeaUpdatesPostRequest } from '@/dtos/ideas/admin-idea/updates/post'
import { AdminIdeaUpdatesPutRequest, AdminIdeaUpdatesPutResponse } from '@/dtos/ideas/admin-idea/updates/put'

class AdminIdeasClient extends APIClientBase {
  constructor() {
    super(`/ideas/${IDEA_TYPES.ADMIN_IDEA}`)
  }

  getIdeaDetail(req: AdminIdeaDetailGetRequest): Promise<AdminIdeaDetailGetResponse> {
    return this.get(`${req.ideaId}`, '', AdminIdeaDetailGetResponse)
  }

  getAdminIdeaSessions(req: AdminIdeaSessionGetRequest): Promise<AdminIdeaSessionGetResponse> {
    return this.get(`${req.ideaId}/session`, '', AdminIdeaSessionGetResponse)
  }

  getAdminIdeaOpinions(req: AdminIdeaOpinionsGetRequest): Promise<AdminIdeaOpinionsGetResponse> {
    const { ideaId, ...rest } = req
    return this.get(`${ideaId}/opinions`, rest, AdminIdeaOpinionsGetResponse, false)
  }

  getAdminIdeaUpdates(req: AdminIdeaUpdatesGetRequest): Promise<AdminIdeaUpdatesGetResponse> {
    const { ideaId, ...rest } = req
    return this.get(`${ideaId}/updates`, rest, AdminIdeaUpdatesGetResponse, false)
  }

  getAgreements(req: AdminIdeaAgreementsGetRequest) : Promise<AdminIdeaAgreementsGetResponse> {
    return this.get(`${req.ideaId}/agreements`, '', AdminIdeaAgreementsGetResponse, false)
  }

  putAgreements(req: AdminIdeaAgreementsPutRequest) : Promise<void> {
    return this.put(`${req.ideaId}/agreements`, req)
  }

  getAdminIdeaResults(req: AdminIdeaResultsGetRequest): Promise<AdminIdeaResultsGetResponse> {
    return this.get(`${req.ideaId}/results`, '', AdminIdeaResultsGetResponse)
  }

  deleteAdminIdea(req: AdminIdeasDeleteRequest): Promise<void> {
    return this.delete(`${req.ideaId}`)
  }

  deleteDraftAdminIdea(req: AdminIdeasDraftDeleteRequest) : Promise<void> {
    return this.delete(`/draft/${req.ideaId}`)
  }

  postOpinion(req: AdminIdeaOpinionsPostRequest): Promise<void> {
    return this.post(`${req.ideaId}/opinions`, req)
  }

  deleteAdminIdeaOpinion(req: AdminIdeasOpinionsDeleteRequest): Promise<void> {
    return this.delete(`${req.ideaId}/opinions/${req.opinionId}`)
  }

  postAdminIdeaOpinionComment(req: AdminIdeasOpinionsCommentsPostRequest): Promise<void | AdminIdeasOpinionsCommentsPostResponse> {
    const { ideaId, opinionId, ...body } = req
    return this.post(`${ideaId}/opinions/${opinionId}/comments`, body, AdminIdeasOpinionsCommentsPostResponse)
  }

  putAdminIdeaOpinionComment(req: AdminIdeasOpinionsCommentsPutRequest): Promise<void | AdminIdeasOpinionsCommentsPutResponse> {
    const { ideaId, opinionId, opinionCommentId, ...body } = req
    return this.put(`${ideaId}/opinions/${opinionId}/comments/${opinionCommentId}`, body, AdminIdeasOpinionsCommentsPutResponse)
  }

  deleteAdminIdeaOpinionComment(req: AdminIdeasOpinionsCommentsDeleteRequest):Promise<void> {
    return this.delete(`${req.ideaId}/opinions/${req.opinionId}/comments/${req.opinionCommentId}`, undefined)
  }

  deleteAdminIdeaUpdates(req: AdminIdeaUpdatesDeleteRequest): Promise<void> {
    return this.delete(`${req.ideaId}/updates/${req.updateId}`)
  }

  postAdminIdea(req: AdminIdeasPostRequest): Promise<void> {
    return this.post('', req)
  }

  getIdeaDecision(req: AdminIdeaDecisionGetRequest): Promise<AdminIdeaDecisionGetResponse> {
    return this.get(`${req.ideaId}/decision`, '', AdminIdeaDecisionGetResponse)
  }

  postAdminIdeaDraft(req: AdminIdeasDraftPostRequest): Promise<void> {
    return this.post('/draft', req)
  }

  putAdminIdeaSessions(req: AdminIdeaSessionPutRequest): Promise<void> {
    return this.put(`${req.ideaId}/session`, req)
  }

  postAdminIdeaUpdates(req: AdminIdeaUpdatesPostRequest): Promise<void> {
    return this.post(`${req.ideaId}/updates`, req)
  }

  putAdminIdea(req: AdminIdeasPutRequest) : Promise<void> {
    return this.put(`${req.ideaId}`, req)
  }

  putAdminIdeaUpdates(req: AdminIdeaUpdatesPutRequest) : Promise< void | AdminIdeaUpdatesPutResponse> {
    return this.put(`${req.ideaId}/updates/${req.updateId}`, req, AdminIdeaUpdatesPutResponse)
  }

  putAdminIdeaResults(req: AdminIdeaResultsPutRequest): Promise<void> {
    return this.put(`${req.ideaId}/results`, req)
  }

  postPreparingStatus(req: PreparingStatusPostRequest): Promise<void | PreparingStatusPostResponse> {
    return this.post(`${req.ideaId}/preparing-status`, req, PreparingStatusPostResponse)
  }

  putPreparingStatus(req: PreparingStatusPutRequest): Promise<void | PreparingStatusPutResponse> {
    return this.put(`${req.ideaId}/preparing-status/${req.resolutionPreparingStatusId}`, req, PreparingStatusPutResponse)
  }

  deletePreparingStatus(req: PreparingStatusDeleteRequest): Promise<void> {
    return this.delete(`${req.ideaId}/preparing-status/${req.resolutionPreparingStatusId}`)
  }
}

export const adminIdeasClient = new AdminIdeasClient()
