import { IdeaState } from '@/constants/schema-constants'
import { User, FollowingResolution, Section } from '@/dtos/commons'
import { Details, Decision } from '@/dtos/ideas/admin-idea/commons'
import { Type } from 'class-transformer'

export class AdminIdeaResultsGetRequest {
  ideaId!: string
}

export class Result {
  itemTitle!: string
  itemBody!: string
  @Type(() => Decision)decision: Decision = new Decision()
  @Type(() => Section)sections?: Section[]
}

export class Summary {
  agreedOwnerRate!: number
  agreedOwnerCount!: number
  ownerCount!: number
}

export class ResultAgreement {
  planId!: string
  planTitle!: string
  agreementCount!: number
  @Type(() => User)agreedOwner: User[] = []
  // アコーディオン表示用の項目
  isExpanded = false
}

export class ResolutionPreparingStatus {
  resolutionPreparingStatusId!: string
  @Type(() => User)admin!: User
  resolutionPreparingStatusBody!: string
  version!: number
  postedAt!: string
}

export class AdminIdeaResultsGetResponse {
  ideaId!: string
  ideaState!: IdeaState
  postedAt!: string
  isNotAcceptable!: boolean
  title!: string
  @Type(() => Details)details: Details = new Details()
  @Type(() => User)user: User = new User()
  @Type(() => Result)result?: Result
  @Type(() => FollowingResolution)followingResolution?: FollowingResolution
  @Type(() => Summary)summary: Summary = new Summary()
  @Type(() => ResultAgreement)agreements: ResultAgreement[] = []
  @Type(() => ResolutionPreparingStatus)resolutionPreparingStatus?: ResolutionPreparingStatus
  version!: number
}
