


























































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'

/** 照合結果 */
export class CollationResultTableItem {
  unitId?: string
  casysRoomNumber?: string
  casysOwnerCode?: string
  casysFullName?: string
  roomNumber?: string
  fullName?: string
  isUpdated?: boolean
  owningBuildingUnitId?: string
  casysDifferenceOwnerId?: string
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
  }
})

export default class SmTableCollationResult extends Vue {
  // ヘッダー自体を画面に表示はしないが、テーブル内でのitemの表示場所をvalueで判断するために使用
  headers: Header[] = [
    new Header({ text: '部屋', value: 'casysRoomNumber' }),
    new Header({ text: '顧客コード', value: 'casysOwnerCode' }),
    new Header({ text: '顧客名（漢字）', value: 'casysFullName' }),
    new Header({ text: '住戸番号', value: 'roomNumber' }),
    new Header({ text: 'CASYS登録氏名', value: 'fullName' }),
    new Header({ text: '修正対応', value: 'isUpdated' })
  ]

  @Prop({ default: () => [], required: true })
  readonly items!: CollationResultTableItem[]

  @Prop({ default: true, required: true })
  readonly isVisibleFixBtn!: boolean

  // CASYS登録判定
  isRegistered(item: CollationResultTableItem): boolean {
    return item.casysRoomNumber !== undefined &&
    item.casysOwnerCode !== undefined &&
    item.casysFullName !== undefined
  }

  // 照合差分判定
  isDiffName(item: CollationResultTableItem): boolean {
    return this.isRegistered(item) && item.casysFullName !== item.fullName
  }
}
