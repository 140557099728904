

















































































































































































































































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { MATERIAL_TYPES, QUESTIONNAIRE_QUESTION_TYPES, QUESTIONNAIRE_QUESTION_ELEMENT_TYPES, SECTION_TYPES } from '@/constants/schema-constants'
import { QuestionnaireDetailGetRequest, QuestionnaireDetailGetResponse, QuestionnaireQuestion, QuestionnaireQuestionElement, QuestionnaireSection } from '@/dtos/questionnaires/get'
import { questionnairesModule } from '@/stores/questionnaires-store'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),
    SmTextPrintSizeAnnounce: () => import('@/components/atoms/SmTextPrintSizeAnnounce.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmPrintLabel: () => import('@/components/molecules/SmPrintLabel.vue'),

    SmPrintHeader: () => import('@/components/organisms/SmPrintHeader.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class DetailPrintPage extends Mixins(CurrentAdminManager) {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop({ required: true, default: '' })
  private readonly questionnaireId!: string

  async created():Promise<void> {
    await questionnairesModule.fetchQuestionnaireDetail(new QuestionnaireDetailGetRequest(this.questionnaireId))
  }

  private get questionnaire(): QuestionnaireDetailGetResponse {
    return questionnairesModule.questionnaireDetail(this.questionnaireId) ?? new QuestionnaireDetailGetResponse()
  }

  private get questionnaireSections(): QuestionnaireSection[] {
    return this.questionnaire.questionnaireSections
  }

  private get opinionQuestionnaireQuestion(): QuestionnaireQuestion {
    return this.questionnaireSections.filter(qs => qs.questionnaireSectionType === SECTION_TYPES.OPINION)[0].questionnaireQuestions[0]
  }

  // 投票期日を時間なし（YYYY年MM月DD日（曜））で表示
  private get deadline(): string {
    return this.questionnaire.deadline?.substring(0, 14)
  }

  private get building(): string | undefined {
    return this.questionnaire.building.buildingName
  }

  private getLabel(questionnaire: QuestionnaireQuestion):string {
    switch (questionnaire.questionnaireQuestionType) {
      case QUESTIONNAIRE_QUESTION_TYPES.FREE:
        if (questionnaire.questionnaireQuestionElements[0] && questionnaire.questionnaireQuestionElements[0].placeholder) return questionnaire.questionnaireQuestionElements[0].placeholder
        else return '入力して下さい'
      case QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE: return '選択肢に✔︎を入れてください。'
      case QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES: return '選択肢に✔︎を入れてください。※複数✔可'
    }
  }

  private isOtherElement(questionElement: QuestionnaireQuestionElement): boolean {
    return questionElement.questionnaireQuestionElementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION
  }

  private isOpinionSection(section: QuestionnaireSection): boolean {
    return section.questionnaireSectionType === SECTION_TYPES.OPINION
  }

  private isFreeInputQuestion(question: QuestionnaireQuestion): boolean {
    return question.questionnaireQuestionType === QUESTIONNAIRE_QUESTION_TYPES.FREE
  }

  private isRequired(question: QuestionnaireQuestion): boolean {
    return question.required
  }
}
