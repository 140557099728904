




















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { TICKET_STATES, TICKET_TASK_STATES } from '@/constants/schema-constants'
import type { TicketState, TicketTaskState } from '@/constants/schema-constants'
import { ChipColors } from '@/constants/ux-constants'

import { getTicketStateChipColor, getTicketStateLabel, getTicketTaskStateChipColor, getTicketTaskStateLabel } from '@/libs/state-handler'
import { getTicketBadge, getTicketLabel, isOwnerResponseTasks } from '@/libs/type-handler'

import { Header } from '@/components/molecules/SmTableDataExternalPaging.vue'
import { FILTER_TOGGLE_TICKET_STATES, TicketInputParams } from '@/pages/tickets/TicketsListPage.vue'
import { ADMIN_MENU_TYPES, FILTER_TOGGLE_TASK_STATES, TaskInputParams } from '@/pages/tasks/list/TaskListSubPage.vue'

import { dashboardModule } from '@/stores/dashboard-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { ticketsModule } from '@/stores/tickets-store'
import { tasksModule } from '@/stores/tasks-store'

import { PersonalProcessingAdmin, PersonalProcessingStates } from '@/dtos/dashboard/processing-states/get'
import { DashboardSummaryGetRequest, DashboardSummaryGetResponse } from '@/dtos/dashboard/summary/get'
import { Ticket, TicketsSearchPostRequest } from '@/dtos/tickets/search/post'
import { ListResponseTaskDto, TasksSearchPostRequest, Period } from '@/dtos/tasks/search/post'

// テーブルに表示させるのに必要な内容
class TicketItem {
  ticketId!: string
  ticketState!: string
  chipColor!: ChipColors
  task!: string
  accrualDate!: string
  buildingName?: string
  buildingId?: string
  ticketTypeText!: string
  ticketTypeTextStyle!: string
  ticketTypeIcon!: string
  ticketTypeIconColor!: string
  ticketName!: string
  ticketNo!: string
  postedAt!: string
}

class TaskItem {
  ticketTaskId!: string
  ticketTaskState!: string
  chipColor!: ChipColors
  deadline!: string
  deadlineDate!: string
  buildingName?: string
  buildingId?: string
  ticketTypeText!: string
  ticketTypeTextStyle!: string
  ticketTypeIcon!: string
  ticketTypeIconColor!: string
  ticketTaskName!: string
  ticketName!: string
  ticketId!: string
  adminName!: string
  adminShortName!: string
}

export class ProcessingStatusTableItem {
  user!: PersonalProcessingAdmin
  notCompletedTicketsCount!: number
  notStartedYetTasksCount!: number
  notCompletedTasksCount!: number
  nearDeadlineTasksCount!: number
  expiredTasksCount!: number
}

@Component({
  components: {
    SmBadgeCategory: () => import('@/components/atoms/SmBadgeCategory.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmTableDataExternalPaging: () => import('@/components/molecules/SmTableDataExternalPaging.vue'),
    SmTableDashboardProcessingStatus: () => import('@/components/molecules/SmTableDashboardProcessingStatus.vue'),

    SmTicketsTasksSummary: () => import('@/pages/dashboard/SmTicketsTasksSummary.vue'),
  }
})
export default class AllSubPage extends Vue {
  isFilterMenuOpenOfDeadlineTask = true
  isFilterMenuOpenOfTicket = true
  isFilterMenuOpenOfResponseOwnerTask = true

  async created(): Promise<void> {
    // 検索条件初期化
    this.ticketsSearchPostRequestParam = new TicketsSearchPostRequest(0, 999)
    this.tasksSearchPostRequestParam = new TasksSearchPostRequest(999)
    tasksModule.clearFetchedTask()
    await Promise.all([
      dashboardModule.fetchDashboardSummary(new DashboardSummaryGetRequest(false)),
      dashboardModule.fetchDashboardProcessingStates(),
      ticketsModule.fetchTickets(this.toSearchTicketsPostRequest()),
      tasksModule.fetchDashboardDeadlineTasks(this.toSearchTasksPostRequest()),
      tasksModule.fetchDashboardResponseOwnerTasks(this.toSearchResponseOwnerTasksPostRequest())
    ])
  }

  private get ticketHeaderTitle():string {
    return '担当者未設定チケット（' + this.tickets.length + '件）'
  }

  private get deadlineTaskHeaderTitle():string {
    return '期限切れタスク（' + this.deadlineTasks.length + '件）'
  }

  private get responseOwnerTaskHeaderTitle():string {
    return '区分所有者への対応タスク（' + this.responseOwnerTasks.length + '件）'
  }

  // ----------------------チケット・タスクサマリーエリア----------------------
  private get dashboardSummary():DashboardSummaryGetResponse { return dashboardModule.getDashboardSummary }

  clickTicketsCount(ticketState: TicketState): void {
    // チケット一覧画面への遷移後、チケットステータス（処理中）と担当者での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.ticketsList.name
    const ticketsInputParams = new TicketInputParams()

    // チケットステータス（担当者未設定 or 処理中）を検索条件に設定
    if (ticketState === TICKET_STATES.UNSET_PERSON_IN_CHARGE) ticketsInputParams.ticketStates = [FILTER_TOGGLE_TICKET_STATES.UNSET_PERSON_IN_CHARGE]
    if (ticketState === TICKET_STATES.NOT_COMPLETED) ticketsInputParams.ticketStates = [FILTER_TOGGLE_TICKET_STATES.NOT_COMPLETED]

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...ticketsInputParams } })
    this.$router.push({ name: staticRoutes.ticketsList.name })
  }

  clickTasksCount(ticketTaskState: TicketTaskState): void {
    // タスク一覧画面への遷移後、タスクステータス（担当者未設定 or 未着手 or 処理中）と担当者での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.ALL

    // タスクステータス（担当者未設定 or 未着手 or 処理中）を検索条件に設定
    if (ticketTaskState === TICKET_TASK_STATES.UNSET_PERSON_IN_CHARGE) tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE]
    if (ticketTaskState === TICKET_TASK_STATES.NOT_STARTED_YET) tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET]
    if (ticketTaskState === TICKET_TASK_STATES.NOT_COMPLETED) tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  clickNearDeadlineTasksCount(): void {
    // タスク一覧画面への遷移後、期日での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    tasksInputParams.staffMenuTypes = 'all'

    // 期日を本日から6日後を検索条件として設定
    const time = new Date()
    tasksInputParams.deadline = new Period()
    tasksInputParams.deadline.fromDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate()))
    tasksInputParams.deadline.toDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate() + 6))

    // タスクステータスが完了以外を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE, FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET, FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  // ----------------------担当者別処理状況エリア----------------------
  private get processingStatusTableItem(): ProcessingStatusTableItem[] {
    const _getDashboardProcessingStates = dashboardModule.getDashboardProcessingStates
    if (_getDashboardProcessingStates.personalProcessingStates.length === 0) return []
    return _getDashboardProcessingStates.personalProcessingStates.map(t => this.toProcessingStatusTableItem(t))
  }

  toProcessingStatusTableItem(t: PersonalProcessingStates): ProcessingStatusTableItem {
    const item = new ProcessingStatusTableItem()
    item.user = t.admin
    item.notCompletedTicketsCount = t.ticket.notCompletedTicketsCount
    item.notStartedYetTasksCount = t.task.notStartedYetTasksCount
    item.notCompletedTasksCount = t.task.notCompletedTasksCount
    item.nearDeadlineTasksCount = t.task.nearDeadlineTasksCount
    item.expiredTasksCount = t.task.expiredTasksCount
    return item
  }

  goTicketsList(item: ProcessingStatusTableItem): void {
    // チケット一覧画面への遷移後、チケットステータス（処理中）と選択したユーザーでの絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.ticketsList.name
    const ticketsInputParams = new TicketInputParams()

    // チケットステータス（処理中）を検索条件に設定
    ticketsInputParams.ticketStates = [FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    // 選択したユーザーを検索対象の担当者として条件に設定
    ticketsInputParams.admins = [item.user]

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...ticketsInputParams } })
    this.$router.push({ name: staticRoutes.ticketsList.name })
  }

  goNotStartedYetTasksList(item: ProcessingStatusTableItem): void {
    // タスク一覧画面への遷移後、タスクステータス（未着手）と選択したユーザーでの絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    // タスクステータス（未着手）を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET]

    // 選択したユーザーを検索対象の担当者として条件に設定
    tasksInputParams.staffs = [item.user]
    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.EACH

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  goNotCompletedTasksList(item: ProcessingStatusTableItem): void {
    // タスク一覧画面への遷移後、タスクステータス（処理中）と選択したユーザーでの絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    // タスクステータス（未着手）を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    // 選択したユーザーを検索対象の担当者として条件に設定
    tasksInputParams.staffs = [item.user]
    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.EACH

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  goNearDeadlineTasksList(item: ProcessingStatusTableItem): void {
    // タスク一覧画面への遷移後、タスクステータス（完了以外）と選択したユーザーと期日での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    // 期日を本日から6日後を検索条件として設定
    const time = new Date()
    tasksInputParams.deadline = new Period()
    tasksInputParams.deadline.fromDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate()))
    tasksInputParams.deadline.toDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate() + 6))

    // タスクステータスが完了以外を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE, FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET, FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    // 選択したユーザーを検索対象の担当者として条件に設定
    tasksInputParams.staffs = [item.user]
    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.EACH

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  goExpiredTasksList(item: ProcessingStatusTableItem): void {
    // タスク一覧画面への遷移後、期日と担当者での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    // タスクステータスが完了以外を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE, FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET, FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    // 期日の終了日を前日として検索条件として設定
    const time = new Date()
    tasksInputParams.deadline = new Period()
    tasksInputParams.deadline.fromDate = ''
    tasksInputParams.deadline.toDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate() - 1))

    // 選択したユーザーを検索対象の担当者として条件に設定
    tasksInputParams.staffs = [item.user]
    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.EACH

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  // ----------------------担当者未設定チケットエリア----------------------
  ticketsSearchPostRequestParam: TicketsSearchPostRequest | null = null
  toSearchTicketsPostRequest(): TicketsSearchPostRequest {
    const req = new TicketsSearchPostRequest(0, 999)
    req.ticketStates = [TICKET_STATES.UNSET_PERSON_IN_CHARGE]
    req.ticketTypes = undefined
    req.buildings = undefined
    req.owners = undefined
    req.fromDate = undefined
    req.toDate = undefined
    req.admins = undefined
    req.keyword = undefined
    req.sortOrder = 'desc'// チケット発生日が新しい順で並び替え
    return req
  }

  private get tickets(): TicketItem[] {
    const _tickets = ticketsModule.tickets
    if (_tickets.length === 0) return []
    return _tickets.map(t => this.toTicketItem(t))
  }

  toTicketItem(t: Ticket): TicketItem {
    const item = new TicketItem()
    item.ticketId = t.ticketId
    item.ticketState = getTicketStateLabel(t.ticketState)
    item.chipColor = this.getChipColor(t.ticketState)
    item.task = t.completedTaskCount + '/' + t.allTaskCount
    item.accrualDate = t.postedAt
    item.buildingName = t.buildingName
    item.buildingId = t.buildingId
    item.ticketTypeText = getTicketLabel(t.ticketType)
    item.ticketTypeTextStyle = getTicketBadge(t.ticketType).textStyle
    item.ticketTypeIcon = getTicketBadge(t.ticketType).icon
    item.ticketTypeIconColor = getTicketBadge(t.ticketType).iconColor
    item.ticketName = t.ticketName
    item.ticketNo = t.ticketNo
    item.postedAt = t.postedAt
    return item
  }

  getChipColor(state: TicketState): ChipColors {
    return getTicketStateChipColor(state)
  }

  TicketTableHeaders: Header[] = [
    new Header({ text: 'チケット受付日', value: 'postedAt', sortable: false, width: '160px' }),
    new Header({ text: 'マンション名', value: 'buildingName', sortable: false, width: '184px' }),
    new Header({ text: 'コンテンツ', value: 'ticketType', sortable: false, width: '134px' }),
    new Header({ text: 'チケットタイトル', value: 'ticketName', sortable: false, width: '498px' }),
  ]

  goTicketsListPage(): void {
    // チケット一覧画面への遷移後、チケットステータス（担当者未設定）での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.ticketsList.name
    const ticketsInputParams = new TicketInputParams()

    // チケットステータス（担当者未設定）を検索条件に設定
    ticketsInputParams.ticketStates = [FILTER_TOGGLE_TICKET_STATES.UNSET_PERSON_IN_CHARGE]

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...ticketsInputParams } })
    this.$router.push({ name: staticRoutes.ticketsList.name })
  }

  goToTicketDetailPage(ticket: TicketItem): void {
    this.$router.push({ name: staticRoutes.ticketDetail.name, params: { ticketId: ticket.ticketId } })
  }

  // ----------------------期限切れ担当タスクエリア----------------------
  tasksSearchPostRequestParam: TasksSearchPostRequest | null = null

  toSearchTasksPostRequest(): TasksSearchPostRequest {
    const req = new TasksSearchPostRequest(999)
    req.ticketId = undefined
    req.ticketTaskStates = [TICKET_TASK_STATES.UNSET_PERSON_IN_CHARGE, TICKET_TASK_STATES.NOT_STARTED_YET, TICKET_TASK_STATES.NOT_COMPLETED]
    req.ticketTypes = undefined
    req.buildings = undefined
    req.staffs = undefined
    req.postedDate = undefined
    req.deadline = undefined
    req.keyword = undefined
    req.sortItem = 'deadline' // 期日が古い順で並び替える
    return req
  }

  private get deadlineTasks(): TaskItem[] {
    const _tasks = tasksModule.deadlineTasks
    if (_tasks.length === 0) return []
    const personalTaskItem = _tasks.map(t => this.toTaskItem(t))
    const time = new Date()
    return personalTaskItem.filter(t => new Date(t.deadlineDate).getTime() < new Date(time.getFullYear(), time.getMonth(), time.getDate()).getTime()) // 期限が前日のタスクだけに絞り込む
  }

  deadlineTaskTableHeaders: Header[] = [
    new Header({ text: 'タスク期日', value: 'deadline', sortable: false, width: '160px' }),
    new Header({ text: '担当者', value: 'adminShortName', sortable: false, width: '128px' }),
    new Header({ text: 'マンション名', value: 'buildingName', sortable: false, width: '155px' }),
    new Header({ text: 'コンテンツ', value: 'ticketType', sortable: false, width: '134px' }),
    new Header({ text: 'タスクタイトル', value: 'ticketTaskName', sortable: false, width: '199.5px' }),
    new Header({ text: '親チケットタイトル', value: 'ticketName', sortable: false, width: '199.5px' }),
  ]

  goTasksListPageExpired(): void {
    // タスク一覧画面への遷移後、期日と担当者での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.ALL

    // タスクステータスが完了以外を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE, FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET, FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    // 期日の終了日を前日として検索条件として設定
    const time = new Date()
    tasksInputParams.deadline = new Period()
    tasksInputParams.deadline.fromDate = ''
    tasksInputParams.deadline.toDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate() - 1, 0, 0, 0, 0)) // 前日を検索条件の終了日とする

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  formatDate(date: Date): string {
    const y = date.getFullYear()
    const m = ('00' + (date.getMonth() + 1)).slice(-2)
    const d = ('00' + date.getDate()).slice(-2)
    return (y + '-' + m + '-' + d)
  }

  // ----------------------区分所有者への対応タスクエリア----------------------
  responseOwnerTasksSearchPostRequestParam: TasksSearchPostRequest | null = null
  toSearchResponseOwnerTasksPostRequest(): TasksSearchPostRequest {
    const req = new TasksSearchPostRequest(999)
    req.ticketId = undefined
    req.ticketTaskStates = [TICKET_TASK_STATES.UNSET_PERSON_IN_CHARGE, TICKET_TASK_STATES.NOT_STARTED_YET, TICKET_TASK_STATES.NOT_COMPLETED]
    req.ticketTypes = undefined
    req.ticketTaskTypes = isOwnerResponseTasks(true)
    req.buildings = undefined
    req.staffs = undefined
    req.postedDate = undefined
    req.deadline = undefined
    req.keyword = undefined
    req.sortItem = 'deadline' // 期日が古い順で並び替える
    return req
  }

  private get responseOwnerTasks(): TaskItem[] {
    const _tasks = tasksModule.responseOwnerTasks
    return _tasks.length ? _tasks.map(t => this.toTaskItem(t)) : []
  }

  goTasksListPageFilteredResponseOwnerTask(): void {
    // タスク一覧画面への遷移後、期日と担当者での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.ALL

    // タスクステータスが完了以外を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE, FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET, FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    // 区分所有者への対応タスクのタスク種別を検索条件として設定
    tasksInputParams.responseOwnerTasks = true

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

    responseOwnerTaskTableHeaders: Header[] = [
      new Header({ text: 'タスク期日', value: 'deadline', sortable: false, width: '160px' }),
      new Header({ text: '担当者', value: 'adminShortName', sortable: false, width: '128px' }),
      new Header({ text: 'マンション名', value: 'buildingName', sortable: false, width: '155px' }),
      new Header({ text: 'コンテンツ', value: 'ticketType', sortable: false, width: '134px' }),
      new Header({ text: 'タスクタイトル', value: 'ticketTaskName', sortable: false, width: '199.5px' }),
      new Header({ text: '親チケットタイトル', value: 'ticketName', sortable: false, width: '199.5px' }),
    ]

    // ----------------------共通利用----------------------
    getChipColorOfTask(state: TicketTaskState): ChipColors {
      return getTicketTaskStateChipColor(state)
    }

    toTaskItem(t: ListResponseTaskDto): TaskItem {
      const item = new TaskItem()
      item.ticketTaskId = t.ticketTaskId
      item.ticketTaskState = getTicketTaskStateLabel(t.ticketTaskState)
      item.chipColor = this.getChipColorOfTask(t.ticketTaskState)
      item.deadline = t.deadline
      item.deadlineDate = t.deadlineDate
      item.buildingName = t.building?.buildingName
      item.buildingId = t.building?.buildingId
      item.ticketTypeText = getTicketLabel(t.ticket.ticketType)
      item.ticketTypeTextStyle = getTicketBadge(t.ticket.ticketType).textStyle
      item.ticketTypeIcon = getTicketBadge(t.ticket.ticketType).icon
      item.ticketTypeIconColor = getTicketBadge(t.ticket.ticketType).iconColor
      item.ticketTaskName = t.ticketTaskName
      item.ticketName = t.ticket.ticketName
      item.ticketId = t.ticket.ticketId
      if (t.ticket.adminName)item.adminName = t.ticket.adminName
      if (t.ticket.adminShortName)item.adminShortName = t.ticket.adminShortName
      return item
    }

    goToTicketDetailPageTaskTab(task: TaskItem): void {
      this.$router.push({ name: staticRoutes.ticketDetail.getChild('tasks').name, params: { ticketId: task.ticketId, ticketTaskId: task.ticketTaskId } }) // フォーカス状態とするためタスクIDを渡す
    }
}
