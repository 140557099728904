import { APIClientBase } from './api-client-base'
import { IDEA_TYPES } from '@/constants/schema-constants'
import { BuildingsGetRequest, BuildingsGetResponse } from '@/dtos/buildings/get'
import { BuildingDetailGetRequest, BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { BuildingsPostRequest, BuildingsPostResponse } from '@/dtos/buildings/post'
import { BuildingsPutRequest } from '@/dtos/buildings/put'
import { BuildingDeleteRequest } from '@/dtos/buildings/delete'
import { BuildingIdeasGetRequest, BuildingIdeasGetResponse } from '@/dtos/buildings/ideas/get'
import { BuildingOwnersGetRequest, BuildingOwnersGetResponse } from '@/dtos/buildings/owners/get'
import { OwnerNotificationPinningBuildingsGetRequest, OwnerNotificationPinningBuildingsGetResponse } from '@/dtos/buildings/owner-notifications/pinning/get'
import { BuildingResolutionsGetRequest, BuildingResolutionsGetResponse } from '@/dtos/buildings/resolutions/get'
import { BuildingAdminIdeasGetRequest, BuildingAdminIdeasGetResponse } from '@/dtos/buildings/admin-idea/get'

class BuildingsClient extends APIClientBase {
  constructor() {
    super('/buildings')
  }

  getBuildings(req: BuildingsGetRequest): Promise<BuildingsGetResponse> {
    return this.get('', req, BuildingsGetResponse)
  }

  getBuildingDetail(req: BuildingDetailGetRequest): Promise<BuildingDetailGetResponse> {
    return this.get(`${req.buildingId}`, '', BuildingDetailGetResponse)
  }

  async postBuildings(req: BuildingsPostRequest): Promise<BuildingsPostResponse | void> {
    return await this.post('', req, BuildingsPostResponse)
  }

  async putBuildings(req: BuildingsPutRequest): Promise<void> {
    await this.put(`${req.buildingId}`, req)
  }

  // サービス利用終了
  async deleteBuilding(req: BuildingDeleteRequest): Promise<void> {
    return this.delete(`${req.buildingId}`)
  }

  getBuildingIdeas(req: BuildingIdeasGetRequest): Promise<BuildingIdeasGetResponse> {
    const { buildingId, ...params } = req
    return this.get(`${buildingId}/ideas`, params, BuildingIdeasGetResponse)
  }

  getBuildingAdminIdeas(req: BuildingAdminIdeasGetRequest) : Promise<BuildingAdminIdeasGetResponse> {
    const { buildingId, ...query } = req
    return this.get(`${buildingId}/ideas/${IDEA_TYPES.ADMIN_IDEA}`, query, BuildingAdminIdeasGetResponse)
  }

  getBuildingOwners(req: BuildingOwnersGetRequest): Promise<BuildingOwnersGetResponse> {
    return this.get(`${req.buildingId}/owners`, '', BuildingOwnersGetResponse)
  }

  getBuildingResolutions(req: BuildingResolutionsGetRequest): Promise<BuildingResolutionsGetResponse> {
    const { buildingId, ...params } = req
    return this.get(`${buildingId}/resolutions`, params, BuildingResolutionsGetResponse)
  }

  getNotificationPinningBuildings(req: OwnerNotificationPinningBuildingsGetRequest): Promise<OwnerNotificationPinningBuildingsGetResponse> {
    return this.get('/owner-notifications/pinning', req, OwnerNotificationPinningBuildingsGetResponse)
  }
}

export const buildingsClient = new BuildingsClient()
