import { render, staticRenderFns } from "./SmTableData.vue?vue&type=template&id=66326af0&scoped=true&"
import script from "./SmTableData.vue?vue&type=script&lang=ts&"
export * from "./SmTableData.vue?vue&type=script&lang=ts&"
import style0 from "./SmTableData.vue?vue&type=style&index=0&id=66326af0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66326af0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VDataTable,VIcon,VPagination,VSimpleCheckbox})
