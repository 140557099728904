import { Type } from 'class-transformer'

export class QuestionnaireQuestionsPostRequest {
  questionnaireQuestionIds?: string[]

  constructor(questionnaireQuestionIds?: string[]) {
    this.questionnaireQuestionIds = questionnaireQuestionIds
  }
}

export class QuestionItem {
  questionnaireQuestionId!: string
  sectionTitle?: string
  questionSentence!: string
  questionType!: string
}

export class QuestionnaireQuestionsPostResponse {
  @Type(() => QuestionItem) questionnaireQuestions: QuestionItem[] = []
}
