

































import { Mixins, Component } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { IDEA_STATES } from '@/constants/schema-constants'
import { Tab } from '@/components/molecules/SmTabs.vue'
import { AdminIdeasDeleteRequest } from '@/dtos/ideas/admin-idea/delete'
import { structureModule } from '@/stores/structure-store'
import { adminIdeasModule, AdminIdeaCommonState } from '@/stores/admin-ideas-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class AdminIdeaDetailPage extends Mixins(CurrentAdminManager) {
  showDeleteDialog = false

  private get ideaCommon(): AdminIdeaCommonState {
    return adminIdeasModule.adminIdeaCommonStateGet ?? new AdminIdeaCommonState()
  }

  private get tabs(): Tab[] {
    const tabs = [
      new Tab('プラン内容', staticRoutes.adminIdeaDetail.getChild('detail').name, !this.ideaCommon.details.isIdea),
      new Tab('意見交換会', staticRoutes.adminIdeaDetail.getChild('session').name, !this.ideaCommon.details.isSession),
      new Tab('意見', staticRoutes.adminIdeaDetail.getChild('opinions').name, !this.ideaCommon.details.isOpinions),
      new Tab('更新情報', staticRoutes.adminIdeaDetail.getChild('updates').name, !this.ideaCommon.details.isUpdates),
      new Tab('賛同集計結果', staticRoutes.adminIdeaDetail.getChild('agreements').name, !this.ideaCommon.details.isAgreement),
    ]
    // プレビュー時
    const previewTabs = [
      new Tab('プラン内容', staticRoutes.adminIdeaDetailPreview.getChild('detail').name, !this.ideaCommon.details.isIdea),
      new Tab('意見交換会', staticRoutes.adminIdeaDetailPreview.getChild('session').name, !this.ideaCommon.details.isSession),
      new Tab('意見', staticRoutes.adminIdeaDetailPreview.getChild('opinions').name, !this.ideaCommon.details.isOpinions),
      new Tab('更新情報', staticRoutes.adminIdeaDetailPreview.getChild('updates').name, !this.ideaCommon.details.isUpdates),
      new Tab('賛同集計結果', staticRoutes.adminIdeaDetailPreview.getChild('agreements').name, !this.ideaCommon.details.isAgreement),
    ]
    return this.isPreview ? previewTabs : tabs
  }

  private get isDeleted(): boolean {
    if (this.$isServiceStaff &&
        (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT || this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION)) {
      return true
    }
    return false
  }

  private get isPreview(): boolean { return structureModule.isPreview }

  async onClickIdeaDelete() : Promise<void> {
    const ideaId = this.ideaCommon.ideaId
    const req = new AdminIdeasDeleteRequest(ideaId)

    this.showDeleteDialog = false
    await adminIdeasModule.deleteIdea(req)
    this.$router.push({ name: staticRoutes.ideasList.name })
  }
}
