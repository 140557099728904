




































import { Component, Mixins } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { CurrentAdminManager } from './mixins/current-admin-manager'

import { structureModule, NAVIGATIONS, NavigationId } from './stores/structure-store'
import { myProfileModule } from './stores/my-profile-store'
import { buildingsModule } from './stores/buildings-store'
import { currentStateModule } from './stores/current-state'
import { paramStorageModule } from './stores/param-storage-store'

import { Navigation } from './dtos/navigation'
import { BuildingDetailGetResponse } from './dtos/buildings/get-detail'
import { SYSTEM_BAR_ACTIONS } from './components/organisms/SmSystemBar.vue'
import type { SystemBarActions } from './components/organisms/SmSystemBar.vue'
import { REPAIR_PLAN_TYPES } from './constants/schema-constants'
import { WIDTH_PATTERNS } from './constants/ux-constants'
import type { WidthPattern, TransitionPattern } from './constants/ux-constants'
import { setViewport } from './libs/viewport-helper'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'

const navigationsForServiceStaff = [NAVIGATIONS.building, NAVIGATIONS.owner, NAVIGATIONS.idea, NAVIGATIONS.resolution, NAVIGATIONS.repairPlan, NAVIGATIONS.repairReserveFund, NAVIGATIONS.consultation, NAVIGATIONS.report]

const navigationsForAccountingDept = [NAVIGATIONS.building, NAVIGATIONS.owner, NAVIGATIONS.repairReserveFund]
const navigationsForFrontPerson = [NAVIGATIONS.building, NAVIGATIONS.owner, NAVIGATIONS.idea, NAVIGATIONS.resolution, NAVIGATIONS.repairPlan, NAVIGATIONS.repairReserveFund, NAVIGATIONS.report]
const navigationsForGCC = [NAVIGATIONS.building, NAVIGATIONS.owner]

@Component({
  components: {
    SmDrawer: () => import('@/components/organisms/SmDrawer.vue'),
    SmProgressOverlay: () => import('@/components/organisms/SmProgressOverlay.vue'),
    SmSystemBar: () => import('@/components/organisms/SmSystemBar.vue'),
  }
})
export default class App extends Mixins(CurrentAdminManager) {
  get navigations(): Navigation[] {
    if (this.$isServiceStaff) {
      let navigations: Navigation[] = deepCopy(
        navigationsForServiceStaff,
        { navigation: new ColumnToType(Navigation) },
        'navigation')
      // 簡易版長計の場合、サイドナビゲーションバーから修繕積立金を非表示にする
      if (this._isSimpleRepairPlan()) navigations = navigations.filter(e => e.id !== NAVIGATIONS.repairReserveFund.id)
      // 相談・連絡機能の有無が無しの場合、サイドナビゲーションから相談・連絡を非表示にする
      if (!this.building?.isConsultationUse) navigations = navigations.filter(e => e.id !== NAVIGATIONS.consultation.id)
      return navigations
    } else if (this.$isAccountingDept) {
      return this._isSimpleRepairPlan() ? navigationsForAccountingDept.filter(e => e.id !== NAVIGATIONS.repairReserveFund.id) : navigationsForAccountingDept
    } else if (this.$isFrontPerson) {
      return this._isSimpleRepairPlan() ? navigationsForFrontPerson.filter(e => e.id !== NAVIGATIONS.repairReserveFund.id) : navigationsForFrontPerson
    } else if (this.$isGroupCallCenter) {
      return navigationsForGCC
    } else {
      return []
    }
  }

  private _isSimpleRepairPlan(): boolean {
    return this.building?.repairPlanType === REPAIR_PLAN_TYPES.SIMPLE_REPAIR_PLAN
  }

  get navigationVisible(): boolean { return structureModule.navigationVisible }
  get systemBarVisible(): boolean { return structureModule.systemBarVisible }
  get currentNavigation(): NavigationId { return structureModule.currentNavigation }
  get showOverlay(): boolean { return structureModule.showOverlay }
  get widthPattern(): WidthPattern | undefined { return structureModule.widthPattern }
  get systemBarBtnsVisible(): boolean { return structureModule.systemBarBtnsVisible }

  get building(): BuildingDetailGetResponse | null { return buildingsModule.buildingDetailGet }
  get userIconImagePath(): string | undefined { return myProfileModule.myProfileGet?.user.iconImagePath }

  get transitionPattern():TransitionPattern { return structureModule.transitionPattern }
  get isPreview(): boolean { return structureModule.isPreview }

  WIDTH_PATTERNS = Object.freeze(WIDTH_PATTERNS)

  onClickSystemBarAction(id: SystemBarActions): void {
    switch (id) {
      case SYSTEM_BAR_ACTIONS.DASHBOARD: this.pushExceptSamePage(staticRoutes.dashboard.getChild('all').name); break
      case SYSTEM_BAR_ACTIONS.BUILDINGS: this.pushExceptSamePage(staticRoutes.buildingsList.name); break
      case SYSTEM_BAR_ACTIONS.OWNER_NOTIFICATIONS: this.pushExceptSamePage(staticRoutes.ownerNotificationsList.name); break
      case SYSTEM_BAR_ACTIONS.STAFFS: this.pushExceptSamePage(staticRoutes.staffsList.getChild('serviceStaff').name); break
      case SYSTEM_BAR_ACTIONS.USERS: this.pushExceptSamePage(staticRoutes.usersSearch.name); break
      case SYSTEM_BAR_ACTIONS.QUESTIONNAIRE: this.pushExceptSamePage(staticRoutes.questionnairesList.name); break
    }
  }

  onClickAvatar(): void {
    this.pushExceptSamePage(staticRoutes.account.name)
  }

  latestProfileFetchedAt = 0
  reloadProfile(): void {
    // アイコン画像の表示失敗は署名付きURLの有効期限切れの可能性が著しく高いため、プロフィールを再取得して有効なURLを得る
    // ただその他の原因の場合に無限ループしてしまわないよう、1度呼んでから一定時間は再取得しない
    const now = Date.now()
    if (this.latestProfileFetchedAt > now - 5 * 60 * 1000) return

    this.latestProfileFetchedAt = now
    myProfileModule.fetchMyProfile()
  }

  onClickDrawerBtn(NavigationId: NavigationId): void {
    if (this.isPreview) return
    paramStorageModule.deleteAll()

    switch (NavigationId) {
      case NAVIGATIONS.building.id: {
        const buildingId = currentStateModule.currentBuildingId
        if (this.$route.name !== staticRoutes.buildingDetail.name && buildingId) this.$router.push({ name: staticRoutes.buildingDetail.name, params: { buildingId } })
        break
      }
      case NAVIGATIONS.owner.id:
        this.pushExceptSamePage(staticRoutes.ownersList.name)
        break
      case NAVIGATIONS.idea.id:
        this.pushExceptSamePage(staticRoutes.ideasList.name)
        break
      case NAVIGATIONS.resolution.id:
        this.pushExceptSamePage(staticRoutes.resolutionsList.name)
        break
      case NAVIGATIONS.repairPlan.id: {
        if (!this.building) break
        else if (this.building.repairPlanType === REPAIR_PLAN_TYPES.REPAIR_PLAN) {
          this.pushExceptSamePage(staticRoutes.repairPlanDetail.getChild('summary').name)
        } else if (this.building.repairPlanType === REPAIR_PLAN_TYPES.SIMPLE_REPAIR_PLAN) {
          this.pushExceptSamePage(staticRoutes.simpleRepairPlanDetail.getChild('summary').name)
        }
        break
      }
      case NAVIGATIONS.repairReserveFund.id:
        this.pushExceptSamePage(staticRoutes.reserveFunds.getChild('detail').name)
        break
      case NAVIGATIONS.consultation.id:
        this.pushExceptSamePage(staticRoutes.consultationsList.name)
        break
      case NAVIGATIONS.report.id:
        this.pushExceptSamePage(staticRoutes.reports.name)
        break
    }
  }

  onClickNoticeBtn():void {
    this.pushExceptSamePage(staticRoutes.notices.name)
  }

  pushExceptSamePage(routeName:string):void {
    if (this.$route.name !== routeName) this.$router.push({ name: routeName })
  }

  created():void {
    const fixedWidth = 976 // .sm__main-areaのmin-widthと同じ値を設定
    setViewport(this.$vuetify.breakpoint.mobile, fixedWidth)
  }

  private get firstMenuItems():{[id: string]: { text: string, label: string | undefined }} | undefined {
    if (this.$isServiceStaff) {
      return {
        menu1: {
          text: 'チケット',
          label: 'ToDo▼'
        },
        menu2: {
          text: 'タスク',
          label: 'ToDo▼'
        }
      }
    } else return undefined
  }

  private get secondMenuItems():{[id: string]: { text: string, label: string | undefined }} | undefined {
    if (this.$isServiceStaff) {
      return {
        menu1: {
          text: 'マンション',
          label: '検索▼'
        },
        menu2: {
          text: '区分所有者',
          label: '検索▼'
        }
      }
    } else return undefined
  }

  onChangeFirstMenu(id: string): void {
    switch (id) {
      case 'menu1': this.pushExceptSamePage(staticRoutes.ticketsList.name); break
      case 'menu2': this.pushExceptSamePage(staticRoutes.tasks.getChild('list').name); break
    }
  }

  onChangeSecondMenu(id: string): void {
    switch (id) {
      case 'menu1': this.pushExceptSamePage(staticRoutes.buildingsList.name); break
      case 'menu2': this.pushExceptSamePage(staticRoutes.usersSearch.name); break
    }
  }
}
