















































































import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { MaterialType, MATERIAL_TYPES } from '@/constants/schema-constants'

class MaterialTypeDetail {
  label!: string
  fileExtensions!: string[]
  mimeType!: string
}

const ACCEPTABLE_FILES: Record<MaterialType, MaterialTypeDetail[]> = {
  [MATERIAL_TYPES.IMAGE]: [
    { label: 'jpg', fileExtensions: ['.jpg', '.jpeg'], mimeType: 'image/jpeg' },
    { label: 'png', fileExtensions: ['.png'], mimeType: 'image/png' },
    { label: 'gif', fileExtensions: ['.gif'], mimeType: 'image/gif' }
  ],
  [MATERIAL_TYPES.VIDEO]: [
    { label: 'mp4', fileExtensions: ['.mp4'], mimeType: 'video/mp4' },
    { label: 'mov', fileExtensions: ['.mov'], mimeType: 'video/quicktime' }
  ],
  [MATERIAL_TYPES.PDF]: [
    { label: 'pdf', fileExtensions: ['.pdf'], mimeType: 'application/pdf' }
  ],
  [MATERIAL_TYPES.EXCEL]: [
    { label: 'xlsx', fileExtensions: ['.xlsx'], mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' } // no '.xls'
  ],
  [MATERIAL_TYPES.EXCEL_OLD]: [
    { label: 'xls', fileExtensions: ['.xls'], mimeType: 'application/vnd.ms-excel' }
  ],
  [MATERIAL_TYPES.ZIP]: [
    { label: 'zip', fileExtensions: ['.zip'], mimeType: 'application/zip' }
  ],
  [MATERIAL_TYPES.EXCEL_MACRO]: [
    { label: 'xlsm', fileExtensions: ['.xlsm'], mimeType: 'application/vnd.ms-excel.sheet.macroEnabled.12' }
  ]
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmMaterialInputArea extends Vue {
  @Prop({ default: () => [] })
  @Model('change')
  readonly selectedFiles!: File[]

  get _files(): File[] { return this.selectedFiles }
  set _files(newValue: File[]) {
    this.$emit('change', Object.assign(() => [], newValue))
  }

  @Prop({ default: () => [] })
  readonly accepts!: MaterialType[]

  get _accepts(): { label: string, types: string } {
    const labels: string[] = []
    const types: string[] = []

    for (const type of this.accepts) {
      labels.push(...ACCEPTABLE_FILES[type].map(file => file.label))
      types.push(...ACCEPTABLE_FILES[type].flatMap(file => file.fileExtensions))
    }
    return { label: '形式：' + labels.join(','), types: types.join(',') }
  }

  @Prop({ default: false })
  readonly multiple!: boolean // 複数ファイル選択可能かどうか

  @Prop({ default: '' })
  parentErrorMessage!: string

  private get _parentErrorMessage(): string { return this.parentErrorMessage }
  private set _parentErrorMessage(newValue : string) { this.$emit('update:parentErrorMessage', newValue) }

  readonly INPUT_REF = 'uploader'

  // ドラッグオーバー中フラグ
  dragOver = false
  timeoutId = 0
  onDragOver(): void {
    this.dragOver = true
    clearTimeout(this.timeoutId)
    this.timeoutId = setTimeout(() => {
      this.dragOver = false // ドラッグオーバーしなくなった場合にフラグをfalseにする
    }, 100)
  }

  // ドラッグ&ドロップ時に呼ばれる
  onDrop(event: DragEvent): void {
    this.onFileChanged(event.dataTransfer?.files)
  }

  // クリックしてファイルを選択時に呼ばれる
  // TODO: 同じファイルを再度選択すると発火しない(選択→削除→選択)
  onFileSelected(event: InputEvent): void {
    this.onFileChanged((event.target as HTMLInputElement)?.files)
  }

  onFileChanged(fileList: FileList | null | undefined): void {
    this._parentErrorMessage = ''
    this.errorFiles = []
    if (!fileList) return

    if (!this.multiple) this._files.splice(0)
    const fileNames = this._files.map(file => file.name)

    const files = this.multiple ? Array.from(fileList) : [fileList[0]]
    files.sort((a, b) => a.name > b.name ? 1 : -1)
    files.forEach(file => {
      // ファイル名が同一のファイルが選択済みの場合
      if (fileNames.includes(file.name)) return
      // ファイル拡張子が異なる場合
      const materialType = this.getMaterialType(file.type)
      if (!materialType) {
        this.onSelectFailed(file)
        return
      }

      this._files.push(file)
    })

    this.$emit('change', this._files)
  }

  getMaterialType(mimeType?: string): MaterialType | undefined {
    if (!mimeType) return undefined

    for (const type of this.accepts) {
      if (ACCEPTABLE_FILES[type].map(detail => detail.mimeType).includes(mimeType)) return type
    }
    return undefined
  }

  get errorMessage(): string | undefined {
    if (this._parentErrorMessage) return this._parentErrorMessage
    if (this.errorFiles?.length <= 0) return undefined
    return `選択されたファイルの形式をサポートしていません\n・${this.errorFiles.map(e => e.name).join('\n・')}`
  }

  errorFiles: File[] = []

  onSelectFailed(file: File): void {
    this.errorFiles.push(file)
  }

  onClickDelete(index: number): void {
    this._files?.splice(index, 1)
  }
}
