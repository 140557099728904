export class ConsultationDraftGetRequest {
  userId!: string

  constructor(userId: string) {
    this.userId = userId
  }
}

export class ConsultationDraftGetResponse {
  consultationId!: string
  message!: string
  postedAt!: string
}
