import { IdeaState, IdeaType } from '@/constants/schema-constants'

export class IdeasGetRequest {
  ideaStates: IdeaState[] | null | undefined = null
  fromDate: string | null | undefined = null
  toDate: string | null | undefined = null
  keyword: string | null | undefined = null
  isUnread: boolean | null | undefined = null
  sortOrder: string | null | undefined = null
  skipToken?: string
  take: number

  constructor(take: number) { this.take = take }
}

export class ListResponseIdeaDto {
  ideaType!: IdeaType
  ideaId!: string
  ideaState!: IdeaState
  isUnread!: boolean
  title!: string
  deadline?: string
  readCount?: number
  planCount?: number
  agreedOwnerCount?: number
  opinionCount?: number
}

export class IdeasGetResponse {
  ideas: ListResponseIdeaDto[] = []
  skipToken!: string
}
