import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { plainToClass, ClassConstructor } from 'class-transformer'
import { STATUS_CODE } from '@/constants/schema-constants'
import { ERROR_MESSAGES } from '@/constants/ux-constants'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { structureModule } from '@/stores/structure-store'
import router from '@/router'
import { staticRoutes } from '@/routes'
import { authModule } from '@/stores/auth-store'
import { cognitoAuth } from '@/libs/cognito-auth-adapter'
import { assertExhaustive } from '@/libs/exhaustive-helper'

const CURRENT_BLD_HEADER = 'X-SM-CURRENT-BLD'

export const HTTP_METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
} as const
export type HttpMethod = typeof HTTP_METHOD[keyof typeof HTTP_METHOD]

export abstract class APIClientBase {
  private readonly _client: AxiosInstance

  constructor(additionalBaseUrl: string) {
    this._client = axios.create({
      baseURL: this._joinPath(process.env.VUE_APP_API_BASE_URL, additionalBaseUrl),
      // 複数物件でのアンケート同時投稿ができない暫定対応としてタイムアウトを上限の29sとしている。（API GateWayのタイムアウトが29sであるため）
      // TODO 恒久対応で、SQSにメール内容を送信する処理を非同期処理にすることで、タイムアウトを9sに戻す。
      timeout: 29000, // 29sec.
      headers: { 'content-type': 'application/json' }
    })

    this._client.interceptors.request.use(async config => {
      if (errorsModule.globalErrors.length) errorsModule.clearGlobalErrors()

      // 選択中物件の伝達
      // FIXME: Routeのメタデータを使うなどして物件に属する/否を保持し、しない画面への遷移時にストアをクリアするようにできるとなお望ましい
      if (currentStateModule.currentBuildingId) config.headers[CURRENT_BLD_HEADER] = currentStateModule.currentBuildingId
      else delete config.headers[CURRENT_BLD_HEADER]

      // 認証情報の取得
      await authModule.updateSessionIfNecessary()
      config.headers.Authorization = cognitoAuth.getIdToken()

      return config
    })

    this._client.interceptors.response.use(
      response => {
        errorsModule.clearResponseFieldErrorPrefix()
        return response
      },
      async error => {
        structureModule.forceHideProgressOverlay()
        if (!error.response) {
          errorsModule.clearResponseFieldErrorPrefix()
          errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
          throw error
        }

        const status = error.response.status
        if (error.response.data) { // smooth-eサーバからのエラー
          const innerStatus = error.response.data.innerStatusCode
          errorsModule.setErrors(error.response.data)
          errorsModule.clearResponseFieldErrorPrefix()
          if (status === STATUS_CODE.VALIDATION) {
            switch (innerStatus) {
              case STATUS_CODE.SESSION_NOT_FOUND: {
                await authModule.logout(ERROR_MESSAGES.SESSION_EXPIRED)
                break
              }
              case STATUS_CODE.NOT_FOUND: {
                router.replace({ name: staticRoutes.notFound.name })
                break
              }
            }
          // TODO 新GRIPで150件以上の区分所有者を保持する物件に対し、サービス利用停止が可能になった後に削除する
          } else if (innerStatus === STATUS_CODE.NOT_IMPLEMENTED) {
            errorsModule.setGlobalErrors([ERROR_MESSAGES.NOT_IMPLEMENTED])
          }
        } else if (status === STATUS_CODE.BAD_REQUEST) { // cognito
          errorsModule.clearResponseFieldErrorPrefix()
          await authModule.logout(ERROR_MESSAGES.SESSION_EXPIRED)
        } else {
          errorsModule.clearResponseFieldErrorPrefix()
          errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        }
        throw error
      })
  }

  protected async get<T>(url: string, params: unknown, responseClass: ClassConstructor<T>, showProgressOverlay = true): Promise<T> {
    if (showProgressOverlay) structureModule.requestShowProgressOverlay()

    const fullResponse = await this._client.get(url, {
      params: this._formatQueryParams(params),
    })

    if (showProgressOverlay) structureModule.requestHideProgressOverlay()
    return plainToClass(responseClass, fullResponse.data)
  }

  protected async post<T>(url: string, body: unknown, responseClass?: ClassConstructor<T>, showProgressOverlay = true): Promise<T | void> {
    if (showProgressOverlay) structureModule.requestShowProgressOverlay()

    const fullResponse = await this._client.post(url, body)
    if (showProgressOverlay) structureModule.requestHideProgressOverlay()

    if (!responseClass) return
    return plainToClass(responseClass, fullResponse.data)
  }

  protected async put<T>(url: string, body: unknown, responseClass?: ClassConstructor<T>, showProgressOverlay = true): Promise<T | void> {
    if (showProgressOverlay) structureModule.requestShowProgressOverlay()

    const fullResponse = await this._client.put(url, body)
    if (showProgressOverlay) structureModule.requestHideProgressOverlay()

    if (!responseClass) return
    return plainToClass(responseClass, fullResponse.data)
  }

  protected async delete<T>(url: string, responseClass?: ClassConstructor<T>, showProgressOverlay = true): Promise<T | void> {
    if (showProgressOverlay) structureModule.requestShowProgressOverlay()

    const fullResponse = await this._client.delete(url)
    if (showProgressOverlay) structureModule.requestHideProgressOverlay()

    if (!responseClass) return
    return plainToClass(responseClass, fullResponse.data)
  }

  protected async download(url: string, params: unknown, method:HttpMethod = HTTP_METHOD.GET, prependBOM = false, showProgressOverlay = true): Promise<void> {
    if (showProgressOverlay) structureModule.requestShowProgressOverlay()
    let response:AxiosResponse<unknown> | undefined
    switch (method) {
      case HTTP_METHOD.GET: {
        response = await this._client.get(url, {
          params: this._formatQueryParams(params),
        })
        break
      }
      case HTTP_METHOD.POST: {
        response = await this._client.post(url, params, { headers: { 'content-type': 'text/plain' } })
        break
      }
      case HTTP_METHOD.PUT:
      case HTTP_METHOD.DELETE:
        throw new Error('unexpected HTTP method on download')
      default:
        assertExhaustive(method)
    }
    if (!response) return

    const targetContent = prependBOM ? [new Uint8Array([0xEF, 0xBB, 0xBF]), (response.data as Blob)] : [(response.data as Blob)]
    const downloadUrl = window.URL.createObjectURL(new Blob(targetContent, { type: response.headers['content-type'] }))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = response.headers['content-disposition'].split('filename=')[1].slice(1, -1) // attachment; filename="hoge.ext" ===> hoge.ext
    document.body.appendChild(link)

    if (showProgressOverlay) structureModule.requestHideProgressOverlay()
    link.click()
  }

  private _joinPath(...paths: string[]): string {
    let path = ''
    for (const p of paths) {
      if (!p.startsWith('/') && path.length > 0) path += '/'

      path += p

      if (path.endsWith('/')) path = path.substr(0, path.length - 1)
    }
    return path
  }

  /**
   * クエリパラメータの項目値として配列が設定されていた場合に、項目名に配列番号を添字としてその要素を展開します。
   * e.g. { types: [11, 12, 13] } => { 'types[0]': 11, 'types[1]': 12, 'types[2]': 13, }
   * @param params クエリパラメータとして指定する、キーと値の組み合わせのオブジェクト
   */
  private _formatQueryParams(params: unknown) {
    if (typeof params !== 'object' || params === null) return params

    return Object.entries(params).reduce((acc: Record<string, unknown>, [key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v, idx) => { acc[`${key}[${idx}]`] = v })
      } else {
        acc[key] = value
      }
      return acc
    }, {})
  }
}
