import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { ManagementOperationsOnboarding, ManagementOperationsOnboardingSearchPostRequest, ManagementOperationsOnboardingSearchPostResponse } from '@/dtos/management-operations/onboarding/search/post'
import { managementOperationsClient } from '@/clients/management-operations-client'
import { ManagementOperationsRoutine, ManagementOperationsRoutineSearchPostRequest, ManagementOperationsRoutineSearchPostResponse } from '@/dtos/management-operations/routine/search/post'
import { ManagementOperationsUnregisteredExecutorGetResponse, UnregisteredExecutor } from '@/dtos/management-operations/unregistered-executor/get'
import { ManagementOperationsUnregisteredExecutorPostRequest } from '@/dtos/management-operations/unregistered-executor/post'
import { ManagementOperationsUnregisteredExecutorDeleteRequest } from '@/dtos/management-operations/unregistered-executor/delete'
import { ManagementOperationsTaskStatusPutRequest, ManagementOperationsTaskStatusPutResponse } from '@/dtos/management-operations/task-status/put'

@Module({ store, dynamic: true, namespaced: true, name: 'managementOperations' })
class ManagementOperationsStore extends VuexModule {
  // 稼働準備検索
  private _managementOperationsOnboarding: ManagementOperationsOnboarding[] = []

  get managementOperationsOnboarding(): ManagementOperationsOnboarding[] {
    return this._managementOperationsOnboarding
  }

  private _smIntroducedYearList: number[] = []

  get smIntroducedYearList(): number[] {
    return this._smIntroducedYearList
  }

  private _managementOperationOnboardingCount: number | null = null

  get managementOperationOnboardingCount(): number | null {
    return this._managementOperationOnboardingCount
  }

  @Mutation
  private PUSH_OPERATION_ONBOARDING(res: ManagementOperationsOnboardingSearchPostResponse): void {
    this._managementOperationsOnboarding = res.managementOperations?.map(mo => Object.assign(new ManagementOperationsOnboarding(), mo)) ?? []
    this._smIntroducedYearList = res.smIntroducedYearList
    this._managementOperationOnboardingCount = res.managementOperationCount
  }

  @Action
  async fetchManagementOperationsOnboarding(req: ManagementOperationsOnboardingSearchPostRequest): Promise<void> {
    const res = await managementOperationsClient.postOperationOnboardingSearch(req)

    if (res) this.PUSH_OPERATION_ONBOARDING(res)
  }

  // 定例業務検索
  private _managementOperationsRoutine: ManagementOperationsRoutine[] = []

  get managementOperationsRoutine(): ManagementOperationsRoutine[] {
    return this._managementOperationsRoutine
  }

  private _managementOperationRoutineCount: number | null = null

  get managementOperationRoutineCount(): number | null {
    return this._managementOperationRoutineCount
  }

  @Mutation
  private PUSH_OPERATION_ROUTINE(res: ManagementOperationsRoutineSearchPostResponse): void {
    this._managementOperationsRoutine = res.managementOperations?.map(mo => Object.assign(new ManagementOperationsRoutine(), mo)) ?? []
    this._managementOperationRoutineCount = res.managementOperationCount
  }

  @Action
  async fetchManagementOperationsRoutine(req: ManagementOperationsRoutineSearchPostRequest): Promise<void> {
    const res = await managementOperationsClient.postOperationRoutineSearch(req)

    if (res) this.PUSH_OPERATION_ROUTINE(res)
  }

  @Action
  async postUnregisteredExecutor(req: ManagementOperationsUnregisteredExecutorPostRequest): Promise<void> {
    await managementOperationsClient.postUnregisteredExecutor(req)
  }

  // その他実施者取得
  private _managementOperationsUnregisteredExecutor: UnregisteredExecutor[] = []

  get managementOperationsUnregisteredExecutor(): UnregisteredExecutor[] {
    return this._managementOperationsUnregisteredExecutor
  }

  @Mutation
  private PUSH_UNREGISTERED_EXECUTORS(res: ManagementOperationsUnregisteredExecutorGetResponse): void {
    this._managementOperationsUnregisteredExecutor = res.executors
  }

  @Action
  async fetchManagementOperationsUnregisteredExecutors(): Promise<void> {
    const res = await managementOperationsClient.getUnregisteredExecutor()
    if (res) this.PUSH_UNREGISTERED_EXECUTORS(res)
  }

  // その他実施者削除
  @Action
  async deleteUnregisteredExecutor(req: ManagementOperationsUnregisteredExecutorDeleteRequest): Promise<void> {
    await managementOperationsClient.deleteUnregisteredExecutor(req)
  }

  // タスク別対応状況更新
  private _putTaskStatusResponses: Record<string, ManagementOperationsTaskStatusPutResponse> = {}

  get putTaskStatusResponse(): (managementOperationTaskStatusId: string) => ManagementOperationsTaskStatusPutResponse | undefined {
    return (managementOperationTaskStatusId: string) => this._putTaskStatusResponses[managementOperationTaskStatusId]
  }

  @Mutation
  private SET_PUT_TASK_STATUS_RESPONSE({ managementOperationTaskStatusId, res }: { managementOperationTaskStatusId: string, res: ManagementOperationsTaskStatusPutResponse }) {
    Vue.set(this._putTaskStatusResponses, managementOperationTaskStatusId, res)
  }

  @Action
  async putTaskStatus(req: ManagementOperationsTaskStatusPutRequest): Promise<void> {
    const res = await managementOperationsClient.putTaskStatus(req)
    if (res) this.SET_PUT_TASK_STATUS_RESPONSE({ managementOperationTaskStatusId: req.managementOperationTaskStatusId, res })
  }
}

export const managementOperationsModule = getModule(ManagementOperationsStore)
