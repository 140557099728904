
















































import { Mixins, Component } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { BuildingsPostRequest } from '@/dtos/buildings/post'
import { MaterialFormInputDto } from '@/dtos/commons'
import { buildingExcelParser } from '@/libs/excel-parser/building-parser'
import { staticRoutes } from '@/routes'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'

const INVALID_FILE_FORMAT_MESSAGE = 'ファイルのフォーマットが異なります。正しいファイルをアップロードしてください。'
const INVALID_FORMAT_MESSAGE = '導入マンションを新規登録できませんでした。初期データ入力シートの以下の項目を修正してください。\n'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class BuildingRegisterPage extends Mixins(CurrentAdminManager) {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  uploaded: MaterialFormInputDto | null = null

  get fileName(): string { return this.uploaded?.file?.name ?? '' }

  isUploadDialogVisible = false

  closeUploadDialog(): void {
    this.isUploadDialogVisible = false
  }

  async uploadFile(): Promise<void> {
    errorsModule.clearGlobalErrors()
    if (!this.uploaded?.file) return
    this.isUploadDialogVisible = false

    const data = await buildingExcelParser.parse(this.uploaded.file)
    // ファイルに不備があった場合
    if (data.errors?.size) {
      const errorMessages = Array.from(data.errors.entries()).map(([key, value]) => `・${key.substring(0, key.indexOf('|'))}${value}`).join('\n')
      errorsModule.setGlobalErrors([INVALID_FORMAT_MESSAGE, errorMessages])
      return
    }
    if (!data.reqBuilding || !data.reqOwningBuildingUnits) {
      errorsModule.setGlobalErrors([INVALID_FILE_FORMAT_MESSAGE])
      return
    }

    // 物件・住戸・区分所有者登録API呼び出し
    const req = new BuildingsPostRequest()
    req.building = data.reqBuilding
    req.owningBuildingUnits = data.reqOwningBuildingUnits

    if (!req.building.employeeId || req.building.employeeId === '') {
      req.building.employeeId = undefined
    }
    const res = await buildingsModule.postBuildings(req)

    if (res && res.buildingId) {
      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(res.buildingId))
      currentStateModule.setCurrentBuilding(res.buildingId)
      // 物件詳細画面に遷移する（管理者業務執行者・フロント担当・ライフマネージャー登録案内ダイアログを表示する）
      this.$router.replace({ name: staticRoutes.buildingDetail.name, params: { buildingId: res.buildingId, routeFromName: staticRoutes.buildingRegister.name } })
    }
  }
}
