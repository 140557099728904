
import { Type } from 'class-transformer'
import { User } from '@/dtos/commons'
import { Section } from '@/dtos/resolutions/online-resolution/results/commons'
import { Details } from '@/dtos/resolutions/commons'
import type { ResolutionState } from '@/constants/schema-constants'

export class Result {
  isPassed: boolean | null = null
  resultComment?: string
  @Type(() => Section) sections: Section[] = []
  postedAt!: string
}

export class VoteSummary {
  votingRightCount!: number
  votingRightRate!: number
}

export class Summary {
  votingRightCount!: number
  @Type(() => VoteSummary) vote = new VoteSummary()
}

export class Owner {
  userId!: string
  userName!: string
  roomNumber!: string
  votingRightCount!: number
}

export class ResultDetail {
  votingRightCount!: number
  votingRightRate!: number
  @Type(() => Owner) owners: Owner[] = []
}

export class OnlineResultsGetResponse {
  resolutionId!: string
  resolutionState!: ResolutionState
  postedAt!: string
  title!: string
  @Type(() => User) user = new User()
  @Type(() => Details) details = new Details()
  @Type(() => Result) results?: Result
  @Type(() => Summary) summary = new Summary()
  @Type(() => ResultDetail) agree = new ResultDetail()
  @Type(() => ResultDetail) disagree = new ResultDetail()
  version!: number
}
