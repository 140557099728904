import { render, staticRenderFns } from "./QuestionnairesProxyInputTable.vue?vue&type=template&id=1acc4bef&scoped=true&"
import script from "./QuestionnairesProxyInputTable.vue?vue&type=script&lang=ts&"
export * from "./QuestionnairesProxyInputTable.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionnairesProxyInputTable.vue?vue&type=style&index=0&id=1acc4bef&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1acc4bef",
  null
  
)

export default component.exports