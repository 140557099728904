import { Type } from 'class-transformer'

export class QuestionnaireOpinionsSearchPostRequest {
  questionnaireId!: string
  questionnaireChoiceAnswerIds?: string[]

  constructor(questionnaireId: string, questionnaireChoiceAnswerIds?: string[]) {
    this.questionnaireId = questionnaireId
    this.questionnaireChoiceAnswerIds = questionnaireChoiceAnswerIds
  }
}

export class QuestionnaireOpinionDto {
  questionnaireChoiceAnswerId!: string
  userId!: string
  userName!: string
  roomNumber!: number
  opinionBody!: string
}

export class QuestionnaireOpinionsSearchPostResponse {
  @Type(() => QuestionnaireOpinionDto) questionnaireOpinions: QuestionnaireOpinionDto[] = []
}
