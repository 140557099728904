


































































































import { Mixins, Component, Watch } from 'vue-property-decorator'
import { FilterLabel } from '@/components/molecules/SmExpansionFilterMenu.vue'
import { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'
import { ConsultationGroup, ConsultationGroupsGetRequest } from '@/dtos/consultations/get'
import { Owner, OwnersGetRequest } from '@/dtos/owners/get'
import { generateUuid } from '@/libs/uuid-generator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { consultationsModule } from '@/stores/consultations-store'
import { errorsModule } from '@/stores/errors'
import { ownersModule } from '@/stores/owners-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { staticRoutes } from '@/routes'

const SKIP = 0
const TAKE = 20
const STORAGE_KEY = staticRoutes.consultationsList.name

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardConsultationSummary: () => import('@/components/molecules/card/SmCardConsultationSummary.vue'),
    SmExpansionFilterMenu: () => import('@/components/molecules/SmExpansionFilterMenu.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    ConsultationsOwnerSelectModal: () => import('@/pages/consultations/consultations-list/ConsultationsOwnerSelectModal.vue'),
  }
})
export default class ConsultationsListPage extends Mixins(CurrentAdminManager) {
  request = new ConsultationGroupsGetRequest()
  // 絞り込み条件
  selectedOwner = ''
  inputKeyword = ''

  // モーダルの絞り込み条件
  consultationOwnerInputText = ''
  consultationOwnerKeyword = ''

  // 検索ボタン連打防止用
  isWaitingSwitch = false
  handler: LoadingHandler | null = null

  isInitialized = false
  async created(): Promise<void> {
    const ownerRequest = new OwnersGetRequest()
    ownerRequest.isInitialAuth = true
    this.request.take = TAKE
    await ownersModule.fetchOwners(ownerRequest)

    // 初期検索パラメーターの設定（以前の検索条件が残っている場合、それを復元して反映）
    const stored = paramStorageModule.savedParam(STORAGE_KEY, ConsultationGroupsGetRequest)
    if (stored) {
      this.request = stored
      this.selectedOwner = stored.userId ?? ''
      this.inputKeyword = stored.keyword ?? ''
    }

    this.isInitialized = true
    this.refreshList()
  }

  refreshList(): void {
    consultationsModule.clearConsultationGroups()
    this.request.skip = SKIP
    this.identifier++
  }

  private get owners(): Owner[] { return ownersModule.ownersGet.owners }
  private get consultationGroups(): ConsultationGroup[] { return consultationsModule.consultationGroupsGet }

  identifier = 1
  async loadConsultationGroups(handler: LoadingHandler): Promise<void> {
    this.isWaitingSwitch = true
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }
    this.request.skip = this.consultationGroups.length
    await consultationsModule.fetchConsultationGroups(this.request)
    paramStorageModule.save({ key: STORAGE_KEY, params: { ...this.request } })

    if (this.consultationGroups.length === 0) {
      // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    handler.loaded()
    this.isWaitingSwitch = false
    const expectingToBe = this.request.skip + this.request.take
    if (this.consultationGroups.length < expectingToBe) handler.complete()
  }

  // trueなら絞り込みメニューが展開された状態で画面初期表示する
  isFilterExpansionOpen = true

  filterLabels = {
    condition1: new FilterLabel('区分所有者', 'center', '160px'),
    condition2: new FilterLabel('キーワード', 'center', '160px'),
  }

  private get selectionItems(): { value: string, label: string }[] {
    const items = [{ value: '', label: 'すべて' }]
    const owners = this.owners.map(o => { return { value: o.userId, label: o.userName } })
    return items.concat(owners)
  }

  onClickOwnerSelect(): void {
    if (this.selectedOwner) this.request.userId = this.selectedOwner
    else this.request.userId = null
    this.refreshList()
  }

  onClickKeywordSearchBtn(): void {
    this.request.keyword = this.inputKeyword
    this.refreshList()
  }

  // 絞り込みを行っているかの判定（0件時のメッセージの判定に使用、trueならば絞り込みを行っていない）
  private get isDefaultCondition(): boolean {
    if (this.request.userId || this.request.keyword) {
      return false
    } else {
      return true
    }
  }

  isConsultationsOwnerSelectModalVisible = false
  consultationsOwnerSelectModalKey = generateUuid()
  openConsultationsOwnerSelectModal(): void {
    this.consultationsOwnerSelectModalKey = generateUuid()
    this.isConsultationsOwnerSelectModalVisible = true
  }

  goToConsultationDetail(userId: string): void {
    this.$router.push({ name: staticRoutes.consultationDetail.name, params: { userId }, query: { keyword: this.inputKeyword } })
  }

  goToSelectConsultationDetail(userId: string): void {
    this.$router.push({ name: staticRoutes.consultationDetail.name, params: { userId } })
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
    this.isWaitingSwitch = false
  }
}
