import { User } from '@/dtos/commons'
import { Type } from 'class-transformer'
import type { AreaType, AuditState, RepairPlanType } from '@/constants/schema-constants'

export class BuildingDetailGetRequest {
  buildingId: string

  constructor(buildingId: string) {
    this.buildingId = buildingId
  }
}

export class BuildingDetailGetResponse {
  buildingId!: string
  condominiumCd!: string
  ridgeCd!: string
  buildingName!: string
  buildingPostalCode!: string
  buildingAddress!: string
  buildingAreaType!: AreaType
  buildingArea!: string
  isOutOfService!: boolean
  isTrialTargetBuilding!: boolean
  isConsultationUse!: boolean
  @Type(() => User) frontStaff: User[] = []
  @Type(() => User) lmStaff: User[] = []
  @Type(() => User) serviceStaff: User = new User()
  gmMonth!: number
  closingMonth!: number
  smIntroducedDateStr!: string
  firstPeriodEndYear!: number
  billingStartDate!: string
  billingStartDateStr!: string
  accounting!: AuditState
  repairPlanType!: RepairPlanType
  adoptRepairPlanService!: boolean
  adoptReservePlanService!: boolean
  isFacilityReservationAvailable!: boolean
  keyPerson?: string
  concerns?: string
  agreementRequirement!: string
}
