





































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { MaterialFormInputDto } from '@/dtos/commons'

export class TicketCommentUpdateFormInputs {
  comment!: string
  material?: MaterialFormInputDto | null = null
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),

    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
  }
})

export default class SmCartTicketCommentUpdate extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop({ default: '' })
  private readonly originalComment!: string

  @Prop()
  private readonly originalMaterial?: MaterialFormInputDto

  @Prop()
  private readonly fieldIdPrefix?: string

  inputs = new TicketCommentUpdateFormInputs()

  created(): void {
    this.inputs.comment = this.originalComment
    this.inputs.material = deepCopy(
      this.originalMaterial,
      { material: new ColumnToType(MaterialFormInputDto) },
      'material'
    )
  }

  onClickUpdateBtn(): void {
    this.$emit('update', this.inputs)
  }
}
