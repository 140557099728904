































































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { IDEA_STATES, MATERIAL_TYPES } from '@/constants/schema-constants'
import { ERROR_MESSAGES } from '@/constants/ux-constants'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'

import { adminIdeasModule, AdminIdeaCommonState } from '@/stores/admin-ideas-store'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { myProfileModule } from '@/stores/my-profile-store'
import { structureModule } from '@/stores/structure-store'

import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { User, MaterialFormInputDto } from '@/dtos/commons'
import { Details } from '@/dtos/ideas/admin-idea/commons'
import { AdminIdeaUpdatesGetRequest, AdminIdeaUpdatesGetResponse, UpdateGetResponseUpdate } from '@/dtos/ideas/admin-idea/updates/get'
import { AdminIdeaUpdatesPostRequest } from '@/dtos/ideas/admin-idea/updates/post'
import { AdminIdeaUpdatesPutRequest } from '@/dtos/ideas/admin-idea/updates/put'
import { AdminIdeaUpdatesDeleteRequest } from '@/dtos/ideas/admin-idea/updates/delete'
import { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'
import { UpdateEditFormInputs } from '@/pages/ideas/admin-idea/admin-idea-detail/updates/UpdateEditCard.vue'

const TAKE = 20

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    UpdateEditCard: () => import('@/pages/ideas/admin-idea/admin-idea-detail/updates/UpdateEditCard.vue'),
  }
})
export default class UpdatesSubPage extends Mixins(CurrentAdminManager) {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop()
  ideaId!: string

  @Prop()
  buildingId?: string

  updatesParams = new AdminIdeaUpdatesGetRequest()
  showPostDialog = false
  showEditDialog = false
  showDeleteDialog = false
  targetUpdateId = ''
  targetIndex= 0
  targetUpdateInputs = new UpdateEditFormInputs()
  targetUpdateVersion: number | null = null

  // 新規投稿用の入力値
  updateBody = ''
  material: MaterialFormInputDto | null = null
  caption = ''
  isFileUploaded=false

  async created(): Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    this.updatesParams.take = TAKE
    this.updatesParams.ideaId = this.ideaId
    await this.refreshList()
  }

  private get isPreview(): boolean { return structureModule.isPreview }

  private get ideaCommon(): AdminIdeaCommonState {
    return adminIdeasModule.adminIdeaCommonStateGet ?? new AdminIdeaCommonState()
  }

  private get ideaUpdate(): AdminIdeaUpdatesGetResponse {
    return adminIdeasModule.adminIdeasUpdatesGet(this.ideaId) ?? new AdminIdeaUpdatesGetResponse()
  }

  identifier = 1
  updates: UpdateGetResponseUpdate[] = []
  skipToken: string | undefined = undefined

  async fetchUpdates(): Promise<void> {
    await adminIdeasModule.fetchIdeaUpdates(this.updatesParams)
    const responseUpdates = deepCopy(
      this.ideaUpdate,
      {
        AdminIdeaUpdatesGetResponse: new ColumnToType(AdminIdeaUpdatesGetResponse),
        details: new ColumnToType(Details),
        user: new ColumnToType(User),
        updates: new ColumnToType(UpdateGetResponseUpdate),
        material: new ColumnToType(MaterialFormInputDto),
      },
      'AdminIdeaUpdatesGetResponse'
    )
    this.skipToken = this.ideaUpdate.skipToken
    this.updates.push(...responseUpdates.updates)
  }

  private async loadUpdates(handler: LoadingHandler): Promise<void> {
    const beforeUpdatesCount = this.updates.length
    this.updatesParams.skipToken = this.skipToken
    await this.fetchUpdates()

    if (this.updates.length === 0) {
      // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
      handler.complete()
      return
    }

    handler.loaded()
    const expectingToBe = beforeUpdatesCount + TAKE
    if (this.updates.length < expectingToBe) handler.complete()
  }

  private refreshList(): void {
    this.updates = []
    this.skipToken = undefined
    this.identifier++
  }

  private get isEditable(): boolean {
    return this.$isServiceStaff &&
          (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT ||
           this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION ||
           this.ideaCommon.ideaState === IDEA_STATES.ADMIN.COUNTING)
  }

  private get hasUpdates(): boolean {
    return this.updates && this.updates.length > 0
  }

  // 更新情報内容はrequiredで必須チェックをすると投稿後の初期化でも動作してしまいエラー表示されてしまうため、computedで必須のチェックを行う
  private get isInputUpdateBody() {
    return this.updateBody !== ''
  }

  private async executePostUpdate(): Promise<void> {
    this.showPostDialog = false
    const rawReq = new AdminIdeaUpdatesPostRequest()
    rawReq.ideaId = this.ideaUpdate.ideaId
    rawReq.updateBody = this.updateBody
    if (this.material) {
      rawReq.material = this.material
      if (this.caption) rawReq.material.caption = this.caption // no empty string
    }

    const uploader = new FileUploader()
    const req = await uploader.prepare(rawReq)
    await adminIdeasModule.postIdeaUpdates(req)

    this.refreshList()
    this.updateBody = ''
    this.material = null
    this.caption = ''
  }

  private onClickEdit(updateId: string, index: number, inputs: UpdateEditFormInputs, version: number): void {
    if (this.isPreview) return
    this.targetUpdateId = updateId
    this.targetIndex = index
    this.targetUpdateInputs = inputs
    this.targetUpdateVersion = version

    this.showEditDialog = true
  }

  private async executeEditUpdate(): Promise<void> {
    this.showEditDialog = false

    const rawReq = new AdminIdeaUpdatesPutRequest()
    rawReq.ideaId = this.ideaUpdate.ideaId
    rawReq.updateId = this.targetUpdateId
    rawReq.updateBody = this.targetUpdateInputs.updateBody
    rawReq.material = this.targetUpdateInputs.material ?? undefined
    if (this.targetUpdateVersion === null) return
    rawReq.version = this.targetUpdateVersion

    let req : AdminIdeaUpdatesPutRequest | undefined

    // 添付画像がbase64データだった場合、またはPDFを添付した場合、プレビュー用にデータを登録する
    let uploadedMaterial : MaterialFormInputDto | undefined
    const rawMaterialRegExp = /^data:.*$/

    if (rawReq.material?.materialType === MATERIAL_TYPES.PDF ||
      (rawReq.material?.materialType === MATERIAL_TYPES.IMAGE &&
      rawReq.material?.materialUrl &&
      rawMaterialRegExp.test(rawReq.material.materialUrl))) {
      req = rawReq
      uploadedMaterial = await uploadMaterial(Object.assign(new MaterialFormInputDto(), rawReq.material))
      if (uploadedMaterial) {
        req.material = uploadedMaterial
        rawReq.material.materialUrl = uploadedMaterial.materialUrl
      }
    }

    errorsModule.presetResponseFieldErrorPrefix(`updates[${this.targetIndex}].`)
    const uploader = new FileUploader()
    req = await uploader.prepare(rawReq)

    await adminIdeasModule.putIdeaUpdates(req)
    Object.assign(this.updates[this.targetIndex], rawReq)
    const putResponse = adminIdeasModule.adminIdeaUpdatesPut
    this.$set(this.updates[this.targetIndex], 'postedAt', putResponse.postedAt)
    this.$set(this.updates[this.targetIndex], 'version', putResponse.version)

    this.changeEditMode(this.targetUpdateId, false)
  }

  private async onClickEditCancel(updateId: string): Promise<void> {
    this.changeEditMode(updateId, false)
  }

  private onClickDelete(updateId: string, index: number): void {
    if (this.isPreview) return
    this.targetUpdateId = updateId
    this.targetIndex = index
    this.showDeleteDialog = true
  }

  private async executeDeleteUpdate(): Promise<void> {
    this.showDeleteDialog = false
    const req = new AdminIdeaUpdatesDeleteRequest()
    req.ideaId = this.ideaUpdate.ideaId
    req.updateId = this.targetUpdateId
    errorsModule.presetResponseFieldErrorPrefix(`updates[${this.targetIndex}].`)
    this.showDeleteDialog = false
    await adminIdeasModule.deleteIdeaUpdates(req)
    this.updates.splice(this.targetIndex, 1)
  }

  private changeEditMode(updateId: string, isEdit: boolean): void {
    if (this.isPreview) return
    const targetUpdate = this.updates.find(_ => _.updateId === updateId)
    if (targetUpdate !== undefined) {
      targetUpdate.isEditMode = isEdit
    }
  }
}
