import type { OwnerNotificationType } from '@/constants/schema-constants'

export class OwnerNotificationPinningBuildingsGetRequest {
  ownerNotificationType!: OwnerNotificationType
  scheduledPostDateTime?: string
  ownerNotificationId?: string
}

export class OwnerNotificationPinningBuildingsGetResponse {
  buildingIds: string[] = []
}
