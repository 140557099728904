import type { CaseType, InformationSourceType, TicketType } from '@/constants/schema-constants'

export class TicketsPostRequestAdmin {
  userId: string
  constructor(userId: string) {
    this.userId = userId
  }
}

export class TicketsPostRequestBuilding {
  buildingId: string
  constructor(buildingId: string) {
    this.buildingId = buildingId
  }
}

export class TicketsPostRequestOwnerUser {
  userId: string
  constructor(userId: string) {
    this.userId = userId
  }
}

export class TicketsPostRequestBaseIdea {
  ideaId: string
  sortOrderNum: number
  constructor(ideaId: string, sortOrderNum: number) {
    this.ideaId = ideaId
    this.sortOrderNum = sortOrderNum
  }
}

export class TicketsPostRequest {
  ticketName: string
  ticketType: TicketType
  admin: TicketsPostRequestAdmin
  building?: TicketsPostRequestBuilding
  ownerUser?: TicketsPostRequestOwnerUser
  receptionDate: string
  informationSourceType: InformationSourceType
  informationSource?: string
  caseType: CaseType
  inquiryBody?: string
  correspondence?: string
  baseIdeas?: TicketsPostRequestBaseIdea[]
  isManagementStatusReport: boolean
  isOperationStatusReport: boolean

  constructor(ticketName: string, ticketType: TicketType, admin: TicketsPostRequestAdmin, receptionDate: string, informationSourceType: InformationSourceType, caseType: CaseType, isManagementStatusReport: boolean, isOperationStatusReport: boolean, building?: TicketsPostRequestBuilding, ownerUser?: TicketsPostRequestOwnerUser, informationSource?: string, inquiryBody?: string, correspondence?: string, baseIdeas?: TicketsPostRequestBaseIdea[]) {
    this.ticketName = ticketName
    this.ticketType = ticketType
    this.admin = admin
    this.building = building
    this.ownerUser = ownerUser
    this.receptionDate = receptionDate
    this.informationSourceType = informationSourceType
    this.informationSource = informationSource
    this.caseType = caseType
    this.inquiryBody = inquiryBody
    this.correspondence = correspondence
    this.baseIdeas = baseIdeas
    this.isManagementStatusReport = isManagementStatusReport
    this.isOperationStatusReport = isOperationStatusReport
  }
}
