










































































































































import { SwitchableFormShowMode, SWITCHABLE_FORM_SHOW_MODE } from '@/constants/ux-constants'
import { User } from '@/dtos/commons'
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
  }
})
export default class SmModeSwitchableForm extends Vue {
  @Prop({ required: true, default: '' })
  label!:string

  @Prop({ required: true, default: '' })
  placeholder!:string

  @Prop({ default: '' })
  fieldIdPrefix!: string

  @Prop()
  body?:string

  @Prop()
  user?:User

  @Prop()
  postedAt?:string

  // 親要素のID（画面内に複数の項目があり、それぞれにテキストを紐つける場合の紐付け対象
  @Prop()
  relationId?:string

  @Prop({ default: true })
  isEditable!:boolean

  @Prop({ default: false })
  btnDisable!:boolean

  @Prop({ default: false })
  dense!:boolean

  @Prop({ default: false })
  displayLabel!:boolean

  editingBody = ''

  SWITCHABLE_FORM_SHOW_MODE = Object.freeze(SWITCHABLE_FORM_SHOW_MODE)
  showMode:SwitchableFormShowMode = SWITCHABLE_FORM_SHOW_MODE.VIEW

  @Watch('body', { immediate: true })
  onChangeBody():void {
    if (this.isEditable) {
      this.showMode = this.body ? SWITCHABLE_FORM_SHOW_MODE.VIEW : SWITCHABLE_FORM_SHOW_MODE.REGISTER
      return
    }
    this.showMode = SWITCHABLE_FORM_SHOW_MODE.VIEW
  }

  startEdit():void {
    if (!this.body || this.btnDisable) return
    this.showMode = SWITCHABLE_FORM_SHOW_MODE.UPDATE
    this.editingBody = this.body
  }

  cancelEdit():void {
    this.showMode = SWITCHABLE_FORM_SHOW_MODE.VIEW
  }
}
