





































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})
export default class SmOwBtnText extends Vue {
  @Prop()
  private readonly leftIcon?: string

  @Prop({ default: 'secondary' })
  private readonly leftIconColor!: string

  @Prop()
  private readonly rightIcon?: string

  @Prop({ default: 'secondary' })
  private readonly rightIconColor!: string

  @Prop()
  private readonly text?: string

  @Prop()
  private readonly count?: number

  @Prop({ default: 'sm-ow__btn-mini' })
  private readonly format!: string

  @Prop({ default: 'secondary' })
  private readonly textColor!: string

  @Prop({ default: false })
  private readonly disabled!: boolean
}
