

















































import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { ownersModule } from '@/stores/owners-store'
import { Owner } from '@/dtos/owners/get'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class OwnerUsersSelectModal extends Vue {
  @Prop()
  private readonly selectedOwnerUserId?: string

  @Model('input', { required: true })
  private readonly isModalVisible!: boolean

  // 検索時にコンポーネントに検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!: string

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!: string

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  private get _isModalVisible(): boolean { return this.isModalVisible }
  private set _isModalVisible(newValue: boolean) {
    this.$emit('input', newValue)
  }

  @Watch('isModalVisible', { immediate: true, deep: true })
  private fetchSelectedUser(isModalVisible: boolean): void {
    if (isModalVisible && this.selectedOwnerUserId) {
      this.selectedUpdateUserId = [this.selectedOwnerUserId]
    }
  }

  private get owners(): Owner[] { return ownersModule.ownersGet.owners }

  headers: Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '112px' }),
    new Header({ text: '氏名', value: 'userName', sortable: false }),
  ]

  // 住戸番号列の昇順／降順の切替えを数値として行うために指定するオブジェクト
  customSorts = {
    roomNumber: {
      sortMethod: (a: Owner, b: Owner): number => {
        const aNumStr = a.roomNumber.match(/\d+/)
        const aNum = aNumStr ? parseInt(aNumStr.toString()) : 0
        const bNumStr = b.roomNumber.match(/\d+/)
        const bNum = bNumStr ? parseInt(bNumStr.toString()) : 0

        return aNum - bNum
      }
    }
  }

  // SmTableDataの返却値（配列）を格納するための変数。当モーダルは単一選択のため返却値は一つのみ。
  selectedUpdateUserId: string[] = []

  onSearch(): void {
    this._keyword = this._inputText
  }

  passSelectedUser():void {
    const selectedUser = this.owners.find(({ userId }) => userId === this.selectedUpdateUserId[0])
    this.$emit('select', selectedUser)
    this._isModalVisible = false
  }
}
