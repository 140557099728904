



































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Route, NavigationGuardNext } from 'vue-router'

import { convertIndexIntoSerialChar } from '@/libs/add-alphabet-provider'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'
import { staticKeyProvider } from '@/libs/static-key-provider'
import { generateUuid } from '@/libs/uuid-generator'
import { windowOpen } from '@/libs/window-open'

import { MATERIAL_TYPES, QUESTIONNAIRE_QUESTION_ELEMENT_TYPES, QUESTIONNAIRE_QUESTION_TYPES, SECTION_TYPES, TEMPLATE_STATE } from '@/constants/schema-constants'
import type { QuestionnaireQuestionType } from '@/constants/schema-constants'
import { PAGE_TYPES } from '@/constants/ux-constants'
import type { PageType } from '@/constants/ux-constants'

import { Material, MaterialFormInputDto } from '@/dtos/commons'
import { QuestionnaireQuestionElementBase, QuestionnaireQuestionElementForPreview, QuestionnaireQuestionBase, QuestionnaireQuestionForPreview, QuestionnaireSectionBase, QuestionnaireSectionForPreview } from '@/dtos/questionnaires/commons'
import { QuestionnaireTemplateDetailGetRequest, QuestionnaireTemplateDetailGetResponse } from '@/dtos/questionnaires/templates/get-detail'
import { QuestionnaireTemplatePostRequest, QuestionnaireTemplateQuestion, QuestionnaireTemplateQuestionElement, QuestionnaireTemplateSection } from '@/dtos/questionnaires/templates/post'
import { QuestionnaireTemplatePutRequest } from '@/dtos/questionnaires/templates/put'
import { QuestionnaireTemplateDraftDeleteRequest } from '@/dtos/questionnaires/templates/draft/delete'
import { QuestionnaireTemplateDraftPostRequest } from '@/dtos/questionnaires/templates/draft/post'
import { QuestionnairePreviewContent, newTabLocalParamStorageModule } from '@/stores/new-tab-local-param-storage-store'
import { questionnaireTemplatesModule } from '@/stores/questionnaire-templates-store'
import { staticRoutes } from '@/routes'

function sectionFactory(base:QuestionnaireSectionBase, sortOrderNum:number):QuestionnaireTemplateSection {
  const section = new QuestionnaireTemplateSection()
  section.sectionType = base.sectionType
  section.sectionTitle = base.sectionTitle
  section.sectionExplanation = base.sectionExplanation
  section.sortOrderNum = sortOrderNum + 1
  section.material = base.material
  section.templateQuestions = base.questions.map((baseQuestion, questionIndex) => {
    const question = new QuestionnaireTemplateQuestion()
    question.templateQuestionId = baseQuestion.templateQuestionId
    question.questionType = baseQuestion.questionType
    question.questionSentence = baseQuestion.questionSentence
    question.questionRequired = baseQuestion.questionRequired
    question.sortOrderNum = questionIndex + 1
    question.questionElements = baseQuestion.questionElements
    return question
  })
  return section
}

class FormInputs {
  templateName!: string
  usageNote?: string
  questionnaireTitle!: string
  questionnaireExplanation!: string
  material?: Material | null = null
}

interface PageTypeSpecifications {
  title: string
  dialogExecuteBtnLabel: string
  executeBtnLabel: string
  created:(templateId?: string) => Promise<void>
  confirmDialogMessage:(isQuestionnaireEdited?: boolean) => string
  onClickExecute:(
    inputs:FormInputs,
    answerSections:QuestionnaireSectionBase[],
    opinionSection:QuestionnaireSectionBase | null) => Promise<void>
  getTemplateId:() => string
}

class CreateSpecifications implements PageTypeSpecifications {
  title = 'テンプレートを新規作成する'
  dialogExecuteBtnLabel = '保存する'
  executeBtnLabel = '新規作成する'
  confirmDialogMessage() { return 'テンプレートとして保存します。よろしいですか？' }
  async created(templateId?: string): Promise<void> {
    if (templateId) { await questionnaireTemplatesModule.fetchQuestionnaireTemplateDetail(new QuestionnaireTemplateDetailGetRequest(templateId)) }
  }

  async onClickExecute(inputs: FormInputs, answerSections: QuestionnaireSectionBase[], opinionSection: QuestionnaireSectionBase | null):Promise<void> {
    const state = questionnaireTemplatesModule.questionnaireTemplateDetailGet.templateState
    const id = questionnaireTemplatesModule.questionnaireTemplateDetailGet.templateId
    const rawReq = Object.assign(new QuestionnaireTemplatePostRequest(), inputs)
    // 登録のためにセクションデータをまとめ、表示順を採番
    const sections = [...answerSections]
    if (opinionSection) sections.push(opinionSection)
    if (state === TEMPLATE_STATE.DRAFT && id) rawReq.templateId = questionnaireTemplatesModule.questionnaireTemplateDetailGet.templateId
    rawReq.sections = sections.map((e, i) => sectionFactory(e, i))
    const fileUploader = new FileUploader()
    const req = await fileUploader.prepare(rawReq)
    await questionnaireTemplatesModule.postQuestionnaireTemplate(req)
  }

  getTemplateId():string {
    return questionnaireTemplatesModule.questionnaireTemplatePost.templateId
  }
}

class UpdateSpecifications implements PageTypeSpecifications {
  title = 'テンプレートを編集する'
  dialogExecuteBtnLabel = '更新する'
  executeBtnLabel = '更新する'
  templateId: string
  version?: number
  constructor(templateId: string) {
    this.templateId = templateId
  }

  async created(): Promise<void> {
    await questionnaireTemplatesModule.fetchQuestionnaireTemplateDetail(new QuestionnaireTemplateDetailGetRequest(this.templateId))
    this.version = questionnaireTemplatesModule.questionnaireTemplateDetailGet.version
  }

  confirmDialogMessage(isQuestionnaireEdited?:boolean) {
    if (isQuestionnaireEdited) return '追加・削除を行ったセクションまたは回答は、過去のものと比較できなくなります。テンプレートを更新してもよろしいですか？'
    return 'テンプレートを更新します。よろしいですか？'
  }

  async onClickExecute(inputs: FormInputs, answerSections: QuestionnaireSectionBase[], opinionSection: QuestionnaireSectionBase | null):Promise<void> {
    if (!this.version) throw new Error() // unexpected
    const rawReq = Object.assign(new QuestionnaireTemplatePutRequest(), inputs)
    rawReq.templateId = this.templateId
    rawReq.version = this.version
    // 登録のためにセクションデータをまとめ、表示順を採番
    const sections = [...answerSections]
    if (opinionSection) sections.push(opinionSection)
    rawReq.sections = sections.map((e, i) => {
      const section = new QuestionnaireTemplateSection()
      section.templateSectionId = e.templateSectionId
      section.sectionType = e.sectionType
      section.sectionTitle = e.sectionTitle
      section.sectionExplanation = e.sectionExplanation
      section.sortOrderNum = i + 1
      section.material = e.material
      section.templateQuestions = e.questions.map((baseQuestion, questionIndex) => {
        const question = new QuestionnaireTemplateQuestion()
        question.templateQuestionId = baseQuestion.templateQuestionId
        question.questionType = baseQuestion.questionType
        question.questionSentence = baseQuestion.questionSentence
        question.questionRequired = baseQuestion.questionRequired
        question.sortOrderNum = questionIndex + 1
        question.questionElements = baseQuestion.questionElements.map(baseElement => {
          const element = new QuestionnaireTemplateQuestionElement()
          element.templateQuestionElementId = baseElement.templateQuestionElementId
          element.elementType = baseElement.elementType
          element.choice = baseElement.choice
          element.answerPlaceholder = baseElement.answerPlaceholder
          element.sortOrderNum = baseElement.sortOrderNum
          return element
        })
        return question
      })
      return section
    })
    const fileUploader = new FileUploader()
    const req = await fileUploader.prepare(rawReq)
    await questionnaireTemplatesModule.putQuestionnaireTemplate(req)
  }

  getTemplateId():string {
    return this.templateId
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardQuestionnaireSection: () => import('@/components/molecules/card/SmCardQuestionnaireSection.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogCheckbox: () => import('@/components/organisms/dialog/SmDialogCheckbox.vue'),
    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmInterceptor: () => import('@/components/organisms/SmInterceptor.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')

  }
})
export default class TemplatePostPage extends Vue {
  @Prop()
  private readonly templateId?: string

  @Prop({ required: true, default: PAGE_TYPES.CREATE })
  private readonly pageType!: PageType

  async created():Promise<void> {
    await this.typeSpecs.created(this.templateId)
    switch (this.pageType) {
      case PAGE_TYPES.CREATE: {
        if (this.templateId) { // 複製・下書きから新規作成する場合
          this.setReplicateData()
        } else {
          // 初期情報設定
          this.addAnswerSection()
          this.createOpinionSection()
          questionnaireTemplatesModule.clearQuestionnaireTemplateDetail()
        }
        break
      }
      case PAGE_TYPES.EDIT: {
        // テンプレート詳細から値を取得
        this.inputs = Object.assign(new FormInputs(), this.detail)
        const answerSections = this.detail.sections.filter(e => e.sectionType === SECTION_TYPES.QUESTION)
        this.answerSections = answerSections.map(e => {
          const section = Object.assign(new QuestionnaireSectionBase(), e)
          section.questions = e.templateQuestions
          return section
        })
        const opinionSection = this.detail.sections.find(e => e.sectionType === SECTION_TYPES.OPINION)
        if (opinionSection) {
          this.opinionSection = Object.assign(new QuestionnaireSectionBase(), opinionSection)
          this.opinionSection.questions = opinionSection.templateQuestions
        }
        break
      }
      default: assertExhaustive(this.pageType)
    }
  }

  private get typeSpecs(): PageTypeSpecifications {
    if (!this.templateId) return new CreateSpecifications()
    switch (this.pageType) {
      case PAGE_TYPES.CREATE: return new CreateSpecifications()
      case PAGE_TYPES.EDIT: return new UpdateSpecifications(this.templateId)
      default: return assertExhaustive(this.pageType)
    }
  }

  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
  PAGE_TYPES = Object.freeze(PAGE_TYPES)

  // --------------フォーム------------
  inputs = new FormInputs()
  get detail():QuestionnaireTemplateDetailGetResponse {
    return questionnaireTemplatesModule.questionnaireTemplateDetailGet
  }

  private get isDraft(): boolean { return this.detail?.templateState === TEMPLATE_STATE.DRAFT }

  // 回答セクション
  answerSections:QuestionnaireSectionBase[] = []

  // 意見セクション
  opinionSection:QuestionnaireSectionBase | null = null

  // 素材IDと取得済み参照用URLを含む素材情報の組み合わせを保持
  materialReferenceURLMap: Map<string, MaterialFormInputDto> = new Map()

  // -------------ダイアログメッセージ------------
  isQuestionnaireEdited = false
  // セクション部分で追加・削除が発生した際に変更フラグを立てる
  private onContaminate():void {
    this.isQuestionnaireEdited = true
  }

  // --------------下書き---------------
  isDeleteDraftDialogVisible = false
  async onClickDeleteDraft():Promise<void> {
    this.isDeleteDraftDialogVisible = false
    if (!this.isDraft || !this.templateId) return
    await this._deleteDraft()
    this.processCompleted = true
    this.$router.push({ name: staticRoutes.questionnairesList.name })
  }

  isSaveDraftDialogVisible = false
  async onClickSaveDraft():Promise<void> {
    this.isSaveDraftDialogVisible = false
    await this.onClickExecuteDraft(this.inputs, this.answerSections, this.opinionSection)
    this.processCompleted = true
    this.$router.push({ name: staticRoutes.questionnairesList.name })
  }

  async onClickExecuteDraft(inputs: FormInputs, answerSections: QuestionnaireSectionBase[], opinionSection: QuestionnaireSectionBase | null):Promise<void> {
    const rawReq = Object.assign(new QuestionnaireTemplateDraftPostRequest(), inputs)
    // 登録のためにセクションデータをまとめ、表示順を採番
    const sections = [...answerSections]
    if (opinionSection) sections.push(opinionSection)
    if (this.isDraft) rawReq.templateId = this.templateId
    rawReq.sections = sections.map((e, i) => sectionFactory(e, i))
    const fileUploader = new FileUploader()
    const req = await fileUploader.prepare(rawReq)
    await questionnaireTemplatesModule.postQuestionnaireTemplateDraft(req)
  }

  // --------------登録---------------

  // 設問が一つ以上あるか確認
  private get hasQuestion():boolean {
    const sections = [...this.answerSections]
    if (this.opinionSection) sections.push(this.opinionSection)
    if (sections.length === 0) return false
    return sections.every(s => s.questions.length !== 0)
  }

  registerConfirmDialogVisible = false
  openQuestionnaireTemplateRegisterModal():void {
    this.registerConfirmDialogVisible = true
  }

  async registerTemplate(isTransitionToQuestionnaireRegister:boolean):Promise<void> {
    this.registerConfirmDialogVisible = false
    await this.typeSpecs.onClickExecute(this.inputs, this.answerSections, this.opinionSection)

    this.processCompleted = true
    if (isTransitionToQuestionnaireRegister) {
      this.$router.push({ name: staticRoutes.questionnaireCreate.name, query: { templateId: this.typeSpecs.getTemplateId() } })
    } else {
      this.$router.push({ name: staticRoutes.questionnairesList.name })
    }
  }

  private async _deleteDraft(): Promise<void> {
    if (this.isDraft && this.templateId) await questionnaireTemplatesModule.deleteQuestionnaireTemplateDraft(new QuestionnaireTemplateDraftDeleteRequest(this.templateId))
  }

  // 回答セクション名生成
  getSectionName(index: number): string {
    return `回答セクション${convertIndexIntoSerialChar(index)}`
  }

  // 回答セクション追加
  addAnswerSection():void {
    const newSection = new QuestionnaireSectionBase()
    newSection.sectionType = SECTION_TYPES.QUESTION
    newSection.questions.push(this.createAnswerQuestion(QUESTIONNAIRE_QUESTION_TYPES.FREE, 1))
    newSection.questions.push(this.createAnswerQuestion(QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE, 2))
    newSection.questions.push(this.createAnswerQuestion(QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES, 3))
    this.answerSections.push(newSection)
    this.isQuestionnaireEdited = true
  }

  createAnswerQuestion(questionType: QuestionnaireQuestionType, sortOrderNum: number): QuestionnaireQuestionBase {
    const question = staticKeyProvider.create(QuestionnaireQuestionBase)
    question.questionSentence = ''
    question.questionRequired = true
    question.questionType = questionType
    question.sortOrderNum = sortOrderNum
    switch (questionType) {
      case QUESTIONNAIRE_QUESTION_TYPES.FREE: {
        const element = staticKeyProvider.create(QuestionnaireQuestionElementBase)
        element.sortOrderNum = 1
        element.answerPlaceholder = ''
        element.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.FREE
        question.questionElements.push(element)
        break
      }
      case QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE: {
        question.questionType = QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE
        question.questionElements.push(this.createAnswerQuestionElement(1))
        question.questionElements.push(this.createAnswerQuestionElement(2))
        break
      }
      case QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES: {
        question.questionType = QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES
        question.questionElements.push(this.createAnswerQuestionElement(1))
        question.questionElements.push(this.createAnswerQuestionElement(2))
      }
    }
    return question
  }

  createAnswerQuestionElement(sortOrderNum: number): QuestionnaireQuestionElementBase {
    const option = new QuestionnaireQuestionElementBase()
    option.choice = ''
    option.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.SELECTABLE_OPTION
    option.sortOrderNum = sortOrderNum
    return option
  }

  // セクション更新
  updateSection(newValue:QuestionnaireSectionBase, index:number):void {
    this.answerSections[index] = Object.assign(staticKeyProvider.create(QuestionnaireSectionBase), newValue)
  }

  // セクション削除
  onClickAnswerSectionDelete(index:number):void {
    this.answerSections.splice(index, 1)
    this.isQuestionnaireEdited = true
  }

  // ご意見・ご要望追加
  private createOpinionSection():void {
    this.opinionSection = new QuestionnaireSectionBase()
    this.opinionSection.sectionType = SECTION_TYPES.OPINION
    const opinionQuestion = new QuestionnaireQuestionBase()
    opinionQuestion.questionType = QUESTIONNAIRE_QUESTION_TYPES.FREE
    opinionQuestion.questionSentence = ''
    opinionQuestion.questionRequired = true
    opinionQuestion.sortOrderNum = 99 // 末尾
    const questionElement = staticKeyProvider.create(QuestionnaireQuestionElementBase)
    questionElement.sortOrderNum = 1
    questionElement.answerPlaceholder = ''
    questionElement.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.FREE
    opinionQuestion.questionElements.push(questionElement)
    this.opinionSection.questions.push(opinionQuestion)
    this.isQuestionnaireEdited = true
  }

  // ご意見・ご要望削除
  onClickOpinionSectionDelete():void {
    this.opinionSection = null
    this.isQuestionnaireEdited = true
  }

  // 作成内容破棄確認ダイアログ
  nextRoute: Route | null = null
  processCompleted = false
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (this.processCompleted || this.nextRoute) {
      next()
    } else {
      this.nextRoute = to
      next(false)
    }
  }

  // 複製して新規作成する際の回答セクション追加
  setReplicateData(): void {
    this.inputs.templateName = this.detail.templateName
    this.inputs.usageNote = this.detail.usageNote
    this.inputs.questionnaireTitle = this.detail.questionnaireTitle
    this.inputs.questionnaireExplanation = this.detail.questionnaireExplanation
    this.inputs.material = this.detail.material

    this.detail.sections.forEach(s => {
      const section = new QuestionnaireSectionBase()
      section.sectionTitle = s.sectionTitle
      section.sectionExplanation = s.sectionExplanation
      section.sectionType = s.sectionType
      section.material = s.material
      section.questions = s.templateQuestions.map(tq => {
        const question = new QuestionnaireQuestionBase()
        question.questionType = tq.questionType
        question.questionSentence = tq.questionSentence
        question.questionRequired = tq.questionRequired
        question.sortOrderNum = tq.sortOrderNum
        question.questionElements = tq.questionElements.map(qe => {
          const element = new QuestionnaireQuestionElementBase()
          element.answerPlaceholder = qe.answerPlaceholder
          element.choice = qe.choice
          element.elementType = qe.elementType
          element.sortOrderNum = qe.sortOrderNum
          return element
        })
        return question
      })
      if (s.sectionType === SECTION_TYPES.QUESTION) this.answerSections.push(section)
      else this.opinionSection = section
    })
  }

  // --------------プレビュー---------------
  async goToTemplatePreviewPage():Promise<void> {
    const previewContents = new QuestionnairePreviewContent()
    previewContents.questionnaireTitle = this.inputs.questionnaireTitle
    previewContents.explanation = this.inputs.questionnaireExplanation
    if (this.inputs.material) previewContents.material = await this.getPreUploadFile(Object.assign(new MaterialFormInputDto(), this.inputs.material))
    const previewSections = this.answerSections.map(section => { return section })
    if (this.opinionSection) previewSections.push(this.opinionSection)
    if (previewSections.length) {
      previewContents.sections = await Promise.all(previewSections.map(async section => {
        const previewSection = new QuestionnaireSectionForPreview()
        previewSection.sectionId = generateUuid()
        previewSection.sectionTitle = section.sectionTitle
        previewSection.sectionExplanation = section.sectionExplanation
        previewSection.sectionType = section.sectionType
        if (section.material) {
          previewSection.material = await this.getPreUploadFile(Object.assign(new MaterialFormInputDto(), section.material))
        }
        if (section.questions.length) {
          previewSection.questions = section.questions.map(question => {
            const previewQuestion = new QuestionnaireQuestionForPreview()
            previewQuestion.questionId = generateUuid()
            previewQuestion.questionType = question.questionType
            previewQuestion.questionRequired = question.questionRequired
            previewQuestion.questionSentence = question.questionSentence
            previewQuestion.sortOrderNum = question.sortOrderNum
            if (question.questionElements) {
              previewQuestion.questionElements = question.questionElements.map(element => {
                const previewQuestionElement = new QuestionnaireQuestionElementForPreview()
                previewQuestionElement.questionElementId = generateUuid()
                previewQuestionElement.answerPlaceholder = element.answerPlaceholder
                previewQuestionElement.choiceName = element.choice
                previewQuestionElement.elementType = element.elementType
                previewQuestionElement.sortOrderNum = element.sortOrderNum
                return previewQuestionElement
              })
            }
            return previewQuestion
          })
        }
        return previewSection
      })
      )
    }
    const previewContentsId = generateUuid()
    newTabLocalParamStorageModule.setQuestionnairePreviewContent({ key: previewContentsId, questionnairePreviewContent: previewContents })
    windowOpen(staticRoutes.questionnairePreviewTop.path.replace(':id', previewContentsId))
  }

  async getPreUploadFile(material: MaterialFormInputDto): Promise<MaterialFormInputDto | undefined> {
    if (!material.materialId) { // 新たに添付した素材の場合
      // ローカルストレージのサイズの制約上、プレビュー時に素材をそのまま別タブに渡すのが難しいため、ここでアップロードする
      const uploadedMaterial = await uploadMaterial(material)
      if (uploadedMaterial) {
        this.materialReferenceURLMap.set(uploadedMaterial.materialId, uploadedMaterial)
        // 画面に表示中の素材データにもIDを格納することで投稿／編集時に素材を再登録しなくて済むようにする
        material.materialId = uploadedMaterial.materialId
        return uploadedMaterial
      }
    } else if (this.materialReferenceURLMap.get(material.materialId)) { // 一度プレビューした素材を付け替えずに使用する場合
      return this.materialReferenceURLMap.get(material.materialId)
    } else { // すでにDBに登録済みの素材を画面初期表示から一度も付け替えずに使用する場合
      return material
    }
  }

  leaveHere(): void {
    const routeName = this.nextRoute?.name
    const routeParams = this.nextRoute?.params
    if (routeName) this.$router.push({ name: routeName, params: routeParams })
  }
}
