












































import { Component, Vue, Prop } from 'vue-property-decorator'
import { RESOLUTION_STATES } from '@/constants/schema-constants'
import { buildingsModule } from '@/stores/buildings-store'
import { BuildingResolutionDto, BuildingResolutionsGetRequest } from '@/dtos/buildings/resolutions/get'
import { Header } from '@/components/molecules/SmTableData.vue'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})

export default class UnvotedGmResolutionSelectModalContent extends Vue {
  headers : Header[] = [
    new Header({ text: '投稿日時', value: 'postedAt', filterable: false, width: '220px' }),
    new Header({ text: '開催日', value: 'holdingAt', filterable: false, width: '198px' }),
    new Header({ text: '総会決議タイトル', value: 'title', sortable: false }),
  ]

  // textfieldに入力された値
  @Prop({ default: '' })
  private inputText!: string

  // 検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!: string

  // 指定した物件ID
  @Prop({ required: true, default: '' })
  buildingId!: string

  // 選択したID
  @Prop({ default: '' })
  targetId!: string

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  private selectedIds: string[] = []

  private get _selectedIds() { return this.selectedIds }
  private set _selectedIds(newValue: string[]) {
    this.selectedIds = newValue
    this.$emit('select', newValue[0] ?? '') // コンポーネント（SmTableData）の返却値は配列だが、当モーダルは単一選択のため返却値は一つのみ
  }

  async created(): Promise<void> {
    this._selectedIds = [this.targetId]

    await this.fetchUnvotedGmResolutions()
  }

  private async fetchUnvotedGmResolutions(): Promise<void> {
    const resolutionsGetRequest = new BuildingResolutionsGetRequest()
    resolutionsGetRequest.buildingId = this.buildingId
    resolutionsGetRequest.resolutionStates = [RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL, RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT]
    resolutionsGetRequest.hasUnvotedOwners = true
    await buildingsModule.fetchBuildingGmResolutions(resolutionsGetRequest)
  }

  private get gmResolutions(): BuildingResolutionDto[] {
    return buildingsModule.buildingGmResolutionsGet?.resolutions
  }

  search(): void {
    this._keyword = this._inputText
  }
}
