































































import { ReserveFund, RepairPlan } from '@/dtos/repairs/expenses/get'
import { buildingsModule } from '@/stores/buildings-store'
import { ChartData, ChartOptions, ChartTooltipItem } from 'chart.js'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmLegend: () => import('@/components/atoms/SmLegend.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmChart: () => import('@/components/molecules/SmChart.vue'),
  }
})
export default class SmChartRepairPlan extends Vue {
  @Prop({ required: true })
  repairPlan!:RepairPlan

  @Prop({ required: true })
  reserveFund!:ReserveFund

  @Prop()
  min?:number

  @Prop()
  max?:number

  private get chartData():ChartData | undefined {
    if (!this.repairPlan) return
    return {
      labels: this.repairPlan.originals.map(e => e.period),
      datasets: [
        {
          type: 'bar',
          label: '当初修繕計画',
          data: this.repairPlan.originals.map(e => e.amount),
          fill: false,
          lineTension: 0,
          borderColor: '#000000',
          backgroundColor: '#000000',
        },
        {
          type: 'bar',
          label: '修繕費予測',
          data: this.repairPlan.prospects.map(e => e.amount),
          fill: false,
          lineTension: 0,
          borderColor: '#00A2D5',
          backgroundColor: '#00A2D5',
        },
        {
          type: 'bar',
          label: '修繕費実績',
          data: this.repairPlan.performances.map(e => e.amount),
          fill: false,
          lineTension: 0,
          borderColor: '#FCB9B9',
          backgroundColor: '#FCB9B9',
        },
        {
          type: 'line',
          label: '積立額予測',
          data: this.reserveFund.prospects.map(e => e.amount),
          fill: false,
          borderColor: '#007296',
          borderWidth: 1,
          borderJoinStyle: 'miter',
          cubicInterpolationMode: 'monotone',
          pointRadius: 0, // 線上の丸点（ポイント）を非表示にしたい（他にやり方あるかも）
          borderDash: [3, 1], // 実線ではなく点線に変更
        },
        {
          type: 'line',
          label: '積立額実績',
          data: this.reserveFund.performances.map(e => e.amount),
          fill: false,
          borderColor: '#B03131',
          borderWidth: 1,
          pointRadius: 0, // 線上の丸点（ポイント）を非表示にしたい（他にやり方あるかも）
        },
      ]
    }
  }

  commaFormatter = new Intl.NumberFormat('en')

  private get chartOptions(): ChartOptions {
    return {
      responsive: false,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'index',
        callbacks: {
          title: (item: ChartTooltipItem[]):string => {
            const label = item[0].label
            if (!label) return ''
            const yearMonth = buildingsModule.periodToYearMonth(Number(label))
            return `${label}期（${yearMonth}）`
          },
          label: (tooltipItem:ChartTooltipItem):string => {
            // undefinedになっている値はtooltipItemでは'NaN'と扱われる
            if (tooltipItem.value === 'NaN') return ''
            const value = Math.round(Number(tooltipItem.value) / 1000)
            return `\xA5${this.commaFormatter.format(value)}`
          }
        }
        // enabled: false, // マウスオーバー時のツールチップを非表示にする
      },
      // 凡例はchart外で表示
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false, // 縦の目盛り線を非表示に
          },
          ticks: {
            min: this.min,
            max: this.max,
            maxRotation: 0, // 自動的に回転する角度を固定（これがないと斜め表示になったりする）
            minRotation: 0,
          },
        }],
        yAxes: [{
          // id: '1', // 混合グラフで複数の軸を使用したい場合のみ使用
          position: 'left',
          scaleLabel: {
            display: false, // 凡例はSmTextで埋め込み
          },
          ticks: {
            /* 軸の最大値、最小値や刻み幅も手動で設定可能
              suggestedMax: 1000000000,
              suggestedMin: 100000,
              stepSize: 10000000,
              */

            // グラフの軸ラベルを任意の値に変更
            callback: function(label:number):string {
              return (label / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') // 「XXX,XXX」 表記（千円）
              // return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +' 円'; // 「XXX,XXX,XXX円」 表記
            },
          },
        }],
      }
    }
  }
}
