


















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { Section } from '@/dtos/resolutions/gm-resolution/results/commons'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardSectionData: () => import('@/components/molecules/card/SmCardSectionData.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class CardSubjectResult extends Vue {
  @Prop({ required: true, default: '' })
  private readonly subjectTitle!: string

  private get _subjectTitle(): string | undefined { return this.subjectTitle }
  private set _subjectTitle(newValue: string | undefined) {
    this.$emit('update:subjectTitle', newValue)
  }

  @Prop()
  private readonly isPassed?: boolean

  private get _isPassed(): boolean { return this.isPassed ?? true }
  private set _isPassed(newValue: boolean) {
    this.$emit('update:isPassed', newValue ?? true)
  }

  @Prop()
  private readonly resultComment?: string

  private get _resultComment(): string | undefined { return this.resultComment }
  private set _resultComment(newValue: string | undefined) {
    this.$emit('update:resultComment', newValue)
  }

  @Prop({ default: () => [] })
  private readonly sections!: Section[]

  private get _sections(): Section[] { return this.sections.map(s => Object.assign(new Section(), s)) }

  @Prop({ required: true, default: '' })
  private readonly subjectPrefix!: string

  options: RadioOption[] = [
    new RadioOption('可決', true),
    new RadioOption('否決', false)
  ]
}
