import type { QuestionnaireQuestionElementType, QuestionnaireQuestionType, SectionType } from '@/constants/schema-constants'
import { Material } from '@/dtos/commons'
import { Type } from 'class-transformer'

export class QuestionnaireTemplateQuestionElement {
  templateQuestionElementId?: string
  answerPlaceholder?: string
  choice?: string
  sortOrderNum!: number
  elementType!: QuestionnaireQuestionElementType
}

export class QuestionnaireTemplateQuestion {
  templateQuestionId?: string
  questionType!: QuestionnaireQuestionType
  questionSentence!: string
  questionRequired!: boolean
  sortOrderNum!: number
  @Type(() => QuestionnaireTemplateQuestionElement)questionElements: QuestionnaireTemplateQuestionElement[] = []
}

export class QuestionnaireTemplateSection {
  templateSectionId?: string
  sectionType!: SectionType
  sectionTitle?: string
  sectionExplanation?: string
  sortOrderNum!: number
  @Type(() => Material)material?: Material
  @Type(() => QuestionnaireTemplateQuestion)templateQuestions: QuestionnaireTemplateQuestion[] = []
}

export class QuestionnaireTemplatePostRequest {
  templateId?: string
  templateName!: string
  usageNote?: string
  questionnaireTitle!: string
  questionnaireExplanation!: string
  @Type(() => Material)material?: Material
  @Type(() => QuestionnaireTemplateSection)sections: QuestionnaireTemplateSection[] = []
}

export class QuestionnaireTemplatePostResponse {
  templateId!: string
}
