




















































import type { BtnType } from '@/constants/ux-constants'
import { Vue, Component, Prop, Model } from 'vue-property-decorator'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmDialogBase extends Vue {
  @Prop({ default: 'キャンセル' })
  private readonly cancelBtnLabel!: string

  @Prop({ default: false })
  private readonly disableCancelBtn!: boolean

  @Prop()
  private readonly executionBtnLabel?: string

  @Prop()
  private readonly executionBtnType?: BtnType

  @Prop()
  private readonly height?: string

  @Prop({ default: false })
  private readonly isSelectedItemStyle!: boolean

  @Model('change')
  private isVisible!: boolean

  private get contentStyle():string {
    let style = ''
    style += this.height ? `height:${this.height}` : ''
    return style
  }
}
