import { ReservePlanState } from '@/constants/schema-constants'
import { Type } from 'class-transformer'

export class ReservePlan {
  period!:number
  accountingMonth!:string
  targetYear!:number
  estimatedCost?:number
  incomePlan?:number
  incomeRecord?:number
  unitPrice?:number
  reservedAmount?:number
}

export class Price {
  period!:number
  monthlyPrice!:number
}

export class PaymentDetail {
  occupiedArea!:number
  buildingUnitCount!:number
  @Type(() => Price) prices: Price[] = []
}

export class ReservesGetResponse {
  @Type(() => ReservePlan)reservePlan:ReservePlan[] = []
  occupiedArea!:number
  @Type(() => PaymentDetail)paymentPlan:PaymentDetail[] = []
  reservePlanState!:ReservePlanState
}
