/**
 * viewportを設定する
 * @param isMobile モバイル端末かどうか（=> this.$vuetify.breakpoint.mobile）
 * @param fixedWidthForMobile モバイル端末の場合に設定する画面の固定幅
 */
export function setViewport(isMobile:boolean, fixedWidthForMobile:number):void {
  const viewport = document.querySelector("meta[name='viewport']")
  const content = viewport?.getAttribute('content')

  if (isMobile) {
    const ratio = (window.innerWidth / fixedWidthForMobile).toFixed(2) // 画面幅に合わせた拡大率を計算
    const viewportMobile = `width=${fixedWidthForMobile},initial-scale=${ratio}`
    if (content !== viewportMobile) {
      viewport?.setAttribute('content', viewportMobile)
    }
  } else {
    const viewportDefault = 'width=device-width,initial-scale=1.0'
    if (content !== viewportDefault) {
      viewport?.setAttribute('content', viewportDefault)
    }
  }
}
