/**
 * 新規作成画面か更新画面か
 * ※「下書きの編集」は新規作成画面
 */
export const PAGE_TYPES = {
  CREATE: 1,
  EDIT: 2,
} as const
export type PageType = typeof PAGE_TYPES[keyof typeof PAGE_TYPES]

/**
 * 新規作成画面か更新画面か
 * ※「下書きの編集」は新規作成画面
 * 文字列で返却する
 */
export const PAGE_TYPES_STR = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
} as const
export type PageTypeStr = typeof PAGE_TYPES_STR[keyof typeof PAGE_TYPES_STR]

export const btnTypes = [
  'reflection',
  'primary-fix',
  'primary-flex',
  'secondary',
  'secondary-caution'
] as const
export type BtnType = typeof btnTypes[number]

/**
 * チップカラー
 */
export const CHIP_COLORS = {
  WHITE1: 'white1',
  WHITE2: 'white2',
  RED: 'red',
  GREEN: 'green',
  GRAY: 'gray',
  ORANGE: 'orange',
  BLUE: 'blue',
} as const

export type ChipColors =
    typeof CHIP_COLORS[keyof typeof CHIP_COLORS]

/**
 * 曜日
 */
export const DAY_OF_WEEK = [
  '日',
  '月',
  '火',
  '水',
  '木',
  '金',
  '土'
] as const

/**
 * 議題を省略表示する文字数の閾値
 */
export const SUBJECT_ABBR_LENGTH = 6

/*
 * 選択不能な期
 */
export const NOT_SELECTABLE_PERIOD_VALUE = 0

/**
 * 修積PDFの線
 * borderDash:破線
 * 1つ目：実線で表示する範囲（px）
 * 2つ目：空白で表示する範囲（px）
 * [4,2]なら
 * ■■■■□□■■■■□□■■■■□□■■■■□□■■■■□□...
 * と続いていく点線になる
 */
export const RESERVE_PLAN_GRAPH_LINES = [
  { color: '#FC5353', borderDash: [1, 1] },
  { color: '#FC8947', borderDash: [2, 1] },
  { color: '#03C775', borderDash: [3, 1] },
  { color: '#933EFF', borderDash: [5, 1] },
  { color: '#00A2D5', borderDash: [4, 2] },
  { color: '#C9BD11', borderDash: [3, 3] },
]

/**
 * 画面横幅パターン
 */
export const WIDTH_PATTERNS = {
  PRINT_A4: 'A4',
  PRINT_A3: 'A3',
  OWNER: 'owner',
} as const
export type WidthPattern = typeof WIDTH_PATTERNS[keyof typeof WIDTH_PATTERNS]

/**
 * 画面遷移時のアニメーションパターン
 */
export const TRANSITION_PATTERNS = {
  NO_ANIMATION: 'no-animation',
  MOVE_IN: 'move-in',
  MOVE_OUT: 'move-out',
} as const
export type TransitionPattern = typeof TRANSITION_PATTERNS[keyof typeof TRANSITION_PATTERNS]

/**
 * WYSIWYGエディタの種類
 */

export const WYSIWYG_EDITOR_TYPES = [
  'text',
  'annotation'
] as const
export type WysiwygEditorType = typeof WYSIWYG_EDITOR_TYPES[number]

/**
 * エラーメッセージ
 */
export const ERROR_MESSAGES = {
  UNEXPECTED: '予期せぬエラーが発生しました。時間を置いてから再度お試しいただくか、システム管理者へお問い合わせください。',
  SESSION_EXPIRED: '認証がタイムアウトしました。再度ログインしてください。',
  INVALID_USER: 'このアカウントはご利用になれません。システム管理者へ問い合わせください。',
  NOT_IMPLEMENTED: '150件以上の区分所有者を保持する物件に対して本機能は使えません。システム管理者が代わりに実行するので、システム管理者に依頼してください。'// TODO 新GRIPで150件以上の区分所有者を保持する物件に対し、サービス利用停止が可能になった後に削除する
} as const

export const SWITCHABLE_FORM_SHOW_MODE = {
  REGISTER: 'register',
  UPDATE: 'update',
  VIEW: 'view',
} as const
export type SwitchableFormShowMode = typeof SWITCHABLE_FORM_SHOW_MODE[keyof typeof SWITCHABLE_FORM_SHOW_MODE]

/**
 * アンケート機能 グラフの色
 */
export const ENQUETE_CHART_BACKGROUND_COLORS = [
  '#0073A3',
  '#00A2D5',
  '#C9BD11',
  '#C55200',
  '#B22D12',
  '#6A39A8',
  '#545384',
  '#00000080',
]
