






































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { RegisteredPerformance } from '@/dtos/simple-repairs/plans/get'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogClosableSimpleRepairPlanRegistration: () => import('@/components/organisms/dialog/SmDialogClosableSimpleRepairPlanRegistration.vue'),
  }
})
export default class SmDialogSimpleRepairPlanPerformanceRegister extends Vue {
  @Prop({ required: true })
  periods!: number[]

  @Prop()
  registeredPeriods?: number[]

  @Prop({ default: false })
  private readonly value!: boolean

  @Prop({ required: true, default: () => [] })
  private readonly registeredPerformances!: RegisteredPerformance[]

  private get isDialogVisible(): boolean { return this.value }
  private set isDialogVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get displayPeriods(): { value: number, label: string }[] {
    return this.periods.map(value => {
      const period = value
      return { value: period, label: `${period}期` }
    })
  }

  selectedPeriod: number | string = '-'
  repairPlanPerformance: number | null = null
  reserveFundPerformance: number | null = null
  isSelectedPeriod = false

  @Watch('selectedPeriod')
  getRegisteredPerformancesRepairCost(): void {
    this.isSelectedPeriod = true
    this.repairPlanPerformance = this.registeredPerformances.find(rp => rp.period === this.selectedPeriod)?.repairCost ?? null
    this.reserveFundPerformance = this.registeredPerformances.find(rp => rp.period === this.selectedPeriod)?.reservedAmount ?? null
  }

  onClickRegister(): void {
    const period = this.selectedPeriod
    const repairPlanPerformance = this.repairPlanPerformance
    const reserveFundPerformance = this.reserveFundPerformance
    this.$emit('register', period, repairPlanPerformance, reserveFundPerformance)

    this.selectedPeriod = '-'
    this.repairPlanPerformance = null
    this.reserveFundPerformance = null
  }

  onClickDelete(): void {
    const period = this.selectedPeriod
    this.$emit('delete', period)

    this.selectedPeriod = '-'
    this.repairPlanPerformance = null
    this.reserveFundPerformance = null
  }

  onClickClose(): void {
    this.selectedPeriod = '-'
    this.repairPlanPerformance = null
    this.reserveFundPerformance = null
    this.$emit('close')
  }

  // 実績登録済みの期を選択している場合のみ削除するボタンを活性にする（delete-btn-disabledにfalseを渡す）
  get isDisabled(): boolean {
    if (!this.registeredPeriods) return true
    return !this.registeredPeriods.some(period => period === this.selectedPeriod)
  }
}
