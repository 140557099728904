import { buildingsModule } from '@/stores/buildings-store'

// 請求開始期を返す
export function getBillingPeriod(): number {
  if (!buildingsModule.buildingDetailGet) return 1
  const billingDate = new Date(buildingsModule.buildingDetailGet?.billingStartDate)
  let period = billingDate.getFullYear() - buildingsModule.buildingDetailGet.firstPeriodEndYear + 1
  if (billingDate.getMonth() + 1 > buildingsModule.buildingDetailGet.closingMonth) {
    period += 1
  }
  return period
}

// 請求開始期の係数（○ヶ月○日分払うか）を計算して返す（日割り計算用）
export function getFirstPeriodScale(): number {
  if (!buildingsModule.buildingDetailGet) return 1
  const billingDate = new Date(buildingsModule.buildingDetailGet?.billingStartDate)

  // 最初期のみ、決算月までの期間をベースに計算
  // 決算年月を取得
  const [periodYear, periodMonth] = buildingsModule.periodToYearMonth(getBillingPeriod()).split('/').map(e => Number(e))
  // 決算月の1日を取得 ※Date型内部では月は0始まりなので、-1する
  const accountingDate = new Date(periodYear, periodMonth - 1, 1)

  // 丸々加算できる月
  const durationMonth = (accountingDate.getFullYear() - billingDate.getFullYear()) * 12 + accountingDate.getMonth() - billingDate.getMonth()
  // 請求開始日の日割計算
  const billingStartMonthLastDay = new Date(billingDate.getFullYear(), billingDate.getMonth() + 1, 0)
  const durationDay = 1 - (billingDate.getDate() - 1) / billingStartMonthLastDay.getDate()

  return durationMonth + durationDay
}
