





























































import { Component, Prop, Vue } from 'vue-property-decorator'

// アップロードデータ情報エリア表示用クラス
export class UploadedData {
  uploadedAt!: string
  userName!: string
  fileCount!: number
  differenceCount!: number
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})

export default class CollationResultDetailUploadDataArea extends Vue {
  @Prop({ required: true, default: '' })
  readonly uploadedData!: UploadedData
}
