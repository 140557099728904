import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { casysClient } from '@/clients/casys-client'
import { CasysUnitsPostRequest, CasysUnitsPostResponse } from '@/dtos/casys/units/post'
import { CasysUnitsPutRequest } from '@/dtos/casys/units/put'
import { CasysDetectionsGetRequest, CasysDetectionsGetResponse } from '@/dtos/casys/detections/get'
import { CasysDetectionDetailGetRequest, CasysDetectionDetailGetResponse, Building } from '@/dtos/casys/detections/get-detail'
import { CasysDetectionsResultGetRequest, DetectionResult, Difference, InProgressData } from '@/dtos/casys/detections/results/get'

@Module({ store, dynamic: true, namespaced: true, name: 'casysStore' })
class CasysStore extends VuexModule {
    // CASYS差分検出
    private _casysUnitsPost: CasysUnitsPostResponse = new CasysUnitsPostResponse()
    get casysUnitsPost(): CasysUnitsPostResponse { return this._casysUnitsPost }

    @Mutation
    private SET_CASYS_UNITS_POST(res: CasysUnitsPostResponse): void {
      this._casysUnitsPost = res
    }

    @Action
    async uploadCasysUnits(req: CasysUnitsPostRequest): Promise<void> {
      const res = await casysClient.postCasysUnits(req)
      if (res) this.SET_CASYS_UNITS_POST(res)
    }

    @Action
    async updateCasysUnits(req: CasysUnitsPutRequest): Promise<void> {
      await casysClient.putCasysUnits(req)
    }

    // 照合履歴一覧
    private _casysDetectionsGet: CasysDetectionsGetResponse = new CasysDetectionsGetResponse()
    get casysDetectionsGet(): CasysDetectionsGetResponse { return this._casysDetectionsGet }

    @Mutation
    private SET_CASYS_DETECTIONS_GET(res: CasysDetectionsGetResponse): void {
      this._casysDetectionsGet = res
    }

    @Action
    async fetchCasysDetections(req: CasysDetectionsGetRequest): Promise<void> {
      const res = await casysClient.getCasysDetections(req)
      this.SET_CASYS_DETECTIONS_GET(res)
    }

    // 照合履歴詳細
    private _casysDetectionDetailGet: CasysDetectionDetailGetResponse = new CasysDetectionDetailGetResponse()
    get casysDetectionDetailGet(): CasysDetectionDetailGetResponse { return this._casysDetectionDetailGet }

    @Mutation
    private SET_CASYS_DETECTION_DETAIL_GET(res: CasysDetectionDetailGetResponse): void {
      this._casysDetectionDetailGet = res
    }

    private _detectionDetailBuildings: Building[] = []
    get detectionDetailBuildings(): Building[] { return this._detectionDetailBuildings }

    @Mutation
    private PUSH_DETECTION_DETAIL_BUILDINGS(buildings: Building[]): void {
      this._detectionDetailBuildings.push(...buildings)
    }

    @Mutation
    private CLEAR_DETECTION_DETAIL_BUILDINGS(): void {
      this._detectionDetailBuildings = []
    }

    @Action
    async fetchCasysDetectionDetail(req: CasysDetectionDetailGetRequest): Promise<void> {
      const res = await casysClient.getCasysDetectionDetail(req)
      this.SET_CASYS_DETECTION_DETAIL_GET(res)
      this.PUSH_DETECTION_DETAIL_BUILDINGS(res.buildings)
    }

    @Action
    clearDetectionDetailBuildings(): void {
      this.CLEAR_DETECTION_DETAIL_BUILDINGS()
    }

    // 差分照合結果
    private _casysDetectionsResults: DetectionResult[] = []
    get casysDetectionsResult(): DetectionResult[] { return this._casysDetectionsResults }

    @Mutation
    private PUSH_CASYS_DETECTIONS_RESULTS(detectionsResults: DetectionResult[]): void {
      this._casysDetectionsResults.push(...detectionsResults)
    }

    @Mutation
    private UPDATE_DETECTION_FIX_OWNER_NAME_FLAG({ difference, isUpdated }: {difference: Difference, isUpdated: boolean}): void {
      difference.isUpdated = isUpdated
    }

    @Mutation
    private CLEAR_CASYS_DETECTIONS_RESULTS(): void {
      this._casysDetectionsResults = []
    }

    private _casysDetectionsInprogressData: InProgressData[] = []
    get casysDetectionsInprogressData(): InProgressData[] { return this._casysDetectionsInprogressData }

    @Action
    pushCasysDetectionsInprogressData(inProgressData: InProgressData[]): void {
      this._casysDetectionsInprogressData.unshift(...inProgressData)
    }

    @Mutation
    private SET_CASYS_DETECTIONS_INPROGRESS_DATA(inProgressData: InProgressData[]): void {
      this._casysDetectionsInprogressData = inProgressData
    }

    @Mutation
    private CLEAR_CASYS_DETECTIONS_INPROGRESS_DATA(): void {
      this._casysDetectionsInprogressData = []
    }

    private _casysDetectionsResultsSkipToken: string | null = null
    get casysDetectionsResultSkipToken(): string | null { return this._casysDetectionsResultsSkipToken }

    @Mutation
    private SET_CASYS_DETECTIONS_RESULTS_SKIP_TOKEN(skipToken: string): void {
      this._casysDetectionsResultsSkipToken = skipToken
    }

    @Mutation
    private CLEAR_CASYS_DETECTIONS_RESULTS_SKIP_TOKEN(): void {
      this._casysDetectionsResultsSkipToken = null
    }

    private _casysDifferenceErrors: string[] | null = null
    get casysDifferenceErrors(): string[] | null { return this._casysDifferenceErrors }

    @Mutation
    private SET_CASYS_DIFFERENCE_ERRORS(casysDifferenceErrors: string[] | null): void {
      this._casysDifferenceErrors = casysDifferenceErrors
    }

    @Mutation
    private CLEAR_CASYS_DIFFERENCE_ERRORS(): void {
      this._casysDifferenceErrors = null
    }

    @Action
    async fetchCasysDetectionsResults(req: CasysDetectionsResultGetRequest): Promise<void> {
      const res = await casysClient.getCasysDetectionsResults(req)
      this.PUSH_CASYS_DETECTIONS_RESULTS(res.results)
      this.SET_CASYS_DETECTIONS_INPROGRESS_DATA(res.inProgressData)
      this.SET_CASYS_DETECTIONS_RESULTS_SKIP_TOKEN(res.skipToken)
      this.SET_CASYS_DIFFERENCE_ERRORS(res.casysDifferenceErrors ?? null)
    }

    @Action
    updateDetectionFixOwnerNameFlag(
      { casysDifferenceDetectionBatchStatusId, condominiumCd, unitId, isUpdated }: { casysDifferenceDetectionBatchStatusId: string, condominiumCd: string, unitId: string, isUpdated: boolean }
    ): void {
      const targetDifference = this._casysDetectionsResults.find(detectionResult => detectionResult.casysDifferenceDetectionBatchStatusId === casysDifferenceDetectionBatchStatusId)
        ?.buildings.find(building => building.building.condominiumCd === condominiumCd)
        ?.differences.find(difference => difference.unitId === unitId)
      if (targetDifference) this.UPDATE_DETECTION_FIX_OWNER_NAME_FLAG({ difference: targetDifference, isUpdated: isUpdated })
    }

    @Action
    clearCasysDetectionResults(): void {
      this.CLEAR_CASYS_DETECTIONS_INPROGRESS_DATA()
      this.CLEAR_CASYS_DETECTIONS_RESULTS()
      this.CLEAR_CASYS_DETECTIONS_RESULTS_SKIP_TOKEN()
      this.CLEAR_CASYS_DIFFERENCE_ERRORS()
    }

    @Action
    clearCasysDifferenceErrors(): void {
      this.CLEAR_CASYS_DIFFERENCE_ERRORS()
    }
}

export const casysModule = getModule(CasysStore)
