

















































































































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { gmResolutionsModule } from '@/stores/gm-resolutions-store'
import { myProfileModule } from '@/stores/my-profile-store'
import { structureModule } from '@/stores/structure-store'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { GMResolutionDetailGetRequest, GMResolutionDetailGetResponse } from '@/dtos/resolutions/gm-resolution/get-detail'
import { HOLDING_METHOD_TYPE, RESOLUTION_STATES } from '@/constants/schema-constants'
import type { HoldingMethodType, ResolutionState } from '@/constants/schema-constants'
import { CHIP_COLORS, ERROR_MESSAGES } from '@/constants/ux-constants'
import { getResolutionLabel } from '@/libs/state-handler'
import { getHoldingMethodTypeLabel } from '@/libs/type-handler'

const EDITABLE_STATES:ResolutionState[] = [
  // 下書きは当画面を使用しないため、考慮不要
  RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL,
  RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT,
]

@Component({
  components: {
    SmBadgeContent: () => import('@/components/atoms/SmBadgeContent.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmListBudget: () => import('@/components/molecules/list/SmListBudget.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
  }
})
export default class DetailSubPage extends Mixins(CurrentAdminManager) {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)
  HOLDING_METHOD_TYPE = Object.freeze(HOLDING_METHOD_TYPE)

  @Prop({ required: true, default: '' })
  private readonly resolutionId!:string

  @Prop()
  private readonly buildingId?: string

  async created(): Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    await gmResolutionsModule.fetchResolutionDetail(new GMResolutionDetailGetRequest(this.resolutionId))
  }

  private get isPreview(): boolean { return structureModule.isPreview }

  private get detail():GMResolutionDetailGetResponse {
    return gmResolutionsModule.detailResponse(this.resolutionId) ?? new GMResolutionDetailGetResponse()
  }

  private get stateLabel():string {
    return getResolutionLabel(this.detail.resolutionState)
  }

  private get stateType():string {
    return this.detail.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.COUNTING || this.detail.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.RELEASED ? 'generalMeetingArchive' : 'generalMeeting'
  }

  private holdingMethodTypeDisplay(holdingMethodType:HoldingMethodType): string {
    return getHoldingMethodTypeLabel(holdingMethodType)
  }

  // 管理者業務執行者、且つ決議の投稿者のみ編集可能
  private get hasEditAuthority():boolean {
    return this.$isServiceStaff
  }

  // 権限を持ち、かつ「質問・意思表明受付中、意思表明受付中」のタイミングでのみ編集可能（下書きは当画面を使用しないため、下書きの考慮は不要）
  private get isEditable():boolean {
    return this.hasEditAuthority && EDITABLE_STATES.includes(this.detail.resolutionState)
  }

  onClickEdit():void {
    if (this.isPreview) return
    this.$router.push({ name: staticRoutes.gmResolutionUpdate.name, params: { resolutionId: this.resolutionId } })
  }

  /**
   * 決議のもととなった情報を表示するため、賛同意見受付画面へ遷移
   */
  private goToBaseIdea(ideaId: string):void {
    if (this.isPreview) return
    this.$router.push({ name: staticRoutes.adminIdeaDetail.name, params: { ideaId: ideaId } })
  }
}
