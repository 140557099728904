import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'

@Module({ store, dynamic: true, namespaced: true, name: 'errorsStore' })
class ErrorsStore extends VuexModule {
  /**
   * 次に処理するAPIレスポンスのフィールドエラーの各項目に、このprefixを付与する
   */
  private _fieldErrorPrefix = ''

  /**
   * キーとしてエラーの対象項目名、値としてメッセージの配列を保有.
   * サーバから返されるエラーは、キーがリクエストオブジェクトの項目名に沿って付けられている.
   * 以下の例を参考に、必要に応じて presetResponseFieldErrorPrefix を使って調整し、
   * それを各テキストエリア／フィールドのfield-idとして設定しておくことで参照構造を作る.
   * エラー表示が不要になる際は、個別に clearSingleFieldError を呼び出してリセットする.
   *
   * e.g.
   * リクエストオブジェクト: { hoge: 'xxxx', fuga: { fugaChild: 'yyyy' }, piyo: [{ piyoElement: 'zzz' }] }
   * ↓ ↓ ↓
   * fieldErrors: {
   *   hoge: ['~~~~'],
   *   fuga.fugaChild: ['~~~~'],
   *   piyo[0].piyoElement: ['~~~~'],
   * }
   */
  private _fieldErrors: { [key: string]: string[] } = {}
  private _globalErrors: string[] = []

  get fieldErrors() {
    return (fieldId: string): string[] => {
      return this._fieldErrors[fieldId]
    }
  }

  get globalErrors(): string[] {
    return this._globalErrors
  }

  get hasErrors(): boolean {
    return Object.keys(this._fieldErrors).length > 0 || this._globalErrors.length > 0
  }

  @Mutation
  private SET_FIELD_ERRORS(fieldErrors: { [key: string]: string[] } = {}): void {
    const prefix = this._fieldErrorPrefix
    if (prefix.length) {
      this._fieldErrors = Object.entries(fieldErrors).reduce((acc:{ [key: string]: string[] }, [_key, _value]) => {
        acc[`${prefix}${_key}`] = _value
        return acc
      }, {})
    } else {
      this._fieldErrors = fieldErrors
    }
  }

  @Mutation
  private SET_GLOBAL_ERRORS(globalErrors: string[] = []): void {
    this._globalErrors = globalErrors
  }

  @Mutation
  private SET_FIELD_ERROR_PREFIX(prefix = ''): void {
    this._fieldErrorPrefix = prefix
  }

  @Mutation
  private CLEAR_FIELD_ERROR(fieldId: string): void {
    Vue.delete(this._fieldErrors, fieldId)
  }

  @Mutation
  private CLEAR_ALL_FIELD_ERROR(): void {
    this._fieldErrors = {}
  }

  @Action
  setErrors(errors: { fieldErrors: { [key: string]: string[] }, globalErrors: string[] }): void {
    this.SET_FIELD_ERRORS(errors.fieldErrors)
    this.SET_GLOBAL_ERRORS(errors.globalErrors)
  }

  @Action
  setGlobalErrors(globalErrors: string[]): void {
    this.SET_GLOBAL_ERRORS(globalErrors)
  }

  @Action
  presetResponseFieldErrorPrefix(prefix: string): void {
    this.SET_FIELD_ERROR_PREFIX(prefix)
  }

  @Action
  clearResponseFieldErrorPrefix(): void {
    this.SET_FIELD_ERROR_PREFIX()
  }

  @Action
  clearGlobalErrors(): void {
    this.SET_GLOBAL_ERRORS([])
  }

  @Action
  clearSingleFieldError(filedId: string): void {
    this.CLEAR_FIELD_ERROR(filedId)
  }

  @Action
  clearAllFieldError(): void {
    this.CLEAR_ALL_FIELD_ERROR()
  }
}

export const errorsModule = getModule(ErrorsStore)
