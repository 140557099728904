






























import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator'
import { repairsModule } from '@/stores/repairs-store'
import { LogsGetResponseLog } from '@/dtos/repairs/updates/logs/get'
import { Header } from '@/components/molecules/SmTableData.vue'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class UpdatesLogsSelectModal extends Vue {
  @Model('change')
  private readonly isModalVisible!: boolean

  @Prop({ default: '' })
  private selectedLogId!: string

  private get _isModalVisible(): boolean { return this.isModalVisible }
  private set _isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  updateLogId:string[] = []

  @Watch('selectedLogId', { immediate: true })
  private fetchLogId(): void {
    // テーブルではバインドする値を配列にする必要があるため変換する
    if (this.selectedLogId) this.updateLogId = [this.selectedLogId]
  }

  private get repairsUpdatesLogs(): LogsGetResponseLog[] { return repairsModule.repairsUpdatesLogsGet.logs }

  private get isUpdateLogId(): boolean { return this.updateLogId[0] === undefined }

  headers: Header[] = [
    new Header({ text: '更新日時', value: 'updatedAt', sortable: false, filterable: false, width: '220px' }),
    new Header({ text: '担当者', value: 'userName', sortable: false, width: '250px' }),
    new Header({ text: 'ファイル', value: 'fileName', sortable: false, filterable: false }),
  ]

  passSelectedLogId():void {
    this.$emit('select', this.updateLogId[0])
    this._isModalVisible = false
  }
}
