import { Type } from 'class-transformer'
import { Material, User } from '@/dtos/commons'
import { Details } from '@/dtos/resolutions/commons'
import { Subject } from '@/dtos/resolutions/gm-resolution/results/commons'
import type { ResolutionState } from '@/constants/schema-constants'

export class Result {
  resultComment?: string
  @Type(() => Material) material?: Material
  @Type(() => Subject) subjects: Subject[] = []
}

export class ResultsGetResponse {
  resolutionId!: string
  resolutionState!: ResolutionState
  postedAt!: string
  title!: string
  @Type(() => User) user: User = new User()
  @Type(() => Details) details: Details = new Details()
  @Type(() => Result) results: Result = new Result()
  version!: number
}
