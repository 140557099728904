import { Type } from 'class-transformer'

export class UnregisteredExecutor {
  unregisteredExecutorId?: string
  unregisteredExecutorName?: string
}

export class ManagementOperationsUnregisteredExecutorGetResponse {
  @Type(() => UnregisteredExecutor) executors: UnregisteredExecutor[] = []
}
