import * as ExcelJs from 'exceljs'
import { ExcelParserBase } from './excel-parser-base'
import { ChoiceSection, InputSection, OpinionSection, QuestionnaireInternalReportPostResponse } from '@/dtos/questionnaires/reports/internal/post'
import { QuestionnaireTemplateDetailGetResponse, QuestionnaireTemplateQuestion } from '@/dtos/questionnaires/templates/get-detail'
import { QUESTIONNAIRE_QUESTION_TYPES, SECTION_TYPES } from '@/constants/schema-constants'

class QuestionnaireExcelParser extends ExcelParserBase {
  private readonly _buildingInfoEndColumnIdx = 6 // F列（マンション情報の終了列）
  async downloadAsExcel(data: QuestionnaireInternalReportPostResponse, template: QuestionnaireTemplateDetailGetResponse): Promise<void> {
    const fileName = `社内向け報告書_${template.templateName}`

    await this.toExcel(fileName, (sheet: ExcelJs.Worksheet) => {
      let inputStartRowIdx = 1
      let opinionStartRowIdx = 1

      // シートの設定
      sheet.views = [{ zoomScale: 70 }]
      sheet.properties.defaultColWidth = 10
      sheet.getColumn(1).width = 3; sheet.getColumn(2).width = 20; sheet.getColumn(3).width = 14; sheet.getColumn(4).width = 14; sheet.getColumn(5).width = 30; sheet.getColumn(7).width = 14

      // 選択式
      if (data.choiceSections) {
        const choiceQuestions = template.sections.flatMap(ts => ts.templateQuestions.filter(tq => tq.questionType === QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE || tq.questionType === QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES))
        const choiceElementCount = this._getChoiceElementCount(template)
        const choiceRowCount = this._getChoiceRowCount(data.choiceSections)
        const choiceStartColumnIdx = 11 // K列から設問名・結果の入力開始
        const choiceStartRowIdx = 5
        const endColumnIdx = choiceElementCount + 10
        const endResultRowIdx = choiceRowCount + 4
        const sumRowIdx = choiceRowCount + 6

        inputStartRowIdx += choiceRowCount + 7; opinionStartRowIdx += choiceRowCount + 7

        // タイトル
        sheet.addRows([['', '回答結果(選択式)'], []])
        sheet.getRow(1).font = { size: 24 }

        // ヘッダー
        sheet.addRows(this._formatChoiceHeader(choiceQuestions))
        this.mergeByRange(sheet, false, ...this._getChoiceHeaderMergeRange(choiceQuestions, choiceStartColumnIdx))
        this.borderizeByRange(sheet, 'thin', `B3:${this.idxToAlpha(endColumnIdx)}4`)
        this._fillAndChangeFont(sheet, 4, false)
        this._fillAndChangeFont(sheet, 3, true)

        // 結果エリア
        sheet.addRows(this._formatChoiceResultSummary(data.choiceSections))
        this.addChoiceResultValue(sheet, data.choiceSections, template, choiceStartColumnIdx, choiceStartRowIdx)
        this.borderizeByRange(sheet, 'thin', `G5:J${endResultRowIdx}`)
        this.borderizeByRange(sheet, 'dotted', `K5:${this.idxToAlpha(endColumnIdx)}${endResultRowIdx}`)
        this.surroundByRange(sheet, 'thin', ...this._getChoiceSurroundRange(data.choiceSections, choiceStartRowIdx, choiceQuestions, endResultRowIdx, choiceStartColumnIdx))
        // 結果エリアの回答率が左に寄ってしまうため、右寄せにする。
        sheet.getColumn('J').eachCell(cell => { cell.alignment = Object.assign(cell.alignment || {}, { horizontal: 'right' }) })

        // 合計エリア
        sheet.addRows([[''], this._formatSumResult(data.choiceSections, template), ['']])
        this.borderizeByRange(sheet, 'thin', `G${sumRowIdx}:J${sumRowIdx}`)
        this.borderizeByRange(sheet, 'dotted', `K${sumRowIdx}:${this.idxToAlpha(endColumnIdx)}${sumRowIdx}`)
        this.surroundByRange(sheet, 'thin', ...this._getChoiceSumSurroundRange(choiceQuestions, sumRowIdx, choiceStartColumnIdx))
        sheet.getCell(`J${sumRowIdx}`).numFmt = '0%'

        // カラースケール
        const colorCells = this._getColorRanges(data.choiceSections, choiceStartRowIdx, choiceQuestions, sumRowIdx, choiceStartColumnIdx)
        this._addColorScale(sheet, colorCells)
      }

      // 入力式
      if (data.inputSections) {
        const inputQuestionCount = this._getInputQuestionCount(template)
        const inputRowCount = this._getInputRowCount(data.inputSections)
        const inputSentenceColumnCount = 6 // 入力式設問1つの列数
        const endColumnIdx = inputQuestionCount * inputSentenceColumnCount + 8
        const headerRow = inputStartRowIdx + 2
        const answerStartRow = inputStartRowIdx + 3

        opinionStartRowIdx += inputRowCount + 4

        // タイトル
        sheet.addRows([['', '回答結果(入力式)'], ['']])
        sheet.getRow(inputStartRowIdx).font = { size: 24 }

        // ヘッダー
        sheet.addRow(this._formatInputHeader(template))
        this.mergeByRange(sheet, false, ...this._getInputHeaderMergeRange(template, inputQuestionCount, headerRow, inputSentenceColumnCount))
        this.borderizeByRange(sheet, 'thin', `B${headerRow}:${this.idxToAlpha(endColumnIdx)}${headerRow}`)
        this._fillAndChangeFont(sheet, headerRow, true)

        // 回答エリア
        const inputSectionRows = this._formatInputResult(data.inputSections)
        inputSectionRows.forEach(isr => sheet.addRows(isr))
        this.mergeByRange(sheet, false, ...this._getInputAnswerMergeRange(template, inputQuestionCount, answerStartRow, inputRowCount, inputSentenceColumnCount))
        this.borderizeByRange(sheet, 'thin', `G${answerStartRow}:${this.idxToAlpha(endColumnIdx)}${answerStartRow + inputRowCount - 1}`)
        this.surroundByRange(sheet, 'thin', ...this._getInputSurroundRange(data.inputSections, answerStartRow))

        // 折り返しの設定
        this._getWrapTextRange(answerStartRow, inputQuestionCount, inputRowCount).forEach(cell => { sheet.getCell(cell).alignment = { wrapText: true } })
        sheet.addRow('')
      }

      // ご意見・ご要望
      if (data.opinionSections) {
        const opinionRowCount = this._getOpinionRowCount(data.opinionSections)
        const headerRow = opinionStartRowIdx + 2
        const answerStartRow = opinionStartRowIdx + 3

        // タイトル
        sheet.addRows([['', 'ご意見・ご要望'], ['']])
        sheet.getRow(opinionStartRowIdx).font = { size: 24 }

        // ヘッダー
        sheet.addRow(this.commonColumn.concat(['区分所有者', 'ご意見・ご要望']))
        this.mergeByRange(sheet, false, `I${headerRow}:N${headerRow}`)
        this.borderizeByRange(sheet, 'thin', `B${headerRow}:I${headerRow}`)
        this._fillAndChangeFont(sheet, headerRow, true)

        // ご意見エリア
        sheet.addRows(this._formatOpinionResult(data.opinionSections))
        this.mergeByRange(sheet, false, ...this._getOpinionAnswerMergeRange(answerStartRow, opinionRowCount))
        this.borderizeByRange(sheet, 'thin', `G${answerStartRow}:I${answerStartRow + opinionRowCount - 1}`)
        this.surroundByRange(sheet, 'thin', ...this._getOpinionSurroundRange(data.opinionSections, answerStartRow))

        // 折り返しの設定
        this._getWrapTextRange(answerStartRow, 1, opinionRowCount).forEach(cell => { sheet.getCell(cell).alignment = { wrapText: true } })
      }

      // 最後に全てのセルに対してフォントとフォントサイズと文字位置を設定する
      this.setDefaultFont(sheet, 12, '游ゴシック')
      sheet.eachRow(row => row.eachCell(cell => {
        // ヘッダー以外の文字位置を中央に設定
        if (!cell.alignment || (cell.alignment && cell.alignment.vertical !== 'top')) cell.alignment = Object.assign(cell.alignment || {}, { vertical: 'middle' })
      }))
    })
  }

  private get commonColumn(): string[] {
    return ['', 'マンションエリア', 'マンションNo', '稼働開始時期', 'マンション名', '決算月', '回答締切日']
  }

  private _formatChoiceHeader(choiceQuestions: QuestionnaireTemplateQuestion[]): ExcelJs.CellValue[][] {
    const header = this.commonColumn.concat(['回答情報', '', ''])
    const secondHeader = ['', '', '', '', '', '', '', '戸数', '回答者数', '回答率']
    // 選択肢の設問を抽出
    choiceQuestions.forEach(cq => {
      header.push(cq.questionSentence)
      cq.questionElements.forEach(qe => {
        header.push('')
        if (qe.choice) secondHeader.push(qe.choice)
      })
      header.pop()
    })
    return [
      header,
      secondHeader
    ]
  }

  private _getChoiceHeaderMergeRange(choiceQuestions: QuestionnaireTemplateQuestion[], choiceStartColumnIdx: number): string[] {
    const range = ['B3:B4', 'C3:C4', 'D3:D4', 'E3:E4', 'F3:F4', 'G3:G4', 'H3:J3']
    let columnNumber = choiceStartColumnIdx
    choiceQuestions.forEach(cq => {
      const elemntCount = cq.questionElements.length
      range.push(`${this.idxToAlpha(columnNumber)}3:${this.idxToAlpha(columnNumber + elemntCount - 1)}3`)
      columnNumber += elemntCount
    })
    return range
  }

  private _getInputHeaderMergeRange(template: QuestionnaireTemplateDetailGetResponse, inputQuestionCount: number, inputHeaderRowIdx: number, inputSentenceColumnCount:number): string[] {
    const range = []
    const startSentenceColumn = 9
    let column = startSentenceColumn

    for (let i = 0; i < inputQuestionCount; i++) {
      range.push(`${this.idxToAlpha(column)}${inputHeaderRowIdx}:${this.idxToAlpha(column + inputSentenceColumnCount - 1)}${inputHeaderRowIdx}`)
      column += inputSentenceColumnCount
    }
    return range
  }

  private _getInputAnswerMergeRange(template: QuestionnaireTemplateDetailGetResponse, inputQuestionCount: number, inputAnswerRowIdx: number, inputRowCount: number, inputSentenceColumnCount:number): string[] {
    const range = []
    const startSentenceColumn = 9

    for (let i = inputAnswerRowIdx; i < inputAnswerRowIdx + inputRowCount; i++) {
      let column = startSentenceColumn
      for (let j = 0; j < inputQuestionCount; j++) {
        range.push(`${this.idxToAlpha(column)}${i}:${this.idxToAlpha(column + inputSentenceColumnCount - 1)}${i}`)
        column += inputSentenceColumnCount
      }
    }
    return range
  }

  private _getOpinionAnswerMergeRange(answerStartRowIdx: number, opinionRowCount: number): string[] {
    const range = []
    for (let i = 0; i < opinionRowCount; i++) range.push(`I${answerStartRowIdx + i}:N${answerStartRowIdx + i}`)
    return range
  }

  private _getChoiceSurroundRange(choiceSections: ChoiceSection[], choiceStartRowIdx: number, choiceQuestions: QuestionnaireTemplateQuestion[], endResultRowIdx: number, choiceStartColumnIdx: number): string[] {
    const range: string[] = []
    let rowNumber = choiceStartRowIdx

    // 概要エリア
    choiceSections.forEach(cs => {
      const rowCount = cs.questionSummaries.length
      for (let i = 2; i <= this._buildingInfoEndColumnIdx; i++) range.push(`${this.idxToAlpha(i)}${rowNumber}:${this.idxToAlpha(i)}${rowNumber + rowCount - 1}`)
      rowNumber += rowCount
    })

    // 結果エリア
    let columnNumber = choiceStartColumnIdx
    choiceQuestions.forEach(cq => {
      const elemntCount = cq.questionElements.length
      range.push(`${this.idxToAlpha(columnNumber)}${choiceStartRowIdx}:${this.idxToAlpha(columnNumber + elemntCount - 1)}${endResultRowIdx}`)
      columnNumber += elemntCount
    })
    return range
  }

  private _getChoiceSumSurroundRange(choiceQuestions: QuestionnaireTemplateQuestion[], sumStartRowIndex: number, choiceStartColumnIdx: number): string[] {
    const range: string[] = []
    let columnNumber = choiceStartColumnIdx
    choiceQuestions.forEach(cq => {
      const elemntCount = cq.questionElements.length
      range.push(`${this.idxToAlpha(columnNumber)}${sumStartRowIndex}:${this.idxToAlpha(columnNumber + elemntCount - 1)}${sumStartRowIndex}`)
      columnNumber += elemntCount
    })
    return range
  }

  private _getInputSurroundRange(inputSections: InputSection[], dataStartRowIdx: number): string[] {
    const range: string[] = []
    let rowNumber = dataStartRowIdx

    // マンションごとの回答数の配列
    const answerCountsByBuilding: number[] = []
    inputSections.forEach(is => {
      let ownerUserIds: string[] = []
      let templateQuestionIds: string[] = []
      let count = 0
      is.inputQuestions.forEach((iq, idx) => {
        iq.inputQuestionElements.forEach(iqe => {
          if (idx === 0 || templateQuestionIds.includes(iq.templateQuestionId) || !ownerUserIds.includes(iqe.ownerUserId)) {
            if (templateQuestionIds.includes(iq.templateQuestionId)) ownerUserIds = []; templateQuestionIds = []
            count += 1
            ownerUserIds.push(iqe.ownerUserId)
          }
        })
        templateQuestionIds.push(iq.templateQuestionId)
      })
      answerCountsByBuilding.push(count)
    })

    answerCountsByBuilding.forEach(count => {
      const rowCount = count
      for (let i = 2; i <= this._buildingInfoEndColumnIdx; i++) range.push(`${this.idxToAlpha(i)}${rowNumber}:${this.idxToAlpha(i)}${rowNumber + rowCount - 1}`)
      rowNumber += rowCount
    })
    return range
  }

  private _getOpinionSurroundRange(opinionSections: OpinionSection[], dataStartRowIdx: number): string[] {
    const range: string[] = []
    let rowNumber = dataStartRowIdx

    opinionSections.forEach(os => {
      const rowCount = os.opinionElements.length
      for (let i = 2; i <= this._buildingInfoEndColumnIdx; i++) range.push(`${this.idxToAlpha(i)}${rowNumber}:${this.idxToAlpha(i)}${rowNumber + rowCount - 1}`)
      rowNumber += rowCount
    })
    return range
  }

  private _formatChoiceResultSummary(choiceSections: ChoiceSection[]): ExcelJs.CellValue[][] {
    const rows: ExcelJs.CellValue[][] = []

    // 選択式の概要の部分（B-J列の情報)を格納
    choiceSections.forEach(cs =>
      cs.questionSummaries.forEach((qs, idx) => {
        const row: ExcelJs.CellValue[] = []
        if (idx === 0) row.push('', cs.buildingArea, cs.condominiumCd, '', cs.buildingName, cs.accountingMonth, qs.deadline, qs.buildingUnitCount, qs.answerCount, qs.answerRate + '%')
        else row.push('', '', '', '', '', '', qs.deadline, qs.buildingUnitCount, qs.answerCount, qs.answerRate + '%')
        rows.push(row)
      })
    )
    return rows
  }

  private addChoiceResultValue(sheet: ExcelJs.Worksheet, choiceSections: ChoiceSection[], template: QuestionnaireTemplateDetailGetResponse, choiceStartColumnIdx: number, choiceStartRowIdx: number): void {
    // templateQuestionElementIdをキーにColumn番号をバリューとしたMapを生成
    const templateElementIds = new Map<string, number>()
    const choiceQuestion = template.sections.flatMap(ts =>
      ts.templateQuestions.filter(tq => tq.questionType === QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE || tq.questionType === QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES))

    let count = 0
    choiceQuestion.forEach(tq => tq.questionElements.forEach(qe => {
      templateElementIds.set(qe.templateQuestionElementId, count + choiceStartColumnIdx)
      count += 1
    }))

    // セルに回答数を代入
    let row = choiceStartRowIdx
    choiceSections.forEach(cs => cs.questionSummaries.forEach(qs => {
      qs.choiceQuestions.forEach(cq =>
        cq.choiceQuestionElements.forEach(cqe => {
          sheet.getCell(`${this.idxToAlpha(templateElementIds.get(cqe.templateQuestionElementId) ?? 0)}${row}`).value = cqe.answerCount
        })
      )
      row += 1
    })
    )
  }

  private _formatInputHeader(template: QuestionnaireTemplateDetailGetResponse): ExcelJs.CellValue[] {
    const column = this.commonColumn.concat(['区分所有者'])

    // 回答セクションを抽出
    const answerSections = template.sections.filter(s => s.sectionType === SECTION_TYPES.QUESTION)

    // 入力式設問を抽出
    const inputQuestions = answerSections.flatMap(as =>
      as.templateQuestions.filter(tq => tq.questionType === QUESTIONNAIRE_QUESTION_TYPES.FREE)
    )
    inputQuestions.forEach(iq => {
      column.push(iq.questionSentence, '', '', '', '', '')
    })
    return column
  }

  private _formatInputResult(inputSections: InputSection[]): ExcelJs.CellValue[][][] {
    const rows: ExcelJs.CellValue[][][] = []
    inputSections.forEach(is => {
      let questionTemplateIds: string[] = []
      let row: (string|number)[][] = []

      const inputQuestions = is.inputQuestions.filter(iq => iq.inputQuestionElements.length)
      inputQuestions.forEach((iq, iqIdx) => {
        // 最初のアンケートかアンケートが切り替わった場合
        if (iqIdx === 0 || questionTemplateIds.includes(iq.templateQuestionId)) {
          if (iqIdx !== 0) rows.push(row)
          row = []; questionTemplateIds = []

          iq.inputQuestionElements.forEach((iqe, iqeIdx) => {
            if (iqIdx === 0 && iqeIdx === 0) row.push(['', is.buildingArea, is.condominiumCd, '', is.buildingName, is.accountingMonth, iqe.deadline, iqe.ownerName, iqe.freeAnswer])
            else row.push(['', '', '', '', '', '', iqe.deadline, iqe.ownerName, iqe.freeAnswer])
          })
          // 同じアンケートで他の入力式設問に移った場合
        } else {
          iq.inputQuestionElements.forEach((iqe, iqeIdx) => {
            row[iqeIdx].push('', '', '', '', '', iqe.freeAnswer)
          })
        }
        questionTemplateIds.push(iq.templateQuestionId)
      })
      rows.push(row)
    })
    return rows
  }

  private _formatOpinionResult(opinionSections: OpinionSection[]): ExcelJs.CellValue[][] {
    const rows: ExcelJs.CellValue[][] = []
    opinionSections.forEach(os =>
      os.opinionElements.forEach((oe, idx) => {
        if (idx === 0) rows.push(['', os.buildingArea, os.condominiumCd, '', os.buildingName, os.accountingMonth, oe.deadline, oe.ownerName, oe.freeAnswer])
        else rows.push(['', '', '', '', '', '', oe.deadline, oe.ownerName, oe.freeAnswer])
      })
    )
    return rows
  }

  private _formatSumResult(choiceSections: ChoiceSection[], template: QuestionnaireTemplateDetailGetResponse): ExcelJs.CellValue[] {
    // templateElementIdをkey、回答数の合計をvalueとしたMapを生成(回答数の初期値は0)
    const templateElementIdAndCount = new Map<string, number>()
    const choiceQuestion = template.sections.flatMap(ts =>
      ts.templateQuestions.filter(tq => tq.questionType === QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE || tq.questionType === QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES))
    choiceQuestion.forEach(tq => tq.questionElements.forEach(qe => {
      templateElementIdAndCount.set(qe.templateQuestionElementId, 0)
    }))

    // 回答数の合計を入力
    choiceSections.forEach(cs => cs.questionSummaries.forEach(qs => {
      qs.choiceQuestions.forEach(cq => cq.choiceQuestionElements.forEach(cqe => {
        if (templateElementIdAndCount.get(cqe.templateQuestionElementId)) {
          templateElementIdAndCount.set(cqe.templateQuestionElementId, cqe.answerCount + (templateElementIdAndCount.get(cqe.templateQuestionElementId) ?? 0))
        } else {
          templateElementIdAndCount.set(cqe.templateQuestionElementId, cqe.answerCount)
        }
      }))
    }))

    // 戸数と回答者数の合計
    let buildingCount = 0; let answerCount = 0
    choiceSections.forEach(cs => cs.questionSummaries.forEach(qs => {
      buildingCount += qs.buildingUnitCount
      answerCount += qs.answerCount
    }))

    const column: ExcelJs.CellValue[] = ['', '', '', '', '', '', '合計', buildingCount, answerCount, answerCount / buildingCount]
    templateElementIdAndCount.forEach(count => {
      column.push(count)
    })

    return column
  }

  private _getChoiceRowCount(choiceSections: ChoiceSection[]): number {
    let questionnaireCount = 0
    choiceSections.forEach(cs => {
      questionnaireCount += cs.questionSummaries.length
    })
    return questionnaireCount
  }

  private _getChoiceElementCount(template: QuestionnaireTemplateDetailGetResponse): number {
    let count = 0
    const choiceQuestion = template.sections.flatMap(ts =>
      ts.templateQuestions.filter(tq => tq.questionType === QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE || tq.questionType === QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES))
    choiceQuestion.forEach(tq => {
      count += tq.questionElements.length
    })
    return count
  }

  private _getInputRowCount(inputSections: InputSection[]): number {
    let count = 0
    inputSections.forEach(is => {
      let ownerUserIds: string[] = []
      let templateQuestionIds: string[] = []

      is.inputQuestions.forEach((iq, idx) => {
        iq.inputQuestionElements.forEach(iqe => {
          if (idx === 0 || templateQuestionIds.includes(iq.templateQuestionId) || !ownerUserIds.includes(iqe.ownerUserId)) {
            if (templateQuestionIds.includes(iq.templateQuestionId)) ownerUserIds = []; templateQuestionIds = []
            count += 1
            ownerUserIds.push(iqe.ownerUserId)
          }
        })
        templateQuestionIds.push(iq.templateQuestionId)
      })
    })
    return count
  }

  private _getInputQuestionCount(template: QuestionnaireTemplateDetailGetResponse): number {
    const answerSections = template.sections.filter(s => s.sectionType === SECTION_TYPES.QUESTION)

    // 入力式設問を抽出して、個数を返す
    return answerSections.flatMap(as => as.templateQuestions.filter(tq => tq.questionType === QUESTIONNAIRE_QUESTION_TYPES.FREE)).length
  }

  private _getOpinionRowCount(opinionSections: OpinionSection[]): number {
    let questionnaireCount = 0
    opinionSections.forEach(os => {
      questionnaireCount += os.opinionElements.length
    })
    return questionnaireCount
  }

  // 塗つぶしを行うメソッド 塗りつぶしの色、文字色、文字サイズは固定
  private _fillAndChangeFont(sheet: ExcelJs.Worksheet, row: number, bold: boolean): void {
    sheet.getRow(row).eachCell({ includeEmpty: true }, function(cell) {
      // A列は塗りつぶしをしない
      if (cell.address.replace(/[^A-Z]/g, '') === 'A') return
      sheet.getCell(cell.address).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF44546A' }
      }
      sheet.getCell(cell.address).font = {
        color: { argb: 'FFFFFFFF' },
        bold: bold
      }
      sheet.getCell(cell.address).alignment = {
        vertical: 'top',
        horizontal: 'left'
      }
    })
  }

  // カラースケールを適用する範囲を取得
  private _getColorRanges(choiceSections: ChoiceSection[], resultStartRowIndex: number, choiceQuestions: QuestionnaireTemplateQuestion[], sumStartRowIndex: number, choiceStartColumnIdx:number):string[] {
    const colorRange:string[] = []
    let rawIdx = resultStartRowIndex

    // 結果エリアのカラースケール適用範囲
    choiceSections.forEach(cs =>
      cs.questionSummaries.forEach(qs => {
        let column = choiceStartColumnIdx
        qs.choiceQuestions.forEach(cq => {
          colorRange.push(`${this.idxToAlpha(column)}${rawIdx}:${this.idxToAlpha(column + cq.choiceQuestionElements.length - 1)}${rawIdx}`)
          column += cq.choiceQuestionElements.length
        })
        rawIdx += 1
      }))

    // 合計エリアのカラースケール適用範囲
    let sumColumn = choiceStartColumnIdx
    choiceQuestions.forEach(tq => {
      colorRange.push(`${this.idxToAlpha(sumColumn)}${sumStartRowIndex}:${this.idxToAlpha(sumColumn + tq.questionElements.length - 1)}${sumStartRowIndex}`)
      sumColumn += tq.questionElements.length
    })

    return colorRange
  }

  private _addColorScale(sheet: ExcelJs.Worksheet, ranges:string[]): void {
    ranges.forEach(range => {
      sheet.addConditionalFormatting({
        ref: range,
        rules: [{
          type: 'colorScale',
          priority: 1,
          cfvo: [{ type: 'min' }, { type: 'max' }],
          color: [{ argb: 'FFFFFFFF' }, { argb: 'FFF8696B' }]
        }]
      })
    })
  }

  private _getWrapTextRange(answerStartRowIdx: number, questionCount: number, answerRowCount: number): string[] {
    const cells: string[] = []
    const columnNumber = 8 // 区分所有者の列
    for (let i = columnNumber; i <= columnNumber + questionCount; i++) {
      for (let j = answerStartRowIdx; j < answerStartRowIdx + answerRowCount; j++) cells.push(`${this.idxToAlpha(i)}${j}`)
    }
    return cells
  }
}

export const questionnaireExcelParser = new QuestionnaireExcelParser()
