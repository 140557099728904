import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { resolutionsClient } from '@/clients/resolutions-client'

import { ResolutionsGetRequest, ResolutionsGetResponse, ListResponseResolutionDto } from '@/dtos/resolutions/get'
import { RequirementsGetResponse } from '@/dtos/resolutions/requirements/get'
import { QuestionsGetRequest, QuestionsGetResponse, DisplayingQuestion } from '@/dtos/resolutions/questions/get'
import { QuestionsDeleteRequest } from '@/dtos/resolutions/questions/delete'
import { AnswersPostRequest } from '@/dtos/resolutions/questions/answers/post'
import { AnswersPutRequest } from '@/dtos/resolutions/questions/answers/put'
import { AnswersDeleteRequest } from '@/dtos/resolutions/questions/answers/delete'

export class QuestionForRequest {
  resolutionId!: string
  question!: DisplayingQuestion

  constructor(resolutionId: string, question: DisplayingQuestion) {
    this.resolutionId = resolutionId
    this.question = question
  }
}

@Module({ store, dynamic: true, namespaced: true, name: 'resolutionsStore' })
class ResolutionsStore extends VuexModule {
  // 決議一覧
  private _resolutions: ListResponseResolutionDto[] = []
  private _skipToken: string | null = null

  get resolutions(): ListResponseResolutionDto[] {
    return this._resolutions
  }

  get skipToken(): string | null {
    return this._skipToken
  }

  @Mutation
  private PUSH_RESOLUTIONS(res: ResolutionsGetResponse): void {
    this._resolutions.push(...res.resolutions)
    this._skipToken = res.skipToken
  }

  @Mutation
  private CLEAR_RESOLUTIONS_GET(): void {
    this._resolutions = []
    this._skipToken = null
  }

  @Action
  async fetchResolutions(req: ResolutionsGetRequest): Promise<void> {
    const res = await resolutionsClient.getResolutions(req)
    this.PUSH_RESOLUTIONS(res)
  }

  @Action
  clearFetchedResolutions(): void {
    this.CLEAR_RESOLUTIONS_GET()
  }

  // 決議要件
  private _requirementsGet: RequirementsGetResponse = new RequirementsGetResponse()

  get requirementsGet(): RequirementsGetResponse {
    return this._requirementsGet
  }

  @Mutation
  private SET_REQUIREMENTS_GET(res: RequirementsGetResponse): void {
    this._requirementsGet = res
  }

  @Action
  async fetchRequirements(): Promise<void> {
    const res = await resolutionsClient.getRequirements()
    this.SET_REQUIREMENTS_GET(res)
  }

  // 質問一覧 取得
  private _questionsGet: Record<string, QuestionsGetResponse> = {}

  get questionsGet(): (id: string) => QuestionsGetResponse | undefined { return (id: string) => this._questionsGet[id] }

  @Mutation
  private SET_QUESTIONS_GET(res: QuestionsGetResponse): void {
    Vue.set(this._questionsGet, res.resolutionId, res)
  }

  @Action
  async fetchQuestions(req: QuestionsGetRequest): Promise<void> {
    const res = await resolutionsClient.getQuestions(req)
    this.SET_QUESTIONS_GET(res)
  }

  // 質問 削除
  @Action
  async deleteQuestions(del: QuestionForRequest): Promise<void> {
    await resolutionsClient.deleteQuestions(new QuestionsDeleteRequest(del.resolutionId, del.question.questionId))
  }

  // 回答 登録
  private _postAnswerResponses: Record<string, { version: number, postedAt?: string }> = {}
  get answerResponse(): (questionId: string) => { version: number, postedAt?: string } | undefined { return (questionId: string) => this._postAnswerResponses[questionId] }
  @Mutation private SET_ANSWER_RESPONSE({ questionId, res }: { questionId: string, res: { version: number, postedAt?: string } }) { Vue.set(this._postAnswerResponses, questionId, res) }

  @Action
  async postAnswers(post: QuestionForRequest): Promise<void> {
    const req = new AnswersPostRequest(
      post.resolutionId,
      post.question.questionId,
      post.question.answerInput, // answerBody
    )
    const res = await resolutionsClient.postAnswers(req)
    if (res) this.SET_ANSWER_RESPONSE({ questionId: post.question.questionId, res })
  }

  // 回答 更新
  @Action
  async putAnswers(put: QuestionForRequest): Promise<void> {
    const req = new AnswersPutRequest(
      put.resolutionId,
      put.question.questionId,
      put.question.answerInput,
      put.question.answer?.version ?? 0
    )
    const res = await resolutionsClient.putAnswers(req)
    if (res) this.SET_ANSWER_RESPONSE({ questionId: put.question.questionId, res })
  }

  // 回答 削除
  @Action
  async deleteAnswers(del:QuestionForRequest): Promise<void> {
    await resolutionsClient.deleteAnswers(new AnswersDeleteRequest(del.resolutionId, del.question.questionId))
  }
}

export const resolutionsModule = getModule(ResolutionsStore)
