import { Type } from 'class-transformer'

export class OwnerIdeasGetRequest {
  keyword?: string
  adminIdeaId?: string
}

export class OwnerIdea {
  ideaId!: string
  title!: string
  postedAt!: string
}

export class OwnerIdeasGetResponse {
  @Type(() => OwnerIdea)ideas: OwnerIdea[] = []
}
