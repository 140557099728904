













































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
  }
})
export default class SmDatePicker extends Vue {
  private menu = false

  @Prop({ default: '' })
  private readonly targetDate!: string

  @Prop({ default: '' })
  private readonly fieldId!: string

  @Prop({ default: '235px' })
  private readonly width!: string

  @Prop()
  private readonly placeholder?: string

  @Prop()
  private readonly label?: string

  @Prop({ default: false })
  private readonly disabled!: boolean

  private get formatDate(): string | undefined {
    if (!this.targetDate) return undefined

    const [year, month] = this.date.substr(0, 10).split('-')
    return `${year}年${month}月`
  }

  private dateValue = this.targetDate // ローカルなデータとして管理

  private get date(): string { return this.dateValue }
  private set date(newValue: string) {
    this.dateValue = newValue
    this.$emit('input', newValue)
  }

  private get isDateNotSelected(): boolean {
    return !this.date
  }
}
