



























































import { QUESTIONNAIRE_QUESTION_ELEMENT_TYPES } from '@/constants/schema-constants'
import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator'
import { QuestionOption } from './SmProxyAnswerEachQuestion.vue'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})

export default class SmCheckboxAnswers extends Vue {
  // labelスロットにカスタムコンポーネントのみを設定すると描画されないため、空文字の力を借りる
  private emptyDummyString = ''

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly indeterminate?: boolean

  @Prop({ required: true, default: () => [] })
  private readonly options!:QuestionOption[]

  @Prop({ required: true, default: '' })
  private readonly fieldId!: string

  @Prop({ required: true, default: false })
  private readonly required!: boolean

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!: string

  @Model('change')
  private readonly selectedCheckboxValue!: number[]

  private get _selectedCheckboxValue(): number[] { return this.selectedCheckboxValue }
  private set _selectedCheckboxValue(newValue : number[]) { this.$emit('change', newValue) }

  private onClickCheckBox(option: QuestionOption): void {
    if (option.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION && this.selectedCheckboxValue.some(value => value === option.value)) this._inputText = ''
  }

  private isOtherOption(option: QuestionOption): boolean {
    return option.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION
  }

  private get isSelectOtherOption(): boolean {
    const filteredCheckbox = this.options.flatMap(o => o.elementType)
    const otherOption = filteredCheckbox.indexOf(QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION)
    return this._selectedCheckboxValue.includes(otherOption)
  }

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get rules(): string {
    const rules = []
    if (this.required) rules.push('required')
    return rules.join('|')
  }

  isValid = false

  @Watch('_selectedCheckboxValue')
  private updateIsValid():void {
    if (this._selectedCheckboxValue.length === 0 && this.required) {
      this.isValid = true
    } else {
      this.isValid = false
    }
    this.$emit('select-checkbox', this._selectedCheckboxValue)
  }

  private get validateCheckbox(): (string | true)[] {
    return [(this._selectedCheckboxValue.length > 0 || !this.required) || 'この選択肢は必須項目です']
  }
}
