import { Type } from 'class-transformer'

export class Confirmation {
  billingMonth!: string
  billingMonthStr!: string
  isConfirmed!: boolean
}

export class CasysOutput {
  billingMonth!: string
  billingMonthStr!: string
  isChanged!: boolean
}

export class ReservesPaymentsCheckedGetResponse {
  @Type(() => Confirmation) confirmation = new Confirmation()
  @Type(() => CasysOutput) casysOutput?: CasysOutput
}
