






































































import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { Duration } from '@/dtos/commons'

export class BulkInputProp {
  duration: Duration = new Duration()
  inputValue: number | null = null
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogClosable: () => import('@/components/organisms/dialog/SmDialogClosable.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class PaymentPlanEditPage extends Vue {
  @Model('change', { required: true })
  private readonly isVisible!: boolean

  private get isDialogVisible(): boolean { return this.isVisible }
  private set isDialogVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  @Prop({ default: () => [] })
  periodItems!: {label:string, value:number | null }[]

  @Prop()
  counter?: number

  bulkInputProp:BulkInputProp = new BulkInputProp()

  private bulkInput():void {
    this.$emit('bulk-input', this.bulkInputProp)
  }
}
