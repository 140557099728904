export class TicketsManagementStatusesCsvPostRequest {
  toDate!: string
  isIncludeProcessingAndCompletedTickets!: boolean
  buildings?: string[]
  constructor(toDate:string, isIncludeProcessingAndCompletedTickets:boolean, buildings?: string[]) {
    this.toDate = toDate
    this.isIncludeProcessingAndCompletedTickets = isIncludeProcessingAndCompletedTickets
    this.buildings = buildings
  }
}
