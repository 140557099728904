

































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ReservePlanDetailGetRequest, ReservePlanDetailGetResponse } from '@/dtos/reserve-funds/plans/get-detail'
import { reserveFundsPlansModule } from '@/stores/reserve-funds-plans-store'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    PaymentPlanDetailTable: () => import('@/pages/reserve-funds/plans/PaymentPlanDetailTable.vue'),
    MonthlyAmountTable: () => import('@/pages/reserve-funds/MonthlyAmountTable.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class PaymentPlanDetailPage extends Vue {
  @Prop({ required: true, default: '' })
  paymentPlanId!:string

  private get response():ReservePlanDetailGetResponse {
    return reserveFundsPlansModule.paymentPlanDetail(this.paymentPlanId) ?? new ReservePlanDetailGetResponse()
  }

  async created():Promise<void> {
    const req = new ReservePlanDetailGetRequest()
    req.paymentPlanId = this.paymentPlanId
    await reserveFundsPlansModule.fetchReservePlanDetail(req)
  }
}
