





















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ChartItem } from '@/components/molecules/SmChartStackedBar.vue'

class RowItem {
  label !: string
  amount!: number
  symbolColor! : string
  bold!: boolean
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmChartStackedBar: () => import('@/components/molecules/SmChartStackedBar.vue'),

    SmOwLegend: () => import('@/components/owner-apps/SmOwLegend.vue'),
  }
})
export default class SmListBudget extends Vue {
  @Prop({ required: true })
  private readonly budget!: number

  @Prop({ required: true })
  private readonly budgetLabel!:string

  @Prop({ required: true })
  private readonly expense!: number

  @Prop({ required: true })
  private readonly expenseLabel!:string

  @Prop()
  private readonly spent?: number

  @Prop()
  private readonly spentLabel?: string

  @Prop({ required: true })
  private readonly balanceLabel!:string

  /**
   * 残高の計算式：（残高）＝（予算）－（必要な支出額）－（あれば支出済額）
   */
  private get _balance() : number {
    return this.budget - this.expense - (this.spent ?? 0)
  }

  /**
   * 棒グラフで使うための「今期総予算に対する金額の割合」を算出
   */
  private _chartPercentage(amount:number, budget:number):number {
    if (!budget || !amount) { return 0 }
    return (amount / budget) * 100
  }

  private get _chartItems():ChartItem[] {
    const chartItems:ChartItem[] = [
      { name: this.expenseLabel, percentage: this._chartPercentage(this.expense, this.budget), color: '#FC5353' },
      { name: this.balanceLabel, percentage: this._chartPercentage(this._balance, this.budget), color: '#A1ABAE' },
    ]

    if (this.spent !== undefined && this.spentLabel) {
      // 今期支出済額を表示する場合、先頭に追加（グラフの一番左に表示される）
      chartItems.unshift(
        { name: this.spentLabel, percentage: this._chartPercentage(this.spent, this.budget), color: 'rgba(0, 0, 0, 0.5)' },
      )
    }

    return chartItems
  }

  private get _rowItems():RowItem[] {
    const rowItems:RowItem[] = [
      { label: this.expenseLabel, amount: this.expense, symbolColor: '#FC5353', bold: true },
      { label: this.balanceLabel, amount: this._balance, symbolColor: '#A1ABAE', bold: false },
    ]

    if (this.spent !== undefined && this.spentLabel) {
      // 今期支出済額を表示する場合、二行目に追加（上から二番目に表示される）
      rowItems.splice(1, 0,
        { label: this.spentLabel, amount: this.spent, symbolColor: 'rgba(0,0,0,0.5)', bold: false },
      )
    }

    return rowItems
  }
}

