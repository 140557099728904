import { PostTimingType } from '@/constants/schema-constants'
import { Type } from 'class-transformer'
import { Material } from '@/dtos/commons'
import { QuestionnaireTargetBuilding, QuestionnaireSectionBase } from '@/dtos/questionnaires/commons'

export class QuestionnairePostRequest {
  questionnaireId?: string
  templateId!: string
  questionnaireTitle!: string
  explanation!: string
  targetBuildings!: QuestionnaireTargetBuilding[]
  postTimingType!:PostTimingType
  postedAt?: string
  deadline!: string
  @Type(() => Material)material?: Material
  @Type(() => QuestionnaireSectionBase)sections: QuestionnaireSectionBase[] = []
  constructor(templateId: string) {
    this.templateId = templateId
  }
}
