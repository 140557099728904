import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import { store } from './index'
import { questionnairesClient } from '@/clients/questionnaires-client'

import { QuestionnaireInternalReportPostRequest, QuestionnaireInternalReportPostResponse } from '@/dtos/questionnaires/reports/internal/post'

@Module({ store, dynamic: true, namespaced: true, name: 'questionnaireInternalReportsStore' })
class QuestionnaireInternalReportsStore extends VuexModule {
  // 社内向け報告書情報 取得
  private _internalReportGet = new QuestionnaireInternalReportPostResponse()

  public get internalReportGet(): QuestionnaireInternalReportPostResponse { return this._internalReportGet }

  @Mutation
  private SET_INTERNAL_REPORT_GET(res: QuestionnaireInternalReportPostResponse): void { this._internalReportGet = res }

  @Action
  async fetchQuestionnaireInternalReport(req: QuestionnaireInternalReportPostRequest): Promise<void> {
    const res = await questionnairesClient.postQuestionnaireInternalReports(req)
    if (res) this.SET_INTERNAL_REPORT_GET(res)
  }
}

export const questionnaireInternalReportsModule = getModule(QuestionnaireInternalReportsStore)
