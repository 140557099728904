













import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import Chart from 'chart.js'
import { Line, Bar, mixins } from 'vue-chartjs'

// CSP対応のためインラインCSSインジェクションを抑止（代わりにstyle.cssにCSSを記載）
Chart.platform.disableCSSInjection = true

@Component({})
export default class SmChart extends Mixins(Line, Bar, mixins.reactiveProp) {
  @Prop()
  chartData!: Chart.ChartData

  @Prop()
  chartOptions!: Chart.ChartOptions

  @Watch('chartData')
  onChartDataChanged():void {
    this.load()
  }

  mounted():void {
    this.renderChart(this.chartData, this.chartOptions)
  }

  load():void {
    this.renderChart(this.chartData, this.chartOptions)
  }
}
