import type { QuestionnaireQuestionElementType, QuestionnaireQuestionType, SectionType } from '@/constants/schema-constants'
import { Material, MaterialFormInputDto } from '@/dtos/commons'
import { Type } from 'class-transformer'

export class QuestionnaireQuestionElementBase {
  templateQuestionElementId?: string
  answerPlaceholder?: string
  choice?: string
  elementType!: QuestionnaireQuestionElementType
  sortOrderNum!: number
}

export class QuestionnaireQuestionBase {
  templateQuestionId?: string
  questionType!: QuestionnaireQuestionType
  questionSentence!: string
  questionRequired!: boolean
  questionElements: QuestionnaireQuestionElementBase[] = []
  sortOrderNum!: number
}

export class QuestionnaireSectionBase {
  templateSectionId?: string
  sectionTitle?:string
  sectionExplanation?: string
  sectionType!: SectionType
  @Type(() => Material)material?: Material
  questions: QuestionnaireQuestionBase[] = []
  sortOrderNum!:number
}

export class QuestionnaireTargetBuilding {
  buildingId: string
  constructor(buildingId: string) {
    this.buildingId = buildingId
  }
}

export class QuestionnaireQuestionElementForPreview {
  questionElementId!: string
  answerPlaceholder?: string
  choiceName?: string
  elementType!: QuestionnaireQuestionElementType
  sortOrderNum!: number
}

export class QuestionnaireQuestionForPreview {
  questionId!: string
  questionType!: QuestionnaireQuestionType
  questionRequired!: boolean
  questionSentence!: string
  questionElements: QuestionnaireQuestionElementForPreview[] = []
  sortOrderNum!: number
}

export class QuestionnaireSectionForPreview {
  sectionId!: string
  sectionTitle?:string
  sectionExplanation?: string
  sectionType!: SectionType
  @Type(() => MaterialFormInputDto)material?: MaterialFormInputDto | undefined
  questions: QuestionnaireQuestionForPreview[] = []
}
