import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { ownerNotificationsClient } from '@/clients/owner-notifications-client'
import { OwnerNotificationDetailGetRequest, OwnerNotificationDetailGetResponse } from '@/dtos/owner-notifications/get-detail'
import { OwnerNotification, OwnerNotificationsSearchPostRequest, OwnerNotificationsSearchPostResponse } from '@/dtos/owner-notifications/search/post'
import { OwnerNotificationsPostRequest } from '@/dtos/owner-notifications/post'
import { OwnerNotificationsDraftPostRequest } from '@/dtos/owner-notifications/draft/post'
import { OwnerNotificationsPutRequest } from '@/dtos/owner-notifications/put'
import { OwnerNotificationsArchivePutRequest } from '@/dtos/owner-notifications/archive/put'
import { OwnerNotificationsDeleteRequest } from '@/dtos/owner-notifications/delete'

@Module({ store, dynamic: true, namespaced: true, name: 'owner-notification' })
class OwnerNotificationsStore extends VuexModule {
  // 区分所有者お知らせ一覧
  private _ownerNotifications: OwnerNotification[] = []
  private _skipToken: string | null = null

  get ownerNotifications(): OwnerNotification[] {
    return this._ownerNotifications
  }

  get skipToken(): string | null {
    return this._skipToken
  }

  @Mutation
  private PUSH_OWNER_NOTIFICATIONS(res: OwnerNotificationsSearchPostResponse): void {
    this._ownerNotifications.push(...res.ownerNotifications)
    this._skipToken = res.skipToken
  }

  @Mutation
  private CLEAR_OWNER_NOTIFICATIONS_GET(): void {
    this._ownerNotifications = []
    this._skipToken = null
  }

  @Action
  async fetchOwnerNotifications(req: OwnerNotificationsSearchPostRequest): Promise<void> {
    const res = await ownerNotificationsClient.postOwnerNotificationsSearch(req)
    if (res) this.PUSH_OWNER_NOTIFICATIONS(res)
  }

  @Action
  clearFetchedOwnerNotifications(): void {
    this.CLEAR_OWNER_NOTIFICATIONS_GET()
  }

  // 区分所有者お知らせ詳細取得
  private _ownerNotificationDetails: Record<string, OwnerNotificationDetailGetResponse> = {}

  get detailResponse(): (id: string) => OwnerNotificationDetailGetResponse | undefined {
    return (id: string) => this._ownerNotificationDetails[id]
  }

  @Mutation
  private SET_OWNER_NOTIFICATION_DETAIL_GET(res: OwnerNotificationDetailGetResponse): void {
    Vue.set(this._ownerNotificationDetails, res.ownerNotificationId, res)
  }

  @Action
  async fetchOwnerNotificationDetail(req: OwnerNotificationDetailGetRequest): Promise<void> {
    const res = await ownerNotificationsClient.getOwnerNotificationDetail(req)
    this.SET_OWNER_NOTIFICATION_DETAIL_GET(res)
  }

  // 区分所有者お知らせ投稿
  @Action
  async postOwnerNotification(req: OwnerNotificationsPostRequest): Promise<void> {
    await ownerNotificationsClient.postOwnerNotification(req)
  }

  // 区分所有者お知らせ下書き保存
  @Action
  async postOwnerNotificationDraft(req: OwnerNotificationsDraftPostRequest): Promise<void> {
    await ownerNotificationsClient.postOwnerNotificationDraft(req)
  }

  // 区分所有者お知らせ更新
  @Action
  async putOwnerNotification(req: OwnerNotificationsPutRequest): Promise<void> {
    await ownerNotificationsClient.putOwnerNotification(req)
  }

  // 区分所有者お知らせ更新（アーカイブ）
  @Action
  async archiveOwnerNotifications(req: OwnerNotificationsArchivePutRequest): Promise<void> {
    await ownerNotificationsClient.putOwnerNotificationsArchive(req)
  }

  // 区分所有者お知らせ削除
  @Action
  async deleteOwnerNotifications(req: OwnerNotificationsDeleteRequest): Promise<void> {
    await ownerNotificationsClient.deleteOwnerNotifications(req)
  }
}

export const ownerNotificationsModule = getModule(OwnerNotificationsStore)
