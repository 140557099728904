

































































































































import { Component, Vue, Prop } from 'vue-property-decorator'

import { staffsModule } from '@/stores/staffs-store'
import { staticRoutes } from '@/routes'
import { StaffDetailGetRequest, StaffDetailGetResponse } from '@/dtos/staffs/get-detail'
import { StaffDeleteRequest, LifeManagerDeleteRequest } from '@/dtos/staffs/delete'

import { ADMIN_ROLE, INITIAL_AUTH_STATE } from '@/constants/schema-constants'
import type { AdminRole, InitialAuthState } from '@/constants/schema-constants'
import { getAdminRoleLabel, getInitialAuthStateLabel } from '@/libs/state-handler'
import { assertExhaustive } from '@/libs/exhaustive-helper'

@Component({
  components: {
    SmAvatar: () => import('@/components/atoms/SmAvatar.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class StaffDetailPage extends Vue {
  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)
  INITIAL_AUTH_STATE = Object.freeze(INITIAL_AUTH_STATE)

  @Prop({ required: true, default: '' })
  private readonly adminId!: string

  @Prop({ required: true, default: ADMIN_ROLE.SERVICE_STAFF })
  private readonly pageType!: AdminRole

  @Prop({ default: '' })
  private readonly routeFromName!: string

  get member(): StaffDetailGetResponse {
    return staffsModule.staffDetail(this.adminId) ?? new StaffDetailGetResponse()
  }

  async created(): Promise<void> {
    await staffsModule.fetchStaffDetail(new StaffDetailGetRequest(this.adminId))

    if (this.routeFromName === staticRoutes.frontRegister.name || this.routeFromName === staticRoutes.lifeManagerRegister.name) this.isAssignDialogVisible = true
  }

  private getAdminRoleLabel(adminRole: AdminRole): string {
    return getAdminRoleLabel(adminRole)
  }

  private getInitialAuthStateLabel(initialAuthState: InitialAuthState): string {
    return getInitialAuthStateLabel(initialAuthState)
  }

  private getEmailSentLabel(isEmailSent: boolean): string {
    return isEmailSent ? '完了' : '未完了'
  }

  onClickEdit(): void {
    if (!this.adminId) return

    switch (this.pageType) {
      case ADMIN_ROLE.SERVICE_STAFF: this.$router.push({ name: staticRoutes.serviceStaffEdit.name }); break
      case ADMIN_ROLE.FRONT: this.$router.push({ name: staticRoutes.frontEdit.name }); break
      case ADMIN_ROLE.LIFE_MANAGER: this.$router.push({ name: staticRoutes.lifeManagerEdit.name }); break
      case ADMIN_ROLE.ACCOUNTING_DEPARTMENT:
      case ADMIN_ROLE.GROUP_CALL_CENTER:
        break
      default: assertExhaustive(this.pageType)
    }
  }

  isDeleteDialogVisible = false
  async onClickDelete(): Promise<void> {
    this.isDeleteDialogVisible = false

    switch (this.pageType) {
      case ADMIN_ROLE.SERVICE_STAFF : {
        await staffsModule.deleteStaff(new StaffDeleteRequest(this.adminId))
        this.$router.push({ name: staticRoutes.staffsList.getChild('serviceStaff').name })
        break
      }
      case ADMIN_ROLE.FRONT : {
        await staffsModule.deleteStaff(new StaffDeleteRequest(this.adminId))
        this.$router.push({ name: staticRoutes.staffsList.getChild('front').name })
        break
      }
      case ADMIN_ROLE.LIFE_MANAGER : {
        await staffsModule.deleteLifeManager(new LifeManagerDeleteRequest(this.adminId))
        this.$router.push({ name: staticRoutes.staffsList.getChild('lifeManager').name })
        break
      }
      case ADMIN_ROLE.ACCOUNTING_DEPARTMENT:
      case ADMIN_ROLE.GROUP_CALL_CENTER:
        break
      default: assertExhaustive(this.pageType)
    }
  }

  isAssignDialogVisible = false
  private getAssignDialogCaption(): string {
    const assignMessageList: string[] = []
    if (this.pageType === ADMIN_ROLE.FRONT) {
      assignMessageList.push('登録するとフロント担当者が以下のような操作を行えます')
      assignMessageList.push('・マンション検索画面と区分所有者検索画面を「自分が担当しているマンションのみ」で絞り込んで表示できます')
    } else if (this.pageType === ADMIN_ROLE.LIFE_MANAGER) {
      assignMessageList.push('登録するとライフマネージャーが以下のような操作を行えます。')
      assignMessageList.push('・担当マンションの閲覧ができます')
    }
    return assignMessageList ? assignMessageList.join('\n') : ''
  }

  openBuildingSearch(): void {
    this.$router.push({ name: staticRoutes.buildingsList.name })
  }
}
