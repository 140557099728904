import { PaymentDetail } from '@/dtos/reserve-funds/get'
import { PaymentPlanDetail } from '@/dtos/reserve-funds/plans/get-detail'
import { Type } from 'class-transformer'

export class PersonalPlansInitialFormGetRequest {
  buildingUnitId!: string
  appliedAt!: string
}

export class PersonalPlanDetail extends PaymentPlanDetail {
  startDate!:string
  endDate!:string
  minUnitPrice!:number
}

export class PersonalPlansInitialFormGetResponse {
  planCode!: string
  appliedAt?: string
  recordedReservedAmount!:number
  @Type(() => PersonalPlanDetail) paymentPlanDetail: PersonalPlanDetail[] = []
  @Type(() => PaymentDetail) paymentDetail: PaymentDetail[] = []
}
