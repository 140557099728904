













































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import VueRouter from 'vue-router'
import { NavigationGuardNext, Route } from 'vue-router/types'

import { IdeaState, IDEA_STATES, ITEM_TYPES, MATERIAL_TYPES } from '@/constants/schema-constants'
import { DAY_OF_WEEK, PAGE_TYPES } from '@/constants/ux-constants'
import type { PageType } from '@/constants/ux-constants'

import { BuildingOwnersGetRequest } from '@/dtos/buildings/owners/get'
import { BaseIdea, Estimate, Material, Section, Session, Plan, User, BaseOpinion, MaterialFormInputDto } from '@/dtos/commons'
import { Details, Result, SessionGetResponseSession, Guide } from '@/dtos/ideas/admin-idea/commons'
import { AdminIdeasDraftPostRequest } from '@/dtos/ideas/admin-idea/draft/post'
import { AdminIdeasDraftDeleteRequest } from '@/dtos/ideas/admin-idea/draft/delete'
import { AdminIdeaDetailGetRequest, AdminIdeaDetailGetResponse } from '@/dtos/ideas/admin-idea/get-detail'
import { AdminIdeaOpinionsGetRequest, AdminIdeaOpinionsGetResponse, Opinion } from '@/dtos/ideas/admin-idea/opinions/get'
import { AdminIdeasPostRequest, PreviewPlan, PreviewSession } from '@/dtos/ideas/admin-idea/post'
import { AdminIdeasPutRequest } from '@/dtos/ideas/admin-idea/put'
import { AdminIdeaResultsGetRequest, AdminIdeaResultsGetResponse } from '@/dtos/ideas/admin-idea/results/get'
import { AdminIdeaSessionGetRequest, AdminIdeaSessionGetResponse } from '@/dtos/ideas/admin-idea/session/get'
import { AdminIdeaUpdatesGetRequest, AdminIdeaUpdatesGetResponse } from '@/dtos/ideas/admin-idea/updates/get'
import { OwnerIdeasGetRequest } from '@/dtos/ideas/owner-idea/get'
import { OwnerIdeaDetailGetResponse } from '@/dtos/ideas/owner-idea/get-detail'

import { concatSerialChar } from '@/libs/add-alphabet-provider'
import { deepCopy, ColumnToType } from '@/libs/deep-copy-provider'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'
import { staticKeyProvider } from '@/libs/static-key-provider'
import { generateUuid } from '@/libs/uuid-generator'
import { windowOpen } from '@/libs/window-open'

import { staticRoutes } from '@/routes'

import { adminIdeasModule } from '@/stores/admin-ideas-store'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { newTabLocalParamStorageModule, AdminIdeaPreviewContent } from '@/stores/new-tab-local-param-storage-store'
import { ownerIdeasModule } from '@/stores/owner-ideas-store'

/**
 * SmDatePicker用のフォーマット（YYYY-MM-DD）でn日後の年月日を返す
 * n日後を指定していない場合、30日後の日付を返却する
 */
const dateAfter = (n?:number): string => {
  const date = new Date()
  n ? date.setDate(date.getDate() + n) : date.setDate(date.getDate() + 30)
  // ISOString形式の日本時間を取得したいが、
  // toISOString()の結果はUTCのため新規・修正に関わらず9時間進める
  date.setHours(date.getHours() + 9)
  return date.toISOString().substr(0, 10)
}

const uploadThenPost = async <T, >(rawReq: T, exec: (_: T) => Promise<void>) => {
  const uploader = new FileUploader()
  const req = await uploader.prepare(rawReq)

  await exec(req)
}

class FormInputs {
  ideaId?: string
  baseIdeaId: string | null = null
  version?: number

  title = ''

  opinionDate = dateAfter()
  opinionHour = 0
  opinionMinute = 0

  agreementDate = dateAfter(60)
  agreementHour = 0
  agreementMinute = 0

  isNotAcceptable = false
  sessions: Session[] = []
  plans: Plan[] = []

  get opinionDeadline(): string {
    return `${this.opinionDate} ${this.opinionHour}:${this.opinionMinute}`
  }

  get deadline(): string {
    return `${this.agreementDate} ${this.agreementHour}:${this.agreementMinute}`
  }
}

interface PageTypeSpecifications {
  pageTitle: string
  executeBtnLabel: string
  dialogMessage: string

  isDraft: boolean

  created: (baseIdeaId?: string, ideaId?: string) => void | Promise<void>
  onClickExecute: ($router: VueRouter, inputs: FormInputs) => Promise<void> | void
  onClickSaveDraft: ($router: VueRouter, inputs: FormInputs) => Promise<void> | void
  onClickDeleteDraft: ($router: VueRouter) => Promise<void> | void
  isPast: (targetDate: string | undefined) => boolean
}

class CreateSpecifications implements PageTypeSpecifications {
  readonly pageTitle = 'プランを新規作成する'
  readonly executeBtnLabel = '投稿する'
  readonly dialogMessage = 'プランを投稿します。よろしいですか？'
  readonly isDraft = false

  async created(baseIdeaId?: string, ideaId?: string) {
    // 遷移元の画面からもとにするアイデアを指定される場合がある
    if (baseIdeaId) await ownerIdeasModule.fetchIdeaDetail({ ideaId: baseIdeaId })

    // モーダルで使用するもとになったアイデアを取得
    const req = new OwnerIdeasGetRequest()
    req.adminIdeaId = ideaId
    await ownerIdeasModule.fetchIdeas(req)
  }

  async onClickExecute($router: VueRouter, inputs: FormInputs): Promise<void> {
    const req = new AdminIdeasPostRequest(inputs.title, inputs.opinionDeadline, inputs.deadline, inputs.isNotAcceptable)
    if (inputs.baseIdeaId) req.baseIdea = new BaseIdea(inputs.baseIdeaId)
    if (inputs.sessions.length > 0) req.sessions = inputs.sessions
    if (inputs.plans.length > 0) req.plans = inputs.plans

    await uploadThenPost(req, adminIdeasModule.postIdeas)
    $router.push({ name: staticRoutes.ideasList.name })
  }

  async onClickSaveDraft($router: VueRouter, inputs: FormInputs): Promise<void> {
    const req = new AdminIdeasDraftPostRequest(inputs.title, inputs.opinionDeadline, inputs.deadline, inputs.isNotAcceptable)
    if (inputs.baseIdeaId) req.baseIdea = new BaseIdea(inputs.baseIdeaId)
    if (inputs.sessions.length > 0) req.sessions = inputs.sessions
    if (inputs.plans.length > 0) req.plans = inputs.plans

    await uploadThenPost(req, adminIdeasModule.postAdminIdeaDraft)
    $router.push({ name: staticRoutes.ideasList.name })
  }

  onClickDeleteDraft() { /** nothing to do */ }

  isPast() { return false }
}

class DraftSpecifications implements PageTypeSpecifications {
  ideaId: string
  constructor(ideaId: string) { this.ideaId = ideaId }

  readonly pageTitle = 'プランを新規作成する'
  readonly executeBtnLabel = '投稿する'
  readonly dialogMessage = 'プランを投稿します。よろしいですか？'
  readonly isDraft = true

  async created(baseIdeaId?: string, ideaId?: string) {
    // モーダルで使用するもとになったアイデアを取得
    const req = new OwnerIdeasGetRequest()
    req.adminIdeaId = ideaId
    await ownerIdeasModule.fetchIdeas(req)
  }

  async onClickExecute($router: VueRouter, inputs: FormInputs): Promise<void> {
    const req = new AdminIdeasPostRequest(inputs.title, inputs.opinionDeadline, inputs.deadline, inputs.isNotAcceptable, this.ideaId)
    if (inputs.baseIdeaId) req.baseIdea = new BaseIdea(inputs.baseIdeaId)
    if (inputs.sessions.length > 0) req.sessions = inputs.sessions
    if (inputs.plans.length > 0) req.plans = inputs.plans

    await uploadThenPost(req, adminIdeasModule.postIdeas)
    $router.push({ name: staticRoutes.ideasList.name })
  }

  async onClickSaveDraft($router: VueRouter, inputs: FormInputs): Promise<void> {
    const req = new AdminIdeasDraftPostRequest(inputs.title, inputs.opinionDeadline, inputs.deadline, inputs.isNotAcceptable, this.ideaId)
    if (inputs.baseIdeaId) req.baseIdea = new BaseIdea(inputs.baseIdeaId)
    if (inputs.sessions.length > 0) req.sessions = inputs.sessions
    if (inputs.plans.length > 0) req.plans = inputs.plans

    await uploadThenPost(req, adminIdeasModule.postAdminIdeaDraft)
    $router.push({ name: staticRoutes.ideasList.name })
  }

  async onClickDeleteDraft($router: VueRouter): Promise<void> {
    await adminIdeasModule.deleteDraftIdea(new AdminIdeasDraftDeleteRequest(this.ideaId))

    $router.go(-1)
  }

  isPast() { return false }
}

class UpdateSpecifications implements PageTypeSpecifications {
  ideaId: string
  ideaState: IdeaState
  dialogMessage: string
  constructor(ideaId: string, ideaState:IdeaState) {
    this.ideaId = ideaId
    this.ideaState = ideaState
    this.dialogMessage = this.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT ? '賛同受付期間中です。プランを更新してよろしいですか？' : 'プランを更新します。よろしいですか？'
  }

  readonly pageTitle = 'プランを編集する'
  readonly executeBtnLabel = '更新する'
  readonly isDraft = false

  async created() { /** nothing to do */ }

  async onClickExecute($router: VueRouter, inputs: FormInputs): Promise<void> {
    const req = new AdminIdeasPutRequest(this.ideaId, inputs.title, inputs.opinionDeadline, inputs.deadline, inputs.isNotAcceptable, inputs.plans, inputs.version)
    if (inputs.baseIdeaId) req.baseIdea = new BaseIdea(inputs.baseIdeaId)

    await uploadThenPost(req, adminIdeasModule.putAdminIdeas)
    $router.go(-1) // プラン詳細画面で矢印アイコンから遷移元の画面に戻れるようにする
  }

  onClickSaveDraft() { /** nothing to do */ }
  onClickDeleteDraft() { /** nothing to do */ }

  isPast(targetDate: string | undefined) {
    if (targetDate === undefined) return false // targetDateの値がAPIから取得される前に呼び出された場合にエラーが起きないようにする
    return new Date(targetDate).getTime() < new Date().getTime()
  }
}

const TAKE = 10

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardSectionMoney: () => import('@/components/molecules/card/SmCardSectionMoney.vue'),
    SmCardSectionText: () => import('@/components/molecules/card/SmCardSectionText.vue'),
    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmDraftInterceptor: () => import('@/components/organisms/SmDraftInterceptor.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    OwnerIdeasSelectModal: () => import('@/pages/ideas/owner-idea/OwnerIdeasSelectModal.vue'),
    OpinionPostUserSelectModal: () => import('@/pages/ideas/admin-idea/OpinionPostUserSelectModal.vue'),
  }
})

export default class AdminIdeaPostPage extends Vue {
  PAGE_TYPES = Object.freeze(PAGE_TYPES)
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop()
  baseIdeaId?: string

  @Prop()
  ideaId?: string

  @Prop({ required: true, default: PAGE_TYPES.CREATE })
  private readonly pageType!: PageType

  private headerIcon = '$arrow_back_round'

  private buildingId: string | null = null

  async created(): Promise<void> {
    // プレビュー画面>内部リンク遷移で使用する物件IDを取得
    this.buildingId = currentStateModule.currentBuildingId
    const buildingReq = new BuildingOwnersGetRequest()
    buildingReq.buildingId = this.buildingId ?? ''
    await buildingsModule.fetchBuildingOwners(buildingReq)
    if (this.ideaId) {
      const req = new AdminIdeaDetailGetRequest(this.ideaId)
      const sessionReq = new AdminIdeaSessionGetRequest(this.ideaId)
      const opinionReq = new AdminIdeaOpinionsGetRequest()
      opinionReq.ideaId = this.ideaId
      opinionReq.take = TAKE
      const agreementReq = new AdminIdeaResultsGetRequest()
      agreementReq.ideaId = this.ideaId
      const updatereq = new AdminIdeaUpdatesGetRequest()
      updatereq.take = TAKE
      updatereq.ideaId = this.ideaId
      await adminIdeasModule.fetchIdeaDetail(req)
      await adminIdeasModule.fetchIdeaSession(sessionReq)
      await adminIdeasModule.fetchIdeaOpinions(opinionReq)
      await adminIdeasModule.fetchIdeaResults(agreementReq)
      await adminIdeasModule.fetchIdeaUpdates(updatereq)
      if (this.storedAdminIdea?.ideaState !== IDEA_STATES.ADMIN.DRAFT) this.headerIcon = '$close_round'
    }
    await this.typeSpecs.created(this.baseIdeaId, this.ideaId)
    if (this.baseIdeaId) {
      this.headerIcon = '$close_round'
      this.inputs.baseIdeaId = this.baseIdeaId
    }
    this.inputs.ideaId = this.ideaId

    // 意見受付締切日時が過ぎているかどうかを判定
    this.disabledOpinionDeadline = this.typeSpecs.isPast(this.storedAdminIdea?.opinionDeadlineDateTime)
    // 賛同受付締切日時が過ぎているかどうかを判定
    this.disabledDeadline = this.typeSpecs.isPast(this.storedAdminIdea?.deadlineDateTime)
  }

  private get typeSpecs(): PageTypeSpecifications {
    if (!this.ideaId || !this.storedAdminIdea?.ideaState) return new CreateSpecifications()

    switch (this.pageType) {
      case PAGE_TYPES.CREATE: return new DraftSpecifications(this.ideaId)
      case PAGE_TYPES.EDIT: return new UpdateSpecifications(this.ideaId, this.storedAdminIdea.ideaState)
      default: return assertExhaustive(this.pageType)
    }
  }

  get isPageTypeEdit(): boolean { return this.pageType === PAGE_TYPES.EDIT }

  inputs = new FormInputs()
  disabledOpinionDeadline = false
  disabledDeadline = false

  private get ownerCount(): number {
    return buildingsModule.buildingOwnersGet.owners.length
  }

  private get baseIdea(): OwnerIdeaDetailGetResponse | null {
    if (this.inputs.baseIdeaId) return ownerIdeasModule.detailResponse(this.inputs.baseIdeaId) ?? null
    return null
  }

  private get isDisplayBaseIdea(): boolean {
    switch (this.pageType) {
      case PAGE_TYPES.CREATE: return true
      case PAGE_TYPES.EDIT: return !!this.baseIdea
      default: return assertExhaustive(this.pageType)
    }
  }

  baseIdeaExpansion = false
  showPostDialog = false
  showDraftDeleteDialog = false
  showDraftPostDialog = false

  private hours(): { value: number, label: string }[] {
    const hours : { value: number, label: string }[] = []
    for (let i = 0; i <= 23; i++) {
      hours.push({ value: i, label: ('0' + i).slice(-2) }) // 数字を2桁で表示
    }
    return hours
  }

  private get storedAdminIdea(): AdminIdeaDetailGetResponse | undefined {
    if (!this.ideaId) return undefined
    return adminIdeasModule.detailResponse(this.ideaId)
  }

  private get ideaOpinions(): AdminIdeaOpinionsGetResponse | undefined {
    if (!this.ideaId) return
    return adminIdeasModule.adminIdeasOpinionsGet(this.ideaId) ?? new AdminIdeaOpinionsGetResponse()
  }

  private get ideaAgreement(): AdminIdeaResultsGetResponse | undefined {
    if (!this.ideaId) return
    return adminIdeasModule.adminIdeasResultsGet(this.ideaId) ?? new AdminIdeaResultsGetResponse()
  }

  private get ideaSession():AdminIdeaSessionGetResponse | undefined {
    if (!this.ideaId) return
    const res = adminIdeasModule.adminIdeasSessionGet(this.ideaId) ?? new AdminIdeaSessionGetResponse()
    return deepCopy(
      res,
      {
        AdminIdeaSessionGetResponse: new ColumnToType(AdminIdeaSessionGetResponse),
        details: new ColumnToType(Details),
        user: new ColumnToType(User),
        material: new ColumnToType(Material),
        sessions: new ColumnToType(SessionGetResponseSession, true),
        guide: new ColumnToType(Guide),
        result: new ColumnToType(Result),
        sections: new ColumnToType(Section, true),
      },
      'AdminIdeaSessionGetResponse')
  }

  private get ideaUpdate(): AdminIdeaUpdatesGetResponse | undefined {
    if (!this.ideaId) return
    return adminIdeasModule.adminIdeasUpdatesGet(this.ideaId) ?? new AdminIdeaUpdatesGetResponse()
  }

  // 意見受付期間と賛同受付期間を同期する
  private synchronizeAgreementDateTime():void {
    this.inputs.agreementDate = this.inputs.opinionDate
    this.inputs.agreementHour = this.inputs.opinionHour
    this.inputs.agreementMinute = this.inputs.opinionMinute
  }

  // 「賛同期間を設けない」チェックボックスが切替時に、意見受付期間と賛同受付期間を同期する
  private opinionDeadlineToAgreementDeadline():void {
    if (this.inputs.isNotAcceptable) {
      this.synchronizeAgreementDateTime()
    }
  }

  private get disableIsNotAcceptable():boolean {
    return this.storedAdminIdea?.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT || this.storedAdminIdea?.ideaState === IDEA_STATES.ADMIN.COUNTING
  }

  // 「賛同期間を設けない」チェックボックスがOFFの場合の、プラン必須チェック
  private get isNotAcceptableNeedsPlans():boolean {
    return this.inputs.isNotAcceptable ? false : this.inputs.plans.length === 0
  }

  /**
   * プランを追加するボタンの活性・非活性制御
   * プランの最大登録可能数は26
   */
  private get addPlanLimitOverDisabled(): boolean {
    return this.inputs.plans.length >= 26
  }

  @Watch('storedAdminIdea', { immediate: false, deep: false })
  async onStoredAdminIdeaFetched(fetched: AdminIdeaDetailGetResponse | undefined): Promise<void> {
    if (!fetched) return

    // 取得した結果、もとにしたアイデアが登録されていた場合はその情報も追加で取得
    const baseIdeaId = fetched.baseIdea?.ideaId
    if (baseIdeaId) {
      await ownerIdeasModule.fetchIdeaDetail({ ideaId: baseIdeaId })
      this.inputs.baseIdeaId = baseIdeaId
    }

    // 意見交換会の取得
    if (this.pageType === PAGE_TYPES.CREATE) {
      await adminIdeasModule.fetchIdeaSession({ ideaId: fetched.ideaId })
      const ideaSession = adminIdeasModule.adminIdeasSessionGet(fetched.ideaId)

      const copySpecs = { // minimum
        sections: new ColumnToType(Section, true),
        material: new ColumnToType(MaterialFormInputDto),
      }
      if (ideaSession && ideaSession.sessions) {
        this.inputs.sessions = ideaSession.sessions.map(_ => {
          const copy = deepCopy(_.guide, copySpecs, '-NO_NEED-')
          const session = new Session()
          session.itemType = ITEM_TYPES.SESSION
          session.itemTitle = copy.itemTitle
          session.itemBody = copy.itemBody
          session.material = copy.material
          if (copy.sections) session.sections = copy.sections
          return session
        })
      }
    }

    this.inputs.title = fetched.title

    this.inputs.opinionDate = fetched.opinionDeadlineDateTime.substring(0, 10)
    this.inputs.opinionHour = Number(fetched.opinionDeadlineDateTime.substring(11, 13))
    this.inputs.opinionMinute = Number(fetched.opinionDeadlineDateTime.substring(14, 16))

    this.inputs.agreementDate = fetched.deadlineDateTime.substring(0, 10)
    this.inputs.agreementHour = Number(fetched.deadlineDateTime.substring(11, 13))
    this.inputs.agreementMinute = Number(fetched.deadlineDateTime.substring(14, 16))

    this.inputs.isNotAcceptable = fetched.isNotAcceptable
    this.inputs.plans = deepCopy(
      fetched.plans,
      {
        plans: new ColumnToType(Plan, true),
        ownerUsers: new ColumnToType(User),
        estimates: new ColumnToType(Estimate, true),
        sections: new ColumnToType(Section, true),
        material: new ColumnToType(MaterialFormInputDto),
      },
      'plans'
    )
    this.inputs.version = fetched.version
  }

  // 各種実行ボタン
  async onClickExecute(): Promise<void> {
    this.opinionDeadlineToAgreementDeadline()
    this.processCompleted = true
    this.showPostDialog = false
    await this.typeSpecs.onClickExecute(this.$router, this.inputs)
  }

  async onClickSaveDraft(): Promise<void> {
    this.opinionDeadlineToAgreementDeadline()
    this.processCompleted = true
    this.showDraftPostDialog = false
    await this.typeSpecs.onClickSaveDraft(this.$router, this.inputs)
  }

  async onClickDeleteDraft(): Promise<void> {
    this.processCompleted = true
    this.showDraftDeleteDialog = false
    await this.typeSpecs.onClickDeleteDraft(this.$router)
  }

  // 素材IDと取得済み参照用URLを含む素材情報の組み合わせを保持
  materialReferenceURLMap: Map<string, MaterialFormInputDto> = new Map()

  async onClickPreviewBtn(): Promise<void> {
    const previewContents: AdminIdeaPreviewContent = new AdminIdeaPreviewContent()
    // 新規作成のアイデアの場合ideaIdがないため、存在確認
    if (this.inputs.ideaId) previewContents.ideaId = this.inputs.ideaId
    previewContents.title = this.inputs.title
    previewContents.ideaState = this.storedAdminIdea ? this.storedAdminIdea.ideaState : IDEA_STATES.ADMIN.ACCEPTING_OPINION
    previewContents.isAgreementDenied = this.inputs.isNotAcceptable
    previewContents.previewSession = (!this.storedAdminIdea?.ideaState || this.storedAdminIdea?.ideaState === IDEA_STATES.ADMIN.DRAFT) ? await this.toSessionPreviewDto(this.inputs.sessions) : this.ideaSession
    previewContents.previewPlans = await this.toPlansPreviewDto(this.inputs.plans, this.ideaAgreement)
    previewContents.previewOpinions = this.ideaOpinions?.opinions
    if (this.inputs.baseIdeaId) previewContents.previewBaseIdea = new BaseIdea(this.inputs.baseIdeaId)
    previewContents.agreedOwnerCount = this.ideaAgreement?.summary.agreedOwnerCount ?? 0
    previewContents.opinionDeadline = this.toDeadlineFormat(this.inputs.opinionDate, this.inputs.opinionHour, this.inputs.opinionMinute)
    previewContents.deadline = this.toDeadlineFormat(this.inputs.agreementDate, this.inputs.agreementHour, this.inputs.agreementMinute)
    previewContents.lastUpdatedAt = this.storedAdminIdea?.processedAt
    if (this.storedAdminIdea?.user) previewContents.user = this.storedAdminIdea?.user
    previewContents.postedAt = this.storedAdminIdea?.postedAt ?? this.nowDate()
    previewContents.currentBuildingId = this.buildingId ?? ''
    previewContents.ownerCount = this.ownerCount
    previewContents.agreementRequirement = buildingsModule.buildingDetailGet?.agreementRequirement ?? ''
    previewContents.isUpdates = this.ideaUpdate?.updates ? this.ideaUpdate?.updates.length > 0 : false

    const previewContentsId = generateUuid()
    newTabLocalParamStorageModule.setAdminIdeaPreviewContent({ key: previewContentsId, adminIdeaPreviewContent: previewContents })
    windowOpen(staticRoutes.adminIdeaPreview.path.replace(':id', previewContentsId))
  }

  async toSessionPreviewDto(sessions: Session[]): Promise<PreviewSession> {
    const pewviewSessionDto = new PreviewSession()
    pewviewSessionDto.postedAt = this.nowDate()
    pewviewSessionDto.sessions = await Promise.all(sessions.map(async session => {
      const previewSession = new SessionGetResponseSession()
      previewSession.guide = new Guide()
      if (session.itemTitle) previewSession.guide.itemTitle = session.itemTitle
      if (session.itemBody) previewSession.guide.itemBody = session.itemBody
      if (session.material) {
        previewSession.guide.material = await this._uploadMaterial(Object.assign(new MaterialFormInputDto(), session.material))
        // 画面に表示中の素材データにもIDを格納することで投稿／編集時に素材を再登録しなくて済むようにする
        session.material = previewSession.guide.material
      }
      if (session.sections) previewSession.guide.sections = session.sections.map(section => section)
      return previewSession
    }))
    return pewviewSessionDto
  }

  private nowDate(): string {
    const date = new Date()
    const numberOfDate = date.getDay()
    const dayOfWeek = DAY_OF_WEEK[numberOfDate]
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日(${dayOfWeek})`
  }

  async toPlansPreviewDto(plans: Plan[], ideaAgreement: AdminIdeaResultsGetResponse | undefined): Promise<PreviewPlan[]> {
    const previewPlans = await Promise.all(plans.map(async plan => {
      const previewPlan = new PreviewPlan()
      previewPlan.planId = plan.planId
      previewPlan.title = plan.itemTitle
      // 賛同ボタンの押下処理は必要ないため、falseで固定
      previewPlan.isAgreed = false
      previewPlan.agreementCount = ideaAgreement ? this.getAgreementCount(plan.planId, ideaAgreement) : 0
      previewPlan.budgets = plan.estimates.map(estimate => estimate)
      previewPlan.previewElements = await Promise.all(plan.sections.map(async section => {
        if (section.material) {
          section.material = await this._uploadMaterial(Object.assign(new MaterialFormInputDto(), section.material))
        }
        return section
      }))
      return previewPlan
    }))
    return previewPlans
  }

  getAgreementCount(planId: string, ideaAgreement: AdminIdeaResultsGetResponse): number {
    return ideaAgreement.agreements.find(agreement => agreement.planId === planId)?.agreementCount ?? 0
  }

  toOpinionsPreviewDto(adminIdeaOpinions: AdminIdeaOpinionsGetResponse): Opinion[] {
    const previewOpinions = adminIdeaOpinions.opinions.map(opinion => opinion)
    return previewOpinions
  }

  toDeadlineFormat(date: string, hour: number, minute: number): string {
    const dadlineDayOfWeek = DAY_OF_WEEK[new Date(date).getDay()]
    const deadlineDate = `${date.replace('-', '年').replace('-', '月')}日`
    const deadlineHour = hour < 10 ? `0${hour}` : hour
    const deadlineMinute = minute < 10 ? `0${minute}` : minute
    return `${deadlineDate}(${dadlineDayOfWeek}) ${deadlineHour}:${deadlineMinute}`
  }

  private async _uploadMaterial(material: MaterialFormInputDto): Promise<MaterialFormInputDto | undefined> {
    if (!material.materialId) { // 新たに添付した素材の場合
      // ローカルストレージのサイズの制約上、プレビュー時に素材をそのまま別タブに渡すのが難しいため、ここでアップロードする
      const uploadedMaterial = await uploadMaterial(material)
      if (uploadedMaterial) {
        this.materialReferenceURLMap.set(uploadedMaterial.materialId, uploadedMaterial)
        return uploadedMaterial
      }
    } else if (this.materialReferenceURLMap.get(material.materialId)) { // 一度プレビューした素材を付け替えずに使用する場合
      const renamedMaterial = this.materialReferenceURLMap.get(material.materialId)
      if (renamedMaterial) {
        renamedMaterial.originalFileName = material.originalFileName
        renamedMaterial.originalFileNameInitialCopy = material.originalFileNameInitialCopy
      }
      return renamedMaterial
    } else { // すでにDBに登録済みの素材を画面初期表示から一度も付け替えずに使用する場合
      return material
    }
  }

  isOwnerIdeaSelectModalVisible = false
  ownerIdeaSelectModalKey = generateUuid()
  ownerIdeaSelectModalKeyword = ''
  ownerIdeaSelectModalInputText = ''
  openOwnerIdeaSelectModal(): void {
    this.ownerIdeaSelectModalKey = generateUuid()
    this.isOwnerIdeaSelectModalVisible = true
  }

  async onSelectedBaseIdea(baseIdeaId: string): Promise<void> {
    this.isOwnerIdeaSelectModalVisible = false
    this.inputs.baseIdeaId = baseIdeaId
    await ownerIdeasModule.fetchIdeaDetail({ ideaId: baseIdeaId })
  }

  onReleaseBaseIdea(): void {
    this.ownerIdeaSelectModalKeyword = ''
    this.ownerIdeaSelectModalInputText = ''
    this.inputs.baseIdeaId = null
  }

  deleteOwnerUser(opinionId: string, planIndex:number): void {
    this.inputs.plans[planIndex].baseOpinions = this.inputs.plans[planIndex].baseOpinions.filter(b => b.opinionId !== opinionId)
  }

  onSelectedOpinionUser(baseOpinions: BaseOpinion[], planIndex: number): void {
    this.inputs.plans[planIndex].baseOpinions = baseOpinions
  }

  addSectionToSession(sessionIndex: number): void {
    this.inputs.sessions[sessionIndex].sections.push(this.createSection())
  }

  addSectionToPlan(planIndex: number): void {
    this.inputs.plans[planIndex].sections.push(this.createSection())
  }

  addEstimateToPlan(planIndex: number): void {
    this.inputs.plans[planIndex].estimates.push(this.createEstimate())
  }

  addSession(): void {
    const session = staticKeyProvider.create(Session)
    session.itemType = ITEM_TYPES.SESSION

    session.sections.push(this.createSection())

    session.itemTitle = '意見交換会のご案内'
    this.inputs.sessions.push(session)
  }

  addPlan(): void {
    const plan = staticKeyProvider.create(Plan)
    plan.itemType = ITEM_TYPES.PLAN

    plan.estimates.push(this.createEstimate())
    plan.sections.push(this.createSection())
    this.inputs.plans.push(plan)
  }

  createEstimate(): Estimate {
    const estimate = staticKeyProvider.create(Estimate)
    estimate.expenseLabel = ''
    estimate.budgetLabel = ''
    estimate.balanceLabel = ''
    estimate.expense = undefined
    estimate.budget = undefined
    estimate.spent = undefined
    return estimate
  }

  createSection(): Section {
    const section = staticKeyProvider.create(Section)
    section.sectionTitle = ''
    section.sectionBody = ''
    return section
  }

  deleteSectionFromSession(sessionIndex: number, sectionIndex: number): void {
    this.inputs.sessions[sessionIndex].sections.splice(sectionIndex, 1)
  }

  deleteSectionFromPlan(planIndex: number, sectionIndex: number): void {
    this.inputs.plans[planIndex].sections.splice(sectionIndex, 1)
  }

  deleteEstimateFromPlan(planIndex: number, estimateIndex: number): void {
    this.inputs.plans[planIndex].estimates.splice(estimateIndex, 1)
  }

  deletePlan(planIndex: number): void {
    this.inputs.plans.splice(planIndex, 1)
  }

  deleteSession(sessionIndex: number): void {
    this.inputs.sessions.splice(sessionIndex, 1)
  }

  // 上下のカードを入れ替えるメソッドたち
  goUpSectionInSession(sessionIndex: number, sectionIndex: number): void {
    const targetSections = this.inputs.sessions[sessionIndex].sections
    targetSections.splice(sectionIndex - 1, 2, targetSections[sectionIndex], targetSections[sectionIndex - 1])
  }

  goDownSectionInSession(sessionIndex: number, sectionIndex: number): void {
    const targetSections = this.inputs.sessions[sessionIndex].sections
    targetSections.splice(sectionIndex, 2, targetSections[sectionIndex + 1], targetSections[sectionIndex])
  }

  goUpSectionInPlan(planIndex: number, sectionIndex: number): void {
    const targetSections = this.inputs.plans[planIndex].sections
    targetSections.splice(sectionIndex - 1, 2, targetSections[sectionIndex], targetSections[sectionIndex - 1])
  }

  goDownSectionInPlan(planIndex: number, sectionIndex: number): void {
    const targetSections = this.inputs.plans[planIndex].sections
    targetSections.splice(sectionIndex, 2, targetSections[sectionIndex + 1], targetSections[sectionIndex])
  }

  goUpEstimateInPlan(planIndex: number, estimateIndex: number): void {
    const targetEstimates = this.inputs.plans[planIndex].estimates
    targetEstimates.splice(estimateIndex - 1, 2, targetEstimates[estimateIndex], targetEstimates[estimateIndex - 1])
  }

  goDownEstimateInPlan(planIndex: number, estimateIndex: number): void {
    const targetEstimates = this.inputs.plans[planIndex].estimates
    targetEstimates.splice(estimateIndex, 2, targetEstimates[estimateIndex + 1], targetEstimates[estimateIndex])
  }

  nextRoute: Route | null = null
  processCompleted = false
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (this.isPageTypeEdit || this.processCompleted || this.nextRoute) {
      next()
    } else {
      this.nextRoute = to
      next(false)
    }
  }

  leaveHere(): void {
    const routeName = this.nextRoute?.name
    const routeParams = this.nextRoute?.params
    if (routeName) {
      if (routeName === staticRoutes.ownerIdeaDetail.name) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: routeName, params: routeParams })
      }
    }
  }

  getPlanTitleWithPrefix(planIndex: number): string {
    return concatSerialChar(this.inputs.plans.length, planIndex)
  }
}
