
































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { gmResolutionsModule } from '@/stores/gm-resolutions-store'
import { StatementType, STATEMENT_TYPES, VOTE_TYPES } from '@/constants/schema-constants'
import { SUBJECT_ABBR_LENGTH } from '@/constants/ux-constants'
import { Header } from '@/components/molecules/SmTableData.vue'
import { Vote } from '@/dtos/resolutions/gm-resolution/statements/common'
import { StatementsGetResponse, Statement, SummaryVote } from '@/dtos/resolutions/gm-resolution/statements/get'
import { StatementsPutRequest, StatementForPut } from '@/dtos/resolutions/gm-resolution/statements/put'
import { DisplayingStatement } from '@/pages/resolutions/gm-resolution/StatementsUpdateTable.vue'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'

/**
 * 与えられた議案を省略表示した値（例：【第1号…）を返す
 */
function getSubjectAbbr(subjectTitle:string):string {
  if (subjectTitle.length <= SUBJECT_ABBR_LENGTH) return subjectTitle
  return subjectTitle.slice(0, SUBJECT_ABBR_LENGTH) + '…'
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    StatementsUpdateTable: () => import('@/pages/resolutions/gm-resolution/StatementsUpdateTable.vue'),
  }
})
export default class StatementsUpdatePage extends Vue {
  @Prop({ required: true })
  private readonly resolutionId!: string

  // ヘッダー用
  statementHeaders:Header[] = []

  // 画面表示用のデータ
  displayingStatements: DisplayingStatement[] = []
  showDialog = false

  /**
   * textfieldに入力された値
   */
  private textValue = ''
  /**
   * 検索時にコンポーネントに検索ワードを渡すための変数
   */
  private keyword = ''

  async created():Promise<void> {
    await gmResolutionsModule.fetchStatements(this.resolutionId)
    this.displayingStatements = this.convertDisplayingStatements(this.res.statements)
    this.makeHeader()
  }

  private get res():StatementsGetResponse {
    return gmResolutionsModule.statementsGet(this.resolutionId) ?? new StatementsGetResponse()
  }

  private get subjects(): SummaryVote[] {
    return this.res.summary.statement.details.vote ?? []
  }

  // サーバから取得したデータを画面表示用のデータに変換
  private convertDisplayingStatements(statements:Statement[]):DisplayingStatement[] {
    const newStatements = deepCopy(
      statements,
      {
        statements: new ColumnToType(Statement),
        vote: new ColumnToType(Vote),
      },
      'statements'
    )

    return newStatements.map(e =>
      new DisplayingStatement(
        e.userId,
        e.roomNumber,
        e.userName,
        e.votingRightCount,
        e.statementType,
        e.isStatement,
        this.initializeVote(e.vote),
      )
    )
  }

  // 画面に表示するための空のVoteを作成する
  private initializeVote(votes:Vote[]):Vote[] {
    if (votes.length > 0) return votes
    return this.subjects.map(e => this.makeBlankVote(e.subjectId))
  }

  private makeBlankVote(subjectId:string): Vote {
    const vote = new Vote()
    vote.subjectId = subjectId
    vote.voteType = VOTE_TYPES.BLANK
    return vote
  }

  // 議案列のテーブルヘッダー定義の設定
  private makeHeader():void {
    const header: Header[] = [
      new Header({ text: '住戸番号', value: 'roomNumber', width: '113px' }),
      new Header({ text: '氏名', value: 'userName', width: '144px' }),
      new Header({ text: '保有議決権', value: 'votingRightCount', width: '120px', filterable: false }),
      new Header({ text: '意思表明', value: 'isPresent', width: '125px', filterable: false }),
      new Header({ text: '', value: 'reset', width: '100px', sortable: false, filterable: false }),
    ]
    this.subjects.forEach((e, i) => header.push(
      new Header({ text: getSubjectAbbr(e.subjectTitle), value: `vote[${i}].voteType`, width: '150px', sortable: false, filterable: false })
    ))
    this.statementHeaders = header
  }

  // 更新ボタン処理
  async updateStatements():Promise<void> {
    const req = this.getRequest(this.displayingStatements)
    this.showDialog = false
    await gmResolutionsModule.putStatements(req)
    this.$router.go(-1)
  }

  private getRequest(statements:DisplayingStatement[]):StatementsPutRequest {
    return new StatementsPutRequest(
      this.resolutionId,
      statements.map(s =>
        new StatementForPut(
          s.userId,
          s.votingRightCount,
          s.statementType,
          this.convertVoteIntoReq(s.vote, s.statementType)
        )
      ),
      this.res.version
    )
  }

  // 初期化した空のVoteを取り除く
  private convertVoteIntoReq(votes:Vote[], statementType:StatementType|undefined|string):Vote[] | undefined {
    if (statementType === STATEMENT_TYPES.VOTE || statementType === STATEMENT_TYPES.PRESENT_REMOTE) return votes
    return undefined
  }
}
