import { assertExhaustive } from '@/libs/exhaustive-helper'
import { NAVIGATION_GROUP } from '@/routes'
import type { NavigationGroup } from '@/routes'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { TRANSITION_PATTERNS } from '@/constants/ux-constants'
import type { TransitionPattern, WidthPattern } from '@/constants/ux-constants'

export const NAVIGATIONS = {
  building: { id: 'building', name: 'マンション情報', icon: '$text_snippet_outlined' },
  owner: { id: 'owner', name: '区分所有者', icon: '$groups_outlined' },
  idea: { id: 'idea', name: 'アイデア・プラン', icon: '$sm-idea-plan-icon' },
  resolution: { id: 'resolution', name: '決議', icon: '$how_to_vote_outlined' },
  repairPlan: { id: 'repairPlan', name: '長期修繕計画', icon: '$insert_chart_outlined' },
  repairReserveFund: { id: 'repairReserveFund', name: '修繕積立金', icon: '$receipt_long_outlined' },
  consultation: { id: 'consultation', name: '相談・連絡', icon: '$sm-chat' },
  report: { id: 'report', name: '通報（報告）', icon: 'outlined_flag' },
} as const
export type NavigationId = keyof typeof NAVIGATIONS | 'NOT_SELECTED'

/**
 * 共通のページ構造に関する値を保持するモジュール
 */
@Module({ store, dynamic: true, namespaced: true, name: 'structureStore' })
class StructureStore extends VuexModule {
  private _currentNavigation: NavigationId = 'NOT_SELECTED'
  private _systemBarVisible = true
  private _drawerVisible = true
  private _overlayStacks = 0 // progress-overlayの表示の要求数（1以上で表示を想定）
  private _widthPattern:WidthPattern | undefined = undefined // 通常以外の横幅の指定。また、印刷時systemBar・appBar非表示の有無
  private _transitionPattern:TransitionPattern = TRANSITION_PATTERNS.NO_ANIMATION // 画面遷移時アニメーション
  private _systemBarBtnsVisible = true
  private _isPreview = false

  get currentNavigation(): NavigationId { return this._currentNavigation }

  get systemBarVisible():boolean { return this._systemBarVisible }

  get navigationVisible(): boolean { return this._drawerVisible }

  get showOverlay(): boolean { return this._overlayStacks > 0 }

  get widthPattern(): WidthPattern | undefined { return this._widthPattern }

  get transitionPattern(): TransitionPattern { return this._transitionPattern }

  get systemBarBtnsVisible(): boolean { return this._systemBarBtnsVisible }

  get isPreview(): boolean { return this._isPreview }

  @Mutation
  private SET_CURRENT_NAVIGATION(id: NavigationId): void { this._currentNavigation = id }

  @Mutation
  private SET_SYSTEM_BAR_VISIBLE(systemBarVisible: boolean):void { this._systemBarVisible = systemBarVisible }

  @Mutation
  private SET_NAVIGATION_VISIBLE(drawerVisible: boolean):void { this._drawerVisible = drawerVisible }

  @Mutation
  private ADD_OVERLAY_STACK(diff: number): void { this._overlayStacks += diff }

  @Mutation
  private SET_WIDTH_PATTERN(widthPattern?: WidthPattern): void { this._widthPattern = widthPattern }

  @Mutation
  private SET_TRANSITION_PATTERN(transactionPattern: TransitionPattern): void { this._transitionPattern = transactionPattern }

  @Mutation
  private SET_SYSTEM_BAR_BTNS_VISIBLE(systemBarBtnsVisible: boolean): void { this._systemBarBtnsVisible = systemBarBtnsVisible }

  @Mutation
  private SET_IS_PREVIEW(isPreview: boolean): void { this._isPreview = isPreview }

  @Action
  public changeCurrentNavigation(groupOfRoutingTo: NavigationGroup): void {
    switch (groupOfRoutingTo) {
      case NAVIGATION_GROUP.BUILDING: this.SET_CURRENT_NAVIGATION(NAVIGATIONS.building.id); break
      case NAVIGATION_GROUP.OWNER: this.SET_CURRENT_NAVIGATION(NAVIGATIONS.owner.id); break
      case NAVIGATION_GROUP.IDEA: this.SET_CURRENT_NAVIGATION(NAVIGATIONS.idea.id); break
      case NAVIGATION_GROUP.RESOLUTION: this.SET_CURRENT_NAVIGATION(NAVIGATIONS.resolution.id); break
      case NAVIGATION_GROUP.REPAIR_PLAN: this.SET_CURRENT_NAVIGATION(NAVIGATIONS.repairPlan.id); break
      case NAVIGATION_GROUP.REPAIR_RESERVE_FUND: this.SET_CURRENT_NAVIGATION(NAVIGATIONS.repairReserveFund.id); break
      case NAVIGATION_GROUP.CONSULTATION: this.SET_CURRENT_NAVIGATION(NAVIGATIONS.consultation.id); break
      case NAVIGATION_GROUP.REPORT: this.SET_CURRENT_NAVIGATION(NAVIGATIONS.report.id); break
      case NAVIGATION_GROUP.INDEPENDENT: this.SET_CURRENT_NAVIGATION('NOT_SELECTED'); break
      default: return assertExhaustive(groupOfRoutingTo)
    }
  }

  @Action
  public showNavigation():void {
    this.SET_NAVIGATION_VISIBLE(true)
  }

  @Action
  public hideNavigation(): void {
    this.SET_NAVIGATION_VISIBLE(false)
  }

  @Action
  public showSystemBar():void {
    this.SET_SYSTEM_BAR_VISIBLE(true)
  }

  @Action
  public hideSystemBar(): void {
    this.SET_SYSTEM_BAR_VISIBLE(false)
  }

  @Action public requestShowProgressOverlay(): void { this.ADD_OVERLAY_STACK(1) }
  @Action public requestHideProgressOverlay(): void { this.ADD_OVERLAY_STACK(-1) }
  @Action public forceHideProgressOverlay(): void { this.ADD_OVERLAY_STACK(-1 * this._overlayStacks) }

  @Action
  public setWidthPattern(widthPattern?:WidthPattern):void {
    this.SET_WIDTH_PATTERN(widthPattern)
  }

  @Action
  public setTransitionPattern(transitionPattern:TransitionPattern):void {
    this.SET_TRANSITION_PATTERN(transitionPattern)
  }

  @Action
  public showSystemBarBtns(): void {
    this.SET_SYSTEM_BAR_BTNS_VISIBLE(true)
  }

  @Action
  public hideSystemBarBtns(): void {
    this.SET_SYSTEM_BAR_BTNS_VISIBLE(false)
  }

  @Action
  public setIsPreview(isPreview: boolean): void {
    this.SET_IS_PREVIEW(isPreview)
  }
}

export const structureModule = getModule(StructureStore)
