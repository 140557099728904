




























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmTooltip extends Vue {
  @Prop({ required: true, default: '$sticky_note_2_round ' })
  private readonly icon!: string

  @Prop({ required: true, default: 'secondary' })
  private readonly iconColor!: string

  @Prop({ required: true, default: '' })
  private readonly tooltipText!: string

  @Prop({ required: true, default: 'white' })
  private readonly tooltipTextColor!: string

  @Prop({ required: true, default: 'secondary' })
  private readonly tooltipColor!: string
}
