import { NoticesPostRequest, NoticesPostResponse } from '@/dtos/notices/post'
import { NoticeReadPutRequest } from '@/dtos/notices/read/put'
import { APIClientBase } from './api-client-base'

class NoticesClient extends APIClientBase {
  constructor() {
    super('/notices')
  }

  postNotices(req:NoticesPostRequest):Promise<void | NoticesPostResponse> {
    return this.post('', req, NoticesPostResponse, false)
  }

  putNoticesRead(req:NoticeReadPutRequest):Promise<void> {
    return this.put(`${req.noticeId}/reads`, undefined)
  }
}

export const noticesClient = new NoticesClient()
