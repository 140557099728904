import { APIClientBase } from '@/clients/api-client-base'
import { PaymentPlanGetRequest, PaymentPlansGetResponse } from '@/dtos/reserve-funds/plans/get'
import { ReservePlanDetailGetRequest, ReservePlanDetailGetResponse } from '@/dtos/reserve-funds/plans/get-detail'
import { PaymentPlanInitialFormGetResponse } from '@/dtos/reserve-funds/plans/initial-form/get-detail'
import { PaymentPlanPostRequest } from '@/dtos/reserve-funds/plans/post'

class ReserveFundsPlansClient extends APIClientBase {
  constructor() {
    super('/reserves/plans')
  }

  async getReservePlans(req:PaymentPlanGetRequest) :Promise<PaymentPlansGetResponse> {
    return await this.get('/', req, PaymentPlansGetResponse)
  }

  async postReservePlan(req:PaymentPlanPostRequest) :Promise<void> {
    await this.post('/', req)
  }

  async getReservePlansInitialForm():Promise<PaymentPlanInitialFormGetResponse> {
    return await this.get('initialForm', undefined, PaymentPlanInitialFormGetResponse)
  }

  async getReservePlanDetail(req:ReservePlanDetailGetRequest) :Promise<ReservePlanDetailGetResponse> {
    return await this.get(`${req.paymentPlanId}`, undefined, ReservePlanDetailGetResponse)
  }
}

export const reserveFundsPlansClient = new ReserveFundsPlansClient()
