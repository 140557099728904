
















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Material } from '@/dtos/commons'
import { BulletPoint } from '@/dtos/owner-notifications/get-detail'
import { Format } from '@/components/atoms/SmText.vue'
import { MATERIAL_TYPES, NOTIFICATION_ELEMENT_TYPES } from '@/constants/schema-constants'
import type { NotificationElementType, TransitionTo } from '@/constants/schema-constants'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextConvertedToBoldAndStrikethrough: () => import('@/components/atoms/SmTextConvertedToBoldAndStrikethrough.vue'),
    SmTextConvertedToList: () => import('@/components/atoms/SmTextConvertedToList.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmBtnFloating: () => import('@/components/organisms/SmBtnFloating.vue'),
  }
})
export default class SmFreeFormatNotificationElement extends Vue {
  NOTIFICATION_ELEMENT_TYPES = Object.freeze(NOTIFICATION_ELEMENT_TYPES)
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop({ required: true, default: NOTIFICATION_ELEMENT_TYPES.BODY })
  private readonly notificationElementType!: NotificationElementType

  @Prop()
  private readonly elementBody?: string

  @Prop()
  private readonly transitionType?: TransitionTo

  @Prop()
  private readonly transitionParams?: Record<string, string>

  @Prop()
  private readonly externalSiteUrl?: string

  @Prop()
  private readonly emailAddress?: string

  @Prop()
  private readonly phoneNumber?: string

  @Prop({ required: true, default: 1 })
  private readonly sortOrderNum!: number

  @Prop()
  private readonly material?: Material

  @Prop({ default: () => [] })
  private readonly bulletPoints!: BulletPoint[]

  @Prop({ default: false })
  private readonly sameOwner!: boolean

  private get textFormat(): Format {
    switch (this.notificationElementType) {
      case NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL2: return this.sameOwner ? 'sm-ow__h2' : 'sm__h2'
      case NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL3: return this.sameOwner ? 'sm-ow__h3' : 'sm__h3'
      case NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL4: return this.sameOwner ? 'sm-ow__h4' : 'sm__h4'
      case NOTIFICATION_ELEMENT_TYPES.LINK.EMAIL: return 'sm-ow__body'
      case NOTIFICATION_ELEMENT_TYPES.LINK.PHONE_NUMBER: return 'sm-ow__label'
      case NOTIFICATION_ELEMENT_TYPES.ROUNDED_SQUARE.LIGHT_GRAY: return 'sm-ow__label'
      case NOTIFICATION_ELEMENT_TYPES.LINK.SMOOTH_E: return this.sameOwner ? 'sm-ow__btn-mini' : 'sm__btn-medium'
      case NOTIFICATION_ELEMENT_TYPES.LINK.EXTERNAL: return this.sameOwner ? 'sm-ow__btn-mini' : 'sm__btn-medium'
      default: return this.sameOwner ? 'sm-ow__body' : 'sm__body'
    }
  }
}
