
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TICKET_TASK_STATES } from '@/constants/schema-constants'
import { ListResponseTaskDto } from '@/dtos/tasks/search/post'

@Component({
  components: {
    SmCardTaskParentTicket: () => import('@/components/molecules/card/SmCardTaskParentTicket.vue'),
    SmCardTaskStates: () => import('@/components/molecules/card/SmCardTaskStates.vue'),
  }
})

export default class SmCardTask extends Vue {
  @Prop({ default: false })
  isFocus?: boolean

  @Prop({ required: true, default: {} })
  task!: ListResponseTaskDto

  @Prop({ default: false })
  disableTicketDetailLink?: boolean

  @Prop({ default: '' })
  private readonly fieldIdPrefix!: string

  private get backgroundColor():string {
    if (this.isFocus) return 'sm__background-blue'
    if (this.task.ticketTaskState === TICKET_TASK_STATES.COMPLETED || this.task.ticketTaskState === TICKET_TASK_STATES.UNNECESSARY) {
      return 'sm__background-perm'
    } else if (this.task.isExpired) {
      return 'sm__card-task--expired'
    } else {
      return 'sm__background-white'
    }
  }

  onClickUpdate(editedTask: ListResponseTaskDto):void {
    this.$emit('update', editedTask)
  }

  createOnlineResolution():void {
    this.$emit('create-online-resolution')
  }

  createGeneralMeetingResolution():void {
    this.$emit('create-general-meeting-resolution')
  }

  goContent(index:number):void {
    this.$emit('go-content', index)
  }
}
