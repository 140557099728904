


















































































import { Component, Vue, Model } from 'vue-property-decorator'

export class ServiceStaffFormInputs {
  employeeId = ''
  adminName = ''
  adminShortName = ''
  emailAddress = ''
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class ServiceStaffPostModal extends Vue {
  @Model('change')
  readonly isVisible!: boolean

  get isModalVisible(): boolean { return this.isVisible }
  set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  inputs = new ServiceStaffFormInputs()

  postStaff():void {
    this.$emit('post', this.inputs)
  }
}
