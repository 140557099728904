














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmBadgeRead extends Vue {
  @Prop({ required: true, default: false })
  private readonly isUnread!: boolean

  @Prop({ default: '未読' })
  private readonly unreadLabel!: string

  @Prop({ default: '既読' })
  private readonly readLabel!: string

  @Prop({ default: false })
  private readonly isAdmin?: boolean

  private get readOrUnreadMessage():string {
    const readOrUnread = this.isUnread ? this.unreadLabel : this.readLabel
    // 区分所有者か、管理者業務執行者によって文言を変更する
    return this.isAdmin
      ? `区分所有者 ${readOrUnread}`
      : readOrUnread
  }
}
