























































































































import { Mixins, Component } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { Unit, UnitsGetRequest, UnitsGetResponse } from '@/dtos/units/get'
import { buildingsModule } from '@/stores/buildings-store'
import { unitsModule } from '@/stores/units-store'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

  }
})
export default class OwnersListPage extends Mixins(CurrentAdminManager) {
  tableHeaders:Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '120px' }),
    new Header({ text: '氏名', value: 'userName', width: '144px' }),
    new Header({ text: 'CASYS登録氏名', value: 'casysUserName', align: ' d-none' }),
    new Header({ text: 'CASYSと相違', value: 'isAlias', filterable: false, width: '150px' }),
    new Header({ text: '外部居住', value: 'isLivingOutside', filterable: false, width: '110px' }),
    new Header({ text: '紙利用申請', value: 'isPaperUseApplied', filterable: false, width: '120px' }),
    new Header({ text: '所有住戸', value: 'ownedDwellingUnits', filterable: false, width: '110px' }),
    new Header({ text: '初回認証', value: 'isInitialAuth', filterable: false, width: '110px' }),
    new Header({ text: '議決権数', value: 'votingRightCount', filterable: false, width: '110px' })
  ]

  private get units():Unit[] { return unitsModule.unitsGet.units }
  private get unitsGet():UnitsGetResponse { return unitsModule.unitsGet }

  async created():Promise<void> {
    await unitsModule.fetchUnits(new UnitsGetRequest())
  }

  get building(): BuildingDetailGetResponse | null {
    return buildingsModule.buildingDetailGet
  }

  get isBuildingOutOfService(): boolean {
    return this.building ? this.building.isOutOfService : false
  }

  inputText = ''
  keyword = ''

  async downloadCsv(): Promise<void> {
    await unitsModule.downloadUnitsCsv(new UnitsGetRequest())
  }

  goDetail(unitId:string):void {
    this.$router.push({ name: staticRoutes.ownerDetail.name, params: { unitId: unitId } })
  }
}
