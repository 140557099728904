import { render, staticRenderFns } from "./SmText.vue?vue&type=template&id=7b8f9de2&scoped=true&"
import script from "./SmText.vue?vue&type=script&lang=ts&"
export * from "./SmText.vue?vue&type=script&lang=ts&"
import style0 from "./SmText.vue?vue&type=style&index=0&id=7b8f9de2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b8f9de2",
  null
  
)

export default component.exports