










































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmAvatar: () => import('@/components/atoms/SmAvatar.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmListUser extends Vue {
  @Prop({ default: '' })
  private readonly iconImagePath!: string

  @Prop({ default: '' })
  private readonly userName!: string

  @Prop()
  private readonly roomNumber?: string

  @Prop({ default: false })
  private readonly deletable!: boolean
}
