import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { ticketsClient } from '@/clients/tickets-client'
import { TicketsPostRequest } from '@/dtos/tickets/post'
import { Ticket, TicketsSearchPostRequest, TicketsSearchPostResponse } from '@/dtos/tickets/search/post'
import { TicketsGetRequest, TicketGetResponse } from '@/dtos/tickets/get'
import { TicketDetailGetRequest, TicketDetailGetResponse } from '@/dtos/tickets/get-detail'
import { TicketAdminInChargePutRequest } from '@/dtos/tickets/admin-in-charge/put'
import { TicketClosePutRequest } from '@/dtos/tickets/close/put'
import { TicketDetailPutRequest } from '@/dtos/tickets/put'
import { ResolutionTicketRelatedIdeaGetRequest, ResolutionTicketRelatedIdeaGetResponse } from '@/dtos/tickets/resolutions/ideas/get'
import { TicketComment, TicketCommentsGetRequest, TicketCommentsGetResponse } from '@/dtos/tickets/comments/get'
import { TicketCommentsPostRequest } from '@/dtos/tickets/comments/post'
import { TicketCommentsPutRequest, TicketCOmmentsPutResponse } from '@/dtos/tickets/comments/put'
import { TicketCommentsDeleteRequest } from '@/dtos/tickets/comments/delete'
import { TicketsRegularMtgCsvPostRequest } from '@/dtos/tickets/regular-mtg/post'
import { TicketsManagementStatusesCsvPostRequest } from '@/dtos/tickets/search/management-statuses/post'

@Module({ store, dynamic: true, namespaced: true, name: 'ticket' })
class TicketsStore extends VuexModule {
  // チケット一覧
  private _tickets: Ticket[] = []
  private _ticketCount: number | null = null

  get tickets(): Ticket[] {
    return this._tickets
  }

  get ticketCount(): number | null {
    return this._ticketCount
  }

  @Mutation
  private PUSH_TICKETS(res: TicketsSearchPostResponse): void {
    this._tickets = res.tickets
    this._ticketCount = res.ticketCount
  }

  @Action
  async fetchTickets(req: TicketsSearchPostRequest): Promise<void> {
    const res = await ticketsClient.postTicketsSearch(req)
    if (res) this.PUSH_TICKETS(res)
  }

  // チケット登録
  @Action
  async postTicket(req: TicketsPostRequest): Promise<void> {
    await ticketsClient.postTicket(req)
  }

  // チケット詳細（共通）
  private _ticketDetail: Record<string, TicketGetResponse> = {}

  get ticketDetail(): (id: string) => TicketGetResponse | undefined {
    return (id: string) => this._ticketDetail[id]
  }

  @Mutation
  private SET_TICKET_DETAIL(res: TicketGetResponse): void {
    Vue.set(this._ticketDetail, res.ticketId, res)
  }

  @Action
  async fetchTicketDetail(req: TicketsGetRequest): Promise<void> {
    const res = await ticketsClient.getTicket(req)
    this.SET_TICKET_DETAIL(res)
  }

  // チケット担当者更新
  @Action
  async putTicketAdminInCharge(req: TicketAdminInChargePutRequest): Promise<void> {
    await ticketsClient.putTicketAdminInCharge(req)
  }

  // チケット完了登録
  @Action
  async putTicketClose(req: TicketClosePutRequest): Promise<void> {
    await ticketsClient.putTicketClose(req)
  }

  // チケット詳細（内容）取得
  private _ticketDetailContents: Record<string, TicketDetailGetResponse> = {}

  get ticketDetailContentGet(): (id: string) => TicketDetailGetResponse | undefined {
    return (id: string) => this._ticketDetailContents[id]
  }

  @Mutation
  private SET_TICKET_DETAIL_CONTENT_GET(res: TicketDetailGetResponse): void {
    Vue.set(this._ticketDetailContents, res.ticketId, res)
  }

  @Action
  async fetchTicketDetailContent(req: TicketDetailGetRequest): Promise<void> {
    const res = await ticketsClient.getTicketContents(req)
    this.SET_TICKET_DETAIL_CONTENT_GET(res)
  }

  // チケット詳細（内容）更新
  @Action
  async putTicketDetailContent(req: TicketDetailPutRequest): Promise<void> {
    await ticketsClient.putTicketDetailContent(req)
  }

  // チケットコメント一覧
  private _ticketComments: TicketComment[] = []
  private _skipToken: string | null = null

  get ticketComments(): TicketComment[] { return this._ticketComments }
  get skipToken(): string | null { return this._skipToken }

  @Mutation
  private PUSH_TICKET_COMMENTS(res: TicketCommentsGetResponse): void {
    this._ticketComments.push(...res.ticketComments)
    this._skipToken = res.skipToken
  }

  @Mutation
  private CLEAR_TICKET_COMMENTS(): void {
    this._ticketComments = []
    this._skipToken = null
  }

  @Action
  async fetchTicketComments(req: TicketCommentsGetRequest): Promise<void> {
    const res = await ticketsClient.getTicketComments(req)
    this.PUSH_TICKET_COMMENTS(res)
  }

  @Action clearFetchedTicketComments(): void {
    this.CLEAR_TICKET_COMMENTS()
  }

  // チケットコメント登録
  @Action
  async postTicketComments(req: TicketCommentsPostRequest): Promise<void> {
    await ticketsClient.postTicketComments(req)
  }

  // チケットコメント更新
  private _putTicketCommentsResponses: Record<string, TicketCOmmentsPutResponse> = {}

  get putTicketCommentsResponse(): (ticketCommentId: string) => TicketCOmmentsPutResponse | undefined {
    return (ticketCommentId: string) => this._putTicketCommentsResponses[ticketCommentId]
  }

  @Mutation
  private SET_PUT_TICKET_COMMENTS_RESPONSE({ ticketCommentId, res }: { ticketCommentId: string, res: TicketCOmmentsPutResponse }) {
    Vue.set(this._putTicketCommentsResponses, ticketCommentId, res)
  }

  @Action
  async putTicketComments(req: TicketCommentsPutRequest): Promise<void> {
    const res = await ticketsClient.putTicketComments(req)
    if (res) this.SET_PUT_TICKET_COMMENTS_RESPONSE({ ticketCommentId: req.ticketCommentId, res })
  }

  // チケットコメント削除
  @Action
  async deleteTicketComments(req: TicketCommentsDeleteRequest): Promise<void> {
    await ticketsClient.deleteTicketComments(req)
  }

  // 決議チケットの関連アイデア取得
  private _resolutionTicketRelatedIdeas: ResolutionTicketRelatedIdeaGetResponse | null = null

  get resolutionTicketRelatedIdeas(): ResolutionTicketRelatedIdeaGetResponse | null {
    return this._resolutionTicketRelatedIdeas
  }

  @Mutation
  private SET_RESOLUTION_TICKET_RELATED_IDEAS_GET(res: ResolutionTicketRelatedIdeaGetResponse): void {
    this._resolutionTicketRelatedIdeas = res
  }

  @Action
  async fetchResolutionTicketRelatedIdeas(req:ResolutionTicketRelatedIdeaGetRequest): Promise<void> {
    const res = await ticketsClient.getResolutionTicketRelatedIdea(req)
    this.SET_RESOLUTION_TICKET_RELATED_IDEAS_GET(res)
  }

  // 定例MTG資料用CSV出力
  @Action
  async postRegularMtgCsv(req:TicketsRegularMtgCsvPostRequest): Promise<void> {
    await ticketsClient.postRegularMtgCsv(req)
  }

  // 管理状況報告書用CSV出力
  @Action
  async postManagementStatusesCsv(req:TicketsManagementStatusesCsvPostRequest): Promise<void> {
    await ticketsClient.postManagementStatusesCsv(req)
  }
}

export const ticketsModule = getModule(TicketsStore)
