




















































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator'

import { RadioOption } from '@/components/owner-apps/SmOwRadioSelectsQuestion.vue'
import { CheckboxQuestionOption } from '@/components/owner-apps/SmOwCheckboxQuestion.vue'

import { QUESTIONNAIRE_QUESTION_TYPES, QUESTIONNAIRE_QUESTION_ELEMENT_TYPES } from '@/constants/schema-constants'

import { QuestionnaireQuestionForPreview, QuestionnaireQuestionElementForPreview, QuestionnaireSectionForPreview } from '@/dtos/questionnaires/commons'

import { QuestionnairePreviewAnswers, QuestionnairePreviewAnswer } from '@/pages/questionnaires/QuestionnairePreviewTopPage.vue'

import { QuestionnairePreviewContent } from '@/stores/new-tab-local-param-storage-store'

const TRANSITION_PATTERNS = {
  NEXT: 'right-move-in',
  PREV: 'left-move-in'
} as const
type TransitionPattern = typeof TRANSITION_PATTERNS[keyof typeof TRANSITION_PATTERNS]

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmOwBtnOutline: () => import('@/components/owner-apps/SmOwBtnOutline.vue'),
    SmOwCheckboxQuestion: () => import('@/components/owner-apps/SmOwCheckboxQuestion.vue'),
    SmOwRadioSelectsQuestion: () => import('@/components/owner-apps/SmOwRadioSelectsQuestion.vue'),
    SmOwLabeledTextArea: () => import('@/components/owner-apps/SmOwLabeledTextArea.vue'),
    SmOwProgressLinear: () => import('@/components/owner-apps/SmOwProgressLinear.vue'),
    SmOwTextHyperLinked: () => import('@/components/owner-apps/SmOwTextHyperLinked.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class QuestionnairePreviewAnswerModal extends Vue {
  QUESTIONNAIRE_QUESTION_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_TYPES)
  private transition: TransitionPattern = TRANSITION_PATTERNS.NEXT

  @Model('change')
  private readonly isVisible!: boolean

  @Prop()
  private readonly questionnaire!: QuestionnairePreviewContent

  @Prop()
  private questionnairePreviewAnswers!: QuestionnairePreviewAnswers

  page = 1

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get sectionNum(): number {
    return this.page - 1
  }

  private get progressRate(): number {
    if (!this.questionnaire.sections?.length) return 0
    return 100 * this.page / (this.questionnaire.sections.length)
  }

  private get progressBarText(): string | undefined {
    return `${this.page} / ${this.questionnaire.sections?.length}`
  }

  private get currentSection(): QuestionnaireSectionForPreview | undefined {
    if (!this.questionnaire.sections) return
    return this.questionnaire.sections[this.sectionNum]
  }

  private get options(): (string | RadioOption[] | CheckboxQuestionOption[])[] | undefined {
    if (!this.questionnaire.sections || !this.questionnairePreviewAnswers.sections[this.page - 1]) return undefined
    const options = this.questionnaire.sections[this.page - 1].questions.map(question => {
      let optionPerQuestion: string | RadioOption[] | CheckboxQuestionOption[] = ''
      const currentQuestion = this.questionnairePreviewAnswers.sections[this.page - 1].questions.find(q => q.questionnaireQuestionId === question.questionId)
      if (question.questionType === QUESTIONNAIRE_QUESTION_TYPES.FREE) {
        let temporaryText = ''
        let temporaryElements: QuestionnaireQuestionElementForPreview[] = []
        if (question.questionElements) {
          temporaryElements = question.questionElements
          const answer = currentQuestion?.answers.find(answer => answer.questionnaireQuestionElementId === temporaryElements[0].questionElementId)
          temporaryText = answer?.freeAnswer ?? ''
        }
        optionPerQuestion = temporaryText
      } else if (question.questionType === QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE) {
        let radioOptions: RadioOption[] = []
        if (question.questionElements && currentQuestion) {
          radioOptions = question.questionElements.map(element => {
            const elementOb = new RadioOption()
            elementOb.label = element.choiceName ? element.choiceName : ''
            elementOb.value = element.questionElementId
            const answer = currentQuestion.answers.find(answer => answer.questionnaireQuestionElementId === element.questionElementId)
            if (!answer) {
              elementOb.isSelected = false
              elementOb.inputOtherText = ''
            } else {
              elementOb.isSelected = (!answer.choiceAnswer) ? false : answer.choiceAnswer
              elementOb.inputOtherText = (!answer.freeAnswer) ? '' : answer.freeAnswer
            }
            elementOb.isOtherOption = element.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION
            return elementOb
          })
        }
        optionPerQuestion = radioOptions
      } else if (question.questionType === QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES) {
        let checkboxOptions: CheckboxQuestionOption[] = []
        if (question.questionElements && currentQuestion) {
          checkboxOptions = question.questionElements.map(element => {
            const elementOb = new CheckboxQuestionOption()
            elementOb.value = element.questionElementId
            elementOb.optionText = element.choiceName ? element.choiceName : ''
            elementOb.elementType = element.elementType
            const answer = currentQuestion.answers.find(answer => answer.questionnaireQuestionElementId === element.questionElementId)
            if (!answer) {
              elementOb.isSelected = false
              elementOb.inputOtherText = ''
            } else {
              elementOb.isSelected = (!answer.choiceAnswer) ? false : answer.choiceAnswer
              elementOb.inputOtherText = (!answer.freeAnswer) ? '' : answer.freeAnswer
            }
            return elementOb
          })
        }
        optionPerQuestion = checkboxOptions
      }
      return optionPerQuestion
    })
    return options
  }

  private get isLastPage():boolean {
    return this.page === this.questionnaire.sections?.length
  }

  private createFreeTextAnswer(question: QuestionnaireQuestionForPreview, elements: QuestionnaireQuestionElementForPreview[], newText: string): void {
    const currentSectionId = this.currentSection?.sectionId
    const currentQuestionId = question.questionId
    const answeredElement = elements[0]
    const answeredElementId = answeredElement.questionElementId
    this.questionnairePreviewAnswers.sections.forEach(owSection => {
      if (owSection.questionnaireSectionId === currentSectionId) {
        owSection.questions.forEach(owQuestion => {
          if (owQuestion.questionnaireQuestionId === currentQuestionId) {
            if (owQuestion.answers.find(answer => {
              return answer.questionnaireQuestionElementId === answeredElementId
            })) {
              owQuestion.answers.forEach(answer => {
                if (answer.questionnaireQuestionElementId === answeredElementId) {
                  answer.freeAnswer = newText
                }
              })
            } else {
              const owQuestionnaireAnswer = new QuestionnairePreviewAnswer()
              owQuestionnaireAnswer.questionnaireQuestionElementId = answeredElementId
              owQuestionnaireAnswer.freeAnswer = newText
              owQuestion.answers.push(owQuestionnaireAnswer)
            }
          }
        })
      }
    })
  }

  private createSelectAnswer(question: QuestionnaireQuestionForPreview, newValue: RadioOption[] | CheckboxQuestionOption[]): void {
    const currentSectionId = this.currentSection?.sectionId
    const currentQuestionId = question.questionId
    this.questionnairePreviewAnswers.sections.forEach(owSection => {
      if (owSection.questionnaireSectionId === currentSectionId) {
        owSection.questions.forEach(owQuestion => {
          if (owQuestion.questionnaireQuestionId === currentQuestionId) {
            owQuestion.answers.forEach(answer => {
              newValue.forEach(option => {
                if (option.value === answer.questionnaireQuestionElementId) {
                  answer.choiceAnswer = option.isSelected
                  answer.freeAnswer = option.inputOtherText
                }
              })
            })
          }
        })
      }
    })
  }

  clickReturnBtn():void {
    this.transition = TRANSITION_PATTERNS.PREV
    this.page--
  }

  clickNextBtn():void {
    if (!this.isLastPage) {
      this.transition = TRANSITION_PATTERNS.NEXT
      this.page++
    }
  }
}
