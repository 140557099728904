










































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { ProcessingStatusTableItem } from '@/pages/dashboard/all/AllSubPage.vue'

@Component({
  components: {
    SmAvatar: () => import('@/components/atoms/SmAvatar.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
  }
})

export default class SmTableDashboardProcessingStatus extends Vue {
  // ヘッダー自体を画面に表示はしないが、テーブル内でのitemの表示場所をvalueで判断するために使用
  headers: Header[] = [
    new Header({ text: '担当者', value: 'user' }),
    new Header({ text: '処理中', value: 'notCompletedTicketsCount' }),
    new Header({ text: '未着手', value: 'notStartedYetTasksCount' }),
    new Header({ text: '処理中', value: 'notCompletedTasksCount' }),
    new Header({ text: '一週間期限', value: 'nearDeadlineTasksCount' }),
    new Header({ text: '期限切れ', value: 'expiredTasksCount' }),
  ]

  ticketIcon = '$content_copy_round'
  taskIcon = '$task_alt_round'

  @Prop({ default: () => [], required: true })
  private readonly items!: ProcessingStatusTableItem[]

  goTicketsList(item: ProcessingStatusTableItem): void {
    this.$emit('go-tickets-list', item)
  }

  goNotStartedYetTasksList(item: ProcessingStatusTableItem): void {
    this.$emit('go-not-started-tasks-list', item)
  }

  goNotCompletedTasksList(item: ProcessingStatusTableItem): void {
    this.$emit('go-not-completed-tasks-list', item)
  }

  goNearDeadlineTasksList(item: ProcessingStatusTableItem): void {
    this.$emit('go-near-deadline-tasks-list', item)
  }

  goExpiredTasksList(item: ProcessingStatusTableItem): void {
    this.$emit('go-expired-tasks-list', item)
  }
}
