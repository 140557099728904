



































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { QUESTION_STATES, RESOLUTION_STATES } from '@/constants/schema-constants'
import type { ResolutionState, QuestionState } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import { User } from '@/dtos/commons'
import { Answer } from '@/dtos/resolutions/questions/get'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
  }
})
export default class SmListQA extends Vue {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)

  @Prop({ required: true, default: false })
  private isEdited!: boolean

  @Prop()
  private ownerUser!: User

  @Prop({ required: true, default: '' })
  private questionBody!: string

  @Prop({ required: true, default: QUESTION_STATES.RAW })
  private questionState!: QuestionState

  @Prop({ required: true, default: '' })
  private postedAt!: string

  @Prop({ required: true, default: false })
  private isEditMode!: boolean

  @Prop()
  private answerInput?: string

  private get _answerInput():string|undefined { return this.answerInput }
  private set _answerInput(newValue: string|undefined) {
    this.$emit('update:answerInput', newValue)
  }

  @Prop()
  private answer?: Answer

  @Prop({ required: true, default: 0 /* どれでもない値 */ })
  private resolutionState!: ResolutionState

  @Prop({ required: true, default: false })
  private hasAuthority!: boolean

  @Prop({ required: true, default: '' })
  private answerFieldIdPrefix!: string

  // 編集権限を持ち、且つ「公開済み以外」のタイミング、且つ質問が削除されていない場合のみ、「質問の削除・回答の投稿・編集・削除」が可能
  private get isEditable(): boolean {
    return this.hasAuthority && !this.isQuestionDeleted && (
      this.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL ||
      this.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT ||
      this.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.COUNTING ||
      this.resolutionState === RESOLUTION_STATES.ONLINE.ACCEPTING_ALL ||
      this.resolutionState === RESOLUTION_STATES.ONLINE.ACCEPTING_VOTE ||
      this.resolutionState === RESOLUTION_STATES.ONLINE.COUNTING
    )
  }

  /**
   * 質問削除済フラグ
   */
  private get isQuestionDeleted():boolean {
    switch (this.questionState) {
      case QUESTION_STATES.RAW:
        return false
      case QUESTION_STATES.DELETED_SELF:
        return true
      case QUESTION_STATES.DELETED:
        return true
    }
  }

  private get deletedQuestionLabel():string {
    switch (this.questionState) {
      case QUESTION_STATES.DELETED_SELF:
        return '区分所有者により削除されました'
      case QUESTION_STATES.DELETED:
        return '管理者業務執行者により削除されました'
      default:
        return ''
    }
  }
}
