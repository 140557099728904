import type { InitialAuthCodeType } from '@/constants/schema-constants'

export class UnitDetailGetRequest {
  unitId!: string
  constructor(unitId:string) { this.unitId = unitId }
}

export class UnitDetailGetResponse {
  unitId!: string
  userId!: string
  roomNumber!: string
  unitTypeCode!: number
  casysName!: string
  joinedAt!: string
  votingRightCount!: number
  occupiedArea!: string
  isInitialAuth!: boolean
  isLivingOutside!: boolean
  isPaperUseApplied!: boolean
  ownedDwellingUnits?: string
  initialAuthCode?: string
  initialAuthCodeType?: InitialAuthCodeType
  adminShortName?: string
  emailAddress?: string
  userName?: string
  personalMemo?: string
}
