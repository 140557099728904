import { reserveFundsClient } from '@/clients/reserve-funds-client'
import { ReservesGetResponse } from '@/dtos/reserve-funds/get'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { ReservesPostRequest } from '@/dtos/reserve-funds/post'
import { ReservesIncomeRecordRequest } from '@/dtos/reserve-funds/records/post'

@Module({ store, dynamic: true, namespaced: true, name: 'reserveFunds' })
class ReserveFundsStore extends VuexModule {
  // 積立計画
  private _reserveFund = new ReservesGetResponse()

  public get reserveFund():ReservesGetResponse { return this._reserveFund }

  @Mutation
  private SET_RESERVE_FUND(res:ReservesGetResponse) { this._reserveFund = res }

  @Action
  public async fetchReserveFund():Promise<void> {
    const res = await reserveFundsClient.getReserves()
    this.SET_RESERVE_FUND(res)
  }

  @Action
  public async postReserveFund(req:ReservesPostRequest) {
    await reserveFundsClient.postReserves(req)
  }

  // 受取利息等登録
  @Action
  public async postReservesIncomeRecords(req:ReservesIncomeRecordRequest):Promise<void> {
    await reserveFundsClient.postReservesIncomeRecords(req)
  }

  @Action
  async putReservesUpdatable(): Promise<void> {
    await reserveFundsClient.putReservesUpdatable()
  }
}

export const reserveFundsModule = getModule(ReserveFundsStore)
