













import { Vue, Component, Prop } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { Tab } from '@/components/molecules/SmTabs.vue'
import { ReservePaymentDetailGetRequest, ReservePaymentDetailGetResponse } from '@/dtos/reserve-funds/payments/get-detail'
import { reservePaymentsModule } from '@/stores/reserve-payments-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmAppBar: () => import('@/components/organisms/SmAppBar.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class PaymentDetailPage extends Vue {
  @Prop({ required: true })
  buildingUnitId!:string

  private get paymentDetaii():ReservePaymentDetailGetResponse | undefined {
    return reservePaymentsModule.paymentDetail(this.buildingUnitId)
  }

  async created():Promise<void> {
    const req = new ReservePaymentDetailGetRequest(this.buildingUnitId)
    await reservePaymentsModule.fetchPaymentDetail(req)
  }

  tabs: Tab[] = [
    new Tab('積立計画', staticRoutes.reserveFundsPersonalDetail.getChild('detail').name),
    new Tab('専用支払いプラン', staticRoutes.reserveFundsPersonalDetail.getChild('plans').name),
  ]

  currentTab = ''
}
