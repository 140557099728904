

































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { MATERIAL_TYPES, NEW_TAB_TRANSITION_TO } from '@/constants/schema-constants'
import { staticRoutes } from '@/routes'

import { openNewTab } from '@/libs/open-new-tab'
import { uploadMaterial } from '@/libs/file-uploader'
import { compress } from '@/libs/zip-helper'
import { generateUuid } from '@/libs/uuid-generator'

import { Header } from '@/components/molecules/SmTableData.vue'
import type { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'
import { CollationResultTableItem } from '@/components/molecules/SmTableCollationResult.vue'

import { MaterialFormInputDto } from '@/dtos/commons'
import { CasysDetectionsResultGetRequest, InProgressData, DetectionResult } from '@/dtos/casys/detections/results/get'
import { CasysUnitsPostRequest, CasysUnitsMaterial } from '@/dtos/casys/units/post'
import { CasysUnitsPutRequest } from '@/dtos/casys/units/put'

import { errorsModule } from '@/stores/errors'
import { casysModule } from '@/stores/casys-store'
import { NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { myProfileModule } from '@/stores/my-profile-store'

const TAKE = 5

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialInputArea: () => import('@/components/molecules/SmMaterialInputArea.vue'),
    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTableCollationResult: () => import('@/components/molecules/SmTableCollationResult.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class CasysResultsListPage extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  tableHeaders:Header[] = [
    new Header({ text: 'アップロード日時', value: 'uploadedAt', sortable: false, width: '210px' }),
    new Header({ text: '担当者', value: 'adminName', sortable: false, width: '210px' }),
    new Header({ text: 'ファイル数', value: 'fileCount', sortable: false }),
  ]

  files: File[] = []
  identifier = 1
  errorMessage = ''

  get inProgressData(): InProgressData[] { return casysModule.casysDetectionsInprogressData }
  get detectionsResults(): DetectionResult[] { return casysModule.casysDetectionsResult }
  get skipToken(): string | null { return casysModule.casysDetectionsResultSkipToken }
  get casysDifferenceErrors(): string[] | null { return casysModule.casysDifferenceErrors }

  async created(): Promise<void> {
    this.clearCasysDetectionResults()
  }

  handler: LoadingHandler | null = null
  async loadCasysUnitsResults(handler: LoadingHandler):Promise<void> {
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }

    const beforeLength = this.detectionsResults.length
    const req = new CasysDetectionsResultGetRequest(TAKE)
    if (this.skipToken) req.skipToken = this.skipToken
    await casysModule.fetchCasysDetectionsResults(req)

    if (this.detectionsResults.length === 0) {
      // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
      handler.complete()
      return
    }

    const expectingToBe = beforeLength + TAKE
    if (this.detectionsResults.length < expectingToBe) {
      handler.complete()
      return
    }

    handler.loaded()
  }

  clearCasysDetectionResults(): void {
    casysModule.clearCasysDetectionResults()
    this.identifier++
  }

  async uploadCasysUnits(): Promise<void> {
    // zip化するファイルのコピー
    const workingFiles = [...this.files]
    this.files = []

    // zipファイルを作成する
    try {
      const zipFile = await compress(workingFiles, `${generateUuid()}.zip`)

      // zipファイルのアップロード
      const material: MaterialFormInputDto = Object.assign(new MaterialFormInputDto(), { file: zipFile })
      const filePath = '_casys'
      const uploadedMaterial = await uploadMaterial(material, filePath, false)
      if (!uploadedMaterial) return

      // 区分所有者情報ファイルの新規登録
      const fileCount = workingFiles.length
      const casysUnitsMaterial: CasysUnitsMaterial = {
        materialId: uploadedMaterial.materialId
      }
      const req = new CasysUnitsPostRequest(fileCount, casysUnitsMaterial)
      await casysModule.uploadCasysUnits(req)
      // 照合中データの先頭にアップロードしたデータを追加する
      const inProgressData = Object.assign(new InProgressData(), casysModule.casysUnitsPost)
      inProgressData.adminName = myProfileModule.myProfileGet?.user.userName ?? ''
      casysModule.pushCasysDetectionsInprogressData([inProgressData])
      // アップロードに成功した際に照合中のデータに表示しているエラーを非表示にする
      casysModule.clearCasysDifferenceErrors()
    } catch {
      this.errorMessage = 'ファイルのアップロードに失敗しました。再度ファイルを選択し、アップロードしてください。'
    }
  }

  goToCasysDetectionListPage(): void {
    this.$router.push({ name: staticRoutes.casysDetectionsListPage.name })
  }

  async fixOwnerName(item: CollationResultTableItem, casysDifferenceDetectionBatchStatusId: string, condominiumCd: string): Promise<void> {
    if (!item.casysDifferenceOwnerId || !item.owningBuildingUnitId) return
    const req = new CasysUnitsPutRequest(item.casysDifferenceOwnerId, item.owningBuildingUnitId)
    await casysModule.updateCasysUnits(req)
    if (!item.unitId) return
    casysModule.updateDetectionFixOwnerNameFlag({
      casysDifferenceDetectionBatchStatusId: casysDifferenceDetectionBatchStatusId,
      condominiumCd: condominiumCd,
      unitId: item.unitId,
      isUpdated: true,
    })
  }

  openOwnerUserDetailPage(unitId: string, buildingId?: string) :void {
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.OWNER_DETAIL
    newTabTransitionParams.buildingId = buildingId
    newTabTransitionParams.unitId = unitId
    openNewTab(newTabTransitionParams)
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
  }
}
