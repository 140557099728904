import { IdeaState } from '@/constants/schema-constants'
import { User } from '@/dtos/commons'
import { Details, SessionGetResponseSession } from '@/dtos/ideas/admin-idea/commons'
import { Type } from 'class-transformer'

export class AdminIdeaSessionGetRequest {
  ideaId!: string

  constructor(ideaId: string) {
    this.ideaId = ideaId
  }
}

export class AdminIdeaSessionGetResponse {
  ideaId!: string
  ideaState!: IdeaState
  postedAt!: string
  title!: string
  details!: Details
  @Type(() => User)user: User = new User()
  @Type(() => SessionGetResponseSession)sessions: SessionGetResponseSession[] = []
  version!: number
}
