import { APIClientBase } from './api-client-base'
import { IdeasGetRequest, IdeasGetResponse } from '@/dtos/ideas/get'
import { IdeasReadsPostRequest } from '@/dtos/ideas/reads/post'

class IdeasClient extends APIClientBase {
  constructor() {
    super('/ideas')
  }

  getIdeas(req: IdeasGetRequest) {
    return this.get('', req, IdeasGetResponse, false)
  }

  postIdeasReads(req: IdeasReadsPostRequest) {
    return this.post(`${req.ideaId}/reads`, undefined)
  }
}

export const ideasClient = new IdeasClient()
