import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyLocalStoreInitialized, store } from './index'
import { NewTabTransitionTo } from '@/constants/schema-constants'

export class NewTabTransitionParams {
  // 画面遷移先を設定する
  newTabTransitionTo!: NewTabTransitionTo
  // マンション詳細画面に遷移する場合に設定する。またマンションに紐づく画面に遷移する場合も設定する
  buildingId?: string
  // アイデア詳細画面に遷移する場合に設定する
  ideaId?: string
  // 決議詳細画面に遷移する場合に設定する
  resolutionId?: string
  // 相談・連絡詳細画面に遷移する場合に設定する
  userId?: string
  // 区分所有者詳細画面に遷移する場合に設定する
  unitId?: string
  // 下書き画面に遷移する場合に設定する
  ticketId?: string
}

/**
 * 別タブで画面表示をさせたい場合に、遷移先パラメータの保管先として用いるストア
 * 別タブでの画面表示後、パラメータは不要になる為、別タブでの表示後ローカルストレージの情報は削除される
 */

@Module({ store, dynamic: true, namespaced: true, name: 'newTabLocalTransitionParamStorage', preserveState: isAlreadyLocalStoreInitialized })
class NewTabLocalTransitionParamStorageStore extends VuexModule {
  // 遷移先画面のパラメータ
  private _newTabTransitionParams: Record<string, NewTabTransitionParams> = {}

  get newTabTransitionParams(): (key: string) => NewTabTransitionParams | undefined { return (key: string) => Object.assign({}, this._newTabTransitionParams[key]) }

  @Mutation
  private SET_NEW_TAB_TRANSITION_PARAMS({ key, newTabTransitionParams }: { key: string, newTabTransitionParams: NewTabTransitionParams }): void {
    Vue.set(this._newTabTransitionParams, key, newTabTransitionParams)
  }

  @Mutation
  private DELETE_NEW_TAB_TRANSITION_PARAMS(key: string): void {
    Vue.delete(this._newTabTransitionParams, key)
  }

  @Action
  setNewTabTransitionParams({ key, newTabTransitionParams } : { key: string, newTabTransitionParams: NewTabTransitionParams }): void {
    this.SET_NEW_TAB_TRANSITION_PARAMS({ key, newTabTransitionParams })
  }

  @Action
  deleteNewTabTransitionParams(key: string): void {
    this.DELETE_NEW_TAB_TRANSITION_PARAMS(key)
  }

  @Action
  deleteAllNewTabTransitionParams(): void {
    Object.keys(this._newTabTransitionParams).forEach(key => this.DELETE_NEW_TAB_TRANSITION_PARAMS(key))
  }
}

export const newTabLocalTransitionParamStorageModule = getModule(NewTabLocalTransitionParamStorageStore)
