



































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'

import { Choice } from '@/components/molecules/SmBtnToggle.vue'
import { Header } from '@/components/molecules/SmTableDataExternalPaging.vue'

import { ADMIN_ROLE, NEW_TAB_TRANSITION_TO, TICKET_STATES, TICKET_TYPES } from '@/constants/schema-constants'
import type { TicketState, TicketType } from '@/constants/schema-constants'
import type { ChipColors } from '@/constants/ux-constants'

import { Building } from '@/dtos/buildings/get'
import { BuildingOwnerDto } from '@/dtos/buildings/owners/get'
import { User } from '@/dtos/commons'
import { Ticket, TicketsSearchPostRequest } from '@/dtos/tickets/search/post'

import { getTicketStateChipColor, getTicketStateLabel } from '@/libs/state-handler'
import { getTicketBadge, getTicketLabel } from '@/libs/type-handler'
import { generateUuid } from '@/libs/uuid-generator'
import { openNewTab } from '@/libs/open-new-tab'

import { NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { ticketsModule } from '@/stores/tickets-store'
import { TicketsRegularMtgCsvPostRequest } from '@/dtos/tickets/regular-mtg/post'
import { TicketsManagementStatusesCsvPostRequest } from '@/dtos/tickets/search/management-statuses/post'

const TAKE = 50
const STORAGE_KEY = staticRoutes.ticketsList.name

// ステータスのトグルの内容
export const FILTER_TOGGLE_TICKET_STATES = {
  UNSET_PERSON_IN_CHARGE: 'unsetPersonInCharge',
  NOT_COMPLETED: 'notCompleted',
  COMPLETED: 'completed',
}
export type FilterToggleTicketState = typeof FILTER_TOGGLE_TICKET_STATES[keyof typeof FILTER_TOGGLE_TICKET_STATES]

const FILTER_TOGGLE_CHOICES_STATE: Record<FilterToggleTicketState, TicketState> = {
  [FILTER_TOGGLE_TICKET_STATES.UNSET_PERSON_IN_CHARGE]: TICKET_STATES.UNSET_PERSON_IN_CHARGE,
  [FILTER_TOGGLE_TICKET_STATES.NOT_COMPLETED]: TICKET_STATES.NOT_COMPLETED,
  [FILTER_TOGGLE_TICKET_STATES.COMPLETED]: TICKET_STATES.COMPLETED
}

// コンテンツのトグルの内容
const FILTER_TOGGLE_TICKET_TYPES = {
  IDEA: 'idea',
  RESOLUTION: 'resolution',
  CONSULTATION: 'consultation',
  MANUAL: 'manual',
}
type FilterToggleTicketType = typeof FILTER_TOGGLE_TICKET_TYPES[keyof typeof FILTER_TOGGLE_TICKET_TYPES]

const FILTER_TOGGLE_CHOICES_TYPE: Record<FilterToggleTicketType, TicketType> = {
  [FILTER_TOGGLE_TICKET_TYPES.IDEA]: TICKET_TYPES.IDEA,
  [FILTER_TOGGLE_TICKET_TYPES.RESOLUTION]: TICKET_TYPES.RESOLUTION,
  [FILTER_TOGGLE_TICKET_TYPES.CONSULTATION]: TICKET_TYPES.CONSULTATION,
  [FILTER_TOGGLE_TICKET_TYPES.MANUAL]: TICKET_TYPES.MANUAL,
}

// 担当者選択のメニューの内容
const ADMIN_MENU_ITEMS: {[id: string]: { text: string, label: string}} = {
  ALL: {
    text: 'すべての担当者（担当者未設定を含む）',
    label: '変更する'
  },
  EACH: {
    text: '個別に選択する',
    label: '変更する'
  },
}

// 並び順のメニューの内容
const SORT_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  desc: {
    text: '受付日が新しい順',
    label: '受付日が新しい順'
  },
  asc: {
    text: '受付日が古い順',
    label: '受付日が古い順'
  }
}

// 絞込条件の内容
export class TicketInputParams {
  ticketStates: FilterToggleTicketState[] = []
  ticketTypes: FilterToggleTicketType[] = []
  buildings: Building[] = []
  owners: BuildingOwnerDto[] = []
  fromDate: string | null = null
  toDate: string | null = null
  adminMenuId = 'ALL'
  admins: User[] = []
  adminInputText = ''
  adminKeyword = ''
  keyword = ''
  sortMenuId = 'desc'
}

// テーブルに表示させるのに必要な内容
class TicketItem {
  ticketId!: string
  adminName!: string
  ticketState!: string
  chipColor!: ChipColors
  task!: string
  accrualDate!: string
  buildingName?: string
  buildingId?: string
  ticketTypeText!: string
  ticketTypeTextStyle!: string
  ticketTypeIcon!: string
  ticketTypeIconColor!: string
  ticketName!: string
  ticketNo!: string
  ownerName!: string
}

@Component({
  components: {
    SmBadgeCategory: () => import('@/components/atoms/SmBadgeCategory.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnTextLinkAbbreviation: () => import('@/components/atoms/SmBtnTextLinkAbbreviation.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmBtnToggle: () => import('@/components/molecules/SmBtnToggle.vue'),
    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTableDataExternalPaging: () => import('@/components/molecules/SmTableDataExternalPaging.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    StaffSelectModal: () => import('@/components/organisms/modal/StaffSelectModal.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    BuildingAndOwnerSelectModal: () => import('@/pages/tickets/BuildingAndOwnerSelectModal.vue'),
    ReportOutputModal: () => import('@/pages/tickets/ReportOutputModal.vue'),
  }
})
export default class TicketsListPage extends Vue {
  async created(): Promise<void> {
    // 初期検索パラメーターの設定（以前の検索条件が残っている場合、それを復元して反映）
    const stored = paramStorageModule.savedParam(STORAGE_KEY, TicketInputParams)
    if (stored) { this.inputParams = stored }

    this.ticketsSearchPostRequestParam = new TicketsSearchPostRequest(0, TAKE)
    this.ticketsSearchPostRequestParam.ticketStates = this.inputParams.ticketStates.flatMap(s => FILTER_TOGGLE_CHOICES_STATE[s])
    this.ticketsSearchPostRequestParam.ticketTypes = this.inputParams.ticketTypes.flatMap(t => FILTER_TOGGLE_CHOICES_TYPE[t])
    if (this.inputParams.buildings) this.ticketsSearchPostRequestParam.buildings = this.inputParams.buildings.map(b => b.buildingId)
    if (this.inputParams.owners) this.ticketsSearchPostRequestParam.owners = this.inputParams.owners.map(o => o.userId)
    if (this.inputParams.fromDate) this.ticketsSearchPostRequestParam.fromDate = this.inputParams.fromDate
    if (this.inputParams.toDate) this.ticketsSearchPostRequestParam.toDate = this.inputParams.toDate
    if (this.inputParams.admins) this.ticketsSearchPostRequestParam.admins = this.inputParams.admins.map(a => a.userId)
    if (this.inputParams.keyword) this.ticketsSearchPostRequestParam.keyword = this.inputParams.keyword
    this.ticketsSearchPostRequestParam.sortOrder = this.inputParams.sortMenuId === 'asc' ? 'asc' : 'desc'

    await this.search()
  }

  // --------------- 絞り込み表示情報 ---------------
  ADMIN_MENU_ITEMS = Object.freeze(ADMIN_MENU_ITEMS)
  SORT_MENU_ITEMS = Object.freeze(SORT_MENU_ITEMS)
  isFilterMenuOpen = true

  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)

  // トグルボタン
  ticketStatesChoice: Choice[] = [
    new Choice(FILTER_TOGGLE_TICKET_STATES.UNSET_PERSON_IN_CHARGE, '担当者未設定'),
    new Choice(FILTER_TOGGLE_TICKET_STATES.NOT_COMPLETED, '処理中'),
    new Choice(FILTER_TOGGLE_TICKET_STATES.COMPLETED, '完了'),
  ]

  ticketTypesChoice: Choice[] = [
    new Choice(FILTER_TOGGLE_TICKET_TYPES.IDEA, 'アイデア・プラン'),
    new Choice(FILTER_TOGGLE_TICKET_TYPES.RESOLUTION, '決議'),
    new Choice(FILTER_TOGGLE_TICKET_TYPES.CONSULTATION, '相談・連絡'),
    new Choice(FILTER_TOGGLE_TICKET_TYPES.MANUAL, '手動'),
  ]

  private get selectedBuildingAndOwnerDisplay(): string {
    if (this.inputParams.buildings.length === 0) return 'すべてのマンション（マンション未設定を含む）'
    else if (this.inputParams.owners.length === 0) return this.buildingDisplay
    else return '[' + this.buildingDisplay + ']' + this.ownerDisplay
  }

  private get buildingDisplay(): string {
    return this.inputParams.buildings.map(b => b.buildingName).join('｜')
  }

  private get ownerDisplay(): string {
    return this.inputParams.owners.map(o => o.roomNumber + ' ' + o.userName).join('、')
  }

  private get selectedAdminDisplay(): string {
    if (this.inputParams.admins.length === 0) return 'すべての担当者（マンション未設定を含む）'
    else return this.inputParams.admins.map(a => a.userName).join('、')
  }

  // --------------- 絞り込み条件変更の処理 ---------------

  async onChangeSelectedTicketStates(): Promise<void> {
    if (!this.ticketsSearchPostRequestParam) return
    const ticketStates = this.inputParams.ticketStates.flatMap(s => FILTER_TOGGLE_CHOICES_STATE[s])
    this.ticketsSearchPostRequestParam.ticketStates = ticketStates

    this.pageNum = 1
    await this.search()
  }

  async onChangeSelectedTicketTypes(): Promise<void> {
    if (!this.ticketsSearchPostRequestParam) return
    const ticketTypes = this.inputParams.ticketTypes.flatMap(t => FILTER_TOGGLE_CHOICES_TYPE[t])
    this.ticketsSearchPostRequestParam.ticketTypes = ticketTypes

    this.pageNum = 1
    await this.search()
  }

  async onChangeSelectedBuildingAndOwner(buildings: Building[], owners: BuildingOwnerDto[]): Promise<void> {
    if (!this.ticketsSearchPostRequestParam) return
    this.inputParams.buildings = buildings
    this.inputParams.owners = owners
    this.ticketsSearchPostRequestParam.buildings = buildings.map(b => b.buildingId)
    this.ticketsSearchPostRequestParam.owners = owners.map(o => o.userId)

    this.pageNum = 1
    await this.search()
  }

  async onSearchDateFilter(): Promise<void> {
    if (!this.ticketsSearchPostRequestParam) return
    this.ticketsSearchPostRequestParam.fromDate = this.inputParams.fromDate === '' ? null : this.inputParams.fromDate
    this.ticketsSearchPostRequestParam.toDate = this.inputParams.toDate === '' ? null : this.inputParams.toDate

    this.pageNum = 1
    await this.search()
  }

  async onSelectAdminMenu(): Promise<void> {
    if (!this.ticketsSearchPostRequestParam) return
    if (this.inputParams.adminMenuId === 'ALL') {
      this.inputParams.admins = []
      this.inputParams.adminInputText = ''
      this.inputParams.adminKeyword = ''

      this.ticketsSearchPostRequestParam.admins = []
      this.pageNum = 1
      await this.search()
    } else {
      this.openAdminSelectModal()
    }
  }

  async onChangeSelectedAdmin(admins: User[]): Promise<void> {
    if (!this.ticketsSearchPostRequestParam) return
    this.inputParams.admins = admins
    this.ticketsSearchPostRequestParam.admins = this.inputParams.admins.map(a => a.userId)

    this.pageNum = 1
    await this.search()
  }

  async onSearchKeyword(): Promise<void> {
    if (!this.ticketsSearchPostRequestParam) return
    this.ticketsSearchPostRequestParam.keyword = this.inputParams.keyword === '' ? null : this.inputParams.keyword

    this.pageNum = 1
    await this.search()
  }

  async onSelectSortOrderMenu(): Promise<void> {
    if (!this.ticketsSearchPostRequestParam) return
    this.ticketsSearchPostRequestParam.sortOrder = this.inputParams.sortMenuId === 'asc' ? 'asc' : 'desc'

    this.pageNum = 1
    await this.search()
  }

  // --------------- 検索の処理 ---------------
  ticketsSearchPostRequestParam: TicketsSearchPostRequest | null = null
  inputParams = new TicketInputParams()

  isWaitingSwitch = false

  async search(): Promise<void> {
    this.isWaitingSwitch = true

    await ticketsModule.fetchTickets(this.toSearchPostRequest())
    paramStorageModule.save({ key: STORAGE_KEY, params: { ...this.inputParams } })

    this.isWaitingSwitch = false
  }

  toSearchPostRequest(): TicketsSearchPostRequest {
    const skip = TAKE * (this.pageNum - 1)
    const req = new TicketsSearchPostRequest(skip, TAKE)

    req.ticketStates = this.ticketsSearchPostRequestParam?.ticketStates?.length ? this.ticketsSearchPostRequestParam.ticketStates : undefined
    req.ticketTypes = this.ticketsSearchPostRequestParam?.ticketTypes?.length ? this.ticketsSearchPostRequestParam.ticketTypes : undefined
    req.buildings = this.ticketsSearchPostRequestParam?.buildings?.length ? this.ticketsSearchPostRequestParam.buildings : undefined
    req.owners = this.ticketsSearchPostRequestParam?.owners?.length ? this.ticketsSearchPostRequestParam.owners : undefined
    req.fromDate = this.ticketsSearchPostRequestParam?.fromDate ?? undefined
    req.toDate = this.ticketsSearchPostRequestParam?.toDate ?? undefined
    req.admins = this.ticketsSearchPostRequestParam?.admins?.length ? this.ticketsSearchPostRequestParam.admins : undefined
    req.keyword = this.ticketsSearchPostRequestParam?.keyword ?? undefined
    req.sortOrder = this.ticketsSearchPostRequestParam?.sortOrder ?? undefined

    return req
  }

  // --------------- チケット表示関連 ---------------
  TAKE = Object.freeze(TAKE)
  pageNum = 1

  tableHeaders: Header[] = [
    new Header({ text: '担当者', value: 'adminName', sortable: false, width: '128px' }),
    new Header({ text: 'ステータス', value: 'ticketState', sortable: false, width: '140px' }),
    new Header({ text: 'タスク', value: 'task', sortable: false, width: '74px' }),
    new Header({ text: 'チケット受付日', value: 'accrualDate', sortable: false, width: '160px' }),
    new Header({ text: 'マンション名', value: 'buildingName', sortable: false, width: '210px' }),
    new Header({ text: 'コンテンツ', value: 'ticketType', sortable: false, width: '134px' }),
    new Header({ text: 'チケットタイトル', value: 'ticketName', sortable: false, width: '280px' }),
    new Header({ text: '管理番号', value: 'ticketNo', sortable: false, width: '112px' }),
    new Header({ text: '区分所有者', value: 'ownerName', sortable: false, width: '128px' }),
  ]

  // ページ遷移時に一番上にスクロールされるようにする
  @Watch('pageNum', { immediate: false })
  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  private get ticketCount(): number { return ticketsModule.ticketCount ?? 0 }

  private get tickets(): TicketItem[] {
    const _tickets = ticketsModule.tickets
    if (_tickets.length === 0) return []
    return _tickets.map(t => this.toTicketItem(t))
  }

  toTicketItem(t: Ticket): TicketItem {
    const item = new TicketItem()

    item.ticketId = t.ticketId
    item.adminName = t.adminName ?? '未設定'
    item.ticketState = getTicketStateLabel(t.ticketState)
    item.chipColor = this.getChipColor(t.ticketState)
    item.task = t.completedTaskCount + '/' + t.allTaskCount
    item.accrualDate = t.postedAt
    item.buildingName = t.buildingName
    item.buildingId = t.buildingId
    item.ticketTypeText = getTicketLabel(t.ticketType)
    item.ticketTypeTextStyle = getTicketBadge(t.ticketType).textStyle
    item.ticketTypeIcon = getTicketBadge(t.ticketType).icon
    item.ticketTypeIconColor = getTicketBadge(t.ticketType).iconColor
    item.ticketName = t.ticketName
    item.ticketNo = t.ticketNo
    item.ownerName = t.ownerName ?? '未設定'

    return item
  }

  getChipColor(state: TicketState): ChipColors {
    return getTicketStateChipColor(state)
  }

  async onClickPageBtn(pageNum: number): Promise<void> {
    this.pageNum = pageNum
    await this.search()
  }

  // 絞り込みを行っているかを判定（チケットが0件（コンテンツが0件）の場合trueを返す）
  private get isInitSearch(): boolean {
    if ((this.ticketsSearchPostRequestParam?.ticketStates?.length ?? 0) > 0 ||
      (this.ticketsSearchPostRequestParam?.ticketTypes?.length ?? 0) > 0 ||
      (this.ticketsSearchPostRequestParam?.buildings?.length ?? 0) > 0 ||
      (this.ticketsSearchPostRequestParam?.owners?.length ?? 0) > 0 ||
      this.ticketsSearchPostRequestParam?.fromDate ||
      this.ticketsSearchPostRequestParam?.toDate ||
      (this.ticketsSearchPostRequestParam?.admins?.length ?? 0) > 0 ||
      this.ticketsSearchPostRequestParam?.keyword
    ) {
      return false
    }
    return true
  }

  // --------------- ボタンを押下した際の処理 ---------------

  goToCreateTicketPage(): void {
    this.$router.push({ name: staticRoutes.ticketCreate.name })
  }

  async onClickOperationReportOutputBtn(req:TicketsRegularMtgCsvPostRequest): Promise<void> {
    await ticketsModule.postRegularMtgCsv(req)
  }

  async onClickManagementReportOutputBtn(req:TicketsManagementStatusesCsvPostRequest): Promise<void> {
    await ticketsModule.postManagementStatusesCsv(req)
  }

  goToTicketDetailPage(ticket: TicketItem): void {
    this.$router.push({ name: staticRoutes.ticketDetail.name, params: { ticketId: ticket.ticketId } })
  }

  goToBuildingDetailPage(event: Event, buildingId: string): void {
    event.stopPropagation() // チケット詳細への遷移イベントを止める
    this.openBuildingDetailPage(buildingId)
  }

  openBuildingDetailPage(buildingId: string): void {
    // 該当のマンション詳細画面を別タブで開く
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId
    openNewTab(newTabTransitionParams)
  }

  // --------------- モーダル表示等の処理 ---------------

  // 報告書出力モーダル
  isReportOutputModalVisible = false
  reportOutputModalKey = generateUuid()
  openReportOutputModal(): void {
    this.reportOutputModalKey = generateUuid()
    this.isReportOutputModalVisible = true
  }

  // マンション・区分所有者選択モーダル
  isBuildingAndOwnerSelectModalVisible = false
  buildingAndOwnerSelectModalKey = generateUuid()
  openBuildingAndOwnerSelectModal(): void {
    this.buildingAndOwnerSelectModalKey = generateUuid()
    this.isBuildingAndOwnerSelectModalVisible = true
  }

  // 担当者選択モーダル
  isAdminSelectModalVisible = false
  adminSelectModalKey = generateUuid()
  openAdminSelectModal(): void {
    this.adminSelectModalKey = generateUuid()
    this.isAdminSelectModalVisible = true
  }
}
