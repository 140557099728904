






























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})
export default class SmOwBtnOutline extends Vue {
  @Prop()
  private readonly icon?: string

  @Prop()
  private readonly text?: string

  @Prop()
  private readonly count?: number

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly loading!: boolean

  @Prop({ default: 'secondary' })
  private readonly color!: string
}
