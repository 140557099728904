import type { TicketTaskState } from '@/constants/schema-constants'

export class TaskStatePutRequest {
  ticketTaskId!: string
  updateTaskState!: TicketTaskState
  version!:number

  constructor(ticketTaskId: string, updateTaskState: TicketTaskState, version: number) {
    this.ticketTaskId = ticketTaskId
    this.updateTaskState = updateTaskState
    this.version = version
  }
}

export class TaskStatePutResponse {
  taskState!: TicketTaskState
  version!: number
  completedAt?: string
}
