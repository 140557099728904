


































import { Vue, Component } from 'vue-property-decorator'
import { staticRoutesLocal } from '@/routes'
import { Tab } from '@/components/molecules/SmTabs.vue'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class TemplateUsagePage extends Vue {
  tabs = [
    new Tab('タブ1', staticRoutesLocal.templateUsage.getChild('tab1').name),
    new Tab('タブ2', staticRoutesLocal.templateUsage.getChild('tab2').name),
  ]

  currentTab = ''

  onClickBtn(action: string): void {
    console.log(`${action} button clicked!`)
  }
}
