
















import { Vue, Component, Prop } from 'vue-property-decorator'
import { CHIP_COLORS } from '@/constants/ux-constants'
import type { ChipColors } from '@/constants/ux-constants'

class ChipColor {
  public readonly background!: string
  public readonly textStyle!: string

  constructor(background: string, textStyle: string) {
    this.background = background
    this.textStyle = textStyle
  }
}

const chipColors: Record<ChipColors, ChipColor> = {
  [CHIP_COLORS.WHITE1]: new ChipColor('rgba(255, 255, 255, 1)', 'black'),
  [CHIP_COLORS.WHITE2]: new ChipColor('rgba(242, 242, 242, 1)', 'black'),
  [CHIP_COLORS.RED]: new ChipColor('rgba(254, 221, 221, 1)', 'red-dark'),
  [CHIP_COLORS.GREEN]: new ChipColor('rgba(205, 244, 227, 1)', 'green-dark'),
  [CHIP_COLORS.GRAY]: new ChipColor('rgba(236, 238, 239, 1)', 'gray-dark'),
  [CHIP_COLORS.ORANGE]: new ChipColor('rgba(255, 235, 205, 1)', 'saddle-brown'),
  [CHIP_COLORS.BLUE]: new ChipColor('rgba(230, 230, 250, 1)', 'midnight-blue'),
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})

export default class SmChip extends Vue {
  @Prop({ required: true, default: '' })
  private readonly text!: number | string

  @Prop({
    validator: c => c in chipColors
  })
  private readonly colorType!: ChipColors

  @Prop()
  private readonly isBorder?: boolean

  private get chipColor(): ChipColor {
    return chipColors[this.colorType]
  }
}
