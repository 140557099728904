









































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})
export default class SmBtnFloating extends Vue {
  @Prop()
  private readonly icon?: string

  @Prop()
  private readonly text?: string

  @Prop({ default: false })
  private readonly disabled!: boolean
}
