/** 太字の操作 **/
export function replaceBoldMarkToTag(text: string): string {
  const regexp = /\*\*([\s\S]*?)\*\*/g
  return text.replace(regexp, '<strong>$1</strong>')
}

export function replaceBoldTagToMark(text: string): string {
  const regexp = /<strong( [\s\S]*?)?>([\s\S]*?)<\/strong>/g
  return text.replace(regexp, '**$2**')
}

export function removeBoldTag(text: string): string {
  const regexp = /<strong( [\s\S]*?)?>([\s\S]*?)<\/strong>/g
  return text.replace(regexp, '$2')
}

/** 打消し線の操作 **/
export function replaceStrikethroughMarkToTag(text: string): string {
  const regexp = /~~([\s\S]*?)~~/g
  return text.replace(regexp, '<s>$1</s>')
}

export function replaceStrikethroughTagToMark(text: string): string {
  const regexp = /<s( [\s\S]*?)?>([\s\S]*?)<\/s>/g
  return text.replace(regexp, '~~$2~~')
}

/** 改行の操作 **/
export function replaceNewlineCharacterToTag(text: string, tag: string): string {
  const sentences = text.split('\n')
  for (let i = 0; i < sentences.length; i++) {
    if (sentences[i] === '') { // 空行の場合
      if (text.match(/^(\n)+$/)) { // textが空行のみの場合
        // split('\n')によりsentencesは表示させたい<br>の数+1個の空文字の配列となっているため、先頭の要素は<br>に変換せずに数を調整
        if (i !== 0) sentences[i] = `<${tag}><br></${tag}>`
      } else { // textが空行のみでない場合
        // 末尾が空行の場合、split('\n')によりsentencesの末尾が表示させたい<br>の数+1個の空文字の配列となっているため、最後の要素は<br>に変換せずに数を調整
        if (i !== sentences.length - 1) sentences[i] = `<${tag}><br></${tag}>`
      }
    } else {
      sentences[i] = `<${tag}>${sentences[i]}</${tag}>`
    }
  }
  return sentences.join('')
}

export function replaceNewlineTagToCharacter(text: string): string {
  const regexp = /<br>/g
  return text.replace(regexp, '\n')
}

/** 許容しないHTMLタグの消去 **/
export function filterHTMLTag(text: string): string {
  const regexp = /<(?!(\/?p>|\/?strong>|\/?s>|\/?h[2-4]>|\/?ol>|\/?ul>|\/?li>|li class="ql-indent-[1-8]">|br>))([\s\S]*?)>/g
  return text.replace(regexp, '')
}

/** 特殊文字の操作 **/
export function unescapeAll(text: string): string {
  const leftAngleBracketUnescaped = unescapeLeftAngleBracket(text)
  const rightAngleBracketUnescaped = unescapeRightAngleBracket(leftAngleBracketUnescaped)
  const doubleQuoteUnescaped = unescapeDoubleQuote(rightAngleBracketUnescaped)
  const singleQuoteUnescaped = unescapeSingleQuote(doubleQuoteUnescaped)
  const noBreakSpaceUnescaped = unescapeNoBreakSpace(singleQuoteUnescaped)
  const filtered = filterSpecialCharacters(noBreakSpaceUnescaped)
  const ampersandUnescaped = unescapeAmpersand(filtered)
  return ampersandUnescaped
}

export function unescapeLeftAngleBracket(text: string): string {
  const regexp = /&lt;/g
  return text.replace(regexp, '<')
}

export function unescapeRightAngleBracket(text: string): string {
  const regexp = /&gt;/g
  return text.replace(regexp, '>')
}

export function unescapeAmpersand(text: string): string {
  const regexp = /&amp;/g
  return text.replace(regexp, '&')
}

export function unescapeDoubleQuote(text: string): string {
  const regexp = /&quot;/g
  return text.replace(regexp, '"')
}

export function unescapeSingleQuote(text: string): string {
  const regexp = /&#39;/g
  return text.replace(regexp, '\'')
}

export function unescapeNoBreakSpace(text: string): string {
  const regexp = /&nbsp;/g
  return text.replace(regexp, ' ')
}

export function escapeAll(text: string): string {
  const ampersandEscaped = escapeAmpersand(text)
  const leftAngleBracketEscaped = escapeLeftAngleBracket(ampersandEscaped)
  const rightAngleBracketEscaped = escapeRightAngleBracket(leftAngleBracketEscaped)
  const doubleQuoteEscaped = escapeDoubleQuote(rightAngleBracketEscaped)
  const singleQuoteEscaped = escapeSingleQuote(doubleQuoteEscaped)
  const noBreakSpaceEscaped = escapeNoBreakSpace(singleQuoteEscaped)
  return noBreakSpaceEscaped
}

export function escapeLeftAngleBracket(text: string): string {
  const regexp = /</g
  return text.replace(regexp, '&lt;')
}

export function escapeRightAngleBracket(text: string): string {
  const regexp = />/g
  return text.replace(regexp, '&gt;')
}

export function escapeAmpersand(text: string): string {
  const regexp = /&/g
  return text.replace(regexp, '&amp;')
}

export function escapeDoubleQuote(text: string): string {
  const regexp = /"/g
  return text.replace(regexp, '&quot;')
}

export function escapeSingleQuote(text: string): string {
  const regexp = /'/g
  return text.replace(regexp, '&#39;')
}

export function escapeNoBreakSpace(text: string): string {
  const regexp = / /g
  return text.replace(regexp, '&nbsp;')
}

/**
 * 特殊文字（&と;で囲まれた文字）の全消去
 * ※「&」以外で許容したい特殊文字は本メソッドで消去する前に置換しておき「&」の置換は本メソッド適用後に行うこと
 **/
export function filterSpecialCharacters(text: string): string {
  const regexp = /&(?!(amp;))([\s\S]*?);/g
  return text.replace(regexp, '')
}

/**
 * 太字、打消し線のHTMLタグをマークに変換します。
 * 改行・空行のHTMLタグを改行文字に変換します。
 * 不要なHTMLタグを消去します。
 * 特殊文字のエスケープを解除します。
 * @param isBoldAvailable 太字を適用可能ならtrue
 */
export function adjustTagsAndCharacters(text: string, isBoldAvailable: boolean): string {
  let boldTagReplaced
  if (isBoldAvailable) {
    boldTagReplaced = replaceBoldTagToMark(text)
  } else {
    boldTagReplaced = removeBoldTag(text)
  }
  const strikethroughTagReplaced = replaceStrikethroughTagToMark(boldTagReplaced)
  const newlineTagReplaced = replaceNewlineTagToCharacter(strikethroughTagReplaced)
  const filteredSentence = filterHTMLTag(newlineTagReplaced)
  const unescapedSentence = unescapeAll(filteredSentence)

  return unescapedSentence
}
