export class OnlineVotePost {
  ownerUserId!:string
  voteType?:number
}

export class VotesPostRequest {
  resolutionId!: string
  votes:OnlineVotePost[] = []
  version!:number
}
