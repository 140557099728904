var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sm-template',{attrs:{"title":"区分所有者","top-left-icon":"","is-no-max-width":""}},[_c('div',{staticClass:"sm__background-white px-6 py-4"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex"},[_c('sm-text-field',{staticStyle:{"width":"256px"},attrs:{"placeholder":"住戸番号／氏名で検索","type":"text"},model:{value:(_vm.inputText),callback:function ($$v) {_vm.inputText=$$v},expression:"inputText"}}),_c('sm-btn',{staticClass:"ml-2",attrs:{"type":"primary-flex","text":"検索","disabled":invalid},on:{"click-btn":function($event){_vm.keyword = _vm.inputText}}}),_c('v-spacer'),(_vm.$isServiceStaff && !_vm.isBuildingOutOfService)?_c('sm-btn',{attrs:{"type":"secondary","text":"csvを出力する"},on:{"click-btn":_vm.downloadCsv}}):_vm._e()],1)]}}])}),_c('div',{staticClass:"mt-4 d-flex"},[_c('div',[_c('sm-text',{attrs:{"format":"sm__caption","text":("区分所有者総数・" + (_vm.unitsGet.ownerCount) + "名"),"color":"black","level":"2"}})],1),_c('div',{staticClass:"ml-4"},[_c('sm-text',{attrs:{"format":"sm__caption","text":("紙申請者数・" + (_vm.unitsGet.paperUseAppliedCount) + "/" + (_vm.unitsGet.ownerCount) + "名"),"color":"black","level":"2"}})],1),_c('div',{staticClass:"ml-4"},[_c('sm-text',{attrs:{"format":"sm__caption","text":("初回認証済者数・" + (_vm.unitsGet.initialAuthCount) + "/" + (_vm.unitsGet.ownerCount) + "名"),"color":"black","level":"2"}})],1)]),_c('sm-table-data',{staticClass:"mt-4",attrs:{"headers":_vm.tableHeaders,"items":_vm.units,"item-key":"unitId","content-name":"区分所有者","keyword":_vm.keyword},scopedSlots:_vm._u([{key:"item.roomNumber",fn:function(ref){
var item = ref.item;
return [_c('sm-btn-text',{attrs:{"text":item.roomNumber},on:{"click":function($event){return _vm.goDetail(item.unitId)}}})]}},{key:"item.isAlias",fn:function(ref){
var item = ref.item;
return [(item.isAlias)?_c('sm-text',{attrs:{"format":"sm__data","text":"あり","color":"black","level":"1"}}):_vm._e()]}},{key:"item.isLivingOutside",fn:function(ref){
var item = ref.item;
return [(item.isLivingOutside)?_c('sm-text',{attrs:{"format":"sm__data","text":"有","color":"black","level":"1"}}):_vm._e()]}},{key:"item.isPaperUseApplied",fn:function(ref){
var item = ref.item;
return [(item.isPaperUseApplied)?_c('sm-text',{attrs:{"format":"sm__data","text":"申請済","color":"black","level":"1"}}):_vm._e()]}},{key:"item.ownedDwellingUnits",fn:function(ref){
var item = ref.item;
return [(item.ownedDwellingUnits)?_c('sm-text',{attrs:{"format":"sm__data","text":item.ownedDwellingUnits,"color":"black","level":"1"}}):_vm._e()]}},{key:"item.isInitialAuth",fn:function(ref){
var item = ref.item;
return [_c('sm-text',{attrs:{"format":"sm__data","text":item.isInitialAuth ? '' : '未',"color":"black","level":"1"}})]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }