




















































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import VueRouter from 'vue-router'
import { NavigationGuardNext, Route } from 'vue-router/types'

import { RESOLUTION_STATES } from '@/constants/schema-constants'
import { DAY_OF_WEEK, PAGE_TYPES } from '@/constants/ux-constants'
import type { PageType } from '@/constants/ux-constants'

import { BaseIdea, Estimate, Section, MaterialFormInputDto } from '@/dtos/commons'
import { AdminIdeaDecisionGetRequest, AdminIdeaDecisionGetResponse } from '@/dtos/ideas/admin-idea/decision/get'
import { OnlineResolutionsDraftDeleteRequest } from '@/dtos/resolutions/online-resolution/draft/delete'
import { OnlineResolutionsDraftPostRequest } from '@/dtos/resolutions/online-resolution/draft/post'
import { OnlineResolutionDetailGetRequest, OnlineResolutionDetailGetResponse } from '@/dtos/resolutions/online-resolution/get-detail'
import { OnlineResolutionsPostRequest } from '@/dtos/resolutions/online-resolution/post'
import { OnlineResolutionsPutRequest } from '@/dtos/resolutions/online-resolution/put'
import { ResolutionTicketRelatedIdeaGetRequest } from '@/dtos/tickets/resolutions/ideas/get'

import { deepCopy, ColumnToType } from '@/libs/deep-copy-provider'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'
import { staticKeyProvider } from '@/libs/static-key-provider'
import { generateUuid } from '@/libs/uuid-generator'
import { windowOpen } from '@/libs/window-open'

import { staticRoutes } from '@/routes'

import { adminIdeasModule } from '@/stores/admin-ideas-store'
import { currentStateModule } from '@/stores/current-state'
import { newTabLocalParamStorageModule, OnlineResolutionPreviewContent } from '@/stores/new-tab-local-param-storage-store'
import { onlineResolutionsModule } from '@/stores/online-resolutions-store'
import { ticketsModule } from '@/stores/tickets-store'

// SmDatePicker用のフォーマット（YYYY-MM-DD）でn日後の年月日を返す
const dateAfter = (n: number): string => {
  const date = new Date()
  date.setDate(date.getDate() + n)
  // ISOString形式の日本時間を取得したいが、
  // toISOString()の結果はUTCのため新規・修正に関わらず9時間進める
  date.setHours(date.getHours() + 9)
  return date.toISOString().substr(0, 10)
}

const isPast = (targetDateTime?: string): boolean => {
  if (targetDateTime === undefined) return false
  return new Date(targetDateTime).getTime() < new Date().getTime()
}

const uploadThenPost = async <T, >(rawReq: T, exec: (_: T) => Promise<void>) => {
  const uploader = new FileUploader()
  const req = await uploader.prepare(rawReq)

  await exec(req)
}

class FormInputs {
  resolutionId?: string
  title = ''
  matter = ''

  voteDate = dateAfter(14)
  voteHour = 0
  voteMinute = 0
  questionDate = dateAfter(13)
  questionHour = 0
  questionMinute = 0

  estimates: Estimate[] = []
  sections: Section[] = []

  baseIdeaId: string | null = null
  version?: number

  get voteDeadline(): string {
    return `${this.voteDate} ${this.voteHour}:${this.voteMinute}`
  }

  get deadline(): string {
    return `${this.questionDate} ${this.questionHour}:${this.questionMinute}`
  }
}

interface PageTypeSpecifications {
  pageTitle: string
  executeBtnLabel: string
  dialogMessage: string

  isDraft: boolean
  disablesQuestionDeadlineIfPast: boolean

  created: () => void | Promise<void>
  baseIdeaId: () => string | undefined
  onClickExecute: ($router: VueRouter, inputs: FormInputs) => Promise<void>
  onClickSaveDraft: ($router: VueRouter, inputs: FormInputs) => Promise<void> | void
  onClickDeleteDraft: ($router: VueRouter) => Promise<void> | void
}

class CreateSpecifications implements PageTypeSpecifications {
  ticketId: string
  constructor(ticketId: string) { this.ticketId = ticketId }

  readonly pageTitle = 'オンライン決議を作成する'
  readonly executeBtnLabel = '投稿する'
  readonly dialogMessage = 'オンライン決議を投稿します。よろしいですか？'
  readonly isDraft = false
  readonly disablesQuestionDeadlineIfPast = false

  async created() {
    // 遷移元の画面からチケットIDを指定されるので、もととなるアイデアを取得
    await ticketsModule.fetchResolutionTicketRelatedIdeas(new ResolutionTicketRelatedIdeaGetRequest(this.ticketId))
  }

  baseIdeaId():string | undefined {
    // オンライン決議に紐付くアイデアは1つまで
    return ticketsModule.resolutionTicketRelatedIdeas?.ideaIds[0]
  }

  async onClickExecute($router: VueRouter, inputs: FormInputs): Promise<void> {
    const req = new OnlineResolutionsPostRequest(inputs.title, inputs.deadline, inputs.voteDeadline,
      inputs.matter, inputs.estimates, inputs.sections, this.ticketId)
    if (inputs.baseIdeaId) req.baseIdea = new BaseIdea(inputs.baseIdeaId)

    await uploadThenPost(req, onlineResolutionsModule.postResolutions)

    $router.push({ name: staticRoutes.resolutionsList.name })
  }

  async onClickSaveDraft($router: VueRouter, inputs: FormInputs): Promise<void> {
    const req = new OnlineResolutionsDraftPostRequest(inputs.title, inputs.deadline, inputs.voteDeadline,
      inputs.matter, inputs.estimates, inputs.sections, this.ticketId)
    if (inputs.baseIdeaId) req.baseIdea = new BaseIdea(inputs.baseIdeaId)

    await uploadThenPost(req, onlineResolutionsModule.postDraftResolutions)

    $router.push({ name: staticRoutes.resolutionsList.name })
  }

  onClickDeleteDraft() { /** nothing to do */ }
}

class DraftSpecifications implements PageTypeSpecifications {
  ticketId: string
  resolutionId: string
  constructor(ticketId:string, resolutionId: string) {
    this.ticketId = ticketId
    this.resolutionId = resolutionId
  }

  readonly pageTitle = 'オンライン決議を作成する'
  readonly executeBtnLabel = '投稿する'
  readonly dialogMessage = 'オンライン決議を投稿します。よろしいですか？'
  readonly isDraft = true
  readonly disablesQuestionDeadlineIfPast = false

  async created() {
    await onlineResolutionsModule.fetchResolutionDetail(new OnlineResolutionDetailGetRequest(this.resolutionId))
  }

  baseIdeaId():string | undefined {
    return onlineResolutionsModule.detailResponse(this.resolutionId)?.baseIdea?.ideaId
  }

  async onClickExecute($router: VueRouter, inputs: FormInputs): Promise<void> {
    const req = new OnlineResolutionsPostRequest(inputs.title, inputs.deadline, inputs.voteDeadline,
      inputs.matter, inputs.estimates, inputs.sections, this.ticketId, this.resolutionId)
    if (inputs.baseIdeaId) req.baseIdea = new BaseIdea(inputs.baseIdeaId)

    await uploadThenPost(req, onlineResolutionsModule.postResolutions)

    $router.push({ name: staticRoutes.resolutionsList.name })
  }

  async onClickSaveDraft($router: VueRouter, inputs: FormInputs): Promise<void> {
    const req = new OnlineResolutionsDraftPostRequest(inputs.title, inputs.deadline, inputs.voteDeadline,
      inputs.matter, inputs.estimates, inputs.sections, this.ticketId, this.resolutionId)
    if (inputs.baseIdeaId) req.baseIdea = new BaseIdea(inputs.baseIdeaId)

    await uploadThenPost(req, onlineResolutionsModule.postDraftResolutions)

    $router.push({ name: staticRoutes.resolutionsList.name })
  }

  async onClickDeleteDraft($router: VueRouter): Promise<void> {
    const req = new OnlineResolutionsDraftDeleteRequest(this.resolutionId)
    await onlineResolutionsModule.deleteResolutionsDraft(req)

    $router.go(-1)
  }
}

class UpdateSpecifications implements PageTypeSpecifications {
  resolutionId: string
  constructor(resolutionId: string) { this.resolutionId = resolutionId }

  readonly pageTitle = 'オンライン決議を編集する'
  readonly executeBtnLabel = '更新する'
  readonly dialogMessage = 'オンライン決議を更新します。よろしいですか？'
  readonly isDraft = false
  readonly disablesQuestionDeadlineIfPast = true

  async created() {
    await onlineResolutionsModule.fetchResolutionDetail(new OnlineResolutionDetailGetRequest(this.resolutionId))
  }

  baseIdeaId():string | undefined {
    return onlineResolutionsModule.detailResponse(this.resolutionId)?.baseIdea?.ideaId
  }

  async onClickExecute($router: VueRouter, inputs: FormInputs) {
    const req = new OnlineResolutionsPutRequest(this.resolutionId, inputs.title, inputs.deadline, inputs.voteDeadline,
      inputs.matter, inputs.estimates, inputs.sections, inputs.version)
    if (inputs.baseIdeaId) req.baseIdea = new BaseIdea(inputs.baseIdeaId)

    await uploadThenPost(req, onlineResolutionsModule.putResolutions)

    $router.go(-1) // オンライン決議詳細画面で矢印アイコンから遷移元の画面に戻れるようにする
  }

  onClickSaveDraft() { /** nothing to do */ }
  onClickDeleteDraft() { /** nothing to do */ }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardSectionMoney: () => import('@/components/molecules/card/SmCardSectionMoney.vue'),
    SmCardSectionText: () => import('@/components/molecules/card/SmCardSectionText.vue'),
    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmListBudget: () => import('@/components/molecules/list/SmListBudget.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmDraftInterceptor: () => import('@/components/organisms/SmDraftInterceptor.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
/**
 * ルーティング時に指定する項目
 * pageType -> ルーティング先によって自動判別
 * resolutionId -> 新規作成画面の場合で、下書きの編集ならそのIDを指定（クエリ）。編集画面の場合、その決議のIDを指定（パス）。
 * baseIdeaId -> 新規作成画面で、もととなるプランを事前に指定する場合に、そのアイデアのIDを指定（クエリ）。
 */
export default class OnlineResolutionPostPage extends Vue {
  PAGE_TYPES = Object.freeze(PAGE_TYPES)

  @Prop()
  private readonly resolutionId?: string

  @Prop({ default: '' })
  private readonly ticketId!: string

  @Prop({ required: true, default: PAGE_TYPES.CREATE })
  private readonly pageType!: PageType

  async created(): Promise<void> {
    this.buildingId = currentStateModule.currentBuildingId
    await this.typeSpecs.created()
    const baseIdeaId = this.typeSpecs.baseIdeaId()
    if (baseIdeaId) {
      await this.fetchBaseIdea(baseIdeaId)
    }
  }

  private get typeSpecs(): PageTypeSpecifications {
    // 決議新規作成の場合、チケットIDからbaseIdeaIdを取得
    if (!this.resolutionId) {
      return new CreateSpecifications(this.ticketId)
    }

    switch (this.pageType) {
      case PAGE_TYPES.CREATE: return new DraftSpecifications(this.ticketId, this.resolutionId)
      case PAGE_TYPES.EDIT: return new UpdateSpecifications(this.resolutionId)
      default: return assertExhaustive(this.pageType)
    }
  }

  get isPageTypeEdit(): boolean { return this.pageType === PAGE_TYPES.EDIT }

  private buildingId: string | null = null
  inputs = new FormInputs()
  get disabledQuestionDeadline(): boolean {
    return this.typeSpecs.disablesQuestionDeadlineIfPast && isPast(this.storedResolution?.deadlineDateTime)
  }

  private get storedResolution(): OnlineResolutionDetailGetResponse | undefined {
    if (!this.resolutionId) return undefined
    return onlineResolutionsModule.detailResponse(this.resolutionId)
  }

  @Watch('storedResolution', { immediate: false, deep: false })
  onStoredResolutionFetched(fetched: OnlineResolutionDetailGetResponse | undefined): void {
    if (!fetched) return

    this.inputs.title = fetched.title
    this.inputs.matter = fetched.matter
    this.inputs.questionDate = fetched.deadlineDateTime.substring(0, 10)
    this.inputs.questionHour = Number(fetched.deadlineDateTime.substring(11, 13))
    this.inputs.questionMinute = Number(fetched.deadlineDateTime.substring(14, 16))
    this.inputs.voteDate = fetched.voteDeadlineDateTime.substring(0, 10)
    this.inputs.voteHour = Number(fetched.voteDeadlineDateTime.substring(11, 13))
    this.inputs.voteMinute = Number(fetched.voteDeadlineDateTime.substring(14, 16))
    this.inputs.estimates = deepCopy(
      fetched.estimates,
      { estimates: new ColumnToType(Estimate, true) },
      'estimates'
    )
    this.inputs.sections = deepCopy(
      fetched.sections,
      {
        sections: new ColumnToType(Section, true),
        material: new ColumnToType(MaterialFormInputDto)
      },
      'sections'
    )
    this.inputs.version = fetched.version
  }

  private get hours(): { value: number, label: string }[] {
    const hours : { value: number, label: string }[] = []
    for (let i = 0; i <= 23; i++) {
      hours.push({ value: i, label: ('0' + i).slice(-2) }) // 数字を2桁で表示
    }
    return hours
  }

  private async fetchBaseIdea(baseIdeaId: string): Promise<void> {
    await adminIdeasModule.fetchIdeaDecision(new AdminIdeaDecisionGetRequest(baseIdeaId))
    this.inputs.baseIdeaId = baseIdeaId
    // 下書きがないか、元になったアイデアが変更になった場合、元になったアイデアのタイトルを決議タイトルにする
    if (!this.resolutionId || baseIdeaId !== this.storedResolution?.baseIdea?.ideaId) {
      this.inputs.title = this.baseIdea?.title ?? ''
    }
  }

  // アイデア選択モーダルからideaIdを取得して、その値を使って採用プランをAPIで取得する
  private get baseIdea():AdminIdeaDecisionGetResponse | undefined {
    if (this.inputs.baseIdeaId) {
      return adminIdeasModule.decisionResponse(this.inputs.baseIdeaId)
    } else return undefined
  }

  planExpansion = false

  // ダイアログの表示・非表示
  isDeleteDraftDialogVisible = false
  openDeleteDraftDialog(): void { this.isDeleteDraftDialogVisible = true }
  closeDeleteDraftDialog(): void { this.isDeleteDraftDialogVisible = false }

  isSaveDraftDialogVisible = false
  openSaveDraftDialog(): void { this.isSaveDraftDialogVisible = true }
  closeSaveDraftDialog(): void { this.isSaveDraftDialogVisible = false }

  isExecuteDialogVisible = false
  openExecuteDialog(): void { this.isExecuteDialogVisible = true }
  closeExecuteDialog(): void { this.isExecuteDialogVisible = false }

  // 各種実行ボタン
  async onClickExecute(): Promise<void> {
    this.processCompleted = true
    this.closeExecuteDialog()
    await this.typeSpecs.onClickExecute(this.$router, this.inputs)
  }

  async onClickSaveDraft(): Promise<void> {
    this.processCompleted = true
    this.closeSaveDraftDialog()
    await this.typeSpecs.onClickSaveDraft(this.$router, this.inputs)
  }

  async onClickDeleteDraft(): Promise<void> {
    this.processCompleted = true
    this.closeDeleteDraftDialog()
    await this.typeSpecs.onClickDeleteDraft(this.$router)
  }

  // 素材IDと取得済み参照用URLを含む素材情報の組み合わせを保持
  materialReferenceURLMap: Map<string, MaterialFormInputDto> = new Map()

  async onClickPreviewBtn(): Promise<void> {
    const previewContents: OnlineResolutionPreviewContent = new OnlineResolutionPreviewContent()
    previewContents.resolutionId = this.resolutionId ? this.resolutionId : ''
    previewContents.title = this.inputs.title
    previewContents.resolutionState = this.storedResolution ? this.storedResolution.resolutionState : RESOLUTION_STATES.ONLINE.ACCEPTING_ALL
    previewContents.deadlineDateTime = this.toDeadlineFormat(this.inputs.voteDate, this.inputs.voteHour, this.inputs.voteMinute)
    previewContents.matter = this.inputs.matter
    previewContents.isQustions = this.storedResolution ? this.storedResolution.details.isQuestions : false
    previewContents.previewBudgets = this.inputs.estimates.map(estimate => estimate)
    previewContents.previewElements = await Promise.all(this.inputs.sections.map(async section => {
      if (section.material) {
        section.material = await this._uploadMaterial(Object.assign(new MaterialFormInputDto(), section.material))
      }
      return section
    }))
    if (this.inputs.baseIdeaId) previewContents.baseIdea = new BaseIdea(this.inputs.baseIdeaId)
    previewContents.currentBuildingId = this.buildingId ?? ''

    const previewContentsId = generateUuid()
    newTabLocalParamStorageModule.setOnlineResolutionPreviewContent({ key: previewContentsId, onlineResolutionPreviewContent: previewContents })
    windowOpen(staticRoutes.onlineResolutionPreview.path.replace(':id', previewContentsId))
  }

  private async _uploadMaterial(material: MaterialFormInputDto): Promise<MaterialFormInputDto | undefined> {
    if (!material.materialId) { // 新たに添付した素材の場合
      // ローカルストレージのサイズの制約上、プレビュー時に素材をそのまま別タブに渡すのが難しいため、ここでアップロードする
      const uploadedMaterial = await uploadMaterial(material)
      if (uploadedMaterial) {
        this.materialReferenceURLMap.set(uploadedMaterial.materialId, uploadedMaterial)
        return uploadedMaterial
      }
    } else if (this.materialReferenceURLMap.get(material.materialId)) { // 一度プレビューした素材を付け替えずに使用する場合
      const renamedMaterial = this.materialReferenceURLMap.get(material.materialId)
      if (renamedMaterial) {
        renamedMaterial.originalFileName = material.originalFileName
        renamedMaterial.originalFileNameInitialCopy = material.originalFileNameInitialCopy
      }
      return renamedMaterial
    } else { // すでにDBに登録済みの素材を画面初期表示から一度も付け替えずに使用する場合
      return material
    }
  }

  toDeadlineFormat(date: string, hour: number, minute: number): string {
    const dadlineDayOfWeek = DAY_OF_WEEK[new Date(date).getDay()]
    const deadlineDate = `${date.replace('-', '年').replace('-', '月')}日`
    const deadlineHour = hour < 10 ? `0${hour}` : hour
    const deadlineMinute = minute < 10 ? `0${minute}` : minute
    return `${deadlineDate}(${dadlineDayOfWeek}) ${deadlineHour}:${deadlineMinute}`
  }

  // カードの追加・削除の制御
  addSection(): void {
    const section = staticKeyProvider.create(Section)
    section.sectionTitle = ''
    section.sectionBody = ''
    section.material = null
    this.inputs.sections.push(section)
  }

  addEstimate(): void {
    const estimate = staticKeyProvider.create(Estimate)
    estimate.expenseLabel = ''
    estimate.budgetLabel = ''
    estimate.balanceLabel = ''
    estimate.expense = undefined
    estimate.budget = undefined
    estimate.spent = undefined
    this.inputs.estimates.push(estimate)
  }

  deleteSection(sectionIndex: number): void {
    this.inputs.sections.splice(sectionIndex, 1)
  }

  deleteEstimate(estimateIndex: number): void {
    this.inputs.estimates.splice(estimateIndex, 1)
  }

  // カードの順序の入れ替え
  goUpSection(sectionIndex: number): void {
    this.inputs.sections.splice(sectionIndex - 1, 2, this.inputs.sections[sectionIndex], this.inputs.sections[sectionIndex - 1])
  }

  goDownSection(sectionIndex: number): void {
    this.inputs.sections.splice(sectionIndex, 2, this.inputs.sections[sectionIndex + 1], this.inputs.sections[sectionIndex])
  }

  goUpEstimate(estimateIndex: number): void {
    this.inputs.estimates.splice(estimateIndex - 1, 2, this.inputs.estimates[estimateIndex], this.inputs.estimates[estimateIndex - 1])
  }

  goDownEstimate(estimateIndex: number): void {
    this.inputs.estimates.splice(estimateIndex, 2, this.inputs.estimates[estimateIndex + 1], this.inputs.estimates[estimateIndex])
  }

  nextRoute: Route | null = null
  processCompleted = false
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (this.isPageTypeEdit || this.processCompleted || this.nextRoute) {
      next()
    } else {
      this.nextRoute = to
      next(false)
    }
  }

  leaveHere(): void {
    const routeName = this.nextRoute?.name
    const routeParams = this.nextRoute?.params
    // 画面遷移元が以下のリスト内だった場合、ヒストリーを戻す
    const goBackRouteNames = [
      staticRoutes.ticketDetail.getChild('tasks').name // チケット詳細・タスクタブ
    ]
    if (routeName) {
      if (goBackRouteNames.includes(routeName)) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: routeName, params: routeParams })
      }
    }
  }
}
