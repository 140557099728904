


































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { MATERIAL_TYPES } from '@/constants/schema-constants'
import type { MaterialType } from '@/constants/schema-constants'
import { windowOpen } from '@/libs/window-open'

const MAX_RETRY_ATTEMPT = 6

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),
  }
})
export default class SmMaterialDisplay extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop({ required: true, default: 0 })
  private readonly materialType!: MaterialType

  @Prop({ required: true, default: '' })
  private readonly materialUrl!: string

  @Prop()
  private readonly originalFileName?: string

  @Prop()
  private readonly materialAlt?: string

  @Prop()
  private readonly caption?: string

  @Prop({ default: 200 })
  private readonly maxWidth!: string | number

  @Prop({ default: '75%' })
  private readonly maxHeight!: string | number

  @Prop({ default: false })
  private readonly hyperlinked!: boolean

  @Prop({ default: false })
  private readonly eager!: boolean

  @Prop({ default: false })
  private readonly sameOwner!: boolean

  @Prop({ default: false })
  private readonly disableImageZoomIn!: boolean

  private get alt() { return this.materialAlt ?? this.originalFileName ?? '' }

  private get videoStyle() {
    let style = ''
    if (this.maxWidth) style = style + `max-width: ${this.styled(this.maxWidth)};\n`
    if (this.maxHeight) style = style + `max-height: ${this.styled(this.maxHeight)};\n`
    return style
  }

  private styled(wh: string | number) {
    return typeof wh === 'number' || /^[1-9]+$/.test(wh) ? `${wh}px` : wh
  }

  loaded = false // 画像読み込み完了を待ってアイコンを表示する

  isLoadFailing = false
  retryAttempt = 0
  private get failedMessage() {
    const base = this.retryAttempt >= MAX_RETRY_ATTEMPT
      ? 'の読み込みに失敗しました。画面の再読み込みをお試しください。'
      : 'の準備に時間がかかっています。一定時間後に自動で再読み込みします。'
    switch (this.materialType) {
      case MATERIAL_TYPES.IMAGE: return '画像' + base
      case MATERIAL_TYPES.VIDEO: return '動画' + base + '改善しない場合、ご利用の環境でサポートされていない形式です。'
      default: return 'ファイル' + base
    }
  }

  private onLoadError(): void {
    this.isLoadFailing = true
    if (this.retryAttempt >= MAX_RETRY_ATTEMPT) return

    // 素材のウイルスチェック結果がDBに反映されるまで、アップロードから平均30秒程度かかる想定
    // 最速で素材情報を取得したとして、順に 9s, 8s, 7s, 6s, 5s, 4s の間隔で読み込んで遅くとも4,5回目には成功する見込み
    setTimeout(() => { this.retryAttempt++; this.isLoadFailing = false }, (9 - this.retryAttempt) * 1000)
  }

  private onClickImage(): void {
    if (!this.disableImageZoomIn) this.openMaterialInNewTab()
  }

  private openMaterialInNewTab(): void {
    windowOpen(this.materialUrl)
  }
}
