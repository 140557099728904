









































































































































































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { MATERIAL_TYPES, RESOLUTION_STATES } from '@/constants/schema-constants'
import { ERROR_MESSAGES } from '@/constants/ux-constants'

import { staticKeyProvider } from '@/libs/static-key-provider'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { FileUploader } from '@/libs/file-uploader'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { gmResolutionsModule } from '@/stores/gm-resolutions-store'
import { myProfileModule } from '@/stores/my-profile-store'
import { structureModule } from '@/stores/structure-store'

import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { Section, Subject } from '@/dtos/resolutions/gm-resolution/results/commons'
import { ResultsGetResponse } from '@/dtos/resolutions/gm-resolution/results/get'
import { ResultsPostRequest } from '@/dtos/resolutions/gm-resolution/results/post'
import { ResultsPutRequest } from '@/dtos/resolutions/gm-resolution/results/put'
import { MaterialFormInputDto } from '@/dtos/commons'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    CardSubjectResult: () => import('@/pages/resolutions/gm-resolution/gm-resolution-detail/results/CardSubjectResult.vue'),
  }
})
export default class ResultsSubPage extends Mixins(CurrentAdminManager) {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
  RESOLUTION_STATES = Object.freeze(RESOLUTION_STATES)

  @Prop({ required: true, default: '' })
  private readonly resolutionId!:string

  @Prop()
  private readonly buildingId?: string

  async reloadResults(): Promise<void> {
    await gmResolutionsModule.fetchResults(this.resolutionId)
  }

  async created():Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }
    await this.reloadResults()
  }

  private get isPreview(): boolean { return structureModule.isPreview }

  private get res(): ResultsGetResponse {
    return gmResolutionsModule.resultsGet(this.resolutionId) ?? new ResultsGetResponse()
  }

  private get subjects(): Subject[] { return this.res?.results?.subjects ?? [] }

  resultCommentForInput = ''
  resultMaterial: MaterialFormInputDto | null = null

  // APIから取得した結果と別に、これから登録する内容を保持するSubjectの配列を用意
  // APIの結果返却後、それを初期値として設定する
  subjectsResultInput: Subject[] = []

  @Watch('subjects', { immediate: false, deep: false })
  reflectInitialData(apiResponse: Subject[]): void {
    this.subjectsResultInput = deepCopy(
      apiResponse,
      {
        subject: new ColumnToType(Subject),
        sections: new ColumnToType(Section, true)
      },
      'subject'
    )

    this.resultCommentForInput = this.res.results.resultComment ?? ''
    if (this.res.results.material) this.resultMaterial = Object.assign(new MaterialFormInputDto(), this.res.results.material)
    this.subjectsResultInput.forEach(subject => {
      subject.isPassed = subject.isPassed ?? true
    })
  }

  // 管理者業務執行者のみ、結果の投稿・編集可能
  private get hasAuthority():boolean {
    return this.$isServiceStaff
  }

  private get showInputForm():boolean {
    return this.hasAuthority && (this.res.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.COUNTING || this.isEditMode)
  }

  showPostDialog = false
  showUpdateDialog = false

  async postResult(): Promise<void> {
    const req = new ResultsPostRequest()
    req.resolutionId = this.resolutionId
    req.resultComment = this.resultCommentForInput
    req.material = this.resultMaterial ?? undefined
    req.subjects = this.subjectsResultInput
    req.version = this.res.version

    this.showPostDialog = false
    await this._prepareThenExec(req, gmResolutionsModule.postResults)

    // 画面を再描画
    await this.reloadResults()
  }

  isEditMode = false
  editResult():void {
    if (this.isPreview) return
    this.isEditMode = true
  }

  async updateResult(): Promise<void> {
    const req = new ResultsPutRequest()
    req.resolutionId = this.resolutionId
    req.resultComment = this.resultCommentForInput
    req.material = this.resultMaterial ?? undefined
    req.subjects = this.subjectsResultInput
    req.version = this.res.version

    this.showUpdateDialog = false
    await this._prepareThenExec(req, gmResolutionsModule.putResults)

    this.isEditMode = false
    // 画面を再描画
    await this.reloadResults()
  }

  private async _prepareThenExec<T>(rawReq: T, exec: (req: T) => Promise<void>) {
    const uploader = new FileUploader()
    const req = await uploader.prepare(rawReq)

    await exec(req)
  }

  goUp(subjectIndex: number, sectionIndex:number):void {
    if (this.isPreview) return
    const targetSections = this.subjectsResultInput[subjectIndex].sections
    targetSections?.splice(sectionIndex - 1, 2, targetSections[sectionIndex], targetSections[sectionIndex - 1])
  }

  goDown(subjectIndex: number, sectionIndex:number):void {
    if (this.isPreview) return
    const targetSections = this.subjectsResultInput[subjectIndex].sections
    targetSections?.splice(sectionIndex, 2, targetSections[sectionIndex + 1], targetSections[sectionIndex])
  }

  deleteSection(subjectIndex: number, sectionIndex:number):void {
    if (this.isPreview) return
    this.subjectsResultInput[subjectIndex].sections?.splice(sectionIndex, 1)
  }

  addSection(subjectIndex: number): void {
    if (this.isPreview) return
    const newSection = staticKeyProvider.create(Section)
    newSection.sectionTitle = ''
    newSection.sectionBody = ''
    this.subjectsResultInput[subjectIndex].sections?.push(newSection)
  }

  cancelEdit():void {
    this.isEditMode = false
    this.reflectInitialData(this.subjects)
  }
}
