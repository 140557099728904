import { Type } from 'class-transformer'

export class RepairsExpensesGetRequest {
  repairPlanId?: string

  constructor(repairPlanId?: string) {
    this.repairPlanId = repairPlanId
  }
}

export class Amount {
  period!: number
  amount?: number
}

export class RepairPlan {
  @Type(() => Amount) originals:Amount[] = []
  @Type(() => Amount) prospects:Amount[] = []
  @Type(() => Amount) performances:Amount[] = []
}

export class ReserveFund {
  @Type(() => Amount) prospects:Amount[] = []
  @Type(() => Amount) performances:Amount[] = []
}

export class RepairsExpensesGetResponse {
  @Type(() => RepairPlan) repairPlan?:RepairPlan
  @Type(() => ReserveFund) reserveFund?:ReserveFund
  lastUpdatedAt?: string
  processedAt?: string
}
