import type { AreaType, AuditState } from '@/constants/schema-constants'

export class StaffUserId {
  userId: string

  constructor(userId: string) {
    this.userId = userId
  }
}

export class BuildingsPutRequest {
  buildingId: string

  buildingAreaType: AreaType
  condominiumCd: string
  ridgeCd: string
  buildingName: string
  buildingPostalCode: string
  buildingAddress: string
  isFacilityReservationAvailable: boolean
  gmMonth: number
  isTrialTargetBuilding: boolean
  isConsultationUse?: boolean
  accounting: AuditState
  adoptRepairPlanService: boolean
  adoptReservePlanService: boolean
  keyPerson?: string
  concerns?: string
  serviceStaff?: StaffUserId
  frontStaff?: StaffUserId[] = []
  lmStaff?: StaffUserId[] = []

  constructor(buildingId: string, buildingAreaType: AreaType, condominiumCd: string, ridgeCd: string, buildingName: string, buildingPostalCode: string, buildingAddress: string, gmMonth: number, isTrialTargetBuilding: boolean, accounting: AuditState, adoptRepairPlanService: boolean, adoptReservePlanService: boolean, isFacilityReservationAvailable: boolean,
    isConsultationUse: boolean | undefined, keyPerson: string | null, concerns: string | null, serviceStaff?: StaffUserId, frontStaff?: StaffUserId[], lmStaff?: StaffUserId[]) {
    this.buildingId = buildingId
    this.buildingAreaType = buildingAreaType
    this.condominiumCd = condominiumCd
    this.ridgeCd = ridgeCd
    this.buildingName = buildingName
    this.buildingPostalCode = buildingPostalCode
    this.buildingAddress = buildingAddress
    this.gmMonth = gmMonth
    this.isTrialTargetBuilding = isTrialTargetBuilding
    this.accounting = accounting
    this.adoptRepairPlanService = adoptRepairPlanService
    this.adoptReservePlanService = adoptReservePlanService
    this.isFacilityReservationAvailable = isFacilityReservationAvailable
    this.isConsultationUse = isConsultationUse
    this.keyPerson = keyPerson ?? undefined
    this.concerns = concerns ?? undefined
    this.serviceStaff = serviceStaff
    this.frontStaff = frontStaff
    this.lmStaff = lmStaff
  }
}
