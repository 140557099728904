













































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Task } from '@/dtos/tasks/post'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class TaskPostForm extends Vue {
  @Prop({ required: true, default: {} })
  createTask!: Task

  onClickCreateTask(): void {
    this.$emit('create', this.createTask)
  }

  onClickDeleteTask():void {
    this.$emit('delete')
  }
}

