























































































































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Route, NavigationGuardNext } from 'vue-router'
import { staticRoutes } from '@/routes'

import { REPORT_STATE, MATERIAL_TYPES, QUESTIONNAIRE_QUESTION_TYPES, SECTION_TYPES, PostTimingType, POST_TIMING_TYPE } from '@/constants/schema-constants'
import type { ChartType, QuestionnaireQuestionElementType, QuestionnaireQuestionType } from '@/constants/schema-constants'

import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { QuestionnaireReportSection } from '@/components/molecules/card/SmCardQuestionnaireReportSection.vue'
import { Choice, CompareChoice } from '@/components/molecules/card/SmCardQuestionnaireReportResult.vue'

import { BuildingsGetRequest } from '@/dtos/buildings/get'
import { Material, MaterialFormInputDto } from '@/dtos/commons'
import { QuestionnaireDetailGetRequest, QuestionnaireDetailGetResponse, QuestionnaireQuestion, QuestionnaireQuestionElement } from '@/dtos/questionnaires/get'
import { QuestionnaireQuestionsPostRequest } from '@/dtos/questionnaires/questions/post'
import { QuestionnaireOpinionDto, QuestionnaireOpinionsSearchPostRequest } from '@/dtos/questionnaires/reports/opinions/post'
import { ChoiceForPreview, ImplementationResultForPreview, OpinionForPreview, QuestionForPreview, ResultForPreview, VoteResultForPreview } from '@/dtos/questionnaires/reports/commons'
import { QuestionDto } from '@/dtos/questionnaires/reports/get'
import { ImplementationResult, ImplementationResultQuestion, QuestionnaireOpinionAndAnswer, QuestionnaireOpinionAndAnswerSection, QuestionnaireReportsPostRequest } from '@/dtos/questionnaires/reports/post'
import { QuestionnaireAnswerLibraryPostRequest } from '@/dtos/questionnaires/reports/answer-libraries/post'

import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'
import { staticKeyProvider } from '@/libs/static-key-provider'
import { generateUuid } from '@/libs/uuid-generator'
import { windowOpen } from '@/libs/window-open'

import { buildingsModule } from '@/stores/buildings-store'
import { questionnairesModule } from '@/stores/questionnaires-store'
import { questionnaireReportsModule } from '@/stores/questionnaire-reports-store'
import { newTabLocalParamStorageModule, QuestionnaireReportPreviewContent } from '@/stores/new-tab-local-param-storage-store'

const currentDate = () => {
  const date = new Date()
  // ISOString形式の日本時間を取得したいが、
  // toISOString()の結果はUTCのため新規・修正に関わらず9時間進める
  date.setHours(date.getHours() + 9)
  return date.toISOString().substr(0, 10)
}

export class QuestionnaireReportQuestion {
  questionnaireQuestionId!: string
  questionnaireSentence!: string
  templateQuestionId?: string
  questionType!: QuestionnaireQuestionType
  chartType!: ChartType
  compareChoices: CompareChoice[] = []
}

export class ViewImplementationResult {
  title?: string
  explanation?: string
  material?: Material | null = null

  targetQuestionnaireIds?: string[]
  implementationResultQuestions?: ImplementationResultQuestion[]
}

export class QuestionnaireReportFormInput {
  reportTitle = ''
  reportExplanation = ''
  material?: Material | null = null
  resultTitle = ''
  resultExplanation = ''
  resultMaterial?: Material | null = null
  implementationResult = new ViewImplementationResult()
  questionnaireOpinionAndAnswer?: QuestionnaireOpinionAndAnswer
  reportQuestions:QuestionnaireReportQuestion[] = []
  reportSections: QuestionnaireReportSection[] = []
  postTimingType:PostTimingType = POST_TIMING_TYPE.IMMEDIATE
  postedAt!: {
    date: string
    hour: number
    minute: number
  }

  constructor() {
    this.postedAt = {
      date: currentDate(),
      hour: 0,
      minute: 0
    }
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardQuestionnaireReportResult: () => import('@/components/molecules/card/SmCardQuestionnaireReportResult.vue'),
    SmCardQuestionnaireReportSection: () => import('@/components/molecules/card/SmCardQuestionnaireReportSection.vue'),
    SmDateAndTime: () => import('@/components/molecules/SmDateAndTime.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    QuestionnaireComparisonModal: () => import('@/components/organisms/modal/QuestionnaireComparisonModal.vue'),
    QuestionnaireOpinionsAddModal: () => import('@/components/organisms/modal/QuestionnaireOpinionsAddModal.vue'),
    QuestionSelectModal: () => import('@/components/organisms/modal/QuestionSelectModal.vue'),
    SmDraftInterceptor: () => import('@/components/organisms/SmDraftInterceptor.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})

export default class QuestionnaireReportPostPage extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
  POST_TIMING_TYPE = Object.freeze(POST_TIMING_TYPE)

  input = new QuestionnaireReportFormInput()

  isVisibleReportArea = true
  isPostConfirmationDialogVisible = false
  isPutConfirmationDialogVisible = false
  isDeleteDraftDialogVisible = false
  showSelectQuestionnaireDialog = false
  showSelectOpinionsDialog = false
  isQuestionSelectModalVisible = false
  questionSelectModalKey = generateUuid()
  opinionsDialogKey = generateUuid()
  comparisonDialogKey = generateUuid()

  isDraft = false
  isModify = false

  questionnaireChoiceAnswerIds: string[] = []
  selectionQuestionnaireChoiceAnswerIds: string[] = []

  compareQuestionnaireDeadline: string[] = []

  @Prop()
  private readonly questionnaireId!: string

  @Prop()
  private readonly questionnaireReportId?: string

  private get hasOpinion(): boolean {
    if (!this.showOpinionSection) return true
    const reportSectionsChoiceAnswerIds = this.input.reportSections.flatMap(a => { return a.questionnaireChoiceAnswerIds })
    if (reportSectionsChoiceAnswerIds.length) {
      return true
    } else {
      return false
    }
  }

  private get showOpinionSection(): boolean {
    const questionnaire = questionnairesModule.questionnaireDetail(this.questionnaireId)
    const opinion = questionnaire?.questionnaireSections.find(s => s.questionnaireSectionType === SECTION_TYPES.OPINION)
    return !!opinion
  }

  private get showImplementationResultSection(): boolean {
    const questionnaire = questionnairesModule.questionnaireDetail(this.questionnaireId)
    const section = questionnaire?.questionnaireSections.find(s => s.questionnaireSectionType === SECTION_TYPES.QUESTION)
    return !!section
  }

  private get selectQuestionIds(): string[] {
    return this.input.reportQuestions.map(rq => rq.questionnaireQuestionId)
  }

  private isPastPostedAt(date:Date):boolean {
    return date <= new Date()
  }

  private get postedDateAndTime(): string {
    return `${this.input.postedAt.date} ${this.input.postedAt.hour}:${this.input.postedAt.minute}`
  }

  private get getDeadlineLabel(): string {
    return this.compareQuestionnaireDeadline.join(' , ')
  }

  // 日時選択用データ
  private get hours(): { value: number, label: string }[] {
    const hours : { value: number, label: string }[] = []
    for (let i = 0; i <= 23; i++) {
      hours.push({ value: i, label: ('0' + i).slice(-2) }) // 数字を2桁で表示
    }
    return hours
  }

  private get isOpen():boolean {
    const report = questionnaireReportsModule.questionnaireReportsResponse
    return report.reportState === REPORT_STATE.OPEN
  }

  private get postTimingLabel():string {
    return questionnaireReportsModule.questionnaireReportsResponse.postTimingType === POST_TIMING_TYPE.SCHEDULED ? `投稿日時指定：${questionnaireReportsModule.questionnaireReportsResponse.postedAt}` : '即時'
  }

  // 投稿タイミング設定用データ／メソッド
  postTimingOptions = [new RadioOption('即時', POST_TIMING_TYPE.IMMEDIATE), new RadioOption('投稿日時指定', POST_TIMING_TYPE.SCHEDULED)]

  async created(): Promise<void> {
    questionnaireReportsModule.clearFetchedReport()

    if (this.questionnaireReportId) {
      // 編集
      const questionnaireId = this.questionnaireId
      const questionnaireReportId = this.questionnaireReportId

      await questionnaireReportsModule.fetchReport({ questionnaireId, questionnaireReportId })
      if (questionnaireReportsModule.questionnaireReportsResponse.reportState === REPORT_STATE.DRAFT) { this.isDraft = true } else { this.isModify = true }

      await this.convertReportDetailData()
    } else {
      // 新規作成
      await this.convertData()
      this.addReportSection()
    }

    // 模範回答ダイアログ用取得
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999))
    await questionnaireReportsModule.fetchQuestionnaireAnswerLibraries(new QuestionnaireAnswerLibraryPostRequest([]))
  }

  // 比較アンケート選択時に呼ぶ関数
  async setCompareQuestionnaire(questionnaireIds: string[]): Promise<void> {
    this.compareQuestionnaireDeadline = []
    this.input.implementationResult.targetQuestionnaireIds = questionnaireIds
    const targetQuestionnaires: (QuestionnaireDetailGetResponse | undefined)[] = []
    for (const id of questionnaireIds) {
      await questionnairesModule.fetchQuestionnaireDetail(new QuestionnaireDetailGetRequest(id))
      targetQuestionnaires.push(questionnairesModule.questionnaireDetail(id))
      this.compareQuestionnaireDeadline.push(this.convertDateReportFormat(questionnairesModule.questionnaireDetail(id)?.deadline ?? ''))
    }
    targetQuestionnaires.forEach((targetQuestionnaire, idx) => {
      this.input.reportQuestions.forEach(rq => {
        if (!rq.templateQuestionId) return
        if (targetQuestionnaire) {
          targetQuestionnaire.questionnaireSections.forEach(qs => {
            qs.questionnaireQuestions.forEach(qq => {
              const compareChoices: CompareChoice[] = []
              if (qq.templateQuestionId !== rq.templateQuestionId || !rq.compareChoices.length) return
              if (idx === 0) {
                compareChoices.push(rq.compareChoices[0])
                compareChoices.push(this.createCompareChoice(qq.questionnaireQuestionElements, targetQuestionnaire.deadline))
                rq.compareChoices = compareChoices
              } else {
                compareChoices.push(this.createCompareChoice(qq.questionnaireQuestionElements, targetQuestionnaire.deadline))
                rq.compareChoices = rq.compareChoices.concat(compareChoices)
              }
            })
          })
        }
      })
    })
  }

  createCompareChoice(questionElements: QuestionnaireQuestionElement[], deadline: string): CompareChoice {
    const compareChoice = new CompareChoice()
    compareChoice.deadline = this.convertDateReportFormat(deadline)
    questionElements.forEach(qe => {
      const choice = new Choice()
      choice.questionnaireQuestionElementId = qe.questionnaireQuestionElementId
      choice.choice = qe.choice ?? ''
      choice.templateQuestionElementId = qe.templateQuestionElementId
      choice.questionnaireQuestionElementType = qe.questionnaireQuestionElementType
      choice.sortOrderNum = qe.sortOrderNum
      choice.answerCount = qe.answerCount ?? 0
      compareChoice.choices.push(choice)
    })
    return compareChoice
  }

  async convertReportDetailData(): Promise<void> {
    const questionnaireReports = questionnaireReportsModule.questionnaireReportsResponse

    this.input.reportTitle = questionnaireReports.questionnaireReportTitle
    this.input.reportExplanation = questionnaireReports.questionnaireReportExplanation
    this.input.material = questionnaireReports.questionnaireReportMaterial
    this.input.postTimingType = this.isPastPostedAt(new Date(questionnaireReports.postedAtDateTime)) && questionnaireReportsModule.questionnaireReportsResponse.reportState === REPORT_STATE.DRAFT ? POST_TIMING_TYPE.IMMEDIATE : questionnaireReports.postTimingType
    this.input.postedAt = this.input.postTimingType === POST_TIMING_TYPE.IMMEDIATE && questionnaireReportsModule.questionnaireReportsResponse.reportState === REPORT_STATE.DRAFT
      ? {
          date: currentDate(),
          hour: 0,
          minute: 0
        }
      : {
          date: questionnaireReports.postedAtDateTime.substring(0, 10),
          hour: Number(questionnaireReports.postedAtDateTime.substring(11, 13)),
          minute: Number(questionnaireReports.postedAtDateTime.substring(14, 16)),
        }
    this.input.implementationResult.title = questionnaireReports.implementationResultTitle
    this.input.implementationResult.explanation = questionnaireReports.implementationResultExplanation
    this.input.implementationResult.material = questionnaireReports.implementationResultMaterial

    this.input.resultTitle = questionnaireReports.resultTitle ? questionnaireReports.resultTitle : ''
    this.input.resultExplanation = questionnaireReports.resultExplanation ? questionnaireReports.resultExplanation : ''
    this.input.resultMaterial = questionnaireReports.resultMaterial

    // 報告書詳細APIから画面の型への変換
    if (questionnaireReports.questions) await this.convertData(questionnaireReports.questions.map(q => q.questionnaireQuestionId))
    // 比較対象があれば比較する
    if (questionnaireReports.compareTargets) {
      await this.setCompareQuestionnaire(questionnaireReports.compareTargets.map(ct => ct.questionnaireId))
    }
    if (!questionnaireReports.questionnaireReportResultSection?.length) {
      this.isVisibleReportArea = false
      return
    }
    // ご意見とご回答の変換
    questionnaireReports.questionnaireReportResultSection.forEach(questionnaireReportResult => {
      const reportSection = staticKeyProvider.create(QuestionnaireReportSection)
      this.reportSectionIndex++
      reportSection.questionnaireChoiceAnswerIds = questionnaireReportResult
        .questionnaireReportResultSectionImplementationResultQuestion.map(resultQuestion => resultQuestion.questionnaireChoiceAnswerId)
      reportSection.opinionRequestText = questionnaireReportResult
        .questionnaireReportResultSectionImplementationResultQuestion.map(resultQuestion => resultQuestion.freeAnswer)
      reportSection.answerText = questionnaireReportResult.answerBody
      reportSection.isModelAnswer = questionnaireReportResult.isModelAnswer
      this.input.reportSections.push(reportSection)
      questionnaireReportResult.questionnaireReportResultSectionImplementationResultQuestion.forEach(ImplementationResultQuestion => {
        this.selectionQuestionnaireChoiceAnswerIds.push(ImplementationResultQuestion.questionnaireChoiceAnswerId)
      })
    })
  }

  // APIから画面のデータへ変換する（新規作成時→全ての設問を表示、編集・下書きから作成→保存した状態での設問のみ表示）
  async convertData(questionIds?:string[]): Promise<void> {
    const questionnaireRequest = new QuestionnaireDetailGetRequest(this.questionnaireId)
    await questionnairesModule.fetchQuestionnaireDetail(questionnaireRequest)
    const questionnaire = deepCopy(
      questionnairesModule.questionnaireDetail(this.questionnaireId),
      {
        questionnaireDetailGetResponse: new ColumnToType(QuestionnaireDetailGetResponse)
      }, 'questionnaireDetailGetResponse'
    )
    if (!questionnaire) return

    const questionnaireQuestions = !questionIds?.length ? questionnaire.questionnaireSections.flatMap(qs => qs.questionnaireQuestions) : questionnaire.questionnaireSections.flatMap(qs => qs.questionnaireQuestions.filter(qq => questionIds.includes(qq.questionnaireQuestionId)))
    let reportQuestions: QuestionDto[] = []
    if (questionnaireReportsModule.questionnaireReportsResponse && questionnaireReportsModule.questionnaireReportsResponse.questions?.length) reportQuestions = questionnaireReportsModule.questionnaireReportsResponse.questions
    const sortedQuestionnaireQuestions:QuestionnaireQuestion[] = []
    // 報告書実施結果設問の順番に並び替える
    if (reportQuestions.length) {
      reportQuestions.forEach(q => {
        const question = questionnaireQuestions.find(question => {
          return question.questionnaireQuestionId === q.questionnaireQuestionId
        })
        if (question) sortedQuestionnaireQuestions.push(question)
      })
    }
    // 加えて、設問を追加するボタン押下時に追加出来るように表示されていない情報を最後に来るように並び替える
    questionnaireQuestions.forEach(q => {
      if (!sortedQuestionnaireQuestions.includes(q)) sortedQuestionnaireQuestions.push(q)
    })

    sortedQuestionnaireQuestions.forEach(questionnaireQuestion => {
      if (questionnaireQuestion.questionnaireQuestionType !== QUESTIONNAIRE_QUESTION_TYPES.FREE) {
        const convertedQuestion = staticKeyProvider.create(QuestionnaireReportQuestion)
        convertedQuestion.questionnaireQuestionId = questionnaireQuestion.questionnaireQuestionId
        convertedQuestion.questionType = questionnaireQuestion.questionnaireQuestionType as QuestionnaireQuestionType
        convertedQuestion.questionnaireSentence = questionnaireQuestion.sentence ? questionnaireQuestion.sentence : ''
        convertedQuestion.templateQuestionId = questionnaireQuestion.templateQuestionId
        if (reportQuestions.filter(q => q.questionnaireQuestionId === questionnaireQuestion.questionnaireQuestionId)[0])convertedQuestion.chartType = reportQuestions.filter(q => q.questionnaireQuestionId === questionnaireQuestion.questionnaireQuestionId)[0].chartType

        const choices = questionnaireQuestion.questionnaireQuestionElements.map(questionnaireQuestionElement => {
          const option = new Choice()
          option.questionnaireQuestionElementId = questionnaireQuestionElement.questionnaireQuestionElementId
          option.templateQuestionElementId = questionnaireQuestionElement.templateQuestionElementId

          // 取得したquestionnaireは選択肢の文言がundefinedであることがあり得るが、報告書作成時にはquestionnaireQuestionElement.choiceがundefinedになることはない
          option.choice = questionnaireQuestionElement.choice ?? ''
          option.questionnaireQuestionElementType = questionnaireQuestionElement.questionnaireQuestionElementType as QuestionnaireQuestionElementType

          // 取得したquestionnaireは報告書作成以外のステータスであることがあり得るが、報告書作成時にquestionnaireQuestionElement.answerCountがundefinedになっていることはない
          option.answerCount = questionnaireQuestionElement.answerCount ?? 0
          return option
        })
        const compareChoice = new CompareChoice()
        compareChoice.deadline = this.convertDateReportFormat(questionnaire.deadline)
        compareChoice.choices = choices
        convertedQuestion.compareChoices.push(compareChoice)
        this.input.reportQuestions.push(convertedQuestion)
      }
    })
  }

  convertDateReportFormat(date: string): string {
    return `${date.slice(0, 4)}/${date.slice(5, 7)}/${date.slice(8, 10)}`
  }

  convertWebToAPIData(): QuestionnaireReportsPostRequest {
    let implementationResult: ImplementationResult | null = null
    if (this.showImplementationResultSection) {
      implementationResult = new ImplementationResult()
      const implementationResultQuestions: ImplementationResultQuestion[] = []

      this.input.reportQuestions.forEach((reportQuestion, index) => {
        const implementationResultQuestion = new ImplementationResultQuestion()
        implementationResultQuestion.questionnaireQuestionId = reportQuestion.questionnaireQuestionId
        implementationResultQuestion.questionType = reportQuestion.questionType
        implementationResultQuestion.chartType = reportQuestion.chartType
        implementationResultQuestion.sortOrderNum = index + 1
        implementationResultQuestions.push(implementationResultQuestion)
      })

      implementationResult.title = this.input.implementationResult.title
      implementationResult.explanation = this.input.implementationResult.explanation
      implementationResult.material = this.input.implementationResult.material ? this.input.implementationResult.material : undefined
      implementationResult.targetQuestionnaireIds = this.input.implementationResult.targetQuestionnaireIds
      implementationResult.implementationResultQuestions = implementationResultQuestions
    }

    let questionnaireOpinionAndAnswerSection: QuestionnaireOpinionAndAnswer | null = null
    if (this.showOpinionSection) {
      questionnaireOpinionAndAnswerSection = new QuestionnaireOpinionAndAnswer()
      const questionnaireOpinionAndAnswerSections: QuestionnaireOpinionAndAnswerSection[] = []
      this.input.reportSections.forEach((reportSection, index) => {
        const questionnaireOpinionAndAnswerSection = new QuestionnaireOpinionAndAnswerSection()
        questionnaireOpinionAndAnswerSection.questionnaireChoiceAnswerIds = reportSection.questionnaireChoiceAnswerIds
        questionnaireOpinionAndAnswerSection.answerBody = reportSection.answerText
        questionnaireOpinionAndAnswerSection.isModelAnswer = reportSection.isModelAnswer
        questionnaireOpinionAndAnswerSection.sortOrderNum = index + 1
        questionnaireOpinionAndAnswerSections.push(questionnaireOpinionAndAnswerSection)
      })

      questionnaireOpinionAndAnswerSection.questionnaireOpinionAndAnswerSections = questionnaireOpinionAndAnswerSections
    }

    const req = new QuestionnaireReportsPostRequest()
    req.reportTitle = this.input.reportTitle
    req.reportExplanation = this.input.reportExplanation
    req.reportMaterial = this.input.material ? this.input.material : undefined
    req.postedAt = this.input.postTimingType === POST_TIMING_TYPE.SCHEDULED ? this.postedDateAndTime : undefined
    req.postTimingType = this.input.postTimingType
    req.implementationResult = implementationResult ?? undefined
    req.resultTitle = this.input.resultTitle
    req.resultExplanation = this.input.resultExplanation
    req.resultMaterial = this.input.resultMaterial ? this.input.resultMaterial : undefined
    req.questionnaireOpinionAndAnswer = questionnaireOpinionAndAnswerSection ?? undefined

    return req
  }

  // 報告書を登録する
  async executePostQuestionnaireReport(): Promise<void> {
    this.processCompleted = true
    this.isPostConfirmationDialogVisible = false
    const questionnaireId = this.questionnaireId
    const rawReq = this.convertWebToAPIData()
    const fileUploader = new FileUploader()
    const req = await fileUploader.prepare(rawReq)
    await questionnaireReportsModule.postReport({ questionnaireId, req })
    this.$router.go(-1)
  }

  // 報告書を更新する
  async executePutQuestionnaireReport(): Promise<void> {
    this.processCompleted = true
    this.isPutConfirmationDialogVisible = false

    const rawReq = this.convertWebToAPIData()
    rawReq.version = questionnaireReportsModule.questionnaireReportsResponse.version
    rawReq.questionnaireReportId = this.questionnaireReportId
    const questionnaireId = this.questionnaireId
    const fileUploader = new FileUploader()
    const req = await fileUploader.prepare(rawReq)
    await questionnaireReportsModule.putReport({ questionnaireId, req })
    this.$router.go(-1)
  }

  async executePostDraft(): Promise<void> {
    this.processCompleted = true

    const rawReq = this.convertWebToAPIData()
    const fileUploader = new FileUploader()
    const req = await fileUploader.prepare(rawReq)
    const questionnaireId = this.questionnaireId

    await questionnaireReportsModule.postReportDraft({ questionnaireId, req })
    this.$router.go(-1)
  }

  async executeDeleteDraft(): Promise<void> {
    this.isDeleteDraftDialogVisible = false
    this.processCompleted = true

    const questionnaireId = this.questionnaireId
    const questionnaireReportId = this.questionnaireReportId

    if (!questionnaireReportId) return
    await questionnaireReportsModule.deleteReportDrafts({ questionnaireId, questionnaireReportId })
    this.$router.go(-1)
  }

  // 素材IDと取得済み参照用URLを含む素材情報の組み合わせを保持
  materialReferenceURLMap: Map<string, MaterialFormInputDto> = new Map()

  async goToQuestionnaireReportPreviewPage(): Promise<void> {
    const previewContent: QuestionnaireReportPreviewContent = new QuestionnaireReportPreviewContent()
    previewContent.questionnaireReportTitle = this.input.reportTitle
    previewContent.explanation = this.input.reportExplanation
    previewContent.implementationResult = this.showImplementationResultSection ? await this._toImplementationResultForPreview(this.input.implementationResult, this.input.reportQuestions) : undefined
    previewContent.result = this._toResultForPreview(this.input.resultTitle, this.input.resultExplanation)
    previewContent.opinions = this.showOpinionSection ? this.input.reportSections.map(s => this._toOpinionForPreview(s)) : []

    if (this.input.material) {
      previewContent.material = await this._uploadMaterial(Object.assign(new MaterialFormInputDto(), this.input.material))
      // 画面に表示中の素材データにもIDを格納することで投稿／編集時に素材を再登録しなくて済むようにする
      this.input.material = previewContent.material
    }

    if (this.input.resultMaterial) {
      previewContent.result.material = await this._uploadMaterial(Object.assign(new MaterialFormInputDto(), this.input.resultMaterial))
      // 画面に表示中の素材データにもIDを格納することで投稿／編集時に素材を再登録しなくて済むようにする
      this.input.resultMaterial = previewContent.result.material
    }

    const previewContentsId = generateUuid()
    newTabLocalParamStorageModule.setQuestionnaireReportPreviewContent({ key: previewContentsId, questionnaireReportPreviewContent: previewContent })

    windowOpen(staticRoutes.questionnaireReportPreviewPage.path.replace(':questionnaireId', this.questionnaireId).replace(':questionnaireReportId', previewContentsId))
  }

  private async _uploadMaterial(material: MaterialFormInputDto): Promise<MaterialFormInputDto | undefined> {
    if (!material.materialId) { // 新たに添付した素材の場合
      // ローカルストレージのサイズの制約上、プレビュー時に素材をそのまま別タブに渡すのが難しいため、ここでアップロードする
      const uploadedMaterial = await uploadMaterial(material)
      if (uploadedMaterial) {
        this.materialReferenceURLMap.set(uploadedMaterial.materialId, uploadedMaterial)
        return uploadedMaterial
      }
    } else if (this.materialReferenceURLMap.get(material.materialId)) { // 一度プレビューした素材を付け替えずに使用する場合
      return this.materialReferenceURLMap.get(material.materialId)
    } else { // すでにDBに登録済みの素材を画面初期表示から一度も付け替えずに使用する場合
      return material
    }
  }

  private async _toImplementationResultForPreview(implementationResult: ImplementationResult, reportQuestions: QuestionnaireReportQuestion[]): Promise<ImplementationResultForPreview> {
    const dto = new ImplementationResultForPreview()
    dto.title = implementationResult.title
    dto.explanation = implementationResult.explanation
    dto.questions = reportQuestions.map((q, index) => this._toQuestionForPreview(q, index))
    dto.material = implementationResult.material
    if (implementationResult.material) {
      dto.material = await this._uploadMaterial(Object.assign(new MaterialFormInputDto(), implementationResult.material))
      // 画面に表示中の素材データにもIDを格納することで投稿／編集時に素材を再登録しなくて済むようにする
      implementationResult.material = dto.material
    }
    return dto
  }

  private _toQuestionForPreview(questionnaireReportQuestion: QuestionnaireReportQuestion, index: number): QuestionForPreview {
    const dto = new QuestionForPreview()
    dto.sortOrderNum = index + 1
    dto.questionSentence = questionnaireReportQuestion.questionnaireSentence
    dto.chartType = questionnaireReportQuestion.chartType
    dto.currentVoteResult = this._toVoteResultForPreview(questionnaireReportQuestion.compareChoices[0])
    if (questionnaireReportQuestion.compareChoices.length >= 2) {
      const choices :VoteResultForPreview[] = []
      questionnaireReportQuestion.compareChoices.forEach((choice, idx) => {
        if (idx === 0) return
        choices.push(this._toVoteResultForPreview(choice))
      })
      dto.comparisonVoteResult = choices
    }
    return dto
  }

  private _toVoteResultForPreview(choice: CompareChoice): VoteResultForPreview {
    const dto = new VoteResultForPreview()
    dto.label = `${this.convertDateReportFormat(choice.deadline)}：${choice.choices.reduce((pre, cur) => pre + cur.answerCount, 0)}件`
    dto.choices = choice.choices.map(c => this._getChoiceForPreview(c))
    return dto
  }

  private _getChoiceForPreview(choice: Choice): ChoiceForPreview {
    const dto = new ChoiceForPreview()
    dto.questionnaireQuestionElementId = choice.questionnaireQuestionElementId
    dto.name = choice.choice
    dto.count = choice.answerCount
    return dto
  }

  private _toResultForPreview(title: string, explanation: string): ResultForPreview {
    const dto = new ResultForPreview()
    dto.title = title
    dto.explanation = explanation
    return dto
  }

  private _toOpinionForPreview(reportSection: QuestionnaireReportSection): OpinionForPreview {
    const dto = new OpinionForPreview()
    dto.ownerFreeAnswers = reportSection.opinionRequestText
    dto.adminAnswer = reportSection.answerText
    return dto
  }

  // ----------------チャートを入れかえる関数---------------------------
  updateChartType(index: number, updateChartType: ChartType): void {
    const targetSections = this.input.reportQuestions
    targetSections[index].chartType = updateChartType
  }

  // ----------------上下のカードを入れ替えるメソッド郡---------------------------
  goUpQuestionInSection(questionIndex: number): void {
    const targetSections = this.input.reportQuestions
    targetSections.splice(questionIndex - 1, 2, targetSections[questionIndex], targetSections[questionIndex - 1])
  }

  goDownQuestionInSection(questionIndex: number): void {
    const targetSections = this.input.reportQuestions
    targetSections.splice(questionIndex, 2, targetSections[questionIndex + 1], targetSections[questionIndex])
  }

  deleteQuestionFromSection(questionIndex: number): void {
    this.input.reportQuestions.splice(questionIndex, 1)
    const compareQuestions = this.input.reportQuestions.find(reportQuestion => reportQuestion.compareChoices)
    if (!compareQuestions || compareQuestions.compareChoices.length < 2) {
      this.input.implementationResult.targetQuestionnaireIds = undefined
      this.compareQuestionnaireDeadline = []
    }
  }

  // ----------------ご意見と回答のカードのメソッド郡---------------------------
  reportSectionIndex = 0
  viewReportArea(): void {
    this.input.reportSections = []
    this.isVisibleReportArea = true
    this.addReportSection()
  }

  addReportSection(): void {
    const reportSection = staticKeyProvider.create(QuestionnaireReportSection)
    this.reportSectionIndex++
    reportSection.opinionRequestText = []
    reportSection.answerText = ''
    this.input.reportSections.push(reportSection)
  }

  goUpReportSection(questionIndex: number): void {
    const targetSections = this.input.reportSections
    targetSections.splice(questionIndex - 1, 2, targetSections[questionIndex], targetSections[questionIndex - 1])
  }

  goDownReportSection(questionIndex: number): void {
    const targetSections = this.input.reportSections
    targetSections.splice(questionIndex, 2, targetSections[questionIndex + 1], targetSections[questionIndex])
  }

  deleteReportSection(questionIndex: number): void {
    const reportSection = this.input.reportSections[questionIndex]
    reportSection.questionnaireChoiceAnswerIds.forEach(answerId => {
      this.selectionQuestionnaireChoiceAnswerIds.splice(this.selectionQuestionnaireChoiceAnswerIds.indexOf(answerId), 1)
    })
    this.input.reportSections.splice(questionIndex, 1)
    if (this.input.reportSections.length === 0) this.isVisibleReportArea = false
  }

  deleteVisibleReportArea(): void {
    this.selectionQuestionnaireChoiceAnswerIds = []
    this.input.reportSections = []
    this.isVisibleReportArea = false
  }

  activeOpinionSectionIndex! :number
  async addOpinionRequest(questionnaireReportSectionIndex: number): Promise<void> {
    const req = new QuestionnaireOpinionsSearchPostRequest(this.questionnaireId, this.selectionQuestionnaireChoiceAnswerIds)
    await questionnaireReportsModule.fetchQuestionnaireOpinions(req)
    this.showSelectOpinionsDialog = true
    this.opinionsDialogKey = generateUuid()
    this.activeOpinionSectionIndex = questionnaireReportSectionIndex
  }

  addOpinion(selectedOpinion: QuestionnaireOpinionDto[]):void {
    this.selectionQuestionnaireChoiceAnswerIds.push(...selectedOpinion.map(so => so.questionnaireChoiceAnswerId))
    this.input.reportSections[this.activeOpinionSectionIndex].opinionRequestText.push(...selectedOpinion.map(so => so.opinionBody))
    this.input.reportSections[this.activeOpinionSectionIndex].questionnaireChoiceAnswerIds.push(...selectedOpinion.map(so => so.questionnaireChoiceAnswerId))
  }

  deleteOpinionRequest(questionnaireReportSectionIndex: number, opinionRequestTextIndex: number): void {
    this.input.reportSections[questionnaireReportSectionIndex].opinionRequestText.splice(opinionRequestTextIndex, 1)
    this.selectionQuestionnaireChoiceAnswerIds.splice(this.selectionQuestionnaireChoiceAnswerIds.indexOf(this.input.reportSections[questionnaireReportSectionIndex].questionnaireChoiceAnswerIds[opinionRequestTextIndex]), 1)
    this.input.reportSections[questionnaireReportSectionIndex].questionnaireChoiceAnswerIds.splice(opinionRequestTextIndex, 1)
  }

  addSelectedFromPastAnswer(questionnaireReportSectionIndex: number): void {
    this.input.reportSections[questionnaireReportSectionIndex].answerText = '過去の回答情報'
  }

  openComparisonQuestionnaireSelectModal():void {
    this.comparisonDialogKey = generateUuid()
    this.showSelectQuestionnaireDialog = true
  }

  async setQuestions(reqQuestionIds:string[]): Promise<void> {
    this.convertData(reqQuestionIds)
    if (this.input.implementationResult.targetQuestionnaireIds?.length) {
      await this.setCompareQuestionnaire(this.input.implementationResult.targetQuestionnaireIds)
    }
  }

  async openQuestionSelectModal(): Promise<void> {
    this.questionSelectModalKey = generateUuid()
    const req = new QuestionnaireQuestionsPostRequest(this.selectQuestionIds)
    const questionnaireId = this.questionnaireId
    await questionnaireReportsModule.fetchQuestionnaireQuestions({ questionnaireId, req })
    this.isQuestionSelectModalVisible = true
  }

  // 作成内容破棄確認ダイアログ
  nextRoute: Route | null = null

  processCompleted = false
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (this.processCompleted || this.nextRoute) {
      next()
    } else {
      this.nextRoute = to
      next(false)
    }
  }

  leaveHere(): void {
    const routeName = this.nextRoute?.name
    const routeParams = this.nextRoute?.params
    // 画面遷移元が以下のリスト内だった場合、ヒストリーを戻す
    const goBackRouteNames = [
      staticRoutes.questionnaireDetail.getChild('report').name
    ]
    if (routeName) {
      if (goBackRouteNames.includes(routeName)) this.$router.go(-1)
      else this.$router.push({ name: routeName, params: routeParams })
    }
  }
}

