import { User } from '@/dtos/commons'
import { AdminIdeaPreparingStatus } from '../get-detail'

export class AdminIdeaPreparingStatusPostRequest {
  ideaId!: string
  preparingStatusBody!: string

  constructor(
    ideaId: string,
    preparingStatusBody: string
  ) {
    this.ideaId = ideaId
    this.preparingStatusBody = preparingStatusBody
  }
}

export class AdminIdeaPreparingStatusPostResponse {
  version!: string
  postedAt!: string
}

export class AdminIdeaPreparingStatusUpdateDto extends AdminIdeaPreparingStatus {
  ideaId!: string

  constructor(req:AdminIdeaPreparingStatusPostRequest, res:AdminIdeaPreparingStatusPostResponse, admin:User) {
    super()
    this.admin = admin
    this.ideaId = req.ideaId
    this.preparingStatusBody = req.preparingStatusBody
    this.postedAt = res.postedAt
    this.version = res.version
  }
}
