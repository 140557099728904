import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { repairsClient } from '@/clients/repairs-client'
import { RepairsExpensesGetRequest, RepairsExpensesGetResponse } from '@/dtos/repairs/expenses/get'
import { RepairsItemsGetRequest, RepairsItemsGetResponse } from '@/dtos/repairs/items/get'
import { RepairsUpdatesGetRequest, RepairsUpdatesGetResponse } from '@/dtos/repairs/updates/get-detail'
import { RepairsUpdatesCommentsGetRequest, RepairsUpdatesCommentsGetResponse, Comment } from '@/dtos/repairs/updates/comments/get'
import { RepairsUpdatesCommentDetailGetRequest, RepairsUpdatesCommentDetailGetResponse } from '@/dtos/repairs/updates/comments/get-detail'
import { RepairsUpdatesCommentsPostRequest } from '@/dtos/repairs/updates/comments/post'
import { RepairsUpdatesCommentsPutRequest } from '@/dtos/repairs/updates/comments/put'
import { RepairsUpdatesCommentsDeleteRequest } from '@/dtos/repairs/updates/comments/delete'
import { RepairsUpdatesLogsGetRequest, RepairsUpdatesLogsGetResponse } from '@/dtos/repairs/updates/logs/get'
import { RepairsUpdatesLogDetailGetRequest, RepairsUpdatesLogDetailGetResponse } from '@/dtos/repairs/updates/logs/get-detail'
import { RepairsUpdatesLogsDeleteRequest } from '@/dtos/repairs/updates/logs/delete'
import { RepairPlanGetResponse } from '@/dtos/repairs/plans/get'
import { RepairPlanPostRequest } from '@/dtos/repairs/plans/post'
import { RepairPlanPutRequest } from '@/dtos/repairs/plans/put'

@Module({ store, dynamic: true, namespaced: true, name: 'repairsStore' })
class RepairsStore extends VuexModule {
  // ------------------------------------------------------------------------------------
  // 最新の○○ ----------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------

  // 長計（収支計画）
  private _repairsExpensesGet: RepairsExpensesGetResponse = new RepairsExpensesGetResponse()
  get repairsExpensesGet(): RepairsExpensesGetResponse { return this._repairsExpensesGet }

  @Mutation
  private SET_REPAIRS_EXPENSES_GET(res:RepairsExpensesGetResponse): void {
    this._repairsExpensesGet = res
  }

  @Action
  async fetchRepairsExpenses(req: RepairsExpensesGetRequest): Promise<void> {
    const res = await repairsClient.getRepairsExpenses(req)
    this.SET_REPAIRS_EXPENSES_GET(res)
  }

  // 長計（工事計画）
  private _repairsItemsGet: RepairsItemsGetResponse = new RepairsItemsGetResponse()
  get repairsItemsGet(): RepairsItemsGetResponse { return this._repairsItemsGet }

  @Mutation
  private SET_REPAIRS_ITEMS_GET(res:RepairsItemsGetResponse): void {
    this._repairsItemsGet = res
  }

  @Action
  async fetchRepairsItems(req: RepairsItemsGetRequest): Promise<void> {
    const res = await repairsClient.getRepairsItems(req)
    this.SET_REPAIRS_ITEMS_GET(res)
  }

  // 変更コメント
  private _repairsUpdatesGet: RepairsUpdatesGetResponse = new RepairsUpdatesGetResponse()
  get repairsUpdatesGet(): RepairsUpdatesGetResponse { return this._repairsUpdatesGet }

  @Mutation
  private SET_REPAIRS_UPDATES_GET(res: RepairsUpdatesGetResponse): void {
    this._repairsUpdatesGet = res
  }

  @Action
  async fetchRepairsUpdates(req: RepairsUpdatesGetRequest): Promise<void> {
    const res = await repairsClient.getRepairsUpdates(req)
    this.SET_REPAIRS_UPDATES_GET(res)
  }

  // ------------------------------------------------------------------------------------
  // 長計（単独） ------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------
  private LATEST_REPAIR_PLAN = 'LATEST'
  private _repairPlanGetMap: Record<string, RepairPlanGetResponse> = {}
  get repairPlanGetResponse(): (repairPlanId?: string) => RepairPlanGetResponse | undefined {
    return (id?: string) => this._repairPlanGetMap[id ?? this.LATEST_REPAIR_PLAN]
  }

  @Mutation
  private SET_REPAIR_PLAN_GET({ repairPlanId, res }: { repairPlanId?: string, res: RepairPlanGetResponse }) {
    Vue.set(this._repairPlanGetMap, repairPlanId ?? this.LATEST_REPAIR_PLAN, res)
  }

  @Action
  async fetchRepairPlan(repairPlanId?: string): Promise<void> {
    const res = await repairsClient.getRepairPlan(repairPlanId)
    this.SET_REPAIR_PLAN_GET({ repairPlanId, res })
  }

  @Action async postRepairPlan(req: RepairPlanPostRequest): Promise<void> { await repairsClient.postRepairPlan(req) }
  @Action async putRepairPlan(req: RepairPlanPutRequest): Promise<void> { await repairsClient.putRepairPlan(req) }

  // ------------------------------------------------------------------------------------
  // 変更コメント ------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------

  // 一覧
  private _repairsUpdatesCommentsGet: Comment[] = []
  get repairsUpdatesCommentsGet(): Comment[] { return this._repairsUpdatesCommentsGet }

  @Mutation
  private PUSH_REPAIRS_UPDATES_COMMENTS_GET(res: RepairsUpdatesCommentsGetResponse): void {
    this._repairsUpdatesCommentsGet.push(...res.comments)
  }

  @Mutation
  private CLEAR_REPAIRS_UPDATES_COMMENTS_GET(): void {
    this._repairsUpdatesCommentsGet = []
  }

  @Action
  async fetchRepairsUpdatesComments(req: RepairsUpdatesCommentsGetRequest): Promise<void> {
    const res = await repairsClient.getRepairsUpdatesComments(req)
    this.PUSH_REPAIRS_UPDATES_COMMENTS_GET(res)
  }

  @Action
  clearRepairsUpdatesComments(): void {
    this.CLEAR_REPAIRS_UPDATES_COMMENTS_GET()
  }

  @Action
  async postRepairsUpdatesComments(req: RepairsUpdatesCommentsPostRequest): Promise<void> {
    await repairsClient.postRepairsUpdatesComments(req)
  }

  @Action
  async putRepairsUpdatesComments(req: RepairsUpdatesCommentsPutRequest): Promise<void> {
    await repairsClient.putRepairsUpdatesComments(req)
  }

  @Action
  async deleteRepairsUpdatesComments(req: RepairsUpdatesCommentsDeleteRequest): Promise<void> {
    await repairsClient.deleteRepairsUpdatesComments(req)
  }

  // 詳細
  private _repairsUpdatesCommentDetails: Record<string, RepairsUpdatesCommentDetailGetResponse> = {}
  get repairsUpdatesCommentDetailGet(): (id: string) => RepairsUpdatesCommentDetailGetResponse | undefined { return (id: string) => this._repairsUpdatesCommentDetails[id] }

  @Mutation
  private SET_REPAIRS_UPDATES_COMMENT_DETAIL_GET(res: RepairsUpdatesCommentDetailGetResponse): void {
    Vue.set(this._repairsUpdatesCommentDetails, res.commentId, res)
  }

  @Action
  async fetchRepairsUpdatesCommentDetail(req: RepairsUpdatesCommentDetailGetRequest): Promise<void> {
    const res = await repairsClient.getRepairsUpdatesCommentDetail(req)
    this.SET_REPAIRS_UPDATES_COMMENT_DETAIL_GET(res)
  }

  // ------------------------------------------------------------------------------------
  // 更新履歴 ----------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------

  // 一覧
  private _repairsUpdatesLogsGet: RepairsUpdatesLogsGetResponse = new RepairsUpdatesLogsGetResponse()
  get repairsUpdatesLogsGet(): RepairsUpdatesLogsGetResponse { return this._repairsUpdatesLogsGet }

  @Mutation
  private SET_REPAIRS_UPDATES_LOGS_GET(res: RepairsUpdatesLogsGetResponse): void {
    this._repairsUpdatesLogsGet = res
  }

  @Action
  async fetchRepairsUpdatesLogs(req: RepairsUpdatesLogsGetRequest): Promise<void> {
    const res = await repairsClient.getRepairsUpdatesLogs(req)
    this.SET_REPAIRS_UPDATES_LOGS_GET(res)
  }

  @Action
  async deleteRepairsUpdatesLogs(req: RepairsUpdatesLogsDeleteRequest): Promise<void> {
    await repairsClient.deleteRepairsUpdatesLogs(req)
  }

  // 詳細
  private _repairsUpdatesLogDetailsGet: Record<string, RepairsUpdatesLogDetailGetResponse> = {}
  get repairsUpdatesLogDetailGet(): (id: string) => RepairsUpdatesLogDetailGetResponse | undefined { return (id: string) => this._repairsUpdatesLogDetailsGet[id] }

  @Mutation
  private SET_REPAIRS_UPDATES_LOG_DETAIL_GET(res: RepairsUpdatesLogDetailGetResponse): void {
    Vue.set(this._repairsUpdatesLogDetailsGet, res.logId, res)
  }

  @Action
  async fetchRepairsUpdatesLogDetail(req: RepairsUpdatesLogDetailGetRequest): Promise<void> {
    const res = await repairsClient.getRepairsUpdatesLogDetail(req)
    this.SET_REPAIRS_UPDATES_LOG_DETAIL_GET(res)
  }
}

export const repairsModule = getModule(RepairsStore)
