import { ADMIN_ROLE_MAP, INITIAL_AUTH_STATE_MAP, AUDIT_STATE_MAP, IDEA_STATE_MAP, OWNER_NOTIFICATION_STATE_MAP, RESOLUTION_STATE_MAP, TICKET_STATE_MAP, TICKET_TASK_STATE_MAP, QUESTIONNAIRE_STATE_MAP } from '@/constants/schema-constants'
import type { AdminRole, InitialAuthState, AuditState, IdeaState, OwnerNotificationState, ResolutionState, TicketState, TicketTaskState, QuestionnaireState } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import type { ChipColors } from '@/constants/ux-constants'

/**
 * ステータスに紐づくラベルを返す
 * @param {AdminRole} adminRole - ステータス（例：1, 2）
 * @return {string} ステータス用のラベル（例："smooth-e 管理者" , "ライフマネージャー"）
 */
export function getAdminRoleLabel(adminRole:AdminRole): string {
  return ADMIN_ROLE_MAP.get(adminRole)?.LABEL ?? ''
}

/**
 * ステータスに紐づくラベルを返す
 * @param {InitialAuthState} initialAuthState - ステータス（例：1, 9）
 * @return {string} ステータス用のラベル（例："済" , "未"）
 */
export function getInitialAuthStateLabel(initialAuthState:InitialAuthState): string {
  return INITIAL_AUTH_STATE_MAP.get(initialAuthState)?.LABEL ?? ''
}

/**
 * ステータスに紐づくラベルを返す
 * @param {IdeaState} ideaState - ステータス（例：11, 12）
 * @return {string} ステータス用のラベル（例："投票受付中" , "アーカイブ"）
 */
export function getIdeaLabel(ideaState:IdeaState): string {
  return IDEA_STATE_MAP.get(ideaState)?.LABEL ?? ''
}

/**
 * ステータスに紐づくラベルを返す
 * @param {ResolutionState} resolutionState - ステータス（例：11, 12）
 * @return {string} ステータス用のラベル（例："投票受付中" , "アーカイブ"）
 */
export function getResolutionLabel(resolutionState:ResolutionState): string {
  return RESOLUTION_STATE_MAP.get(resolutionState)?.LABEL ?? ''
}

/**
 * ステータスに紐づくラベルを返す
 * @param {AuditState} auditState - ステータス（例：1, 2）
 * @return {string} ステータス用のラベル（例："外部監査役に委託" , "組合員から監事を選任"）
 */
export function getAuditLabel(auditState:AuditState): string {
  return AUDIT_STATE_MAP.get(auditState)?.LABEL ?? ''
}

/**
 * ステータスに紐づくラベルを返す
 * @param {OwnerNotificationState} ownerNotificationState - ステータス（例：1, 2）
 * @return {string} ステータス用のラベル（例："下書き" , "公開予定"）
 */
export function getOwnerNotificationStateLabel(ownerNotificationState:OwnerNotificationState): string {
  return OWNER_NOTIFICATION_STATE_MAP.get(ownerNotificationState)?.LABEL ?? ''
}

/**
 * ステータスに紐づくラベルを返す
 * @param {TicketState} ticketState - ステータス（例：1, 2, 3）
 * @returns {string} ステータス用のラベル（例："担当者未設定" , "処理中" , "完了"）
 */
export function getTicketStateLabel(ticketState:TicketState): string {
  return TICKET_STATE_MAP.get(ticketState)?.LABEL ?? ''
}

/**
 * ステータスに紐づくチップカラーを返す
 * @param {TicketState} ticketState - ステータス（例：1, 2, 3）
 * @returns {ChipColors} ステータス用のラベル（例：'green' ,'gray'）
 */
export function getTicketStateChipColor(ticketState:TicketState): ChipColors {
  return TICKET_STATE_MAP.get(ticketState)?.CHIP_COLOR ?? CHIP_COLORS.GRAY
}

/**
 * ステータスに紐づくラベルを返す
 * @param {TicketTaskState} ticketTaskState - ステータス（例：1, 2, 3, 4）
 * @returns {string} ステータス用のラベル（例："担当者未設定" , "未着手" , "処理中" , "完了"）
 */
export function getTicketTaskStateLabel(ticketTaskState:TicketTaskState): string {
  return TICKET_TASK_STATE_MAP.get(ticketTaskState)?.LABEL ?? ''
}

/**
 * ステータスに紐づくチップカラーを返す
 * @param {TicketTaskState} ticketTaskState - ステータス（例：1, 2, 3, 4）
 * @returns {ChipColors} ステータス用のラベル（例：'green' ,'gray'）
 */
export function getTicketTaskStateChipColor(ticketTaskState:TicketTaskState): ChipColors {
  return TICKET_TASK_STATE_MAP.get(ticketTaskState)?.CHIP_COLOR ?? CHIP_COLORS.GRAY
}

/**
 * アンケート状態に紐づくラベルを返す
 * @param {QuestionnaireState} questionnaireState - 種別
 * @returns {string} アンケート状態に紐づくラベル
 */
export function getQuestionnaireLabel(questionnaireState:QuestionnaireState): string {
  return QUESTIONNAIRE_STATE_MAP.get(questionnaireState)?.LABEL ?? ''
}

/**
 * アンケート状態に紐づくチップカラーを返す
 * @param {QuestionnaireState} questionnaireState - ステータス
 * @returns {string} ステータス用のラベル（例：”下書き" , "公開中")
 */
export function getQuestionnaireStateLChipColor(questionnaireState:QuestionnaireState): ChipColors {
  return QUESTIONNAIRE_STATE_MAP.get(questionnaireState)?.CHIP_COLOR ?? CHIP_COLORS.GRAY
}
