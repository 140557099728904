






























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { NewTabTransitionTo, NEW_TAB_TRANSITION_TO } from '@/constants/schema-constants'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'

import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { myProfileModule } from '@/stores/my-profile-store'
import { newTabLocalTransitionParamStorageModule, NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { staticAssetsModule } from '@/stores/static-assets-store'

const TRANSITION_TO_RECORD: Record<NewTabTransitionTo, { route: string }> = {
  [NEW_TAB_TRANSITION_TO.IDEA.OWNER]: { route: staticRoutes.ownerIdeaDetail.name },
  [NEW_TAB_TRANSITION_TO.IDEA.ADMIN]: { route: staticRoutes.adminIdeaDetail.name },
  [NEW_TAB_TRANSITION_TO.RESOLUTION.GENERAL_MEETING]: { route: staticRoutes.gmResolutionDetail.name },
  [NEW_TAB_TRANSITION_TO.RESOLUTION.GENERAL_MEETING_DRAFT]: { route: staticRoutes.gmResolutionCreate.name },
  [NEW_TAB_TRANSITION_TO.RESOLUTION.ONLINE]: { route: staticRoutes.onlineResolutionDetail.name },
  [NEW_TAB_TRANSITION_TO.RESOLUTION.ONLINE_DRAFT]: { route: staticRoutes.onlineResolutionCreate.name },
  [NEW_TAB_TRANSITION_TO.QA]: { route: staticRoutes.consultationDetail.name },
  [NEW_TAB_TRANSITION_TO.BUILDING_DETAIL]: { route: staticRoutes.buildingDetail.name },
  [NEW_TAB_TRANSITION_TO.OWNER_DETAIL]: { route: staticRoutes.ownerDetail.name },
}

@Component({
  components: {
    SmProgressOverlay: () => import('@/components/organisms/SmProgressOverlay.vue'),
    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class NewTabTransitionPage extends Mixins(CurrentAdminManager) {
  @Prop({ default: '' })
  private readonly id!: string

  isLoading = true

  async created(): Promise<void> {
    window.addEventListener('error', () => { this.onFailed() }) // windowで何らかのエラー発生時もエラー表示に切り替える
    await this.transition()
  }

  async transition(): Promise<void> {
    // 別タブでも操作ができるように必要な情報を取得しておく
    await myProfileModule.fetchMyProfile()
    if (this.$isServiceStaff) await staticAssetsModule.fetchReactions()

    // 遷移先パラメータを取得
    const transitionParams = newTabLocalTransitionParamStorageModule.newTabTransitionParams(this.id)
    if (transitionParams) await this.moveBasedOnTransitionParams(transitionParams)
    else this.onFailed() // 遷移先が無指定 or 特定できなかった場合はエラー表示

    newTabLocalTransitionParamStorageModule.deleteNewTabTransitionParams(this.id) // 取得後は不要となるため削除
  }

  async moveBasedOnTransitionParams(transitionParams: NewTabTransitionParams): Promise<void> {
    const { newTabTransitionTo, ...params } = transitionParams
    if (params.buildingId) {
      // 物件配下の画面へ遷移を要求されている場合、その物件の詳細取得と、選択中物件の保持
      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(params.buildingId))
      currentStateModule.setCurrentBuilding(params.buildingId)
    }

    if (newTabTransitionTo) {
      const toRouteName = TRANSITION_TO_RECORD[newTabTransitionTo]?.route
      if (toRouteName) {
        await this.moveBasedOnAccount().then(firstRoute => { // 遷移先の戻る画面を、このログイン画面ではなくするために2段階遷移する
          if (firstRoute.name !== toRouteName) this.$router.push({ name: toRouteName, params: params as Record<string, string> /* avoid error */ })
        })
        return
      }
    }
    this.onFailed() // 遷移先が無指定 or 特定できなかった場合はエラー表示
  }

  moveBasedOnAccount(): Promise<Route> { // 基本導線
    if (this.$isServiceStaff) return this.$router.push({ name: staticRoutes.dashboard.name })
    else if (this.$isAccountingDept || this.$isFrontPerson || this.$isGroupCallCenter) return this.$router.push({ name: staticRoutes.buildingsList.name })
    else throw new Error() // unexpected
  }

  onFailed(): void {
    // ローディングを停止しエラー表示する
    this.isLoading = false
  }
}
