import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { ownersClient } from '@/clients/owners-client'
import { OwnersGetRequest, OwnersGetResponse } from '@/dtos/owners/get'

@Module({ store, dynamic: true, namespaced: true, name: 'ownersStore' })
class OwnersStore extends VuexModule {
  private _ownersGet: OwnersGetResponse = new OwnersGetResponse()

  get ownersGet(): OwnersGetResponse { return this._ownersGet }

  @Mutation
  private SET_OWNERS_GET(res: OwnersGetResponse): void {
    this._ownersGet = res
  }

  @Action
  async fetchOwners(req: OwnersGetRequest): Promise<void> {
    const res = await ownersClient.getOwners(req)
    this.SET_OWNERS_GET(res)
  }
}

export const ownersModule = getModule(OwnersStore)
