





















































































































import { Mixins, Component } from 'vue-property-decorator'
import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { simpleRepairPlanExcelParser } from '@/libs/excel-parser/simple-repair-plan-parser'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'

import { buildingsModule } from '@/stores/buildings-store'
import { errorsModule } from '@/stores/errors'
import { simpleRepairsModule } from '@/stores/simple-repairs-store'

import { BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { MaterialFormInputDto } from '@/dtos/commons'
import { SimpleRepairPlanPostRequest } from '@/dtos/simple-repairs/plans/post'

const INVALID_FORMAT_MESSAGES = ['ファイルの形式が想定と異なるか、不正な値が設定されているため、アップロードできません。']

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class SimpleRepairPlanRegisterPage extends Mixins(CurrentAdminManager) {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  private get buildingDetail(): BuildingDetailGetResponse | null { return buildingsModule.buildingDetailGet }

  repairPlanUploaded: MaterialFormInputDto | null = null
  financialPlanUploaded: MaterialFormInputDto | null = null
  financialPlanBalance = 0

  isUploadDialogVisible = false

  closeUploadDialog(): void {
    this.isUploadDialogVisible = false
  }

  async uploadFiles(): Promise<void> {
    errorsModule.clearGlobalErrors()
    if (!this.repairPlanUploaded?.file || !this.financialPlanUploaded?.file) return
    this.isUploadDialogVisible = false

    const constructionPlanData = await simpleRepairPlanExcelParser.constructionPlanParse(this.repairPlanUploaded.file)
    const financialPlanData = await simpleRepairPlanExcelParser.financialPlanParse(this.financialPlanUploaded.file)

    if (!constructionPlanData || !financialPlanData) {
      errorsModule.setGlobalErrors(INVALID_FORMAT_MESSAGES)
      return
    }
    financialPlanData.balance = this.financialPlanBalance
    const req = new SimpleRepairPlanPostRequest(constructionPlanData, financialPlanData)
    await simpleRepairsModule.postSimpleRepairPlan(req)

    this.$router.replace({ name: staticRoutes.simpleRepairPlanDetail.getChild('summary').name, params: { needsGuideDialog: 'true' } })
  }
}
