import { myProfileModule } from './my-profile-store'
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { ownerIdeasClient } from '@/clients/owner-ideas-client'
import { OwnerIdeasGetRequest, OwnerIdeasGetResponse } from '@/dtos/ideas/owner-idea/get'
import { OwnerIdeaDetailGetRequest, OwnerIdeaDetailGetResponse, AdminIdeaPreparingStatus, FollowingIdea } from '@/dtos/ideas/owner-idea/get-detail'
import { OwnerIdeasPostRequest } from '@/dtos/ideas/owner-idea/post'
import { OwnerIdeasDraftPostRequest } from '@/dtos/ideas/owner-idea/draft/post'
import { OwnerIdeasDeleteRequest } from '@/dtos/ideas/owner-idea/delete'
import { OwnerIdeasRejectRequest } from '@/dtos/ideas/owner-idea/reject/put'
import { OwnerIdeasDraftDeleteRequest } from '@/dtos/ideas/owner-idea/draft/delete'
import { AdminIdeaPreparingStatusDeleteRequest } from '@/dtos/ideas/owner-idea/admin-idea-preparing-status/delete'
import { AdminIdeaPreparingStatusPostRequest, AdminIdeaPreparingStatusUpdateDto } from '@/dtos/ideas/owner-idea/admin-idea-preparing-status/post'
import { AdminIdeaPreparingStatusPutRequest } from '@/dtos/ideas/owner-idea/admin-idea-preparing-status/put'
import { Material, User } from '@/dtos/commons'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'

@Module({ store, dynamic: true, namespaced: true, name: 'ownerIdeasStore' })
class OwnerIdeasStore extends VuexModule {
  private _ownerIdeasGet: OwnerIdeasGetResponse = new OwnerIdeasGetResponse()

  get ownerIdeasGet(): OwnerIdeasGetResponse {
    return this._ownerIdeasGet
  }

  @Mutation
  private SET_IDEAS_GET(res: OwnerIdeasGetResponse): void {
    this._ownerIdeasGet = res
  }

  @Action
  async fetchIdeas(req: OwnerIdeasGetRequest): Promise<void> {
    const res = await ownerIdeasClient.getIdeas(req)
    this.SET_IDEAS_GET(res)
  }

  private _ideaDetails: Record<string, OwnerIdeaDetailGetResponse> = {}

  get detailResponse(): (id: string) => OwnerIdeaDetailGetResponse | undefined { return (id: string) => this._ideaDetails[id] }

  @Mutation
  private SET_IDEA_DETAIL_GET(res: OwnerIdeaDetailGetResponse): void {
    Vue.set(this._ideaDetails, res.ideaId, res)
  }

  @Action
  async fetchIdeaDetail(req: OwnerIdeaDetailGetRequest): Promise<void> {
    const res = await ownerIdeasClient.getIdeaDetail(req)
    this.SET_IDEA_DETAIL_GET(res)
  }

  @Action
  async postIdeas(req: OwnerIdeasPostRequest): Promise<void> {
    await ownerIdeasClient.postIdeas(req)
  }

  @Action
  async postDraftIdeas(req: OwnerIdeasDraftPostRequest): Promise<void> {
    await ownerIdeasClient.postDraftIdeas(req)
  }

  @Action
  async deleteIdeas(req: OwnerIdeasDeleteRequest): Promise<void> {
    await ownerIdeasClient.deleteIdeas(req)
  }

  @Action
  async deleteDraftIdeas(ideaId: string): Promise<void> {
    await ownerIdeasClient.deleteDraftIdeas(new OwnerIdeasDraftDeleteRequest(ideaId))
  }

  @Action
  async rejectIdeas(req: OwnerIdeasRejectRequest): Promise<void> {
    await ownerIdeasClient.rejectIdeas(req)
  }

  /* プラン準備状況関連 */
  @Mutation
  private SET_ADMIN_IDEA_PREPARING_STATUS(preparingStatusDto:AdminIdeaPreparingStatusUpdateDto) {
    const { ideaId, ...preparingStatus } = preparingStatusDto
    // ストア内の要素の一つだけを更新する際、単純に再代入するとVueが変更を検知できないので、ディープコピーして別インスタンスを生成する
    const detail = deepCopy(this._ideaDetails[ideaId], {
      this: new ColumnToType(OwnerIdeaDetailGetResponse),
      material: new ColumnToType(Material),
      followingIdea: new ColumnToType(FollowingIdea),
      adminIdeaPreparingStatus: new ColumnToType(AdminIdeaPreparingStatus),
      admin: new ColumnToType(User),
      ownerUser: new ColumnToType(User)
    }, 'this')
    if (!detail) throw new Error('target owner idea is not registered')
    detail.adminIdeaPreparingStatus = preparingStatus
    Vue.set(this._ideaDetails, ideaId, detail)
  }

  @Mutation
  private DELETE_ADMIN_IDEA_PREPARING_STATUS(ideaId:string) {
    const detail = deepCopy(this._ideaDetails[ideaId], {
      this: new ColumnToType(OwnerIdeaDetailGetResponse),
      material: new ColumnToType(Material),
      followingIdea: new ColumnToType(FollowingIdea),
      adminIdeaPreparingStatus: new ColumnToType(AdminIdeaPreparingStatus),
      admin: new ColumnToType(User),
      ownerUser: new ColumnToType(User)
    }, 'this')
    if (!detail) throw new Error('target owner idea is not registered')
    detail.adminIdeaPreparingStatus = undefined
    Vue.set(this._ideaDetails, ideaId, detail)
  }

  @Action
  async postAdminIdeaPreparingStatus(req:AdminIdeaPreparingStatusPostRequest): Promise<void> {
    const res = await ownerIdeasClient.postAdminIdeaPreparingStatus(req)
    if (!res) throw new Error() // unexpected
    const admin = myProfileModule.myProfileGet?.user
    if (!admin) throw new Error() // unexpected
    const preparingStatus = new AdminIdeaPreparingStatusUpdateDto(req, res, admin)
    this.SET_ADMIN_IDEA_PREPARING_STATUS(preparingStatus)
  }

  @Action
  async putAdminIdeaPreparingStatus(req:AdminIdeaPreparingStatusPutRequest): Promise<void> {
    const res = await ownerIdeasClient.putAdminIdeaPreparingStatus(req)
    if (!res) throw new Error() // unexpected
    const admin = myProfileModule.myProfileGet?.user
    if (!admin) throw new Error() // unexpected
    const preparingStatus = new AdminIdeaPreparingStatusUpdateDto(req, res, admin)
    this.SET_ADMIN_IDEA_PREPARING_STATUS(preparingStatus)
  }

  @Action
  async deleteAdminIdeaPreparingStatus(req:AdminIdeaPreparingStatusDeleteRequest): Promise<void> {
    await ownerIdeasClient.deleteAdminIdeaPreparingStatus(req)
    this.DELETE_ADMIN_IDEA_PREPARING_STATUS(req.ideaId)
  }
}

export const ownerIdeasModule = getModule(OwnerIdeasStore)
