































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { QUESTIONNAIRE_QUESTION_TYPES, REPORT_STATE } from '@/constants/schema-constants'
import type { ReportState } from '@/constants/schema-constants'
import { QuestionnaireDetailGetRequest } from '@/dtos/questionnaires/get'
import { QuestionnaireReportResultSection, QuestionnaireReportsResponse } from '@/dtos/questionnaires/reports/get'
import { QuestionnaireAnswerLibraryPutRequest } from '@/dtos/questionnaires/reports/answer-libraries/put'
import { questionnaireReportsModule } from '@/stores/questionnaire-reports-store'
import { questionnairesModule } from '@/stores/questionnaires-store'
import { convertIndexIntoSerialChar } from '@/libs/add-alphabet-provider'
import { staticRoutes } from '@/routes'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmQuestionnairesReportsQuestionAnswerText: () => import('@/components/molecules/SmQuestionnairesReportsQuestionAnswerText.vue'),
  }
})
export default class ReportSubPage extends Vue {
  REPORT_STATE = Object.freeze(REPORT_STATE)
  QUESTIONNAIRE_QUESTION_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_TYPES)

  @Prop({ required: true, default: '' })
  private readonly questionnaireId!: string

  async created(): Promise<void> {
    const questionnaireId = this.questionnaireId
    await questionnairesModule.fetchQuestionnaireDetail(new QuestionnaireDetailGetRequest(this.questionnaireId))
    const questionnaireReportId = this.questionnaireReportId
    if (questionnaireReportId) await questionnaireReportsModule.fetchReport({ questionnaireId, questionnaireReportId })
  }

  private get questionnaireReportId(): string | undefined { return questionnairesModule.questionnaireDetail(this.questionnaireId)?.questionnaireReportId }
  private get reportState(): ReportState | undefined { return questionnairesModule.questionnaireDetail(this.questionnaireId)?.reportState }

  private get questionnaireReport(): QuestionnaireReportsResponse { return questionnaireReportsModule.questionnaireReportsResponse }

  private getSectionName(sortOrderNum: number): string {
    return `セクション${convertIndexIntoSerialChar(sortOrderNum)}`
  }

  private async onClickPutAnswerBtn(section: QuestionnaireReportResultSection) {
    const questionnaireReportResultSectionAnswerId = section.questionnaireReportResultSectionAnswerId
    const req = new QuestionnaireAnswerLibraryPutRequest(!section.isModelAnswer, section.version)
    await questionnaireReportsModule.putQuestionnaireAnswerLibraries({ questionnaireReportResultSectionAnswerId, req })

    const questionnaireId = this.questionnaireId
    const questionnaireReportId = this.questionnaireReportId ?? ''
    await questionnaireReportsModule.fetchReport({ questionnaireId, questionnaireReportId })
  }

  private onClickCreate(): void {
    this.$router.push({ name: staticRoutes.questionnaireReportPostPage.name, query: { questionnaireId: this.questionnaireId, questionnaireReportId: this.questionnaireReportId } })
  }

  private onClickEdit(): void {
    this.$router.push({ name: staticRoutes.questionnaireReportPostPage.name, query: { questionnaireId: this.questionnaireId, questionnaireReportId: this.questionnaireReportId } })
  }

  private onClickPreview(): void {
    this.$router.push({ name: staticRoutes.questionnaireReportPrint.name, query: { questionnaireId: this.questionnaireId, questionnaireReportId: this.questionnaireReportId } })
  }
}
