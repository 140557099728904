










































import { Vue, Component, Model, Prop } from 'vue-property-decorator'

export class FilterLabel {
  label: string
  align?: string
  width?: string

  constructor(label: string, align?: string, width?: string) {
    this.label = label
    this.align = align
    this.width = width
  }
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
  }
})
export default class SmExpansionFilterMenu extends Vue {
  @Prop({ required: true, default: () => ({}) })
  private readonly labels!: Record<string, FilterLabel>

  @Model('change')
  private readonly isOpen!: boolean

  // スロットにカスタムコンポーネントのみを設定すると描画されないため、空文字の力を借りる
  emptyDummyString = ''

  private get _isOpen():boolean { return this.isOpen }
  private set _isOpen(newValue: boolean) {
    this.$emit('change', newValue)
  }
}
