import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { config } from 'vuex-module-decorators'

Vue.use(Vuex)
config.rawError = true

const KEY = 'smooth-e'
const PRESERVED_STATE_SESSION_PATHS = ['auth', 'blobUrl', 'buildings', 'current', 'myProfile', 'paramStorage', 'staticAssets']
const PRESERVED_STATE_LOCAL_PATHS = ['localParamStorage', 'newTabLocalTransitionParamStorage', 'newTabLocalParamStorage']

// すでにstateが保持されていればそれを、でなければ初期化したものをストアとして使う
clearInvalidStorage()
export const isAlreadyInitialized = window.sessionStorage.getItem(KEY) !== null
export const isAlreadyLocalStoreInitialized = window.localStorage.getItem(KEY) !== null

/**
 * persistedState(セッションストレージ)の設定方法
 * 1. 対象のstoreのModule宣言時、preserveState: isAlreadyInitialized を追加する
 *    @Module({ store, dynamic: true, namespaced: true, name: 'persistedSampleStore', preserveState: isAlreadyInitialized })
 * 2. ↑のPRESERVED_STATE_SESSION_PATHS内に保持するstore名（name）を記載する
 * 3. @/stores/preserve-stateに、対象のstoreをimportさせる
 */
const persistedSessionStorage = createPersistedState({
  key: KEY,
  paths: PRESERVED_STATE_SESSION_PATHS,
  storage: window.sessionStorage,
})

/**
 * persistedState(ローカルストレージ)の設定方法
 * 1. 対象のstoreのModule宣言時、preserveState: isAlreadyLocalStoreInitialized を追加する
 *    @Module({ store, dynamic: true, namespaced: true, name: 'persistedSampleStore', preserveState: isAlreadyLocalStoreInitialized })
 * 2. ↑のPRESERVED_STATE_LOCAL_PATHS内に保持するstore名（name）を記載する
 * 3. @/stores/preserve-stateに、対象のstoreをimportさせる
 */
const persistedLocalStorage = createPersistedState({
  key: KEY,
  paths: PRESERVED_STATE_LOCAL_PATHS,
  storage: window.localStorage,
})

export const store = new Vuex.Store({
  modules: {
    /** dynamically injected if module declared as @Module({ store, dynamic: true }) */
  },
  plugins: [persistedSessionStorage, persistedLocalStorage]
})

function clearInvalidStorage() {
  try {
    const _v = window.sessionStorage.getItem(KEY)
    if (!_v) return
    else if (_v === '{}') {
      window.sessionStorage.removeItem(KEY)
      return
    }

    const v = JSON.parse(_v)
    for (const p of PRESERVED_STATE_SESSION_PATHS) {
      if (!v[p]) {
        window.sessionStorage.removeItem(KEY)
        return
      }
    }
  } catch {
    // parseに失敗した場合
    window.sessionStorage.removeItem(KEY)
  }

  try {
    const _v = window.localStorage.getItem(KEY)
    if (!_v) return
    else if (_v === '{}') {
      window.localStorage.removeItem(KEY)
      return
    }

    const v = JSON.parse(_v)
    for (const p of PRESERVED_STATE_LOCAL_PATHS) {
      if (!v[p]) {
        window.localStorage.removeItem(KEY)
        return
      }
    }
  } catch {
    // parseに失敗した場合
    window.localStorage.removeItem(KEY)
  }
}
