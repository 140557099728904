import { ChartType, PostTimingType, QuestionnaireQuestionType, ReportState } from '@/constants/schema-constants'
import { Material } from '@/dtos/commons'
import { Type } from 'class-transformer'

export class QuestionnaireReportResultSectionImplementationResultQuestion {
  questionnaireReportResultSectionImplementationResultQuestionId!: string
  questionnaireChoiceAnswerId!: string
  freeAnswer!: string
  sortOrderNum!: number
}

export class QuestionnaireReportResultSection {
  questionnaireReportResultSectionId!: string
  sortOrderNum!: number
  questionnaireReportResultSectionAnswerId!: string
  answerBody!: string
  isModelAnswer!: boolean
  version!: number
  questionnaireReportResultSectionImplementationResultQuestion!: QuestionnaireReportResultSectionImplementationResultQuestion[]
}

export class CompareTargetDto {
  questionnaireReportCompareTargetId!: string
  questionnaireId!: string
}

export class ChoiceSummaryDto {
  questionnaireReportChoiceSummaryId!:string
  choice!:string
  sortOrderNum!: number
  finalAnswerCount!: number
}

export class QuestionnaireSummaryDto {
  questionnaireReportQuestionQuestionnaireSummaryId!:string
  finalAnswerCount!: number
  deadline!: string
  choicesSummary!: ChoiceSummaryDto[]
}

export class QuestionDto {
  questionnaireReportImplementationResultQuestionId!: string
  questionnaireQuestionId!: string
  sortOrderNum!: number
  questionBody!: string
  questionType!: QuestionnaireQuestionType
  chartType!: ChartType
  questionnairesSummary!: QuestionnaireSummaryDto[]
}

export class QuestionnaireReportsResponse {
  buildingId!: string
  buildingName!: string
  reportState!: ReportState
  questionnaireReportTitle!: string
  questionnaireReportExplanation!: string

  @Type(() => Material)
  questionnaireReportMaterial?: Material

  implementationResultTitle?: string
  implementationResultExplanation?: string

  @Type(() => Material)
  implementationResultMaterial?: Material

  resultTitle?: string
  resultExplanation?: string

  @Type(() => Material)
  resultMaterial?: Material

  postedAt!: string
  postTimingType!: PostTimingType
  postedAtDateTime!: string
  deadline!: string
  compareTargets?: CompareTargetDto[]
  questions?: QuestionDto[]
  questionnaireReportResultSection?: QuestionnaireReportResultSection[]
  version!: number
}

export class QuestionnaireComparisonsGetRequest {
  questionnaireId!: string

  constructor(questionnaireId: string) {
    this.questionnaireId = questionnaireId
  }
}
export class QuestionnaireComparisonDto {
  questionnaireId!: string
  title!: string
  deadline!: string
  deadlineDate!: Date
}

export class QuestionnaireComparisonsGetResponse {
  @Type(() => QuestionnaireComparisonDto) questionnaireComparisons: QuestionnaireComparisonDto[] = []
}
