





















import { RepairsItemsGetRequest, RepairsItemsGetResponse } from '@/dtos/repairs/items/get'
import { repairsModule } from '@/stores/repairs-store'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    SmTextPrintSizeAnnounce: () => import('@/components/atoms/SmTextPrintSizeAnnounce.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    RepairPlanConstructionSubPrintPage: () => import('@/pages/repair-plan/repair-plan-detail/repair-plan-construction/RepairPlanConstructionSubPrintPage.vue'),
  }
})
export default class RepairPlanPrintPage extends Vue {
  async created():Promise<void> {
    await repairsModule.fetchRepairsItems(new RepairsItemsGetRequest())
  }

  private get repairItems():RepairsItemsGetResponse {
    return repairsModule.repairsItemsGet
  }
}
