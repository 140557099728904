














































import { Header } from '@/components/molecules/SmTableData.vue'
import { Plan, UnitPayment } from '@/dtos/reserve-funds/payments/get'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { deepCopy, ColumnToType } from '@/libs/deep-copy-provider'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue')

  }
})
export default class UnitPaymentsTable extends Vue {
  @Prop({ required: true })
  unitPayments!:UnitPayment[]

  @Prop({ default: () => [] })
  selectedUnits!:string[]

  @Prop({ default: false })
  canChangePlan!:boolean

  private get _unitPayments(): UnitPayment[] { return this.unitPayments }
  private set _unitPayments(newValue: UnitPayment[]) {
    this.$emit('update:unitPayments', newValue)
  }

  private get _selectedUnits():string[] { return this.selectedUnits }
  private set _selectedUnits(newValue:string[]) { this.$emit('update:selectedUnits', newValue) }

  selectItems(plans:Plan[]):{label:string, value:string}[] {
    return plans.map(e => {
      return {
        label: e.planName,
        value: e.planCode
      }
    })
  }

  changeSelectedItem(roomNumber: string, value: string): void {
    const index = this.getIndex(roomNumber)
    const newUnitPayments = deepCopy(
      this._unitPayments,
      {
        unitPayments: new ColumnToType(UnitPayment),
        plans: new ColumnToType(Plan)
      },
      'unitPayments'
    )
    this.$set(newUnitPayments[index], 'planCode', value)
    this._unitPayments = newUnitPayments
  }

  getIndex(roomNumber: string) :number {
    return this._unitPayments.findIndex(_ => _.roomNumber === roomNumber)
  }

  tableHeaders:Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '112px' }),
    new Header({ text: 'CASYS登録氏名', value: 'userName', width: '155px' }),
    new Header({ text: '支払いプラン', value: 'planCode', width: '260px' }),
    new Header({ text: 'プラン更新日', value: 'updatedAt', width: '170px' }),
    new Header({ text: '前月から支払額変更', value: 'isChanged', width: '180px' }),

  ]
}
