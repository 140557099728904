





import { Component, Prop, Vue } from 'vue-property-decorator'
import { blobUrlModule } from '@/stores/blob-url-store'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { localParamStorageModule } from '@/stores/local-param-storage-store'
import { myProfileModule } from '@/stores/my-profile-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { cognitoAuth } from '@/libs/cognito-auth-adapter'
import { staticRoutes } from '@/routes'
import type { TransitionParams } from '@/dtos/transition'
import { ERROR_MESSAGES } from '@/constants/ux-constants'

@Component({
  components: {
    SmProgressOverlay: () => import('@/components/organisms/SmProgressOverlay.vue'),
  }
})
export default class ExternalLoginPage extends Vue {
  @Prop()
  transitionParams?:TransitionParams

  async created(): Promise<void> {
    this.clearSessionStorage()
    localParamStorageModule.clearTransitionParams()
    // クエリパラメータがなければランディング画面に遷移
    if (!this.transitionParams || !this.transitionParams.transitionTo) {
      this.$router.push({ name: staticRoutes.landing.name })
      return
    }
    localParamStorageModule.setTransitionParams(this.transitionParams)

    // 認証要求
    await cognitoAuth.getSession({
      // 有効なトークンがあった場合
      onSuccess: () => {
        this.$router.push({ name: staticRoutes.loginCompletion.name })
      },
      // 認証時にエラーが発生した場合
      onFailure: () => {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.SESSION_EXPIRED])
      }
    })
  }

  clearSessionStorage(): void {
    blobUrlModule.clear()
    buildingsModule.clear()
    currentStateModule.clear()
    myProfileModule.clear()
    paramStorageModule.clear()
  }

  get globalErrors(): string[] { return errorsModule.globalErrors }
}
