























































































































































































































































































import { CHART_TYPES } from '@/constants/schema-constants'
import { ENQUETE_CHART_BACKGROUND_COLORS } from '@/constants/ux-constants'
import { VoteResultForPreview } from '@/dtos/questionnaires/reports/commons'
import { errorsModule } from '@/stores/errors'
import { newTabLocalParamStorageModule, QuestionnaireReportPreviewContent } from '@/stores/new-tab-local-param-storage-store'
import { Component, Prop, Vue } from 'vue-property-decorator'

const CONTENT_NOT_EXIST = 'プレビューを表示できませんでした。タブを閉じて再度プレビューボタンから画面を開きなおしてください。'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmChartUnionReportHorizontalBar: () => import('@/components/molecules/SmChartUnionReportHorizontalBar.vue'),
    SmChartUnionReportPie: () => import('@/components/molecules/SmChartUnionReportPie.vue'),

    SmOwTextHyperLinked: () => import('@/components/owner-apps/SmOwTextHyperLinked.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class QuestionnaireReportPreviewPage extends Vue {
  CHART_TYPES = Object.freeze(CHART_TYPES)
  ENQUETE_CHART_BACKGROUND_COLORS = Object.freeze(ENQUETE_CHART_BACKGROUND_COLORS)

  @Prop({ default: '' })
  questionnaireId!: string

  @Prop({ default: '' })
  questionnaireReportId!: string

  created(): void {
    if (!this.questionnaireReportId) errorsModule.setGlobalErrors([CONTENT_NOT_EXIST])
  }

  private get report(): QuestionnaireReportPreviewContent { return newTabLocalParamStorageModule.questionnaireReportPreviewContent(this.questionnaireReportId) ?? new QuestionnaireReportPreviewContent() }

  private getChartLabel(voteResult: VoteResultForPreview): string {
    return voteResult.label
  }

  private getChartValueResult(voteResult: VoteResultForPreview): number[] {
    return voteResult.choices.map(choice => choice.count)
  }
}
