import { IdeaState, OpinionState } from '@/constants/schema-constants'
import { User, Material } from '@/dtos/commons'
import { Details } from '@/dtos/ideas/admin-idea/commons'
import { Type } from 'class-transformer'

export class AdminIdeaOpinionsGetRequest {
  isAlternative?: boolean
  isAvailable?: boolean
  skipToken?: string
  take!: number
  ideaId!: string
}

export class OpinionComment {
  opinionCommentId!:string
  @Type(() => User)admin: User = new User()
  commentBody!:string
  postedAt!:string
  version!:number
}

export class Opinion {
  opinionId!: string
  alternativeIdeaBackground?: string
  alternativeIdeaTitle?: string
  opinionBody?: string
  @Type(() => User)ownerUser = new User()
  postedAt!: string
  isAlternative!: boolean
  opinionState!: OpinionState
  @Type(() => Material)material?: Material
  @Type(() => OpinionComment) opinionComment?:OpinionComment
  version!: number
}

export class AdminIdeaOpinionsGetResponse {
  ideaId!: string
  ideaState!: IdeaState
  postedAt!: string
  title!: string
  @Type(() => Details)details: Details = new Details()
  @Type(() => User)user: User = new User()
  @Type(() => Opinion)opinions: Opinion[] = []
  skipToken!: string
  version!: number
}
