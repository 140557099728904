




































































import Vue from 'vue'
import { Component, Model, Prop } from 'vue-property-decorator'

export class Choice {
  id: string
  label: string

  constructor(id: string, label: string) {
    this.id = id
    this.label = label
  }
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})
export default class SmBtnToggle extends Vue {
  @Prop({ required: true, default: () => [] })
  private readonly choices!: Choice[]

  @Model('change')
  private readonly selectedChoices!: string[]

  // トグルボタンを上段、下段でわける場合に上段に配置する数を指定する
  // 指定がない場合は全て上段に並ぶ（下段は非表示）
  @Prop()
  private readonly upperElements?: number

  @Prop({ default: false })
  private readonly isWaiting!:boolean

  private onClickSelectAll() {
    if (this.isWaiting) return
    // "すべて"が押し直された場合は値を更新しない
    if (this.selectedChoices.length !== 0) {
      this._selected = []
    }
  }

  private get upperChoices(): Choice[] {
    if (!this.upperElements || this.choices.length <= this.upperElements) return this.choices
    return this.choices.slice(0, this.upperElements)
  }

  private get lowerChoices(): Choice[] | undefined {
    if (!this.upperElements || this.choices.length <= this.upperElements) return undefined
    return this.choices.slice(this.upperElements)
  }

  private onClickButton(buttonId: string) {
    if (this.isWaiting) return

    if (this._selected.includes('ALL')) {
      this._selected = [buttonId]
    } else {
      if (this._selected.includes(buttonId)) {
        this._selected = this._selected.filter(_ => _ !== buttonId)
      } else {
        this._selected = this._selected.concat(buttonId)
      }
    }
  }

  private get _selected(): string[] { return this.selectedChoices }
  private set _selected(newValue: string[]) {
    this.$emit('change', newValue)
  }
}
