





























































































































































import { Component, Mixins } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { Plan, ReservePaymentsGetResponse, UnitPayment } from '@/dtos/reserve-funds/payments/get'
import { reservePaymentsModule } from '@/stores/reserve-payments-store'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { ReservePaymentsPutRequest, BuildingUnit } from '@/dtos/reserve-funds/payments/put'
import { ReservesPaymentsCheckedGetResponse } from '@/dtos/reserve-funds/payments/checked/get'
import { ReservePaymentsCheckedPostRequest } from '@/dtos/reserve-funds/payments/checked/post'
import { staticRoutes } from '@/routes'
import { generateUuid } from '@/libs/uuid-generator'

class PlanDiff {
  unit!:UnitPayment
  beforePlan?:Plan
  afterPlan!:Plan

  toPlainString():string {
    return `${this.unit.roomNumber} ${this.beforePlan?.plainString() ?? ''}→${this.afterPlan.plainString()}`
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    UnitPaymentsTable: () => import('@/pages/reserve-funds/payments/UnitPaymentsTable.vue'),

    OutputPlanSelectModal: () => import('@/pages/reserve-funds/payments/OutputPlanSelectModal.vue')
  }
})
export default class PaymentsSubPage extends Mixins(CurrentAdminManager) {
  private get response():ReservePaymentsGetResponse {
    return reservePaymentsModule.payments
  }

  private get checked():ReservesPaymentsCheckedGetResponse {
    return reservePaymentsModule.reservesPaymentsChecked
  }

  async beforeCreate():Promise<void> {
    await Promise.all([
      reservePaymentsModule.fetchPayments(),
      reservePaymentsModule.fetchReservesPaymentsChecked(),
    ])
    this.setPayments()
  }

  setPayments():void {
    this.payments = deepCopy(this.response.payments, {
      this: new ColumnToType(UnitPayment),
      plans: new ColumnToType(Plan)
    }, 'this')
    this.payments = this.payments.map(e => {
      e.plans = this.response.plans.concat(e.plans)
      return e
    })
  }

  payments:UnitPayment[] = []

  selectedUnits:string[] = []
  private get _selectedNames(): string | undefined {
    if (!this.selectedUnits.length) return
    return this.payments.filter(e => this.selectedUnits.includes(e.buildingUnitId)).map(e => e.roomNumber + ' ' + e.userName).join('、')
  }

  openDetail(id:string):void {
    this.$router.push({ name: staticRoutes.reserveFundsPersonalDetail.name, params: { buildingUnitId: id } })
  }

  isConfirmDialogVisible = false

  async checkPayment():Promise<void> {
    const req = new ReservePaymentsCheckedPostRequest()
    req.billingMonth = this.checked.confirmation.billingMonth
    req.isConfirmed = !this.checked.confirmation.isConfirmed // 確定/非確定は送信時に反転
    this.isConfirmDialogVisible = false
    await reservePaymentsModule.postReserveChecked(req)
    await reservePaymentsModule.fetchReservesPaymentsChecked()
  }

  async downloadCSV():Promise<void> {
    if (!this.checked.casysOutput) return
    await reservePaymentsModule.getPaymentsCsv()
  }

  private get candidatePlans():Plan[] {
    if (this.selectedUnits.length === 1) {
      return this.payments.find(e => e.buildingUnitId === this.selectedUnits[0])?.plans ?? []
    }
    return this.response.plans
  }

  isOutputPlanSelectModalVisible = false
  isSelectedUnitsNumOverLimit = false
  outputPlanSelectModalKey = generateUuid()

  onClickOutputPDFBtn():void {
    if (this.selectedUnits.length >= 11) {
      this.isSelectedUnitsNumOverLimit = true
    } else {
      this.isSelectedUnitsNumOverLimit = false
      this.outputPlanSelectModalKey = generateUuid()
      this.isOutputPlanSelectModalVisible = true
    }
  }

  outputPlan(planIds:string[]):void {
    this.isOutputPlanSelectModalVisible = false
    this.$router.push({ name: staticRoutes.reserveFundPaymentsPrint.name, query: { buildingUnits: this.selectedUnits, paymentPlans: planIds } })
  }

  isChangeConfirmDialogVisible = false

  private get changedPlans():PlanDiff[] {
    const DUMMY_PLAN_DIFF = new PlanDiff()
    const UNSETTED_PLAN = new Plan()

    const diffs = this.payments.map(after => {
      const before = this.response.payments.find(e => after.buildingUnitId === e.buildingUnitId)
      // 元の住戸が存在しない場合スキップ（まずないはず）
      if (!before) return DUMMY_PLAN_DIFF

      // プランが選択されてない、もしくは一致する場合もスキップ
      const beforePlan = after.plans.find(e => {
        return e.planCode === before.planCode
      })
      const afterPlan = after.plans.find(e => e.planCode === after.planCode)
      if (!afterPlan || beforePlan?.planCode === after.planCode) return DUMMY_PLAN_DIFF
      const result = new PlanDiff()
      result.unit = after
      result.beforePlan = beforePlan ?? UNSETTED_PLAN
      result.afterPlan = afterPlan
      return result
    })
    return diffs.filter(e => e !== DUMMY_PLAN_DIFF)
  }

  async changePlan():Promise<void> {
    const req = new ReservePaymentsPutRequest()
    const payments = this.payments.filter(p => {
      if (!p.planCode) return false
      const before = this.response.payments.find(e => p.buildingUnitId === e.buildingUnitId)
      if (before?.planCode !== p.planCode) return true
      return false
    })
    req.buildingUnits = payments.map(e => {
      const paymentId = e.plans.find(p => p.planCode === e.planCode)?.paymentPlanId
      const unit = new BuildingUnit(e.buildingUnitId, paymentId ?? '')
      return unit
    })
    this.isChangeConfirmDialogVisible = false
    await reservePaymentsModule.putPayments(req)
    await reservePaymentsModule.fetchPayments()
    this.setPayments()
  }
}
