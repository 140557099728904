


































import { Vue, Component } from 'vue-property-decorator'
import { resolutionsModule } from '@/stores/resolutions-store'
import { Requirement } from '@/dtos/resolutions/requirements/get'
import { getResolutionLabel } from '@/libs/type-handler'
import type { ResolutionType } from '@/constants/schema-constants'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class RequirementsListPage extends Vue {
  async created():Promise<void> {
    await resolutionsModule.fetchRequirements()
  }

  private get requirements(): Requirement[] {
    return resolutionsModule.requirementsGet?.requirements ?? []
  }

  private getLabel(resolutionType:ResolutionType):string {
    return getResolutionLabel(resolutionType)
  }
}
