export class MaterialPostRequest {
  mimeType: string
  fileName: string
  filePath?: string
  needReferenceUrl?: boolean

  constructor(mimeType: string, fileName: string, filePath?: string, needReferenceUrl?: boolean) {
    this.mimeType = mimeType
    this.fileName = fileName
    this.filePath = filePath
    this.needReferenceUrl = needReferenceUrl
  }
}

export class MaterialPostResponse {
  materialId!: string
  signedUrl!: string
  signedReferenceUrl?: string
}
