








































































import { Component, Vue, Prop, Model } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { Plan } from '@/dtos/reserve-funds/payments/get'

const MAX_PLAN_COUNT = 6

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})

export default class OutputPlanSelectModal extends Vue {
  @Prop({ default: () => [] })
  plans!:Plan[]

  headers : Header[] = [
    new Header({ text: '作成日', value: 'plannedAt', width: '200px' }),
    new Header({ text: 'プランID', value: 'planCode', width: '120px' }),
    new Header({ text: 'プラン名', value: 'planName' }),
  ]

  MAX_PLAN_COUNT = Object.freeze(MAX_PLAN_COUNT)

  @Model('change', { required: true })
  private readonly isVisible!: boolean

  private get isModalVisible(): boolean {
    this.selectedPlanIds = [] // 開閉時に選択をリセット
    return this.isVisible
  }

  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get _selectedNames(): string | undefined {
    if (!this.selectedPlanIds.length) return
    return this.plans.filter(e => this.selectedPlanIds.includes(e.paymentPlanId)).map(e => e.planCode + ' ' + e.planName).join('、')
  }

  isSelectedNumOverLimit = false
  onClickBtn():void {
    if (this.selectedPlanIds.length > MAX_PLAN_COUNT) {
      this.isSelectedNumOverLimit = true
    } else {
      this.isSelectedNumOverLimit = false
      this.$emit('output', this.selectedPlanIds)
      this.isModalVisible = false
    }
  }

  private selectedPlanIds:string[] = []
}
