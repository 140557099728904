
import { Type } from 'class-transformer'
export class BuildingsGetRequest {
  keyword?: string
  isStaff?: boolean | null
  exceptOutOfService?: boolean | null
  skip: number
  take: number

  constructor(skip: number, take: number, keyword?: string, isStaff?: boolean, exceptOutOfService?: boolean) {
    this.skip = skip
    this.take = take
    this.keyword = keyword
    this.isStaff = isStaff
    this.exceptOutOfService = exceptOutOfService
  }
}

export class serviceStaffDto {
  adminId!: string
  adminName!: string
}

export class Building {
  buildingId!: string
  condominiumCd!: string
  buildingName!: string
  buildingAreaType!: number
  buildingArea!: string
  isOutOfService!: boolean
  @Type(() => serviceStaffDto) serviceStaff?: serviceStaffDto
}

export class BuildingsGetResponse {
  buildings: Building[] = []
}
