import { authClient } from '@/clients/auth-client'
import { ERROR_MESSAGES } from '@/constants/ux-constants'
import { AuthLoginResponse } from '@/dtos/auth/post'
import { cognitoAuth } from '@/libs/cognito-auth-adapter'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyInitialized, store } from './index'
import { localParamStorageModule } from './local-param-storage-store'

const AUTH_STATE = {
  LOGOUT: 0,
  LOGIN: 1,
} as const

type AuthState = typeof AUTH_STATE[keyof typeof AUTH_STATE]

@Module({ store, dynamic: true, namespaced: true, name: 'auth', preserveState: isAlreadyInitialized })
class AuthStore extends VuexModule {
  private _authState: AuthState = AUTH_STATE.LOGOUT

  get isAuthenticated(): boolean {
    return this._authState !== AUTH_STATE.LOGOUT
  }

  @Mutation
  SET_AUTH_STATE(authState: AuthState): void {
    this._authState = authState
  }

  @Mutation
  RESET() {
    this._authState = AUTH_STATE.LOGOUT
  }

  @Action
  async login(): Promise<void> {
    const res = await authClient.postLogin()
    if (!res) throw new Error() // unexpected
    this.SET_LOGIN_RESPONSE(res)
    this.SET_AUTH_STATE(AUTH_STATE.LOGIN)
  }

  @Action
  async logout(message?:string): Promise<void> {
    localParamStorageModule.resetLoginErrorMessage()
    // iOS・EdgeではCSP違反報告API（/csp）または不具合報告API（/log）がログイン前に呼び出されてしまい、LandingPageが無限に再表示されてログインができなくなるため、ログイン前の場合はサインアウトしない
    if (!authModule.isAuthenticated) return
    if (message)localParamStorageModule.setLoginErrorMessage(message)
    this.RESET()
    await cognitoAuth.signOut()
  }

  /**
   * セッション継続処理
   * アクセストークン有効：セッションをそのまま継続(getSessionの機能)
   * アクセストークン期限切れ・リフレッシュトークン有効：リフレッシュ（getSessionの機能）
   * リフレッシュトークン期限切れ：セッション切れとしてランディング画面に戻す
   */
  @Action
  async updateSessionIfNecessary(): Promise<void> {
    if (cognitoAuth.sessionIsValid()) return
    return new Promise<void>((resolve) => {
      cognitoAuth.refreshSession({
        // リフレッシュ成功
        onSuccess: () => {
          resolve()
        },
        // リフレッシュ失敗
        onFailure: async(responseJson) => {
          if (responseJson) {
            const response = JSON.parse(responseJson)
            // cognitoからエラーが返却されている場合のみログアウト
            if (Object.prototype.hasOwnProperty.call(response, 'error')) {
              await this.logout(ERROR_MESSAGES.SESSION_EXPIRED)
            }
          }
          resolve()
        }
      })
    })
  }

  private _loginResponse:AuthLoginResponse = new AuthLoginResponse()

  get loginResponse():AuthLoginResponse { return this._loginResponse }

  @Mutation
  private SET_LOGIN_RESPONSE(res:AuthLoginResponse) { this._loginResponse = res }
}

export const authModule = getModule(AuthStore)
