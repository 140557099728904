






























import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { Tab } from '@/components/molecules/SmTabs.vue'
import { RESOLUTION_STATES } from '@/constants/schema-constants'

import { onlineResolutionsModule } from '@/stores/online-resolutions-store'
import { structureModule } from '@/stores/structure-store'
import { ResolutionCommonState } from '@/dtos/resolutions/commons'
import { OnlineResolutionsDeleteRequest } from '@/dtos/resolutions/online-resolution/delete'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class OnlineResolutionDetailPage extends Mixins(CurrentAdminManager) {
  @Prop({ required: true, default: '' })
  private readonly resolutionId!:string

  private get onlineResolutionCommon(): ResolutionCommonState { return onlineResolutionsModule.onlineResolutionCommonStateGet ?? new ResolutionCommonState() }

  private get isPreview(): boolean { return structureModule.isPreview }

  private get tabs(): Tab[] {
    const tabs = [
      new Tab('オンライン決議内容', staticRoutes.onlineResolutionDetail.getChild('detail').name, !this.onlineResolutionCommon.details.isResolution),
      new Tab('質問', staticRoutes.onlineResolutionDetail.getChild('questions').name, !this.onlineResolutionCommon.details.isQuestions),
      new Tab('決議結果', staticRoutes.onlineResolutionDetail.getChild('results').name, !this.onlineResolutionCommon.details.isResult),
    ]
    const previewTabs = [
      new Tab('オンライン決議内容', staticRoutes.onlineResolutionDetailPreview.getChild('detail').name, !this.onlineResolutionCommon.details.isResolution),
      new Tab('質問', staticRoutes.onlineResolutionDetailPreview.getChild('questions').name, !this.onlineResolutionCommon.details.isQuestions),
      new Tab('決議結果', staticRoutes.onlineResolutionDetailPreview.getChild('results').name, !this.onlineResolutionCommon.details.isResult),
    ]
    return this.isPreview ? previewTabs : tabs
  }

  currentTab = ''

  isConfirmDialogVisible = false

  // 管理者業務執行者のみ削除可能
  private get hasDeleteAuthority(): boolean {
    return this.$isServiceStaff
  }

  // 削除権限を持ち、ステータスが「結果集計中か結果公開済以外」の場合のみ、削除可能
  private get isDeletable():boolean {
    return this.hasDeleteAuthority &&
    (this.onlineResolutionCommon.resolutionState === RESOLUTION_STATES.ONLINE.ACCEPTING_ALL ||
     this.onlineResolutionCommon.resolutionState === RESOLUTION_STATES.ONLINE.ACCEPTING_VOTE)
  }

  async deleteResolution(): Promise<void> {
    const req = new OnlineResolutionsDeleteRequest(this.resolutionId)
    this.isConfirmDialogVisible = false
    await onlineResolutionsModule.deleteResolutions(req)

    this.$router.push({ name: staticRoutes.resolutionsList.name })
  }
}
