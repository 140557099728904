import { PaymentDetail } from '@/dtos/reserve-funds/get'
import { Type } from 'class-transformer'

export class ReservePlanDetailGetRequest {
  paymentPlanId!:string
}

export class PaymentPlanDetail {
  period!:number
  accountingMonth!:string
  targetYear!:number
  unitPrice!:number
}

export class ReservePlanDetailGetResponse {
  paymentPlanId!:string
  plannedAt!:string
  planCode!:string
  planName!:string
  @Type(() => PaymentPlanDetail)paymentPlanDetail:PaymentPlanDetail[] = []
  @Type(() => PaymentDetail)paymentDetail:PaymentDetail[] = []
}
