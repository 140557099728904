















import { assertExhaustive } from '@/libs/exhaustive-helper'
import { Vue, Component, Prop } from 'vue-property-decorator'

const avatarSizes = ['0.6x', '0.75x', 'default', '1.6x', '4x'] as const // 24, 30, 40, 64, 160
type AvatarSize = typeof avatarSizes[number]
const defaultSize = 40

const DEFAULT_AVATAR = '/resources/general/default-avatar.png'

@Component
export default class SmAvatar extends Vue {
  @Prop({
    default: 'default',
    validator: f => avatarSizes.includes(f)
  })
  private readonly size!: AvatarSize

  @Prop({ default: '/resources/owner-icon/profile_icon_00.png' })
  private readonly src!: string

  @Prop()
  private readonly alt?: string

  @Prop({ default: false })
  private readonly pointer!: boolean

  private get _size(): number {
    switch (this.size) {
      case '0.6x': return defaultSize * 0.6
      case '0.75x': return defaultSize * 0.75
      case 'default': return defaultSize
      case '1.6x': return defaultSize * 1.6
      case '4x': return defaultSize * 4
      default : throw assertExhaustive(this.size)
    }
  }

  private get _src(): string {
    if (this.src) return this.src
    else return DEFAULT_AVATAR
  }
}
