


































































































































































































































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'

import { staticKeyProvider, _Key } from '@/libs/static-key-provider'
import { generateUuid } from '@/libs/uuid-generator'

import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { myProfileModule } from '@/stores/my-profile-store'
import { onlineResolutionsModule } from '@/stores/online-resolutions-store'
import { structureModule } from '@/stores/structure-store'

import { RESOLUTION_STATES } from '@/constants/schema-constants'
import { ERROR_MESSAGES } from '@/constants/ux-constants'

import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { Header } from '@/components/molecules/SmTableData.vue'

import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { ResolutionCommonState } from '@/dtos/resolutions/commons'
import { Section } from '@/dtos/resolutions/online-resolution/results/commons'
import { OnlineResultsGetResponse, Summary } from '@/dtos/resolutions/online-resolution/results/get'
import { OnlineResultsPostRequest } from '@/dtos/resolutions/online-resolution/results/post'
import { OnlineResultsPutRequest } from '@/dtos/resolutions/online-resolution/results/put'
import { VotesGetRequest } from '@/dtos/resolutions/online-resolution/votes/get'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmCardSectionData: () => import('@/components/molecules/card/SmCardSectionData.vue'),
    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    ProxyVoteModal: () => import('@/pages/resolutions/online-resolution/online-resolution-detail/results/ProxyVoteModal.vue')
  }
})
export default class ResultsSubPage extends Mixins(CurrentAdminManager) {
  @Prop({ required: true })
  private readonly resolutionId!:string

  @Prop()
  private readonly buildingId?: string

  async created():Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    await this.reloadResults()
  }

  RESOLUTION_STATES = Object.freeze(RESOLUTION_STATES)

  private get res(): OnlineResultsGetResponse { return onlineResolutionsModule.resultsGet }
  private get commonState(): ResolutionCommonState { return onlineResolutionsModule.onlineResolutionCommonStateGet }
  private get summary(): Summary { return this.res.summary }
  private get isPreview(): boolean { return structureModule.isPreview }

  // ------------------------- 決議結果エリアの表示 -------------------------
  private get showInputForm():boolean { return this.res.resolutionState === RESOLUTION_STATES.ONLINE.COUNTING || this.isEditMode }
  isEditMode = false
  private get isResultEditable():boolean {
    // 結果の投稿・編集は、管理者業務執行者のみ可能
    return this.$isServiceStaff
  }

  options: RadioOption[] = [
    new RadioOption('可決', true),
    new RadioOption('否決', false)
  ]

  showPostDialog = false
  showUpdateDialog = false

  isPassedForInput = true
  resultCommentForInput = '' // 決議結果
  sectionsForInput: (Section & _Key)[] = [] // 決議結果詳細
  proxyVoteInputText=''
  proxyVoteKeyword=''

  // 「↑・↓・削除」が押された場合、sectionの入れ替え・削除
  onClickUp(sectionIndex: number):void {
    if (this.isPreview) return
    this.sectionsForInput.splice(sectionIndex - 1, 2, this.sectionsForInput[sectionIndex], this.sectionsForInput[sectionIndex - 1])
  }

  onClickDown(sectionIndex: number):void {
    if (this.isPreview) return
    this.sectionsForInput.splice(sectionIndex, 2, this.sectionsForInput[sectionIndex + 1], this.sectionsForInput[sectionIndex])
  }

  onClickDelete(sectionIndex: number):void {
    if (this.isPreview) return
    this.sectionsForInput.splice(sectionIndex, 1)
  }

  addSection():void {
    if (this.isPreview) return
    const section = staticKeyProvider.create(Section)
    section.sectionTitle = ''
    section.sectionBody = ''
    this.sectionsForInput.push(section)
  }

  // ------------------------- 投票内訳エリアの表示-------------------------
  isProxyVoteModalVisible = false
  proxyVoteModalKey = generateUuid()

  private get isProxyVotable(): boolean {
    // 代理投票は、質問・投票受付中、投票受付中、結果集計中のいずれかで、かつ管理者業務執行者orフロント担当の場合のみ可能
    if (this.res.resolutionState === RESOLUTION_STATES.ONLINE.ACCEPTING_ALL ||
    this.res.resolutionState === RESOLUTION_STATES.ONLINE.ACCEPTING_VOTE ||
    this.res.resolutionState === RESOLUTION_STATES.ONLINE.COUNTING) {
      return this.$isServiceStaff || this.$isFrontPerson
    }
    return false
  }

  async openProxyVoteModal():Promise<void> {
    if (this.isPreview) return
    await this.fetchVotes()
    this.proxyVoteModalKey = generateUuid()
    this.isProxyVoteModalVisible = true
  }

  async fetchVotes(): Promise<void> {
    const req = new VotesGetRequest(this.resolutionId)
    await onlineResolutionsModule.fetchVotes(req)
  }

  private get totalVoteRateLabel(): string { return `${this.summary.vote.votingRightRate}%(${this.summary.vote.votingRightCount}/${this.summary.votingRightCount})` }
  private get agreeVoteRateLabel():string { return this._getResultDetailLabel(this.res.agree.votingRightRate, this.res.agree.votingRightCount, this.summary.vote.votingRightCount) }
  private get disagreeVoteRateLabel():string { return this._getResultDetailLabel(this.res.disagree.votingRightRate, this.res.disagree.votingRightCount, this.summary.vote.votingRightCount) }

  private _getResultDetailLabel(rate:number, voteCount:number, wholeVoteCount:number): string { return `${rate}%(${voteCount}/${wholeVoteCount}票)` }

  isExpansionAgreeOpen = false
  isExpansionDisagreeOpen = false
  private get showAgreeExpansion():boolean { return this.res.agree.owners.length > 0 }
  private get showDisagreeExpansion():boolean { return this.res.disagree.owners.length > 0 }

  voteTableHeaders : Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '120px' }),
    new Header({ text: '氏名', value: 'userName', width: '145px' }),
    new Header({ text: '議決権数', value: 'votingRightCount' }),
  ]

  // ------------------------- アクションの実行 -------------------------
  async reloadResults(): Promise<void> {
    await onlineResolutionsModule.fetchResults(this.resolutionId)
  }

  openEditForm(): void {
    if (!this.res.results || this.isPreview) return

    this.isPassedForInput = this.res.results.isPassed ?? true
    this.resultCommentForInput = this.res.results.resultComment ?? ''
    this.sectionsForInput = this.res.results.sections?.map(s => {
      const section = staticKeyProvider.create(Section)
      section.sectionTitle = s.sectionTitle
      section.sectionBody = s.sectionBody
      return section
    }) ?? []

    this.isEditMode = true
  }

  async postResult(): Promise<void> {
    this.showPostDialog = false
    const req = new OnlineResultsPostRequest(this.resolutionId, this.isPassedForInput, this.resultCommentForInput, this.sectionsForInput, this.res.version)
    await onlineResolutionsModule.postResults(req)

    await this.reloadResults()
  }

  async updateResult(): Promise<void> {
    this.showUpdateDialog = false
    const req = new OnlineResultsPutRequest(this.resolutionId, this.isPassedForInput, this.resultCommentForInput, this.sectionsForInput, this.res.version)
    await onlineResolutionsModule.putResults(req)
    this.isEditMode = false
    await this.reloadResults()
  }
}
