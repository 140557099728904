import { MANAGEMENT_OPERATION_TYPES } from '@/constants/schema-constants'
import { APIClientBase } from './api-client-base'
import { ManagementOperationsOnboardingSearchPostRequest, ManagementOperationsOnboardingSearchPostResponse } from '@/dtos/management-operations/onboarding/search/post'
import { ManagementOperationsRoutineSearchPostRequest, ManagementOperationsRoutineSearchPostResponse } from '@/dtos/management-operations/routine/search/post'
import { ManagementOperationsUnregisteredExecutorGetResponse } from '@/dtos/management-operations/unregistered-executor/get'
import { ManagementOperationsUnregisteredExecutorPostRequest } from '@/dtos/management-operations/unregistered-executor/post'
import { ManagementOperationsUnregisteredExecutorDeleteRequest } from '@/dtos/management-operations/unregistered-executor/delete'
import { ManagementOperationsTaskStatusPutRequest, ManagementOperationsTaskStatusPutResponse } from '@/dtos/management-operations/task-status/put'
class ManagementOperationsClient extends APIClientBase {
  constructor() {
    super('/management-operations')
  }

  // 稼働準備一覧検索
  async postOperationOnboardingSearch(req: ManagementOperationsOnboardingSearchPostRequest): Promise<ManagementOperationsOnboardingSearchPostResponse | void> {
    return await this.post(`${MANAGEMENT_OPERATION_TYPES.ONBOARDING}/search`, req, ManagementOperationsOnboardingSearchPostResponse)
  }

  // 定例業務一覧検索
  async postOperationRoutineSearch(req: ManagementOperationsRoutineSearchPostRequest): Promise<ManagementOperationsRoutineSearchPostResponse | void> {
    return await this.post(`${MANAGEMENT_OPERATION_TYPES.ROUTINE}/search`, req, ManagementOperationsRoutineSearchPostResponse)
  }

  // アカウント未登録実施者登録
  async postUnregisteredExecutor(req: ManagementOperationsUnregisteredExecutorPostRequest): Promise<void> {
    return await this.post('unregistered-executor', req)
  }

  // アカウント未登録実施者取得
  async getUnregisteredExecutor(): Promise<ManagementOperationsUnregisteredExecutorGetResponse> {
    return await this.get('unregistered-executor', undefined, ManagementOperationsUnregisteredExecutorGetResponse)
  }

  // アカウント未登録実施者削除
  async deleteUnregisteredExecutor(req: ManagementOperationsUnregisteredExecutorDeleteRequest): Promise<void> {
    return await this.delete(`/unregistered-executor/${req.unregisteredExecutorId}`)
  }

  // タスク別対応状況更新
  async putTaskStatus(req: ManagementOperationsTaskStatusPutRequest): Promise<void | ManagementOperationsTaskStatusPutResponse> {
    return await this.put(`task-status/${req.managementOperationTaskStatusId}`, req, ManagementOperationsTaskStatusPutResponse)
  }
}
export const managementOperationsClient = new ManagementOperationsClient()
