import { Type } from 'class-transformer'

export class PersonalProcessingAdmin {
  userId!: string
  userName!: string
  iconImagePath!: string
}

export class PersonalProcessingTicket {
  notCompletedTicketsCount!: number
}

export class PersonalProcessingTask {
  notStartedYetTasksCount!: number
  notCompletedTasksCount!: number
  nearDeadlineTasksCount!: number
  expiredTasksCount!: number
}
export class PersonalProcessingStates {
  @Type(() => PersonalProcessingAdmin) admin: PersonalProcessingAdmin = new PersonalProcessingAdmin()
  @Type(() => PersonalProcessingTicket) ticket: PersonalProcessingTicket = new PersonalProcessingTicket()
  @Type(() => PersonalProcessingTask) task: PersonalProcessingTask = new PersonalProcessingTask()
}

export class DashboardProcessingStatesGetResponse {
  @Type(() => PersonalProcessingStates) personalProcessingStates: PersonalProcessingStates[] = []
}
