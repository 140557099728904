





















import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class NotFoundPage extends Vue {}
