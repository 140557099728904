






































import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { errorsModule } from '@/stores/errors'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmAppBar: () => import('@/components/organisms/SmAppBar.vue'),
  }
})
export default class SmModal extends Vue {
  @Prop({ required: true, default: '' })
  private readonly title!: string

  @Prop({ default: '$close_round' })
  private readonly icon!: string

  @Prop()
  private readonly rightIcon?: string

  @Model('input')
  private isVisible!: boolean

  private get _isVisible(): boolean { return this.isVisible }
  private set _isVisible(newValue: boolean) {
    this.$emit('input', newValue)
  }

  get globalErrors(): string[] { return errorsModule.globalErrors }

  onClose(): void {
    if (this.globalErrors.length) errorsModule.clearGlobalErrors()
    this.$emit('close')
  }
}
