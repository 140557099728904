import { Type } from 'class-transformer'

export class PersonalPlansGetRequest {
  // TODO skip・takeを使うのか、使うとして固定でよいか要確認
  skip = 0
  take = 999
  buildingUnitId!: string
  constructor(buildingUnitId:string) {
    this.buildingUnitId = buildingUnitId
  }
}

export class PaymentPlan {
  paymentPlanId!: string
  plannedAt!: string
  planCode!: string
  planName!: string
}

export class PersonalPlansGetResponse {
  buildingUnitId!: string
  roomNumber!: string
  paymentConfirmedDate?: string
  @Type(() => PaymentPlan)paymentPlans: PaymentPlan[] = []
}
