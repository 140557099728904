import { APIClientBase } from './api-client-base'
import { CasysUnitsPostRequest, CasysUnitsPostResponse } from '@/dtos/casys/units/post'
import { CasysUnitsPutRequest } from '@/dtos/casys/units/put'
import { CasysDetectionsGetRequest, CasysDetectionsGetResponse } from '@/dtos/casys/detections/get'
import { CasysDetectionDetailGetRequest, CasysDetectionDetailGetResponse } from '@/dtos/casys/detections/get-detail'
import { CasysDetectionsResultGetRequest, CasysDetectionsResultGetResponse } from '@/dtos/casys/detections/results/get'

class CasysClient extends APIClientBase {
  constructor() {
    super('/casys')
  }

  postCasysUnits(req: CasysUnitsPostRequest): Promise<void | CasysUnitsPostResponse> {
    return this.post('/units', req, CasysUnitsPostResponse)
  }

  async putCasysUnits(req: CasysUnitsPutRequest): Promise<void> {
    const { owningBuildingUnitId, ...rest } = req
    await this.put(`/units/${owningBuildingUnitId}`, rest)
  }

  getCasysDetections(req: CasysDetectionsGetRequest): Promise<CasysDetectionsGetResponse> {
    return this.get('/detections', req, CasysDetectionsGetResponse, false)
  }

  getCasysDetectionDetail(req: CasysDetectionDetailGetRequest): Promise<CasysDetectionDetailGetResponse> {
    const { casysDifferenceDetectionBatchStatusId, ...rest } = req
    return this.get(`/detections/${casysDifferenceDetectionBatchStatusId}`, rest, CasysDetectionDetailGetResponse, false)
  }

  getCasysDetectionsResults(req: CasysDetectionsResultGetRequest): Promise<CasysDetectionsResultGetResponse> {
    return this.get('/detections/results', req, CasysDetectionsResultGetResponse, false)
  }
}

export const casysClient = new CasysClient()
