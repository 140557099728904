import { Type } from 'class-transformer'

export class QuestionnairesRespondentsGetRequest {
  questionnaireId: string
  constructor(questionnaireId: string) {
    this.questionnaireId = questionnaireId
  }
}

export class Respondent {
  ownerUserId!: string
  ownerUserName!: string
  answerCount!: number
  roomNumber!: string
  isAnswered!: boolean
}

export class QuestionnairesRespondentsGetResponse {
  questionnaireId!: string
  @Type(() => Respondent) respondents: Respondent[] = []
}
