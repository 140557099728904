import { AREA_TYPE_MAP, BUILDING_GROUP_MAP, CASE_TYPE_MAP, RESOLUTION_TYPE_MAP, VOTE_TYPE_MAP, SUBJECT_TYPES_MAP, STATEMENT_TYPES_MAP, PINNING_SETTING_TYPE_MAP, HIERARCHY_TYPE_INDEX_MAP, HIERARCHY_TYPE_DOT_MAP, HOLDING_METHOD_TYPE_MAP, INFORMATION_SOURCE_TYPE_MAP, TICKET_TYPE_MAP, TICKET_TASK_TYPES_MAP, TICKET_TASK_TYPE_IS_AUTO_COMPLETE_MAP, TICKET_TASK_NAME_TYPES_MAP, REPAIR_PLAN_TYPES_MAP, QUESTIONNAIRE_TYPE_MAP, TEMPLATE_TYPE_MAP, QUESTIONNAIRE_QUESTION_TYPE_MAP, TICKET_TASK_TYPE_IS_RESPONSE_OWNER, INITIAL_AUTH_CODE_TYPE_MAP } from '@/constants/schema-constants'
import type { AreaType, BuildingGroupType, CaseType, ResolutionType, VoteType, SubjectType, StatementType, PinningSettingType, HierarchyTypes, HoldingMethodType, InformationSourceType, TicketType, TicketTaskType, TicketTaskNameType, RepairPlanType, QuestionnaireType, TemplateType, QuestionnaireQuestionType, InitialAuthCodeType } from '@/constants/schema-constants'

// 決議関連
/**
 * 決議種別に紐づくラベルを返す
 * @param {ResolutionType} resolutionType - 決議種別（例：1, 2）
 * @return {string} ステータス用のラベル（例："総会決議" , "オンライン決議"）
 */
export function getResolutionLabel(resolutionType:ResolutionType): string {
  return RESOLUTION_TYPE_MAP.get(resolutionType)?.LABEL ?? ''
}

/**
 * 議案種別に紐づくラベルを返す
 * @param {SubjectType} subjectType - 種別（例：1, 2）
 * @return {string} 種別用のラベル（例："普通決議" , "特別決議"）
*/
export function getSubjectLabel(subjectType:SubjectType): string {
  return SUBJECT_TYPES_MAP.get(subjectType)?.LABEL ?? ''
}

// 投票関連

/**
 * 種別に紐づくラベルを返す
 * @param {VoteType} voteType - 種別（例：1, 2）
 * @return {string} 種別用のラベル（例："賛成" , "反対"）
*/
export function getVoteLabel(voteType:VoteType): string {
  return VOTE_TYPE_MAP.get(voteType)?.LABEL ?? ''
}

/**
 * 意思表明種別に紐づくラベルを返す
 * @param {StatementType} statementType - 種別（例：1, 2, 3, 4）
 * @return {string} 種別用のラベル（例："会場" , "議決権行使", "リモート"）
*/
export function getStatementLabel(statementType:StatementType): string {
  return STATEMENT_TYPES_MAP.get(statementType)?.LABEL ?? ''
}

// 区分所有者お知らせ関連
/**
 * お知らせ固定表示設定種別に紐づくラベルを返す
 * @param {PinningSettingType} pinningSettingType - 種別（例：1, 2）
 * @return {string} 種別用のラベル（例："通常のお知らせ" , "固定指定されたお知らせ"）
 */
export function getPinningSettingLabel(pinningSettingType:PinningSettingType): string {
  return PINNING_SETTING_TYPE_MAP.get(pinningSettingType)?.LABEL ?? ''
}

/**
 * 箇条書き（順序なし）の種別に紐づくラベルを返す
 * @param {HierarchyTypes} hierarchyTypes - 種別（0, 1, 2）
 * @return {string} liタグtype属性用の種類（1:"decimal" , 2:"lower-alpha", 0:"lower-roman"）
*/
export function getHierarchyTypeDot(hierarchyTypes:HierarchyTypes): string {
  return HIERARCHY_TYPE_DOT_MAP.get(hierarchyTypes) ?? ''
}

/**
 * 箇条書き（順序あり）の種別に紐づくラベルを返す
 * @param {HierarchyTypes} hierarchyTypes - 種別（0, 1, 2）
 * @return {string} liタグtype属性用の種類（1:"decimal" , 2:"lower-alpha", 0:"lower-roman"）
*/
export function getHierarchyTypeIndex(hierarchyTypes:HierarchyTypes): string {
  return HIERARCHY_TYPE_INDEX_MAP.get(hierarchyTypes) ?? ''
}

/**
 * 開催種別に紐づくラベルを返す
 * @param {HoldingMethodType} holdingMethodType - 種別（1, 2, 3）
 * @return {string} 開催種別に紐づくラベル（1:"会場のみ" , 2:"リモートのみ", 3:"会場・リモート両方"）
*/
export function getHoldingMethodTypeLabel(holdingMethodType:HoldingMethodType): string {
  return HOLDING_METHOD_TYPE_MAP.get(holdingMethodType)?.LABEL ?? ''
}

/**
 * マンショングループに紐づくラベルを返す
 * @param {BuildingGroupType} buildingGroupType 種別（1,2,3,4,5）
 * @returns {string} マンショングループに紐づくラベル（例："HCM東京", "HCM関西", "CMQ", "CMW", "CMO"）
 */
export function getGroupLabel(buildingGroupType:BuildingGroupType): string {
  return BUILDING_GROUP_MAP.get(buildingGroupType)?.LABEL ?? ''
}

/**
 * チケット種別に紐づくラベルを返す
 * @param {TicketType} ticketType - 種別（例：1, 2, 3, 4）
 * @returns {string} チケット種別に紐づくラベル（例： 1:"アイデア" , 2:"決議" , 3:"相談・連絡" , 4:"手動"）
 */
export function getTicketLabel(ticketType:TicketType): string {
  return TICKET_TYPE_MAP.get(ticketType)?.LABEL ?? ''
}

/**
 * チケット種別に紐づくバッジの情報を返す
 * @param {TicketType} ticketType - 種別（例：1, 2, 3, 4）
 * @returns {string, string, string} チケット種別に紐づくバッジの情報（例：1:{ icon: '$emoji_objects_outlined', iconColor: '#03C775', textStyle: 'green-dark' }）
 */
export function getTicketBadge(ticketType:TicketType): {icon: string, iconColor: string, textStyle: string} {
  return TICKET_TYPE_MAP.get(ticketType)?.BADGE ?? { icon: '', iconColor: '', textStyle: '' }
}

/**
 * 情報元種別に紐づくラベルを返す
 * @param {InformationSourceType} informationSourceType - 種別（例：1, 2, 3, 4, 5, 6, 7, 8, 99）
 * @returns {string} 情報元種別に紐づくラベル（例： 1:"アイデア投稿", 2:"相談・連絡", 3:"フロント", 4:"LM", 5:"GCC", 6:"sm@haseko", 7:"管理者業務執行者宛メール受信", 8:"HP", 99:"そのほか（自由入力）"）
 */
export function getInformationSourceLabel(informationSourceType:InformationSourceType): string {
  return INFORMATION_SOURCE_TYPE_MAP.get(informationSourceType)?.LABEL ?? ''
}

/**
 * 案件種別に紐づくラベルを返す
 * @param {CaseType} caseType - 種別（例：1, 2, 3, 4）
 * @returns {string} 案件種別に紐づくラベル（例： 1:"顧客対応（アイデア）", 2:"顧客対応（相談・連絡）", 3:"判断対応", 4:"規約に基づく申請受付"）
 */
export function getCaseLabel(caseType:CaseType): string {
  return CASE_TYPE_MAP.get(caseType)?.LABEL ?? ''
}

/**
 * 地域種別に紐づくラベルを返す
 * @param {AreaType} areaType - 種別（例：1, 2, 3, 4, 5）
 * @returns {string} 地域種別に紐づくラベル（例：1:"HCM東京", 2:"HCM関西", 3:"CMQ", 4:"CMW", 5:"CMO"）
 */
export function getAreaLabel(areaType:AreaType): string {
  return AREA_TYPE_MAP.get(areaType)?.LABEL ?? ''
}

/**
 * タスク種別に紐づくラベルを返す
 * @param {ticketTaskType} ticketTaskType - タスク種別（例：10, 11）
 * @return {string} ステータス用のラベル（例："アイデア投稿で自動生成" , "手動で作成"）
 */
export function getTicketTaskTypeLabel(ticketTaskType:TicketTaskType): string {
  return TICKET_TASK_TYPES_MAP.get(ticketTaskType)?.LABEL ?? ''
}

/**
 * タスク種別に基づき「自動でタスクが完了するか」を返す
 * @param {ticketTaskType} ticketTaskType - ステータス（例：11, 12）
 * @return {boolean} 自動でタスクが完了する場合true
 */
export function isTaskAutoComplete(ticketTaskType:TicketTaskType):boolean {
  return TICKET_TASK_TYPE_IS_AUTO_COMPLETE_MAP.get(ticketTaskType)?.TASK_AUTO_COMPLETE ?? false
}

/**
 * タスクタイトル種別に紐づくラベルを返す
 * @param {ticketTaskNameType} ticketTaskNameType - タスクタイトル種別（例：10, 11）
 * @return {string} ステータス用のラベル（例："アイデア投稿で自動生成" , "手動で作成"）
 */
export function getTicketTaskNameTypeLabel(ticketTaskNameType:TicketTaskNameType): string {
  return TICKET_TASK_NAME_TYPES_MAP.get(ticketTaskNameType)?.LABEL ?? ''
}

/**
 * タスクタイトル種別に紐づきタスクタイトルが手動で変更可能かを返す
 * @param {ticketTaskNameType} ticketTaskNameType - タスクタイトル種別（例：10, 11）
 * @return {boolean} タスクタイトルが手動で変更可能な場合true
 */
export function canEditTicketTaskName(ticketTaskNameType:TicketTaskNameType): boolean {
  return TICKET_TASK_NAME_TYPES_MAP.get(ticketTaskNameType)?.CAN_EDIT_TICKET_TASK_NAME ?? false
}

/**
 * 区分所有者への対応タスクを返す
 * @param {boolean} isOwnerResponseTasks 区分所有者への対応タスクか否か
 * @return {TicketTaskType} 区分所有者への対応タスク
 */
export function isOwnerResponseTasks(isOwnerResponseTasks:boolean):TicketTaskType[] {
  return TICKET_TASK_TYPE_IS_RESPONSE_OWNER.get(isOwnerResponseTasks) ?? []
}

// 長計関連
/**
 * 長期修繕計画種類に紐づくラベルを返す
 * @param {RepairPlanType} repairPlanType - 長期修繕計画種類（例：1, 2）
 * @return {string} 長計用のラベル（例："リアルタイム長計" , "簡易版リアルタイム長計"）
 */
export function getRepairPlanTypeLabel(repairPlanType:RepairPlanType): string {
  return REPAIR_PLAN_TYPES_MAP.get(repairPlanType)?.LABEL ?? ''
}

/**
  * アンケート種別に紐づくバッジの情報を返す
 * @param {QuestionnaireType} questionnaireType - 種別
 * @returns {string, string, string} アンケート種別に紐づくバッジの情報
 */
export function getQuestionnaireBadge(questionnaireType: QuestionnaireType): {icon: string, iconColor: string, textStyle: string} {
  return QUESTIONNAIRE_TYPE_MAP.get(questionnaireType)?.BADGE ?? { icon: '', iconColor: '', textStyle: '' }
}

/**
 * テンプレート種別に紐づくバッジの情報を返す
 * @param {TemplateType} templateType - 種別
 * @returns {string, string, string} テンプレート種別に紐づくバッジの情報
 */
export function getQuestionnaireTemplateBadge(templateType: TemplateType): {icon: string, iconColor: string, textStyle: string} {
  return TEMPLATE_TYPE_MAP.get(templateType)?.BADGE ?? { icon: '', iconColor: '', textStyle: '' }
}

/**
 * アンケート設問種別に紐づくラベルを返す
 * @param {QuestionnaireQuestionType} questionnaireQuestionType
 * @return {string} アンケート設問構成要素種別に紐づくラベル
 */
export function getQuestionnaireQuestionTypeLabel(questionnaireQuestionType: QuestionnaireQuestionType): string {
  return QUESTIONNAIRE_QUESTION_TYPE_MAP.get(questionnaireQuestionType)?.LABEL ?? ''
}

/**
 * アンケート設問種別に紐づく選択肢のアイコン名を返す
 * @param {QuestionnaireQuestionType} questionnaireQuestionType
 * @return {string} アンケート設問種別に紐づく選択肢のアイコン名
 */
export function getQuestionnaireQuestionTypeOptionIconName(questionnaireQuestionType: QuestionnaireQuestionType): string {
  return QUESTIONNAIRE_QUESTION_TYPE_MAP.get(questionnaireQuestionType)?.optionIconName ?? ''
}

/**
 * 初回認証コード種別に紐づくラベルを返す
 * @param {InitialAuthCodeType} initialAuthCodeType
 * @return {string} 初回認証コード種別に紐づくラベル
 */
export function getInitialAuthCodeTypeLabel(initialAuthCodeType: InitialAuthCodeType): string {
  return INITIAL_AUTH_CODE_TYPE_MAP.get(initialAuthCodeType)?.LABEL ?? ''
}
