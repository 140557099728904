import Vue from 'vue'
import { UnitDetailGetRequest, UnitDetailGetResponse } from '@/dtos/units/get-detail'
import { unitsClient } from '@/clients/units-client'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { UnitsGetRequest, UnitsGetResponse } from '@/dtos/units/get'
import { UnitOwnerSimulateTokenGetRequest, UnitOwnerSimulateTokenGetResponse } from '@/dtos/units/simulate-token/get'
import { UnitsPutRequest, UnitsChangePutRequest } from '@/dtos/units/put'

@Module({ store, dynamic: true, namespaced: true, name: 'units' })
class UnitsStore extends VuexModule {
  // 区分所有者一覧
  private _unitsGet = new UnitsGetResponse()

  public get unitsGet():UnitsGetResponse { return this._unitsGet }

  @Mutation
  private SET_UNITS_GET(res:UnitsGetResponse) { this._unitsGet = res }

  @Action
  public async fetchUnits(req:UnitsGetRequest) {
    const res = await unitsClient.getUnits(req)
    this.SET_UNITS_GET(res)
  }

  @Action
  public async downloadUnitsCsv(req: UnitsGetRequest) {
    await unitsClient.downloadUnitsCsv(req)
  }

  // 区分所有者詳細
  private _unitDetails:Record<string, UnitDetailGetResponse> = {}

  public get unitDetail():(id:string) => UnitDetailGetResponse | undefined {
    return (id:string) => this._unitDetails[id]
  }

  @Mutation
  private SET_UNIT_DETAIL(res:UnitDetailGetResponse):void { Vue.set(this._unitDetails, res.unitId, res) }

  @Action
  public async fetchUnitDetail(req:UnitDetailGetRequest):Promise<void> {
    const res = await unitsClient.getUnitDetail(req)
    this.SET_UNIT_DETAIL(res)
  }

  // 区分所有者アプリログイン用トークン取得
  private _simulateTokenGet = new UnitOwnerSimulateTokenGetResponse()

  public get simulateTokenGet(): UnitOwnerSimulateTokenGetResponse { return this._simulateTokenGet }

  @Mutation
  private SET_SIMULATE_TOKEN_GET(res: UnitOwnerSimulateTokenGetResponse): void {
    this._simulateTokenGet = res
  }

  @Action
  async fetchSimulateToken(req: UnitOwnerSimulateTokenGetRequest): Promise<void> {
    const res = await unitsClient.getSimulateToken(req)
    this.SET_SIMULATE_TOKEN_GET(res)
  }

  // 区分所有者詳細更新
  @Action
  public async putUnits(req:UnitsPutRequest):Promise<void> {
    await unitsClient.putUnitDetail(req)
  }

  @Action
  public async putUnitsChange(req:UnitsChangePutRequest):Promise<void> {
    await unitsClient.putUnitDetailChange(req)
  }
}

export const unitsModule = getModule(UnitsStore)
