/**
   * 1つ以上の対象が追加される場合にアルファベットを付与する
   * 対象が1つの場合は、アルファベットを付与せずに返却する
   */
export function concatSerialChar(length:number, index: number): string {
  if (length === 1) return ''
  return convertIndexIntoSerialChar(index)
}

/**
   * 各セクションタイトルにA～Zを付与するためPlanのインデックスをもとにアルファベットを生成
   * fromCharCodeの引数がUTF-16コードのため、65以上の値を渡すようにしている
   * 例：65→A、90→Z
   */
export function convertIndexIntoSerialChar(index: number): string {
  return String.fromCharCode(index + 65)
}
