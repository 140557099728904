/**
 * 主に複数箇所から参照のあるデザイン関連の定数値を定義する
 *
 * usage:
 *  import { STYLES } from '@/constants/styles'
 *  ...
 *  STYLES = Object.freeze(STYLES) // to be non-reactive
 */

export const STYLES = {
  SYSTEM_BAR_HEIGHT: 48,
  APP_BAR_HEIGHT: 48,
  APP_BAR_EXTENSION_HEIGHT: 48,
  SYSTEM_BAR_PREVIEW_HEIGHT: 126,
} as const
