import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { TasksSearchPostResponse, ListResponseTaskDto, TasksSearchPostRequest } from '@/dtos/tasks/search/post'
import { tasksClient } from '@/clients/tasks-client'
import { TaskPutRequest, TaskPutResponse } from '@/dtos/tasks/put'
import { TaskStatePutRequest, TaskStatePutResponse } from '@/dtos/tasks/state/put'
import { TaskPostRequest, TaskPostResponse } from '@/dtos/tasks/post'

@Module({ store, dynamic: true, namespaced: true, name: 'task' })
class TasksStore extends VuexModule {
  // タスク一覧取得 ※検索条件の上限撤廃のため、getではなくpostを使用
  private _tasks:ListResponseTaskDto[] = []
  private _deadlineTasks:ListResponseTaskDto[] = []
  private _responseOwnerTasks:ListResponseTaskDto[] = []
  private _personalDeadlineTasks:ListResponseTaskDto[] = []
  private _personalTasks:ListResponseTaskDto[] = []
  private _skipToken: string | null = null

  get tasks(): ListResponseTaskDto[] {
    return this._tasks
  }

  get deadlineTasks(): ListResponseTaskDto[] {
    return this._deadlineTasks
  }

  get responseOwnerTasks(): ListResponseTaskDto[] {
    return this._responseOwnerTasks
  }

  get skipToken(): string | null {
    return this._skipToken
  }

  get personalDeadlineTasks(): ListResponseTaskDto[] {
    return this._personalDeadlineTasks
  }

  get personalTasks(): ListResponseTaskDto[] {
    return this._personalTasks
  }

  @Mutation
  private PUSH_POST_TASKS_RESPONSE(res: TasksSearchPostResponse): void {
    this._tasks.push(...res.tasks)
    this._skipToken = res.skipToken
  }

  @Mutation
  private SET_POST_DEADLINE_TASKS_RESPONSE(res: TasksSearchPostResponse): void {
    this._deadlineTasks = res.tasks
  }

  @Mutation
  private SET_POST_RESPONSE_OWNER_TASKS_RESPONSE(res: TasksSearchPostResponse): void {
    this._responseOwnerTasks = res.tasks
  }

  @Mutation
  private SET_POST_PARSONAL_TASKS_RESPONSE(res: TasksSearchPostResponse): void {
    this._personalTasks = res.tasks
  }

  @Mutation
  private SET_POST_PERSONAL_DEADLINE_TASKS_RESPONSE(res: TasksSearchPostResponse): void {
    this._personalDeadlineTasks = res.tasks
  }

  @Mutation
  private CLEAR_TASKS(): void {
    this._tasks = []
    this._skipToken = null
  }

  @Action
  async fetchTasks(req: TasksSearchPostRequest): Promise<void> {
    const res = await tasksClient.postSearchTasks(req)
    if (!res) this.PUSH_POST_TASKS_RESPONSE(new TasksSearchPostResponse())
    else this.PUSH_POST_TASKS_RESPONSE(res)
  }

  @Action
  async fetchDashboardDeadlineTasks(req: TasksSearchPostRequest): Promise<void> {
    const res = await tasksClient.postSearchTasks(req)
    if (res) this.SET_POST_DEADLINE_TASKS_RESPONSE(res)
  }

  @Action
  async fetchDashboardResponseOwnerTasks(req: TasksSearchPostRequest): Promise<void> {
    const res = await tasksClient.postSearchTasks(req)
    if (res) this.SET_POST_RESPONSE_OWNER_TASKS_RESPONSE(res)
  }

  @Action
  async fetchDashboardPersonalTasks(req: TasksSearchPostRequest): Promise<void> {
    const res = await tasksClient.postSearchTasks(req)
    if (res) this.SET_POST_PARSONAL_TASKS_RESPONSE(res)
  }

  @Action
  async fetchDashboardPersonalDeadlineTasks(req: TasksSearchPostRequest): Promise<void> {
    const res = await tasksClient.postSearchTasks(req)
    if (res) this.SET_POST_PERSONAL_DEADLINE_TASKS_RESPONSE(res)
  }

  @Action
  clearFetchedTask(): void {
    this.CLEAR_TASKS()
  }

  // タスク登録
  private _postTaskResponse: TaskPostResponse = new TaskPostResponse()
  get postTaskResponse(): TaskPostResponse | undefined { return this._postTaskResponse }

  @Mutation
  private SET_POST_TASK_RESPONSE(res: TaskPostResponse): void { this._postTaskResponse = res }

  @Action
  async postTasks(req: TaskPostRequest): Promise<void> {
    const res = await tasksClient.postTask(req)

    if (res) this.SET_POST_TASK_RESPONSE(res)
  }

  // タスク更新
  private _putTaskResponses: Record<string, TaskPutResponse> = {}
  get putTaskResponse(): (ticketTaskId: string) => TaskPutResponse | undefined { return (ticketTaskId: string) => this._putTaskResponses[ticketTaskId] }

  @Mutation
  private SET_PUT_TASK_RESPONSE({ ticketTaskId, res }: { ticketTaskId: string, res: TaskPutResponse }) { Vue.set(this._putTaskResponses, ticketTaskId, res) }

  @Action
  async putTasks(req: TaskPutRequest) {
    const res = await tasksClient.putTask(req)

    if (res) this.SET_PUT_TASK_RESPONSE({ ticketTaskId: req.ticketTaskId, res })
  }

  // タスク状態更新
  private _putTaskStateResponses: Record<string, TaskStatePutResponse> = {}
  get putTaskStateResponse(): (ticketTaskId: string) => TaskStatePutResponse | undefined { return (ticketTaskId: string) => this._putTaskStateResponses[ticketTaskId] }

  @Mutation
  private SET_PUT_TASK_STATE_RESPONSE({ ticketTaskId, res }: { ticketTaskId: string, res: TaskStatePutResponse }) { Vue.set(this._putTaskStateResponses, ticketTaskId, res) }

  @Action
  async putTaskStates(req: TaskStatePutRequest): Promise<void> {
    const res = await tasksClient.putTaskStates(req)

    if (res) this.SET_PUT_TASK_STATE_RESPONSE({ ticketTaskId: req.ticketTaskId, res })
  }
}

export const tasksModule = getModule(TasksStore)
