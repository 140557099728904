




































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { NEW_TAB_TRANSITION_TO, QUESTIONNAIRE_STATES, SECTION_TYPES } from '@/constants/schema-constants'
import { PAGE_TYPES_STR } from '@/constants/ux-constants'
import { QuestionnaireDetailGetRequest, QuestionnaireDetailGetResponse, QuestionnaireSection } from '@/dtos/questionnaires/get'
import { convertIndexIntoSerialChar } from '@/libs/add-alphabet-provider'
import { openNewTab } from '@/libs/open-new-tab'
import { NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { questionnairesModule } from '@/stores/questionnaires-store'
import { staticRoutes } from '@/routes'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmQuestion: () => import('@/components/molecules/SmQuestion.vue'),
  }
})
export default class DetailSubPage extends Vue {
  QUESTIONNAIRE_STATES = Object.freeze(QUESTIONNAIRE_STATES)

  @Prop({ required: true, default: '' })
  private readonly questionnaireId!: string

  async created(): Promise<void> {
    await questionnairesModule.fetchQuestionnaireDetail(new QuestionnaireDetailGetRequest(this.questionnaireId))
  }

  private get questionnaire(): QuestionnaireDetailGetResponse {
    return questionnairesModule.questionnaireDetail(this.questionnaireId) ?? new QuestionnaireDetailGetResponse()
  }

  private get questionSections(): QuestionnaireSection[] {
    return this.questionnaire.questionnaireSections.filter(s => s.questionnaireSectionType === SECTION_TYPES.QUESTION)
  }

  private get optionSection(): QuestionnaireSection | undefined {
    return this.questionnaire.questionnaireSections.find(s => s.questionnaireSectionType === SECTION_TYPES.OPINION) ?? undefined
  }

  private _getSectionName(idx: number): string { return `回答セクション${convertIndexIntoSerialChar(idx)}` }

  private onClickEdit(): void {
    this.$router.push({ name: staticRoutes.questionnaireCreate.name, query: { questionnaireId: this.questionnaireId, templateId: this.questionnaire.templateId, pageType: PAGE_TYPES_STR.EDIT } })
  }

  private onClickDuplicate(): void {
    this.$router.push({ name: staticRoutes.questionnaireCreate.name, query: { questionnaireId: this.questionnaireId, templateId: this.questionnaire.templateId, pageType: PAGE_TYPES_STR.CREATE } })
  }

  private onClickPrintPdf(): void {
    this.$router.push({ name: staticRoutes.questionnaireDetailPrint.name, query: { questionnaireId: this.questionnaireId } })
  }

  private onClickBuildingBtn(): void {
    // 該当のマンション詳細画面を別タブで開く
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = this.questionnaire.building.buildingId
    openNewTab(newTabTransitionParams)
  }
}
