
































































































import { Component, Vue, Model } from 'vue-property-decorator'

export class LmStaffFormInputs {
  employeeId = ''
  adminName = ''
  receiverName = ''
  phoneNumber = ''
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class LmStaffPostModal extends Vue {
  @Model('change')
  readonly isVisible!: boolean

  get isModalVisible(): boolean { return this.isVisible }
  set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  inputs = new LmStaffFormInputs()

  postStaff():void {
    this.$emit('post', this.inputs)
  }
}
