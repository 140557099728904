









import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { RESOLUTION_TYPES } from '@/constants/schema-constants'
import { errorsModule } from '@/stores/errors'
import { structureModule } from '@/stores/structure-store'

@Component({
  components: {
    QuestionsSubPageBase: () => import('@/pages/resolutions/QuestionsSubPageBase.vue')
  }
})
export default class GmQuestionsSubPage extends Vue {
  @Prop({ required: true })
  private readonly resolutionId!:string

  @Prop()
  private readonly buildingId?: string

  RESOLUTION_TYPES = Object.freeze(RESOLUTION_TYPES)

  private get isPreview(): boolean { return structureModule.isPreview }

  private get hasError(): string[] { return errorsModule.globalErrors }

  @Watch('hasError')
  scrollTop(hasError: string[]): void {
    if (!hasError.length) return
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }
}
