









































































































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { ADMIN_ROLE, MANAGEMENT_OPERATION_TYPES, NEW_TAB_TRANSITION_TO, OPERATION_STATES } from '@/constants/schema-constants'
import type { ManagementOperationTypes } from '@/constants/schema-constants'
import { generateUuid } from '@/libs/uuid-generator'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { openNewTab } from '@/libs/open-new-tab'

import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { User } from '@/dtos/commons'
import { ManagementOperationsSearchCommonPostResponseExecutor, ManagementOperationsSearchCommonPostRequest, ManagementOperationsTaskProgress } from '@/dtos/management-operations/common/search/post'
import { ManagementOperationsOnboarding, ManagementOperationsOnboardingSearchPostRequest } from '@/dtos/management-operations/onboarding/search/post'
import { ManagementOperationsRoutine, ManagementOperationsRoutineSearchPostRequest } from '@/dtos/management-operations/routine/search/post'
import { ManagementOperationsTaskStatusPutRequest, ManagementOperationsTaskStatusPutRequestExecutor } from '@/dtos/management-operations/task-status/put'

import { buildingsModule } from '@/stores/buildings-store'
import { managementOperationsModule } from '@/stores/management-operations-store'
import { NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { myProfileModule } from '@/stores/my-profile-store'

import { Header } from '@/components/molecules/SmTableDataExternalPaging.vue'
import { FilterLabel } from '@/components/molecules/SmExpansionFilterMenu.vue'
import { ManagementOperationsEditItem } from '@/pages/tasks/onboardingAndRoutine/SmDialogManagementOperationEdit.vue'
import { errorsModule } from '@/stores/errors'

const STORAGE_KEY_ONBOARDING = staticRoutes.tasks.getChild('onboarding').name
const STORAGE_KEY_ROUTINE = staticRoutes.tasks.getChild('routine').name

const BUILDING_MENU_ITEMS_FOR_TASKS: {[id: string]: { text: string, label: string }} = {
  all: {
    text: 'すべてのマンション',
    label: '変更する'
  },
  each: {
    text: '個別に選択する',
    label: '変更する'
  },
}

export const BUILDING_MENU_TYPES_FOR_TASKS = {
  ALL: 'all',
  EACH: 'each',
} as const
export type BuildingMenuTypesForTasks = typeof BUILDING_MENU_TYPES_FOR_TASKS[keyof typeof BUILDING_MENU_TYPES_FOR_TASKS]

const ADMIN_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  all: {
    text: 'すべての担当者（担当者未設定を含む）',
    label: '変更する'
  },
  each: {
    text: '個別に選択する',
    label: '変更する'
  },
}

export const ADMIN_MENU_TYPES = {
  ALL: 'all',
  EACH: 'each',
}as const
export type StaffMenuTypes = typeof ADMIN_MENU_TYPES[keyof typeof ADMIN_MENU_TYPES]

export class ManagementOperationInputParams {
  buildings: string[] = []
  admins: User[] = []
  buildingMenuType: BuildingMenuTypesForTasks = 'all'
  staffMenuTypes: StaffMenuTypes = 'all'
  showOnlyUnresolvedTasks = true
  showTasksBeyondTargetMonth = false
  smIntroducedYear?: number
  smIntroducedMonth?: number
  accountingMonth?: number
}

const TAKE = 20

const HEADERS: Header[] = [
  new Header({ text: 'MaNo.', value: 'condominiumCd' }),
  new Header({ text: 'マンション名', value: 'buildingName' }),
  new Header({ text: '担当', value: 'adminShortName' }),
  new Header({ text: '', value: 'labels' }),
]

export class ManagementOperationsOnboardingTaskTableItem extends ManagementOperationsOnboarding {
  labels : string[] = ['実施者', '目安', 'ステータス', 'メモ']
}
export class ManagementOperationsRoutineTaskTableItem extends ManagementOperationsRoutine {
  labels : string[] = ['実施者', '目安', 'ステータス', 'メモ']
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),

    SmExpansionFilterMenu: () => import('@/components/molecules/SmExpansionFilterMenu.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTableDataExternalPaging: () => import('@/components/molecules/SmTableDataExternalPaging.vue'),

    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),
    ManagementOperationTaskStatusArea: () => import('@/components/organisms/ManagementOperationTaskStatusArea.vue'),
    StaffSelectModal: () => import('@/components/organisms/modal/StaffSelectModal.vue'),

    SmDialogManagementOperationEdit: () => import('@/pages/tasks/onboardingAndRoutine/SmDialogManagementOperationEdit.vue'),
    OnboardingHeader: () => import('@/pages/tasks/onboardingAndRoutine/OnboardingHeader.vue'),
    RoutineHeader: () => import('@/pages/tasks/onboardingAndRoutine/RoutineHeader.vue'),
  }
})

export default class ManagementOperationSubPage extends Vue {
  MANAGEMENT_OPERATION_TYPES = Object.freeze(MANAGEMENT_OPERATION_TYPES)
  ADMIN_MENU_ITEMS = Object.freeze(ADMIN_MENU_ITEMS)
  BUILDING_MENU_ITEMS_FOR_TASKS = Object.freeze(BUILDING_MENU_ITEMS_FOR_TASKS)
  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)
  OPERATION_STATES = Object.freeze(OPERATION_STATES)

  selectedBuildingMenuType: BuildingMenuTypesForTasks = BUILDING_MENU_TYPES_FOR_TASKS.ALL
  selectedBuildings: Building[] = []

  selectedStaffMenuType: StaffMenuTypes = ADMIN_MENU_TYPES.ALL
  selectedStaffs: User[] = []

  isFilterMenuOpen = true

  // ページ遷移時にrouterからpropsを受け取る
  @Prop({ required: true, default: MANAGEMENT_OPERATION_TYPES.ONBOARDING })
  operationType!: ManagementOperationTypes

  // 稼働準備⇔定例業務のタブ切り替えを検知する
  @Watch('operationType')
  async switchTab(): Promise<void> {
    await this.initialize()
  }

  async created(): Promise<void> {
    await this.initialize()
  }

  private async initialize(): Promise<void> {
    // 初期検索パラメーターの設定（以前の検索条件が残っている場合、それを復元して反映）
    const storedOnboarding = paramStorageModule.savedParam(STORAGE_KEY_ONBOARDING, ManagementOperationInputParams)
    const storedRoutine = paramStorageModule.savedParam(STORAGE_KEY_ROUTINE, ManagementOperationInputParams)

    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999))

    switch (this.operationType) {
      case MANAGEMENT_OPERATION_TYPES.ONBOARDING:
        this.onboardingSearchPostRequestParam = new ManagementOperationsOnboardingSearchPostRequest(true, false, 0, TAKE)
        if (storedOnboarding) this.inputParams = storedOnboarding
        if (!storedOnboarding) {
          this.inputParams = new ManagementOperationInputParams()
          this.selectedBuildings = []
          this.selectedStaffs = []
          this.selectedBuildingMenuType = BUILDING_MENU_TYPES_FOR_TASKS.ALL
          this.selectedStaffMenuType = ADMIN_MENU_TYPES.ALL
        }
        if (this.inputParams.smIntroducedYear) {
          this.onboardingSearchPostRequestParam.smIntroducedYear = this.inputParams.smIntroducedYear
          this.smIntroducedYear = this.inputParams.smIntroducedYear
        }
        if (this.inputParams.smIntroducedMonth) {
          this.onboardingSearchPostRequestParam.smIntroducedMonth = this.inputParams.smIntroducedMonth
          this.smIntroducedMonth = this.inputParams.smIntroducedMonth
        }
        break
      case MANAGEMENT_OPERATION_TYPES.ROUTINE:
        this.routineSearchPostRequestParam = new ManagementOperationsRoutineSearchPostRequest(true, false, 0, TAKE)
        if (storedRoutine) this.inputParams = storedRoutine
        if (!storedRoutine) {
          this.inputParams = new ManagementOperationInputParams()
          this.selectedBuildings = []
          this.selectedStaffs = []
          this.selectedBuildingMenuType = BUILDING_MENU_TYPES_FOR_TASKS.ALL
          this.selectedStaffMenuType = ADMIN_MENU_TYPES.ALL
        }
        if (this.inputParams.accountingMonth) {
          this.routineSearchPostRequestParam.accountingMonth = this.inputParams.accountingMonth
          this.accountingMonth = this.inputParams.accountingMonth
        }
        break
      default: assertExhaustive(this.operationType)
    }

    this.searchCommonPostRequestParam = new ManagementOperationsSearchCommonPostRequest(true, false, 0, TAKE)

    if (this.inputParams.buildingMenuType) this.selectedBuildingMenuType = this.inputParams.buildingMenuType
    if (this.inputParams.buildings.length) {
      this.searchCommonPostRequestParam.buildings = this.inputParams.buildings
      this.selectedBuildings = this.getTargetBuildings(this.inputParams.buildings ?? [])
    } else this.selectedBuildings = []

    if (this.inputParams.staffMenuTypes) this.selectedStaffMenuType = this.inputParams.staffMenuTypes
    if (this.inputParams.admins.length) {
      this.searchCommonPostRequestParam.admins = this.inputParams.admins.map(a => a.userId)
      this.selectedStaffs = this.inputParams.admins
    } else this.selectedStaffs = []
    this.searchCommonPostRequestParam.showOnlyUnresolvedTasks = this.inputParams.showOnlyUnresolvedTasks
    this.searchCommonPostRequestParam.showTasksBeyondTargetMonth = this.inputParams.showTasksBeyondTargetMonth

    this.pageNum = 1
    await this.search()
    this.makeTableBase()

    // 稼働準備と定例業務で共通利用しているモーダルの検索キーワードを初期化する
    this.buildingInputText = ''
    this.buildingKeyword = ''
    this.staffInputText = ''
    this.staffKeyword = ''
  }

  // --------------- リクエストパラメータと、そのもととなる画面上の入力項目 ---------------
  onboardingSearchPostRequestParam = new ManagementOperationsOnboardingSearchPostRequest(true, false, 0, TAKE)
  routineSearchPostRequestParam = new ManagementOperationsRoutineSearchPostRequest(true, false, 0, TAKE)
  searchCommonPostRequestParam = new ManagementOperationsSearchCommonPostRequest(true, false, 0, TAKE)
  inputParams = new ManagementOperationInputParams()

  // --------------- データの読み込み ---------------

  isWaitingSwitch = false

  private async search(): Promise<void> {
    this.isWaitingSwitch = true

    switch (this.operationType) {
      case MANAGEMENT_OPERATION_TYPES.ONBOARDING:
        paramStorageModule.save({ key: STORAGE_KEY_ONBOARDING, params: { ...this.inputParams } })
        await managementOperationsModule.fetchManagementOperationsOnboarding(this.toOnboardingSearchPostRequest())
        break
      case MANAGEMENT_OPERATION_TYPES.ROUTINE:
        paramStorageModule.save({ key: STORAGE_KEY_ROUTINE, params: { ...this.inputParams } })
        await managementOperationsModule.fetchManagementOperationsRoutine(this.toRoutineSearchPostRequest())
        break
      default: assertExhaustive(this.operationType)
    }

    this.isWaitingSwitch = false
    this.resetScrollPosition()
  }

  staffInputText = ''
  staffKeyword = ''

  pageNum = 1

  private toOnboardingSearchPostRequest(): ManagementOperationsOnboardingSearchPostRequest {
    const skip = TAKE * (this.pageNum - 1)
    const reqOnboarding = new ManagementOperationsOnboardingSearchPostRequest(false, false, skip, TAKE)

    reqOnboarding.buildings = this.searchCommonPostRequestParam?.buildings ?? undefined
    reqOnboarding.admins = this.searchCommonPostRequestParam?.admins ?? undefined
    reqOnboarding.showOnlyUnresolvedTasks = this.searchCommonPostRequestParam?.showOnlyUnresolvedTasks ?? false
    reqOnboarding.showTasksBeyondTargetMonth = this.searchCommonPostRequestParam?.showTasksBeyondTargetMonth ?? false
    reqOnboarding.smIntroducedMonth = this.onboardingSearchPostRequestParam?.smIntroducedMonth ?? undefined
    reqOnboarding.smIntroducedYear = this.onboardingSearchPostRequestParam?.smIntroducedYear ?? undefined

    return reqOnboarding
  }

  private toRoutineSearchPostRequest(): ManagementOperationsRoutineSearchPostRequest {
    const skip = TAKE * (this.pageNum - 1)
    const reqRoutine = new ManagementOperationsRoutineSearchPostRequest(false, false, skip, TAKE)

    reqRoutine.buildings = this.searchCommonPostRequestParam?.buildings ?? undefined
    reqRoutine.admins = this.searchCommonPostRequestParam?.admins ?? undefined
    reqRoutine.showOnlyUnresolvedTasks = this.searchCommonPostRequestParam?.showOnlyUnresolvedTasks ?? false
    reqRoutine.showTasksBeyondTargetMonth = this.searchCommonPostRequestParam?.showTasksBeyondTargetMonth ?? false
    reqRoutine.accountingMonth = this.routineSearchPostRequestParam?.accountingMonth ?? undefined

    return reqRoutine
  }

  // -------------- 画面描画用のデータ ---------------
  isFilterExpansionOpen = true

  // 絞り込みメニューのラベル名（各slotのname属性ごとにラベルを用意する）
  fixFilterLabels = {
    condition1: new FilterLabel('マンション', 'center', '160px'),
    condition2: new FilterLabel('担当者', 'center', '160px'),
  }

  private get filterLabels(): Record<string, FilterLabel> {
    let addFilterLabels
    switch (this.operationType) {
      case MANAGEMENT_OPERATION_TYPES.ONBOARDING:
        addFilterLabels = { condition3: new FilterLabel('サービス提供開始年月', 'center', '160px') }
        break
      case MANAGEMENT_OPERATION_TYPES.ROUTINE:
        addFilterLabels = { condition3: new FilterLabel('決算月', 'center', '160px') }
        break
      default: assertExhaustive(this.operationType)
    }
    return {
      ...this.fixFilterLabels,
      ...addFilterLabels
    }
  }

  private get selectedBuildingDisplay(): string {
    if (this.selectedBuildings.length) return this.selectedBuildings.map(e => e.buildingName).join(' | ')
    return 'すべてのマンション'
  }

  private getTargetBuildings(buildingIds: string[]): Building[] {
    return buildingsModule.buildingsGet.buildings.filter(e => buildingIds.includes(e.buildingId))
  }

  private get selectedStaffDisplay(): string {
    if (this.selectedStaffs.length) return this.selectedStaffs.map(e => e.userName).join('、')
    return 'すべての担当者（担当者未設定を含む）'
  }

  private smIntroducedMonth = 0
  private accountingMonth = 0
  private get months(): { label: string, value: number }[] {
    const allMonths = Array.from({ length: 12 }).map((_, index) => { return { label: `${index + 1}`, value: index + 1 } })
    allMonths.unshift({ label: 'すべて', value: 0 })
    return allMonths
  }

  private get smIntroducedYearList(): number[] {
    return managementOperationsModule.smIntroducedYearList
  }

  private smIntroducedYear = 0
  private get years(): { label: string, value: number }[] {
    const years = Array.from(this.smIntroducedYearList).map((siy) => { return { label: `${siy}`, value: siy } })
    years.unshift({ label: 'すべて', value: 0 })
    return years
  }

  private get managementOperationCount(): number {
    switch (this.operationType) {
      case MANAGEMENT_OPERATION_TYPES.ONBOARDING:
        return managementOperationsModule.managementOperationOnboardingCount ?? 0
      case MANAGEMENT_OPERATION_TYPES.ROUTINE:
        return managementOperationsModule.managementOperationRoutineCount ?? 0
      default: assertExhaustive(this.operationType)
    }
    return 0 // unexpected
  }

  // --------------- ボタンなどを押下した際の処理 ---------------

  private selectBuildingMenu(): void {
    switch (this.selectedBuildingMenuType) {
      case BUILDING_MENU_TYPES_FOR_TASKS.ALL:
        this.changeBuilding([])
        break
      case BUILDING_MENU_TYPES_FOR_TASKS.EACH:
        this.openSelectBuildingModal()
        break
      default: assertExhaustive(this.selectedBuildingMenuType)
    }
  }

  private async changeBuilding(buildingIds:string[]): Promise<void> {
    // 個別マンション選択ダイアログで選択されたマンションを保持する（それ以外の場合はクリア）
    if (this.selectedBuildingMenuType === BUILDING_MENU_TYPES_FOR_TASKS.EACH) {
      this.selectedBuildings = this.getTargetBuildings(buildingIds)
      if (!this.searchCommonPostRequestParam?.buildings) return
      this.searchCommonPostRequestParam.buildings = buildingIds

      this.inputParams.buildings = buildingIds
      this.inputParams.buildingMenuType = BUILDING_MENU_TYPES_FOR_TASKS.EACH
    }
    if (this.selectedBuildingMenuType === BUILDING_MENU_TYPES_FOR_TASKS.ALL) {
      this.selectedBuildings = []
      this.inputParams.buildings = []
      this.searchCommonPostRequestParam.buildings = []
      this.inputParams.buildingMenuType = BUILDING_MENU_TYPES_FOR_TASKS.ALL
    }

    this.pageNum = 1
    await this.search()
  }

  // スイッチ:未対応のタスクがある行のみ
  private async switchOnlyUnresolvedTasks(): Promise<void> {
    this.searchCommonPostRequestParam.showOnlyUnresolvedTasks = this.inputParams.showOnlyUnresolvedTasks

    // 未対応のタスクがある行のみがOFFの場合、目安月を過ぎたタスクがある行のみもOFFにする
    if (!this.searchCommonPostRequestParam.showOnlyUnresolvedTasks) {
      this.inputParams.showTasksBeyondTargetMonth = false
      this.searchCommonPostRequestParam.showTasksBeyondTargetMonth = false
    }
    this.pageNum = 1
    await this.search()
  }

  // スイッチ:目安月を過ぎたタスクがある行のみ
  private async switchTasksBeyondTargetMonth(): Promise<void> {
    this.searchCommonPostRequestParam.showTasksBeyondTargetMonth = this.inputParams.showTasksBeyondTargetMonth
    this.pageNum = 1
    await this.search()
  }

  isBuildingModalVisible = false
  buildingSelectModalKey = generateUuid()

  private openSelectBuildingModal():void {
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingModalVisible = true
  }

  async selectStaffs(staffs:User[]): Promise<void> {
    this.selectedStaffs = staffs
    if (!this.searchCommonPostRequestParam) return
    this.searchCommonPostRequestParam.admins = this.selectedStaffs.map(s => s.userId)
    this.inputParams.admins = this.selectedStaffs
    this.inputParams.staffMenuTypes = this.selectedStaffMenuType
    this.pageNum = 1

    await this.search()
  }

  isStaff = true
  buildingKeyword = ''
  buildingInputText=''

  async selectStaffMenu(): Promise<void> {
    switch (this.selectedStaffMenuType) {
      case ADMIN_MENU_TYPES.ALL:
        this.inputParams.admins = []
        this.inputParams.staffMenuTypes = this.selectedStaffMenuType
        this.searchCommonPostRequestParam.admins = []
        this.selectedStaffs = []
        await this.search()
        break
      case ADMIN_MENU_TYPES.EACH:
        this.openStaffSelectModal()
        break
      default: assertExhaustive(this.selectedStaffMenuType)
    }
  }

  adminSelectModalKey = generateUuid()
  isAdminSelectModalVisible = false

  private openStaffSelectModal():void {
    this.adminSelectModalKey = generateUuid()
    this.isAdminSelectModalVisible = true
  }

  private openBuildingDetailPage(buildingId: string): void {
    // 該当のマンション詳細画面を別タブで開く
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId
    openNewTab(newTabTransitionParams)
  }

  private async onSearchSmIntroduceDateFilter(): Promise<void> {
    this.inputParams.smIntroducedYear = this.smIntroducedYear
    this.onboardingSearchPostRequestParam.smIntroducedYear = this.inputParams.smIntroducedYear === 0 ? undefined : this.smIntroducedYear

    this.inputParams.smIntroducedMonth = this.smIntroducedMonth
    this.inputParams.accountingMonth = undefined
    this.onboardingSearchPostRequestParam.smIntroducedMonth = this.inputParams.smIntroducedMonth === 0 ? undefined : this.smIntroducedMonth

    this.pageNum = 1
    await this.search()
  }

  async onSearchAccountingMonthFilter(): Promise<void> {
    this.inputParams.accountingMonth = this.accountingMonth
    this.routineSearchPostRequestParam.accountingMonth = this.inputParams.accountingMonth === 0 ? undefined : this.accountingMonth

    this.inputParams.smIntroducedYear = undefined
    this.inputParams.smIntroducedMonth = undefined

    this.pageNum = 1
    await this.search()
  }

  private onSelectThisMonthToSmIntroducedDate(): void {
    const currentDate: Date = new Date()
    const currentYear: number = currentDate.getFullYear()
    this.smIntroducedYear = currentYear

    const currentMonth: number = currentDate.getMonth()
    this.smIntroducedMonth = currentMonth + 1

    this.pageNum = 1
  }

  private onSelectThisMonthToAccountingMonth(): void {
    const currentDate: Date = new Date()

    const currentMonth: number = currentDate.getMonth()
    this.accountingMonth = currentMonth + 1

    this.pageNum = 1
  }

  // 絞り込みを行っているかを判定
  private isFiltering(): boolean {
    if (
      (this.searchCommonPostRequestParam.admins?.length ?? 0) > 0 ||
      (this.searchCommonPostRequestParam.buildings?.length ?? 0) > 0 ||
      (this.searchCommonPostRequestParam.showOnlyUnresolvedTasks) ||
      (this.searchCommonPostRequestParam.showTasksBeyondTargetMonth)
    ) { return true }
    switch (this.operationType) {
      case MANAGEMENT_OPERATION_TYPES.ONBOARDING:
        if (this.onboardingSearchPostRequestParam?.smIntroducedYear || this.onboardingSearchPostRequestParam?.smIntroducedMonth) return true
        break
      case MANAGEMENT_OPERATION_TYPES.ROUTINE:
        if (this.routineSearchPostRequestParam?.accountingMonth) return true
        break
      default: assertExhaustive(this.operationType)
    }
    return false
  }

  // ------------------------------- 定例業務 タスク一覧エリア-------------------------------------
  private get managementOperationsOnboarding(): ManagementOperationsOnboarding[] {
    return managementOperationsModule.managementOperationsOnboarding
  }

  private get managementOperationsRoutine(): ManagementOperationsRoutine[] {
    return managementOperationsModule.managementOperationsRoutine
  }

  private get managementOperationsCount(): number {
    switch (this.operationType) {
      case MANAGEMENT_OPERATION_TYPES.ONBOARDING:
        return managementOperationsModule.managementOperationsOnboarding.length
      case MANAGEMENT_OPERATION_TYPES.ROUTINE:
        return managementOperationsModule.managementOperationsRoutine.length
      default: assertExhaustive(this.operationType)
        return 0
    }
  }

  get taskTableAddLabelItems(): ManagementOperationsOnboardingTaskTableItem[] | ManagementOperationsRoutineTaskTableItem[] {
    const onboardingTaskTableItems: ManagementOperationsOnboardingTaskTableItem[] = []
    let onboardingTaskTableItem = new ManagementOperationsOnboardingTaskTableItem()

    const routineTaskTableItems: ManagementOperationsRoutineTaskTableItem[] = []
    let routineTaskTableItem = new ManagementOperationsRoutineTaskTableItem()

    switch (this.operationType) {
      case MANAGEMENT_OPERATION_TYPES.ONBOARDING:
        managementOperationsModule.managementOperationsOnboarding.forEach(mo => {
          onboardingTaskTableItem.smIntroducedDate = mo.smIntroducedDate
          onboardingTaskTableItem.condominiumCd = mo.condominiumCd
          onboardingTaskTableItem.buildingId = mo.buildingId
          onboardingTaskTableItem.buildingName = mo.buildingName
          onboardingTaskTableItem.isOutOfService = mo.isOutOfService
          onboardingTaskTableItem.adminShortName = mo.adminShortName
          onboardingTaskTableItem.managementOperationsTaskProgress = mo.managementOperationsTaskProgress
          onboardingTaskTableItems.push(onboardingTaskTableItem)
          onboardingTaskTableItem = new ManagementOperationsOnboardingTaskTableItem()
        })
        return onboardingTaskTableItems
      case MANAGEMENT_OPERATION_TYPES.ROUTINE:
        managementOperationsModule.managementOperationsRoutine.forEach(mr => {
          routineTaskTableItem.accountingMonth = mr.accountingMonth
          routineTaskTableItem.condominiumCd = mr.condominiumCd
          routineTaskTableItem.buildingId = mr.buildingId
          routineTaskTableItem.buildingName = mr.buildingName
          routineTaskTableItem.isOutOfService = mr.isOutOfService
          routineTaskTableItem.adminShortName = mr.adminShortName
          routineTaskTableItem.managementOperationsTaskProgress = mr.managementOperationsTaskProgress
          routineTaskTableItems.push(routineTaskTableItem)
          routineTaskTableItem = new ManagementOperationsRoutineTaskTableItem()
        })
        return routineTaskTableItems
      default: assertExhaustive(this.operationType)
    }
    return []
  }

  get tasksLength(): number {
    if (!this.taskTableAddLabelItems[0]) return 0
    return this.taskTableAddLabelItems[0].managementOperationsTaskProgress.length ?? 0
  }

  private detailHeaders:Header[] = []
  private makeTableBase():void {
    const headers: Header[] = [...HEADERS]
    switch (this.operationType) {
      case MANAGEMENT_OPERATION_TYPES.ONBOARDING:
        if (!this.managementOperationsOnboarding.length) return
        headers.unshift(new Header({ text: 'サービス提供開始年月', value: 'smIntroducedDate' }))
        this.managementOperationsOnboarding[0].managementOperationsTaskProgress.forEach((tp, i) => headers.push(new Header({ text: `task[${i}]`, value: `managementOperationsTaskProgress[${i}]`, width: '100px', sortable: false })))
        break
      case MANAGEMENT_OPERATION_TYPES.ROUTINE:
        if (!this.managementOperationsRoutine.length) return
        headers.unshift(new Header({ text: '決算月', value: 'accountingMonth' }))
        this.managementOperationsRoutine[0].managementOperationsTaskProgress.forEach((tp, i) => headers.push(new Header({ text: `task[${i}]`, value: `managementOperationsTaskProgress[${i}]`, width: '100px', sortable: false })))
        break
      default: assertExhaustive(this.operationType)
    }
    this.detailHeaders = headers
  }

  toSearchPostRequest(): ManagementOperationsOnboardingSearchPostRequest {
    const skip = TAKE
    const req = new ManagementOperationsOnboardingSearchPostRequest(false, false, skip, TAKE)
    return req
  }

  clickCell(managementOperationsTaskProgress: ManagementOperationsTaskProgress, buildingName: string, managementOperationsIndex: string, managementOperationsTaskProgressIndex: string) :void {
    // 編集ダイアログに渡すデータを作成
    this.managementOperationsEditItem = new ManagementOperationsEditItem()

    if (managementOperationsTaskProgress.executor?.adminId) {
      this.managementOperationsEditItem.adminId = managementOperationsTaskProgress.executor.adminId
      this.managementOperationsEditItem.adminName = managementOperationsTaskProgress.executor.adminName
      this.managementOperationsEditItem.adminShortName = managementOperationsTaskProgress.executor.adminShortName
      this.managementOperationsEditItem.unregisteredExecutorId = undefined
      this.managementOperationsEditItem.unregisteredExecutorName = undefined
    }
    if (managementOperationsTaskProgress.executor?.unregisteredExecutorId) {
      this.managementOperationsEditItem.unregisteredExecutorId = managementOperationsTaskProgress.executor.unregisteredExecutorId
      this.managementOperationsEditItem.unregisteredExecutorName = managementOperationsTaskProgress.executor.unregisteredExecutorName
      this.managementOperationsEditItem.adminId = undefined
      this.managementOperationsEditItem.adminName = undefined
    }

    this.managementOperationsEditItem.buildingName = buildingName
    this.managementOperationsEditItem.taskName = managementOperationsTaskProgress.taskName
    this.managementOperationsEditItem.operationState = managementOperationsTaskProgress.operationState
    if (managementOperationsTaskProgress.memo) this.managementOperationsEditItem.memo = managementOperationsTaskProgress.memo
    if (managementOperationsTaskProgress.completedAt) this.managementOperationsEditItem.completedAt = this.convertDateFormat(managementOperationsTaskProgress.completedAt)
    this.managementOperationsEditItem.targetDate = this.convertYearMonthFormat(managementOperationsTaskProgress.targetDate)
    this.managementOperationsEditItem.version = managementOperationsTaskProgress.version
    this.managementOperationsEditItem.managementOperationTaskStatusId = managementOperationsTaskProgress.managementOperationTaskStatusId
    this.managementOperationsEditItem.managementOperationsIndex = managementOperationsIndex
    this.managementOperationsEditItem.managementOperationsTaskProgressIndex = managementOperationsTaskProgressIndex

    this.isManagementOperationEditDialog = true
  }

  async onClickPageBtn(pageNum: number): Promise<void> {
    this.pageNum = pageNum
    await this.search()
  }

  isManagementOperationEditDialog = false
  managementOperationsEditItem = new ManagementOperationsEditItem()

  async updateTask(newValue: ManagementOperationsEditItem):Promise<void> {
    const admin = myProfileModule.myProfileGet?.user
    if (!admin) throw new Error() // unexpected

    const executor = new ManagementOperationsTaskStatusPutRequestExecutor()
    if (newValue.adminId) executor.adminId = newValue.adminId
    if (newValue.unregisteredExecutorId) executor.unregisteredExecutorId = newValue.unregisteredExecutorId

    const managementOperationsTaskStatusPutRequest = new ManagementOperationsTaskStatusPutRequest(
      newValue.managementOperationTaskStatusId,
      (!newValue.adminId && !newValue.unregisteredExecutorId) ? undefined : executor,
      admin.userId,
      newValue.operationState,
      newValue.targetDate,
      newValue.version,
      newValue.completedAt ?? undefined,
      (!newValue.memo || '') ? undefined : newValue.memo,
    )
    await managementOperationsModule.putTaskStatus(managementOperationsTaskStatusPutRequest)

    // 編集された内容を元の配列に適用する
    const taskProgress = this.taskTableAddLabelItems[Number(newValue.managementOperationsIndex)].managementOperationsTaskProgress[Number(newValue.managementOperationsTaskProgressIndex)]

    taskProgress.memo = newValue.memo
    taskProgress.operationState = newValue.operationState

    if (newValue.completedAt) taskProgress.completedAt = newValue.completedAt

    const putTaskStatusResponse = managementOperationsModule.putTaskStatusResponse(newValue.managementOperationTaskStatusId)
    if (putTaskStatusResponse) taskProgress.version = Number(putTaskStatusResponse.version)
    if (newValue.adminId && newValue.adminName && newValue.adminShortName) {
      const executor = new ManagementOperationsSearchCommonPostResponseExecutor()
      executor.adminId = newValue.adminId
      executor.adminName = newValue.adminName
      executor.adminShortName = newValue.adminShortName
      taskProgress.executor = executor
    } else if (newValue.unregisteredExecutorId && newValue.unregisteredExecutorName) {
      const executor = new ManagementOperationsSearchCommonPostResponseExecutor()
      executor.unregisteredExecutorId = newValue.unregisteredExecutorId
      executor.unregisteredExecutorName = newValue.unregisteredExecutorName
      taskProgress.executor = executor
    } else {
      taskProgress.executor = new ManagementOperationsSearchCommonPostResponseExecutor()
    }

    taskProgress.targetDate = this.formatYearMonth(newValue.targetDate)
  }

  truncateString(inputString: string, truncateLength :number): string {
    if (inputString.length <= truncateLength + 1) {
      return inputString
    } else {
      return inputString.slice(0, truncateLength) + '･･･'
    }
  }

  isCellGray(managementOperationsTaskProgress: ManagementOperationsTaskProgress[], isOutOfService: boolean): boolean {
    const isNotAllTasksCompleted = managementOperationsTaskProgress.some(t => t.operationState === OPERATION_STATES.UNRESOLVED)
    return !isNotAllTasksCompleted || isOutOfService
  }

  get tableHeight(): string | undefined {
    if (this.managementOperationsCount >= 11) return String(750)
    return undefined
  }

  convertYearMonthFormat(dateString: string): string {
    // 文字列をスラッシュで分割
    const parts = dateString.split('/')
    // 年と月の部分を取得
    const year = parts[0]
    const month = parts[1].padStart(2, '0') // 1桁の月を2桁のゼロパディング

    // 変換後の形式に結合
    return `${year}-${month}`
  }

  convertDateFormat(dateString: string): string {
    // 文字列を-で分割
    const parts = dateString.split('-')
    // 年、月、日の部分を取得
    const year = parts[0]
    const month = parts[1].padStart(2, '0') // 1桁の月を2桁のゼロパディング
    const day = parts[2].padStart(2, '0') // 1桁の日を2桁のゼロパディング

    // 変換後の形式に結合
    return `${year}-${month}-${day}`
  }

  formatYearMonth(dateString: string): string {
    // "-" を "/" に置換して日付文字列を変換
    const formattedDate = dateString.replace('-', '/')

    return formattedDate
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.isWaitingSwitch = false

    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }

  // スクロール位置をリセットする処理
  private resetScrollPosition() {
    if (!this.managementOperationsCount) return
    const dataTableWrapper = (this.$refs.dataTable as Vue & { $el: HTMLElement }).$el.querySelector('.v-data-table__wrapper')
    if (dataTableWrapper) {
      dataTableWrapper.scrollTop = 0
      dataTableWrapper.scrollLeft = 0
    }
  }
}
