

























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ReservePlan } from '@/dtos/reserve-funds/get'

@Component({
  components: {
    SmBadgeInline: () => import('@/components/atoms/SmBadgeInline.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

  }
})
export default class ReservedAmountTable extends Vue {
  @Prop({ required: true, default: () => [] })
  totalPeriodReserves!:(number | undefined)[]

  @Prop({ required: true, default: () => [] })
  reservePlans!:ReservePlan[]
}
