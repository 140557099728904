import { Type } from 'class-transformer'
import type { IdeaState, NotificationElementType, OwnerNotificationState, OwnerNotificationType, PinningSettingType, PostTimingType, ResolutionState, TargetBuildingType, TargetOwnerType, TransitionTo } from '@/constants/schema-constants'
import { Material } from '@/dtos/commons'
import { TargetBuilding, TargetOwner } from '@/dtos/owner-notifications/commons'

export class OwnerNotificationDetailGetRequest {
  ownerNotificationId!: string
}

export class OwnerNotificationDetailGetResponseIdea {
  ideaId!: string
  ideaState!: IdeaState
  title!: string
  deadlineDateTime?: string
}

export class OwnerNotificationDetailGetResponseResolution {
  resolutionId!: string
  resolutionState!: ResolutionState
  title!: string
  holdingDate?: string
  deadlineDateTime?: string
}

export class BulletPoint {
  bulletPointId!: string
  sortOrderNum!: number
  bulletPointBody!: string
  hierarchyLevel!: number
}

export class FFNElement {
  freeFormatNotificationElementId!: string
  notificationElementType!: NotificationElementType
  elementBody?: string
  inputField?: string
  transitionType?: TransitionTo
  transitionParams?: Record<string, string>
  isLinkAvailable?: boolean
  externalSiteUrl?: string
  emailAddress?: string
  phoneNumber?: string
  sortOrderNum!: number
  @Type(() => Material)material?: Material
  @Type(() => BulletPoint)bulletPoints: BulletPoint[] = []
}

export class OwnerNotificationDetailGetResponse {
  ownerNotificationId!: string
  notificationType!: OwnerNotificationType
  notificationState!: OwnerNotificationState
  title!: string
  notifiedAt?: string
  notifiedDateTime!: string
  scheduledPostingAt?: string
  targetBuildingType!: TargetBuildingType
  targetOwnerType!: TargetOwnerType
  postTimingType!: PostTimingType
  pinningSettingType!: PinningSettingType
  pinningDeadline?: string
  pinningDeadlineDate?: string
  poster!: string
  pinnedBuildingCount?: number
  targetBuildingCount?: number
  @Type(() => TargetBuilding)targetBuildings: TargetBuilding[] = []
  @Type(() => OwnerNotificationDetailGetResponseIdea)idea?: OwnerNotificationDetailGetResponseIdea
  @Type(() => OwnerNotificationDetailGetResponseResolution)resolution?: OwnerNotificationDetailGetResponseResolution
  @Type(() => TargetOwner)targetOwners: TargetOwner[] = []
  @Type(() => Material)material?: Material
  @Type(() => FFNElement)freeFormatNotificationElements: FFNElement[] = []
  version!: number
}
