





















import { Vue, Component, Prop, Model } from 'vue-property-decorator'
@Component({
  components: {
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    SmOwTextRequired: () => import('@/components/owner-apps/SmOwTextRequired.vue'),
  }
})

export default class SmOwLabeledTextArea extends Vue {
  @Prop({ required: true, default: '' })
  private readonly label!: string

  @Prop({ required: true, default: false })
  private readonly isRequired!: boolean

  @Prop({ default: '' })
  private readonly placeholder!: string

  @Prop({ default: 100 })
  private readonly counter?: number

  @Model('input', { default: '' })
  textValue!: string

  private text = ''

  created(): void {
    if (this.textValue.length > 0) this.text = this.textValue
  }

  private get inputText(): string { return this.text }
  private set inputText(newValue: string) {
    this.text = newValue
    this.$emit('input', newValue)
  }
}
