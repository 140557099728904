









































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import { QUESTIONNAIRE_QUESTION_ELEMENT_TYPES } from '@/constants/schema-constants'
import { QuestionOption } from './SmProxyAnswerEachQuestion.vue'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class SmRadioButtonAnswers extends Vue {
  // labelスロットにカスタムコンポーネントのみを設定すると描画されないため、空文字の力を借りる
  private emptyDummyString = ''

  @Prop({ default: false })
  private readonly required!: boolean

  @Prop({ default: '' })
  private readonly fieldId!: string

  @Model('change')
  private readonly radioValue!: string | number

  @Prop({ required: true, default: () => [] })
  private readonly options!:QuestionOption[]

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!: string

  private get _radioValue():string | number { return this.radioValue }
  private set _radioValue(newValue : string | number) { this.$emit('change', newValue) }

  private get rules(): string {
    const rules = []
    if (this.required) rules.push('required')
    return rules.join('|')
  }

  private onClickRadio(option: QuestionOption): void {
    if (option.elementType !== QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION) this._inputText = ''
  }

  private isOtherOption(option: QuestionOption): boolean {
    return option.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION
  }

  private get isSelectOtherOption(): boolean {
    const otherOption = this.options.filter(option => option.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION)[0]
    return this._radioValue === otherOption.value
  }

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }
}
