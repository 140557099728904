






















































































































import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { QuestionnaireComparisonDto, QuestionnaireComparisonsGetRequest } from '@/dtos/questionnaires/reports/get'
import { questionnaireReportsModule } from '@/stores/questionnaire-reports-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmTableData: () => import('@/components/molecules/SmTableData.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class QuestionnaireComparisonModal extends Vue {
  @Model('change')
  private readonly isVisible!: boolean

  @Prop()
  private readonly questionnaireId!: string

  @Prop()
  private readonly targetQuestionnaireIds!: string[]

  async created(): Promise<void> {
    this.selectedQuestionnaireIds = this.targetQuestionnaireIds ?? []
    const comparisonQuestionnaires = new QuestionnaireComparisonsGetRequest(this.questionnaireId)
    await questionnaireReportsModule.fetchQuestionnaireComparisons(comparisonQuestionnaires)
  }

  private get isModalVisible(): boolean {
    return this.isVisible
  }

  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  headers: Header[] = [
    new Header({ text: 'アンケートタイトル', value: 'title' }),
    new Header({ text: '回答締切日', value: 'deadline', width: '180px' }),
  ]

  private get res(): QuestionnaireComparisonDto[] {
    return questionnaireReportsModule.comparisonsGet.questionnaireComparisons
  }

  isWaitingSwitch = false

  selectedQuestionnaireIds: string[] = []

  // 画面表示用のデータ
  displayQuestionnaireComparisons = this.res

  answerDeadline = {
    fromDate: '',
    toDate: ''
  }

  fromDate?: Date
  toDate?: Date

  selectFromDate(newValue: Date): void {
    this.fromDate = newValue
  }

  selectToDate(newValue: Date): void {
    this.toDate = newValue
  }

  onSearchDateFilter(): void {
    Object.assign(this.displayQuestionnaireComparisons, this.res)
    this.displayQuestionnaireComparisons = this.displayQuestionnaireComparisons.filter(qc => {
      if (this.fromDate && !this.toDate) {
        return this.truncateDate(qc.deadlineDate) >= this.truncateDate(this.fromDate)
      } else if (!this.fromDate && this.toDate) {
        return this.truncateDate(qc.deadlineDate) <= this.truncateDate(this.toDate)
      } else if (this.fromDate && this.toDate) {
        return this.truncateDate(qc.deadlineDate) >= this.truncateDate(this.fromDate) && this.truncateDate(qc.deadlineDate) <= this.truncateDate(this.toDate)
      } else {
        return this.displayQuestionnaireComparisons
      }
    })
    if (!this.displayQuestionnaireComparisons.length) {
      this.noResults = true
    } else {
      this.noResults = false
    }
  }

  private truncateDate(date: Date | string) {
    if (typeof date === 'string') {
      const dateStr = date.split('-')
      return new Date(Number(dateStr[0]), Number(dateStr[1]), Number(dateStr[2].split('T')[0]))
    } else {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate())
    }
  }

  // 選択
  onClickSelectBtn(): void {
    this.$emit('select', this.selectedQuestionnaireIds)
    this.isModalVisible = false
  }

  private get isSelected(): boolean {
    if (this.selectedQuestionnaireIds.length < 1 || !this.selectedQuestionnaireIds[0]) return false
    return true
  }

  noResults = false
}
