



























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { managementOperationsModule } from '@/stores/management-operations-store'
import { UnregisteredExecutor } from '@/dtos/management-operations/unregistered-executor/get'
import { ManagementOperationsUnregisteredExecutorDeleteRequest } from '@/dtos/management-operations/unregistered-executor/delete'
import { generateUuid } from '@/libs/uuid-generator'
import { ManagementOperationsUnregisteredExecutorPostRequest } from '@/dtos/management-operations/unregistered-executor/post'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    UnregisteredExecutorRegisterModal: () => import('@/pages/tasks/UnregisteredExecutorRegisterModal.vue'),

  }
})

export default class UnregisteredExecutorSelectModalContent extends Vue {
  headers : Header[] = [
    new Header({ text: '名前', value: 'unregisteredExecutorName', filterable: false, sortable: true }),
    new Header({ text: '', value: 'actions', width: '56px' }),
  ]

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!: string

  // 検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!: string

  // 選択したID
  @Prop({ default: '' })
  targetId!: string

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  private selectedIds: string[] = []

  private get _selectedIds() { return this.selectedIds }
  private set _selectedIds(newValue: string[]) {
    this.selectedIds = newValue
    const selectedName = this.unregisteredExecutors.find(ue => ue.unregisteredExecutorId === newValue[0])?.unregisteredExecutorName
    this.$emit('select', newValue[0] ?? '', selectedName ?? '') // コンポーネント（SmTableData）の返却値は配列だが、当モーダルは単一選択のため返却値は一つのみ
  }

  async created(): Promise<void> {
    this._selectedIds = [this.targetId]

    await this.fetchManagementOperationsUnregisteredExecutors()
    this.item = new UnregisteredExecutor()
    this.item.unregisteredExecutorId = ''
    this.item.unregisteredExecutorName = ''
  }

  private async fetchManagementOperationsUnregisteredExecutors(): Promise<void> {
    await managementOperationsModule.fetchManagementOperationsUnregisteredExecutors()
  }

  private get unregisteredExecutors(): UnregisteredExecutor[] {
    return managementOperationsModule.managementOperationsUnregisteredExecutor ?? []
  }

  search(): void {
    this._keyword = this._inputText
  }

  item!: UnregisteredExecutor
  async onClickDeleteIcon(item: UnregisteredExecutor): Promise<void> {
    this.item = item
    this.isDeleteDialogVisible = true
  }

  @Watch('item', { immediate: true, deep: true })
  executorName():string {
    if (!this.item) return ''
    return this.item.unregisteredExecutorName ?? ''
  }

  unregisteredExecutorPostModalKey = generateUuid()
  isUnregisteredExecutorPostModalVisible = false

  async postUnregisteredExecutor(unregisteredExecutorName: string): Promise<void> {
    await managementOperationsModule.postUnregisteredExecutor(new ManagementOperationsUnregisteredExecutorPostRequest(unregisteredExecutorName))
    await this.fetchManagementOperationsUnregisteredExecutors()
    this.isUnregisteredExecutorPostModalVisible = false
  }

  openUnregisteredExecutorPostModal(): void {
    this.isUnregisteredExecutorPostModalVisible = true
  }

  isDeleteDialogVisible = false

  async onClickDelete(): Promise<void> {
    if (this.item.unregisteredExecutorId) await managementOperationsModule.deleteUnregisteredExecutor(new ManagementOperationsUnregisteredExecutorDeleteRequest(this.item.unregisteredExecutorId))
    await this.fetchManagementOperationsUnregisteredExecutors()
    this.isDeleteDialogVisible = false
  }
}
