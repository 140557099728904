


































































































import { PaymentDetail, Price, ReservePlan, ReservesGetResponse } from '@/dtos/reserve-funds/get'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { reserveFundsModule } from '@/stores/reserve-funds-store'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { INPUT_TARGET } from '@/pages/reserve-funds/detail/ReservePlanTable.vue'
import type { InputTarget } from '@/pages/reserve-funds/detail/ReservePlanTable.vue'
import { staticRoutes } from '@/routes'
import { ReservesPostRequest } from '@/dtos/reserve-funds/post'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { BulkInputProp } from '@/pages/reserve-funds/BulkInputDialog.vue'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { getFirstPeriodScale, getBillingPeriod } from '@/libs/reserve-funds-billing-handler'

class PageTypeSpecifications {
  executeBtnLabel!:string
  executeBtnType!:string
}

const CREATE_PAGE:PageTypeSpecifications = {
  executeBtnLabel: '作成する',
  executeBtnType: 'primary-flex'
}

const UPDATE_PAGE:PageTypeSpecifications = {
  executeBtnLabel: '更新する',
  executeBtnType: 'reflection'
}

@Component({
  components: {
    SmBadgeInline: () => import('@/components/atoms/SmBadgeInline.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    BulkInputDialog: () => import('@/pages/reserve-funds/BulkInputDialog.vue'),

    ReservePlanTable: () => import('@/pages/reserve-funds/detail/ReservePlanTable.vue'),
    ReservedAmountTable: () => import('@/pages/reserve-funds/detail/ReservedAmountTable.vue'),
    MonthlyAmountTable: () => import('@/pages/reserve-funds/MonthlyAmountTable.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class ReserveDetailEdit extends Mixins(CurrentAdminManager) {
  @Prop({ required: true, default: false })
  isRegister!:boolean

  async created():Promise<void> {
    await reserveFundsModule.fetchReserveFund()
    this.reserveFunds = deepCopy(reserveFundsModule.reserveFund, {
      this: new ColumnToType(ReservesGetResponse),
      reservePlan: new ColumnToType(ReservePlan),
      paymentPlan: new ColumnToType(PaymentDetail),
      prices: new ColumnToType(Price),
    }, 'this')

    if (this.isRegister) {
      // 最低積立㎡単価 初期値設定
      this.reserveFunds.reservePlan = this.reserveFunds.reservePlan.map(e => {
        if (e.period < this.billingPeriod) {
          e.incomePlan = e.estimatedCost
          e.unitPrice = 0
        } else {
          e.unitPrice = Math.ceil(((e.estimatedCost ?? 0) - (e.incomePlan ?? 0)) / this.reserveFunds.occupiedArea / (e.period === this.billingPeriod ? this.firstPeriodScale : 12))
        }
        return e
      })
    }
  }

  private get billingPeriod(): number {
    return getBillingPeriod()
  }

  private get firstPeriodScale(): number {
    return getFirstPeriodScale()
  }

  private get pageSpecs():PageTypeSpecifications {
    return this.isRegister ? CREATE_PAGE : UPDATE_PAGE
  }

  reserveFunds = new ReservesGetResponse()

  private get paymentPlan():PaymentDetail[] {
    return this.reserveFunds.paymentPlan.map(paymentDetailPlan => {
      const occupiedArea = paymentDetailPlan.occupiedArea
      paymentDetailPlan.prices = this.reserveFunds.reservePlan.map(_ => {
        const price = new Price()
        price.period = _.period
        if (_.unitPrice === undefined || _.unitPrice === null) {
          return price
        }
        price.monthlyPrice = Math.round(_.unitPrice * occupiedArea / 10) * 10
        return price
      })
      return paymentDetailPlan
    })
  }

  private get reservedAmounts():number[] {
    return this.reserveFunds.reservePlan.map((reservePlan, i) => {
      return this.paymentPlan.reduce((accumulator:number, current:PaymentDetail) => {
        const monthlyPrice = current.prices[i]?.monthlyPrice ?? 0
        const added = accumulator + monthlyPrice * (reservePlan.period === this.billingPeriod ? this.firstPeriodScale : 12) * current.buildingUnitCount
        return added
      }, 0)
    })
  }

  private get totalReservedAmounts(): number[] {
    let total = 0
    return this.reservedAmounts.map(m => {
      total += m
      return total
    })
  }

  showDialog = false

  async postReserveDetail():Promise<void> {
    const req = new ReservesPostRequest()
    req.reservePlan = this.reserveFunds.reservePlan.map(e => {
      return {
        targetYear: e.targetYear,
        unitPrice: e.unitPrice ?? 0,
        incomePlan: e.incomePlan ?? 0,
      }
    })
    this.showDialog = false
    await reserveFundsModule.postReserveFund(req)
    this.$router.push({ name: staticRoutes.reserveFunds.getChild('detail').name })
  }

  bulkInsertDialogVisible = false

  INPUT_TARGET = Object.freeze(INPUT_TARGET)
  inputTarget:InputTarget = INPUT_TARGET.INCOME_PLAN

  bulkInsertDialogOpen(inputTarget:InputTarget):void {
    this.bulkInsertDialogVisible = true
    this.inputTarget = inputTarget
  }

  private get periodIncomeItems():{ value: number | null, label: string }[] {
    if (!this.reserveFunds.reservePlan) return []
    return this.reserveFunds.reservePlan.map(e => { return { value: e.period, label: `${e.period}` } })
  }

  private get periodItems():{ value: number | null, label: string }[] {
    if (!this.reserveFunds.reservePlan) return []
    const billingPeriod = getBillingPeriod()
    return this.periodIncomeItems.filter(p => (p.value ?? 0) >= billingPeriod)
  }

  bulkInput(bulkInputProp:BulkInputProp):void {
    this.bulkInsertDialogVisible = false
    this.reserveFunds.reservePlan = this.reserveFunds.reservePlan.map(e => {
      const updatedValue = Object.assign({}, e)
      if (bulkInputProp.duration.periodFrom <= e.period && e.period <= bulkInputProp.duration.periodTo) {
        switch (this.inputTarget) {
          case INPUT_TARGET.INCOME_PLAN:
            updatedValue.incomePlan = bulkInputProp.inputValue ?? undefined
            break
          case INPUT_TARGET.UNIT_PRICE:
            updatedValue.unitPrice = bulkInputProp.inputValue ?? undefined
            break
          default : assertExhaustive(this.inputTarget)
        }
      }
      return updatedValue
    })
  }
}
