











import { Vue, Component, Prop } from 'vue-property-decorator'
import type { Format } from '@/components/atoms/SmText.vue'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  },
})
export default class SmOwTextLink extends Vue {
  @Prop({ required: true })
  private readonly text!: string | number

  @Prop()
  private readonly format?: Format
}
