









































import { Vue, Component, Prop } from 'vue-property-decorator'

import { Menu } from '@/components/owner-apps/SmOwMenu.vue'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmAvatar: () => import('@/components/atoms/SmAvatar.vue'),

    SmOwMenu: () => import('@/components/owner-apps/SmOwMenu.vue'),
  }
})
export default class SmOwListUser extends Vue {
  @Prop()
  private readonly src?: string

  @Prop()
  private readonly alt?: string

  @Prop()
  private userName?: string

  @Prop()
  private userRoom?: string

  @Prop()
  private menus?:Menu[]
}
