


























































import { Header } from '@/components/molecules/SmTableData.vue'
import { HOLDING_METHOD_TYPE, STATEMENT_TYPES, VOTE_TYPES } from '@/constants/schema-constants'
import type { HoldingMethodType, StatementType } from '@/constants/schema-constants'
import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { Vote } from '@/dtos/resolutions/gm-resolution/statements/common'
import { Statement, SummaryVote } from '@/dtos/resolutions/gm-resolution/statements/get'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { Vue, Component, Prop } from 'vue-property-decorator'

// 画面表示用のstatementクラス
export class DisplayingStatement extends Statement {
  isPresent = false
  constructor(
    userId: string,
    roomNumber: string,
    userName: string,
    votingRightCount:number,
    statementType:StatementType|undefined,
    isStatement: boolean,
    vote: Vote[]
  ) {
    super()
    this.userId = userId
    this.roomNumber = roomNumber
    this.userName = userName
    this.votingRightCount = votingRightCount
    this.statementType = statementType
    this.isStatement = isStatement
    this.vote = vote
    this.isPresent = (statementType !== undefined) ? (statementType === STATEMENT_TYPES.PRESENT_LOCAL || statementType === STATEMENT_TYPES.PRESENT_REMOTE) : false
  }
}

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class StatementsUpdateTable extends Vue {
  HOLDING_METHOD_TYPE = Object.freeze(HOLDING_METHOD_TYPE)
  VOTE_TYPES = Object.freeze(VOTE_TYPES)

  voteTypes = [new RadioOption('○', VOTE_TYPES.FOR), new RadioOption('×', VOTE_TYPES.AGAINST)]

  private isPresentOptions(holdingMethodType:HoldingMethodType): RadioOption[] {
    if (holdingMethodType === HOLDING_METHOD_TYPE.BOTH) return [new RadioOption('会場', STATEMENT_TYPES.PRESENT_LOCAL), new RadioOption('リモート', STATEMENT_TYPES.PRESENT_REMOTE)]
    if (holdingMethodType === HOLDING_METHOD_TYPE.REMOTE) return [new RadioOption('リモート', STATEMENT_TYPES.PRESENT_REMOTE)]
    return [new RadioOption('会場', STATEMENT_TYPES.PRESENT_LOCAL)]
  }

  @Prop({ required: true })
  private tableHeaders!: Header[]

  @Prop({ required: true })
  statements!:DisplayingStatement[]

  @Prop({ required: true })
  private subjects!:SummaryVote[]

  @Prop({ required: true })
  private holdingMethodType!: HoldingMethodType

  @Prop()
  private readonly keyword?: string

  private get _statements():DisplayingStatement[] { return this.statements }
  private set _statements(newValue:DisplayingStatement[]) {
    this.$emit('update:statements', newValue)
  }

  // 修正対象のインデックス取得
  private getIndex(userId:string):number {
    return this._statements.findIndex(st => st.userId === userId)
  }

  // 編集用のstatements生成
  private copyStatement():DisplayingStatement[] {
    return deepCopy(
      this._statements,
      {
        statements: new ColumnToType(Statement),
        vote: new ColumnToType(Vote),
      },
      'statements'
    )
  }

  // 保有議決権の処理
  private inputVotingRightCount(userId: string, votingRightCount: number): void {
    const index = this.getIndex(userId)
    const statements: DisplayingStatement[] = this.copyStatement()
    this.$set(statements[index], 'votingRightCount', votingRightCount)
    this._statements = statements
  }

  // 出席ラジオボタンの処理
  private clickPresent(userId: string, isPresent: boolean, statementType: StatementType):void {
    const index = this.getIndex(userId)
    const statements: DisplayingStatement[] = this.copyStatement()
    // 会場出席の場合はデフォルトで各voteTypeを未選択(BLANK)で登録
    if (isPresent && statementType === STATEMENT_TYPES.PRESENT_LOCAL) {
      this.$set(statements[index], 'statementType', STATEMENT_TYPES.PRESENT_LOCAL)
      statements[index].vote = this.statements[index].vote.map(e => {
        const vote = new Vote()
        vote.subjectId = e.subjectId
        vote.voteType = VOTE_TYPES.BLANK
        return vote
      })
    // リモート出席の場合は、デフォルトで各voteTypeを賛成(FOR)で登録
    } else if (isPresent && statementType === STATEMENT_TYPES.PRESENT_REMOTE) {
      this.$set(statements[index], 'statementType', STATEMENT_TYPES.PRESENT_REMOTE)
      statements[index].vote = this.statements[index].vote.map(e => {
        const vote = new Vote()
        vote.subjectId = e.subjectId
        vote.voteType = e.voteType === 0 ? VOTE_TYPES.FOR : e.voteType
        return vote
      })
    } else {
      this.$set(statements[index], 'statementType', undefined)
    }
    this._statements = statements
  }

  // リセットボタンの処理
  private reset(userId: string):void {
    const index = this.getIndex(userId)
    const statements: DisplayingStatement[] = this.copyStatement()
    statements[index].vote = this.statements[index].vote.map(e => {
      const vote = new Vote()
      vote.subjectId = e.subjectId
      vote.voteType = VOTE_TYPES.BLANK
      return vote
    })
    this.$set(statements[index], 'statementType', undefined)
    this._statements = statements
  }

  // 各議案のラジオボタンの処理
  private changeVote(userId: string, statementType: StatementType, voteIndex: number, radioValue: number):void {
    const index = this.getIndex(userId)
    const statements: DisplayingStatement[] = this.copyStatement()
    // ステータスがリモート出席(REMOTE)の場合は、各ラジオボタンのステータスをREMOTEで登録する
    if (statementType === STATEMENT_TYPES.PRESENT_REMOTE) {
      this.$set(statements[index], 'statementType', STATEMENT_TYPES.PRESENT_REMOTE)
    } else { // ステータスが議決権行使(VOTE)の場合は、各ラジオボタンのステータスをVOTEで登録する
      this.$set(statements[index], 'statementType', STATEMENT_TYPES.VOTE)
    }
    this.$set(statements[index].vote[voteIndex], 'voteType', radioValue)
    this._statements = statements
  }

  // 活性／非活性を制御するための処理
  private isPresentClickable(statement:DisplayingStatement): boolean {
    return !statement.isStatement && (!statement.statementType || statement.statementType === STATEMENT_TYPES.PRESENT_LOCAL || statement.statementType === STATEMENT_TYPES.PRESENT_REMOTE)
  }

  private isVoteChangable(statement:DisplayingStatement): boolean {
    return !statement.isStatement && (!statement.statementType || statement.statementType === STATEMENT_TYPES.VOTE || statement.statementType === STATEMENT_TYPES.PRESENT_REMOTE)
  }

  private isVoteAndPresentResetChangable(statement:DisplayingStatement): boolean {
    return !statement.isStatement && (statement.statementType === STATEMENT_TYPES.PRESENT_LOCAL || statement.statementType === STATEMENT_TYPES.PRESENT_REMOTE || statement.statementType === STATEMENT_TYPES.VOTE)
  }
}
