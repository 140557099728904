

























































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmOwRadioInverse extends Vue {
  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly required!: boolean

  @Prop()
  private readonly title?: string

  @Prop({ required: true })
  private readonly value!: string | number

  @Prop({ required: true })
  private readonly options!:{
    label: string
    caption?: string
    value: string | number
  }[]

  private get radioValue():string | number { return this.value }

  private set radioValue(newValue : string | number) {
    this.$emit('input', newValue)
    this.$emit('change')
  }

  onClickRadio(value: string | number): void {
    this.radioValue = value
  }
}
