import { AuthLoginResponse } from '@/dtos/auth/post'
import { APIClientBase } from './api-client-base'

class AuthClient extends APIClientBase {
  constructor() {
    super('/auth')
  }

  postLogin() {
    return this.post('/login', undefined, AuthLoginResponse)
  }
}

export const authClient = new AuthClient()
