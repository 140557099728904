import { TransitionTo } from '@/constants/schema-constants'
import type { NoticeType, TicketType } from '@/constants/schema-constants'
export class NoticesPostRequest {
  buildings?:string[]
  isUnread!:boolean
  skipToken?:string
  take!:number
  sortOrder!:string
}

export class Notice {
  noticeId!:string
  noticeTargetType?:TicketType
  noticeTargetTitle?:string
  noticeType!:NoticeType
  noticeTitle!:string
  buildingId?:string
  buildingName?:string
  noticedAt!:string
  transitionType!:TransitionTo
  transitionParams!:Record<string, string>
  isUnread!:boolean
}

export class NoticesPostResponse {
  notices:Notice[] = []
  skipToken!:string
}
