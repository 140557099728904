










































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class SmOption extends Vue {
  @Prop()
  private readonly placeholder?: string

  @Prop({ required: true, default: '' })
  private readonly fieldIdPrefix!: string

  @Prop({ default: '' })
  value!: string

  @Prop({ default: 'radio_button_unchecked' })
  private readonly iconName?: string

  @Prop({ default: true })
  private readonly canEditOption?: boolean

  @Prop({ default: true })
  private readonly isDeletable?: boolean

  private get optionText():string {
    return this.value
  }

  private set optionText(newValue:string) {
    this.$emit('input', newValue)
  }
}
