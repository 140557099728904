










































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { Report, ReportsGetRequest } from '@/dtos/reports/get'
import { reportsModule } from '@/stores/reports-store'
import { staticRoutes } from '@/routes'
import { SettleReportDeleteRequest } from '@/dtos/reports/settle/delete'
import { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'
import { OPINION_STATES } from '@/constants/schema-constants'

const TAKE = 20

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),

    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmCardReport: () => import('@/pages/reports/SmCardReport.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class ReportsPage extends Mixins(CurrentAdminManager) {
  @Prop()
  reportId?:string

  reports:Report[] = []

  isExceptDeleted = false

  req = new ReportsGetRequest()

  created():void {
    if (this.reportId) this.req.reportId = this.reportId
    this.req.take = TAKE
    this.req.exceptDeleted = this.isExceptDeleted
    this.initReports()
  }

  identifier = 1
  isWaitingSwitch = false

  async loadReports(handler: LoadingHandler):Promise<void> {
    this.isWaitingSwitch = true
    this.req.skip = this.reports.length
    await reportsModule.fetchReports(this.req)
    this.reports.push(...reportsModule.reports)

    if (this.reports.length === 0) {
      // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = false
    handler.loaded()
    if (this.reports.length < this.req.skip + this.req.take) handler.complete()
  }

  initReports():void {
    this.reports = []
    this.identifier++
  }

  async changeExceptDeleted():Promise<void> {
    this.req.exceptDeleted = this.isExceptDeleted
    this.initReports()
  }

  goQa(userId:string):void {
    this.$router.push({ name: staticRoutes.consultationDetail.name, params: { userId: userId } })
  }

  goIdea(ideaId:string):void {
    this.$router.push({ name: staticRoutes.adminIdeaDetail.name, params: { ideaId: ideaId } })
  }

  deleteOpinionId = ''
  isConfirmDeleteDialogVisible = false

  confirmDelete(opinionId:string):void {
    this.deleteOpinionId = opinionId
    this.isConfirmDeleteDialogVisible = true
  }

  async deleteOpinion():Promise<void> {
    this.isConfirmDeleteDialogVisible = false
    await reportsModule.deleteOpinion(new SettleReportDeleteRequest(this.deleteOpinionId))
    this.reports = this.reports.map(e => {
      if (e.opinion.opinionId === this.deleteOpinionId) e.opinion.opinionState = OPINION_STATES.ADMIN_DELETED
      return e
    })
  }

  isConfirmDisplayAllDialogVisible = false

  displayAll():void {
    this.req.reportId = undefined
    this.initReports()
    this.isConfirmDisplayAllDialogVisible = false
  }
}
