export class ConsultationReactionPostRequest {
  userId: string
  consultationId: string
  assetId: string

  constructor(userId: string, consultationId: string, assetId: string) {
    this.userId = userId
    this.consultationId = consultationId
    this.assetId = assetId
  }
}
