import { ReportsGetRequest, ReportsGetResponse } from '@/dtos/reports/get'
import { SettleReportDeleteRequest } from '@/dtos/reports/settle/delete'
import { APIClientBase } from './api-client-base'

class ReportsClient extends APIClientBase {
  constructor() {
    super('/reports')
  }

  getReports(req:ReportsGetRequest):Promise<ReportsGetResponse> {
    return this.get('', req, ReportsGetResponse, false)
  }

  async deleteSettleReport(req:SettleReportDeleteRequest):Promise<void> {
    await this.delete(`/settle/${req.opinionId}`)
  }
}

export const reportsClient = new ReportsClient()
