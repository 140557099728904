





































































import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { MaterialFormInputDto } from '@/dtos/commons'
import { Vue, Component, Prop, Model } from 'vue-property-decorator'

export type DialogType = 'update' | 'reset'
export type UploadedInfo = { selectedPeriod: number, file: File }

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),

    SmDialogClosable: () => import('@/components/organisms/dialog/SmDialogClosable.vue'),
  }
})
export default class SmDialogRepairPlanUpload extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop({ required: true, default: 'update' })
  private readonly dialogType!: DialogType

  @Prop({ required: true, default: '' })
  private readonly buildingName!: string

  @Prop({ required: true, default: '' })
  private readonly condominiumCd!: string

  @Model('change')
  private readonly value!: boolean

  private get isDialogVisible(): boolean { return this.value }
  private set isDialogVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  selectedPeriod = 1
  uploaded: MaterialFormInputDto | null = null

  periods = Array.from({ length: 60 }).map((_, index) => {
    const period = index + 1
    return { value: period, label: `${period}期` }
  })

  onClickUpload(): void {
    if (!this.uploaded?.file) return

    const info: UploadedInfo = { selectedPeriod: this.selectedPeriod, file: this.uploaded.file }
    this.$emit('upload', info)
  }
}
