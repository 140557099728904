









































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Material, User } from '@/dtos/commons'
import { Reaction } from '@/dtos/static-assets/get'
import { staticAssetsModule } from '@/stores/static-assets-store'
import { CHIP_COLORS } from '@/constants/ux-constants'
import { MATERIAL_TYPES } from '@/constants/schema-constants'

@Component({
  components: {
    SmBadgeRead: () => import('@/components/atoms/SmBadgeRead.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
  }
})
export default class SmCardConsultationSummary extends Vue {
  private CHIP_COLORS = Object.freeze(CHIP_COLORS)
  private MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop({ required: true })
  private isUnread!: boolean

  @Prop({ required: true })
  private hasDraft!: boolean

  @Prop({ required: true })
  private ownerUser!: User

  @Prop()
  private message?: string

  @Prop()
  private material?: Material

  @Prop()
  private postedAt?: string

  @Prop({ default: () => [] })
  private reactions!: string[]

  @Prop()
  private admin?: User

  private get reactionsMaster(): Reaction[] { return staticAssetsModule.reactions }
}
