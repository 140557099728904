






























































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import VueRouter from 'vue-router'
import { NavigationGuardNext, Route } from 'vue-router/types'

import { HOLDING_METHOD_TYPE, MATERIAL_TYPES, RESOLUTION_STATES } from '@/constants/schema-constants'
import type { HoldingMethodType } from '@/constants/schema-constants'
import { DAY_OF_WEEK, PAGE_TYPES } from '@/constants/ux-constants'
import type { PageType } from '@/constants/ux-constants'

import { RadioOption } from '@/components/atoms/SmRadio.vue'

import { BaseIdea, Material, MaterialFormInputDto, Subject } from '@/dtos/commons'
import { AdminIdeaDecisionGetRequest, AdminIdeaDecisionGetResponse } from '@/dtos/ideas/admin-idea/decision/get'
import { GMResolutionsDraftDeleteRequest } from '@/dtos/resolutions/gm-resolution/draft/delete'
import { GMResolutionsDraftPostRequest } from '@/dtos/resolutions/gm-resolution/draft/post'
import { GMResolutionDetailGetRequest, GMResolutionDetailGetResponse } from '@/dtos/resolutions/gm-resolution/get-detail'
import { GMResolutionsPostRequest } from '@/dtos/resolutions/gm-resolution/post'
import { GMResolutionsPutRequest } from '@/dtos/resolutions/gm-resolution/put'
import { ResolutionTicketRelatedIdeaGetRequest } from '@/dtos/tickets/resolutions/ideas/get'

import { assertExhaustive } from '@/libs/exhaustive-helper'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'
import { staticKeyProvider, _Key } from '@/libs/static-key-provider'
import { generateUuid } from '@/libs/uuid-generator'
import { windowOpen } from '@/libs/window-open'

import { staticRoutes } from '@/routes'

import { adminIdeasModule } from '@/stores/admin-ideas-store'
import { currentStateModule } from '@/stores/current-state'
import { gmResolutionsModule } from '@/stores/gm-resolutions-store'
import { GMResolutionPreviewContent, newTabLocalParamStorageModule } from '@/stores/new-tab-local-param-storage-store'
import { ticketsModule } from '@/stores/tickets-store'

// SmDatePicker用のフォーマット（YYYY-MM-DD）でn日後の年月日を返す
// TODO: SmDatePicker改修後、削除
const dateAfter = (n: number): string => {
  const date = new Date()
  date.setDate(date.getDate() + n)
  return date.toISOString().substr(0, 10)
}

const uploadThenPost = async <T, >(rawReq: T, exec: (_: T) => Promise<void>) => {
  const uploader = new FileUploader()
  const req = await uploader.prepare(rawReq)

  await exec(req)
}

class FormInputs {
  title = ''
  holdingMethodType : HoldingMethodType = HOLDING_METHOD_TYPE.LOCAL
  holdingAt = dateAfter(14)
  holdingTime = ''
  venue = ''
  meetingUrl?: string
  meetingInformation?: string
  statementDeadlineText = ''
  statementDeadlineAnnotation?: string
  questionDate = dateAfter(13)
  questionHour = 0
  questionMinute = 0
  material: MaterialFormInputDto | null = null
  subjects: Subject[] = [staticKeyProvider.create(Subject)]
  version!: number

  get deadline(): string {
    return `${this.questionDate} ${this.questionHour}:${this.questionMinute}`
  }
}

interface PageTypeSpecifications {
  pageTitle: string
  executeBtnLabel: string
  dialogMessage: string

  created: () => void | Promise<void>
  onClickExecute: ($router: VueRouter, inputs: FormInputs, resolutionId?: string) => Promise<void>
  onClickSaveDraft: ($router: VueRouter, inputs: FormInputs, resolutionId?: string) => Promise<void> | void
  onClickDeleteDraft: ($router: VueRouter, resolutionId?: string) => Promise<void> | void
  isPast: (targetDate: string | undefined) => boolean
}

class CreateSpecifications implements PageTypeSpecifications {
  ticketId:string
  resolutionId?:string
  constructor(ticketId:string, resolutionId?:string) {
    this.ticketId = ticketId
    this.resolutionId = resolutionId
  }

  readonly pageTitle = '総会決議を作成する'
  readonly executeBtnLabel = '投稿する'
  readonly dialogMessage = '総会決議を投稿します。よろしいですか？'

  async created() {
    // 遷移元の画面からチケットIDを指定されるので、もととなるアイデアを取得
    await ticketsModule.fetchResolutionTicketRelatedIdeas(new ResolutionTicketRelatedIdeaGetRequest(this.ticketId))
    // 下書きの場合は下書きデータを取得
    if (this.resolutionId) {
      await gmResolutionsModule.fetchResolutionDetail(new GMResolutionDetailGetRequest(this.resolutionId))
    }
  }

  async onClickExecute($router: VueRouter, inputs: FormInputs, resolutionId?: string): Promise<void> {
    if (!inputs.material) return // ボタン押下時にチェック想定

    const req = new GMResolutionsPostRequest(
      inputs.title, inputs.deadline, inputs.holdingMethodType, inputs.holdingAt, inputs.holdingTime,
      inputs.venue, inputs.statementDeadlineText, inputs.material, inputs.subjects,
      this.ticketId, resolutionId,
      inputs.holdingMethodType !== HOLDING_METHOD_TYPE.LOCAL ? inputs.meetingUrl : undefined,
      inputs.holdingMethodType !== HOLDING_METHOD_TYPE.LOCAL ? inputs.meetingInformation : undefined,
      inputs.statementDeadlineAnnotation)
    await uploadThenPost(req, gmResolutionsModule.postResolutions)

    $router.push({ name: staticRoutes.resolutionsList.name })
  }

  async onClickSaveDraft($router: VueRouter, inputs: FormInputs, resolutionId?: string): Promise<void> {
    if (!inputs.material) return // ボタン押下時にチェック想定

    const req = new GMResolutionsDraftPostRequest(
      inputs.title, inputs.deadline, inputs.holdingMethodType, inputs.holdingAt, inputs.holdingTime,
      inputs.venue, inputs.statementDeadlineText, inputs.material, inputs.subjects,
      this.ticketId, resolutionId,
      inputs.holdingMethodType !== HOLDING_METHOD_TYPE.LOCAL ? inputs.meetingUrl : undefined,
      inputs.holdingMethodType !== HOLDING_METHOD_TYPE.LOCAL ? inputs.meetingInformation : undefined,
      inputs.statementDeadlineAnnotation)
    await uploadThenPost(req, gmResolutionsModule.postDraftResolutions)

    $router.push({ name: staticRoutes.resolutionsList.name })
  }

  async onClickDeleteDraft($router: VueRouter, resolutionId?: string): Promise<void> {
    if (!resolutionId) return
    const req = new GMResolutionsDraftDeleteRequest(resolutionId)
    await gmResolutionsModule.deleteDraftResolutions(req)

    $router.push({ name: staticRoutes.resolutionsList.name })
  }

  isPast() {
    return false
  }
}

class UpdateSpecifications implements PageTypeSpecifications {
  resolutionId:string
  constructor(resolutionId:string) {
    this.resolutionId = resolutionId
  }

  readonly pageTitle = '総会決議を編集する'
  readonly executeBtnLabel = '更新する'
  readonly dialogMessage = '総会決議を更新します。よろしいですか？'

  async created() {
    // 決議投稿後、もととなるアイデアは変更不可能になるので、チケットに紐付くアイデアと決議に紐付くアイデアを比較する必要はない
    await gmResolutionsModule.fetchResolutionDetail(new GMResolutionDetailGetRequest(this.resolutionId))
  }

  async onClickExecute($router: VueRouter, inputs: FormInputs, resolutionId?: string) {
    if (!resolutionId || !inputs.material) return
    const req = new GMResolutionsPutRequest(
      resolutionId, inputs.title, inputs.deadline, inputs.holdingMethodType, inputs.holdingAt, inputs.holdingTime,
      inputs.venue, inputs.statementDeadlineText, inputs.material, inputs.subjects, inputs.version,
      inputs.holdingMethodType !== HOLDING_METHOD_TYPE.LOCAL ? inputs.meetingUrl : undefined,
      inputs.holdingMethodType !== HOLDING_METHOD_TYPE.LOCAL ? inputs.meetingInformation : undefined,
      inputs.statementDeadlineAnnotation
    )
    await uploadThenPost(req, gmResolutionsModule.putResolutions)

    $router.go(-1) // 総会決議詳細画面で矢印アイコンから遷移元の画面に戻れるように遷移する
  }

  onClickSaveDraft() { /** nothing to do */ }
  onClickDeleteDraft() { /** nothing to do */ }

  isPast(targetDate: string | undefined) {
    if (targetDate === undefined) return false // targetDateの値がAPIから取得される前に呼び出された場合にエラーが起きないようにする
    return new Date(targetDate).getTime() < new Date().getTime()
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmDraftInterceptor: () => import('@/components/organisms/SmDraftInterceptor.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    SmCardSubjectInput: () => import('./SmCardSubjectInput.vue'),
  }
})
export default class GMResolutionPostPage extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
  HOLDING_METHOD_TYPE = Object.freeze(HOLDING_METHOD_TYPE)

  holdingMethodTypeOptions = [new RadioOption('会場のみ', HOLDING_METHOD_TYPE.LOCAL), new RadioOption('リモートのみ', HOLDING_METHOD_TYPE.REMOTE), new RadioOption('会場・リモート両方', HOLDING_METHOD_TYPE.BOTH)]

  @Prop()
  private readonly resolutionId?: string

  @Prop({ required: true, default: PAGE_TYPES.CREATE })
  private readonly pageType!: PageType

  @Prop()
  private readonly ticketId?: string

  async created(): Promise<void> {
    this.buildingId = currentStateModule.currentBuildingId
    await this.typeSpecs.created()

    // すべての議案のもとになったアイデア詳細を取得する
    await Promise.all(
      this.relatedIdeaIds?.map(ideaId => {
        return adminIdeasModule.fetchIdeaDecision(new AdminIdeaDecisionGetRequest(ideaId))
      }) ?? []
    )

    // 質問締切日が過ぎているかどうかを判定
    this.disabledDeadline = this.typeSpecs.isPast(this.resolution?.deadlineDateTime)

    // 新規作成の場合は議案のみ生成
    if (!this.resolution) {
      this.inputs.subjects = this._createSubjectFromIdeaIds(this.relatedIdeaIds ?? [])
      // 決議内容が0個の時は空の決議を作成する
      this.inputs.subjects = this.inputs.subjects.length === 0 ? [staticKeyProvider.create(Subject)] : this.inputs.subjects
      return
    }
    // 初期値を表示する
    this.inputs.title = this.resolution.title
    this.inputs.holdingMethodType = this.resolution.holdingMethodType
    this.inputs.holdingAt = this.resolution.holdingDate
    this.inputs.holdingTime = this.resolution.holdingTime
    this.inputs.venue = this.resolution.venue
    this.inputs.meetingUrl = this.resolution.meetingUrl
    this.inputs.meetingInformation = this.resolution.meetingInformation
    this.inputs.statementDeadlineText = this.resolution.statementDeadlineText
    this.inputs.statementDeadlineAnnotation = this.resolution.statementDeadlineAnnotation
    this.inputs.questionDate = this.resolution.deadlineDateTime.substring(0, 10)
    this.inputs.questionHour = Number(this.resolution.deadlineDateTime.substring(11, 13))
    this.inputs.questionMinute = Number(this.resolution.deadlineDateTime.substring(14, 16))
    this.inputs.material = Object.assign(new MaterialFormInputDto(), this.resolution.material)
    const rawSubjects = this.resolution.subjects.map(s => {
      const subject = staticKeyProvider.create(Subject)
      subject.subjectId = s.subjectId
      subject.subjectType = s.subjectType
      subject.subjectTitle = s.subjectTitle
      if (s.baseIdea)subject.baseIdea = Object.assign(new BaseIdea(s.baseIdea.ideaId), s.baseIdea)
      return subject
    })
    // 下書きの場合（新規作成の場合は、上部で処理済み）
    if (this.pageType === PAGE_TYPES.CREATE) {
      // APIから取得した議案から、基アイデアがない議案、チケットと紐づいているアイデアの議案の2つの議案を抜き出す
      const updatedSubjects = rawSubjects.filter(subject => (!subject.baseIdea || this.relatedIdeaIds?.includes(subject.baseIdea.ideaId)))

      // 前回の下書き保存以降に決議チケットに紐づけられたアイデアを抜き出す
      const rawSubjectIdeaIds: string[] = []
      rawSubjects.forEach(subject => {
        if (subject.baseIdea?.ideaId) rawSubjectIdeaIds.push(subject.baseIdea?.ideaId)
      })
      const addSubjectsIdeaIds = this.relatedIdeaIds?.filter(relatedIdeaId => !rawSubjectIdeaIds.includes(relatedIdeaId)) ?? []
      const addSubjects = this._createSubjectFromIdeaIds(addSubjectsIdeaIds)

      updatedSubjects.push(...addSubjects)
      this.inputs.subjects = updatedSubjects
    } else {
      // 編集の場合は決議の議案をそのまま使用する
      this.inputs.subjects = rawSubjects
    }
    this.inputs.version = this.resolution.version
  }

  // アイデアから議案生成
  private _createSubjectFromIdeaIds(ideaIds:string[]):(Subject & _Key)[] {
    return ideaIds.map(ideaId => {
      const subject = staticKeyProvider.create(Subject)
      const adminIdea = adminIdeasModule.decisionResponse(ideaId)
      subject.subjectTitle = adminIdea?.title ?? ''
      subject.baseIdea = adminIdea
      return subject
    })
  }

  private get typeSpecs(): PageTypeSpecifications {
    switch (this.pageType) {
      case PAGE_TYPES.CREATE: {
        if (!this.ticketId) throw new Error() // unexpected
        return new CreateSpecifications(this.ticketId, this.resolutionId)
      }
      case PAGE_TYPES.EDIT: {
        if (!this.resolutionId) throw new Error() // unexpected
        return new UpdateSpecifications(this.resolutionId)
      }
      default: return assertExhaustive(this.pageType)
    }
  }

  get isCreate(): boolean { return this.pageType === PAGE_TYPES.CREATE }
  get isDraft(): boolean { return this.resolution?.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.DRAFT }
  get isEdit(): boolean { return this.pageType === PAGE_TYPES.EDIT }

  private buildingId: string | null = null
  inputs = new FormInputs()
  disabledDeadline = true

  hours(): { value: number, label: string }[] {
    const hours : { value: number, label: string }[] = []
    for (let i = 0; i <= 23; i++) {
      hours.push({ value: i, label: ('0' + i).slice(-2) }) // 数字を2桁で表示
    }
    return hours
  }

  private get resolution(): GMResolutionDetailGetResponse | undefined { return this.resolutionId ? gmResolutionsModule.detailResponse(this.resolutionId) : undefined }

  private get relatedIdeaIds(): string[] | undefined {
    return ticketsModule.resolutionTicketRelatedIdeas?.ideaIds
  }

  baseIdea(baseIdeaId: string | undefined): AdminIdeaDecisionGetResponse | undefined {
    if (baseIdeaId) return adminIdeasModule.decisionResponse(baseIdeaId)
  }

  // ダイアログとエクスパンションの表示・非表示
  saveConfirmDialogVisible = false
  deleteConfirmDialogVisible = false
  executeConfirmDialogVisible = false
  ideasSelectModalVisible = false

  // カードの追加・削除の制御
  addSubject(): void {
    const subject = staticKeyProvider.create(Subject)
    subject.baseIdea = undefined
    this.inputs.subjects.push(subject)
  }

  deleteSubject(subjectIndex: number): void {
    this.inputs.subjects.splice(subjectIndex, 1)
  }

  // カードの順序の入れ替え
  goUpSubject(subjectIndex: number): void {
    this.inputs.subjects.splice(subjectIndex - 1, 2, this.inputs.subjects[subjectIndex], this.inputs.subjects[subjectIndex - 1])
  }

  goDownSubject(subjectIndex: number): void {
    this.inputs.subjects.splice(subjectIndex, 2, this.inputs.subjects[subjectIndex + 1], this.inputs.subjects[subjectIndex])
  }

  // 各種実行ボタン
  async onClickExecute(): Promise<void> {
    this.processCompleted = true
    this.executeConfirmDialogVisible = false
    await this.typeSpecs.onClickExecute(this.$router, this.inputs, this.resolutionId)
  }

  async onClickSaveDraft(): Promise<void> {
    this.processCompleted = true
    this.saveConfirmDialogVisible = false
    await this.typeSpecs.onClickSaveDraft(this.$router, this.inputs, this.resolutionId)
  }

  async onClickDeleteDraft(): Promise<void> {
    this.processCompleted = true
    this.deleteConfirmDialogVisible = false
    await this.typeSpecs.onClickDeleteDraft(this.$router, this.resolutionId)
  }

  // 素材IDと取得済み参照用URLを含む素材情報の組み合わせを保持
  materialReferenceURLMap: Map<string, MaterialFormInputDto> = new Map()

  async onClickPreviewBtn(): Promise<void> {
    const previewContents: GMResolutionPreviewContent = new GMResolutionPreviewContent()
    previewContents.resolutionId = this.resolutionId ? this.resolutionId : ''
    previewContents.resolutionState = this.resolution ? this.resolution.resolutionState : RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL
    previewContents.title = this.inputs.title

    if (this.inputs.material) {
      if (!this.inputs.material.materialId) { // 新たに添付した素材の場合
        // ローカルストレージのサイズの制約上、プレビュー時に素材をそのまま別タブに渡すのが難しいため、ここでアップロードする
        const uploadedMaterial = await uploadMaterial(this.inputs.material)
        if (uploadedMaterial) {
          this.materialReferenceURLMap.set(uploadedMaterial.materialId, uploadedMaterial)
          // 画面に表示中の素材データにもIDを格納することで投稿／編集時に素材を再登録しなくて済むようにする
          this.inputs.material.materialId = uploadedMaterial.materialId
          previewContents.material = uploadedMaterial
        }
      } else if (this.materialReferenceURLMap.get(this.inputs.material.materialId)) {
        previewContents.material = this.materialReferenceURLMap.get(this.inputs.material.materialId) ?? new Material()
        // ファイル名の更新を反映
        previewContents.material.originalFileName = this.inputs.material.originalFileName
      } else { // すでにDBに登録済みの素材を画面初期表示から一度も付け替えずに使用する場合
        previewContents.material = this.inputs.material
      }
    }

    previewContents.holdingAt = this.toHoldingAtFormat(this.inputs.holdingAt)
    previewContents.holdingDate = this.inputs.holdingTime
    previewContents.venue = this.inputs.venue
    previewContents.holdingMethodType = this.inputs.holdingMethodType
    previewContents.statementDeadlineText = this.inputs.statementDeadlineText
    previewContents.statementDeadlineAnnotation = this.inputs.statementDeadlineAnnotation
    previewContents.meetingUrl = this.inputs.meetingUrl
    previewContents.meetingInformation = this.inputs.meetingInformation
    previewContents.previewSubjects = this.inputs.subjects
    previewContents.isQuestions = this.resolution ? this.resolution.details.isQuestions : false
    previewContents.currentBuildingId = this.buildingId ?? ''

    const previewContentsId = generateUuid()
    newTabLocalParamStorageModule.setGMResolutionPreviewContent({ key: previewContentsId, gmResolutionPreviewContent: previewContents })
    windowOpen(staticRoutes.gmResolutionPreview.path.replace(':gmResolutionPreviewId', previewContentsId))
  }

  toHoldingAtFormat(date: string): string {
    const dadlineDayOfWeek = DAY_OF_WEEK[new Date(date).getDay()]
    const deadlineDate = `${date.replace('-', '年').replace('-', '月')}日`
    return `${deadlineDate}(${dadlineDayOfWeek})`
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (this.isEdit || this.processCompleted || this.nextRoute) {
      next()
    } else {
      this.nextRoute = to
      next(false)
    }
  }

  nextRoute: Route | null = null
  processCompleted = false
  leaveHere(): void {
    const routeName = this.nextRoute?.name
    const routeParams = this.nextRoute?.params
    // 画面遷移元が以下のリスト内だった場合、ヒストリーを戻す
    const goBackRouteNames = [
      staticRoutes.ticketDetail.getChild('tasks').name // チケット詳細・タスクタブ
    ]
    if (routeName) {
      if (goBackRouteNames.includes(routeName)) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: routeName, params: routeParams })
      }
    }
  }
}
