export class QuestionnaireInternalReportPostRequest {
  questionnaireIds!: string[]
  templateQuestionIds!: string[]
  constructor(questionnaireIds: string[], templateQuestionIds: string[]) {
    this.questionnaireIds = questionnaireIds
    this.templateQuestionIds = templateQuestionIds
  }
}

export class ChoiceQuestionElement {
    public templateQuestionElementId!: string
    public choice!: string
    public answerCount!: number
}
export class ChoiceQuestion {
    public templateQuestionId!: string
    public questionnaireSentence!: string
    public choiceQuestionElements!: ChoiceQuestionElement[]
}
export class QuestionSummary {
    public deadline!: string
    public buildingUnitCount!: number
    public answerCount!: number
    public answerRate!: number
    public choiceQuestions!: ChoiceQuestion[]
}
export class ChoiceSection {
    public buildingArea!: string
    public condominiumCd!: string
    public buildingName!: string
    public accountingMonth!: string
    public questionSummaries!: QuestionSummary[]
}

export class InputQuestionElement {
    public deadline!: string
    public ownerName!: string
    public ownerUserId!: string
    public freeAnswer!: string
}
export class InputQuestion {
  public templateQuestionId!: string
  public questionnaireSentence!: string
  public inputQuestionElements!: InputQuestionElement[]
}
export class InputSection {
    public buildingArea!: string
    public condominiumCd!: string
    public buildingName!: string
    public accountingMonth!: string
    public inputQuestions!: InputQuestion[]
}
export class OpinionElement {
    public deadline!: string
    public ownerName!: string
    public freeAnswer!: string
}
export class OpinionSection {
    public buildingArea!: string
    public condominiumCd!: string
    public buildingName!: string
    public accountingMonth!: string
    public opinionElements!: OpinionElement[]
}

export class QuestionnaireInternalReportPostResponse {
    public choiceSections?: ChoiceSection[]
    public inputSections?: InputSection[]
    public opinionSections?: OpinionSection[]
}
