
































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { errorsModule } from '@/stores/errors'
import { DAY_OF_WEEK } from '@/constants/ux-constants'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
  }
})
export default class SmDatePicker extends Vue {
  private menu = false
  private get fieldErrors() {
    return errorsModule.fieldErrors(this.fieldId)?.join(' ')
  }

  @Prop()
  private readonly value!: string

  @Prop({ default: '' })
  private readonly fieldId!: string

  @Prop({ default: '235px' })
  private readonly width!: string

  @Prop()
  private readonly placeholder?: string

  @Prop()
  private readonly label?: string

  @Prop()
  private readonly allowedDateFrom?: Date

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: () => [] })
  private readonly validationRules!: string[]

  @Prop({ default: false })
  private readonly required!: boolean

  @Prop({ default: false })
  private readonly immediate!: boolean

  private get formatDate(): string | undefined {
    if (!this.date) return undefined
    const date = new Date(this.date)
    const numberOfDate = date.getDay()
    const dayOfWeek = DAY_OF_WEEK[numberOfDate]
    const [year, month, day] = this.date.substr(0, 10).split('-')
    return `${year}年${month}月${day}日(${dayOfWeek})`
  }

  private get rules(): string {
    const rules = this.validationRules
    if (this.required) rules.push('required')
    if (!this.required) {
      const index = rules.indexOf('required')
      if (index !== -1) rules.splice(index, 1)
    }
    return rules.join('|')
  }

  private get allowedDates(): ((val: string) => boolean) | undefined {
    if (!this.allowedDateFrom) return undefined
    const date = this.allowedDateFrom
    // ISOString形式の日本時間を取得したいが、
    // toISOString()の結果はUTCのため新規・修正に関わらず9時間進める
    date.setHours(date.getHours() + 9)
    const afterDate = date.toISOString().substr(0, 10).replaceAll('-', '')
    return (val) => parseInt(afterDate) <= parseInt(val.replaceAll('-', ''))
  }

  private get date(): string { return this.value }
  private set date(newValue: string) {
    if (errorsModule.fieldErrors(this.fieldId)) errorsModule.clearSingleFieldError(this.fieldId)
    this.$emit('input', newValue)
  }

  private get isDateNotSelected(): boolean {
    return !this.date
  }

  private onClickClear(): void {
    this.menu = false
    this.date = ''
  }
}
