
















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { TRANSITION_TO } from '@/constants/schema-constants'
import { noticesModule } from '@/stores/notices-store'
import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { generateUuid } from '@/libs/uuid-generator'
import { buildingsModule } from '@/stores/buildings-store'
import { Notice, NoticesPostRequest } from '@/dtos/notices/post'
import { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'
import { errorsModule } from '@/stores/errors'
import { paramStorageModule } from '@/stores/param-storage-store'
import { staticRoutes } from '@/routes'
import { NoticeReadPutRequest } from '@/dtos/notices/read/put'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { currentStateModule } from '@/stores/current-state'

const TAKE = 20
const STORAGE_KEY = staticRoutes.notices.name
const BUILDING_MENU_ITEMS_FOR_NOTICES: {[id: string]: { text: string, label: string}} = {
  all: {
    text: 'すべてのマンション',
    label: '変更する'
  },
  each: {
    text: '個別に選択する',
    label: '変更する'
  }
}
export const BUILDING_MENU_TYPES_FOR_NOTICES = {
  ALL: 'all',
  EACH: 'each',
} as const
export type BuildingMenuTypesForNotices = typeof BUILDING_MENU_TYPES_FOR_NOTICES[keyof typeof BUILDING_MENU_TYPES_FOR_NOTICES]

class SearchCondition {
  buildings?: string[]
  isUnread = false
  selectedBuildingMenuType?: BuildingMenuTypesForNotices
}

const INITIAL_SEARCH_CONDITION:SearchCondition = {
  buildings: [],
  isUnread: false,
  selectedBuildingMenuType: BUILDING_MENU_TYPES_FOR_NOTICES.ALL
}

@Component({
  components: {
    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmListNotice: () => import('@/components/molecules/list/SmListNotice.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),

    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),

  }
})
export default class NoticesListPage extends Vue {
  BUILDING_MENU_ITEMS = Object.freeze(BUILDING_MENU_ITEMS_FOR_NOTICES)
  // 絞り込み条件
  selectedBuildingMenuType: BuildingMenuTypesForNotices = BUILDING_MENU_TYPES_FOR_NOTICES.ALL

  searchCondition:SearchCondition = Object.assign({}, INITIAL_SEARCH_CONDITION)
  sortOrder = 'desc'

  selectedBuildings:Building[] = []
  selectedBuildingsByEach:Building[] = []

  isFilterMenuOpen = true

  private get notices():Notice[] { return noticesModule.notices }
  private get skipToken():string | null { return noticesModule.skipToken }

  sortItems: {[id: string]: { text: string, label: string }} = {
    desc: {
      text: '通知日時が新しい順',
      label: '通知日時が新しい順'
    },
    asc: {
      text: '通知日時が古い順',
      label: '通知日時が古い順'
    }
  }

  created():void {
    this.searchCondition = paramStorageModule.savedParam(STORAGE_KEY, SearchCondition) ?? Object.assign({}, INITIAL_SEARCH_CONDITION)
    if (this.searchCondition.selectedBuildingMenuType) this.selectedBuildingMenuType = this.searchCondition.selectedBuildingMenuType

    this.search()
  }

  async mounted():Promise<void> {
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999))

    if (this.selectedBuildingMenuType && this.selectedBuildingMenuType === BUILDING_MENU_TYPES_FOR_NOTICES.EACH) {
      this.changeBuilding(this.searchCondition.buildings)
    }
  }

  async clickCardNotification(noticeId:string):Promise<void> {
    const notice = this.notices.find(e => e.noticeId === noticeId)
    if (!notice) return
    const noticeReadPutReq = new NoticeReadPutRequest(noticeId)
    await noticesModule.putRead(noticeReadPutReq)
    switch (notice.transitionType) {
      case TRANSITION_TO.TICKET.DETAIL:
        this.$router.push({ name: staticRoutes.ticketDetail.name, params: notice.transitionParams }); break
      case TRANSITION_TO.TICKET.DETAIL_TASK:
        this.$router.push({ name: staticRoutes.ticketDetail.getChild('tasks').name, params: notice.transitionParams }); break
      case TRANSITION_TO.RESOLUTION.GENERAL_MEETING:
        await this.setCurrentBuilding(noticeId, notice.buildingId)
        this.$router.push({ name: staticRoutes.gmResolutionDetail.name, params: notice.transitionParams }); break
      case TRANSITION_TO.RESOLUTION.ONLINE:
        await this.setCurrentBuilding(noticeId, notice.buildingId)
        this.$router.push({ name: staticRoutes.onlineResolutionDetail.name, params: notice.transitionParams }); break
      case TRANSITION_TO.REPORT:
        await this.setCurrentBuilding(noticeId, notice.buildingId)
        this.$router.push({ name: staticRoutes.reports.name, params: notice.transitionParams }); break
      case TRANSITION_TO.STAFF_DETAIL.FRONT:
        this.$router.push({ name: staticRoutes.frontDetail.name, params: notice.transitionParams }); break
      case TRANSITION_TO.STAFF_DETAIL.LIFE_MANAGER:
        this.$router.push({ name: staticRoutes.lifeManagerDetail.name, params: notice.transitionParams }); break
      case TRANSITION_TO.CASYS_RESULTS:
        this.$router.push({ name: staticRoutes.casysResultsListPage.name }); break
      /* --- not use --- */
      case TRANSITION_TO.IDEA.OWNER:
      case TRANSITION_TO.IDEA.ADMIN:
      case TRANSITION_TO.QA:
      case TRANSITION_TO.NOTICE:
        return
      default: return assertExhaustive(notice.transitionType)
    }
  }

  async setCurrentBuilding(noticeId:string, buildingId: string|undefined):Promise<void> {
    if (!buildingId) throw new Error(`buildingId is not register noticeId:${noticeId}`)
    await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(buildingId))
    currentStateModule.setCurrentBuilding(buildingId)
  }

  private get isNoCondition():boolean {
    return !this.searchCondition || (
      this.searchCondition.buildings?.length === 0 &&
      this.searchCondition.isUnread === false
    )
  }

  isBuildingModalVisible = false
  buildingModalKey = generateUuid()
  isStaff = true
  buildingKeyword = ''
  inputText = ''

  openSelectBuildingModal():void {
    this.buildingModalKey = generateUuid()
    this.isBuildingModalVisible = true
  }

  changeBuilding(buildingIds:string[] | undefined):void {
    if (buildingIds?.length) this.selectedBuildings = this.getTargetBuildings(buildingIds)
    else this.selectedBuildings = []
    if (!this.searchCondition) return
    this.searchCondition.buildings = buildingIds

    // 個別マンション選択ダイアログで選択されたマンションを保持する（それ以外の場合はクリア）
    if (this.selectedBuildingMenuType === BUILDING_MENU_TYPES_FOR_NOTICES.EACH) this.selectedBuildingsByEach = this.selectedBuildings.slice()
    else this.selectedBuildingsByEach = []

    this.setSelectedBuildingDisplay()
    this.search()
  }

  getTargetBuildings(buildingIds: string[]): Building[] {
    return buildingsModule.buildingsGet.buildings.filter(e => buildingIds.includes(e.buildingId))
  }

  selectMenu(): void {
    switch (this.selectedBuildingMenuType) {
      case BUILDING_MENU_TYPES_FOR_NOTICES.ALL:
        this.changeBuilding(undefined)
        break
      case BUILDING_MENU_TYPES_FOR_NOTICES.EACH:
        this.openSelectBuildingModal()
        break
    }
  }

  private selectedBuildingDisplay = 'すべてのマンション'

  private setSelectedBuildingDisplay(): void {
    switch (this.selectedBuildingMenuType) {
      case BUILDING_MENU_TYPES_FOR_NOTICES.ALL:
        this.selectedBuildingDisplay = 'すべてのマンション'
        break
      // 個別の物件を選択した場合は各マンション名を表示
      case BUILDING_MENU_TYPES_FOR_NOTICES.EACH:
        this.selectedBuildingDisplay = this.buildingNames
        break
    }
  }

  private get buildingNames():string {
    return this.selectedBuildings.map(e => e.buildingName).join(' | ')
  }

  // --------------- データの読み込み ---------------
  identifier = 1
  isWaitingSwitch = false
  handler: LoadingHandler | null = null
  async loadNotices(handler: LoadingHandler): Promise<void> {
    this.isWaitingSwitch = true
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }

    // 検索パラメータの初期化が完了していなければ完了扱いにする
    if (!this.searchCondition) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }
    const req = Object.assign(new NoticesPostRequest(), this.searchCondition)
    // 画面表示のため、ローカルストレージに保存していたのでリクエストからは削除する
    req.selectedBuildingMenuType = undefined

    req.skipToken = this.skipToken ?? undefined
    req.take = TAKE
    req.sortOrder = this.sortOrder

    const beforeLength = this.notices.length
    req.skipToken = this.skipToken ?? undefined
    await noticesModule.fetchNotices(req)

    // ローカルストレージに保存する用にメニュータイプを格納
    req.selectedBuildingMenuType = this.selectedBuildingMenuType
    paramStorageModule.save({ key: STORAGE_KEY, params: { ...req } })

    // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
    if (this.notices.length === 0) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = false
    handler.loaded()

    const expectingToBe = beforeLength + TAKE
    if (this.notices.length < expectingToBe) handler.complete()
  }

  search():void {
    noticesModule.clearNotices()
    this.identifier++ // infinite-loadingを再度動かさせる
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
    this.isWaitingSwitch = false
  }
}
