import { APIClientBase } from './api-client-base'

import { DashboardSummaryGetRequest, DashboardSummaryGetResponse } from '@/dtos/dashboard/summary/get'
import { DashboardProcessingStatesGetResponse } from '@/dtos/dashboard/processing-states/get'

class DashboardClient extends APIClientBase {
  constructor() {
    super('/dashboard')
  }

  // チケット・タスクサマリーエリア取得
  getDashboardSummary(req: DashboardSummaryGetRequest) {
    return this.get('summary', req, DashboardSummaryGetResponse)
  }

  // 担当者別処理状況エリア取得
  getDashboardProcessingStates() {
    return this.get('admin-in-charge', null, DashboardProcessingStatesGetResponse)
  }
}

export const dashboardClient = new DashboardClient()
