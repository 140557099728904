




















































































import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { simpleRepairPlanExcelParser } from '@/libs/excel-parser/simple-repair-plan-parser'
import { staticRoutes } from '@/routes'
import { MATERIAL_TYPES } from '@/constants/schema-constants'

import { MaterialFormInputDto } from '@/dtos/commons'
import { SimpleRepairsPlansDetailGetResponse } from '@/dtos/simple-repairs/plans/get'
import { SimpleRepairPlanPostRequest } from '@/dtos/simple-repairs/plans/post'

import { errorsModule } from '@/stores/errors'
import { simpleRepairsModule } from '@/stores/simple-repairs-store'

const INVALID_FORMAT_MESSAGES = ['ファイルの形式が想定と異なるか、不正な値が設定されているため、アップロードできません。']

@Component({
  components: {
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogClosable: () => import('@/components/organisms/dialog/SmDialogClosable.vue'),
    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
  }
})
export default class SmDialogSimpleRepairPlanReset extends Vue {
  @Prop({ required: true, default: '' })
  private readonly buildingName!: string

  @Prop({ required: true, default: '' })
  private readonly condominiumCd!: string

  @Prop({ default: false })
  @Model('change')
  private readonly visible!: boolean

  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  repairPlanUploaded: MaterialFormInputDto | null = null
  financialPlanUploaded: MaterialFormInputDto | null = null
  financialPlanBalance = 0
  isUploadDialogVisible = false

  private get isDialogVisible(): boolean { return this.visible }
  private set isDialogVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get simpleRepairsDetail(): SimpleRepairsPlansDetailGetResponse { return simpleRepairsModule.simpleRepairsDetailGet }

  async refreshPage(): Promise<void> {
    await simpleRepairsModule.fetchSimpleRepairsDetail()
    if (this.simpleRepairsDetail.incomeExpensePlan.repairPlan.originals.length === 0) {
      this.$router.replace({ name: staticRoutes.simpleRepairPlanRegister.name })
    }
  }

  async uploadFiles(): Promise<void> {
    errorsModule.clearGlobalErrors()
    if (!this.repairPlanUploaded?.file || !this.financialPlanUploaded?.file) return

    const constructionPlanData = await simpleRepairPlanExcelParser.constructionPlanParse(this.repairPlanUploaded.file)
    const financialPlanData = await simpleRepairPlanExcelParser.financialPlanParse(this.financialPlanUploaded.file)

    if (!constructionPlanData || !financialPlanData) {
      errorsModule.setGlobalErrors(INVALID_FORMAT_MESSAGES)
      this.isUploadDialogVisible = false
      return
    }
    financialPlanData.balance = this.financialPlanBalance
    const req = new SimpleRepairPlanPostRequest(constructionPlanData, financialPlanData)
    await simpleRepairsModule.postSimpleRepairPlan(req)

    await this.refreshPage()

    this.repairPlanUploaded = null
    this.financialPlanUploaded = null
    this.financialPlanBalance = 0
    this.isUploadDialogVisible = false
  }

  onClickExecute(): void {
    this.isUploadDialogVisible = true
    this.isDialogVisible = false
  }

  onClickClose(): void {
    this.repairPlanUploaded = null
    this.financialPlanUploaded = null
    this.financialPlanBalance = 0
    this.$emit('close')
  }

  closeUploadDialog(): void {
    this.repairPlanUploaded = null
    this.financialPlanUploaded = null
    this.financialPlanBalance = 0
    this.isUploadDialogVisible = false
  }
}
