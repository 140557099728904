

































import { Vue, Component, Prop } from 'vue-property-decorator'

const colors = [
  'secondary',
  'caution',
  'white',
]
type ColorType = typeof colors[number]

const format = [
  'sm__btn-ex-large',
  'sm__btn-large',
  'sm__btn-medium',
  'sm__btn-mini',
  'sm__label',
  'sm-ow__body',
  'sm-ow__btn-mini',
]
type FormatType = typeof format[number]

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  },
})
export default class SmBtnText extends Vue {
  @Prop()
  private readonly text?: string | number

  @Prop()
  private readonly leftIcon?: string

  @Prop()
  private readonly rightIcon?: string

  @Prop({ default: 'secondary' })
  private readonly color!: ColorType

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: 'sm__btn-medium' })
  private readonly format!: FormatType

  @Prop({ default: false })
  private readonly sameOwner?: boolean
}
