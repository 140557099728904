import { Section } from '@/dtos/commons'
import { Decision } from '@/dtos/ideas/admin-idea/commons'
import { Type } from 'class-transformer'

export class AdminIdeaResultsPutRequest {
  ideaId!: string
  itemTitle!: string
  itemBody!: string
  decision!: Decision
  @Type(() => Section)sections?: Section[]
  version!: number
}
