import { APIClientBase } from '@/clients/api-client-base'
import { ReservesGetResponse } from '@/dtos/reserve-funds/get'
import { ReservesPostRequest } from '@/dtos/reserve-funds/post'
import { ReservesIncomeRecordRequest } from '@/dtos/reserve-funds/records/post'

class ReserveFundsClient extends APIClientBase {
  constructor() {
    super('/reserves')
  }

  async getReserves() :Promise<ReservesGetResponse> {
    return await this.get('/', undefined, ReservesGetResponse)
  }

  async postReserves(req:ReservesPostRequest): Promise<void> {
    await this.post('/', req)
  }

  async postReservesIncomeRecords(req:ReservesIncomeRecordRequest): Promise<void> {
    await this.post('records', req)
  }

  async putReservesUpdatable(): Promise<void> {
    await this.put('/updatable', {})
  }
}

export const reserveFundsClient = new ReserveFundsClient()
