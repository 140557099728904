import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyInitialized, store } from './index'

const MAX_BLOB_URL_COUNT = 10

@Module({ store, dynamic: true, namespaced: true, name: 'blobUrl', preserveState: isAlreadyInitialized })
class BlobUrlStore extends VuexModule {
  private _blobUrls: string[] = []

  @Mutation
  private REVOKE_BLOB_URL_IF_NEEDED(): void {
    // 保存しているURLが一定数を超える場合は一番古い（先頭）のBlobRURLを解放する
    if (this._blobUrls.length >= MAX_BLOB_URL_COUNT) {
      const revokeUrl = this._blobUrls.shift()
      if (revokeUrl) window.URL.revokeObjectURL(revokeUrl)
    }
  }

  @Mutation
  private PUSH_BLOB_URL(url: string): void {
    this._blobUrls.push(url)
  }

  @Mutation
  private DELETE_BLOB_URL(url: string): void {
    const targetIndex = this._blobUrls.indexOf(url)
    if (targetIndex >= 0) this._blobUrls.splice(targetIndex, 1)
  }

  @Mutation
  private CLEAR_BLOB_URLS(): void {
    this._blobUrls.forEach(url => window.URL.revokeObjectURL(url))
    this._blobUrls = []
  }

  /**
   * URL.createObjectURL で生成したBlobURLを保存する。
   * @param url URL.createObjectURL で生成したBlobURL
   * 参照： https://developer.mozilla.org/ja/docs/Web/API/URL/createObjectURL
   */
  @Action
  putBlobUrl(url: string): void {
    this.REVOKE_BLOB_URL_IF_NEEDED()
    this.PUSH_BLOB_URL(url)
  }

  @Action
  deleteBlobUrl(url: string): void {
    this.DELETE_BLOB_URL(url)
  }

  @Action
  clear() {
    this.CLEAR_BLOB_URLS()
  }
}

export const blobUrlModule = getModule(BlobUrlStore)
