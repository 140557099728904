import { Type } from 'class-transformer'

export class AdminIdeaAgreementsGetRequest {
  ideaId!: string
}

export class Plan {
  planId!: string
  planTitle!: string
  isAgreed!: boolean
  isProxyAgreement!: boolean
}

export class Agreement {
  userId!: string
  userName!: string
  votingRightCount!: number
  roomNumber!: string
  isOwnerAgreed!: boolean
  @Type(() => Plan) plans: Plan[] = []
}

export class AdminIdeaAgreementsGetResponse {
  @Type(() => Agreement)agreements: Agreement[] = []
}
