
















import { Vue, Component } from 'vue-property-decorator'
import { staticRoutesLocal } from '@/routes'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class TemplateTab1 extends Vue {
  goToTab2(): void {
    this.$router.push({ name: staticRoutesLocal.templateUsage.getChild('tab2').name })
  }
}
