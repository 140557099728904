































































































































































































































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { IDEA_STATES, RESOLUTION_TYPES } from '@/constants/schema-constants'
import { ERROR_MESSAGES } from '@/constants/ux-constants'
import { concatSerialChar } from '@/libs/add-alphabet-provider'
import { staticKeyProvider } from '@/libs/static-key-provider'
import { deepCopy, ColumnToType } from '@/libs/deep-copy-provider'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { generateUuid } from '@/libs/uuid-generator'

import { adminIdeasModule, AdminIdeaCommonState } from '@/stores/admin-ideas-store'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { myProfileModule } from '@/stores/my-profile-store'
import { structureModule } from '@/stores/structure-store'

import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { Section, User, FollowingResolution } from '@/dtos/commons'
import { AdminIdeaAgreementsGetRequest, Agreement } from '@/dtos/ideas/admin-idea/agreements/get'
import { AdminIdeaAgreementsPutRequest, Agreement as AgreementPut, Plan } from '@/dtos/ideas/admin-idea/agreements/put'
import { Decision, Details } from '@/dtos/ideas/admin-idea/commons'
import { PreparingStatusDeleteRequest } from '@/dtos/ideas/admin-idea/preparing-status/delete'
import { PreparingStatusPostRequest } from '@/dtos/ideas/admin-idea/preparing-status/post'
import { PreparingStatusPutRequest } from '@/dtos/ideas/admin-idea/preparing-status/put'
import { AdminIdeaResultsGetRequest, AdminIdeaResultsGetResponse, Summary, ResultAgreement, Result } from '@/dtos/ideas/admin-idea/results/get'
import { AdminIdeaResultsPutRequest } from '@/dtos/ideas/admin-idea/results/put'
import { ResultEditItem, RADIO_OPTION_FAILURE_VALUE } from '@/pages/ideas/admin-idea/admin-idea-detail/agreements/AgreementsEditResult.vue'

const TABLE_HEADER = [
  { text: '住戸番号', value: 'roomNumber', width: '112px' },
  { text: '氏名', value: 'userName', sortable: false },
] as const

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    AgreementsEditResult: () => import('@/pages/ideas/admin-idea/admin-idea-detail/agreements/AgreementsEditResult.vue'),
    ProxyAgreeModal: () => import('@/pages/ideas/admin-idea/admin-idea-detail/agreements/ProxyAgreeModal.vue')
  }
})
export default class AgreementsSubPage extends Mixins(CurrentAdminManager) {
  IDEA_STATES = Object.freeze(IDEA_STATES)

  @Prop()
  ideaId!: string

  @Prop()
  buildingId?: string

  resultsParams = new AdminIdeaResultsGetRequest()
  tableHeader = TABLE_HEADER
  isResultEdit = false
  showPutDialog = false
  resultEditItem: ResultEditItem = {
    ideaState: IDEA_STATES.ADMIN.COUNTING,
    itemBody: '',
    planId: '',
    hasResult: false,
    agreements: [],
    sections: []
  }

  private proxyAgreeModalKey = generateUuid()
  private proxyAgreeInputText = ''
  private proxyAgreeKeyword = ''
  private isModalVisible = false

  async showModal(): Promise<void> {
    if (this.isPreview) return
    const req = new AdminIdeaAgreementsGetRequest()
    req.ideaId = this.ideaId
    await adminIdeasModule.fetchAgreements(req)

    this.proxyAgreeModalKey = generateUuid()
    this.isModalVisible = true
  }

  async created(): Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    this.resultsParams.ideaId = this.ideaId
    await this.loadResult()
    this.refreshResultEditItem()
  }

  private get isPreview(): boolean { return structureModule.isPreview }

  private get ideaCommon(): AdminIdeaCommonState {
    return adminIdeasModule.adminIdeaCommonStateGet ?? new AdminIdeaCommonState()
  }

  private get ideaAgreement(): AdminIdeaResultsGetResponse {
    return adminIdeasModule.adminIdeasResultsGet(this.ideaId) ?? new AdminIdeaResultsGetResponse()
  }

  async loadResult(): Promise<void> {
    await adminIdeasModule.fetchIdeaResults(this.resultsParams)
  }

  private get showResultArea(): boolean {
    if (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.RELEASED) {
      return true
    }
    if (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.COUNTING &&
        this.$isServiceStaff) {
      return true
    }
    return false
  }

  private get isEditable(): boolean {
    return this.$isServiceStaff
  }

  private get planTitles():string[] {
    return this.resultEditItem.agreements.map(agreement => agreement.planTitle)
  }

  /**
   * 代理賛同可能か否かの判断（下記の条件を全て満たす場合のみ）
   * ・管理者業務執行者
   * ・ステータスが「賛同受付中」か、「賛同数集計中」
   * ・賛同意見を受け付けるアイデアであること（賛同意見を受け付けないアイデアも作成可能なため）
   * ・案が1つ以上あること（案が0個の賛同意見受付も作成可能なため）
   */
  private get canAgree(): boolean {
    return this.$isServiceStaff &&
    (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT || this.ideaCommon.ideaState === IDEA_STATES.ADMIN.COUNTING) &&
    !this.ideaAgreement.isNotAcceptable &&
    this.ideaAgreement.agreements.length > 0
  }

  private get showEditMode(): boolean {
    if (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.COUNTING) {
      return true
    }
    if (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.RELEASED) {
      return this.isResultEdit
    }
    return false
  }

  private getPlanTitleWithPrefix(index:number):string {
    return concatSerialChar(this.resultEditItem.agreements.length, index)
  }

  private onClickFollowingResolution(): void {
    if (this.isPreview) return
    const resolutionId = this.ideaAgreement.followingResolution?.resolutionId
    const type = this.ideaAgreement.followingResolution?.resolutionType
    if (!resolutionId || !type) return

    switch (type) {
      case RESOLUTION_TYPES.GENERAL_MEETING: this.$router.push({ name: staticRoutes.gmResolutionDetail.name, params: { resolutionId } }); return
      case RESOLUTION_TYPES.ONLINE: this.$router.push({ name: staticRoutes.onlineResolutionDetail.name, params: { resolutionId } }); return
      default: assertExhaustive(type)
    }
  }

  // 賛同結果の登録／編集関連のメソッド
  private refreshResultEditItem(isProxyAgree?: boolean): void {
    const copiedIdeaAgreement = deepCopy(
      this.ideaAgreement,
      {
        adminIdeaAgreementsGetResponse: new ColumnToType(AdminIdeaResultsGetResponse),
        details: new ColumnToType(Details),
        user: new ColumnToType(User),
        result: new ColumnToType(Result),
        decision: new ColumnToType(Decision),
        sections: new ColumnToType(Section, true),
        followingResolution: new ColumnToType(FollowingResolution),
        summary: new ColumnToType(Summary),
        agreements: new ColumnToType(ResultAgreement),
        agreementOwner: new ColumnToType(User),
      },
      'AdminIdeaResultsGetResponse'
    )

    this.resultEditItem.ideaState = this.ideaCommon.ideaState
    if (this.resultEditItem.agreements.length === 0 || isProxyAgree) { // 初期表示と代理賛同した場合のみ各案の賛同一覧の情報の更新する
      this.resultEditItem.agreements = copiedIdeaAgreement.agreements
    }

    // 代理賛同時は結果項目の更新はしない
    if (!isProxyAgree) {
      if (this.resultEditItem.ideaState === IDEA_STATES.ADMIN.COUNTING) {
        this.resultEditItem.sections = this.getInitialSections()
      } else if (copiedIdeaAgreement.result) {
        this.resultEditItem.hasResult = true
        this.resultEditItem.itemBody = copiedIdeaAgreement.result.itemBody
        if (!copiedIdeaAgreement.result.decision.isAgreed) {
          this.resultEditItem.planId = 'failure'
        } else {
          (!copiedIdeaAgreement.result.decision.planId) ? this.resultEditItem.planId = '' : this.resultEditItem.planId = copiedIdeaAgreement.result.decision.planId
        }
        if (copiedIdeaAgreement.result.sections) {
          this.resultEditItem.sections = copiedIdeaAgreement.result.sections
        }
      }
    }
  }

  private getInitialSections(): Section[] {
    const initialSections: Section[] = []

    // サービスの仕様上、賛同者要件は物件ごとに異なる条件を設定できるが、ここでは一般値として10%を用いている
    this.addNewSection(initialSections, '賛同者要件', this.ideaAgreement.summary.agreedOwnerRate >= 10 ? '成立' : '不成立')
    this.addNewSection(initialSections, '賛同者率', `${this.ideaAgreement.summary.agreedOwnerRate}%`)
    for (const agreement of this.ideaAgreement.agreements) {
      const agreementTitleWithPrefix = `【プラン${concatSerialChar(this.resultEditItem.agreements.length, this.ideaAgreement.agreements.indexOf(agreement))}】${agreement.planTitle}`
      this.addNewSection(initialSections, agreementTitleWithPrefix, `${agreement.agreementCount}`)
    }

    return initialSections
  }

  private addNewSection(targetSections: Section[], title: string, body: string) {
    const newSection = staticKeyProvider.create(Section)
    newSection.sectionTitle = title
    newSection.sectionBody = body
    targetSections.push(newSection)
  }

  private openEditForm(): void {
    if (this.isPreview) return
    this.refreshResultEditItem()
    this.isResultEdit = true
  }

  private onClickUpSection(sectionIndex: number): void {
    if (this.isPreview) return
    this.resultEditItem.sections.splice(sectionIndex - 1, 2, this.resultEditItem.sections[sectionIndex], this.resultEditItem.sections[sectionIndex - 1])
  }

  private onClickDownSection(sectionIndex: number): void {
    if (this.isPreview) return
    this.resultEditItem.sections.splice(sectionIndex, 2, this.resultEditItem.sections[sectionIndex + 1], this.resultEditItem.sections[sectionIndex])
  }

  private onClickDeleteSection(sectionIndex: number): void {
    if (this.isPreview) return
    this.resultEditItem.sections.splice(sectionIndex, 1)
  }

  private onClickAddSection(): void {
    if (this.isPreview) return
    const newSection = staticKeyProvider.create(Section)
    newSection.sectionTitle = ''
    newSection.sectionBody = ''
    this.resultEditItem.sections.push(newSection)
  }

  private async onClickPutResult(): Promise<void> {
    const putParams = new AdminIdeaResultsPutRequest()
    putParams.ideaId = this.ideaAgreement.ideaId
    putParams.itemTitle = '賛同数集計結果'
    putParams.itemBody = this.resultEditItem.itemBody
    putParams.decision = new Decision()
    if (this.resultEditItem.planId === RADIO_OPTION_FAILURE_VALUE) {
      putParams.decision.isAgreed = false
    } else {
      putParams.decision.isAgreed = true
      putParams.decision.planId = this.resultEditItem.planId
    }
    if (this.resultEditItem.sections) {
      putParams.sections = this.resultEditItem.sections
    }
    putParams.version = this.ideaAgreement.version

    this.showPutDialog = false
    await adminIdeasModule.putIdeaResult(putParams)
    this.isResultEdit = false
    await this.loadResult()
    this.refreshResultEditItem()
  }

  private async updateAgreements(agreements: Agreement[]): Promise<void> {
    const req = new AdminIdeaAgreementsPutRequest()
    req.ideaId = this.ideaId

    req.agreements = agreements.filter(e => !e.isOwnerAgreed).map(e => { // 更新用のリクエストデータに区分所有者賛同済データは含めない
      const agree = new AgreementPut()
      agree.userId = e.userId
      agree.plans = e.plans.map(p => {
        const plan = new Plan()
        plan.planId = p.planId
        plan.isAgreed = p.isAgreed
        return plan
      })
      return agree
    })
    await adminIdeasModule.putIdeaAgreements(req)
    this.isModalVisible = false

    // 賛同結果を再描画
    await this.loadResult()
    this.refreshResultEditItem(true)
  }

  // ----------------議案準備状況関連--------------------
  preparingStatusBody = ''

  // 議案準備状況エリアの表示／非表示の判定
  private get showResolutionPreparingStatusArea(): boolean {
    return this.ideaCommon.ideaState === IDEA_STATES.ADMIN.RELEASED && !!this.ideaAgreement.result?.decision.isAgreed &&
      (this.showPreparingStatusPostForm || this.showPreparingStatusText || this.showPreparingStatusEditForm)
  }

  // 議案準備状況入力フォームの表示／非表示の判定
  private get showPreparingStatusPostForm(): boolean {
    return this.$isServiceStaff && !this.ideaAgreement.followingResolution && !this.ideaAgreement.resolutionPreparingStatus
  }

  // テキストの議案準備状況の表示／非表示の判定
  private get showPreparingStatusText(): boolean {
    return !!this.ideaAgreement.resolutionPreparingStatus && !this.showPreparingStatusEditForm
  }

  // 「（区分所有者画面には非表示）」の表示／非表示の判定
  private get isFollowingResolutionRegistered(): boolean {
    return !!this.ideaAgreement.followingResolution
  }

  // 議案準備状況の「編集する」ボタン、「削除する」ボタンの表示／非表示の判定
  private get showResolutionPreparingStatusButton(): boolean {
    return this.$isServiceStaff && !this.ideaAgreement.followingResolution
  }

  showPreparingStatusEditForm = false
  openPreparingStatusEditForm(): void {
    if (this.isPreview) return
    this.preparingStatusBody = this.ideaAgreement.resolutionPreparingStatus?.resolutionPreparingStatusBody ?? ''
    this.showPreparingStatusEditForm = true
  }

  async postPreparingStatus(): Promise<void> {
    this.isPostPreparingStatusDialogVisible = false
    const req = new PreparingStatusPostRequest(this.ideaId, this.preparingStatusBody)
    await adminIdeasModule.postPreparingStatus(req)
  }

  isPostPreparingStatusDialogVisible = false
  private openPreparingStatusPostDialog(): void {
    if (this.isPreview) return
    this.isPostPreparingStatusDialogVisible = true
  }

  async putPreparingStatus(): Promise<void> {
    this.isPutPreparingStatusDialogVisible = false
    if (!this.ideaAgreement.resolutionPreparingStatus) return // unexpected
    const req = new PreparingStatusPutRequest(this.ideaId, this.preparingStatusBody,
      this.ideaAgreement.resolutionPreparingStatus.resolutionPreparingStatusId, this.ideaAgreement.resolutionPreparingStatus.version)
    await adminIdeasModule.putPreparingStatus(req)
    this.showPreparingStatusEditForm = false
  }

  isPutPreparingStatusDialogVisible = false
  private openPreparingStatusPutDialog(): void {
    if (this.isPreview) return
    this.isPutPreparingStatusDialogVisible = true
  }

  async deletePreparingStatus(): Promise<void> {
    this.isDeletePreparingStatusDialogVisible = false
    if (!this.ideaAgreement.resolutionPreparingStatus) return // unexpected
    const req = new PreparingStatusDeleteRequest(this.ideaId, this.ideaAgreement.resolutionPreparingStatus.resolutionPreparingStatusId)
    await adminIdeasModule.deletePreparingStatus(req)
  }

  isDeletePreparingStatusDialogVisible = false
  private openPreparingStatusDeleteDialog(): void {
    if (this.isPreview) return
    this.isDeletePreparingStatusDialogVisible = true
  }
}
