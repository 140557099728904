








































































































































import { Component, Vue, Prop } from 'vue-property-decorator'

import { ADMIN_ROLE } from '@/constants/schema-constants'
import type { AdminRole } from '@/constants/schema-constants'

import { StaffPostRequest, StaffPostResponse, LifeManagerStaffPostRequest } from '@/dtos/staffs/post'

import { assertExhaustive } from '@/libs/exhaustive-helper'
import { getAdminRoleLabel } from '@/libs/state-handler'

import { staticRoutes } from '@/routes'

import { staffsModule } from '@/stores/staffs-store'

import VueRouter from 'vue-router'

const toServiceStaffPostRequest = (inputs: StaffFormInputs): StaffPostRequest => {
  const registerServiceStaff = new StaffPostRequest(inputs.roleType, inputs.employeeId, inputs.adminName)
  registerServiceStaff.adminShortName = inputs.adminShortName
  registerServiceStaff.emailAddress = inputs.emailAddress
  return registerServiceStaff
}

const toFrontStaffPostRequest = (inputs: StaffFormInputs): StaffPostRequest => {
  return new StaffPostRequest(inputs.roleType, inputs.employeeId, inputs.adminName)
}

const toLifeManagerPostRequest = (inputs: StaffFormInputs): LifeManagerStaffPostRequest => {
  return new LifeManagerStaffPostRequest(inputs.employeeId, inputs.adminName, inputs.authKeyRecipient, inputs.phoneNumber)
}

class StaffFormInputs {
  roleType!: AdminRole
  employeeId!: string
  adminName!: string
  adminShortName = ''
  emailAddress = ''
  authKeyRecipient = ''
  phoneNumber = ''
  constructor(roleType: AdminRole) {
    this.roleType = roleType
  }
}

interface PageTypeSpecifications {
  adminRole: string
  onClickExecute: ($router: VueRouter, inputs: StaffFormInputs) => Promise<void>
}

class ServiceStaffSpecifications implements PageTypeSpecifications {
  readonly adminRole = getAdminRoleLabel(ADMIN_ROLE.SERVICE_STAFF)

  async onClickExecute($router: VueRouter, inputs: StaffFormInputs): Promise<void> {
    const req = toServiceStaffPostRequest(inputs)
    let res: StaffPostResponse | null = null
    if (req) {
      res = await staffsModule.postStaff(req)
    }
    if (res) $router.replace({ name: staticRoutes.serviceStaffDetail.name, params: { adminId: res.admin.userId, routeFromName: staticRoutes.serviceStaffRegister.name } })
  }
}

class FrontStaffSpecifications implements PageTypeSpecifications {
  readonly adminRole = getAdminRoleLabel(ADMIN_ROLE.FRONT)

  async onClickExecute($router: VueRouter, inputs: StaffFormInputs): Promise<void> {
    const req = toFrontStaffPostRequest(inputs)
    let res: StaffPostResponse | null = null
    if (req) {
      res = await staffsModule.postStaff(req)
    }
    if (res) $router.replace({ name: staticRoutes.frontDetail.name, params: { adminId: res.admin.userId, routeFromName: staticRoutes.frontRegister.name } })
  }
}

class LifeManagerStaffSpecifications implements PageTypeSpecifications {
  readonly adminRole = getAdminRoleLabel(ADMIN_ROLE.LIFE_MANAGER)

  async onClickExecute($router: VueRouter, inputs: StaffFormInputs): Promise<void> {
    const req = toLifeManagerPostRequest(inputs)
    let res: StaffPostResponse | null = null
    if (req) {
      res = await staffsModule.postLifeManagerStaff(req)
    }
    if (res) $router.replace({ name: staticRoutes.lifeManagerDetail.name, params: { adminId: res.admin.userId, routeFromName: staticRoutes.lifeManagerRegister.name } })
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class StaffRegisterPage extends Vue {
  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)

  @Prop({ required: true, default: ADMIN_ROLE.SERVICE_STAFF })
  private readonly pageType!: AdminRole

  private get typeSpecs(): PageTypeSpecifications | undefined {
    switch (this.pageType) {
      case ADMIN_ROLE.SERVICE_STAFF: return new ServiceStaffSpecifications()
      case ADMIN_ROLE.FRONT: return new FrontStaffSpecifications()
      case ADMIN_ROLE.LIFE_MANAGER: return new LifeManagerStaffSpecifications()
      case ADMIN_ROLE.ACCOUNTING_DEPARTMENT:
      case ADMIN_ROLE.GROUP_CALL_CENTER:
        return
      default: return assertExhaustive(this.pageType)
    }
  }

  private get placeholderText(): string {
    return this.pageType === ADMIN_ROLE.SERVICE_STAFF ? '例）管理者業務執行者 長谷工' : '例）長谷工 太郎'
  }

  inputs = new StaffFormInputs(this.pageType)

  async clickRegisterBtn(): Promise<void> {
    this.typeSpecs?.onClickExecute(this.$router, this.inputs)
  }
}
