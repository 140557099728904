














































































import { Component, Prop, Vue } from 'vue-property-decorator'

import { Type } from 'class-transformer'

import { QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'

import { generateUuid } from '@/libs/uuid-generator'

import { errorsModule } from '@/stores/errors'
import { newTabLocalParamStorageModule, QuestionnairePreviewContent } from '@/stores/new-tab-local-param-storage-store'

const CONTENT_NOT_EXIST = 'プレビューを表示できませんでした。タブを閉じて再度プレビューボタンから画面を開きなおしてください。'

export class QuestionnairePreviewAnswer {
  questionnaireQuestionElementId!: string
  choiceAnswer?: boolean
  freeAnswer?: string
}

export class QuestionnairePreviewQuestion {
  questionnaireQuestionId!: string
  @Type(() => QuestionnairePreviewAnswer) answers!: QuestionnairePreviewAnswer[]
}

export class QuestionnairePreviewSection {
  questionnaireSectionId!: string
  @Type(() => QuestionnairePreviewQuestion) questions!: QuestionnairePreviewQuestion[]
}

export class QuestionnairePreviewAnswers {
  @Type(() => QuestionnairePreviewSection) sections!: QuestionnairePreviewSection[]
}

@Component({
  components: {
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmBtnContained: () => import('@/components/owner-apps/SmOwBtnContained.vue'),
    SmOwTextHyperLinked: () => import('@/components/owner-apps/SmOwTextHyperLinked.vue'),

    QuestionnairePreviewAnswerModal: () => import('@/pages/questionnaires/QuestionnairePreviewAnswerModal.vue'),
    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class QuestionnairePreviewPage extends Vue {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)
  QUESTIONNAIRE_QUESTION_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_TYPES)
  @Prop({ default: '' })
  private readonly id!: string

  questionnairePreviewAnswers = new QuestionnairePreviewAnswers()

  created(): void {
    if (!this.questionnaire) {
      errorsModule.setGlobalErrors([CONTENT_NOT_EXIST])
    }
    this.createAnswerForm()
  }

  private get questionnaire(): QuestionnairePreviewContent | undefined {
    return newTabLocalParamStorageModule.questionnairePreviewContent(this.id)
  }

  private get questionnaireDeadline(): string | undefined {
    return `回答期限：${this.questionnaire?.deadline}`
  }

  private get questionsCounts():string | undefined {
    let questionsCount = 0
    if (!this.questionnaire?.sections) return
    this.questionnaire.sections.forEach(section => {
      if (!section.questions) return
      questionsCount += section.questions.length
    })
    return `設問数：${questionsCount}問（目安時間${Math.ceil(questionsCount / 2)}分）`
  }

  // アンケート回答を記憶するためのフォーム
  createAnswerForm(): void {
    if (this.questionnaire && this.questionnaire.sections) {
      this.questionnairePreviewAnswers.sections = this.questionnaire.sections.map(section => {
        const questionnaireSection = new QuestionnairePreviewSection()
        questionnaireSection.questionnaireSectionId = section.sectionId
        if (section.questions) {
          questionnaireSection.questions = section.questions.map(question => {
            const questionnaireQuestion = new QuestionnairePreviewQuestion()
            questionnaireQuestion.questionnaireQuestionId = question.questionId
            if (question.questionElements) {
              questionnaireQuestion.answers = question.questionElements.map(element => {
                const questionnaireQuestionAnswer = new QuestionnairePreviewAnswer()
                questionnaireQuestionAnswer.questionnaireQuestionElementId = element.questionElementId
                if (question.questionType === QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE || question.questionType === QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES) {
                  questionnaireQuestionAnswer.choiceAnswer = false
                  questionnaireQuestionAnswer.freeAnswer = ''
                } else {
                  questionnaireQuestionAnswer.freeAnswer = ''
                }
                return questionnaireQuestionAnswer
              })
            }
            return questionnaireQuestion
          })
        }
        return questionnaireSection
      })
    }
  }

  isQuestionnairePreviewAnswerModalVisible = false
  answerPreviewModalKey = generateUuid()
  openQuestionnairePreviewAnswerModal(): void {
    this.createAnswerForm()
    if (this.questionnaire?.sections) {
      this.answerPreviewModalKey = generateUuid()
      this.isQuestionnairePreviewAnswerModalVisible = true
    }
  }
}
