import { Material } from '../commons'

export class ConsultationsPostRequest {
  userId: string
  consultationId?: string
  message: string
  material?: Material

  constructor(userId: string, message: string, consultationId?: string, material?: Material | null) {
    this.userId = userId
    this.message = message
    this.consultationId = consultationId ?? undefined
    this.material = material ?? undefined
  }
}
