






































import { Component, Vue, Model } from 'vue-property-decorator'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class UnregisteredExecutorRegisterModal extends Vue {
  @Model('change')
  readonly isVisible!: boolean

  get isModalVisible(): boolean { return this.isVisible }
  set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  unregisteredExecutorName = ''

  postUnregisteredExecutor():void {
    this.$emit('post', this.unregisteredExecutorName)
  }
}
