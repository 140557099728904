import type { AreaType, AuditState, RepairPlanType } from '@/constants/schema-constants'

export class BuildingPostDto {
  buildingAreaType!: AreaType
  condominiumCd!: string
  ridgeCd!: string
  buildingName!: string
  buildingPostalCode!: string
  buildingAddress!: string
  employeeId?: string
  isFacilityReservationAvailable!: boolean
  accountingMonth! : number
  gmMonth!: number
  smIntroducedDate!: Date
  isTrialTargetBuilding!: boolean
  isConsultationUse!: boolean
  accounting!: AuditState
  repairPlanType!: RepairPlanType
  adoptRepairPlanService?: boolean
  adoptReservePlanService?: boolean
  billingStartDate?: Date
  firstPeriodEndYear!: number
  keyPerson?: string
  concerns?: string
}

export class OwningBuildingUnitPostDto {
  roomNumber!: string
  unitTypeCode?: number
  occupiedArea?: number
  votingRightCount!: number
  casysName!: string
  joinedAt?: Date
  userName?: string
  personalMemo?: string
  isLivingOutside?: boolean
  sameOwnerNo?: number
  initialAuthCode?: string
}

export class BuildingsPostRequest {
  building!: BuildingPostDto
  owningBuildingUnits!: OwningBuildingUnitPostDto[]
}

export class BuildingsPostResponse {
  buildingId!: string
}
