





















































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { Tab } from '@/components/molecules/SmTabs.vue'
import { RESOLUTION_STATES } from '@/constants/schema-constants'

import { GMResolutionCommonState } from '@/dtos/resolutions/commons'
import { GMResolutionDetailGetRequest } from '@/dtos/resolutions/gm-resolution/get-detail'
import { GMResolutionsPutCloseRequest } from '@/dtos/resolutions/gm-resolution/close/put'
import { GMResolutionsDeleteRequest } from '@/dtos/resolutions/gm-resolution/delete'

import { gmResolutionsModule } from '@/stores/gm-resolutions-store'
import { structureModule } from '@/stores/structure-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class GMResolutionDetailPage extends Mixins(CurrentAdminManager) {
  @Prop({ required: true, default: '' })
  private readonly resolutionId!:string

  private get gmResolutionCommon(): GMResolutionCommonState { return gmResolutionsModule.gmResolutionCommonStateGet ?? new GMResolutionCommonState() }

  private get canHoldRemote(): boolean { return gmResolutionsModule.canHoldRemote ?? false }

  private get isPreview(): boolean { return structureModule.isPreview }

  private get tabs(): Tab[] {
    const tabs = [
      new Tab('総会決議内容', staticRoutes.gmResolutionDetail.getChild('detail').name, !this.gmResolutionCommon.details.isResolution),
      new Tab('質問', staticRoutes.gmResolutionDetail.getChild('questions').name, !this.gmResolutionCommon.details.isQuestions),
      new Tab('決議内訳', staticRoutes.gmResolutionDetail.getChild('statements').name, !this.gmResolutionCommon.details.isStatements),
      new Tab('決議結果', staticRoutes.gmResolutionDetail.getChild('results').name, !this.isResultTabActive),
    ]
    const previewTabs = [
      new Tab('総会決議内容', staticRoutes.gmResolutionDetailPreview.getChild('detail').name, !this.gmResolutionCommon.details.isResolution),
      new Tab('質問', staticRoutes.gmResolutionDetailPreview.getChild('questions').name, !this.gmResolutionCommon.details.isQuestions),
      new Tab('決議内訳', staticRoutes.gmResolutionDetailPreview.getChild('statements').name, !this.gmResolutionCommon.details.isStatements),
      new Tab('決議結果', staticRoutes.gmResolutionDetailPreview.getChild('results').name, !this.isResultTabActive),
    ]
    return this.isPreview ? previewTabs : tabs
  }

  currentTab = ''
  private get isNoMaxWidth(): boolean {
    const tabName = this.currentTab.split('/').pop()
    return tabName === staticRoutes.gmResolutionDetail.getChild('statements').path
  }

  private get isResultTabActive():boolean {
    if (this.hasEditAuthority) {
      // 自分が投稿した決議の場合は意思表明を締め切るまで非活性
      return this.gmResolutionCommon.details.isResult
    } else {
      // 自分以外が投稿した決議の場合は決議結果が投稿されるまで非活性
      return this.gmResolutionCommon.details.isResult && (this.gmResolutionCommon.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.RELEASED)
    }
  }

  private get isClosedAccepting():boolean {
    return this.gmResolutionCommon.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.COUNTING || this.gmResolutionCommon.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.RELEASED
  }

  isCloseDialogVisible = false

  async closeAccepting(): Promise<void> {
    // 意思表明締切処理
    const req = new GMResolutionsPutCloseRequest(this.resolutionId, this.gmResolutionCommon.version)
    this.isCloseDialogVisible = false
    await gmResolutionsModule.putResolutionsClose(req)
    await gmResolutionsModule.fetchResolutionDetail(new GMResolutionDetailGetRequest(this.resolutionId))
  }

  isDeleteDialogVisible = false

  private get hasEditAuthority():boolean {
    return this.$isServiceStaff
  }

  // 権限を持ち、且つ「質問・意思表明受付中、もしくは意思表明受付中」の状態のみ編集（削除・意思表明締め切ること）が可能
  // 下書きは本画面を使用しない
  private get isEditable(): boolean {
    return this.hasEditAuthority && (this.gmResolutionCommon.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL || this.gmResolutionCommon.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT)
  }

  async deleteResolution(): Promise<void> {
    const req = new GMResolutionsDeleteRequest(this.resolutionId)
    this.isDeleteDialogVisible = false
    await gmResolutionsModule.deleteResolutions(req)

    this.$router.push({ name: staticRoutes.resolutionsList.name })
  }
}
