import { Type } from 'class-transformer'
import { User } from '@/dtos/commons'

export class SimpleRepairsUpdatesCommentDetailGetRequest {
  commentId!: string

  constructor(commentId: string) {
    this.commentId = commentId
  }
}

export class SimpleRepairsUpdatesCommentDetailGetResponse {
  commentId!: string
  comment!: string
  postedAt!: string
  @Type(() => User) user = new User()
  version!: number
}
