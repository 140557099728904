import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyInitialized, store } from './index'
import { currentStateModule } from './current-state'

import { BuildingsGetRequest, BuildingsGetResponse } from '@/dtos/buildings/get'
import { BuildingDetailGetRequest, BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { BuildingDeleteRequest } from '@/dtos/buildings/delete'
import { buildingsClient } from '@/clients/buildings-client'
import { BuildingsPostRequest, BuildingsPostResponse } from '@/dtos/buildings/post'
import { BuildingsPutRequest } from '@/dtos/buildings/put'
import { BuildingIdeasGetRequest, BuildingIdeasGetResponse } from '@/dtos/buildings/ideas/get'
import { BuildingOwnersGetRequest, BuildingOwnersGetResponse } from '@/dtos/buildings/owners/get'
import { OwnerNotificationPinningBuildingsGetRequest, OwnerNotificationPinningBuildingsGetResponse } from '@/dtos/buildings/owner-notifications/pinning/get'
import { BuildingResolutionsGetRequest, BuildingResolutionsGetResponse } from '@/dtos/buildings/resolutions/get'
import { BuildingAdminIdeasGetRequest, BuildingAdminIdeasGetResponse } from '@/dtos/buildings/admin-idea/get'

@Module({ store, dynamic: true, namespaced: true, name: 'buildings', preserveState: isAlreadyInitialized })
class BuildingsStore extends VuexModule {
  private _buildingsGet: BuildingsGetResponse = new BuildingsGetResponse()

  get buildingsGet(): BuildingsGetResponse {
    return this._buildingsGet
  }

  @Mutation
  private SET_BUILDINGS_GET(res: BuildingsGetResponse): void {
    this._buildingsGet = res
  }

  @Action
  async fetchBuildings(req: BuildingsGetRequest): Promise<void> {
    const res = await buildingsClient.getBuildings(req)
    this.SET_BUILDINGS_GET(res)
  }

  private _buildingDetails: Record<string, BuildingDetailGetResponse> = {}

  get buildingDetailGet(): BuildingDetailGetResponse | null {
    return currentStateModule.currentBuildingId ? this._buildingDetails[currentStateModule.currentBuildingId] ?? null : null
  }

  @Mutation
  private SET_BUILDING_DETAIL_GET(res: BuildingDetailGetResponse): void {
    Vue.set(this._buildingDetails, res.buildingId, res)
  }

  @Action
  async fetchBuildingDetail(req: BuildingDetailGetRequest): Promise<void> {
    const res = await buildingsClient.getBuildingDetail(req)
    this.SET_BUILDING_DETAIL_GET(res)
  }

  /**
   * 期を決算の年月に変換する関数を返します
   * 例えば、1期の会計年度が2021年2月～2022年1月であれば、「1 -> 2022/1」「5 -> 2026/1」です
   */
  public get periodToYearMonth(): (period: number) => string {
    return (period: number) => {
      return `${this._periodToEndYear(period) ?? ''}/${this.buildingDetailGet?.closingMonth ?? ''}`
    }
  }

  private get _periodToEndYear(): (period: number) => number | null { // その期が決算月を迎える西暦
    return (period: number) => {
      if (!this.buildingDetailGet) return null
      return this.buildingDetailGet.firstPeriodEndYear + period - 1
    }
  }

  @Action
  async postBuildings(req: BuildingsPostRequest): Promise<BuildingsPostResponse | void> {
    return await buildingsClient.postBuildings(req)
  }

  @Action
  async putBuildings(req: BuildingsPutRequest): Promise<void> {
    await buildingsClient.putBuildings(req)
  }

  // サービス利用終了
  @Action
  async deleteBuilding(req: BuildingDeleteRequest): Promise<void> {
    await buildingsClient.deleteBuilding(req)
  }

  @Mutation
  private SET_BUILDING_CLEAR(): void {
    this._buildingsGet = new BuildingsGetResponse()
    this._buildingDetails = {}
  }

  @Action
  clear() {
    this.SET_BUILDING_CLEAR()
  }

  /**
   * 物件指定区分所有者検索
   */
  private _buildingOwnersGet:BuildingOwnersGetResponse = new BuildingOwnersGetResponse()

  public get buildingOwnersGet():BuildingOwnersGetResponse { return this._buildingOwnersGet }

  @Mutation
  private SET_BUILDING_OWNERS_GET(res:BuildingOwnersGetResponse):void { this._buildingOwnersGet = res }

  @Action
  async fetchBuildingOwners(req:BuildingOwnersGetRequest):Promise<void> {
    const res = await buildingsClient.getBuildingOwners(req)
    this.SET_BUILDING_OWNERS_GET(res)
  }

  /**
   * 物件指定アイデア一覧検索
   */
  private _buildingOwnerIdeasGet: BuildingIdeasGetResponse = new BuildingIdeasGetResponse()

  public get buildingOwnerIdeasGet(): BuildingIdeasGetResponse {
    return this._buildingOwnerIdeasGet
  }

  @Mutation
  private SET_BUILDING_OWNER_IDEAS_GET(res: BuildingIdeasGetResponse): void {
    this._buildingOwnerIdeasGet = res
  }

  @Action
  async fetchBuildingOwnerIdeas(req: BuildingIdeasGetRequest): Promise<void> {
    const res = await buildingsClient.getBuildingIdeas(req)
    this.SET_BUILDING_OWNER_IDEAS_GET(res)
  }

  /**
   * 物件指定プラン一覧検索
   */
  private _buildingAdminIdeasGet: BuildingIdeasGetResponse = new BuildingIdeasGetResponse()

  public get buildingAdminIdeasGet(): BuildingIdeasGetResponse {
    return this._buildingAdminIdeasGet
  }

  @Mutation
  private SET_BUILDING_ADMIN_IDEAS_GET(res: BuildingIdeasGetResponse): void {
    this._buildingAdminIdeasGet = res
  }

  @Action
  async fetchBuildingAdminIdeas(req: BuildingIdeasGetRequest): Promise<void> {
    const res = await buildingsClient.getBuildingIdeas(req)
    this.SET_BUILDING_ADMIN_IDEAS_GET(res)
  }

  /**
   * 物件指定オンライン決議一覧検索
   */
  private _buildingOnlineResolutionsGet: BuildingResolutionsGetResponse = new BuildingResolutionsGetResponse()

  public get buildingOnlineResolutionsGet(): BuildingResolutionsGetResponse {
    return this._buildingOnlineResolutionsGet
  }

  @Mutation
  private SET_BUILDING_ONLINE_RESOLUTIONS_GET(res: BuildingResolutionsGetResponse): void {
    this._buildingOnlineResolutionsGet = res
  }

  @Action
  async fetchBuildingOnlineResolutions(req: BuildingResolutionsGetRequest): Promise<void> {
    const res = await buildingsClient.getBuildingResolutions(req)
    this.SET_BUILDING_ONLINE_RESOLUTIONS_GET(res)
  }

  /**
   * 物件指定総会決議一覧検索
   */
  private _buildingGmResolutionsGet: BuildingResolutionsGetResponse = new BuildingResolutionsGetResponse()

  public get buildingGmResolutionsGet(): BuildingResolutionsGetResponse {
    return this._buildingGmResolutionsGet
  }

  @Mutation
  private SET_BUILDING_GM_RESOLUTIONS_GET(res: BuildingResolutionsGetResponse): void {
    this._buildingGmResolutionsGet = res
  }

  @Action
  async fetchBuildingGmResolutions(req: BuildingResolutionsGetRequest): Promise<void> {
    const res = await buildingsClient.getBuildingResolutions(req)
    this.SET_BUILDING_GM_RESOLUTIONS_GET(res)
  }

  /**
   * お知らせ固定表示対象物件検索
   */
  private _ownerNotificationPinningBuildingsGet: OwnerNotificationPinningBuildingsGetResponse = new OwnerNotificationPinningBuildingsGetResponse()

  get ownerNotificationPinningBuildingsGet(): OwnerNotificationPinningBuildingsGetResponse {
    return this._ownerNotificationPinningBuildingsGet
  }

  @Mutation
  private SET_OWNER_NOTIFICATION_PINNING_BUILDINGS_GET(res: OwnerNotificationPinningBuildingsGetResponse): void {
    this._ownerNotificationPinningBuildingsGet = res
  }

  @Action
  async fetchNotificationPinningBuildings(req: OwnerNotificationPinningBuildingsGetRequest): Promise<void> {
    const res = await buildingsClient.getNotificationPinningBuildings(req)
    this.SET_OWNER_NOTIFICATION_PINNING_BUILDINGS_GET(res)
  }

  /**
   * プラン一覧取得（アイデア選択）
   */
  private _adminIdeasGet: BuildingAdminIdeasGetResponse = new BuildingAdminIdeasGetResponse()

  get adminIdeasGet(): BuildingAdminIdeasGetResponse {
    return this._adminIdeasGet
  }

  @Mutation
  private SET_ADMIN_IDEAS_GET(res: BuildingAdminIdeasGetResponse): void {
    this._adminIdeasGet = res
  }

  @Action
  async fetchAdminIdeas(req: BuildingAdminIdeasGetRequest): Promise<void> {
    const res = await buildingsClient.getBuildingAdminIdeas(req)
    this.SET_ADMIN_IDEAS_GET(res)
  }
}

export const buildingsModule = getModule(BuildingsStore)
