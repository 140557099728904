import { ManagementOperationsCommon, ManagementOperationsSearchCommonPostRequest, ManagementOperationsSearchCommonPostResponse } from '../../common/search/post'

export class ManagementOperationsRoutineSearchPostRequest extends ManagementOperationsSearchCommonPostRequest {
  accountingMonth?: number
}

export class ManagementOperationsRoutine extends ManagementOperationsCommon {
  accountingMonth!: string
}

export class ManagementOperationsRoutineSearchPostResponse extends ManagementOperationsSearchCommonPostResponse {
}
