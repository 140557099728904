

















































import { Component, Mixins } from 'vue-property-decorator'
import { getAdminRoleLabel } from '@/libs/state-handler'
import { FileUploader } from '@/libs/file-uploader'

import { myProfileModule } from '@/stores/my-profile-store'
import { authModule } from '@/stores/auth-store'
import { MyProfileGetResponse } from '@/dtos/my-profile/get'
import { MaterialFormInputDto } from '@/dtos/commons'
import { MyProfilePutRequest } from '@/dtos/my-profile/put'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmAvatar: () => import('@/components/atoms/SmAvatar.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialInputIcon: () => import('@/components/molecules/SmMaterialInputIcon.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

  }
})
export default class AccountPage extends Mixins(CurrentAdminManager) {
  async created() : Promise<void> {
    // プロフィールはログイン時に取得して保持しているが、アイコン画像の署名付きURLが期限切れの場合があるため再取得する
    await myProfileModule.fetchMyProfile()
  }

  iconMaterial: MaterialFormInputDto | null = null

  get myProfile(): MyProfileGetResponse | null { return myProfileModule.myProfileGet }

  get _iconMaterial(): MaterialFormInputDto | null { return this.iconMaterial ?? Object.assign(new MaterialFormInputDto(), { materialUrl: this.myProfile?.user.iconImagePath }) }
  set _iconMaterial(newIcon: MaterialFormInputDto | null) { this.iconMaterial = newIcon }

  getAdminRoleLabel(): string {
    if (this.myProfile === null) return ''
    return getAdminRoleLabel(this.myProfile?.role)
  }

  async onClickLogoutBtn(): Promise<void> {
    await authModule.logout()
  }

  async onChangeIcon(newIcon: MaterialFormInputDto): Promise<void> {
    const rawReq = new MyProfilePutRequest(newIcon)

    const uploader = new FileUploader()
    const req = await uploader.prepare(rawReq)

    await myProfileModule.updateProfile({ req, newIconPath: newIcon.materialUrl })
  }
}
