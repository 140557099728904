import { APIClientBase } from './api-client-base'

import { ResolutionsGetRequest, ResolutionsGetResponse } from '@/dtos/resolutions/get'
import { RequirementsGetResponse } from '@/dtos/resolutions/requirements/get'
import { QuestionsGetRequest, QuestionsGetResponse } from '@/dtos/resolutions/questions/get'
import { QuestionsDeleteRequest } from '@/dtos/resolutions/questions/delete'
import { AnswersPostRequest, AnswersPostResponse } from '@/dtos/resolutions/questions/answers/post'
import { AnswersPutRequest, AnswersPutResponse } from '@/dtos/resolutions/questions/answers/put'
import { AnswersDeleteRequest } from '@/dtos/resolutions/questions/answers/delete'

class ResolutionsClient extends APIClientBase {
  constructor() {
    super('/resolutions')
  }

  // 決議（総会／オンライン）
  getResolutions(req: ResolutionsGetRequest) {
    return this.get('', req, ResolutionsGetResponse, false)
  }

  // 決議要件
  getRequirements() {
    return this.get('requirements', null, RequirementsGetResponse)
  }

  // 質問一覧 取得
  getQuestions(req:QuestionsGetRequest) {
    const { resolutionId, ...rest } = req
    return this.get(`${resolutionId}/questions`, rest, QuestionsGetResponse, false)
  }

  // 質問 削除
  deleteQuestions(req: QuestionsDeleteRequest) {
    return this.delete(`${req.resolutionId}/questions/${req.questionId}`)
  }

  // 回答 登録
  postAnswers(req: AnswersPostRequest) {
    return this.post(`${req.resolutionId}/questions/${req.questionId}/answer`, req, AnswersPostResponse)
  }

  // 回答 更新
  putAnswers(req: AnswersPutRequest) {
    return this.put(`${req.resolutionId}/questions/${req.questionId}/answer`, req, AnswersPutResponse)
  }

  // 回答 削除
  deleteAnswers(req: AnswersDeleteRequest) {
    return this.delete(`${req.resolutionId}/questions/${req.questionId}/answer`)
  }
}

export const resolutionsClient = new ResolutionsClient()
