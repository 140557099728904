import { BaseIdea, Plan } from '@/dtos/commons'

export class AdminIdeasPutRequest {
  ideaId: string
  title: string
  opinionDeadline: string
  deadline: string
  isNotAcceptable!: boolean
  plans?: Plan[]
  baseIdea?: BaseIdea
  version?: number

  constructor(ideaId: string, title: string, opinionDeadline: string, deadline: string, isNotAcceptable: boolean, plans?: Plan[], version?: number, baseIdea?: BaseIdea) {
    this.ideaId = ideaId
    this.title = title
    this.opinionDeadline = opinionDeadline
    this.deadline = deadline
    this.isNotAcceptable = isNotAcceptable
    this.plans = plans
    this.version = version
    this.baseIdea = baseIdea
  }
}
