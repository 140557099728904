




















































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
// import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { windowOpen } from '@/libs/window-open'

import { buildingsModule } from '@/stores/buildings-store'
import { myProfileModule } from '@/stores/my-profile-store'
import { unitsModule } from '@/stores/units-store'

import { BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { UnitDetailGetRequest, UnitDetailGetResponse } from '@/dtos/units/get-detail'
import { UnitOwnerSimulateTokenGetRequest } from '@/dtos/units/simulate-token/get'
import { OWNER_SIMULATE_LOGIN_COMPLETION_URL } from '@/pages/users/UsersSearchPage.vue'

import { staticRoutes } from '@/routes'
import { getInitialAuthCodeTypeLabel } from '@/libs/type-handler'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmDataRow: () => import('@/components/atoms/SmDataRow.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDialogSimulateConfirmation: () => import('@/components/organisms/dialog/SmDialogSimulateConfirmation.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

  }
})
export default class OwnerDetailPage extends Mixins(CurrentAdminManager) {
  @Prop()
  private readonly unitId!:string

  unit = new UnitDetailGetResponse()

  async created():Promise<void> {
    await unitsModule.fetchUnitDetail(new UnitDetailGetRequest(this.unitId))
    this.unit = unitsModule.unitDetail(this.unitId) ?? new UnitDetailGetResponse()
  }

  private get unitTypeCode(): string | number {
    // sm-data-rowではnumberの０は非表示になるため、表示するにはstringに変換が必要
    if (this.unit.unitTypeCode === 0) return '0'
    return this.unit.unitTypeCode ? this.unit.unitTypeCode : ''
  }

  private get casysUserName(): string { return this.unit.casysName }

  private get userName(): string { return this.unit.userName ?? '' }

  get building(): BuildingDetailGetResponse | null { return buildingsModule.buildingDetailGet }

  get isBuildingOutOfService(): boolean { return this.building ? this.building.isOutOfService : false }

  isSimulateConfirmationDialogVisible = false
  openSimulateConfirmationDialog(): void {
    this.isSimulateConfirmationDialogVisible = true
  }

  onClickEdit(): void { this.$router.push({ name: staticRoutes.ownerEdit.name, params: { unitId: this.unit.unitId } }) }

  onClickChange(): void { this.$router.push({ name: staticRoutes.ownerChange.name, params: { unitId: this.unit.unitId } }) }

  async startSimulate(): Promise<void> {
    this.isSimulateConfirmationDialogVisible = false

    const req = new UnitOwnerSimulateTokenGetRequest(this.unit.unitId)
    await unitsModule.fetchSimulateToken(req)

    const adminId = myProfileModule.myProfileGet?.user.userId
    const token = unitsModule.simulateTokenGet.oneTimeToken

    windowOpen(`${process.env.VUE_APP_OWNER_FRONT_BASE_URL}${OWNER_SIMULATE_LOGIN_COMPLETION_URL}?adminId=${adminId}&oneTimeToken=${token}`)
  }

  private get initialAuthCodeTypeLabel():string {
    if (!this.unit.initialAuthCodeType) return ''
    return getInitialAuthCodeTypeLabel(this.unit.initialAuthCodeType)
  }
}
