import { TicketState, TicketTaskState } from '@/constants/schema-constants'
import { Type } from 'class-transformer'

export class DashboardSummaryGetRequest {
  isAdminIncharge: boolean

  constructor(isAdminIncharge: boolean) {
    this.isAdminIncharge = isAdminIncharge
  }
}

export class TicketSummary {
  ticketState!: TicketState
  ticketStateCount!: number
}

export class TicketTaskSummary {
  ticketTaskState!: TicketTaskState
  ticketTaskStateCount!: number
}

export class DashboardSummaryGetResponse {
  @Type(() => TicketSummary) tickets: TicketSummary[] = []
  @Type(() => TicketTaskSummary) tasks: TicketTaskSummary[] = []
  nearDeadlineTaskCount!: number
  expiredTaskCount?: number
}
