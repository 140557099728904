



































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})

export default class OnboardingHeader extends Vue {
}
