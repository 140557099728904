

















































































































































































































































import { Mixins, Component, Watch, Vue } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { TICKET_TASK_STATES, TICKET_TYPES, ADMIN_ROLE, TASK_INQUIRY_SOURCE_TYPES, NEW_TAB_TRANSITION_TO, RESOLUTION_TYPES, RESOLUTION_STATES } from '@/constants/schema-constants'
import type { TicketTaskState, TicketType } from '@/constants/schema-constants'
import { DAY_OF_WEEK } from '@/constants/ux-constants'
import { generateUuid } from '@/libs/uuid-generator'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { openNewTab } from '@/libs/open-new-tab'
import { canEditTicketTaskName, getTicketTaskNameTypeLabel, isOwnerResponseTasks } from '@/libs/type-handler'

import { Choice } from '@/components/molecules/SmBtnToggle.vue'
import { FilterLabel } from '@/components/molecules/SmExpansionFilterMenu.vue'
import type { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'

import { Period } from '@/pages/tasks/PeriodSelectModal.vue'

import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { User } from '@/dtos/commons'
import { TaskPutRequest } from '@/dtos/tasks/put'
import { TasksSearchPostRequest, ListResponseTaskDto, TaskInquirySource } from '@/dtos/tasks/search/post'
import { TaskStatePutRequest } from '@/dtos/tasks/state/put'

import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { myProfileModule } from '@/stores/my-profile-store'
import { NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { tasksModule } from '@/stores/tasks-store'

const TAKE = 50
const STORAGE_KEY = staticRoutes.tasks.getChild('list').name

// ステータスのトグルボタンの各ボタンIDの定義
export const FILTER_TOGGLE_TASK_STATES = {
  UNSET_PERSON_IN_CHARGE: 'unsetPersonInCharge',
  NOT_STARTED_YET: 'notStartedYet',
  NOT_COMPLETED: 'notCompleted',
  COMPLETED: 'completed',
} as const
export type FilterToggleStateId = typeof FILTER_TOGGLE_TASK_STATES[keyof typeof FILTER_TOGGLE_TASK_STATES]

// ステータスのトグルボタンIDに対するタスクステータスの定義
const FILTER_TOGGLE_CHOICES_STATE:Record<FilterToggleStateId, TicketTaskState[]> = {
  [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE]: [TICKET_TASK_STATES.UNSET_PERSON_IN_CHARGE],
  [FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET]: [TICKET_TASK_STATES.NOT_STARTED_YET],
  [FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]: [TICKET_TASK_STATES.NOT_COMPLETED],
  [FILTER_TOGGLE_TASK_STATES.COMPLETED]: [TICKET_TASK_STATES.COMPLETED, TICKET_TASK_STATES.UNNECESSARY],
}

// コンテンツのトグルボタンの各ボタンIDの定義
const FILTER_TOGGLE_TICKET_TYPE_IDS = {
  IDEA: 'idea',
  RESOLUTION: 'resolution',
  CONSULTATION: 'consultation',
  MANUAL: 'manual',
}
type FilterToggleTicketTypeId = typeof FILTER_TOGGLE_TICKET_TYPE_IDS[keyof typeof FILTER_TOGGLE_TICKET_TYPE_IDS]

// ステータスのトグルボタンIDに対するタスクステータスの定義
const FILTER_TOGGLE_CHOICES_TICKET_TYPE:Record<FilterToggleTicketTypeId, TicketType[]> = {
  [FILTER_TOGGLE_TICKET_TYPE_IDS.IDEA]: [TICKET_TYPES.IDEA],
  [FILTER_TOGGLE_TICKET_TYPE_IDS.RESOLUTION]: [TICKET_TYPES.RESOLUTION],
  [FILTER_TOGGLE_TICKET_TYPE_IDS.CONSULTATION]: [TICKET_TYPES.CONSULTATION],
  [FILTER_TOGGLE_TICKET_TYPE_IDS.MANUAL]: [TICKET_TYPES.MANUAL],
}

const BUILDING_MENU_ITEMS_FOR_TASKS: {[id: string]: { text: string, label: string }} = {
  all: {
    text: 'すべてのマンション',
    label: '変更する'
  },
  each: {
    text: '個別に選択する',
    label: '変更する'
  },
}

export const BUILDING_MENU_TYPES_FOR_TASKS = {
  ALL: 'all',
  EACH: 'each',
} as const
export type BuildingMenuTypesForTasks = typeof BUILDING_MENU_TYPES_FOR_TASKS[keyof typeof BUILDING_MENU_TYPES_FOR_TASKS]

const ADMIN_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  all: {
    text: 'すべての担当者（担当者未設定を含む）',
    label: '変更する'
  },
  each: {
    text: '個別に選択する',
    label: '変更する'
  },
}

export const ADMIN_MENU_TYPES = {
  ALL: 'all',
  EACH: 'each',
}as const
export type StaffMenuTypes = typeof ADMIN_MENU_TYPES[keyof typeof ADMIN_MENU_TYPES]

// 並び替えメニューの内容
const SORT_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  accrualDate: {
    text: '発生日が新しい順に並び替える',
    label: '発生日が新しい順'
  },
  deadline: {
    text: '期日が古い順に並び替える',
    label: '期日が古い順'
  }
}

export class TaskInputParams {
  selectedStates: FilterToggleStateId[] = ['notStartedYet', 'notCompleted'] // 初期表示で未着手・処理中のタスクを表示
  selectedTicketTypes: FilterToggleTicketTypeId[] = []
  buildings:string[] = []
  staffs: User[] = []
  postedDate: Period | null = null
  deadline: Period | null = null
  keyword = ''
  responseOwnerTasks = false
  sortItem = 'accrualDate' // 並べ替えメニューのキー（id）
  buildingMenuType!: BuildingMenuTypesForTasks
  staffMenuTypes!:StaffMenuTypes
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),

    SmBtnToggle: () => import('@/components/molecules/SmBtnToggle.vue'),
    SmCardTask: () => import('@/components/molecules/card/SmCardTask.vue'),
    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmExpansionFilterMenu: () => import('@/components/molecules/SmExpansionFilterMenu.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    TasksPostForm: () => import('@/components/molecules/TasksPostForm.vue'),

    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),
    StaffSelectModal: () => import('@/components/organisms/modal/StaffSelectModal.vue'),
    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    PeriodSelectModal: () => import('@/pages/tasks/PeriodSelectModal.vue'),

  }
})

export default class TaskListSubPage extends Mixins(CurrentAdminManager) {
  selectedBuildingMenuType: BuildingMenuTypesForTasks = BUILDING_MENU_TYPES_FOR_TASKS.ALL

  selectedBuildings: Building[] = []

  BUILDING_MENU_ITEMS_FOR_TASKS = Object.freeze(BUILDING_MENU_ITEMS_FOR_TASKS)
  selectedBuildingsByEach: Building[] = [] // 個別選択ダイアログで以前選択した物件を保持しておく変数

  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)

  selectedStaffMenuType: StaffMenuTypes = ADMIN_MENU_TYPES.EACH
  ADMIN_MENU_ITEMS = Object.freeze(ADMIN_MENU_ITEMS)
  selectedStaffs: User[] = []

  selectedAccrualDate: Period = { fromDate: null, toDate: null }
  selectedDeadline: Period = { fromDate: null, toDate: null }

  isFilterMenuOpen = true

  isFocus = false

  async created(): Promise<void> {
    this.inputParams.sortItem = 'accrualDate'
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999))

    // 初期検索パラメーターの設定（以前の検索条件が残っている場合、それを復元して反映）
    const stored = paramStorageModule.savedParam(STORAGE_KEY, TaskInputParams)
    if (stored) this.inputParams = stored

    this.tasksSearchPostRequestParam = new TasksSearchPostRequest(TAKE)

    this.tasksSearchPostRequestParam.ticketTaskStates = this.inputParams.selectedStates.flatMap(s => FILTER_TOGGLE_CHOICES_STATE[s])

    this.tasksSearchPostRequestParam.ticketTypes = this.inputParams.selectedTicketTypes.flatMap(s => FILTER_TOGGLE_CHOICES_TICKET_TYPE[s])

    if (this.inputParams.buildingMenuType) this.selectedBuildingMenuType = this.inputParams.buildingMenuType
    this.selectedBuildings = this.getTargetBuildings(this.inputParams.buildings ?? [])
    if (this.selectedBuildingMenuType === BUILDING_MENU_TYPES_FOR_TASKS.EACH) this.selectedBuildingsByEach = this.selectedBuildings
    this.tasksSearchPostRequestParam.buildings = this.selectedBuildings.map(s => s.buildingId)

    if (this.inputParams.staffMenuTypes) this.selectedStaffMenuType = this.inputParams.staffMenuTypes
    if (this.inputParams.staffs.length) {
      this.tasksSearchPostRequestParam.staffs = this.inputParams.staffs.map(s => s.userId)
      this.selectedStaffs = this.inputParams.staffs
    } else {
      // ストアに検索条件が残っていない場合は、初期表示でログイン中のユーザーを設定する
      if (myProfileModule.myProfileGet?.user) {
        this.tasksSearchPostRequestParam.staffs = [myProfileModule.myProfileGet?.user.userId]
        this.selectedStaffs = [myProfileModule.myProfileGet?.user]
      }
    }

    if (this.inputParams.postedDate) {
      this.tasksSearchPostRequestParam.postedDate = this.inputParams.postedDate
      this.selectedAccrualDate = this.inputParams.postedDate
    }

    if (this.inputParams.deadline) {
      this.tasksSearchPostRequestParam.deadline = this.inputParams.deadline
      this.selectedDeadline = this.inputParams.deadline
    }

    if (this.inputParams.keyword) this.tasksSearchPostRequestParam.keyword = this.inputParams.keyword
    this.tasksSearchPostRequestParam.sortItem = this.inputParams.sortItem

    if (this.inputParams.responseOwnerTasks) {
      this.tasksSearchPostRequestParam.ticketTaskTypes = isOwnerResponseTasks(this.inputParams.responseOwnerTasks)
    }

    this.setSelectedBuildingDisplay()
    this.setSelectedStaffDisplay()
    this.reloadTasks()
  }

  private get tasks(): ListResponseTaskDto[] { return tasksModule.tasks }
  private get skipToken(): string | null { return tasksModule.skipToken }

  // --------------- リクエストパラメータと、そのもととなる画面上の入力項目 ---------------
  tasksSearchPostRequestParam: TasksSearchPostRequest | null = null

  inputParams = new TaskInputParams()

  // --------------- データの読み込み ---------------
  identifier = 1
  isWaitingSwitch = false
  handler: LoadingHandler | null = null

  staffInputText = ''
  staffKeyword = ''

  async loadTasks(handler: LoadingHandler): Promise<void> {
    this.isWaitingSwitch = true
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }

    this.inputParams.buildings = this.selectedBuildings.map(e => e.buildingId)
    this.inputParams.buildingMenuType = this.selectedBuildingMenuType

    // 検索パラメータの初期化が完了していなければ完了扱いにする
    if (!this.tasksSearchPostRequestParam) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    const beforeLength = this.tasks.length
    this.tasksSearchPostRequestParam.skipToken = this.skipToken ?? undefined
    const toSearchPostRequest = this.toSearchPostRequest()
    await tasksModule.fetchTasks(toSearchPostRequest)
    paramStorageModule.save({ key: STORAGE_KEY, params: { ...this.inputParams } })

    // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
    if (this.tasks.length === 0) {
      handler.complete()
      this.isWaitingSwitch = false
      return
    }

    this.isWaitingSwitch = false
    handler.loaded()

    const expectingToBe = beforeLength + this.tasksSearchPostRequestParam.take
    if (this.tasks.length < expectingToBe) handler.complete()
  }

  reloadTasks(): void {
    tasksModule.clearFetchedTask()
    this.identifier++
  }

  toSearchPostRequest(): TasksSearchPostRequest {
    const req = new TasksSearchPostRequest(TAKE)
    req.ticketId = this.tasksSearchPostRequestParam?.ticketId ?? undefined
    req.ticketTaskStates = this.tasksSearchPostRequestParam?.ticketTaskStates ?? undefined
    req.ticketTypes = this.tasksSearchPostRequestParam?.ticketTypes ?? undefined
    req.ticketTaskTypes = this.tasksSearchPostRequestParam?.ticketTaskTypes ?? undefined
    req.buildings = this.tasksSearchPostRequestParam?.buildings ?? undefined
    req.staffs = this.tasksSearchPostRequestParam?.staffs ?? undefined

    req.postedDate = this.tasksSearchPostRequestParam?.postedDate ?? undefined
    if (req.postedDate !== undefined) {
      if (this.tasksSearchPostRequestParam?.postedDate?.fromDate === '') { req.postedDate.fromDate = undefined }
      req.postedDate.fromDate = this.tasksSearchPostRequestParam?.postedDate?.fromDate ?? undefined
      if (this.tasksSearchPostRequestParam?.postedDate?.toDate === '') { req.postedDate.toDate = undefined }
      req.postedDate.toDate = this.tasksSearchPostRequestParam?.postedDate?.toDate ?? undefined
    }

    req.deadline = this.tasksSearchPostRequestParam?.deadline ?? undefined
    if (req.deadline !== undefined) {
      if (this.tasksSearchPostRequestParam?.deadline?.fromDate === '') { req.deadline.fromDate = undefined }
      req.deadline.fromDate = this.tasksSearchPostRequestParam?.deadline?.fromDate ?? undefined
      if (this.tasksSearchPostRequestParam?.deadline?.toDate === '') { req.deadline.toDate = undefined }
      req.deadline.toDate = this.tasksSearchPostRequestParam?.deadline?.toDate ?? undefined
    }

    req.keyword = this.tasksSearchPostRequestParam?.keyword ?? undefined
    req.sortItem = this.tasksSearchPostRequestParam?.sortItem ?? undefined
    req.skipToken = this.tasksSearchPostRequestParam?.skipToken
    return req
  }

  // -------------- 画面描画用のデータ ---------------
  sortMenuItems = SORT_MENU_ITEMS

  isFilterExpansionOpen = true

  // 絞り込みメニューのラベル名（各slotのname属性ごとにラベルを用意する）
  filterLabels = {
    condition1: new FilterLabel('ステータス', 'center', '160px'),
    condition2: new FilterLabel('コンテンツ', 'center', '160px'),
    condition3: new FilterLabel('マンション', 'center', '160px'),
    condition4: new FilterLabel('担当者', 'center', '160px'),
    condition5: new FilterLabel('期間', 'center', '160px'),
    condition6: new FilterLabel('キーワード', 'center', '160px'),
  }

  private get statesChoices(): Choice[] {
    const choices = [
      new Choice(FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE, '担当者未設定'),
      new Choice(FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET, '未着手'),
      new Choice(FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED, '処理中'),
      new Choice(FILTER_TOGGLE_TASK_STATES.COMPLETED, '完了'),
    ]
    return choices
  }

  private get ticketTypesChoices(): Choice[] {
    const choices = [
      new Choice(FILTER_TOGGLE_TICKET_TYPE_IDS.IDEA, 'アイデア・プラン'),
      new Choice(FILTER_TOGGLE_TICKET_TYPE_IDS.RESOLUTION, '決議'),
      new Choice(FILTER_TOGGLE_TICKET_TYPE_IDS.CONSULTATION, '相談・連絡'),
      new Choice(FILTER_TOGGLE_TICKET_TYPE_IDS.MANUAL, '手動'),
    ]
    return choices
  }

  // 絞り込みを行っているかの判定（0件時のメッセージの判定に利用）
  private get isNoCondition(): boolean {
    if (!this.tasksSearchPostRequestParam) return true
    if (this.tasksSearchPostRequestParam.ticketId ||
        (this.tasksSearchPostRequestParam.ticketTaskStates && this.tasksSearchPostRequestParam.ticketTaskStates.length > 0) ||
        (this.tasksSearchPostRequestParam.ticketTypes && this.tasksSearchPostRequestParam.ticketTypes.length > 0) ||
        (this.tasksSearchPostRequestParam.buildings && this.tasksSearchPostRequestParam.buildings.length > 0) ||
        (this.tasksSearchPostRequestParam.staffs && this.tasksSearchPostRequestParam.staffs.length > 0) ||
        (this.tasksSearchPostRequestParam.postedDate && this.tasksSearchPostRequestParam.postedDate.fromDate) ||
        (this.tasksSearchPostRequestParam.postedDate && this.tasksSearchPostRequestParam.postedDate.toDate) ||
        (this.tasksSearchPostRequestParam.deadline && this.tasksSearchPostRequestParam.deadline.fromDate) ||
        (this.tasksSearchPostRequestParam.deadline && this.tasksSearchPostRequestParam.deadline.toDate) ||
        this.tasksSearchPostRequestParam.keyword) {
      return false
    }
    return true
  }

  // --------------- ボタンなどを押下した際の処理 ---------------
  changeEditMode(task :ListResponseTaskDto, isEditMode: boolean): void { task.isEditMode = isEditMode }

  // 絞り込み処理（各フォームを操作した際のみ検索条件として反映）
  private onChangeSelectedStatesChoices(): void {
    if (!this.tasksSearchPostRequestParam) return
    const ticketTaskState = this.inputParams.selectedStates.flatMap(s => FILTER_TOGGLE_CHOICES_STATE[s])
    this.tasksSearchPostRequestParam.ticketTaskStates = ticketTaskState
    this.reloadTasks()
  }

  private onChangeSelectedTicketTypesChoices(): void {
    if (!this.tasksSearchPostRequestParam) return
    const TicketType = this.inputParams.selectedTicketTypes.flatMap(s => FILTER_TOGGLE_CHOICES_TICKET_TYPE[s])
    this.tasksSearchPostRequestParam.ticketTypes = TicketType
    this.reloadTasks()
  }

  private onSearchKeyword(): void {
    if (!this.tasksSearchPostRequestParam) return
    this.tasksSearchPostRequestParam.keyword = this.inputParams.keyword === '' ? null : this.inputParams.keyword
    this.reloadTasks()
  }

  private onChangeFilter(): void {
    if (!this.tasksSearchPostRequestParam) return
    this.tasksSearchPostRequestParam.ticketTaskTypes = this.inputParams.responseOwnerTasks ? isOwnerResponseTasks(this.inputParams.responseOwnerTasks) : undefined
    this.reloadTasks()
  }

  private onChangeSortItem(): void {
    if (!this.tasksSearchPostRequestParam) return
    this.tasksSearchPostRequestParam.sortItem = this.inputParams.sortItem
    this.reloadTasks()
  }

  selectBuildingMenu(): void {
    switch (this.selectedBuildingMenuType) {
      case BUILDING_MENU_TYPES_FOR_TASKS.ALL:
        this.changeBuilding(undefined)
        break
      case BUILDING_MENU_TYPES_FOR_TASKS.EACH:
        this.openSelectBuildingModal()
        break
      default: assertExhaustive(this.selectedBuildingMenuType)
    }
  }

  changeBuilding(buildingIds:string[] | undefined):void {
    if (buildingIds?.length) {
      this.selectedBuildings = this.getTargetBuildings(buildingIds)
      this.inputParams.buildings = this.selectedBuildings.map(e => e.buildingId)
      if (this.tasksSearchPostRequestParam?.buildings === null || this.tasksSearchPostRequestParam?.buildings === undefined) return
      this.tasksSearchPostRequestParam.buildings = this.selectedBuildings.map(s => s.buildingId)
    } else {
      this.selectedBuildings = []
      if (this.tasksSearchPostRequestParam?.buildings === null || this.tasksSearchPostRequestParam?.buildings === undefined) return
      this.tasksSearchPostRequestParam.buildings = []
    }

    // 個別マンション選択ダイアログで選択されたマンションを保持する（それ以外の場合はクリア）
    if (this.selectedBuildingMenuType === BUILDING_MENU_TYPES_FOR_TASKS.EACH) {
      this.selectedBuildingsByEach = this.selectedBuildings.slice()
      if (this.tasksSearchPostRequestParam?.buildings === null || this.tasksSearchPostRequestParam?.buildings === undefined) return
      this.tasksSearchPostRequestParam.buildings = this.selectedBuildingsByEach.map(s => s.buildingId)
    }
    if (this.selectedBuildingMenuType === BUILDING_MENU_TYPES_FOR_TASKS.ALL) this.selectedBuildingsByEach = []
    this.setSelectedBuildingDisplay()
    this.reloadTasks()
  }

  isBuildingModalVisible = false
  buildingSelectModalKey = generateUuid()
  openSelectBuildingModal():void {
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingModalVisible = true
  }

  private selectedBuildingDisplay = 'すべてのマンション'

  private setSelectedBuildingDisplay(): void {
    switch (this.selectedBuildingMenuType) {
      case BUILDING_MENU_TYPES_FOR_TASKS.ALL:
        this.selectedBuildingDisplay = 'すべてのマンション'
        if (this.tasksSearchPostRequestParam?.buildings === null || this.tasksSearchPostRequestParam?.buildings === undefined) return
        this.tasksSearchPostRequestParam.buildings = []
        break
      case BUILDING_MENU_TYPES_FOR_TASKS.EACH:
        this.selectedBuildingDisplay = this.buildingNames
        break
      default: assertExhaustive(this.selectedBuildingMenuType)
    }
  }

  private get buildingNames():string {
    return this.selectedBuildings.map(e => e.buildingName).join(' | ')
  }

  getTargetBuildings(buildingIds: string[]): Building[] {
    return buildingsModule.buildingsGet.buildings.filter(e => buildingIds.includes(e.buildingId))
  }

  private selectedStaffDisplay = 'すべての担当者（担当者未設定を含む）'

  private setSelectedStaffDisplay(): void {
    switch (this.selectedStaffMenuType) {
      case ADMIN_MENU_TYPES.ALL:
        this.selectedStaffDisplay = 'すべての担当者（担当者未設定を含む）'
        if (this.tasksSearchPostRequestParam?.staffs === null || this.tasksSearchPostRequestParam?.staffs === undefined) return
        this.tasksSearchPostRequestParam.staffs = []
        break
      case ADMIN_MENU_TYPES.EACH:
        this.selectedStaffDisplay = this.selectedStaffs.map(e => e.userName).join('、')
        break
      default: assertExhaustive(this.selectedStaffMenuType)
    }
  }

  selectStaffs(staffs:User[]):void {
    this.selectedStaffs = staffs
    if (!this.tasksSearchPostRequestParam) return
    this.tasksSearchPostRequestParam.staffs = this.selectedStaffs.map(s => s.userId)
    this.inputParams.staffs = this.selectedStaffs
    this.inputParams.staffMenuTypes = this.selectedStaffMenuType
    this.setSelectedStaffDisplay()
    this.reloadTasks()
  }

  private get selectedAccrualDisplay(): string {
    if (!this.selectedAccrualDate.fromDate && !this.selectedAccrualDate.toDate) {
      return 'すべて'
    } else {
      const fromDate = !this.selectedAccrualDate.fromDate ? '' : this.formatDate(this.selectedAccrualDate.fromDate)
      const toDate = !this.selectedAccrualDate.toDate ? '' : this.formatDate(this.selectedAccrualDate.toDate)
      return fromDate + ' 〜 ' + toDate
    }
  }

  private get selectedDeadlineDisplay(): string {
    if (!this.selectedDeadline.fromDate && !this.selectedDeadline.toDate) {
      return 'すべて'
    } else {
      const fromDate = !this.selectedDeadline.fromDate ? '' : this.formatDate(this.selectedDeadline.fromDate)
      const toDate = !this.selectedDeadline.toDate ? '' : this.formatDate(this.selectedDeadline.toDate)
      return fromDate + ' 〜 ' + toDate
    }
  }

  formatDate(inputDate:string | null | undefined):string| undefined {
    if (!inputDate) return ''
    const date = new Date(inputDate)
    const numberOfDate = date.getDay()
    const dayOfWeek = DAY_OF_WEEK[numberOfDate]
    const [year, month, day] = inputDate.substr(0, 10).split('-')
    return `${year}年${month}月${day}日(${dayOfWeek})`
  }

  isPeriodModalVisible = false
  periodSelectModalKey = generateUuid()
  openPeriodModal():void {
    this.periodSelectModalKey = generateUuid()
    this.isPeriodModalVisible = true
  }

  isStaff = true
  buildingKeyword = ''
  inputText=''

  selectStaffMenu(): void {
    switch (this.selectedStaffMenuType) {
      case ADMIN_MENU_TYPES.ALL:
        this.setSelectedStaffDisplay()
        this.inputParams.staffs = []
        this.inputParams.staffMenuTypes = this.selectedStaffMenuType
        this.reloadTasks()
        break
      case ADMIN_MENU_TYPES.EACH:
        this.openStaffSelectModal()
        break
      default: assertExhaustive(this.selectedStaffMenuType)
    }
  }

  changePeriod(accrualDate:Period, deadline:Period): void {
    this.selectedAccrualDate = accrualDate
    this.selectedDeadline = deadline
    this.inputParams.postedDate = accrualDate
    this.inputParams.deadline = deadline
    if (!this.tasksSearchPostRequestParam) return
    this.tasksSearchPostRequestParam.postedDate = this.selectedAccrualDate
    this.tasksSearchPostRequestParam.deadline = this.selectedDeadline
    this.reloadTasks()
  }

  adminSelectModalKey = generateUuid()
  isAdminSelectModalVisible = false
  openStaffSelectModal():void {
    this.adminSelectModalKey = generateUuid()
    this.isAdminSelectModalVisible = true
  }

  sortItems = {
    accrualDate: {
      text: '発生日が新しい順',
      label: '発生日が新しい順'
    },
    deadline: {
      text: '期日が古い順',
      label: '期日が古い順'
    }
  }

  async createOnlineResolution(ticketTask: ListResponseTaskDto): Promise<void> {
    if (!ticketTask.building) return
    await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(ticketTask.building.buildingId))
    currentStateModule.setCurrentBuilding(ticketTask.building.buildingId)
    this.$router.push({ name: staticRoutes.onlineResolutionCreate.name, query: { ticketId: ticketTask.ticket.ticketId } })
  }

  async createGeneralMeetingResolution(ticketTask: ListResponseTaskDto): Promise<void> {
    if (!ticketTask.building) return
    await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(ticketTask.building.buildingId))
    currentStateModule.setCurrentBuilding(ticketTask.building.buildingId)
    this.$router.push({ name: staticRoutes.gmResolutionCreate.name, query: { ticketId: ticketTask.ticket.ticketId } })
  }

  targetUpdateTaskIndex: number | null = null
  targetUpdateTask: ListResponseTaskDto | null = null

  async updateTask(index: number, task: ListResponseTaskDto): Promise<void> {
    this.targetUpdateTaskIndex = index
    this.targetUpdateTask = task

    if (this.targetUpdateTaskIndex === null || this.targetUpdateTask === null) return

    const targetTask = this.tasks[this.targetUpdateTaskIndex]

    const req = new TaskPutRequest(targetTask.ticketTaskId, this.targetUpdateTask.ticketTaskState, this.targetUpdateTask.ticketTaskNameType, this.targetUpdateTask.deadlineDate, targetTask.version)
    // タスクタイトルが手動で変更可能な場合のみタスクタイトルをリクエストに設定する。タスクタイトルが手動で変更可能な場合以外はタスクタイトル種別に紐づくタスクタイトルをAPIにて登録する
    if (canEditTicketTaskName(this.targetUpdateTask.ticketTaskNameType)) {
      req.ticketTaskName = this.targetUpdateTask.ticketTaskName
    }

    await tasksModule.putTasks(req)
    const res = tasksModule.putTaskResponse(targetTask.ticketTaskId)
    if (!res) return

    // 画面の再読み込みはせず、必要な部分だけクライアント側で更新する
    targetTask.ticketTaskState = this.targetUpdateTask.ticketTaskState
    targetTask.ticketTaskNameType = this.targetUpdateTask.ticketTaskNameType
    targetTask.ticketTaskName = canEditTicketTaskName(this.targetUpdateTask.ticketTaskNameType) ? this.targetUpdateTask.ticketTaskName : getTicketTaskNameTypeLabel(this.targetUpdateTask.ticketTaskType) // タスクタイトルが変えられないタスク場合は、タスク種別に紐付くタスクタイトルをセットする
    targetTask.deadline = this.targetUpdateTask.deadline
    targetTask.deadlineDate = this.targetUpdateTask.deadlineDate
    targetTask.version = res.version
    targetTask.isExpired = false // タスク更新後は期限切れフラグをクリアする
    targetTask.isEditMode = false

    this.targetUpdateTaskIndex = null
    this.targetUpdateTask = null
  }

  isCompleteDialogVisible = false
  targetCompleteTaskIndex: number | null = null
  targetCompleteTask: ListResponseTaskDto | null = null

  openCompleteConfirmationDialog(ticketTask: ListResponseTaskDto, index: number): void {
    this.targetCompleteTaskIndex = index
    this.targetCompleteTask = ticketTask
    this.isCompleteDialogVisible = true
  }

  async changeStateComplete(): Promise<void> {
    this.isCompleteDialogVisible = false

    if (this.targetCompleteTaskIndex === null || this.targetCompleteTask === null) return

    await this.changeState(this.targetCompleteTask, this.targetCompleteTaskIndex, TICKET_TASK_STATES.COMPLETED)

    this.targetCompleteTaskIndex = null
    this.targetCompleteTask = null
  }

  targetNotCompleteTaskIndex: number | null = null
  targetNotCompleteTask: ListResponseTaskDto | null = null

  async changeStateNotComplete(ticketTask: ListResponseTaskDto, index: number): Promise<void> {
    this.targetNotCompleteTaskIndex = index
    this.targetNotCompleteTask = ticketTask

    if (this.targetNotCompleteTask === null || this.targetNotCompleteTaskIndex === null) return

    await this.changeState(this.targetNotCompleteTask, this.targetNotCompleteTaskIndex, TICKET_TASK_STATES.NOT_COMPLETED)

    this.targetNotCompleteTaskIndex = null
    this.targetNotCompleteTask = null
  }

  isUnnecessaryDialogVisible = false
  targetUnnecessaryTaskIndex: number | null = null
  targetUnnecessaryTask: ListResponseTaskDto | null = null

  openUnnecessaryConfirmationDialog(ticketTask: ListResponseTaskDto, index: number): void {
    this.targetUnnecessaryTaskIndex = index
    this.targetUnnecessaryTask = ticketTask
    this.isUnnecessaryDialogVisible = true
  }

  async changeStateUnnecessary(): Promise<void> {
    this.isUnnecessaryDialogVisible = false
    if (this.targetUnnecessaryTaskIndex === null || this.targetUnnecessaryTask === null) return

    await this.changeState(this.targetUnnecessaryTask, this.targetUnnecessaryTaskIndex, TICKET_TASK_STATES.UNNECESSARY)

    this.targetUnnecessaryTaskIndex = null
    this.targetUnnecessaryTask = null
  }

  async changeState(ticketTask: ListResponseTaskDto, index: number, state: TicketTaskState): Promise<void> {
    // タスクステータス更新
    const req = new TaskStatePutRequest(ticketTask.ticketTaskId, state, ticketTask.version)
    await tasksModule.putTaskStates(req)

    // タスクステータス更新結果を反映する
    const res = tasksModule.putTaskStateResponse(ticketTask.ticketTaskId)
    if (!res) return
    ticketTask.ticketTaskState = res.taskState
    ticketTask.version = res.version
    ticketTask.completedAt = res.completedAt
    Vue.set(this.tasks, index, ticketTask)
  }

  isOnlineCheckResolutionDraft = false
  isGmResolutionDraft = false

  async goParentTicket(task:ListResponseTaskDto):Promise<void> {
    this.$router.push({ name: staticRoutes.ticketDetail.name, params: { ticketId: task.ticket.ticketId } })
  }

  goContent(ticketTask: ListResponseTaskDto, inquirySourceIndex: number): void {
    if (!ticketTask.building || !ticketTask.inquirySources || !ticketTask.inquirySources[inquirySourceIndex]) return
    this.openInquirySourcePage(ticketTask.building.buildingId, ticketTask.inquirySources[inquirySourceIndex])
  }

  openInquirySourcePage(buildingId: string, inquirySource: TaskInquirySource): void {
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId

    switch (inquirySource.inquirySourceType) {
      case TASK_INQUIRY_SOURCE_TYPES.OWNER_IDEA: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.IDEA.OWNER
        newTabTransitionParams.ideaId = inquirySource.inquirySourceId
        break
      }
      case TASK_INQUIRY_SOURCE_TYPES.ADMIN_IDEA: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.IDEA.ADMIN
        newTabTransitionParams.ideaId = inquirySource.inquirySourceId
        break
      }
      case TASK_INQUIRY_SOURCE_TYPES.CONSULTATION: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.QA
        newTabTransitionParams.userId = inquirySource.inquirySourceId
        break
      }
      case TASK_INQUIRY_SOURCE_TYPES.GM_RESOLUTION: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.RESOLUTION.GENERAL_MEETING
        newTabTransitionParams.resolutionId = inquirySource.inquirySourceId
        break
      }
      case TASK_INQUIRY_SOURCE_TYPES.ONLINE_RESOLUTION: {
        newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.RESOLUTION.ONLINE
        newTabTransitionParams.resolutionId = inquirySource.inquirySourceId
        break
      }
      default: return assertExhaustive(inquirySource.inquirySourceType)
    }

    openNewTab(newTabTransitionParams)
  }

  goBuilding(ticketTask:ListResponseTaskDto): void {
    if (!ticketTask.building) return
    this.openBuildingDetailPage(ticketTask.building.buildingId)
  }

  openBuildingDetailPage(buildingId: string): void {
    // 該当のマンション詳細画面を別タブで開く
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId
    openNewTab(newTabTransitionParams)
  }

  async createFromDraft(ticketTask: ListResponseTaskDto):Promise<void> {
    if (!ticketTask.resolution) return
    if (!ticketTask.building) return
    await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(ticketTask.building.buildingId))
    currentStateModule.setCurrentBuilding(ticketTask.building.buildingId)

    switch (ticketTask.resolution.resolutionType) {
      case RESOLUTION_TYPES.GENERAL_MEETING:
        if (ticketTask.resolution.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.DRAFT) {
          this.$router.push({ name: staticRoutes.gmResolutionCreate.name, query: { resolutionId: ticketTask.resolution.resolutionId, ticketId: ticketTask.ticket.ticketId } })
        }
        break
      case RESOLUTION_TYPES.ONLINE:
        if (ticketTask.resolution.resolutionState === RESOLUTION_STATES.ONLINE.DRAFT) {
          this.$router.push({ name: staticRoutes.onlineResolutionCreate.name, query: { resolutionId: ticketTask.resolution.resolutionId, ticketId: ticketTask.ticket.ticketId } })
        }
        break
      default: return assertExhaustive(ticketTask.resolution.resolutionType)
    }
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
    this.isWaitingSwitch = false

    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }
}
