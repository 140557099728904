
























import { Vue, Component, Model } from 'vue-property-decorator'
import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { MaterialFormInputDto } from '@/dtos/commons'

@Component({
  components: {
    SmAvatar: () => import('@/components/atoms/SmAvatar.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
  }
})
export default class SmMaterialInputIcon extends Vue {
  @Model('change', { default: null })
  material?: MaterialFormInputDto | null

  readonly INPUT_REF = 'uploader'
  selectedFile: File | undefined

  async onFileSelected(event: InputEvent): Promise<void> {
    const file = (event.target as HTMLInputElement)?.files?.item(0)
    if (!file || !file.type.startsWith('image/')) {
      this.$emit('failed')
      return
    }

    const materialUrl = await this.toDataUrl(file)
    const p: Partial<MaterialFormInputDto> = { file, materialType: MATERIAL_TYPES.IMAGE, materialUrl, originalFileName: file.name }
    const newMaterial = Object.assign(new MaterialFormInputDto(), p)

    this.$emit('change', newMaterial)
  }

  toDataUrl(file: File): Promise<string> {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = f => resolve(f.target?.result as string)
      reader.readAsDataURL(file)
    })
  }
}
