import { render, staticRenderFns } from "./HelloWorldPage3.vue?vue&type=template&id=524b7822&scoped=true&"
import script from "./HelloWorldPage3.vue?vue&type=script&lang=ts&"
export * from "./HelloWorldPage3.vue?vue&type=script&lang=ts&"
import style0 from "./HelloWorldPage3.vue?vue&type=style&index=0&id=524b7822&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "524b7822",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDivider,VImg,VSimpleCheckbox,VSpacer})
