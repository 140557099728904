import { Type } from 'class-transformer'

export class Plan {
  planId!: string
  isAgreed!: boolean
}

export class Agreement {
  userId!: string
  @Type(() => Plan) plans: Plan[] = []
}

export class AdminIdeaAgreementsPutRequest {
  ideaId!: string
  @Type(() => Agreement)agreements: Agreement[] = []
}
