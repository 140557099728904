













































































































































































import { Component, Vue, Prop, Model } from 'vue-property-decorator'

import { Header, TableItemInterface } from '@/components/molecules/SmTableData.vue'
import { TEMPLATE_STATE, TEMPLATE_TYPES } from '@/constants/schema-constants'
import type { TemplateState } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import { QuestionnaireTemplatesDeleteRequest } from '@/dtos/questionnaires/templates/delete'
import { QuestionnaireTemplateDraftDeleteRequest } from '@/dtos/questionnaires/templates/draft/delete'
import { QuestionnaireTemplate, QuestionnaireTemplatesGetRequest } from '@/dtos/questionnaires/templates/get'
import { getQuestionnaireTemplateBadge } from '@/libs/type-handler'
import { questionnaireTemplatesModule } from '@/stores/questionnaire-templates-store'

const TEMPLATE_MENU_ITEMS = {
  EDIT: {
    id: 'EDIT',
    text: '編集',
    label: ''
  },
  REPLICATE: {
    id: 'REPLICATE',
    text: '複製して新規のテンプレートを作成',
    label: ''
  },
  DELETE: {
    id: 'DELETE',
    text: '削除',
    label: ''
  },
} as const
type TemplateMenuId = keyof typeof TEMPLATE_MENU_ITEMS

const TEMPLATE_DRAFT_MENU_ITEMS = {
  DRAFT: {
    id: 'DRAFT',
    text: '編集',
    label: ''
  },
  DELETE: {
    id: 'DELETE',
    text: '削除',
    label: ''
  },
} as const
type TemplateDraftMenuId = keyof typeof TEMPLATE_DRAFT_MENU_ITEMS

class QuestionnaireTemplateItem extends QuestionnaireTemplate implements TableItemInterface {
  icon!:string
  iconColor!:string
  textStyle!:string
  hideItem = false
  checkboxDisabled!: boolean
}

@Component({
  components: {
    SmBadgeCategory: () => import('@/components/atoms/SmBadgeCategory.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class QuestionnaireTemplateSelectModal extends Vue {
  @Model('change')
  private readonly isVisible!: boolean

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!: string

  @Prop({ default: true })
  isCreateQuestionnaire!: boolean

  // 検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!: string

  @Prop({ default: () => [] })
  selectedTemplateIds!: string[]

  @Prop({ default: false })
  singleSelect?: boolean

  isDeleteDialogVisible = false

  deleteTemplateId?: string

  private get isDeleteModalVisible(): boolean { return this.isDeleteDialogVisible }
  private set isDeleteModalVisible(newValue: boolean) {
    this.isDeleteDialogVisible = !this.isDeleteDialogVisible
  }

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  CHIP_COLORS = Object.freeze(CHIP_COLORS)
  TEMPLATE_STATE = Object.freeze(TEMPLATE_STATE)
  TEMPLATE_TYPES = Object.freeze(TEMPLATE_TYPES)

  private get questionnaireTemplateItems(): QuestionnaireTemplateItem[] {
    const questionnaireTemplateItems = questionnaireTemplatesModule.templatesGet.templates
    if (!questionnaireTemplateItems) return []
    return questionnaireTemplateItems.map(e => {
      const dto = new QuestionnaireTemplateItem()
      dto.templateId = e.templateId
      dto.sectionsNumber = e.sectionsNumber
      dto.templateName = e.templateName
      dto.templateType = e.templateType
      dto.templateState = e.templateState
      dto.usageNote = e.usageNote
      dto.checkboxDisabled = this.isDraft(e.templateState)

      const badge = getQuestionnaireTemplateBadge(e.templateType)
      dto.icon = badge.icon
      dto.iconColor = badge.iconColor
      dto.textStyle = badge.textStyle
      return dto
    })
  }

  private isDraft(state: TemplateState): boolean {
    return state === TEMPLATE_STATE.DRAFT
  }

  headers : Header[] = [
    new Header({ text: 'テンプレートタイトル', value: 'templateName', width: '218px' }),
    new Header({ text: '', value: 'templateState', width: '100px' }),
    new Header({ text: '利用メモ', value: 'usageNote', filterable: true }),
    new Header({ text: '回答セクション数', value: 'sectionsNumber', filterable: false, width: '200px' })
  ]

  private templateId: string[] = []

  chipText(state: TemplateState): string {
    if (state === TEMPLATE_STATE.DRAFT) return '下書き'
    return ''
  }

  created(): void {
    this.templateId = this.selectedTemplateIds
  }

  search():void {
    this._keyword = this._inputText
  }

  private get isSelected(): boolean {
    if (!this.templateId || this.templateId.length < 1) return false
    return !!this.templateId[0]
  }

  templateMenu = ''
  TEMPLATE_MENU_ITEMS = Object.freeze(TEMPLATE_MENU_ITEMS)
  TEMPLATE_DRAFT_MENU_ITEMS = Object.freeze(TEMPLATE_DRAFT_MENU_ITEMS)

  onClickSelectBtn():void {
    this.$emit('select-template', this.templateId)
    this.isModalVisible = false
  }

  onClickCreateTemplateBtn():void {
    this.$emit('create-template')
    this.isModalVisible = false
  }

  async onClickDelete(): Promise<void> {
    if (!this.deleteTemplateId) return
    let req = new QuestionnaireTemplatesDeleteRequest(this.deleteTemplateId)
    this.isDeleteModalVisible = false

    const template = questionnaireTemplatesModule.templatesGet.templates.find(t => t.templateId === this.deleteTemplateId)
    if (template && template.templateState === TEMPLATE_STATE.DRAFT) {
      req = new QuestionnaireTemplateDraftDeleteRequest(this.deleteTemplateId)
      await questionnaireTemplatesModule.deleteQuestionnaireTemplateDraft(req)
    } else {
      await questionnaireTemplatesModule.deleteQuestionnaireTemplate(req)
    }
    await questionnaireTemplatesModule.fetchQuestionnaireTemplates(new QuestionnaireTemplatesGetRequest(0, 999))
  }

  onClickMenu(questionnaireTemplateId:string, menuId:TemplateMenuId | TemplateDraftMenuId): void {
    switch (menuId) {
      case TEMPLATE_MENU_ITEMS.EDIT.id:
        this.$emit('edit-template', questionnaireTemplateId)
        this.isModalVisible = false
        break
      case TEMPLATE_MENU_ITEMS.REPLICATE.id:
        this.$emit('replicate-template', questionnaireTemplateId)
        this.isModalVisible = false
        break
      case TEMPLATE_DRAFT_MENU_ITEMS.DRAFT.id:
        this.$emit('create-template', questionnaireTemplateId)
        this.isModalVisible = false
        break
      case TEMPLATE_MENU_ITEMS.DELETE.id || TEMPLATE_DRAFT_MENU_ITEMS.DELETE.id:
        this.deleteTemplateId = questionnaireTemplateId
        this.isDeleteDialogVisible = true
        break
    }
  }
}
