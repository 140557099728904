import { APIClientBase } from './api-client-base'
import { MyProfileGetResponse } from '@/dtos/my-profile/get'
import { MyProfilePutRequest } from '@/dtos/my-profile/put'

class MyProfileClient extends APIClientBase {
  constructor() {
    super('/my/profile')
  }

  getMyProfile(): Promise<MyProfileGetResponse> {
    return this.get('', '', MyProfileGetResponse)
  }

  async putMyProfile(req: MyProfilePutRequest): Promise<void> {
    await this.put('', req)
  }
}

export const myProfileClient = new MyProfileClient()
