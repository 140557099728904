
















































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { adminIdeasModule } from '@/stores/admin-ideas-store'
import { AdminIdeaOpinionsGetRequest, AdminIdeaOpinionsGetResponse, Opinion } from '@/dtos/ideas/admin-idea/opinions/get'
import { BaseOpinion } from '@/dtos/commons'
import type { OpinionState } from '@/constants/schema-constants'
import { OPINION_STATES } from '@/constants/schema-constants'
import { getDeletedMessage } from '@/libs/opinion-state-handler'
import { generateUuid } from '@/libs/uuid-generator'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),
    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class OpinionPostUserSelectModal extends Vue {
  OPINION_STATES = OPINION_STATES

  private getDeletedMessage(state: OpinionState):string | undefined {
    return getDeletedMessage(state)
  }

  private isVisible = false
  private modalKey = generateUuid()
  private isAlternative = false
  private isAvailable = false
  private selected: string[] = []
  private param = new AdminIdeaOpinionsGetRequest()

  private get storeResponse(): AdminIdeaOpinionsGetResponse { return adminIdeasModule.adminIdeasOpinionsGet(this.ideaId) ?? new AdminIdeaOpinionsGetResponse() }
  private allOpinions: Opinion[] | undefined = []

  @Prop({ required: true, default: '' })
  private readonly ideaId!: string

  @Prop()
  private readonly baseOpinions!: BaseOpinion[] | undefined

  private get hasOpinion(): boolean {
    return this.storeResponse.opinions && this.storeResponse.opinions.length > 0
  }

  private get isFilterMessage(): boolean {
    return this.isAlternative || this.isAvailable
  }

  private get _selectedNames(): string | undefined {
    if (!this.selected.length || !this.allOpinions) return

    // 選択した意見投稿者を住戸番号でソート
    const sortedOpinions: Opinion[] = this.allOpinions.filter(e => this.selected.includes(e.opinionId)).sort((a, b) => {
      if (!a.ownerUser.roomNumber) return -1
      if (!b.ownerUser.roomNumber) return 1

      // 部屋番号が数値でない場合（例：A101/A102/A103、101号室/102号室/103号室）はABC順で並び替え
      if (isNaN(Number(a.ownerUser.roomNumber)) || isNaN(Number(b.ownerUser.roomNumber))) {
        if (a.ownerUser.roomNumber > b.ownerUser.roomNumber) {
          return 1
        }
        return -1
      }

      // 部屋番号が数値の場合、数字順で並び替え
      if (Number(a.ownerUser.roomNumber) > Number(b.ownerUser.roomNumber)) {
        return 1
      }
      return -1
    })
    return sortedOpinions.map(e => e.ownerUser.roomNumber + ' ' + e.ownerUser.userName).join('、')
  }

  async showModal(): Promise<void> {
    this.isAlternative = false
    this.isAvailable = false

    // 選択した意見投稿者表示用に裏で保持するための全意見データを取得
    const param = new AdminIdeaOpinionsGetRequest()
    param.take = 999
    param.ideaId = this.ideaId
    param.isAlternative = false
    param.isAvailable = false
    await adminIdeasModule.fetchIdeaOpinions(param)
    this.allOpinions = adminIdeasModule.adminIdeasOpinionsGet(this.ideaId)?.opinions

    // 画面初期表示用のデータを取得
    this.modalKey = generateUuid() // 要素の再描画
    this.param.take = 999
    this.param.ideaId = this.ideaId
    this.param.isAlternative = this.isAlternative
    this.param.isAvailable = this.isAvailable
    this.selected = this.baseOpinions?.map(b => b.opinionId) ?? []
    await adminIdeasModule.fetchIdeaOpinions(this.param)

    this.isVisible = true
  }

  async onChangeAlternativeFilter(): Promise<void> {
    this.param.isAlternative = this.isAlternative
    await adminIdeasModule.fetchIdeaOpinions(this.param)
    this.selected = this.getOpinionIdIncludeFilterOpinions()
  }

  async onChangeAvailableFilter(): Promise<void> {
    this.param.isAvailable = this.isAvailable
    await adminIdeasModule.fetchIdeaOpinions(this.param)
    this.selected = this.getOpinionIdIncludeFilterOpinions()
  }

  // 選択済みの意見が、絞り込み後の選択肢に含まれる意見のみを返す
  private getOpinionIdIncludeFilterOpinions(): string[] {
    const opinionIds = this.storeResponse.opinions.map(res => res.opinionId)
    return this.selected.filter(s => opinionIds.includes(s))
  }

  private onClickUserSelectedBtn(): void {
    const baseOpinions: BaseOpinion[] = this.selected.map(opinionId => {
      const baseOpinion = new BaseOpinion()
      const user = this.storeResponse.opinions.find(o => o.opinionId === opinionId)
      baseOpinion.opinionId = opinionId
      if (user) baseOpinion.ownerUser = user.ownerUser
      return baseOpinion
    })
    this.$emit('select-opinion-user', baseOpinions)
    this.isVisible = false
  }
}
