



























































































import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import type { TransitionTo } from '@/constants/schema-constants'
import { TRANSITION_TO } from '@/constants/schema-constants'
import { Tab } from '@/components/molecules/SmTabsInPage.vue'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmTabsInPage: () => import('@/components/molecules/SmTabsInPage.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),

    AdminIdeaSelectModalContent: () => import('@/pages/owner-notifications/transition-to-select/admin-ideas/AdminIdeaSelectModalContent.vue'),
    GmResolutionSelectModalContent: () => import('@/pages/owner-notifications/transition-to-select/gm-resolutions/GmResolutionSelectModalContent.vue'),
    OnlineResolutionSelectModalContent: () => import('@/pages/owner-notifications/transition-to-select/online-resolutions/OnlineResolutionSelectModalContent.vue'),
    OwnerIdeaSelectModalContent: () => import('@/pages/owner-notifications/transition-to-select/owner-ideas/OwnerIdeaSelectModalContent.vue'),
  }
})

export default class OwnerGroupSelectModal extends Vue {
  @Model('change', { required: true, default: false })
  private readonly isVisible!: boolean

  // 物件ID
  @Prop({ required: true, default: '' })
  buildingId!: string

  // 選択中のタブ(110, 120, 130, 140のいずれか) => お知らせ編集時に使用
  @Prop({ default: TRANSITION_TO.IDEA })
  transitionType!: TransitionTo

  // 選択中のプラン/決議のID(遷移先タイプに応じたID) => お知らせ編集時に使用
  @Prop()
  targetId?: string

  // アイデアタブのtextfieldに入力された値
  @Prop({ default: '' })
  ownerIdeaInputText!: string

  // アイデアタブの検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  ownerIdeaKeyword!: string

  // プランタブのtextfieldに入力された値
  @Prop({ default: '' })
  adminIdeaInputText!: string

  // プランタブの検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  adminIdeaKeyword!: string

  // オンライン決議タブのtextfieldに入力された値
  @Prop({ default: '' })
  onlineResolutionInputText!: string

  // オンライン決議タブの検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  onlineResolutionKeyword!: string

  // 総会決議タブのtextfieldに入力された値
  @Prop({ default: '' })
  gmResolutionInputText!: string

  // 総会決議タブの検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  gmResolutionKeyword!: string

  // 表示中のタブで選択されているID
  selectedTargetId = ''

  // アイデアタブで選択中のアイデアID
  ownerIdeaId = ''

  // プランタブで選択中のアイデアID
  adminIdeaId = ''

  // オンライン決議タブで選択中の決議ID
  onlineResolutionId = ''

  // 総会決議タブで選択中の決議ID
  gmResolutionId = ''

  // アイデアタブで選択された場合にIDを設定する
  selectOwnerIdea(ideaId: string): void {
    this.ownerIdeaId = ideaId
    this.selectedTargetId = ideaId
  }

  // プランタブで選択された場合にIDを設定する
  selectAdminIdea(ideaId: string): void {
    this.adminIdeaId = ideaId
    this.selectedTargetId = ideaId
  }

  // オンライン決議タブで選択された場合にIDを設定する
  selectOnlineResolution(resolutionId: string): void {
    this.onlineResolutionId = resolutionId
    this.selectedTargetId = resolutionId
  }

  // 総会決議タブで選択された場合にIDを設定する
  selectGmResolution(resolutionId: string): void {
    this.gmResolutionId = resolutionId
    this.selectedTargetId = resolutionId
  }

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get _ownerIdeaInputText(): string { return this.ownerIdeaInputText }
  private set _ownerIdeaInputText(newValue: string) { this.$emit('update:ownerIdeaInputText', newValue) }

  private get _ownerIdeaKeyword(): string { return this.ownerIdeaKeyword }
  private set _ownerIdeaKeyword(newValue: string) { this.$emit('update:ownerIdeaKeyword', newValue) }

  private get _adminIdeaInputText(): string { return this.adminIdeaInputText }
  private set _adminIdeaInputText(newValue: string) { this.$emit('update:adminIdeaInputText', newValue) }

  private get _adminIdeaKeyword(): string { return this.adminIdeaKeyword }
  private set _adminIdeaKeyword(newValue: string) { this.$emit('update:adminIdeaKeyword', newValue) }

  private get _onlineResolutionInputText(): string { return this.onlineResolutionInputText }
  private set _onlineResolutionInputText(newValue: string) { this.$emit('update:onlineResolutionInputText', newValue) }

  private get _onlineResolutionKeyword(): string { return this.onlineResolutionKeyword }
  private set _onlineResolutionKeyword(newValue: string) { this.$emit('update:onlineResolutionKeyword', newValue) }

  private get _gmResolutionInputText(): string { return this.gmResolutionInputText }
  private set _gmResolutionInputText(newValue: string) { this.$emit('update:gmResolutionInputText', newValue) }

  private get _gmResolutionKeyword(): string { return this.gmResolutionKeyword }
  private set _gmResolutionKeyword(newValue: string) { this.$emit('update:gmResolutionKeyword', newValue) }

  tabs: Tab[] = [
    new Tab(TRANSITION_TO.IDEA.OWNER.toString(), 'アイデア'),
    new Tab(TRANSITION_TO.IDEA.ADMIN.toString(), 'プラン'),
    new Tab(TRANSITION_TO.RESOLUTION.ONLINE.toString(), 'オンライン決議'),
    new Tab(TRANSITION_TO.RESOLUTION.GENERAL_MEETING.toString(), '総会決議'),
  ]

  currentTab = this.transitionType.toString()

  onClickBtn(): void {
    const selectedTransitionType = this.convertTransitionType(this.currentTab)
    this.selectedTargetId = this.getTargetIdOnCurrentTab(selectedTransitionType)
    this.$emit('select', { targetType: selectedTransitionType, targetId: this.selectedTargetId })
    this.isModalVisible = false
  }

  @Watch('isModalVisible', { immediate: true })
  applyTransitionToTargetId(isModalVisible: boolean): void {
    if (isModalVisible && this.targetId) {
      // 選択されているグループに応じてIDを設定する
      switch (this.transitionType) {
        case TRANSITION_TO.IDEA.OWNER:
          this.ownerIdeaId = this.targetId
          break
        case TRANSITION_TO.IDEA.ADMIN:
          this.adminIdeaId = this.targetId
          break
        case TRANSITION_TO.RESOLUTION.ONLINE:
          this.onlineResolutionId = this.targetId
          break
        case TRANSITION_TO.RESOLUTION.GENERAL_MEETING:
          this.gmResolutionId = this.targetId
          break
        case TRANSITION_TO.QA:
        case TRANSITION_TO.REPORT:
        case TRANSITION_TO.TICKET.DETAIL:
        case TRANSITION_TO.TICKET.DETAIL_TASK:
        case TRANSITION_TO.NOTICE:
        case TRANSITION_TO.STAFF_DETAIL.FRONT:
        case TRANSITION_TO.STAFF_DETAIL.LIFE_MANAGER:
        case TRANSITION_TO.CASYS_RESULTS:
          // transitionTypeに 110, 120, 130, 140以外が指定された場合はエラーとする
          throw new Error('The transition type(' + this.transitionType + ') is unavailable')
        default: return assertExhaustive(this.transitionType)
      }
    }
  }

  @Watch('currentTab', { immediate: true })
  onCurrentTabChanged(newValue: string): void {
    // 選択中のタブに合わせて状態を更新する
    const selectedTransitionType = this.convertTransitionType(newValue)
    this.selectedTargetId = this.getTargetIdOnCurrentTab(selectedTransitionType)
  }

  private convertTransitionType(transitionTypeStr: string) : TransitionTo {
    switch (Number(transitionTypeStr)) {
      case TRANSITION_TO.IDEA.OWNER: return TRANSITION_TO.IDEA.OWNER
      case TRANSITION_TO.IDEA.ADMIN: return TRANSITION_TO.IDEA.ADMIN
      case TRANSITION_TO.RESOLUTION.ONLINE: return TRANSITION_TO.RESOLUTION.ONLINE
      case TRANSITION_TO.RESOLUTION.GENERAL_MEETING: return TRANSITION_TO.RESOLUTION.GENERAL_MEETING
      case TRANSITION_TO.QA:
      case TRANSITION_TO.REPORT:
        // transitionTypeに 110, 120, 130, 140以外が指定された場合はエラーとする
        throw new Error('The transition type(' + transitionTypeStr + ') is unavailable')
      default: return TRANSITION_TO.IDEA.OWNER
    }
  }

  private getTargetIdOnCurrentTab(transitionType: TransitionTo) : string {
    switch (transitionType) {
      case TRANSITION_TO.IDEA.OWNER: return this.ownerIdeaId
      case TRANSITION_TO.IDEA.ADMIN: return this.adminIdeaId
      case TRANSITION_TO.RESOLUTION.ONLINE: return this.onlineResolutionId
      case TRANSITION_TO.RESOLUTION.GENERAL_MEETING: return this.gmResolutionId
      case TRANSITION_TO.QA:
      case TRANSITION_TO.REPORT:
      case TRANSITION_TO.TICKET.DETAIL:
      case TRANSITION_TO.TICKET.DETAIL_TASK:
      case TRANSITION_TO.NOTICE:
      case TRANSITION_TO.STAFF_DETAIL.FRONT:
      case TRANSITION_TO.STAFF_DETAIL.LIFE_MANAGER:
      case TRANSITION_TO.CASYS_RESULTS:
        // transitionType 110, 120, 130, 140以外が指定された場合はエラーとする
        throw new Error('The transition type(' + transitionType + ') is unavailable')
      default: return assertExhaustive(transitionType)
    }
  }

  private get isSelected(): boolean {
    return !!this.selectedTargetId
  }
}
