import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { PaymentPlanGetRequest, PaymentPlansGetResponse } from '@/dtos/reserve-funds/plans/get'
import { reserveFundsPlansClient } from '@/clients/reserve-funds-plans-client'
import { ReservePlanDetailGetRequest, ReservePlanDetailGetResponse } from '@/dtos/reserve-funds/plans/get-detail'
import { PaymentPlanPostRequest } from '@/dtos/reserve-funds/plans/post'
import { PaymentPlanInitialFormGetResponse } from '@/dtos/reserve-funds/plans/initial-form/get-detail'

@Module({ store, dynamic: true, namespaced: true, name: 'reserveFundPlans' })
class ReserveFundsPlansStore extends VuexModule {
  // 支払いプラン一覧
  private _paymentPlans = new PaymentPlansGetResponse()

  public get paymentPlans():PaymentPlansGetResponse { return this._paymentPlans }

  @Mutation
  private SET_PAYMENT_PLANS(res:PaymentPlansGetResponse) { this._paymentPlans = res }

  @Action
  public async fetchPaymentPlans(req:PaymentPlanGetRequest) {
    const res = await reserveFundsPlansClient.getReservePlans(req)
    this.SET_PAYMENT_PLANS(res)
  }

  // 支払いプラン登録
  @Action
  public async postReservePlan(req:PaymentPlanPostRequest):Promise<void> {
    await reserveFundsPlansClient.postReservePlan(req)
  }

  // 支払いプラン取得初期フォーム取得
  private _reservePlanInitialForm = new PaymentPlanInitialFormGetResponse()

  public get reservePlanInitialForm():PaymentPlanInitialFormGetResponse {
    return this._reservePlanInitialForm
  }

  @Mutation
  private SET_RESERVE_PLAN_INITIAL_FORM(res:PaymentPlanInitialFormGetResponse) {
    this._reservePlanInitialForm = res
  }

  @Action
  public async fetchReservePlanInitialForm() {
    const res = await reserveFundsPlansClient.getReservePlansInitialForm()
    this.SET_RESERVE_PLAN_INITIAL_FORM(res)
  }

  // 支払いプラン詳細
  private _paymentPlanDetails: Record<string, ReservePlanDetailGetResponse> = {}

  public get paymentPlanDetail():(id: string) => ReservePlanDetailGetResponse | undefined { return (id: string) => this._paymentPlanDetails[id] }

  @Mutation
  private SET_PAYMENT_PLAN_DETAIL(res:ReservePlanDetailGetResponse) {
    Vue.set(this._paymentPlanDetails, res.paymentPlanId, res)
  }

  @Action
  public async fetchReservePlanDetail(req:ReservePlanDetailGetRequest) {
    const res = await reserveFundsPlansClient.getReservePlanDetail(req)
    this.SET_PAYMENT_PLAN_DETAIL(res)
  }
}

export const reserveFundsPlansModule = getModule(ReserveFundsPlansStore)
