
















































































import { Vue, Component, Prop } from 'vue-property-decorator'

import { concatSerialChar } from '@/libs/add-alphabet-provider'

import { Section } from '@/dtos/commons'
import { ResultAgreement } from '@/dtos/ideas/admin-idea/results/get'
import { RadioOption } from '@/components/atoms/SmRadio.vue'
import type { IdeaState } from '@/constants/schema-constants'
import { IDEA_STATES } from '@/constants/schema-constants'

export const RADIO_OPTION_FAILURE_VALUE = 'failure'
export class ResultEditItem {
  ideaState!: IdeaState
  itemBody!: string
  planId!: string
  hasResult!: boolean
  sections: Section[] = []
  agreements: ResultAgreement[] = []
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardSectionData: () => import('@/components/molecules/card/SmCardSectionData.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
  }
})
export default class AgreementsEditResult extends Vue {
  IDEA_STATES = Object.freeze(IDEA_STATES)

  @Prop({ required: true, default: 0 /* どれでもない */ })
  private readonly ideaState!: IdeaState

  @Prop({ default: '' })
  private readonly itemBody!: string

  @Prop({ default: '' })
  private readonly planId!: string

  @Prop({ required: true, default: false })
  private readonly hasResult!: boolean

  @Prop({ default: () => [] })
  private readonly sections!: Section[]

  @Prop({ required: true, default: () => [] })
  private readonly agreements!: ResultAgreement[]

  private get radioOptions(): RadioOption[] {
    return [...this.agreements.map((a, idx) => new RadioOption(this.getPlanTitleWithPrefix(a.planTitle, this.agreements.length, idx), a.planId)), new RadioOption('不成立', RADIO_OPTION_FAILURE_VALUE)]
  }

  private get _planId(): string {
    return (this.hasResult && !this.planId) ? 'failure' : this.planId
  }

  private set _planId(newValue: string) {
    this.$emit('update:planId', newValue)
  }

  private get _itemBody(): string { return this.itemBody }
  private set _itemBody(newValue: string) {
    this.$emit('update:itemBody', newValue)
  }

  private getPlanTitleWithPrefix(planTitle:string, planLength:number, planIndex: number): string {
    return `【プラン${concatSerialChar(planLength, planIndex)}】${planTitle}`
  }
}
