import { CHIP_COLORS } from './ux-constants'

export const ADMIN_ROLE = {
  SERVICE_STAFF: 1,
  LIFE_MANAGER: 2,
  ACCOUNTING_DEPARTMENT: 3,
  FRONT: 4,
  GROUP_CALL_CENTER: 5,
} as const
export type AdminRole = typeof ADMIN_ROLE[keyof typeof ADMIN_ROLE]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const ADMIN_ROLE_MAP = new Map([
  [ADMIN_ROLE.SERVICE_STAFF, { LABEL: '管理者業務執行者' }],
  [ADMIN_ROLE.LIFE_MANAGER, { LABEL: 'ライフマネージャー' }],
  [ADMIN_ROLE.ACCOUNTING_DEPARTMENT, { LABEL: '組合会計部' }],
  [ADMIN_ROLE.FRONT, { LABEL: 'フロント担当者' }],
  [ADMIN_ROLE.GROUP_CALL_CENTER, { LABEL: '素敵生活サービスデスク' }],
])

export const OWNER_EDIT_PAGE_TYPE = {
  EDIT: 1,
  CHANGE: 2,
} as const
export type OwnerEditPageType = typeof OWNER_EDIT_PAGE_TYPE[keyof typeof OWNER_EDIT_PAGE_TYPE]

/**
 * 初回認証状態
 */
export const INITIAL_AUTH_STATE = {
  UNPROCESSED: 1,
  COMPLETED: 9
} as const
export type InitialAuthState = typeof INITIAL_AUTH_STATE[keyof typeof INITIAL_AUTH_STATE]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const INITIAL_AUTH_STATE_MAP = new Map([
  [INITIAL_AUTH_STATE.UNPROCESSED, { LABEL: '未' }],
  [INITIAL_AUTH_STATE.COMPLETED, { LABEL: '済' }],
])

/**
 * 初回認証コード種別
 */
export const INITIAL_AUTH_CODE_TYPE = {
  SUTEKINET: 1,
  SMOOTH_E: 2,
} as const
export type InitialAuthCodeType = typeof INITIAL_AUTH_CODE_TYPE[keyof typeof INITIAL_AUTH_CODE_TYPE]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const INITIAL_AUTH_CODE_TYPE_MAP = new Map([
  [INITIAL_AUTH_CODE_TYPE.SUTEKINET, { LABEL: '素敵ネット' }],
  [INITIAL_AUTH_CODE_TYPE.SMOOTH_E, { LABEL: 'smooth-e' }],
])

/**
 * 地域種別
 */
export const AREA_TYPE = {
  /**
   * HCM東京
   */
  TOKYO: 1,
  /**
   * HCM関西
   */
  KANSAI: 2,
  /**
   * CMQ（九州）
   */
  CMQ: 3,
  /**
   * CMW（西日本）
   */
  CMW: 4,
  /**
   * CMO（沖縄）
   */
  CMO: 5
} as const

export type AreaType = typeof AREA_TYPE[keyof typeof AREA_TYPE]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const AREA_TYPE_MAP = new Map([
  [AREA_TYPE.TOKYO, { LABEL: 'HCM東京' }],
  [AREA_TYPE.KANSAI, { LABEL: 'HCM関西' }],
  [AREA_TYPE.CMQ, { LABEL: 'CMQ' }],
  [AREA_TYPE.CMW, { LABEL: 'CMW' }],
  [AREA_TYPE.CMO, { LABEL: 'CMO' }],
])

/**
 * アイデア種別
 */
export const IDEA_TYPES = {
  /**
   * アイデア
   */
  OWNER_IDEA: 1,
  /**
   * プラン
   */
  ADMIN_IDEA: 2,
} as const

export type IdeaType = typeof IDEA_TYPES[keyof typeof IDEA_TYPES]

/**
 * アイデア状態
 */
export const IDEA_STATES = {
  /**
    * アイデア
    */
  OWNER: {
    /**
      * 下書き
      */
    DRAFT: 10,
    /**
      * 区分所有者の投稿
      */
    POSTED: 11,
    /**
      * 採用済み
      */
    ADOPTED: 12,
    /**
      * アーカイブ
      */
    REJECTED: 13,
    /**
      * 削除済（by投稿者）
      */
    DELETED_SELF: 18,
    /**
      * 削除済（by管理者）
      */
    DELETED: 19,
  } as const,
  /**
    * プラン
    */
  ADMIN: {
    /**
      * 下書き
      */
    DRAFT: 20,
    /**
      * 賛同受付中
      */
    ACCEPTING_AGREEMENT: 21,
    /**
      * 賛同数集計中
      */
    COUNTING: 22,
    /**
      * 結果公開済み
      */
    RELEASED: 23,
    /**
     * 意見受付中
     */
    ACCEPTING_OPINION: 24,
  } as const,
} as const

export type IdeaState =
    typeof IDEA_STATES.ADMIN[keyof typeof IDEA_STATES.ADMIN]
    |typeof IDEA_STATES.OWNER[keyof typeof IDEA_STATES.OWNER]

/**
 * プランアイテム種別
 */
export const ITEM_TYPES = {
  /**
   * 案
   */
  PLAN: 1,
  /**
   * 集計結果
   */
  PLAN_RESULT: 2,
  /**
   * 意見交換会の案内
   */
  SESSION: 3,
  /**
   * 意見交換会の結果
   */
  SESSION_RESULT: 4,
} as const
export type ItemType = typeof ITEM_TYPES[keyof typeof ITEM_TYPES]

/**
 * 共通関数用のMap
 * ラベルやアーカイブを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const IDEA_STATE_MAP = new Map([
  [IDEA_STATES.OWNER.DRAFT,
    { LABEL: '下書き' }
  ],
  [IDEA_STATES.OWNER.POSTED,
    { LABEL: 'アイデア' }
  ],
  [IDEA_STATES.OWNER.ADOPTED,
    { LABEL: 'アーカイブ' }
  ],
  [IDEA_STATES.OWNER.REJECTED,
    { LABEL: 'アーカイブ' }
  ],
  [IDEA_STATES.OWNER.DELETED_SELF,
    { LABEL: '削除済み' }
  ],
  [IDEA_STATES.OWNER.DELETED,
    { LABEL: '削除済み' }
  ],
  [IDEA_STATES.ADMIN.DRAFT,
    { LABEL: '下書き' }
  ],
  [IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT,
    { LABEL: 'プラン／賛同受付中' }
  ],
  [IDEA_STATES.ADMIN.COUNTING,
    { LABEL: 'プラン／賛同数集計中' }
  ],
  [IDEA_STATES.ADMIN.RELEASED,
    { LABEL: 'アーカイブ' }
  ],
  [IDEA_STATES.ADMIN.ACCEPTING_OPINION,
    { LABEL: 'プラン／意見受付中' }
  ],
])

/**
 * 決議種別
 */
export const RESOLUTION_TYPES = {
  /** 総会 */
  GENERAL_MEETING: 1,
  /** オンライン決議 */
  ONLINE: 2,
} as const

export type ResolutionType = typeof RESOLUTION_TYPES[keyof typeof RESOLUTION_TYPES]

/**
 * 共通関数用のMap
 * ラベルやアーカイブを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const RESOLUTION_TYPE_MAP = new Map([
  [RESOLUTION_TYPES.GENERAL_MEETING,
    { LABEL: '総会決議' }
  ],
  [RESOLUTION_TYPES.ONLINE,
    { LABEL: 'オンライン決議' }
  ],
])

/**
 * 決議状態
*/
export const RESOLUTION_STATES = {
  /**
    * 総会
    */
  GENERAL_MEETING: {
    /**
      * 下書き
      */
    DRAFT: 10,
    /**
      * 意思表明・質問受付中
      */
    ACCEPTING_ALL: 11,
    /**
      * 意思表明受付中、質問締切
      */
    ACCEPTING_STATEMENT: 12,
    /**
      * 結果集計中
      */
    COUNTING: 13,
    /**
      * 結果公開済み
      */
    RELEASED: 14,
    /**
      * 削除済み
      */
    DELETED: 19,
  },
  /**
    * オンライン決議
    */
  ONLINE: {
    /**
      * 下書き
      */
    DRAFT: 20,
    /**
      * 投票・質問受付中
      */
    ACCEPTING_ALL: 21,
    /**
      * 投票受付中、質問締切
      */
    ACCEPTING_VOTE: 22,
    /**
      * 結果集計中
      */
    COUNTING: 23,
    /**
      * 結果公開
      */
    RELEASED: 24,
    /**
      * 削除済み
      */
    DELETED: 29,
  },
} as const

export type ResolutionState =
    typeof RESOLUTION_STATES.GENERAL_MEETING[keyof typeof RESOLUTION_STATES.GENERAL_MEETING]
    |typeof RESOLUTION_STATES.ONLINE[keyof typeof RESOLUTION_STATES.ONLINE]

/**
 * 共通関数用のMap
 * ラベルやアーカイブを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const RESOLUTION_STATE_MAP = new Map([
  [RESOLUTION_STATES.GENERAL_MEETING.DRAFT,
    { LABEL: '下書き' }
  ],
  [RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL,
    { LABEL: '意思表明受付中' }
  ],
  [RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT,
    { LABEL: '意思表明受付中' }
  ],
  [RESOLUTION_STATES.GENERAL_MEETING.COUNTING,
    { LABEL: '結果集計中' }
  ],
  [RESOLUTION_STATES.GENERAL_MEETING.RELEASED,
    { LABEL: 'アーカイブ' }
  ],
  [RESOLUTION_STATES.GENERAL_MEETING.DELETED,
    { } // not use
  ],
  [RESOLUTION_STATES.ONLINE.DRAFT,
    { LABEL: '下書き' }
  ],
  [RESOLUTION_STATES.ONLINE.ACCEPTING_ALL,
    { LABEL: '投票受付中' }
  ],
  [RESOLUTION_STATES.ONLINE.ACCEPTING_VOTE,
    { LABEL: '投票受付中' }
  ],
  [RESOLUTION_STATES.ONLINE.COUNTING,
    { LABEL: '結果集計中' }
  ],
  [RESOLUTION_STATES.ONLINE.RELEASED,
    { LABEL: 'アーカイブ' }
  ],
  [RESOLUTION_STATES.ONLINE.DELETED,
    { } // not use
  ],
])

/**
 * 質問状態
 */
export const QUESTION_STATES = {
  /**
   * 投稿済／編集済
   */
  RAW: 1,
  /**
   * 削除済（by投稿者）
   */
  DELETED_SELF: 3,
  /**
   * 削除済（by管理者）
   */
  DELETED: 4,
} as const

export type QuestionState = typeof QUESTION_STATES[keyof typeof QUESTION_STATES]

/**
 * 議案種別
*/
export const SUBJECT_TYPES = {
  /** 普通決議 */
  ORDINARY: 1,
  /** 特別決議 */
  SPECIAL: 2,
} as const

export type SubjectType = typeof SUBJECT_TYPES[keyof typeof SUBJECT_TYPES]

/**
 * 共通関数用のMap
 * ラベルやアーカイブを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const SUBJECT_TYPES_MAP = new Map([
  [SUBJECT_TYPES.ORDINARY,
    { LABEL: '普通決議' }
  ],
  [SUBJECT_TYPES.SPECIAL,
    { LABEL: '特別決議' }
  ],
])

/**
 * 意思表明種別
*/
export const STATEMENT_TYPES = {
  /** 出席する(会場) */
  PRESENT_LOCAL: 1,
  /** 委任する */
  // DELEGATE: 2, 機能改善⑧ 追加開発No.73の対応で使用しないこととなった
  /** 議決権行使する */
  VOTE: 3,
  /** 出席する(リモート) */
  PRESENT_REMOTE: 4,
} as const

export type StatementType = typeof STATEMENT_TYPES[keyof typeof STATEMENT_TYPES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const STATEMENT_TYPES_MAP = new Map([
  [STATEMENT_TYPES.PRESENT_LOCAL, { LABEL: '会場' }],
  [STATEMENT_TYPES.VOTE, { LABEL: '議決権行使' }],
  [STATEMENT_TYPES.PRESENT_REMOTE, { LABEL: 'リモート' }],
])

/**
 * 投票種別
*/
export const VOTE_TYPES = {
  /** 白票 */
  BLANK: 0,
  /** 賛成 */
  FOR: 1,
  /** 反対 */
  AGAINST: 2,
} as const

export type VoteType = typeof VOTE_TYPES[keyof typeof VOTE_TYPES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const VOTE_TYPE_MAP = new Map([
  [VOTE_TYPES.BLANK, { }],
  [VOTE_TYPES.FOR, { LABEL: '賛成' }],
  [VOTE_TYPES.AGAINST, { LABEL: '反対' }],
])

/**
 * 開催方法種別
*/
export const HOLDING_METHOD_TYPE = {
  /** 会場のみ */
  LOCAL: 1,
  /** リモートのみ */
  REMOTE: 2,
  /** 会場＋リモート両方 */
  BOTH: 3,
} as const

export type HoldingMethodType = typeof HOLDING_METHOD_TYPE[keyof typeof HOLDING_METHOD_TYPE]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const HOLDING_METHOD_TYPE_MAP = new Map([
  [HOLDING_METHOD_TYPE.LOCAL, { LABEL: '会場のみ' }],
  [HOLDING_METHOD_TYPE.REMOTE, { LABEL: 'リモートのみ' }],
  [HOLDING_METHOD_TYPE.BOTH, { LABEL: '会場・リモート両方' }],
])

/**
 * 監査業務種別
 */
export const AUDIT_STATES = {
  /** 外部監査役に委託 */
  ENTRUST_TO_OUTSIDE_AUDITOR: 1,
  /** 組合員から監事を選任 */
  ELECT_INSIDE_AUDITOR: 2,
}

export type AuditState = typeof AUDIT_STATES[keyof typeof AUDIT_STATES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const AUDIT_STATE_MAP = new Map([
  [AUDIT_STATES.ENTRUST_TO_OUTSIDE_AUDITOR, { LABEL: '外部監査役に委託' }],
  [AUDIT_STATES.ELECT_INSIDE_AUDITOR, { LABEL: '組合員から監事を選任' }],
])

/**
 * 素材種別
 */
export const MATERIAL_TYPES = {
  /** 画像 */
  IMAGE: 1,
  /** 動画 */
  VIDEO: 2,
  /** PDF */
  PDF: 3,
  /** Excel */
  EXCEL: 4,
  /** Excel(xls) */
  EXCEL_OLD: 5,
  /** Zip */
  ZIP: 6,
  /** Excel(xlsm) */
  EXCEL_MACRO: 7
} as const

export type MaterialType = typeof MATERIAL_TYPES[keyof typeof MATERIAL_TYPES]

/**
 * 意見状態
 */
export const OPINION_STATES = {
  /**
   * ユーザー側公開中
   */
  RAW: 1,
  /**
   * 上書き（削除扱い）
   */
  OVER_WRITTEN: 2,
  /**
   * ユーザー削除
   */
  OWNER_DELETED: 3,
  /**
   * 管理者削除
   */
  ADMIN_DELETED: 4,
} as const

export type OpinionState = typeof OPINION_STATES[keyof typeof OPINION_STATES]

// 通報一覧／賛同意見受付 意見タブ
export const OPINION_STATES_MAP = new Map<OpinionState, string>([
  [OPINION_STATES.OVER_WRITTEN, '編集上書きにより削除されました'],
  [OPINION_STATES.OWNER_DELETED, '区分所有者により削除されました'],
  [OPINION_STATES.ADMIN_DELETED, '管理者業務執行者により削除されました'],
])

/*
 * 修積：積立計画作成状態
 */
export const RESERVE_PLAN_STATES = {
  /**
   * 未作成
   */
  NOT_CREATED: 0,
  /**
   * 作成済
   */
  CREATED: 1,
  /**
   * 更新可能
   */
  UPDATABLE: 2,
} as const
export type ReservePlanState = typeof RESERVE_PLAN_STATES[keyof typeof RESERVE_PLAN_STATES]

/** 一括支払いプランのプランコード */
export const PAYMENT_CODE_ONE_TIME = '000'

export const CONSULTATION_STATES = {
  /** 投稿済 */
  RAW: 1,
  /** 編集済 */
  MODIFIED: 2,
  /** 区分所有者により削除済 */
  OWNER_DELETED: 3
} as const
export type ConsultationState = typeof CONSULTATION_STATES[keyof typeof CONSULTATION_STATES]

/**
 * お知らせ種別
 */
export const NOTIFICATION_TYPES = {

  /**
   * アイデア系
   */
  IDEA: {
    /**
     * アイデア投稿時
     */
    POSTED: 1,

    /**
     * アイデア代理入力時
     */
    PROXY_INPUTTED: 2,
    /**
     * プランに意見が投稿されたとき
     */
    OPINION_POSTED: 3,

    /**
     * プランに代替アイデアが投稿されたとき
     */
    ALTERNATIVE_PROPOSAL_POSTED: 4,

    /**
     * プランが結果集計中になったとき
     */
    AGGREGATING: 5,

    /**
     * 決議作成待ち
     */
    INVESTIGATED: 6
  },

  /**
   * 決議系
   */
  RESOLUTION: {

    /**
     * オンライン決議
     */
    ONLINE: {
      /**
       * 質問が投稿された
       */
      QUESTION_POSTED: 7,

      /**
       * 投票率が低い
       */
      LOW_TURNOUT: 8,

      /**
       * 結果集計中
       */
      AGGREGATING: 9,
    },

    /**
     * 総会決議
     */
    GENERAL_MEETING: {

      /**
       * 質問が投稿された
       */
      QUESTION_POSTED: 10,

      /**
       * 参加率が低い
       */
      LOW_PARTICIPATION: 11,

      /**
       * 集計締め切り
       */
      AGGREGATE_CLOSED: 12,
    },

  },
  /**
   * 相談・通報
   */
  OTHERS: {
    /**
     * 相談着信
     */
    QA: 13,

    /**
     * 通報発生
     */
    REPORT: 14,

    /**
     * 相談リアクション
     */
    QA_REACTION: 16,

  }
} as const

export type NotificationType =
    typeof NOTIFICATION_TYPES.IDEA[keyof typeof NOTIFICATION_TYPES.IDEA]
    |typeof NOTIFICATION_TYPES.RESOLUTION.ONLINE[keyof typeof NOTIFICATION_TYPES.RESOLUTION.ONLINE]
    |typeof NOTIFICATION_TYPES.RESOLUTION.GENERAL_MEETING[keyof typeof NOTIFICATION_TYPES.RESOLUTION.GENERAL_MEETING]
    |typeof NOTIFICATION_TYPES.OTHERS[keyof typeof NOTIFICATION_TYPES.OTHERS]

/**
 * 共通関数用のMap
 * ラベルやアーカイブを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const NOTIFICATION_TYPE_MAP = new Map([
  [NOTIFICATION_TYPES.IDEA.POSTED,
    {
      TASK_AUTO_CREATE: true,
      TASK_CLOSABLE: false
    }
  ],
  [NOTIFICATION_TYPES.IDEA.PROXY_INPUTTED,
    {
      TASK_AUTO_CREATE: true,
      TASK_CLOSABLE: false
    }
  ],
  [NOTIFICATION_TYPES.IDEA.OPINION_POSTED,
    {
      TASK_AUTO_CREATE: false,
      TASK_CLOSABLE: true
    }
  ],
  [NOTIFICATION_TYPES.IDEA.ALTERNATIVE_PROPOSAL_POSTED,
    {
      TASK_AUTO_CREATE: false,
      TASK_CLOSABLE: true
    }
  ],
  [NOTIFICATION_TYPES.IDEA.AGGREGATING,
    {
      TASK_AUTO_CREATE: true,
      TASK_CLOSABLE: false
    }
  ],
  [NOTIFICATION_TYPES.IDEA.INVESTIGATED,
    {
      TASK_AUTO_CREATE: true,
      TASK_CLOSABLE: false
    }
  ],
  [NOTIFICATION_TYPES.RESOLUTION.ONLINE.QUESTION_POSTED,
    {
      TASK_AUTO_CREATE: true,
      TASK_CLOSABLE: true
    }
  ],
  [NOTIFICATION_TYPES.RESOLUTION.ONLINE.LOW_TURNOUT,
    {
      TASK_AUTO_CREATE: false,
      TASK_CLOSABLE: true
    }
  ],
  [NOTIFICATION_TYPES.RESOLUTION.ONLINE.AGGREGATING,
    {
      TASK_AUTO_CREATE: true,
      TASK_CLOSABLE: false
    }
  ],
  [NOTIFICATION_TYPES.RESOLUTION.GENERAL_MEETING.QUESTION_POSTED,
    {
      TASK_AUTO_CREATE: true,
      TASK_CLOSABLE: true
    }
  ],
  [NOTIFICATION_TYPES.RESOLUTION.GENERAL_MEETING.LOW_PARTICIPATION,
    {
      TASK_AUTO_CREATE: false,
      TASK_CLOSABLE: true
    }
  ],
  [NOTIFICATION_TYPES.RESOLUTION.GENERAL_MEETING.AGGREGATE_CLOSED,
    {
      TASK_AUTO_CREATE: true,
      TASK_CLOSABLE: false
    }
  ],
  [NOTIFICATION_TYPES.OTHERS.QA,
    {
      TASK_AUTO_CREATE: false,
      TASK_CLOSABLE: true
    }
  ],
  [NOTIFICATION_TYPES.OTHERS.REPORT,
    {
      TASK_AUTO_CREATE: false,
      TASK_CLOSABLE: true
    }
  ],
  [NOTIFICATION_TYPES.OTHERS.QA_REACTION,
    {
      TASK_AUTO_CREATE: false,
      TASK_CLOSABLE: true
    }
  ],
])

/**
 * 区分所有者お知らせ種別
 */
export const OWNER_NOTIFICATION_TYPE = {
  /**
   * 管理者業務執行者からのお知らせ
   */
  NOTIFIED_BY_ADMIN: 100,
  /**
   * システム管理者からのお知らせ
   */
  SYSTEM: 110
} as const

export type OwnerNotificationType = typeof OWNER_NOTIFICATION_TYPE[keyof typeof OWNER_NOTIFICATION_TYPE]

/**
 * 区分所有者お知らせ状態
 */
export const OWNER_NOTIFICATION_STATE = {
  /**
   * 下書き
   */
  DRAFT: 1,
  /**
   * 公開予定
   */
  SCHEDULED: 2,
  /**
   * 公開中
   */
  NOTIFIED: 3,
  /**
   * アーカイブ
   */
  ARCHIVED: 4,
} as const

export type OwnerNotificationState = typeof OWNER_NOTIFICATION_STATE[keyof typeof OWNER_NOTIFICATION_STATE]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const OWNER_NOTIFICATION_STATE_MAP = new Map([
  [OWNER_NOTIFICATION_STATE.DRAFT, { LABEL: '下書き' }],
  [OWNER_NOTIFICATION_STATE.SCHEDULED, { LABEL: '公開予定' }],
  [OWNER_NOTIFICATION_STATE.NOTIFIED, { LABEL: '公開中' }],
  [OWNER_NOTIFICATION_STATE.ARCHIVED, { LABEL: 'アーカイブ' }],
])

/**
 * お知らせ対象物件種別
 */
export const TARGET_BUILDING_TYPE = {
  /**
   * すべてのマンション
   */
  ALL: 1,
  /**
   * すべての担当マンション
   */
  ALL_IN_CHARGE: 2,
  /**
   * 個別に選択する
   */
  INDIVIDUALLY_SELECTED: 3,
} as const

export type TargetBuildingType = typeof TARGET_BUILDING_TYPE[keyof typeof TARGET_BUILDING_TYPE]

/**
 * お知らせ対象区分所有者種別
 */
export const TARGET_OWNER_TYPE = {
  /**
   * すべての区分所有者
   */
  ALL: 1,
  /**
   * オンライン決議未投票の区分所有者
   */
  ONLINE_RESOLUTION_NOT_VOTED: 2,
  /**
   * 総会決議で意思表明をしていない区分所有者
   */
  GM_RESOLUTION_NOT_VOTED: 3,
  /**
   * プラン未読の区分所有者
   */
  ADMIN_IDEA_UNREAD: 4,
  /**
   * 個別に選択する
   */
  INDIVIDUALLY_SELECTED: 5,
} as const

export type TargetOwnerType = typeof TARGET_OWNER_TYPE[keyof typeof TARGET_OWNER_TYPE]

/**
 * 投稿タイミング種別
 */
export const POST_TIMING_TYPE = {
  /**
   * 即時
   */
  IMMEDIATE: 1,
  /**
   * 投稿日時指定
   */
  SCHEDULED: 2,
} as const

export type PostTimingType = typeof POST_TIMING_TYPE[keyof typeof POST_TIMING_TYPE]

/**
 * お知らせ固定表示設定種別
 */
export const PINNING_SETTING_TYPE = {
  /**
   * 通常のお知らせ
   */
  NORMAL: 1,
  /**
   * 固定指定されたお知らせ
   */
  PINNED: 2,
} as const

export type PinningSettingType = typeof PINNING_SETTING_TYPE[keyof typeof PINNING_SETTING_TYPE]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const PINNING_SETTING_TYPE_MAP = new Map([
  [PINNING_SETTING_TYPE.NORMAL, { LABEL: '通常のお知らせ' }],
  [PINNING_SETTING_TYPE.PINNED, { LABEL: '固定指定されたお知らせ' }],
])

/**
 * お知らせ固定表示状態
 */
export const NOTIFICATION_PINNING_STATE = {
  /**
   * 非固定中
   */
  NOT_PINNING: 1,
  /**
   * 固定中
   */
  PINNING: 2,
} as const

export type NotificationPinningState = typeof NOTIFICATION_PINNING_STATE[keyof typeof NOTIFICATION_PINNING_STATE]

/**
 * お知らせ構成要素種別
 */
export const NOTIFICATION_ELEMENT_TYPES = {
  /** 本文 */
  BODY: 10,

  /** 添付 */
  ATTACHMENT: 20,

  /** リンク */
  LINK: {

    /** smooth-e */
    SMOOTH_E: 31,

    /** 外部サイト */
    EXTERNAL: 32,

    /** メール */
    EMAIL: 33,

    /** 電話番号 */
    PHONE_NUMBER: 34,
  } as const,

  /** 見出し */
  HEADING: {

    /** レベル2 */
    LEVEL2: 42,

    /** レベル3 */
    LEVEL3: 43,

    /** レベル4 */
    LEVEL4: 44,
  } as const,

  /** 箇条書き */
  BULLET_POINT: {

    /** 黒ポチ（順序なし） */
    DOT: 51,

    /** 記号（順序あり） */
    INDEX: 52,
  } as const,

  /** 横線 */
  HORIZON: 60,

  /** 角丸四角 */
  ROUNDED_SQUARE: {

    /** 薄い灰色 */
    LIGHT_GRAY: 71,
  } as const,

  /** ボタン */
  BUTTON: {

    /** アイデア投稿 */
    POST_IDEA: 81,

    /** 相談・連絡 */
    CONSULTATION: 82,

  } as const,
} as const

export type NotificationElementType = typeof NOTIFICATION_ELEMENT_TYPES.BODY | typeof NOTIFICATION_ELEMENT_TYPES.ATTACHMENT | typeof NOTIFICATION_ELEMENT_TYPES.HORIZON
  | typeof NOTIFICATION_ELEMENT_TYPES.LINK[keyof typeof NOTIFICATION_ELEMENT_TYPES.LINK]
  | typeof NOTIFICATION_ELEMENT_TYPES.HEADING[keyof typeof NOTIFICATION_ELEMENT_TYPES.HEADING]
  | typeof NOTIFICATION_ELEMENT_TYPES.BULLET_POINT[keyof typeof NOTIFICATION_ELEMENT_TYPES.BULLET_POINT]
  | typeof NOTIFICATION_ELEMENT_TYPES.ROUNDED_SQUARE[keyof typeof NOTIFICATION_ELEMENT_TYPES.ROUNDED_SQUARE]
  | typeof NOTIFICATION_ELEMENT_TYPES.BUTTON[keyof typeof NOTIFICATION_ELEMENT_TYPES.BUTTON]

/**
 * お知らせ本文（リスト）
 * 階層種別
 */
export const HIERARCHY_TYPES = {
  FIRST_HIERARCHY: 1,
  SECOND_HIERARCHY: 2,
  THIRD_HIERARCHY: 0
} as const
export type HierarchyTypes = typeof HIERARCHY_TYPES[keyof typeof HIERARCHY_TYPES]

/**
 * お知らせ本文（リスト）用MAP
 * 階層種別に紐づくli用の種類（記号）
 */
export const HIERARCHY_TYPE_DOT_MAP = new Map([
  [HIERARCHY_TYPES.FIRST_HIERARCHY, 'disc'],
  [HIERARCHY_TYPES.SECOND_HIERARCHY, 'circle'],
  [HIERARCHY_TYPES.THIRD_HIERARCHY, 'square']
])

/**
 * お知らせ本文（リスト）用MAP
 * 階層種別に紐づくli用の種類（順番）
 */
export const HIERARCHY_TYPE_INDEX_MAP = new Map([
  [HIERARCHY_TYPES.FIRST_HIERARCHY, 'decimal'],
  [HIERARCHY_TYPES.SECOND_HIERARCHY, 'lower-alpha'],
  [HIERARCHY_TYPES.THIRD_HIERARCHY, 'lower-roman']
])

export const TASK_STATES = {
  NOT_INITIALIZED: 0,
  NOT_COMPLETED: 1,
  COMPLETED: 2,
  UNNECESSARY: 9
} as const

export type TaskState = typeof TASK_STATES[keyof typeof TASK_STATES]

export const TRANSITION_TO = {
  IDEA: {
    OWNER: 110,
    ADMIN: 120,
  },
  RESOLUTION: {
    GENERAL_MEETING: 130,
    ONLINE: 140,
  },
  QA: 510,
  REPORT: 600,
  TICKET: {
    DETAIL: 710,
    DETAIL_TASK: 711,
  },
  NOTICE: 800,
  STAFF_DETAIL: {
    FRONT: 1001,
    LIFE_MANAGER: 1002,
  },
  CASYS_RESULTS: 1100,
} as const

export type TransitionTo = typeof TRANSITION_TO.IDEA[keyof typeof TRANSITION_TO.IDEA]
  | typeof TRANSITION_TO.RESOLUTION[keyof typeof TRANSITION_TO.RESOLUTION]
  | typeof TRANSITION_TO.QA
  | typeof TRANSITION_TO.REPORT
  | typeof TRANSITION_TO.TICKET[keyof typeof TRANSITION_TO.TICKET]
  | typeof TRANSITION_TO.NOTICE
  | typeof TRANSITION_TO.STAFF_DETAIL[keyof typeof TRANSITION_TO.STAFF_DETAIL]
  | typeof TRANSITION_TO.CASYS_RESULTS

export const NEW_TAB_TRANSITION_TO = {
  IDEA: {
    OWNER: 110,
    ADMIN: 120,
  },
  RESOLUTION: {
    GENERAL_MEETING: 130,
    GENERAL_MEETING_DRAFT: 131,
    ONLINE: 140,
    ONLINE_DRAFT: 141,
  },
  QA: 510,
  BUILDING_DETAIL: 910,
  OWNER_DETAIL: 920,
} as const

export type NewTabTransitionTo = typeof NEW_TAB_TRANSITION_TO.IDEA[keyof typeof NEW_TAB_TRANSITION_TO.IDEA]
    | typeof NEW_TAB_TRANSITION_TO.RESOLUTION[keyof typeof NEW_TAB_TRANSITION_TO.RESOLUTION]
    | typeof NEW_TAB_TRANSITION_TO.QA
    | typeof NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    | typeof NEW_TAB_TRANSITION_TO.OWNER_DETAIL

export const STATUS_CODE = {
  SUCCESS: 200,
  VALIDATION: 400,
  BAD_REQUEST: 401,
  OPTIMISTIC_LOCK: 402,
  PERMISSION_DENIED: 403,
  NOT_FOUND: 404,
  SESSION_NOT_FOUND: 405,
  APPLICATION: 500,
  NOT_IMPLEMENTED: 501, // TODO 新GRIPで150件以上の区分所有者を保持する物件に対し、サービス利用停止が可能になった後に削除する
  SERVICE_UNAVAILABLE: 503,
} as const

// マンションメニューの内容
export const BUILDING_MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  all: {
    text: 'すべてのマンション',
    label: '変更する'
  },
  group: {
    text: 'グループから選択する',
    label: '変更する'
  },
  each: {
    text: '個別に選択する',
    label: '変更する'
  },
}

export const BUILDING_MENU_TYPES = {
  ALL: 'all',
  GROUP: 'group',
  EACH: 'each',
}
export type BuildingMenuTypes = typeof BUILDING_MENU_TYPES[keyof typeof BUILDING_MENU_TYPES]

export const BUILDING_GROUP_TYPE = {
  TOKYO: 1,
  KANSAI: 2,
  CMQ: 3,
  CMW: 4,
  CMO: 5

} as const
export type BuildingGroupType = typeof BUILDING_GROUP_TYPE[keyof typeof BUILDING_GROUP_TYPE]

export const BUILDING_GROUP_MAP = new Map([
  [BUILDING_GROUP_TYPE.TOKYO, { LABEL: 'HCM東京' }],
  [BUILDING_GROUP_TYPE.KANSAI, { LABEL: 'HCM関西' }],
  [BUILDING_GROUP_TYPE.CMQ, { LABEL: 'CMQ' }],
  [BUILDING_GROUP_TYPE.CMW, { LABEL: 'CMW' }],
  [BUILDING_GROUP_TYPE.CMO, { LABEL: 'CMO' }],
])

/**
 * チケット種別
 */
export const TICKET_TYPES = {
  /** アイデア */
  IDEA: 1,

  /** 決議 */
  RESOLUTION: 2,

  /** 相談・連絡 */
  CONSULTATION: 3,

  /** 手動 */
  MANUAL: 4,
} as const
export type TicketType = typeof TICKET_TYPES[keyof typeof TICKET_TYPES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const TICKET_TYPE_MAP = new Map([
  [TICKET_TYPES.IDEA, { LABEL: 'アイデア・プラン', BADGE: { icon: '$sm-idea-plan-icon', iconColor: '#66CE14', textStyle: 'green-dark' } }],
  [TICKET_TYPES.RESOLUTION, { LABEL: '決議', BADGE: { icon: '$how_to_vote_outlined', iconColor: '#FC5353', textStyle: 'red-dark' } }],
  [TICKET_TYPES.CONSULTATION, { LABEL: '相談・連絡', BADGE: { icon: '$sm-chat', iconColor: '#933EFF', textStyle: 'purple-dark' } }],
  [TICKET_TYPES.MANUAL, { LABEL: '手動', BADGE: { icon: '$create_round', iconColor: '#9797BE', textStyle: 'blue-gray-dark' } }],
])

/**
 * チケット状態
 */
export const TICKET_STATES = {
  /** 担当者未設定 */
  UNSET_PERSON_IN_CHARGE: 1,

  /** 処理中 */
  NOT_COMPLETED: 2,

  /** 完了 */
  COMPLETED: 3,
} as const
export type TicketState = typeof TICKET_STATES[keyof typeof TICKET_STATES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const TICKET_STATE_MAP = new Map([
  [TICKET_STATES.UNSET_PERSON_IN_CHARGE, { LABEL: '担当者未設定', CHIP_COLOR: CHIP_COLORS.GREEN }],
  [TICKET_STATES.NOT_COMPLETED, { LABEL: '処理中', CHIP_COLOR: CHIP_COLORS.GRAY }],
  [TICKET_STATES.COMPLETED, { LABEL: '完了', CHIP_COLOR: CHIP_COLORS.GRAY }],
])

/**
 * 情報元種別
 */
export const INFORMATION_SOURCE_TYPES = {
  /** アイデア投稿 */
  IDEA_POSTED: 1,

  /** 相談・連絡 */
  CONSULTATION: 2,

  /** フロント */
  FRONT: 3,

  /** LM */
  LM: 4,

  /** GCC */
  GCC: 5,

  /** sm@haseko */
  SM_AT_HASEKO: 6,

  /** 管理者業務執行者宛メール受信 */
  MAIL_RECEIVED_AT_SERVICE_STAFF: 7,

  /** HP */
  HP: 8,

  /** そのほか（自由入力） */
  OTHERS: 99
} as const
export type InformationSourceType = typeof INFORMATION_SOURCE_TYPES[keyof typeof INFORMATION_SOURCE_TYPES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const INFORMATION_SOURCE_TYPE_MAP = new Map([
  [INFORMATION_SOURCE_TYPES.IDEA_POSTED, { LABEL: 'アイデア・プラン投稿' }],
  [INFORMATION_SOURCE_TYPES.CONSULTATION, { LABEL: '相談・連絡' }],
  [INFORMATION_SOURCE_TYPES.FRONT, { LABEL: 'フロント' }],
  [INFORMATION_SOURCE_TYPES.LM, { LABEL: 'LM' }],
  [INFORMATION_SOURCE_TYPES.GCC, { LABEL: 'GCC' }],
  [INFORMATION_SOURCE_TYPES.SM_AT_HASEKO, { LABEL: 'sm@haseko' }],
  [INFORMATION_SOURCE_TYPES.MAIL_RECEIVED_AT_SERVICE_STAFF, { LABEL: '管理者業務執行者宛メール受信' }],
  [INFORMATION_SOURCE_TYPES.HP, { LABEL: 'HP' }],
  [INFORMATION_SOURCE_TYPES.OTHERS, { LABEL: 'そのほか（自由入力）' }],
])

/**
 * 案件種別
 */
export const CASE_TYPES = {
  /** 顧客対応（アイデア） */
  OWNER_IDEA: 1,

  /** 顧客対応（相談・連絡） */
  OWNER_CONSULTATION: 2,

  /** 判断対応 */
  JUDGEMENT: 3,

  /** 規約に基づく申請受付 */
  BASED_ON_THE_TERMS: 4,
} as const
export type CaseType = typeof CASE_TYPES[keyof typeof CASE_TYPES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const CASE_TYPE_MAP = new Map([
  [CASE_TYPES.OWNER_IDEA, { LABEL: '顧客対応（アイデア・プラン）' }],
  [CASE_TYPES.OWNER_CONSULTATION, { LABEL: '顧客対応（相談・連絡）' }],
  [CASE_TYPES.JUDGEMENT, { LABEL: '判断対応' }],
  [CASE_TYPES.BASED_ON_THE_TERMS, { LABEL: '規約に基づく申請受付' }],
])

/**
 * チケット問合せ元種別
 */
export const TICKET_INQUIRY_SOURCE_TYPES = {
  /** アイデア */
  OWNER_IDEA: 1,

  /** プラン */
  ADMIN_IDEA: 2,

  /** 相談・連絡 */
  CONSULTATION: 3,
} as const
export type TicketInquirySourceType = typeof TICKET_INQUIRY_SOURCE_TYPES[keyof typeof TICKET_INQUIRY_SOURCE_TYPES]

/**
 * タスク問合せ元種別
 */
export const TASK_INQUIRY_SOURCE_TYPES = {
  /** アイデア */
  OWNER_IDEA: 1,

  /** プラン */
  ADMIN_IDEA: 2,

  /** 相談・連絡 */
  CONSULTATION: 3,

  /** 総会決議 */
  GM_RESOLUTION: 4,

  /** オンライン決議 */
  ONLINE_RESOLUTION: 5,
} as const
export type TaskInquirySourceType = typeof TASK_INQUIRY_SOURCE_TYPES[keyof typeof TASK_INQUIRY_SOURCE_TYPES]

/**
 * チケットコメント状態
 */
export const TICKET_COMMENT_STATES = {
  /** 投稿済 */
  RAW: 1,

  /** 編集済 */
  MODIFIED: 2,
} as const
export type TicketCommentState = typeof TICKET_COMMENT_STATES[keyof typeof TICKET_COMMENT_STATES]

/**
 * タスク種別
 */
export const TICKET_TASK_TYPES = {
  /** プランを登録する */
  ADMIN_IDEA_POST: 10,

  /** 意見に返信する */
  OPINION_REPLY: 11,

  /** 代替アイデア投稿に返信する */
  ALTERNATIVE_IDEA_REPLY: 12,

  /** 賛同結果を登録する */
  ADMIN_IDEA_STARTED_RESULT_POST: 13,

  /** 決議チケットを作成する */
  RESOLUTION_TICKET_POST: 14,

  /** プランを作成する */
  CREATE_PLAN: 15,

  /** 意見受付期間を延長する */
  OPINION_DEADLINE_EXTEND: 16,

  /** 【プランの閲覧数が少ない場合】プランを印刷して全員に配布する */
  ADMIN_IDEA_PAPER_DISTRIBUTION_7_DAYS_LATER: 17,

  /** 決議を作成する  */
  RESOLUTION_CREATE: 20,

  /** 議案書を作成する */
  RESOLUTION_BILL_CREATE: 21,

  /** 質問に回答する */
  QUESTION_ANSWER: 22,

  /** オンライン決議の投票を促す（自由入力） */
  PROXY_VOTE_ENCOURAGE: 23,

  /** オンライン決議に結果を登録する */
  ONLINE_RESOLUTION_RESULT_POST: 24,

  /** 総会決議の意思表明を促す（自由入力） */
  STATEMENT_ENCOURAGE: 25,

  /** 総会決議に結果を登録する */
  GM_RESOLUTION_RESULT_POST: 26,

  /** オンライン決議を印刷して紙申請者に配布する */
  ONLINE_RESOLUTION_PAPER_DISTRIBUTION: 27,

  /** 【オンライン決議の意思表示数が少ない場合】オンライン決議を印刷して意思表示未済者に配布する */
  ONLINE_RESOLUTION_PAPER_DISTRIBUTION_7_DAYS_LATER: 28,

  /** 相談・連絡に返信する（自由入力） */
  CONSULTATION_REPLY: 30,

  /** 相談・連絡へのリアクションに返信する */
  CONSULTATION_REACTION_REPLY: 31,

  /** 手動タスク生成（自由入力） */
  MANUAL_TASK_CREATE: 90,
} as const
export type TicketTaskType = typeof TICKET_TASK_TYPES[keyof typeof TICKET_TASK_TYPES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const TICKET_TASK_TYPES_MAP = new Map([
  [TICKET_TASK_TYPES.ADMIN_IDEA_POST, { LABEL: 'アイデア投稿で自動生成' }],
  [TICKET_TASK_TYPES.OPINION_REPLY, { LABEL: 'アイデアへの意見投稿で自動生成' }],
  [TICKET_TASK_TYPES.ALTERNATIVE_IDEA_REPLY, { LABEL: 'アイデアへの代替アイデア投稿で自動生成' }],
  [TICKET_TASK_TYPES.ADMIN_IDEA_STARTED_RESULT_POST, { LABEL: 'プラン結果集計中で自動生成' }],
  [TICKET_TASK_TYPES.RESOLUTION_TICKET_POST, { LABEL: 'プラン可決登録で自動生成' }],
  [TICKET_TASK_TYPES.CREATE_PLAN, { LABEL: 'アイデアへの代替アイデア投稿で自動生成' }],
  [TICKET_TASK_TYPES.OPINION_DEADLINE_EXTEND, { LABEL: 'アイデアへの代替アイデア投稿で自動生成' }],
  [TICKET_TASK_TYPES.ADMIN_IDEA_PAPER_DISTRIBUTION_7_DAYS_LATER, { LABEL: 'プランの賛同受付開始日時から7日経過で自動生成' }],
  [TICKET_TASK_TYPES.RESOLUTION_CREATE, { LABEL: '決議チケット作成で自動生成' }],
  [TICKET_TASK_TYPES.RESOLUTION_BILL_CREATE, { LABEL: '決議チケット作成で自動生成' }],
  [TICKET_TASK_TYPES.QUESTION_ANSWER, { LABEL: '質問投稿で自動生成' }],
  [TICKET_TASK_TYPES.PROXY_VOTE_ENCOURAGE, { LABEL: 'オンライン決議投票者数条件以下で自動生成' }],
  [TICKET_TASK_TYPES.ONLINE_RESOLUTION_RESULT_POST, { LABEL: 'オンライン決議結果集計中で自動生成' }],
  [TICKET_TASK_TYPES.STATEMENT_ENCOURAGE, { LABEL: '総会決議意思表明者数条件以下で自動生成' }],
  [TICKET_TASK_TYPES.GM_RESOLUTION_RESULT_POST, { LABEL: '総会決議集計〆切で自動生成' }],
  [TICKET_TASK_TYPES.ONLINE_RESOLUTION_PAPER_DISTRIBUTION, { LABEL: 'オンライン決議投稿で自動生成' }],
  [TICKET_TASK_TYPES.ONLINE_RESOLUTION_PAPER_DISTRIBUTION_7_DAYS_LATER, { LABEL: 'オンライン決議投稿から7日経過で自動生成' }],
  [TICKET_TASK_TYPES.CONSULTATION_REPLY, { LABEL: '相談・連絡投稿で自動生成' }],
  [TICKET_TASK_TYPES.CONSULTATION_REACTION_REPLY, { LABEL: '相談・連絡投稿へのリアクションで自動生成' }],
  [TICKET_TASK_TYPES.MANUAL_TASK_CREATE, { LABEL: '手動で作成' }],
])

/**
 * 共通関数用のMap
 * ラベルやアーカイブを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const TICKET_TASK_TYPE_IS_AUTO_COMPLETE_MAP = new Map([
  [TICKET_TASK_TYPES.ADMIN_IDEA_POST, { TASK_AUTO_COMPLETE: true }],
  [TICKET_TASK_TYPES.OPINION_REPLY, { TASK_AUTO_COMPLETE: true }],
  [TICKET_TASK_TYPES.ALTERNATIVE_IDEA_REPLY, { TASK_AUTO_COMPLETE: true }],
  [TICKET_TASK_TYPES.ADMIN_IDEA_STARTED_RESULT_POST, { TASK_AUTO_COMPLETE: true }],
  [TICKET_TASK_TYPES.RESOLUTION_TICKET_POST, { TASK_AUTO_COMPLETE: true }],
  [TICKET_TASK_TYPES.CREATE_PLAN, { TASK_AUTO_COMPLETE: false }],
  [TICKET_TASK_TYPES.OPINION_DEADLINE_EXTEND, { TASK_AUTO_COMPLETE: false }],
  [TICKET_TASK_TYPES.ADMIN_IDEA_PAPER_DISTRIBUTION_7_DAYS_LATER, { TASK_AUTO_COMPLETE: false }],
  [TICKET_TASK_TYPES.RESOLUTION_CREATE, { TASK_AUTO_COMPLETE: true }],
  [TICKET_TASK_TYPES.RESOLUTION_BILL_CREATE, { TASK_AUTO_COMPLETE: false }],
  [TICKET_TASK_TYPES.QUESTION_ANSWER, { TASK_AUTO_COMPLETE: true }],
  [TICKET_TASK_TYPES.PROXY_VOTE_ENCOURAGE, { TASK_AUTO_COMPLETE: false }],
  [TICKET_TASK_TYPES.ONLINE_RESOLUTION_RESULT_POST, { TASK_AUTO_COMPLETE: true }],
  [TICKET_TASK_TYPES.STATEMENT_ENCOURAGE, { TASK_AUTO_COMPLETE: false }],
  [TICKET_TASK_TYPES.GM_RESOLUTION_RESULT_POST, { TASK_AUTO_COMPLETE: true }],
  [TICKET_TASK_TYPES.ONLINE_RESOLUTION_PAPER_DISTRIBUTION, { TASK_AUTO_COMPLETE: false }],
  [TICKET_TASK_TYPES.ONLINE_RESOLUTION_PAPER_DISTRIBUTION_7_DAYS_LATER, { TASK_AUTO_COMPLETE: false }],
  [TICKET_TASK_TYPES.CONSULTATION_REPLY, { TASK_AUTO_COMPLETE: false }],
  [TICKET_TASK_TYPES.CONSULTATION_REACTION_REPLY, { TASK_AUTO_COMPLETE: false }],
  [TICKET_TASK_TYPES.MANUAL_TASK_CREATE, { TASK_AUTO_COMPLETE: false }],
])

/**
 * 共通関数用のMap
 * ラベルやアーカイブを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const TICKET_TASK_TYPE_IS_RESPONSE_OWNER:Map<boolean, TicketTaskType[]> = new Map([
  [true, [TICKET_TASK_TYPES.ADMIN_IDEA_POST, TICKET_TASK_TYPES.OPINION_REPLY, TICKET_TASK_TYPES.ALTERNATIVE_IDEA_REPLY, TICKET_TASK_TYPES.CREATE_PLAN, TICKET_TASK_TYPES.OPINION_DEADLINE_EXTEND, TICKET_TASK_TYPES.QUESTION_ANSWER, TICKET_TASK_TYPES.CONSULTATION_REPLY, TICKET_TASK_TYPES.CONSULTATION_REACTION_REPLY]],
  [false, [TICKET_TASK_TYPES.ADMIN_IDEA_STARTED_RESULT_POST, TICKET_TASK_TYPES.RESOLUTION_TICKET_POST, TICKET_TASK_TYPES.ADMIN_IDEA_PAPER_DISTRIBUTION_7_DAYS_LATER, TICKET_TASK_TYPES.RESOLUTION_CREATE, TICKET_TASK_TYPES.RESOLUTION_BILL_CREATE, TICKET_TASK_TYPES.PROXY_VOTE_ENCOURAGE, TICKET_TASK_TYPES.ONLINE_RESOLUTION_RESULT_POST, TICKET_TASK_TYPES.STATEMENT_ENCOURAGE, TICKET_TASK_TYPES.GM_RESOLUTION_RESULT_POST, TICKET_TASK_TYPES.ONLINE_RESOLUTION_PAPER_DISTRIBUTION, TICKET_TASK_TYPES.MANUAL_TASK_CREATE]]
])
/**
 * タスクタイトル種別
 */
export const TICKET_TASK_NAME_TYPES = {
  /** プランを登録する */
  ADMIN_IDEA_POST: 10,

  /** 意見に返信する */
  OPINION_REPLY: 11,

  /** 代替アイデア投稿に返信する */
  ALTERNATIVE_IDEA_REPLY: 12,

  /** 賛同結果を登録する */
  ADMIN_IDEA_STARTED_RESULT_POST: 13,

  /** 決議チケットを作成する */
  RESOLUTION_TICKET_POST: 14,

  /** プランを作成する */
  CREATE_PLAN: 15,

  /** 意見受付期間を延長する */
  OPINION_DEADLINE_EXTEND: 16,

  /** 【プランの閲覧数が少ない場合】プランを印刷して全員に配布する */
  ADMIN_IDEA_PAPER_DISTRIBUTION_7_DAYS_LATER: 17,

  /** 決議を作成する  */
  RESOLUTION_CREATE: 20,

  /** 議案書を作成する */
  RESOLUTION_BILL_CREATE: 21,

  /** 質問に回答する */
  QUESTION_ANSWER: 22,

  /** オンライン決議の投票を促す（自由入力） */
  PROXY_VOTE_ENCOURAGE: 23,

  /** オンライン決議に結果を登録する */
  ONLINE_RESOLUTION_RESULT_POST: 24,

  /** 総会決議の意思表明を促す（自由入力） */
  STATEMENT_ENCOURAGE: 25,

  /** 総会決議に結果を登録する */
  GM_RESOLUTION_RESULT_POST: 26,

  /** オンライン決議を印刷して紙申請者に配布する */
  ONLINE_RESOLUTION_PAPER_DISTRIBUTION: 27,

  /** 【オンライン決議の意思表示数が少ない場合】オンライン決議を印刷して意思表示未済者に配布する */
  ONLINE_RESOLUTION_PAPER_DISTRIBUTION_7_DAYS_LATER: 28,

  /** 相談・連絡に返信する（自由入力） */
  CONSULTATION_REPLY: 30,

  /** 相談・連絡へのリアクションに返信する */
  CONSULTATION_REACTION_REPLY: 31,

  /** 手動タスク生成（自由入力） */
  MANUAL_TASK_CREATE: 90,

  /** その他（自由入力） */
  FREE_WRITING: 91,
} as const
export type TicketTaskNameType = typeof TICKET_TASK_NAME_TYPES[keyof typeof TICKET_TASK_NAME_TYPES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const TICKET_TASK_NAME_TYPES_MAP = new Map([
  [TICKET_TASK_NAME_TYPES.ADMIN_IDEA_POST, { LABEL: 'プランを登録する', CAN_EDIT_TICKET_TASK_NAME: false }],
  [TICKET_TASK_NAME_TYPES.OPINION_REPLY, { LABEL: '意見に返信する', CAN_EDIT_TICKET_TASK_NAME: false }],
  [TICKET_TASK_NAME_TYPES.ALTERNATIVE_IDEA_REPLY, { LABEL: '代替アイデア投稿に返信する', CAN_EDIT_TICKET_TASK_NAME: false }],
  [TICKET_TASK_NAME_TYPES.ADMIN_IDEA_STARTED_RESULT_POST, { LABEL: '賛同結果を登録する', CAN_EDIT_TICKET_TASK_NAME: false }],
  [TICKET_TASK_NAME_TYPES.RESOLUTION_TICKET_POST, { LABEL: '決議チケットを作成する', CAN_EDIT_TICKET_TASK_NAME: false }],
  [TICKET_TASK_NAME_TYPES.CREATE_PLAN, { LABEL: 'プランを作成する', CAN_EDIT_TICKET_TASK_NAME: true }],
  [TICKET_TASK_NAME_TYPES.OPINION_DEADLINE_EXTEND, { LABEL: '意見受付期間を延長する', CAN_EDIT_TICKET_TASK_NAME: true }],
  [TICKET_TASK_TYPES.ADMIN_IDEA_PAPER_DISTRIBUTION_7_DAYS_LATER, {
    LABEL: `【プランの閲覧数が少ない場合】
プランを印刷して全員に配布する`,
    CAN_EDIT_TICKET_TASK_NAME: true
  }],
  [TICKET_TASK_NAME_TYPES.RESOLUTION_CREATE, { LABEL: '決議を作成する', CAN_EDIT_TICKET_TASK_NAME: false }],
  [TICKET_TASK_NAME_TYPES.RESOLUTION_BILL_CREATE, { LABEL: '議案書を作成する', CAN_EDIT_TICKET_TASK_NAME: true }],
  [TICKET_TASK_NAME_TYPES.QUESTION_ANSWER, { LABEL: '質問に回答する', CAN_EDIT_TICKET_TASK_NAME: false }],
  [TICKET_TASK_NAME_TYPES.PROXY_VOTE_ENCOURAGE, { LABEL: 'オンライン決議の投票を促す', CAN_EDIT_TICKET_TASK_NAME: true }],
  [TICKET_TASK_NAME_TYPES.ONLINE_RESOLUTION_RESULT_POST, { LABEL: 'オンライン決議に結果を登録する', CAN_EDIT_TICKET_TASK_NAME: false }],
  [TICKET_TASK_NAME_TYPES.STATEMENT_ENCOURAGE, { LABEL: '総会決議の意思表明を促す', CAN_EDIT_TICKET_TASK_NAME: true }],
  [TICKET_TASK_NAME_TYPES.GM_RESOLUTION_RESULT_POST, { LABEL: '総会決議に結果を登録する', CAN_EDIT_TICKET_TASK_NAME: false }],
  [TICKET_TASK_NAME_TYPES.ONLINE_RESOLUTION_PAPER_DISTRIBUTION, { LABEL: 'オンライン決議を印刷して紙申請者に配布する', CAN_EDIT_TICKET_TASK_NAME: true }],
  [TICKET_TASK_NAME_TYPES.ONLINE_RESOLUTION_PAPER_DISTRIBUTION_7_DAYS_LATER, {
    LABEL: `【オンライン決議の意思表示数が少ない場合】
オンライン決議を印刷して意思表示未済者に配布する`,
    CAN_EDIT_TICKET_TASK_NAME: true
  }],
  [TICKET_TASK_NAME_TYPES.CONSULTATION_REPLY, { LABEL: '相談・連絡に返信する', CAN_EDIT_TICKET_TASK_NAME: true }],
  [TICKET_TASK_NAME_TYPES.CONSULTATION_REACTION_REPLY, { LABEL: '相談連絡へのリアクションに返信する', CAN_EDIT_TICKET_TASK_NAME: true }],
  [TICKET_TASK_NAME_TYPES.MANUAL_TASK_CREATE, { LABEL: 'その他', CAN_EDIT_TICKET_TASK_NAME: true }],
  [TICKET_TASK_NAME_TYPES.FREE_WRITING, { LABEL: 'その他', CAN_EDIT_TICKET_TASK_NAME: true }],
])

/**
 * タスク状態
 */
export const TICKET_TASK_STATES = {
  /** 担当者未設定 */
  UNSET_PERSON_IN_CHARGE: 1,

  /** 未着手 */
  NOT_STARTED_YET: 2,

  /** 処理中 */
  NOT_COMPLETED: 3,

  /** 完了 */
  COMPLETED: 4,

  /** 完了（対応しない） */
  UNNECESSARY: 9
} as const
export type TicketTaskState = typeof TICKET_TASK_STATES[keyof typeof TICKET_TASK_STATES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const TICKET_TASK_STATE_MAP = new Map([
  [TICKET_TASK_STATES.UNSET_PERSON_IN_CHARGE, { LABEL: '担当者未設定', CHIP_COLOR: CHIP_COLORS.GREEN }],
  [TICKET_TASK_STATES.NOT_STARTED_YET, { LABEL: '未着手', CHIP_COLOR: CHIP_COLORS.GREEN }],
  [TICKET_TASK_STATES.NOT_COMPLETED, { LABEL: '処理中', CHIP_COLOR: CHIP_COLORS.GRAY }],
  [TICKET_TASK_STATES.COMPLETED, { LABEL: '完了', CHIP_COLOR: CHIP_COLORS.GRAY }],
  [TICKET_TASK_STATES.UNNECESSARY, { LABEL: '完了', CHIP_COLOR: CHIP_COLORS.GRAY }],
])

/**
 * 通知種別
 */
export const NOTICE_TYPES = {

  /** 意見が投稿された */
  OPINION_POSTED: 2,

  /** 代替アイデアが投稿された */
  ALTERNATIVE_IDEA_POSTED: 3,

  /** プランが賛同集計中になった */
  ADMIN_IDEA_COUNTING: 4,

  /** プランが可決として結果登録された */
  ADMIN_IDEA_PASSED: 5,

  /** 質問が投稿された */
  QUESTION_POSTED: 6,

  /** 総会決議の集計が締め切られた */
  GM_RESOLUTION_ACCEPTING_CLOSED: 7,

  /** 決議チケットが作成された */
  RESOLUTION_TICKET_CREATED: 8,

  /** オンライン決議の投票が締め切られた */
  ONLINE_RESOLUTION_VOTING_CLOSED: 9,

  /** 総会決議の意思表明率が低い */
  GM_RESOLUTION_LOW_PARTICIPATION_RATE_BEFORE_CLOSE_QUESTION: 10,

  /** オンライン決議の投票率が低い */
  ONLINE_RESOLUTION_LOW_PARTICIPATION_RATE_BEFORE_CLOSE_ACCEPTING: 11,

  /** 自分がアサインされている相談・連絡が投稿された */
  CONSULTATION_IN_CHARGE_POSTED: 13,

  /** 自分がアサインされている相談・連絡にリアクションがあった */
  CONSULTATION_IN_CHARGE_REACTION_POSTED: 14,

  /** チケットがアサインされた */
  TICKET_ASSIGNED: 15,

  /** 担当チケットにコメントがされた */
  COMMENTED_TO_TICKET: 16,

  /** 担当チケットの対応内容が更新された */
  TICKET_IN_CHARGE_MODIFIED: 17,

  /** 通報（報告）があった */
  REPORTED: 18,

  /** 担当タスクの期限が切れている */
  TASK_DEADLINE_OVERED: 19,

} as const
export type NoticeType = typeof NOTICE_TYPES[keyof typeof NOTICE_TYPES]

/**
 * 報告書種類
 */
export const REPORT_TYPES = {
  /** 定例MTG資料 */
  OPERATION: 0,

  /** 管理状況報告書 */
  MANAGEMENT: 1,
} as const
export type ReportType = typeof REPORT_TYPES[keyof typeof REPORT_TYPES]

/**
 * 長期修繕計画種類
 */
export const REPAIR_PLAN_TYPES = {
  /** リアルタイム長計 */
  REPAIR_PLAN: 1,
  /** 簡易版リアルタイム長計 */
  SIMPLE_REPAIR_PLAN: 2,
} as const
export type RepairPlanType = typeof REPAIR_PLAN_TYPES[keyof typeof REPAIR_PLAN_TYPES]

/*
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const REPAIR_PLAN_TYPES_MAP = new Map([
  [REPAIR_PLAN_TYPES.REPAIR_PLAN, { LABEL: 'リアルタイム長計' }],
  [REPAIR_PLAN_TYPES.SIMPLE_REPAIR_PLAN, { LABEL: '簡易版リアルタイム長計' }],
])

/*
 * アンケート状態
 */
export const QUESTIONNAIRE_STATES = {
  /**
   * 下書き
   */
  DRAFT: 1,
  /**
   * 公開済
   */
  OPEN: 2,
  /**
   * 回答締切済
   */
  COUNTING: 3,
  /**
   * 報告書作成済
   */
  RELEASED: 4,
  /**
   * 公開予定
   */
  SCHEDULED: 5
} as const
export type QuestionnaireState = typeof QUESTIONNAIRE_STATES[keyof typeof QUESTIONNAIRE_STATES]

/**
 * 報告書状態
 */
export const REPORT_STATE = {
  /**
   * 下書き
   */
  DRAFT: 1,
  /**
   * 公開済
   */
  OPEN: 2,
} as const
export type ReportState = typeof REPORT_STATE[keyof typeof REPORT_STATE]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const QUESTIONNAIRE_STATE_MAP = new Map([
  [QUESTIONNAIRE_STATES.DRAFT, { LABEL: '下書き', CHIP_COLOR: CHIP_COLORS.GREEN }],
  [QUESTIONNAIRE_STATES.OPEN, { LABEL: '公開中', CHIP_COLOR: CHIP_COLORS.GRAY }],
  [QUESTIONNAIRE_STATES.COUNTING, { LABEL: '回答締切済', CHIP_COLOR: CHIP_COLORS.GRAY }],
  [QUESTIONNAIRE_STATES.RELEASED, { LABEL: '報告書作成済', CHIP_COLOR: CHIP_COLORS.GRAY }],
  [QUESTIONNAIRE_STATES.SCHEDULED, { LABEL: '公開予定', CHIP_COLOR: CHIP_COLORS.GRAY }],
])

export const QUESTIONNAIRE_TYPES = {
  /**
  * 継続利用アンケート
  */
  CONTINUED_USE: 1,
  /**
  * 個別アンケート
  */
  INDIVIDUAL: 2,
} as const
export type QuestionnaireType = typeof QUESTIONNAIRE_TYPES[keyof typeof QUESTIONNAIRE_TYPES]

export const QUESTIONNAIRE_TYPE_MAP = new Map([
  [QUESTIONNAIRE_TYPES.CONTINUED_USE, { BADGE: { icon: 'cached', iconColor: '#00A2D5', textStyle: 'secondary' } }],
  [QUESTIONNAIRE_TYPES.INDIVIDUAL, { BADGE: { icon: '$text_snippet_outlined', iconColor: '#F4A300', textStyle: 'brown-dark' } }],
])

/**
 * アンケートテンプレート種別
 */
export const TEMPLATE_TYPES = {
  REPEAT_USE: 1,
  ONCE_USE: 2,
} as const
export type TemplateType = typeof TEMPLATE_TYPES[keyof typeof TEMPLATE_TYPES]

/**
 * アンケートテンプレート状態
 */
export const TEMPLATE_STATE = {
  VALID: 1, // 有効
  DRAFT: 2, // 下書き
  DELETED: 9, // 削除済（※物理削除すると紐付くアンケートがすべて削除されてしまうので、論理削除とする）
} as const
export type TemplateState = typeof TEMPLATE_STATE[keyof typeof TEMPLATE_STATE]

/**
 * アンケートセクション種別
 */
export const SECTION_TYPES = {
  /**
   * 回答
   */
  QUESTION: 1,

  /**
   * ご意見・ご要望
   */
  OPINION: 2,
}
export type SectionType = typeof SECTION_TYPES[keyof typeof SECTION_TYPES]

/**
 * アンケート設問種別
 */
export const QUESTIONNAIRE_QUESTION_TYPES = {
  /**
   * 自由入力
   */
  FREE: 1,

  /**
   * 単一選択式
   */
  SINGLE_CHOICE: 2,

  /**
   * 複数選択
   */
  MULTIPLE_CHOICES: 3,
} as const
export type QuestionnaireQuestionType = typeof QUESTIONNAIRE_QUESTION_TYPES[keyof typeof QUESTIONNAIRE_QUESTION_TYPES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const TEMPLATE_TYPE_MAP = new Map([
  [TEMPLATE_TYPES.REPEAT_USE, { BADGE: { icon: '$sm-questionnaire-icon', iconColor: 'rgba(202, 206, 20, 1)', textStyle: 'green-light' } }],
  [TEMPLATE_TYPES.ONCE_USE, { BADGE: { icon: '$sm-questionnaire-icon', iconColor: 'rgba(202, 206, 20, 1)', textStyle: 'green-light' } }],
])

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const QUESTIONNAIRE_QUESTION_TYPE_MAP = new Map([
  [QUESTIONNAIRE_QUESTION_TYPES.FREE, { LABEL: '入力式', optionIconName: '' }],
  [QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE, { LABEL: '単一選択式', optionIconName: 'radio_button_unchecked' }],
  [QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES, { LABEL: '複数選択式', optionIconName: '$check_box_outline_blank_round' }],
]
)

/**
 * アンケートグラフ種別
 */
export const CHART_TYPES = {
  /**
   * 帯グラフ
   */
  HORIZONTAL: 1,

  /**
   * 円グラフ
   */
  PIE: 2,
} as const
export type ChartType = typeof CHART_TYPES[keyof typeof CHART_TYPES]

/**
 * アンケート設問構成要素種別
 */
export const QUESTIONNAIRE_QUESTION_ELEMENT_TYPES = {
  /**
   * 自由入力（入力式）
   */
  FREE: 0,

  /**
   * 選択肢（選択式）
   */
  SELECTABLE_OPTION: 1,

  /**
   * その他（選択式）
   */
  OTHER_OPTION: 2,
} as const
export type QuestionnaireQuestionElementType = typeof QUESTIONNAIRE_QUESTION_ELEMENT_TYPES[keyof typeof QUESTIONNAIRE_QUESTION_ELEMENT_TYPES]

/**
 * 設問構成要素種別
 */
export const QUESTION_ELEMENT_TYPES = {
  /**
   * 自由入力式
   */
  FREE_INPUT: 0,
  /**
   * 選択肢
   */
  CHOICE: 1,
  /**
   * その他
   */
  OTHER: 2,
} as const
export type QuestionElementType = typeof QUESTION_ELEMENT_TYPES[keyof typeof QUESTION_ELEMENT_TYPES]

/** 管理業務 業務種別 */
export const MANAGEMENT_OPERATION_TYPES = {
  /** 稼働準備 */
  ONBOARDING: 1,

  /** 定例業務 */
  ROUTINE: 2,
} as const
export type ManagementOperationTypes = typeof MANAGEMENT_OPERATION_TYPES[keyof typeof MANAGEMENT_OPERATION_TYPES]

/** 管理業務タスク別対応状況 対応状態 */
export const OPERATION_STATES = {
  /** 未 */
  UNRESOLVED: 1,

  /** 済 */
  COMPLETED: 2,

  /** 対象外 */
  OUT_OF_SCOPE: 9,

} as const
export type OperationState = typeof OPERATION_STATES[keyof typeof OPERATION_STATES]

/**
 * 管理業務実施者種別
 */
export const TARGET_EXECUTOR_TYPE = {
  /**
   * 管理者業務執行者
   */
  SERVICE_STAFF: 1,
  /**
   * その他の実施者
   */
  UNREGISTERED_EXECUTOR: 2,

} as const

export type TargetExecutorType = typeof TARGET_EXECUTOR_TYPE[keyof typeof TARGET_EXECUTOR_TYPE]
