import type { TicketTaskNameType, TicketTaskState } from '@/constants/schema-constants'

export class TaskPutRequest {
  ticketTaskId!:string
  ticketTaskState!:TicketTaskState
  ticketTaskNameType!:TicketTaskNameType
  ticketTaskName?:string
  deadline!:string
  version!:number

  constructor(ticketTaskId: string, ticketTaskState: TicketTaskState, ticketTaskNameType: TicketTaskNameType, deadline: string, version: number) {
    this.ticketTaskId = ticketTaskId
    this.ticketTaskState = ticketTaskState
    this.ticketTaskNameType = ticketTaskNameType
    this.deadline = deadline
    this.version = version
  }
}

export class TaskPutResponse {
  version!: number
}
