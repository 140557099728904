

























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmLegend extends Vue {
  @Prop({ default: false })
  private readonly captionMini!:boolean

  @Prop({ default: false })
  private readonly bold!:boolean

  @Prop({ default: false })
  private readonly print!:boolean

  @Prop({ default: false })
  private readonly line!:boolean

  @Prop({ default: false })
  private readonly circle!:boolean

  @Prop({ required: true })
  private readonly label!:string

  @Prop({ required: true })
  private readonly symbolColor!:string

  private readonly DEFAULT_HEIGHT = 12
  private readonly LINE_HEIGHT = 1
  private readonly CAPTION_HEIGHT = 8

  private readonly DEFAULT_WIDTH = 12
  private readonly CAPTION_WIDTH = 8

  private get _height():number {
    if (this.line) {
      return this.LINE_HEIGHT
    } else if (this.captionMini) {
      return this.CAPTION_HEIGHT
    }
    return this.DEFAULT_HEIGHT
  }

  private get _width():number {
    if (this.captionMini) { return this.CAPTION_WIDTH }
    return this.DEFAULT_WIDTH
  }

  private get _radius():number {
    if (this.circle) { return 50 }
    return 0
  }
}
