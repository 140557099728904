


















import { Vue, Component, Prop } from 'vue-property-decorator'

export class ChartItem {
  name!: string
  percentage!: number
  color!: string
}

@Component
export default class SmChartStackedBar extends Vue {
  @Prop()
  private readonly items!: ChartItem[]
}
