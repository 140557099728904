import { Type } from 'class-transformer'
import type { AdminRole, InitialAuthState } from '@/constants/schema-constants'

export class MembersGetRequest {
  role!: AdminRole
  keyword?: string

  constructor(role: AdminRole) {
    this.role = role
  }
}

export class MembersGetResponseStaff {
  userId!: string
  employeeId!: string
  userName!: string
  initialAuthState?: InitialAuthState
  initialPassword?: string
  authKeyRecipient?: string
  isEmailSent!: boolean
}

export class MembersGetResponse {
  @Type(() => MembersGetResponseStaff) staffs: MembersGetResponseStaff[] = []
}
