










































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmPrintHeader extends Vue {
  @Prop({ required: true })
  private readonly title!:string

  @Prop({ required: true })
  private readonly myCurrentBuilding!:string

  @Prop({ required: true })
  private readonly processedAt!:string
}
