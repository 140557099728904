
































































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import { OnlineResolutionVote } from '@/dtos/resolutions/online-resolution/votes/get'
import { onlineResolutionsModule } from '@/stores/online-resolutions-store'
import { OnlineVotePost, VotesPostRequest } from '@/dtos/resolutions/online-resolution/votes/post'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),

    ProxyVoteTable: () => import('@/pages/resolutions/online-resolution/online-resolution-detail/results/ProxyVoteTable.vue'),
  }
})
export default class ProxyVoteModal extends Vue {
  @Model('change')
  private readonly isVisible!: boolean

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  @Prop({ required: true })
  private readonly resolutionId!: string

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!:string

  // 検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!:string

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  private get version():number { return onlineResolutionsModule.votesGet.version }

  private votes:OnlineResolutionVote[] = []

  async created():Promise<void> {
    this.votes = onlineResolutionsModule.votesGet.votes.map(e => { return { ...e } })
  }

  showDialog = false

  private readonly LEGEND = '賛成：○　反対：×'

  async updateVotes():Promise<void> {
    this.showDialog = false
    const req = new VotesPostRequest()
    req.resolutionId = this.resolutionId
    req.version = this.version
    req.votes = this.votes.map(e => {
      const vote = new OnlineVotePost()
      vote.ownerUserId = e.userId
      vote.voteType = e.voteType
      return vote
    })
    await onlineResolutionsModule.postVotes(req)
    this.$emit('update-vote-proxy')
    this.isModalVisible = false
  }

  private reset(userId: string):void {
    this.votes = this.votes.map(e => {
      const vote = { ...e }
      if (vote.userId === userId) {
        vote.voteType = undefined
      }
      return vote
    })
  }

  private changeVote(item:OnlineResolutionVote):void {
    this.votes = this.votes.map(e => {
      const vote = { ...e }
      return vote.userId === item.userId ? item : vote
    })
  }

  search():void {
    this._keyword = this._inputText
  }
}
