import { Type } from 'class-transformer'
import { QuestionnaireQuestionType } from '@/constants/schema-constants'

export class QuestionQuestionGetRequest {
  questionnaireId!:string
  questionnaireQuestionId!:string

  constructor(questionnaireId: string, questionnaireQuestionId:string) {
    this.questionnaireId = questionnaireId
    this.questionnaireQuestionId = questionnaireQuestionId
  }
}

export class Answer {
  userId!: string
  userName!: string
  roomNumber!: string
  answerCount?:number
  ownerAnswer!: string
}

export class QuestionnaireQuestionDetailGetResponse {
  questionnaireQuestionId!: string
  questionSentence!: string
  questionType!: QuestionnaireQuestionType
  @Type(() => Answer)answers: Answer[] = []
}
