
































































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import { STYLES } from '@/constants/styles'
import { Tab } from '@/components/molecules/SmTabs.vue'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmTabs: () => import('@/components/molecules/SmTabs.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmAppBar extends Vue {
  STYLES = Object.freeze(STYLES)

  @Prop({ default: false })
  private readonly app!: boolean

  @Prop()
  private readonly title?: string

  @Prop()
  private readonly icon?: string

  @Prop()
  private readonly rightIcon?: string

  @Prop()
  private readonly tabs?: Tab[]

  @Prop({ default: false })
  isAvailableExtensionContent!: boolean

  @Prop({ default: STYLES.APP_BAR_EXTENSION_HEIGHT })
  private readonly extensionHeight!: number

  @Model('change-tab')
  private currentTab?: string

  private get _currentTab(): string | undefined { return this.currentTab }
  private set _currentTab(newValue: string | undefined) { this.$emit('change-tab', newValue) }

  private get isAvailableExtension():boolean {
    return (this.tabs !== undefined && this.tabs.length > 0) || this.isAvailableExtensionContent
  }
}

