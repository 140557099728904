export class AdminUserId {
  userId: string

  constructor(userId: string) {
    this.userId = userId
  }
}

export class TicketAdminInChargePutRequest {
  ticketId!: string
  admin!: AdminUserId
  version!: number

  constructor(ticketId: string, admin: AdminUserId, version: number) {
    this.ticketId = ticketId
    this.admin = admin
    this.version = version
  }
}
