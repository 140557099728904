import { RESOLUTION_STATES } from '@/constants/schema-constants'
import type { ResolutionState } from '@/constants/schema-constants'
import { User } from '../commons'

export class Details {
  isResolution!: boolean
  isQuestions!: boolean
  isResult!: boolean
  // 総会決議詳細取得とオンライン決議詳細取得の双方のdetailsに適用するため任意項目とする
  isStatements?: boolean
}

export class ResolutionCommonState {
  resolutionId = ''
  resolutionState: ResolutionState = RESOLUTION_STATES.GENERAL_MEETING.DRAFT
  version!: number
  details: Details = new Details()
  title = ''
  user: User = new User()
}

export class GMResolutionCommonState extends ResolutionCommonState {
  canHoldRemote?: boolean
}
