











































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue')
  }
})
export default class SmDataRow extends Vue {
  @Prop()
  private readonly label?: string

  @Prop()
  private readonly data?: string | number

  @Prop()
  private readonly labelCaption?: string

  @Prop({ default: 200 })
  private readonly labelWidth!: number

  @Prop({ default: false })
  private readonly disableDivider?: boolean
}
