







































import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { staticRoutes } from '@/routes'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { PAGE_TYPES } from '@/constants/ux-constants'
import type { PageType } from '@/constants/ux-constants'
import { simpleRepairsModule } from '@/stores/simple-repairs-store'
import { SimpleRepairsUpdatesCommentsPostRequest } from '@/dtos/simple-repairs/updates/comments/post'
import { SimpleRepairsUpdatesCommentDetailGetRequest, SimpleRepairsUpdatesCommentDetailGetResponse } from '@/dtos/simple-repairs/updates/comments/get-details'
import { SimpleRepairsUpdatesCommentsPutRequest } from '@/dtos/simple-repairs/updates/comments/put'

interface PageTypeSpecifications {
  pageTitle: string
  executeBtnLabel: string
  dialogMessage: string

  created(commentId?: string): Promise<void>
  onClickExecute($router: VueRouter, comment: string, commentId?: string, version?: number): Promise<void>
}

class CreateSpecifications implements PageTypeSpecifications {
  readonly pageTitle = '変更コメント新規作成'
  readonly executeBtnLabel = '投稿する'
  readonly dialogMessage = '変更コメントを投稿します。よろしいですか？'

  async created() { /** nothing to do */ }

  async onClickExecute($router: VueRouter, comment: string): Promise<void> {
    const req = new SimpleRepairsUpdatesCommentsPostRequest()
    req.comment = comment

    await simpleRepairsModule.postSimpleRepairsUpdatesComments(req)

    $router.push({ name: staticRoutes.simpleRepairPlanDetail.name })
  }
}

class UpdateSpecifications implements PageTypeSpecifications {
  readonly pageTitle = '変更コメント編集'
  readonly executeBtnLabel = '更新する'
  readonly dialogMessage = '変更コメントを更新します。よろしいですか？'

  async created(commentId: string): Promise<void> {
    await simpleRepairsModule.fetchSimpleRepairsUpdatesCommentDetail(new SimpleRepairsUpdatesCommentDetailGetRequest(commentId))
  }

  async onClickExecute($router: VueRouter, comment: string, commentId: string, version: number): Promise<void> {
    const req = new SimpleRepairsUpdatesCommentsPutRequest()
    req.commentId = commentId
    req.comment = comment
    req.version = version

    await simpleRepairsModule.putSimpleRepairsUpdatesComments(req)

    $router.go(-1)
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class SimpleRepairPlanCommentsRegisterPage extends Vue {
  @Prop({ required: true, default: PAGE_TYPES.CREATE })
  private readonly pageType!: PageType

  @Prop()
  private readonly commentId?: string

  async created(): Promise<void> {
    await this.typeSpecs.created(this.commentId)
  }

  private get typeSpecs(): PageTypeSpecifications {
    switch (this.pageType) {
      case PAGE_TYPES.CREATE: return new CreateSpecifications()
      case PAGE_TYPES.EDIT: return new UpdateSpecifications()
      default: return assertExhaustive(this.pageType)
    }
  }

  comment = ''
  version: number | null = null

  private get storedSimpleRepairsUpdatesCommentDetail(): SimpleRepairsUpdatesCommentDetailGetResponse | undefined {
    if (!this.commentId) return undefined
    return simpleRepairsModule.simpleRepairsUpdatesCommentDetailGet(this.commentId)
  }

  @Watch('storedSimpleRepairsUpdatesCommentDetail', { immediate: false, deep: false })
  onStoredUpdatesCommentDetailFetched(fetched: SimpleRepairsUpdatesCommentDetailGetResponse | undefined): void {
    if (!fetched) return
    this.comment = fetched.comment
    this.version = fetched.version
  }

  isExecuteDialogVisible = false
  openExecuteDialog(): void { this.isExecuteDialogVisible = true }
  closeExecuteDialog(): void { this.isExecuteDialogVisible = false }

  async onClickExecute($router: VueRouter, comment: string, commentId: string, version: number): Promise<void> {
    this.isExecuteDialogVisible = false
    await this.typeSpecs.onClickExecute($router, comment, commentId, version)
  }
}
