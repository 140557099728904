























import { Tab } from '@/components/molecules/SmTabs.vue'
import { staticRoutes } from '@/routes'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class DashboardPage extends Vue {
  private get tabs(): Tab[] {
    const tabs = [
      new Tab('全体', staticRoutes.dashboard.getChild('all').name),
      new Tab('担当分', staticRoutes.dashboard.getChild('personal').name),
    ]
    return tabs
  }

  goToOperationOnboarding(): void {
    this.$router.push({ name: staticRoutes.tasks.getChild('onboarding').name })
  }
}
