






























import { Vue, Component, Prop } from 'vue-property-decorator'
import { ChartData, ChartOptions } from 'chart.js'
import { ENQUETE_CHART_BACKGROUND_COLORS } from '@/constants/ux-constants'
import { Context } from 'chartjs-plugin-datalabels'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmChartHorizontalBar: () => import('@/components/molecules/SmChartHorizontalBar.vue'),
  }
})

export default class SmChartUnionReportHorizontalBar extends Vue {
  @Prop({ required: true, default: '' })
  label!: string

  @Prop({ required: true, default: () => [] })
  voteResult!:number[]

  private get total(): number {
    return this.voteResult.reduce((sum, element) => sum + element, 0)
  }

  /**
   * 棒ブラフで表示させる情報を配列で入れる
   */
  private get chartData(): ChartData {
    const dataSets = this.voteResult.map((numOfVotes, i) => {
      return {
        type: 'horizontalBar',
        data: [this.getPercentage(numOfVotes, this.total)],
        backgroundColor: [ENQUETE_CHART_BACKGROUND_COLORS[i]]
      }
    })
    return {
      datasets: dataSets
    }
  }

  /**
   * 実数値をパーセンテージ（小数第一位四捨五入）に変換
   */
  private getPercentage(elementNum: number, total: number): number {
    if (elementNum === 0) return 0
    if (total === 0) return 0
    return Math.round(elementNum / total * 100)
  }

  /**
   * responsive:trueとmaintainAspectRatio:falseにすることでstyleでグラフサイズをstyleで指定することが可能
   * 凡例は既存のコンポーネントで表示想定のため、dispay:falseに設定
   * カーソル当たるとパーセンテージが表示されるので、tooltipsで表示させないよう制御
   * devicePixelRatioはグラフをPDFで出力する際にグラフが荒く表示されてしまうため、2に設定
   * scalesの内容はdataの内容を重ねて表示するために指定（指定しないとグラフが3つ縦に並んで表示される）
   */
  private get chartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      devicePixelRatio: 2,
      tooltips: {
        enabled: false
      },
      scales: {
        xAxes: [{
          display: false,
          stacked: true,
          gridLines: {
            display: false,
          }
        }],
        yAxes: [{
          display: false,
          stacked: true,
          gridLines: {
            display: false,
          }
        }],
      },
      plugins: {
        datalabels: {
          color: this.total === 0 ? 'black' : 'white',
          font: {
            weight: 'bold'
          },
          display: (context: Context) => {
            if (context.dataset.data && this.voteResult) {
              if (context.dataset.data[0] === 0) {
                if (this.voteResult.length > context.datasetIndex && this.voteResult[context.datasetIndex + 1] === 0) {
                  return false
                }
              }
            }
            return 'auto'
          },
          align: (context: Context) => {
            if (context.dataset.data && this.voteResult) {
              if (context.dataset.data[0] === 0) {
                if (context.datasetIndex === (this.voteResult.length - 1)) return 'start'
                return 'end'
              }
            }
            return 'center'
          },
          formatter: function(value: number): string { return value + '%' }
        }
      }
    }
  }
}
