






























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { NEW_TAB_TRANSITION_TO } from '@/constants/schema-constants'
import { openNewTab } from '@/libs/open-new-tab'
import { CasysDetectionDetailGetRequest, CasysDetectionDetailGetResponse, Building } from '@/dtos/casys/detections/get-detail'
import { casysModule } from '@/stores/casys-store'
import { NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { errorsModule } from '@/stores/errors'
import type { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'

const TAKE = 50

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableCollationResult: () => import('@/components/molecules/SmTableCollationResult.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    CollationResultDetailUploadDataArea: () => import('@/pages/collation-results/detail/CollationResultDetailUploadDataArea.vue'),
  }
})
export default class CasysDetectionDetailPage extends Vue {
  @Prop({ required: true, default: '' })
  private readonly casysDifferenceDetectionBatchStatusId!: string

  skip = 0
  identifier = 1

  get casysDetectionDetail(): CasysDetectionDetailGetResponse { return casysModule.casysDetectionDetailGet }
  get casysDetectionResultBuildings(): Building[] { return casysModule.detectionDetailBuildings }
  get title(): string { return casysModule.casysDetectionDetailGet.uploadedAt }

  async created(): Promise<void> {
    this.clearCasysDetectionsResultBuildings()
  }

  clearCasysDetectionsResultBuildings(): void {
    casysModule.clearDetectionDetailBuildings()
    this.identifier++
  }

  handler: LoadingHandler | null = null
  async loadCollationResults(handler: LoadingHandler):Promise<void> {
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }

    const skip = casysModule.detectionDetailBuildings.length
    const req = new CasysDetectionDetailGetRequest(
      skip,
      TAKE,
      this.casysDifferenceDetectionBatchStatusId
    )
    await casysModule.fetchCasysDetectionDetail(req)

    if (this.casysDetectionResultBuildings.length === 0) {
      // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
      handler.complete()
      return
    }

    const expectingToBe = skip + TAKE
    if (this.casysDetectionResultBuildings.length < expectingToBe) {
      handler.complete()
      return
    }

    handler.loaded()
  }

  openOwnerUserDetailPage(unitId: string, buildingId?: string):void {
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.OWNER_DETAIL
    newTabTransitionParams.buildingId = buildingId
    newTabTransitionParams.unitId = unitId
    openNewTab(newTabTransitionParams)
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
  }
}
