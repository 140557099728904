























































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { QuestionnaireReportsResponse, QuestionnaireSummaryDto } from '@/dtos/questionnaires/reports/get'

import { CHART_TYPES, MATERIAL_TYPES } from '@/constants/schema-constants'
import { ENQUETE_CHART_BACKGROUND_COLORS } from '@/constants/ux-constants'
import { questionnaireReportsModule } from '@/stores/questionnaire-reports-store'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),
    SmTextPrintSizeAnnounce: () => import('@/components/atoms/SmTextPrintSizeAnnounce.vue'),

    SmChartUnionReportHorizontalBar: () => import('@/components/molecules/SmChartUnionReportHorizontalBar.vue'),
    SmChartUnionReportPie: () => import('@/components/molecules/SmChartUnionReportPie.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmPrintLabel: () => import('@/components/molecules/SmPrintLabel.vue'),

    SmPrintHeader: () => import('@/components/organisms/SmPrintHeader.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class QuestionnaireReportPrintPage extends Vue {
  CHART_TYPES = Object.freeze(CHART_TYPES)
  ENQUETE_CHART_BACKGROUND_COLORS = Object.freeze(ENQUETE_CHART_BACKGROUND_COLORS)
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop()
  private readonly questionnaireId!: string

  @Prop()
  private readonly questionnaireReportId!: string

  async created(): Promise<void> {
    const questionnaireId = this.questionnaireId
    const questionnaireReportId = this.questionnaireReportId
    await questionnaireReportsModule.fetchReport({ questionnaireId, questionnaireReportId })
  }

  private get detail(): QuestionnaireReportsResponse {
    return questionnaireReportsModule.questionnaireReportsResponse
  }

  private getChartLabel(questionnaireSummary: QuestionnaireSummaryDto): string {
    const date = questionnaireSummary.deadline.split(/年|月|日/)
    const sumCounts = questionnaireSummary.finalAnswerCount

    return `${date[0]}/${date[1]}/${date[2]}：${sumCounts}件`
  }

  private getChartValueResult(questionnaireSummary: QuestionnaireSummaryDto): number[] {
    return questionnaireSummary.choicesSummary.map(cs => Number(cs.finalAnswerCount))
  }

  private get isResultSection(): boolean {
    if (this.detail.questionnaireReportResultSection && this.detail.questionnaireReportResultSection.length > 0) return true
    else return false
  }
}
