import { Type } from 'class-transformer'
import type { QuestionnaireState } from '@/constants/schema-constants'

export class QuestionnairesSearchPostRequest {
  questionnaireStates?: QuestionnaireState[] = []
  buildings?: string[] = []
  templates?: string[] = []
  fromDate?: string | null = null
  toDate?: string | null = null
  keyword?: string | null = null
  sortOrder?: string
  skipToken?: string
  take: number

  constructor(take: number) { this.take = take }
}

export class Questionnaire {
  questionnaireId!: string
  questionnaireTitle!: string
  templateId!: string
  templateName!: string
  questionnaireState!: QuestionnaireState
  deadline!: string
  deadlineDate!: string
  postedAt!: string
  buildingName!: string
  readCount?: number
  answerCount?: number
}

export class QuestionnairesSearchPostResponse {
  @Type(() => Questionnaire) questionnaires: Questionnaire[] = []
  skipToken!: string
}
