import { Type } from 'class-transformer'

export class ReservePaymentDetailGetRequest {
  buildingUnitId: string
  constructor(unitId: string) { this.buildingUnitId = unitId }
}

export class UnitPlanDetailMonthlyPrice {
  month!: number
  monthlyPrice?: number
  isPaid?: boolean
}

export class UnitPaymentPlanDetail {
  period!: number
  accountingMonth!: string
  reservedAmount?: number
  @Type(() => UnitPlanDetailMonthlyPrice) monthlyPrices: UnitPlanDetailMonthlyPrice[] = []
}

export class ReservePaymentDetailGetResponse {
  buildingUnitId!: string
  planCode?: string
  planName?: string
  userName!: string
  roomNumber?: string
  @Type(() => UnitPaymentPlanDetail) paymentPlanDetail: UnitPaymentPlanDetail[] = []
}
