




































































import { RepairPlan, ReserveFund } from '@/dtos/simple-repairs/plans/get'
import { buildingsModule } from '@/stores/buildings-store'
import { Vue, Component, Prop } from 'vue-property-decorator'

class Record {
  label!: string
  symbolColor!:string
  isLine?: boolean
  cells!: (number | undefined)[]
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmOwLegend: () => import('@/components/owner-apps/SmOwLegend.vue'),

    VueScrollingTable: () => import('@/components/external-packages/vue-scrolling-table/VueScrollingTable.vue'),
  }
})
export default class SmTableSimpleRepairPlanPrint extends Vue {
  @Prop({ required: true })
  private readonly repairPlan!: RepairPlan

  @Prop({ required: true })
  private readonly reserveFund!: ReserveFund

  private get periods():number[] {
    return this.repairPlan.originals.map(e => e.period)
  }

  private get yearMonths():string[] {
    return this.periods.map(e => `${e}期\n${buildingsModule.periodToYearMonth(e)}`)
  }

  private get records():Record[] {
    return [
      {
        label: '当初修繕\n計画',
        symbolColor: '#000000',
        isLine: false,
        cells: this.repairPlan.originals.map(e => e.amount),
      },
      {
        label: '修繕費\n実績',
        symbolColor: '#FCB9B9',
        isLine: false,
        cells: this.repairPlan.performances ? this.repairPlan.performances.map(e => e.amount) : [],
      },
      {
        label: '積立額\n予測',
        symbolColor: '#007296',
        isLine: true,
        cells: this.reserveFund.prospects.map(e => e.amount),
      },
      {
        label: '積立額\n実績',
        symbolColor: '#B03131',
        isLine: true,
        cells: this.reserveFund.performances ? this.reserveFund.performances.map(e => e.amount) : [],
      },
    ]
  }
}
