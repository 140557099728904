




































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmListRepairPlanUpdateDetail: () => import('@/pages/repair-plan/SmListRepairPlanUpdateDetail.vue'),
  }
})
export default class SmTableRepairPlanUpdateDetails extends Vue {
  @Prop({ required: true })
  private readonly logs!: { previous: string, current: string }[]

  @Prop({ default: '変更前' })
  private readonly leftHeaderName!: string

  @Prop({ default: '変更後' })
  private readonly rightHeaderName!: string
}
