





























import { Component, Vue, Prop, Model } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { QuestionItem } from '@/dtos/questionnaires/questions/post'
import { TemplateQuestionItem } from '@/dtos/questionnaires/templates/questions/get'
import { questionnaireReportsModule } from '@/stores/questionnaire-reports-store'
import { questionnaireTemplatesModule } from '@/stores/questionnaire-templates-store'

@Component({
  components: {
    SmBadgeCategory: () => import('@/components/atoms/SmBadgeCategory.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class QuestionSelectModal extends Vue {
  @Model('change')
  private readonly isVisible!: boolean

  @Prop({ default: () => [] })
  selectedQuestionIds!: string[]

  // 設問種別を表示するかを判定するフラグ
  @Prop()
  showDisplayType!: boolean

  created():void {
    if (!this.showDisplayType) this.questionIds = this.selectedQuestionIds
  }

  private questionIds: string[] = []

  onClickSelectBtn():void {
    this.$emit('select-questions', this.questionIds)
    this.isModalVisible = false
  }

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get questionItems(): QuestionItem[] | TemplateQuestionItem[] {
    if (this.showDisplayType) return questionnaireReportsModule.questionGet.questionnaireQuestions
    else return questionnaireTemplatesModule.questionGet.templateQuestions
  }

  private get headers(): Header[] {
    const headers = [
      new Header({ text: 'セクションタイトル', value: 'sectionTitle', width: '214px' }),
      new Header({ text: '設問文', value: 'questionSentence' })
    ]
    if (this.showDisplayType) headers.splice(1, 0, new Header({ text: 'タイプ', value: 'questionType', width: '102px' }))
    return headers
  }

  private get isSelected(): boolean {
    if (this.questionIds.length < 1 || !this.questionIds[0]) return false
    return true
  }
}
