import { Type } from 'class-transformer'
import { User } from '@/dtos/commons'

export class SimpleRepairsUpdatesCommentsGetRequest {
  keyword?: string
  sortOrder?: string
  skip: number
  take: number

  constructor(skip: number, take: number) {
    this.skip = skip
    this.take = take
  }
}

export class Comment {
  commentId!: string
  comment!: string
  postedAt!: string
  @Type(() => User) user: User = new User()
}

export class SimpleRepairsUpdatesCommentsGetResponse {
  @Type(() => Comment) comments: Comment[] = []
}
