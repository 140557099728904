


























































































import { Component, Mixins } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { NOT_SELECTABLE_PERIOD_VALUE } from '@/constants/ux-constants'

import { Duration } from '@/dtos/commons'
import { PaymentPlan, PaymentPlanGetRequest } from '@/dtos/reserve-funds/plans/get'
import { ReservesGetResponse } from '@/dtos/reserve-funds/get'

import { getBillingPeriod } from '@/libs/reserve-funds-billing-handler'
import { reservesBldPlanParser } from '@/libs/excel-parser/reserves-building-plan-parser'
import { buildingsModule } from '@/stores/buildings-store'
import { reserveFundsPlansModule } from '@/stores/reserve-funds-plans-store'
import { reserveFundsModule } from '@/stores/reserve-funds-store'

const DOWNLOAD_FAILED_MESSAGE = '想定外のエラーによりファイルのダウンロードに失敗しました。画面を読み込み直すか、しばらくしてから再度お試しください。'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    PaymentsPlansTable: () => import('@/pages/reserve-funds/plans/PaymentPlansTable.vue'),
  }
})
export default class PlansSubPage extends Mixins(CurrentAdminManager) {
  async beforeCreate():Promise<void> {
    await reserveFundsPlansModule.fetchPaymentPlans(new PaymentPlanGetRequest())
  }

  private get paymentPlans():PaymentPlan[] {
    return reserveFundsPlansModule.paymentPlans.paymentPlans
  }

  private get reserves(): ReservesGetResponse | undefined { return reserveFundsModule.reserveFund } // tabの親がfetchしている
  private get buildingName(): string | undefined { return buildingsModule.buildingDetailGet?.buildingName }

  targetPeriods:Duration[] = [new Duration()]

  addTargetPeriod():void {
    this.targetPeriods.push(new Duration())
  }

  private get periods():{ value: number | null, label: string }[] {
    const nullValue:{ value: number | null, label: string }[] = [{ value: NOT_SELECTABLE_PERIOD_VALUE, label: '-' }]
    const billingPeriod = getBillingPeriod()

    const periods = Array.from({ length: 60 - (billingPeriod - 1) }).map((_, index) => {
      const period = billingPeriod + index
      return { value: period, label: `${period}` }
    })
    return nullValue.concat(periods)
  }

  openDetail(paymentPlanId:string):void {
    this.$router.push({
      name: staticRoutes.reserveFundsPaymentPlanDetail.name,
      params: { paymentPlanId: paymentPlanId }
    })
  }

  openAddMode():void {
    this.$router.push({ name: staticRoutes.reserveFundsPaymentPlanEdit.name })
  }

  errors:string[] = []

  async downloadExcel(): Promise<void> {
    this.errors = []
    const periods = this.targetPeriods.filter(p => p.periodFrom !== NOT_SELECTABLE_PERIOD_VALUE && p.periodTo !== NOT_SELECTABLE_PERIOD_VALUE)
    periods.sort((a, b) => a.periodFrom - b.periodFrom)
    // 範囲重複チェック
    // 一度[from1,to1,from2,to2,...]という一つの配列に変換
    // 1,3,3,4のように重複・前後不順があればエラー
    const isDuplicated = periods.flatMap(p => [p.periodFrom, p.periodTo]).some((num, idx, arr) => idx !== 0 && num <= arr[idx - 1])
    if (isDuplicated) {
      this.errors.push('入力された期が重ならないように入力をしてください。')
      return
    }

    if (!this.reserves || !this.buildingName) {
      this.errors.push(DOWNLOAD_FAILED_MESSAGE)
      return
    }

    try {
      await reservesBldPlanParser.downloadAsExcel(this.buildingName, periods, this.reserves)
    } catch (err) {
      this.errors.push(DOWNLOAD_FAILED_MESSAGE)
      throw err
    }
  }

  private get isNoTargetPeriod() {
    return this.targetPeriods.some(m => {
      if (m.periodFrom === NOT_SELECTABLE_PERIOD_VALUE && m.periodTo === NOT_SELECTABLE_PERIOD_VALUE) return false
      return m.periodFrom === NOT_SELECTABLE_PERIOD_VALUE || m.periodTo === NOT_SELECTABLE_PERIOD_VALUE
    })
  }

  NOT_SELECTABLE_PERIOD_VALUE = Object.freeze(NOT_SELECTABLE_PERIOD_VALUE)
}
