import { APIClientBase, HTTP_METHOD } from './api-client-base'
import { UnitsGetRequest, UnitsGetResponse } from '@/dtos/units/get'
import { UnitDetailGetRequest, UnitDetailGetResponse } from '@/dtos/units/get-detail'
import { UnitOwnerSimulateTokenGetRequest, UnitOwnerSimulateTokenGetResponse } from '@/dtos/units/simulate-token/get'
import { UnitsPutRequest, UnitsChangePutRequest } from '@/dtos/units/put'

class UnitsClient extends APIClientBase {
  constructor() {
    super('/units')
  }

  async getUnits(req: UnitsGetRequest): Promise<UnitsGetResponse> {
    return await this.get('', req, UnitsGetResponse)
  }

  async downloadUnitsCsv(req: UnitsGetRequest): Promise<void> {
    await this.download('/csv', req, HTTP_METHOD.GET, true)
  }

  getUnitDetail(req: UnitDetailGetRequest): Promise<UnitDetailGetResponse> {
    return this.get(`/${req.unitId}`, undefined, UnitDetailGetResponse)
  }

  getSimulateToken(req: UnitOwnerSimulateTokenGetRequest): Promise<UnitOwnerSimulateTokenGetResponse> {
    return this.get(`/${req.unitId}/simulate-token`, undefined, UnitOwnerSimulateTokenGetResponse)
  }

  putUnitDetail(req: UnitsPutRequest): Promise<void> {
    const { unitId, ...body } = req
    return this.put(`/${unitId}`, body)
  }

  putUnitDetailChange(req: UnitsChangePutRequest): Promise<void> {
    const { unitId, ...body } = req
    return this.put(`/${unitId}/owner`, body)
  }
}

export const unitsClient = new UnitsClient()
