























































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import { RESOLUTION_STATES } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'

import { getResolutionLabel } from '@/libs/state-handler'
import { newTabLocalParamStorageModule, OnlineResolutionPreviewContent } from '@/stores/new-tab-local-param-storage-store'
import { staticRoutes } from '@/routes'
import { windowOpen } from '@/libs/window-open'

@Component({
  components: {
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmOwBtnContained: () => import('@/components/owner-apps/SmOwBtnContained.vue'),
    SmOwBtnText: () => import('@/components/owner-apps/SmOwBtnText.vue'),
    SmOwExpansionArea: () => import('@/components/owner-apps/SmOwExpansionArea.vue'),
    SmOwListBudget: () => import('@/components/owner-apps/SmOwListBudget.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class adminIdeaPreviewPage extends Vue {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)

  @Prop({ default: '' })
    private readonly id!: string

  private get resolutionDetail(): OnlineResolutionPreviewContent {
    return newTabLocalParamStorageModule.onlineReslutionPreviewContent(this.id) ?? new OnlineResolutionPreviewContent()
  }

  private get stateLabel():string {
    return getResolutionLabel(this.resolutionDetail.resolutionState)
  }

  private get isQuestionable(): boolean {
    return this.resolutionDetail.resolutionState === RESOLUTION_STATES.ONLINE.ACCEPTING_ALL || this.resolutionDetail.resolutionState === RESOLUTION_STATES.ONLINE.DRAFT
  }

  private get isHiddenVoteArea(): boolean {
    return this.resolutionDetail.resolutionState === RESOLUTION_STATES.ONLINE.COUNTING || this.resolutionDetail.resolutionState === RESOLUTION_STATES.ONLINE.RELEASED
  }

  voteFor():void { /* nothig to do */ }

  voteAgainst():void { /* nothig to do */ }

  goToBaseIdea(baseIdeaId: string): void {
    const path = staticRoutes.adminIdeaDetailPreview.path.replace(':ideaId', baseIdeaId)
    windowOpen(`${path}/detail?buildingId=${this.resolutionDetail?.currentBuildingId}`)
  }

  goToQuestion(): void {
    if (this.resolutionDetail?.resolutionId && this.resolutionDetail?.isQustions) {
      const path = this.resolutionDetail ? staticRoutes.onlineResolutionDetailPreview.path.replace(':resolutionId', this.resolutionDetail?.resolutionId) : ''
      windowOpen(`${path}/questions?buildingId=${this.resolutionDetail?.currentBuildingId}`)
    }
  }
}
