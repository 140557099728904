import { Material } from '../../commons'

export class ConsultationDraftPostRequest {
  userId: string
  consultationId?: string
  message: string
  material?: Material

  constructor(userId: string, message: string, material?: Material | null, consultationId?: string) {
    this.userId = userId
    this.message = message
    this.consultationId = consultationId
    this.material = material ?? undefined
  }
}
