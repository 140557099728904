export type _Key = { _key: number }

class StaticKeyProvider {
  _enumerator = 0

  /**
   * `_key`という項目に一意な値を持ったT型のオブジェクトを生成します。
   * リストレンダリングのキー値として使用することを想定しています。
   *
   * @param ArrayElementClass 一意なキー値を与えたい、配列の要素になるオブジェクトのクラス
   */
  create<T>(ArrayElementClass: { new (): T }): T & _Key {
    const keyedClass = new ArrayElementClass() as T & _Key
    keyedClass._key = this._enumerator++
    return keyedClass
  }
}

/**
 * ユーザ操作に基づいて動的に配列要素の増減や順序の入れ替えをするような画面で、
 * 要素1つ1つに固定で一意のキー値を付与する仕組みを提供します。
 */
export const staticKeyProvider = new StaticKeyProvider()
