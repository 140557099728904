
































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import VueRouter, { NavigationGuardNext, Route } from 'vue-router'
import { staticRoutes } from '@/routes'
import { FFNELEMENT_CARD_TYPE, FFNELEMENT_CARD_TYPE_RECORD, FFNELEMENT_MENU_ITEMS_ATTACHMENT_RESTRICTED, FFNELEMENT_MENU_ITEMS_DEFAULT, FFNELEMENT_MENU_ITEMS_LINK_SMOOTHE_ATTACHMENT_RESTRICTED, FFNELEMENT_MENU_ITEMS_LINK_SMOOTHE_RESTRICTED, TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_AFTER_BUILDING_SELECTED, TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_DEFAULT, TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS, TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_AFTER_OWNER_SELECTED, TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_DEFAULT, TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_IDS, TRANSITION_BUTTON_OPTION_VALUE } from '@/constants/owner-notifications/owner-notification-post-page-constants'
import type { FFNElementCardType, TargetBuildingTypeSelectMenuItemsIds, TargetOwnerTypeSelectMenuItemsIds, TransitionButtonOptionValues } from '@/constants/owner-notifications/owner-notification-post-page-constants'
import { BUILDING_MENU_ITEMS, IDEA_STATES, MATERIAL_TYPES, NOTIFICATION_ELEMENT_TYPES, OWNER_NOTIFICATION_STATE, OWNER_NOTIFICATION_TYPE, PINNING_SETTING_TYPE, POST_TIMING_TYPE, RESOLUTION_STATES, TARGET_BUILDING_TYPE, TARGET_OWNER_TYPE, TRANSITION_TO } from '@/constants/schema-constants'
import type { NotificationElementType, OwnerNotificationState, PinningSettingType, PostTimingType, TargetBuildingType, TargetOwnerType, TransitionTo } from '@/constants/schema-constants'
import { DAY_OF_WEEK, PAGE_TYPES } from '@/constants/ux-constants'
import type { PageType } from '@/constants/ux-constants'

import { assertExhaustive } from '@/libs/exhaustive-helper'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'
import { generateUuid } from '@/libs/uuid-generator'
import { windowOpen } from '@/libs/window-open'
import { adjustTagsAndCharacters, escapeAll, replaceBoldMarkToTag, replaceNewlineCharacterToTag, replaceStrikethroughMarkToTag } from '@/libs/wysiwyg-operator'

import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { BuildingIdeaDto, BuildingIdeasGetRequest } from '@/dtos/buildings/ideas/get'
import { OwnerNotificationPinningBuildingsGetRequest, OwnerNotificationPinningBuildingsGetResponse } from '@/dtos/buildings/owner-notifications/pinning/get'
import { BuildingOwnersGetRequest } from '@/dtos/buildings/owners/get'
import { BuildingResolutionDto, BuildingResolutionsGetRequest } from '@/dtos/buildings/resolutions/get'
import { MaterialFormInputDto } from '@/dtos/commons'
import { TargetOwner } from '@/dtos/owner-notifications/commons'
import { OwnerNotificationsDeleteRequest } from '@/dtos/owner-notifications/delete'
import { OwnerNotificationsDraftPostRequest } from '@/dtos/owner-notifications/draft/post'
import { FFNElement, OwnerNotificationDetailGetResponse } from '@/dtos/owner-notifications/get-detail'
import { OwnerNotificationsPostRequest, OwnerNotificationsPostRequestBuilding, OwnerNotificationsPostRequestBulletPoint, OwnerNotificationsPostRequestFFNElement, OwnerNotificationsPostRequestOwner } from '@/dtos/owner-notifications/post'
import { OwnerNotificationsPutRequest } from '@/dtos/owner-notifications/put'

import { buildingsModule } from '@/stores/buildings-store'
import { errorsModule } from '@/stores/errors'
import { newTabLocalParamStorageModule, OwnerNotificationPreviewContent } from '@/stores/new-tab-local-param-storage-store'
import { ownerNotificationsModule } from '@/stores/owner-notifications-store'
import { RadioOption } from '@/components/atoms/SmRadio.vue'

// YYYY-MM-DD形式で現在の年月日を返す
const currentDate = () => {
  const date = new Date()
  // ISOString形式の日本時間を取得したいが、
  // toISOString()の結果はUTCのため新規・修正に関わらず9時間進める
  date.setHours(date.getHours() + 9)
  return date.toISOString().substr(0, 10)
}

// YYYY-MM-DD形式で現在から1年後の年月日を返す
const oneYearAfterCurrentDate = () => {
  const date = new Date()
  // ISOString形式の日本時間を取得したいが、
  // toISOString()の結果はUTCのため新規・修正に関わらず9時間進める
  date.setFullYear(date.getFullYear() + 1)
  date.setHours(date.getHours() + 9)
  return date.toISOString().substr(0, 10)
}

const uploadThenPost = async <T, >(rawReq: T, exec: (_: T) => Promise<void>) => {
  const uploader = new FileUploader()
  const req = await uploader.prepare(rawReq)

  await exec(req)
}

class DisplayingFFNElement {
  ffnElementCardType!: FFNElementCardType
  elementBody?: string | null = null
  transitionType?: TransitionTo
  transitionParams?: Record<string, string>
  isLinkAvailable?: boolean
  externalSiteUrl?: string
  emailAddress?: string
  phoneNumber?: string
  material?: MaterialFormInputDto

  // 遷移先選択全画面ダイアログ用
  transitionToSelectModalOwnerIdeaKeyword = ''
  transitionToSelectModalOwnerIdeaInputText = ''
  transitionToSelectModalAdminIdeaKeyword = ''
  transitionToSelectModalAdminIdeaInputText = ''
  transitionToSelectModalOnlineResolutionKeyword = ''
  transitionToSelectModalOnlineResolutionInputText = ''
  transitionToSelectModalGMResolutionKeyword = ''
  transitionToSelectModalGMResolutionInputText = ''
  isTransitionToInput = false

  // WYSIWYGエディタ（テキストコンポーネント・注釈コンポーネント）以外の入力コンポーネントのフィールドエラー表示用
  // （fieldIdにリクエスト時の要素番号を組み込むことでAPIから返却されたフィールドエラーを表示できるようにする）
  requestedIndex: number | null = null

  // WYSIWYGエディタのフィールドエラー表示用
  // （ここに格納されているIDをキーとするものがerrorsModuleの_fieldErrorsに一つでもあればエラーメッセージを表示する）
  wysiwygFieldIds: string[] = []

  // WYSIWYGエディタ未入力のエラーメッセージ出し分け用
  isWysiwygInput = false

  constructor(ffnElementCardType: FFNElementCardType) {
    this.ffnElementCardType = ffnElementCardType
  }
}

class FormInputs {
  ownerNotificationId: string | null = null
  title = ''
  material: MaterialFormInputDto | null = null
  targetBuildingType: TargetBuildingType | null = null
  buildings: Building[] = []
  targetOwnerType: TargetOwnerType = TARGET_OWNER_TYPE.ALL
  targetOwnerGroupContentId: string | null = null
  targetOwnerGroupContentTitle: string | null = null
  targetOwnerGroupContentDeadline: string | null = null
  owners: TargetOwner[] = []
  postTimingType: PostTimingType = POST_TIMING_TYPE.IMMEDIATE
  scheduledPostDate: string = currentDate()
  scheduledPostHour = 0
  scheduledPostMinute = 0
  pinningSettingType: PinningSettingType = PINNING_SETTING_TYPE.NORMAL
  pinningDeadlineDate: string = currentDate()
  displayingFFNElements: DisplayingFFNElement[] = [new DisplayingFFNElement(FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR)]
  transitionButton: TransitionButtonOptionValues = TRANSITION_BUTTON_OPTION_VALUE.NONE
  version: number | null = null

  get scheduledPostDateTime(): string {
    return `${this.scheduledPostDate} ${this.scheduledPostHour}:${this.scheduledPostMinute}`
  }

  get formattedScheduledPostDateTime(): string {
    const date = new Date(this.scheduledPostDate)
    const numberOfDate = date.getDay()
    const dayOfWeek = DAY_OF_WEEK[numberOfDate]
    const [year, month, day] = this.scheduledPostDate.substr(0, 10).split('-')

    const hour = ('0' + this.scheduledPostHour).slice(-2)
    const minute = ('0' + this.scheduledPostMinute).slice(-2)

    return `${year}年${month}月${day}日(${dayOfWeek})${hour}:${minute}`
  }
}

interface PageTypeSpecifications {
  pageTitle: string
  executeBtnLabel: string

  created: (ownerNotificationId?: string) => void | Promise<void>
  onClickExecute: ($router: VueRouter, inputs: FormInputs, postReqFFNElements: OwnerNotificationsPostRequestFFNElement[]) => void | Promise<void>
  onClickSaveDraft: ($router: VueRouter, inputs: FormInputs, postReqFFNElements: OwnerNotificationsPostRequestFFNElement[]) => void | Promise<void>
  onClickDeleteDraft: ($router: VueRouter, ownerNotificationId: string) => void | Promise<void>
}

class CreateSpecifications implements PageTypeSpecifications {
  readonly pageTitle = 'お知らせを新規作成する'
  readonly executeBtnLabel = '投稿する'

  // 下書き／アーカイブから作成する場合
  async created(ownerNotificationId?: string) {
    if (ownerNotificationId) await ownerNotificationsModule.fetchOwnerNotificationDetail({ ownerNotificationId: ownerNotificationId })
  }

  async onClickExecute($router: VueRouter, inputs: FormInputs, postReqFFNElements: OwnerNotificationsPostRequestFFNElement[]): Promise<void> {
    if (!inputs.targetBuildingType) return

    const req = new OwnerNotificationsPostRequest(
      inputs.title, inputs.targetBuildingType, inputs.targetOwnerType,
      inputs.postTimingType, inputs.pinningSettingType, postReqFFNElements,
      inputs.ownerNotificationId ?? undefined, inputs.material ?? undefined,
      inputs.buildings.length ? inputs.buildings.map(b => new OwnerNotificationsPostRequestBuilding(b.buildingId)) : undefined,
      inputs.targetOwnerType === TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD ? inputs.targetOwnerGroupContentId ?? undefined : undefined,
      inputs.targetOwnerType === TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED || inputs.targetOwnerType === TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED ? inputs.targetOwnerGroupContentId ?? undefined : undefined,
      inputs.owners.length ? inputs.owners.map(o => new OwnerNotificationsPostRequestOwner(o.userId)) : undefined,
      inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED ? inputs.scheduledPostDateTime : undefined,
      inputs.pinningSettingType === PINNING_SETTING_TYPE.PINNED ? inputs.pinningDeadlineDate : undefined
    )
    await uploadThenPost(req, ownerNotificationsModule.postOwnerNotification)

    $router.push({ name: staticRoutes.ownerNotificationsList.name })
  }

  async onClickSaveDraft($router: VueRouter, inputs: FormInputs, postReqFFNElements: OwnerNotificationsPostRequestFFNElement[]): Promise<void> {
    if (!inputs.targetBuildingType) return

    const req = new OwnerNotificationsDraftPostRequest(
      inputs.title, inputs.targetBuildingType, inputs.targetOwnerType,
      inputs.postTimingType, inputs.pinningSettingType, postReqFFNElements,
      inputs.ownerNotificationId ?? undefined, inputs.material ?? undefined,
      inputs.buildings.length ? inputs.buildings.map(b => new OwnerNotificationsPostRequestBuilding(b.buildingId)) : undefined,
      inputs.targetOwnerType === TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD ? inputs.targetOwnerGroupContentId ?? undefined : undefined,
      inputs.targetOwnerType === TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED || inputs.targetOwnerType === TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED ? inputs.targetOwnerGroupContentId ?? undefined : undefined,
      inputs.owners.length ? inputs.owners.map(o => new OwnerNotificationsPostRequestOwner(o.userId)) : undefined,
      inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED ? inputs.scheduledPostDateTime : undefined,
      inputs.pinningSettingType === PINNING_SETTING_TYPE.PINNED ? inputs.pinningDeadlineDate : undefined
    )
    await uploadThenPost(req, ownerNotificationsModule.postOwnerNotificationDraft)

    $router.push({ name: staticRoutes.ownerNotificationsList.name })
  }

  async onClickDeleteDraft($router: VueRouter, ownerNotificationId: string): Promise<void> {
    await ownerNotificationsModule.deleteOwnerNotifications(new OwnerNotificationsDeleteRequest(ownerNotificationId))
    $router.go(-1)
  }
}

class UpdateSpecifications implements PageTypeSpecifications {
  readonly pageTitle = 'お知らせを編集する'
  readonly executeBtnLabel = '更新する'

  async created(ownerNotificationId?: string) {
    if (ownerNotificationId) await ownerNotificationsModule.fetchOwnerNotificationDetail({ ownerNotificationId: ownerNotificationId })
  }

  async onClickExecute($router: VueRouter, inputs: FormInputs, postReqFFNElements: OwnerNotificationsPostRequestFFNElement[]): Promise<void> {
    if (!inputs.ownerNotificationId || !inputs.targetBuildingType || !inputs.version) return

    const req = new OwnerNotificationsPutRequest(
      inputs.ownerNotificationId, inputs.title, inputs.targetBuildingType,
      inputs.targetOwnerType, inputs.postTimingType, inputs.pinningSettingType,
      postReqFFNElements, inputs.version, inputs.material ?? undefined,
      inputs.buildings.length ? inputs.buildings.map(b => new OwnerNotificationsPostRequestBuilding(b.buildingId)) : undefined,
      inputs.targetOwnerType === TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD ? inputs.targetOwnerGroupContentId ?? undefined : undefined,
      inputs.targetOwnerType === TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED || inputs.targetOwnerType === TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED ? inputs.targetOwnerGroupContentId ?? undefined : undefined,
      inputs.owners.length ? inputs.owners.map(o => new OwnerNotificationsPostRequestOwner(o.userId)) : undefined,
      inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED ? inputs.scheduledPostDateTime : undefined,
      inputs.pinningSettingType === PINNING_SETTING_TYPE.PINNED ? inputs.pinningDeadlineDate : undefined
    )
    await uploadThenPost(req, ownerNotificationsModule.putOwnerNotification)

    $router.go(-1) // お知らせ詳細画面で矢印アイコンから遷移元の画面に戻れるように遷移する
  }

  onClickSaveDraft() { /** nothing to do */ }
  onClickDeleteDraft() { /** nothing to do */ }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardSectionFrame: () => import('@/components/molecules/card/SmCardSectionFrame.vue'),
    SmDateAndTime: () => import('@/components/molecules/SmDateAndTime.vue'),
    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmWysiwygEditor: () => import('@/components/molecules/SmWysiwygEditor.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmDraftInterceptor: () => import('@/components/organisms/SmDraftInterceptor.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),
    OwnerGroupSelectModal: () => import('@/pages/owner-notifications/owner-group-select/OwnerGroupSelectModal.vue'),
    OwnerSelectModal: () => import('@/pages/owner-notifications/OwnerSelectModal.vue'),
    TransitionToSelectModal: () => import('@/pages/owner-notifications/transition-to-select/TransitionToSelectModal.vue'),
  }
})

export default class OwnerNotificationPostPage extends Vue {
  BUILDING_MENU_ITEMS = Object.freeze(BUILDING_MENU_ITEMS)
  FFNELEMENT_CARD_TYPE = Object.freeze(FFNELEMENT_CARD_TYPE)
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
  OWNER_NOTIFICATION_STATE = Object.freeze(OWNER_NOTIFICATION_STATE)
  PINNING_SETTING_TYPE = Object.freeze(PINNING_SETTING_TYPE)
  POST_TIMING_TYPE = Object.freeze(POST_TIMING_TYPE)
  TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_DEFAULT = Object.freeze(TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_DEFAULT)
  TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_AFTER_BUILDING_SELECTED = Object.freeze(TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_AFTER_BUILDING_SELECTED)
  TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_DEFAULT = Object.freeze(TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_DEFAULT)
  TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_AFTER_OWNER_SELECTED = Object.freeze(TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_AFTER_OWNER_SELECTED)
  PAGE_TYPES = Object.freeze(PAGE_TYPES)

  @Prop({ required: true, default: PAGE_TYPES.CREATE })
  private readonly pageType!: PageType

  @Prop()
  ownerNotificationId?: string

  inputs = new FormInputs()

  private get isCreate(): boolean { return this.pageType === PAGE_TYPES.CREATE }
  private get isDraft(): boolean { return this.storedOwnerNotification?.notificationState === OWNER_NOTIFICATION_STATE.DRAFT }
  private get isNotified(): boolean { return this.storedOwnerNotification?.notificationState === OWNER_NOTIFICATION_STATE.NOTIFIED }
  private get isArchive(): boolean { return this.storedOwnerNotification?.notificationState === OWNER_NOTIFICATION_STATE.ARCHIVED }
  private get isScheduled(): boolean { return this.storedOwnerNotification?.notificationState === OWNER_NOTIFICATION_STATE.SCHEDULED }

  async created(): Promise<void> {
    const exceptOutOfService = !this.isNotified // 投稿先のマンションに利用停止物件を表示しないためのフラグ（公開中の場合は投稿先を変更できないため除外）
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999, undefined, undefined, exceptOutOfService))
    await this.typeSpecs.created(this.ownerNotificationId)
  }

  private get typeSpecs(): PageTypeSpecifications {
    switch (this.pageType) {
      case PAGE_TYPES.CREATE: return new CreateSpecifications()
      case PAGE_TYPES.EDIT: return new UpdateSpecifications()
      default: return assertExhaustive(this.pageType)
    }
  }

  private get storedOwnerNotification(): OwnerNotificationDetailGetResponse | undefined {
    if (!this.ownerNotificationId) return undefined
    return ownerNotificationsModule.detailResponse(this.ownerNotificationId)
  }

  private get storedOwnerNotificationState(): OwnerNotificationState | undefined {
    return this.storedOwnerNotification?.notificationState
  }

  private get buildingOnlineResolutions(): BuildingResolutionDto[] { return buildingsModule.buildingOnlineResolutionsGet?.resolutions }
  private get buildingGMResolutions(): BuildingResolutionDto[] { return buildingsModule.buildingGmResolutionsGet?.resolutions }
  private get buildingAdminIdeas(): BuildingIdeaDto[] { return buildingsModule.buildingAdminIdeasGet?.ideas }
  private get buildingOwnerIdeas(): BuildingIdeaDto[] { return buildingsModule.buildingOwnerIdeasGet?.ideas }

  private get pageErrorMessage(): string | undefined {
    if (this.postTimingOverOwGroupDeadlineErrorMessage) return this.postTimingOverOwGroupDeadlineErrorMessage
    else if (this.owGroupContentNotExistErrorMessage) return this.owGroupContentNotExistErrorMessage
    else if (this.linkSmootheSelectingErrorMessage) return this.linkSmootheSelectingErrorMessage
    else return undefined
  }

  @Watch('storedOwnerNotification', { immediate: false, deep: false })
  async onStoredOwnerNotificationFetched(fetched: OwnerNotificationDetailGetResponse | undefined): Promise<void> {
    if (!fetched) return

    if (this.isDraft || !this.isCreate) {
      this.inputs.ownerNotificationId = fetched.ownerNotificationId
    }
    this.inputs.title = fetched.title
    this.inputs.material = fetched.material ? Object.assign(new MaterialFormInputDto(), fetched.material) : null

    // 「下書き」以外のステータス（公開予定・公開中・アーカイブ）から複製の場合、複製前の投稿先データは引き継がない
    if (this.isDraft || !this.isCreate) {
      this.inputs.targetBuildingType = fetched.targetBuildingType
      switch (this.inputs.targetBuildingType) {
        case (TARGET_BUILDING_TYPE.ALL):
          this.selectedTargetBuildingTypeId = TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS.ALL
          break
        case (TARGET_BUILDING_TYPE.ALL_IN_CHARGE):
          this.selectedTargetBuildingTypeId = TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS.ALL_IN_CHARGE
          break
        case (TARGET_BUILDING_TYPE.INDIVIDUALLY_SELECTED):
          this.selectedTargetBuildingTypeId = TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS.INDIVIDUALLY_SELECTED
          break
        default: assertExhaustive(this.inputs.targetBuildingType)
      }
      this.inputs.buildings = this.storedBuildings.filter(sb => fetched.targetBuildings.map(fb => fb.buildingId).includes(sb.buildingId))
      this.selectedTargetBuildingsByModal = this.storedBuildings.filter(sb => fetched.targetBuildings.map(fb => fb.buildingId).includes(sb.buildingId))
    }

    // 1物件を選択しているときのみ、投稿先＞区分所有者を設定する
    if (this.isOneBuildingSelected) {
      switch (fetched.targetOwnerType) {
      // 「下書き」または「アーカイブ」において既に締切を過ぎた決議やプランが選択されている場合、対象区分所有者は初期状態（すべての区分所有者）とする
        case (TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED):
          if (!((this.isDraft || this.isArchive) && fetched.resolution?.deadlineDateTime && new Date(fetched.resolution.deadlineDateTime) <= new Date())) {
            this.inputs.targetOwnerType = fetched.targetOwnerType
            this.inputs.targetOwnerGroupContentId = fetched.resolution?.resolutionId ?? null
            this.inputs.targetOwnerGroupContentTitle = fetched.resolution?.title ?? null
            this.inputs.targetOwnerGroupContentDeadline = fetched.resolution?.deadlineDateTime ?? null
            this.selectedTargetOwnerGroupTypeByModal = TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED
            this.selectedTargetOwnerGroupContentByModal = fetched.resolution?.resolutionId ?? null

            // 「下書き」「アーカイブ」または「公開予定」のお知らせにおいて投稿先に設定していたオンライン決議が選択不可能になっている場合はエラーメッセージを表示する
            if (this.isDraft || this.isArchive || this.isScheduled) {
              const onlineResolutionsGetRequest = new BuildingResolutionsGetRequest()
              onlineResolutionsGetRequest.buildingId = this.inputs.buildings[0].buildingId
              onlineResolutionsGetRequest.resolutionStates = [RESOLUTION_STATES.ONLINE.ACCEPTING_ALL, RESOLUTION_STATES.ONLINE.ACCEPTING_VOTE]
              onlineResolutionsGetRequest.hasUnvotedOwners = true
              await buildingsModule.fetchBuildingOnlineResolutions(onlineResolutionsGetRequest)

              if (this.buildingOnlineResolutions.filter(r => r.resolutionId === this.inputs.targetOwnerGroupContentId).length === 0) {
                this.owGroupContentNotExistErrorMessage = '投稿先に設定していた区分所有者グループはありません。再度選択をおこなってください。\n選択していたグループ：オンライン決議　' + this.inputs.targetOwnerGroupContentTitle + '　未投票の区分所有者'
                // 区分所有者グループ選択モーダルの選択状態をリセットする
                this.selectedTargetOwnerGroupTypeByModal = TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD
                this.selectedTargetOwnerGroupContentByModal = null
              }
            }
          }
          break
        case (TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED):
          if (!((this.isDraft || this.isArchive) && fetched.resolution?.holdingDate && new Date(fetched.resolution.holdingDate) <= new Date(currentDate()))) {
            this.inputs.targetOwnerType = fetched.targetOwnerType
            this.inputs.targetOwnerGroupContentId = fetched.resolution?.resolutionId ?? null
            this.inputs.targetOwnerGroupContentTitle = fetched.resolution?.title ?? null
            this.inputs.targetOwnerGroupContentDeadline = fetched.resolution?.holdingDate ?? null
            this.selectedTargetOwnerGroupTypeByModal = TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED
            this.selectedTargetOwnerGroupContentByModal = fetched.resolution?.resolutionId ?? null

            // 「下書き」「アーカイブ」または「公開予定」のお知らせにおいて投稿先に設定していた総会決議が選択不可能になっている場合はエラーメッセージを表示する
            if (this.isDraft || this.isArchive || this.isScheduled) {
              const gmResolutionsGetRequest = new BuildingResolutionsGetRequest()
              gmResolutionsGetRequest.buildingId = this.inputs.buildings[0].buildingId
              gmResolutionsGetRequest.resolutionStates = [RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL, RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT]
              gmResolutionsGetRequest.hasUnvotedOwners = true
              await buildingsModule.fetchBuildingGmResolutions(gmResolutionsGetRequest)

              if (this.buildingGMResolutions.filter(r => r.resolutionId === this.inputs.targetOwnerGroupContentId).length === 0) {
                this.owGroupContentNotExistErrorMessage = '投稿先に設定していた区分所有者グループはありません。再度選択をおこなってください。\n選択していたグループ：総会決議　' + this.inputs.targetOwnerGroupContentTitle + '　意思表明をしていない区分所有者'
                // 区分所有者グループ選択モーダルの選択状態をリセットする
                this.selectedTargetOwnerGroupTypeByModal = TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD
                this.selectedTargetOwnerGroupContentByModal = null
              }
            }
          }
          break
        case (TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD):
          if (!((this.isDraft || this.isArchive) && fetched.idea?.deadlineDateTime && new Date(fetched.idea.deadlineDateTime) <= new Date())) {
            this.inputs.targetOwnerType = fetched.targetOwnerType
            this.inputs.targetOwnerGroupContentId = fetched.idea?.ideaId ?? null
            this.inputs.targetOwnerGroupContentTitle = fetched.idea?.title ?? null
            this.inputs.targetOwnerGroupContentDeadline = fetched.idea?.deadlineDateTime ?? null
            this.selectedTargetOwnerGroupTypeByModal = TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD
            this.selectedTargetOwnerGroupContentByModal = fetched.idea?.ideaId ?? null

            // 「下書き」「アーカイブ」または「公開予定」のお知らせにおいて投稿先に設定していたプランが選択不可能になっている場合はエラーメッセージを表示する
            if (this.isDraft || this.isArchive || this.isScheduled) {
              const adminIdeasGetRequest = new BuildingIdeasGetRequest()
              adminIdeasGetRequest.buildingId = this.inputs.buildings[0].buildingId
              adminIdeasGetRequest.ideaStates = [IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT]
              adminIdeasGetRequest.hasUnreadOwners = true
              await buildingsModule.fetchBuildingAdminIdeas(adminIdeasGetRequest)

              if (this.buildingAdminIdeas.filter(i => i.ideaId === this.inputs.targetOwnerGroupContentId).length === 0) {
                this.owGroupContentNotExistErrorMessage = '投稿先に設定していた区分所有者グループはありません。再度選択をおこなってください。\n選択していたグループ：プラン　' + this.inputs.targetOwnerGroupContentTitle + '　未読の区分所有者'
                // 区分所有者グループ選択モーダルの選択状態をリセットする
                this.selectedTargetOwnerGroupTypeByModal = TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD
                this.selectedTargetOwnerGroupContentByModal = null
              }
            }
          }
          break
        case (TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED):
          this.inputs.targetOwnerType = fetched.targetOwnerType
          this.inputs.owners = deepCopy(
            fetched.targetOwners,
            { targetOwners: new ColumnToType(TargetOwner, true) },
            'targetOwners'
          )
          this.selectedTargetOwnerIdsByModal = this.inputs.owners.map(o => o.userId)
          break
        case (TARGET_OWNER_TYPE.ALL):
          break // 初期値のままにする
        default: assertExhaustive(fetched.targetOwnerType)
      }
    }

    // 「下書き」ステータスかつ投稿指定日時を超過している、または、「下書き」以外のステータス（公開予定・公開中・アーカイブ）から複製の場合、投稿タイミングは初期状態（即時）とする
    if ((this.isDraft && new Date(fetched.notifiedDateTime) <= new Date()) || (!this.isDraft && this.isCreate)) {
      this.inputs.postTimingType = POST_TIMING_TYPE.IMMEDIATE
    } else {
      this.inputs.postTimingType = fetched.postTimingType
      this.inputs.scheduledPostDate = fetched.notifiedDateTime.substring(0, 10)
      this.inputs.scheduledPostHour = Number(fetched.notifiedDateTime.substring(11, 13))
      this.inputs.scheduledPostMinute = Number(fetched.notifiedDateTime.substring(14, 16))
      // 更新確認ダイアログの文言出し分け（投稿日時指定の変更有無で出し分ける）のため、編集前の初期値を控えておく
      this.editInitialScheduledPostDateTime = fetched.notifiedDateTime
    }

    // 「下書き」ステータスかつ固定表示終了日を超過している、または、「下書き」以外のステータス（公開予定・公開中・アーカイブ）から複製の場合、表示方法は初期状態（通常のお知らせ）とする
    if ((this.isDraft && fetched.pinningDeadlineDate && new Date(fetched.pinningDeadlineDate) < new Date(currentDate())) || (!this.isDraft && this.isCreate)) {
      this.inputs.pinningSettingType = PINNING_SETTING_TYPE.NORMAL
    } else {
      this.inputs.pinningSettingType = fetched.pinningSettingType
      this.inputs.pinningDeadlineDate = fetched.pinningDeadlineDate ?? currentDate()
    }

    if (this.isDraft || !this.isCreate) {
      const selectedTransitionButton = fetched.freeFormatNotificationElements.filter(e => e.notificationElementType === NOTIFICATION_ELEMENT_TYPES.BUTTON.POST_IDEA || e.notificationElementType === NOTIFICATION_ELEMENT_TYPES.BUTTON.CONSULTATION)
      if (selectedTransitionButton.length === 1) {
        if (selectedTransitionButton[0].notificationElementType === NOTIFICATION_ELEMENT_TYPES.BUTTON.POST_IDEA) this.inputs.transitionButton = TRANSITION_BUTTON_OPTION_VALUE.POST_IDEA
        if (selectedTransitionButton[0].notificationElementType === NOTIFICATION_ELEMENT_TYPES.BUTTON.CONSULTATION) this.inputs.transitionButton = TRANSITION_BUTTON_OPTION_VALUE.CONSULTATION
      }
    }

    this.inputs.displayingFFNElements = this.getDisplayingFFNElements(fetched.freeFormatNotificationElements)
    this.inputs.version = fetched.version
  }

  // 「下書き」または「アーカイブ」において投稿先に設定していた区分所有者グループが選択不可になっていた場合のエラーメッセージ表示用データ
  owGroupContentNotExistErrorMessage: string | null = null

  // 対象マンション設定用データ／メソッド
  private get storedBuildings(): Building[] { return buildingsModule.buildingsGet.buildings }

  selectedTargetBuildingTypeId: TargetBuildingTypeSelectMenuItemsIds | null = null

  private get isLinkSmootheSelecting(): boolean {
    const selectingLinkSmoothe = this.inputs.displayingFFNElements.filter(de => de.ffnElementCardType === FFNELEMENT_CARD_TYPE.LINK_SMOOTHE)
    return !!selectingLinkSmoothe.length
  }

  linkSmootheSelectingErrorMessage: string | null = null

  async onSelectTargetBuildingType(): Promise<void> {
    // 非定型お知らせ構成要素に内部リンクがある場合は対象マンションの変更はできない
    if (this.isLinkSmootheSelecting) {
      this.linkSmootheSelectingErrorMessage = '本文で特定のマンションに紐づくリンク（内部）を選択しています。投稿先を変更するには、リンク（内部）を削除してください。'
      return
    } else {
      this.linkSmootheSelectingErrorMessage = null
    }

    switch (this.selectedTargetBuildingTypeId) {
      case TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS.ALL:
        this.inputs.targetBuildingType = TARGET_BUILDING_TYPE.ALL
        this.inputs.buildings = []
        this.selectedTargetBuildingsByModal = []
        this.clearBuildingDependedSettings()
        break
      case TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS.ALL_IN_CHARGE:
        this.inputs.targetBuildingType = TARGET_BUILDING_TYPE.ALL_IN_CHARGE
        await this.selectAllBuildingsInCharge()
        this.clearBuildingDependedSettings()
        break
      case TARGET_BUILDING_TYPE_SELECT_MENU_ITEMS_IDS.INDIVIDUALLY_SELECTED:
        await this.openBuildingSelectModal()
        break
    }
  }

  async selectAllBuildingsInCharge(): Promise<void> {
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999, undefined, true, true))
    this.inputs.buildings = this.storedBuildings
    this.selectedTargetBuildingsByModal = []
  }

  isBuildingSelectModalVisible = false
  buildingSelectModalKey = generateUuid()
  async openBuildingSelectModal(): Promise<void> {
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999, undefined, undefined, true))
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingSelectModalVisible = true
  }

  selectedTargetBuildingsByModal: Building[] = []
  buildingSelectModalIsStaff = true
  buildingSelectModalKeyword = ''
  buildingSelectModalInputText = ''
  onSelectBuildingsByModal(buildingIds: string[]): void {
    this.inputs.targetBuildingType = TARGET_BUILDING_TYPE.INDIVIDUALLY_SELECTED
    this.selectedTargetBuildingsByModal = this.storedBuildings.filter(sb => buildingIds.includes(sb.buildingId))
    if (JSON.stringify(this.inputs.buildings.map(b => b.buildingId).sort()) !== JSON.stringify(buildingIds.sort())) {
      this.inputs.buildings = this.storedBuildings.filter(sb => buildingIds.includes(sb.buildingId))
      this.clearBuildingDependedSettings()
    }
  }

  private get selectedBuildingLabel(): string | undefined {
    if (this.inputs.targetBuildingType === TARGET_BUILDING_TYPE.ALL) {
      return 'すべてのマンション'
    } else if (this.inputs.targetBuildingType === TARGET_BUILDING_TYPE.ALL_IN_CHARGE || this.inputs.targetBuildingType === TARGET_BUILDING_TYPE.INDIVIDUALLY_SELECTED) {
      return this.namesOfSelectedBuildings
    } else {
      return undefined
    }
  }

  private get namesOfSelectedBuildings(): string { return this.inputs.buildings.map(b => b.buildingName).join(' | ') }

  private get isTargetBuildingSelected(): boolean {
    if (this.inputs.targetBuildingType === TARGET_BUILDING_TYPE.ALL) {
      return true
    } else if (this.inputs.targetBuildingType === TARGET_BUILDING_TYPE.ALL_IN_CHARGE || this.inputs.targetBuildingType === TARGET_BUILDING_TYPE.INDIVIDUALLY_SELECTED) {
      return this.inputs.buildings.length > 0
    } else {
      return false
    }
  }

  // 対象区分所有者設定用データ／メソッド
  private get isOneBuildingSelected(): boolean { return (this.inputs.targetBuildingType === TARGET_BUILDING_TYPE.INDIVIDUALLY_SELECTED && this.inputs.buildings.length === 1) }
  private get oneTargetBuilding(): Building | undefined { return this.isOneBuildingSelected ? this.inputs.buildings[0] : undefined }

  selectedTargetOwnerTypeId: TargetOwnerTypeSelectMenuItemsIds | null = null

  async onSelectTargetOwnerType(): Promise<void> {
    switch (this.selectedTargetOwnerTypeId) {
      case TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_IDS.ALL:
        this.inputs.targetOwnerType = TARGET_OWNER_TYPE.ALL
        this.inputs.owners = []
        this.selectedTargetOwnerIdsByModal = []
        this.owGroupContentNotExistErrorMessage = null
        break
      case TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_IDS.GROUP:
        await this.openOwnerGroupSelectModal()
        break
      case TARGET_OWNER_TYPE_SELECT_MENU_ITEMS_IDS.INDIVIDUALLY_SELECTED:
        await this.openOwnerSelectModal()
        break
    }
  }

  isOwnerGroupSelectModalVisible = false
  ownerGroupSelectModalKey = generateUuid()
  async openOwnerGroupSelectModal(): Promise<void> {
    if (!this.isOneBuildingSelected) return
    this.ownerGroupSelectModalKey = generateUuid()
    this.isOwnerGroupSelectModalVisible = true
  }

  selectedTargetOwnerGroupTypeByModal: TargetOwnerType = TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD
  selectedTargetOwnerGroupContentByModal: string | null = null
  ownerGroupSelectModalIdeaKeyword = ''
  ownerGroupSelectModalIdeaInputText = ''
  ownerGroupSelectModalOnlineResolutionKeyword = ''
  ownerGroupSelectModalOnlineResolutionInputText = ''
  ownerGroupSelectModalGMResolutionKeyword = ''
  ownerGroupSelectModalGMResolutionInputText = ''

  onSelectOwnerGroupByModal(selectedTargetOwnerType: TargetOwnerType, selectedTargetId: string): void {
    this.inputs.targetOwnerType = selectedTargetOwnerType
    this.inputs.targetOwnerGroupContentId = selectedTargetId
    this.selectedTargetOwnerGroupTypeByModal = selectedTargetOwnerType
    this.selectedTargetOwnerGroupContentByModal = selectedTargetId
    this.owGroupContentNotExistErrorMessage = null

    if (selectedTargetOwnerType === TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED) {
      const selectedResolution = this.buildingOnlineResolutions.filter(r => r.resolutionId === selectedTargetId)[0]
      this.inputs.targetOwnerGroupContentTitle = selectedResolution.title
      this.inputs.targetOwnerGroupContentDeadline = selectedResolution.deadlineDateTime ?? null
    } else if (selectedTargetOwnerType === TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED) {
      const selectedResolution = this.buildingGMResolutions.filter(r => r.resolutionId === selectedTargetId)[0]
      this.inputs.targetOwnerGroupContentTitle = selectedResolution.title
      this.inputs.targetOwnerGroupContentDeadline = selectedResolution.holdingDate ?? null
    } else {
      const selectedIdea = this.buildingAdminIdeas.filter(i => i.ideaId === selectedTargetId)[0]
      this.inputs.targetOwnerGroupContentTitle = selectedIdea.title
      this.inputs.targetOwnerGroupContentDeadline = selectedIdea.deadlineDateTime ?? null
    }
  }

  isOwnerSelectModalVisible = false
  ownerSelectModalKey = generateUuid()
  async openOwnerSelectModal(): Promise<void> {
    if (!this.isOneBuildingSelected) return

    const req = new BuildingOwnersGetRequest()
    req.buildingId = this.inputs.buildings[0].buildingId
    await buildingsModule.fetchBuildingOwners(req)

    this.ownerSelectModalKey = generateUuid()
    this.isOwnerSelectModalVisible = true
  }

  selectedTargetOwnerIdsByModal: string[] = []
  ownerSelectModalKeyword = ''
  ownerSelectModalInputText = ''
  onSelectOwnersByModal(ownerIds: string[]): void {
    this.inputs.targetOwnerType = TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED
    this.inputs.owners = buildingsModule.buildingOwnersGet.owners.filter(o => ownerIds.includes(o.userId)).map(o => {
      const targetOwner = new TargetOwner()
      targetOwner.userId = o.userId
      targetOwner.userName = o.userName
      targetOwner.roomNumber = o.roomNumber
      return targetOwner
    })
    this.selectedTargetOwnerIdsByModal = ownerIds
    this.owGroupContentNotExistErrorMessage = null
  }

  private get selectedOwnerLabel(): string | undefined {
    if (this.inputs.targetOwnerType === TARGET_OWNER_TYPE.ALL) {
      return 'すべての区分所有者'
    } else if (this.inputs.targetOwnerType === TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED) {
      return 'オンライン決議　' + this.inputs.targetOwnerGroupContentTitle + '　未投票の区分所有者'
    } else if (this.inputs.targetOwnerType === TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED) {
      return '総会決議　' + this.inputs.targetOwnerGroupContentTitle + '　未投票の区分所有者'
    } else if (this.inputs.targetOwnerType === TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD) {
      return 'プラン　' + this.inputs.targetOwnerGroupContentTitle + '　未読の区分所有者'
    } else if (this.inputs.targetBuildingType === TARGET_BUILDING_TYPE.INDIVIDUALLY_SELECTED) {
      return this.namesOfSelectedOwners
    } else {
      return undefined
    }
  }

  private get namesOfSelectedOwners(): string { return this.inputs.owners.map(o => o.userName).join('、') }

  private get isTargetOwnerSelected(): boolean {
    if (this.inputs.targetOwnerType === TARGET_OWNER_TYPE.INDIVIDUALLY_SELECTED) {
      return this.inputs.owners.length > 0
    } else {
      return true
    }
  }

  clearBuildingDependedSettings(): void {
    // 特定の一物件に依存した設定内容（対象区分所有者）をリセットする
    this.selectedTargetOwnerTypeId = null
    this.inputs.targetOwnerType = TARGET_OWNER_TYPE.ALL
    this.inputs.owners = []
    this.inputs.targetOwnerGroupContentId = null
    this.inputs.targetOwnerGroupContentTitle = null
    this.inputs.targetOwnerGroupContentDeadline = null

    this.selectedTargetOwnerGroupTypeByModal = TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD
    this.selectedTargetOwnerGroupContentByModal = null
    this.ownerGroupSelectModalIdeaKeyword = ''
    this.ownerGroupSelectModalIdeaInputText = ''
    this.ownerGroupSelectModalOnlineResolutionKeyword = ''
    this.ownerGroupSelectModalOnlineResolutionInputText = ''
    this.ownerGroupSelectModalGMResolutionKeyword = ''
    this.ownerGroupSelectModalGMResolutionInputText = ''
    this.owGroupContentNotExistErrorMessage = null

    this.selectedTargetOwnerIdsByModal = []
    this.ownerSelectModalKeyword = ''
    this.ownerSelectModalInputText = ''
  }

  // 投稿タイミング設定用データ／メソッド
  postTimingOptions = [new RadioOption('即時', POST_TIMING_TYPE.IMMEDIATE), new RadioOption('投稿日時指定', POST_TIMING_TYPE.SCHEDULED)]

  private get postTimingOverOwGroupDeadlineErrorMessage(): string | undefined {
    // 投稿日時指定で指定している日時がオンライン決議・プランの締切日を過ぎていないか
    if ((this.inputs.targetOwnerType === TARGET_OWNER_TYPE.ONLINE_RESOLUTION_NOT_VOTED || this.inputs.targetOwnerType === TARGET_OWNER_TYPE.ADMIN_IDEA_UNREAD) &&
         this.inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED && this.inputs.scheduledPostDate) {
      if (this.inputs.targetOwnerGroupContentDeadline && new Date(this.inputs.targetOwnerGroupContentDeadline) <= new Date(this.inputs.scheduledPostDateTime)) {
        return '投稿先で選択しているオンライン決議・プランの締切日時よりも前になるように、投稿タイミングを設定してください。'
      }
    }

    // 投稿日時指定で指定している日時が総会決議の開催日を過ぎていないか
    if (this.inputs.targetOwnerType === TARGET_OWNER_TYPE.GM_RESOLUTION_NOT_VOTED && this.inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED && this.inputs.scheduledPostDate) {
      if (this.inputs.targetOwnerGroupContentDeadline && new Date(this.inputs.targetOwnerGroupContentDeadline) <= new Date(this.inputs.scheduledPostDate)) {
        return '投稿先で選択している総会決議の開催日よりも前になるように、投稿タイミングを設定してください。'
      }
    }
  }

  // 表示方法設定用データ／メソッド
  private get pinningSettingOptions(): RadioOption[] {
    return [new RadioOption('通常のお知らせ', PINNING_SETTING_TYPE.NORMAL), new RadioOption('固定されたお知らせ', PINNING_SETTING_TYPE.PINNED, this.inputs.targetOwnerType !== TARGET_OWNER_TYPE.ALL)]
  }

  @Watch('inputs.targetOwnerType', { immediate: false })
  clearPinningSettingType(): void {
    if (this.inputs.targetOwnerType !== TARGET_OWNER_TYPE.ALL) this.inputs.pinningSettingType = PINNING_SETTING_TYPE.NORMAL
  }

  private get pinningDeadlineDateFormValidationRules(): string[] {
    const rules: string[] = []
    if (this.inputs.pinningSettingType === PINNING_SETTING_TYPE.PINNED) {
      // 投稿日より前の日付が入力されていないか
      if (this.storedOwnerNotification?.notificationState === OWNER_NOTIFICATION_STATE.NOTIFIED) {
        rules.push(`is_date_after:${this.storedOwnerNotification.notifiedDateTime.substr(0, 10)},投稿日`)
      } else if (this.inputs.postTimingType === POST_TIMING_TYPE.IMMEDIATE) {
        rules.push('feature-date')
      } else {
        rules.push(`is_date_after:${this.inputs.scheduledPostDate},投稿日`)
      }
      // 即時にチェックが入っている場合は入力時点から一年、投稿日時指定にチェックが入っている場合は投稿指定日から一年を超えていないか
      if (this.inputs.postTimingType === POST_TIMING_TYPE.IMMEDIATE) {
        rules.push(`is_date_before:${oneYearAfterCurrentDate()},投稿日から一年以内で選択してください`)
      } else {
        if (!this.inputs.scheduledPostDate) return rules
        const date = new Date(this.inputs.scheduledPostDate)
        date.setFullYear(date.getFullYear() + 1)
        date.setHours(date.getHours() + 9)
        const oneYearAfterScheduledPostDate = date.toISOString().substr(0, 10)
        rules.push(`is_date_before:${oneYearAfterScheduledPostDate},投稿日から一年以内で選択してください`)
      }
    }
    return rules
  }

  // 日時選択用データ
  private get hours(): { value: number, label: string }[] {
    const hours : { value: number, label: string }[] = []
    for (let i = 0; i <= 23; i++) {
      hours.push({ value: i, label: ('0' + i).slice(-2) }) // 数字を2桁で表示
    }
    return hours
  }

  // 入力コンポーネント操作用データ、メソッド
  getCardTitle(cardType: FFNElementCardType): string {
    switch (cardType) {
      case FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR:
        return 'テキスト'
      case FFNELEMENT_CARD_TYPE.ANNOTATION_MODE_WYSIWYG_EDITOR:
        return '注釈'
      case FFNELEMENT_CARD_TYPE.LINK_SMOOTHE:
        return 'リンク（内部）'
      case FFNELEMENT_CARD_TYPE.LINK_EXTERNAL:
        return 'リンク（外部）'
      case FFNELEMENT_CARD_TYPE.ATTACHMENT:
        return '添付ファイル'
      case FFNELEMENT_CARD_TYPE.HORIZON:
        return '区切り'
      case FFNELEMENT_CARD_TYPE.LINK_EMAIL:
        return 'メールアドレス'
      case FFNELEMENT_CARD_TYPE.LINK_PHONE_NUMBER:
        return '電話番号'
      default: return assertExhaustive(cardType)
    }
  }

  ffnElementMenuId = FFNELEMENT_MENU_ITEMS_DEFAULT.TEXT_MODE_WYSIWYG_EDITOR.key

  private get ffnElementMenuItems(): {[id: string]: { text: string, label: string, key: string }} {
    const attachmentCount = this.inputs.displayingFFNElements.filter(e => e.ffnElementCardType === FFNELEMENT_CARD_TYPE.ATTACHMENT).length
    if (!this.isOneBuildingSelected && attachmentCount >= 5) {
      return FFNELEMENT_MENU_ITEMS_LINK_SMOOTHE_ATTACHMENT_RESTRICTED
    } else if (!this.isOneBuildingSelected) {
      return FFNELEMENT_MENU_ITEMS_LINK_SMOOTHE_RESTRICTED
    } else if (attachmentCount >= 5) {
      return FFNELEMENT_MENU_ITEMS_ATTACHMENT_RESTRICTED
    } else {
      return FFNELEMENT_MENU_ITEMS_DEFAULT
    }
  }

  addFFNElement(): void {
    let element: DisplayingFFNElement

    switch (this.ffnElementMenuId) {
      case FFNELEMENT_MENU_ITEMS_DEFAULT.TEXT_MODE_WYSIWYG_EDITOR.key:
        element = new DisplayingFFNElement(FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR)
        break
      case FFNELEMENT_MENU_ITEMS_DEFAULT.ANNOTATION_MODE_WYSIWYG_EDITOR.key:
        element = new DisplayingFFNElement(FFNELEMENT_CARD_TYPE.ANNOTATION_MODE_WYSIWYG_EDITOR)
        break
      case FFNELEMENT_MENU_ITEMS_DEFAULT.LINK_SMOOTHE.key:
        element = new DisplayingFFNElement(FFNELEMENT_CARD_TYPE.LINK_SMOOTHE)
        break
      case FFNELEMENT_MENU_ITEMS_DEFAULT.LINK_EXTERNAL.key:
        element = new DisplayingFFNElement(FFNELEMENT_CARD_TYPE.LINK_EXTERNAL)
        break
      case FFNELEMENT_MENU_ITEMS_DEFAULT.ATTACHMENT.key:
        element = new DisplayingFFNElement(FFNELEMENT_CARD_TYPE.ATTACHMENT)
        break
      case FFNELEMENT_MENU_ITEMS_DEFAULT.HORIZON.key:
        element = new DisplayingFFNElement(FFNELEMENT_CARD_TYPE.HORIZON)
        break
      case FFNELEMENT_MENU_ITEMS_DEFAULT.LINK_EMAIL.key:
        element = new DisplayingFFNElement(FFNELEMENT_CARD_TYPE.LINK_EMAIL)
        break
      case FFNELEMENT_MENU_ITEMS_DEFAULT.LINK_PHONE_NUMBER.key:
        element = new DisplayingFFNElement(FFNELEMENT_CARD_TYPE.LINK_PHONE_NUMBER)
        break
      default: return
    }

    this.inputs.displayingFFNElements.push(element)
  }

  goUpElement(index: number): void {
    this.inputs.displayingFFNElements.splice(index - 1, 2, this.inputs.displayingFFNElements[index], this.inputs.displayingFFNElements[index - 1])
  }

  goDownElement(index: number): void {
    this.inputs.displayingFFNElements.splice(index, 2, this.inputs.displayingFFNElements[index + 1], this.inputs.displayingFFNElements[index])
  }

  deleteElement(index: number): void {
    this.inputs.displayingFFNElements.splice(index, 1)
    if (!this.isLinkSmootheSelecting) this.linkSmootheSelectingErrorMessage = null
  }

  transitionButtonOptions = [new RadioOption('なし', TRANSITION_BUTTON_OPTION_VALUE.NONE), new RadioOption('アイデアを投稿する', TRANSITION_BUTTON_OPTION_VALUE.POST_IDEA), new RadioOption('相談・連絡を投稿する', TRANSITION_BUTTON_OPTION_VALUE.CONSULTATION)]

  // WYSIWYGエディタ関連の表示調整用データ、メソッド
  getDisplayingFFNElements(rawElements: FFNElement[]): DisplayingFFNElement[] {
    const copiedElements = deepCopy(
      rawElements,
      { rawElements: new ColumnToType(FFNElement, true) },
      'rawElements'
    ).filter(e => e.notificationElementType !== NOTIFICATION_ELEMENT_TYPES.BUTTON.POST_IDEA && e.notificationElementType !== NOTIFICATION_ELEMENT_TYPES.BUTTON.CONSULTATION
    ).sort((former, latter) => former.sortOrderNum - latter.sortOrderNum)

    const adjustedElements: FFNElement[] = []
    for (const element of copiedElements) {
      if (element.notificationElementType === NOTIFICATION_ELEMENT_TYPES.LINK.SMOOTH_E && !this.isOneBuildingSelected) {
        // 内部リンクで1物件を選択していないときは、コンポーネントを表示しない
        continue
      }
      if (element.notificationElementType === NOTIFICATION_ELEMENT_TYPES.BUTTON.POST_IDEA || element.notificationElementType === NOTIFICATION_ELEMENT_TYPES.BUTTON.CONSULTATION) {
        // ボタン要素から画面表示用のオブジェクトは生成しない
      } else if (
        FFNELEMENT_CARD_TYPE_RECORD[element.notificationElementType] !== FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR &&
        FFNELEMENT_CARD_TYPE_RECORD[element.notificationElementType] !== FFNELEMENT_CARD_TYPE.ANNOTATION_MODE_WYSIWYG_EDITOR) {
        adjustedElements.push(element)
      } else {
        // WYSIWYGエディタを用いる構成要素にHTMLタグを付与する
        const taggedElement = this.addTagsToElementBody(element)
        // 入力フィールドが同じものは同じ入力欄に表示されるようまとめる
        if (adjustedElements.length > 0 && adjustedElements[adjustedElements.length - 1].inputField &&
            element.inputField && adjustedElements[adjustedElements.length - 1].inputField === element.inputField &&
            element.notificationElementType !== NOTIFICATION_ELEMENT_TYPES.ROUNDED_SQUARE.LIGHT_GRAY) {
          adjustedElements[adjustedElements.length - 1].elementBody = `${adjustedElements[adjustedElements.length - 1].elementBody}${taggedElement.elementBody}`
        } else {
          adjustedElements.push(taggedElement)
        }
      }
    }

    const displayingElements = adjustedElements.map(e => {
      const displayingElement = new DisplayingFFNElement(FFNELEMENT_CARD_TYPE_RECORD[e.notificationElementType])
      displayingElement.elementBody = e.elementBody
      displayingElement.transitionType = e.transitionType
      displayingElement.transitionParams = e.transitionParams
      displayingElement.isLinkAvailable = e.isLinkAvailable
      if (displayingElement.transitionType) displayingElement.isTransitionToInput = true
      displayingElement.externalSiteUrl = e.externalSiteUrl
      displayingElement.emailAddress = e.emailAddress
      displayingElement.phoneNumber = e.phoneNumber
      displayingElement.material = e.material ? Object.assign(new MaterialFormInputDto(), e.material) : undefined
      return displayingElement
    })
    return displayingElements
  }

  addTagsToElementBody(element: FFNElement): FFNElement {
    switch (element.notificationElementType) {
      case NOTIFICATION_ELEMENT_TYPES.BODY:
      case NOTIFICATION_ELEMENT_TYPES.ROUNDED_SQUARE.LIGHT_GRAY:
        if (!element.elementBody) return element
        element.elementBody = escapeAll(element.elementBody)
        element.elementBody = replaceNewlineCharacterToTag(element.elementBody, 'p')
        element.elementBody = replaceStrikethroughMarkToTag(element.elementBody)
        element.elementBody = replaceBoldMarkToTag(element.elementBody)
        break
      case NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL2:
        if (!element.elementBody) return element
        element.elementBody = escapeAll(element.elementBody)
        element.elementBody = replaceNewlineCharacterToTag(element.elementBody, 'h2')
        element.elementBody = replaceStrikethroughMarkToTag(element.elementBody)
        break
      case NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL3:
        if (!element.elementBody) return element
        element.elementBody = escapeAll(element.elementBody)
        element.elementBody = replaceNewlineCharacterToTag(element.elementBody, 'h3')
        element.elementBody = replaceStrikethroughMarkToTag(element.elementBody)
        break
      case NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL4:
        if (!element.elementBody) return element
        element.elementBody = escapeAll(element.elementBody)
        element.elementBody = replaceNewlineCharacterToTag(element.elementBody, 'h4')
        element.elementBody = replaceStrikethroughMarkToTag(element.elementBody)
        break
      case NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.DOT:
      case NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.INDEX:
        element.bulletPoints = element.bulletPoints.sort((former, latter) => former.sortOrderNum - latter.sortOrderNum)
        for (const bulletPoint of element.bulletPoints) {
          bulletPoint.bulletPointBody = escapeAll(bulletPoint.bulletPointBody)
          if (bulletPoint.hierarchyLevel === 1) { // DBに格納されるhierarchyLevelは1~9
            element.elementBody = `${element.elementBody ?? ''}<li>${bulletPoint.bulletPointBody}</li>`
          } else {
            element.elementBody = `${element.elementBody ?? ''}<li class="ql-indent-${bulletPoint.hierarchyLevel - 1}">${bulletPoint.bulletPointBody}</li>`
          }
        }
        element.notificationElementType === NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.DOT ? element.elementBody = `<ul>${element.elementBody}</ul>` : element.elementBody = `<ol>${element.elementBody}</ol>`
        element.elementBody = element.elementBody.replace('\n', '<br>')
        element.elementBody = replaceStrikethroughMarkToTag(element.elementBody)
        element.elementBody = replaceBoldMarkToTag(element.elementBody)
        break
      default:
        return element
    }
    return element
  }

  // 素材IDと取得済み参照用URLを含む素材情報の組み合わせを保持
  materialReferenceURLMap: Map<string, MaterialFormInputDto> = new Map()

  async getPostReqFFNElementsFromInputs(isPreview?: boolean): Promise<OwnerNotificationsPostRequestFFNElement[]> {
    const postReqFFNElements: OwnerNotificationsPostRequestFFNElement[] = []

    // ローカルストレージのサイズの制約上、プレビュー時に素材をそのまま別タブに渡すのが難しいため、DBにアップロードする
    // 素材情報のみ先にアップロードを完了させる
    const displayingFFNMaterialElements = this.inputs.displayingFFNElements.filter(de => de.ffnElementCardType === FFNELEMENT_CARD_TYPE.ATTACHMENT)
    await Promise.allSettled(
      displayingFFNMaterialElements.map(async dm => {
        if (dm.material && !dm.material.materialId) { // DBにアップロードされているかの判定をmaterialIdの有無で判定
          const uploadedMaterial = await uploadMaterial(dm.material)
          if (!uploadedMaterial?.materialId) return Promise.resolve(null)
          this.materialReferenceURLMap.set(uploadedMaterial.materialId, uploadedMaterial)
          dm.material.materialId = uploadedMaterial.materialId
        }
      })
    )

    // 入力コンポーネント群からリクエストを生成
    this.inputs.displayingFFNElements.forEach(de => {
      if (de.ffnElementCardType === FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR) {
        if (!de.elementBody) return
        const parsedElementBodies = this.parseHTMLText(de.elementBody)
        const inputField = generateUuid()

        parsedElementBodies.forEach(pb => {
          const postReqElement = new OwnerNotificationsPostRequestFFNElement()
          postReqElement.sortOrderNum = postReqFFNElements.length + 1

          if (pb.elementType === NOTIFICATION_ELEMENT_TYPES.BODY) {
            postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.BODY
            postReqElement.elementBody = pb.elementBody
            postReqElement.inputField = inputField
            if (!isPreview) de.wysiwygFieldIds.push(`freeFormatNotificationElements[${postReqFFNElements.length}].elementBody`)
          } else if (pb.elementType === NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL2) {
            postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL2
            postReqElement.elementBody = pb.elementBody
            postReqElement.inputField = inputField
            if (!isPreview) de.wysiwygFieldIds.push(`freeFormatNotificationElements[${postReqFFNElements.length}].elementBody`)
          } else if (pb.elementType === NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL3) {
            postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL3
            postReqElement.elementBody = pb.elementBody
            postReqElement.inputField = inputField
            if (!isPreview) de.wysiwygFieldIds.push(`freeFormatNotificationElements[${postReqFFNElements.length}].elementBody`)
          } else if (pb.elementType === NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL4) {
            postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL4
            postReqElement.elementBody = pb.elementBody
            postReqElement.inputField = inputField
            if (!isPreview) de.wysiwygFieldIds.push(`freeFormatNotificationElements[${postReqFFNElements.length}].elementBody`)
          } else if (pb.elementType === NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.DOT) {
            postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.DOT
            postReqElement.inputField = inputField
            postReqElement.bulletPoints = this.getPostReqBulletPoints(pb.elementBody)
            if (!isPreview) {
              for (let i = 0; i < postReqElement.bulletPoints.length; i++) {
                de.wysiwygFieldIds.push(`freeFormatNotificationElements[${postReqFFNElements.length}].bulletPoints[${i}].bulletPointBody`)
              }
            }
          } else {
            postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.INDEX
            postReqElement.inputField = inputField
            postReqElement.bulletPoints = this.getPostReqBulletPoints(pb.elementBody)
            if (!isPreview) {
              for (let i = 0; i < postReqElement.bulletPoints.length; i++) {
                de.wysiwygFieldIds.push(`freeFormatNotificationElements[${postReqFFNElements.length}].bulletPoints[${i}].bulletPointBody`)
              }
            }
          }
          postReqFFNElements.push(postReqElement)
        })
      } else if (de.ffnElementCardType === FFNELEMENT_CARD_TYPE.ANNOTATION_MODE_WYSIWYG_EDITOR) {
        if (!de.elementBody) return
        if (!isPreview) de.wysiwygFieldIds.push(`freeFormatNotificationElements[${postReqFFNElements.length}].elementBody`)
        const isAnnotation = true
        const parsedElementBodies = this.parseHTMLText(de.elementBody, isAnnotation)
        const postReqElement = new OwnerNotificationsPostRequestFFNElement()
        postReqElement.sortOrderNum = postReqFFNElements.length + 1
        postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.ROUNDED_SQUARE.LIGHT_GRAY
        postReqElement.elementBody = parsedElementBodies[0].elementBody
        postReqFFNElements.push(postReqElement)
      } else if (de.ffnElementCardType === FFNELEMENT_CARD_TYPE.LINK_SMOOTHE) {
        if (!isPreview) de.requestedIndex = postReqFFNElements.length
        const postReqElement = new OwnerNotificationsPostRequestFFNElement()
        postReqElement.sortOrderNum = postReqFFNElements.length + 1
        postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.LINK.SMOOTH_E
        postReqElement.elementBody = de.elementBody ?? undefined
        postReqElement.transitionType = de.transitionType
        postReqElement.transitionParams = de.transitionParams
        postReqFFNElements.push(postReqElement)
      } else if (de.ffnElementCardType === FFNELEMENT_CARD_TYPE.LINK_EXTERNAL) {
        if (!isPreview) de.requestedIndex = postReqFFNElements.length
        const postReqElement = new OwnerNotificationsPostRequestFFNElement()
        postReqElement.sortOrderNum = postReqFFNElements.length + 1
        postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.LINK.EXTERNAL
        postReqElement.elementBody = de.elementBody ?? undefined
        postReqElement.externalSiteUrl = de.externalSiteUrl
        postReqFFNElements.push(postReqElement)
      } else if (de.ffnElementCardType === FFNELEMENT_CARD_TYPE.ATTACHMENT) {
        if (!de.material) return
        if (!isPreview) de.requestedIndex = postReqFFNElements.length
        const postReqElement = new OwnerNotificationsPostRequestFFNElement()
        postReqElement.sortOrderNum = postReqFFNElements.length + 1
        postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.ATTACHMENT
        if (this.materialReferenceURLMap.has(de.material.materialId)) { // アップロード済みのmaterialを取得して設定する
          postReqElement.material = this.materialReferenceURLMap.get(de.material.materialId)
        } else { // 編集画面等でmaterialがアップロード済みの場合はそのまま設定する
          postReqElement.material = de.material
        }
        postReqFFNElements.push(postReqElement)
      } else if (de.ffnElementCardType === FFNELEMENT_CARD_TYPE.HORIZON) {
        if (!isPreview) de.requestedIndex = postReqFFNElements.length
        const postReqElement = new OwnerNotificationsPostRequestFFNElement()
        postReqElement.sortOrderNum = postReqFFNElements.length + 1
        postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.HORIZON
        postReqFFNElements.push(postReqElement)
      } else if (de.ffnElementCardType === FFNELEMENT_CARD_TYPE.LINK_EMAIL) {
        if (!isPreview) de.requestedIndex = postReqFFNElements.length
        const postReqElement = new OwnerNotificationsPostRequestFFNElement()
        postReqElement.sortOrderNum = postReqFFNElements.length + 1
        postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.LINK.EMAIL
        postReqElement.elementBody = de.elementBody ?? undefined
        postReqElement.emailAddress = de.emailAddress
        postReqFFNElements.push(postReqElement)
      } else {
        if (!isPreview) de.requestedIndex = postReqFFNElements.length
        const postReqElement = new OwnerNotificationsPostRequestFFNElement()
        postReqElement.sortOrderNum = postReqFFNElements.length + 1
        postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.LINK.PHONE_NUMBER
        postReqElement.elementBody = de.elementBody ?? undefined
        postReqElement.phoneNumber = de.phoneNumber
        postReqFFNElements.push(postReqElement)
      }
    })

    // 遷移ボタンラジオボタンからリクエストを生成
    if (this.inputs.transitionButton === TRANSITION_BUTTON_OPTION_VALUE.POST_IDEA) {
      const postReqElement = new OwnerNotificationsPostRequestFFNElement()
      postReqElement.sortOrderNum = postReqFFNElements.length + 1
      postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.BUTTON.POST_IDEA
      postReqFFNElements.push(postReqElement)
    } else if (this.inputs.transitionButton === TRANSITION_BUTTON_OPTION_VALUE.CONSULTATION) {
      const postReqElement = new OwnerNotificationsPostRequestFFNElement()
      postReqElement.sortOrderNum = postReqFFNElements.length + 1
      postReqElement.notificationElementType = NOTIFICATION_ELEMENT_TYPES.BUTTON.CONSULTATION
      postReqFFNElements.push(postReqElement)
    }

    return postReqFFNElements
  }

  parseHTMLText(text: string, isAnnotation?: boolean): {elementType: NotificationElementType, elementBody: string}[] {
    const regexps = /<p>([\s\S]*?)<\/p>|<h2>([\s\S]*?)<\/h2>|<h3>([\s\S]*?)<\/h3>|<h4>([\s\S]*?)<\/h4>|<ul>([\s\S]*?)<\/ul>|<ol>([\s\S]*?)<\/ol>/g
    const sentences = text.match(regexps)
    if (!sentences?.length) return []

    const parsedSentences = sentences.map(s => {
      if (s.match(/<p>([\s\S]*?)<\/p>/) && !isAnnotation) {
        const adjustedSentence = adjustTagsAndCharacters(s, true)
        return { elementType: NOTIFICATION_ELEMENT_TYPES.BODY, parsedSentence: adjustedSentence.replace(/<p>([\s\S]*?)<\/p>/, '$1') }
      } else if (s.match(/<p>([\s\S]*?)<\/p>/) && isAnnotation) {
        const adjustedSentence = adjustTagsAndCharacters(s, true)
        return { elementType: NOTIFICATION_ELEMENT_TYPES.ROUNDED_SQUARE.LIGHT_GRAY, parsedSentence: adjustedSentence.replace(/<p>([\s\S]*?)<\/p>/, '$1') }
      } else if (s.match(/<h2>([\s\S]*?)<\/h2>/)) {
        const adjustedSentence = adjustTagsAndCharacters(s, false)
        return { elementType: NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL2, parsedSentence: adjustedSentence.replace(/<h2>([\s\S]*?)<\/h2>/, '$1') }
      } else if (s.match(/<h3>([\s\S]*?)<\/h3>/)) {
        const adjustedSentence = adjustTagsAndCharacters(s, false)
        return { elementType: NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL3, parsedSentence: adjustedSentence.replace(/<h3>([\s\S]*?)<\/h3>/, '$1') }
      } else if (s.match(/<h4>([\s\S]*?)<\/h4>/)) {
        const adjustedSentence = adjustTagsAndCharacters(s, false)
        return { elementType: NOTIFICATION_ELEMENT_TYPES.HEADING.LEVEL4, parsedSentence: adjustedSentence.replace(/<h4>([\s\S]*?)<\/h4>/, '$1') }
      } else if (s.match(/<ul>([\s\S]*?)<\/ul>/)) {
        const adjustedSentence = adjustTagsAndCharacters(s, true)
        return { elementType: NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.DOT, parsedSentence: adjustedSentence.replace(/<ul>([\s\S]*?)<\/ul>/, '$1') }
      } else {
        const adjustedSentence = adjustTagsAndCharacters(s, true)
        return { elementType: NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.INDEX, parsedSentence: adjustedSentence.replace(/<ol>([\s\S]*?)<\/ol>/, '$1') }
      }
    })

    const elementBodies: {elementType: NotificationElementType, elementBody: string}[] = []
    for (let i = 0; i < parsedSentences.length; i++) {
      if (elementBodies.length > 0 && parsedSentences[i].elementType === elementBodies[elementBodies.length - 1].elementType) {
        if (elementBodies[elementBodies.length - 1].elementBody.match(/\n$/)) {
          // 最後に連結したsentenceが空行だった場合、そのまま次のsentenceを連結する（改行分の\nはすでに付与されているため、改行としての\nは付与しない）
          elementBodies[elementBodies.length - 1].elementBody = `${elementBodies[elementBodies.length - 1].elementBody}${parsedSentences[i].parsedSentence}`
        } else {
          // 最後に連結したsentenceが空行ではなかった場合、改行として\nを付与する
          elementBodies[elementBodies.length - 1].elementBody = `${elementBodies[elementBodies.length - 1].elementBody}\n${parsedSentences[i].parsedSentence}`
        }
      } else {
        elementBodies.push({ elementType: parsedSentences[i].elementType, elementBody: parsedSentences[i].parsedSentence })
      }
    }
    return elementBodies
  }

  getPostReqBulletPoints(text: string): OwnerNotificationsPostRequestBulletPoint[] {
    const regexps = /<li>([\s\S]*?)<\/li>|<li class="ql-indent-([1-8])">([\s\S]*?)<\/li>/g
    const sentences = text.match(regexps)

    const bulletPoints: OwnerNotificationsPostRequestBulletPoint[] = []
    sentences?.forEach(s => {
      if (s.match(/<li>([\s\S]*?)<\/li>/)) {
        const bulletPoint = new OwnerNotificationsPostRequestBulletPoint(
          bulletPoints.length + 1,
          s.replace(/<li>([\s\S]*?)<\/li>/, '$1'),
          1,
        )
        bulletPoints.push(bulletPoint)
      } else if (s.match(/<li class="ql-indent-([1-8])">([\s\S]*?)<\/li>/)) {
        const bulletPoint = new OwnerNotificationsPostRequestBulletPoint(
          bulletPoints.length + 1,
          s.replace(/<li class="ql-indent-([1-8])">([\s\S]*?)<\/li>/, '$2'),
          Number(s.replace(/<li class="ql-indent-([1-8])">([\s\S]*?)<\/li>/, '$1')) + 1,
        )
        bulletPoints.push(bulletPoint)
      }
    })
    return bulletPoints
  }

  private get wysiwygErrorMessage(): (ffnElementCardType: FFNElementCardType, isWysiwygInput: boolean, fieldIds: string[], text?: string | null) => string[] | undefined {
    return (ffnElementCardType, isWysiwygInput, fieldIds, text) => {
      // 未入力チェック
      if (isWysiwygInput && !text?.length) return ffnElementCardType === FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR ? ['テキストは必須項目です。'] : ['注釈テキストは必須項目です。']

      // 文字数上限超過チェック
      if (!text) return undefined
      const parsedElementBodies = this.parseHTMLText(text)
      const charNumOverMessage = ffnElementCardType === FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR ? '文字数が超過しています。1000文字以内にしてください。' : '注釈テキストは1000文字以内にしてください。'
      for (const body of parsedElementBodies) {
        if (body.elementType === NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.DOT || body.elementType === NOTIFICATION_ELEMENT_TYPES.BULLET_POINT.INDEX) {
          let checkTarget = ''
          const reqBulletPoints = this.getPostReqBulletPoints(body.elementBody)
          reqBulletPoints.forEach(bp => { checkTarget = checkTarget.concat(bp.bulletPointBody) })
          if (checkTarget.length > 1000) return [charNumOverMessage]
        } else {
          if (body.elementBody.length > 1000) return [charNumOverMessage]
        }
      }

      // フィールドエラーチェック
      for (const id of fieldIds) {
        // フィールドエラーが一つでもあれば固定文言のエラーメッセージを返却する
        if (errorsModule.fieldErrors(id)) return [charNumOverMessage]
      }

      return undefined
    }
  }

  onInputWysiwygEditor(index: number): void {
    this.inputs.displayingFFNElements[index].isWysiwygInput = true
    this.inputs.displayingFFNElements[index].wysiwygFieldIds.forEach(id => { if (errorsModule.fieldErrors(id)) errorsModule.clearSingleFieldError(id) })
  }

  private get isWysiwygInputCompleted(): boolean {
    const notCompletedCount = this.inputs.displayingFFNElements.filter(e => {
      if (e.ffnElementCardType === FFNELEMENT_CARD_TYPE.TEXT_MODE_WYSIWYG_EDITOR || e.ffnElementCardType === FFNELEMENT_CARD_TYPE.ANNOTATION_MODE_WYSIWYG_EDITOR) {
        return this.wysiwygErrorMessage(e.ffnElementCardType, e.isWysiwygInput, e.wysiwygFieldIds, e.elementBody) || !e.elementBody?.length
      } else {
        return false
      }
    }).length
    return notCompletedCount === 0
  }

  // 内部リンクのindexを保持しておくための変数
  transitionToIndex: number | null = null
  transitionToSelectModalKey = generateUuid()
  isTransitionToSelectModalVisible = false
  // 遷移先選択全画面ダイアログ用データ、メソッド
  openTransitionToSelectModal(index: number): void {
    this.transitionToIndex = index
    this.selectedTargetTransitionTypeByModal = this.inputs.displayingFFNElements[index].transitionType ?? TRANSITION_TO.IDEA.OWNER

    if (this.inputs.displayingFFNElements[index].transitionType === TRANSITION_TO.IDEA.OWNER || this.inputs.displayingFFNElements[index].transitionType === TRANSITION_TO.IDEA.ADMIN) {
      this.selectedTargetTransitionIdByModal = this.inputs.displayingFFNElements[index].transitionParams?.ideaId ?? null
    } else if (this.inputs.displayingFFNElements[index].transitionType === TRANSITION_TO.RESOLUTION.ONLINE || this.inputs.displayingFFNElements[index].transitionType === TRANSITION_TO.RESOLUTION.GENERAL_MEETING) {
      this.selectedTargetTransitionIdByModal = this.inputs.displayingFFNElements[index].transitionParams?.resolutionId ?? null
    } else {
      this.selectedTargetTransitionIdByModal = null
    }

    this.transitionToSelectModalKey = generateUuid()
    this.isTransitionToSelectModalVisible = true
  }

  selectedTargetTransitionTypeByModal: TransitionTo = TRANSITION_TO.IDEA.OWNER
  selectedTargetTransitionIdByModal: string | null = null
  transitionToSelectModalOwnerIdeaKeyword = ''
  transitionToSelectModalOwnerIdeaInputText = ''
  transitionToSelectModalAdminIdeaKeyword = ''
  transitionToSelectModalAdminIdeaInputText = ''
  transitionToSelectModalOnlineResolutionKeyword = ''
  transitionToSelectModalOnlineResolutionInputText = ''
  transitionToSelectModalGMResolutionKeyword = ''
  transitionToSelectModalGMResolutionInputText = ''

  onSelectTransitionToByModal(event: { targetType: TransitionTo, targetId: string }): void {
    if (this.transitionToIndex === null) return
    switch (event.targetType) {
      case TRANSITION_TO.IDEA.OWNER:
        this.inputs.displayingFFNElements[this.transitionToIndex].transitionType = TRANSITION_TO.IDEA.OWNER
        this.inputs.displayingFFNElements[this.transitionToIndex].transitionParams = { ideaId: event.targetId }
        this.inputs.displayingFFNElements[this.transitionToIndex].elementBody = this.buildingOwnerIdeas.filter(idea => idea.ideaId === event.targetId)[0].title
        break
      case TRANSITION_TO.IDEA.ADMIN:
        this.inputs.displayingFFNElements[this.transitionToIndex].transitionType = TRANSITION_TO.IDEA.ADMIN
        this.inputs.displayingFFNElements[this.transitionToIndex].transitionParams = { ideaId: event.targetId }
        this.inputs.displayingFFNElements[this.transitionToIndex].elementBody = this.buildingAdminIdeas.filter(idea => idea.ideaId === event.targetId)[0].title
        break
      case TRANSITION_TO.RESOLUTION.ONLINE:
        this.inputs.displayingFFNElements[this.transitionToIndex].transitionType = TRANSITION_TO.RESOLUTION.ONLINE
        this.inputs.displayingFFNElements[this.transitionToIndex].transitionParams = { resolutionId: event.targetId }
        this.inputs.displayingFFNElements[this.transitionToIndex].elementBody = this.buildingOnlineResolutions.filter(resolution => resolution.resolutionId === event.targetId)[0].title
        break
      case TRANSITION_TO.RESOLUTION.GENERAL_MEETING:
        this.inputs.displayingFFNElements[this.transitionToIndex].transitionType = TRANSITION_TO.RESOLUTION.GENERAL_MEETING
        this.inputs.displayingFFNElements[this.transitionToIndex].transitionParams = { resolutionId: event.targetId }
        this.inputs.displayingFFNElements[this.transitionToIndex].elementBody = this.buildingGMResolutions.filter(resolution => resolution.resolutionId === event.targetId)[0].title
        break
      case TRANSITION_TO.QA:
      case TRANSITION_TO.REPORT:
      case TRANSITION_TO.TICKET.DETAIL:
      case TRANSITION_TO.TICKET.DETAIL_TASK:
      case TRANSITION_TO.NOTICE:
      case TRANSITION_TO.STAFF_DETAIL.FRONT:
      case TRANSITION_TO.STAFF_DETAIL.LIFE_MANAGER:
      case TRANSITION_TO.CASYS_RESULTS:
        return
      default: assertExhaustive(event.targetType)
    }
    this.inputs.displayingFFNElements[this.transitionToIndex].isTransitionToInput = true
    this.inputs.displayingFFNElements[this.transitionToIndex].isLinkAvailable = true
    this.transitionToIndex = null
  }

  private get isTransitionToInputCompleted(): boolean { return !this.inputs.displayingFFNElements.some(e => e.ffnElementCardType === FFNELEMENT_CARD_TYPE.LINK_SMOOTHE && !e.isTransitionToInput) }
  private get isTransitionToLinkAvailable(): boolean { return !this.inputs.displayingFFNElements.some(e => e.ffnElementCardType === FFNELEMENT_CARD_TYPE.LINK_SMOOTHE && !e.isLinkAvailable) }

  // 固定されたお知らせ重複アラート表示・非表示
  isPinningUpdateIndividualAlertVisible = false
  isPinningUpdateAllAlertVisible = false

  private get ownerNotificationPinningBuildings(): OwnerNotificationPinningBuildingsGetResponse { return buildingsModule.ownerNotificationPinningBuildingsGet }

  private get pinningUpdateBuildingCount(): number {
    const pinningUpdateTargets = this.inputs.buildings.filter(b => this.ownerNotificationPinningBuildings.buildingIds.includes(b.buildingId))
    return pinningUpdateTargets.length
  }

  private get pinningUpdateBuildingNameList(): string {
    const pinningUpdateTargetNames = this.inputs.buildings.filter(b => this.ownerNotificationPinningBuildings.buildingIds.includes(b.buildingId)).map(b => b.buildingName)
    const list = `・${pinningUpdateTargetNames.join('\n・')}`
    return list
  }

  // 確認ダイアログ表示・非表示
  isSaveDraftDialogVisible = false
  isDeleteDraftDialogVisible = false
  isExecuteDialogVisible = false

  editInitialScheduledPostDateTime = ''
  private get executeDialogMessage(): string {
    if (this.isCreate) {
      if (this.inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED) {
        return `${this.inputs.formattedScheduledPostDateTime}にお知らせを投稿します。よろしいですか？`
      } else {
        return 'お知らせを投稿します。よろしいですか？'
      }
    } else {
      // 投稿日時指定かつ投稿日時に変更を加えた場合
      if (this.inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED && new Date(this.inputs.scheduledPostDateTime).valueOf() !== new Date(this.editInitialScheduledPostDateTime).valueOf()) {
        return `${this.inputs.formattedScheduledPostDateTime}にお知らせを投稿します。よろしいですか？`
      } else {
        return 'お知らせを更新します。よろしいですか？'
      }
    }
  }

  // 各種実行ボタン押下時の処理
  async goToOwnerNotificationPreviewPage(): Promise<void> {
    const previewContents = new OwnerNotificationPreviewContent()
    previewContents.title = this.inputs.title
    previewContents.freeFormatNotificationElements = await this.getPostReqFFNElementsFromInputs(true)

    if (this.inputs.material) {
      if (!this.inputs.material.materialId) { // 新たに添付した素材の場合
        // ローカルストレージのサイズの制約上、プレビュー時に素材をそのまま別タブに渡すのが難しいため、ここでアップロードする
        const uploadedMaterial = await uploadMaterial(this.inputs.material)
        if (uploadedMaterial) {
          this.materialReferenceURLMap.set(uploadedMaterial.materialId, uploadedMaterial)
          // 画面に表示中の素材データにもIDを格納することで投稿／編集時に素材を再登録しなくて済むようにする
          this.inputs.material.materialId = uploadedMaterial.materialId
          previewContents.material = uploadedMaterial
        }
      } else if (this.materialReferenceURLMap.get(this.inputs.material.materialId)) { // 一度プレビューした素材を付け替えずに使用する場合
        previewContents.material = this.materialReferenceURLMap.get(this.inputs.material.materialId)
      } else { // すでにDBに登録済みの素材を画面初期表示から一度も付け替えずに使用する場合
        previewContents.material = this.inputs.material
      }
    }

    if (this.isOneBuildingSelected) previewContents.buildingId = this.inputs.buildings[0].buildingId

    const previewContentsId = generateUuid()
    newTabLocalParamStorageModule.setOwnerNotificationPreviewContent({ key: previewContentsId, ownerNotificationPreviewContent: previewContents })
    windowOpen(staticRoutes.ownerNotificationPreview.path.replace(':id', previewContentsId))
  }

  private get isInvalidInput(): boolean {
    // validation-observerでは検知しないデータのバリデーションチェックを行う
    return (!!this.owGroupContentNotExistErrorMessage ||
            !this.isTargetBuildingSelected ||
            !this.isTargetOwnerSelected ||
            !!this.postTimingOverOwGroupDeadlineErrorMessage ||
            !this.isWysiwygInputCompleted ||
            !this.isTransitionToInputCompleted ||
            !this.isTransitionToLinkAvailable ||
            this.inputs.displayingFFNElements.length === 0)
  }

  async onClickExecuteBtn(): Promise<void> {
    if (this.inputs.pinningSettingType === PINNING_SETTING_TYPE.PINNED && !(!this.isCreate && this.storedOwnerNotificationState === OWNER_NOTIFICATION_STATE.NOTIFIED && new Date(this.inputs.pinningDeadlineDate) < new Date(currentDate()))) {
      const req = new OwnerNotificationPinningBuildingsGetRequest()
      req.ownerNotificationType = OWNER_NOTIFICATION_TYPE.NOTIFIED_BY_ADMIN
      if (this.inputs.postTimingType === POST_TIMING_TYPE.SCHEDULED) req.scheduledPostDateTime = this.inputs.scheduledPostDateTime
      if (!this.isCreate) req.ownerNotificationId = this.storedOwnerNotification?.ownerNotificationId
      await buildingsModule.fetchNotificationPinningBuildings(req)

      if (this.inputs.targetBuildingType === TARGET_BUILDING_TYPE.ALL && this.ownerNotificationPinningBuildings.buildingIds.length > 0) {
        this.isPinningUpdateAllAlertVisible = true
        return
      }

      if (this.pinningUpdateBuildingCount > 0) {
        this.isPinningUpdateIndividualAlertVisible = true
        return
      }
    }

    this.isExecuteDialogVisible = true
  }

  onClickDeleteDraft(): void {
    if (!this.inputs.ownerNotificationId) return
    this.processCompleted = true
    this.isDeleteDraftDialogVisible = false
    this.typeSpecs.onClickDeleteDraft(this.$router, this.inputs.ownerNotificationId)
  }

  async onClickSaveDraft(): Promise<void> {
    this.processCompleted = true
    this.isSaveDraftDialogVisible = false
    this.typeSpecs.onClickSaveDraft(this.$router, this.inputs, await this.getPostReqFFNElementsFromInputs())
  }

  async onClickExecute(): Promise<void> {
    this.processCompleted = true
    this.isExecuteDialogVisible = false
    this.isPinningUpdateAllAlertVisible = false
    this.isPinningUpdateIndividualAlertVisible = false
    this.typeSpecs.onClickExecute(this.$router, this.inputs, await this.getPostReqFFNElementsFromInputs())
  }

  nextRoute: Route | null = null
  processCompleted = false
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (!this.isCreate || this.processCompleted || this.nextRoute) {
      next()
    } else {
      this.nextRoute = to
      next(false)
    }
  }

  leaveHere(): void {
    const routeName = this.nextRoute?.name
    const routeParams = this.nextRoute?.params
    if (routeName) {
      if ((this.isArchive || this.isNotified || this.isScheduled) && routeName === staticRoutes.ownerNotificationDetail.name) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: routeName, params: routeParams })
      }
    }
  }
}
