








































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmOwExpansionArea extends Vue {
  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop()
  private readonly header?: string

  @Prop()
  private readonly expandHeader?: string
}
