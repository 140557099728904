





































import { Vue, Component, Model, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDialogBase: () => import('@/components/organisms/dialog/SmDialogBase.vue'),
  }
})
export default class SmDialogCheckbox extends Vue {
  @Model('change')
  private isVisible!: boolean

  @Prop({ default: '', required: true })
  private readonly title!: string

  @Prop({ default: '', required: true })
  private readonly checkboxLabel!: string

  @Prop({ default: '保存する' })
  private readonly executionBtnLabel!: string

  @Prop({ default: 'キャンセル' })
  private readonly cancelBtnLabel!: string

  @Prop()
  private readonly text?: string

  checked = false
}
