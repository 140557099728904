import { Type } from 'class-transformer'
import { Material } from '../commons'
import type { QuestionnaireQuestionElementType, QuestionnaireQuestionType, SectionType, QuestionnaireState, ReportState, PostTimingType } from '@/constants/schema-constants'

export class QuestionnaireDetailGetRequest {
  questionnaireId: string
  constructor(questionnaireId: string) {
    this.questionnaireId = questionnaireId
  }
}

export class FreeAnswer {
  questionnaireChoiceAnswerId!: string
  freeAnswer!: string
}

export class QuestionnaireQuestionElement {
  questionnaireQuestionElementId!: string
  questionnaireQuestionElementType!: QuestionnaireQuestionElementType
  templateQuestionElementId?: string
  placeholder?: string
  choice?: string
  sortOrderNum!: number
  answerCount?: number
  answerRate?: number
  previousAnswerRate?: number
}

export class QuestionnaireQuestion {
  questionnaireQuestionId!: string
  questionnaireQuestionType!: QuestionnaireQuestionType
  templateQuestionId? :string
  sentence!: string
  required!: boolean
  sortOrderNum!: number
  @Type(() => QuestionnaireQuestionElement) questionnaireQuestionElements: QuestionnaireQuestionElement[] = []
  @Type(() => FreeAnswer) freeAnswers: FreeAnswer[] = []
}

export class QuestionnaireSection {
  questionnaireSectionId!: string
  questionnaireSectionType!: SectionType
  title?: string
  explanation?: string
  @Type(() => Material) material?: Material
  sortOrderNum!: number
  @Type(() => QuestionnaireQuestion) questionnaireQuestions: QuestionnaireQuestion[] = []
}

export class QuestionnaireBuilding {
  buildingId!: string
  buildingName!: string
}

export class QuestionnaireDetailGetResponse {
  questionnaireId!: string
  title!: string
  templateId!: string
  templateName!: string
  questionnaireState!: QuestionnaireState
  readCount?: number
  answerCount?: number
  explanation!: string
  @Type(() => Material) material?: Material
  @Type(() => QuestionnaireBuilding) building = new QuestionnaireBuilding()
  postTimingType!: PostTimingType
  postedAt!: string
  postedAtDateTime!: string
  deadline!: string
  deadlineDateTime!: string
  processedAt!: string
  version!: number
  questionnaireReportId?: string
  reportState?: ReportState
  @Type(() => QuestionnaireSection) questionnaireSections: QuestionnaireSection[] = []
}
