import * as ExcelJs from 'exceljs'

// 引数で与えられた配列の、conditionに該当する行を末尾からtrimする
const trimEnd = <T>(arr: T[], trimRule: ((elm: T) => boolean)) => {
  const maxAttempt = arr.length
  for (let idx = 0; idx < maxAttempt; idx++) {
    const lastRow = arr.pop()

    if (lastRow && !trimRule(lastRow)) {
      arr.push(lastRow)
      break
    }
  }
}

const INVALID_CHARS = /[*?:\\/[\]]/g // シート名に設定不可な文字群。 * ? : \ / [ ]

/**
 * ユーザ（管・区問わず）が画面で任意文字列を入力可能な項目をそのまま出力するセルには、
 * Excelインジェクション対策として適切なエスケープ処理をするため、 esc メソッドに値を通すこと
 */
export abstract class ExcelParserBase {
  protected async fromExcel(file: File, sheetIndex = 0, validator?: (parsed: ExcelJs.CellValue[][]) => boolean): Promise<unknown[][] | undefined> {
    const book = new ExcelJs.Workbook()
    await book.xlsx.load(await this._convertToUint8Array(file))

    const sheet = book.worksheets[sheetIndex]
    if (!sheet) return undefined

    const data: ExcelJs.CellValue[][] = []
    sheet.eachRow({ includeEmpty: true }, row => {
      const _row: ExcelJs.CellValue[] = []
      row.eachCell({ includeEmpty: true }, cell => { _row.push(cell.value) })

      // 奥の列の一見値の無いセルも、書式設定されたものがあると値がnullで続いてしまうので、その部分を取り除く。
      // 行の要素全てがそうした値だった場合は空配列となる
      trimEnd(_row, val => val == null)
      data.push(_row)
    })

    trimEnd(data, row => row.length === 0) // また、上記のような事情で作られた空配列が末尾に続いていた場合はその部分を無いものとして取り込む（何かしら値のある行だけを対象とする）

    if (!validator || validator(data)) return data
    else return undefined
  }

  /* 長期修繕計画ファイルのパース処理（簡易版リアルタイム長計で利用） */
  protected async fromRepairPlanExcel(file: File, sheetIndex = 1): Promise<unknown[][] | undefined> {
    const book = new ExcelJs.Workbook()
    await book.xlsx.load(await this._convertToUint8Array(file))

    const sheet = book.worksheets[sheetIndex]
    if (!sheet) return undefined

    this._unMergeCells(sheet)

    const data: ExcelJs.CellValue[][] = []
    sheet.eachRow({ includeEmpty: true }, row => {
      const _row: ExcelJs.CellValue[] = []
      row.eachCell({ includeEmpty: true }, cell => { _row.push(cell.value) })

      // 奥の列の一見値の無いセルも、書式設定されたものがあると値がnullで続いてしまうので、その部分を取り除く。
      // 行の要素全てがそうした値だった場合は空配列となる
      trimEnd(_row, val => val == null)
      data.push(_row)
    })

    trimEnd(data, row => row.length === 0) // また、上記のような事情で作られた空配列が末尾に続いていた場合はその部分を無いものとして取り込む（何かしら値のある行だけを対象とする）
    return data
  }

  /* 資金計画表ファイルのパース処理（簡易版リアルタイム長計で利用） */
  protected async fromFinancialPlanExcel(file: File, sheetIndex = 0): Promise<unknown[][] | undefined> {
    const book = new ExcelJs.Workbook()
    await book.xlsx.load(await this._convertToUint8Array(file))

    const sheet = book.worksheets[sheetIndex]
    if (!sheet) return undefined

    this._unMergeCells(sheet)

    const data: ExcelJs.CellValue[][] = []
    sheet.eachRow({ includeEmpty: true }, row => {
      const _row: ExcelJs.CellValue[] = []
      row.eachCell({ includeEmpty: true }, cell => { _row.push(cell.value) })

      // 奥の列の一見値の無いセルも、書式設定されたものがあると値がnullで続いてしまうので、その部分を取り除く。
      // 行の要素全てがそうした値だった場合は空配列となる
      trimEnd(_row, val => val == null)
      data.push(_row)
    })

    trimEnd(data, row => row.length === 0) // また、上記のような事情で作られた空配列が末尾に続いていた場合はその部分を無いものとして取り込む（何かしら値のある行だけを対象とする）

    return data
  }

  protected async toExcel(fileName: string, dataDefs: ((sheet: ExcelJs.Worksheet) => void) | ((sheet: ExcelJs.Worksheet) => void)[], sheetNames?: string | string[]): Promise<void> {
    const book = new ExcelJs.Workbook()
    book.creator = 'smooth-e'
    book.lastModifiedBy = 'smooth-e'
    book.created = new Date()
    book.views = [{ x: 0, y: 0, width: 100, height: 2000, firstSheet: 0, activeTab: 0, visibility: 'visible' }]

    const _dataDefs = Array.isArray(dataDefs) ? dataDefs : [dataDefs]
    const _names = sheetNames ? (Array.isArray(sheetNames) ? sheetNames : [sheetNames]) : []
    for (let i = 0; i < _dataDefs.length; i++) {
      const sheet = book.addWorksheet(_names[i]?.replaceAll(INVALID_CHARS, '_'))
      _dataDefs[i](sheet)
    }

    const blob = new Blob([await book.xlsx.writeBuffer()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`
    document.body.appendChild(link)
    link.click()
  }

  protected esc(val: string): string { // https://owasp.org/www-community/attacks/CSV_Injection
    return /^[=＝+＋\-－@＠;；,\t\r\n]/.test(val) ? `'${val}` : val
  }

  /** 1 -> A, 26 -> Z, 27 -> AA, 28 -> AB, ... */
  protected idxToAlpha(idx: number): string {
    let str = ''
    let p = idx
    while (p > 0) {
      str = String.fromCharCode(((p - 1) % 26) + 65) + str
      p = Math.floor((p - 1) / 26)
    }
    return str
  }

  /** A -> 1, Z -> 26, AA -> 27, AB -> 28, ... */
  protected alphaToIdx(alpha: string): number {
    let num = 1
    for (let charAt = 0; charAt < alpha.length; charAt++) {
      num += Math.pow(26, charAt) * (alpha.charCodeAt(alpha.length - charAt - 1) - 64)
    }
    return num
  }

  /**
   * @param sheet
   * @param ranges 'A1:C2' returns 6cells, A1,A2,B1,B2,C1,C2
   */
  protected getRange(sheet: ExcelJs.Worksheet, ...ranges: string[]): ExcelJs.Cell[] {
    const cells: ExcelJs.Cell[] = []

    for (const range of ranges) {
      const match = range.match(/([A-Z]+)(\d+):([A-Z]+)(\d+)/)
      if (!match) continue

      const startColIdx = this.alphaToIdx(match[1]) - 1
      const startRowIdx = Number(match[2])
      const endColIdx = this.alphaToIdx(match[3]) - 1
      const endRowIdx = Number(match[4])

      const rows = sheet.getRows(startRowIdx, endRowIdx - startRowIdx + 1)
      if (!rows) continue

      for (const row of rows) {
        for (let col = startColIdx; col <= endColIdx; col++) { cells.push(row.getCell(col)) }
      }
    }

    return cells
  }

  /** 枠線で埋める */
  protected borderizeByRange(sheet: ExcelJs.Worksheet, style: ExcelJs.BorderStyle, ...ranges: string[]): void {
    this.getRange(sheet, ...ranges).forEach(cell => { cell.border = Object.assign(cell.border ?? {}, { top: { style }, left: { style }, bottom: { style }, right: { style }, diagonal: {} }) })
  }

  /** 枠線で囲う */
  protected surroundByRange(sheet: ExcelJs.Worksheet, style: ExcelJs.BorderStyle, ...ranges: string[]): void {
    for (const _range of ranges) {
      const match = _range.match(/([A-Z]+)(\d+):([A-Z]+)(\d+)/)
      if (!match) continue
      const left = match[1]; const top = match[2]; const right = match[3]; const bottom = match[4]

      this.getRange(sheet, _range).forEach(cell => {
        const _cr = cell.$col$row.split('$'); const col = _cr[1]; const row = _cr[2]
        if (col === left) cell.border = Object.assign(cell.border ?? {}, { left: { style } })
        if (col === right) cell.border = Object.assign(cell.border ?? {}, { right: { style } })
        if (row === top) cell.border = Object.assign(cell.border ?? {}, { top: { style } })
        if (row === bottom) cell.border = Object.assign(cell.border ?? {}, { bottom: { style } })
      })
    }
  }

  protected mergeByRange(sheet: ExcelJs.Worksheet, centerize: boolean, ...ranges: string[]): void {
    for (const range of ranges) {
      sheet.mergeCells(range)
      if (centerize) sheet.getCell(range.split(':')[0]).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
    }
  }

  /** シートのデフォルトフォントを設定する */
  protected setDefaultFont(sheet: ExcelJs.Worksheet, fontsize: number, fontName: string): void {
    sheet.eachRow(row => {
      row.eachCell(cell => {
        // default styles
        if (!cell.font?.size) {
          cell.font = Object.assign(cell.font || {}, { size: fontsize })
        }
        cell.font = Object.assign(cell.font || {}, { name: fontName })
      })
    })
  }

  private async _convertToUint8Array(file: File): Promise<Uint8Array> {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = f => resolve(new Uint8Array(f.target?.result as ArrayBuffer))
      reader.readAsArrayBuffer(file)
    })
  }

  /**
   * 正しく値を取得するためにセル結合を解除する
   * @param sheet 対象のExcelワークシート
   */
  private _unMergeCells(sheet: ExcelJs.Worksheet): void {
    // 結合されたセルを特定しその位置（例：A1）を取得する
    const mergedcells: string[] = []
    sheet.eachRow({ includeEmpty: true }, row => {
      row.eachCell({ includeEmpty: true }, cell => {
        if (cell.isMerged) {
          mergedcells.push(cell.address)
        }
      })
    })

    mergedcells.forEach((mergedcell) => {
      sheet.unMergeCells(mergedcell)
    })
  }
}
