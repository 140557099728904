
























import { Vue, Component, Prop } from 'vue-property-decorator'

const BULLET_LIST = [
  '利用中画面を見る機能内での操作内容は保存されず、再読み込み時にリセットされます。',
  '区分所有者の利用画面に操作内容が反映されることはありません。',
  'smooth-eを通して素敵ネットの機能を利用する事はできません。',
  'ログアウト後、再びログインする場合は、管理者用アプリ経由でログインし直してください。',
] as const

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDialogClosable: () => import('@/components/organisms/dialog/SmDialogClosable.vue'),
  }
})
export default class SmDialogSimulateConfirmation extends Vue {
  @Prop({ default: false })
  private readonly value!: boolean

  private get isDialogVisible(): boolean { return this.value }
  private set isDialogVisible(newValue: boolean) {
    this.$emit('input', newValue)
  }

  private BULLET_LIST = Object.freeze(BULLET_LIST)

  onClickClose(): void {
    this.$emit('close')
  }

  onClickExecute(): void { this.$emit('execute') }
}
