

















































import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'

import { unitsModule } from '@/stores/units-store'

import { Owner } from '@/dtos/owners/get'
import { Unit } from '@/dtos/units/get'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class RelatedOwnerSelectModal extends Vue {
  @Model('input', { required: true })
  readonly isModalVisible!: boolean

  // 紐づけする住戸の現区分所有者の区分所有者ID（この住戸の区分所有者を除いて表示）
  @Prop({ required: true, default: '' })
  readonly currentUserId!: string

  // 検索時にコンポーネントに検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!: string

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!: string

  get _inputText(): string { return this.inputText }
  set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  get _keyword(): string { return this.keyword }
  set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  get _isModalVisible(): boolean { return this.isModalVisible }
  set _isModalVisible(newValue: boolean) {
    this.$emit('input', newValue)
  }

  get owners(): Unit[] { return unitsModule.unitsGet.units.filter(unit => unit.userId !== this.currentUserId) }

  headers: Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '112px' }),
    new Header({ text: '氏名', value: 'userName' }),
  ]

  // 住戸番号列の昇順／降順の切替えを数値として行うために指定するオブジェクト
  customSorts = {
    roomNumber: {
      sortMethod: (a: Owner, b: Owner): number => {
        const aNumStr = a.roomNumber.match(/\d+/)
        const aNum = aNumStr ? parseInt(aNumStr.toString()) : 0
        const bNumStr = b.roomNumber.match(/\d+/)
        const bNum = bNumStr ? parseInt(bNumStr.toString()) : 0

        return aNum - bNum
      }
    }
  }

  // SmTableDataの返却値（配列）を格納するための変数。当モーダルは単一選択のため返却値は一つのみ。
  selectedUpdateUnitId: string[] = []

  onSearch(): void {
    this._keyword = this._inputText
  }

  passSelectedUser():void {
    const selectedUser = this.owners.find(({ unitId }) => unitId === this.selectedUpdateUnitId[0])
    this.$emit('select', selectedUser)
    this._isModalVisible = false
  }
}
