import { IdeaState, ItemType } from '@/constants/schema-constants'
import { BaseIdea, BaseOpinion, Estimate, FollowingResolution, Section, User } from '@/dtos/commons'
import { Details } from '@/dtos/ideas/admin-idea/commons'
import { Type } from 'class-transformer'

export class AdminIdeaDetailGetRequest {
  ideaId!: string

  constructor(ideaId: string) {
    this.ideaId = ideaId
  }
}

export class AdminIdeaDetailGetResponsePlan {
  planId!: string
  itemType!: ItemType
  itemTitle!: string
  @Type(() => BaseOpinion)baseOpinions: BaseOpinion[] = []
  @Type(() => Estimate)estimates: Estimate[] = []
  @Type(() => Section)sections: Section[] = []
  // アコーディオン表示用の項目
  isExpanded = false
}

export class AdminIdeaDetailGetResponse {
  ideaId!: string
  ideaState!: IdeaState
  postedAt!: string
  opinionDeadline!: string
  opinionDeadlineDateTime!: string
  deadline!: string
  deadlineDateTime!: string
  isNotAcceptable!: boolean
  title!: string
  @Type(() => Details)details: Details = new Details()
  @Type(() => BaseIdea)baseIdea?: BaseIdea
  @Type(() => FollowingResolution)followingResolution?: FollowingResolution
  @Type(() => User)user: User = new User()
  @Type(() => AdminIdeaDetailGetResponsePlan)plans: AdminIdeaDetailGetResponsePlan[] = []
  processedAt!: string
  version!: number
}
