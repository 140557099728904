export class TicketsRegularMtgCsvPostRequest {
  fromDate!: string
  toDate!: string
  isIncludeProcessingAndCompletedTickets!: boolean
  buildings?: string[]
  constructor(fromDate: string, toDate:string, isIncludeProcessingAndCompletedTickets:boolean, buildings?: string[]) {
    this.fromDate = fromDate
    this.toDate = toDate
    this.isIncludeProcessingAndCompletedTickets = isIncludeProcessingAndCompletedTickets
    this.buildings = buildings
  }
}
