





















































































import { Header } from '@/components/molecules/SmTableData.vue'
import { NEW_TAB_TRANSITION_TO } from '@/constants/schema-constants'
import { BuildingsGetRequest, Building } from '@/dtos/buildings/get'
import { openNewTab } from '@/libs/open-new-tab'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { buildingsModule } from '@/stores/buildings-store'
import { NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'
import { Component, Mixins } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')

  }
})
export default class BuildingsListPage extends Mixins(CurrentAdminManager) {
  async created(): Promise<void> {
    this.isStaff = this.initializeIsStaff
    await this.onSwitchIsStaff()
  }

  buildingsGetRequestParams = new BuildingsGetRequest(0, 1000, '')
  isStaff: boolean | null = null

  private get initializeIsStaff(): boolean | null {
    if (this.$isFrontPerson) return true
    return null
  }

  tableHeaders:Header[] = [
    new Header({ text: 'MaNo.', value: 'condominiumCd', sortable: false, width: '120px' }),
    new Header({ text: 'マンション名', value: 'buildingName', sortable: false }),
    new Header({ text: '管理者業務執行者', value: 'serviceStaff.adminName', sortable: false, width: '200px' }),
  ]

  get buildings(): Building[] { return buildingsModule.buildingsGet.buildings }

  inputKeyword = ''
  searchingKeyword = ''

  onSearchKeyword(): void {
    this.searchingKeyword = this.inputKeyword
  }

  async onSwitchIsStaff(): Promise<void> {
    this.buildingsGetRequestParams.isStaff = this.isStaff
    await buildingsModule.fetchBuildings(this.buildingsGetRequestParams)
  }

  onClickBuilding(buildingId : string): void {
    this.openBuildingDetailPage(buildingId)
  }

  openBuildingDetailPage(buildingId: string): void {
    // 該当のマンション詳細画面を別タブで開く
    const newTabTransitionParams = new NewTabTransitionParams()
    newTabTransitionParams.newTabTransitionTo = NEW_TAB_TRANSITION_TO.BUILDING_DETAIL
    newTabTransitionParams.buildingId = buildingId
    openNewTab(newTabTransitionParams)
  }

  goToBuildingRegisterPage(): void {
    this.$router.push({ name: staticRoutes.buildingRegister.name })
  }
}
