import { AdminIdeasOpinionsCommentsPostRequest, AdminIdeasOpinionsCommentsPostResponse } from '@/dtos/ideas/admin-idea/opinions/comments/post'

export class AdminIdeasOpinionsCommentsPutRequest extends AdminIdeasOpinionsCommentsPostRequest {
  opinionCommentId!:string
  version!:number
}

export class AdminIdeasOpinionsCommentsPutResponse extends AdminIdeasOpinionsCommentsPostResponse {

}
