import * as ExcelJs from 'exceljs'
import { ExcelParserBase } from './excel-parser-base'

import { Duration } from '@/dtos/commons'
import { PaymentDetail, ReservePlan, ReservesGetResponse } from '@/dtos/reserve-funds/get'

// ---------------
//  1: タイトル
//
//  4-15: 期間内の支出・収支のサマリ
// 17-XX: 住戸別の支払月額関係（物件に存在する専有面積の種類数による）
//
// その下に、XXの3行下を1として、
//  1- 3: 期ごとの支出
//  5- 9: 期ごとの収入
//    11: 期ごとの収支
// ---------------
// 4-XX行目は、画面で設定された"期間"の数だけ5列（4行+空行）の組み合わせを繰り返す構造。
// セルの値は基本的に行方向の組で計算しているが、この部分のみ列方向の組を基に値を埋め込んでいる。
// ---------------

class RervesBldPlanParser extends ExcelParserBase {
  private readonly _unitRowsStartRow = 20 // 20行目から住戸タイプによる行数可変のエリアがスタート
  private readonly _durationColsStartCol = 6 // 6列目から選択期間による列数可変のエリアがスタート

  async downloadAsExcel(buildingName: string, durations: Duration[], reserves: ReservesGetResponse): Promise<void> {
    const fileName = `支払いプラン検討_${buildingName}`
    const initialPeriod = reserves.reservePlan[0].period
    const finalPeriod = reserves.reservePlan[reserves.reservePlan.length - 1].period
    const unitRowsCount = reserves.paymentPlan.length
    const sumRowsStartRow = this._unitRowsStartRow + unitRowsCount + 5 // 住戸タイプによる可変行の終端の5行下から、期ごとの合計行のエリアをスタート（空行分の余裕を含む）

    const _durations = this._specifyDurations(durations, initialPeriod, finalPeriod) // 積立計画と比較して、選択されなかった期も表示されるよう補正

    await this.toExcel(fileName, (sheet: ExcelJs.Worksheet) => {
      sheet.properties.defaultColWidth = 13
      sheet.views = [{ state: 'frozen', xSplit: 4, ySplit: 0, showGridLines: false, zoomScale: 80 }]
      sheet.addRows([
        [`新規支払いプラン検討（${buildingName}）`], ['\'---------------------------------------------'],
        [`1. ㎡単価の設定額を決定（セルH${this._unitRowsStartRow - 1}など）`], ['2. 計算された積立金年額を表に転記 ※値のみ貼り付け'],
        ['3. 全ての期間に対して1,2を実施'], ['4. 表の収支行が全て黒字になるまで1-3を繰り返す'], ['\'---------------------------------------------'],
        ['請求開始の期など丸1年請求しない期がある場合、'], ['手動でその期の本来の積立額を計算し、表に転記する'], [],
        ['途中で支払いを完了するプランを検討する場合は、'], ['その期間の"支出累計"欄に最終期の支出累計の額を'], ['転記することで、㎡単価の見込み金額を算出できる'], [],
        ['なお金額は参考値であり、資金計画で実際に既定した'], ['最低㎡単価によってはここで計算した設定額だと収支が'], ['赤字になる場合があり、その際は画面での登録に失敗する'],
      ])

      // 住戸タイプの数によって可変になるエリア
      // -- うち、先頭列付近の住戸基本情報のエリア（行指定で設定）
      const unitInfoRows = this._formatUnitInfoRows(reserves.paymentPlan)
      unitInfoRows.forEach((row, rowIdx) => {
        sheet.getRow(this._unitRowsStartRow + rowIdx - 1).values = row // unitInfoRowsは可変行の手前のラベル行から含むため1行減算
      })
      this.borderizeByRange(sheet, 'thin', `A${this._unitRowsStartRow - 1}:C${sheet.rowCount}`)

      // -- うち、期間の選択によって可変になるエリア（列指定で、複数列の塊を繰り返し設定）
      const durationColSets = _durations.map((d, idx) => this._formatDurationColSet(idx + 1, d, initialPeriod, unitRowsCount, sumRowsStartRow))
      durationColSets.forEach((cols, setIdx) => {
        const startCol = this._durationColsStartCol + (setIdx * cols.length)
        cols.forEach((col, colIdx) => { sheet.getColumn(startCol + colIdx).values = col })

        const _1 = this.idxToAlpha(startCol); const _2 = this.idxToAlpha(startCol + 1); const _3 = this.idxToAlpha(startCol + 2)
        this.borderizeByRange(sheet, 'thin', `${_1}5:${_2}6`, `${_3}7:${_3}7`, `${_1}8:${_3}9`, `${_1}11:${_3}15`, `${_1}18:${_3}${this._unitRowsStartRow + unitRowsCount + 1}`)
        this.mergeByRange(sheet, false, `${_1}8:${_2}8`, `${_1}9:${_2}9`, `${_1}11:${_2}11`, `${_1}12:${_2}12`, `${_1}13:${_2}13`, `${_1}14:${_2}14`, `${_1}15:${_2}15`)
        sheet.getCell(`${_3}${this._unitRowsStartRow - 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '92D050' } }
      })

      // 期ごとの合計エリア（行指定で設定）
      const summaryRows = this._formatSummaryRows(reserves.reservePlan, sumRowsStartRow)
      summaryRows.forEach((row, rowIdx) => {
        sheet.getRow(sumRowsStartRow + rowIdx).values = row
        this.borderizeByRange(sheet, 'thin', `A${sumRowsStartRow + rowIdx}:${this.idxToAlpha(row.length)}${sumRowsStartRow + rowIdx}`)
      })
      this.mergeByRange(sheet, true, `A${sumRowsStartRow}:C${sumRowsStartRow}`, `A${sumRowsStartRow + 1}:C${sumRowsStartRow + 2}`, `A${sumRowsStartRow + 4}:C${sumRowsStartRow + 8}`, `A${sumRowsStartRow + 10}:C${sumRowsStartRow + 10}`)

      // 書式設定
      // -- タイトルだけ大きく
      sheet.getCell('A1').font = { size: 16 }

      // -- 収支の累計行が赤字であれば文字色を赤に
      sheet.addConditionalFormatting({
        ref: `E${sumRowsStartRow + 10}:${this.idxToAlpha(sheet.getRow(sumRowsStartRow + 10).cellCount)}${sumRowsStartRow + 10}`,
        rules: [
          { type: 'cellIs', operator: 'lessThan', formulae: [0], priority: 0, style: { font: { color: { argb: 'FFFF0000' } } } }
        ]
      })

      // -- 数値の書式設定. 基本は3桁ごとにカンマ区切りの整数で、面積セルだけ小数点以下が入りうるため異なるフォーマット
      sheet.eachRow(row => { row.numFmt = '#,##0' })
      this.getRange(sheet,
        `A${this._unitRowsStartRow}:A${this._unitRowsStartRow + unitRowsCount - 1}`,
        `C${this._unitRowsStartRow}:C${this._unitRowsStartRow + unitRowsCount + 1}`,
        ...durationColSets.map((_, idx) => { const _c = this.idxToAlpha(this._durationColsStartCol + (idx * _.length)); return `${_c}${this._unitRowsStartRow}:${_c}${this._unitRowsStartRow + unitRowsCount}` })
      )
        .forEach(cell => { cell.numFmt = '#,##0.00' }) // 第二位まで表示
    })
  }

  /**
   * 計画期と指定された期とを比較して、"まとめる期"として出力する期間の一覧を作成する
   * 1-60期、指定5-30,36-40 -> 1,...,4,5-30,31,...,35,36-40,41,42,...,60
   * 5-55期、指定1-30,31-60 -> 5-30,31-55
   */
  private _specifyDurations(selected: Duration[], planStartPeriod: number, planEndPeriod: number) {
    const fill = (s: number, e: number) => Array(e - s + 1).fill(null).map((_, idx) => new Duration(s + idx, s + idx))

    const truncated = selected.filter(s => s.periodFrom <= planEndPeriod && s.periodTo >= planStartPeriod)

    if (truncated.length === 0) { // 期間指定が無ければ、全体を単年の期間と見なす
      return fill(planStartPeriod, planEndPeriod)
    }

    const adjusted: Duration[] = []

    for (const d of truncated) {
      const _s = Math.max(planStartPeriod, d.periodFrom)
      const _e = Math.min(planEndPeriod, d.periodTo)

      const currentLast = adjusted[adjusted.length - 1]?.periodTo ?? (planStartPeriod - 1)
      if (currentLast < _s - 1) {
        // 途中に空白が発生しないよう穴埋め
        adjusted.push(...fill(currentLast + 1, _s - 1))
      }

      adjusted.push(new Duration(_s, _e))
    }

    // 終端の不足があれば補足
    if (adjusted[adjusted.length - 1].periodTo < planEndPeriod) adjusted.push(...fill(adjusted[adjusted.length - 1].periodTo + 1, planEndPeriod))

    return adjusted
  }

  private _formatUnitInfoRows(paymentPlans: PaymentDetail[]): ExcelJs.CellValue[][] {
    const rows: ExcelJs.CellValue[][] = [
      ['面積', '戸数', '合計㎡'],
      ...paymentPlans.map((p, idx) => {
        const _unitRowNum = this._unitRowsStartRow + idx
        return [p.occupiedArea, p.buildingUnitCount, { formula: `$A${_unitRowNum}*$B${_unitRowNum}`, result: undefined, date1904: false }]
      }),
      [
        '合計', { formula: `SUM($B${this._unitRowsStartRow}:$B${this._unitRowsStartRow + paymentPlans.length - 1})`, result: undefined, date1904: false },
        { formula: `SUM($C${this._unitRowsStartRow}:$C${this._unitRowsStartRow + paymentPlans.length - 1})`, result: undefined, date1904: false },
      ],
    ]
    return rows
  }

  private _formatDurationColSet(termNum: number, duration: Duration, initialPeriod: number, unitRowsCount: number, sumRowsStartRow: number): ExcelJs.CellValue[][] {
    const emptyTopRows = [null, null, null] // 3行
    const unitsBaseArray = Array(unitRowsCount).fill(null)

    const baseColumnIdx = this._durationColsStartCol + (termNum - 1) * 5 // 1期間あたり5列
    const secondColAlpha = this.idxToAlpha(baseColumnIdx + 1)
    const thirdColAlpha = this.idxToAlpha(baseColumnIdx + 2)

    const startPeriodCell = `${secondColAlpha}5`
    const endPeriodCell = `${secondColAlpha}6`

    const firstCol = [
      ...emptyTopRows, `期間${termNum}`, '開始期', '終了期', null, '支出累計',
      '【全期間】支出累計', null,
      '積立金累計', '繰越金累計', '受取利息等累計', '収入累計', '【全期間】収入累計', null,
      null, '㎡単価', null,
      ...unitsBaseArray.map((_, idx) => ({ formula: `$A${this._unitRowsStartRow + idx}`, result: undefined })),
      '合計',
      null,
      null
    ]

    const secondCol = [
      ...emptyTopRows, null, duration.periodFrom, duration.periodTo, null, null,
      null, null,
      null, null, null, null, null, null,
      null, '必要金額', { formula: `ROUND((${thirdColAlpha}8-${thirdColAlpha}12-${thirdColAlpha}13)/($C$${this._unitRowsStartRow + unitRowsCount}*12*(${endPeriodCell}-${startPeriodCell}+1)),0)`, result: undefined },
      ...unitsBaseArray.map((_, idx) => ({ formula: `ROUND(${secondColAlpha}$${this._unitRowsStartRow - 1}*$A${this._unitRowsStartRow + idx}, -1)`, result: undefined })),
      { formula: `SUMPRODUCT($B$${this._unitRowsStartRow}:$B$${this._unitRowsStartRow + unitRowsCount - 1}, ${secondColAlpha}$${this._unitRowsStartRow}:${secondColAlpha}$${this._unitRowsStartRow + unitRowsCount - 1})`, result: undefined },
      '差額',
      '平均（/戸）'
    ]

    const thirdCol = [
      ...emptyTopRows, null, null, null, '小計', { formula: `SUM(OFFSET($E$${sumRowsStartRow + 1},0,${startPeriodCell}-${initialPeriod},1,${endPeriodCell}-${startPeriodCell}+1))`, result: undefined },
      { formula: `OFFSET($E$${sumRowsStartRow + 2},0,${endPeriodCell}-${initialPeriod})`, result: undefined }, null,
      { formula: `SUM(OFFSET($E$${sumRowsStartRow + 4},0,${startPeriodCell}-${initialPeriod},1,${endPeriodCell}-${startPeriodCell}+1))`, result: undefined }, // 積立金
      { formula: `SUM(OFFSET($E$${sumRowsStartRow + 5},0,${startPeriodCell}-${initialPeriod},1,${endPeriodCell}-${startPeriodCell}+1))`, result: undefined }, // 繰越金
      { formula: `SUM(OFFSET($E$${sumRowsStartRow + 6},0,${startPeriodCell}-${initialPeriod},1,${endPeriodCell}-${startPeriodCell}+1))`, result: undefined }, // 受取利息等
      { formula: `SUM(OFFSET($E$${sumRowsStartRow + 7},0,${startPeriodCell}-${initialPeriod},1,${endPeriodCell}-${startPeriodCell}+1))`, result: undefined }, // 収入累計
      { formula: `OFFSET($E$${sumRowsStartRow + 8},0,${endPeriodCell}-${initialPeriod})`, result: undefined }/* 【全期間】収入累計 */, null,
      '（単位：円/月）', '設定額', null/* エクセル内で調整する手動の設定額入力欄 */,
      ...unitsBaseArray.map((_, idx) => ({ formula: `ROUND(${thirdColAlpha}$${this._unitRowsStartRow - 1}*$A${this._unitRowsStartRow + idx},-1)`, result: undefined })),
      { formula: `SUMPRODUCT($B$${this._unitRowsStartRow}:$B$${this._unitRowsStartRow + unitRowsCount - 1}, ${thirdColAlpha}$${this._unitRowsStartRow}:${thirdColAlpha}$${this._unitRowsStartRow + unitRowsCount - 1})`, result: undefined },
      { formula: `${thirdColAlpha}${this._unitRowsStartRow + unitRowsCount}-${secondColAlpha}${this._unitRowsStartRow + unitRowsCount}`, result: undefined },
      { formula: `ROUND(${thirdColAlpha}${this._unitRowsStartRow + unitRowsCount}/$B${this._unitRowsStartRow + unitRowsCount},-1)`, result: undefined },
    ]

    const forthCol = [
      // 単月積立金合計の横に、その年額の値を表示する
      ...Array(this._unitRowsStartRow + unitRowsCount - 2).fill(null),
      '↓年額', { formula: `${thirdColAlpha}${this._unitRowsStartRow + unitRowsCount}*12`, result: undefined },
    ]

    return [firstCol, secondCol, thirdCol, forthCol, []] // 5列目は余白用
  }

  private _formatSummaryRows(reservePlans: ReservePlan[], sumRowsStartRow: number): ExcelJs.CellValue[][] {
    const dynamicAreaStartCol = this.idxToAlpha(5) // 以下の通り最初の4列は固定、5列目から可変

    const periodRow: ExcelJs.CellValue[] = [null, null, null, '期'] // 1行目
    const expenseRow: ExcelJs.CellValue[] = ['支出', null, null, '単期'] // 2行目
    const expenseCumlRow: ExcelJs.CellValue[] = [null, null, null, '累計'] // 3行目

    const reserveRow: ExcelJs.CellValue[] = ['収入', null, null, '積立金'] // 5行目
    const carryOverRow: ExcelJs.CellValue[] = [null, null, null, '繰越金'] // 6行目
    const interestRow: ExcelJs.CellValue[] = [null, null, null, '受取利息等'] // 7行目
    const totalIncomeRow: ExcelJs.CellValue[] = [null, null, null, '単期合計'] // 8行目
    const incomeCumlRow: ExcelJs.CellValue[] = [null, null, null, '累計'] // 9行目

    const balanceRow: ExcelJs.CellValue[] = ['収支', null, null, '累計'] // 11行目

    reservePlans.forEach((plan, idx) => {
      const currentCol = periodRow.length + 1
      const currentColAlpha = this.idxToAlpha(currentCol)
      const prevColAlpha = this.idxToAlpha(currentCol - 1)

      // 固定値
      periodRow.push(plan.period)
      expenseRow.push(plan.estimatedCost)
      reserveRow.push(null)
      carryOverRow.push(null)
      interestRow.push(plan.incomeRecord ?? plan.incomePlan)

      // 関数値
      totalIncomeRow.push({ formula: `SUM(${currentColAlpha}${sumRowsStartRow + 4}:${currentColAlpha}${sumRowsStartRow + 6})`, result: undefined, date1904: false })
      balanceRow.push({ formula: `${currentColAlpha}${sumRowsStartRow + 8}-${currentColAlpha}${sumRowsStartRow + 2}`, result: undefined, date1904: false })
      if (idx === 0) {
        expenseCumlRow.push({ formula: `${currentColAlpha}${sumRowsStartRow + 1}`, result: undefined, date1904: false })
        incomeCumlRow.push({ formula: `${currentColAlpha}${sumRowsStartRow + 7}`, result: undefined, date1904: false })
      } else {
        expenseCumlRow.push({ formula: `${currentColAlpha}${sumRowsStartRow + 1}+${prevColAlpha}${sumRowsStartRow + 2}`, result: undefined, date1904: false })
        incomeCumlRow.push({ formula: `${currentColAlpha}${sumRowsStartRow + 7}+${prevColAlpha}${sumRowsStartRow + 8}`, result: undefined, date1904: false })
      }
    })

    // 最終列に各行の集計式を追加
    periodRow.push('総計')
    const lastCol = this.idxToAlpha(periodRow.length - 1)
    expenseRow.push({ formula: `SUM(${dynamicAreaStartCol}${sumRowsStartRow + 1}:${lastCol}${sumRowsStartRow + 1})`, result: undefined, date1904: false })
    expenseCumlRow.push({ formula: `${lastCol}${sumRowsStartRow + 2}`, result: undefined, date1904: false })
    reserveRow.push({ formula: `SUM(${dynamicAreaStartCol}${sumRowsStartRow + 4}:${lastCol}${sumRowsStartRow + 4})`, result: undefined, date1904: false })
    carryOverRow.push({ formula: `SUM(${dynamicAreaStartCol}${sumRowsStartRow + 5}:${lastCol}${sumRowsStartRow + 5})`, result: undefined, date1904: false })
    interestRow.push({ formula: `SUM(${dynamicAreaStartCol}${sumRowsStartRow + 6}:${lastCol}${sumRowsStartRow + 6})`, result: undefined, date1904: false })
    totalIncomeRow.push({ formula: `SUM(${dynamicAreaStartCol}${sumRowsStartRow + 7}:${lastCol}${sumRowsStartRow + 7})`, result: undefined, date1904: false })
    incomeCumlRow.push({ formula: `${lastCol}${sumRowsStartRow + 8}`, result: undefined, date1904: false })
    balanceRow.push({ formula: `${lastCol}${sumRowsStartRow + 10}`, result: undefined, date1904: false })

    return [
      periodRow, expenseRow, expenseCumlRow, [],
      reserveRow, carryOverRow, interestRow, totalIncomeRow, incomeCumlRow, [],
      balanceRow,
    ]
  }
}

export const reservesBldPlanParser = new RervesBldPlanParser()
