import type { QuestionnaireQuestionElementType, QuestionnaireQuestionType, SectionType, TemplateState, TemplateType } from '@/constants/schema-constants'
import { Material } from '@/dtos/commons'
import { Type } from 'class-transformer'

export class QuestionnaireTemplateDetailGetRequest {
  templateId!: string
  constructor(templateId: string) {
    this.templateId = templateId
  }
}

export class QuestionnaireTemplateQuestionElement {
  templateQuestionElementId!: string
  answerPlaceholder?: string
  choice?: string
  sortOrderNum!: number
  elementType!: QuestionnaireQuestionElementType
}

export class QuestionnaireTemplateQuestion {
  templateQuestionId!: string
  questionType!: QuestionnaireQuestionType
  questionSentence!: string
  questionRequired!: boolean
  sortOrderNum!: number
  @Type(() => QuestionnaireTemplateQuestionElement)questionElements: QuestionnaireTemplateQuestionElement[] = []
}

export class QuestionnaireTemplateSection {
  templateSectionId!: string
  sectionType!: SectionType
  sectionTitle?: string
  sectionExplanation?: string
  sortOrderNum!: number
  @Type(() => Material)material?: Material
  @Type(() => QuestionnaireTemplateQuestion)templateQuestions: QuestionnaireTemplateQuestion[] = []
}

export class QuestionnaireTemplateDetailGetResponse {
  templateId!: string
  templateName!: string
  templateType!: TemplateType
  templateState!: TemplateState
  usageNote?: string
  questionnaireTitle!: string
  questionnaireExplanation!: string
  @Type(() => Material)material?: Material
  @Type(() => QuestionnaireTemplateSection)sections: QuestionnaireTemplateSection[] = []
  version!: number
}
