








































































import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import { buildingsModule } from '@/stores/buildings-store'
import { BuildingOwnerDto } from '@/dtos/buildings/owners/get'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class OwnerSelectModal extends Vue {
  headers : Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '112px' }),
    new Header({ text: '氏名', value: 'userName' }),
  ]

  @Model('change')
  private readonly isVisible!: boolean

  @Prop({ default: '選択する' })
  private readonly topRightBtnText!: string

  @Prop({ default: false })
  private readonly singleSelect!: boolean

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!:string

  // 検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!:string

  @Prop({ default: () => [] })
  selectedOwnerUserIds!:string[]

  @Prop({ required: true, default: '' })
  buildingName!:string

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  private get ownerUsers(): BuildingOwnerDto[] {
    return buildingsModule.buildingOwnersGet.owners
  }

  private get _selectedNames(): string | undefined {
    if (!this.isSelected) return
    return this.ownerUsers.filter(e => this.selectedUpdateOwnerUserIds.includes(e.userId)).map(e => `${e.roomNumber} ${e.userName}`).join('、')
  }

  @Watch('isModalVisible', { immediate: true })
  fetchStaffId(isModalVisible: boolean): void {
    if (isModalVisible && this.selectedOwnerUserIds.length > 0) this.selectedUpdateOwnerUserIds = this.selectedOwnerUserIds
  }

  onClickBtn():void {
    this.$emit('select', this.selectedUpdateOwnerUserIds)
    this.isModalVisible = false
  }

  private selectedUpdateOwnerUserIds: string[] = []

  search():void {
    this._keyword = this._inputText
  }

  private get isSelected(): boolean {
    return this.selectedUpdateOwnerUserIds.length > 0
  }
}
