import type { AdminRole, InitialAuthState } from '@/constants/schema-constants'

export class StaffDetailGetRequest {
  adminId!: string

  constructor(adminId: string) {
    this.adminId = adminId
  }
}

export class StaffDetailGetResponse {
  userId!: string
  employeeId!: string
  userName!: string
  role!: AdminRole
  adminShortName?: string
  emailAddress?: string
  initialAuthState?: InitialAuthState
  initialPassword?: string
  authKeyRecipient?: string
  phoneNumber?: string
  isEmailSent!: boolean
  iconImagePath!: string
}
