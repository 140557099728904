var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sm-table-data',{staticClass:"sm__table",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"unitId","content-name":"照合結果","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"405px"},attrs:{"colspan":"3"}},[_c('sm-text',{staticClass:"pl-4",attrs:{"format":"sm__data-header","color":"black","text":"CASYS"}})],1),_c('th',{staticClass:"px-4 sm__table-border-left",staticStyle:{"width":"308px"},attrs:{"colspan":"2"}},[_c('sm-text',{staticClass:"pl-4",attrs:{"format":"sm__data-header","color":"black","text":"smooth-e"}})],1),_c('th',{staticClass:"sm__table-border-left",staticStyle:{"width":"258px"},attrs:{"rowspan":"1"}},[_c('sm-text',{staticClass:"pl-4",attrs:{"format":"sm__data-header","color":"black","text":"修正対応"}})],1)]),_c('tr',[_c('th',{staticStyle:{"width":"80px"}},[_c('sm-text',{staticClass:"pl-4",attrs:{"format":"sm__data-header","color":"black","text":"部屋"}})],1),_c('th',{staticClass:"sm__table-border-left",staticStyle:{"width":"112px"}},[_c('sm-text',{staticClass:"pl-4",attrs:{"format":"sm__data-header","color":"black","text":"顧客コード"}})],1),_c('th',{staticClass:"sm__table-border-left",staticStyle:{"width":"213px"}},[_c('sm-text',{staticClass:"pl-4",attrs:{"format":"sm__data-header","color":"black","text":"顧客名（漢字）"}})],1),_c('th',{staticClass:"sm__table-border-left",staticStyle:{"width":"95px"}},[_c('sm-text',{staticClass:"pl-4",attrs:{"format":"sm__data-header","color":"black","text":"住戸番号"}})],1),_c('th',{staticClass:"sm__table-border-left sm__table-border-left",staticStyle:{"width":"213px"}},[_c('sm-text',{staticClass:"pl-4",attrs:{"format":"sm__data-header","color":"black","text":"CASYS登録氏名"}})],1),_c('th',{staticClass:"sm__table-border-left"})])])]},proxy:true},{key:"item.casysRoomNumber",fn:function(ref){
var item = ref.item;
return [_c('sm-text',{class:{'sm__data-highlight-caution':!_vm.isRegistered(item)},attrs:{"text":item.casysRoomNumber,"color":"black"}})]}},{key:"item.casysOwnerCode",fn:function(ref){
var item = ref.item;
return [_c('sm-text',{class:{'sm__data-highlight-caution':!_vm.isRegistered(item)},attrs:{"text":item.casysOwnerCode,"color":"black"}})]}},{key:"item.casysFullName",fn:function(ref){
var item = ref.item;
return [_c('sm-text',{class:{'sm__data-highlight-caution':!_vm.isRegistered(item)},attrs:{"text":item.casysFullName,"color":"black"}})]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('sm-text',{class:{'sm__data-highlight-caution':_vm.isDiffName(item)},attrs:{"text":item.fullName,"color":"black"}})]}},{key:"item.isUpdated",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[_c('div',{staticClass:"mr-4",staticStyle:{"width":"104px"}},[(_vm.isDiffName(item) && !item.isUpdated && _vm.isVisibleFixBtn)?_c('sm-btn',{staticClass:"my-3",attrs:{"type":"reflection","text":"修正する"},on:{"click-btn":function($event){return _vm.$emit('click-btn-fix', item)}}}):(_vm.isRegistered(item) && item.isUpdated)?_c('sm-text',{staticClass:"my-4",attrs:{"text":"修正済み","color":"black"}}):(!_vm.isRegistered(item))?_c('div',{staticClass:"my-1 d-flex flex-column"},[_c('sm-text',{attrs:{"color":"black","text":"CASYS未登録"}}),_c('sm-text',{staticClass:"mt-2 mr-3",attrs:{"format":"sm__caption","color":"black","level":"2","text":"CASYSに住戸を登録してください"}})],1):_vm._e()],1),_c('sm-btn-text',{attrs:{"color":"secondary","text":"区分所有者詳細","right-icon":"$open_in_new_outlined"},on:{"click":function($event){return _vm.$emit('click-link-owner-detail', item.unitId)}}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }