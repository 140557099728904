

































































import { Vue, Component, Prop } from 'vue-property-decorator'

export class Column {
  /**
   * Rowクラスのプロパティ名（例：rowLabel , period1 , period2 … , itemTotal）がidとなる
   */
  id!: string
  period?: number
  yearMonth?: string
  isCategoryColumn?: boolean
  isTotalColumn?: boolean
}

export class Cell {
  prospect?: number
  performance?: number
  total?: number
}

export class Row {
  rowLabel!: string
  cells?: Record<string, Cell>
  itemTotal?: Cell
  isCategoryRow?: boolean
  isBlueTotalRow?: boolean
  isGrayTotalRow?: boolean
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTableCellMatrix: () => import('@/components/atoms/SmTableCellMatrix.vue'),

    VueScrollingTable: () => import('@/components/external-packages/vue-scrolling-table/VueScrollingTable.vue'),
  }
})
export default class SmTableRepairPlanDetail extends Vue {
  @Prop({ required: true, default: () => [] })
  private readonly columns!: Column[]

  @Prop({ required: true, default: () => [] })
  private readonly rows!: Row[]

  @Prop({ required: true, default: '' })
  private readonly tableHeight!: string

  private getHeaderLabel(column:Column):string {
    if (column.isCategoryColumn) {
      return '修繕工事項目'
    } else if (column.isTotalColumn) {
      return '合計'
    }

    return column.period + '期\n' + column.yearMonth
  }
}
