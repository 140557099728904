










































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class SmCardSectionData extends Vue {
  @Prop({ required: true, default: '' })
  private readonly sectionTitle!: string

  @Prop({ default: '項目' })
  private readonly sectionTitleLabel!: string

  @Prop()
  private readonly sectionTitlePlaceholder?: string

  @Prop({ required: true, default: '' })
  private readonly sectionBody!: string

  @Prop({ default: '内容' })
  private readonly sectionBodyLabel!: string

  @Prop()
  private readonly sectionBodyPlaceholder?: string

  @Prop({ default: '' })
  private readonly fieldIdPrefix!: string

  @Prop({ default: false })
  private readonly head!: boolean

  @Prop({ default: false })
  private readonly foot!: boolean

  @Prop({ default: false })
  private readonly deletable!: boolean

  private get _sectionTitle(): string | undefined { return this.sectionTitle }
  private set _sectionTitle(newValue: string | undefined) {
    this.$emit('update:sectionTitle', newValue)
  }

  private get _sectionBody(): string | undefined { return this.sectionBody }
  private set _sectionBody(newValue: string | undefined) {
    this.$emit('update:sectionBody', newValue)
  }
}
