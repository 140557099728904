import { staticKeyProvider } from './static-key-provider'

/**
 * deepCopyに必要な、項目名とクラスの1つの対応を表すためのクラス
 */
export class ColumnToType<T> {
  public readonly TypeConstructor: { new (): T }

  public readonly needKey: boolean

  /**
   * @param cons コンストラクタ
   * @param needKey static-key-provider により _key を与えるか否か
   */
  constructor(cons: { new (): T }, needKey = false) {
    this.TypeConstructor = cons
    this.needKey = needKey
  }
}

/**
 * クラスオブジェクトを、型の情報を保ってディープコピーします。
 * 第2引数に、型の情報を保持したい項目名とクラスの対応を与えてください。
 *
 * class Ppp {
 *   q: Qqq
 *   r: string
 * }
 *
 * class Qqq {
 *   s: Sss
 *   t: Ttt
 * }
 *
 * => {
 *   this: Ppp, // 最も上位のクラス。この項目名を第3引数で与える
 *   q: Qqq,
 *   s: Sss, // 孫要素以降もフラットに定義してよい
 *   // t: Ttt // 定義を省略した項目の型はobjectになる
 * }
 *
 *
 * @param base コピーしたいオブジェクト
 * @param columnToTypes 項目名とクラスの対応
 * @param parentKey 第2引数のオブジェクトで、コピーするオブジェクトのクラスを指す項目名
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepCopy = <T>(base: T, columnToTypes: { [key: string]: ColumnToType<any> }, parentKey: string): T => {
  let current

  switch (typeof base) {
    case 'object':
      if (base === null) {
      // null => null
        current = null
      } else if (Array.isArray(base)) {
        current = base.map(_ => deepCopy(_, columnToTypes, parentKey))
      } else {
        switch (toString.call(base)) {
          case '[object Date]':
            current = new Date(base as unknown as Date)
            break
          case '[object RegExp]':
            current = new RegExp(base as unknown as RegExp)
            break
          default: {
            const toType = columnToTypes[parentKey]
            const initial = toType
              ? (toType.needKey ? staticKeyProvider.create(toType.TypeConstructor) : new toType.TypeConstructor())
              : {}

            current = Object.entries(base).reduce(function(prev, [key, value]) {
              prev[key] = deepCopy(value, columnToTypes, key)
              return prev
            }, initial)
            break
          }
        }
      }
      break
    default:
      current = base // is a primitive
      break
  }
  return current
}
