import { Type } from 'class-transformer'

export class CasysUnitsMaterial {
  materialId!: string
}

export class CasysUnitsPostRequest {
  fileCount: number
  @Type(() => CasysUnitsMaterial) material: CasysUnitsMaterial

  constructor(fileCount: number, material: CasysUnitsMaterial) {
    this.fileCount = fileCount
    this.material = material
  }
}

export class CasysUnitsPostResponse {
  casysDifferenceDetectionBatchStatusId!: string
  adminName!: string
  fileCount!: number
  uploadedAt!: string
}
