import { Type } from 'class-transformer'

export class CasysDetectionDetailGetRequest {
  skip: number
  take: number
  casysDifferenceDetectionBatchStatusId: string

  constructor(skip: number, take:number, casysDifferenceDetectionBatchStatusId: string) {
    this.skip = skip
    this.take = take
    this.casysDifferenceDetectionBatchStatusId = casysDifferenceDetectionBatchStatusId
  }
}

export class Difference {
  casysDifferenceOwnerId!: string
  unitId!: string
  roomNumber!: string
  fullName!: string
  casysRoomNumber?: string
  casysOwnerCode?: string
  casysFullName?: string
  isUpdated!: boolean
}

export class Building {
  casysDifferenceBuildingId!: string
  buildingId?: string
  condominiumCd!: string
  buildingName!: string
  existsAtSmoothe!: boolean
  @Type(() => Difference) differences: Difference[] = []
}

export class CasysDetectionDetailGetResponse {
  casysDifferenceDetectionBatchStatusId!: string
  uploadedAt!: string
  userName!: string
  fileCount!: number
  differenceCount!: number
  @Type(() => Building) buildings: Building[] = []
}
