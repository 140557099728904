import { Material } from '@/dtos/commons'

export class TicketCommentsPutRequest {
  ticketId: string
  ticketCommentId: string
  ticketCommentBody: string
  material?: Material
  version: number

  constructor(ticketId: string, ticketCommentId: string, ticketCommentBody: string, version: number, material?: Material) {
    this.ticketId = ticketId
    this.ticketCommentId = ticketCommentId
    this.ticketCommentBody = ticketCommentBody
    this.material = material
    this.version = version
  }
}

export class TicketCOmmentsPutResponse {
  version!: number
}
