












































































import { Material, MaterialFormInputDto } from '@/dtos/commons'
import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { MaterialType, MATERIAL_TYPES } from '@/constants/schema-constants'
import type { UploadedFile } from '@/components/molecules/SmMaterialInputBase.vue'
import { blobUrlModule } from '@/stores/blob-url-store'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmMaterialInputBase: () => import('@/components/molecules/SmMaterialInputBase.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class SmMaterialInput extends Vue {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
  ALWAYS_EMPTY = null

  @Prop({ default: null })
  @Model('change')
  material?: MaterialFormInputDto | null

  @Prop({ default: () => [MATERIAL_TYPES.IMAGE, MATERIAL_TYPES.VIDEO, MATERIAL_TYPES.PDF] })
  accepts!: MaterialType[]

  @Prop({ default: false })
  required!: boolean

  @Prop()
  fieldIdPrefix?: string

  @Prop({ default: 'ファイルを添付' })
  text!: string

  get _material(): Material | null {
    const m = this.material // short name
    if (!m) return null

    // キャプションを除いた情報を表示に用いる
    const display: Partial<Material> = { materialId: m.materialId, materialUrl: m.materialUrl, materialType: m.materialType, originalFileName: m.originalFileName }
    return Object.assign(new Material(), display)
  }

  errorMessage: string | null = null

  onFileSelected(selected: UploadedFile): void {
    this.errorMessage = null
    const newData: Partial<MaterialFormInputDto> = { ...selected, originalFileName: selected.file?.name, caption: this.material?.caption }
    this.$emit('change', Object.assign(new MaterialFormInputDto(), newData))
  }

  onSelectFailed(): void {
    this.errorMessage = '選択されたファイルの形式をサポートしていません。'
  }

  onClickDelete(): void {
    if (this.required) this.errorMessage = 'ファイルの選択は必須です。'
    if (this.material?.materialUrl) blobUrlModule.deleteBlobUrl(this.material?.materialUrl)
    this.$emit('change', null)
  }

  onFileNameInput(newText: string): void {
    const newData: Partial<MaterialFormInputDto> = { ...this.material, originalFileName: newText }
    if (!this.material?.originalFileNameInitialCopy) newData.originalFileNameInitialCopy = this.material?.originalFileName

    this.$emit('change', Object.assign(new MaterialFormInputDto(), newData))
  }
}
