

















import { Component, Vue } from 'vue-property-decorator'
import { generateUuid } from '@/libs/uuid-generator'
import { questionnairesModule } from '@/stores/questionnaires-store'
import { QuestionQuestionGetRequest } from '@/dtos/questionnaires/questions/get'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    QuestionnaireQuestionDetailModal: () => import('@/components/organisms/modal/QuestionnaireQuestionDetailModal.vue'),
  }
})
export default class HelloWorldPage2 extends Vue {
  isQuestionnaireQuestionDetailModalVisible = false
  questionnaireQuestionDetailModalKey = generateUuid()
  questionnaireId = '73dc985e-bb7c-4b34-9417-a71258eae788'
  questionnaireQuestionId = 'f21cf642-1abb-408c-a25c-98b3e4b99970'
  answerKeyword = ''
  answerInputText = ''
  async openQuestionnaireQuestionDetailModal():Promise<void> {
    const request = new QuestionQuestionGetRequest(this.questionnaireId, this.questionnaireQuestionId)
    await questionnairesModule.getQuestionnaireQuestionDetail(request)
    this.isQuestionnaireQuestionDetailModalVisible = true
  }
}
