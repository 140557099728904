






































































import { Component, Prop, Model, Mixins } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableData.vue'
import type { TableItemInterface } from '@/components/molecules/SmTableData.vue'
import { Building } from '@/dtos/buildings/get'
import { buildingsModule } from '@/stores/buildings-store'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'

class BuildingTableItemDto extends Building implements TableItemInterface {
    hideItem = false
    checkboxDisabled = false
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class BuildingSelectModal extends Mixins(CurrentAdminManager) {
  @Model('change', { required: true })
  private readonly isVisible!: boolean

  @Prop()
  private readonly topRightBtnText!: string

  @Prop({ default: '表示するマンションを選択してください' })
  private readonly modalTitle!:string

  @Prop({ default: () => [] })
  private readonly selectedBuildings!:Building[]

  @Prop({ default: false })
  private readonly singleSelect!:boolean

  @Prop({ default: '' })
  inputText!:string

  @Prop({ default: '' })
  keyword!:string

  private _selectedBuildingIds:string[] = []
  private get _selectedBuildingNames(): string | undefined {
    if (!this.isSelected) return
    return this.buildings.filter(e => this.$data._selectedBuildingIds.includes(e.buildingId)).map(e => e.buildingName).join('｜')
  }

  headers : Header[] = [
    new Header({ text: 'MaNo.', value: 'condominiumCd', width: '200px' }),
    new Header({ text: 'マンション名', value: 'buildingName' }),
  ]

  created(): void {
    this.$data._selectedBuildingIds = this.selectedBuildings.map(e => e.buildingId)
  }

  private get buildings(): BuildingTableItemDto[] {
    const buildings = buildingsModule.buildingsGet.buildings
    if (!buildings) return []
    return buildings.map(b => {
      const dto = new BuildingTableItemDto()
      dto.buildingId = b.buildingId
      dto.condominiumCd = b.condominiumCd
      dto.buildingName = b.buildingName
      dto.hideItem = false
      return dto
    })
  }

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  onClickBtn():void {
    this.$emit('select', this.$data._selectedBuildingIds)
    this.isModalVisible = false
  }

  search():void {
    this._keyword = this._inputText
  }

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  private get isSelected(): boolean {
    return this.$data._selectedBuildingIds.length > 0
  }
}
