import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyLocalStoreInitialized, store } from './index'
import type { TransitionParams } from '@/dtos/transition'

/**
 * ローカルストレージにパラメータを保持させたい場合に、その保管先として用いるストア
 */
@Module({ store, dynamic: true, namespaced: true, name: 'localParamStorage', preserveState: isAlreadyLocalStoreInitialized })
class LocalParamStorageStore extends VuexModule {
  // メールリンクからの遷移時のパラメータ
  private _transitionParams : TransitionParams | null = null

  get transitionParams():TransitionParams | null { return this._transitionParams }

  @Mutation
  private SET_TRANSITION_PARAMS(newValue:TransitionParams | null) { this._transitionParams = newValue }

  @Action
  public setTransitionParams(newValue:TransitionParams) { this.SET_TRANSITION_PARAMS(newValue) }

  @Action
  public clearTransitionParams() { this.SET_TRANSITION_PARAMS(null) }

  // ログイン画面のエラー文言（リダイレクトを挟む兼ね合いでローカルストレージに格納）
  private _loginErrorMessage: string | null = null

  get loginErrorMessage():string | null { return this._loginErrorMessage }

  @Mutation
  private SET_LOGIN_ERROR_MESSAGE(message:string | null) { this._loginErrorMessage = message }

  @Action
  public setLoginErrorMessage(message:string) {
    this.SET_LOGIN_ERROR_MESSAGE(message)
  }

  @Action
  public resetLoginErrorMessage() {
    this.SET_LOGIN_ERROR_MESSAGE(null)
  }

  // 「利用中画面を見る」確認ダイアログの表示／非表示
  private _dontShowSimulateConfirmationDialog = false

  get dontShowSimulateConfirmationDialog(): boolean { return this._dontShowSimulateConfirmationDialog }

  @Mutation private SET_DONT_SHOW_SIMULATE_CONFIRMATION_DIALOG(dontShowSimulateConfirmationDialog: boolean): void {
    this._dontShowSimulateConfirmationDialog = dontShowSimulateConfirmationDialog
  }

  @Action putDontShowSimulateConfirmationDialog(dontShowSimulateConfirmationDialog: boolean): void {
    this.SET_DONT_SHOW_SIMULATE_CONFIRMATION_DIALOG(dontShowSimulateConfirmationDialog)
  }
}

export const localParamStorageModule = getModule(LocalParamStorageStore)
