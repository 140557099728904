





















import { Vue, Component } from 'vue-property-decorator'
import { SimpleRepairsPlansDetailGetResponse } from '@/dtos/simple-repairs/plans/get'
import { simpleRepairsModule } from '@/stores/simple-repairs-store'

@Component({
  components: {
    SmTextPrintSizeAnnounce: () => import('@/components/atoms/SmTextPrintSizeAnnounce.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    SimpleRepairPlanConstructionSubPrintPage: () => import('@/pages/simple-repair-plan/detail/construction/SimpleRepairPlanConstructionSubPrintPage.vue'),
  }
})
export default class SimpleRepairPlanConstructionPrintPage extends Vue {
  async created():Promise<void> {
    await simpleRepairsModule.fetchSimpleRepairsDetail()
  }

  private get simpleRepairsDetail():SimpleRepairsPlansDetailGetResponse {
    return simpleRepairsModule.simpleRepairsDetailGet
  }
}
