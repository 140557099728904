










































































































































































































































































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { concatSerialChar } from '@/libs/add-alphabet-provider'
import { adminIdeasModule } from '@/stores/admin-ideas-store'
import { buildingsModule } from '@/stores/buildings-store'
import { AdminIdeaDetailGetRequest, AdminIdeaDetailGetResponse } from '@/dtos/ideas/admin-idea/get-detail'
import { AdminIdeaUpdatesGetRequest, AdminIdeaUpdatesGetResponse } from '@/dtos/ideas/admin-idea/updates/get'
import { MATERIAL_TYPES } from '@/constants/schema-constants'

const TAKE = 999

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),
    SmTextPrintSizeAnnounce: () => import('@/components/atoms/SmTextPrintSizeAnnounce.vue'),

    SmListBudgetPrint: () => import('@/components/molecules/list/SmListBudgetPrint.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmPrintLabel: () => import('@/components/molecules/SmPrintLabel.vue'),

    SmPrintHeader: () => import('@/components/organisms/SmPrintHeader.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class DetailPrintPage extends Mixins(CurrentAdminManager) {
  @Prop({ required: true, default: '' })
  private readonly ideaId!:string

  planTitleOverLimitChar = false

  async created():Promise<void> {
    // 詳細情報取得
    await adminIdeasModule.fetchIdeaDetail(new AdminIdeaDetailGetRequest(this.ideaId))

    // 更新情報取得
    const req = new AdminIdeaUpdatesGetRequest()
    req.take = TAKE
    req.ideaId = this.ideaId
    await adminIdeasModule.fetchIdeaUpdates(req)
  }

  /**
   * 取得する日付から時刻を削除する
   */
  private get sliceProcessedAt(): string {
    if (!this.detail.processedAt) return ''
    const processedAt: string = this.detail.processedAt
    return processedAt.slice(0, 14)
  }

  // TODO データを取得できなかったケースの実装
  private get detail(): AdminIdeaDetailGetResponse {
    return adminIdeasModule.detailResponse(this.ideaId) ?? new AdminIdeaDetailGetResponse()
  }

  private get updateInfo(): AdminIdeaUpdatesGetResponse {
    return adminIdeasModule.adminIdeasUpdatesGet(this.ideaId) ?? new AdminIdeaUpdatesGetResponse()
  }

  private get myCurrentBuilding():string | undefined {
    return buildingsModule.buildingDetailGet?.buildingName
  }

  // プランが4つ以上ある場合、改ページを入れる
  private get pageBreak(): boolean {
    if (this.detail.plans.length >= 4) return true
    return false
  }

  // 掲載期日を時間なし（YYYY年MM月DD日（曜））で表示
  private get deadline(): string {
    return this.detail.deadline?.substring(0, 14)
  }

  private getPlanTitleWithPrefix(planTitle:string, index:number):string {
    for (const plan of this.detail.plans) {
      if (plan.itemTitle.length >= 39) {
        this.planTitleOverLimitChar = true
        break
      }
    }
    if (this.planTitleOverLimitChar) return planTitle
    const prefix = concatSerialChar(this.detail.plans.length, index)
    const planTitleWithPrefix = `【プラン${prefix}】${planTitle}`
    return planTitleWithPrefix
  }

  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
}
