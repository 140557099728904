



































































import { Component, Prop, Model, Mixins } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'

export class Period {
  fromDate: string | null | undefined = null
  toDate: string | null | undefined = null
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})

export default class PeriodSelectModal extends Mixins(CurrentAdminManager) {
  @Model('change', { required: true })
  private readonly isVisible!: boolean

  @Prop({ required: true, default: () => [] })
  private readonly selectedAccrualDate!:Period

  @Prop({ required: true, default: () => [] })
  private readonly selectedDeadline!:Period

  accrualDate:Period = new Period()
  deadline:Period = new Period()

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  created(): void {
    this.accrualDate.fromDate = this.selectedAccrualDate.fromDate
    this.accrualDate.toDate = this.selectedAccrualDate.toDate
    this.deadline.fromDate = this.selectedDeadline.fromDate
    this.deadline.toDate = this.selectedDeadline.toDate
  }

  onClickBtn():void {
    this.$emit('change-period', this.accrualDate, this.deadline)
    this.isModalVisible = false
  }
}
