import { PaymentDetail } from '@/dtos/reserve-funds/get'
import { Type } from 'class-transformer'

export class PaymentPlanDetailInitialForm {
  period!:number
  accountingMonth!:string
  targetYear!:number
  minUnitPrice!:number
}

export class PaymentPlanDetailEdit extends PaymentPlanDetailInitialForm {
  unitPrice!:number
}

export class PaymentPlanInitialFormGetResponse {
  planCode!:string
  @Type(() => PaymentPlanDetailInitialForm)paymentPlanDetail:PaymentPlanDetailInitialForm[] = []
  @Type(() => PaymentDetail)paymentDetail:PaymentDetail[] = []
}
