export class StaffDeleteRequest {
  adminId!: string

  constructor(adminId: string) {
    this.adminId = adminId
  }
}

export class LifeManagerDeleteRequest {
  adminId!: string

  constructor(adminId: string) {
    this.adminId = adminId
  }
}
