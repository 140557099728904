








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SmProgressCircular extends Vue {
  @Prop({ default: '40' })
  private readonly size!: string | number
}
