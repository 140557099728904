































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js'
import { UnitPaymentPlan } from '@/dtos/reserve-funds/payments/pdf/get'
import { RESERVE_PLAN_GRAPH_LINES } from '@/constants/ux-constants'

@Component({
  components: {
    SmLegend: () => import('@/components/atoms/SmLegend.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmChart: () => import('@/components/molecules/SmChart.vue'),
  }
})
export default class SmChartReservesPayments extends Vue {
  @Prop()
  unitPaymentPlan!: UnitPaymentPlan

  private get chartData(): ChartData | undefined {
    // 選択した納入プラン
    const datasets:ChartDataSets[] = this.unitPaymentPlan.reserveFund.prospectivePlansAmounts.map((plan, index) => {
      return {
        type: 'line',
        label: `${String.fromCharCode('A'.charCodeAt(0) + index)}プラン`,
        data: plan.amounts.map(e => e.amount),
        fill: false,
        borderColor: RESERVE_PLAN_GRAPH_LINES[index].color,
        borderWidth: 2,
        borderJoinStyle: 'miter',
        cubicInterpolationMode: 'monotone',
        pointRadius: 0, // 線上の丸点（ポイント）を非表示にしたい（他にやり方あるかも）
        borderDash: [1 + index, 2], // 実線ではなく点線に変更
      }
    })
    datasets.push(
      {
        type: 'bar',
        label: '最低納入額（累計）',
        data: this.unitPaymentPlan.reserveFund.minimumAmounts.map(e => e.amount),
        fill: false,
        lineTension: 0,
        backgroundColor: '#A1ABAE',
      },
    )
    if (this.unitPaymentPlan.reserveFund.currentPlanAmounts) {
      datasets.push(
        {
          type: 'line',
          label: '現在の納入プラン',
          data: this.unitPaymentPlan.reserveFund.currentPlanAmounts.map(e => e.amount),
          fill: false,
          borderColor: '#000000',
          borderWidth: 2,
          borderJoinStyle: 'miter',
          cubicInterpolationMode: 'monotone',
          pointRadius: 0, // 線上の丸点（ポイント）を非表示にしたい（他にやり方あるかも）
        }
      )
    }
    return {
      labels: this.unitPaymentPlan.reserveFund.minimumAmounts.map(e => e.period),
      datasets: datasets
    }
  }

  commaFormatter = new Intl.NumberFormat('en')

  chartOptions: ChartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false, // マウスオーバー時のツールチップを非表示にする
    },
    // 凡例はchart外で表示
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false, // 縦の目盛り線を非表示に
        },
        ticks: {
          maxRotation: 0, // 自動的に回転する角度を固定（これがないと斜め表示になったりする）
          minRotation: 0,
          maxTicksLimit: 12, // 表示される目盛り数の最大
        },
      }],
      yAxes: [{
        position: 'left',
        scaleLabel: {
          display: false, // 凡例はSmTextで埋め込み
        },
        ticks: {

          // グラフの軸ラベルを任意の値に変更
          callback: function(label:number):string {
            return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') // 「XXX,XXX」 表記
          },
        },
      }],
    }
  }

  RESERVE_PLAN_GRAPH_LINE = Object.freeze(RESERVE_PLAN_GRAPH_LINES)
}
