













































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IDEA_STATES } from '@/constants/schema-constants'
import type { IdeaState } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import type { ChipColors } from '@/constants/ux-constants'

class StateContent {
  public readonly stateType: string
  public readonly chipText: string
  public readonly chipColor: ChipColors

  constructor(stateType: string, chipText: string, chipColor: ChipColors) {
    this.stateType = stateType
    this.chipText = chipText
    this.chipColor = chipColor
  }
}

const ideaStateContents: Record<IdeaState, StateContent> = {
  [IDEA_STATES.OWNER.DRAFT]: new StateContent('ownerIdea', '下書き', CHIP_COLORS.GREEN),
  [IDEA_STATES.OWNER.POSTED]: new StateContent('ownerIdea', 'アイデア', CHIP_COLORS.WHITE2),
  [IDEA_STATES.OWNER.ADOPTED]: new StateContent('ownerIdeaArchive', 'アーカイブ', CHIP_COLORS.WHITE2),
  [IDEA_STATES.OWNER.REJECTED]: new StateContent('ownerIdeaArchive', 'アーカイブ', CHIP_COLORS.WHITE2),
  [IDEA_STATES.OWNER.DELETED_SELF]: new StateContent('ownerIdea', '削除済み', CHIP_COLORS.WHITE2),
  [IDEA_STATES.OWNER.DELETED]: new StateContent('ownerIdea', '削除済み', CHIP_COLORS.WHITE2),
  [IDEA_STATES.ADMIN.DRAFT]: new StateContent('adminIdea', '下書き', CHIP_COLORS.GREEN),
  [IDEA_STATES.ADMIN.ACCEPTING_OPINION]: new StateContent('adminIdea', 'プラン／意見受付中', CHIP_COLORS.WHITE2),
  [IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT]: new StateContent('adminIdeaAgreement', 'プラン／賛同受付中', CHIP_COLORS.WHITE2),
  [IDEA_STATES.ADMIN.COUNTING]: new StateContent('adminIdeaAgreement', 'プラン／賛同数集計中', CHIP_COLORS.WHITE2),
  [IDEA_STATES.ADMIN.RELEASED]: new StateContent('adminIdeaArchive', 'アーカイブ', CHIP_COLORS.WHITE2),
}

@Component({
  components: {
    SmBadgeContent: () => import('@/components/atoms/SmBadgeContent.vue'),
    SmBadgeRead: () => import('@/components/atoms/SmBadgeRead.vue'),

    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmCardIdea extends Vue {
  private IDEA_STATES = Object.freeze(IDEA_STATES)

  @Prop({
    required: true,
    validator: s => s in ideaStateContents
  })
  private ideaState!: IdeaState

  @Prop({ default: false })
  private isUnread!: boolean

  @Prop({ required: true, default: '' })
  private title!: string

  @Prop()
  private deadline?: string

  @Prop()
  private opinionDeadline?: string

  // readCountを指定すると以降のカウントがカードに表示がされる
  @Prop()
  private readCount?: number

  @Prop()
  private planCount?: number

  @Prop()
  private agreedOwnerCount?: number

  @Prop()
  private opinionCount?: number

  private get stateContent(): StateContent | undefined {
    return ideaStateContents[this.ideaState]
  }

  private get isDisplayUnread(): boolean {
    return this.isUnread &&
      (this.ideaState === IDEA_STATES.OWNER.POSTED ||
       this.ideaState === IDEA_STATES.OWNER.DELETED_SELF ||
       this.ideaState === IDEA_STATES.OWNER.DELETED)
  }

  private get isDisplayStatus(): boolean {
    return (this.ideaState === IDEA_STATES.OWNER.DRAFT ||
       this.ideaState === IDEA_STATES.OWNER.ADOPTED ||
       this.ideaState === IDEA_STATES.OWNER.REJECTED ||
       this.ideaState === IDEA_STATES.OWNER.DELETED_SELF ||
       this.ideaState === IDEA_STATES.OWNER.DELETED ||
       this.ideaState === IDEA_STATES.ADMIN.DRAFT ||
       this.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION ||
       this.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT ||
       this.ideaState === IDEA_STATES.ADMIN.COUNTING ||
       this.ideaState === IDEA_STATES.ADMIN.RELEASED)
  }

  private get isDisplayDeadline(): boolean {
    return (this.deadline !== undefined && this.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT) || (this.opinionDeadline !== undefined && this.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION)
  }

  private get deadlineContext(): string {
    return this.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT ? `賛同締切日時：${this.deadline}` : `意見締切日時：${this.opinionDeadline}`
  }
}
