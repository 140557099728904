



































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'
import type { ChartType, QuestionnaireQuestionType } from '@/constants/schema-constants'
import { QuestionnaireSummaryDto } from '@/dtos/questionnaires/reports/get'
import { assertExhaustive } from '@/libs/exhaustive-helper'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmQuestionnairesReportsQuestionAnswerText extends Vue {
  @Prop({ default: '' })
  questionnaireReportImplementationResultQuestionId!: string

  @Prop({ default: 0 })
  sortOrderNum!: number

  @Prop({ default: '' })
  questionBody!: string

  @Prop({ default: QUESTIONNAIRE_QUESTION_TYPES.FREE })
  questionType!: QuestionnaireQuestionType

  @Prop({ required: false, default: undefined })
  chartType?: ChartType

  @Prop({ default: () => [] })
  questionnairesSummary?: QuestionnaireSummaryDto[]

  private get questionTypeText(): string {
    switch (this.questionType) {
      case QUESTIONNAIRE_QUESTION_TYPES.FREE: return '' // 自由入力は報告書に出力されない
      case QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE: return `${this.sortOrderNum}. 設問(単一選択式)`
      case QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES: return `${this.sortOrderNum}. 設問(複数選択式)`
      default: return assertExhaustive(this.questionType)
    }
  }

  private deadline(deadline?:string): string { return deadline ? `${deadline.substring(0, 4)}/${deadline.substring(5, 7)}/${deadline.substring(8, 10)}` : '' }

  private getPercentage(answerCount: number, totalCount: number): number {
    return totalCount ? Math.round(answerCount / totalCount * 100) : 0
  }
}
