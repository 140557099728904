import { PaymentPlanDetail } from '@/dtos/reserve-funds/plans/post'
import { Type } from 'class-transformer'

export class PersonalPlansPostRequest {
  buildingUnitId!: string
  planName!: string
  appliedAt!: string
  recordedReservedAmount!: number
  @Type(() => PaymentPlanDetail) paymentPlanDetail: PaymentPlanDetail[] = []
}
