









































































































































































import { ReservePlan } from '@/dtos/reserve-funds/get'
import { Vue, Component, Model, Prop } from 'vue-property-decorator'

export const INPUT_TARGET = {
  INCOME_PLAN: 1,
  UNIT_PRICE: 2,
} as const

export type InputTarget = typeof INPUT_TARGET[keyof typeof INPUT_TARGET]
class Income {
  amount?:number
  isRecorded:boolean

  constructor(reservePlan:ReservePlan) {
    if (reservePlan.incomeRecord !== undefined) {
      this.amount = reservePlan.incomeRecord
      this.isRecorded = true
    } else {
      this.amount = reservePlan.incomePlan
      this.isRecorded = false
    }
  }
}

@Component({
  components: {
    SmBadgeInline: () => import('@/components/atoms/SmBadgeInline.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class ReservePlanTable extends Vue {
  @Model('input')
  reservePlans!:ReservePlan[]

  @Prop({ default: false })
  edit!:boolean

  @Prop({ required: true, default: () => [] })
  totalPeriodReserves!:number[]

  @Prop({ default: 1 })
  billingPeriod!: number

  private get _reservePlans():ReservePlan[] { return JSON.parse(JSON.stringify(this.reservePlans)) }

  income(reservePlan:ReservePlan):Income {
    return new Income(reservePlan)
  }

  private get totalIncom(): number[] {
    let total = 0
    return this._reservePlans.map(m => {
      if (m.incomePlan) total += m.incomePlan
      return total
    })
  }

  private get totalEstimateCost(): number[] {
    let total = 0
    return this._reservePlans.map(m => {
      if (m.estimatedCost) total += m.estimatedCost
      return total
    })
  }

  INPUT_TARGET = Object.freeze(INPUT_TARGET)
}
