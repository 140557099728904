import { AnswersPostRequest } from '@/dtos/resolutions/questions/answers/post'

export class AnswersPutRequest extends AnswersPostRequest {
  version!: number

  constructor(resolutionId:string, questionId:string, answerBody:string, version:number) {
    super(resolutionId, questionId, answerBody)
    this.version = version
  }
}

export class AnswersPutResponse {
  version!: number
}
