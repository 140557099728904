




































































































import { Mixins, Component, Watch } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import type { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'
import { SimpleRepairsUpdatesCommentsGetRequest, Comment } from '@/dtos/simple-repairs/updates/comments/get'
import { SimpleRepairsUpdatesCommentsDeleteRequest } from '@/dtos/simple-repairs/updates/comments/delete'
import { errorsModule } from '@/stores/errors'
import { simpleRepairsModule } from '@/stores/simple-repairs-store'

const SKIP = 0
const TAKE = 20

const MENU_ITEMS: {[id: string]: { text: string, label: string }} = {
  desc: {
    text: '投稿日が新しい順',
    label: '投稿日が新しい順'
  },
  asc: {
    text: '投稿日が古い順',
    label: '投稿日が古い順'
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    SmListRepairPlanUpdatesComment: () => import('@/pages/repair-plan/repair-plan-comments/SmListRepairPlanUpdatesComment.vue'),
  }
})
export default class SimpleRepairPlanCommentsPage extends Mixins(CurrentAdminManager) {
  created(): void { this.refreshList() }

  refreshList(): void {
    simpleRepairsModule.clearSimpleRepairsUpdatesComments()
    this.request.sortOrder = this.menuId
    this.identifier++
  }

  private get repairsUpdatesComments(): Comment[] { return simpleRepairsModule.simpleRepairsUpdatesCommentsGet }

  identifier = 1
  request = new SimpleRepairsUpdatesCommentsGetRequest(SKIP, TAKE)
  handler: LoadingHandler | null = null
  async loadComments(handler: LoadingHandler): Promise<void> {
    this.handler = handler
    // グローバルエラーとフィールドエラーをクリアする
    if (this.hasErrors) {
      errorsModule.clearGlobalErrors()
      errorsModule.clearAllFieldError()
    }

    this.request.skip = this.repairsUpdatesComments.length
    await simpleRepairsModule.fetchSimpleRepairsUpdatesComments(this.request)

    if (this.repairsUpdatesComments.length === 0) {
      // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
      handler.complete()
      return
    }

    handler.loaded()
    const expectingToBe = this.request.skip + this.request.take
    if (this.repairsUpdatesComments.length < expectingToBe) handler.complete()
  }

  inputKeyword = ''

  // 絞り込みを行っているかの判定（0件時のメッセージの判定に使用、trueならば絞り込みを行っていない）
  isDefaultCondition = true

  onSearchKeyword(): void {
    this.request.keyword = this.inputKeyword
    if (this.inputKeyword) {
      this.isDefaultCondition = false
    } else {
      this.isDefaultCondition = true
    }
    this.refreshList()
  }

  menuId = 'desc'
  menuItems = MENU_ITEMS

  targetCommentId = ''
  isExecuteDialogVisible = false

  openExecuteDialog(commentId: string): void {
    this.targetCommentId = commentId
    this.isExecuteDialogVisible = true
  }

  async deleteComment(): Promise<void> {
    this.isExecuteDialogVisible = false
    await simpleRepairsModule.deleteSimpleRepairsUpdatesComments(new SimpleRepairsUpdatesCommentsDeleteRequest(this.targetCommentId))
    this.refreshList()
  }

  goToRepairPlanCommentsCreate(): void {
    this.$router.push({ name: staticRoutes.simpleRepairPlanCommentsCreate.name })
  }

  goToRepairPlanCommentsUpdate(commentId: string): void {
    this.$router.push({ name: staticRoutes.simpleRepairPlanCommentsUpdate.name, params: { commentId } })
  }

  // --------------- データ読み込みでエラーが発生した際の処理 ---------------
  private get hasErrors(): boolean { return errorsModule.hasErrors }

  @Watch('hasErrors', { immediate: false, deep: false })
  private onLoadError(hasErrors: boolean): void {
    if (!hasErrors) return

    this.handler?.complete()
  }
}
