





































































































import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator'
import { generateUuid } from '@/libs/uuid-generator'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { Header } from '@/components/molecules/SmTableData.vue'
import { ServiceStaffFormInputs } from '@/pages/buildings/building-edit/ServiceStaffPostModal.vue'
import { LmStaffFormInputs } from '@/pages/buildings/building-edit/LmStaffPostModal.vue'
import { FrontStaffFormInputs } from '@/pages/buildings/building-edit/FrontStaffPostModal.vue'
import { ADMIN_ROLE } from '@/constants/schema-constants'
import type { AdminRole } from '@/constants/schema-constants'
import { User } from '@/dtos/commons'
import { StaffsGetRequest, StaffsGetResponseStaff } from '@/dtos/staffs/get'
import { LifeManagerStaffPostRequest, StaffPostRequest } from '@/dtos/staffs/post'
import { staffsModule } from '@/stores/staffs-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),

    ServiceStaffPostModal: () => import('@/pages/buildings/building-edit/ServiceStaffPostModal.vue'),
    FrontStaffPostModal: () => import('@/pages/buildings/building-edit/FrontStaffPostModal.vue'),
    LmStaffPostModal: () => import('@/pages/buildings/building-edit/LmStaffPostModal.vue'),
  }
})
export default class staffSelectModal extends Vue {
  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)

  @Model('change')
  private readonly isVisible!: boolean

  @Prop({ required: true, default: ADMIN_ROLE.SERVICE_STAFF })
  private readonly adminRole!: AdminRole

  @Prop({ required: true, default: '担当者' })
  private readonly roleName!: string

  @Prop({ default: '' })
  private readonly placeholder!: string

  @Prop({ default: '選択する' })
  private readonly topRightBtnText!: string

  @Prop({ default: false })
  private readonly showServiceRegisterBtn!: boolean

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!:string

  // 検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!:string

  @Prop({ default: () => [] })
  selectedStaffs!:User[]

  @Prop({ default: false })
  private readonly singleSelect?: boolean

  @Prop({ default: false })
  private readonly isIncludeIconImagePath?: boolean

  @Prop({ default: false })
  private readonly hasLimit?: boolean

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  private get staffs(): StaffsGetResponseStaff[] {
    return staffsModule.staffs(this.adminRole)
  }

  async created(): Promise<void> {
    this.fetchStaffs()
  }

  async fetchStaffs(): Promise<void> {
    const req = new StaffsGetRequest(this.adminRole)
    req.isIncludeIconImagePath = this.isIncludeIconImagePath
    await staffsModule.fetchStaffs(req)
  }

  private get headers(): Header[] {
    const headers:Header[] = []
    headers.push(new Header({ text: '社員名', value: 'userName', sortable: false }))
    return headers
  }

  private get _selectedNames(): string {
    if (!this.isSelected) return ''
    return this.staffs.filter(e => this.selectedUpdateStaffIds.includes(e.userId)).map(e => e.userName).join('、')
  }

  @Watch('isModalVisible', { immediate: true })
  fetchStaffId(isModalVisible: string): void {
    if (isModalVisible && this.selectedStaffs.length > 0) this.selectedUpdateStaffIds = this.selectedStaffs.map(ss => ss.userId)
  }

  onClickBtn():void {
    const selectedStaffs:User[] = []
    this.selectedUpdateStaffIds.forEach(staffId => {
      const staff = this.staffs.find(value => value.userId === staffId)

      if (staff) {
        const user = new User()
        user.userId = staff.userId
        user.userName = staff.userName
        user.iconImagePath = staff.iconImagePath
        selectedStaffs.push(user)
      }
    })
    this.$emit('select', selectedStaffs)
    this.isModalVisible = false
  }

  private selectedUpdateStaffIds: string[] = []

  search():void {
    this._keyword = this._inputText
  }

  private get isSelected(): boolean {
    return this.selectedUpdateStaffIds.length > 0
  }

  serviceStaffPostModalKey = generateUuid()
  isServiceStaffPostModalVisible = false

  frontStaffPostModalKey = generateUuid()
  isFrontStaffPostModalVisible = false

  lmStaffPostModalKey = generateUuid()
  isLmStaffPostModalVisible = false

  openStaffPostModal(): void {
    switch (this.adminRole) {
      case ADMIN_ROLE.LIFE_MANAGER:
        this.lmStaffPostModalKey = generateUuid()
        this.isLmStaffPostModalVisible = true
        break
      case ADMIN_ROLE.FRONT:
        this.frontStaffPostModalKey = generateUuid()
        this.isFrontStaffPostModalVisible = true
        break
      case ADMIN_ROLE.SERVICE_STAFF:
        this.serviceStaffPostModalKey = generateUuid()
        this.isServiceStaffPostModalVisible = true
        break
      case ADMIN_ROLE.ACCOUNTING_DEPARTMENT:
      case ADMIN_ROLE.GROUP_CALL_CENTER:
        break
      default: assertExhaustive(this.adminRole)
    }
  }

  async postServiceStaff(formInputs: ServiceStaffFormInputs): Promise<void> {
    await staffsModule.postStaff(new StaffPostRequest(ADMIN_ROLE.SERVICE_STAFF, formInputs.employeeId, formInputs.adminName, formInputs.adminShortName, formInputs.emailAddress))
    this.fetchStaffs()
    this.isServiceStaffPostModalVisible = false
  }

  async postFrontStaff(formInputs: FrontStaffFormInputs): Promise<void> {
    await staffsModule.postStaff(new StaffPostRequest(ADMIN_ROLE.FRONT, formInputs.employeeId, formInputs.adminName))
    this.fetchStaffs()
    this.isFrontStaffPostModalVisible = false
  }

  async postLmStaff(formInputs: LmStaffFormInputs): Promise<void> {
    await staffsModule.postLifeManagerStaff(new LifeManagerStaffPostRequest(formInputs.employeeId, formInputs.adminName, formInputs.receiverName, formInputs.phoneNumber))
    this.fetchStaffs()
    this.isLmStaffPostModalVisible = false
  }
}
