import { AdminRole, ADMIN_ROLE } from '@/constants/schema-constants'
import { myProfileModule } from '@/stores/my-profile-store'
import Vue from 'vue'
import Component from 'vue-class-component'

/**
 * 画面表示や処理で共通的に必要となる、ログイン中の管理者にまつわる種々の判定を提供します。
 * 'Vue' の代わりに 'Mixins(CurrentAdminManager)' を継承することで、templateやscriptタグ内で直接利用できます。
 *
 * 権限制御は原則として、「〇〇でない場合」を条件とするのではなく、「✕✕である場合」を条件として書くようにします。
 * これは権限の種別が追加された場合に、新規の権限が初期状態でアクションの対象になってしまうことを避けるためです。
 * 「使えるべきなのに使えない」はまだよいですが、「使えないべきなのに使える」は重大なインシデントに繋がる危険性があります。
 *   e.g.
 *   あるボタンを利用できない新しい権限Xが追加された時を考えます。
 *   フロント担当以外が利用できるボタンを置く際、
 *
 *     <sm-btn v-if="!$isFrontPerson" />
 *       ---->  権限Xはボタンを利用できてしまう。特にその機能が個人情報等を扱う場合に、改修が漏れるととても危険
 *
 *     <sm-btn v-if="$isServiceStaff || $isAccountingDept" />
 *       ---->  権限Xはボタンを利用できない。最悪の場合改修が漏れても、権限X以外の他のユーザに悪影響がない
 */
@Component
export class CurrentAdminManager extends Vue {
  private get _loginUserRole(): AdminRole | undefined { return myProfileModule.myProfileGet?.role }
  private get _currentAdminId(): string | undefined { return myProfileModule.myProfileGet?.user.userId }

  get $isServiceStaff(): boolean { return this._loginUserRole === ADMIN_ROLE.SERVICE_STAFF }
  get $isAccountingDept(): boolean { return this._loginUserRole === ADMIN_ROLE.ACCOUNTING_DEPARTMENT }
  get $isFrontPerson(): boolean { return this._loginUserRole === ADMIN_ROLE.FRONT }
  get $isGroupCallCenter(): boolean { return this._loginUserRole === ADMIN_ROLE.GROUP_CALL_CENTER }

  $isCurrentAdmin(testingUserId: string): boolean { return testingUserId === this._currentAdminId }
}
