import { Type } from 'class-transformer'
import { User } from '@/dtos/commons'
import { Details } from '@/dtos/resolutions/commons'
import type { QuestionState, ResolutionState } from '@/constants/schema-constants'

export class QuestionsGetRequest {
  resolutionId!: string
  take!: number
  isUnanswered?: boolean
  isAvailable?: boolean
  skipToken?: string
}

export class Answer {
  answerId!: string
  answerBody!: string
  @Type(() => User) user: User = new User()
  postedAt!: string
  version!: number
}

export class Question {
  questionId!: string
  questionBody!: string
  @Type(() => User) ownerUser = new User()
  postedAt!: string
  questionState!: QuestionState
  isEdited!: boolean
  @Type(() => Answer) answer?: Answer
}

export class DisplayingQuestion extends Question {
  isEditMode?: boolean = false
  answerInput =''
  constructor() {
    super()
    this.answerInput = this.answer?.answerBody ?? ''
  }
}

export class QuestionsGetResponse {
  resolutionId!: string
  resolutionState!: ResolutionState
  postedAt!: string
  title?: string
  /**
   * 決議の投稿者
   */
  @Type(() => User) user: User = new User()
  @Type(() => Details) details: Details = new Details()
  @Type(() => DisplayingQuestion) questions: DisplayingQuestion[] = []
  skipToken!: string
}
