




































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmListRepairPlanUpdateDetail extends Vue {
  @Prop({ required: true, default: '-' })
  private readonly previous!: string

  @Prop({ required: true, default: '-' })
  private readonly current!: string
}
