








































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'

import { getTicketStateChipColor, getTicketStateLabel, getTicketTaskStateChipColor, getTicketTaskStateLabel } from '@/libs/state-handler'
import { getTicketBadge, getTicketLabel } from '@/libs/type-handler'

import { Header } from '@/components/molecules/SmTableDataExternalPaging.vue'
import { FILTER_TOGGLE_TICKET_STATES, TicketInputParams } from '@/pages/tickets/TicketsListPage.vue'
import { ADMIN_MENU_TYPES, FILTER_TOGGLE_TASK_STATES, TaskInputParams } from '@/pages/tasks/list/TaskListSubPage.vue'

import { TICKET_STATES, TICKET_TASK_STATES } from '@/constants/schema-constants'
import type { TicketState, TicketTaskState } from '@/constants/schema-constants'
import { ChipColors } from '@/constants/ux-constants'

import { dashboardModule } from '@/stores/dashboard-store'
import { myProfileModule } from '@/stores/my-profile-store'
import { paramStorageModule } from '@/stores/param-storage-store'
import { tasksModule } from '@/stores/tasks-store'
import { ticketsModule } from '@/stores/tickets-store'

import { DashboardSummaryGetRequest, DashboardSummaryGetResponse } from '@/dtos/dashboard/summary/get'
import { ListResponseTaskDto, Period, TasksSearchPostRequest } from '@/dtos/tasks/search/post'
import { Ticket, TicketsSearchPostRequest } from '@/dtos/tickets/search/post'

// テーブルに表示させるのに必要な内容
class PersonalTicketItem {
  ticketId!: string
  ticketState!: string
  chipColor!: ChipColors
  task!: string
  accrualDate!: string
  buildingName?: string
  buildingId?: string
  ticketTypeText!: string
  ticketTypeTextStyle!: string
  ticketTypeIcon!: string
  ticketTypeIconColor!: string
  ticketName!: string
  ticketNo!: string
}

class PersonalTaskItem {
  ticketTaskId!: string
  ticketTaskState!: string
  chipColor!: ChipColors
  deadline!: string
  deadlineDate!: string
  buildingName?: string
  buildingId?: string
  ticketTypeText!: string
  ticketTypeTextStyle!: string
  ticketTypeIcon!: string
  ticketTypeIconColor!: string
  ticketTaskName!: string
  ticketName!: string
  ticketId!: string
}

@Component({
  components: {
    SmBadgeCategory: () => import('@/components/atoms/SmBadgeCategory.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmTableDataExternalPaging: () => import('@/components/molecules/SmTableDataExternalPaging.vue'),

    SmTicketsTasksSummary: () => import('@/pages/dashboard/SmTicketsTasksSummary.vue'),
  }
})
export default class PersonalSubPage extends Mixins(CurrentAdminManager) {
  isFilterMenuOpenOfPersonalDeadlineTask = true
  isFilterMenuOpenOfPersonalTicket = true
  isFilterMenuOpenOfPersonalTask = true

  async created(): Promise<void> {
    tasksModule.clearFetchedTask()
    await Promise.all([
      dashboardModule.fetchDashboardSummary(new DashboardSummaryGetRequest(true)),
      ticketsModule.fetchTickets(this.toSearchTicketsPostRequest()),
      tasksModule.fetchDashboardPersonalTasks(this.toSearchTasksPostRequest()),
      tasksModule.fetchDashboardPersonalDeadlineTasks(this.toSearchDeadlineTasksPostRequest())
    ])
  }

  private get personalDeadlineTaskHeaderTitle():string {
    return 'あなたの期限切れ担当タスク（' + this.deadlineTasks.length + '件）'
  }

  private get personalTicketHeaderTitle():string {
    return 'あなたの担当チケット（' + this.tickets.length + '件）'
  }

  private get personalTaskHeaderTitle():string {
    return 'あなたの担当タスク（' + this.tasks.length + '件）'
  }

  // ----------------------チケット・タスクサマリーエリア----------------------
  private get dashboardSummary():DashboardSummaryGetResponse { return dashboardModule.getDashboardSummary }

  clickTicketsCount(): void {
    // チケット一覧画面への遷移後、チケットステータス（処理中）と担当者での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.ticketsList.name
    const ticketsInputParams = new TicketInputParams()

    // チケットステータス（処理中）を検索条件に設定
    ticketsInputParams.ticketStates = [FILTER_TOGGLE_TICKET_STATES.NOT_COMPLETED]

    // ログイン中のユーザーを検索対象の担当者として条件に設定
    if (myProfileModule.myProfileGet?.user)ticketsInputParams.admins = [myProfileModule.myProfileGet?.user]

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...ticketsInputParams } })
    this.$router.push({ name: staticRoutes.ticketsList.name })
  }

  clickTasksCount(ticketTaskState: TicketTaskState): void {
    // タスク一覧画面への遷移後、タスクステータス（未着手 or 処理中）と担当者での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    // タスクステータス（未着手 or 処理中）を検索条件に設定
    if (ticketTaskState === TICKET_TASK_STATES.NOT_STARTED_YET) tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET]
    if (ticketTaskState === TICKET_TASK_STATES.NOT_COMPLETED) tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    // ログイン中のユーザーを検索対象の担当者として条件に設定
    if (myProfileModule.myProfileGet?.user)tasksInputParams.staffs = [myProfileModule.myProfileGet?.user]
    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.EACH

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  clickNearDeadlineTasksCount(): void {
    // タスク一覧画面への遷移後、担当者と期日での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    // ログイン中のユーザーを検索対象の担当者として条件に設定
    if (myProfileModule.myProfileGet?.user)tasksInputParams.staffs = [myProfileModule.myProfileGet?.user]
    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.EACH

    // 期日を日を本日から6日後を検索条件として設定
    const time = new Date()
    tasksInputParams.deadline = new Period()
    tasksInputParams.deadline.fromDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate()))
    tasksInputParams.deadline.toDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate() + 6))

    // タスクステータスが完了以外を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE, FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET, FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  clickExpiredTasksCount(): void {
    // タスク一覧画面への遷移後、担当者と期日での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    // ログイン中のユーザーを検索対象の担当者として条件に設定
    if (myProfileModule.myProfileGet?.user)tasksInputParams.staffs = [myProfileModule.myProfileGet?.user]
    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.EACH

    // 期日の終了日を前日として検索条件として設定
    const time = new Date()
    tasksInputParams.deadline = new Period()
    tasksInputParams.deadline.fromDate = ''
    tasksInputParams.deadline.toDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate() - 1))

    // タスクステータスが完了以外を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE, FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET, FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  // ----------------------担当チケットエリア----------------------
  toSearchTicketsPostRequest(): TicketsSearchPostRequest {
    const req = new TicketsSearchPostRequest(0, 999)
    req.ticketStates = [TICKET_STATES.NOT_COMPLETED]
    req.ticketTypes = undefined
    req.buildings = undefined
    req.owners = undefined
    req.fromDate = undefined
    req.toDate = undefined
    if (myProfileModule.myProfileGet?.user) req.admins = [myProfileModule.myProfileGet?.user.userId]
    req.keyword = undefined
    req.sortOrder = 'desc'// チケット発生日が新しい順で並び替え
    return req
  }

  private get tickets(): PersonalTicketItem[] {
    const _tickets = ticketsModule.tickets
    if (_tickets.length === 0) return []
    return _tickets.map(t => this.toPersonalTicketItem(t))
  }

  toPersonalTicketItem(t: Ticket): PersonalTicketItem {
    const item = new PersonalTicketItem()
    item.ticketId = t.ticketId
    item.ticketState = getTicketStateLabel(t.ticketState)
    item.chipColor = this.getChipColor(t.ticketState)
    item.task = t.completedTaskCount + '/' + t.allTaskCount
    item.accrualDate = t.postedAt
    item.buildingName = t.buildingName
    item.buildingId = t.buildingId
    item.ticketTypeText = getTicketLabel(t.ticketType)
    item.ticketTypeTextStyle = getTicketBadge(t.ticketType).textStyle
    item.ticketTypeIcon = getTicketBadge(t.ticketType).icon
    item.ticketTypeIconColor = getTicketBadge(t.ticketType).iconColor
    item.ticketName = t.ticketName
    item.ticketNo = t.ticketNo
    return item
  }

  getChipColor(state: TicketState): ChipColors {
    return getTicketStateChipColor(state)
  }

  PersonalTicketTableHeaders: Header[] = [
    new Header({ text: 'タスク', value: 'task', sortable: false, width: '74px' }),
    new Header({ text: 'マンション名', value: 'buildingName', sortable: false, width: '184px' }),
    new Header({ text: 'コンテンツ', value: 'ticketType', sortable: false, width: '134px' }),
    new Header({ text: 'チケットタイトル', value: 'ticketName', sortable: false, width: '584px' }),
  ]

  goTicketsListPage(): void {
    // チケット一覧画面への遷移後、チケットステータス（完了以外）と担当者での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.ticketsList.name
    const ticketsInputParams = new TicketInputParams()

    // チケットステータス（完了以外）を検索条件に設定
    ticketsInputParams.ticketStates = [FILTER_TOGGLE_TICKET_STATES.UNSET_PERSON_IN_CHARGE, FILTER_TOGGLE_TICKET_STATES.NOT_COMPLETED]

    // ログイン中のユーザーを検索対象の担当者として条件に設定
    if (myProfileModule.myProfileGet?.user)ticketsInputParams.admins = [myProfileModule.myProfileGet?.user]

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...ticketsInputParams } })
    this.$router.push({ name: staticRoutes.ticketsList.name })
  }

  goToTicketDetailPage(ticket: PersonalTicketItem): void {
    this.$router.push({ name: staticRoutes.ticketDetail.name, params: { ticketId: ticket.ticketId } })
  }

  // ----------------------担当タスクエリア----------------------
  toSearchTasksPostRequest(): TasksSearchPostRequest {
    const req = new TasksSearchPostRequest(999)
    req.ticketId = undefined
    req.ticketTaskStates = [TICKET_TASK_STATES.NOT_STARTED_YET, TICKET_TASK_STATES.NOT_COMPLETED]
    req.ticketTypes = undefined
    req.buildings = undefined
    if (myProfileModule.myProfileGet?.user) req.staffs = [myProfileModule.myProfileGet?.user.userId]
    req.postedDate = undefined
    req.deadline = undefined
    req.keyword = undefined
    req.sortItem = 'deadline' // 期日が古い順で並び替える
    return req
  }

  private get tasks(): PersonalTaskItem[] {
    const _tasks = tasksModule.personalTasks
    if (_tasks.length === 0) return []
    const personalTaskItem = _tasks.map(t => this.toPersonalTaskItem(t))
    // タスク期日が新しい順で表示するため反転する
    return personalTaskItem.reverse()
  }

  getChipColorOfTask(state: TicketTaskState): ChipColors {
    return getTicketTaskStateChipColor(state)
  }

  toPersonalTaskItem(t: ListResponseTaskDto): PersonalTaskItem {
    const item = new PersonalTaskItem()
    item.ticketTaskId = t.ticketTaskId
    item.ticketTaskState = getTicketTaskStateLabel(t.ticketTaskState)
    item.chipColor = this.getChipColorOfTask(t.ticketTaskState)
    item.deadline = t.deadline
    item.deadlineDate = t.deadlineDate
    item.buildingName = t.building?.buildingName
    item.buildingId = t.building?.buildingId
    item.ticketTypeText = getTicketLabel(t.ticket.ticketType)
    item.ticketTypeTextStyle = getTicketBadge(t.ticket.ticketType).textStyle
    item.ticketTypeIcon = getTicketBadge(t.ticket.ticketType).icon
    item.ticketTypeIconColor = getTicketBadge(t.ticket.ticketType).iconColor
    item.ticketTaskName = t.ticketTaskName
    item.ticketName = t.ticket.ticketName
    item.ticketId = t.ticket.ticketId
    return item
  }

  PersonalTaskTableHeaders: Header[] = [
    new Header({ text: 'ステータス', value: 'ticketTaskState', sortable: false, width: '102px' }),
    new Header({ text: 'タスク期日', value: 'deadline', sortable: false, width: '160px' }),
    new Header({ text: 'マンション名', value: 'buildingName', sortable: false, width: '155px' }),
    new Header({ text: 'コンテンツ', value: 'ticketType', sortable: false, width: '134px' }),
    new Header({ text: 'タスクタイトル', value: 'ticketTaskName', sortable: false, width: '212.5px' }),
    new Header({ text: '親チケットタイトル', value: 'ticketName', sortable: false, width: '212.5px' }),
  ]

  goTaskListPage(): void {
    // タスク一覧画面への遷移後、タスクステータス（完了以外）と担当者での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    // タスクステータスが完了以外を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE, FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET, FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    // ログイン中のユーザーを検索対象の担当者として条件に設定
    if (myProfileModule.myProfileGet?.user)tasksInputParams.staffs = [myProfileModule.myProfileGet?.user]
    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.EACH

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  // ----------------------期限切れ担当タスクエリア----------------------
  toSearchDeadlineTasksPostRequest(): TasksSearchPostRequest {
    const req = new TasksSearchPostRequest(999)
    req.ticketId = undefined
    req.ticketTaskStates = [TICKET_TASK_STATES.NOT_STARTED_YET, TICKET_TASK_STATES.NOT_COMPLETED]
    req.ticketTypes = undefined
    req.buildings = undefined
    if (myProfileModule.myProfileGet?.user) req.staffs = [myProfileModule.myProfileGet?.user.userId]
    req.postedDate = undefined

    const time = new Date()
    req.deadline = new Period()
    req.deadline.fromDate = undefined
    req.deadline.toDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate() - 1))

    req.keyword = undefined
    req.sortItem = 'deadline' // 期日が古い順で並び替える
    return req
  }

  private get deadlineTasks(): PersonalTaskItem[] {
    const _deadlineTasks = tasksModule.personalDeadlineTasks
    if (_deadlineTasks.length === 0) return []
    return _deadlineTasks.map(t => this.toPersonalTaskItem(t))
  }

  PersonalDeadlineTaskTableHeaders: Header[] = [
    new Header({ text: 'タスク期日', value: 'deadline', sortable: false, width: '160px' }),
    new Header({ text: 'マンション名', value: 'buildingName', sortable: false, width: '155px' }),
    new Header({ text: 'コンテンツ', value: 'ticketType', sortable: false, width: '134px' }),
    new Header({ text: 'タスクタイトル', value: 'ticketTaskName', sortable: false, width: '263.5px' }),
    new Header({ text: '親チケットタイトル', value: 'ticketName', sortable: false, width: '263.5px' }),
  ]

  goTaskListPageExpired(): void {
    // タスク一覧画面への遷移後、期日と担当者とステータス（完了以外）での絞り込みのために検索条件に情報を保存する

    const STORAGE_KEY = staticRoutes.tasks.getChild('list').name
    const tasksInputParams = new TaskInputParams()

    // ログイン中のユーザーを検索対象の担当者として条件に設定
    if (myProfileModule.myProfileGet?.user)tasksInputParams.staffs = [myProfileModule.myProfileGet?.user]
    tasksInputParams.staffMenuTypes = ADMIN_MENU_TYPES.EACH

    // タスクステータスが完了以外を検索条件に設定
    tasksInputParams.selectedStates = ['unsetPersonInCharge', 'notStartedYet', 'notCompleted']

    // 期日の終了日を前日として検索条件として設定
    const time = new Date()
    tasksInputParams.deadline = new Period()
    tasksInputParams.deadline.fromDate = ''
    tasksInputParams.deadline.toDate = this.formatDate(new Date(time.getFullYear(), time.getMonth(), time.getDate() - 1))

    // タスクステータスが完了以外を検索条件に設定
    tasksInputParams.selectedStates = [FILTER_TOGGLE_TASK_STATES.UNSET_PERSON_IN_CHARGE, FILTER_TOGGLE_TASK_STATES.NOT_STARTED_YET, FILTER_TOGGLE_TASK_STATES.NOT_COMPLETED]

    paramStorageModule.save({ key: STORAGE_KEY, params: { ...tasksInputParams } })
    this.$router.push({ name: staticRoutes.tasks.getChild('list').name })
  }

  goToTicketDetailPageTaskTab(task: PersonalTaskItem): void {
    this.$router.push({ name: staticRoutes.ticketDetail.getChild('tasks').name, params: { ticketId: task.ticketId, ticketTaskId: task.ticketTaskId } }) // フォーカス状態とするためタスクIDを渡す
  }

  formatDate(date: Date): string {
    const y = date.getFullYear()
    const m = ('00' + (date.getMonth() + 1)).slice(-2)
    const d = ('00' + date.getDate()).slice(-2)
    return (y + '-' + m + '-' + d)
  }
}

