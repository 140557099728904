import { Type } from 'class-transformer'

export class Plan {
  paymentPlanId!: string
  planCode!: string
  planName!: string
  plannedAt!: string
  plainString():string { return this.paymentPlanId ? `${this.planCode}:${this.planName}` : '未設定' }
}

export class UnitPayment {
  buildingUnitId!:string
  userName!:string
  roomNumber!:string
  planCode?:string
  @Type(() => Plan)plans: Plan[] = []
  updatedAt!: string
  isChanged!: boolean
}

export class ReservePaymentsGetResponse {
  @Type(() => Plan)plans: Plan[] = []
  @Type(() => UnitPayment)payments:UnitPayment[] = []
}
