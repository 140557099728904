


















































































import { MATERIAL_TYPES, QUESTIONNAIRE_QUESTION_ELEMENT_TYPES, QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'
import { QuestionnaireQuestionBase, QuestionnaireQuestionElementBase, QuestionnaireSectionBase } from '@/dtos/questionnaires/commons'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { staticKeyProvider } from '@/libs/static-key-provider'
import { Vue, Component, Prop } from 'vue-property-decorator'

const QUESTION_MENU_ITEMS = {
  FREE: { text: '設問(入力式)を追加', label: '設問を追加する' },
  SINGLE_CHOICE: { text: '設問(単一選択式)を追加', label: '設問を追加する' },
  MULTIPLE_CHOICES: { text: '設問(複数選択式)を追加', label: '設問を追加する' },
} as const
type QuestionMenuItem = keyof typeof QUESTION_MENU_ITEMS

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardQuestionnaireQuestion: () => import('@/components/molecules/card/SmCardQuestionnaireQuestion.vue'),
    SmMaterialInput: () => import('@/components/molecules/SmMaterialInput.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
  }
})
export default class SmCardQuestionnaireSection extends Vue {
  @Prop({ required: true, default: {} })
  value!: QuestionnaireSectionBase

  @Prop({ required: true, default: '' })
  sectionName!: string

  @Prop({ required: true, default: '' })
  fieldIdPrefix!: string

  @Prop({ default: false })
  questionUnmovable!: boolean

  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
  QUESTION_MENU_ITEMS = Object.freeze(QUESTION_MENU_ITEMS)

  private get section():QuestionnaireSectionBase {
    return this.value
  }

  private set section(newValue:QuestionnaireSectionBase) {
    this.$emit('input', newValue)
  }

  /** 設問追加メニュー */
  questionType:QuestionMenuItem = 'FREE'

  createQuestion():void {
    const newQuestion = staticKeyProvider.create(QuestionnaireQuestionBase)
    newQuestion.questionSentence = ''
    newQuestion.questionRequired = true
    switch (this.questionType) {
      case 'FREE': {
        newQuestion.questionType = QUESTIONNAIRE_QUESTION_TYPES.FREE
        const questionElement = staticKeyProvider.create(QuestionnaireQuestionElementBase)
        questionElement.sortOrderNum = this.section.questions.length + 1
        questionElement.answerPlaceholder = ''
        questionElement.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.FREE
        newQuestion.questionElements.push(questionElement)
        break
      }

      case 'SINGLE_CHOICE': newQuestion.questionType = QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE; break
      case 'MULTIPLE_CHOICES': newQuestion.questionType = QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES; break
      default: assertExhaustive(this.questionType)
    }
    // 選択式の場合、初期値として選択肢を2つ追加する
    if (this.questionType !== 'FREE') {
      const option1 = new QuestionnaireQuestionElementBase()
      option1.choice = ''
      option1.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.SELECTABLE_OPTION
      option1.sortOrderNum = 1
      const option2 = new QuestionnaireQuestionElementBase()
      option2.choice = ''
      option2.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.SELECTABLE_OPTION
      option2.sortOrderNum = 2
      newQuestion.questionElements.push(option1, option2)
    }
    newQuestion.sortOrderNum = this.section.questions.length + 1

    this.section.questions.push(newQuestion)
    this.$emit('contaminate')
  }

  updateQuestionElements(questionsElements:QuestionnaireQuestionElementBase[], index:number):void {
    this.section.questions[index].questionElements = questionsElements
  }

  goUpQuestion(questionIndex: number): void {
    this.section.questions.splice(questionIndex - 1, 2, this.section.questions[questionIndex], this.section.questions[questionIndex - 1])
  }

  goDownQuestion(questionIndex: number): void {
    this.section.questions.splice(questionIndex, 2, this.section.questions[questionIndex + 1], this.section.questions[questionIndex])
  }

  deleteQuestion(questionIndex: number): void {
    this.section.questions.splice(questionIndex, 1)
    this.$emit('contaminate')
  }

  onContaminate(): void {
    this.$emit('contaminate')
  }

  onClickSectionDelete():void {
    this.$emit('delete')
  }
}
