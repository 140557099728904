








































import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { QuestionnaireQuestionElementType, QuestionnaireQuestionType, SectionType, QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'
import { Header } from '@/components/molecules/SmTableData.vue'
import { QuestionOption } from '@/components/molecules/SmProxyAnswerEachQuestion.vue'

export class QuestionnairesProxyInputQuestionElement {
  questionnaireQuestionElementId: string
  questionnaireQuestionElementType: QuestionnaireQuestionElementType
  questionnaireQuestionElementChoice?: string
  questionnaireQuestionElementFreeAnswer?: string
  questionnaireQuestionElementPlaceholder?: string
  answeredOwnerUserId?: string // 選択肢を選択した場合にユーザーIDを格納するプロパティ
  constructor(
    questionnaireQuestionElementId: string,
    questionnaireQuestionElementType:QuestionnaireQuestionElementType,
  ) {
    this.questionnaireQuestionElementId = questionnaireQuestionElementId
    this.questionnaireQuestionElementType = questionnaireQuestionElementType
  }
}

export class QuestionnairesProxyInputItem {
  questionnaireSectionId: string
  questionnaireSectionType: SectionType
  questionnaireSectionTitle: string
  questionnaireQuestionId: string
  questionnaireQuestionType:QuestionnaireQuestionType
  questionnaireQuestionSentence: string
  questionnaireQuestionRequired: boolean
  questionnaireQuestionElements:QuestionnairesProxyInputQuestionElement[] = []

  constructor(
    questionnaireSectionId: string,
    questionnaireSectionType: SectionType,
    questionnaireSectionTitle: string,
    questionnaireQuestionId: string,
    questionnaireQuestionType:QuestionnaireQuestionType,
    questionnaireQuestionSentence: string,
    questionnaireQuestionRequired: boolean,

  ) {
    this.questionnaireSectionId = questionnaireSectionId
    this.questionnaireSectionType = questionnaireSectionType
    this.questionnaireSectionTitle = questionnaireSectionTitle
    this.questionnaireQuestionId = questionnaireQuestionId
    this.questionnaireQuestionType = questionnaireQuestionType
    this.questionnaireQuestionSentence = questionnaireQuestionSentence
    this.questionnaireQuestionRequired = questionnaireQuestionRequired
  }
}

export class QuestionnairesProxyTableItems {
  displayQuestionnairesProxyVoteTableItems!: QuestionnairesProxyInputItem[]
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),

    SmProxyAnswerEachQuestion: () => import('@/components/molecules/SmProxyAnswerEachQuestion.vue'),
  }
})
export default class QuestionnairesProxyInputTable extends Vue {
  tableHeaders: Header[] = [
    new Header({ text: '回答セクション', value: 'sectionTitle', width: '160px', sortable: false }),
    new Header({ text: '設問文', value: 'questionSentence', width: '360px', sortable: false }),
    new Header({ text: '回答', sortable: false }),
  ]

  @Prop({ default: () => [] })
  @Model('change')
  displayQuestionnairesProxyVoteTableItems!:QuestionnairesProxyInputItem[]

  private get _displayQuestionnairesProxyVoteTableItems(): QuestionnairesProxyInputItem[] { return this.displayQuestionnairesProxyVoteTableItems }
  private set _displayQuestionnairesProxyVoteTableItems(newValue: QuestionnairesProxyInputItem[]) {
    this.$emit('change', newValue)
  }

  @Prop({ required: true, default: false })
  private readonly showConfirmPage!: boolean

  @Prop({ required: true, default: '' })
  private readonly respondentOwnerUserId!: string

  questionnaireQuestionSentence(item: QuestionnairesProxyInputItem): string {
    let questionSentence = item.questionnaireQuestionSentence
    if (item.questionnaireQuestionType === QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES) { questionSentence = questionSentence + '【複数回答可】' }
    if (!item.questionnaireQuestionRequired) { questionSentence = questionSentence + '（任意）' }
    return questionSentence
  }

  confirmPageText!:string

  selectedCheckboxOption:QuestionOption[]=[]
  clickNextBtn(selectedCheckbox: QuestionOption[]): void {
    this.selectedCheckboxOption = selectedCheckbox
  }
}
