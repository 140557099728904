import { Type } from 'class-transformer'

export class RepairsUpdatesLogsGetRequest {
  userId?: string
  hasDiffs?: boolean

  skip: number
  take: number

  constructor(skip: number, take: number) {
    this.skip = skip
    this.take = take
  }
}

export class LogsGetResponseLog {
  logId!: string
  userName!: string
  updatedAt!: string
  repairPlanId!: string
  fileName!: string
  isOriginalPlan!: boolean
  isAvailable!: boolean
}

export class RepairsUpdatesLogsGetResponse {
  @Type(() => LogsGetResponseLog) logs: LogsGetResponseLog[] = []
}
