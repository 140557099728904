















import { Vue, Component } from 'vue-property-decorator'
import { repairsModule } from '@/stores/repairs-store'
import { RepairsExpensesGetResponse } from '@/dtos/repairs/expenses/get'

@Component({
  components: {
    SmChartRepairPlan: () => import('@/components/molecules/SmChartRepairPlan.vue'),
    SmTableRepairPlan: () => import('@/components/molecules/SmTableRepairPlan.vue'),
  }
})
export default class RepairPlanUpdateDetailSummarySubPage extends Vue {
  private get repairsExpenses(): RepairsExpensesGetResponse { return repairsModule.repairsExpensesGet }
}
