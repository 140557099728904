import { ERROR_MESSAGES, TRANSITION_PATTERNS } from '@/constants/ux-constants'
import Vue, { AsyncComponent } from 'vue'
import Router, { Route, RouteConfig } from 'vue-router'
import { RouteMetaData, StaticRoute, staticRoutes, staticRoutesLocal } from '@/routes'

import { authModule } from './stores/auth-store'
import { errorsModule } from './stores/errors'
import { structureModule } from './stores/structure-store'
import { cognitoAuth } from './libs/cognito-auth-adapter'

Vue.use(Router)

class RouteConfigBase {
  name!: string
  path!: string
  meta?: RouteMetaData
  alias?: string
  component?: AsyncComponent
  children?: RouteConfigBase[]
  props!: boolean | Record<string, unknown> | ((route: Route) => unknown)
  redirect?: string
}

const configure = (route: StaticRoute): RouteConfigBase => {
  return {
    name: route.name,
    path: route.path,
    alias: route.alias,
    meta: route.meta,
    props: route.props ?? true,
    redirect: route.redirect,
  }
}

const routeConfigs = formatRouteConfigs()

// 環境変数でサンプル系ページへの遷移可否を制御する
// 環境変数はstringのためboolで判定する
if (process.env.VUE_APP_SHOW_SAMPLEPAGE === 'true') {
  const localConfigs = formatRouteConfigsLocal()
  routeConfigs.splice(routeConfigs.findIndex(c => c.path === '*'), 0, ...localConfigs)
}

const router = new Router({
  routes: routeConfigs,
  mode: 'history',
  scrollBehavior() {
    // -- infiniteLoading not work under transition without scrolling
    // -- https://github.com/PeachScript/vue-infinite-loading/issues/207
    //
    // <transition>下の<router-view />がvue-infinite-loading(=SmInfiniteLoading)を含む場合、
    // 遷移時に多少なりともスクロールした（＝`x=0 && y=0`でない）状態でないと初期表示のローディングが実行されない。
    // その対策として縦方向に1pxのスクロールを適用している。
    // ※ router.beforeEach/afterEachによるwindow.scrollTo(0,1)では、遷移元画面がスクロール不可な（＝コンテンツが少ない）画面で動作しない
    return { x: 0, y: 1 }
  },
})

router.beforeEach(async(to, from, next) => {
  const fromMeta = new RouteMetaData(from.meta)
  const toMeta = new RouteMetaData(to.meta)

  // 別タブを開いて、画面表示する際にcognitoからセッション情報を取得
  if (toMeta.newTab) {
    await authModule.updateSessionIfNecessary()
    // 別タブで開いた際にログインしていない場合、ログアウト処理を実施
    if (!cognitoAuth.sessionIsValid()) {
      await authModule.logout()
      return
    }
    await authModule.login()
  }

  if (toMeta.needLogin && !authModule.isAuthenticated) {
    // ログインが必要な画面に未ログイン状態で遷移しようとした場合、中断してログイン画面へ強制遷移
    await authModule.logout(ERROR_MESSAGES.SESSION_EXPIRED)
    return
  }

  // グローバルエラーとフィールドエラーをクリアする
  if (errorsModule.hasErrors) {
    errorsModule.clearGlobalErrors()
    errorsModule.clearAllFieldError()
  }

  // 全ページの共通構造の制御
  structureModule.changeCurrentNavigation(toMeta.navigationGroup)

  if (toMeta.hideSystemBar) structureModule.hideSystemBar()
  else structureModule.showSystemBar()

  if (toMeta.hideNavigation) structureModule.hideNavigation()
  else structureModule.showNavigation()

  if (toMeta.hideSystemBarBtns) structureModule.hideSystemBarBtns()
  else structureModule.showSystemBarBtns()

  structureModule.setWidthPattern(toMeta.widthPattern)

  structureModule.setIsPreview(toMeta.isPreview)

  // 遷移元・遷移先のアニメーションを決定
  // 次画面がmove-inする画面の場合
  if (toMeta.moveIn) structureModule.setTransitionPattern(TRANSITION_PATTERNS.MOVE_IN)
  // 次画面がmove-inする画面でなく、元画面がmove-inしていた画面であれば、元画面をmove-outさせる
  else if (fromMeta.moveIn) structureModule.setTransitionPattern(TRANSITION_PATTERNS.MOVE_OUT)
  // どちらもmove-inする画面でなければアニメーションなし
  else structureModule.setTransitionPattern(TRANSITION_PATTERNS.NO_ANIMATION)

  next()
})

export default router

// ------------------------------------------------------------------------------------------------
// initialization functions
// ------------------------------------------------------------------------------------------------
function formatRouteConfigs(): RouteConfig[] {
  return Object.values(staticRoutes).map(_route => {
    const config = configure(_route)

    switch (_route.name) {
      case staticRoutes.landing.name:
        config.component = () => import('@/pages/auth/LandingPage.vue'); break
      case staticRoutes.externalLogin.name:
        config.component = () => import('@/pages/auth/ExternalLoginPage.vue'); break
      case staticRoutes.loginCompletion.name:
        config.component = () => import('@/pages/auth/LoginCompletionPage.vue'); break

      case staticRoutes.newTabTransition.name:
        config.component = () => import('@/pages/auth/NewTabTransitionPage.vue'); break

      case staticRoutes.dashboard.name: {
        config.component = () => import('@/pages/dashboard/DashboardPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'all': childConfig.component = () => import('@/pages/dashboard/all/AllSubPage.vue'); break
            case 'personal': childConfig.component = () => import('@/pages/dashboard/personal/PersonalSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutes.ticketsList.name:
        config.component = () => import('@/pages/tickets/TicketsListPage.vue'); break

      case staticRoutes.ticketCreate.name:
        config.component = () => import('@/pages/tickets/TicketPostPage.vue'); break

      case staticRoutes.ticketDetail.name: {
        config.component = () => import('@/pages/tickets/ticket-detail/TicketDetailPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'detail': childConfig.component = () => import('@/pages/tickets/ticket-detail/detail/DetailSubPage.vue'); break
            case 'tasks': childConfig.component = () => import('@/pages/tickets/ticket-detail/tasks/TasksSubPage.vue'); break
            case 'comments': childConfig.component = () => import('@/pages/tickets/ticket-detail/comments/CommentsSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutes.tasks.name: {
        config.component = () => import('@/pages/tasks/TasksPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'list': childConfig.component = () => import('@/pages/tasks/list/TaskListSubPage.vue'); break
            case 'onboarding': childConfig.component = () => import('@/pages/tasks/onboardingAndRoutine/ManagementOperationSubPage.vue'); break
            case 'routine': childConfig.component = () => import('@/pages/tasks/onboardingAndRoutine/ManagementOperationSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      // アンケート
      case staticRoutes.questionnaireReportPostPage.name:
        config.component = () => import('@/pages/questionnaires/QuestionnaireReportPostPage.vue'); break

      case staticRoutes.questionnaireTemplateCreate.name:
        config.component = () => import('@/pages/questionnaires/templates/TemplatePostPage.vue'); break
      case staticRoutes.questionnaireTemplateUpdate.name:
        config.component = () => import('@/pages/questionnaires/templates/TemplatePostPage.vue'); break
      case staticRoutes.questionnaireCreate.name:
        config.component = () => import('@/pages/questionnaires/QuestionnairePostPage.vue'); break
      case staticRoutes.questionnaireUpdate.name:
        config.component = () => import('@/pages/questionnaires/QuestionnairePostPage.vue'); break
      case staticRoutes.questionnairePreviewTop.name:
        config.component = () => import('@/pages/questionnaires/QuestionnairePreviewTopPage.vue'); break
      case staticRoutes.questionnairesList.name:
        config.component = () => import('@/pages/questionnaires/QuestionnairesListPage.vue'); break

      case staticRoutes.questionnaireReportPreviewPage.name:
        config.component = () => import('@/pages/questionnaires/QuestionnaireReportPreviewPage.vue'); break

      case staticRoutes.ideasList.name:
        config.component = () => import('@/pages/ideas/IdeasListPage.vue'); break

      case staticRoutes.ownerIdeaRegister.name:
        config.component = () => import('@/pages/ideas/owner-idea/owner-idea-register/OwnerIdeaRegisterPage.vue'); break
      case staticRoutes.ownerIdeaPreview.name:
        config.component = () => import('@/pages/ideas/owner-idea/owner-idea-register/OwnerIdeaPreviewPage.vue'); break

      case staticRoutes.ownerIdeaDetail.name:
      case staticRoutes.ownerIdeaDetailPreview.name:
        config.component = () => import('@/pages/ideas/owner-idea/OwnerIdeaDetailPage.vue'); break

      case staticRoutes.adminIdeaDetail.name:
      case staticRoutes.adminIdeaDetailPreview.name : {
        config.component = () => import('@/pages/ideas/admin-idea/admin-idea-detail/AdminIdeaDetailPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'detail': childConfig.component = () => import('@/pages/ideas/admin-idea/admin-idea-detail/detail/DetailSubPage.vue'); break
            case 'session': childConfig.component = () => import('@/pages/ideas/admin-idea/admin-idea-detail/session/SessionSubPage.vue'); break
            case 'opinions': childConfig.component = () => import('@/pages/ideas/admin-idea/admin-idea-detail/opinions/OpinionsSubPage.vue'); break
            case 'updates': childConfig.component = () => import('@/pages/ideas/admin-idea/admin-idea-detail/updates/UpdatesSubPage.vue'); break
            case 'agreements': childConfig.component = () => import('@/pages/ideas/admin-idea/admin-idea-detail/agreements/AgreementsSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutes.adminIdeaDetailPrint.name: {
        config.component = () => import('@/pages/ideas/admin-idea/admin-idea-detail/detail/DetailPrintPage.vue'); break
      }

      case staticRoutes.adminIdeaSessionUpdate.name:
        config.component = () => import('@/pages/ideas/admin-idea/AdminIdeaSessionUpdatePage.vue'); break

      // 決議
      case staticRoutes.resolutionsList.name:
        config.component = () => import('@/pages/resolutions/ResolutionsListPage.vue'); break

      case staticRoutes.adminIdeaCreate.name:
        config.component = () => import('@/pages/ideas/admin-idea/AdminIdeaPostPage.vue'); break

      case staticRoutes.adminIdeaPreview.name:
        config.component = () => import('@/pages/ideas/admin-idea/AdminIdeaPreviewPage.vue'); break

      case staticRoutes.adminIdeaUpdate.name:
        config.component = () => import('@/pages/ideas/admin-idea/AdminIdeaPostPage.vue'); break

      case staticRoutes.requirementsList.name:
        config.component = () => import('@/pages/resolutions/RequirementsListPage.vue'); break

      // 総会決議
      case staticRoutes.gmResolutionDetail.name:
      case staticRoutes.gmResolutionDetailPreview.name: {
        config.component = () => import('@/pages/resolutions/gm-resolution/gm-resolution-detail/GMResolutionDetailPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'detail': childConfig.component = () => import('@/pages/resolutions/gm-resolution/gm-resolution-detail/detail/DetailSubPage.vue'); break
            case 'questions': childConfig.component = () => import('@/pages/resolutions/gm-resolution/gm-resolution-detail/questions/QuestionsSubPage.vue'); break
            case 'statements': childConfig.component = () => import('@/pages/resolutions/gm-resolution/gm-resolution-detail/statements/StatementsSubPage.vue'); break
            case 'results': childConfig.component = () => import('@/pages/resolutions/gm-resolution/gm-resolution-detail/results/ResultsSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      // 総会決議 内訳編集
      case staticRoutes.statementEdit.name:
        config.component = () => import('@/pages/resolutions/gm-resolution/StatementsUpdatePage.vue'); break

      case staticRoutes.gmResolutionCreate.name:
        config.component = () => import('@/pages/resolutions/gm-resolution/GMResolutionPostPage.vue'); break

      case staticRoutes.gmResolutionPreview.name:
        config.component = () => import('@/pages/resolutions/gm-resolution/GMResolutionPreviewPage.vue'); break

      case staticRoutes.gmResolutionUpdate.name:
        config.component = () => import('@/pages/resolutions/gm-resolution/GMResolutionPostPage.vue'); break

      // オンライン決議
      case staticRoutes.onlineResolutionDetailPrint.name: {
        config.component = () => import('@/pages/resolutions/online-resolution/online-resolution-detail/detail/DetailPrintPage.vue'); break
      }

      case staticRoutes.onlineResolutionDetail.name:
      case staticRoutes.onlineResolutionDetailPreview.name: {
        config.component = () => import('@/pages/resolutions/online-resolution/online-resolution-detail/OnlineResolutionDetailPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'detail': childConfig.component = () => import('@/pages/resolutions/online-resolution/online-resolution-detail/detail/DetailSubPage.vue'); break
            case 'questions': childConfig.component = () => import('@/pages/resolutions/online-resolution/online-resolution-detail/questions/QuestionsSubPage.vue'); break
            case 'results': childConfig.component = () => import('@/pages/resolutions/online-resolution/online-resolution-detail/results/ResultsSubPage.vue'); break
            case 'print': childConfig.component = () => import('@/pages/resolutions/online-resolution/online-resolution-detail/detail/DetailPrintPage.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutes.onlineResolutionCreate.name:
        config.component = () => import('@/pages/resolutions/online-resolution/OnlineResolutionPostPage.vue'); break

      case staticRoutes.onlineResolutionPreview.name:
        config.component = () => import('@/pages/resolutions/online-resolution/OnlineResolutionPreviewPage.vue'); break

      case staticRoutes.onlineResolutionUpdate.name:
        config.component = () => import('@/pages/resolutions/online-resolution/OnlineResolutionPostPage.vue'); break

      // 長計
      case staticRoutes.repairPlanDetail.name: {
        config.component = () => import('@/pages/repair-plan/repair-plan-detail/RepairPlanDetailPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'summary': childConfig.component = () => import('@/pages/repair-plan/repair-plan-detail/repair-plan-summary/RepairPlanSummarySubPage.vue'); break
            case 'construction': childConfig.component = () => import('@/pages/repair-plan/repair-plan-detail/repair-plan-construction/RepairPlanConstructionSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutes.repairPlanRegister.name:
        config.component = () => import('@/pages/repair-plan/RepairPlanRegisterPage.vue'); break

      case staticRoutes.repairPlanComments.name:
        config.component = () => import('@/pages/repair-plan/repair-plan-comments/RepairPlanCommentsPage.vue'); break

      case staticRoutes.repairPlanCommentsCreate.name:
        config.component = () => import('@/pages/repair-plan/repair-plan-comments-register/RepairPlanCommentsRegisterPage.vue'); break

      case staticRoutes.repairPlanCommentsUpdate.name:
        config.component = () => import('@/pages/repair-plan/repair-plan-comments-register/RepairPlanCommentsRegisterPage.vue'); break

      case staticRoutes.repairPlanUpdates.name:
        config.component = () => import('@/pages/repair-plan/RepairPlanUpdatesPage.vue'); break

      case staticRoutes.repairPlanUpdateDetail.name: {
        config.component = () => import('@/pages/repair-plan/repair-plan-update-detail/RepairPlanUpdateDetailPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'summary': childConfig.component = () => import('@/pages/repair-plan/repair-plan-update-detail/repair-plan-update-detail-summary/RepairPlanUpdateDetailSummarySubPage.vue'); break
            case 'construction': childConfig.component = () => import('@/pages/repair-plan/repair-plan-update-detail/repair-plan-update-detail-construction/RepairPlanUpdateDetailConstructionSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutes.repairPlanDetailSummaryPrint.name: {
        config.component = () => import('@/pages/repair-plan/repair-plan-detail/repair-plan-summary/RepairPlanPrintPage.vue'); break
      }

      case staticRoutes.repairPlanDetailConstructionPrint.name: {
        config.component = () => import('@/pages/repair-plan/repair-plan-detail/repair-plan-construction/RepairPlanConstructionPrintPage.vue'); break
      }

      // 簡易版長計
      case staticRoutes.simpleRepairPlanDetail.name: {
        config.component = () => import('@/pages/simple-repair-plan/detail/SimpleRepairPlanDetailPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'summary': childConfig.component = () => import('@/pages/simple-repair-plan/detail/summary/SimpleRepairPlanSummarySubPage.vue'); break
            case 'construction': childConfig.component = () => import('@/pages/simple-repair-plan/detail/construction/SimpleRepairPlanConstructionSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutes.simpleRepairPlanRegister.name:
        config.component = () => import('@/pages/simple-repair-plan/SimpleRepairPlanRegisterPage.vue'); break

      case staticRoutes.simpleRepairPlanComments.name:
        config.component = () => import('@/pages/simple-repair-plan/comments/SimpleRepairPlanCommentsPage.vue'); break

      case staticRoutes.simpleRepairPlanCommentsCreate.name:
        config.component = () => import('@/pages/simple-repair-plan/comments-register/SimpleRepairPlanCommentsRegisterPage.vue'); break

      case staticRoutes.simpleRepairPlanCommentsUpdate.name:
        config.component = () => import('@/pages/simple-repair-plan/comments-register/SimpleRepairPlanCommentsRegisterPage.vue'); break

      case staticRoutes.simpleRepairPlanDetailSummaryPrint.name:
        config.component = () => import('@/pages/simple-repair-plan/detail/summary/SimpleRepairPlanPrintPage.vue'); break

      case staticRoutes.simpleRepairPlanDetailConstructionPrint.name:
        config.component = () => import('@/pages/simple-repair-plan/detail/construction/SimpleRepairPlanConstructionPrintPage.vue'); break

      case staticRoutes.reserveFunds.name: {
        config.component = () => import('@/pages/reserve-funds/ReserveFundPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'detail': childConfig.component = () => import('@/pages/reserve-funds/detail/DetailSubPage.vue'); break
            case 'plans': childConfig.component = () => import('@/pages/reserve-funds/plans/PlansSubPage.vue'); break
            case 'payments': childConfig.component = () => import('@/pages/reserve-funds/payments/PaymentsSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      // CASYS差分
      case staticRoutes.casysResultsListPage.name: {
        config.component = () => import('@/pages/casys/CasysResultsListPage.vue'); break
      }

      case staticRoutes.casysDetectionsListPage.name: {
        config.component = () => import('@/pages/casys/detection/CasysDetectionsListPage.vue'); break
      }

      case staticRoutes.casysDetectionDetailPage.name: {
        config.component = () => import('@/pages/casys/detection/CasysDetectionDetailPage.vue'); break
      }

      case staticRoutes.reserveFundDetailRegister.name: {
        config.component = () => import('@/pages/reserve-funds/ReserveDetailEditPage.vue'); break
      }

      case staticRoutes.reserveFundDetailEdit.name: {
        config.component = () => import('@/pages/reserve-funds/ReserveDetailEditPage.vue'); break
      }

      case staticRoutes.reserveFundPaymentsPrint.name: {
        config.component = () => import('@/pages/reserve-funds/payments/print/SelectReservePlanPrintPage.vue'); break
      }

      case staticRoutes.reserveFundsPaymentPlanEdit.name: {
        config.component = () => import('@/pages/reserve-funds/PaymentPlanEditPage.vue'); break
      }
      case staticRoutes.reserveFundsPaymentPlanDetail.name: {
        config.component = () => import('@/pages/reserve-funds/PaymentPlanDetailPage.vue'); break
      }

      case staticRoutes.reserveFundsPersonalEdit.name: {
        config.component = () => import('@/pages/reserve-funds/PersonalPlanEditPage.vue'); break
      }

      case staticRoutes.reserveFundsPersonalDetail.name: {
        config.component = () => import('@/pages/reserve-funds/payments/payment-detail/PaymentDetailPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'detail': childConfig.component = () => import('@/pages/reserve-funds/payments/payment-detail/detail/PersonalPaymentsDetailSubPage.vue'); break
            case 'plans': childConfig.component = () => import('@/pages/reserve-funds/payments/payment-detail/personal-plans/PersonalPaymentPlansSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutes.account.name: {
        config.component = () => import('@/pages/account/AccountPage.vue'); break
      }

      case staticRoutes.buildingsList.name: {
        config.component = () => import('@/pages/buildings/BuildingsListPage.vue'); break
      }

      case staticRoutes.buildingDetail.name: {
        config.component = () => import('@/pages/buildings/BuildingDetailPage.vue'); break
      }

      case staticRoutes.buildingEdit.name: {
        config.component = () => import('@/pages/buildings/BuildingEditPage.vue'); break
      }

      case staticRoutes.buildingRegister.name: {
        config.component = () => import('@/pages/buildings/BuildingRegisterPage.vue'); break
      }

      case staticRoutes.ownersList.name: {
        config.component = () => import('@/pages/owners/OwnersListPage.vue'); break
      }
      case staticRoutes.ownerDetail.name:
        config.component = () => import('@/pages/owners/OwnerDetailPage.vue'); break
      case staticRoutes.ownerEdit.name:
        config.component = () => import('@/pages/owners/OwnerEditPage.vue'); break
      case staticRoutes.ownerChange.name:
        config.component = () => import('@/pages/owners/OwnerEditPage.vue'); break

      case staticRoutes.usersSearch.name:
        config.component = () => import('@/pages/users/UsersSearchPage.vue'); break

      case staticRoutes.staffsList.name: {
        config.component = () => import('@/pages/staffs/staffs-list/StaffsListPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'serviceStaff': childConfig.component = () => import('@/pages/staffs/staffs-list/StaffsListSubPage.vue'); break
            case 'front': childConfig.component = () => import('@/pages/staffs/staffs-list/StaffsListSubPage.vue'); break
            case 'lifeManager': childConfig.component = () => import('@/pages/staffs/staffs-list/StaffsListSubPage.vue'); break
          }
          return childConfig
        })
        break
      }
      case staticRoutes.serviceStaffRegister.name: {
        config.component = () => import('@/pages/staffs/StaffRegisterPage.vue'); break
      }
      case staticRoutes.frontRegister.name: {
        config.component = () => import('@/pages/staffs/StaffRegisterPage.vue'); break
      }
      case staticRoutes.lifeManagerRegister.name: {
        config.component = () => import('@/pages/staffs/StaffRegisterPage.vue'); break
      }
      case staticRoutes.serviceStaffDetail.name: {
        config.component = () => import('@/pages/staffs/StaffDetailPage.vue'); break
      }
      case staticRoutes.frontDetail.name: {
        config.component = () => import('@/pages/staffs/StaffDetailPage.vue'); break
      }
      case staticRoutes.lifeManagerDetail.name: {
        config.component = () => import('@/pages/staffs/StaffDetailPage.vue'); break
      }
      case staticRoutes.serviceStaffEdit.name: {
        config.component = () => import('@/pages/staffs/StaffEditPage.vue'); break
      }
      case staticRoutes.frontEdit.name: {
        config.component = () => import('@/pages/staffs/StaffEditPage.vue'); break
      }
      case staticRoutes.lifeManagerEdit.name: {
        config.component = () => import('@/pages/staffs/StaffEditPage.vue'); break
      }

      case staticRoutes.consultationsList.name: {
        config.component = () => import('@/pages/consultations/consultations-list/ConsultationsListPage.vue'); break
      }

      case staticRoutes.consultationDetail.name: {
        config.component = () => import('@/pages/consultations/consultation-detail/ConsultationDetailPage.vue'); break
      }

      case staticRoutes.ownerNotificationsList.name: {
        config.component = () => import('@/pages/owner-notifications/OwnerNotificationsListPage.vue'); break
      }
      case staticRoutes.ownerNotificationCreate.name: {
        config.component = () => import('@/pages/owner-notifications/OwnerNotificationPostPage.vue'); break
      }
      case staticRoutes.ownerNotificationUpdate.name: {
        config.component = () => import('@/pages/owner-notifications/OwnerNotificationPostPage.vue'); break
      }
      case staticRoutes.ownerNotificationDetail.name: {
        config.component = () => import('@/pages/owner-notifications/OwnerNotificationDetailPage.vue'); break
      }
      case staticRoutes.ownerNotificationPreview.name: {
        config.component = () => import('@/pages/owner-notifications/OwnerNotificationPreviewPage.vue'); break
      }

      case staticRoutes.notices.name:
        config.component = () => import('@/pages/notices/NoticesListPage.vue'); break

      case staticRoutes.questionnaireDetail.name: {
        config.component = () => import('@/pages/questionnaires/questionnaire-detail/QuestionnaireDetailPage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'detail': childConfig.component = () => import('@/pages/questionnaires/questionnaire-detail/detail/DetailSubPage.vue'); break
            case 'answer': childConfig.component = () => import('@/pages/questionnaires/questionnaire-detail/answer/AnswerSubPage.vue'); break
            case 'respondents': childConfig.component = () => import('@/pages/questionnaires/questionnaire-detail/respondents/RespondentsSubPage.vue'); break
            case 'report': childConfig.component = () => import('@/pages/questionnaires/questionnaire-detail/report/ReportSubPage.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutes.questionnaireDetailPrint.name: {
        config.component = () => import('@/pages/questionnaires/questionnaire-detail/detail/DetailPrintPage.vue'); break
      }

      case staticRoutes.questionnaireRespondentDetail.name: {
        config.component = () => import('@/pages/questionnaires/questionnaire-detail/respondents/RespondentDetailPage.vue'); break
      }

      case staticRoutes.questionnaireReportPrint.name: {
        config.component = () => import('@/pages/questionnaires/QuestionnaireReportPrintPage.vue'); break
      }

      case staticRoutes.reports.name: {
        config.component = () => import('@/pages/reports/ReportsPage.vue'); break
      }

      case staticRoutes.notFound.name: {
        config.component = () => import('@/pages/errors/NotFoundPage.vue'); break
      }

      default: {
        // 開発時にエラー原因に気づきやすいようにするためのもの。単体テスト以降で通過想定なし
        throw new Error(`${_route.name} page component not defined`)
      }
    }

    return config
  })
}

function formatRouteConfigsLocal(): RouteConfig[] {
  return Object.values(staticRoutesLocal).map(_route => {
    const config = configure(_route)

    switch (_route.name) {
      case staticRoutesLocal.helloWorld.name: {
        config.component = () => import('@/pages/HelloWorld.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'page1': childConfig.component = () => import('@/pages/HelloWorldPage1.vue'); break
            case 'page2': childConfig.component = () => import('@/pages/HelloWorldPage2.vue'); break
            case 'page3': childConfig.component = () => import('@/pages/HelloWorldPage3.vue'); break
            case 'page4': childConfig.component = () => import('@/pages/HelloWorldPage4.vue'); break
            case 'page5': childConfig.component = () => import('@/pages/HelloWorldPage5.vue'); break
            case 'page6': childConfig.component = () => import('@/pages/HelloWorldPage6.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutesLocal.templateUsage.name: {
        config.component = () => import('@/pages/_samples/TemplateUsage.vue')

        config.children = Object.entries(_route.children ?? {}).map(([key, child]) => {
          const childConfig = configure(child)
          switch (key) {
            case 'tab1': childConfig.component = () => import('@/pages/_samples/TemplateTab1.vue'); break
            case 'tab2': childConfig.component = () => import('@/pages/_samples/TemplateTab2.vue'); break
          }
          return childConfig
        })
        break
      }

      case staticRoutesLocal.draftInterceptor.name:
        config.component = () => import('@/pages/_samples/DraftInterceptor.vue'); break

      default: {
        throw new Error(`${_route.name} page component not defined`)
      }
    }
    return config
  })
}
