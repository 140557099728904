import { User } from '@/dtos/commons'
import { ResolutionPreparingStatus } from '../results/get'

export class PreparingStatusPostRequest {
  ideaId!: string
  resolutionPreparingStatusBody!: string

  constructor(ideaId: string, resolutionPreparingStatusBody: string) {
    this.ideaId = ideaId
    this.resolutionPreparingStatusBody = resolutionPreparingStatusBody
  }
}

export class PreparingStatusPostResponse {
  resolutionPreparingStatusId!: string
  postedAt!: string
  version!: number
}

export class PreparingStatusUpdateDto extends ResolutionPreparingStatus {
  ideaId!: string

  constructor(req: PreparingStatusPostRequest, res: PreparingStatusPostResponse, admin:User) {
    super()
    this.ideaId = req.ideaId
    this.resolutionPreparingStatusId = res.resolutionPreparingStatusId
    this.resolutionPreparingStatusBody = req.resolutionPreparingStatusBody
    this.admin = admin
    this.postedAt = res.postedAt
    this.version = res.version
  }
}
