




































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import { QUESTIONNAIRE_QUESTION_ELEMENT_TYPES } from '@/constants/schema-constants'

export class CheckboxQuestionOption {
  value!: string
  optionText!: string
  isSelected?: boolean
  elementType!:number
  inputOtherText?: string
}

@Component({
  components: {
    SmOwTextRequired: () => import('@/components/owner-apps/SmOwTextRequired.vue'),
    SmOwCheckBoxInverse: () => import('@/components/owner-apps/SmOwCheckBoxInverse.vue'),

    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
  }
})

export default class SmOwCheckboxQuestion extends Vue {
  @Model('input')
  options!: CheckboxQuestionOption[]

  @Prop({ required: true, default: '' })
  private readonly label!: string

  @Prop({ required: true, default: false })
  private readonly isRequired!: boolean

  private textLock = true

  private otherText = ''

  created(): void {
    const option = this._options.find(option => option.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION)
    this.textLock = option ? !option.isSelected : true
    this.otherText = (option && option.inputOtherText) ? option.inputOtherText : ''
  }

  private get hasNoOtherOption(): boolean {
    return !this._options.find(option => option.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION)
  }

  private onClickCheckbox(option:CheckboxQuestionOption): void {
    if (option.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION && option.isSelected) this.textLock = false
    if (option.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION && !option.isSelected) {
      this.textLock = true
      option.inputOtherText = ''
      this.otherText = ''
    }
    this.$emit('input', this._options)
  }

  private changeText(newText: string): void {
    this._options.forEach(option => {
      if (option.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION) {
        option.inputOtherText = newText
      }
    })
    this.$emit('input', this._options)
  }

  private get inputText(): string { return this.otherText }
  private set inputText(nreValue: string) {
    this.otherText = nreValue
    this.$emit('input', this._options)
  }

  private get otherOption(): CheckboxQuestionOption {
    return this._options.filter(option => option.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION)[0]
  }

  private get _options(): CheckboxQuestionOption[] { return this.options }
  private set _options(newValue: CheckboxQuestionOption[]) { this.$emit('input', newValue) }
}
