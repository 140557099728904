import { Type } from 'class-transformer'
import { User } from '@/dtos/commons'
import { Details } from '@/dtos/resolutions/commons'
import { Vote } from '@/dtos/resolutions/gm-resolution/statements/common'
import type { HoldingMethodType, ResolutionState, SubjectType, StatementType } from '@/constants/schema-constants'

export class StatementsGetRequest {
  resolutionId!: string
}
export class SummaryStatementDetailAgreeCountDto {
  votingRightCount!: number
  ownerCount?: number
}
export class SummaryStatementDto {
  votingRightCount!: number
  ownerCount!: number
}

export class SummaryAgreement {
  ownerCount?: number
  totalOwnerCount?: number
  ownerRate?: number
  votingRightCount!: number
  totalVotingRightCount!: number
  votingRightRate!: number
  @Type(() => SummaryStatementDetailAgreeCountDto) presentLocal?: SummaryStatementDetailAgreeCountDto
  @Type(() => SummaryStatementDetailAgreeCountDto) presentRemote?: SummaryStatementDetailAgreeCountDto
  @Type(() => SummaryStatementDetailAgreeCountDto) exerciseVote: SummaryStatementDetailAgreeCountDto = new SummaryStatementDetailAgreeCountDto()
}

export class SummaryVote {
  subjectId!: string
  subjectType!: SubjectType
  subjectTitle!: string
  @Type(() => SummaryAgreement) agreements: SummaryAgreement = new SummaryAgreement()
}

export class SummaryDetail {
  attendanceOwnerCount!: number
  @Type(() => SummaryVote) vote: SummaryVote[] = []
}

export class SummaryStatement {
  ownerCount!: number
  votingRightCount!: number
  totalVotingRightCount!: number
  votingRightRate!: number
  @Type(() => SummaryDetail) details: SummaryDetail = new SummaryDetail()
  @Type(() => SummaryStatementDto) presentLocal?: SummaryStatementDto
  @Type(() => SummaryStatementDto) presentRemote?: SummaryStatementDto
  @Type(() => SummaryStatementDto) exerciseVote: SummaryStatementDto = new SummaryStatementDto()
}

export class Summary {
  buildingUnitCount!: number
  ownerCount!: number
  votingRightCount!: number
  @Type(() => SummaryStatement) statement: SummaryStatement = new SummaryStatement()
}

export class Statement {
  userId!: string
  roomNumber!: string
  userName!: string
  votingRightCount!: number
  statementType?: StatementType
  isStatement!: boolean
  @Type(() => Vote) vote: Vote[] = []
}

export class StatementsGetResponse {
  resolutionId!: string
  resolutionState!: ResolutionState
  postedAt!: string
  title!: string
  @Type(() => User) user: User = new User()
  @Type(() => Details) details: Details = new Details()
  @Type(() => Summary) summary: Summary = new Summary()
  @Type(() => Statement) statements: Statement[] = []
  holdingMethodType!: HoldingMethodType
  version!: number
}
