






























































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'

import { repairPlanExcelParser } from '@/libs/excel-parser/repair-plan-parser'
import { buildingsModule } from '@/stores/buildings-store'
import { repairsModule } from '@/stores/repairs-store'
import { reserveFundsModule } from '@/stores/reserve-funds-store'
import { errorsModule } from '@/stores/errors'

import { Tab } from '@/components/molecules/SmTabs.vue'
import { BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { RepairsExpensesGetRequest, RepairsExpensesGetResponse } from '@/dtos/repairs/expenses/get'
import { RepairsItemsGetRequest, RepairsItemsGetResponse } from '@/dtos/repairs/items/get'
import { RepairsUpdatesGetRequest, RepairsUpdatesGetResponse } from '@/dtos/repairs/updates/get-detail'
import { UploadedInfo } from '@/pages/repair-plan/repair-plan-detail/SmDialogRepairPlanUpload.vue'
import { RepairPlanPutRequest } from '@/dtos/repairs/plans/put'

const RESTRICTED_MENU_ITEMS: {[id: string]: { text: string }} = {
  updateHistory: { text: '更新履歴' }
}

const MENU_ITEMS: {[id: string]: { text: string }} = {
  updateHistory: { text: '更新履歴' },
  resetting: { text: '当初計画を再設定する' }
}

const DOWNLOAD_FAILED_MESSAGES = ['ファイルのダウンロードに失敗しました。画面を読み込み直すか、しばらくしてから再度お試しください。']
const INVALID_FORMAT_MESSAGES = ['ファイルの形式が想定と異なるか、不正な値が設定されているため、アップロードできません。']

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTabs: () => import('@/components/molecules/SmTabs.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    SmDialogRepairPlanUpload: () => import('@/pages/repair-plan/repair-plan-detail/SmDialogRepairPlanUpload.vue'),
    SmTableRepairPlanUpdateDetails: () => import('@/pages/repair-plan/SmTableRepairPlanUpdateDetails.vue'),
  }
})
export default class RepairPlanDetailPage extends Mixins(CurrentAdminManager) {
  @Prop()
  needsGuideDialog?: string

  async created(): Promise<void> {
    if (this.needsGuideDialog) { this.openGuideDialog() }
    if (this.$isServiceStaff) { this.menuItems = MENU_ITEMS }
    await Promise.all([
      this.refreshPage(),
      repairsModule.fetchRepairsUpdates(new RepairsUpdatesGetRequest()),
    ])
  }

  async refreshPage(): Promise<void> {
    await Promise.all([
      repairsModule.fetchRepairsExpenses(new RepairsExpensesGetRequest()),
      repairsModule.fetchRepairsItems(new RepairsItemsGetRequest()),
    ])
    if (!this.repairsExpenses.repairPlan) { this.$router.replace({ name: staticRoutes.repairPlanRegister.name }) }
  }

  tabs = [
    new Tab('収支計画', staticRoutes.repairPlanDetail.getChild('summary').name),
    new Tab('工事計画', staticRoutes.repairPlanDetail.getChild('construction').name),
  ]

  private get repairsUpdates(): RepairsUpdatesGetResponse { return repairsModule.repairsUpdatesGet }
  private get repairsExpenses(): RepairsExpensesGetResponse { return repairsModule.repairsExpensesGet }
  private get repairsItems(): RepairsItemsGetResponse { return repairsModule.repairsItemsGet }
  private get buildingDetail(): BuildingDetailGetResponse | null { return buildingsModule.buildingDetailGet }

  menuId = 'updateHistory'
  menuItems = RESTRICTED_MENU_ITEMS

  onClickMenu(id: string): void {
    switch (id) {
      case 'updateHistory' : this.$router.push({ name: staticRoutes.repairPlanUpdates.name }); break
      case 'resetting' : this.openUploadDialog('reset'); break
    }
  }

  async outputExcelFile(): Promise<void> {
    errorsModule.clearGlobalErrors()
    await repairsModule.fetchRepairPlan()

    const data = repairsModule.repairPlanGetResponse()
    if (!data) {
      errorsModule.setGlobalErrors(DOWNLOAD_FAILED_MESSAGES)
      return
    }

    try {
      await repairPlanExcelParser.downloadAsExcel(data, buildingsModule.periodToYearMonth)
    } catch (err) {
      errorsModule.setGlobalErrors(DOWNLOAD_FAILED_MESSAGES)
      throw err
    }
  }

  dialogType = ''
  isUploadDialogVisible = false

  openUploadDialog(type: string): void {
    this.dialogType = type
    this.isUploadDialogVisible = true
  }

  closeUploadDialog(): void {
    this.dialogType = ''
    this.uploadedFile = null
    this.selectedPeriod = 1
    this.isUploadDialogVisible = false
  }

  uploadedFile: File | null = null
  selectedPeriod = 1
  isConfirmationDialogVisible = false

  openConfirmationDialog(uploadedInfo: UploadedInfo): void {
    this.uploadedFile = uploadedInfo.file
    this.selectedPeriod = uploadedInfo.selectedPeriod

    this.isUploadDialogVisible = false
    this.isConfirmationDialogVisible = true
  }

  closeConfirmationDialog(): void {
    this.uploadedFile = null
    this.selectedPeriod = 1

    this.isConfirmationDialogVisible = false
  }

  async updateRepairPlan(): Promise<void> {
    const succeeded = await this._putRepairPlanRequest(false)
    if (succeeded) await this.refreshPage()
  }

  async registerNewOriginalPlan(): Promise<void> {
    const succeeded = await this._putRepairPlanRequest(true)
    if (!succeeded) return

    await this.refreshPage()
    this.openGuideDialog()
  }

  private async _putRepairPlanRequest(isReset: boolean): Promise<boolean> { // succeeded or not
    if (!this.uploadedFile) return Promise.resolve(false)
    this.isConfirmationDialogVisible = false
    errorsModule.clearGlobalErrors()

    const data = await repairPlanExcelParser.parse(this.uploadedFile)

    if (!data) {
      errorsModule.setGlobalErrors(INVALID_FORMAT_MESSAGES)
      return false
    }

    const req = new RepairPlanPutRequest(
      isReset,
      this.selectedPeriod,
      data.planningStartPeriod,
      data.uploadedFileName,
      data.categories
    )
    await repairsModule.putRepairPlan(req)
    return true
  }

  isGuideDialogAfterResettingVisible = false
  isGuideDialogAfterInitializationVisible = false

  openGuideDialog(): void {
    if (this.dialogType === 'reset') {
      this.isGuideDialogAfterResettingVisible = true
    } else {
      this.isGuideDialogAfterInitializationVisible = true
    }
  }

  async onClickModifyReserves(): Promise<void> {
    this.isGuideDialogAfterResettingVisible = false
    await reserveFundsModule.putReservesUpdatable()
    this.goToReserveFundsDetail()
  }

  goToRepairPlanComments(): void {
    this.$router.push({ name: staticRoutes.repairPlanComments.name })
  }

  goToRepairPlanCommentsCreate(): void {
    this.$router.push({ name: staticRoutes.repairPlanCommentsCreate.name })
  }

  goToReserveFundsDetail(): void {
    this.isGuideDialogAfterResettingVisible = false
    this.isGuideDialogAfterInitializationVisible = false
    this.$router.push({ name: staticRoutes.reserveFunds.name })
  }
}
