import Vue from 'vue'
import { ReservePaymentsCheckedPostRequest } from '@/dtos/reserve-funds/payments/checked/post'
import { reservePaymentsClient } from '@/clients/reserve-payments-client'
import { ReservePaymentsGetResponse } from '@/dtos/reserve-funds/payments/get'
import { ReservePaymentsPutRequest } from '@/dtos/reserve-funds/payments/put'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { ReservesPaymentsCheckedGetResponse } from '@/dtos/reserve-funds/payments/checked/get'
import { ReservePaymentDetailGetRequest, ReservePaymentDetailGetResponse } from '@/dtos/reserve-funds/payments/get-detail'
import { PersonalPlansGetRequest, PersonalPlansGetResponse } from '@/dtos/reserve-funds/payments/plans/get'
import { PersonalPlansPostRequest } from '@/dtos/reserve-funds/payments/plans/post'
import { PersonalPlansInitialFormGetRequest, PersonalPlansInitialFormGetResponse } from '@/dtos/reserve-funds/payments/plans/initial-form/get-detail'
import { ReservePaymentsPdfGetRequest, ReservesPaymentsPdfGetResponse } from '@/dtos/reserve-funds/payments/pdf/get'
import { ReservesPersonalPlanProspectsGetRequest, ReservesPersonalPlanProspectsGetResponse } from '@/dtos/reserve-funds/payments/plans/prospects/get'

@Module({ store, dynamic: true, namespaced: true, name: 'reservePayments' })
class ReservePaymentsStore extends VuexModule {
  // 住戸別支払計画一覧
  private _payments = new ReservePaymentsGetResponse()

  public get payments():ReservePaymentsGetResponse { return this._payments }

  @Mutation
  private SET_PAYMENTS(res:ReservePaymentsGetResponse):void {
    this._payments = res
  }

  @Action
  public async fetchPayments():Promise<void> {
    const res = await reservePaymentsClient.getPayments()
    this.SET_PAYMENTS(res)
  }

  // 住戸別支払計画更新
  @Action
  public async putPayments(req:ReservePaymentsPutRequest):Promise<void> {
    await reservePaymentsClient.putPayments(req)
  }

  // 住戸別支払計画 積立計画詳細
  private _paymentDetails:Record<string, ReservePaymentDetailGetResponse> = {}

  get paymentDetail(): (id: string) => ReservePaymentDetailGetResponse | undefined { return (id: string) => this._paymentDetails[id] }

  @Mutation
  private SET_PAYMENT_DETAIL(res: ReservePaymentDetailGetResponse): void {
    Vue.set(this._paymentDetails, res.buildingUnitId, res)
  }

  @Action
  public async fetchPaymentDetail(req:ReservePaymentDetailGetRequest):Promise<void> {
    const res = await reservePaymentsClient.getPaymentDetail(req)
    this.SET_PAYMENT_DETAIL(res)
  }

  // PDF出力用データ取得
  private _paymentPlansPdf = new ReservesPaymentsPdfGetResponse()

  get paymentPlansPdf():ReservesPaymentsPdfGetResponse { return this._paymentPlansPdf }

  @Mutation
  private SET_PAYMENT_PLANS_PDF(res:ReservesPaymentsPdfGetResponse) { this._paymentPlansPdf = res }

  @Action
  public async fetchPaymentsPdf(req:ReservePaymentsPdfGetRequest):Promise<void> {
    const res = await reservePaymentsClient.getPaymentsPdf(req)
    this.SET_PAYMENT_PLANS_PDF(res)
  }

  // CSV出力
  @Action
  public async getPaymentsCsv():Promise<void> {
    await reservePaymentsClient.getPaymentsCsv()
  }

  // 確定状況取得
  private _reservesPaymentsChecked = new ReservesPaymentsCheckedGetResponse()

  public get reservesPaymentsChecked():ReservesPaymentsCheckedGetResponse { return this._reservesPaymentsChecked }

  @Mutation
  private SET_RESERVES_PAYMENTS_CHECKED(res:ReservesPaymentsCheckedGetResponse):void { this._reservesPaymentsChecked = res }

  @Action
  public async fetchReservesPaymentsChecked():Promise<void> {
    const res = await reservePaymentsClient.getPaymentsChecked()
    this.SET_RESERVES_PAYMENTS_CHECKED(res)
  }

  // 確定登録
  @Action
  public async postReserveChecked(req:ReservePaymentsCheckedPostRequest):Promise<void> {
    await reservePaymentsClient.postPaymentsChecked(req)
  }

  // 住戸別支払計画支払いプラン一覧
  private _personalPlans:Record<string, PersonalPlansGetResponse> = {}

  public get personalPlans():(id:string) => PersonalPlansGetResponse | undefined { return (id:string) => this._personalPlans[id] }

  @Mutation
  private SET_PERSONAL_PLANS(res: PersonalPlansGetResponse): void {
    Vue.set(this._personalPlans, res.buildingUnitId, res)
  }

  @Action
  public async fetchPersonalPlans(req:PersonalPlansGetRequest) {
    const res = await reservePaymentsClient.getPersonalPlans(req)
    this.SET_PERSONAL_PLANS(res)
  }

  // 住戸別支払計画支払いプラン登録
  @Action
  public async postPersonalPlans(req:PersonalPlansPostRequest) {
    await reservePaymentsClient.postPersonalPlans(req)
  }

  // 住戸別支払いプラン追加 初期フォーム取得
  private _personalPlansInitialForm = new PersonalPlansInitialFormGetResponse()

  public get personalPlansInitialForm():PersonalPlansInitialFormGetResponse { return this._personalPlansInitialForm }

  @Mutation
  private SET_PERSONAL_PLANS_INITIAL_FORM(res:PersonalPlansInitialFormGetResponse) { this._personalPlansInitialForm = res }

  @Action
  public async fetchPersonalPlansInitialForm(req:PersonalPlansInitialFormGetRequest) {
    const res = await reservePaymentsClient.getPersonalPlansInitialForm(req)
    this.SET_PERSONAL_PLANS_INITIAL_FORM(res)
  }

  // 住戸別専用支払いプラン検討用excel出力のための将来データ取得
  private _paymentPersonalProspects: Record<string, ReservesPersonalPlanProspectsGetResponse> = {}
  public get paymentPersonalProspect(): (buildingUnitId: string) => ReservesPersonalPlanProspectsGetResponse | undefined {
    return (id: string) => this._paymentPersonalProspects[id]
  }

  @Mutation private SET_PAYMENT_PERSONAL_PROSPECT({ res, id }: {res: ReservesPersonalPlanProspectsGetResponse, id: string}) {
    Vue.set(this._paymentPersonalProspects, id, res)
  }

  @Action public async fetchPaymentPersonalProspect(req: ReservesPersonalPlanProspectsGetRequest) {
    const res = await reservePaymentsClient.getPaymentPersonalProspects(req)
    this.SET_PAYMENT_PERSONAL_PROSPECT({ res, id: req.buildingUnitId })
  }
}

export const reservePaymentsModule = getModule(ReservePaymentsStore)
