var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sm__dashboard"},[_c('div',{staticClass:"mt-6"},[_c('sm-tickets-tasks-summary',{attrs:{"id":"sm-tickets-tasks-summary","near-deadline-tasks-title":"一週間期限のタスク","tickets-icon-name":"$content_copy_round","tickets-title":"チケット","tasks-icon-name":"$task_alt_round","tasks-title":"タスク","tickets-tasks-summary":_vm.dashboardSummary},on:{"click-tickets-count":_vm.clickTicketsCount,"click-tasks-count":_vm.clickTasksCount,"click-near-deadline-tasks-count":_vm.clickNearDeadlineTasksCount}})],1),_c('div',{staticClass:"mt-6 px-6 py-6 sm__background-white"},[_c('sm-text',{attrs:{"text":"担当者別処理状況","format":"sm__title","color":"black"}}),_c('sm-table-dashboard-processing-status',{staticClass:"mt-4",attrs:{"items":_vm.processingStatusTableItem},on:{"go-tickets-list":_vm.goTicketsList,"go-not-started-tasks-list":_vm.goNotStartedYetTasksList,"go-not-completed-tasks-list":_vm.goNotCompletedTasksList,"go-near-deadline-tasks-list":_vm.goNearDeadlineTasksList,"go-expired-tasks-list":_vm.goExpiredTasksList}})],1),_c('div',{staticClass:"mt-6 px-6 py-4 sm__background-white sm__ticket-table"},[_c('sm-expansion-area',{attrs:{"header-title":_vm.ticketHeaderTitle,"icon-color":"black","title-color":"black","title-format":"sm__title","link-title":"チケット一覧"},on:{"go-target-page":_vm.goTicketsListPage},model:{value:(_vm.isFilterMenuOpenOfTicket),callback:function ($$v) {_vm.isFilterMenuOpenOfTicket=$$v},expression:"isFilterMenuOpenOfTicket"}},[_c('div',{staticClass:"mt-4"},[_c('sm-table-data-external-paging',{attrs:{"headers":_vm.TicketTableHeaders,"items":_vm.tickets,"item-key":"ticket_id","content-name":"担当者未設定チケット","total-item-length":999,"is-transition-click":"","is-init-search":""},on:{"click":_vm.goToTicketDetailPage},scopedSlots:_vm._u([{key:"item.buildingName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.buildingId)?_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.buildingName,"format":"sm__data","color":"black"}}):_c('sm-text',{attrs:{"text":"未設定","format":"sm__data","color":"black"}})],1)]}},{key:"item.ticketType",fn:function(ref){
var item = ref.item;
return [_c('sm-badge-category',{attrs:{"icon":item.ticketTypeIcon,"icon-color":item.ticketTypeIconColor,"text":item.ticketTypeText,"text-style":item.ticketTypeTextStyle}})]}},{key:"item.ticketName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.ticketName,"format":"sm__data","color":"black"}})],1)]}}],null,true)})],1)])],1),_c('div',{staticClass:"my-6 px-6 py-4 sm__background-white sm__ticket-table"},[_c('sm-expansion-area',{attrs:{"header-title":_vm.deadlineTaskHeaderTitle,"icon-color":"black","sub-icon-name":"$local_fire_department_outlined","sub-icon-color":"caution","title-color":"black","title-format":"sm__title","link-title":"タスク一覧"},on:{"go-target-page":_vm.goTasksListPageExpired},model:{value:(_vm.isFilterMenuOpenOfDeadlineTask),callback:function ($$v) {_vm.isFilterMenuOpenOfDeadlineTask=$$v},expression:"isFilterMenuOpenOfDeadlineTask"}},[_c('div',{staticClass:"mt-4"},[_c('sm-table-data-external-paging',{attrs:{"headers":_vm.deadlineTaskTableHeaders,"items":_vm.deadlineTasks,"item-key":"ticket_task_id","content-name":"期限切れタスク","total-item-length":999,"is-transition-click":"","is-init-search":""},on:{"click":_vm.goToTicketDetailPageTaskTab},scopedSlots:_vm._u([{key:"item.adminShortName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.adminShortName)?_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.adminShortName,"format":"sm__data","color":"black"}}):_c('sm-text',{attrs:{"text":"未設定","format":"sm__data","color":"black"}})],1)]}},{key:"item.buildingName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.buildingId)?_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.buildingName,"format":"sm__data","color":"black"}}):_c('sm-text',{attrs:{"text":"未設定","format":"sm__data","color":"black"}})],1)]}},{key:"item.ticketType",fn:function(ref){
var item = ref.item;
return [_c('sm-badge-category',{attrs:{"icon":item.ticketTypeIcon,"icon-color":item.ticketTypeIconColor,"text":item.ticketTypeText,"text-style":item.ticketTypeTextStyle}})]}},{key:"item.ticketTaskName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.ticketTaskName,"format":"sm__data","color":"black"}})],1)]}},{key:"item.ticketName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.ticketName,"format":"sm__data","color":"black"}})],1)]}}],null,true)})],1)])],1),_c('div',{staticClass:"my-6 px-6 py-4 sm__background-white sm__ticket-table"},[_c('sm-expansion-area',{attrs:{"header-title":_vm.responseOwnerTaskHeaderTitle,"title-color":"black","title-format":"sm__title","link-title":"タスク一覧"},on:{"go-target-page":_vm.goTasksListPageFilteredResponseOwnerTask},model:{value:(_vm.isFilterMenuOpenOfResponseOwnerTask),callback:function ($$v) {_vm.isFilterMenuOpenOfResponseOwnerTask=$$v},expression:"isFilterMenuOpenOfResponseOwnerTask"}},[_c('div',{staticClass:"mt-4"},[_c('sm-table-data-external-paging',{attrs:{"headers":_vm.responseOwnerTaskTableHeaders,"items":_vm.responseOwnerTasks,"item-key":"ticket_task_id","content-name":"区分所有者への対応タスク","total-item-length":999,"is-transition-click":"","is-init-search":""},on:{"click":_vm.goToTicketDetailPageTaskTab},scopedSlots:_vm._u([{key:"item.adminShortName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.adminShortName)?_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.adminShortName,"format":"sm__data","color":"black"}}):_c('sm-text',{attrs:{"text":"未設定","format":"sm__data","color":"black"}})],1)]}},{key:"item.buildingName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.buildingId)?_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.buildingName,"format":"sm__data","color":"black"}}):_c('sm-text',{attrs:{"text":"未設定","format":"sm__data","color":"black"}})],1)]}},{key:"item.ticketType",fn:function(ref){
var item = ref.item;
return [_c('sm-badge-category',{attrs:{"icon":item.ticketTypeIcon,"icon-color":item.ticketTypeIconColor,"text":item.ticketTypeText,"text-style":item.ticketTypeTextStyle}})]}},{key:"item.ticketTaskName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.ticketTaskName,"format":"sm__data","color":"black"}})],1)]}},{key:"item.ticketName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('sm-text',{staticClass:"text-no-wrap",attrs:{"text":item.ticketName,"format":"sm__data","color":"black"}})],1)]}}],null,true)})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }