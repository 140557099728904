









































































































import { Vue, Mixins, Component, Prop, Watch } from 'vue-property-decorator'
import { NavigationGuardNext, Route } from 'vue-router/types'
import { ConsultationUpdateFormInputs } from '@/components/molecules/card/SmCardConsultationUpdate.vue'
import type { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'
import { MATERIAL_TYPES } from '@/constants/schema-constants'

import { MaterialFormInputDto, User } from '@/dtos/commons'

import { ConsultationDetailGetRequest, ConsultationDetailGetResponseConsultation } from '@/dtos/consultations/get-detail'
import { ConsultationsPostRequest } from '@/dtos/consultations/post'
import { ConsultationsPutRequest } from '@/dtos/consultations/put'
import { ConsultationsDeleteRequest } from '@/dtos/consultations/delete'
import { ConsultationDraftDeleteRequest } from '@/dtos/consultations/draft/delete'
import { ConsultationDraftGetRequest } from '@/dtos/consultations/draft/get'

import { ConsultationsReadsPostRequest } from '@/dtos/consultations/reads/post'
import { ConsultationReactionPostRequest } from '@/dtos/consultations/reactions/post'
import { ConsultationReactionDeleteRequest } from '@/dtos/consultations/reactions/delete'

import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { ConsultationPostFormInputs } from '@/pages/consultations/consultation-detail/ConsultationsPostForm.vue'
import { consultationsModule } from '@/stores/consultations-store'
import { errorsModule } from '@/stores/errors'
import { ConsultationDraftPostRequest } from '@/dtos/consultations/draft/post'

const SKIP = 0
const TAKE = 20

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardConsultationDetail: () => import('@/components/molecules/card/SmCardConsultationDetail.vue'),
    SmCardConsultationUpdate: () => import('@/components/molecules/card/SmCardConsultationUpdate.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    SmDraftInterceptor: () => import('@/components/organisms/SmDraftInterceptor.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    ConsultationsPostForm: () => import('@/pages/consultations/consultation-detail/ConsultationsPostForm.vue'),
  }
})
export default class ConsultationDetailPage extends Mixins(CurrentAdminManager) {
  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)

  @Prop({ required: true })
  userId!: string

  @Prop()
  keyword?: string

  private async created(): Promise<void> {
    await consultationsModule.fetchConsultationDraft(new ConsultationDraftGetRequest(this.userId))
    this.copiedConsultations = []
    await consultationsModule.fetchConsultationDetailOwnerUser(new ConsultationDetailGetRequest(this.userId, 0, 1))
    await consultationsModule.postConsultationsReads(new ConsultationsReadsPostRequest(this.consultationDetailOwnerUser.userId))
  }

  private get consultationDetailOwnerUser(): User { return consultationsModule.consultationDetailOwnerUserGet }
  private get pageTitle(): string { return `${this.consultationDetailOwnerUser.roomNumber}｜${this.consultationDetailOwnerUser.userName}` }

  copiedConsultations: ConsultationDetailGetResponseConsultation[] = []

  async fetchCopiedConsultations(): Promise<void> {
    await consultationsModule.fetchConsultationDetailConsultations(this.request)

    const fetchedConsultations = consultationsModule.consultationDetailConsultationsGet
    const additionalCopiedConsultations = deepCopy(
      fetchedConsultations,
      {
        ConsultationDetailGetResponseConsultation: new ColumnToType(ConsultationDetailGetResponseConsultation),
        user: new ColumnToType(User),
        material: new ColumnToType(MaterialFormInputDto),
      },
      'ConsultationDetailGetResponseConsultation'
    )

    this.copiedConsultations.unshift(...additionalCopiedConsultations.reverse())
  }

  identifier = 1
  request = new ConsultationDetailGetRequest(this.userId, SKIP, TAKE)
  private async loadConsultations(handler: LoadingHandler): Promise<void> {
    this.handler = handler
    this.request.skip = this.copiedConsultations.length
    await this.fetchCopiedConsultations()

    if (this.copiedConsultations.length === 0) {
      // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
      handler.complete()
      return
    }

    handler.loaded()
    const expectingToBe = this.request.skip + this.request.take
    if (this.copiedConsultations.length < expectingToBe) handler.complete()
  }

  private isMyMessage(messageUserId: string): boolean {
    return this.$isCurrentAdmin(messageUserId)
  }

  private isFocused(message: string): boolean {
    if (!this.keyword) { return false }

    return message.indexOf(this.keyword) !== -1
  }

  private changeEditMode(consultationId: string, isEdit: boolean): void {
    const targetConsultation = this.copiedConsultations.find(c => c.consultationId === consultationId)
    if (targetConsultation !== undefined) {
      targetConsultation.isEditMode = isEdit
    }
  }

  private switchToEditMode(consultationId: string): void {
    this.changeEditMode(consultationId, true)
  }

  private cancelEditMode(consultationId: string): void {
    this.changeEditMode(consultationId, false)
  }

  targetConsultationIndex: number | null = null
  targetConsultationId: string | null = null
  targetConsultationVersion: number | null = null
  targetConsultationInputs = new ConsultationUpdateFormInputs()

  isPostFormOpened = false

  private closePostForm(): void {
    this.isPostFormOpened = false
  }

  postConsultationInputs: ConsultationPostFormInputs | null = null

  // 相談の投稿
  isPostConfirmationDialogVisible = false

  private openPostConfirmationDialog(inputs: ConsultationPostFormInputs): void {
    this.postConsultationInputs = inputs

    this.isPostConfirmationDialogVisible = true
  }

  private async executePostConsultation(): Promise<void> {
    if (!this.postConsultationInputs) return // 確認ダイアログ表示時に保持想定

    const rawReq = new ConsultationsPostRequest(
      this.consultationDetailOwnerUser.userId,
      this.postConsultationInputs.message,
      this.postConsultationInputs.consultationId,
      this.postConsultationInputs.material
    )

    const uploader = new FileUploader()
    const req = await uploader.prepare(rawReq)

    this.isPostConfirmationDialogVisible = false
    await consultationsModule.postConsultations(req)
    if (this.postConsultationInputs.consultationId) await consultationsModule.fetchConsultationDraft(new ConsultationDraftGetRequest(this.userId)) // 下書き投稿の場合、下書きの情報を取得しなおす

    this.closePostForm()
    this.refreshList()
  }

  // 下書きの編集
  isEditConfirmationDialogVisible = false

  private openEditConfirmationDialog(index: number, consultationId: string, version: number, inputs: ConsultationUpdateFormInputs): void {
    this.targetConsultationIndex = index
    this.targetConsultationId = consultationId
    this.targetConsultationVersion = version
    this.targetConsultationInputs = inputs

    this.isEditConfirmationDialogVisible = true
  }

  private async executeEditConsultation(): Promise<void> {
    if (this.targetConsultationIndex === null || this.targetConsultationId === null || this.targetConsultationVersion === null) return

    const rawReq = new ConsultationsPutRequest(
      this.consultationDetailOwnerUser.userId,
      this.targetConsultationId,
      this.targetConsultationInputs.message,
      this.targetConsultationVersion,
      this.targetConsultationInputs.material
    )

    let req:ConsultationsPutRequest | undefined

    // 添付ファイルが画像かつbase64データだった場合、プレビュー用にデータを登録する
    let uploadedMaterial : MaterialFormInputDto | undefined
    const rawMaterialRegExp = /^data:.*$/

    if (rawReq.material?.materialType === MATERIAL_TYPES.PDF ||
      (rawReq.material?.materialType === MATERIAL_TYPES.IMAGE &&
      this.targetConsultationInputs.material?.materialUrl &&
      rawMaterialRegExp.test(this.targetConsultationInputs.material.materialUrl))) {
      req = rawReq
      uploadedMaterial = await uploadMaterial(Object.assign(new MaterialFormInputDto(), rawReq.material))
      if (uploadedMaterial) {
        req.material = uploadedMaterial
        rawReq.material.materialUrl = uploadedMaterial.materialUrl
      }
    }
    const uploader = new FileUploader()
    req = await uploader.prepare(rawReq)

    errorsModule.presetResponseFieldErrorPrefix(`consultations[${this.targetConsultationIndex}]`)
    this.isEditConfirmationDialogVisible = false
    await consultationsModule.putConsultations(req)

    if (rawReq.material && req.material) { rawReq.material.materialId = req.material.materialId }
    const res = consultationsModule.putConsultationsResponse(rawReq.consultationId)
    if (res) rawReq.version = res.version

    // フォームタイトル、添付ファイルのいずれかに変更があったのか判定を行う
    const isChangeConsultation = this.copiedConsultations[this.targetConsultationIndex].message !== this.targetConsultationInputs.message ||
            this.copiedConsultations[this.targetConsultationIndex].material?.materialId !== this.targetConsultationInputs.material?.materialId
    Object.assign(this.copiedConsultations[this.targetConsultationIndex], rawReq)
    this.copiedConsultations[this.targetConsultationIndex].reactions = []
    // 編集した投稿が最新の投稿かつ、管理者業務執行者の投稿だった場合、「区分所有者 未読」状態とする
    const latestConsultation = this.copiedConsultations[this.copiedConsultations.length - 1]
    if (latestConsultation.user && latestConsultation.consultationId === this.targetConsultationId) {
      // フォームタイトル、添付ファイルのいずれも変更が無い場合は、データが更新されない為、未読状態にしない
      if (isChangeConsultation) {
        this.copiedConsultations[this.targetConsultationIndex].isUnread = true
      }
    }
    this.changeEditMode(this.targetConsultationId, false)
  }

  // 相談の削除
  isDeleteConfirmationDialogVisible = false

  private openDeleteConfirmationDialog(index: number, consultationId: string): void {
    this.targetConsultationIndex = index
    this.targetConsultationId = consultationId
    this.isDeleteConfirmationDialogVisible = true
  }

  private async executeDeleteConsultation(): Promise<void> {
    if (this.targetConsultationIndex === null || this.targetConsultationId === null) return

    errorsModule.presetResponseFieldErrorPrefix(`consultations[${this.targetConsultationIndex}]`)
    this.isDeleteConfirmationDialogVisible = false
    await consultationsModule.deleteConsultations(new ConsultationsDeleteRequest(this.consultationDetailOwnerUser.userId, this.targetConsultationId))

    this.copiedConsultations.splice(this.targetConsultationIndex, 1)
  }

  // 下書きの保存
  isSaveConfirmationDraftDialogVisible = false

  private openSaveConfirmationDraftDialog(inputs: ConsultationPostFormInputs): void {
    this.postConsultationInputs = inputs

    this.isSaveConfirmationDraftDialogVisible = true
  }

  private async executeSaveDraft(): Promise<void> {
    if (!this.postConsultationInputs) return // 確認ダイアログ表示時に保持想定
    this.isSaveConfirmationDraftDialogVisible = false
    const rawReq = new ConsultationDraftPostRequest(
      this.userId,
      this.postConsultationInputs.message,
      this.postConsultationInputs.material,
      this.postConsultationInputs.consultationId
    )

    const uploader = new FileUploader()
    const req = await uploader.prepare(rawReq)

    await consultationsModule.postConsultationDraft(req)
    await consultationsModule.fetchConsultationDraft(new ConsultationDraftGetRequest(this.userId))
  }

  // 下書きの削除
  isDeleteConfirmationDraftDialogVisible = false

  private openDeleteConfirmationDraftDialog(consultationId: string): void {
    this.targetConsultationId = consultationId

    this.isDeleteConfirmationDraftDialogVisible = true
  }

  private async executeDeleteDraft(): Promise<void> {
    if (this.targetConsultationId === null) return

    const req = new ConsultationDraftDeleteRequest(this.userId, this.targetConsultationId)
    await consultationsModule.deleteConsultationDraft(req)
    await consultationsModule.fetchConsultationDraft(new ConsultationDraftGetRequest(this.userId))
    this.closePostForm()
    this.isDeleteConfirmationDraftDialogVisible = false
  }

  // リアクションの登録・削除を判断
  private async executeConsultationReaction(assetId: string, consultationId: string): Promise<void> {
    if (!consultationId) { return }

    const targetConsultations = this.copiedConsultations.find(consultation => consultation.consultationId === consultationId)

    if (!targetConsultations) { return }

    const targetConsultationIndex = targetConsultations.reactions.indexOf(assetId) // 値が見つからない場合は-1を返す

    if (targetConsultationIndex === -1) {
      targetConsultations.reactions.push(assetId)
      await this.executePostConsultationReaction(assetId, consultationId)
    } else {
      targetConsultations.reactions.splice(targetConsultationIndex, 1)
      await this.executeDeleteConsultationReaction(assetId, consultationId)
    }
  }

  // リアクション登録
  private async executePostConsultationReaction(assetId: string, consultationId: string): Promise<void> {
    const req = new ConsultationReactionPostRequest(
      this.consultationDetailOwnerUser.userId,
      this.targetConsultationId = consultationId,
      assetId
    )
    await consultationsModule.postConsultationReaction(req)
  }

  // リアクション削除
  private async executeDeleteConsultationReaction(assetId: string, consultationId: string): Promise<void> {
    const req = new ConsultationReactionDeleteRequest(
      this.consultationDetailOwnerUser.userId,
      this.targetConsultationId = consultationId,
      assetId
    )
    await consultationsModule.deleteConsultationReaction(req)
  }

  // 下書き一覧の再読み込み
  private refreshList(): void {
    this.copiedConsultations = []
    this.request.skip = SKIP
    this.identifier++
  }

  nextRoute: Route | null = null
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (!this.isPostFormOpened || this.nextRoute) {
      next()
    } else {
      this.nextRoute = to
      next(false)
    }
  }

  leaveHere(): void {
    const routeName = this.nextRoute?.name
    const routeParams = this.nextRoute?.params
    if (routeName) this.$router.push({ name: routeName, params: routeParams })
  }

  private get hasError(): string[] { return errorsModule.globalErrors }

  handler: LoadingHandler | null = null
  @Watch('hasError')
  scrollTop(hasError: string[]): void {
    if (!hasError.length) return
    if (this.handler) this.handler.complete()
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }
}
