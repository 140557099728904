import { Type } from 'class-transformer'
import { Vote } from '@/dtos/resolutions/gm-resolution/statements/common'
import type { StatementType } from '@/constants/schema-constants'

export class StatementForPut {
  userId!: string
  votingRightCount!: number
  statementType?: StatementType
  @Type(() => Vote) vote?: Vote[]
  constructor(userId: string, votingRightCount: number, statementType: StatementType|undefined, vote?: Vote[]) {
    this.userId = userId
    this.votingRightCount = votingRightCount
    this.statementType = statementType
    this.vote = vote
  }
}

export class StatementsPutRequest {
  resolutionId!: string
  @Type(() => StatementForPut) statements: StatementForPut[] = []
  version!: number
  constructor(resolutionId: string, statements: StatementForPut[], version:number) {
    this.resolutionId = resolutionId
    this.statements = statements
    this.version = version
  }
}
