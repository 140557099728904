import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { gmResolutionsClient } from '@/clients/gm-resolutions-client'
import { Details, GMResolutionCommonState } from '@/dtos/resolutions/commons'
import { GMResolutionDetailGetRequest, GMResolutionDetailGetResponse } from '@/dtos/resolutions/gm-resolution/get-detail'
import { GMResolutionsPostRequest } from '@/dtos/resolutions/gm-resolution/post'
import { GMResolutionsDraftPostRequest } from '@/dtos/resolutions/gm-resolution/draft/post'
import { GMResolutionsPutRequest } from '@/dtos/resolutions/gm-resolution/put'
import { GMResolutionsPutCloseRequest } from '@/dtos/resolutions/gm-resolution/close/put'
import { GMResolutionsDeleteRequest } from '@/dtos/resolutions/gm-resolution/delete'
import { GMResolutionsDraftDeleteRequest } from '@/dtos/resolutions/gm-resolution/draft/delete'
import { StatementsGetResponse } from '@/dtos/resolutions/gm-resolution/statements/get'
import { StatementsPutRequest } from '@/dtos/resolutions/gm-resolution/statements/put'
import { StatementsCsvGetRequest } from '@/dtos/resolutions/gm-resolution/statements/get-csv'
import { ResultsGetResponse } from '@/dtos/resolutions/gm-resolution/results/get'
import { ResultsPostRequest } from '@/dtos/resolutions/gm-resolution/results/post'
import { ResultsPutRequest } from '@/dtos/resolutions/gm-resolution/results/put'
import { HOLDING_METHOD_TYPE } from '@/constants/schema-constants'

@Module({ store, dynamic: true, namespaced: true, name: 'gmResolutions' })
class GMResolutionsStore extends VuexModule {
  private _gmResolutionCommonStateGet = new GMResolutionCommonState()

  get gmResolutionCommonStateGet(): GMResolutionCommonState {
    return this._gmResolutionCommonStateGet
  }

  get canHoldRemote(): boolean | undefined {
    return this._canHoldRemote
  }

  private _canHoldRemote : boolean | undefined

  @Mutation
  private SET_COMMON_STATE_GET(state: GMResolutionCommonState): void {
    this._gmResolutionCommonStateGet.resolutionId = state.resolutionId
    this._gmResolutionCommonStateGet.resolutionState = state.resolutionState
    this._gmResolutionCommonStateGet.version = state.version
    this._gmResolutionCommonStateGet.title = state.title
    this._gmResolutionCommonStateGet.details = state.details
    this._gmResolutionCommonStateGet.user = state.user
    this._canHoldRemote = state.canHoldRemote
  }

  @Action
  setGMResolutionCommonState(state: GMResolutionCommonState): void {
    this.SET_COMMON_STATE_GET(state)
  }

  // 総会決議詳細
  private _gmDetails: Record<string, GMResolutionDetailGetResponse> = {}

  get detailResponse(): (id: string) => GMResolutionDetailGetResponse | undefined { return (id: string) => this._gmDetails[id] }

  @Mutation
  private SET_RESOLUTION_DETAIL_GET(res: GMResolutionDetailGetResponse): void {
    Vue.set(this._gmDetails, res.resolutionId, res)
  }

  @Action
  async fetchResolutionDetail(req: GMResolutionDetailGetRequest): Promise<void> {
    const res = await gmResolutionsClient.getResolutionDetail(req)
    this.setGMResolutionCommonState({
      resolutionId: res.resolutionId,
      resolutionState: res.resolutionState,
      version: res.version,
      title: res.title,
      details: res.details ?? new Details(),
      user: res.user,
      canHoldRemote: res.holdingMethodType === HOLDING_METHOD_TYPE.REMOTE || res.holdingMethodType === HOLDING_METHOD_TYPE.BOTH
    })
    this.SET_RESOLUTION_DETAIL_GET(res)
  }

  @Action
  async postResolutions(req: GMResolutionsPostRequest): Promise<void> {
    await gmResolutionsClient.postResolutions(req)
  }

  @Action
  async postDraftResolutions(req: GMResolutionsDraftPostRequest): Promise<void> {
    await gmResolutionsClient.postDraftResolutions(req)
  }

  @Action
  async putResolutions(req: GMResolutionsPutRequest): Promise<void> {
    await gmResolutionsClient.putResolutions(req)
  }

  // 意見投票締切
  @Action
  async putResolutionsClose(req: GMResolutionsPutCloseRequest): Promise<void> {
    await gmResolutionsClient.putResolutionsClose(req)
  }

  @Action
  async deleteResolutions(req: GMResolutionsDeleteRequest): Promise<void> {
    await gmResolutionsClient.deleteResolutions(req)
  }

  @Action
  async deleteDraftResolutions(req: GMResolutionsDraftDeleteRequest): Promise<void> {
    await gmResolutionsClient.deleteDraftResolutions(req)
  }

  // 決議内訳 取得
  private _statementsGet: Record<string, StatementsGetResponse> = {}

  get statementsGet(): (id: string) => StatementsGetResponse | undefined { return (id: string) => this._statementsGet[id] }

  @Mutation
  private SET_STATEMENTS(res:StatementsGetResponse) {
    Vue.set(this._statementsGet, res.resolutionId, res)
  }

  @Action
  async fetchStatements(resolutionId:string): Promise<void> {
    const res = await gmResolutionsClient.getStatements(resolutionId)
    this.setGMResolutionCommonState({
      resolutionId: res.resolutionId,
      resolutionState: res.resolutionState,
      version: res.version,
      title: res.title,
      details: res.details,
      user: res.user,
      canHoldRemote: res.holdingMethodType === HOLDING_METHOD_TYPE.REMOTE || res.holdingMethodType === HOLDING_METHOD_TYPE.BOTH
    })
    this.SET_STATEMENTS(res)
  }

  // 決議内訳 更新
  @Action
  async putStatements(req: StatementsPutRequest) {
    await gmResolutionsClient.putStatements(req)
  }

  @Action
  public async downloadStatementCsv(req: StatementsCsvGetRequest) {
    await gmResolutionsClient.downloadStatementCsv(req)
  }

  // 決議結果 取得
  private _resultsGet: Record<string, ResultsGetResponse> = {}

  get resultsGet(): (id: string) => ResultsGetResponse | undefined { return (id: string) => this._resultsGet[id] }

  @Mutation
  private SET_RESULTS(res:ResultsGetResponse) {
    Vue.set(this._resultsGet, res.resolutionId, res)
  }

  @Action
  async fetchResults(resolutionId:string): Promise<void> {
    const res = await gmResolutionsClient.getResults(resolutionId)
    this.setGMResolutionCommonState({
      resolutionId: res.resolutionId,
      resolutionState: res.resolutionState,
      version: res.version,
      title: res.title,
      details: res.details,
      user: res.user
    })
    this.SET_RESULTS(res)
  }

  // 決議結果 登録
  @Action
  async postResults(req: ResultsPostRequest) {
    await gmResolutionsClient.postResults(req)
  }

  // 決議結果 更新
  @Action
  async putResults(req: ResultsPutRequest) {
    await gmResolutionsClient.putResults(req)
  }
}

export const gmResolutionsModule = getModule(GMResolutionsStore)
