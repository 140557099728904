








































import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import { Header } from '@/components/molecules/SmTableDataExternalPaging.vue'
import { questionnairesModule } from '@/stores/questionnaires-store'
import { QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),
  }
})
export default class QuestionnaireQuestionDetailModal extends Vue {
  choiceQuestionHeaders : Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '110px' }),
    new Header({ text: '氏名', value: 'userName', width: '200px' }),
    new Header({ text: '票数', value: 'answerCount', width: '80px' }),
    new Header({ text: '回答', value: 'ownerAnswer' }),
  ]

  inputQuestionHeaders : Header[] = [
    new Header({ text: '住戸番号', value: 'roomNumber', width: '110px' }),
    new Header({ text: '氏名', value: 'userName', width: '200px' }),
    new Header({ text: '回答', value: 'ownerAnswer' }),
  ]

  @Model('change')
  private readonly isVisible!: boolean

  // textfieldに入力された値
  @Prop({ default: '' })
  inputText!:string

  // 検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  keyword!:string

  private get questionnaireQuestionDetail() {
    return questionnairesModule.questionnaireQuestionDetail
  }

  private get headers():Header[] {
    if (this.questionnaireQuestionDetail.questionType === QUESTIONNAIRE_QUESTION_TYPES.FREE) return this.inputQuestionHeaders
    return this.choiceQuestionHeaders
  }

  private get placeholder():string {
    if (this.questionnaireQuestionDetail.questionType === QUESTIONNAIRE_QUESTION_TYPES.FREE) return '住戸番号／氏名／回答で検索'
    return '住戸番号／氏名／票数／回答で検索'
  }

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) { this.$emit('change', newValue) }

  private get _inputText(): string { return this.inputText }
  private set _inputText(newValue: string) { this.$emit('update:inputText', newValue) }

  private get _keyword(): string { return this.keyword }
  private set _keyword(newValue: string) { this.$emit('update:keyword', newValue) }

  search():void { this._keyword = this._inputText }
}
