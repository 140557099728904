import { APIClientBase, HTTP_METHOD } from './api-client-base'
import { TicketsPostRequest } from '@/dtos/tickets/post'
import { TicketsSearchPostRequest, TicketsSearchPostResponse } from '@/dtos/tickets/search/post'
import { TicketsGetRequest, TicketGetResponse } from '@/dtos/tickets/get'
import { TicketDetailGetRequest, TicketDetailGetResponse } from '@/dtos/tickets/get-detail'
import { TicketAdminInChargePutRequest } from '@/dtos/tickets/admin-in-charge/put'
import { TicketClosePutRequest } from '@/dtos/tickets/close/put'
import { TicketDetailPutRequest } from '@/dtos/tickets/put'
import { TicketCommentsGetRequest, TicketCommentsGetResponse } from '@/dtos/tickets/comments/get'
import { TicketCommentsPostRequest } from '@/dtos/tickets/comments/post'
import { TicketCommentsPutRequest, TicketCOmmentsPutResponse } from '@/dtos/tickets/comments/put'
import { TicketCommentsDeleteRequest } from '@/dtos/tickets/comments/delete'
import { TicketsRegularMtgCsvPostRequest } from '@/dtos/tickets/regular-mtg/post'
import { TicketsManagementStatusesCsvPostRequest } from '@/dtos/tickets/search/management-statuses/post'
import { TICKET_TYPES } from '@/constants/schema-constants'
import { ResolutionTicketRelatedIdeaGetRequest, ResolutionTicketRelatedIdeaGetResponse } from '@/dtos/tickets/resolutions/ideas/get'

class TicketsClient extends APIClientBase {
  constructor() {
    super('/tickets')
  }

  // チケット一覧取得
  postTicketsSearch(req: TicketsSearchPostRequest): Promise<TicketsSearchPostResponse | void> {
    return this.post('search', req, TicketsSearchPostResponse)
  }

  // チケット登録
  postTicket(req: TicketsPostRequest) {
    return this.post('', req)
  }

  // チケット詳細（共通）取得
  getTicket(req: TicketsGetRequest): Promise<TicketGetResponse> {
    return this.get(`${req.ticketId}`, '', TicketGetResponse, false)
  }

  // チケット担当者更新
  putTicketAdminInCharge(req: TicketAdminInChargePutRequest): Promise<void> {
    return this.put(`${req.ticketId}/admin-in-charge`, req)
  }

  // チケット完了登録
  putTicketClose(req: TicketClosePutRequest): Promise<void> {
    return this.put(`${req.ticketId}/close`, req)
  }

  // チケット詳細（内容）取得
  getTicketContents(req: TicketDetailGetRequest): Promise<TicketDetailGetResponse> {
    return this.get(`${req.ticketId}/contents`, '', TicketDetailGetResponse, false)
  }

  // チケット詳細（内容）更新
  putTicketDetailContent(req: TicketDetailPutRequest): Promise<void> {
    const { ticketId, ...body } = req
    return this.put(`${ticketId}`, body)
  }

  // 定例MTG資料用CSV出力
  async postRegularMtgCsv(req: TicketsRegularMtgCsvPostRequest):Promise<void> {
    return await this.download('regular-mtg/csv', req, HTTP_METHOD.POST)
  }

  // 管理状況報告書用CSV出力
  async postManagementStatusesCsv(req: TicketsManagementStatusesCsvPostRequest):Promise<void> {
    return await this.download('management-statuses/csv', req, HTTP_METHOD.POST)
  }

  // チケットコメント取得
  getTicketComments(req: TicketCommentsGetRequest): Promise<TicketCommentsGetResponse> {
    const { ticketId, ...rest } = req
    return this.get(`${ticketId}/comments`, rest, TicketCommentsGetResponse, false)
  }

  // チケットコメント登録
  postTicketComments(req: TicketCommentsPostRequest): Promise<void> {
    const { ticketId, ...rest } = req
    return this.post(`${ticketId}/comments`, rest)
  }

  // チケットコメント更新
  putTicketComments(req: TicketCommentsPutRequest): Promise<TicketCOmmentsPutResponse | void> {
    const { ticketId, ticketCommentId, ...rest } = req
    return this.put(`${ticketId}/comments/${ticketCommentId}`, rest, TicketCOmmentsPutResponse)
  }

  // チケットコメント削除
  deleteTicketComments(req: TicketCommentsDeleteRequest): Promise<void> {
    return this.delete(`${req.ticketId}/comments/${req.ticketCommentId}`)
  }

  // チケット（決議）に関連するアイデア取得
  getResolutionTicketRelatedIdea(req: ResolutionTicketRelatedIdeaGetRequest): Promise<ResolutionTicketRelatedIdeaGetResponse> {
    return this.get(`${TICKET_TYPES.RESOLUTION}/${req.ticketId}/ideas`, '', ResolutionTicketRelatedIdeaGetResponse)
  }
}

export const ticketsClient = new TicketsClient()
