import { Type } from 'class-transformer'

export class VotesGetRequest {
  resolutionId!:string
  constructor(resolutionId:string) {
    this.resolutionId = resolutionId
  }
}

export class OnlineResolutionVote {
  userId!:string
  userName!:string
  roomNumber!:string
  votingRightCount!:number
  isStatement!:boolean
  voteType?:number
}

export class VotesGetResponse {
  @Type(() => OnlineResolutionVote)votes:OnlineResolutionVote[] = []
  version!:number
}
