









































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmTableCellMatrix extends Vue {
  @Prop()
  private readonly prospect ?: number

  @Prop()
  private readonly performance ?: number

  @Prop()
  private readonly total ?: number

  @Prop({ default: false })
  private readonly mini !: boolean

  private get _topValue():number|undefined {
    if (this.prospect && this.performance) return this.prospect
    return undefined
  }

  private get _bottomValue():number|undefined {
    if (this.total || this.total === 0) {
      return this.total
    } else if (this.performance) {
      return this.performance
    } else if (this.prospect) {
      return this.prospect
    } else {
      return undefined
    }
  }
}
