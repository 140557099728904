









































import { Component, Prop, Vue } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { TRANSITION_TO, NOTIFICATION_ELEMENT_TYPES } from '@/constants/schema-constants'
import type { TransitionTo } from '@/constants/schema-constants'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { windowOpen } from '@/libs/window-open'
import { errorsModule } from '@/stores/errors'
import { OwnerNotificationPreviewContent, newTabLocalParamStorageModule } from '@/stores/new-tab-local-param-storage-store'

const CONTENT_NOT_EXIST = 'プレビューを表示できませんでした。タブを閉じて再度プレビューボタンから画面を開きなおしてください。'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmFreeFormatNotificationElement: () => import('@/components/organisms/SmFreeFormatNotificationElement.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class OwnerNotificationPreviewPage extends Vue {
  @Prop({ default: '' })
  private readonly id!: string

  NOTIFICATION_ELEMENT_TYPES = Object.freeze(NOTIFICATION_ELEMENT_TYPES)

  created(): void {
    if (!this.ownerNotification) {
      errorsModule.setGlobalErrors([CONTENT_NOT_EXIST])
    }
  }

  private get ownerNotification(): OwnerNotificationPreviewContent | undefined { return newTabLocalParamStorageModule.ownerNotificationPreviewContent(this.id) }

  private goToSmoothePage(transitionType: TransitionTo, transitionParams: Record<string, string>): void {
    let path = ''
    switch (transitionType) {
      case TRANSITION_TO.IDEA.OWNER:
        path = staticRoutes.ownerIdeaDetailPreview.path.replace(':ideaId', transitionParams.ideaId); break
      case TRANSITION_TO.IDEA.ADMIN:
        path = staticRoutes.adminIdeaDetailPreview.path.replace(':ideaId', transitionParams.ideaId); break
      case TRANSITION_TO.RESOLUTION.GENERAL_MEETING:
        path = staticRoutes.gmResolutionDetailPreview.path.replace(':resolutionId', transitionParams.resolutionId); break
      case TRANSITION_TO.RESOLUTION.ONLINE:
        path = staticRoutes.onlineResolutionDetailPreview.path.replace(':resolutionId', transitionParams.resolutionId); break
      case TRANSITION_TO.QA:
      case TRANSITION_TO.REPORT:
      case TRANSITION_TO.TICKET.DETAIL:
      case TRANSITION_TO.TICKET.DETAIL_TASK:
      case TRANSITION_TO.NOTICE:
      case TRANSITION_TO.STAFF_DETAIL.FRONT:
      case TRANSITION_TO.STAFF_DETAIL.LIFE_MANAGER:
      case TRANSITION_TO.CASYS_RESULTS:
        throw new Error(('The transition type(' + transitionType + ') is unavailable'))
      default: assertExhaustive(transitionType)
    }
    windowOpen(`${path}?buildingId=${this.ownerNotification?.buildingId}`)
  }

  private goToExternalPage(externalSiteUrl: string): void {
    windowOpen(externalSiteUrl)
  }
}
