import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { consultationsClient } from '@/clients/consultations-client'
import { User } from '@/dtos/commons'
import { ConsultationGroup, ConsultationGroupsGetRequest, ConsultationGroupsGetResponse } from '@/dtos/consultations/get'
import { ConsultationDetailGetRequest, ConsultationDetailGetResponse, ConsultationDetailGetResponseConsultation } from '@/dtos/consultations/get-detail'
import { ConsultationsPostRequest } from '@/dtos/consultations/post'
import { ConsultationsPutRequest, ConsultationsPutResponse } from '@/dtos/consultations/put'
import { ConsultationsDeleteRequest } from '@/dtos/consultations/delete'
import { ConsultationDraftGetRequest, ConsultationDraftGetResponse } from '@/dtos/consultations/draft/get'
import { ConsultationDraftPostRequest } from '@/dtos/consultations/draft/post'
import { ConsultationDraftDeleteRequest } from '@/dtos/consultations/draft/delete'
import { ConsultationsReadsPostRequest } from '@/dtos/consultations/reads/post'
import { ConsultationReactionPostRequest } from '@/dtos/consultations/reactions/post'
import { ConsultationReactionDeleteRequest } from '@/dtos/consultations/reactions/delete'

@Module({ store, dynamic: true, namespaced: true, name: 'consultationsStore' })
class ConsultationsStore extends VuexModule {
  // 相談一覧検索
  private _consultationGroupsGet: ConsultationGroup[] = []

  get consultationGroupsGet(): ConsultationGroup[] { return this._consultationGroupsGet }

  @Mutation
  private PUSH_CONSULTATION_GROUPS(res: ConsultationGroupsGetResponse): void {
    this._consultationGroupsGet.push(...res.consultationGroups)
  }

  @Mutation
  private CLEAR_CONSULTATION_GROUPS(): void {
    this._consultationGroupsGet = []
  }

  @Action
  async fetchConsultationGroups(req: ConsultationGroupsGetRequest): Promise<void> {
    const res = await consultationsClient.getConsultationGroups(req)
    this.PUSH_CONSULTATION_GROUPS(res)
  }

  @Action
  clearConsultationGroups(): void {
    this.CLEAR_CONSULTATION_GROUPS()
  }

  // 相談取得
  private _consultationDetailOwnerUserGet = new User()
  private _consultationDetailConsultationsGet: ConsultationDetailGetResponseConsultation[] = []

  get consultationDetailOwnerUserGet(): User { return this._consultationDetailOwnerUserGet }
  get consultationDetailConsultationsGet(): ConsultationDetailGetResponseConsultation[] { return this._consultationDetailConsultationsGet }

  @Mutation
  private SET_CONSULTATION_DETAIL_OWNER_USER(res: ConsultationDetailGetResponse): void {
    this._consultationDetailOwnerUserGet = res.ownerUser
  }

  @Mutation
  private SET_CONSULTATION_DETAIL_CONSULTATIONS(res: ConsultationDetailGetResponse): void {
    this._consultationDetailConsultationsGet = res.consultations
  }

  @Action
  async fetchConsultationDetailOwnerUser(req: ConsultationDetailGetRequest): Promise<void> {
    const res = await consultationsClient.getConsultationDetail(req)
    this.SET_CONSULTATION_DETAIL_OWNER_USER(res)
  }

  @Action
  async fetchConsultationDetailConsultations(req: ConsultationDetailGetRequest): Promise<void> {
    const res = await consultationsClient.getConsultationDetail(req)
    this.SET_CONSULTATION_DETAIL_CONSULTATIONS(res)
  }

  // 相談登録
  @Action
  async postConsultations(req: ConsultationsPostRequest): Promise<void> {
    await consultationsClient.postConsultations(req)
  }

  // 相談更新
  private _putConsultationsResponses: Record<string, ConsultationsPutResponse> = {}
  get putConsultationsResponse(): (consultationId: string) => ConsultationsPutResponse | undefined { return (consultationId: string) => this._putConsultationsResponses[consultationId] }

  @Mutation
  private SET_PUT_CONSULTATIONS_RESPONSE({ consultationId, res }: { consultationId: string, res: ConsultationsPutResponse }) { Vue.set(this._putConsultationsResponses, consultationId, res) }

  @Action
  async putConsultations(req: ConsultationsPutRequest): Promise<void> {
    const res = await consultationsClient.putConsultations(req)

    if (res) this.SET_PUT_CONSULTATIONS_RESPONSE({ consultationId: req.consultationId, res })
  }

  // 相談削除
  @Action
  async deleteConsultations(req: ConsultationsDeleteRequest): Promise<void> {
    await consultationsClient.deleteConsultations(req)
  }

  // 相談既読登録
  @Action
  async postConsultationsReads(req: ConsultationsReadsPostRequest): Promise<void> {
    await consultationsClient.postConsultationsReads(req)
  }

  // リアクションの登録
  @Action
  async postConsultationReaction(req: ConsultationReactionPostRequest): Promise<void> {
    await consultationsClient.postConsultationReaction(req)
  }

  // リアクションの削除
  @Action
  async deleteConsultationReaction(req: ConsultationReactionDeleteRequest): Promise<void> {
    await consultationsClient.deleteConsultationReaction(req)
  }

  // 下書き
  private _consultationDraft:ConsultationDraftGetResponse = new ConsultationDraftGetResponse()

  get getConsultationDraft(): ConsultationDraftGetResponse { return this._consultationDraft }

  @Mutation
  private SET_CONSULTATION_DRAFT(res: ConsultationDraftGetResponse): void {
    this._consultationDraft = res
  }

  // 下書きの取得
  @Action
  async fetchConsultationDraft(req: ConsultationDraftGetRequest): Promise<void> {
    const res = await consultationsClient.getConsultationDraft(req)
    this.SET_CONSULTATION_DRAFT(res)
  }

  // 下書きの登録
  @Action
  async postConsultationDraft(req: ConsultationDraftPostRequest): Promise<void> {
    await consultationsClient.postConsultationDraft(req)
  }

  // 下書きの削除
  @Action
  async deleteConsultationDraft(req: ConsultationDraftDeleteRequest): Promise<void> {
    await consultationsClient.deleteConsultationDraft(req)
  }
}

export const consultationsModule = getModule(ConsultationsStore)
