import { Type } from 'class-transformer'
import { Material, User } from '@/dtos/commons'
import type { OpinionState } from '@/constants/schema-constants'

export class ReportsGetRequest {
  reportId?:string
  exceptDeleted!:boolean
  skip!:number
  take!:number
}

export class ReportIdea {
  ideaId!:string
  title!:string
  userId!:string
}

export class ReportOpinion {
  opinionId!: string
  alternativeIdeaBackground?: string
  alternativeIdeaTitle?: string
  opinionBody?: string
  @Type(() => User)ownerUser: User = new User()
  postedAt!: string
  isAlternative!: boolean
  opinionState!: OpinionState
  @Type(() => Material) material?: Material
}

export class ReportDetail {
  reportId!:string
  reportedReason!:string
  postedAt!:string
  @Type(() => User)ownerUser:User = new User()
}

export class Report {
  @Type(() => ReportIdea)idea = new ReportIdea()
  @Type(() => ReportOpinion)opinion = new ReportOpinion()
  @Type(() => ReportDetail)report = new ReportDetail()
}

export class ReportsGetResponse {
  @Type(() => Report)reports:Report[] = []
}
