import { windowOpen } from '@/libs/window-open'
import { generateUuid } from '@/libs/uuid-generator'
import { staticRoutes } from '@/routes'
import { newTabLocalTransitionParamStorageModule, NewTabTransitionParams } from '@/stores/new-tab-local-transition-param-storage-store'

/**
 * 任意の遷移先を別タブで開きます。
 * パラメータには遷移先の画面に応じたidを設定してください。
 * @param newTabTransitionParams 遷移先画面のパラメータ
 */
export function openNewTab(newTabTransitionParams: NewTabTransitionParams): void {
  const newTabTransitionId = generateUuid()
  newTabLocalTransitionParamStorageModule.setNewTabTransitionParams({ key: newTabTransitionId, newTabTransitionParams: newTabTransitionParams })
  windowOpen(staticRoutes.newTabTransition.path.replace(':id', newTabTransitionId))
}
