



































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getQuestionnaireQuestionTypeLabel, getQuestionnaireQuestionTypeOptionIconName } from '@/libs/type-handler'
import { QUESTIONNAIRE_QUESTION_ELEMENT_TYPES, QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'
import type { QuestionnaireQuestionType } from '@/constants/schema-constants'
import { QuestionnaireQuestionElementBase } from '@/dtos/questionnaires/commons'
import { staticKeyProvider } from '@/libs/static-key-provider'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmSwitch: () => import('@/components/atoms/SmSwitch.vue'),

    SmOption: () => import('@/components/molecules/SmOption.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
  }
})
export default class SmCardQuestionnaireQuestion extends Vue {
  QUESTIONNAIRE_QUESTION_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_TYPES)
  QUESTIONNAIRE_QUESTION_ELEMENT_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_ELEMENT_TYPES)

  @Prop({ required: true, default: false })
  private readonly head!: boolean

  @Prop({ required: true, default: false })
  private readonly foot!: boolean

  @Prop({ required: true, default: false })
  private readonly deletable!: boolean

  @Prop({ required: true, default: '' })
  private readonly fieldIdPrefix!: string

  @Prop({ required: true, default: QUESTIONNAIRE_QUESTION_TYPES.FREE })
  private questionType!: QuestionnaireQuestionType

  @Prop({ required: true, default: '' })
  private questionSentence!: string

  @Prop({ default: false })
  private questionRequired!: boolean

  @Prop({ required: true, default: () => [] })
  private questionElements!: QuestionnaireQuestionElementBase[]

  @Prop({ required: true, default: true })
  private readonly canEditQuestion!: boolean

  @Prop({ default: false })
  unmovable!: boolean

  // ----------------------------入力系--------------------------

  private get _questionSentence():string {
    return this.questionSentence
  }

  private set _questionSentence(newValue: string) {
    this.$emit('update:questionSentence', newValue)
  }

  // ----------------------------共通----------------------------

  private get _questionRequired(): boolean { return this.questionRequired }
  private set _questionRequired(newValue: boolean) { this.$emit('update:questionRequired', newValue) }

  private get questionTitle():string {
    return `設問(${getQuestionnaireQuestionTypeLabel(this.questionType)})`
  }

  private get optionIconName():string {
    return getQuestionnaireQuestionTypeOptionIconName(this.questionType)
  }

  private isEditableOption(element:QuestionnaireQuestionElementBase): boolean {
    if (element.templateQuestionElementId) return false
    if (element.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION) return false
    return true
  }

  // ----------------------------入力式----------------------------

  private get answerPlaceholder():string {
    return this.questionElements[0].answerPlaceholder ?? ''
  }

  private set answerPlaceholder(newValue:string) {
    this.questionElements[0].answerPlaceholder = newValue
    this.$emit('update-question-elements', this.questionElements)
  }

  // ----------------------------単一選択式・複数選択式----------------------------

  private get isAddable(): boolean {
    return this.questionElements.length < 8
  }

  private get isDeletable(): boolean {
    return this.questionElements.length > 2
  }

  private get hasOtherOption():boolean {
    return this.questionElements.some(e => e.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION)
  }

  private onClickAddOtherOption():void {
    const option = staticKeyProvider.create(QuestionnaireQuestionElementBase)
    option.choice = 'その他'
    option.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION
    option.sortOrderNum = 99 // 末尾
    this.addOption(option)
  }

  private onClickAddOption():void {
    const option = staticKeyProvider.create(QuestionnaireQuestionElementBase)
    option.choice = ''
    option.elementType = QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.SELECTABLE_OPTION
    option.sortOrderNum = this.questionElements.filter(e => e.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.SELECTABLE_OPTION).length + 1
    this.addOption(option)
  }

  private addOption(option: QuestionnaireQuestionElementBase):void {
    const newQuestionElements = [...this.questionElements]
    newQuestionElements.push(option)
    const sortedQuestionElements = newQuestionElements.sort((a, b) => a.sortOrderNum - b.sortOrderNum) // その他が末尾に来るようソート
    this.$emit('update-question-elements', sortedQuestionElements)
    this.$emit('contaminate')
  }

  private deleteOption(choiceIndex: number):void {
    const newQuestionElements = [...this.questionElements]
    newQuestionElements.splice(choiceIndex, 1)
    // 削除に伴うsortOrderNum採番ズレを修正
    const sortedQuestionElements = newQuestionElements.map((e, i) => {
      if (e.elementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION) return e // その他は常に末尾にする
      e.sortOrderNum = i + 1
      return e
    })
    this.$emit('update-question-elements', sortedQuestionElements)
    this.$emit('contaminate')
  }
}

