import { APIClientBase } from './api-client-base'
import { RESOLUTION_TYPES } from '@/constants/schema-constants'
import { OnlineResolutionDetailGetRequest, OnlineResolutionDetailGetResponse } from '@/dtos/resolutions/online-resolution/get-detail'
import { OnlineResolutionsPostRequest } from '@/dtos/resolutions/online-resolution/post'
import { OnlineResolutionsDraftPostRequest } from '@/dtos/resolutions/online-resolution/draft/post'
import { OnlineResolutionsDraftDeleteRequest } from '@/dtos/resolutions/online-resolution/draft/delete'
import { OnlineResolutionsPutRequest } from '@/dtos/resolutions/online-resolution/put'
import { OnlineResolutionsDeleteRequest } from '@/dtos/resolutions/online-resolution/delete'
import { OnlineResultsGetResponse } from '@/dtos/resolutions/online-resolution/results/get'
import { OnlineResultsPostRequest } from '@/dtos/resolutions/online-resolution/results/post'
import { OnlineResultsPutRequest } from '@/dtos/resolutions/online-resolution/results/put'
import { VotesGetRequest, VotesGetResponse } from '@/dtos/resolutions/online-resolution/votes/get'
import { VotesPostRequest } from '@/dtos/resolutions/online-resolution/votes/post'

class OnlineResolutionsClient extends APIClientBase {
  constructor() {
    super(`/resolutions/${RESOLUTION_TYPES.ONLINE}`)
  }

  getResolutionDetail(req: OnlineResolutionDetailGetRequest): Promise<OnlineResolutionDetailGetResponse> {
    return this.get(`${req.resolutionId}`, '', OnlineResolutionDetailGetResponse)
  }

  postResolutions(req: OnlineResolutionsPostRequest): Promise<void> {
    return this.post('', req)
  }

  postDraftResolutions(req: OnlineResolutionsDraftPostRequest): Promise<void> {
    return this.post('/draft', req)
  }

  putResolutions(req: OnlineResolutionsPutRequest): Promise<void> {
    return this.put(`${req.resolutionId}`, req)
  }

  deleteResolutions(req: OnlineResolutionsDeleteRequest): Promise<void> {
    return this.delete(`/${req.resolutionId}`)
  }

  deleteDraftResolution(req: OnlineResolutionsDraftDeleteRequest): Promise<void> {
    return this.delete(`/draft/${req.resolutionId}`)
  }

  // オンライン決議詳細 決議結果取得
  getResults(resolutionId: string) {
    return this.get(`${resolutionId}/results`, null, OnlineResultsGetResponse)
  }

  // 決議結果 登録
  postResults(req: OnlineResultsPostRequest) {
    return this.post(`${req.resolutionId}/results`, req)
  }

  // 決議結果 更新
  putResults(req: OnlineResultsPutRequest) {
    return this.put(`${req.resolutionId}/results`, req)
  }

  // 投票一覧取得
  getVotes(req: VotesGetRequest):Promise<VotesGetResponse> {
    return this.get(`${req.resolutionId}/votes`, undefined, VotesGetResponse)
  }

  // 代理投票
  async postVotes(req: VotesPostRequest):Promise<void> {
    await this.post(`${req.resolutionId}/votes`, req)
  }
}

export const onlineResolutionsClient = new OnlineResolutionsClient()
