import { RepairPlanPostRequest, RepairPlanPostCategoryItem } from './post'

export class RepairPlanPutRequest extends RepairPlanPostRequest {
  isReset: boolean

  constructor(isReset: boolean, currentPeriod: number, planningStartPeriod: number, uploadedFileName: string, categories: RepairPlanPostCategoryItem[]) {
    super(currentPeriod, planningStartPeriod, uploadedFileName, categories)
    this.isReset = isReset
  }
}
