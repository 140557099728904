import { APIClientBase } from './api-client-base'

import { LifeManagerDeleteRequest, StaffDeleteRequest } from '@/dtos/staffs/delete'
import { StaffsGetRequest, StaffsGetResponse } from '@/dtos/staffs/get'
import { StaffPutRequest, LifeManagerPutRequest } from '@/dtos/staffs/put'
import { StaffDetailGetRequest, StaffDetailGetResponse } from '@/dtos/staffs/get-detail'
import { MembersGetRequest, MembersGetResponse } from '@/dtos/staffs/members/get'
import { StaffPostRequest, StaffPostResponse, LifeManagerStaffPostRequest } from '@/dtos/staffs/post'

class StaffsClient extends APIClientBase {
  constructor() {
    super('/staffs')
  }

  getStaffs(req: StaffsGetRequest): Promise<StaffsGetResponse> {
    return this.get('', req, StaffsGetResponse)
  }

  // 社員登録
  postStaff(req: StaffPostRequest): Promise<void | StaffPostResponse> {
    return this.post('', req, StaffPostResponse)
  }

  postLifeManagerStaff(req: LifeManagerStaffPostRequest): Promise<void | StaffPostResponse> {
    return this.post('lm', req, StaffPostResponse)
  }

  putStaff(req: StaffPutRequest) : Promise<void> {
    const { adminId, ...body } = req
    return this.put(`/${adminId}`, body)
  }

  putLifeManager(req: LifeManagerPutRequest) : Promise<void> {
    const { adminId, ...body } = req
    return this.put(`/lm/${adminId}`, body)
  }

  getStaffDetail(req: StaffDetailGetRequest): Promise<StaffDetailGetResponse> {
    return this.get(`${req.adminId}`, '', StaffDetailGetResponse)
  }

  deleteStaff(req: StaffDeleteRequest): Promise<void> {
    return this.delete(`${req.adminId}`)
  }

  deleteLifeManager(req: LifeManagerDeleteRequest): Promise<void> {
    return this.delete(`/lm/${req.adminId}`)
  }

  getMembers(req: MembersGetRequest): Promise<MembersGetResponse> {
    return this.get('/members', req, MembersGetResponse)
  }
}

export const staffsClient = new StaffsClient()
