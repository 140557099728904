

















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmOwProgressLinear extends Vue {
  @Prop({ required: true, default: 100 })
  private readonly progressRate!: number

  @Prop({ default: '' })
  private readonly text!: string | number
}
