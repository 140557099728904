import { Type } from 'class-transformer'

export class Amount {
  period!: number
  amount?: number
}
export class RepairPlan {
  @Type(() => Amount) originals: Amount[] = []
  @Type(() => Amount) performances: Amount[] = []
}

export class AnnualRevenue {
  period!: number
  annualRevenue?: number
}

export class ReserveFund {
  @Type(() => Amount) prospects: Amount[] = []
  @Type(() => Amount) performances: Amount[] = []
  @Type(() => AnnualRevenue) annualRevenues: AnnualRevenue[] = []
  balance?: number
}

export class RegisteredPerformance {
  period!: number
  repairCost!: number
  reservedAmount!: number
}

export class IncomeExpensePlan {
  @Type(() => RepairPlan) repairPlan = new RepairPlan()
  @Type(() => ReserveFund) reserveFund = new ReserveFund()
  @Type(() => RegisteredPerformance) registeredPerformances: RegisteredPerformance[] = []
}

export class Period {
  period!: number
  prospect?: number
}

export class Item {
  itemLabel!: string
  @Type(() => Period) periods: Period[] = []
  total!: number
}
export class Category {
  categoryLabel!: string
  @Type(() => Item) items: Item[] = []
}

export class DetailRow {
  @Type(() => Category) categories: Category[] = []
}

export class PeriodTotal {
  period!: number
  taxExcluded?: number
  tax?: number
  taxIncluded?: number
  cumulative?: number
  performanceTaxIncluded?: number
  performanceCumulative?: number
}

export class RowTotal {
  taxExcluded!: number
  tax!: number
  taxIncluded!: number
  cumulative!: number
  performanceTaxIncluded?: number
  performanceCumulative?: number
}

export class TotalRow {
  @Type(() => PeriodTotal) periodTotals: PeriodTotal[] = []
  @Type(() => RowTotal) rowTotal = new RowTotal()
}

export class ConstructionPlan {
  @Type(() => DetailRow) detailRows = new DetailRow()
  @Type(() => TotalRow) totalRows = new TotalRow()
}

export class SimpleRepairsPlansDetailGetResponse {
  @Type(() => IncomeExpensePlan) incomeExpensePlan = new IncomeExpensePlan()
  @Type(() => ConstructionPlan) constructionPlan = new ConstructionPlan()
  lastUpdatedAt!: string
  processedAt!: string
}
