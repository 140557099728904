export class OwnerIdeasRejectRequest {
  ideaId: string
  version: number
  rejectedReason: string

  constructor(ideaId: string, version: number, rejectedReason: string) {
    this.ideaId = ideaId
    this.version = version
    this.rejectedReason = rejectedReason
  }
}
