





































import { Vue, Component } from 'vue-property-decorator'
import { buildingsModule } from '@/stores/buildings-store'
import { simpleRepairsModule } from '@/stores/simple-repairs-store'
import { SimpleRepairsPlansDetailGetResponse } from '@/dtos/simple-repairs/plans/get'

@Component({
  components: {
    SmTextPrintSizeAnnounce: () => import('@/components/atoms/SmTextPrintSizeAnnounce.vue'),

    SmPrintHeader: () => import('@/components/organisms/SmPrintHeader.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    SimpleRepairPlanSummarySubPage: () => import('@/pages/simple-repair-plan/detail/summary/SimpleRepairPlanSummarySubPrintPage.vue'),
  }
})
export default class SimpleRepairPlanPrintPage extends Vue {
  async beforeCreate():Promise<void> {
    await simpleRepairsModule.fetchSimpleRepairsDetail()
  }

  private get simpleRepairsDetailGet():SimpleRepairsPlansDetailGetResponse {
    return simpleRepairsModule.simpleRepairsDetailGet ?? new SimpleRepairsPlansDetailGetResponse()
  }

  private get summarySplits():{min: number, max: number}[] {
    if (!this.simpleRepairsDetailGet.incomeExpensePlan.repairPlan.originals.length) return []
    const startPeriod = this.simpleRepairsDetailGet.incomeExpensePlan.repairPlan.originals[0].period
    const endPeriod = this.simpleRepairsDetailGet.incomeExpensePlan.repairPlan.originals.slice(-1)[0].period
    return [{ min: startPeriod, max: endPeriod }]
  }

  private get myCurrentBuilding():string | undefined {
    return buildingsModule.buildingDetailGet?.buildingName
  }
}
