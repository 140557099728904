



























































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { TicketCommentUpdateFormInputs } from '@/components/molecules/card/SmCardTicketCommentUpdate.vue'
import { LoadingHandler } from '@/components/molecules/SmInfiniteLoading.vue'
import { TicketCommentPostFormInputs } from '@/pages/tickets/ticket-detail/comments/CommentsPostForm.vue'

import { MaterialFormInputDto, User } from '@/dtos/commons'
import { TicketComment, TicketCommentsGetRequest } from '@/dtos/tickets/comments/get'
import { TicketCommentsPostRequest } from '@/dtos/tickets/comments/post'
import { TicketCommentsPutRequest } from '@/dtos/tickets/comments/put'
import { TicketCommentsDeleteRequest } from '@/dtos/tickets/comments/delete'

import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { FileUploader, uploadMaterial } from '@/libs/file-uploader'

import { errorsModule } from '@/stores/errors'
import { ticketsModule } from '@/stores/tickets-store'

const TAKE = 20

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmCardTicketCommentDetail: () => import('@/components/molecules/card/SmCardTicketCommentDetail.vue'),
    SmCardTicketCommentUpdate: () => import('@/components/molecules/card/SmCardTicketCommentUpdate.vue'),
    SmInfiniteLoading: () => import('@/components/molecules/SmInfiniteLoading.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    CommentsPostForm: () => import('@/pages/tickets/ticket-detail/comments/CommentsPostForm.vue'),
  }
})
export default class CommentsSubPage extends Mixins(CurrentAdminManager) {
  @Prop({ required: true, default: '' })
  ticketId!: string

  mounted(): void { ticketsModule.clearFetchedTicketComments() }

  targetTicketCommentIndex: number | null = null
  targetTicketCommentId: string | null = null
  targetTicketCommentVersion: number | null = null
  targetTicketCommentInputs = new TicketCommentUpdateFormInputs()

  copiedTicketComments: TicketComment[] = []

  copyTicketComments(): void {
    const ticketComments = ticketsModule.ticketComments
    const additionalCopiedTicketComments = deepCopy(
      ticketComments,
      {
        ticketComment: new ColumnToType(TicketComment),
        admin: new ColumnToType(User),
        material: new ColumnToType(MaterialFormInputDto)
      },
      'ticketComment'
    )

    this.copiedTicketComments = additionalCopiedTicketComments.reverse()
  }

  identifier = 0
  async loadTicketComments(handler: LoadingHandler): Promise<void> {
    this.handler = handler
    const beforeTicketCommentsCount = this.copiedTicketComments.length
    const skipToken = ticketsModule.skipToken ?? undefined
    await ticketsModule.fetchTicketComments(new TicketCommentsGetRequest(this.ticketId, TAKE, skipToken))
    this.copyTicketComments()

    if (!this.copiedTicketComments.length) {
      // 初回読み込みで結果ゼロの場合だけはno-resultsスロットを描画したいので、loadedを呼ばずにcompleteする
      handler.complete()
      return
    }

    handler.loaded()
    const expectingToBe = beforeTicketCommentsCount + TAKE
    if (this.copiedTicketComments.length < expectingToBe) handler.complete()
  }

  isMyMessage(userId: string): boolean { return this.$isCurrentAdmin(userId) }

  isPostFormOpened = false
  closePostForm(): void { this.isPostFormOpened = false }

  // --------------- ボタンを押下した際の処理 ---------------

  postFormInputs: TicketCommentPostFormInputs | null = null

  async onClickPostBtn(input: TicketCommentPostFormInputs): Promise<void> {
    this.postFormInputs = input
    if (this.postFormInputs === null) return

    const rawReq = new TicketCommentsPostRequest(
      this.ticketId,
      this.postFormInputs.comment,
      this.postFormInputs.material ?? undefined
    )

    const uploader = new FileUploader()
    const req = await uploader.prepare(rawReq)

    await ticketsModule.postTicketComments(req)
    this.isPostFormOpened = false
    this.refreshList()

    this.postFormInputs = null
  }

  updateFormInputs: TicketCommentUpdateFormInputs | null = null
  updateIndex: number | null = null
  updateTicketCommentId: string | null = null
  updateVersion: number | null = null

  async onClickUpdateBtn(inputs: TicketCommentUpdateFormInputs, index: number, ticketCommentId: string, version: number): Promise<void> {
    this.updateFormInputs = inputs
    this.updateIndex = index
    this.updateTicketCommentId = ticketCommentId
    this.updateVersion = version

    if (this.updateFormInputs === null || this.updateIndex === null || this.updateTicketCommentId === null || this.updateVersion === null) return
    const rawReq = new TicketCommentsPutRequest(
      this.ticketId,
      this.updateTicketCommentId,
      this.updateFormInputs.comment,
      this.updateVersion,
      this.updateFormInputs.material ?? undefined
    )

    let req: TicketCommentsPutRequest | undefined

    // 添付ファイルが画像かつbase64データだった場合、プレビュー用にデータを登録する
    const rawMaterialRegExp = /^data:.*$/
    if (rawReq.material?.materialType === MATERIAL_TYPES.PDF ||
      (rawReq.material?.materialType === MATERIAL_TYPES.IMAGE &&
      this.updateFormInputs.material?.materialUrl &&
      rawMaterialRegExp.test(this.updateFormInputs.material.materialUrl))) {
      req = rawReq
      const uploadedMaterial = await uploadMaterial(Object.assign(new MaterialFormInputDto(), rawReq.material))
      if (uploadedMaterial) {
        req.material = uploadedMaterial
        rawReq.material.materialUrl = uploadedMaterial.materialUrl
      }
    }

    const uploader = new FileUploader()
    req = await uploader.prepare(rawReq)

    errorsModule.presetResponseFieldErrorPrefix(`ticketComments[${this.updateIndex}]`)
    await ticketsModule.putTicketComments(req)

    if (rawReq.material && req.material) { rawReq.material.materialId = req.material.materialId }
    const res = ticketsModule.putTicketCommentsResponse(rawReq.ticketCommentId)
    if (res) { rawReq.version = res.version }

    Object.assign(this.copiedTicketComments[this.updateIndex], rawReq)
    this.changeEditMode(this.updateTicketCommentId, false)
  }

  cancelEditMode(ticketCommentId: string): void { this.changeEditMode(ticketCommentId, false) }

  switchToEditMode(ticketCommentId: string): void { this.changeEditMode(ticketCommentId, true) }

  isDeleteTicketCommentDialogVisible = false
  openDeleteConfirmationDialog(index: number, ticketCommentId: string): void {
    this.targetTicketCommentIndex = index
    this.targetTicketCommentId = ticketCommentId
    this.isDeleteTicketCommentDialogVisible = true
  }

  async executeDeleteTicketComment(): Promise<void> {
    if (this.targetTicketCommentIndex === null || this.targetTicketCommentId === null) return

    errorsModule.presetResponseFieldErrorPrefix(`ticketComments[${this.targetTicketCommentIndex}]`)
    this.isDeleteTicketCommentDialogVisible = false
    await ticketsModule.deleteTicketComments(new TicketCommentsDeleteRequest(this.ticketId, this.targetTicketCommentId))

    this.copiedTicketComments.splice(this.targetTicketCommentIndex, 1)
  }

  // --------------- 共通処理 ---------------
  changeEditMode(ticketCommentId: string, isEditMode: boolean): void {
    const targetTicketComment = this.copiedTicketComments.find(c => c.ticketCommentId === ticketCommentId)
    if (targetTicketComment) targetTicketComment.isEditMode = isEditMode
  }

  refreshList(): void {
    ticketsModule.clearFetchedTicketComments()
    this.copiedTicketComments = []
    this.identifier++
  }

  private get hasError(): string[] { return errorsModule.globalErrors }

  handler: LoadingHandler | null = null
  @Watch('hasError')
  scrollTop(hasError: string[]): void {
    if (!hasError.length) return
    if (this.handler) this.handler.complete()
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }
}
