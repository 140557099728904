import { Plan } from '@/dtos/commons'
import { Type } from 'class-transformer'

export class AdminIdeaDecisionGetRequest {
  ideaId: string

  constructor(ideaId: string) {
    this.ideaId = ideaId
  }
}

export class AdminIdeaDecisionGetResponse {
  ideaId!: string
  title!: string
  @Type(() => Plan) plan: Plan = new Plan()
}
