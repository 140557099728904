import { AsyncZipOptions, zip } from 'fflate'

/**
 * 複数のファイルをZipに圧縮する
 * @param files 圧縮対象のファイル
 * @param filename 圧縮後のファイル名(拡張子も含める)
 * @param compressOptions 圧縮に関するオプション
 * @returns Zipファイル
 */
export async function compress(
  files: File[],
  filename: string,
  compressOptions: AsyncZipOptions = {}
): Promise<File> {
  try {
    const fileContents: Record<string, Uint8Array> = {}
    const promises = files.map(async(f) => {
      const arrayBuffer = await f.arrayBuffer()
      fileContents[f.name] = new Uint8Array(arrayBuffer)
    })
    await Promise.all(promises)
    const zippedContent: Uint8Array = await new Promise((resolve, reject) => {
      zip(fileContents, compressOptions, (err, data) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
    })
    return new File([zippedContent], filename, { type: 'application/zip' })
  } catch (err) {
    return Promise.reject(new Error(`compress failed: ${err}`))
  }
}
