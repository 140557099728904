import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'

import { simpleRepairsClient } from '@/clients/simple-repairs-client'

import { SimpleRepairsPerformancesDeleteRequest } from '@/dtos/simple-repairs/performances/delete'
import { SimpleRepairsPerformancesPostRequest } from '@/dtos/simple-repairs/performances/post'
import { SimpleRepairsPlansDetailGetResponse } from '@/dtos/simple-repairs/plans/get'
import { SimpleRepairPlanPostRequest } from '@/dtos/simple-repairs/plans/post'
import { RepairsUpdatesCommentsDeleteRequest } from '@/dtos/repairs/updates/comments/delete'
import { SimpleRepairsUpdatesCommentDetailGetRequest, SimpleRepairsUpdatesCommentDetailGetResponse } from '@/dtos/simple-repairs/updates/comments/get-details'
import { SimpleRepairPlanUpdatesLatestCommentResponse } from '@/dtos/simple-repairs/updates/comments/get-latest'
import { SimpleRepairsUpdatesCommentsGetRequest, SimpleRepairsUpdatesCommentsGetResponse, Comment } from '@/dtos/simple-repairs/updates/comments/get'
import { SimpleRepairsUpdatesCommentsPostRequest } from '@/dtos/simple-repairs/updates/comments/post'
import { SimpleRepairsUpdatesCommentsPutRequest } from '@/dtos/simple-repairs/updates/comments/put'

@Module({ store, dynamic: true, namespaced: true, name: 'simpleRepairs' })
class SimpleRepairsStore extends VuexModule {
  @Action
  async postSimpleRepairPlan(req: SimpleRepairPlanPostRequest): Promise<void> {
    await simpleRepairsClient.postSimpleRepairPlan(req)
  }

  @Action
  async postPerformances(req: SimpleRepairsPerformancesPostRequest): Promise<void> {
    await simpleRepairsClient.postPerformances(req)
  }

  @Action
  async deletePerformances(req: SimpleRepairsPerformancesDeleteRequest): Promise<void> {
    await simpleRepairsClient.deletePerformances(req)
  }

  private _latestComment: SimpleRepairPlanUpdatesLatestCommentResponse = new SimpleRepairPlanUpdatesLatestCommentResponse()

  get latestComment(): SimpleRepairPlanUpdatesLatestCommentResponse {
    return this._latestComment
  }

  @Mutation
  private SET_LATEST_COMMENT(res: SimpleRepairPlanUpdatesLatestCommentResponse): void {
    this._latestComment = res
  }

  @Action
  async fetchLatestComment(): Promise<void> {
    const res = await simpleRepairsClient.getLatestComment()
    this.SET_LATEST_COMMENT(res)
  }

  // ------------------------------------------------------------------------------------
  // 変更コメント ------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------
  @Action
  async postSimpleRepairsUpdatesComments(req: SimpleRepairsUpdatesCommentsPostRequest): Promise<void> {
    await simpleRepairsClient.postSimpleRepairsUpdatesComments(req)
  }

  @Action
  async putSimpleRepairsUpdatesComments(req: SimpleRepairsUpdatesCommentsPutRequest): Promise<void> {
    await simpleRepairsClient.putSimpleRepairsUpdatesComments(req)
  }

  // 詳細
  private _simpleRepairsUpdatesCommentDetails: Record<string, SimpleRepairsUpdatesCommentDetailGetResponse> = {}
  get simpleRepairsUpdatesCommentDetailGet(): (id: string) => SimpleRepairsUpdatesCommentDetailGetResponse | undefined { return (id: string) => this._simpleRepairsUpdatesCommentDetails[id] }

  @Mutation
  private SET_SIMPLE_REPAIRS_UPDATES_COMMENT_DETAIL_GET(res: SimpleRepairsUpdatesCommentDetailGetResponse): void {
    Vue.set(this._simpleRepairsUpdatesCommentDetails, res.commentId, res)
  }

  @Action
  async fetchSimpleRepairsUpdatesCommentDetail(req: SimpleRepairsUpdatesCommentDetailGetRequest): Promise<void> {
    const res = await simpleRepairsClient.getSimpleRepairsUpdatesCommentDetail(req)
    this.SET_SIMPLE_REPAIRS_UPDATES_COMMENT_DETAIL_GET(res)
  }

  // 変更コメント
  private _simpleRepairsUpdatesCommentsGet: Comment[] = []
  get simpleRepairsUpdatesCommentsGet(): Comment[] { return this._simpleRepairsUpdatesCommentsGet }

  @Mutation
  private PUSH_SIMPLE_REPAIRS_UPDATES_COMMENTS_GET(res: SimpleRepairsUpdatesCommentsGetResponse): void {
    this._simpleRepairsUpdatesCommentsGet.push(...res.comments)
  }

  @Mutation
  private CLEAR_SIMPLE_REPAIRS_UPDATES_COMMENTS_GET(): void {
    this._simpleRepairsUpdatesCommentsGet = []
  }

  @Action
  async fetchSimpleRepairsUpdatesComments(req: SimpleRepairsUpdatesCommentsGetRequest): Promise<void> {
    const res = await simpleRepairsClient.getSimpleRepairsUpdatesComments(req)
    this.PUSH_SIMPLE_REPAIRS_UPDATES_COMMENTS_GET(res)
  }

  @Action
  clearSimpleRepairsUpdatesComments(): void {
    this.CLEAR_SIMPLE_REPAIRS_UPDATES_COMMENTS_GET()
  }

  @Action
  async deleteSimpleRepairsUpdatesComments(req: RepairsUpdatesCommentsDeleteRequest): Promise<void> {
    await simpleRepairsClient.deleteSimpleRepairsUpdatesComments(req)
  }

  // ------------------------------------------------------------------------------------
  // 簡易版長期修繕計画詳細 --------------------------------------------------------------
  // ------------------------------------------------------------------------------------

  private _simpleRepairsDetailGet: SimpleRepairsPlansDetailGetResponse = new SimpleRepairsPlansDetailGetResponse()
  get simpleRepairsDetailGet(): SimpleRepairsPlansDetailGetResponse { return this._simpleRepairsDetailGet }

  @Mutation
  private SET_SIMPLE_REPAIRS_EXPENSES_GET(res:SimpleRepairsPlansDetailGetResponse): void {
    this._simpleRepairsDetailGet = res
  }

  @Action
  async fetchSimpleRepairsDetail(): Promise<void> {
    const res = await simpleRepairsClient.getSimpleRepairsDetail()
    this.SET_SIMPLE_REPAIRS_EXPENSES_GET(res)
  }
}

export const simpleRepairsModule = getModule(SimpleRepairsStore)
