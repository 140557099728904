































import { Vue, Component, Prop, Model } from 'vue-property-decorator'

export class RadioOption {
  label!: string
  value!: string
  isSelected!: boolean
  isOtherOption!: boolean
  inputOtherText!: string
  caption?: string
}

@Component({
  components: {
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    SmOwTextRequired: () => import('@/components/owner-apps/SmOwTextRequired.vue'),
    SmOwRadioInverse: () => import('@/components/owner-apps/SmOwRadioInverse.vue'),
  }
})
export default class SmOwRadioSelectsQuestion extends Vue {
  @Model('input')
  options!: RadioOption[]

  @Prop({ required: true, default: '' })
  private readonly title!: string

  @Prop({ default: false })
  private readonly required!: boolean

  private selectedRadioValue = ''
  private selectedRadioOthertext = ''

  private get _options(): RadioOption[] { return this.options }
  private set _options(newValue: RadioOption[]) { this.$emit('input', newValue) }

  private get selectedOptionValue(): string {
    this.options.forEach(option => {
      if (option.isSelected === true) {
        this.selectedRadioValue = option.value
      }
    })
    return this.selectedRadioValue
  }

  private set selectedOptionValue(newValue: string) {
    this.selectedRadioValue = newValue
    const updatedOptions = this._options.map(option => {
      option.isSelected = (option.value === this.selectedRadioValue)
      if (!option.isSelected && option.isOtherOption) {
        this.selectedRadioOthertext = ''
        option.inputOtherText = ''
      }
      return option
    })
    this._options = updatedOptions
    this.$emit('input', this._options)
  }

  private get selectedInputOtherText(): string {
    this.options.forEach(option => {
      if (option.inputOtherText.length > 0) {
        this.selectedRadioOthertext = option.inputOtherText
      }
    })
    return this.selectedRadioOthertext
  }

  private set selectedInputOtherText(newValue: string) {
    this.selectedRadioOthertext = newValue
    const updatedOptions = this._options.map(option => {
      if (option.isSelected && option.isOtherOption) {
        option.inputOtherText = newValue
      }
      return option
    })
    this._options = updatedOptions
    this.$emit('input', this._options)
  }

  private get isOtherSelection(): boolean {
    return this._options.some(option => option.isOtherOption)
  }

  private get isOtherOptionSelected(): boolean {
    return this._options.find(option => this.selectedOptionValue === option.value)?.isOtherOption ?? false
  }
}
