import { APIClientBase } from './api-client-base'
import { OwnersGetRequest, OwnersGetResponse } from '@/dtos/owners/get'

class OwnersClient extends APIClientBase {
  constructor() {
    super('/owners')
  }

  getOwners(req: OwnersGetRequest): Promise<OwnersGetResponse> {
    return this.get('', req, OwnersGetResponse, false)
  }
}

export const ownersClient = new OwnersClient()
