


























































































































import { Vue, Component, Watch, Model } from 'vue-property-decorator'
import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { REPORT_TYPES } from '@/constants/schema-constants'
import type { ReportType } from '@/constants/schema-constants'
import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { generateUuid } from '@/libs/uuid-generator'
import { buildingsModule } from '@/stores/buildings-store'
import { TicketsRegularMtgCsvPostRequest } from '@/dtos/tickets/regular-mtg/post'
import { TicketsManagementStatusesCsvPostRequest } from '@/dtos/tickets/search/management-statuses/post'

// マンション選択のメニューの内容
const BUILDING_MENU_ITEMS: {[id: string]: { text: string, label: string}} = {
  all: {
    text: 'すべてのマンション',
    label: '変更する'
  },
  each: {
    text: '個別に選択する',
    label: '変更する'
  }
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),

    SmModal: () => import('@/components/organisms/SmModal.vue'),

    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),
  }
})
export default class SmReportOutput extends Vue {
  BUILDING_MENU_ITEMS = Object.freeze(BUILDING_MENU_ITEMS)
  REPORT_TYPES = Object.freeze(REPORT_TYPES)

  @Model('change', { required: true, default: false })
  private readonly isVisible!: boolean

  reportType: ReportType = REPORT_TYPES.OPERATION
  fromDate = ''
  toDate = ''

  isOutputProcessingAndCompletionTickets = false

  selectedBuildingMenuId = 'all'
  preservingBuildingMenuId = 'all'
  selectedBuildings: Building[] = []
  keyword = ''
  inputText = ''
  isBuildingSelectModalVisible = false
  buildingSelectModalKey = generateUuid()

  private get isDateSelected(): boolean {
    switch (this.reportType) {
      case REPORT_TYPES.OPERATION:
        return this.fromDate !== '' && this.toDate !== ''
      case REPORT_TYPES.MANAGEMENT:
        return this.toDate !== ''
      default:
        return assertExhaustive(this.reportType)
    }
  }

  reportTypeOptions = [new RadioOption('管理状況報告書', REPORT_TYPES.MANAGEMENT), new RadioOption('定例MTG資料', REPORT_TYPES.OPERATION)]

  @Watch('reportType', { immediate: false, deep: false })
  onChangeIsOutputProcessingAndCompletionTickets(): void {
    switch (this.reportType) {
      case REPORT_TYPES.OPERATION:
        this.isOutputProcessingAndCompletionTickets = false
        this.toDate = ''
        break
      case REPORT_TYPES.MANAGEMENT:
        this.isOutputProcessingAndCompletionTickets = true
        this.fromDate = ''
        this.toDate = ''
        break
      default:
        assertExhaustive(this.reportType)
    }
  }

  async onChangeBuildingMenu(): Promise<void> {
    if (this.selectedBuildingMenuId === 'all') {
      this.selectedBuildings = []
      this.keyword = ''
      this.inputText = ''
      this.preservingBuildingMenuId = this.selectedBuildingMenuId
    } else {
      await this.openBuildingSelectModal()
    }
  }

  private get selectedBuildingDisplay(): string {
    if (this.preservingBuildingMenuId === 'all') {
      return 'すべてのマンション'
    } else {
      return this.buildingNames
    }
  }

  private get buildingNames(): string {
    return this.selectedBuildings.map(b => b.buildingName).join('｜')
  }

  onChangeBuilding(buildingIds?: string[]): void {
    this.selectedBuildings = this.getTargetBuildings(buildingIds)
    this.preservingBuildingMenuId = this.selectedBuildingMenuId
  }

  getTargetBuildings(buildingIds?: string[]): Building[] {
    return buildingsModule.buildingsGet.buildings.filter(b => buildingIds?.includes(b.buildingId))
  }

  async openBuildingSelectModal(): Promise<void> {
    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999))
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingSelectModalVisible = true
  }

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  onClickOutputBtn(): void {
    const buildingIds = this.selectedBuildings.map(building => building.buildingId)
    switch (this.reportType) {
      case REPORT_TYPES.OPERATION: {
        const ticketsRegularMtgCsvPostRequest = new TicketsRegularMtgCsvPostRequest(this.fromDate, this.toDate, this.isOutputProcessingAndCompletionTickets, buildingIds)
        this.$emit('output-operation', ticketsRegularMtgCsvPostRequest)
        break
      }
      case REPORT_TYPES.MANAGEMENT: {
        const ticketsManagementStatusesCsvPostRequest = new TicketsManagementStatusesCsvPostRequest(this.toDate, this.isOutputProcessingAndCompletionTickets, buildingIds)
        this.$emit('output-management', ticketsManagementStatusesCsvPostRequest)
        break
      }
      default:
        return assertExhaustive(this.reportType)
    }
    this.isModalVisible = false
  }
}
