































































import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { ADMIN_ROLE, TARGET_EXECUTOR_TYPE } from '@/constants/schema-constants'
import type { TargetExecutorType } from '@/constants/schema-constants'

import { Tab } from '@/components/molecules/SmTabsInPage.vue'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmTabsInPage: () => import('@/components/molecules/SmTabsInPage.vue'),
    SmModal: () => import('@/components/organisms/SmModal.vue'),

    UnregisteredExecutorSelectModalContent: () => import('@/pages/tasks/onboardingAndRoutine/UnregisteredExecutorSelectModalContent.vue'),
    ServiceStaffSelectModalContent: () => import('@/pages/tasks/onboardingAndRoutine/ServiceStaffSelectModalContent.vue'),
  }
})

export default class ExecutorSelectModal extends Vue {
  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)

  @Model('change', { required: true, default: false })
  private readonly isVisible!: boolean

  @Prop({ default: TARGET_EXECUTOR_TYPE.SERVICE_STAFF })
  targetExecutorType!: TargetExecutorType

  @Prop()
  targetId?: string

  // 管理者業務執行者タブのtextfieldに入力された値
  @Prop({ default: '' })
  serviceStaffInputText!: string

  // 管理者業務執行者タブの検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  serviceStaffKeyword!: string

  // その他タブのtextfieldに入力された値
  @Prop({ default: '' })
  unregisteredExecutorInputText!: string

  // その他タブの検索時にコンポーネント(SmTableData)に検索ワードを渡すための変数
  @Prop({ default: '' })
  unregisteredExecutorKeyword!: string

  // 表示中のタブで選択されているID
  selectedTargetId = ''

  // 表示中のタブで選択されている名前
  selectedTargetName = ''

  // 管理者業務執行者タブで選択中のID
  serviceStaffId = ''

  // 管理者業務執行者タブで選択中の社員名
  serviceStaffName = ''

  // 管理者業務執行者タブで選択中の管理者名(略称)
  serviceStaffShortName = ''

  // その他タブで選択中のID
  unregisteredExecutorId = ''

  // その他タブで選択中の名前
  unregisteredExecutorName = ''

  // 管理者業務執行者タブで選択された場合にIDを設定する
  selectServiceStaff(serviceStaffId: string, serviceStaffName: string, adminShortName: string): void {
    this.serviceStaffId = serviceStaffId
    this.serviceStaffName = serviceStaffName
    this.selectedTargetId = serviceStaffId
    this.selectedTargetName = serviceStaffName
    this.serviceStaffShortName = adminShortName
  }

  // その他タブで選択された場合にIDを設定する
  selectUnregisteredExecutor(unregisteredExecutorId: string, unregisteredExecutorName: string): void {
    this.unregisteredExecutorId = unregisteredExecutorId
    this.unregisteredExecutorName = unregisteredExecutorName
    this.selectedTargetId = unregisteredExecutorId
    this.selectedTargetName = unregisteredExecutorName
  }

  private get isModalVisible(): boolean { return this.isVisible }
  private set isModalVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  private get _serviceStaffInputText(): string { return this.serviceStaffInputText }
  private set _serviceStaffInputText(newValue: string) { this.$emit('update:serviceStaffInputText', newValue) }

  private get _serviceStaffKeyword(): string { return this.serviceStaffKeyword }
  private set _serviceStaffKeyword(newValue: string) { this.$emit('update:serviceStaffKeyword', newValue) }

  private get _unregisteredExecutorInputText(): string { return this.unregisteredExecutorInputText }
  private set _unregisteredExecutorInputText(newValue: string) { this.$emit('update:unregisteredExecutorInputText', newValue) }

  private get _unregisteredExecutorKeyword(): string { return this.unregisteredExecutorKeyword }
  private set _unregisteredExecutorKeyword(newValue: string) { this.$emit('update:unregisteredExecutorKeyword', newValue) }

  tabs: Tab[] = [
    new Tab(TARGET_EXECUTOR_TYPE.SERVICE_STAFF.toString(), '管理者業務執行者'),
    new Tab(TARGET_EXECUTOR_TYPE.UNREGISTERED_EXECUTOR.toString(), 'その他'),
  ]

  currentTab = this.targetExecutorType.toString()

  onClickBtn(): void {
    const selectedTargetExecutorType = this.convertOwnerType(this.currentTab)
    this.selectedTargetId = this.getTargetIdOnCurrentTab(selectedTargetExecutorType)
    this.$emit('select', selectedTargetExecutorType, this.selectedTargetId, this.selectedTargetName, this.serviceStaffShortName)
    this.isModalVisible = false
  }

  @Watch('isModalVisible', { immediate: true })
  applyOwnerGroupTargetId(isModalVisible: boolean): void {
    if (isModalVisible && this.targetId) {
      // 選択されているグループに応じてIDを設定する
      switch (this.targetExecutorType) {
        case TARGET_EXECUTOR_TYPE.SERVICE_STAFF:
          this.serviceStaffId = this.targetId
          break
        case TARGET_EXECUTOR_TYPE.UNREGISTERED_EXECUTOR:
          this.unregisteredExecutorId = this.targetId
          break
        default: return assertExhaustive(this.targetExecutorType)
      }
    }
  }

  @Watch('currentTab', { immediate: true })
  onCurrentTabChanged(newValue: string): void {
    // 選択中のタブに合わせて状態を更新する
    const selectedTargetOwnerType = this.convertOwnerType(newValue)
    this.selectedTargetId = this.getTargetIdOnCurrentTab(selectedTargetOwnerType)
  }

  private convertOwnerType(executorTypeStr: string) : TargetExecutorType {
    switch (Number(executorTypeStr)) {
      case TARGET_EXECUTOR_TYPE.SERVICE_STAFF: return TARGET_EXECUTOR_TYPE.SERVICE_STAFF
      case TARGET_EXECUTOR_TYPE.UNREGISTERED_EXECUTOR: return TARGET_EXECUTOR_TYPE.UNREGISTERED_EXECUTOR
      default: return TARGET_EXECUTOR_TYPE.SERVICE_STAFF
    }
  }

  private getTargetIdOnCurrentTab(targetExecutorType: TargetExecutorType) : string {
    switch (targetExecutorType) {
      case TARGET_EXECUTOR_TYPE.SERVICE_STAFF: return this.serviceStaffId
      case TARGET_EXECUTOR_TYPE.UNREGISTERED_EXECUTOR: return this.unregisteredExecutorId
      default: return assertExhaustive(targetExecutorType)
    }
  }

  private get isSelected(): boolean {
    return !!this.selectedTargetId
  }
}
