





















































































































































import { TicketState, TicketTaskState, TICKET_STATES, TICKET_TASK_STATES } from '@/constants/schema-constants'
import { DashboardSummaryGetResponse } from '@/dtos/dashboard/summary/get'
import { getTicketStateLabel, getTicketTaskStateLabel } from '@/libs/state-handler'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmTicketsTasksSummary extends Vue {
  TICKET_STATES = Object.freeze(TICKET_STATES)
  TICKET_TASK_STATES = Object.freeze(TICKET_TASK_STATES)

  @Prop({ required: true, default: '' })
  private readonly nearDeadlineTasksTitle!:string

  @Prop({ required: true, default: () => [] })
  private readonly ticketsTasksSummary!:DashboardSummaryGetResponse

  @Prop({ required: true, default: '' })
  private readonly ticketsIconName!:string

  @Prop({ required: true, default: '' })
  private readonly ticketsTitle!:string

  @Prop({ required: true, default: '' })
  private readonly tasksIconName!:string

  @Prop({ required: true, default: '' })
  private readonly tasksTitle!:string

  private ticketStateLabel(ticketState: TicketState): string {
    return getTicketStateLabel(ticketState)
  }

  private ticketTaskStateLabel(ticketTaskState: TicketTaskState): string {
    return getTicketTaskStateLabel(ticketTaskState)
  }

  upperLimmttedNum(param:number): number {
    const minNum = 0
    const maxNum = 999
    return Math.max(minNum, Math.min(maxNum, param))
  }
}
