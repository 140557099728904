

























import { Vue, Component } from 'vue-property-decorator'
import { NavigationGuardNext, Route } from 'vue-router/types'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDraftInterceptor: () => import('@/components/organisms/SmDraftInterceptor.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue')
  }
})
export default class DraftInterceptorPage extends Vue {
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (this.nextRoute) {
      next()
    } else {
      this.nextRoute = to
      next(false)
    }
  }

  nextRoute: Route | null = null
  leaveHere(): void {
    const routeName = this.nextRoute?.name
    const routeParams = this.nextRoute?.params
    if (routeName) this.$router.push({ name: routeName, params: routeParams })
  }
}
