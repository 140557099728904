





























import { Vue, Component, Model, Prop } from 'vue-property-decorator'

export class Tab {
  label: string
  routeName: string
  disabled: boolean

  constructor(label: string, routeName: string, disabled = false) {
    this.label = label
    this.routeName = routeName
    this.disabled = disabled
  }
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})
export default class SmTabs extends Vue {
  @Prop({ required: true, default: () => [] })
  private readonly tabs!: Tab[]

  /**
    * タブ切替のURL遷移をヒストリーに記録するかを制御します。
    * true -> 記録されない（ブラウザ／ヒストリーバックで、親レベルで前画面へ戻る）
    * false -> 記録される（ブラウザ／ヒストリーバックで、1つ前のタブに戻る）
    */
  @Prop({ default: true })
  private readonly replace!: boolean

  @Prop()
  @Model('change')
  private readonly currentTab!: string

  private get _currentTab(): string { return this.currentTab }
  private set _currentTab(newValue: string) {
    this.$emit('change', newValue)
  }
}
