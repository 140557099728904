


































import type { BtnType } from '@/constants/ux-constants'
import { Vue, Component, Prop, Model } from 'vue-property-decorator'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),

    SmDialogBase: () => import('@/components/organisms/dialog/SmDialogBase.vue'),
  }
})
export default class SmDialogClosable extends Vue {
  @Prop({ required: true, default: '' })
  private readonly title!: string

  @Prop()
  private readonly cancelBtnLabel?: string

  @Prop({ default: false })
  private readonly disableCancelBtn!: boolean

  @Prop()
  private readonly executionBtnLabel?: string

  @Prop({ default: 'primary-flex' })
  private readonly executionBtnType!: BtnType

  @Prop()
  private readonly height?: string

  @Model('change')
  private isVisible!: boolean
}
