



























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { QUESTIONNAIRE_QUESTION_ELEMENT_TYPES, QUESTIONNAIRE_QUESTION_TYPES } from '@/constants/schema-constants'
import type { QuestionnaireQuestionType } from '@/constants/schema-constants'
import { FreeAnswer, QuestionnaireQuestionElement } from '@/dtos/questionnaires/get'
import { QuestionQuestionGetRequest } from '@/dtos/questionnaires/questions/get'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { generateUuid } from '@/libs/uuid-generator'
import { questionnairesModule } from '@/stores/questionnaires-store'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),

    QuestionnaireQuestionDetailModal: () => import('@/components/organisms/modal/QuestionnaireQuestionDetailModal.vue'),
  }
})
export default class SmQuestionAnswer extends Vue {
  QUESTIONNAIRE_QUESTION_TYPES = Object.freeze(QUESTIONNAIRE_QUESTION_TYPES)

  @Prop({ default: '' })
  questionnaireId!: string

  @Prop({ default: '' })
  questionnaireQuestionId!: string

  @Prop({ default: QUESTIONNAIRE_QUESTION_TYPES.FREE })
  questionnaireQuestionType!: QuestionnaireQuestionType

  @Prop()
  sentence!: string

  @Prop({ default: 1 })
  sortOrderNum!: number

  @Prop({ default: () => [] })
  questionnaireQuestionElements!: QuestionnaireQuestionElement[]

  @Prop({ default: () => [] })
  freeAnswers!: FreeAnswer[]

  isQuestionnaireQuestionDetailModalVisible = false
  questionnaireQuestionDetailModalKey = generateUuid()
  answerKeyword = ''
  answerInputText = ''

  private get questionTypeText(): string {
    switch (this.questionnaireQuestionType) {
      case QUESTIONNAIRE_QUESTION_TYPES.FREE: return `${this.sortOrderNum}. 設問(入力式)`
      case QUESTIONNAIRE_QUESTION_TYPES.SINGLE_CHOICE: return `${this.sortOrderNum}. 設問(単一選択式)`
      case QUESTIONNAIRE_QUESTION_TYPES.MULTIPLE_CHOICES: return `${this.sortOrderNum}. 設問(複数選択式)`
      default: return assertExhaustive(this.questionnaireQuestionType)
    }
  }

  private get hasFreeAnswer(): boolean {
    return this.questionnaireQuestionElements.some(e => e.questionnaireQuestionElementType === QUESTIONNAIRE_QUESTION_ELEMENT_TYPES.OTHER_OPTION)
  }

  private async onClickQuestionDetail(): Promise<void> {
    await questionnairesModule.getQuestionnaireQuestionDetail(new QuestionQuestionGetRequest(this.questionnaireId, this.questionnaireQuestionId))
    this.isQuestionnaireQuestionDetailModalVisible = true
  }
}
