import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store } from './index'
import { staffsClient } from '@/clients/staffs-client'
import { AdminRole, ADMIN_ROLE } from '@/constants/schema-constants'
import { LifeManagerDeleteRequest, StaffDeleteRequest } from '@/dtos/staffs/delete'
import { StaffsGetRequest, StaffsGetResponseStaff } from '@/dtos/staffs/get'
import { StaffPutRequest, LifeManagerPutRequest } from '@/dtos/staffs/put'
import { StaffDetailGetRequest, StaffDetailGetResponse } from '@/dtos/staffs/get-detail'
import { MembersGetRequest, MembersGetResponseStaff } from '@/dtos/staffs/members/get'
import { StaffPostRequest, StaffPostResponse, LifeManagerStaffPostRequest } from '@/dtos/staffs/post'

@Module({ store, dynamic: true, namespaced: true, name: 'StaffsStore' })
class StaffsStore extends VuexModule {
  private _staffs: Record<AdminRole, StaffsGetResponseStaff[]> = {
    [ADMIN_ROLE.SERVICE_STAFF]: [],
    [ADMIN_ROLE.LIFE_MANAGER]: [],
    [ADMIN_ROLE.ACCOUNTING_DEPARTMENT]: [],
    [ADMIN_ROLE.FRONT]: [],
    [ADMIN_ROLE.GROUP_CALL_CENTER]: [],
  }

  // 担当者（フロント担当、LM）一覧検索
  get staffs(): (adminRole: AdminRole) => StaffsGetResponseStaff[] {
    return (adminRole: AdminRole) => this._staffs[adminRole] ?? []
  }

  @Mutation
  private SET_STAFFS_GET({ adminRole, staffs } : { adminRole: AdminRole, staffs: StaffsGetResponseStaff[] }): void {
    Vue.set(this._staffs, adminRole, staffs)
  }

  @Action
  async fetchStaffs(req: StaffsGetRequest): Promise<void> {
    const res = await staffsClient.getStaffs(req)
    this.SET_STAFFS_GET({ adminRole: req.role, staffs: res.staffs })
  }

  // 社員登録
  @Action
  async postStaff(req: StaffPostRequest): Promise<StaffPostResponse> {
    const res = await staffsClient.postStaff(req)
    if (!res) throw new Error() // unexpected
    return res
  }

  @Action
  async postLifeManagerStaff(req: LifeManagerStaffPostRequest): Promise<StaffPostResponse> {
    const res = await staffsClient.postLifeManagerStaff(req)
    if (!res) throw new Error() // unexpected
    return res
  }

  // 社員更新
  @Action
  async putStaff(req: StaffPutRequest): Promise<void> {
    await staffsClient.putStaff(req)
  }

  @Action
  async putLifeManagerStaff(req: LifeManagerPutRequest): Promise<void> {
    await staffsClient.putLifeManager(req)
  }

  // 担当者詳細取得
  private _staffDetails: Record<string, StaffDetailGetResponse> = {}
  get staffDetail(): (id: string) => StaffDetailGetResponse | undefined { return (id: string) => this._staffDetails[id] }

  @Mutation
  private SET_STAFF_DETAIL_GET(res: StaffDetailGetResponse): void {
    Vue.set(this._staffDetails, res.userId, res)
  }

  @Action
  async fetchStaffDetail(req: StaffDetailGetRequest): Promise<void> {
    const res = await staffsClient.getStaffDetail(req)
    this.SET_STAFF_DETAIL_GET(res)
  }

  // 管理者業務執行者・フロント担当者削除
  @Action
  async deleteStaff(req: StaffDeleteRequest): Promise<void> {
    await staffsClient.deleteStaff(req)
  }

  // LM削除
  @Action
  async deleteLifeManager(req: LifeManagerDeleteRequest): Promise<void> {
    await staffsClient.deleteLifeManager(req)
  }

  // 担当者（管理者業務執行者、フロント担当、LM）一覧取得
  private _members: Record<AdminRole, MembersGetResponseStaff[]> = {
    [ADMIN_ROLE.SERVICE_STAFF]: [],
    [ADMIN_ROLE.LIFE_MANAGER]: [],
    [ADMIN_ROLE.ACCOUNTING_DEPARTMENT]: [],
    [ADMIN_ROLE.FRONT]: [],
    [ADMIN_ROLE.GROUP_CALL_CENTER]: [],
  }

  get members(): (adminRole: AdminRole) => MembersGetResponseStaff[] {
    return (adminRole: AdminRole) => this._members[adminRole] ?? []
  }

  @Mutation
  private SET_MEMBERS_GET({ adminRole, members } : { adminRole: AdminRole, members: MembersGetResponseStaff[] }): void {
    Vue.set(this._members, adminRole, members)
  }

  @Action
  async fetchMembers(req: MembersGetRequest): Promise<void> {
    const res = await staffsClient.getMembers(req)
    this.SET_MEMBERS_GET({ adminRole: req.role, members: res.staffs })
  }
}

export const staffsModule = getModule(StaffsStore)
