















































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import { Material, User, Section } from '@/dtos/commons'
import { staticRoutesLocal } from '@/routes'
import { Tab } from '@/components/molecules/SmTabs.vue'
import { RadioOption } from '@/components/owner-apps/SmOwRadioSelectsQuestion.vue'
import { CheckboxQuestionOption } from '@/components/owner-apps/SmOwCheckboxQuestion.vue'
import { buildingsModule } from '@/stores/buildings-store'
import { BuildingOwnersGetRequest } from '@/dtos/buildings/owners/get'
import { generateUuid } from '@/libs/uuid-generator'

@Component({
  components: {
    SmAvatar: () => import('@/components/atoms/SmAvatar.vue'),
    SmBadgeContent: () => import('@/components/atoms/SmBadgeContent.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmCheckbox: () => import('@/components/atoms/SmCheckbox.vue'),
    SmChip: () => import('../components/atoms/SmChip.vue'),
    SmSwitch: () => import('../components/atoms/SmSwitch.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmBtnToggle: () => import('../components/molecules/SmBtnToggle.vue'),
    SmCheckBoxInverse: () => import('@/components/molecules/SmCheckBoxInverse.vue'),
    SmCardIdea: () => import('@/components/molecules/card/SmCardIdea.vue'),
    SmCardResolution: () => import('@/components/molecules/card/SmCardResolution.vue'),
    SmCardSectionData: () => import('@/components/molecules/card/SmCardSectionData.vue'),
    SmDatePickers: () => import('../components/molecules/SmDatePickers.vue'),
    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmListBudget: () => import('@/components/molecules/list/SmListBudget.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTabs: () => import('@/components/molecules/SmTabs.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),

    SmAppBar: () => import('@/components/organisms/SmAppBar.vue'),
    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),

    SmOwRadioSelectsQuestion: () => import('@/components/owner-apps/SmOwRadioSelectsQuestion.vue'),
    SmOwCheckboxQuestion: () => import('@/components/owner-apps/SmOwCheckboxQuestion.vue'),

    OwnerSelectModal: () => import('@/pages/owner-notifications/OwnerSelectModal.vue')
  }
})
export default class HelloWorld extends Vue {
  CHIP_COLORS = Object.freeze(CHIP_COLORS)

  // 単一選択式の設問
  questions = [
    { text: '性別', isRequired: true },
    { text: '性別２', isRequired: false },
  ]

  // 単一選択式の選択肢
  radioOption: RadioOption[] = [
    {
      label: '男性',
      value: '1',
      isSelected!: false,
      isOtherOption: false,
      inputOtherText: ''
    },
    {
      label: '女性',
      value: '2',
      isSelected!: false,
      isOtherOption: false,
      inputOtherText: ''
    },
    {
      label: '無回答',
      value: '3',
      isSelected!: false,
      isOtherOption: false,
      inputOtherText: ''
    },
    {
      label: 'その他',
      value: '0',
      isSelected!: false,
      isOtherOption: true,
      inputOtherText: ''
    },
  ]

  radioOption2: RadioOption[] = [
    {
      label: '男性',
      value: '1',
      isSelected!: false,
      isOtherOption: false,
      inputOtherText: ''
    },
    {
      label: '女性',
      value: '2',
      isSelected!: false,
      isOtherOption: false,
      inputOtherText: ''
    },
    {
      label: '無回答',
      value: '3',
      isSelected!: false,
      isOtherOption: false,
      inputOtherText: ''
    },
  ]

  questionOptions:CheckboxQuestionOption[] = [
    { optionText: '理事への就任の負担がなくなるから', isSelected: false, elementType: 1, value: '1' },
    { optionText: 'スマホ・PCを用いた非対面・全員参加型として有効だから', isSelected: false, elementType: 2, value: '2' },
    { optionText: 'その他', isSelected: false, elementType: 3, value: '0' }
  ]

  questionOptions2:CheckboxQuestionOption[] = [
    { optionText: '設問21', isSelected: false, elementType: 1, value: '1' },
    { optionText: '設問2', isSelected: false, elementType: 1, value: '2' },
    { optionText: 'その他', isSelected: false, elementType: 1, value: '0' }
  ]

  questionData = {
    text: '2.1.で①、②の回答を選んだ方は理由を教えてください',
    isRequired: true,
    options: this.questionOptions
  }

  questionData2 = {
    text: '設問文2',
    isRequired: false,
    options: this.questionOptions2
  }

  btnTexts = [
    {
      text: '負担がどの程度増えるか再度確認したいです',
      id: 0
    },
    {
      text: '基本料金の内訳、会計監査の委託先開示を希望します',
      id: 1
    },
    {
      text: '現状、費用対効果が見えないこと、まだこのサービスが始まってまもなく知見が集まっていないこと…',
      id: 2
    }
  ]

  check1 = true
  check2 = true
  check3:string[] = []

  labelText = '理事への就任負担が少なくなるから'
  smCheckBoxInverse = false

  areaText = ''
  areaText2 = ''
  count=0
  date = ''
  expansion = false
  testWord='testest'
  testWord2=0

  isDialogVisible = false
  selectedToggles = []
  switchValue=false
  section: Section = { sectionTitle: '', sectionBody: '', material: null }
  menuId = 'menu1'
  isImgDialogVisible = false

  image: Material = {
    caption: 'キャプションキャプションキャプションキャプションキャプションキャプションキャプションキャプション',
    materialId: 'aga51gtg-6tgg-5ta6-y7ga-56ya6ha46hga',
    materialType: MATERIAL_TYPES.IMAGE,
    materialUrl: 'http://placehold.jp/800x600.png',
    originalFileName: 'dummy.jpg',
  }

  video: Material = {
    caption: 'キャプションキャプションキャプションキャプションキャプションキャプションキャプションキャプション',
    materialId: 'aga51gtg-6tgg-5ta6-y7ga-56ya6ha46hga',
    materialType: MATERIAL_TYPES.VIDEO,
    materialUrl: 'http://techslides.com/demos/sample-videos/small.mp4',
    originalFileName: 'dummy.jpg',
  }

  menuItems = {
    menu1: {
      text: 'アイデア（区分所有者の投稿）',
      label: 'アイデアを新規作成する'
    },
    menu2: {
      text: 'アイデア（賛同・意見受付用）',
      label: 'アイデアを新規作成する'
    }
  }

  menuItems2 = {
    menu1: {
      text: '投稿日が新しい順に並び替える',
      label: '投稿日が新しい順'
    },
    menu2: {
      text: '投稿日が古い順に並び替える',
      label: '投稿日が古い順'
    }
  }

  userWithRoom: User
  = {
    userId: '1111',
    iconImagePath: 'https://cdn.vuetifyjs.com/images/john.jpg',
    userName: '佐藤健太',
    roomNumber: '301号室'
  }

  user: User
  = {
    userId: '1111',
    iconImagePath: 'https://cdn.vuetifyjs.com/images/john.jpg',
    userName: '佐藤健太'
  }

  userEmpty = new User()

  toggleButtons = [
    {
      id: 'test1',
      label: 'テスト１'
    },
    {
      id: 'test2',
      label: 'テスト２'
    },
    {
      id: 'test3',
      label: 'テスト３'
    },
  ]

  selectedTab = ''
  tabs: Tab[] = [
    new Tab('アイデア内容', staticRoutesLocal.helloWorld.getChild('page1').name, false),
    new Tab('意見', staticRoutesLocal.helloWorld.getChild('page2').name, true),
    new Tab('相談', staticRoutesLocal.helloWorld.getChild('page3').name, true)
  ]

  isOwnerSelectModalVisible = false
  async openOwnerSelectModal():Promise<void> {
    const req = new BuildingOwnersGetRequest()
    req.buildingId = 'buildingId'
    await buildingsModule.fetchBuildingOwners(req)
    this.ownerSelectModalKey = generateUuid()
    this.isOwnerSelectModalVisible = true
  }

  ownerSelectModalKey = generateUuid()

  // オブジェクトでくくっていることに特に意味はない
  ownerSelectModalProp = {
    inputText: '',
    keyword: '',
  }

  selectedOwnerUserIds:string[] = []
  updateOwnerUserIds(newValue:string[]):void {
    this.selectedOwnerUserIds = newValue
  }

  get selectedOwners():string {
    return buildingsModule.buildingOwnersGet.owners.filter(e => this.selectedOwnerUserIds.includes(e.userId)).map(e => e.userName).join('、')
  }

  onToggleChange(): void {
    console.log('selected toggle changed: ' + this.selectedToggles)
  }

  onClickBtn(): void {
    console.log('click btn')
    console.log(this.section)
  }

  goToTemplateUsage(): void { this.$router.push({ name: staticRoutesLocal.templateUsage.name }) }

  onClickAvatar(): void {
    console.log('click avatar icon')
  }

  clickBtn(): void {
    console.log('click btn')
  }

  onClickCard(): void {
    console.log('card click')
  }

  onClickMenuButton(id: string): void {
    console.log(id)
  }

  onChangeMenu(id: string): void {
    console.log(id)
    this.menuId = id
  }

  onChangeSwitch(): void {
    console.log('change switch')
  }

  onChangeSelectedTab(): void {
    console.log(this.selectedTab)
  }
}
