





















import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { VueEditor } from 'vue2-editor'
import { WYSIWYG_EDITOR_TYPES } from '@/constants/ux-constants'
import type { WysiwygEditorType } from '@/constants/ux-constants'

// WYSIWYGエディタの種類ごとで、ツールバーに表示する要素のリストを保持する辞書
const customToolbars = {
  text: ['bold', 'strike', { list: 'ordered' }, { list: 'bullet' }, { header: [false, 2, 3, 4] }],
  annotation: ['bold', 'strike'],
} as const

// WYSIWYGエディタの種類ごとに入力を許容する装飾を保持する辞書（これが無いと外部からの入力で無制限に装飾可能となってしまう）
const customFormats = {
  text: ['bold', 'strike', 'list', 'indent', 'header'],
  annotation: ['bold', 'strike'],
} as const

@Component({
  components: {
    VueEditor,
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})

export default class SmWysiwygEditor extends Vue {
  @Prop({
    default: 'text',
    validator: w => WYSIWYG_EDITOR_TYPES.includes(w)
  })
  private readonly wysiwygEditorType!: WysiwygEditorType

  @Prop()
  private readonly errorMessages?: string[]

  @Prop()
  private readonly placeholder?: string

  @Model('input', { required: true, default: '' })
  private readonly content!: string | null

  private get _content(): string { return this.content ?? '' }
  private set _content(newValue: string) { this.$emit('input', newValue) }

  private get _customToolbar() { return customToolbars[this.wysiwygEditorType] }
  private get _customFormat() { return customFormats[this.wysiwygEditorType] }
}
