









































import { Vue, Component } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { Column, Row, Cell } from '@/components/molecules/SmTableSimpleRepairPlanDetail.vue'
import { buildingsModule } from '@/stores/buildings-store'
import { simpleRepairsModule } from '@/stores/simple-repairs-store'
import { SimpleRepairsPlansDetailGetResponse } from '@/dtos/simple-repairs/plans/get'

/**
 * periodごとの列のid（period1 , period2・・・）。ヘッダ行とデータ行とで各カラムのidを一致させる。
 */
function getPeriodId(period: number): string {
  return 'period' + period
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmTableSimpleRepairPlanDetail: () => import('@/components/molecules/SmTableSimpleRepairPlanDetail.vue'),

    SmOwBtnText: () => import('@/components/owner-apps/SmOwBtnText.vue'),
  }
})
export default class SimpleRepairPlanConstructionSubPage extends Vue {
  private get simpleRepairsDetail(): SimpleRepairsPlansDetailGetResponse { return simpleRepairsModule.simpleRepairsDetailGet }

  outputPDF(): void {
    this.$router.push({ name: staticRoutes.simpleRepairPlanDetailConstructionPrint.name })
  }

  // ヘッダ行
  private get columns(): Column[] {
    // ラベル列 -> 期の一覧 -> 総計列 という構造の表を作る。
    const periods = this.simpleRepairsDetail?.constructionPlan?.totalRows?.periodTotals.map(total => {
      return {
        id: getPeriodId(total.period),
        period: total.period,
        yearMonth: buildingsModule.periodToYearMonth(total.period)
      }
    }) ?? []
    return [{ id: 'rowLabel', isCategoryColumn: true }, ...periods, { id: 'itemTotal', isTotalColumn: true }]
  }

  // データ行（全体）
  private get rows(): Row[] {
    return [...this.detailRows, ...this.totalRows]
  }

  // データ行（明細）
  private get detailRows(): Row[] {
    // カテゴリの配列がそれぞれ修繕項目の配列を持っているものを、フラットな1つの配列に変換
    return this.simpleRepairsDetail?.constructionPlan?.detailRows?.categories.flatMap(category => {
      const periods = category.items.map(item => {
        const cells = item.periods.reduce((acc: Record<string, Cell>, current) => {
          acc[getPeriodId(current.period)] = { prospect: current.prospect ?? undefined }
          return acc
        }, {})

        return { rowLabel: item.itemLabel, cells: cells, itemTotal: { total: item.total } }
      })

      return [
        { rowLabel: category.categoryLabel, isCategoryRow: true },
        ...periods,
      ]
    }) ?? []
  }

  // データ行（合計値）
  private get totalRows(): Row[] {
    if (!this.simpleRepairsDetail) return []
    const taxExcludedCells : Record<string, Cell> = {}
    const taxCells : Record<string, Cell> = {}
    const taxIncludedCells : Record<string, Cell> = {}
    const cumulativeCells : Record<string, Cell> = {}
    const performanceTaxIncludedCells : Record<string, Cell> = {}
    const performanceCumulativeCells : Record<string, Cell> = {}

    if (this.simpleRepairsDetail?.constructionPlan?.totalRows?.periodTotals) {
      for (const periodTotal of this.simpleRepairsDetail.constructionPlan.totalRows.periodTotals) {
        const periodId = getPeriodId(periodTotal.period)

        taxExcludedCells[periodId] = { total: periodTotal.taxExcluded }
        taxCells[periodId] = { total: periodTotal.tax }
        taxIncludedCells[periodId] = { total: periodTotal.taxIncluded }
        cumulativeCells[periodId] = { total: periodTotal.cumulative }
        performanceTaxIncludedCells[periodId] = { total: periodTotal.performanceTaxIncluded }
        performanceCumulativeCells[periodId] = { total: periodTotal.performanceCumulative }
      }
    }

    const rowTotal = this.simpleRepairsDetail?.constructionPlan?.totalRows?.rowTotal
    return [
      { rowLabel: '単年度修繕費', cells: taxExcludedCells, itemTotal: { total: rowTotal?.taxExcluded }, isBlueTotalRow: true },
      { rowLabel: '単年度消費税', cells: taxCells, itemTotal: { total: rowTotal?.tax }, isBlueTotalRow: true },
      { rowLabel: '単年度合計', cells: taxIncludedCells, itemTotal: { total: rowTotal?.taxIncluded }, isGrayTotalRow: true },
      { rowLabel: '修繕費累計', cells: cumulativeCells, itemTotal: { total: rowTotal?.cumulative }, isGrayTotalRow: true },
      { rowLabel: '単年度合計（実績）', cells: performanceTaxIncludedCells, itemTotal: { total: rowTotal?.performanceTaxIncluded }, isRedTotalRow: true },
      { rowLabel: '修繕費累計（実績）', cells: performanceCumulativeCells, itemTotal: { total: rowTotal?.performanceCumulative }, isRedTotalRow: true },
    ]
  }
}
