










































































































































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { staticRoutes } from '@/routes'
import { concatSerialChar } from '@/libs/add-alphabet-provider'
import { ColumnToType, deepCopy } from '@/libs/deep-copy-provider'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { getIdeaLabel } from '@/libs/state-handler'

import { IDEA_STATES, ResolutionType, RESOLUTION_TYPES } from '@/constants/schema-constants'
import { CHIP_COLORS, ERROR_MESSAGES } from '@/constants/ux-constants'
import { BuildingDetailGetRequest } from '@/dtos/buildings/get-detail'
import { Estimate, FollowingResolution, User, Section, Material } from '@/dtos/commons'
import { Details } from '@/dtos/ideas/admin-idea/commons'
import { AdminIdeaDetailGetResponse, AdminIdeaDetailGetResponsePlan } from '@/dtos/ideas/admin-idea/get-detail'
import { adminIdeasModule, AdminIdeaCommonState } from '@/stores/admin-ideas-store'
import { buildingsModule } from '@/stores/buildings-store'
import { currentStateModule } from '@/stores/current-state'
import { errorsModule } from '@/stores/errors'
import { myProfileModule } from '@/stores/my-profile-store'
import { structureModule } from '@/stores/structure-store'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),

    SmExpansionArea: () => import('@/components/molecules/SmExpansionArea.vue'),
    SmListBudget: () => import('@/components/molecules/list/SmListBudget.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue')
  }
})
export default class DetailSubPage extends Mixins(CurrentAdminManager) {
  @Prop()
  ideaId!: string

  @Prop()
  buildingId?: string

  async created(): Promise<void> {
    // プレビュー初期表示時
    if (this.isPreview && !currentStateModule.currentBuildingId) {
      if (!this.buildingId) {
        errorsModule.setGlobalErrors([ERROR_MESSAGES.UNEXPECTED])
        return
      }

      await buildingsModule.fetchBuildingDetail(new BuildingDetailGetRequest(this.buildingId))
      await myProfileModule.fetchMyProfile()
      currentStateModule.setCurrentBuilding(this.buildingId)
    }

    await adminIdeasModule.fetchIdeaDetail({ ideaId: this.ideaId })
    this.initIdea()
  }

  private get isPreview(): boolean { return structureModule.isPreview }

  private get ideaCommon(): AdminIdeaCommonState {
    return adminIdeasModule.adminIdeaCommonStateGet ?? new AdminIdeaCommonState()
  }

  idea = new AdminIdeaDetailGetResponse()

  private initIdea(): void {
    const res = adminIdeasModule.detailResponse(this.ideaId) ?? new AdminIdeaDetailGetResponse()
    this.idea = deepCopy(
      res,
      {
        AdminIdeaDetailGetResponse: new ColumnToType(AdminIdeaDetailGetResponse),
        details: new ColumnToType(Details),
        followingResolution: new ColumnToType(FollowingResolution),
        user: new ColumnToType(User),
        material: new ColumnToType(Material),
        plans: new ColumnToType(AdminIdeaDetailGetResponsePlan),
        estimates: new ColumnToType(Estimate, true),
        sections: new ColumnToType(Section, true),
      },
      'AdminIdeaDetailGetResponse')
  }

  private get isEditable(): boolean {
    return this.$isServiceStaff &&
        (this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT ||
         this.ideaCommon.ideaState === IDEA_STATES.ADMIN.COUNTING ||
         this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION)
  }

  private get isPrintable(): boolean {
    return this.$isServiceStaff && this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT
  }

  private get deadlineText():string {
    return this.ideaCommon.ideaState === IDEA_STATES.ADMIN.ACCEPTING_OPINION ? `意見締切日時:${this.idea.opinionDeadline}` : `賛同締切日時:${this.idea.deadline}`
  }

  private get chipText(): string {
    return getIdeaLabel(this.ideaCommon.ideaState)
  }

  private get chipColor(): string {
    return this.ideaCommon.ideaState === IDEA_STATES.ADMIN.DRAFT ? CHIP_COLORS.GREEN : CHIP_COLORS.WHITE2
  }

  private onClickFollowingResolution(resolutionId: string, type: ResolutionType) {
    if (this.isPreview) return
    switch (type) {
      case RESOLUTION_TYPES.GENERAL_MEETING: this.$router.push({ name: staticRoutes.gmResolutionDetail.name, params: { resolutionId } }); return
      case RESOLUTION_TYPES.ONLINE: this.$router.push({ name: staticRoutes.onlineResolutionDetail.name, params: { resolutionId } }); return
      default: assertExhaustive(type)
    }
  }

  private onClickBaseIdea(baseIdeaId: string) {
    if (this.isPreview) return
    this.$router.push({ name: staticRoutes.ownerIdeaDetail.name, params: { ideaId: baseIdeaId } })
  }

  private getPlanTitleWithPrefix(index:number):string {
    return concatSerialChar(this.idea.plans.length, index)
  }

  onClickEdit(): void {
    if (this.isPreview) return
    this.$router.push({ name: staticRoutes.adminIdeaUpdate.name, params: { ideaId: this.ideaId } })
  }

  onClickPrintPdf():void {
    if (this.isPreview) return
    this.$router.push({ name: staticRoutes.adminIdeaDetailPrint.name, params: { ideaId: this.ideaId } })
  }
}
