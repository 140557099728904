




















































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { ticketsClient } from '@/clients/tickets-client'
import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { ADMIN_ROLE, CaseType, CASE_TYPES, InformationSourceType, INFORMATION_SOURCE_TYPES, TicketType, TICKET_TYPES } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import { Building, BuildingsGetRequest } from '@/dtos/buildings/get'
import { BuildingAdminIdea } from '@/dtos/buildings/admin-idea/get'
import { BuildingOwnersGetRequest } from '@/dtos/buildings/owners/get'
import { User } from '@/dtos/commons'
import { TicketsPostRequest, TicketsPostRequestAdmin, TicketsPostRequestBaseIdea, TicketsPostRequestBuilding, TicketsPostRequestOwnerUser } from '@/dtos/tickets/post'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { generateUuid } from '@/libs/uuid-generator'
import { staticRoutes } from '@/routes'
import { buildingsModule } from '@/stores/buildings-store'
import { myProfileModule } from '@/stores/my-profile-store'

// YYYY-MM-DD形式で現在の年月日を返す
const currentDate = () => {
  const date = new Date()
  // ISOString形式の日本時間を取得したいが、
  // toISOString()の結果はUTCのため新規・修正に関わらず9時間進める
  date.setHours(date.getHours() + 9)
  return date.toISOString().substr(0, 10)
}

// 投稿内容
class FormInputs {
  ticketName = ''
  ticketType: TicketType = TICKET_TYPES.MANUAL
  admin: User | null = null
  buildingId = ''
  ownerUserId = ''
  receptionDate: string = currentDate()
  informationSourceType: InformationSourceType = INFORMATION_SOURCE_TYPES.FRONT
  informationSource = ''
  caseType: CaseType = CASE_TYPES.OWNER_CONSULTATION
  inquiryBody = ''
  ideaIds: string[] = []
  correspondence = ''
  isManagementStatusReport = true
  isOperationStatusReport = true
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),
    SmTextField: () => import('@/components/molecules/SmTextField.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    AdminIdeasSelectModal: () => import('@/pages/ideas/admin-idea/AdminIdeasSelectModal.vue'),
    BuildingSelectModal: () => import('@/components/organisms/modal/BuildingSelectModal.vue'),
    OwnerSelectModal: () => import('@/pages/owner-notifications/OwnerSelectModal.vue'),
    StaffSelectModal: () => import('@/components/organisms/modal/StaffSelectModal.vue'),
  }
})
export default class TicketPostPage extends Vue {
  TICKET_TYPES = Object.freeze(TICKET_TYPES)
  INFORMATION_SOURCE_TYPES = Object.freeze(INFORMATION_SOURCE_TYPES)
  CASE_TYPES = Object.freeze(CASE_TYPES)
  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)
  CHIP_COLORS = Object.freeze(CHIP_COLORS)
  inputs = new FormInputs()

  async created(): Promise<void> {
    this.inputs.admin = myProfileModule.myProfileGet?.user ?? null

    await buildingsModule.fetchBuildings(new BuildingsGetRequest(0, 999, undefined, undefined, true))
  }

  private get isInvalidInput(): boolean {
    // validation-observerでは検知しないデータのバリデーションチェックを行う
    if (this.inputs.ticketType === TICKET_TYPES.RESOLUTION) {
      if (this.inputs.buildingId === '') return true
      if (this.inputs.ownerUserId !== '') return true
    }
    return false
  }

  private get isResolutionTicket(): boolean {
    return this.inputs.ticketType === TICKET_TYPES.RESOLUTION
  }

  get showCorrespondenceDetail(): boolean {
    return this.inputs.ticketType === TICKET_TYPES.MANUAL
  }

  // --------------- 画面表示内容 ---------------

  // コンテンツ（チケット種別）選択内容
  selectionTicketTypeItems = [
    { value: TICKET_TYPES.RESOLUTION, label: '決議' },
    { value: TICKET_TYPES.MANUAL, label: '手動' },
  ]

  private get selectedBuildingName(): string | undefined {
    return buildingsModule.buildingsGet.buildings.find(b => b.buildingId === this.inputs.buildingId)?.buildingName
  }

  private get selectedOwnerUserName(): string | undefined {
    return buildingsModule.buildingOwnersGet.owners.find(o => o.userId === this.inputs.ownerUserId)?.userName
  }

  // 情報元選択内容
  private get selectionInformationSourceItems(): {value: InformationSourceType, label: string}[] {
    const items: {value: InformationSourceType, label: string}[] = []
    if (this.inputs.ticketType === TICKET_TYPES.RESOLUTION) {
      items.push(
        { value: INFORMATION_SOURCE_TYPES.IDEA_POSTED, label: 'アイデア・プラン投稿' },
      )
    } else {
      items.push(
        { value: INFORMATION_SOURCE_TYPES.FRONT, label: 'フロント' },
        { value: INFORMATION_SOURCE_TYPES.LM, label: 'LM' },
        { value: INFORMATION_SOURCE_TYPES.GCC, label: 'GCC' },
        { value: INFORMATION_SOURCE_TYPES.SM_AT_HASEKO, label: 'sm@haseko' },
        { value: INFORMATION_SOURCE_TYPES.MAIL_RECEIVED_AT_SERVICE_STAFF, label: '管理者業務執行者宛メール受信' },
        { value: INFORMATION_SOURCE_TYPES.HP, label: 'HP' },
        { value: INFORMATION_SOURCE_TYPES.OTHERS, label: 'そのほか（自由入力）' },
      )
    }
    return items
  }

  // 案件種別選択内容
  private get selectionCaseTypeItems(): {value: CaseType, label: string}[] {
    switch (this.inputs.ticketType) {
      case TICKET_TYPES.RESOLUTION:
        return [{ value: CASE_TYPES.OWNER_IDEA, label: '顧客対応（アイデア・プラン）' }]
      case TICKET_TYPES.MANUAL:
        return [
          { value: CASE_TYPES.OWNER_CONSULTATION, label: '顧客対応（相談・連絡）' },
          { value: CASE_TYPES.JUDGEMENT, label: '判断対応' },
          { value: CASE_TYPES.BASED_ON_THE_TERMS, label: '規約に基づく申請受付' },
        ]
      case TICKET_TYPES.IDEA:
      case TICKET_TYPES.CONSULTATION:
        break
      default:
        assertExhaustive(this.inputs.ticketType)
    }
    return []
  }

  private get managementCaseTypeLabel(): string {
    switch (this.inputs.caseType) {
      case CASE_TYPES.OWNER_IDEA: return 'Webアプリの投稿アイデアへの取り組み'
      case CASE_TYPES.OWNER_CONSULTATION: return '居住者からの意見・要望への取り組み'
      case CASE_TYPES.JUDGEMENT: return '居住者からの意見・要望への取り組み'
      case CASE_TYPES.BASED_ON_THE_TERMS: return '-'
      default: return assertExhaustive(this.inputs.caseType)
    }
  }

  private get operationCaseTypeLabel(): string {
    switch (this.inputs.caseType) {
      case CASE_TYPES.OWNER_IDEA: return '対応履歴'
      case CASE_TYPES.OWNER_CONSULTATION: return '対応履歴'
      case CASE_TYPES.JUDGEMENT: return '対応履歴'
      case CASE_TYPES.BASED_ON_THE_TERMS: return '-'
      default: return assertExhaustive(this.inputs.caseType)
    }
  }

  private get baseIdeas(): (BuildingAdminIdea | undefined)[] {
    if (this.inputs.ideaIds.length) {
      return buildingsModule.adminIdeasGet.ideas.filter(i => this.inputs.ideaIds.includes(i.ideaId))
    } else return []
  }

  private get reportOutputRadioOptions(): RadioOption[] {
    return [
      new RadioOption('出力する', true),
      new RadioOption('出力しない', false),
    ]
  }

  // --------------- ボタンを押下した際の処理 ---------------
  async onClickRegisterBtn(): Promise<void> {
    const req = new TicketsPostRequest(
      this.inputs.ticketName,
      this.inputs.ticketType,
      new TicketsPostRequestAdmin(this.inputs.admin?.userId ?? ''),
      this.inputs.receptionDate,
      this.inputs.informationSourceType,
      this.inputs.caseType,
      this.inputs.isManagementStatusReport,
      this.inputs.isOperationStatusReport,
      this.inputs.buildingId ? new TicketsPostRequestBuilding(this.inputs.buildingId) : undefined,
      this.inputs.ownerUserId && this.inputs.ticketType === TICKET_TYPES.MANUAL ? new TicketsPostRequestOwnerUser(this.inputs.ownerUserId) : undefined,
      this.inputs.informationSourceType === INFORMATION_SOURCE_TYPES.OTHERS ? this.inputs.informationSource : undefined,
      this.inputs.inquiryBody ? this.inputs.inquiryBody : undefined,
      this.inputs.correspondence ? this.inputs.correspondence : undefined,
      this.inputs.ideaIds.length && this.inputs.informationSourceType === INFORMATION_SOURCE_TYPES.IDEA_POSTED ? this.inputs.ideaIds.map((id, index) => new TicketsPostRequestBaseIdea(id, index + 1)) : undefined
    )

    await ticketsClient.postTicket(req)

    this.$router.push({ name: staticRoutes.ticketsList.name })
  }

  onChangeTicketType(value: TicketType): void {
    switch (value) {
      case TICKET_TYPES.RESOLUTION:
        this.inputs.ownerUserId = ''
        this.inputs.informationSourceType = INFORMATION_SOURCE_TYPES.IDEA_POSTED
        this.inputs.informationSource = ''
        this.inputs.caseType = CASE_TYPES.OWNER_IDEA
        this.inputs.isManagementStatusReport = true
        this.inputs.isOperationStatusReport = true
        break
      case TICKET_TYPES.MANUAL:
        this.inputs.informationSourceType = INFORMATION_SOURCE_TYPES.FRONT
        this.inputs.informationSource = ''
        this.inputs.caseType = CASE_TYPES.OWNER_CONSULTATION
        this.inputs.ideaIds = []
        this.inputs.isManagementStatusReport = true
        this.inputs.isOperationStatusReport = true
        break
      case TICKET_TYPES.IDEA:
      case TICKET_TYPES.CONSULTATION:
        break
      default:
        assertExhaustive(value)
    }
  }

  onChangeSelectedAdmin(admins: User[]): void {
    this.inputs.admin = admins[0]
  }

  onChangeSelectedBuilding(buildingIds: string[]): void {
    this.inputs.buildingId = buildingIds[0]
    this.inputs.ownerUserId = ''
    this.inputs.ideaIds = []
  }

  private get selectedBuilding():Building[] {
    const building = buildingsModule.buildingsGet.buildings.find(building => building.buildingId === this.inputs.buildingId)
    return building ? [building] : []
  }

  onClickClearSelectedBuilding(): void {
    this.inputs.buildingId = ''
    this.inputs.ownerUserId = ''
    this.inputs.ideaIds = []
  }

  onChangeSelectedOwnerUser(ownerUserIds: string[]): void {
    this.inputs.ownerUserId = ownerUserIds[0]
  }

  onClickClearSelectedOwnerUser(): void {
    this.inputs.ownerUserId = ''
  }

  onChangeInformationSource(): void {
    this.inputs.informationSource = ''
    this.inputs.isManagementStatusReport = this.inputs.caseType !== CASE_TYPES.BASED_ON_THE_TERMS
    this.inputs.isOperationStatusReport = this.inputs.caseType !== CASE_TYPES.BASED_ON_THE_TERMS
  }

  onChangeCaseType(value: CaseType): void {
    this.inputs.isManagementStatusReport = value !== CASE_TYPES.BASED_ON_THE_TERMS
    this.inputs.isOperationStatusReport = value !== CASE_TYPES.BASED_ON_THE_TERMS
  }

  onChangeSelectedAdminIdeas(ideaIds: string[]): void {
    this.inputs.ideaIds = ideaIds
  }

  // --------------- モーダル表示等の処理 ---------------

  // 担当者選択モーダル
  isAdminSelectModalVisible = false
  adminSelectModalKeyword = ''
  adminSelectModalInputText = ''
  async openAdminSelectModal(): Promise<void> {
    this.isAdminSelectModalVisible = true
  }

  // マンション選択モーダル
  isBuildingSelectModalVisible = false
  buildingSelectModalKey = generateUuid()
  buildingSelectModalKeyword = ''
  buildingSelectModalInputText = ''
  openBuildingSelectModal(): void {
    this.buildingSelectModalKey = generateUuid()
    this.isBuildingSelectModalVisible = true
  }

  // 区分所有者選択モーダル
  isOwnerSelectModalVisible = false
  ownerSelectModalKey = generateUuid()
  ownerSelectModalKeyword = ''
  ownerSelectModalInputText = ''
  async openOwnerSelectModal(): Promise<void> {
    if (!this.inputs.buildingId) return

    const req = new BuildingOwnersGetRequest()
    req.buildingId = this.inputs.buildingId
    await buildingsModule.fetchBuildingOwners(req)

    this.ownerSelectModalKey = generateUuid()
    this.isOwnerSelectModalVisible = true
  }

  // アイデア選択モーダル
  isAdminIdeaSelectModalVisible = false
  adminIdeaSelectModalKey = generateUuid()
  openAdminIdeaSelectModal(): void {
    this.adminIdeaSelectModalKey = generateUuid()
    this.isAdminIdeaSelectModalVisible = true
  }
}
