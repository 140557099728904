export class BuildingAdminIdeasGetRequest {
  buildingId!: string
  resolutionTicketId?: string

  constructor(buildingId:string, resolutionTicketId?: string) {
    this.buildingId = buildingId
    this.resolutionTicketId = resolutionTicketId
  }
}

export class BuildingAdminIdea {
  ideaId!: string
  title!: string
  archivedAt?: string
}

export class BuildingAdminIdeasGetResponse {
  ideas: BuildingAdminIdea[] = []
}
