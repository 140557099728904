import { render, staticRenderFns } from "./SmBadgeContent.vue?vue&type=template&id=563f00a2&"
import script from "./SmBadgeContent.vue?vue&type=script&lang=ts&"
export * from "./SmBadgeContent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports