























































import type { Navigation } from '@/dtos/navigation'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmDrawer extends Vue {
  @Prop({ required: true, default: '' })
  private readonly building!: string

  @Prop({ required: true, default: () => [] })
  private readonly items?: Navigation[]

  @Prop({ required: true, default: '' })
  private readonly currentId?: string
}
