import { Type } from 'class-transformer'
import { AdminRole } from '@/constants/schema-constants'

export class StaffsGetRequest {
  keyword?: string
  role: AdminRole
  isIncludeIconImagePath?: boolean

  constructor(role: AdminRole) {
    this.role = role
  }
}

export class StaffsGetResponseStaff {
  userId!: string
  userName!: string
  userShortName?: string
  iconImagePath?: string
}

export class StaffsGetResponse {
  @Type(() => StaffsGetResponseStaff) staffs: StaffsGetResponseStaff[] = []
}
