



































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue')
  }
})
export default class SmOwBtnToggle extends Vue {
  @Prop({ default: false })
  private readonly btnDense!: boolean

  @Prop({ default: false })
  private readonly textDense!: boolean

  @Prop({ default: false })
  private readonly countDense!: boolean

  @Prop()
  private readonly icon?: string

  @Prop()
  private readonly text?: string

  @Prop()
  private readonly count?: number

  @Prop({ default: false })
  private readonly value!: boolean

  @Prop({ default: false })
  private readonly disabled!: boolean

  private get isSelected(): boolean { return this.value }
  private set isSelected(newValue: boolean) { this.$emit('input', newValue) }

  private onClick(): void {
    this.isSelected ? this.$emit('off') : this.$emit('on')
    this.isSelected = !this.isSelected
  }
}
