import { APIClientBase } from './api-client-base'
import { QuestionnaireDeleteRequest } from '@/dtos/questionnaires/delete'
import { QuestionnaireDetailGetRequest, QuestionnaireDetailGetResponse } from '@/dtos/questionnaires/get'
import { QuestionnairePostRequest } from '@/dtos/questionnaires/post'
import { QuestionnairePutRequest } from '@/dtos/questionnaires/put'
import { QuestionnaireQuestionDetailGetResponse, QuestionQuestionGetRequest } from '@/dtos/questionnaires/questions/get'
import { QuestionnairesRespondentsGetRequest, QuestionnairesRespondentsGetResponse } from '@/dtos/questionnaires/respondents/get'
import { QuestionnairesRespondentDetailGetRequest, QuestionnairesRespondentDetailGetResponse } from '@/dtos/questionnaires/respondents/get-detail'
import { QuestionnairesRespondentsPostRequest } from '@/dtos/questionnaires/respondents/post'
import { QuestionnaireAnswerLibraryPostRequest, QuestionnaireAnswerLibraryPostResponse } from '@/dtos/questionnaires/reports/answer-libraries/post'
import { QuestionnaireAnswerLibraryPutRequest } from '@/dtos/questionnaires/reports/answer-libraries/put'
import { QuestionnaireReportsPostRequest } from '@/dtos/questionnaires/reports/post'
import { QuestionnaireComparisonsGetRequest, QuestionnaireComparisonsGetResponse, QuestionnaireReportsResponse } from '@/dtos/questionnaires/reports/get'
import { QuestionnaireInternalReportPostRequest, QuestionnaireInternalReportPostResponse } from '@/dtos/questionnaires/reports/internal/post'
import { QuestionnaireOpinionsSearchPostRequest, QuestionnaireOpinionsSearchPostResponse } from '@/dtos/questionnaires/reports/opinions/post'
import { QuestionnaireQuestionsPostResponse, QuestionnaireQuestionsPostRequest } from '@/dtos/questionnaires/questions/post'
import { QuestionnairesSearchPostRequest, QuestionnairesSearchPostResponse } from '@/dtos/questionnaires/search/post'
import { QuestionnaireTemplatesDeleteRequest } from '@/dtos/questionnaires/templates/delete'
import { QuestionnaireTemplateDraftDeleteRequest } from '@/dtos/questionnaires/templates/draft/delete'
import { QuestionnaireTemplateDraftPostRequest } from '@/dtos/questionnaires/templates/draft/post'
import { QuestionnaireTemplatesGetRequest, QuestionnaireTemplatesGetResponse } from '@/dtos/questionnaires/templates/get'
import { QuestionnaireTemplateDetailGetRequest, QuestionnaireTemplateDetailGetResponse } from '@/dtos/questionnaires/templates/get-detail'
import { QuestionnaireTemplatePostRequest, QuestionnaireTemplatePostResponse } from '@/dtos/questionnaires/templates/post'
import { QuestionnaireTemplatePutRequest, QuestionnaireTemplatePutResponse } from '@/dtos/questionnaires/templates/put'
import { TemplateQuestionsGetResponse } from '@/dtos/questionnaires/templates/questions/get'

class QuestionnairesClient extends APIClientBase {
  constructor() {
    super('/questionnaires')
  }

  // テンプレート登録
  postQuestionnaireTemplate(req: QuestionnaireTemplatePostRequest): Promise<void | QuestionnaireTemplatePostResponse> {
    return this.post('/templates', req, QuestionnaireTemplatePostResponse)
  }

  // テンプレート下書き保存
  postQuestionnaireTemplateDraft(req: QuestionnaireTemplateDraftPostRequest): Promise<void> {
    return this.post('/templates/draft', req)
  }

  // テンプレート下書き削除
  async deleteQuestionnaireTemplateDraft(req: QuestionnaireTemplateDraftDeleteRequest): Promise<void> {
    return this.delete(`/templates/draft/${req.templateId}`)
  }

  // テンプレート更新
  putQuestionnaireTemplate(req: QuestionnaireTemplatePutRequest): Promise<void | QuestionnaireTemplatePutResponse> {
    const { templateId, ...body } = req
    return this.put(`/templates/${templateId}`, body, QuestionnaireTemplatePutResponse)
  }

  // テンプレート詳細取得
  getQuestionnaireTemplateDetail(req: QuestionnaireTemplateDetailGetRequest): Promise<QuestionnaireTemplateDetailGetResponse> {
    return this.get(`/templates/${req.templateId}`, '', QuestionnaireTemplateDetailGetResponse)
  }

  // アンケートテンプレート一覧検索
  async getQuestionnaireTemplates(req: QuestionnaireTemplatesGetRequest): Promise<QuestionnaireTemplatesGetResponse> {
    return this.get('/templates', req, QuestionnaireTemplatesGetResponse)
  }

  // アンケートテンプレート削除
  async deleteQuestionnaireTemplate(req: QuestionnaireTemplatesDeleteRequest): Promise<void> {
    return this.delete(`/templates/${req.templateId}`)
  }

  // アンケート一覧取得
  postQuestionnairesSearch(req: QuestionnairesSearchPostRequest): Promise<QuestionnairesSearchPostResponse | void> {
    return this.post('search', req, QuestionnairesSearchPostResponse, false)
  }

  // アンケート詳細取得
  getQuestionnaire(req: QuestionnaireDetailGetRequest): Promise<QuestionnaireDetailGetResponse> {
    return this.get(`${req.questionnaireId}`, '', QuestionnaireDetailGetResponse, false)
  }

  // アンケート登録
  postQuestionnaire(req: QuestionnairePostRequest): Promise<void> {
    return this.post('', req)
  }

  // アンケート更新
  putQuestionnaire(req: QuestionnairePutRequest): Promise<void> {
    return this.put(`${req.questionnaireId}`, req)
  }

  // アンケート削除
  deleteQuestionnaire(req: QuestionnaireDeleteRequest): Promise<void> {
    return this.delete(`${req.questionnaireId}`)
  }

  // アンケート下書き登録
  postQuestionnaireDraft(req: QuestionnairePostRequest): Promise<void> {
    return this.post('/draft', req)
  }

  // アンケート（設問）詳細取得
  getQuestionnaireQuestionDetail(req: QuestionQuestionGetRequest): Promise<QuestionnaireQuestionDetailGetResponse> {
    return this.get(`${req.questionnaireId}/questions/${req.questionnaireQuestionId}`, '', QuestionnaireQuestionDetailGetResponse, false)
  }

  // アンケート（回答者）一覧取得
  getQuestionnaireRespondents(req: QuestionnairesRespondentsGetRequest): Promise<QuestionnairesRespondentsGetResponse> {
    return this.get(`${req.questionnaireId}/respondents`, '', QuestionnairesRespondentsGetResponse, false)
  }

  // アンケート（回答者）詳細取得
  getQuestionnairesRespondentDetail(req: QuestionnairesRespondentDetailGetRequest): Promise<QuestionnairesRespondentDetailGetResponse> {
    return this.get(`${req.questionnaireId}/respondents/${req.userId}`, '', QuestionnairesRespondentDetailGetResponse, false)
  }

  // アンケート代理回答登録
  postQuestionnairesRespondentProxyAnswers(questionnaireId: string, req: QuestionnairesRespondentsPostRequest): Promise<void> {
    return this.post(`${questionnaireId}/respondents`, req)
  }

  // 報告書下書き削除
  deleteQuestionnairesReportsDrafts(questionnaireId: string, reportId: string): Promise<void> {
    return this.delete(`${questionnaireId}/reports/drafts/${reportId}`)
  }

  // 報告書詳細取得
  getQuestionnairesReports(questionnaireId: string, reportId: string): Promise<QuestionnaireReportsResponse> {
    return this.get(`${questionnaireId}/reports/${reportId}`, '', QuestionnaireReportsResponse, false)
  }

  // 報告書登録
  postQuestionnairesReports(questionnaireId: string, req: QuestionnaireReportsPostRequest): Promise<void> {
    return this.post(`${questionnaireId}/reports`, req)
  }

  // 報告書下書き登録
  postQuestionnairesReportsDrafts(questionnaireId: string, req: QuestionnaireReportsPostRequest): Promise<void> {
    return this.post(`${questionnaireId}/reports/drafts`, req)
  }

  // 報告書更新
  putQuestionnairesReports(questionnaireId: string, req: QuestionnaireReportsPostRequest): Promise<void> {
    return this.put(`${questionnaireId}/reports/${req.questionnaireReportId}`, req)
  }

  // Excel出力用 報告書情報取得
  postQuestionnaireInternalReports(req: QuestionnaireInternalReportPostRequest): Promise<void | QuestionnaireInternalReportPostResponse> {
    return this.post('/internal/report', req, QuestionnaireInternalReportPostResponse)
  }

  // 意見・要望一覧取得
  postQuestionnairesOpinionsSearch(req: QuestionnaireOpinionsSearchPostRequest): Promise<void | QuestionnaireOpinionsSearchPostResponse> {
    return this.post(`${req.questionnaireId}/opinions`, req, QuestionnaireOpinionsSearchPostResponse)
  }

  // テンプレート内アンケート一覧取得
  getQuestionnaireComparisons(req: QuestionnaireComparisonsGetRequest): Promise<QuestionnaireComparisonsGetResponse> {
    return this.get(`${req.questionnaireId}/comparisons`, null, QuestionnaireComparisonsGetResponse, false)
  }

  // アンケートテンプレート（設問）一覧取得
  getQuestionnaireTemplateQuestions(templateId: string): Promise<TemplateQuestionsGetResponse> {
    return this.get(`/templates/${templateId}/questions`, '', TemplateQuestionsGetResponse, false)
  }

  // アンケート（設問）一覧取得
  postQuestionnaireQuestions(questionnaireId: string, req: QuestionnaireQuestionsPostRequest): Promise<void | QuestionnaireQuestionsPostResponse> {
    return this.post(`${questionnaireId}/questions`, req, QuestionnaireQuestionsPostResponse)
  }

  // 模範回答一覧取得
  postQuestionnaireAnswerLibraries(req: QuestionnaireAnswerLibraryPostRequest): Promise<void | QuestionnaireAnswerLibraryPostResponse> {
    return this.post('answer-libraries', req, QuestionnaireAnswerLibraryPostResponse)
  }

  // 模範回答更新
  putQuestionnaireAnswerLibraries(questionnaireReportResultSectionAnswerId :string, req: QuestionnaireAnswerLibraryPutRequest): Promise<void> {
    return this.put(`answer-libraries/${questionnaireReportResultSectionAnswerId}`, req)
  }
}

export const questionnairesClient = new QuestionnairesClient()
