import { QuestionnaireTemplatePostRequest } from '@/dtos/questionnaires/templates/post'

export class QuestionnaireTemplatePutRequest extends QuestionnaireTemplatePostRequest {
  templateId!: string
  version!: number
}

export class QuestionnaireTemplatePutResponse {
  templateId!: string
}
