import { Section } from '@/dtos/resolutions/online-resolution/results/commons'

export class OnlineResultsPostRequest {
  resolutionId: string
  isPassed: boolean
  resultComment?: string
  sections?: Section[]
  version: number // 本体のオンライン決議のバージョンをpostでも指定

  constructor(resolutionId: string, isPassed: boolean, resultComment: string, sections: Section[], version: number) {
    this.resolutionId = resolutionId
    this.isPassed = isPassed
    this.resultComment = resultComment.length > 0 ? resultComment : undefined
    this.sections = sections.length > 0 ? sections : undefined
    this.version = version
  }
}
