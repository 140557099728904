import { Type } from 'class-transformer'

export class BuildingUnit {
  buildingUnitId!: string
  paymentPlanId!: string
  constructor(buildingUnitId: string, paymentPlanId:string) {
    this.buildingUnitId = buildingUnitId
    this.paymentPlanId = paymentPlanId
  }
}

export class ReservePaymentsPutRequest {
  @Type(() => BuildingUnit) buildingUnits: BuildingUnit[] = []
}
