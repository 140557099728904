







































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { RESOLUTION_STATES } from '@/constants/schema-constants'
import type { ResolutionState } from '@/constants/schema-constants'
import { ChipColors, CHIP_COLORS } from '@/constants/ux-constants'

class StateContent {
  public readonly stateType: string
  public readonly chipText: string
  public readonly chipColor: ChipColors

  constructor(stateType: string, chipText: string, chipColor: ChipColors) {
    this.stateType = stateType
    this.chipText = chipText
    this.chipColor = chipColor
  }
}

const resolutionStateContents: Record<ResolutionState, StateContent> = {
  [RESOLUTION_STATES.GENERAL_MEETING.DRAFT]: new StateContent('generalMeeting', '下書き', CHIP_COLORS.GREEN),
  [RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL]: new StateContent('generalMeeting', '意思表明受付中', CHIP_COLORS.WHITE2),
  [RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT]: new StateContent('generalMeeting', '意思表明受付中', CHIP_COLORS.WHITE2),
  [RESOLUTION_STATES.GENERAL_MEETING.COUNTING]: new StateContent('generalMeetingArchive', '結果集計中', CHIP_COLORS.WHITE2),
  [RESOLUTION_STATES.GENERAL_MEETING.RELEASED]: new StateContent('generalMeetingArchive', 'アーカイブ', CHIP_COLORS.WHITE2),
  [RESOLUTION_STATES.ONLINE.DRAFT]: new StateContent('online', '下書き', CHIP_COLORS.GREEN),
  [RESOLUTION_STATES.ONLINE.ACCEPTING_ALL]: new StateContent('online', '投票受付中', CHIP_COLORS.WHITE2),
  [RESOLUTION_STATES.ONLINE.ACCEPTING_VOTE]: new StateContent('online', '投票受付中', CHIP_COLORS.WHITE2),
  [RESOLUTION_STATES.ONLINE.COUNTING]: new StateContent('onlineArchive', '結果集計中', CHIP_COLORS.WHITE2),
  [RESOLUTION_STATES.ONLINE.RELEASED]: new StateContent('onlineArchive', 'アーカイブ', CHIP_COLORS.WHITE2),

  // not use
  [RESOLUTION_STATES.GENERAL_MEETING.DELETED]: new StateContent('generalMeetingArchive', 'アーカイブ', CHIP_COLORS.WHITE2),
  [RESOLUTION_STATES.ONLINE.DELETED]: new StateContent('onlineArchive', 'アーカイブ', CHIP_COLORS.WHITE2),

}

@Component({
  components: {
    SmBadgeContent: () => import('@/components/atoms/SmBadgeContent.vue'),

    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmCardResolution extends Vue {
  @Prop({
    required: true,
    validator: s => s in resolutionStateContents
  })
  private resolutionState!: ResolutionState

  @Prop({ required: true, default: '' })
  private title!: string

  @Prop()
  private deadline?: string

  @Prop()
  private holdingAt?: string

  // readCountを指定すると以降のカウントがカードに表示がされる
  @Prop()
  private readCount?: number

  @Prop()
  private voteCount?: number

  @Prop()
  private statementCount?: number

  @Prop()
  private questionCount?: number

  private get resolutionStateContent(): StateContent {
    return resolutionStateContents[this.resolutionState]
  }

  private get dateContext(): string | undefined {
    if (this.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL ||
    this.resolutionState === RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT) {
      return `総会開催日時：${this.holdingAt}`
    } else
    if (this.resolutionState === RESOLUTION_STATES.ONLINE.ACCEPTING_ALL ||
    this.resolutionState === RESOLUTION_STATES.ONLINE.ACCEPTING_VOTE) {
      return `投票締切日時：${this.deadline}`
    }
    return ''
  }
}
