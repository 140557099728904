


































import { Vue, Component, Prop } from 'vue-property-decorator'

export class Menu {
  id!:string
  icon!:string
  text!:string
}

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmOwMenu extends Vue {
  @Prop({ required: true })
  private readonly items!:Menu[]

  @Prop({ default: 'more_vert' })
  private readonly icon!: string
}
