













import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import Chart from 'chart.js'
import { mixins, Pie } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

// CSP対応のためインラインCSSインジェクションを抑止（代わりにstyle.cssにCSSを記載）
Chart.platform.disableCSSInjection = true

@Component({})
export default class SmChartPie extends Mixins(Pie, mixins.reactiveProp) {
  @Prop()
  chartData!: Chart.ChartData

  @Prop()
  chartOptions!: Chart.ChartOptions

  @Prop({ default: false })
  showDataLabels!: boolean

  @Watch('chartData')
  onChartDataChanged():void {
    this.load()
  }

  mounted():void {
    if (this.showDataLabels) this.addPlugin(ChartDataLabels)
    this.renderChart(this.chartData, this.chartOptions)
  }

  load():void {
    this.renderChart(this.chartData, this.chartOptions)
  }
}
