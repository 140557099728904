import { Type } from 'class-transformer'
import type { OwnerNotificationState, PinningSettingType, TargetBuildingType, TargetOwnerType } from '@/constants/schema-constants'
import { TargetBuilding, TargetOwner } from '@/dtos/owner-notifications/commons'

export class OwnerNotificationsSearchPostRequest {
  notificationStates?: OwnerNotificationState[] = []
  pinningSettingTypes?: PinningSettingType[] = []
  fromDate: string | null | undefined = null
  toDate: string | null | undefined = null
  buildings?: string[] = []
  adminId: string | null | undefined = null
  keyword: string | null | undefined = null
  sortOrder?: string
  skipToken?: string
  take: number

  constructor(take: number) { this.take = take }
}

export class OwnerNotification {
  ownerNotificationId!: string
  notificationState!: OwnerNotificationState
  title!: string
  notifiedAt?: string
  scheduledPostingAt?: string
  poster!: string
  pinningSettingType!: PinningSettingType
  pinningDeadline?: string
  targetBuildingType!: TargetBuildingType
  targetOwnerType!: TargetOwnerType
  targetBuildingCount?: number
  @Type(() => TargetBuilding) targetBuildings: TargetBuilding[] = []
  @Type(() => TargetOwner) targetOwners: TargetOwner[] = []
  ideaTitle?: string
  resolutionTitle?: string
}

export class OwnerNotificationsSearchPostResponse {
  @Type(() => OwnerNotification) ownerNotifications: OwnerNotification[] = []
  skipToken!: string
}
