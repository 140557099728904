import { UsersGetRequest, UsersGetResponse } from '@/dtos/users/get'
import { APIClientBase } from './api-client-base'

class UsersClient extends APIClientBase {
  constructor() {
    super('/users')
  }

  async getUsers(req: UsersGetRequest): Promise<UsersGetResponse> {
    return await this.get('', req, UsersGetResponse)
  }
}

export const usersClient = new UsersClient()
