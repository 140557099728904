











































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { staticRoutes } from '@/routes'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'
import { reservesPersonalPlanParser } from '@/libs/excel-parser/reserves-personal-plan-parser'
import { buildingsModule } from '@/stores/buildings-store'
import { reservePaymentsModule } from '@/stores/reserve-payments-store'

import { Header } from '@/components/molecules/SmTableData.vue'
import { BuildingDetailGetResponse } from '@/dtos/buildings/get-detail'
import { ReservePaymentDetailGetRequest, ReservePaymentDetailGetResponse } from '@/dtos/reserve-funds/payments/get-detail'
import { PersonalPlansGetRequest, PersonalPlansGetResponse } from '@/dtos/reserve-funds/payments/plans/get'
import { ReservesPersonalPlanProspectsGetRequest, ReservesPersonalPlanProspectsGetResponse } from '@/dtos/reserve-funds/payments/plans/prospects/get'

const DOWNLOAD_FAILED_MESSAGE = 'ファイルのダウンロードに失敗しました。画面を読み込み直すか、しばらくしてから再度お試しください。'
const INVALID_SELECTED_MONTH_MESSAGE = '変更する期に過去の月を含めないよう選択してください。'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),

    SmTableData: () => import('@/components/molecules/SmTableData.vue'),

    OutputPlanSelectModal: () => import('@/pages/reserve-funds/payments/OutputPlanSelectModal.vue')
  }
})
export default class PersonalPaymentPlansSubPage extends Mixins(CurrentAdminManager) {
  @Prop({ required: true })
  private readonly buildingUnitId!:string

  async created():Promise<void> {
    const req = new PersonalPlansGetRequest(this.buildingUnitId)
    await reservePaymentsModule.fetchPersonalPlans(req)
  }

  private get buidingDetailGet(): BuildingDetailGetResponse { return buildingsModule.buildingDetailGet ?? new BuildingDetailGetResponse() }
  private get personalPlans(): PersonalPlansGetResponse | undefined { return reservePaymentsModule.personalPlans(this.buildingUnitId) }
  private get paymentDetail(): ReservePaymentDetailGetResponse | undefined { return reservePaymentsModule.paymentDetail(this.buildingUnitId) }
  private get paymentProspect(): ReservesPersonalPlanProspectsGetResponse | undefined { return reservePaymentsModule.paymentPersonalProspect(this.buildingUnitId) }

  headers: Header[] = [
    new Header({ text: '作成日', value: 'plannedAt', width: '200px' }),
    new Header({ text: 'プランID', value: 'planCode', width: '110px' }),
    new Header({ text: 'プラン名', value: 'planName' }),
  ]

  period: number | null = null
  month: number | null = null
  errors: string[] = []

  private get periods():{label:string, value:number}[] {
    let currentPeriod = 1
    if (this.confirmedDate) {
      currentPeriod = this.confirmedDate.getFullYear() - this.buidingDetailGet.firstPeriodEndYear + 1
      if (this.confirmedDate.getMonth() + 1 > this.buidingDetailGet.closingMonth) {
        currentPeriod += 1
      }
    }
    return Array.from({ length: 60 - currentPeriod + 1 }).map((_, index) => { return { label: `${index + currentPeriod}`, value: index + currentPeriod } })
  }

  private get months():{label:string, value:number}[] {
    return Array.from({ length: 12 }).map((_, index) => { return { label: `${index + 1}`, value: index + 1 } })
  }

  private get isPeriodAndMonthSelected(): boolean {
    return this.period != null && this.month != null
  }

  private get confirmedDate(): Date | undefined {
    if (!this.personalPlans?.paymentConfirmedDate) return undefined
    return new Date(this.personalPlans.paymentConfirmedDate)
  }

  async downloadExcel(): Promise<void> {
    this.errors = []
    if (this.isConfirmedMonth) {
      this.errors.push(INVALID_SELECTED_MONTH_MESSAGE)
      return
    }

    await Promise.all([
      reservePaymentsModule.fetchPaymentPersonalProspect(new ReservesPersonalPlanProspectsGetRequest(this.buildingUnitId, this.appliedAt)),
      this.paymentDetail ? Promise.resolve() : reservePaymentsModule.fetchPaymentDetail(new ReservePaymentDetailGetRequest(this.buildingUnitId)), // 別タブで取得済みならそれを使用
    ])
    if (!this.paymentProspect || !this.paymentDetail) {
      this.errors.push(DOWNLOAD_FAILED_MESSAGE)
      return
    }

    try {
      await reservesPersonalPlanParser.downloadAsExcel(this.buidingDetailGet.buildingName, this.paymentProspect, this.paymentDetail)
    } catch (err) {
      this.errors.push(DOWNLOAD_FAILED_MESSAGE)
      throw err
    }
  }

  private get appliedAt(): string { // PersonalPlanEditPageと同ロジック
    const closingMonth = buildingsModule.buildingDetailGet?.closingMonth ?? 0
    let period = this.period ?? this.periods[0].value
    const month = (this.month ?? this.months[0].value)
    // buildingsModule.periodToYearMonthで取得する年の調整
    if (month > closingMonth) {
      period -= 1
    }
    const year = buildingsModule.periodToYearMonth(period).substring(0, 4)
    return `${year}-${('00' + month).slice(-2)}-01`
  }

  createNewPlan(): void {
    if (!this.isPeriodAndMonthSelected) return // period & month selected
    this.errors = []
    if (this.isConfirmedMonth) {
      this.errors.push(INVALID_SELECTED_MONTH_MESSAGE)
      return
    }
    this.$router.push({
      name: staticRoutes.reserveFundsPersonalEdit.name,
      params: { buildingUnitId: this.buildingUnitId },
      query: { period: this.period?.toString(), month: this.month?.toString() }
    })
  }

  private get isConfirmedMonth(): boolean {
    if (!this.isPeriodAndMonthSelected) return false // period & month selected

    let targetYear = this.buidingDetailGet.firstPeriodEndYear + (this.period ?? this.periods[0].value) - 1
    if ((this.month ?? this.months[0].value) > this.buidingDetailGet.closingMonth) {
      targetYear -= 1
    }
    const targetDate = new Date(`${targetYear}-${this.month}-01`)
    if (!this.confirmedDate) return false
    return targetDate <= this.confirmedDate
  }
}
