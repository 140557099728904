







































































































import { Tab } from '@/components/molecules/SmTabs.vue'
import { staticRoutes } from '@/routes'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { TicketsGetRequest, TicketGetResponse } from '@/dtos/tickets/get'
import { TicketAdminInChargePutRequest, AdminUserId } from '@/dtos/tickets/admin-in-charge/put'
import { TicketClosePutRequest } from '@/dtos/tickets/close/put'
import { ticketsModule } from '@/stores/tickets-store'
import { ADMIN_ROLE, TICKET_STATES, TICKET_TYPES } from '@/constants/schema-constants'
import { getTicketStateLabel, getTicketStateChipColor } from '@/libs/state-handler'
import { getTicketBadge, getTicketLabel } from '@/libs/type-handler'
import { User } from '@/dtos/commons'

// チケットのステータスチップ用クラス
class TicketChipItem {
  text!: string
  colorType!: string
}

@Component({
  components: {
    SmBadgeCategory: () => import('@/components/atoms/SmBadgeCategory.vue'),
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmListUser: () => import('@/components/molecules/list/SmListUser.vue'),

    SmDialogText: () => import('@/components/organisms/dialog/SmDialogText.vue'),
    StaffSelectModal: () => import('@/components/organisms/modal/StaffSelectModal.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),
  }
})
export default class TicketDetailPage extends Vue {
  TICKET_STATES = Object.freeze(TICKET_STATES)
  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)

  @Prop({ required: true, default: '' })
  private readonly ticketId!: string

  extensionHeight = 304

  tabs: Tab[] = [
    new Tab('チケット内容', staticRoutes.ticketDetail.getChild('detail').name),
    new Tab('タスク', staticRoutes.ticketDetail.getChild('tasks').name),
    new Tab('コメント・メモ', staticRoutes.ticketDetail.getChild('comments').name),
  ]

  async created():Promise<void> {
    await this.fetchTicketDetail()
  }

  async mounted():Promise<void> {
    // 画面サイズ変更でタイトルの行が変わるため、画面サイズ変更のたびにヘッダのサイズを再計算
    window.addEventListener('resize', this.resizeHeader)
  }

  updated():void {
    // 画面描画が完了するのを待つため、0.5秒後に処理を動かす
    this.$nextTick(function() {
      setTimeout(() => {
        this.resizeHeader()
      }, 500)
    })
  }

  resizeHeader(): void {
    const smTemplateRef = this.$refs.smTemplateRef as Vue
    if (!smTemplateRef) return
    const extensionRef = smTemplateRef.$el.getElementsByClassName('v-toolbar__extension')
    if (!extensionRef) return
    const targetDom = extensionRef[0].children[0]
    this.extensionHeight = targetDom.clientHeight
  }

  private get detail(): TicketGetResponse {
    return ticketsModule.ticketDetail(this.ticketId) ?? new TicketGetResponse()
  }

  private get ticketIcon(): string { return getTicketBadge(this.detail.ticketType ?? TICKET_TYPES.IDEA).icon }
  private get ticketIconColor(): string { return getTicketBadge(this.detail.ticketType ?? TICKET_TYPES.IDEA).iconColor }
  private get ticketTextStyle(): string { return getTicketBadge(this.detail.ticketType ?? TICKET_TYPES.IDEA).textStyle }
  private get ticketText(): string { return `${this.detail.ticketNo}｜${getTicketLabel(this.detail.ticketType ?? TICKET_TYPES.IDEA)}｜${this.detail.ticketName}` }

  private get ticketChipItem(): TicketChipItem {
    const item = new TicketChipItem()
    item.text = getTicketStateLabel(this.detail.ticketState)
    item.colorType = getTicketStateChipColor(this.detail.ticketState)
    return item
  }

  isCloseDialogVisible = false
  async onClickTicketClose(): Promise<void> {
    this.isCloseDialogVisible = false
    const req = new TicketClosePutRequest(this.detail.ticketId, this.detail.version)
    await ticketsModule.putTicketClose(req)
    await this.fetchTicketDetail()
  }

  isAdminSelectModalVisible = false
  staffSelectInputText = ''
  staffSelectKeyword = ''

  async onChangeAdminInCharge(selectedStaffs:User[]): Promise<void> {
    if (selectedStaffs.length !== 1) throw new Error('管理者が複数選択されています')
    const req = new TicketAdminInChargePutRequest(this.detail.ticketId, new AdminUserId(selectedStaffs[0].userId), this.detail.version)
    await ticketsModule.putTicketAdminInCharge(req)
    await this.fetchTicketDetail()
  }

  async fetchTicketDetail(): Promise<void> {
    await ticketsModule.fetchTicketDetail(new TicketsGetRequest(this.ticketId))
  }
}
