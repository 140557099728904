import { Type } from 'class-transformer'
import type { TicketTaskNameType, TicketTaskState, TicketTaskType } from '@/constants/schema-constants'
import { TaskBuilding, TaskInquirySource } from './search/post'

export class Task {
  taskName!: string
  deadline!: string
}

export class TaskPostRequest {
  ticketId!:string
  taskName!:string
  deadline!:string

  constructor(ticketId:string, taskName:string, deadline:string) {
    this.ticketId = ticketId
    this.taskName = taskName
    this.deadline = deadline
  }
}

export class TaskPostResponse {
  taskId!: string
  taskType!: TicketTaskType
  taskState!: TicketTaskState
  taskNameType!: TicketTaskNameType
  taskName!: string
  deadline!: string
  deadlineDate!: string
  postedAt!: string
  version!: number
  @Type(() => TaskInquirySource) inquirySources?: TaskInquirySource[]
  @Type(() => TaskBuilding) building?: TaskBuilding
}
