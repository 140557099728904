













































































































import { Mixins, Component } from 'vue-property-decorator'
import { ENQUETE_CHART_BACKGROUND_COLORS } from '@/constants/ux-constants'
import { CurrentAdminManager } from '@/mixins/current-admin-manager'

import { AdminIdeaDetailGetResponse } from '@/dtos/ideas/admin-idea/get-detail'
import { BaseIdea } from '@/dtos/commons'

import { generateUuid } from '@/libs/uuid-generator'
import { staticRoutes, staticRoutesLocal } from '@/routes'

import { adminIdeasModule } from '@/stores/admin-ideas-store'
import { myProfileModule } from '@/stores/my-profile-store'
import { questionnaireTemplatesModule } from '@/stores/questionnaire-templates-store'
import { questionnaireReportsModule } from '@/stores/questionnaire-reports-store'
import { QuestionnaireQuestionsPostRequest } from '@/dtos/questionnaires/questions/post'

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),

    SmLegend: () => import('@/components/atoms/SmLegend.vue'), // グラフの凡例で使用
    SmText: () => import('@/components/atoms/SmText.vue'),

    SmChartUnionReportHorizontalBar: () => import('@/components/molecules/SmChartUnionReportHorizontalBar.vue'),
    SmChartUnionReportPie: () => import('@/components/molecules/SmChartUnionReportPie.vue'),
    QuestionSelectModal: () => import('@/components/organisms/modal/QuestionSelectModal.vue'),

    QuestionnaireAnswerLibrarySelectModal: () => import('@/components/organisms/modal/QuestionnaireAnswerLibrarySelectModal.vue'),
  }
})

export default class HelloWorldPage1 extends Mixins(CurrentAdminManager) {
  ENQUETE_CHART_BACKGROUND_COLORS = Object.freeze(ENQUETE_CHART_BACKGROUND_COLORS)

  async beforeCreate(): Promise<void> {
    await myProfileModule.fetchMyProfile()
  }

  async created(): Promise<void> {
    await questionnaireTemplatesModule.fetchTemplateQuestions('670a7136-45e1-4f35-90cd-0a88f5115732')
  }

  isQuestionModalVisible = false
  questionnaireKey = generateUuid()
  questionIds: string[] = []

  isTemplateQuestionModalVisible = false
  templateKey = generateUuid()
  templateQuestionIds: string[] = []

  async onClickQuestionnaire(reqQuestionIds:string[]): Promise<void> {
    this.questionnaireKey = generateUuid()
    this.questionIds.push(...reqQuestionIds)
  }

  onClickTemplate(reqQuestionIds:string[]): void {
    this.templateKey = generateUuid()
    this.templateQuestionIds = reqQuestionIds
  }

  async openQuestionSelectModal(): Promise<void> {
    const questionnaireId = '0ce695af-4c33-40ff-93b3-47d0ce2ccc13'
    const req = new QuestionnaireQuestionsPostRequest(this.questionIds)
    await questionnaireReportsModule.fetchQuestionnaireQuestions({ questionnaireId, req })
    this.isQuestionModalVisible = true
  }

  openTemplateQuestionSelectModal():void {
    this.isTemplateQuestionModalVisible = true
  }

  testWord = ''
  subject = this.initialSubject

  private get initialSubject(): {subjectTitle: string, subjectType?: string, baseIdea: BaseIdea } {
    const baseIdea = new BaseIdea('ede66c43-9b9d-4222-93ed-5f11c96e08e2')
    return { subjectTitle: '', subjectType: undefined, baseIdea: baseIdea }
  }

  private get baseIdeaDetail(): AdminIdeaDetailGetResponse { return adminIdeasModule.detailResponse('f3779ebe-4a2a-4156-9d4f-2be9f0a6bbae') ?? new AdminIdeaDetailGetResponse() }

  clickIcon(): void { console.log('icon') }
  clickBtn(): void { console.log('btn') }
  selectBaseIdeaId(ideaId: string): void { console.log('ideaId:' + ideaId) }

  goToGMResolutionCreatePage(): void {
    this.$router.push({ name: staticRoutes.gmResolutionCreate.name, query: { resolutionId: 'f3779ebe-4a2a-4156-9d4f-2be9f0a6bbae' } })
  }

  goToGMResolutionUpdatePage(): void {
    this.$router.push({ name: staticRoutes.gmResolutionUpdate.name, params: { resolutionId: 'f3779ebe-4a2a-4156-9d4f-2be9f0a6bbae' } })
  }

  goToPage2(): void {
    this.$router.push({ name: staticRoutesLocal.helloWorld.getChild('page2').name })
  }

  inputAnswer(answer:string): void {
    // answerをご意見とご回答の回答テキストフィールドに代入する
    console.log(answer)
  }

  // 報告書出力モーダル
  isQuestionnaireAnswerLibrarySelectModalVisible = false
  questionnaireAnswerLibrarySelectModalKey = generateUuid()
  openQuestionnaireAnswerLibrarySelectModal(): void {
    this.questionnaireAnswerLibrarySelectModalKey = generateUuid()
    this.isQuestionnaireAnswerLibrarySelectModalVisible = true
  }
}
