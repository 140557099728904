import { Type } from 'class-transformer'
import { User } from '@/dtos/commons'

export class RepairsUpdatesCommentDetailGetRequest {
  commentId!: string

  constructor(commentId: string) {
    this.commentId = commentId
  }
}

export class CommentDetailGetResponseLog {
  previous!: string
  current!: string
}

export class RepairsUpdatesCommentDetailGetResponse {
  commentId!: string
  comment!: string
  logId?: string
  @Type(() => CommentDetailGetResponseLog) logs?: CommentDetailGetResponseLog[]
  postedAt!: string
  @Type(() => User) user = new User()
  version!: number
}
