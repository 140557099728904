






















































































































import { Component, Vue } from 'vue-property-decorator'
import { ADMIN_ROLE, CONSULTATION_STATES } from '@/constants/schema-constants'
import { ConsultationUpdateFormInputs } from '@/components/molecules/card/SmCardConsultationUpdate.vue'
import { Column, Row, Cell } from '@/components/molecules/SmTableRepairPlanDetail.vue'
import { repairsModule } from '@/stores/repairs-store'
import { staffsModule } from '@/stores/staffs-store'
import { Material, User } from '@/dtos/commons'
import { RepairsExpensesGetRequest, RepairsExpensesGetResponse } from '@/dtos/repairs/expenses/get'
import { RepairsItemsGetRequest, RepairsItemsGetResponse } from '@/dtos/repairs/items/get'
import { RepairsUpdatesCommentsPostRequest } from '@/dtos/repairs/updates/comments/post'
import { RepairsUpdatesCommentsPutRequest } from '@/dtos/repairs/updates/comments/put'
import { RepairsUpdatesLogsGetRequest } from '@/dtos/repairs/updates/logs/get'
import { RepairsUpdatesLogDetailGetRequest } from '@/dtos/repairs/updates/logs/get-detail'
import { StaffsGetResponseStaff, StaffsGetRequest } from '@/dtos/staffs/get'

/**
 * periodごとの列のid（period1 , period2・・・）。ヘッダ行とデータ行とで各カラムのidを一致させる。
 */
function getPeriodId(period: number): string {
  return 'period' + period
}

@Component({
  components: {
    SmSelect: () => import('@/components/atoms/SmSelect.vue'),

    SmCardConsultationDetail: () => import('@/components/molecules/card/SmCardConsultationDetail.vue'),
    SmCardConsultationSummary: () => import('@/components/molecules/card/SmCardConsultationSummary.vue'),
    SmCardConsultationUpdate: () => import('@/components/molecules/card/SmCardConsultationUpdate.vue'),
    SmChartRepairPlan: () => import('@/components/molecules/SmChartRepairPlan.vue'),
    SmMaterialInputArea: () => import('@/components/molecules/SmMaterialInputArea.vue'),
    SmMenu: () => import('@/components/molecules/SmMenu.vue'),
    SmTableRepairPlan: () => import('@/components/molecules/SmTableRepairPlan.vue'),
    SmTableRepairPlanDetail: () => import('@/components/molecules/SmTableRepairPlanDetail.vue'),
  }
})

export default class HelloWorldPage4 extends Vue {
  CONSULTATION_STATES = Object.freeze(CONSULTATION_STATES)

  async created(): Promise<void> {
    repairsModule.fetchRepairsExpenses(new RepairsExpensesGetRequest())
    repairsModule.fetchRepairsItems(new RepairsItemsGetRequest())

    const repairsUpdatesLogsGetRequest = new RepairsUpdatesLogsGetRequest(0, 1000)
    repairsModule.fetchRepairsUpdatesLogs(repairsUpdatesLogsGetRequest)

    const repairsUpdatesLogDetailGetRequest = new RepairsUpdatesLogDetailGetRequest('2222')
    repairsModule.fetchRepairsUpdatesLogDetail(repairsUpdatesLogDetailGetRequest)

    const postUpdatesComments = new RepairsUpdatesCommentsPostRequest()
    postUpdatesComments.comment = '直接仮説の予定している工事の実施時期を以下の通り変更しましたのでお知らせします。'
    repairsModule.postRepairsUpdatesComments(postUpdatesComments)

    const putUpdatesComments = new RepairsUpdatesCommentsPutRequest()
    putUpdatesComments.commentId = '1111'
    putUpdatesComments.comment = '直接仮説の予定している工事の実施時期を以下の通り変更しましたのでお知らせします。'
    repairsModule.putRepairsUpdatesComments(putUpdatesComments)

    const staffsGetRequest = new StaffsGetRequest(ADMIN_ROLE.SERVICE_STAFF)
    staffsGetRequest.keyword = '榊原英太郎'
    await staffsModule.fetchStaffs(staffsGetRequest)
  }

  private get exampleOwnerUser(): User {
    const exampleOwnerUser = new User()
    exampleOwnerUser.userId = '111'
    exampleOwnerUser.iconImagePath = 'https://cdn.vuetifyjs.com/images/john.jpg'
    exampleOwnerUser.userName = '佐藤健太'
    exampleOwnerUser.roomNumber = '301号室'
    return exampleOwnerUser
  }

  private get exampleAdminUser(): User {
    const exampleAdminUser = new User()
    exampleAdminUser.userId = '222'
    exampleAdminUser.iconImagePath = 'https://cdn.vuetifyjs.com/images/john.jpg'
    exampleAdminUser.userName = '榊原英太郎（サービス担当）'
    return exampleAdminUser
  }

  private get exampleMaterial(): Material {
    const exampleMaterial = new Material()
    exampleMaterial.materialId = 'aga51gtg-6tgg-5ta6-y7ga-56ya6ha46hga'
    exampleMaterial.materialType = 1
    exampleMaterial.materialUrl = 'http://placehold.jp/800x600.png'
    exampleMaterial.originalFileName = 'dummy.jpg'
    return exampleMaterial
  }

  updateConsultation(inputs: ConsultationUpdateFormInputs): void {
    console.log(`body:${inputs.message}, material:${inputs.material}`)
  }

  onClickCancelBtn(): void {
    console.log('キャンセルボタンがクリックされました')
  }

  onClickUpdateBtn(): void {
    console.log('編集ボタンがクリックされました')
  }

  onClickDeleteBtn(): void {
    console.log('削除ボタンがクリックされました')
  }

  onClickCardConsultationSummary(): void {
    console.log('cardConsultationSummaryがクリックされました')
  }

  private get repairsExpenses(): RepairsExpensesGetResponse { return repairsModule.repairsExpensesGet }
  private get repairsItems(): RepairsItemsGetResponse { return repairsModule.repairsItemsGet }

  // ヘッダ行
  private get columns(): Column[] {
    // ラベル列 -> 期の一覧 -> 総計列 という構造の表を作る。
    const periods = this.repairsItems.totalRows?.periodTotals.map(total => {
      return {
        id: getPeriodId(total.period),
        period: total.period,
        yearMonth: '2021/3'
        /* yearMonth: myCurrentBuildingModule.periodToYearMonth(total.period) */
      }
    }) ?? []
    return [{ id: 'rowLabel', isCategoryColumn: true }, ...periods, { id: 'itemTotal', isTotalColumn: true }]
  }

  // データ行（全体）
  private get rows(): Row[] {
    return [...this.detailRows, ...this.totalRows]
  }

  // データ行（明細）
  private get detailRows(): Row[] {
    // カテゴリの配列がそれぞれ修繕項目の配列を持っているものを、フラットな1つの配列に変換
    return this.repairsItems.detailRows?.categories.flatMap(category => {
      const periods = category.items.map(item => {
        const cells = item.periods.reduce((acc: Record<string, Cell>, current) => {
          acc[getPeriodId(current.period)] = { prospect: current.prospect ?? undefined, performance: current.performance ?? undefined }
          return acc
        }, {})

        return { rowLabel: item.itemLabel, cells: cells, itemTotal: { total: item.total } }
      })

      return [
        { rowLabel: category.categoryLabel, isCategoryRow: true },
        ...periods,
      ]
    }) ?? []
  }

  // データ行（合計値）
  private get totalRows(): Row[] {
    if (!this.repairsItems) return []
    const taxExcludedCells : Record<string, Cell> = {}
    const taxCells : Record<string, Cell> = {}
    const taxIncludedCells : Record<string, Cell> = {}
    const cumulativeCells : Record<string, Cell> = {}

    if (this.repairsItems.totalRows?.periodTotals) {
      for (const periodTotal of this.repairsItems.totalRows.periodTotals) {
        const periodId = getPeriodId(periodTotal.period)

        taxExcludedCells[periodId] = { total: periodTotal.taxExcluded }
        taxCells[periodId] = { total: periodTotal.tax }
        taxIncludedCells[periodId] = { total: periodTotal.taxIncluded }
        cumulativeCells[periodId] = { total: periodTotal.cumulative }
      }
    }

    const rowTotal = this.repairsItems.totalRows?.rowTotal
    return [
      { rowLabel: '単年度修繕費', cells: taxExcludedCells, itemTotal: { total: rowTotal?.taxExcluded }, isBlueTotalRow: true },
      { rowLabel: '単年度消費税', cells: taxCells, itemTotal: { total: rowTotal?.tax }, isBlueTotalRow: true },
      { rowLabel: '単年度合計', cells: taxIncludedCells, itemTotal: { total: rowTotal?.taxIncluded }, isGrayTotalRow: true },
      { rowLabel: '修繕費累計', cells: cumulativeCells, itemTotal: { total: rowTotal?.cumulative }, isGrayTotalRow: true },
    ]
  }

  menuId = 'menu1'

  onClickMenu(id: string): void {
    console.log(id)
    this.menuId = id
  }

  menuItems = {
    menu1: {
      text: '更新履歴',
    },
    menu2: {
      text: '当初計画を再設定する',
    }
  }

  selectedUserId = ''

  onClickSelect(selectedUserId: string): void {
    console.log(`${selectedUserId}が選択されました`)
  }

  private get serviceStaffs(): StaffsGetResponseStaff[] { return staffsModule.staffs(ADMIN_ROLE.SERVICE_STAFF) }

  private get selectionItems(): { value: string, label: string }[] {
    let items = [{ value: '', label: 'すべて' }]
    const serviceStaffs = this.serviceStaffs.map(s => { return { value: s.userId, label: s.userName } })
    items = items.concat(serviceStaffs)
    return items
  }
}
