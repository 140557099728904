



































































































import { Vue, Component, Prop } from 'vue-property-decorator'

interface Material {
  materialId: string
  materialType: number
  materialUrl: string
  originalFileName: string
  caption: string
}

interface Elements {
  label: string
  body: string
  material?: Material
}

export class Budget {
  budget!:number
  budgetLabel!:string
  expense!:number
  expenseLabel!:string
  spent?:number
  spentLabel?:string
  balanceLabel!:string
}

@Component({
  components: {
    SmBtnText: () => import('@/components/atoms/SmBtnText.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextHyperlinked: () => import('@/components/atoms/SmTextHyperlinked.vue'),

    SmMaterialDisplay: () => import('@/components/molecules/SmMaterialDisplay.vue'),

    SmOwBtnToggle: () => import('@/components/owner-apps/SmOwBtnToggle.vue'),
    SmOwExpansionArea: () => import('@/components/owner-apps/SmOwExpansionArea.vue'),
    SmOwListBudget: () => import('@/components/owner-apps/SmOwListBudget.vue'),

  }
})
export default class SmOwListPlan extends Vue {
  @Prop({ default: false })
  private readonly value!: boolean

  @Prop({ required: true, default: 0 })
  private readonly index!:number

  @Prop({ required: true, default: 1 })
  private readonly length!:number

  @Prop({ required: true, default: '' })
  private readonly planName!:string

  @Prop({ required: true, default: 0 })
  private readonly count!:number

  @Prop({ default: () => [] })
  private readonly budgets!:Budget[]

  @Prop({ default: () => [] })
  private readonly elements!:Elements[]

  @Prop({ default: true })
  private readonly canAgree!:boolean

  // 各プランのインデックスをアルファベットに変換
  private get planCapName(): string {
    if (this.length !== 1) {
      return 'プラン' + this.convertNumToCapital(this.index)
    } else {
      return 'プラン'
    }
  }

  private convertNumToCapital(index: number): string {
    let temp
    let letter = ''
    // プランの数が27個以上になることは想定されないが、以降AA、ABに変換する処理を含んでいる
    while (index >= 0) {
      temp = index % 26
      letter = String.fromCharCode(temp + 65) + letter
      index = (index - temp - 1) / 26
    }
    return letter
  }

  private get isAgree(): boolean { return this.value }
  private set isAgree(newValue: boolean) { this.$emit('input', newValue) }

  private agree():void {
    this.$emit('agree')
  }

  private disagree():void {
    this.$emit('disagree')
  }
}
