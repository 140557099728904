import type { InitialAuthCodeType } from '@/constants/schema-constants'

export class UnitsPutRequest {
  unitId!: string
  votingRightCount!: number
  casysName!: string
  userName?: string
  isLivingOutside!: boolean
  isPaperUseApplied!: boolean
  initialAuthCode?: string
  initialAuthCodeType?: InitialAuthCodeType
  personalMemo?: string
}

export class UnitsChangePutRequest {
  unitId!: string
  relatedUserId?: string
  casysName!: string
  userName?: string
  joinedDate?: string
  owningEndDate?: string
  isLivingOutside!: boolean
  isPaperUseApplied!: boolean
  initialAuthCode?: string
  personalMemo?: string
}
