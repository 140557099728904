import { Type } from 'class-transformer'
import { IRepairPlansIO, IRepairPlansIOCategoryItem } from './common'

class RepairPlanGetCategoryItem implements IRepairPlansIOCategoryItem {
  categoryLabel!: string
  itemLabel!: string
  prospects: (number | undefined)[] = []
  performances: (number | undefined)[] = []
}

export class RepairPlanGetResponse implements IRepairPlansIO {
  currentPeriod!: number
  planningStartPeriod!: number
  uploadedFileName!: string
  @Type(() => RepairPlanGetCategoryItem) categories: RepairPlanGetCategoryItem[] = []
  originals!: number[]
}
