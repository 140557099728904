import { Type } from 'class-transformer'
import { User } from '@/dtos/commons'

export class RepairsUpdatesCommentsGetRequest {
  keyword?: string
  sortOrder?: string
  skip: number
  take: number

  constructor(skip: number, take: number) {
    this.skip = skip
    this.take = take
  }
}

export class CommentsGetResponseLog {
  previous!: string
  current!: string
}

export class Comment {
  commentId!: string
  comment!: string
  logId?: string
  @Type(() => CommentsGetResponseLog) logs?: CommentsGetResponseLog[]
  postedAt!: string
  @Type(() => User) user: User = new User()
}

export class RepairsUpdatesCommentsGetResponse {
  @Type(() => Comment) comments: Comment[] = []
}
