





















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmBadgeCategory extends Vue {
  @Prop({ required: true, default: '' })
  private readonly icon!: string

  @Prop({ required: true, default: '#000000' })
  private readonly iconColor!: string

  @Prop({ required: true, default: '' })
  private readonly text!: string

  @Prop({ required: true, default: 'black' })
  private readonly textStyle!: string

  @Prop({ default: false })
  private readonly noTruncate!: boolean
}
