

















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { OWNER_NOTIFICATION_STATE, PINNING_SETTING_TYPE } from '@/constants/schema-constants'
import type { OwnerNotificationState, PinningSettingType } from '@/constants/schema-constants'
import { CHIP_COLORS } from '@/constants/ux-constants'
import type { ChipColors } from '@/constants/ux-constants'
import { assertExhaustive } from '@/libs/exhaustive-helper'

class ChipContent {
  public readonly chipText: string
  public readonly chipColor: ChipColors

  constructor(chipText: string, chipColor: ChipColors) {
    this.chipText = chipText
    this.chipColor = chipColor
  }
}

const notificationsChipContents: Record<OwnerNotificationState, ChipContent> = {
  [OWNER_NOTIFICATION_STATE.DRAFT]: new ChipContent('下書き', CHIP_COLORS.GREEN),
  [OWNER_NOTIFICATION_STATE.SCHEDULED]: new ChipContent('公開予定', CHIP_COLORS.WHITE1),
  [OWNER_NOTIFICATION_STATE.NOTIFIED]: new ChipContent('公開中', CHIP_COLORS.WHITE2),
  [OWNER_NOTIFICATION_STATE.ARCHIVED]: new ChipContent('アーカイブ', CHIP_COLORS.WHITE2)
}

@Component({
  components: {
    SmBadgeContent: () => import('@/components/atoms/SmBadgeContent.vue'),
    SmChip: () => import('@/components/atoms/SmChip.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
  }
})
export default class SmCardOwnerNotification extends Vue {
  @Prop({
    required: true,
    validator: s => s in notificationsChipContents
  })
  private notificationState!: OwnerNotificationState

  @Prop({ required: true, default: PINNING_SETTING_TYPE.NORMAL })
  private pinningSettingType!: PinningSettingType

  @Prop({ required: true, default: '' })
  private title!: string

  @Prop()
  private notifiedAt?: string

  @Prop()
  private scheduledPostingAt?: string

  @Prop()
  private pinningDeadline?: string

  @Prop({ required: true, default: '' })
  private postedTo!: string

  @Prop({ required: true, default: '' })
  private poster!: string

  private get pinningSettingStateType(): string {
    switch (this.pinningSettingType) {
      case PINNING_SETTING_TYPE.NORMAL: return 'normalOwnerNotification'
      case PINNING_SETTING_TYPE.PINNED: return 'pinnedOwnerNotification'
      default: return assertExhaustive(this.pinningSettingType)
    }
  }

  private get notificationChipContent(): ChipContent {
    return notificationsChipContents[this.notificationState]
  }

  private get isBorder(): boolean {
    return this.notificationState === OWNER_NOTIFICATION_STATE.SCHEDULED
  }
}
