













































































































































import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { ADMIN_ROLE, OPERATION_STATES, TargetExecutorType, TARGET_EXECUTOR_TYPE } from '@/constants/schema-constants'
import type { OperationState } from '@/constants/schema-constants'
import { RadioOption } from '@/components/atoms/SmRadio.vue'
import { assertExhaustive } from '@/libs/exhaustive-helper'
import { generateUuid } from '@/libs/uuid-generator'

export class ManagementOperationsEditItem {
  managementOperationTaskStatusId!: string
  adminId?: string
  adminName?: string
  adminShortName?: string
  unregisteredExecutorId?: string
  unregisteredExecutorName?: string
  operationState!: OperationState
  completedAt? :string
  targetDate! :string
  memo!: string
  buildingName!: string
  taskName!: string
  version!: number
  managementOperationsIndex!: string
  managementOperationsTaskProgressIndex!: string
}

@Component({
  components: {
    SmBtn: () => import('@/components/atoms/SmBtn.vue'),
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmRadio: () => import('@/components/atoms/SmRadio.vue'),

    SmDatePickers: () => import('@/components/molecules/SmDatePickers.vue'),
    SmDatePickersMonth: () => import('@/components/molecules/SmDatePickersMonth.vue'),
    SmTextarea: () => import('@/components/molecules/SmTextarea.vue'),

    SmDialogClosable: () => import('@/components/organisms/dialog/SmDialogClosable.vue'),

    ExecutorSelectModal: () => import('@/pages/tasks/onboardingAndRoutine/ExecutorSelectModal.vue'),
  }
})
export default class SmDialogManagementOperationEdit extends Vue {
  OPERATION_STATES = Object.freeze(OPERATION_STATES)
  ADMIN_ROLE = Object.freeze(ADMIN_ROLE)

  @Prop({ required: true, default: () => [] })
  private readonly managementOperationsEditItem!: ManagementOperationsEditItem

  @Prop({ default: false })
  @Model('change')
  private readonly visible!: boolean

  memo=''
  private get isDialogVisible(): boolean {
    this.memo = this.managementOperationsEditItem.memo
    // 一覧表から受け取ったデータを元に本コンポーネントで使うデータにそれぞれ反映
    this.completedAt = this.managementOperationsEditItem.completedAt ?? ''
    if (this.managementOperationsEditItem.operationState) this.changeRadio(this.managementOperationsEditItem.operationState)

    if (this.managementOperationsEditItem.adminId) {
      this.managementOperationsEditItem.unregisteredExecutorId = undefined
      this.managementOperationsEditItem.unregisteredExecutorName = undefined
    } else if (this.managementOperationsEditItem.unregisteredExecutorId) {
      this.managementOperationsEditItem.adminId = undefined
      this.managementOperationsEditItem.adminName = undefined
    } else {
      this.managementOperationsEditItem.unregisteredExecutorId = undefined
      this.managementOperationsEditItem.unregisteredExecutorName = undefined
      this.managementOperationsEditItem.adminId = undefined
      this.managementOperationsEditItem.adminName = undefined
    }
    return this.visible
  }

  private set isDialogVisible(newValue: boolean) {
    this.$emit('change', newValue)
  }

  onClickExecute(): void {
    this.$emit('execute', this.managementOperationsEditItem)
    this.isDialogVisible = false
  }

  onClickClose(): void {
    this.$emit('close')
  }

  changeExecutor():void {
    this.isExecutorSelectModalVisible = true
  }

  private get operationStateOption(): RadioOption[] {
    return [
      new RadioOption('未', OPERATION_STATES.UNRESOLVED, false, 'caution'),
      new RadioOption('済', OPERATION_STATES.COMPLETED),
      new RadioOption('対象外', OPERATION_STATES.OUT_OF_SCOPE),
    ]
  }

  selectFromMonth(newValue: string): void {
    this.managementOperationsEditItem.targetDate = newValue
  }

  completedAt = this.managementOperationsEditItem.completedAt ?? ''

  selectFromDate(newValue: string): void {
    this.managementOperationsEditItem.completedAt = newValue
  }

  isDataPickerDisabled = !(this.managementOperationsEditItem.completedAt) ?? true
  isDataPickerRequired = (this.managementOperationsEditItem.operationState === OPERATION_STATES.COMPLETED) ?? false

  changeRadio(newValue: OperationState): void {
    switch (newValue) {
      case OPERATION_STATES.UNRESOLVED:
      case OPERATION_STATES.OUT_OF_SCOPE:
        this.isDataPickerDisabled = true
        this.isDataPickerRequired = false
        this.completedAt = ''
        this.managementOperationsEditItem.completedAt = undefined
        break
      case OPERATION_STATES.COMPLETED:
        this.isDataPickerDisabled = false
        this.isDataPickerRequired = true
        this.completedAt = this.managementOperationsEditItem.completedAt ?? ''
        break
      default: return assertExhaustive(newValue)
    }
  }

  isExecutorSelectModalVisible = false
  executorSelectModalKey = generateUuid()
  async openOwnerGroupSelectModal(): Promise<void> {
    this.executorSelectModalKey = generateUuid()
    this.isExecutorSelectModalVisible = true
  }

  get targetExecutorType(): TargetExecutorType {
    if (this.managementOperationsEditItem.adminId) return TARGET_EXECUTOR_TYPE.SERVICE_STAFF
    if (this.managementOperationsEditItem.unregisteredExecutorId) return TARGET_EXECUTOR_TYPE.UNREGISTERED_EXECUTOR
    return TARGET_EXECUTOR_TYPE.SERVICE_STAFF
  }

  get selectedTargetExecutorIdByModal():string {
    if (this.managementOperationsEditItem.adminId) return this.managementOperationsEditItem.adminId
    if (this.managementOperationsEditItem.unregisteredExecutorId) return this.managementOperationsEditItem.unregisteredExecutorId
    return ''
  }

  executorSelectModalServiceStaffKeyword = ''
  executorSelectModalServiceStaffInputText = ''
  executorSelectModalUnregisteredExecutorKeyword = ''
  executorSelectModalUnregisteredExecutorInputText = ''

  onSelectExecutorByModal(selectedTargetExecutorType: TargetExecutorType, selectedTargetId: string, selectedTargetName: string, selectedAdminShortName?: string): void {
    switch (selectedTargetExecutorType) {
      case TARGET_EXECUTOR_TYPE.SERVICE_STAFF:
        this.managementOperationsEditItem.adminId = selectedTargetId
        this.managementOperationsEditItem.adminName = selectedTargetName
        this.managementOperationsEditItem.adminShortName = selectedAdminShortName
        this.managementOperationsEditItem.unregisteredExecutorId = undefined
        this.managementOperationsEditItem.unregisteredExecutorName = undefined
        break
      case TARGET_EXECUTOR_TYPE.UNREGISTERED_EXECUTOR:
        this.managementOperationsEditItem.adminId = undefined
        this.managementOperationsEditItem.adminName = undefined
        this.managementOperationsEditItem.adminShortName = undefined
        this.managementOperationsEditItem.unregisteredExecutorId = selectedTargetId
        this.managementOperationsEditItem.unregisteredExecutorName = selectedTargetName
        break
      default: return assertExhaustive(selectedTargetExecutorType)
    }
    this.isExecutorSelectModalVisible = false
  }

  executorName(): string {
    if (this.managementOperationsEditItem.adminName) return this.managementOperationsEditItem.adminName
    if (this.managementOperationsEditItem.unregisteredExecutorName) return this.managementOperationsEditItem.unregisteredExecutorName
    return '未設定'
  }

  changeMemo(newValue: string): void {
    this.managementOperationsEditItem.memo = newValue
  }
}
