






































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { buildingsModule } from '@/stores/buildings-store'
import { MATERIAL_TYPES } from '@/constants/schema-constants'
import { reservePaymentsModule } from '@/stores/reserve-payments-store'
import { Plan, UnitPaymentPlan, ReservePaymentsPdfGetRequest, ReservesPaymentsPdfGetResponse } from '@/dtos/reserve-funds/payments/pdf/get'
import { RESERVE_PLAN_GRAPH_LINES } from '@/constants/ux-constants'

@Component({
  components: {
    SmText: () => import('@/components/atoms/SmText.vue'),
    SmTextPrintSizeAnnounce: () => import('@/components/atoms/SmTextPrintSizeAnnounce.vue'),

    SmPrintLabel: () => import('@/components/molecules/SmPrintLabel.vue'),

    SmPrintHeader: () => import('@/components/organisms/SmPrintHeader.vue'),

    SmTemplate: () => import('@/components/templates/SmTemplate.vue'),

    SmChartReservesPayments: () => import('@/pages/reserve-funds/payments/print/SmChartReservesPayments.vue'),
    SmTableReservesPayments: () => import('@/pages/reserve-funds/payments/print/SmTableReservesPayments.vue'),
  }
})
export default class SelectReservePlanPrintPage extends Vue {
  @Prop({ required: true })
  private readonly buildingUnits!:string[]

  @Prop({ required: true })
  private readonly paymentPlans!:string[]

  async created():Promise<void> {
    const req = new ReservePaymentsPdfGetRequest()
    req.buildingUnits = this.buildingUnits
    req.paymentPlans = this.paymentPlans

    await reservePaymentsModule.fetchPaymentsPdf(req)
  }

  MATERIAL_TYPES = Object.freeze(MATERIAL_TYPES)
  RESERVE_PLAN_GRAPH_LINES = Object.freeze(RESERVE_PLAN_GRAPH_LINES)

  private get paymentPlansPdf():ReservesPaymentsPdfGetResponse { return reservePaymentsModule.paymentPlansPdf }

  private get myCurrentBuilding():string | undefined {
    return buildingsModule.buildingDetailGet?.buildingName
  }

  private get tableTitle():((plans:Plan[], index:number) => string) {
    return (plans:Plan[], index:number) => {
      return `${String.fromCharCode('A'.charCodeAt(0) + index)}プラン | ${plans[index].planName}`
    }
  }

  private totalPageOfProspectivePlans(unitPaymentPlan: UnitPaymentPlan): number {
    // 1ページあたり1or2つなので、 1or2->1, 3or4->2, ... になるよう計算
    return Math.floor((unitPaymentPlan.prospectivePlans.length + 1) / 2)
  }
}
